import React from 'react';

import PropTypes from 'prop-types';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';
import DateTimeSelectComponent from 'components/common/DateTimeSelectComponent';
import TimeComponent from 'components/common/TimeSelectComponent';

import CorrectionSurvey from './CorrectionSurvey.png';

import styles from './styles.module.scss';

class FeedbackQuestionModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  

  render() {
    const { feedbackResponse , title , dateFormatDisplay } = this.props;
    return (
          <div>
            <div className={styles['feedback-question-container']}>
                <div className={styles['feedback-question-header']}><img src={CorrectionSurvey} height="15" width="15"/> Created by: {feedbackResponse[0].created_by}</div>
                <div className={styles['feedback-question-header']}>Created at: {feedbackResponse[0].create_date}</div>
            </div>
            <div className={`${styles['custom-survey-label']}`}>{feedbackResponse[0].label}</div>
            <div className={`${styles['feedback-que-section']}`}>
            {feedbackResponse && feedbackResponse.length && feedbackResponse.map((item,index)=>
            <div>
              {(item.definition == 'single_select' || item.definition == 'SINGLE_SELECT') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'multi_select' || item.definition == 'MULTI_SELECT') &&
                <div>
                  <MultiSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                  </div>
              }
              {(item.definition == 'free_text' || item.definition == 'FREE_TEXT') &&
                <div>
                  <FreeSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'boolean' || item.definition == 'BOOLEAN') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'date_time_select' || item.definition == 'DATE_TIME_SELECT') &&
                <div>
                  <DateTimeSelectComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }   
              {(item.definition == 'time_select' || item.definition == 'TIME_SELECT') &&
                <div>
                  <TimeComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
            </div>
          )}
        </div>
        </div>
    );
  }
};

export default FeedbackQuestionModal;