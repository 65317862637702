 
//import { useParams } from "react";

//const params = useParams();
const employeeId =(window.location.pathname).split('/')[2]
const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/applicant', label: 'Applicants' },
];

export const NAV = {
  ApplicantListPage: {
    title: 'Applicants',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    parent: '/dashboard',
    description: 'View & manage all your Applicants profiles',
  },
  ApplicantCreatePage: {
    title: 'Add Applicant',
    breadcrumb: [
      ...baseNav,
      { label: 'New' },
    ],
    parent: '/applicant/list',
    // description: 'Add new Employee to your Company',
  },
  ApplicantEditPage: {
    title: 'Edit Applicant',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/applicant/list',
    // description: 'Add new Employee to your Company',
  },
  
};

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];


export const LOGIN_STATUS_FILTER_OPTIONS = [
  { value: 'ALL', label: 'All' },
  { value: 'SUCCESS', label: 'Valid' },
  { value: 'FAILURE', label: 'Invalid' },
];  


export const TABS = [
  {key: 'detail', label: 'Applicant Detail'},
];

export const ACTIVITY_TYPE ={
  punch_in : 'Punch In',
  punch_out : 'Punch Out',
  meal_start : 'Meal Start',
  meal_end : 'Meal End',
  'Invalid user' :'Invalid user'
}

export default { NAV, FILTER_OPTIONS, TABS,LOGIN_STATUS_FILTER_OPTIONS,ACTIVITY_TYPE };
