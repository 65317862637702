import React from "react";

import PropTypes from "prop-types";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import { toast } from "react-toastify";

import * as RouteDucks from "ducks/routes";
import * as JobDucks from "ducks/jobs/job";
import * as InfoDucks from "ducks/info/info";
import * as CustomerDucks from "ducks/customers/customer";
import * as NoteDucks from "ducks/jobs/comment";
import * as TaskDucks from "ducks/jobs/task";
import * as EmployeeDucks from "ducks/employees/employee";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";

import TwoColumnLayout from "layouts/TwoColumn";
import SectionLoaderAtom from "atoms/SectionLoader";

import MealDetailsForm from "../../components/vendors/mealAdjustments/MealDetailsForm";
import MealProgressBarSection from "../../sections/vendors/form/ProgressBar";

import { NAV } from "constants/vendors";

/**
 * JobFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class MealAdjustmentsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parent: NAV.VendorMealAdjustmentsAddFormPage.parent,
      isMealAddInfo: true,
      mealTypes: {},
      rules:{},
      employees:[],
      is_active:null,
      initialValues: {
        meal_type: null,
        name: null,
        description: null,
      },
    };
    // this.fetchData = this.fetchData.bind(this);
    // this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    this.setState({ isLoading: true });

    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;

    Promise.all([
      this.props.getMealTypes({
        company_id: this.props.userExtraData?.company?.id,
        page_size: 10,
        paginate: true,
        is_active: true,
        id: params.id,
      }),
    ])
      .then((responses) => {
        const mealTypesResponse = responses[0];
        this.setState({
          is_active:mealTypesResponse?.value?.is_active,
          rules:mealTypesResponse?.value?.rule_configuration,
          mealTypes: mealTypesResponse?.value?.rule_configuration,
          employees:mealTypesResponse?.value?.employees,
          initialValues: {
            meal_type: mealTypesResponse?.value?.meal_rule_type,
            name: mealTypesResponse?.value?.name,
            description: mealTypesResponse?.value?.description,
          },
          isLoading:false,
        });
      })
      .catch((error) => {
        toast.error(error?.non_field_errors);
        this.setState({ isLoading: false });
      });
  }

  setParentUrl(query) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleInfo() {
    const { isMealAddInfo } = this.state;
    this.setState({ isMealAddInfo: !isMealAddInfo });
    cookie.save("meal_add_form_info", !isMealAddInfo);
  }

  render() {
    const { isLoading, isJobEditInfo, isMealAddInfo, rules } = this.state;
    const {
      jobDetail,
      infoList,
      location: { query },
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const selectedId = params ? params.id : null;
    return (
      <div>
        <TwoColumnLayout
          navInfo={selectedId ? NAV.VendorMealAdjustmentsEditFormPage:NAV.VendorMealAdjustmentsAddFormPage}
          infoList={infoList}
          isInfoOpen={isMealAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          editJob
          jobDetail={jobDetail}
        >
          <Row
            className="pt-4 ml-3 mr-4"
            style={{ backgroundColor: "white", borderTop: "2px solid #09819A" }}
          >
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <MealProgressBarSection
                    active={1}
                    isInfoOpen={isMealAddInfo}
                    location={this.props.location}
                    selectedId={selectedId}
                    rules={rules}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                {isLoading ? (
                    <SectionLoaderAtom active />
                  ) : (
                  <MealDetailsForm
                    selectedId={selectedId}
                    initialValues={this.state.initialValues}
                    mealTypes={this.state.mealTypes}
                    is_active={this.state.is_active}
                    employees={this.state.employees}
                  />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-5" />
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  getMealTypes: bindActionCreators(AdjustmentTypesDucks.getMealTypes, dispatch),
});

MealAdjustmentsDetails.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

MealAdjustmentsDetails.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MealAdjustmentsDetails);
