import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as BusinessAdminDucks from 'ducks/businessAdmins/businessAdmin';

import BasicSettingFormComponent from 'components/businessAdmins/basicSetting/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/businessAdmins';

/**
 * BasicSettingFormPage -> BasicSettingFormSection
 *
 * Components:
 *    - {@link BasicSettingFormComponent}
 *
 * State:
 *    - setting detail
 *
 * Actions:
 *    - Form:
 *        - update basic settings
 */
class BasicSettingFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(data) {
    return this.props.BusinessAdminDucks.putBusinessAdmin(data)
      .then(() => toast.success(MSGS.BASIC_SETTING_UPDATE_SUCCESS));
  }

  render() {
    const { isLoading, businessAdminDetail } = this.props;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <BasicSettingFormComponent 
        update={this.update}
        initialValues={businessAdminDetail}
      />
    );
  }
}

const mapStateToProps = state => ({
  businessAdminDetail: BusinessAdminDucks.businessAdminDetail(state),

});

const mapActionToProps = dispatch => ({
  BusinessAdminDucks: bindActionCreators(BusinessAdminDucks, dispatch),
});

BasicSettingFormSection.propTypes = ({
  BusinessAdminDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(BasicSettingFormSection);
