import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_NOTIFICATION_SETTINGS = 'vendors/notificationSetting/GET_NOTIFICATION_SETTINGS';
const GET_NOTIFICATION_SETTINGS_FULFILLED = 'vendors/notificationSetting/GET_NOTIFICATION_SETTINGS_FULFILLED';
const GET_NOTIFICATION_SETTING = 'vendors/notificationSetting/GET_NOTIFICATION_SETTING';
const GET_NOTIFICATION_SETTING_FULFILLED = 'vendors/notificationSetting/GET_NOTIFICATION_SETTING_FULFILLED';
const PUT_NOTIFICATION_SETTING = 'vendors/notificationSetting/PUT_NOTIFICATION_SETTING';
const PUT_NOTIFICATION_SETTING_FULFILLED = 'vendors/notificationSetting/PUT_NOTIFICATION_SETTING_FULFILLED';
const TOGGLE_NOTIFICATION_STATUS = 'vendors/notificationSetting/TOGGLE_NOTIFICATION_STATUS;'


export function getNotificationSettings(params) {
  return {
    type: GET_NOTIFICATION_SETTINGS,
    payload: axios.get(URLS.NOTIFICATION_SETTING, { params }),
  };
}

export function getNotificationSetting(params) {
  return {
    type: GET_NOTIFICATION_SETTING,
    payload: axios.get(`${URLS.NOTIFICATION_SETTING}${params.id}/`),
  };
}

export function toggleNotificationStatus(data) {
  return {
    type: TOGGLE_NOTIFICATION_STATUS,
    payload: data,
  }
}

export function putNotificationSetting(data) {
  return {
    type: PUT_NOTIFICATION_SETTING,
    payload: axios.put(`${URLS.NOTIFICATION_SETTING}${data.id}/`, data),
  };
}


const defaultState = {
  list: {},
  detail: {},
};

function GET_NOTIFICATION_SETTINGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_NOTIFICATION_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function TOGGLE_NOTIFICATION_STATUS_REDUCER(state, action) {
  const detail = { ...state.detail };
  if (detail.id === action.payload.id) {
    detail.is_active = !detail.is_active;
  }

  return Object.assign({}, state, { detail });
}

function PUT_NOTIFICATION_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_NOTIFICATION_SETTINGS_FULFILLED]: GET_NOTIFICATION_SETTINGS_FULFILLED_REDUCER,
  [GET_NOTIFICATION_SETTING_FULFILLED]: GET_NOTIFICATION_SETTING_FULFILLED_REDUCER,
  [PUT_NOTIFICATION_SETTING_FULFILLED]: PUT_NOTIFICATION_SETTING_FULFILLED_REDUCER,
  [TOGGLE_NOTIFICATION_STATUS]: TOGGLE_NOTIFICATION_STATUS_REDUCER,

};


const notificationSettingSelector = state => state.vendors.notificationSetting;

export const notificationSettingList = createSelector(
  notificationSettingSelector,
  instance => instance.list,
);

export const notificationSettingDetail = createSelector(
  notificationSettingSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
