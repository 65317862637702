import React from 'react';

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/jobs/task';
import SectionLoaderAtom from 'atoms/SectionLoader';

import TaskBulkUploadFormComponent from 'components/jobs/jobTaskMappingList/BulkUploadForm';
/**
 * TaskBulkListPage -> TaskBulkUploadSection
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class TaskBulkUploadSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.sampleFile = this.sampleFile.bind(this);
    this.state={errorEmp:{}, stats:{}, csvData : "" , isBulkLoading: false}
  }
  
  update(data){
    this.setState({isBulkLoading: true});
    return this.props.TaskDucks.postBulkTask(data)
      .then((res) => {
        if(res.value.stats && (res.value.stats.created === res.value.stats.total)){
          toast.success("Tasks Uploaded Succesfully")
        }
        else if(res.value.stats && (res.value.stats.updated === res.value.stats.total)){
          toast.success("Tasks Updated Succesfully")
        }
        this.setState({ errorEmp : {}, stats : res.value.stats, csvData : {}, isBulkLoading: false})
      })
      .catch((err) => {
        if(err.error){
          toast.error(err.error)
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }  
        else if(err.task){
          toast.error(err.task)
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }  
        else if(err.stats && !(err.stats.created) && !(err.stats.updated)){
          toast.error("Unable to upload tasks");
        }
        else if(err.stats && (err.stats.updated === err.stats.total)){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        else if(err.stats && ((err.stats.create_failed !== err.stats.total) || (err.stats.update_failed !== err.stats.total))){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        this.setState({ errorEmp : err.data, stats: err.stats, csvData : err.data_csv, isBulkLoading: false})
      })
  }

  sampleFile(){
    return this.props.TaskDucks.getBulkTask();
  }


  render() {
    const { isLoading } = this.props;
    const { errorEmp , stats, csvData, isBulkLoading} = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <TaskBulkUploadFormComponent 
          update={this.update}
          errorEmp={errorEmp}
          sampleFile={this.sampleFile}
          stats={stats}
          csvData={csvData}
          isBulkLoading={isBulkLoading}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
});

TaskBulkUploadSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

TaskBulkUploadSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TaskBulkUploadSection);