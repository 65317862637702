import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';

import { SORT_ORDERS } from 'constants/records';
import {ReactComponent as SortIcon} from './sort.svg';
import {ReactComponent as SortAscIcon} from './sort_asc.svg';
import {ReactComponent as SortDescIcon} from './sort_desc.svg';

class SortHeaderWithSeparateKeysComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.sortDirection = this.sortDirection.bind(this);

    const { router: { route: { location: { search } } } } = context;
    const query = queryString.parse(search);
    this.state = { order: query.order };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { location: { search: prevSearch } } } } = this.context;
    const { router: { route: { location: { search: nextSearch } } } } = nextContext;
    const query = queryString.parse(nextSearch);

    if (prevSearch !== nextSearch) {
      this.setState({ order: query.order });
    }
  }

  handleChange() {
    const { router: { history, route: { location: { pathname, search } } } } = this.context;
    const { field } = this.props;
    const query = queryString.parse(search);

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        field,
        order: query.order === SORT_ORDERS.DESC ? SORT_ORDERS.ASC : SORT_ORDERS.DESC,
      }),
    });
  }

  sortDirection() {
    const { router: { route: { location: { search } } } } = this.context;
    const { field } = this.props;
    const { order } = this.state;
    const query = queryString.parse(search);

    if (order && query.field === field) {
      return query.order === SORT_ORDERS.DESC 
        ? <SortDescIcon width="12" height="12" />
        : <SortAscIcon width="12" height="12" />;
    }
    return <SortIcon width="12" height="12" />;
  }

  render() {
    const { children } = this.props;

    return (
      <th className="width-sm">
        {children}
        <Button
          color="link"
          className="p-0 pl-1"
          onClick={this.handleChange}
        >
          {this.sortDirection()}
        </Button>
      </th>
    );
  }
}

SortHeaderWithSeparateKeysComponent.propTypes = {
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
};

SortHeaderWithSeparateKeysComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SortHeaderWithSeparateKeysComponent;
