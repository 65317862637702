import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';
import styles from './styles.module.scss';
import SortHeaderComponent from 'components/common/SortHeader';
/**
 * JobReportsPage -> JobReportsListSection ->  JobReportsTableComponent
 *
 * Props:
 *    - employee list
 */
const JobReportsTableComponent = (props, context) => {
  // const { router: { route: { match: { params } } } } = context;
  const { location: { query }, pageSize, data, showableAttributes, toggle, goTo} = props;
  const { page = 1 } = query;
  // const { query, pathname, search } = location;
  return (
   <div className="ml-3 mr-4">
      <Table responsive hover size="sm" className="table-reports">
      <thead>
        <tr>
          {showableAttributes.date &&
            <SortHeaderComponent ordering="date" className="pl-3">Date</SortHeaderComponent>
          }
          {showableAttributes.first_name &&
            <SortHeaderComponent ordering="first_name">First</SortHeaderComponent>
          }
          {showableAttributes.last_name &&
            <SortHeaderComponent ordering="last_name">Last</SortHeaderComponent>
          }
          {showableAttributes.job_name &&
            <SortHeaderComponent ordering="job_name">Job</SortHeaderComponent>
          }
          {showableAttributes.task_name &&
            <SortHeaderComponent ordering="task_name">Task</SortHeaderComponent>
          }
          {showableAttributes.customer_name &&
            <SortHeaderComponent ordering="customer_name">Customer</SortHeaderComponent>
          }
          {showableAttributes.start_datetime &&
            <SortHeaderComponent ordering="start_datetime">Start Time</SortHeaderComponent>
          }
          {showableAttributes.end_datetime &&
            <SortHeaderComponent ordering="end_datetime">End Time</SortHeaderComponent>
          }
          {showableAttributes.total_time &&
            <SortHeaderComponent ordering="total_time" className="text-right">Hours</SortHeaderComponent>
          }
          {showableAttributes.labor_cost &&
            <SortHeaderComponent ordering="labor_cost" className="text-right pr-3">Labor Cost</SortHeaderComponent>
          }
        </tr>
      </thead>
      <tbody>
        {data.length ? data.filter((item, index) =>
          index < page * pageSize && index >= (page - 1) * pageSize
        ).map(item => {
          if(item.start_datetime === 'Total'){
            return (<tr className={styles['total-row']}>
              <td className="text-left pl-3" hidden={!showableAttributes.first_name}><b>Total</b></td>
              <td hidden={!showableAttributes.date} />
              <td hidden={!showableAttributes.last_name}/>
              <td hidden={!showableAttributes.job_name}/>
              <td hidden={!showableAttributes.task_name}/>
              <td hidden={!showableAttributes.customer_name}/>
              <td hidden={!showableAttributes.start_datetime}/>
              <td hidden={!showableAttributes.end_datetime}/>
              <td hidden={!showableAttributes.total_time} className="text-right"><b>{item.total_time}</b></td>
              <td hidden={!showableAttributes.labor_cost}/>
            </tr>)
          }
          return (<tr>
            <td className="text-left pl-3" title={item.date} hidden={!showableAttributes.date}>{item.date}</td>
            <td className="text-left" hidden={!showableAttributes.first_name} title={item.first_name}>
              {item.first_name ? <span role="button" onClick={() => goTo(`/employee/${item.employee_id}/edit/detail`, "employee")} className={styles['clickable']}>{item.first_name}</span> : null}
            </td>
            <td className="text-left" hidden={!showableAttributes.last_name} title={item.last_name}>{item.last_name}</td>
            <td className="text-left" hidden={!showableAttributes.job_name} title={item.job_name}>
              {item.job_name ? <span role="button" onClick={() => goTo(`/job/list/${item.job_id}/edit`, "job")} className={styles['clickable']}>{item.job_name}</span> : null}
            </td>
            <td className="text-left" hidden={!showableAttributes.task_name} title={item.task_name}>{item.task_name}</td>
            <td className="text-left" hidden={!showableAttributes.customer_name} title={item.customer_name}>
              {item.customer_name ? <span role="button" onClick={() => goTo(`/customers/list/${item.customer_id}/details`, "customer")} className={styles['clickable']}>{item.customer_name}</span> : null}
            </td>
            <td className="text-left" hidden={!showableAttributes.start_datetime} title={item.start_datetime}>{item.start_datetime}</td>
            <td className="text-left" hidden={!showableAttributes.end_datetime} title={item.end_datetime}>{item.end_datetime}</td>
            <td hidden={!showableAttributes.total_time} className={`${styles['clickable-timecard']} text-right`}><p role="button" onClick={() => toggle(item)} className="d-inline-block" style={{'marginBottom':'0'}}>{item.total_time}</p><span className={styles['view-timecard']} role="button" onClick={() => goTo(`/timecard/custom/list/${item.employee_id}/daily`, "timecards", item)}>View Timecards</span></td>
            <td hidden={!showableAttributes.labor_cost} className="text-right pr-3" title={item.labor_cost}>{item.labor_cost && item.labor_cost.toFixed(2)}</td>
          </tr>);
        }) : <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
      </tbody>
      </Table>
   </div>
  );
};

JobReportsTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default JobReportsTableComponent;