import React from 'react';

import moment from 'moment';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { UncontrolledTooltip } from 'reactstrap';
import formValueSelector from 'redux-form/lib/formValueSelector';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import SelectAtom from 'atoms/Select';
import AllocateTaskModalContent from 'components/activities/activitySplitForm/AllocateTaskModalContent';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';
import closeimage from 'components/activities/activitySplitForm/Close-Cross.png';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> AllocateTaskModalComponent
 *Select
 * Props:
 *    - update
 */
class AllocateTaskModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.update = this.update.bind(this);
    this.state = {startTime:null, endTime: null}
  }

  // function to call after successfull validation of editable fields ans setting the state of formatted start and end time
  update(data){
    const {startTime, endTime} = this.state;
    if(data.start_time){
      this.setState({startTime: data.start_time});
    }
    else if(data.end_time){
      this.setState({endTime: data.end_time});
    }
  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime && data.start_datetime_tz.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }


  submit(data) {
    const { saveTask, initialValues } = this.props;
    const {startTime, endTime} = this.state;
    const { id, ...rest } = data;
    const updatedStartTime = moment(startTime ? startTime : data.start_time, "h:mm:ss A").format("HH:mm:ss");
    const updatedStartDateTime = moment(`${moment(data.start_datetime).format('YYYY-MM-DD')}T${updatedStartTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedEndTime = moment(endTime ? endTime : data.end_time, "h:mm:ss A").format("HH:mm:ss");
    let updatedEndDateTime = moment(`${moment(data.end_datetime).format('YYYY-MM-DD')}T${updatedEndTime}`, 'YYYY-MM-DD HH:mm:ss')
    const saveData = initialValues && initialValues.id !== null  ? {...rest , activity_code: 'TASK', id: initialValues && initialValues.id, start_datetime :  moment(updatedStartDateTime).toISOString() , end_datetime :  moment(updatedEndDateTime).toISOString() } : {...rest, activity_code: 'TASK' ,  start_datetime :  moment(updatedStartDateTime).toISOString() , end_datetime :  moment(updatedEndDateTime).toISOString()};
    return saveTask({...saveData});
  }

  render() {
    const { handleSubmit, isOpen, taskList, timeFormat24Hrs, dateFormat, toggle, change, jobList, job, getTasksForJob, task, timeFormat , initialValues } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        className="job-task-modal form-modal"
      >
        <ModalHeader>
          Search Tasks and Allocate Times!!
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody className={`${styles['task-modal']} ml-3 mr-4`}>
            <AllocateTaskModalContent
              handleSubmit={handleSubmit}
              taskList={taskList}
              timeFormat24Hrs={timeFormat24Hrs}
              dateFormat={dateFormat}
              change={change}
              job={job}
              jobList={jobList}
              getTasksForJob={getTasksForJob}
              task={task}
              timeFormat={timeFormat}
              submit={this.submit}
              validateInput={this.validate}
              initialValues={{...initialValues, start_time: moment(initialValues.start_datetime).format('LT') , end_time : moment(initialValues.end_datetime).format('LT')}}
              update={this.update}
              browserParseDate={this.props.browserParseDate}
            />
          </ModalBody>
          <ModalFooter className="ml-3 mr-4">
            <Button color="default" className={styles['cancel-btn']} onClick={toggle} size={FORM_LAYOUT.size} >
              Cancel
            </Button>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size} disabled={this.props.submitLoader} >
              Save <ButtonLoaderAtom active={this.props.submitLoader} />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.job) errors.job = 'Enter Job';
  if (!values.task) errors.task = 'Enter Task'
  if (!values.start_datetime) errors.start_datetime = 'Select Start Date';
  if (!values.end_datetime) errors.end_datetime = 'Select End Date';
  return errors;
};

AllocateTaskModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  saveTask: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
};

const AllocateTaskModal = reduxForm({
  form: 'AllocateJobTaskFormModal',
  validate,
  enableReinitialize: true,
})(AllocateTaskModalComponent);

const selector = formValueSelector('AllocateJobTaskFormModal');

export default connect(
  (state) => {
    const job = selector(state, 'job');
    const task = selector(state, 'task');
    return { job, task };
  },
)(AllocateTaskModal);