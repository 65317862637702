import React from 'react';
import {connect} from 'react-redux';
import UserAPIGateway from 'accounts/gateways/user';
import PaymentAPIGateway from 'payments/paymentMethods/gateways/paymentMethod';
import ActivateUseCase from 'accounts/usecases/activation';
import ActivationForm from 'accounts/components/activation';
import {  Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import ContentLayout from 'layouts/Content';
import * as UserDucks from 'ducks/accounts/user';

class ActivationPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    const paymentAPIGateway = new PaymentAPIGateway();
    this.usecase = new ActivateUseCase(userAPIGateway, paymentAPIGateway);
    this.listenActivationUsecase = this.listenActivationUsecase.bind(this);
    this.state = {
      editEmail: false,
      editPno: false,
      resendingCode: false,
      customerDetail : [],
    }
  }

  componentWillMount(){
    this.listenActivationUsecase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }


  listenActivationUsecase() {
    const { userCompany } = this.props;
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'ACTIVATION_SUCCESS':{
          this.usecase.createCustomer(
            userCompany.id,
            userCompany.email,
            userCompany.name,
            userCompany.phone_no,
          )
          this.props.dispatch({
            type: 'accounts/user/RESET_STATE',
          });
          break;
        }
        case 'UPDATE_SUCCESS':{
          if(event.data === 'email') this.updateState('editEmail', false);
          else this.updateState('editPno', false);
          this.props.dispatch({
            type: 'accounts/user/PUT_PROFILE_FULFILLED',
            payload: event.profile,
          });
          break;
        }
        case 'RESEND_CODE_SUCCESS':{
          toast.success('New Activation Code has been sent. You can now verify them.');
          this.updateState('resendingCode', false);
          break;
        }

        case 'CREATE_CUSTOMER_SUCCESS':
          this.props.dispatch({
            type: 'vendors/payment/GET_CUSTOMER_FULFILLED',
            payload: event.detail,
          })
          this.props.history.push('/accounts/terms&condition');
          /*this.props.history.push('/dashboard');*/
        break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }
 
  render() {
    const { userProfile } = this.props;
    return (
      <ContentLayout>
        <Row className="content justify-center">
          <Col xs={12} md={{ size:5}} className="align-self-center">
            <ActivationForm
              data={userProfile}
              usecase={this.usecase}
              handleClick={this.handleClick}
              handleClick2={this.handleClick2}
              editEmail={this.state.editEmail}
              editPno={this.state.editPno}
              updateState={this.updateState}
              resendingCode={this.state.resendingCode}
            />
          </Col>
       </Row>
      </ContentLayout>
    );
  }

}

const mapStateToProps = (state) => ({
  userProfile: UserDucks.profile(state),
  userCompany: UserDucks.userCompany(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivationPage);