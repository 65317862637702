import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as RouteDucks from "ducks/routes";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as DailyTimecardDucks from "ducks/timecards/dailyTimecard";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as InfoDucks from "ducks/info/info";
import * as UserDucks from "ducks/accounts/user";
import * as PayrollDucks from "ducks/payrolls/payroll";
import * as ActivityDucks from "ducks/activities/activity";
import * as DepartmentDucks from "ducks/vendors/department";
import * as GeoFenceDucks from "ducks/geoFences/geoFence";
import * as TimecardDucks from "ducks/timecards/timecard";

import TwoColumnLayout from "layouts/TwoColumn";
import DailyTimecardFilterSection from "sections/timecards/customDailyTimecardList/Filter";
import DailyTimecardListSection from "sections/timecards/customDailyTimecardList/ListV2";
import ActivityInProgressSection from "sections/timecards/dailyTimecardList/ActivityInProgress";

import { NAV } from "constants/timecards";

class CustomDailyTimecardListPageV2 extends React.Component {
  constructor(props) {
    super(props);
    this.fetchDailyTimecards = this.fetchDailyTimecards.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      isLoading: false,
      parent: NAV.CustomDailyTimecardListPage.parent,
      isInfoOpen: true,
      permission: true,
      listView: true,
      activityInProgressSection:true
    };
    this.getPayrollsOfDepartment = this.getPayrollsOfDepartment.bind(this);
    this.setWeekAndDate = this.setWeekAndDate.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleActivityInProgressSection = this.toggleActivityInProgressSection.bind(this)
  }

  componentWillMount() {
    const {
      location: { query },
    } = this.props;
    this.fetchData();
    const cookieKey = cookie.load("daily_timecard_info");
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.props.InfoDucks.getInformations({
      route: "timecards.timecard.daily_timecard.list",
    });
    this.props.PayrollDucks.getAllPayrolls({
      paginate: false,
      default: true,
      valid: true,
    });
    if (query.department_id) {
      this.loadData(query).then(() => this.setWeekAndDate(query));
    }
  }


  toggleActivityInProgressSection() {
    const {activityInProgressSection} = this.state  
    this.setState({ activityInProgressSection: !activityInProgressSection });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = nextContext;
    const {
      location: { query: prevQuery },
      profile,
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;

    if (prevQuery.department_id !== nextQuery.department_id) {
      if (nextQuery.department_id) {
        this.getPayrollsOfDepartment(nextQuery);
      } else if (this.props.payrollAll.length && profile.company.is_default) {
        this.setQuery(nextQuery, this.props.payrollAll[0]);
      } else {
        this.setQuery(nextQuery, "custom");
      }
      this.setWeekAndDate(nextQuery);
    } else if (prevQuery !== nextQuery) {
      this.setWeekAndDate(nextQuery);
      this.fetchDailyTimecards({ ...nextQuery, ...params });
      this.setParentUrl(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    const {
      location: { pathname },
    } = this.props;
    const nav =
      pathname.indexOf("historic") > -1
        ? NAV.HistoricTimecardListPage
        : NAV.CustomDailyTimecardListPage;
    try {
      if (query.parent) {
        let parsedParams = JSON.parse(query.parent);
        const newParsedParams = {
          ...parsedParams,
          department_id: query.department_id,
          team_id: query.team_id,
          start_date: query.start_date,
          end_date: query.end_date,
          payroll_id: query.payroll_id,
        };
        this.setState({
          parent: `${nav.parent}?${queryString.stringify(newParsedParams)}`,
        });
      } else {
        this.setState({ parent: nav.parent });
      }
    } catch (e) {
      console.log(e);
    }
  }

  setQuery(query, payroll) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname },
    } = this.props;
    const monthStartDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    const monthEndDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");

    let startDate = monthStartDate;
    let endDate = monthEndDate;
    let payrollId;
    if (payroll && payroll.id) {
      startDate = moment(payroll.start_datetime).format("YYYY-MM-DD");
      endDate = moment(payroll.end_datetime).format("YYYY-MM-DD");
      payrollId = payroll.id;
    } else {
      payrollId = "custom";
    }
    cookie.save("payrollId", payrollId, { path: "/" });
    cookie.save("startDate", startDate, { path: "/" });
    cookie.save("endDate", endDate, { path: "/" });

    if (!("payroll_id" in query)) {
      return history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: payrollId,
          start_date: startDate,
          end_date: endDate,
        }),
      });
    }
    return true;
  }

  getPayrollsOfDepartment(query) {
    this.setState({ isLoading: true });
    return this.props.PayrollDucks.getPayrollsOfDepartment({
      valid: true,
      paginate: false,
      department_id: query.department_id,
    }).then(() => {
      const { departmentPayroll, payrollAll, profile } = this.props;
      if (departmentPayroll.length) {
        this.setQuery(query, departmentPayroll[0]);
      } else if (payrollAll.length && profile.company.is_default) {
        this.setQuery(query, payrollAll[0]);
      } else {
        this.setQuery(query, "custom");
      }
    });
  }

  loadData(query) {
    const {
      location: { pathname },
    } = this.props;
    const { history } = this.context;
    this.setState({ isLoading: true });
    return this.props.PayrollDucks.getPayrollsOfDepartment({
      valid: true,
      paginate: false,
      department_id: query.department_id,
    }).then(() => {
      const payrollId = cookie.load("payrollId");
      const startDate = cookie.load("startDate");
      const endDate = cookie.load("endDate");
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          start_date: startDate,
          end_date: endDate,
          payroll_id: payrollId,
        }),
      });
    });
  }

  setWeekAndDate(query) {
    const { departmentAll, defaultPayrollSetting } = this.props;
    const startDate = query.start_date;
    const endDate = query.end_date;
    let weekDay = 0;
    if (query.emp_dept_id) {
      const dept = departmentAll.find((i) => i.id === +query.emp_dept_id);
      weekDay = dept && dept.payroll_setting_week_day;
    } else {
      weekDay = defaultPayrollSetting.week_day
        ? defaultPayrollSetting.week_day
        : 0;
    }
    this.props.DailyTimecardDucks.setWeekAndDate({
      week: weekDay,
      startDate,
      endDate,
    });
  }

  fetchData() {
    const {
      location: { pathname, query },
      userExtraData,
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
        history,
      },
    } = this.context;
    this.setState({ isLoading: true,activityInProgressSection:true });
    this.setParentUrl(query);
    Promise.all([
      this.props.BasicSettingDucks.getBasicSetting(),
      this.props.TimecardDucks.getUserFilters({
        preference_type: "user_preference_timecards",
      }),
      this.props.AdjustmentTypesDucks.getAdjustmentTypes({
        paginate: false,
        company_id: userExtraData.company.id,
        is_active: true,
      }),
    ])
      .then(() => {
        const { basicSettingDetail, userTimecardFilters } = this.props;
        if (!("timecard_decimal_view" in query)) {
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              timecard_decimal_view:
                userTimecardFilters && userTimecardFilters.data
                  ? userTimecardFilters.data.timecard_decimal_view == "true" ||
                    userTimecardFilters.data.timecard_decimal_view == true
                    ? true
                    : false
                  : basicSettingDetail.timecard_decimal_view,
            }),
          });
        } else {
          this.fetchDailyTimecards({ ...query, ...params });
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  fetchDailyTimecards(params) {

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : params?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : params?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));

    this.setState({ isLoading: true });
    Promise.all([
      this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({ ...params,
         department_ids: JSON.stringify(departmentIdsIntegers),
        team_ids: JSON.stringify(teamIdsIntegers), }),
      this.props.ActivityDucks.getInProgressActivity({
        employee_id: params?.employee_id,
        start_date: params?.start_date,
        end_date: params?.end_date,
      }),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("daily_timecard_info", !isInfoOpen);
  }

  toggleView(val) {
    const {
      router: { history },
    } = this.context;
    const {
      match: { params },
      location: { query },
    } = this.props;
    let url = "";
    if (val === "old") {
      url = `/timecard/custom/list/${params.id}/dailyV0`;
      cookie.save("listView", true);
    } else {
      url = `/timecard/custom/list/${params.id}/daily`;
      cookie.save("listView", false);
    }

    history.push({
      pathname: url,
      search: queryString.stringify({ ...query }),
    });
  }
  
  render() {
    const {
      infoList,
      location: { pathname, query },
      activityInProgressList,
    } = this.props;
    const { isLoading, parent, isInfoOpen } = this.state;
    const nav =
      pathname.indexOf("historic") > -1
        ? NAV.HistoricTimecardListPage
        : NAV.CustomDailyTimecardListPage;
    let title = nav.title;
    if (!isLoading) {
      title = `${nav.title} (${query.first_name} ${query.last_name})`;
    }
    const description = <div>
      {<span> <strong>Deparment: </strong>{query?.empployee_dept_name?query.empployee_dept_name:'-'}&emsp;</span>}
      {<span><strong>Team: </strong> {query?.employee_team_name?query.employee_team_name:'-'}&emsp;</span>}
      {<span><strong>Employee ID: </strong> {query?.employee_id_show?query.employee_id_show:'-'}&emsp;</span>}
      {<span><strong>Employee Title: </strong> {query?.employee_title?query.employee_title:'-'}</span>}
    </div>;

    return (
      <TwoColumnLayout
        navInfo={{
          ...nav,
          parent,
          title,
          description,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <DailyTimecardFilterSection
          setWeekAndDate={this.setWeekAndDate}
          toggleView={this.toggleView}
          active="old"
          toggleActivityInProgressSection={
            this.toggleActivityInProgressSection
          }
        />
        {activityInProgressList &&
        activityInProgressList.results.length &&
        !activityInProgressList.nosql_data &&
        !isLoading &&
        pathname.indexOf("historic") < 0 ? (
          <ActivityInProgressSection active="old" />
        ) : null}
        {activityInProgressList &&
        activityInProgressList.results.length &&
        activityInProgressList.nosql_data &&
        !isLoading &&
        this.state.activityInProgressSection ? (
          <ActivityInProgressSection
            active="old"
            toggleActivityInProgressSection={
              this.toggleActivityInProgressSection
            }
          />
        ) : null}
        <DailyTimecardListSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  location: RouteDucks.location(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  infoList: InfoDucks.infoList(state),
  payrollAll: PayrollDucks.payrollAll(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  activityInProgressList: ActivityDucks.activityInProgressList(state),
  userExtraData: UserDucks.userExtraData(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  defaultPayrollSetting: UserDucks.defaultPayrollSetting(state),
  profile: UserDucks.profile(state),
  userTimecardFilters: TimecardDucks.userTimecardFilters(state),
});

const mapActionsToProps = (dispatch) => ({
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  ActivityDucks: bindActionCreators(ActivityDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

CustomDailyTimecardListPageV2.propTypes = {
  location: PropTypes.object.isRequired,
  DailyTimecardDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
  payrollAll: PropTypes.array.isRequired,
};

CustomDailyTimecardListPageV2.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomDailyTimecardListPageV2);
