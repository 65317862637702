import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import FilterFormComponent from 'components/common/FilterForm';
import KioskEmployeesAuditFilterFormComponent from 'components/employees/list/KioskEmployeesAuditFilterForm' 

/**
 * KioskEmployeesAuditListPage -> KioskEmployeesAuditFilterSection
 *
 * Components:
 *    - {@link FilterFormComponent}
 *    - {@link KioskEmployeesAuditFilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    postActiveStatus
 */
class KioskEmployeesAuditFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }
  update(data) {
    const {location} = this.props;
    const {query, pathname} = location
    const {router:{history}} = this.context;
    const {page, ...rest} = query;
    
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...data }),
    });
  }
  render() {
    const { dateFormat, location } = this.props;
    const { query } = location;
    const formattedDates = {};

    if (query && query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query && query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    return (
      <Row className="filter">
        <Col md={7}>
          <KioskEmployeesAuditFilterFormComponent
            initialValues={{
              ...formattedDates,
            }}
            location={location}
            dateFormat={dateFormat}
            update={this.update}
              />
        </Col>
        <Col md={5} className="pl-0 mt-1">
          <section className="float-right">
            <FilterFormComponent
              location={location}
              placeholder="name"
              initialValues={query}
            />
          </section>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
})

const mapActionsToProps = dispatch => ({});

KioskEmployeesAuditFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
}
KioskEmployeesAuditFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KioskEmployeesAuditFilterSection);
