import React from "react";
import { Collapse } from "reactstrap";
import moment from "moment";
import StorageGateway from 'lib/storage-gateway';
import PropTypes from "prop-types";
import queryString from "query-string";
import { Popover } from "reactstrap";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import JobFilterFormComponent from "components/common/JobFilterForm";
import SortHeaderComponent from './QuotedRecurringSortHeaderComponent';
import AttributeToggler from "employees/components/AttributeToggler";
import ManageJobAdvancedFilterForm from './ManageJobAdvancedFilters';
import { UncontrolledTooltip, Tooltip } from 'reactstrap';
import ButtonLoaderAtom from "atoms/ButtonLoader";
import LoaderAtom from "atoms/Loader";
import {ReactComponent as SortIcon} from './sort.svg';
import Button from "reactstrap/lib/Button";
import ReactTable from "react-table";
import { FILTER_OPTIONS , ADVANCE_GROUP_BY_OPTIONS} from "constants/jobs";
import caretArrowUp from "./caret-arrow-up.png";
import reload from "./refresh-page-option.png";
import showHideIcon from './showHideIcon.png'
import cookie from "react-cookies";

import styles from "./styles.module.scss";

/**

 * JobListPage -> JobListSection -> CustomerListTableComponent
 *
 * Props:
 *    - employee list
 */

class ManageQuotedRecurringJobs extends React.Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.refresh = this.refresh.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
    this.setData = this.setData.bind(this);
    this.search = this.search.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onStatusSelection = this.onStatusSelection.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.saveOrdering = this.saveOrdering.bind(this);
    this.getResized = this.getResized.bind(this);
    this.popToggle = this.popToggle.bind(this);
    this.isToolTipOpen = this.isToolTipOpen.bind(this);
    this.getEmployeeList = this.getEmployeeList.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.applyAdvancedFilters = this.applyAdvancedFilters.bind(this);
    this.getTagsList = this.getTagsList.bind(this);
    this.prevJobId = null;
    this.prevTaskId = null;
    this.prevEmployeeId = null;
    this.resultemployee = [];
    this.resultTask = [];
    this.advancedFilters = null;
    this.accessorToHeader = {
      customer_name: "Customer",
      code : 'Job Code',
      name: "Job",
      start_date: "Start Date",
      end_date: "End Date",
      employee: "Employees",
      task_count: "Task",
      tags_count: "Tags Count",
      status : "Status"
    };
    this.widthToHeader = {
      customer__customer_name: 140,
      code : 140,
      name: 140,
      start_date: 140,
      end_date: 140,
      employee_count: 140,
      task_count: 140,
      tags_count: 140,
      status: 140,
    };
    this.state = {
      loading: false,
      currentPage: 0,
      tableView: null,
      currentPageSize: 10,
      pageChange: false,
      pageSize: null,
      PopOverOpen: false,
      selectedItem: null,
      dropdownOpen: false,
      valueStatus: { value: "", label: "Open" },
      selectedId: null,
      searchLoader: true,
      quotedRecurring: false,
      attributeToggler: false,
      showableAttributes: cookie.load('showableAttributesRecurringNew') || {},
      userViewPreferenceLoading: false,
      isEmployeeLoading: false,
      isTaskLoading: false,
      associatedEmployees : {},
      associatedTasks : {},
      associatedTags : {},
    };
  }

  componentWillMount() {
    const { router: { history } } = this.context;
    const { location: { query , pathname }, userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const widthChange = cookie.load(`recurringTableWidthNew${uniqueUuid}${userProfile.company.id}`);
    const advancedFlters =  StorageGateway.get(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    this.advancedFilters = advancedFlters ? JSON.parse(advancedFlters) : {}
    if((this.advancedFilters && !this.advancedFilters.quotedRecurringFiltersCookieNewstatus)){
      this.advancedFilters['quotedRecurringFiltersCookieNewstatus'] = {value : "1",label : "Open"}
    }

    StorageGateway.set(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`, JSON.stringify(this.advancedFilters));

    if(widthChange){
      this.widthToHeader=widthChange;
    }
    this.props.getNewUserPreference({preference_type : 'user_preference_manage_recurring'})
    .then(() => {
      const {newUserViewPreferenceQuotedRecurring } = this.props;
      let obj =  {};
      let show_fields = newUserViewPreferenceQuotedRecurring.show_fields;
      Object.keys(this.accessorToHeader).forEach(v => {
        if(show_fields.indexOf(v)!== -1) {
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
      this.setAttributes(obj);
      cookie.save("pageSizeRecurringNew", newUserViewPreferenceQuotedRecurring.page_size || 10, { path: '/'});
      cookie.save("recurringSortingNew", newUserViewPreferenceQuotedRecurring.default_ordering || null, { path: '/'});
      const pageSizeCookie = cookie.load("pageSizeRecurringNew");
      if (pageSizeCookie) {
        this.setState({ currentPageSize: pageSizeCookie });
      }
      else {
        this.setState({ currentPageSize: 10 })
      }
    })
    .catch(() => {
      let attributes = {};
      for (const acc in this.accessorToHeader) {
          attributes[acc] = true;
      }
      this.setAttributes(attributes);
    })

    this.props.openAccordian();
  }

  componentWillUnmount(){
    cookie.save('RecurringpageNew', 1, { path: '/'});
  }


  componentWillReceiveProps(nextProps) {
    const {
      location: { query }
    } = this.props;
    const {
      location: { search }
    } = nextProps;
    const RecurringpageCookie=cookie.load("RecurringpageNew");
    const { currentPage } = this.state;
    const nextQuery = queryString.parse(search);
    if (query.recurring_search !== nextQuery.recurring_search) {
      this.setState({ currentPage: 0 });
    }
    if(query.recurring_sorting !== nextQuery.recurring_sorting && nextQuery.recurring_sorting && this.state.showableAttributes.name){
      this.saveOrdering(nextQuery);
    }
     if( JSON.stringify(nextProps.quotedRecurringList) !== JSON.stringify(this.props.quotedRecurringList)){
      let temp = {...this.state.associatedEmployees};
      nextProps.quotedRecurringList.forEach((item, index)=> {
        temp[`recurring${index}`] = 'Loading...';
      });
      this.setState({associatedEmployees : temp});
    }
    if( JSON.stringify(nextProps.quotedRecurringList) !== JSON.stringify(this.props.quotedRecurringList)){
      let temp = {...this.state.associatedTasks};
      nextProps.quotedRecurringList.forEach((item, index)=> {
        temp[`recurring${index}`] = 'Loading...';
      });
      this.setState({associatedTasks : temp});
    }
    if(JSON.stringify(nextProps.quotedRecurringList) !== JSON.stringify(this.props.quotedRecurringList)){
      let temp = {...this.state.associatedTags};
      nextProps.quotedRecurringList.forEach((item, index)=> {
        temp[`recurring${index}`] = 'Loading...';
      });
      this.setState({associatedTags : temp});
    }
  }


  toggleTable(key, tableData){
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const advancedFlters =  StorageGateway.get(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    this.advancedFilters = advancedFlters ? JSON.parse(advancedFlters) : {}
    if((this.advancedFilters && !this.advancedFilters.quotedRecurringFiltersCookieNewstatus)){
      this.advancedFilters['quotedRecurringFiltersCookieNewstatus'] = {value : "1",label : "Open"}
    }

    StorageGateway.set(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`, JSON.stringify(this.advancedFilters));
    this.props.toggleAccordian(key, tableData);
  }

  saveOrdering(nextQuery) {
    const showFields = Object.keys(this.state.showableAttributes).filter((item) => {
      if(this.state.showableAttributes[item] === true) 
        return item
      else if(this.state.showableAttributes[item] === false && item === "name")
        return true
      else
        return false
    })
    const pageSize = cookie.load("pageSizeRecurringNew") || 10;
    //const pageSize = 10;
    const preferenceObj = {
      show_fields: showFields,
      page_size: pageSize,
      bill_type: 'QUOTED',
      job_type: 'RECURRING',
      default_ordering : nextQuery.recurring_sorting
    }
    this.props.setUserPreference({...preferenceObj, preference_type : 'user_preference_manage_recurring'})
    cookie.save("recurringSortingNew", nextQuery.recurring_sorting, { path: '/'});
  }

  applyAdvancedFilters(params){
    const { getJobList } = this.props;
    cookie.save('RecurringpageNew', 1, { path: '/'});
    this.setState({ currentPage: 0 });
    return getJobList(this.props , this.state.tableData, 'recurring_search' , params);
  }

  popToggle(targetName){
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen
        }
      });
    }
  };

  isToolTipOpen(targetName) {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  getEmployeeList(jobId , index){
    let results = [];
    const { associatedEmployees } = this.state;
    if(this.prevEmployeeId !== `recurring${index}` && associatedEmployees[`recurring${index}`] == 'Loading...'){
      this.props.setEmployee({job_id: jobId, assigned: true})
      .then((res)=>{
          let temp = {...this.state.associatedEmployees };
          res.value.forEach((item, index)=>
          results.push(item.user.full_name));
          temp[`recurring${index}`] = results;
          this.setState({associatedEmployees : temp});
          this.prevEmployeeId = `recurring${index}`;
    });}
  }

  getTaskList(jobId , index){
    let results = [];
    const { associatedTasks } = this.state;
    if(this.prevTaskId !== `recurring${index}` && associatedTasks[`recurring${index}`] == 'Loading...'){
      this.props.setTask({job_id: jobId, assigned: true})
      .then((res)=>{
        let temp = {...this.state.associatedTasks };
         res.value.forEach((item, index)=>
          results.push(item.name)
        ) 
        temp[`recurring${index}`] = results;
        this.setState({associatedTasks : temp});
        this.prevTaskId = `recurring${index}`;      
      })
    }
  }

  getTagsList(jobId, index){
    let results = [];
    const { associatedTags } = this.state;
    if(this.prevJobId !== `recurring${index}` && associatedTags[`recurring${index}`] && associatedTags[`recurring${index}`] == 'Loading...'){
      this.props.setTags({job_id: jobId, assigned: true})
      .then((res)=>{
          let temp = {...this.state.associatedTags };
          res && res.value && res.value.tags && res.value.tags.length && res.value.tags.forEach((item, index)=>
            results.push(item)
          );
          temp[`recurring${index}`] = results;
          this.setState({associatedTags : temp});
          this.prevJobId = `recurring${index}`;
      });
    }
  }

  search() {
    const { quotedRecurringSearch } = this.state;
    this.setState({ quotedRecurringSearch: !quotedRecurringSearch });
  }

  dropdownToggle() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  toggle() {
    this.setState({ currentPage: 0, currentPageSize: 10 });
  }

  togglePopOver(id, item) {
    const { PopOverOpen } = this.state;
    this.setState({ PopOverOpen: !PopOverOpen, selectedItem: item });
  }

  onStatusSelection(val, id) {
    if (val) {
      this.setState({ valueStatus: val, selectedId: id });
    } else {
      this.setState({ valueStatus: "" });
    }
  }

  setData(params,total) {
    const {
      router: { history }
    } = this.context;
    const {
      location: { query },
      quotedRecurringList , userProfile 
    } = this.props;
    if(params.group_by == 'task'){
      history.push(`/job/list/${params.data.id}/task/edit/?admin_list=true`);
    }
    else{
       history.push(`/job/list/${params.data.id}/employee/edit/?admin_list=true`);
    }
  }

  refresh(key) {
    const cookieKey = cookie.load("tableViewNew");
    if (cookieKey.quotedRecurring === true) {
      const { getJobList } = this.props;
      getJobList(this.props.tableData, this.props, 'recurring_search');
    }
  }

  searchFilter(key) {
    const {
      router: { history }
    } = this.context;
    const {
      location: { pathname }
    } = this.props;
    const cookieKey = cookie.load("tableViewNew");
    if (cookieKey.quotedRecurring === true) {
      history.push({
        pathname,
        search: queryString.stringify({ billType: "QUOTED", JobType: "RECURRING" })
      });
    }
  }

  pageChange(item) {
    cookie.save('RecurringpageNew', (+item) + 1, { path: '/'});
    this.setState({ currentPage: item });
  }

  pageSizeChange(pageSize, pageIndex) {
    cookie.save("pageSizeRecurringNew", pageSize, { path: '/'});
    cookie.save('RecurringpageNew', pageIndex + 1, { path: '/'});
    this.setState({ currentPageSize: pageSize, currentPage: pageIndex });
    this.setColumns('submit');
  }

  updateState(key, value) {
    this.setState({
      [key]: value
    });
  }

  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    if(bool) {
      this.setState({ userViewPreferenceLoading: true})
      this.props.getNewUserPreference({preference_type : 'user_preference_manage_recurring'})
      .then(() => {
        const {newUserViewPreferenceQuotedRecurring } = this.props;
        let obj =  {};
        let show_fields = newUserViewPreferenceQuotedRecurring.show_fields;
        Object.keys(this.accessorToHeader).forEach(v => {
          if(show_fields.indexOf(v)!== -1) {
            obj[v] = true;
          }
          else {
            obj[v] = false;
          }
        }
        )
        this.setAttributes(obj);
        //cookie.save("timecard_page_size", newUserViewPreferenceQuotedRecurring.page_size);
        this.setState({ userViewPreferenceLoading: false});
      })
      .catch((err) => {
        this.setState({ userViewPreferenceLoading: false});
        let attributes = {};
        for (const acc in this.accessorToHeader) {
            attributes[acc] = true;
        }
        this.setAttributes(attributes);
      })
    }else{
      const {newUserViewPreferenceQuotedRecurring} = this.props;
      let obj = {}
      let show_fields = newUserViewPreferenceQuotedRecurring.show_fields;
      Object.keys(this.accessorToHeader).forEach(v => {
        if(show_fields.indexOf(v)!== -1){
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
      this.setAttributes(obj);
    }
  }
  async setAttributes(attributes) {
    await this.setState({ showableAttributes: attributes });
    cookie.save("showableAttributesRecurringNew", attributes, { path: '/'})
  }

  setColumns(submit) {
    const showFields = Object.keys(this.state.showableAttributes).filter((item) => {
      if(this.state.showableAttributes[item] === true) 
        return item
      else if(this.state.showableAttributes[item] === false && item === "name")
        return true
      else
        return false
    })
    const pageSize = cookie.load("pageSizeRecurringNew") || 10;
    //const pageSize = 10;
    const preferenceObj = {
      show_fields: showFields,
      page_size: pageSize,
      bill_type: 'QUOTED',
      job_type: 'RECURRING',
    }
    this.props.setUserPreference({...preferenceObj, preference_type : 'user_preference_manage_recurring'})
    .then(() => {
      if(!submit) this.setState({attributeToggler: !this.state.attributeToggler})
    })
  }

  getResized(newSize){
    const {userProfile} = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    newSize.map((item,index)=>{
      this.widthToHeader[item.id]=item.value;
    })
    cookie.save(`recurringTableWidthNew${uniqueUuid}${userProfile.company.id}`,this.widthToHeader, { path: '/'});
  }


  render() {
    const {
      location,      
      goTo,  
      pageQuotedRecurring,
      saveStatus,
      isStatusLoading,
      toggle,     
      originalCount,      
      exportCSV,
      exportQuotedRecurring,
      isLoading,
      toggleAccordian,
      quotedRecurring,
      quotedRecurringList,
      getJob,
      refresh,     
      PopOverOpen,
      selectedItem,
    } = this.props;
    const { query } = location;
    const {
      currentPage,
      currentPageSize, 
      dropdownOpen,
      selectedId,
      valueStatus,
    } = this.state;
    let updatedJobList = quotedRecurringList.map(item => {
      const s = item.status && FILTER_OPTIONS.filter(i => i.value === item.status)[0].label;
      return { ...item, status: s };
    });
    updatedJobList = updatedJobList.filter(item => item.status || item.total)  
    return (
      <div>
        <div className={quotedRecurring? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
          <div 
            className={styles['card-header__label--quoted']} 
            onClick={() => this.toggleTable("quotedRecurring", this.props.tableData)}>
              <p className={styles['card-header__p']} style={{marginBottom: '1rem'}}>Quoted Recurring</p>
              <img 
                alt=""
                src={caretArrowUp} 
                className={
                  quotedRecurring? styles['card-header__label--quoted--down']: styles['card-header__label--quoted--up']} 
                  height="10" 
              />
          </div>
          {
            quotedRecurring &&
            <div className={styles['card-header__search-bar']}>
                <JobFilterFormComponent 
                  location={location} 
                  id="recurring_search"
                  placeholder="Job Name and Job Code" 
                  initialValues={{...query}}
                  recurringSearchCookieKey="recurring_search_new"
                />
            </div>
          }
          { 
            quotedRecurring &&
            <Button
              color="primary"
              onClick={() => this.setAttributeToggler(!this.state.attributeToggler)}
              className={styles['show-hide-button-custom']}
              >
              <div id="show_hide">
                <img
                  className={styles['button-icon']}
                  src={showHideIcon}
                  alt="Icon"
                />
                <UncontrolledTooltip placement="top" target="show_hide">
                  Show/Hide
                </UncontrolledTooltip>
              </div>
            </Button>
          }
          <div className={styles['card-header__btn']}>
            { quotedRecurring &&
              <Button
                color="primary"
                id="add_job"
                onClick={() => exportCSV(this.props.tableData, "quotedRecurring", this.state.showableAttributes)}
                disabled={updatedJobList && !updatedJobList.length}
              >
                Export <ButtonLoaderAtom active={exportQuotedRecurring} />
              </Button>
            }
            { quotedRecurring &&
              <Button
                color="primary"
                className="align-top ml-2"
                onClick={() => this.goTo({
                  pathname: '/job/list/bulk-upload',
                  search: queryString.stringify({
                    type: 'quotedRecurring',
                    admin_list: true
                  })
                })}
              >
                Bulk Upload
              </Button>
            }
          </div>
          {
            quotedRecurring && 
            <div className={styles['card-header__btn--refresh']}>
              <img src={reload} alt="" width="13" onClick={() => refresh("quotedRecurring")} />
            </div>
          }
          {this.state.attributeToggler &&
            <AttributeToggler
              setToggle={() => this.setAttributeToggler(!this.state.attributeToggler)}
              toggle={this.state.attributeToggler}
              data={{
                labels: Object.values(this.accessorToHeader),
                accessors: Object.keys(this.accessorToHeader),
                defaultVals: Object.values(this.state.showableAttributes)
              }}
              onChange={this.setAttributes}
              mandatoryField = 'name'
              setColumns={this.setColumns}
              isLoading={this.state.userViewPreferenceLoading}
            />
          }
        </div>
        {quotedRecurring && (!isLoading) &&
          <div style={{border:'none', paddingLeft: '1.4rem', position: 'relative', marginTop:'-10px'}} className={quotedRecurring? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
            <p>Total : {quotedRecurring ? this.props.totalRecords : originalCount} Records</p>
          </div>
        }
        {quotedRecurring &&
          <div style={{border:'none', paddingLeft: '1.1rem', paddingBottom: '0.8rem', position: 'relative'}} className={quotedRecurring? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
            <ManageJobAdvancedFilterForm
              getCustomers={this.props.getCustomers}
              customerList={this.props.customerList}
              getEmployees={this.props.getEmployees}
              employeeList={this.props.employeeList}
              getJobs = {this.props.getJobs}
              jobsAndCodesList={this.props.jobsAndCodesList}
              getJobTags={this.props.getJobTags}
              form="form1"
              page={this.state.currentPage}
              jobTagList={this.props.jobTagList}
              getTasks={this.props.getTasks}
              TasksAndCodesList={this.props.TasksAndCodesList}
              getTaskTags={this.props.getTaskTags}
              taskTagList={this.props.taskTagList}
              applyAdvancedFilters = {this.applyAdvancedFilters}
              initialValues = {this.advancedFilters}
              advancedFilterCookie = {'quotedRecurringFiltersCookieNew'}
              dateFormat= {this.props.dateFormat}
              sortJobCodes={this.props.sortJobCodes}
              dateFormatDisplay={this.props.dateFormatDisplay}
              getEmployeeTags= {this.props.getEmployeeTags}
              employeeTagsList={this.props.employeeTagsList}
              userProfile={this.props.userProfile}
              location={location}
              showableAttributes={this.state.showableAttributes}
            />
          </div>
        }
        <Collapse isOpen={quotedRecurring}>
          <div className={styles["loader-z-index"]} />
          <ReactTable
            data={updatedJobList.length ? updatedJobList : []}
            noDataText="No Records Found"
            onFetchData={(item)=>getJob("quotedRecurring",item)}
            manual
            pageSizeOptions= {[5, 10, 20, 25, 50, 100, 500, 1000]}
            loading={isLoading}
            minRows={1}
            page={this.state.currentPage}
            pages={pageQuotedRecurring}
            pageSize={currentPageSize}
            onPageSizeChange={this.pageSizeChange}
            onPageChange={this.pageChange}
            className="text-center mb-3"
            style={{ background: "#FFF" ,maxHeight:'750px' }}
            onResizedChange={(newResized, event) => {
              this.getResized(newResized);
            }}
            columns={[
              {
                Header: row => {return (
                    <SortHeaderComponent recurring_sorting="customer__customer_name">CUSTOMER</SortHeaderComponent>
                    )},
                accessor: "customer__customer_name",
                sortable: false,
                width: this.widthToHeader.customer__customer_name,
                id: 'customer__customer_name',
                show: this.state.showableAttributes.customer_name,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div
                    title={row.original.customer_data.customer_name}
                    className="text-ellipsis text-left pl-3"
                  >
                    <b role="button" onClick={() => goTo(`/customers/list/${row.original.customer_data.id}/details`)}>{ row.original.customer_data.customer_name }</b>
                  </div>)
                }
              },
              {
                Header: row => {return (
                  <SortHeaderComponent recurring_sorting="code">JOB CODE</SortHeaderComponent>
                )},
                accessor: "code",
                id: 'code',
                width: this.widthToHeader.code,
                sortable: false,
                show: this.state.showableAttributes.code,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div className='text-ellipsis text-left pl-3'>
                    <div>{ row.original.code }</div>
                  </div>)
                }
              },
              {
                Header:row => {return (
                    <SortHeaderComponent recurring_sorting="name">JOB</SortHeaderComponent>
                    )},
                accessor: "name",
                id:'name',
                sortable: false,
                width: this.widthToHeader.name,
                show: this.state.showableAttributes.name,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div
                    title={row.original.name}
                    className="text-ellipsis text-left pl-3">
                    <b role="button"
                    onClick={() => goTo(`/job/list/${row.original.id}/edit`)}>{ row.original.name }</b>
                  </div>)
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent recurring_sorting="start_date">START DATE</SortHeaderComponent>
                  )},
                accessor: "start_date",
                id:'start_date',
                sortable: false,
                width: this.widthToHeader.start_date,
                show: this.state.showableAttributes.start_date,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return <div className="text-left pl-3">{ row.original.start_date_display }</div>
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent recurring_sorting="end_date">END DATE</SortHeaderComponent>
                  )},
                accessor: "end_date",
                id:'end_date',
                sortable: false,
                width: this.widthToHeader.end_date,
                show: this.state.showableAttributes.end_date,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return <div  className="text-left pl-3">{ row.original.end_date_display }</div>
                }
              },
              {
                Header: "EMPLOYEES",
                accessor: "employee_count",
                id:'employee_count',
                sortable: false,
                width: this.widthToHeader.employee_count,
                show: this.state.showableAttributes.employee,
                className:'employee-div',
                headerClassName: 'text-right pr-4',
                Cell: row => {
                  return (
                    <div key={`emprecurring${row.index}`} className={`${styles['employee-div']} pr-4`}>
                      <div
                        tabIndex="0"
                        id={`emprecurring${row.index}`}
                        onMouseOver={()=>this.getEmployeeList(row.original.id, row.index)}
                        className={`hover-employee-tooltip-div link-name d-inline-block`}
                        style={{width:'20%', cursor:'default'}}
                      >
                        <b role="button" onClick={() => this.setData({ data: row.original })}>{row.original.employee_count}</b>
                        <Tooltip
                          placement="left"
                          isOpen={this.isToolTipOpen(`emprecurring${row.index}`)}
                          autohide={false} 
                          hideArrow={false}
                          target={`emprecurring${row.index}`}
                          toggle={() => this.popToggle(`emprecurring${row.index}`)}
                          className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                        >
                        {
                          this.state.associatedEmployees &&  this.state.associatedEmployees[`recurring${row.index}`] && this.state.associatedEmployees[`recurring${row.index}`] !== 'Loading...' ?
                           this.state.associatedEmployees && this.state.associatedEmployees[`recurring${row.index}`] && this.state.associatedEmployees[`recurring${row.index}`].length ? this.state.associatedEmployees[`recurring${row.index}`].map((item,index)=>
                              <li>{item}</li>
                              )
                            :
                              <li>No records</li>
                           :
                           <li>{this.state.associatedEmployees && this.state.associatedEmployees[`recurring${row.index}`] ? this.state.associatedEmployees[`recurring${row.index}`] : null}</li>
                        }
                        </Tooltip>
                      </div>
                    </div>
                  );
                }
              },
              {
                Header: row => {return (
                   <SortHeaderComponent recurring_sorting="task_count">TASKS</SortHeaderComponent>
                  )},
                accessor: "task_count",
                id:"task_count",
                sortable: false,
                width: this.widthToHeader.task_count,
                show: this.state.showableAttributes.task_count,
                className:'employee-div',
                headerClassName: 'text-right pr-4',
                Cell: row => {
                  return (  
                  <div key={`taskrecurring${row.index}`} className={`${styles['employee-div']} pr-4`}>
                      <div
                        tabIndex="0"
                        id={`taskrecurring${row.index}`}
                        onMouseOver={()=>this.getTaskList(row.original.id, row.index)}
                        className={`hover-employee-tooltip-div link-name d-inline-block`}
                        style={{width:' 20%', cursor:'default'}}
                      >
                        <b role="button" onClick={() => this.setData({ data: row.original, group_by: "task" })}>{row.original.task_count}</b>
                        <Tooltip
                          placement="left"
                          isOpen={this.isToolTipOpen(`taskrecurring${row.index}`)}
                          autohide={false} 
                          hideArrow={false}
                          target={`taskrecurring${row.index}`}
                          toggle={() => this.popToggle(`taskrecurring${row.index}`)}
                          className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                        >
                          {
                           this.state.associatedTasks && this.state.associatedTasks[`recurring${row.index}`] && this.state.associatedTasks[`recurring${row.index}`] !== 'Loading...' ?
                            this.state.associatedTasks[`recurring${row.index}`] && this.state.associatedTasks[`recurring${row.index}`].length ? this.state.associatedTasks[`recurring${row.index}`].map((item,index)=>
                              <li>{item}</li>
                              )
                            :
                              <li>No records</li>
                           :
                           <li>{this.state.associatedTasks && this.state.associatedTasks[`recurring${row.index}`] ? this.state.associatedTasks[`recurring${row.index}`] : null}</li>
                        }
                        </Tooltip>
                      </div>
                    </div>
                  );
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent recurring_sorting="tags_count">TAGS COUNT</SortHeaderComponent>
                  )},
                accessor: "tags_count",
                id:"tags_count",
                sortable: false,
                width: this.widthToHeader.tags_count,
                show: this.state.showableAttributes.tags_count,
                headerClassName: 'text-right pr-4',
                Cell: row => {
                  return (  
                    <div key={`tagsrecurring${row.index}`} className={`${styles['employee-div']} pr-4`}>
                      <div
                        tabIndex="0"
                        id={`tagsrecurring${row.index}`}
                        onMouseOver={()=>this.getTagsList(row.original.id,row.index)}
                        className={`hover-employee-tooltip-div link-name d-inline-block`}
                        style={{width:'20%', cursor:'default'}}
                      >
                        <b role="button" onClick={() => goTo(`/job/list/${row.original.id}/edit`)}>{row.original.tags_count}</b>
                        <Tooltip
                          placement="left"
                          isOpen={this.isToolTipOpen(`tagsrecurring${row.index}`)}
                          autohide={false} 
                          hideArrow={false}
                          target={`tagsrecurring${row.index}`}
                          toggle={() => this.popToggle(`tagsrecurring${row.index}`)}
                          className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                        >
                          {
                          this.state.associatedTags && this.state.associatedTags[`recurring${row.index}`] && this.state.associatedTags[`recurring${row.index}`] !== 'Loading...' ?
                          this.state.associatedTags&&  this.state.associatedTags[`recurring${row.index}`] && this.state.associatedTags[`recurring${row.index}`].length ? this.state.associatedTags[`recurring${row.index}`].map((item,index)=>
                              <li>{item}</li>
                              )
                            :
                              <li>No records</li>
                           :
                           <li>{this.state.associatedTags && this.state.associatedTags[`recurring${row.index}`] ? this.state.associatedTags[`recurring${row.index}`] : null}</li>
                          }
                        </Tooltip>
                      </div>
                    </div>
                  );
                }
              },
              {
                Header: "STATUS",
                accessor: "status",
                id:'status',
                width: this.widthToHeader.status,
                sortable: false,
                show: this.state.showableAttributes.status,
                Cell: row => {
                  return(
                    <div>
                      <div id={`a${row.original.id}`} className="d-inline-block tooltip-override-div">
                        <span
                          role="button"
                          tabIndex={0}
                          className="link-name"
                          onClick={() => this.props.togglePopOver("quotedRecurring", row.original.id, row.original)}
                        >
                          {row.original.status}
                        </span>
                      </div>
                    </div>
                );}
              }
            ]}
          />
        </Collapse>
      </div>
    );
  }
}

ManageQuotedRecurringJobs.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  JobDucks: PropTypes.object.isRequired
};

ManageQuotedRecurringJobs.contextTypes = {
  router: PropTypes.object.isRequired
};

export default ManageQuotedRecurringJobs;
