import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const PUT_JOB_EMPLOYEE_MAPPING = 'jobs/jobEmployeeMapping/PUT_JOB_EMPLOYEE_MAPPING';
const PUT_JOB_EMPLOYEE_MAPPING_FULFILLED = 'jobs/jobEmployeeMapping/PUT_JOB_EMPLOYEE_MAPPING_FULFILLED';
const PUT_ALL_EMPLOYEE_JOB_MAPPING = 'jobs/jobEmployeeMapping/PUT_ALL_EMPLOYEE_JOB_MAPPING';


export function putJobEmployeeMapping(data) {
  return {
    type: PUT_JOB_EMPLOYEE_MAPPING,
    payload: axios.put(`${URLS.JOB}${data.id}/${URLS.EMPLOYEE_ASSIGN}`, data),
  };
}

export function putAllEmployeeToJobMapping(params) {
  return {
    type: PUT_ALL_EMPLOYEE_JOB_MAPPING,
    payload: axios.put(`${URLS.JOB}${URLS.EMPLOYEE_ASSIGN}0/`, params),
  };
}

const defaultState = {
  detail: {},
};

function PUT_JOB_EMPLOYEE_MAPPING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [PUT_JOB_EMPLOYEE_MAPPING_FULFILLED]: PUT_JOB_EMPLOYEE_MAPPING_FULFILLED_REDUCER,
};


const jobEmployeeMappingSelector = state => state.jobs.jobEmployeeMapping;

export const jobEmployeeMappingDetail = createSelector(
  jobEmployeeMappingSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
