import React from 'react';
import { Button, Card, Row, Col } from 'reactstrap';
import StorageGateway from 'lib/storage-gateway';
import styles from './styles.module.scss';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import moment from 'moment-timezone';
import name from './name.png';

const AccountCancellationComponent = (props) => {
  const { usecase, isLoggedInAdmin, isSubCompany, openModal , logoutLoader, logOutOfAccount, billList, customerDetail, userExtraData} = props;
  const companyId = StorageGateway.get('company_id');
  let totalAmount=0;
  billList && billList.results && billList.results.map((i) => {
    totalAmount += i.total_amount;
    return i;
  });
  if(moment(customerDetail.trial_end) >= moment() || customerDetail.commercial_status === 'Trial period')
  {
    totalAmount =0;
  }
  return (
    <Row className={styles['line-height']}>
      <Col md={3}/>
      <Col md={6}>
        <div className="text-center pb-4 pt-5">
          <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
          <img src={name} height="26px" width="150px" alt="Logo"/>
        </div>  
        <Card className="mt-2 p-3">             
          <div>The account is cancellation in progress. You account will be closed as soon as it is approved by Worksana. </div>
          <div>The system has automatically stopped all the services associated with this account. </div>
          <div>Your outstanding balance is ${totalAmount}. Request you to pay the outstanding amount as soon as possible, if any.</div>
          <br />
          <b>If you wish to restore your account and any attached services, please contact us.  Please be aware that all your Worksana admin panel services will remain inaccessible.</b>
          <br />
          <b>If you believe cancellation in progress has been made in an error, please contact us immediately.</b>
          <Row className="text-right">
            <Col md={4}>
              {companyId &&
                <Button
                  className="btn-backdashboard float-left mt-4"
                  color="primary"
                  onClick={() => usecase.backToMyDashboard(isLoggedInAdmin, isSubCompany)}
                >
                  <u>Back To {isLoggedInAdmin && isSubCompany ? 'Vendor' : 'My'} Dashboard</u>
                </Button>
              }
            </Col>
            <Col md={8}>
            {!userExtraData.is_logged_in_admin && 
              <Button
                className={`${styles['accounts-button']} mt-3 d-inline-block mr-2 mb-2`}
                onClick={() => openModal()}
              >
                  Contact Support
              </Button>
            }
              <Button
                className={`${styles['accounts-button']} mt-3 d-inline-block mr-2 mb-2 float-right`}
                type="submit"
                isColor='secondary'
                onClick={() => logOutOfAccount()}
              >
                Logout <ButtonLoaderAtom active={logoutLoader} />
              </Button>
              </Col>
            </Row>
        </Card>
      </Col>
      <Col md={3} />
    </Row>
  );
}

export default AccountCancellationComponent;
