const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
];

export const NAV = {
  ReportDashboardPage: {
    breadcrumb: [
      ...baseNav,
      { label: 'Reports' },
    ],
    description: 'View & manage reports',
    parent: '/dashboard',
    title: 'Reports',
  },
  ReportDetailPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/report', label: 'Reports' },
    ],
    parent: '/report',
    // Dynamic breadcrumb & title
  },
};

export const REPORT_KEY_MAPPINGS = {
  'MANUAL-CORRECTION-SUMMARY': 'Correction Request - Aggregate',
  'MANUAL-CORRECTION-DETAIL': 'Correction Detail',
  'EMPLOYEE-ACTIVITY-DETAILS-BY-DATE': 'Employee Activity Detail By Date',
  'MEAL-VIOLATION': 'Meal Flags',
  'MEAL-VIOLATION-DETAIL': 'Meal Flags Detail',
  'OVERTIME-SUMMARY': 'Overtime Summary',
  'OVERTIME-DETAIL': 'Overtime Detail',
  'TIMECARD-DETAIL': 'Daily Timecard History',
  'JOB-SUMMARY': 'Job Summary',
  'JOB-DETAIL': 'Employee Activity Detail By Job',
  'EMPLOYEE-WISE-DAILY-TIMECARDS': 'Employee Daily Timecards',
  'GEO-FENCE-BREACH-SUMMARY': 'Geofence Breach Summary',
  'GEO-FENCE-BREACH-DETAIL': 'Geofence Breach Detail',
  'JOB-TASK-TIME-ALLOCATION': 'Job Task Time Allocation',
  'MEAL-FLAG-DETAIL': 'Meal Flag Details by Employees',
  'CORRECTION-REQUEST-REPORT' : 'Correction Request Report',
  'DETAILS-CORRECTION-REQUEST-REPORT' : 'Details Correction Request Report',
  'GENERAL-AUDIT-REPORT' : 'General Audit Report',
  'EMPLOYEE-PROFICIENCY-REPORT' : 'Employees Proficiency Report'
};


export default { NAV, REPORT_KEY_MAPPINGS};
