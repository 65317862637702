import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Route from 'react-router-dom/Route';
import bindActionCreators from 'redux/lib/bindActionCreators';
import cookie from 'react-cookies';

import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

class MatchAlways extends React.Component {
  componentWillMount() {
    const { profile } = this.props;

    if (!Object.keys(profile).length) {
      cookie.remove('lastActivityTime', { path: '/' });
      Promise.all([
        this.props.UserDucks.getProfileUser(),
        this.props.UserDucks.getAccessUser(),
        this.props.UserDucks.getExtraData(),
        this.props.BasicSettingDucks.getBasicSetting(),
      ]);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={
          renderProps => <Component {...renderProps} />
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  profile: UserDucks.profile(state),
});

const mapActionsToProps = dispatch => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

MatchAlways.propTypes = {
  component: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MatchAlways);
