import React from "react";
import Table from "reactstrap/lib/Table";
import Switch from "react-switch";
import { Tooltip } from 'reactstrap';

import queryString from "query-string";

import CopyIcon from "forms/assets/Copy.png";
import DeleteIcon from "forms/assets/Delete.png";
import PreviewIcon from "forms/assets/Preview.png";
import EditIcon from "forms/assets/edit.png";

import SectionLoaderAtom from "atoms/SectionLoader";
import UsernameCircle from "atoms/UsernameCircle/UsernameCircle";
import SortHeaderComponent from 'components/common/SortHeader';

import styles from "forms/components/styles.module.scss";

import LoadCountOfResponsesComponent from "forms/components/loadCOR";

class FieldFormsListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hoverIndex: null };
    this.handleCopy = this.handleCopy.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleCopy(item) {
    const { history } = this.props;
    history.push({
      pathname: `/forms/copy/fieldform`,
      search: this.setSearchParameters(item),
    });
  }

  handleEdit(item) {
    const { history } = this.props;
    history.push({
      pathname: `/forms/${item.id}/fieldform`,
      search: this.setSearchParameters(item),
    });
  }

  setSearchParameters(item = {}) {
    const {
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);

    return queryString.stringify({
      parent: `${pathname}?${queryString.stringify({
        ...query,
      })}`,
      form_id: item.id,
      form_name: item?.name,
      ...query,
      page: item ? 1 : query?.page,
    });
  }

  render() {
    const {
      list,
      isLoading,
      handlePreview,
      history,
      handleDelete,
      selectedForms,
      onSelectForm,
      handleStatus,
      assignEmpList,
    } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className={`ml-3 mr-3 pt-0 `}>
        <Table
          responsive
          hover
          className={`${styles["table-style"]} table-form`}
        >
          <thead className={styles["forms-Header-col"]}>
            <tr>
              <th className="text-center pt-3">
                <input
                  type="checkbox"
                  checked={list.length && list.length === selectedForms.length}
                  onChange={() => onSelectForm('all')}
                />
              </th>
              <SortHeaderComponent ordering='template_data__label'>Name</SortHeaderComponent>
              <th className="text-center">Responses</th>
              <th className="text-center">Assigned To</th>
              <th className="">Created by</th>
              <th className="text-center">Active/Inactive</th>
              <th className="text-center">Date Created</th>
              <th >Action</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length ? (
              list.map((item, index) => (
                <tr
                  key={item.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push({
                      pathname: `/forms/${item.id}/responces`,
                        search: this.setSearchParameters(item),
                    })
                  }}
                >
                  <td
                    title="Select to Mark Active/Inactive"
                    className="text-center pt-3"
                  >
                    <input
                      type="checkbox"
                      onClick={(event) => event.stopPropagation()}
                      checked={selectedForms.indexOf(item.id) > -1 ? true : false}
                      onChange={() => onSelectForm(item.id)}
                    />
                  </td>
                  <td title={item.name} className="pr-3 pt-3">
                    <span>
                      {item?.template_data?.label ?? ""}
                    </span>
                  </td>
                  <td
                    title={item.count_of_responses}
                    className="text-center pt-3"
                  >
                    <LoadCountOfResponsesComponent formId={item.id} />
                  </td>
                  <td 
                    title={item.assigned_to} 
                    className="text-center pt-3"
                    id={`formEmpList${index}`}
                    onMouseOver={()=> {
                      this.props.getEmployeeList(item.id, index);
                      this.setState({hoverIndex: index});
                    }}
                  >
                    {item.assigned_to}
                    <Tooltip
                      placement="right"
                      isOpen={this.state.hoverIndex === index}
                      autohide={false} 
                      hideArrow={false}
                      target={`formEmpList${index}`}
                      toggle={() => this.setState({ hoverIndex: null})}
                      className={`${styles['hover-employee-div']} hover-form-employee-count-tooltip`}
                    >
                      {assignEmpList && assignEmpList[`formEmpList${index}`] && assignEmpList[`formEmpList${index}`].length ? 
                        assignEmpList[`formEmpList${index}`].map(item => <li>{item}</li>) :
                        <li>No records</li>
                      }
                    </Tooltip>
                  </td>
                  <td title={item.created_by} className="text-center">
                    {item.created_by && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <UsernameCircle name={item.created_by} />
                        </div>
                        {item.created_by}
                      </div>
                    )}
                  </td>
                  <td title={item.status} className="text-center pt-3" onClick={e => e.stopPropagation()}>
                    <Switch 
                      checked={item.status}
                      onChange={() => handleStatus(item, true)}
                      height={18}
                      width={36}
                      onColor='#09819A'
                      uncheckedIcon={false}
                      checkedIcon={false}
                    />
                  </td>
                  <td title={item.date_created} className="text-center pt-3">
                    {item.date_created}
                  </td>
                  <td className="text-center mt-1">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width:'90px'
                      }}
                      className="pr-1"
                    >
                      <img
                        height="22"
                        width="22"
                        alt="preview icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          handlePreview(item?.template_data ?? [],true);
                        }}
                        className="cursor-pointer"
                        title="Preview"
                        src={PreviewIcon}
                      />
                      <img
                        src={CopyIcon}
                        alt="Copy icon"
                        style={{ height: "18px", width: "18px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handleCopy(item);
                        }}
                        className="cursor-pointer"
                        title="Clone"
                      />
                      <img
                        src={EditIcon}
                        alt="Edit form icon"
                        style={{ height: "15px", width: "15px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          this.handleEdit(item);
                        }}
                        className="cursor-pointer"
                        title="Edit"
                      />
                      <img
                        height="20"
                        width="20"
                        alt="delete icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDelete(item,true);
                        }}
                        className="cursor-pointer"
                        title="Delete"
                        src={DeleteIcon}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center p-2" colSpan="8">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default FieldFormsListComponent;
