import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select/lib/Select';
import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Label from 'reactstrap/lib/Label';

/**
 * SelectAtom
 *
 * - simpleValue: to return value only
 * - clearable & backspaceRemoves: set both to false for required field
 * - getOptions & isLoading: to load options on focus
 *
 * - For props refer: https://github.com/JedWatson/react-select
 */
const SelectAtom = (props) => {
  const {
    input, layout, label, help, options,
    getOptions, onChange, meta: { error, touched },changeStyle, ...rest
  } = props;

  const handleFocus = (ev) => {
    input.onFocus(ev);
    if (( !options && getOptions ) || ( !options.length  && getOptions )) {
      getOptions();
    }
  };

  const handleChange = (val) => {
    input.onChange(val);
    if (onChange) onChange(val);
  };

  const handleBlur = () => {
    input.onBlur();
  };
 
  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol}>
          <Select
          style={changeStyle && {top:"-0.6rem"}}
            {...input}
            {...rest}
            className='select-disabled select-drop-color select-open select-hover'
            options={options}
            menuContainerStyle={{ zIndex: 999 }}
            onFocus={handleFocus}
            onChange={val => handleChange(val)}
            onBlur={handleBlur}
            onBlurResetsInput={false}
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<span>
          <Select
          style={changeStyle && {top:"-0.6rem"}}
            {...input}
            {...rest}
            className='select-disabled select-drop-color select-open select-hover'
            options={options}
            menuContainerStyle={{ zIndex: 999 }}
            onFocus={handleFocus}
            onChange={val => handleChange(val)}
            onBlur={handleBlur}
            onBlurResetsInput={false}
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </span>)
      }
    </FormGroup>
  );
};

SelectAtom.defaultProps = {
  layout: {},
};

SelectAtom.propTypes = {
  /*input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,*/
  layout: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  help: PropTypes.string,
  getOptions: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default SelectAtom;
