import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';


const GeoFenceBreachTableComponent = (props, context) => {
  const { data, location: { query }, pageSize, child } = props;
  const { router: { route: { match: { params } } } } = context;
  const { page = 1, ...rest } = query;
  let jobIds;
  let parsedJobsParam;

  if(rest && rest.jobs) {
    parsedJobsParam = JSON.parse(rest.jobs);
    if (parsedJobsParam.length > 0) jobIds = parsedJobsParam.map((item) => item.id);
  }
  else if(rest.job_ids) jobIds = JSON.parse(rest.job_ids); 
  
  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."employee_id"'>
            Employee Id
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."first_name"'>
            First Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."last_name"'>
            Last Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."title"'>
            Title
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='department_name'>
            Department Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='team_name'>
            Team Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='breach_count'>
            Breach Count
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td title={item.employee_id} className="pl-3">{item.employee_id}</td>
              <td title={item.first_name}>
                {child.length > 0 &&
                  <Link to={{
                    pathname: `/report/${child[0].id}/${child[0].code}/detail/${item.id}`,
                    search: queryString.stringify({
                      reportDetail: JSON.stringify({ ...query, ...params }),
                      job_ids: JSON.stringify(jobIds),
                    }),
                  }}>
                    {item.first_name}
                  </Link>
                }
              </td>
              <td title={item.last_name}>{item.last_name}</td>
              <td title={item.title}>{item.title}</td>
              <td title={item.department_name}>{item.department_name}</td>
              <td title={item.team_name}>{item.team_name}</td>
              <td title={item.breach_count} className="text-right pr-3">{item.breach_count}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
      <tbody />
    </Table>
    </div>
  );
};

GeoFenceBreachTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  child: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

GeoFenceBreachTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default GeoFenceBreachTableComponent;
