import React from 'react';
import Table from 'reactstrap/lib/Table';

import Link from 'react-router-dom/Link';
import SectionLoaderAtom from 'atoms/SectionLoader';

import EyeIcon from './EyeView.png';
import DeleteIcon from './Delete.png';
import EditIcon from './Edit.png';
import SwitchLanguageIcon from './switchLanguage.png';


class SurveyTemplateListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    
  }

  render() {
    const { list, isLoading , getSurveyDetails , isSurveyModal, showSurvey, deleteTemplateModal, editSurveyDetail, showEditableSurvey } = this.props;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
        <div className="ml-3 mr-4">
          <Table responsive hover className="table-emp survey-table">
            <thead className="Header-col">
              <tr>
                <th width="5%" className="text-center pl-3">&nbsp;</th>
                <th className="text-center">Action</th>
                <th>Survey Name</th>
                <th>Date & Time</th>
                <th>Is Active?</th>
                <th className="pr-3 text-center">Switch Language?</th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? list.map(item => (
                <tr key={item.id}>
                  <td className="text-center pl-3">
                    <img src={EyeIcon} alt="view" className="cursor-pointer ml-1" width="20" height="13" onClick={()=>showSurvey(item.id)}/>
                  </td>
                  <td title="action" className="text-center">
                    <img src={EditIcon} alt="edit"  className="cursor-pointer" width="15" height="15" onClick={()=>editSurveyDetail(item.id)}/>
                    {item.is_deletable && <img src={DeleteIcon} alt="delete" className="ml-3 cursor-pointer" width="15" height="15" onClick={()=>deleteTemplateModal(item.id)}/>}
                  </td>
                  <td title={item.survey_name} onClick={()=>getSurveyDetails(item.id)} className="survey-link">
                    {item.survey_name}
                  </td>
                  <td title={item.date_time}>{item.date_time}</td>
                  <td title={item.is_active ? 'Active' : 'Inactive'}>{item.is_active ? 'Active' : 'Inactive'}</td>
                  <td className="text-center pr-3">
                    <img src={SwitchLanguageIcon} alt="view" className="cursor-pointer ml-1" width="25" height="20" onClick={()=>showEditableSurvey(item.id)}/>
                  </td>
                </tr>
              ))
              :
              <tr><td className="text-center" colSpan="6">No Records Found</td></tr>
              }
            </tbody>
          </Table>
        </div>                
    );
  }
};


export default SurveyTemplateListComponent;
