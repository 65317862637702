import React, {PureComponent} from 'react';
import { Formik } from 'formik';
import { Label, Form, FormText, FormGroup, Input , Button , Card , CardTitle } from 'reactstrap';
import logo from 'sections/common/Sidenav/logo.png';
import name from './name.png';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';
import styles from './styles.module.scss';

class ChangeExpPasswordForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password' , showStrengthCheck : false };
  }


  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const { inputType } = this.state;
    setErrors({});
    this.props.usecase.setup_data(values);
    try {
      await this.props.usecase.execute(values);
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  
 render() {
    const { inputType } = this.state;

    return (
      <div>
        <Card className="ui-card mt-4">
          <div className="text-center pb-5 pt-3">
            <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
            <img src={name} height="26px" width="150px" alt="Logo"/>
          </div>
        <div className="pb-4">
        <CardTitle  className="ml-5">Change Password?</CardTitle>
        <hr className="line"/>
        <Formik
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}  className="ml-5 mr-5">
             <div style={{minHeight: '0vh', fontSize: '20px'}} className="mb-3"><FormText color="danger">{errors && errors.non_field_errors}</FormText></div>
              <FormGroup>
                <div className="pos-relative">
                  <Label className="mb-2">Current Password*</Label>
                  <Input
                    type={inputType}
                    name="current_password"
                    placeholder="Current Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.current_password}
                    invalid={errors && touched.current_password && errors && errors.current_password}
                    className={errors && errors.current_password ? "form-control-error" : null}
                    data-lpignore={true}
                  />
                  {inputType === 'password'
                    ? (<EyeOpenIconAtom
                      className="password-icon"
                      height="22" width="22"
                      onClick={this.toggleInputType}
                    />)
                    : (<EyeCloseIconAtom
                      className="password-icon"
                      height="22" width="22"
                      onClick={this.toggleInputType}
                    />)}
                  <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.current_password}</FormText></div>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="pos-relative">
                  <Label className="mb-2">New Password*</Label>
                  <Input
                    type={inputType}
                    name="password"
                    placeholder="New Password"
                    onChange={handleChange}
                    value={values.password}
                    invalid={errors && touched.password && errors && errors.password}
                    className={errors && errors.password ? "form-control-error" : null}
                    data-lpignore={true}
                    onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                    onFocus = {this.strengthCheckToggle}
                  />
                  <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.password}</FormText></div>
                  {this.state.showStrengthCheck &&
                    <PasswordStrengthMeter
                      values={values}
                    />
                  }
                </div>
              </FormGroup>
              <FormGroup>
                <div>
                  <Label className="mb-2">Confirm New Password*</Label>
                  <Input
                    type={inputType}
                    name="re_password"
                    placeholder="Confirm New Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.re_password}
                    invalid={errors && touched.re_password && errors && errors.re_password}
                    className={errors && errors.re_password ? "form-control-error" : null}
                    data-lpignore={true}
                  />
                 <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.re_password}</FormText></div>
                </div>   
              </FormGroup>
              <div className="text-center">
                <Button
                  className={`${styles['accounts-button']} mt-2`}
                  type="submit"
                  isColor='primary'
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                Submit <ButtonLoaderAtom active={isSubmitting} />
                </Button>
              </div>
            </Form>
            )}
          />
      </div>
    </Card>
  </div>
    );
  }}

export default ChangeExpPasswordForm;
