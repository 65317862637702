import React from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import SSTCFLayout from 'layouts/SSTCF';
import { NAV } from 'constants/vendors';
import { toast } from 'react-toastify';

import {Elements, StripeProvider} from 'react-stripe-elements';

import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import SectionLoaderAtom from 'atoms/SectionLoader';

import PaymentMethodUsecase from 'payments/paymentMethods/usecases/paymentMethod';
import PaymentMethodApiGateway from 'payments/paymentMethods/gateways/paymentMethod';
import PaymentMethodComponent from 'payments/paymentMethods/components/paymentMethod';
import CardTableComponent from 'payments/paymentMethods/components/table';
import ModalComponent from 'components/common/GenericModal';
import ConfirmPasswordModal from 'payments/bills/components/confirmPasswordModal';

const { REACT_APP_STRIPE_API_KEY_BASE } = process.env;


class PaymentMethodPage extends React.PureComponent {

  constructor(props) {
    super(props);
    this.listenPaymentMethodUseCase = this.listenPaymentMethodUseCase.bind(this);
    const paymentMethodApiGateway = new PaymentMethodApiGateway();

    this.usecase = new PaymentMethodUsecase(paymentMethodApiGateway);
    this.fetchData = this.fetchData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggle = this.toggle.bind(this);
    this.subscriptionToggle = this.subscriptionToggle.bind(this);
    this.state={
      companyPlanDetails: [],
      isInfoOpen: true,
      infoList: [],
      parent : NAV.PaymentMethodPage.parent,
      isAddCardModalOpen: false,
      cancelSubscriptionModalOpen: false,
      isLoading: false,
      cardDetails: {},
    }

  }

  componentDidMount() {
    this.listenPaymentMethodUseCase();
    const cookieKey = cookie.load("company_plan_info");
    if(cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.usecase.getInfoList();
  }

  toggle(){
    this.setState({isAddCardModalOpen : false});
  }

  subscriptionToggle() {
    const { cancelSubscriptionModalOpen } = this.state;
    this.setState({
      cancelSubscriptionModalOpen: !cancelSubscriptionModalOpen
    })
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  fetchData(){
    const { profile } = this.props;
    this.usecase.getCardDetails(profile.company.id);
  }

  listenPaymentMethodUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_LOADER':{
          this.updateState('isLoading', true);
          break;
        }
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'CARD_DETAILS':{
          this.props.dispatch({
            type: 'vendors/payment/GET_CUSTOMER_FULFILLED',
            payload: event.detail
          });
          this.setState({
            isLoading: false, isAddCardModalOpen: false });
          break;
        }
        case 'GET_CARD_DETAILS':
          this.fetchData();
          break;
        case 'SAVE_CARD_SUCCESS':{
          this.updateState('isAddCardModalOpen', false);
          this.fetchData();
          break;
        }
        case 'CANCEL_PLAN_SUCCESS': {
          toast.success('Your plan cancellation is in progress');
          this.updateState('cancelSubscriptionModalOpen', !this.state.cancelSubscriptionModalOpen);
          this.fetchData();
          break;
        }
        case 'DELETE_CARD_DETAILS':{
          this.fetchData();
          toast.success('Card deleted successfully.');
          break;
        }
        case 'GET_CUSTOMER_DETAIL_SUCCESS':{
          this.props.history.push('/accounts/cancellation');
          break;
        }
        case 'CARD_DEFAULT_SUCCESS':
          toast.success('Your selected card has been set as default for future payments.');
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("company_plan_info", !isInfoOpen);
  }

  render() {
    const { isInfoOpen, infoList, parent, isAddCardModalOpen, cancelSubscriptionModalOpen, isLoading } = this.state;
    const { profile, customerDetail } = this.props;

    const tabs = [
      { href: '/vendor/plan', label: 'Plan' },
      { href: '/vendor/invoice', label: 'Invoices' },
      { href: '/vendor/payment-method', label: 'Payment Methods' },
    ];

    return(
      <SSTCFLayout
        navInfo={{...NAV.PaymentMethodPage, parent}}
        tabItems={tabs}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.fetchData}
        infoList={infoList}
      >
        {isLoading &&
          <div className="col-min-height">
            <SectionLoaderAtom active />
          </div>}
        {!isLoading &&
          <CardTableComponent
            data={customerDetail && customerDetail.sources}
            defaultCard={{ src_id: customerDetail && customerDetail.default_source }}
            usecase={this.usecase}
            planType={customerDetail && customerDetail.plan.plan_type}
            profile={profile}
            updateState={this.updateState}
            isAddCardModalOpen={isAddCardModalOpen}
            cancelSubscriptionModalOpen={cancelSubscriptionModalOpen}
            subscriptionToggle={this.subscriptionToggle}
            isPageLoading={isLoading}
            companyId={profile.company.id}
            commercialStatus={customerDetail && customerDetail.commercial_status}
          />}
        {isAddCardModalOpen &&
          <ModalComponent
            isOpen={isAddCardModalOpen}
            heading="Add Your Card"
            size="md"
            toggle={this.toggle}
          >
            <StripeProvider apiKey= {`${REACT_APP_STRIPE_API_KEY_BASE}`}>
              <div>
                <Elements>
                  <PaymentMethodComponent
                    usecase={this.usecase}
                    profile={profile}
                    isLoading={isLoading}
                    cardDetails={customerDetail}
                  />
                </Elements>
              </div>
            </StripeProvider>
          </ModalComponent>}

          {cancelSubscriptionModalOpen &&
            <ConfirmPasswordModal
              isOpen={cancelSubscriptionModalOpen}
              toggle={this.subscriptionToggle}
              usecase={this.usecase}
              initialValues={{ email: profile.username,
                company_id: profile.company.id }}
            />

          }
      </SSTCFLayout>
    );
  }
}

const mapStatetoProps = (state) => ({
  profile: UserDucks.profile(state),
  customerDetail: PaymentDucks.customerDetail(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

PaymentMethodPage.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
  mapDispatchToProps
)(PaymentMethodPage);
