import React from 'react';
import { Row, Col} from 'reactstrap';
import queryString from 'query-string';
import UserAPIGateway from 'accounts/gateways/user';
import SetPasswordForm from 'accounts/components/setPassword';
import ContentLayout from 'layouts/Content';
import SetPasswordUseCase from 'accounts/usecases/setPassword';


class SetPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, uid: '', user: {} };
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new SetPasswordUseCase(userAPIGateway);
  }

  componentWillMount() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.updateState('uid', query.uid);
    this.usecase.getUser(query.uid);
    this.listenSetPasswordInUseCase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenSetPasswordInUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SET_PASSWORD_SUCCESS':
          this.props.history.push('/dashboard');
          break;
        case 'GET_USER_SUCCESS':
          this.updateState('user', event.data);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    const { user } = this.state;
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col xs={12} md={{ size:4, offset:4 }} className="align-self-center" >
            <SetPasswordForm  usecase={this.usecase} uid={this.state.uid} user={user}/>
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}


export default SetPasswordPage;
