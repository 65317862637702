 
//import { useParams } from "react";

//const params = useParams();
const employeeId =(window.location.pathname).split('/')[2]
const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/employee', label: 'Employees' },
];

export const NAV = {
  EmployeeListPage: {
    title: 'Employees',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    parent: '/dashboard',
    description: 'View & manage all your Employees profiles',
  },
  EmployeeAddFormPage: {
    title: 'Add Employee',
    breadcrumb: [
      ...baseNav,
      { label: 'New' },
    ],
    parent: '/employee/list',
    description: 'Add new Employee to your Company',
  },
  EmployeeEditFormPage: {
    title: 'Edit Employee',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/employee/list',
    description: 'Edit Employee profile',
  },
  EmployeeAuditLogPage: {
    title: 'Employee(s) Biometric Failure Logs',
    breadcrumb: [
      ...baseNav,
      { label: 'Audit logs' },
    ],
    parent: '/dashboard',
    description: 'View all your employee(s) biometric failures',
  },
  EmployeeBulkUploadPage: {
    title: 'Employee Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/employee/list',
    description: 'Upload all your employees',
  },
  EmployeeJobsListPage: {
    title: 'Jobs',
    breadcrumb: [
      ...baseNav,
      { label: 'Jobs' },
    ],
    parent: '/employee/list',
  },

  EmployeeViewTimeOffPoliciesPage: {
    title: 'View Time Off Policies',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: 'timeOffPolicies',
  },
  
};

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];


export const LOGIN_STATUS_FILTER_OPTIONS = [
  { value: 'ALL', label: 'All' },
  { value: 'SUCCESS', label: 'Valid' },
  { value: 'FAILURE', label: 'Invalid' },
];  


export const TABS = [
  { key: 'detail', label: 'Employee Detail' },
  { key: 'auditLogs', label: 'Audit Logs'},
];

export const ACTIVITY_TYPE ={
  punch_in : 'Punch In',
  punch_out : 'Punch Out',
  meal_start : 'Meal Start',
  meal_end : 'Meal End',
  'Invalid user' :'Invalid user'
}

export const CREATED_DATE_FILTER = [
  { value: 0, label: 'All Days' },
  { value: 7, label: 'Last 7 Days' },
  { value: 30, label: 'Last 30 Days' },
  { value: 60, label: 'Last 60 Days' },
]

export default { NAV, FILTER_OPTIONS, TABS,LOGIN_STATUS_FILTER_OPTIONS,ACTIVITY_TYPE, CREATED_DATE_FILTER };
