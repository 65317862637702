const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/document/completion/report', label: 'Document Reports' },
];

export const NAV = {
  DocumentCompletionReportListPage: {
    title: 'Document Completion Report',
    breadcrumb: [
      ...baseNav,
      { label: 'list' },
    ],
    parent: '/dashboard',
    description: 'View your Employees document completion status',
  },
  NotificationListPage: {
    breadcrumb: [
      { href: '/dashboard', label: 'Dashboard' },
      {href: '/notification/list', label: 'Notification Board'},
      { label: 'list' },
    ],
    parent: '/dashboard',
    description: 'View all sent notification status',
    title: 'Notification Board',
    // Dynamic breadcrumb, description, parent, title
  },

  
};

export const STATUSES = [
  { value: '1', label: 'Approved' },
  { value: '3', label: 'Awaited Approval' },
  { value: '2', label: 'Invalidated' },
  { value: '4', label: 'Delete' }
];

export const RESOLVED_AS = [
  { value: 'User Error', label: 'User Error' },
  { value: 'Violation', label: 'Violation' },
  { value: 'Others', label: 'Others' },
];

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];

export const PUNCH_IN_TYPES = [
  { value: '', label: 'All' },
  { value: 'manual_entry', label: 'Manual Entry' },
  { value: 'kiosk', label: 'Kiosk Entry' },
  { value: 'app_user', label: 'App Entry' },
];

export const PUNCH_IN_TYPES_CURRENT = [
  { value: '', label: 'All' },
  { value: 'kiosk', label: 'Kiosk Entry' },
  { value: 'app_user', label: 'App Entry' },
];

export const SHIFT_VIEW_OPTIONS = [
  { value: '1', label: 'Timeline' },
  { value: '2', label: 'Shift Notes'}
];

export const PUNCH_IN_OPTIONS = [
  { value: '1', label: 'Punched-in' },
  { value: '2', label: 'Not Punched-in'}
]

export default { NAV, STATUSES, FILTER_OPTIONS, RESOLVED_AS, PUNCH_IN_TYPES, PUNCH_IN_TYPES_CURRENT, SHIFT_VIEW_OPTIONS ,PUNCH_IN_OPTIONS};
