import React from 'react';

import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import connect from 'react-redux/lib/connect/connect';
import ToggleAtom from 'atoms/Toggle';
import moment from 'moment-timezone';
import {toast} from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import InfoGreyIconAtom from 'atoms/InfoIcon';
import { UncontrolledTooltip } from 'reactstrap';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import closeimage from './Close-Cross.png'

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * EmployeeListPage -> EmployeeFilterSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class AddMealRuleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state={
      isSubmitting:false
    }
    this.submit=this.submit.bind(this);
  }

  submit(data){
    this.setState({isSubmitting :true});
    const { saveData, initialValues, updateMeal, mealDetails } = this.props;
    const notification = mealDetails && mealDetails.notifications ? mealDetails.notifications : null;
    const mealStartNotification = notification ? notification.find((i) =>
      i.notification_type === 'MEAL_START'):'';
    const mealEndNotification = notification ? notification.find((i) =>
      i.notification_type === 'MEAL_END'):'';
    const forceMealEndNotification = notification ? notification.find((i) =>
      i.notification_type === 'MEAL_FORCE_END'):'';
    var hmsMealStart = data.mealStartNotificationTime ? data.mealStartNotificationTime : null;
    var hmsMealEnd = data.mealEndNotificationTime ? data.mealEndNotificationTime : null;
    var mealStart = hmsMealStart ? hmsMealStart.split(':') : null;
    var mealEnd = hmsMealEnd ? hmsMealEnd.split(':') : null;
    var secondsMealStart = mealStart ? (+mealStart[0]) * 60 * 60 + (+mealStart[1]) * 60  :null;
    var secondsMealEnd = mealEnd ? (+mealEnd[0]) * 60 * 60 + (+mealEnd[1]) * 60 : null;
    const MaxHour = data.MealStarthour ? (+data.MealStarthour) * 60 * 60 : null;
    const MaxMinute = data.MealStartminute ? (+data.MealStartminute) * 60 : null;
    var MaxMealStartSeconds = (+MaxHour) +  (+MaxMinute);
    const MealHour = data.MealBreakHour ? (+data.MealBreakHour) * 60  : null;
    const MealMins = data.MealBreakMinute ? (+data.MealBreakMinute)  : null;
    var MealBreakDuration = (+MealHour) +  (+MealMins);
    const WavierHour = data.wavier_hour ? (+data.wavier_hour) * 60 * 60  : null;
    const WavierMin = data.wavier_minute ? (+data.wavier_minute) * 60  : null;
    var WaiverDuration =(+WavierHour) +  (+WavierMin);
    if(data.is_meal_start_notification && hmsMealStart && !(hmsMealStart.match('^([1-9]{1}|[0-9]{2}):([1-9]{1}|[0-9]{2})$')))
       return toast.error('Enter valid time') && this.setState({isSubmitting: false});
    if(data.is_meal_end_notification && hmsMealEnd && !(hmsMealEnd.match('^([1-9]{1}|[0-9]{2}):([1-9]{1}|[0-9]{2})$')))
       return toast.error('Enter valid time') && this.setState({isSubmitting: false});
     const mealStartNotificationObject ={
      notification_message: data.mealStartNotification ? data.mealStartNotification : '',
      notification_type: 'MEAL_START',
      notification_after: secondsMealStart || secondsMealStart === 0 ? secondsMealStart : '',
      id: mealStartNotification ? mealStartNotification.id : null,
      is_active: mealStartNotification ? mealStartNotification.is_active : null,
      is_visible: mealStartNotification ? mealStartNotification.is_visible : null,
     }
     const mealEndNotificationObject ={
      notification_message: data.mealEndNotification ? data.mealEndNotification : '',
      notification_type: 'MEAL_END',
      notification_after: secondsMealEnd || secondsMealEnd === 0 ? secondsMealEnd : '',
      id: mealEndNotification ? mealEndNotification.id : null,
      is_active: mealEndNotification ? mealEndNotification.is_active :null,
      is_visible: mealEndNotification ? mealEndNotification.is_visible : null,
     }
     const automaticMealEndObject ={
      notification_message: data.automaticMealEndNotification,
      notification_type: 'MEAL_FORCE_END',
      notification_after: MealBreakDuration * 60,
      id: forceMealEndNotification ? forceMealEndNotification.id : null,
      is_active: forceMealEndNotification ? forceMealEndNotification.is_active : null,
      is_visible: forceMealEndNotification ? forceMealEndNotification.is_visible : null,
     }
     const notifications=[];
     if(data.is_meal_start_notification){
        notifications.push(mealStartNotificationObject);
     }
     if(data.is_meal_end_notification){
        notifications.push(mealEndNotificationObject);
     }
     if(data.automatic_meal_end){
        notifications.push(automaticMealEndObject);
     }
    
     const extra_data={
      override_meal_payment: data.override_meal_payment ?  data.override_meal_payment : false,
      start_time: MaxMealStartSeconds,
      duration: MealBreakDuration,
      waiver: WaiverDuration,
      company_id: this.props.company_id,
      automatic_meal_end: data.automatic_meal_end ? data.automatic_meal_end : false,
     }
     if(initialValues.id){
      updateMeal(notifications, extra_data, data, initialValues.id)
       .then(() => {
        this.setState({isSubmitting: false});
       });
     }
     else
     saveData(notifications, extra_data, {...data})
  }

  render() {
    const {
      handleSubmit,
      automaticMealEnd,
      addMealLoader, 
      initialValues, 
      overrideMealPayment,
      isMealStartNotification,
      isMealEndNotification
    } = this.props;
    const { isOpen, toggle} = this.props;
    const { isSubmitting } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
        backdrop="static"
      >
        <ModalHeader>{initialValues.MealBreakHour === null ? ' Add Meal Rules' : 'Edit Meal Rules'}
           <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <ModalBody>
            <div className="pos-relative">
              <Form onSubmit={handleSubmit(this.submit)} noValidate>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      id="is_default"
                      type="checkbox"
                      name="is_default"
                      label={
                        <div>
                          Set as default Meal
                        </div>
                      }
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                    />
                    </Col>
                    </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      id="override_meal_payment"
                      type="checkbox"
                      name="override_meal_payment"
                      label={
                        <div>
                          Pay Meal Break
                          <InfoGreyIconAtom id="payMeal" className="pl-2 align-text-top" />
                          <UncontrolledTooltip placement="right" target="payMeal">
                            Select if meal breaks are paid.
                          </UncontrolledTooltip>
                        </div>
                      }
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                    />
                    </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <Field
                          id="include_rt_ot_dot"
                          type="checkbox"
                          name="include_rt_ot_dot"
                          label={
                            <div>
                              Meals to be added towards RT,OT and DOT
                              <InfoGreyIconAtom id="OtRule" className="pl-2 align-text-top" />
                              <UncontrolledTooltip placement="right" target="OtRule">
                                Select if meals are to be added towards RT,OT and DOT
                              </UncontrolledTooltip>
                            </div>
                          }
                          component={CheckboxAtom}
                          layout={FORM_LAYOUT}
                          disabled={!overrideMealPayment}
                        />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div>
                            Meal Break Duration
                           </div>
                        </Col>
                        <Col>
                          <Field
                            id="MealBreakHour"
                            name="MealBreakHour"
                            type="number"
                            component={InputAtom}
                            placeholder="Hour"
                            layout={FORM_LAYOUT}
                            suffix="Hr"
                            min="0"
                            max="24"
                          />
                        </Col>
                        <Col>
                          <Field
                            id="MealBreakMinute"
                            name="MealBreakMinute"
                            type="number"
                            component={InputAtom}
                            placeholder="Minute"
                            layout={FORM_LAYOUT}
                            suffix="Min"
                            min="0"
                            max="59"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div>
                            Max Time Before Meal Break Start
                            <InfoGreyIconAtom id="maxTime" className="pl-2 align-text-top" />
                            <UncontrolledTooltip placement="right" target="maxTime">
                              The maximum amount of time an employee may be on the clock prior to taking a meal break without triggering a meal violation.
                            </UncontrolledTooltip>
                           </div>
                        </Col>
                        <Col>
                          <Field
                            id="MealStarthour"
                            name="MealStarthour"
                            type="number"
                            component={InputAtom}
                            placeholder="Hour"
                            layout={FORM_LAYOUT}
                            suffix="Hr"
                            min="0"
                            max="24"
                          />
                        </Col>
                        <Col>
                          <Field
                            id="MealStartminute"
                            name="MealStartminute"
                            type="number"
                            component={InputAtom}
                            placeholder="Minute"
                            layout={FORM_LAYOUT}
                            suffix="Min"
                            min="0"
                            max="59"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field
                            id="wavier_hour"
                            name="wavier_hour"
                            type="number"
                            label={
                              <div>
                                Waiver Period
                                <InfoGreyIconAtom id="wavePer" className="pl-2 align-text-top" />
                                <UncontrolledTooltip placement="right" target="wavePer">
                                  If you are in California and have a waiver in place (for example, employees may waive their meal period if working few than 6 hours), enter that value here.
                                </UncontrolledTooltip>
                              </div>
                            }
                            component={InputAtom}
                            placeholder="Hour"
                            layout={FORM_LAYOUT}
                            suffix="Hr"
                            min="0"
                            max="24"
                          />
                        </Col>
                        <Col>
                          <Field
                            id="wavier_minute"
                            name="wavier_minute"
                            type="number"
                            component={InputAtom}
                            placeholder="Minute"
                            label="&nbsp;"
                            layout={FORM_LAYOUT}
                            suffix="Min"
                            min="0"
                            max="59"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span className="mr-1 d-inline-block">Notifications</span>
                          <hr className="mt-0 mb-0"/>
                        </Col>
                      </Row>
                        <Row className="mt-3 " style={{maxHeight: '40px'}}>
                        <Col md={9}  className="pt-3 mr-3">
                          <span className ="mr-1">Meal Start Notification</span>
                          <Field
                            id="is_meal_start_notification"
                            name="is_meal_start_notification"
                            type="boolean"
                            component={ToggleAtom}
                            layout={FORM_LAYOUT}
                          />
                        </Col>
                        <Col className="pl-0" style={{paddingTop: '12px'}}>                           
                          <div className="d-inline-block" style={{paddingTop : '7px'}}><small style={{color: '#696969'}}>Trigger after</small></div>
                            <span>
                               <Field
                                  id="mealStartNotificationTime"
                                  name="mealStartNotificationTime"
                                  component={InputAtom}
                                  layout={FORM_LAYOUT}
                                  placeholder="HH:MM"
                                  disabled={!isMealStartNotification}
                                />
                             </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                            <Field
                              id="mealStartNotification"
                              name="mealStartNotification"
                              component={InputAtom}
                              layout={FORM_LAYOUT}
                              placeholder="Meal Start Notification"
                              disabled={!isMealStartNotification}
                            />
                          </Col>
                        </Row>
                      <Row className={`mt-3`} style={{maxHeight: '40px'}}>
                        <Col md={9}  className="pt-3 mr-3">
                          <span className="mr-1">Meal End Notification</span>
                          <Field
                            id="is_meal_end_notification"
                            name="is_meal_end_notification"
                            type="boolean"
                            component={ToggleAtom}
                            layout={FORM_LAYOUT}
                          />
                        </Col>
                        <Col className="pl-0" style={{paddingTop: '12px'}}>                           
                          <div className="d-inline-block" style={{paddingTop : '7px'}}><small style={{color: '#696969'}}>Trigger After</small></div>
                                                    
                          </Col>
                        </Row>
                        <Row>
                          <Col md={9}>
                          <Field
                            id="mealEndNotification"
                            name="mealEndNotification"
                            component={InputAtom}
                            layout={FORM_LAYOUT}
                            placeholder="Meal End Notification"
                            disabled={!isMealEndNotification}
                          />
                        </Col>
                          <Col>
                           <Field
                              id="mealEndNotificationTime"
                              name="mealEndNotificationTime"
                              component={InputAtom}
                              layout={FORM_LAYOUT}
                              placeholder="HH:MM"
                              disabled={!isMealEndNotification}
                            /> 
                          </Col>
                      </Row>
                       <Row className="mt-3" style={{maxHeight: '40px'}}>
                        <Col md={9}  className="pt-3">
                          <span className = "mr-1">Automatic Meal End Setting</span>
                        </Col>
                        </Row>
                        <div style={{minHeight: '35px', maxHeight: '35px'}} className="mt-2">
                          <Field
                            id="automatic_meal_end"
                            type="checkbox"
                            name="automatic_meal_end"
                            label={
                              <div>
                                Automatically end break when duration ends
                                <InfoGreyIconAtom id="automaticMealEnd" className="pl-2 align-text-top" />
                                <UncontrolledTooltip placement="right" target="automaticMealEnd">
                                  Select if meals breaks are to be ended automatically when the duration ends
                                </UncontrolledTooltip>
                              </div>
                            }
                            component={CheckboxAtom}
                            layout={FORM_LAYOUT}
                          />
                        </div>
                        <Row>
                        <Col>
                          <Field
                            id="automaticMealEndNotification"
                            name="automaticMealEndNotification"
                            component={InputAtom}
                            layout={FORM_LAYOUT}
                            placeholder="Automatic Meal End Notification"
                            disabled={!(automaticMealEnd)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            type="submit"
                            color="secondary"
                            style={{width:'35%'}}
                            className="float-sm-right my-4"
                            size={FORM_LAYOUT.size}
                            disabled={isSubmitting}
                          >
                            Submit <ButtonLoaderAtom active={addMealLoader} />
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                 </div>
          </ModalBody>
      </Modal>
    );
  }
};

const validate = (values) => {
  const wavierValue = moment.duration(+values.wavier_hour || 0, 'hours').asSeconds() +
    moment.duration(+values.wavier_minute || 0, 'minutes').asSeconds();
  const firstMealThreshold = moment.duration(+values.MealStarthour || 0, 'hours').asSeconds() +
    moment.duration(+values.MealStartminute || 0, 'minutes').asSeconds();
  /*const MealBreakNull = ((parseInt(values.MealBreakHour, 10) === 0 && parseInt(values.MealBreakMinute, 10) === 0)) || (parseInt(values.MealBreakHour, 10) === 0 && !values.MealBreakMinute) || (!values.MealBreakHour && parseInt(values.MealBreakMinute, 10) === 0);*/
  const beforeMealHour = (parseInt(values.MealStarthour, 10) < 0);
  const beforeMealMinute = (parseInt(values.MealStartminute, 10) < 0);
  const wavierhour =  (parseInt(values.wavier_hour, 10) < 0);
  const wavier_minute = (parseInt(values.wavier_minute, 10) < 0);
  const isMealBreakMinute = (parseInt(values.MealBreakMinute, 10) < 0);
  const isMealBreakHour = (parseInt(values.MealBreakHour, 10) < 0);
  const errors = {};
  if(values.is_meal_end_notification  &&  (!values.mealEndNotificationTime))  errors.mealEndNotification = 'Enter trigger time for meal end Notification';
  if (!values.MealBreakHour && !values.MealBreakMinute) errors.MealBreakHour = 'Enter Meal Break duration';
  /*if(MealBreakNull) errors.MealBreakMinute = 'Minimum meal duration should be 1 minute';*/
  if(((wavierValue > 0) || (firstMealThreshold > 0)) && wavierValue <= firstMealThreshold) errors.wavier_hour = 'Wavier Period should be greater than Max Time Before Meal Start.';
  if(values.is_meal_start_notification && !values.mealStartNotification)  errors.mealStartNotification = 'Enter Meal Start Notification';
  if(values.is_meal_end_notification && !values.mealEndNotification)  errors.mealEndNotification = 'Enter Meal End Notification';
  if(values.is_meal_start_notification && values.mealStartNotification && (!values.mealStartNotificationTime))  errors.mealStartNotification = 'Enter trigger time for meal start Notification';
  if(values.automatic_meal_end && !values.automaticMealEndNotification) errors.automaticMealEndNotification = 'Enter automatic meal end notification';
  if(beforeMealHour) errors.MealStarthour = 'Enter valid hours';
  if(beforeMealMinute) errors.MealStartminute = 'Enter valid minutes';
  if (wavierhour) errors.wavier_hour = 'Enter valid hours';
  if (wavier_minute) errors.wavier_minute = 'Enter valid minutes';
  if (isMealBreakMinute) errors.MealBreakMinute = 'Enter valid minutes';
  if (isMealBreakHour) errors.MealBreakHour = 'Enter valid hours';
  return errors;
};


const AddRuleForm = reduxForm({
  form: 'AddRuleForm',
  enableReinitialize: true,
  validate,
})(AddMealRuleModal);

export default connect(
  (state) => {
    const addFormData=state.form.AddRuleForm && state.form.AddRuleForm.values;
    const automaticMealEnd  = state.form.AddRuleForm && state.form.AddRuleForm.values &&  state.form.AddRuleForm.values.automatic_meal_end;
    const notification  = state.form.AddRuleForm && state.form.AddRuleForm.values &&  state.form.AddRuleForm.values.notification;
    const overrideMealPayment  = state.form.AddRuleForm && state.form.AddRuleForm.values &&  state.form.AddRuleForm.values.override_meal_payment;
    const isMealStartNotification  = state.form.AddRuleForm && state.form.AddRuleForm.values &&  state.form.AddRuleForm.values.is_meal_start_notification;
    const isMealEndNotification  = state.form.AddRuleForm && state.form.AddRuleForm.values &&  state.form.AddRuleForm.values.is_meal_end_notification;
    return { addFormData , automaticMealEnd, notification, overrideMealPayment, isMealStartNotification, isMealEndNotification};

  },
)(AddRuleForm);
