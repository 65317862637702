import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import Lightbox from 'react-image-lightbox';
import { toast } from 'react-toastify';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import styles from './styles.module.scss';
import ResetBiometricModal from './resetBiometricModal';
import ResetPincodeModal from './resetPincodeModal';

/**
 * KioskEmployeeAuditListPage -> KioskEmployeeAuditFilterSection -> KioskEmployeesAuditTableComponent
 *
 * Components:
 *    - {@link KioskEmployessAuditTableComponent}
 *
 * State:
 *    - employee audit list
 *
 * Actions:
 *    None
 */

class KioskEmployeeAuditTableComponent  extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOverlayOpen: false,
      selectedId: null,
      isResetBiometric: false,
      enabledBiometric: false,
      isResetPincode:false,
      enabledPincode:false,
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.togglePincode = this.togglePincode.bind(this);
    this.enableResetBiometric = this.enableResetBiometric.bind(this);
    this.enableResetPincode = this.enableResetPincode.bind(this);
  }

  togglePopup() {
    const { isResetBiometric} = this.state;
    this.setState({ isResetBiometric: !isResetBiometric})
  }

  togglePincode(){
    const {isResetPincode } = this.state;
    this.setState({isResetPincode: !isResetPincode})
  }

  enableResetBiometric(){
    const { employeeData } = this.props;
    this.setState({ enabledBiometric: true })
    this.props.usecase.enableResetBiometric(employeeData)
    .then(() => {
      this.setState({ isResetBiometric: false, enabledBiometric: false })
      toast.success('Reset biometric successfully')
      this.props.usecase.getEmployeeData(employeeData.id)
    })
    .catch((error) => {
      this.setState({ enabledBiometric: false })
      toast.error(error?.errors?.biometric_access ? error?.errors?.biometric_access : 'Failed to reset biometric')
    });
  }

  enableResetPincode(){
    const { employeeData } = this.props;
    this.setState({ enabledPincode: true })
    this.props.usecase.enableResetPincode(employeeData)
    .then(() => {
      this.setState({ isResetPincode: false, enabledPincode: false })
      toast.success('Reset pincode successfully')
      this.props.usecase.getEmployeeData(employeeData.id)
    })
    .catch((error) => {
      this.setState({ enabledPincode: false })
      toast.error(error?.errors?.pincode_access ? error?.errors?.pincode_access : 'Failed to reset pincode')
    });
  }
  
  render(){
    const { data, selectedItemId, isKioskLoading, employeeData } = this.props;
    const { isOverlayOpen, selectedId, isResetBiometric, enabledBiometric, isResetPincode, enabledPincode } = this.state;
    if(isKioskLoading) return <SectionLoaderAtom active />;

    const sortedData = [...data].sort((a, b) => {
      if (a.extra_data?.favourite===true) return -1;
      if (b.extra_data?.favourite===true) return 1;
      return 0;
    });




    return (
      <div className='table-kiosk-log ml-3 mr-4'>
      { (employeeData.biometric_access || employeeData.pincode_access) &&
        <Button
          color="primary"
          type="button"
          onClick={() => {
            if (this.props.employeeData.biometric_access) {
              this.setState({ isResetBiometric: true });
            } else {
              this.setState({ isResetPincode: true });
            }
          }}          
          className={`${styles['reset-biometric-button']} mt-3 mr-2`}
          disabled={employeeData.biometric_access ? !employeeData.biometric_setup : employeeData.pincode_setup===false}
        >
          {employeeData.biometric_access ? "Enable Reset Biometric":"Enable Reset Pincode"}
        </Button>
      }
        <Table  className="table-emp table table-sm table-hover table-kiosk-audit mt-3" hover responsive size="sm">
          <thead className="Header-col">
            <tr>
              <th className="text-center pl-3">Image</th>
              <th>Date &amp; time</th>
              <th>Type</th>
              <th className="text-center align-middle pl-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {(sortedData.length > 0)  && sortedData.map((item ,index)=> {
              let message = '';
              if (item.note) {
                if(item.note.notes === 'Invalid user'){
                  message = 'Verified';
                }
                else if(item.note.notes === 'Enrollment Photo'){
                  message = 'Added successfully';
                }
                else if(item.note.notes === 'Facial Image reset'){
                  message = 'Reset successfully';
                }
                else if(item.verified){
                  message = 'Verified';
                }
              }
              return (<tr key={item.file_name}>
                <td className="pl-3"> 
                  <div 
                    role="button" 
                    tabIndex="0" 
                    className={styles['img-wrap']} 
                  >
                    <span
                      className="pr-2 outline-none"
                      role='button'
                      tabIndex="0"
                      onClick={() => this.setState({ selectedId: item.id, isOverlayOpen: true })} 
                    >
                      <img src={item.file} alt=""  />
                    </span>  
                  </div>
                  {isOverlayOpen && (selectedId === item.id) && (
                    <Lightbox
                      mainSrc={item.file}
                      onCloseRequest={() => this.setState({ isOverlayOpen: false })}
                    />
                  )}
                </td>
                <td>{item.create_date_display}</td>
                <td>{item.note && item.note.notes ? item.note.notes : null }</td>
                <td className="text-center align-middle">
                  {item.verified ?
                    <div>
                      <span className={styles['verify-audit']}>
                        {message}
                      </span>
                      <div>
                        {item.verified_datetime_display ? `on ${item.verified_datetime_display}` : null }
                      </div>
                      {item.note && item.note.notes === 'Invalid user' ?
                        <Button
                          color="default"
                          onClick={() => this.props.usecase.handleAuditClick(item)}
                          className={styles['unvalid-button']}
                          disabled={item.id === selectedItemId}
                        >
                          Mark as Invalid <ButtonLoaderAtom active={item.id === selectedItemId} />
                        </Button> : null
                      }
                    </div>
                    :
                    <div>
                      {item.note && item.note.notes === 'Invalid user' ? <Button
                        color="primary"
                        type="button"
                        className={styles['valid-button']}
                        onClick={() => this.props.usecase.handleAuditClick(item)}
                        disabled={item.id === selectedItemId}
                      >
                        Mark as Valid <ButtonLoaderAtom active={item.id === selectedItemId} />
                      </Button> : null }
                    </div>
                  }
                  {item.note && item.note.notes === 'Face not setup' ? 'Setup facial recognition from kiosk' : null}
                </td>
              </tr>
              )})}
            {sortedData && !sortedData.length &&
              <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
            }
          </tbody>
        </Table>
      {
        isResetBiometric ?
        <ResetBiometricModal
          isOpen={isResetBiometric}
          toggle={this.togglePopup}
          enableResetBiometric={this.enableResetBiometric}
          enabledBiometric={enabledBiometric}
        />
        :
        null
      }
       {
        isResetPincode ?
        <ResetPincodeModal
          isOpen={isResetPincode}
          toggle={this.togglePincode}
          enableResetPincode={this.enableResetPincode}
          enabledPincode={enabledPincode}
        />
        :
        null
      }
      </div>  
    );
  }
};

KioskEmployeeAuditTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  selectedItemId: PropTypes.object,
};

export default KioskEmployeeAuditTableComponent;
