import React from 'react';
import { toast } from 'react-toastify';

import queryString from 'query-string';

import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import surveyFormUseCase from 'surveys/usecases/surveyFormUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';

import SurveyFormComponent from 'surveys/components/surveyFormComponent';
import SurveyFormProgress from './surveyFormProgressBar';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import connect from 'react-redux/lib/connect/connect';

import SectionLoaderAtom from 'atoms/SectionLoader';
import { SURVEY_TYPE_OPTIONS } from 'constants/surveys';

class SurveyForm extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new surveyFormUseCase(surveyAPIGateway);
    this.listenFormUsecase = this.listenFormUsecase.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.saveSurveyData = this.saveSurveyData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.setSurveyTypeValue = this.setSurveyTypeValue.bind(this);
    this.state={ parent : NAV.EditSurveyTemplate.parent , formData : null, isLoading: true, isSaveLoaderActive:false, isNextLoaderActive: false, data: {roleList : [] , departmentList: [], teamList:[]} }
  }

  componentWillMount(){
    this.loadData();
  }


  setParentUrl() {
    const { location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        const parsedParams = query.parent;
        this.setState({
          parent: parsedParams,
        });
      }
      else{
         this.updateState('parent', `${NAV.EditSurveyTemplate.parent}?${queryString.stringify(query)}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  setSurveyTypeValue(data){
    console.log('data', data)
    if(!data){
      return false
    }
    if(JSON.stringify(data) == JSON.stringify(['delayed_meal'])){
        return 'delayed_meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['missing_meal'])){
      return 'missing_meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['geofence_breach'])){
      return 'geofence_breach';
    } 
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out'])){
      return 'force_punch_out'
    }
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out', 'delayed_meal'])){
      return 'force_punch_out&delayed_meal';
    }
    else if(JSON.stringify(data) == JSON.stringify(['force_punch_out', 'missing_meal'])){
      return 'force_punch_out&missing_meal'
    }
    else{
      return 'others'
    }
  }


  loadData(){
    const { match: { params } , userExtraData} = this.props;
    const companyId = userExtraData && userExtraData.company && userExtraData.company.id;
    this.setParentUrl();
    this.listenFormUsecase();
    if(params.id){
      this.usecase.getFormData(params.id)
      .then(()=>
        this.usecase.getInitialFieldValues(companyId)
      );
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenFormUsecase() {
    const { history } = this.props;
    const { location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SET_DROPDOWN_VALUES':
          this.updateState('data', event.data);
          this.updateState('isLoading', false)
          break;
        case 'GET_SURVEY_DATA_SUCCESS':
          const surveyType = this.setSurveyTypeValue(event.data.survey_type)
          this.updateState('formData', {...event.data , survey_type : surveyType});
          break;
        case 'UPDATE_FORM_SUCCESS':
          if(event.buttonType=='next'){
            history.push({
                pathname: `/surveys/${event.data.id}/questions`,
                search: queryString.stringify(query),
            });
            history.push(`/surveys/${event.data.id}/questions`)
          }
          else{
           history.push(`/surveys/list`); 
          }
          break;
        case 'UPDATE_FORM_FAILURE':
          if(event.data){
            toast.error(event.data);  
          }
          else{
            toast.error('Could not submit form.')
          }
          break;
        case 'SHOW_SUBMIT_LOADER':
          if(event.data=='next'){
            this.updateState('isNextLoaderActive',true);
          }
          else{
            this.updateState('isSaveLoaderActive',true);
          }
          break;
        case 'HIDE_SUBMIT_LOADER':
          this.updateState('isNextLoaderActive',false);
          this.updateState('isSaveLoaderActive',false);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  async saveSurveyData(data,type){
    const { match: { params } } = this.props;
    await this.usecase.updateSurveyFormData(data, params.id, type);
  }

  render() {
    const { parent , isButtonLoader, isLoading , formData, isNextLoaderActive, isSaveLoaderActive } = this.state;
    const { match: { params } , location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    console.log('0000000', formData)
    return (
      <TwoColumnLayout
        navInfo={params.id ? {...NAV.EditSurveyTemplate , parent : parent } : {...NAV.CreateSurveyTemplate , parent : parent }}
        fetchData={() => this.loadData()}
      >
      <div className="ml-3 mr-4 pb-5">
        {isLoading ? 
          <SectionLoaderAtom active/> 
          :
          <div>
            <SurveyFormProgress 
              stepNumber = {1} 
              isStepThree = {formData && formData.template_data && formData.template_data.outcome == 'scoring' && formData.survey_type.length && formData.survey_type == 'others'  ? true : false}
              selectedId = { !(query && query.setup == 'true') && formData && formData.id}
              history={this.props.history}
              query={query}
            /> 
            <SurveyFormComponent 
              data={this.state.data}
              usecase={this.usecase}
              isNextButtonLoader={isNextLoaderActive}
              isSaveButtonLoader={isSaveLoaderActive}
              history={this.props.history}
              initialValues={formData && formData.template_data ? 
                { 
                  label : formData.template_data.label, 
                  role : formData.oauth_roles && formData.oauth_roles.length ? formData.oauth_roles : [],
                  mobile_check : formData.context && formData.context.channel && formData.context.channel.app ? true : false,
                  kiosk_check : formData.context && formData.context.channel && formData.context.channel.kiosk ? true : false,
                  outcome : formData.template_data.outcome,
                  frequency : formData.schedule && formData.schedule.frequency ? formData.schedule.frequency/3600 : null,
                  departments : formData.context && formData.context.department_ids && formData.context.department_ids.length ? formData.context.department_ids : [],
                  teams : formData.context && formData.context.team_ids && formData.context.team_ids.length ? formData.context.team_ids : [],
                  survey_type : formData.survey_type,
                  is_active : formData && formData.is_active ? formData.is_active : false,
                }
                 :
                 null
              }
              saveSurveyData={this.saveSurveyData}              
            /> 
          </div>
        }
      </div>
        
      </TwoColumnLayout>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
});


export default connect(
  mapStateToProps,
)(SurveyForm);
