import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import KnowledgeBaseListPage from './List';
import KnowledgeBaseFormPage from './Form';


const KnowledgeBaseRoutes = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="knowledge_base.list"
        component={KnowledgeBaseListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/add`}
        name="knowledge_base.add"
        component={KnowledgeBaseFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/edit`}
        name="knowledge_base.edit"
        component={KnowledgeBaseFormPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

KnowledgeBaseRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default KnowledgeBaseRoutes;
