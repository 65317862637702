import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { toast } from 'react-toastify';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import SectionLoaderAtom from 'atoms/SectionLoader';
import GeoFenceFormComponent from 'components/geoFences/form/Form';

import { MSGS } from 'constants/geoFences';

const { REACT_APP_GOOGLE_API_KEY_BASE } = process.env;

class GeoFenceFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.setShapeInfo = this.setShapeInfo.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
  }

  setShapeInfo(data) {
    this.props.GeoFenceDucks.setGeoFence(data);
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  createOrUpdate(data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const { geoFenceDetail, selectedGeofence, toggle, updateStatus, getJobGeofence, location: { query } } = this.props;
    let detail = {};

    if (Object.keys(geoFenceDetail.lat_long).length === 0) {
      return Promise.reject({
        non_field_errors: 'No geofence created.',
      });
    }

    if (geoFenceDetail.shape_type === 'Rectangle' ||
      geoFenceDetail.lat_long.type === 'Rectangle') {
      detail = {
        ...data,
        lat_long: {
          ...geoFenceDetail.lat_long,
          type: 'Polygon',
        },
        address: (geoFenceDetail.lat_long.address || geoFenceDetail.lat_long.address === '')
          ? geoFenceDetail.lat_long.address
          : geoFenceDetail.address ? geoFenceDetail.address : data.address.description,
        shape_type: 'Rectangle',
      };
    } else if (geoFenceDetail.shape_type === 'Circle' ||
      geoFenceDetail.lat_long.type === 'Circle') {
      detail = {
        ...data,
        lat_long: {
          ...geoFenceDetail.lat_long,
          type: 'Point',
        },
        address: (geoFenceDetail.lat_long.address || geoFenceDetail.lat_long.address === '')
          ? geoFenceDetail.lat_long.address
          : geoFenceDetail.address ? geoFenceDetail.address : data.address.description,
        radius: geoFenceDetail.lat_long.radius || geoFenceDetail.radius,
        shape_type: 'Circle',
      };
    } else {
      detail = {
        ...data,
        lat_long: geoFenceDetail.lat_long,
        address: (geoFenceDetail.lat_long.address || geoFenceDetail.lat_long.address === '')
          ? geoFenceDetail.lat_long.address
          : geoFenceDetail.address ? geoFenceDetail.address : data.address.description,
        shape_type: geoFenceDetail.lat_long.type,
      };
    }

    if(this.props.associateNewGeofence){
      return this.props.addAndAssociateNewGeofence(detail);
    }

    if(selectedGeofence && selectedGeofence.id){
      return this.props.GeoFenceDucks.putGeoFence(detail)
        .then(() => {
          toast.success(MSGS.GEO_FENCE__UPDATE_SUCCESS);
          history.push({query,page:1});
          if(updateStatus) updateStatus({query,page:1});
          toggle('submit');
          getJobGeofence();
        });
    }

    if (params.id && !(selectedGeofence && selectedGeofence.id)) {
      return this.props.GeoFenceDucks.putGeoFence(detail)
        .then(() => {
          toast.success(MSGS.GEO_FENCE__UPDATE_SUCCESS);
          history.push(query.parent);
        });
    }
    return this.props.GeoFenceDucks.postGeoFence(detail)
      .then(() => {
        toast.success(MSGS.GEO_FENCE_ADD_SUCCESS);
        history.push(query.parent);
      });
  }
  
  render() {
    const { isLoading, geoFenceDetail, google, loaded, selectedGeofence, currentBrowserLocation , associateNewGeofence } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <GeoFenceFormComponent
        createOrUpdate={this.createOrUpdate}
        initialValues={((params.id || (selectedGeofence && selectedGeofence.id)) && !associateNewGeofence) ? {...geoFenceDetail, address: {description: geoFenceDetail.address, place_id: geoFenceDetail && geoFenceDetail.id ? geoFenceDetail.id : 0}} : { is_active: true }}
        geoFenceDetail={geoFenceDetail}
        setShapeInfo={this.setShapeInfo}
        google={google}
        loaded={loaded}
        selectedGeofence={selectedGeofence}
        currentBrowserLocation = {currentBrowserLocation}
        getAddressForMarker = {this.getAddressForMarker}
        associateNewGeofence = {this.props.associateNewGeofence}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  location: RouteDucks.location(state),  
  currentBrowserLocation : ActivityTrackDucks.currentBrowserLocation(state),
});

const mapActionToProps = dispatch => ({
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

GeoFenceFormSection.propTypes = {
  geoFenceDetail: PropTypes.object.isRequired,
  GeoFenceDucks: PropTypes.object.isRequired,
};

GeoFenceFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectFormToRedux = connect(
  mapStateToProps,
  mapActionToProps,
)(GeoFenceFormSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  /*version: REACT_APP_GOOGLE_API_VERSION,*/
})(connectFormToRedux);
