import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';

import styles from './styles.module.scss';

const MultiSelectAtom = (props) => {
  const {
    menuItems, onItemSelect,disabled
  } = props;
  const checkedItems = menuItems && menuItems.length && menuItems.filter(i => i.checked);
  const items = [
    { name: 'Select All', id: null, checked: checkedItems.length === menuItems.length },
    ...menuItems,
  ];

  const handleChange = (val) => {
    if (val && val.name === 'Select All') onItemSelect(val.checked);
    else onItemSelect(val);
  };

  const itemToString = i => i ? i.name : '';

  const selectStatus = (selectedItem) => {
    if (selectedItem.length === 0 && props.allFields === 'All Departments') return 'Select Department';
    if (selectedItem.length === 0 && props.allFields === 'All Teams') return 'Select Team';
    if (disabled && props.allFields === 'All Teams') return 'Select Team';
   if (selectedItem.length === 0 && props.allFields === 'All Tags') return 'Select Tags';
    if (selectedItem.length === 0 && props.allFields === 'All Jobs') return 'Select Jobs';
    if (selectedItem.length === menuItems.length && props.allFields === 'All Jobs') return 'All Jobs';
    if (selectedItem.length === menuItems.length && props.allFields === 'All Teams') return 'All Teams';
    if (selectedItem.length === menuItems.length && props.allFields === 'All Departments') return 'All Departments';
    if (selectedItem.length === menuItems.length && props.allFields === 'All Tags') return 'All Tags';
    if (selectedItem.length === menuItems.length && props.allFields === 'All Employees') return 'All Employees';
    if (selectedItem.length === menuItems.length) return 'All Branches';
    if (selectedItem.length > 0) {
      const combinedString = selectedItem
        .map((item, index) =>
          `${itemToString(item)}${index === selectedItem.length - 1 ? '' : ', '}`
        )
        .reduce((a, b) => `${a}${b}`, '');
      return combinedString;
    }
    return 'Select';
  };

  return (
    <Downshift
      itemToString={itemToString}
      onChange={handleChange}
      selectedItem={checkedItems}
      render={({
        getItemProps,
        isOpen,
        toggleMenu,
        selectedItem,
        highlightedIndex,
        getButtonProps,
      }) => (
        <div className={disabled ? `${styles['btn-grp-disabled']} btn-group multiatom-wrapper mr-2` : "btn-group multiatom-wrapper mr-2"} style={{borderRadius:'5px', width:'90%'}}>
          <button
            type="button"
            title={selectStatus(selectedItem)}
            className={props.allFields === 'All Jobs' ? `${styles['btn-text-massJob']} text-left btn btn-dropdown-white`
              :
              ((props.allFields === 'All Departments' || props.allFields === 'All Teams' || props.allFields == 'All Tags' || props.allFields == 'All Employees')
                ?
                `${styles['drop-font']} btn btn-dropdown-white`
                :
                `${styles['btn-text']} ${styles['branch-text']} btn btn-dropdown-white`
              )
            }
            onClick={toggleMenu}
            {...getButtonProps({
                disabled: disabled
            })}
          >
            {selectStatus(selectedItem)}
          </button>
          <button
            type="button"
            className={disabled ? "btn-accent-disabled btn btn-accent dropdown-toggle dropdown-toggle-split" : "btn btn-accent dropdown-toggle dropdown-toggle-split"}
            onClick={toggleMenu}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={isOpen}
            {...getButtonProps({
                disabled: disabled
            })}
          >
            <span className="sr-only">Toggle Dropdown</span>
          </button>
          {isOpen
            ? (
              <div
                style={{display: 'block'}}
                className="dropdown-menu"
              >
                {/* if there is only select all*/}
                {items.length === 1 && <span title='No Record Found' className="dropdown-item" style={{color: '#1d1e1f',backgroundColor: '#9CDAD7',}} >No Record Found</span>}
                {/*if there are one or more items(show select all)*/}
                {items.length > 1 && items.map((item, index) => {
                  let obj = {...getItemProps({item}),onClick :() => handleChange(item)};
                  return(
                  <button
                    key={item.id}
                    {...obj}
                    title={item.name}
                    className="dropdown-item"
                    style={{
                      color: '#1d1e1f',
                      backgroundColor:
                        highlightedIndex === index ? '#9CDAD7' : 'white',
                    }}
                  >
                    <input
                      type="checkbox"
                      defaultChecked={item.checked}
                      checked={item.checked}
                      className="mr-1"
                    />
                    {itemToString(item)}
                  </button>
                )})}
              </div>
            )
            : null
          }
        </div>
      )}
    />
  );
};

MultiSelectAtom.propTypes = {
  menuItems: PropTypes.array,
  onItemSelect: PropTypes.func,
};

export default MultiSelectAtom;
