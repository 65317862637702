import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Field from "redux-form/lib/Field";
import formValueSelector from "redux-form/lib/formValueSelector";
import reduxForm from "redux-form/lib/reduxForm";
import { toast } from "react-toastify";
import bindActionCreators from "redux/lib/bindActionCreators";
import * as EmployeeDucks from "ducks/employees/employee";


import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";
import { UncontrolledTooltip } from "reactstrap";
import MultiSelectAtom from "atoms/MultiSelect";
import CheckboxAtom from "atoms/Checkbox";
import AutoCompleteAtom from "atoms/AutoComplete";
import FormikMultiSelectAtom from "atoms/FormikMultiselectDropdown";

import ButtonLoaderAtom from "atoms/ButtonLoader";
import { FORM_LAYOUT } from "constants/layout";
import styles from "components/jobs/jobEmployeeEdit/styles.module.scss";

class MealEmpFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isOpen: false,
      dropdownOpen: false,
      dropThird: false,
      dropOpen: false,
      dropdownTeamOpen: false,
      dropdownSecondTeamOpen: false,
      value: { value: "", label: "Select Department" },
      valueSecond: { value: "", label: "Select Department" },
      valueThird: { value: "", label: "Select Department" },
      valueTeam: { value: "", label: "Select Team" },
      valueTeamSecond: { value: "", label: "Select Team" },
      selectedDepartments: [],
      showableTeams: [],
      selectedTeams: [],
      selectedTags: [],
      employee: null,
      employeeList:[],
      selectedEmployees: [],
      selectAllEmployees: false,
      checkedDepartment: [],
    };
    this.assignEmployee = this.assignEmployee.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.assignByDept = this.assignByDept.bind(this);
    this.assignByTeam = this.assignByTeam.bind(this);
    this.handleDepartmentCheckbox = this.handleDepartmentCheckbox.bind(this);
    this.handleTeamCheckbox = this.handleTeamCheckbox.bind(this);
    this.populateDepartments = this.populateDepartments.bind(this);
    this.assignByTag = this.assignByTag.bind(this);
    this.assignAllEmployeesToJob = this.assignAllEmployeesToJob.bind(this);
    this.handleTagsCheckbox = this.handleTagsCheckbox.bind(this);
    this.handleEmployeeChange = this.handleEmployeeChange.bind(this);
  }

  async componentDidMount() {
    this.populateDepartments(this.props.departmentAll);
    this.props.EmployeeDucks.getEmployees({ paginate: false })
    .then(() => {
      const activeEmployeesList = this.props.employeeList.results.filter(item => item.is_active); // Filter employees based on is_active status
      const employeesList = activeEmployeesList.map(item => ({
        id: item.id,
        label: item.user.full_name,
      }));
      this.setState({ employeeList: employeesList });
    })
    .catch(error => {
      // Handle the error here
      console.error("Error fetching employees:", error);
    });
  
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectedTeams, selectedDepartments, selectedTags } = this.state;
    let selectedTeam = selectedTeams.filter((item) => item.checked === true);
    let selectedDepartment = selectedDepartments.filter(
      (item) => item.checked === true
    );
    if (this.props.departmentAll !== prevProps.departmentAll) {
      this.populateDepartments(this.props.departmentAll);
    }
    let department_ids =
    selectedDepartment && selectedDepartment.length
      ? selectedDepartment.map((item) => item.id)
      : selectedDepartment.id;
  let team_ids =
    selectedTeam && selectedTeam.length
      ? selectedTeam.map((item) => item.id)
      : selectedTeam.id;
    // Fetch employees whenever selected departments, teams, or tags change
    if (
      this.state.selectedDepartments !== prevState.selectedDepartments ||
      this.state.selectedTeams !== prevState.selectedTeams ||
      this.state.selectedTags !== prevState.selectedTags
    ) {
        this.props.EmployeeDucks.getEmployees({ paginate: false,department_ids: JSON.stringify(department_ids),
          team_ids: JSON.stringify(team_ids), })
        .then(() => {
          const activeEmployeesList = this.props.employeeList.results.filter(item => item.is_active); // Filter employees based on is_active status
          const employeesList = activeEmployeesList.map(item => ({
            id: item.id,
            label: item.user.full_name,
          }));
          this.setState({ employeeList: employeesList });
        })
        .catch(error => {
          // Handle the error here
          console.error("Error fetching employees:", error);
        });
      
    }
  }

  handleTagsCheckbox(checkedItem) {
    const { selectedTags } = this.state;
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedTagsUpdated = selectedTags.map((item) => {
      if (isSelectAll) {
        return { ...item, checked: true };
      }
      if (checkedItem && !checkedItem.id) {
        return { ...item, checked: false };
      }

      if (item.id === checkedItem.id) {
        return { ...item, checked: !item.checked };
      }

      return item;
    });

    this.setState({
      selectedTags: selectedTagsUpdated,
    });
  }

  populateDepartments(departments) {
    if (!departments.length) return;

    const selectedDepartments = departments.map((item) => {
      return {
        name: item.name,
        id: item.id,
        checked: false,
        teams: item.teams,
      };
    });
    this.setState({ selectedDepartments });
  }

  handleDepartmentCheckbox(checkedItem) {
    const { selectedDepartments } = this.state;
    const { teamAll } = this.props;
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedDepartmentsUpdated = selectedDepartments.map((item) => {
      if (isSelectAll) {
        return { ...item, checked: true };
      }
      if (checkedItem && !checkedItem.id) {
        return { ...item, checked: false };
      }

      if (item.id === checkedItem.id) {
        return { ...item, checked: !item.checked };
      }

      return item;
    });

    const checkedSelectedDept = [];
    const showableTeams = [];

    selectedDepartmentsUpdated.forEach((i) => {
      if (i.checked) {
        teamAll.forEach((item) => {
          checkedSelectedDept.push(item);
          if (item.department === i.id) {
            showableTeams.push(item);
          }
        });
      }
    });

    const selectedTeams = showableTeams.map((team) => {
      return { name: team.name, id: team.id, checked: false };
    });

    this.setState({
      selectedDepartments: selectedDepartmentsUpdated,
      showableTeams,
      selectedTeams,
      checkedDepartment: checkedSelectedDept,
    });
  }

  handleTeamCheckbox(checkedItem) {
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedTeams = this.state.selectedTeams.map((team) => {
      if (isSelectAll) return { ...team, checked: true };
      if (checkedItem && !checkedItem.id) {
        return { ...team, checked: false };
      }
      if (team.id === checkedItem.id)
        return { ...team, checked: !team.checked };

      return team;
    });

    this.setState({ selectedTeams });
  }

  assignAllEmployeesToJob() {
    this.props.assignAllEmployeesToJob();
  }

  getEmployees(params = {}) {
    const { getEmployees } = this.props;
    const { selectedTeams, selectedDepartments, selectedTags } = this.state;
    let selectedTeam = selectedTeams.filter((item) => item.checked === true);
    let selectedDepartment = selectedDepartments.filter(
      (item) => item.checked === true
    );
    let department_ids =
      selectedDepartment && selectedDepartment.length
        ? selectedDepartment.map((item) => item.id)
        : selectedDepartment.id;
    let team_ids =
      selectedTeam && selectedTeam.length
        ? selectedTeam.map((item) => item.id)
        : selectedTeam.id;
    return getEmployees({
      ...params,
      department_ids: JSON.stringify(department_ids),
      team_ids: JSON.stringify(team_ids),
    });
  }

  // this function is called when assign button is clicked
  assignEmployee(data) {
    const { selectedEmployees } = this.state;
    const { assignByEmployee, change } = this.props;

    if (data.select_all_employees) {
      return this.assignAllEmployeesToJob();
    }
    return assignByEmployee(selectedEmployees).then(() => {
      change("employee", []);
    });
  }

  // This function is called when 
  assignByDept(value) {
    const departments = [];
    value.forEach((item) => {
      const newItem = {
        label: item.name,
        value: item.id,
      };
      departments.push(newItem);
    });
    const { assignByDept } = this.props;
    return assignByDept(departments).then(() =>
      this.setState({ value: { value: "", label: "Select Department" } })
    );
  }

  assignByTag(value) {
    const tags = [];
    value.forEach((item) => {
      const newItem = {
        label: item.name,
        value: item.id,
      };
      tags.push(newItem);
    });
    const { assignByTag } = this.props;
    return assignByTag(tags).then(() =>
      this.setState({ value: { value: "", label: "Select Tags" } })
    );
  }

  assignByTeam(team) {
    const { assignByTeam } = this.props;
    return assignByTeam(team).then(() =>
      this.setState({
        valueSecond: { value: "", label: "Select Department" },
        valueTeam: { value: "", label: "Select Team" },
      })
    );
  }


  handleEmployeeChange(data) {
    let employee_ids = data.map((item) => item.id);
    this.setState({ selectedEmployees: employee_ids });
  }


  render() {
    const {
      handleSubmit,
      userExtraData,
      departmentAll,
      isOrganizationVisible,
      teamAll,
      assigningEmployee,
      is_active,
    } = this.props;
   
    const {
      valueSecond,
      valueThird,
      checkedDepartment,
      employee,
      selectAllEmployees,
    } = this.state;
    const isAssignButtonDisabled = !(this.state.selectedEmployees?.length || selectAllEmployees);

    let departmentOptions = [];
    let teamFirstOptions = teamAll;
    let teamSecondOptions = teamAll;

    if (departmentAll && departmentAll.length) {
      departmentOptions =
        departmentAll &&
        departmentAll.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      if (!userExtraData.department) {
        departmentOptions.splice(0, 0, {
          value: "",
          label: "Select Department",
        });
      }
      if (valueSecond.value !== "") {
        teamFirstOptions =
          teamAll && teamAll.filter((i) => i.department === valueSecond.value);
      }
      if (valueThird.value !== "") {
        teamSecondOptions =
          teamAll && teamAll.filter((i) => i.department === valueThird.value);
      }
      teamFirstOptions =
        teamFirstOptions &&
        teamFirstOptions.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      teamSecondOptions =
        teamSecondOptions &&
        teamSecondOptions.map((item) => ({
          value: item.id,
          label: item.name,
        }));
      if (!userExtraData.team) {
        teamFirstOptions.splice(0, 0, { value: "", label: "Select Team" });
        teamSecondOptions.splice(0, 0, { value: "", label: "Select Team" });
      }
    }

    return (
      <div>
        <Row>
          {/* First Column Department*/}
          <Col md={3} className="pr-0 dept-custom-width">
            <div className="text-left">Department</div>
            {isOrganizationVisible && (
              <div className="w-100 emp-job-override">
                <MultiSelectAtom
                  menuItems={this.state.selectedDepartments}
                  allFields={"All Departments"}
                  onItemSelect={(data) => this.handleDepartmentCheckbox(data)}
                  className={styles["department-dropdown"]}
                  disabled={!is_active}
                />
              </div>
            )}
          </Col>

          {/* Second Column Team */}
          <Col className="team-custom-width">
            <div className="text-left">Team</div>
            {isOrganizationVisible && (
              <div className="w-100 task-override emp-task-override">
                <MultiSelectAtom
                  menuItems={this.state.selectedTeams}
                  allFields={"All Teams"}
                  onItemSelect={(data) => this.handleTeamCheckbox(data)}
                  disabled={
                    checkedDepartment && checkedDepartment.length ? false : true
                  }
                />
              </div>
            )}
          </Col>

          <Col md={3} className="team-custom-width">
            <div className="text-left">Tags</div>
            {isOrganizationVisible && (
              <div className="w-100 task-override emp-task-override">
                <MultiSelectAtom
                  menuItems={this.state.selectedTags}
                  allFields={"All Tags"}
                  onItemSelect={(data) => this.handleTagsCheckbox(data)}
                  disabled={!is_active}
                />
              </div>
            )}
          </Col>
          {/* Third Column Assign employees and assign button */}
          <Col className="pl-0">
            <Form
              className="d-inline"
              onSubmit={handleSubmit(this.assignEmployee)}
            >
              <Row>
                <Col md={8} className="pr-2">
                  <div className="text-left emp-custom-width pr-2">
                    Employee
                  </div>
                  <section className="emp-dropdown-override text-left w-100">

                  <FormikMultiSelectAtom
                id="employee"
                name="employee"
                items={this.state.employeeList}
                placeholder="Select Employees"
                itemToString={(i) => i && i.label}
                onChange={(data) => this.handleEmployeeChange(data)}
                disabled={!is_active}
              />

                    <Field
                      id="checkBox"
                      className={`${styles["label-text-allign"]} cursor-pointer`}
                      type="checkbox"
                      name="select_all_employees"
                      label={
                        <div className="cursor-pointer">
                          Select all employees
                          <UncontrolledTooltip
                            placement="top"
                            target="checkBox"
                          >
                            Check to select all employee of this company.
                          </UncontrolledTooltip>
                        </div>
                      }
                      component={CheckboxAtom}
                      onSelect={(name, checked) => {
                        this.setState({ selectAllEmployees: checked });
                      }}
                      layout={FORM_LAYOUT}
                      disabled={!is_active}
                    />
                  </section>
                </Col>
                <Col className="custom-assign-button pr-0 pl-0">
                  <Button
                    className="assign-button"
                    type="submit"
                    style={{ marginTop: "1.2rem", paddingTop: "6.5px" }}
                    disabled={isAssignButtonDisabled}
                  >
                    <span>Assign</span>
                    <ButtonLoaderAtom active={assigningEmployee} />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

MealEmpFilter.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  jobDetail: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTask: PropTypes.func,
  getEmployees: PropTypes.func,
  assignByEmployee: PropTypes.func,
  customerList: PropTypes.object,
  change: PropTypes.func,
  jobList: PropTypes.func,
};

MealEmpFilter.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  employeeList: EmployeeDucks.employeeList(state),
});

const mapActionToProps = (dispatch) => ({
  
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
 
});

const JobEmployee = reduxForm({
  form: "JobEmployeeForm",
  enableReinitialize: true,
})(MealEmpFilter);

export default connect(mapStateToProps, mapActionToProps)(JobEmployee);