import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as VendorDucks from 'ducks/vendors/vendor';

import styles from './styles.module.scss';

/**
 * AdminDashBoardPage -> OnBoardingSummaryListSection
 *
 * Components:
 *    - None
 *
 * State:
 *    - On Boarding Summary List
 *
 * Actions:
 *    None
 */
const OnBoardingSummaryListSection = (props) => {
  const { businessAdminStats } = props;

  return (
    <Row>
      <Col sm="12" md="12">
        <Card block className={`${styles['admindashboard--wrapper']} card-block`}>
          <Row>
            <Col sm="12" className={styles['admindashboard--title']}>
              <span>On Boarding Summary </span>
              <br/>
              <br/>
              <Row>
              <Col md={7}>
              <small className={styles['admindashboard--subtitle']}>
                Total Customers 
              </small>
              </Col>
              <Col md={5}>
              <small> 
               <span className="float-left">:</span>
                <span className="float-right">{businessAdminStats.customer_count}</span>
              </small>
                </Col>
              </Row>
              <Row>
              <Col md={7}>
              <small className={styles['admindashboard--subtitle']}>
                Total Users 
              </small>
              </Col>
              <Col md={5}>
              <small>
              <span className="float-left">:</span>
              <span className="float-right">{businessAdminStats.user_count}</span>
              </small>
              </Col>
              </Row>
              <Row>
              <Col md={7}>
              <small className={styles['admindashboard--subtitle']}>
                Avg Users/Customers
              </small>
              </Col>
              <Col md={5}>
              <small>
                <span className="float-left">:</span>
                <span className="float-right">{businessAdminStats.avg_user_per_customer}</span>
              </small>
              </Col>
            </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  businessAdminStats: VendorDucks.businessAdminStats(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

OnBoardingSummaryListSection.propTypes = {
  businessAdminStats: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(OnBoardingSummaryListSection);
