import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

import * as TaskDucks from 'ducks/jobs/task';
import * as TaskTagDucks from 'ducks/jobs/taskTag';
import * as JobTaskMappingDucks from 'ducks/jobs/jobTaskMapping';
import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import styles from './styles.module.scss';
import {exportFile} from 'helpers/utils';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterFormComponent from 'components/common/FilterForm';
import JobTaskMappingFormModalComponent from 'components/jobs/jobTaskMappingList/FormModal';
import TagModalComponent from 'components/jobs/form/TagModal';

import {  MSGS} from 'constants/jobs';


/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection
 *
 * Components:
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    postActiveStatus
 */
class JobTaskMappingFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.create = this.create.bind(this);
    this.goToUnassignedTasks = this.goToUnassignedTasks.bind(this);
    this.createTag = this.createTag.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.exportTasks = this.exportTasks.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = { isOpen: false, isSubmit: false, isTagModal: false , isExporting : false, isModalHidden:false};
  }

  componentWillMount(){
    this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
  }

  exportTasks(){
    const { location , selectedTasks } = this.props;
    const {query} = location;
    this.setState({isExporting : true});
    return this.props.TaskDucks.exportTask({...query, task_ids: selectedTasks && JSON.stringify(selectedTasks)})
    .then((data) => { 
      exportFile(data , `Tasks.csv`); 
      this.setState({isExporting : false});
      this.props.resetSelectedTasks();
    }) 
    .catch(() => {
      this.setState({isExporting : false});
      this.props.resetSelectedTasks();
    });
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  getUpdatedList(){
    this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  createTag(data) {
    this.setState({ isTagLoading: true });
    return this.props.TaskTagDucks.postTaskTag({ ...data, tag_type: 1 })
  }

  create(data) {
    const { isOpen } = this.state;
    const { location } = this.props;
    const {query} = location;
    const pageSize = cookie.load('task_mapping_size');
    return this.props.TaskDucks.postTask(data)
      .then(() => {
        this.props.TaskDucks.getTasks({ ...query, page_size: pageSize })
        this.setState({ isOpen: !isOpen });
      });
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  goToUnassignedTasks() {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const { page, ...rest } = query;
    history.push({
      pathname,
      search: queryString.stringify({ ...rest, assigned: false }),
    });
  }

  updateActiveStatus(status) {
    const pageSize = cookie.load('task_mapping_size');
    const { taskSelectedList, location: { query } } = this.props;
    this.setState({ isSubmit: true });
    return this.props.JobTaskMappingDucks.putJobTaskMapping({ id: query.job_id, task_ids: taskSelectedList, assigned: status })
      .then(() => {
        this.setState({ isSubmit: false });
        if(status) toast.success(MSGS.JOB_TASK_ASSIGN);
        else toast.success(MSGS.JOB_TASK_UNASSIGN);
        this.props.TaskDucks.getTasks({ ...query, page_size: pageSize })
      });
  }

  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }

  render() {
    const {
      taskSelectedList,
      location,
      isLoading,
      taskTagList,
    } = this.props;
    const { query } = location;
    const { isOpen, isSubmit,isTagLoading, isTagModal , isExporting, isModalHidden} = this.state;
    const tags = taskTagList&& taskTagList.length && taskTagList[0].tag_collection && taskTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    return (
      <Row className={query.job_id ? "filter ml-3 mr-4" : "filter d-flex justify-content-end ml-3 mr-4"}>
        <Col md="5" className="pl-0">
          {(!query.job_id || (query.job_id && query.assigned === '')) &&
            <div>
              <Button
                color="primary"
                id="create_task"
                className={`${styles.custom_button} pr-3 pl-3`}
                onClick={() => this.toggle()}
              >
              Create New Task
              </Button>
            </div>
          }
        </Col>
        <Col md="7" className="pr-0">
            <Button
              color="primary"
              className="align-top float-right ml-2"
              onClick={() => this.goTo({
                pathname: '/job/task/bulk-upload',
                search: queryString.stringify({
                  parent: JSON.stringify(query),
                })
              })}
              style={{marginBottom: '10px',marginRight: '10px'}}
            >
              Bulk Upload
            </Button>
          <Button   className='float-right d-inline-block pr-3 pl-3 ml-3' onClick={()=> this.exportTasks()}>Export <ButtonLoaderAtom className="ml-1" active={isExporting} /></Button>
          <section className="float-right task-search d-inline-block custom-task-filter">
            <FilterFormComponent
              location={location}
              placeholder="Search by name,code and tag"
              initialValues={query}
            />
          </section>
          <section className="float-right">
            {query.assigned === 'false' &&
              <div>
                <Button
                  color="accent"
                  disabled={!taskSelectedList.length || isSubmit|| isLoading }
                  onClick={() => this.updateActiveStatus(true)}
                >
                  <div id ="assigned">
                    Assign <ButtonLoaderAtom className="ml-1" active={isSubmit} />
                    <UncontrolledTooltip placement="top" target="assigned">
                      Assign
                    </UncontrolledTooltip>
                  </div>
                </Button>
              </div>
            }
            {query.assigned === 'true' &&
              <div>
                {query.view === "true"
                  ? null
                  :<Button
                    color="accent"
                    disabled={!taskSelectedList.length || isLoading}
                    onClick={() => this.updateActiveStatus(false)}
                  >
                    <div id ="task_unassign">
                      UnAssign
                      <UncontrolledTooltip placement="top" target="task_unassign">
                        Unassign
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                }
              </div>
            }
            {isTagModal &&
              <TagModalComponent
                isModalOpen={isTagModal}
                toggle={this.toggleTagModal}
                createTag={this.createTag}
                tagsList={tags}
                tagsCollection={taskTagList}
                deleteSelectedTags = {this.deleteSelectedTags}
                updateTagName={this.updateTagName}
                getUpdatedList={this.getUpdatedList}
                hideModal={this.hideModal}                
              />
            }
            {isOpen &&
              <JobTaskMappingFormModalComponent
                isOpen={isOpen}
                toggle={this.toggle}
                createTag={this.createTag}
                isTagLoading={isTagLoading}
                create={this.create}
                tagList ={taskTagList}
                toggleTagModal={this.toggleTagModal}
                isModalHidden={isModalHidden}
              />
            }
          </section>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  taskSelectedList: TaskDucks.taskSelectedList(state),
  location: RouteDucks.location(state),
  taskList: TaskDucks.taskList(state),
  taskAll: TaskDucks.taskAll(state),
  taskTagList: TaskTagDucks.taskTagList(state),
});

const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobTaskMappingDucks: bindActionCreators(JobTaskMappingDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobTaskMappingFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  TaskDucks: PropTypes.object.isRequired,
  JobTaskMappingDucks: PropTypes.object.isRequired,
  taskSelectedList: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  isLoading: PropTypes.bool.isRequired,
};

JobTaskMappingFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobTaskMappingFilterSection);
