export const MSGS = {
  VENDOR_UPDATE_SUCCESS: "Updated successfully",
  VENDOR_PHONE_UPDATE_SUCCESS: "Phone number updated successfully",
  VENDOR_ADD_SUCCESS: "Vendor added successfully",
  BASIC_SETTING_UPDATE_SUCCESS: "Updated successfully",
  SYSTEM_SETTING_UPDATE_SUCCESS: "Updated successfully",
  NOTIFICATION_SETTING_UPDATE_SUCCESS: "Updated successfully",
  VENDOR_ROLE_UPDATE_SUCCESS: "Updated successfully",
  VENDOR_ROLE_ADD_SUCCESS: "Role added successfully",
  VENDOR_ROLE_DELETE_SUCCESS: "Role deleted successfully",
  CHANGE_PASSWORD_SUCCESS: "Password updated successfully",
  VENDOR_DEPARTMENT_ADD_SUCCESS: "Department added successfully",
  VENDOR_DEPARTMENT_UPDATE_SUCCESS: "Department updated successfully",
  VENDOR_DEPARTMENT_DELETE_SUCCESS: "Department deleted successfully",
  VENDOR_TEAM_ADD_SUCCESS: "Team added successfully",
  VENDOR_TEAM_UPDATE_SUCCESS: "Team updated successfully",
  PLUGIN_SETTING_UPDATE_SUCCESS: "Plugin settings updated successfully",
  SYNC_LOADING_MESSAGE: "This may take few minutes to load the suggested mapping list for the first time while the sync process is initiated",
};

export default MSGS;
