import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_TAGS = 'jobs/tag/GET_TAGS';
const GET_TAGS_FULFILLED = 'jobs/tag/GET_TAGS_FULFILLED';
const POST_TAG = 'jobs/tag/POST_TAG';
const POST_TAG_FULFILLED = 'jobs/tag/POST_TAG_FULFILLED';

export function getJobTags(params) {
  return {
    type: GET_TAGS,
    payload: axios.get(`${URLS.JOB}${URLS.TAG}`, { params }),
  };
}

export function postJobTag(data) {
  return {
    type: POST_TAG,
    payload: axios.post(`${URLS.JOB}${URLS.TAG}`, data),
  };
}

const defaultState = {
  list: { results: [] } ,
  detail: {},
};

function GET_TAGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function POST_TAG_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_TAGS_FULFILLED]: GET_TAGS_FULFILLED_REDUCER,
  [POST_TAG_FULFILLED]: POST_TAG_FULFILLED_REDUCER,
};


const tagSelector = state => state.jobs.tag;

export const jobTagList = createSelector(
  tagSelector,
  instance => instance.list,
);

export const tagDetail = createSelector(
  tagSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
