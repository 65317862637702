import React from 'react';

import moment from 'moment';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { UncontrolledTooltip } from 'reactstrap';
import formValueSelector from 'redux-form/lib/formValueSelector';

import Flatpickr from 'react-flatpickr';

import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import DateTimePickerAtom from 'atoms/DateTimePicker';
import SelectAtom from 'atoms/Select';
import EditableCellAtom from 'atoms/EditableCell';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';


class AllocateMealModalContent extends React.PureComponent {
  constructor(props) {
    super(props);
  }


  render() {
    const { handleSubmit, isOpen, timeFormat24Hrs, dateFormat, toggle, timeFormat , submitMealLoader , initialValues, browserParseDate} = this.props;
    return (
      <div className={this.props.addNewActivity ? styles["addNewActivity-form-width"] : null}>
        <div className={` mt-2 mb-2 text-center`}> Specify date and time to the meal: </div>
          <Row>
            <Col md={4} className='pr-0'>
              <Field
                id="start_datetime"
                name="start_datetime"
                placeholder="Start Date"
                component={DateTimePickerAtom}
                options={{
                  dateFormat: `${dateFormat}`,
                  enableTime: false,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  /*noCalendar: !selectedShift.session_split,*/
                  enable: [
                    // Enable the dates b/w payroll startDate & endDate
                    // Also no future date selection
                    (date) => /*moment(date).isAfter(moment(selectedShift.start_datetime).subtract(1, 'day')) &&
                      moment(date).isBefore(moment(selectedShift.end_datetime)) &&*/
                      moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                  ],
                }}
                newLayout
            />
            </Col>
            <Col md={2} className='pr-1 pl-1'>
              <Field
                name='start_time'
                id='start_time'
                valueKey="start_time"
                editable={true}
                component={EditableCellAtom}
                validateInput={this.props.validateInput}
                update={this.props.update}
                values={initialValues.start_time}
              />
            </Col>
            <Col md={4} className='pr-2 pl-0'>
              <Field
                id="end_datetime"
                name="end_datetime"
                placeholder="End Date"
                component={DateTimePickerAtom}
                options={{
                  dateFormat: `${dateFormat}`,
                  enableTime: false,
                  minuteIncrement: 1,
                  /*noCalendar: !selectedShift.session_split,*/
                  time_24hr: timeFormat24Hrs,
                  enable: [
                    // Enable the dates b/w payroll startDate & endDate
                    // Also no future date selection
                    (date) => /*moment(date).isAfter(moment(selectedShift.start_datetime).subtract(1, 'day')) &&
                      moment(date).isSameOrBefore(moment(selectedShift.end_datetime)) &&*/
                      moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                  ],
                }}
                newLayout
            />
            </Col>
            <Col md={2} className='pr-1 pl-0'>
              <Field
                name='end_time'
                valueKey="end_time"
                id='end_time'
                editable={true}
                component={EditableCellAtom}
                validateInput={this.props.validateInput}
                values={initialValues.end_time}
                update={this.props.update}
              />
            </Col>
          </Row>
        </div>
    );
  }
};

AllocateMealModalContent.propTypes = {
  saveTask: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
};

export default AllocateMealModalContent;