import React from 'react';

import Field from 'redux-form/lib/Field';
import Table from 'reactstrap/lib/Table';

import moment from 'moment-timezone';

import DeleteIcon from 'atoms/DeleteIcon';
import EditIcon from 'atoms/EditIcon';

import styles from './styles.module.scss';
import SectionLoaderAtom from 'atoms/SectionLoader';

const AdvancePayrollTableComponent = (props) => {
  const { showPayrollDetails, advancedPayrollSettingList , showPastRecordsLoader , dateFormatDisplay } = props;
  if(showPastRecordsLoader) return <SectionLoaderAtom active/>;
  return (
    <div className="mr-4 mt-0 pb-0">
      <Table hover responsive size="sm">
        <thead className="Header-col">
          <tr>
            <th width="30%" className="pl-4">NAME</th>
            <th>START DATE</th>
            <th>END DATE</th>
            <th>IS ACTIVE?</th>
            <th width="10%" className="text-center">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {advancedPayrollSettingList && advancedPayrollSettingList.length ? advancedPayrollSettingList.map((item) => (
            <tr>
              <td title={item.name} className={`${styles['name-td-width']} pl-4`}>{item.name}</td>
              <td title={item.start_date}>{item.is_default ? '' : item.start_date}</td>
              <td title={item.end_date}>{item.end_date}</td>
              <td title={item.is_active ? 'Active' : 'Inactive'}>{item.is_active ? 'Active' : 'Inactive'}</td>
              <td className="text-center">
                <EditIcon
                  className={`${styles['icon-btn']} mb-0 mr-2`}
                  width={15} height={20}
                  onClick={()=>showPayrollDetails('edit', item.id)} 
                />
              </td>
            </tr>
          )) :
            <tr><td colSpan="5" className="text-center">No Records Found</td></tr>}
        </tbody>
      </Table>
    </div>
  )
};


export default AdvancePayrollTableComponent;