import React from 'react';

import PropTypes from 'prop-types';
import {ReactComponent as EyeOpenIcon} from './eye-open.svg';

const EyeOpenIconAtom = props => (
  <EyeOpenIcon {...props} />
);

EyeOpenIconAtom.defaultProps = {
  height: 15,
  width: 15,
};

EyeOpenIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default EyeOpenIconAtom;
