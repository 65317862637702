export const NAV_DROPDOWN = {
  USER: [
    { href: '/accounts/profile', label: 'Edit My Profile' },
    { href: '/accounts/change-password', label: 'Change My Password' },
  ],
  COMPANY: [
    { href: '/vendor/plan', label: 'Billing', manage_settings: true },
    { href: '/vendor/profile', label: 'Edit Company Profile', company_owner: true },
    { href: '/payroll/setting/list', label: 'Payroll Settings', manage_payroll: true },
    { href: '/vendor/basic-setting', label: 'System Settings', manage_settings: true },
    { href: '/vendor/role-setting', label: 'Organization Settings', manage_settings: true },
    { href: '/vendor/plugin', label: 'Plugins', plugin_setting: true},
    { href: '/pto-policy/list', label:'Paid Time Off Setting', pto_settings: true },
  ],
  SUB_COMPANY: [
    { href: '/vendor/profile', label: 'Edit Company Profile', sub_company_owner: true },
    { href: '/payroll/setting/list', label: 'Payroll Settings', manage_payroll: true },
    { href: '/vendor/basic-setting', label: 'System Settings', manage_settings: true },
    { href: '/vendor/role-setting', label: 'Organization Settings', manage_settings: true },
    { href: '/vendor/plugin', label: 'Plugins', plugin_setting: true},
    { href: '/pto-policy/list', label:'Paid Time Off Setting', pto_settings: true },
  ],
  ADMIN: [
    { href: '/business-admin/basic-setting', label: 'Settings' },
  ],
};

export const SORT_DIRS = {
  ASC: '',
  DESC: '-',
};

export const SORT_ORDERS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const DATE_FORMAT_MAPPINGS = {
  'MM/DD/YYYY': 'm/d/Y',
  'DD/MM/YYYY': 'd/m/Y',
  'YYYY/MM/DD': 'Y/m/d',
  'YYYY/DD/MM': 'Y/d/m',
  'MMMM DD, YYYY': 'F d, Y',
  'MMM DD, YYYY': 'M d, Y',
  'ddd, DD MMM YYYY': 'D, d M Y',
};

export const PAGE_COUNT = [
  {label:"10/page" , value: 10},
  {label:"20/page" , value: 20},
  {label:"50/page" , value: 50},
  {label:"100/page" , value: 100},
];

export const PAGE_SIZE = 10;

export default {
  NAV_DROPDOWN,
  SORT_DIRS,
  SORT_ORDERS,
  DATE_FORMAT_MAPPINGS,
  PAGE_SIZE,
};
