import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as RouteDucks from 'ducks/routes';

import ADPRedirect from 'sections/vendors/pluginSettingsSetupList/adpRedirect';
import QBORedirect from 'sections/vendors/pluginSettingsSetupList/qboRedirect';
import PluginSettingSetupListSection from 'sections/vendors/pluginSettingsSetupList/list';

/**
 * PluginSettingsSetupListPage
 *
 *
 * Sections:
 *    - {@link PluginSettingSetupListSection}
 *
*/

class PluginSettingsSetupListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }
  
  render() {
    const { location:{ pathname } } = this.props;
    return (
      <div>
        <section>
          {pathname === '/vendor/plugin/install/Quickbooks' &&
            <QBORedirect  />
          }
          {pathname === '/vendor/plugin/install/Adp' &&
            <ADPRedirect  />
          }
          {pathname === '/vendor/plugin/uninstall/Quickbooks' &&
            <PluginSettingSetupListSection />
          } 
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

PluginSettingsSetupListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PluginSettingsSetupListPage);