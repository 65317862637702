import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const OvertimeDetailTableComponent = (props) => {
  const { data, location: { query }, pageSize } = props;
  const { page = 1 } = query;

  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table right-align-table right-align-col">
      <thead className="Header-col">
        <tr>
          <th className="pl-3">Employee Name</th>
          <SortHeaderWithSeparateKeysComponent field='"timecards_dailytimecard"."date"'>
            Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"timecards_dailytimecard"."regular_time"'>
            Regular Hours
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"timecards_dailytimecard"."over_time"'>
            Overtime Hours
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"timecards_dailytimecard"."double_over_time"'>
            Double Overtime Hours
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td className="pl-3" title={`${item.first_name} ${item.last_name}`}>
                {item.first_name} {item.last_name}
              </td>
              <td title={item.date}>{item.date}</td>
              <td title={item.regular_time} className="text-right">{item.regular_time}</td>
              <td title={item.over_time} className="text-right">{item.over_time}</td>
              <td title={item.double_over_time} className="text-right pr-3">{item.double_over_time}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

OvertimeDetailTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default OvertimeDetailTableComponent;