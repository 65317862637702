import React from 'react';
import {connect} from 'react-redux';

import UserAPIGateway from 'accounts/gateways/user';
import RegisterUseCase from 'accounts/usecases/register';
import RegisterForm from 'accounts/components/register';
import ContentLayout from 'layouts/Content';
import { Row, Col } from 'reactstrap';

class RegisterPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.listenRegisterInUseCase = this.listenRegisterInUseCase.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new RegisterUseCase(userAPIGateway);
  }

  componentWillMount() {
    this.listenRegisterInUseCase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenRegisterInUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'REGISTER_SUCCESS':
          this.props.history.push('/accounts/activate-user');
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    return (
      <ContentLayout>
        <Row className="content justify-center pb-5">
          <Col xs={12} md={{ size:6}} className="pv4" >
            <RegisterForm data={this.state} usecase={this.usecase} />
          </Col>
        </Row>
      </ContentLayout>
    );
  }

}

const mapStateToProps = (state) => ({
  // userProfile: UserDucks.profile(state)
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPage);
