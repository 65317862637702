import React from 'react';

import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import moment from 'moment';

import CheckboxAtom from 'atoms/Checkbox';

import { FORM_LAYOUT } from 'constants/layout';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import JobSyncIcon from './jobs.png';
import TimecardIcon from './timecard.png';
import EmployeesIcon from './employees.png';
import TasksIcon from './tasks.png';
import PayrollsIcon from './payrolls.png';
import HelpIcon from './help.png';
import styles from './styles.module.scss';

/**
 * BasicSettingFormPage -> BasicSettingFormSection -> BasicSettingFormComponent
 *
 * Props:
 *    - update
 */

class PluginSettingFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSyncEmployeeChange = this.handleSyncEmployeeChange.bind(this);
    this.start = moment().format('YYYY-MM-DD');
    this.end = moment().add(1,'day').format('YYYY-MM-DD');
  }

  submit(data){
    const { updatePluginSetting } = this.props;
    return updatePluginSetting(data)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  handleClick(tab, query){
    const { router: { history } }= this.context;
    return history.push(`/vendor/plugin/settings/Quickbooks/${tab}?${query}`);
  }

  handleSyncEmployeeChange(val){
    const { change } = this.props;
    if(!val){
      change('timecard_sync', null);
      change('job_sync', null);
    }
  }

  render() {
    const { handleSubmit, submitting ,submitFlag,
      initialValues,syncEmployee , toggleModal, source, location: { query } } = this.props;
    const { router: { history } }= this.context;

    return (
      <Form onSubmit={handleSubmit(this.submit)} >
          <Row>
              <Col className="text-right mb-2">
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => {source==='ADP' ?
                    history.push('/vendor/plugin/adp-desktop')
                    :
                    history.push({
                    pathname: '/vendor/plugin/quickbooks-desktop',
                    search: queryString.stringify(
                      { parent: '/vendor/plugin/settings/Quickbooks/employee',
                        query: JSON.stringify({mapped: true})
                      }
                    ),
                  })}}
                  className={styles['link-button']}
                >
                  Click here for instructions
                </div>
              </Col>
          </Row>
        <Row>
          <Col sm="11">
            <h5 className="mb-4">
              What all information would you like to flow into {source==='ADP' ? 'ADP?' : 'QuickBooks?'}
            </h5>
          </Col>
          <Col sm="1">
            {source!= 'ADP' &&
              <div
                onClick={() => toggleModal()}
                role="button"
                tabIndex="0"
                className={`${styles['qb-form__help']} cursor-pointer text-right`}
              >
                <img src={HelpIcon} height="35" alt="help" id="helpText" />
                <UncontrolledTooltip placement="left" target="helpText">
                  Help
                </UncontrolledTooltip>
              </div>
            }
          </Col>
          
          { source !== 'QBO' ?
          <Col sm="7">
            <div className={styles['qb-form__input']}>
              <Field
                component={CheckboxAtom}
                id="payroll_sync"
                name="payroll_sync"
                type="checkbox"
                disabled={submitFlag}
              />
            </div>
            <img
              height="40"
              src={PayrollsIcon}
              className={styles['qb-form__icon']}
              alt="Icon"
            />
          <h6 className={styles['qb-form__label-text']}>Sync Payroll Items</h6>
          </Col>
          : null }
          {initialValues.payroll_sync ?
            <Col sm="5" className="text-right">
              <div
                className={styles['qb-form__box--success']}
                tabIndex="0"
                onClick={() => this.handleClick('Payroll_items', 'synced=true')}
                role="button"
              >
                <h3>{initialValues.sync_payroll_count
                  ? initialValues.sync_payroll_count
                  :0}<small>payroll items</small></h3>
                <p className="mb-0">sync<br/>SUCCESSFUL</p>
              </div>

              <div
                className={styles['qb-form__box--failure']}
                tabIndex="0"
                onClick={() => this.handleClick('Payroll_items', 'synced=false')}
                role="button"
              >
                <h3>{initialValues.to_be_sync_payroll_count
                  ? initialValues.to_be_sync_payroll_count
                  :0}<small>payroll items</small></h3>
                <p className="mb-0">TO BE<br/>SYNC</p>
              </div>
            </Col>
            : null
          }
          <Col sm="7">
            <div className={styles['qb-form__input']}>
              <Field
                component={CheckboxAtom}
                id="employee_sync"
                name="employee_sync"
                label=""
                type="checkbox"
                disabled={submitFlag}
                onChange={(e,val) => this.handleSyncEmployeeChange(val)}
              />
            </div>
            <img
              height="40"
              src={EmployeesIcon}
              className={styles['qb-form__icon']}
              alt="Icon"
            />
            <h6 className={styles['qb-form__label-text']}>Sync Employees ( To Worksana )</h6>

            {/* {syncEmployee ?
              <div className={`${styles['qb-form__radio']} qb-radio`}>
                <Field
                  component={RadioButtonAtom}
                  name="metc_to_plugin"
                  options={options}
                  disabled={submitFlag}
                  onSelect={handlePluginSettingChange}
                />
              </div>
              : null
            }
            */ }
          </Col>

          {initialValues.employee_sync ?
            <Col sm="5" className="text-right">
              <div
                className={styles['qb-form__box--success']}
                tabIndex="0"
                onClick={() => this.handleClick('employee', 'mapped=true')}
                role="button"
              >
                <h3>{initialValues.sync_employee_count
                  ? initialValues.sync_employee_count
                  :0}<small>employees</small></h3>
                <p className="mb-0">sync<br/>SUCCESSFUL</p>
              </div>

              <div
                className={styles['qb-form__box--failure']}
                tabIndex="0"
                onClick={() => this.handleClick('employee', 'mapped=false')}
                role="button"
              >
                <h3>{initialValues.to_be_sync_employee_count
                  ? initialValues.to_be_sync_employee_count
                  :0}<small>employees</small></h3>
                <p className="mb-0">TO BE<br/>SYNC</p>
              </div>
            </Col>
            : null
          }

          <Col sm="7">            
              <div className={styles['qb-form__input']}>
                <Field
                  component={CheckboxAtom}
                  id="timecard_sync"
                  name="timecard_sync"
                  label=""
                  type="checkbox"
                  disabled={!syncEmployee || submitFlag}
                  // onSelect={handlePluginSettingChange}
                />
              </div>
              <img
                height="40"
                src={TimecardIcon}
                className={styles['qb-form__icon']}
                alt="Icon"
              />
              <h6 className={styles['qb-form__label-text']}>Sync Timecards</h6>
          </Col>
          {initialValues.timecard_sync ?
            <Col sm="5" className="text-right">
              <div
                className={styles['qb-form__box--success']}
                tabIndex="0"
                onClick={() => this.handleClick('timecard', `end=${this.end}&start=${this.start}&synced=true`)}
                role="button"
              >
                <h3>{initialValues.sync_timecard_count
                  ? initialValues.sync_timecard_count
                  :0}<small>timecards</small></h3>
                <p className="mb-0">sync<br/>SUCCESSFUL</p>
              </div>

              <div
                className={styles['qb-form__box--failure']}
                tabIndex="0"
                onClick={() => this.handleClick('timecard', `end=${this.end}&start=${this.start}&synced=false`)}
                role="button"
              >
                <h3>{initialValues.to_be_sync_timecard_count
                  ? initialValues.to_be_sync_timecard_count
                  :0}<small>timecards</small></h3>
                <p className="mb-0">TO BE<br/>SYNC</p>
              </div>
            </Col>
            : null
          }
        </Row>

        {source!= 'ADP' &&
          <Row>
            <Col sm="7">
              <div className={styles['qb-form__input']}>
                <Field
                  component={CheckboxAtom}
                  id="job_sync"
                  name="job_sync"
                  type="checkbox"
                  disabled={!syncEmployee || submitFlag}
                />
              </div>
              <img
                height="40"
                src={JobSyncIcon}
                className={styles['qb-form__icon']}
                alt="Icon"
              />
            <h6 className={styles['qb-form__label-text']}>Sync Jobs</h6>
            </Col>
            {initialValues.job_sync ?
              <Col sm="5" className="text-right">
                <div
                  className={styles['qb-form__box--success']}
                  tabIndex="0"
                  onClick={() => this.handleClick('jobs', 'synced=true')}
                  role="button"
                >
                  <h3>{initialValues.sync_job_count
                    ? initialValues.sync_job_count
                    :0}<small>Jobs</small></h3>
                  <p className="mb-0">sync<br/>SUCCESSFUL</p>
                </div>

                <div
                  className={styles['qb-form__box--failure']}
                  tabIndex="0"
                  onClick={() => this.handleClick('jobs', 'synced=false')}
                  role="button"
                >
                  <h3>{initialValues.to_be_sync_job_count
                    ? initialValues.to_be_sync_job_count
                    :0}<small>Jobs</small></h3>
                  <p className="mb-0">TO BE<br/>SYNC</p>
                </div>
              </Col>
              : null
            }
          </Row>
        }
        {source!= 'ADP' && 
          <Row>
            <Col sm="7">
              <div className={styles['qb-form__input']}>
                <Field
                  component={CheckboxAtom}
                  id="task_sync"
                  name="task_sync"
                  type="checkbox"
                  disabled={!syncEmployee || submitFlag}
                />
              </div>
              <img
                height="40"
                src={TasksIcon}
                className={styles['qb-form__icon']}
                alt="Icon"
              />
            <h6 className={styles['qb-form__label-text']}>Sync Tasks</h6>
            </Col>
            {initialValues.task_sync ?
              <Col sm="5" className="text-right">
                <div
                  className={styles['qb-form__box--success']}
                  tabIndex="0"
                  onClick={() => this.handleClick('tasks', 'synced=true')}
                  role="button"
                >
                  <h3>{initialValues.sync_task_count
                    ? initialValues.sync_task_count
                    :0}<small>Tasks</small></h3>
                  <p className="mb-0">sync<br/>SUCCESSFUL</p>
                </div>

                <div
                  className={styles['qb-form__box--failure']}
                  tabIndex="0"
                  onClick={() => this.handleClick('tasks', 'synced=false')}
                  role="button"
                >
                  <h3>{initialValues.to_be_sync_task_count
                    ? initialValues.to_be_sync_task_count
                    :0}<small>Tasks</small></h3>
                  <p className="mb-0">TO BE<br/>SYNC</p>
                </div>
              </Col>
              : null
            }
          </Row>
        }
        <div >
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size} className="mt-5">
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </div>
      </Form>
    );
  }
}


PluginSettingFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

PluginSettingFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const pluginSettingForm = reduxForm({
  form: 'PluginSettingForm',
  enableReinitialize: true,
})(PluginSettingFormComponent);

const selector = formValueSelector('PluginSettingForm');

export default connect(
  (state) => {
    const syncEmployee = selector(state, 'employee_sync');
    return { syncEmployee};
  },
)(pluginSettingForm);
