import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { UncontrolledTooltip } from 'reactstrap';

import styles from './styles.module.scss';

class TimecardOverlayModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen, toggle, timecardItem, dateFormat, timecardDecimalView } = this.props;
    const { location: { pathname, search } } = this.props;
    return (
     <div>
       <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="modal-lg"
          backdrop="static"
        >
        <ModalHeader toggle={toggle}>
          Timecard
        </ModalHeader>
        <ModalBody className="pl-3 ml-3 mr-3 pr-3 pb-5">
          <div className={styles['overlay-body']}>
            <div className={styles['date-row']}>Date : {timecardItem.date? timecardItem.date : `${moment(queryString.parse(search).start_date).format(`${dateFormat}`)} - ${moment(queryString.parse(search).end_date).format(`${dateFormat}`)}`}</div>
            <div className={styles['job-row']}>
              {timecardItem.job_name ? 
                <div className={styles['jobname-row']}>
                <span className={styles['jobname-heading']}>Job Name :</span>
                <span id="job-name" className={styles['jobname']}>{timecardItem.job_name}</span>
                <UncontrolledTooltip placement="bottom" target="job-name">
                  {timecardItem.job_name}
                </UncontrolledTooltip>
                </div>
                : null
              }
            </div>
            <Row className="ml-3 mr-4" style={{padding:'10px 5px 15px', 'borderBottom':'1px solid rgba(0,0,0,0.2)'}}>
              <Col>
                Start Time: {timecardItem.start_datetime ? timecardItem.start_datetime : moment(queryString.parse(search).start_date).format(`${dateFormat}`)}
              </Col>
              <Col>
                End Time: {timecardItem.end_datetime ? timecardItem.end_datetime : moment(queryString.parse(search).end_date).format(`${dateFormat}`)}
              </Col>
            </Row>
            <Row className="ml-3 mr-4" style={{padding:'16px 5px 20px'}}>
              <Col>
                RT - {!timecardDecimalView ? timecardItem.regular_time : `${timecardItem.regular_time}H`}
              </Col>
              <Col>
                OT - {!timecardDecimalView ? timecardItem.over_time : `${timecardItem.over_time}H`}
              </Col>
              <Col>
                DOT - {!timecardDecimalView ? timecardItem.double_over_time : `${timecardItem.double_over_time}H`}
              </Col>
            </Row>
          </div>
        </ModalBody>
        </Modal>
     </div>
    );
  }
};


TimecardOverlayModalComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TimecardOverlayModalComponent;
