import StorageGateway from 'lib/storage-gateway';
import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import { server } from 'helpers/config';
import cookie from 'react-cookies';

const EVENT_TYPES = {
  SHOW_ACCEPT_BUTTON_LOADER: 'SHOW_ACCEPT_BUTTON_LOADER',
  SHOW_DECLINE_BUTTON_LOADER: 'SHOW_DECLINE_BUTTON_LOADER',
  TERMS_CONDITION_SUCCESS: 'TERMS_CONDITION_SUCCESS',
  TERMS_CONDITION_FAILURE: 'TERMS_CONDITION_FAILURE',
  HIDE_BUTTON_LOADER: 'HIDE_BUTTON_LOADER',
  CTA_SUCCESS : 'CTA_SUCCESS',
  CTA_FAILURE : 'CTA_FAILURE'
};



class TermsConditionUseCase {

  userAPIGateway = null;


  constructor(userAPIGateway) {
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'TermsConditionUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  backToMyDashboard(isLoggedInAdmin, isSubCompany){
    if (isLoggedInAdmin && isSubCompany) {
      let domain = window.location.hostname;
      const host = window.location.hostname.split('.');
      if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
      StorageGateway.set('company_id', isSubCompany);
      cookie.save('company_id', isSubCompany, { path: '/' , domain});
    }
    else StorageGateway.remove('company_id', { path: '/' });
   
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 100);
  }

  async ctaExecution(data) {
    if(data == 'tc_agree'){
      this.eventEmitter.emit({
        type: EVENT_TYPES.SHOW_ACCEPT_BUTTON_LOADER,
      });
    }
    else{
      this.eventEmitter.emit({
        type: EVENT_TYPES.SHOW_DECLINE_BUTTON_LOADER,
      }); 
    }
    try {
      this.termsAndCondition = await this.userAPIGateway.executeCTA(data);
      this.eventEmitter.emit({
        type: EVENT_TYPES.CTA_SUCCESS 
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_BUTTON_LOADER,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.CTA_FAILURE 
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_BUTTON_LOADER,
      });
    }
  }
}
export default TermsConditionUseCase;