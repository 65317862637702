import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import queryString from 'query-string';

import Table from 'reactstrap/lib/Table';

import DeleteIconAtom from 'atoms/DeleteIcon';
import EditIconAtom from 'atoms/EditIcon';
import ViewIconAtom from 'atoms/ViewIcon';

import DragIcon from 'components/vendors/Drag.png';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * RoleListPage -> RoleListSection -> RoleListTableComponent
 *
 * Props:
 *    - Role list
 */

class RoleListTableComponent extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      sortedArray: this.props.data.results && this.props.data.results,
    }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  //This function calls after droping the item at destination index.
  onDragEnd(result){
    const { destination, source } = result;
    const {rolesIds} = this.props;
    const {sortedArray} = this.state;
    
    // return true if item was dropped outside
    if (!destination) return true;
    
    // return true if the item was dropped in the same place
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return true;
    }

    const results = Object.assign([], sortedArray);
    const item = sortedArray[source.index]; //save the draggable item in item constant
    results.splice(source.index, 1); //remove the source item from the existing index.
    results.splice(destination.index, 0, item); //add the source item at destination index.
    this.setState({
      sortedArray: results
    });

    const selectedItemPk = sortedArray[source.index] && sortedArray[source.index].id;
    const selectedItemIndex = rolesIds.indexOf(selectedItemPk);
    let updatedArray = rolesIds;
    const difference = destination.index - source.index;
    updatedArray.splice(selectedItemIndex, 1);
    updatedArray.splice(selectedItemIndex + difference, 0, selectedItemPk ) 

    updatedArray && updatedArray.length && this.props.saveRoleOrderPreference(updatedArray)
    .then(() => this.props.getRoleIds());
  }

  render(){
    const { sortedArray } = this.state;
    const { data, deleteRoleData, location: { pathname , search }  } = this.props;
    return(
      <section className="ml-3 mr-4">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Table hover responsive size="sm" id="table">
            <thead className="Header-col">
              <th width="5%" className="text-center" width="5%">&nbsp;</th>
              <th className="table-col-size text-center action-col-width">Action</th>
              <th className="table-col-size">Role</th>
              <th>Description</th>
            </thead>
            <Droppable droppableId="table">
              {(provided, snapshot) => (
                <tbody ref = {provided.innerRef} {...provided.droppableProps} className="timeCard-table font-colorInput">
                  {sortedArray && sortedArray.map((item, idx) => {
                    return (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={idx}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref = {provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item.id}
                          >
                            <td className="text-center" width="5%"><img className="drag-icon" src={DragIcon} width="20" height="20" alt="dragIcon"/></td>
                            {item.is_edit &&
                              <td className="text-center">
                                <span title="Edit Role">
                                  <Link to={{
                                      pathname: `/vendor/role-setting/${item.id}/edit/`,
                                      search: queryString.stringify({
                                        parent: `${pathname}${search}`, edit: `${item.is_edit}`
                                      })
                                    }} title="Edit">
                                    <EditIconAtom
                                      className="cursor-pointer"
                                    />
                                  </Link>
                                </span>
                                <span title="Delete Role" className="ml-2">
                                  <DeleteIconAtom
                                    className="cursor-pointer"
                                    onClick={() => deleteRoleData(item)}
                                  />
                                </span>
                              </td>
                            }
                            {!item.is_edit &&
                              <td className="text-center">
                                <span title="View Role" className="ml-1">
                                <Link to={{
                                      pathname: `/vendor/role-setting/${item.id}/edit/`,
                                      search: queryString.stringify({
                                        parent: `${pathname}${search}`, edit: `${item.is_edit}`
                                      })
                                    }} title="View">
                                  <ViewIconAtom
                                    className="cursor-pointer"
                                  />
                                  </Link>
                                </span>
                              </td>
                            }
                            <td title={item.name}>{item.name}</td>
                            <td title={item.description}>{item.description}</td>
                          </tr>
                        )}
                    </Draggable>)
                  })}
                  {provided.placeholder}
                  {data && !data.count &&
                    <tr><td className="text-center" colSpan="4">No Records Found</td></tr>
                  }
                </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </section>
    )
  }
}

RoleListTableComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  deleteRoleData: PropTypes.func.isRequired,
};

export default RoleListTableComponent;