import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';

import SSTCFLayout from 'layouts/SSTCF';
import AdjustmentTypesListSection from 'sections/vendors/adjustmentTypes/List';
import AdjustmentTypesFilterSection from 'sections/vendors/adjustmentTypes/Filter';

import { NAV } from 'constants/vendors';

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */
class AdjustmentTypesListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.saveUserActivityOrderPreference = this.saveUserActivityOrderPreference.bind(this);
    this.getAdjustmentIds = this.getAdjustmentIds.bind(this);
  }

  componentWillMount() {
    const { location : {query} } =this.props;
    const cookieKey = cookie.load("adjustment_types_form");
    this.setState({ isLoading: true });
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.fetchData(query);
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }


  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }
  
  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("adjustment_types_form", !isInfoOpen);
  }

  fetchData(params){
    this.setState({ isLoading: true });
    const { userExtraData } =this.props;
    const pageSize = cookie.load("adjustment_type_page_size");
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.basic_setting'}),
      this.props.AdjustmentTypesDucks.getAdjustmentTypes({...params, company_id:userExtraData.company.id, page_size:pageSize}),
      this.props.AdjustmentTypesDucks.getAdjustmentIds()
    ])
    .then(() => this.setState({ isLoading: false }))
    .catch(() => this.setState({ isLoading: false }));
  }


  getAdjustmentIds(){
    return this.props.AdjustmentTypesDucks.getAdjustmentIds();
  }

  saveUserActivityOrderPreference(order){
    return this.props.UserDucks.saveUserOrderPreference({data:{sequence: order} , preference_type: 'user_preference_adjustment_types'});
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const {userExtraData} = this.props;
    const tabs = [
      { href: '/vendor/basic-setting', label: 'Basic' },
      { href: '/vendor/system-setting', label: 'System' },
      { href: '/vendor/notification-setting', label: 'Notification' },
      { href: '/vendor/adjustment-types', label: 'Adjustment Types' },
      { href: "/vendor/system-forms", label: "System Forms" },  
      
    ];

    const condition = userExtraData?.company_permissions?.meal_adjustments_enabled;   

    if (condition) {
      tabs.push({href: "/vendor/meal-adjustments", label: "Meal Adjustments"},);
    }




    const { infoList } = this.props;
    return (
      <SSTCFLayout
        navInfo={NAV.AdjustmentTypesListPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      > 
        <AdjustmentTypesFilterSection isLoading={isLoading} fetchData={this.fetchData}/>
        <AdjustmentTypesListSection getAdjustmentIds={this.getAdjustmentIds} isLoading={isLoading} saveUserOrderPreference = {this.saveUserActivityOrderPreference} adjustmentIdsList={this.props.adjustmentIdsList} fetchData={this.fetchData}/>
      </SSTCFLayout>
    );
  }
}



const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  userExtraData: UserDucks.userExtraData(state),
  adjustmentIdsList: AdjustmentTypesDucks.adjustmentIdsList(state)
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

AdjustmentTypesListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

AdjustmentTypesListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AdjustmentTypesListPage);
