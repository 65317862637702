import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_COMMENTS = 'jobs/comment/GET_COMMENTS';
const GET_COMMENTS_FULFILLED = 'jobs/comment/GET_COMMENTS_FULFILLED';
const POST_COMMENT = 'jobs/comment/POST_COMMENT';
const RESET_COMMENTS = 'jobs/comment/RESET_COMMENTS';
const POST_COMMENT_FULFILLED = 'jobs/comment/POST_COMMENT_FULFILLED';
const DELETE_COMMENT = 'jobs/job/DELETE_COMMENT';
const DELETE_COMMENT_FULFILLED = 'jobs/job/DELETE_COMMENT_FULFILLED';


export function getComments(params) {
  return {
    type: GET_COMMENTS,
    payload: axios.get(`${URLS.JOB}${params.job_id}/${URLS.HISTORY}?paginate=false`, {params}),
  };
}

export function postComment(data, id) {
  return {
    type: POST_COMMENT,
    payload: axios.post(`${URLS.JOB}${id}/${URLS.HISTORY}`, data),
  };
}

export function resetCommentList() {
  return {
    type: RESET_COMMENTS,
  };
}

export function deleteComment(data) {
  return {
    type: DELETE_COMMENT,
    payload: axios.delete(`${URLS.JOB}${data.job}/document/${data.id}/`),
  };
}

const defaultState = {
  list: [] ,
  detail: {},
};

function GET_COMMENTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function POST_COMMENT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_COMMENTS_REDUCER(state, action) {
   return Object.assign({}, state, {
    list: []
  });
}

function DELETE_COMMENT_FULFILLED_REDUCER (state, action) {
  return Object.assign({}, state, {
    detail: action.payload
  });
}

const handlers = {
  [GET_COMMENTS_FULFILLED]: GET_COMMENTS_FULFILLED_REDUCER,
  [POST_COMMENT_FULFILLED]: POST_COMMENT_FULFILLED_REDUCER,
  [RESET_COMMENTS]: RESET_COMMENTS_REDUCER,
  [DELETE_COMMENT_FULFILLED] : DELETE_COMMENT_FULFILLED_REDUCER,
};


const commentSelector = state => state.jobs.comment;

export const commentList = createSelector(
  commentSelector,
  instance => instance.list,
);

export const commentDetail = createSelector(
  commentSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
