import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import Lightbox from 'react-image-lightbox';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
//  import AutoCompleteLocalFilterAtom from 'atoms/AutoCompleteLocalFilter';
import AutoCompleteAtom from 'atoms/AutoComplete';
import FileBrowserAtom from 'atoms/FileBrowser';

import { FORM_LAYOUT } from 'constants/layout';
import { DATA_TYPE_OPTIONS } from 'constants/knowledgeBases';
import styles from './styles.module.scss';
import PlayIcon from '../play-btn.png';
import {ReactComponent as PdfIcon} from '../pdf.svg';
import DeleteIcon from './delete.png';
/**
 * JobFormPage -> JobFormSection -> KnowledgeBaseFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class KnowledgeBaseFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    // this.onShapeClick = this.onShapeClick.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.state = { file: null,
      photoIndex: 0,
      isOverlayOpen: false,
      fileUrl: props.initialValues ? props.initialValues.external_reference : null,
      fileType:  props.initialValues ? props.initialValues.kb_data_type : null };
  }

  handleFileRemove() {
    const { change } = this.props;

    change('external_reference', null);
    this.setState({ file: null, fileUrl: null });
    document.getElementById('external_reference').value = null;
  }

  handleFileSelect(e){
    const file = e.target.files[0];
    const reader = new FileReader();

    const fileExt = file && file.name.split('.').pop();
    const imageExt = ['jpg', 'png', 'svg', 'jpeg'];
    const docxExt = ['docs', 'pdf', 'docx'];
    const videoExt = ['mp4', 'webm', 'ogg'];

    if(imageExt.indexOf(fileExt) > -1){
      this.setState({ fileType: 'Image'});
    }else if (docxExt.indexOf(fileExt) > -1){
      this.setState({ fileType: 'Document'});
    }else if (videoExt.indexOf(fileExt) > -1){
      this.setState({ fileType: 'Video'});
    }else {
      this.setState({ file: null, fileUrl: null });
      document.getElementById('external_reference').value = null;
      return toast.error('File type not supported');
    }

    reader.onload = () => this.setState({ file, fileUrl: reader.result });
    if (file) reader.readAsDataURL(file);
    return true;
  }

  submit(data) {
    const {  updateOrAdd } = this.props;
    const { file } = this.state;
    const detail = {
      ...data,
      context_id: data.context && data.context.value,
    };

    const newDetail = new FormData();
    if(file){
      newDetail.append('external_reference', file);
    }
    newDetail.append('kb_data_type', detail.kb_data_type);
    newDetail.append('context_id', detail.context_id);
    newDetail.append('is_active', detail.is_active);
    newDetail.append('rich_text_content', detail.rich_text_content);
    newDetail.append('id', detail.id);
    return updateOrAdd(newDetail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const {
      error,
      handleSubmit,
      submitting,
      getContexts,
      toggle,
    } = this.props;
    const { fileUrl, fileType , isOverlayOpen } = this.state;
    return (
      <Form onSubmit={handleSubmit(this.submit)} noValidate className="ml-3 mr-4 pb-5">
        <div className="error-text">{error}</div>
        <Row className="mt-3">
          <Col md={6}>
            <Field
              id="kb_data_type"
              name="kb_data_type"
              label="Data Type"
              component={SelectAtom}
              layout={FORM_LAYOUT}
              placeholder="Data Type"
              options={DATA_TYPE_OPTIONS}
              simpleValue
            />
          </Col>
          <Col md={6}>
            <Field
              component={AutoCompleteAtom}
              getData={getContexts}
              id="context"
              mapKeys={{ label: 'display', value: 'id' }}
              name="context"
              label="Context"
              layout={FORM_LAYOUT}
              placeholder="Context"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Field
              id="rich_text_content"
              name="rich_text_content"
              label="Rich Text Content"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Rich Text Content"
              // maxLength="500"
            />
          </Col>
          <Col md={6}>
            <Field
              //  accept="image/*"
              id="external_reference"
              name="external_reference"
              label="File"
              component={FileBrowserAtom}
              type="file"
              layout={FORM_LAYOUT}
              onChange={this.handleFileSelect}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <Field
              id="is_active"
              type="checkbox"
              name="is_active"
              label="Active?"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
            />
          </Col>
          <Col md={6}>
            { fileUrl
              ? (<div>
                { fileType === 'Document' &&
                  <div className={`${styles['knowledge-wrap']} show-del`}>
                    <div className={`${styles['document-wrap']} d-flex justify-content-center align-items-center show-del`}>
                      <a download={fileUrl} target="_blank" href={fileUrl} rel="noopener noreferrer">
                        <PdfIcon height="100" width="100"/>
                      </a>
                    </div>
                    <div
                      onClick={() => this.handleFileRemove()}
                      role="button"
                      tabIndex="0"
                    >
                      <img
                        height="18" width="18"
                        src={DeleteIcon}
                        alt="delete"
                        className="delete-icon-knowledge"
                      />
                    </div>
                  </div>
                }
                { fileType === 'Video' &&
                <div className={`${styles['knowledge-wrap']} show-del`}>
                  <div className={styles['video-wrapper']}>
                    <video
                      className={styles['video-content']}
                      muted
                      src={fileUrl}
                    >
                      <track kind="captions" />
                    </video>
                    <div tabIndex="0" role="button" onClick={() => toggle({kb_data_type: fileType, external_reference: fileUrl})}>
                      <img src={PlayIcon} alt='' className={styles['overlay-play-button']} />
                    </div>
                  </div>
                  <div
                    onClick={() => this.handleFileRemove()}
                    role="button"
                    tabIndex="0"
                  >
                    <img
                      height="18" width="18"
                      src={DeleteIcon}
                      alt="delete"
                      className="delete-icon-knowledge"
                    />
                  </div>
                </div>
                }
                { fileType === 'Image' &&
                <div className={`${styles['knowledge-wrap']} show-del`}>
                  <div className={`${styles['img-wrap']} outline-none`} tabIndex="0" role="button" onClick={()=> this.setState({ isOverlayOpen: true })}>
                    <img className={styles['img-wrap-img']} src={fileUrl} alt=''  />
                  </div>
                  <div
                    onClick={()=> this.handleFileRemove()}
                    role="button"
                    tabIndex="0"
                  >
                    <img
                      height="18" width="18"
                      src={DeleteIcon}
                      alt="delete"
                      className="delete-icon-knowledge"
                    />
                  </div>
                </div>
                }
                {isOverlayOpen && (
                  <Lightbox
                    mainSrc={fileUrl}
                    onCloseRequest={() => this.setState({ isOverlayOpen: false })}
                  />
                )}
              </div>)
              : null
            }
          </Col>
        </Row>
        <div>
          <Button
            type="submit"
            color="secondary"
            className="float-right my-4"
            size={FORM_LAYOUT.size}
          >
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </div>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.kb_data_type) errors.kb_data_type = 'Select Data Type'
  if (!values.external_reference) errors.external_reference = 'Select File'
  if (!values.context) errors.context = 'Select Context'
  return errors;
};

KnowledgeBaseFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  updateOrAdd: PropTypes.func,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
  getContexts: PropTypes.func.isRequired,
};

KnowledgeBaseFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: 'KnowledgeBaseForm',
  validate,
})(KnowledgeBaseFormComponent);

const selector = formValueSelector('KnowledgeBaseForm');

export default connect(
  state => {
    const filePreview = selector(state, 'external_reference');
    return { filePreview };
  }
)(withReduxForm);