 /*import  GraniteError from 'lib/GraniteError'; */
class CompanyEntity {
  constructor(object={}){
    
   // if(!username || typeof username! == 'string'){

   //  throw GraniteError(
   //  {
   //    username :'username entity cant be initialized without username';

   //  })
   //  console.log(object);
    this.name = object.name;
    this.suspension_date = object.suspension_date;
    this.suspension_date_display = object.suspension_date_display;
    this.commercial_status = object.commercial_status;
    this.closing_date = object.closing_date;
 }

 


  
  toJSON() {
    const { name, suspension_date,suspension_date_display , commercial_status , closing_date} = this;
    return {name, suspension_date, suspension_date_display , commercial_status, closing_date};
  }
}

export default CompanyEntity;


