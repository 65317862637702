import React from 'react';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import styles from './styles.module.scss';
import closeimage from './Close-Cross.png';

/**
 * SurveyFormComponent -> SurveyFormQuestion -> QuestionPreviewModal
 *
 * Props:
 *    - update
 */
class QuestionPreviewModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen,toggle } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <ModalHeader>
          Survey
          <img src={closeimage} className="float-right cursor-pointer mt-2" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <ModalBody>
          <div className="text-center" style={{fontSize : '15px'}}>     
            <span className={styles['survey-employee-name']}>Name</span>
            <span>(Date)</span>
          </div>
          <div className={`${styles['custom-label']} pt-0`}>Title of the form</div>
        </ModalBody>
      </Modal>
    );
  }
};

export default QuestionPreviewModal;