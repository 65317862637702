import React from 'react';
import StorageGateway from 'lib/storage-gateway';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';

import * as SubCompanyDucks from 'ducks/vendors/subCompany';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import VendorSubCompanyTableComponent from 'components/vendors/subCompanyList/Table';

/**
 * VendorSubCompanyListPage -> VendorSubCompanyListSection
 *
 * Components:
 *    - {@link VendorSubCompanyListSection}
 *
 * State:
 *    - subCompanyList
 *
 * Actions:
 *    None
 */
class VendorSubCompanyListSection extends React.Component {
  static goTo(data) {
    let domain = window.location.hostname;
    const host = window.location.hostname.split('.');
    if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
    StorageGateway.set('company_id', data.id);
    cookie.save('company_id', data.id, { path: '/' , domain});
    cookie.remove('payrollId', { path: '/' });
    cookie.remove('startDate', { path: '/' });
    cookie.remove('endDate', { path: '/' });
    cookie.remove('departmentId', { path: '/' });
    cookie.remove('teamId', { path: '/' });
    setTimeout(() => {
      window.location.href = '/dashboard';
    });
  }

  render() {
    const { isLoading, subCompanyList, location } = this.props;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <VendorSubCompanyTableComponent
          data={subCompanyList}
          goTo={this.constructor.goTo}
        />
        <PaginationComponent
          count={subCompanyList.count}
          location={location}
          cookieKey="sub_company_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  subCompanyList: SubCompanyDucks.subCompanyList(state),
});

const mapActionsToProps = () => ({});

VendorSubCompanyListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  subCompanyList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorSubCompanyListSection);
