import React from 'react';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as UserDucks from 'ducks/accounts/user';
import * as JobDucks from 'ducks/jobs/job';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as JobTagDucks from 'ducks/jobs/tag';
import * as CustomerDucks from 'ducks/customers/customer';
import * as CustomerTagDucks from 'ducks/customers/tag';

import { toast } from 'react-toastify';

import CustomerFormModalComponent from 'components/customers/list/FormModal';
import SectionLoaderAtom from 'atoms/SectionLoader';
import JobFormComponent from 'components/jobs/form/Form';
import TagModalComponent from 'components/jobs/form/TagModal';
import { timeoutsShape } from 'reactstrap';


class JobFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.createTag = this.createTag.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.toggle=this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
    this.jobList = this.jobList.bind(this);
    this.updateCreate = this.updateCreate.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList= this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.state = { status: null,
      job: {},
      isTagLoading: false,
      isTableLoading: false,
      isOpen: false,
      customerSelected: null,
      isTagModal: false,
      tagType: null,
      next: false,
      finish: false,
      isModalHidden:false,
    };
  }

  componentWillMount(nextProps) {
    this.props.JobTagDucks.getJobTags({ paginate: false, tag_type: 2 });
    this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  getUpdatedList(){
    const {  tagType } = this.state;
    if(tagType === 2){
      return this.props.JobTagDucks.getJobTags({ paginate: false, tag_type: 2 });
    }
    if(tagType === 0){
      this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
    }
  }

  toggleTagModal(tagType) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal, tagType });
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  updateCreate(data) {
    const { isOpen } = this.state;
    if(data.id){
      return this.props.CustomerDucks.putCustomersDetails(data)
        .then(() => {
          this.setState({ isOpen: !isOpen });
          this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true });
        });
    }
    return this.props.CustomerDucks.postCustomers(data)
      .then(() => {
        const { customerDetails } = this.props;
        this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true });
        this.setState({ isOpen: !isOpen, customerSelected: customerDetails });
      });
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  toggle(customer, type) {
    const { isOpen } = this.state;
    if(type === 'add' ) {
      this.setState({ customerSelected: null })
    }
    else if (customer && customer.user) {
      const newCustomer = customer;
      const customerTag = customer && customer.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
      newCustomer.tags = customerTag;
      this.setState({ customerSelected: newCustomer });
    }
    return this.setState({ isOpen: !isOpen });
  }

  jobList() {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    const pathname = `/job/list?status=1`;
    const newPath = `/job/list/new?status=1`;
    query.admin_list ? history.push(newPath) : history.push(pathname);
  }

  createOrUpdate(data, submitJob) {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    const detail = data;

    // Creating the job for the first time using submit button
    if(submitJob === true){
      return this.props.JobDucks.postJob(detail)
      .then((res) => {
        toast.success("Job successfully created !");
        query.admin_list ? history.push({ pathname:`/job/list/new`}) : history.push({ pathname:`/job/list`});
      });
    }
    // Creating a job using next Button
    else {
      return this.props.JobDucks.postJob(detail)
        .then((res) => {
          this.setState({ job: res.value });
          submitJob = false;
          toast.success("Job successfully created !");
          history.push({ pathname:`/job/list/${res.value.id}/budget/edit`,
            search: queryString.stringify({ ...query, setup: true })});
      });
    }
  }

  createTag(data) {
    const { tagType } = this.state;
    if(tagType === 2){
      return this.props.JobTagDucks.postJobTag({ ...data, tag_type: 2 })
    }
    if(tagType === 0){
      return this.props.CustomerTagDucks.postCustomerTag({ ...data, tag_type: 0 })
    }
    return true;
  }

  handleStatusChange (key,value) {
    const { jobDetail } = this.props;
    jobDetail[key] = value;
    this.props.JobDucks.resetJobStatus(jobDetail);
  }

  goTo(url, data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const {location: { query } } = this.props;
    this.props.JobDucks.setJob(data);
    if(params.id){
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job_id: params.id,
        }),
      });
    }
    else {
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job: 'new',
        }),
      });
    }
  }

  render() {
    const { isTagLoading, customerSelected, isOpen, isTagModal, nextLoader, submitLoader, tagType, isModalHidden } = this.state;
    const {
      isLoading,
      customerList,
      dateFormat,
      jobTagList,
      location,
      customerTagList,
      customerDetails,
      userCompany,
      formValues,
    } = this.props;

    let initialValues ;

    if (customerDetails.id && customerDetails.is_active ) {
      const customerTag = customerDetails.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
      initialValues = {...customerDetails, tags: customerTag};
    }
    let customer;
    if(customerDetails.id && customerDetails.is_active ){
      customer = { ...initialValues, id: customerDetails.id, name: initialValues.customer_name };
    }

    const jobtags = jobTagList&& jobTagList.length && jobTagList[0].tag_collection && jobTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));

    const customertags = customerTagList&& customerTagList.length && customerTagList[0].tag_collection && customerTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    const tags = tagType == '0' ? customertags : jobtags;

    return (
      <section>
        {isLoading
          ? <SectionLoaderAtom active />
          :<section>
            <JobFormComponent
              initialValues={
                initialValues && initialValues.id
                  ? {
                    ...formValues,
                    customer_name: customer,
                    email: customer && customer.user && customer.user.email,
                    contact: customer && customer.user && customer.user.full_name,
                    phone: customer ? `${customer.phone_ext}${customer.phone_no}` : null,
                  }
                  : {}
              }
              isLoading={isLoading}
              isTagLoading={isTagLoading}
              location={location}
              dateFormat={dateFormat}
              customerList={customerList}
              createOrUpdate={this.createOrUpdate}
              handleStatusChange={this.handleStatusChange}
              createTag={this.createTag}
              tagList={jobTagList}
              goTo={this.goTo}
              getOptions={this.getOptions}
              toggle={this.toggle}
              isOpen={isOpen}
              toggleTagModal={this.toggleTagModal}
              nextLoader={nextLoader}
              submitLoader={submitLoader}
            />
            {isTagModal &&
              <TagModalComponent
                isModalOpen={isTagModal}
                toggle={this.toggleTagModal}
                createTag={this.createTag}
                deleteSelectedTags={this.deleteSelectedTags}
                updateTagName={this.updateTagName}
                getUpdatedList={this.getUpdatedList}
                tagType={tagType}
                tagsList = {tags}
                tagsCollection={tagType == 0 ? customerTagList : jobTagList}
                hideModal = {this.hideModal}
              />
            }
            {isOpen &&
              <CustomerFormModalComponent
                isOpen={isOpen}
                toggle={this.toggle}
                create={this.updateCreate}
                createTag={this.createTag}
                isTagLoading={isTagLoading}
                tagList={customerTagList}
                getOptions={this.getOptions}
                initialValues={{
                  ...customerSelected,
                  point_of_contact: customerSelected && customerSelected.user.full_name,
                  phone_ext: userCompany.phone_ext,
                  user: { is_active: true },
                }}
                toggleTagModal={this.toggleTagModal}
                edit = {customerSelected && customerSelected.id}
                isModalHidden={isModalHidden}
              />
            }
          </section>
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  customerList: CustomerDucks.customerList(state),
  jobTagList: JobTagDucks.jobTagList(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userCompany: UserDucks.userCompany(state),
  customerDetails: CustomerDucks.customerDetails(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
});

const mapActionToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  JobTagDucks: bindActionCreators(JobTagDucks, dispatch),
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
});

JobFormSection.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  jobData: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  jobTagList: PropTypes.array,
  customerList: PropTypes.array.isRequired,
  customerDetails: PropTypes.object,
};

JobFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const jobForm = connect(
  state => ({
    formValues: state.form && state.form.JobAddForm && state.form.JobAddForm.values,
  })
)(JobFormSection);

export default connect(
  mapStateToProps,
  mapActionToProps,
)(jobForm);
