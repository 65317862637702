
import StorageGateway from 'lib/storage-gateway';
import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import { server } from 'helpers/config';
import cookie from 'react-cookies';

const EVENT_TYPES = {
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
};

class setPasswordUseCase {
 
  userAPIGateway = null;
  

  constructor(userAPIGateway) {
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }


  toString() {
    return 'setPasswordUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data) {
    this.password = data.password;
    this.confirmPassword = data.confirm_password;
    this.uid = data.uid;
    this.device = 'WEB';
    this.grant_type= "password";
    this.client_id = server.ClientId;
    this.client_secret= server.ClientSecret;
    this.inputType = data.inputType;
    this.username = data.username;
    this.email = data.email;
  }

  async execute() {
    let errors = {};
    const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';

    if (!this.password) {
      errors.password = 'Enter Password';
    } 
    if(this.inputType === 'password' && !this.confirmPassword){
      errors.confirm_password = 'Enter Confirm Password';
    }
    if(this.inputType === 'password' && this.password && this.confirmPassword && (this.password !== this.confirmPassword)){
      errors.confirm_password = 'Password and Confirm Password does not match';
    }

    if (this.password && this.password.length < 8){
      errors.password = 'Password should contain atleast 8 characters';
    }
    if (this.password && this.password.length >= 8 && this.password && (this.password.match(passwordRegex) || this.password.match(passwordSequential) || this.password.match(passwordRepeated ))) {
      errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    await this.userAPIGateway.setPassword(this.password, this.uid);
    let domain = window.location.hostname;
    const host = window.location.hostname.split('.');
    if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
    const response = await this.userAPIGateway.signIn(this.email, this.password, this.device);
    StorageGateway.set('Token', response.token);
    cookie.save('Token', response.token, { path: '/' , domain});
    const oAuthToken = await this.userAPIGateway.postAuthentication(this.client_id,
      this.client_secret, this.device, this.grant_type, this.password, this.username);
    StorageGateway.set('oAuthToken', oAuthToken);
    cookie.save('oAuthToken', oAuthToken, { path: '/', domain });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_PASSWORD_SUCCESS,
    });
  }

  async getUser(uid){
    const userEntitiy = await this.userAPIGateway.getUser(uid);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_USER_SUCCESS,
      data: userEntitiy,
    });
  }

}


export default setPasswordUseCase; 