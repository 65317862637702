export const EVENT_TYPES = {
  SHOW_ASSIGNED_JOBS: "SHOW_ASSIGNED_JOBS",
  SHOW_UNASSIGNED_JOBS: "SHOW_UNASSIGNED_JOBS",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  ASSIGN_SUCCESS: "ASSIGN_SUCCESS",
  SHOW_ASSIGN_BUTTON_LOADER: "SHOW_ASSIGN_BUTTON_LOADER",
  HIDE_ASSIGN_BUTTON_LOADER: "HIDE_ASSIGN_BUTTON_LOADER",
  SET_DROPDOWN_VALUES: "SET_DROPDOWN_VALUES",
  SET_INITIAL_VALUES: "SET_INITIAL_VALUES",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  KIOSK_LIST: "KIOSK_LIST",
  SET_SELECTED_ITEM: "SET_SELECTED_ITEM",
  SHOW_TOAST: "SHOW_TOAST",
  ADD_NEW: "ADD_NEW",
  LIST_VIEW: "LIST_VIEW",
  SHOW_KIOSK_LOADER: "SHOW_KIOSK_LOADER",
  HIDE_KIOSK_LOADER: "HIDE_KIOSK_LOADER",
  GET_LINK_SUCCESS: "GET_LINK_SUCCESS",
  SYSTEM_SETTINGS: "SYSTEM_SETTINGS",
  AUDIT_LIST: "AUDIT_LIST",
  SHOW_AUDIT_LOGS_LOADER: "SHOW_AUDIT_LOGS_LOADER",
  HIDE_AUDIT_LOGS_LOADER: "HIDE_AUDIT_LOGS_LOADER",
  SET_EMPLOYEE_DATA: "SET_EMPLOYEE_DATA",
  SHOW_ASSIGNED_JOBS: "SHOW_ASSIGNED_JOBS",
  SHOW_UNASSIGNED_JOBS: "SHOW_UNASSIGNED_JOBS",
  SHOW_BUTTON_LOADER: "SHOW_BUTTON_LOADER",
  ASSIGN_SUCCESS: "ASSIGN_SUCCESS",
  HIDE_BUTTON_LOADER: "HIDE_BUTTON_LOADER",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  SET_TAGS_LIST: "SET_TAGS_LIST",
  NEW_TAG_ADDED: "NEW_TAG_ADDED",
  TAGS_DELETED: "TAGS_DELETED",
  SET_POLICIES_LIST: "SET_POLICIES_LIST",
  EMPLOYEE_LIST: "EMPLOYEE_LIST",
  SUBCOMPANY_LIST: "SUBCOMPANY_LIST",
  CHANGE_COMPANIES: "CHANGE_COMPANIES",
  TEAM_LIST: "TEAM_LIST",
  DEPARTMENT_LIST: "DEPARTMENT_LIST",
  INFO_LIST: "INFO_LIST",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_EXPORT_LOADER: "SHOW_EXPORT_LOADER",
  HIDE_EXPORT_LOADER: "HIDE_EXPORT_LOADER",
  SELECTED_EMPLOYEES: "SELECTED_EMPLOYEES",
  ALL_JOBS: "ALL_JOBS",
  SHOW_MODAL_LOADER: "SHOW_MODAL_LOADER",
  HIDE_MODAL_LOADER: "HIDE_MODAL_LOADER",
  SHOW_ASSIGN_BUTTON_LOADER: "SHOW_ASSIGN_BUTTON_LOADER",
  HIDE_ASSIGN_BUTTON_LOADER: "HIDE_ASSIGN_BUTTON_LOADER",
  UPDATE_USER_PREFERENCE: "UPDATE_USER_PREFERENCE",
  GET_USER_PREFERENCE: "GET_USER_PREFERENCE",
  UPDATE_STATUS: "UPDATE_STATUS",
};
