import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  SHOW_LOADER: 'SHOW_LOADER',
  GET_COMPANY_INVOICE_LIST_SUCCESS : 'GET_COMPANY_INVOICE_LIST_SUCCESS',
  INFO_LIST : 'INFO_LIST',
  CANCEL_PLAN_SUCCESS : 'CANCEL_PLAN_SUCCESS',
  GET_CUSTOMER_DETAIL_SUCCESS : 'GET_CUSTOMER_DETAIL_SUCCESS',
  GET_COMPANY_PLAN_SUCCESS: 'GET_COMPANY_PLAN_SUCCESS'
};

class invoiceUsecase {
 
  invoiceApiGateway = null;

  constructor(invoiceApiGateway) {
    this.invoiceApiGateway = invoiceApiGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'invoiceUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }
  
  async getCompanyInvoiceList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.invoiceList = await this.invoiceApiGateway.getCompanyInvoiceList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_COMPANY_INVOICE_LIST_SUCCESS,
      data: this.invoiceList,
    });
  }

  async getInfoList(){
    this.infoList = await this.invoiceApiGateway.getInfoList({ route: 'vendor.invoice'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }


  async cancelPlan(email, password, companyId){
    await this.invoiceApiGateway.authenticatePassword(email, password, 'web');
    let customerDetail = await this.invoiceApiGateway.cancelCompanyPlan(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CANCEL_PLAN_SUCCESS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_CUSTOMER_DETAIL_SUCCESS,
      detail: customerDetail,
    });
  }

   async getCompanyPlan(companyId){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.companyPlanDetails = await this.invoiceApiGateway.getCompanyPlan(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_COMPANY_PLAN_SUCCESS,
      companyPlan: this.companyPlanDetails,
    });
  }

  
}

export default invoiceUsecase;