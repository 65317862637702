import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import queryString from 'query-string';

import CreateJobIcon from './createJobIcon.png';
import BlueCreateJobIcon from './blueCreateJob.png';
import BudgetIcon from './BudgetGrey.png';
import BlueBudgetIcon from './BudgetBlue.png';
import GreyTaskIcon from './taskGrey.png';
import BlueTaskIcon from './taskBlue.png';
import BlueEmployeeIcon from './assignEmployee.png';
import GreyEmployeeIcon from './employee.png';
import BlueGeofenceIcon from './geofenceBlue.png';
import GreyGeofenceIcon from './geofenceGrey.png';
import BlackCreateJobIcon from './JobBlack.png';
import BlackBudgetIcon from './BudgetBlack.png';
import BlackTaskIcon from './TaskBlack.png';
import BlackEmployeeIcon from './EmployeeBlack.png';
import BlackGeofenceIcon from './GeofenceBlack.png';

class JobProgressBarSection extends React.Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.state = {
      isGreen:false,
      selectedPage: 'jobDetails'
    };
  }

  componentWillMount(){
    const { active } = this.props;
    if(active === 1){
      this.setState({selectedPage : 'jobDetails'})
    }
    else if(active === 2){
      this.setState({selectedPage : 'jobBudget'})
    }
    else if(active === 3){
      this.setState({selectedPage : 'jobTask'})
    }
    else if(active === 4){
      this.setState({selectedPage : 'jobEmployee'})
    }else if(active === 5){
      this.setState({selectedPage : 'jobDocuments'})
    }
    else if(active === 6){
      this.setState({selectedPage : 'jobGeofence'})
    }
  }

  goTo(key){
    const { router: { history } } = this.context;
    const { selectedId, isInfoOpen } = this.props;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);

    if(key === 'jobDetails'){
      this.setState({selectedPage : 'jobDetails'});
      if(!selectedId)
        history.push({ pathname:`/job/list/add`});
      else {
        history.push({ pathname:`/job/list/${selectedId}/edit` , search: queryString.stringify(query)});
      }
    }
    if(key === 'jobBudget' ){
      this.setState({selectedPage : 'jobBudget'});
      if(isInfoOpen)
      {
        history.push({ pathname:`/job/list/${selectedId}/budget/edit` , search: queryString.stringify(query)})
      }
      else {
        history.push({ pathname:`/job/list/${selectedId}/budget/edit`,
          search: queryString.stringify({...query, setup: true })});
      }
    }
    if(key === 'jobTask'){
      this.setState({selectedPage : 'jobTask'});
      if(isInfoOpen) {
          history.push({ pathname:`/job/list/${selectedId}/task/edit` , search: queryString.stringify(query)});
      }
      else {
        history.push({ pathname:`/job/list/${selectedId}/task/edit`,
          search: queryString.stringify({ ...query, setup: true })});
      }
    }
    if(key === 'jobEmployee'){
      this.setState({selectedPage : 'jobEmployee'});
      if(isInfoOpen) {
        history.push({ pathname:`/job/list/${selectedId}/employee/edit`, search: queryString.stringify(query)});
      }
      else {
        history.push({ pathname:`/job/list/${selectedId}/employee/edit`,
          search: queryString.stringify({ ...query, setup: true })});
      }
    }
    if(key === 'jobGeofence'){
      this.setState({selectedPage : 'jobGeofence'});
      if(isInfoOpen) {
        history.push({ pathname:`/job/list/${selectedId}/geofence/add` , search: queryString.stringify(query)});
      }
      else {
        history.push({ pathname:`/job/list/${selectedId}/geofence/add`,
          search: queryString.stringify({ ...query, setup: true })});
      }
    }
    if(key === 'jobDocuments'){
      
      this.setState({selectedPage : 'jobDocuments'});
      if(isInfoOpen) {
        console.log(`/job/list/${selectedId}/documents/edit`)
        history.push({ pathname:`/job/list/${selectedId}/documents/edit` , search: queryString.stringify(query)});
      }
      else {
        console.log(`/job/list/${selectedId}/documents/edit`)
        history.push({ pathname:`/job/list/${selectedId}/documents/edit`,
          search: queryString.stringify({ ...query, setup: true })});
      }
    }
  }

  render() {
    const { selectedId , addJob} = this.props;
    console.log('saddfa',selectedId)
    const {selectedPage} = this.state;
    return (
      <section>
        <div role="group" >
          <button type="button" className={styles['progressBarborders']} onClick ={() => this.goTo('jobDetails') }>
            {selectedPage === 'jobDetails' ?
              <img src={BlueCreateJobIcon} height="18px" width="20px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              !selectedId
              ?              
              <img src={CreateJobIcon} height="18px" width="20px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              <img src={BlackCreateJobIcon} height="18px" width="20px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
            }
            <span style={selectedPage==='jobDetails' ? {color: '#09819A'} : !selectedId  ? {color : '#B1BABF'} : {color : 'black'}}>Job Details</span>
          </button>
          <button disabled={!selectedId} type="button" className={styles['progressBar']} onClick ={() => this.goTo('jobBudget') }>
            {selectedPage === 'jobBudget' ?
              <img src={BlueBudgetIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              !selectedId
              ?
              <img src={BudgetIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              <img src={BlackBudgetIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
            }
            <span style={selectedPage==='jobBudget' ? {color: '#09819A'} : !selectedId  ? {color : '#B1BABF'} : {color : 'black'}}>Add/View Budget</span>
          </button>
          <button disabled={!selectedId} type="button" className={styles['progressBar']} onClick ={() => this.goTo('jobTask') }>
            {selectedPage === 'jobTask' ?
              <img src={BlueTaskIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              !selectedId
              ?
              <img src={GreyTaskIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
              :
              <img src={BlackTaskIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}}/>
            }
            <span style={selectedPage==='jobTask' ? {color: '#09819A'} : !selectedId ? {color : '#B1BABF'} : {color : 'black'}}>Add/View Task</span>
          </button>
          <button disabled={!selectedId} type="button" className={styles['progressBar']} onClick ={() => this.goTo('jobEmployee') } style={{width:'18%'}}>
            {selectedPage === 'jobEmployee' ?
              <img src={BlueEmployeeIcon} height="18px" width="19px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} className={styles['progressBarIcons']}/>
              :
              !selectedId
              ?
              <img src={GreyEmployeeIcon} height="18px" width="19px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} className={styles['progressBarIcons']}/>
              :
              <img src={BlackEmployeeIcon} height="18px" width="19px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} className={styles['progressBarIcons']}/>
            }
            <span style={selectedPage==='jobEmployee' ? {color: '#09819A'} : !selectedId? {color : '#B1BABF'} : {color : 'black'}}>Add/View Employee</span>
          </button>
          <button disabled={!selectedId} type="button" className={styles['progressBarEnd']} onClick ={() => this.goTo('jobDocuments') }>
            {selectedPage === 'jobDocuments' ?
              <img src={BlueGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} className={styles['progressBarIcons']}/>
              :
              !selectedId
              ?
              <img src={GreyGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} />
              :
              <img src={BlackGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} />
            }
            <span style={selectedPage==='jobDocuments' ? {color: '#09819A'} : !selectedId ? {color : '#B1BABF'} : {color : 'black'}}>Documents</span>
          </button>
          <button disabled={!selectedId} type="button" className={styles['progressBarEnd']} onClick ={() => this.goTo('jobGeofence') } style={{width:'18%'}}>
            {selectedPage === 'jobGeofence' ?
              <img src={BlueGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} className={styles['progressBarIcons']}/>
              :
              !selectedId
              ?
              <img src={GreyGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} />
              :
              <img src={BlackGeofenceIcon} height="20px" width="15px" alt="Logo" style={{marginTop: '-6px' , marginRight: '8px'}} />
            }
            <span style={selectedPage==='jobGeofence' ? {color: '#09819A'} : !selectedId ? {color : '#B1BABF'} : {color : 'black'}}>Add/View Geofence</span>
          </button>
          
        </div>
        {/*<Row >
          <Col className="steps-form progress-bar-margin pb-2" md={8}>
            <div className="steps-row setup-panel">
              <div className="steps-step active-div">
                <p className="mb-0 job-title">Job Details</p>
                <span className="btn btn-circle active-btn" >&#10003;</span>
              </div>
              <div className={active > 1 ? "steps-step active-div" : "steps-step"}>
                <p className="mb-0 job-title">Add Task</p>
                <span className={active > 1 ? "btn btn-circle active-btn" : "btn btn-circle"}>&#10003;</span>
              </div>
              <div className={active > 2 ? "steps-step active-div" : "steps-step"}>
                <p className="mb-0 job-title">Add Employee</p>
                <span className={active > 2 ? "btn btn-circle active-btn" : "btn btn-circle"}>&#10003;</span>
              </div>
              <div className={active > 3 ? "steps-step active-div" : "steps-step"}>
                <p className="mb-0 job-title">Add Budget</p>
                <span className={active > 3 ? "btn btn-circle active-btn" : "btn btn-circle"}>&#10003;</span>
              </div>
              <div className={active > 4 ? "steps-step active-div" : "steps-step-geo"}>
                <p className="mb-0 job-title">Add Geofence</p>
                <span className={active > 4 ? "btn btn-circle active-btn" : "btn btn-circle"}>&#10003;</span>
              </div>
            </div>
          </Col>
        </Row>*/}
      </section>
    );
  }
}

JobProgressBarSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JobProgressBarSection;
