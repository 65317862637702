import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as RefreshIcon} from './refresh.svg';

const RefreshIconAtom = props => {
  const { active, isLoading, ...rest } = props;
  return (
    <div disabled={isLoading} {...rest} className={isLoading ? "text-center cursor-disabled float-right d-inline refresh-style" : "text-center cursor-pointer float-right d-inline refresh-style"}>
      <RefreshIcon height="20" width="20"/>
    </div>
  );
};

RefreshIconAtom.defaultProps = {
  //	height: 15,
  // width: 15,
};

RefreshIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default RefreshIconAtom;
