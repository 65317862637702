import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import SectionLoaderAtom from 'atoms/SectionLoader';

import {ACTIVITY_TYPE} from 'constants/employees'

import closeimage from './Close-Cross.png';

const activityArr ={
  punch_in : 'Punch In',
  punch_out : 'Punch Out',
  meal_start : 'Meal Start',
  meal_end : 'Meal End'
}
class ViewActivityModal extends React.PureComponent {
  constructor(props) {
    super(props);
   }
  
  render() {
    const { isOpen, toggle , isActivityModalLoading,activityLogs } = this.props;
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          backdrop='static'
          size="lg" className="info-form-modal" 
        >
          <ModalHeader className="mb-0 pb-0">Activity Details
            <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle(false)} />
          </ModalHeader>
          <ModalBody className="pt-2 info-modal-dt pl-0 pr-0 ml-3 mr-3">
            {isActivityModalLoading ? <SectionLoaderAtom active />
              :<div className="infinite-scroll-ovverride">
                <InfiniteScroll
                  dataLength={activityLogs.length}
                  hasMore={false}
                  height={477}
                  //loader={showMore?<div className="text-center"><img  width="auto" height="40px" src={LoaderIcon} alt="spinner"/></div>:null}
                >
                  {activityLogs  && activityLogs.map((item, index)=>{
                   
                    return(
                      <section className="mb-3 pl-3 pr-3 d-flex flex-row-reverse justify-content-between">
                        {item.activity_type &&<Row>
                          <Col className="d-inline-block">
                            { <p className="mb-0"><b>Activity Type</b></p>}
                            <p className="mb-0">{item?.activity_type?ACTIVITY_TYPE[item.activity_type]:null}</p>
                          </Col>
                        </Row>}
                        {item.datetime &&<Row>
                          <Col className="d-inline-block">
                            {<p className="mb-0"><b>Date Time</b></p>}
                            <p className="mb-0">{item.datetime_display}</p>
                          </Col>
                        </Row>}
                        {/*
                          <Row>
                            <Col className="d-inline-block">
                              <p className="mb-0"><b>Facial Recognition History</b></p>
                              <Row>
                                <Col md={8} className="pt-1">
                                  <img src={activityLogs.image_url} width="200" height="150" alt="Facial Recognition History" onClick={() => this.setState({isOverlayOpen: true, selectedIndex: index })} style={{cursor:'pointer'}}/>
                                </Col>
                              </Row>      
                            </Col>
                          </Row>
                        */ }
                      </section>
                    )
                  })}
                  {!activityLogs?.length &&
                    <div className="text-info-managingdt">There are no logs to show.</div>
                  }
                </InfiniteScroll>
              </div>
                } 
          </ModalBody>
        </Modal>
      </div>  
    );
  }
};


export default ViewActivityModal;
