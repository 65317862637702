import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import IncompleteSignUpFilterSection from 'sections/vendors/incompleteSignUpList/Filter';
import IncompleteSignUpListSection from 'sections/vendors/incompleteSignUpList/List';

import { NAV } from 'constants/vendors';

/**
 * IncompleteSignUpListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link VendorFilterSection}
 *    - {@link VendorListSection}
 *
 * Fetch Needs:
 *    - getVendors
*/
class IncompleteSignUpListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_list_incomplete_signup");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_list_incomplete_signup", !isInfoOpen);
  }

  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('incomplete_signup_page_size');
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.list.incomplete_signup'}),
      this.props.VendorDucks.getVendorsIncompleteSignUp({...params, page_size: pageSize})
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout 
        navInfo={NAV.IncompleteSignupListPage} 
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
          <IncompleteSignUpFilterSection />
          <IncompleteSignUpListSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

IncompleteSignUpListPage.propTypes = {
  VendorDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

IncompleteSignUpListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(IncompleteSignUpListPage);
