import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';


/**
 * PayrollFormPage -> PayrollFormSection -> ContactUsFormModalComponent
 *
 * Props:
 *    - update
 */
const ContactUsFormModalComponent = (props) => {
  const {  isOpen, submitting, handleSubmit, toggle, update } = props;

  const submit = data => update(data)
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <ModalBody>
          <Field
            id="message"
            name="message"
            label="Message"
            component={InputAtom}
            layout={FORM_LAYOUT}
            type="textarea"
            placeholder="Please share your query"
            maxLength="1000"
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.message) errors.message = 'Enter Message';
  return errors;
};

ContactUsFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ContactUsModal',
  validate,
})(ContactUsFormModalComponent);
