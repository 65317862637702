import StorageGateway from 'lib/storage-gateway';
import EventEmitter from 'lib/event-emitter';
import cookie from 'react-cookies';

const EVENT_TYPES = {
  SEND_EMAIL : 'SEND_EMAIL',
  LOGOUT_SUCCESS : 'LOGOUT_SUCCESS',
  SHOW_LOADER : 'SHOW_LOADER',
  GET_BILL_LIST_SUCCESS : 'GET_BILL_LIST_SUCCESS',
};


class AccountCancellationUsecase {
 
  userAPIGateway = null;
  billAPIGateway = null;

  constructor(userAPIGateway, billAPIGateway) {
    this.userAPIGateway = userAPIGateway;
    this.billAPIGateway = billAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  toString() {
    return 'accountCancellationUsecase';
  }

  

  async sendEmail(data){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.triggerEmail = await this.userAPIGateway.triggerEmail(data);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SEND_EMAIL,
    });
  }

  async logout(){
    await this.userAPIGateway.logout();
    StorageGateway.remove('company_id');
    StorageGateway.remove('oAuthToken');
    StorageGateway.remove('uniqueUuid');
    StorageGateway.remove('graniteToken');
    StorageGateway.remove('userId');
    cookie.remove('survey_type', { path: '/' })
    cookie.remove('survey_type_dashboard', { path: '/' })
    console.log(cookie)
    cookie.remove('Token', { path: '/' });
    cookie.remove('oAuthToken', { path: '/' });
    this.eventEmitter.emit({
      type: EVENT_TYPES.LOGOUT_SUCCESS,
    });
  }

    async backToMyDashboard(isLoggedInAdmin, isSubCompany){
    if (isLoggedInAdmin && isSubCompany) {
      let domain = window.location.hostname;
      const host = window.location.hostname.split('.');
      if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
      StorageGateway.set('company_id', isSubCompany);
      cookie.save('company_id', isSubCompany, { path: '/' , domain});     
    }
    else StorageGateway.remove('company_id', { path: '/' });
   
    setTimeout(() => {
      window.location.href = '/dashboard';
    }, 100);
  }


  async getBillList(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.billList = await this.billAPIGateway.getCompanyBillList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_BILL_LIST_SUCCESS,
      data: this.billList,
    });
  }

}
  
 


export default AccountCancellationUsecase;