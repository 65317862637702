import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';

import { toast } from 'react-toastify';

import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PayrollFormComponent from 'components/payrolls/form/Form';
import ContactUsFormModalComponent from 'components/payrolls/form/ContactUsForm';


import { MSGS } from 'constants/payrolls';

/**
 * PayrollFormPage -> PayrollFormSection
 *
 * Components:
 *    - {@link PayrollFormComponent}
 *    - {@link ContactUsFormModalComponent}
 *
 * State:
 *    - payrollDetail
 *
 * Actions:
 *    - Form:
 *        - postPayroll
 *        - deletePayroll
 */
class PayrollFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.create = this.create.bind(this);
    this.remove = this.remove.bind(this);
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.updatePayroll = this.updatePayroll.bind(this);
    this.deletePayrolltoggle = this.deletePayrolltoggle.bind(this);
    this.state = { isOpen : false, isDeletePayroll: false, isDeleting: false };
  }

  create(data) {
    const { router: { history } } = this.context;
    return this.props.PayrollDucks.postPayroll(data)
      .then(() => {
        toast.success(MSGS.PAYROLL_ADD_SUCCESS);
        history.push('/payroll/list');
      })
  }

  updatePayroll(data) {
    const { router: { history } } = this.context;
    return this.props.PayrollDucks.putPayroll(data)
      .then(() => {
        toast.success(MSGS.PAYROLL_EDIT_SUCCESS);
        history.push('/payroll/list');
      })
  }

  remove() {
    const { router: { history, route: { match: { params } } } } = this.context;
    this.setState({ isDeleting: true });
    return this.props.PayrollDucks.deletePayroll(params)
      .then(()=> this.setState({ isDeleting: false }))
      .then(() => {
        toast.success(MSGS.PAYROLL_DELETE_SUCCESS);
        history.push('/payroll/list');
      })
      .catch((err) => {
        toast.error(err.company_payroll);
        this.setState({isDeleting:false});
        history.push('/payroll/list');
      })
  }

  update(data) {
    return this.props.PayrollDucks.postPayrollContactUs(data)
      .then(() => {
        toast.success(MSGS.PAYROLL_CONTACT_US_SUCCESS);
        this.setState({ isOpen: !this.state.isOpen });
      });
  }

  toggle(data) {
    this.setState({ isOpen: !this.state.isOpen });
  }

  deletePayrolltoggle(){
    this.setState({ isDeletePayroll: !this.state.isDeletePayroll });
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen, isDeletePayroll, isDeleting } = this.state;
    const {
      basicSettingDetail, userCompany, dateFormat, isLoading, payrollDetail, timeFormat, timeFormat24Hrs,
    } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <PayrollFormComponent
          basicSettingDetail={basicSettingDetail}
          dateFormat={dateFormat}
          isDeletePayroll={isDeletePayroll}
          initialValues={
            params.id
              ? {
                ...payrollDetail,
                start_date: moment(payrollDetail.start_date).format(basicSettingDetail.date_format_display),
              }
              : { is_active: true }
          }
          timeFormat={timeFormat}
          timeFormat24Hrs={timeFormat24Hrs}
          create={this.create}
          remove={this.remove}
          deletePayrolltoggle={this.deletePayrolltoggle}
          updatePayroll={this.updatePayroll}
          isDeleting={isDeleting}
          userCompany={userCompany}
        />
        {isOpen &&
          <ContactUsFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            update={this.update}
          />
        }
      </section>
    );
  };
}

const mapStateToProps = state => ({
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  payrollDetail: PayrollDucks.payrollDetail(state),
  userCompany: UserDucks.userCompany(state),
});

const mapActionToProps = dispatch => ({
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
});

PayrollFormSection.propTypes = {
  basicSettingDetail: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  payrollDetail: PropTypes.object.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
};

PayrollFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PayrollFormSection);
