import React from 'react';

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as EmployeeDucks from 'ducks/employees/employee';
import SectionLoaderAtom from 'atoms/SectionLoader';

import BulkUploadFormComponent from 'common/BulkUploadForm';
/**
 * EmployeeListPage -> EmployeeBulkUploadSection
 *
 * Components:
 *    - {@link EmployeeBulkUploadFormComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class EmployeeBulkUploadSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.sampleFile = this.sampleFile.bind(this);
    this.state={errorEmp:{}, stats:{}, csvData : "", isBulkLoading: false }
  }
  
  update(data){
    this.setState({isBulkLoading: true});
    return this.props.EmployeeDucks.postBulkEmployee(data)
      .then((res) => {
        if(res.value.stats && (res.value.stats.created === res.value.stats.total)){
          toast.success("Employees Uploaded Succesfully")
        }
        if(res.value.stats && (res.value.stats.updated === res.value.stats.total)){
          toast.success("Employees Updated Succesfully")
        }
        this.setState({ errorEmp : {}, stats : res.value.stats, csvData : {}, isBulkLoading: false})
      })
      .catch((err) => {
        if(err.non_field_errors)
        {
          toast.error(err.non_field_errors[0]);
        }else
        if(err.employee){
          toast.error(err.employee);
          this.setState({isBulkLoading: false});
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }
        else if(err.stats && !(err.stats.created) && !(err.stats.updated)){
          toast.error("Unable to upload employees");
        }
        else if(err.stats && (err.stats.updated === err.stats.total)){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        else if(err.stats && ((err.stats.create_failed !== err.stats.total) || (err.stats.update_failed !== err.stats.total))){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        this.setState({ errorEmp : err.data, stats: err.stats, csvData : err.data_csv, isBulkLoading: false})
      })
  }

  sampleFile(){
    return this.props.EmployeeDucks.getBulkEmployee();
  }

  render() {
    const { isLoading } = this.props;
    const { errorEmp, stats, csvData, isBulkLoading } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <BulkUploadFormComponent
          update={this.update}
          sampleFile={this.sampleFile}
          errorEmp={errorEmp}
          stats={stats}
          csvData={csvData}
          isBulkLoading={isBulkLoading}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

EmployeeBulkUploadSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // update: PropTypes.func.isRequired,
  // EmployeeDucks: PropTypes.object.isRequired,
};

EmployeeBulkUploadSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EmployeeBulkUploadSection);