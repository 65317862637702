import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as TaskDucks from 'ducks/jobs/task';


import * as CustomerDucks from 'ducks/customers/customer';

import JobProgressBarSection from 'sections/jobs/form/ProgressBar';
import JobEmployeeEditSection from 'sections/jobs/jobEmployeeEdit/jobEmployeeEdit';
import TwoColumnLayout from 'layouts/TwoColumn';

import { NAV } from 'constants/jobs';

/**
 * JobEmployeeEditPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class JobEmployeeEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, parent: NAV.JobEditFormPage.parent, isJobAddInfo: true, isJobEditInfo: true };
    this.fetchData = this.fetchData.bind(this);
    this.fetchEmployees = this.fetchEmployees.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    this.props.InfoDucks.getInformations({route: 'job.employee.edit'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if ((prevQuery.department_id !== nextQuery.department_id) || (prevQuery.team_id !== nextQuery.team_id) ) {
      this.fetchEmployees(nextQuery);
    }else{
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
    this.props.TaskDucks.resetTask();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const detail = { ...data };
    delete detail.department_id;
    delete detail.team_id;
    let pageSize;
    if(data.page_size){
      pageSize = data.page_size;
    }else{
      pageSize = cookie.load("employee_job_page_size");
    }
    this.setState({ isLoading: true });
    this.props.EmployeeDucks.getAssignedEmployees({ ...detail, job_id: params.id, assigned: true, page_size: pageSize})
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  fetchEmployees(params){
    this.props.EmployeeDucks.getAllEmployees({ ...params, paginate: false });
  }

  loadData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location} = this.props;
    const {  query } = location;

    this.setState({ isLoading: true });
    const pageSize = cookie.load("employee_job_page_size");
    Promise.all([
      this.props.CustomerDucks.getCustomers({ paginate: false }),
      this.props.TaskDucks.getAssignedTasks({ job_id: params.id, assigned: true }),
      this.props.EmployeeDucks.getAssignedEmployees({ job_id: params.id, assigned: true, page_size: pageSize}),
      this.props.JobDucks.getJob(params),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    const cookieKey = cookie.load("jobs_edit_form_info");
    if(cookieKey) {
      this.setState({isJobEditInfo: JSON.parse(cookieKey)});
    }
    this.fetchData(query);
    this.fetchEmployees();
    this.setParentUrl(query);
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isJobAddInfo, isJobEditInfo } = this.state;
    if (params.id) {
      this.setState({ isJobEditInfo: !isJobEditInfo });
      cookie.save("jobs_edit_form_info", !isJobEditInfo);
    }
    else {
      this.setState({ isJobAddInfo: !isJobAddInfo });
      cookie.save("job_add_form_info", !isJobAddInfo);
    }
  }

  render() {
    const { isLoading ,isJobEditInfo, isJobAddInfo } = this.state;
    const {  location: { query }, infoList ,jobDetail} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const selectedId = params ? params.id : null; 

    return (
      <div>
        <TwoColumnLayout
          navInfo={query.setup ? NAV.JobAddFormPage : query.admin_list ? NAV.AdminJobEditFormPage : NAV.JobEditFormPage}
          infoList={infoList}
          isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          jobDetail={jobDetail}
        >
        <div className="pb-5">
           <Row className="pt-4 mr-4 ml-3" style={{backgroundColor: 'white'}}>
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <JobProgressBarSection
                    active={4}
                    selectedId={selectedId}
                    isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
                    addJob={query.setup}
                    location={this.props.location}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <JobEmployeeEditSection isLoading={isLoading} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

JobEmployeeEditPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  EmployeeDucks: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

JobEmployeeEditPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobEmployeeEditPage);
