import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as ArrowUpIcon} from './up-arrow.svg';
import {ReactComponent as ArrowDownIcon} from './down-arrow.svg';
// import HelpIcon from './down-arrow.svg';

const VideoCollapseIconAtom = (props) => {
  const { active, ...rest } = props;
  //  return active ? <div {...rest}>Hide help </div> : <div {...rest}>Show help <HelpIcon height="10" width="10"/></div>;
  return (
    <div {...rest} className="text-center cursor-pointer collapse-icon-wrapper">
      <div className="collapse-icon-text">{active ? "HIDE HELP" : "SHOW HELP"}
        { active ? <ArrowUpIcon height="14" width="14" className="collapse-icon"/> : <ArrowDownIcon height="14" width="14" className="collapse-icon"/>}
      </div>
    </div>
  );
};

VideoCollapseIconAtom.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default VideoCollapseIconAtom;