import React from 'react';

import PropTypes from 'prop-types';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;


class PolygonComponent extends React.PureComponent {
  componentDidMount() {
    if (!this.polygon) this.renderPolygon();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map) ||
      (this.props.fillColor !== prevProps.fillColor) ||
      (JSON.stringify(this.props.paths) !== JSON.stringify(prevProps.paths))
    ) {
      // The relevant props have changed
      if (this.props.paths.length) {
        this.renderPolygon();
      }
      else if (this.polygon) {
        this.polygon.setMap(null);
      }
    }
  }
  
  componentWillUnmount() {
    if (this.polygon) {
      this.polygon.setMap(null);
    }
  }

  renderPolygon() {
    const { map, google, paths, draggable, editable, setShapeInfo, fillColor, data, onClick } = this.props;
    const  bounds = new google.maps.LatLngBounds();
    const pref = {
      map,
      paths,
      fillOpacity: 0.5,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: fillColor || '#162d6e',
      strokeColor: fillColor || '#162d6e',
      draggable,
      editable,
      onClick,
    };
    let polygonAddress;
    let arr;

    if (this.polygon) {
      this.polygon.setMap(null);
    }
    this.polygon = new google.maps.Polygon(pref);
    this.polygon.setOptions({ options: pref });

    this.polygon.getPaths().forEach((path, index) => {

      var setAt = google.maps.event.addListener(path, 'set_at', e => {
        /*console.log('set_at event called')*/
        // New point
        /*console.log('e0');*/
        const points = [];
        path.i.forEach((item) => {
          arr = [ item.lng(), item.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        const polygon = {
          type: 'Polygon',
          coordinates: [points],
        };
        setShapeInfo(polygon);
      });

       google.maps.event.addListener(this.polygon, 'dragstart', e => {
        /*console.log('dragstart')*/
          google.maps.event.removeListener(setAt);
      });

      google.maps.event.addListener(path, 'remove_at', e => {
        /*console.log('remove_at event called')*/
        // Point was removed
        /*console.log('e2', e);*/
        const points = [];
        path.i.forEach((item) => {
          arr = [ item.lng(), item.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        const polygon = {
          type: 'Polygon',
          coordinates: [points],
        };
        setShapeInfo(polygon);
      });


      google.maps.event.addListener(path, 'insert_at', e => {
        /*console.log('insert_at event called')*/
        // New point
        //console.log('e1');
        const points = [];
        path.i.forEach((item) => {
          arr = [ item.lng(), item.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        const polygon = {
          type: 'Polygon',
          coordinates: [points],
        };
        setShapeInfo(polygon);
      });

      google.maps.event.addListener(path, 'remove_at', e => {
        /*console.log('remove_at event called')*/
        // Point was removed
        /*console.log('e2', e);*/
        const points = [];
        path.i.forEach((item) => {
          arr = [ item.lng(), item.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        const polygon = {
          type: 'Polygon',
          coordinates: [points],
        };
        setShapeInfo(polygon);
      });

      google.maps.event.addListener(this.polygon, 'dragend', e => {
        google.maps.event.addListener(setAt);
        /*console.log('dragend event called')*/
        // Point was moved
        /*console.log('e4', path);*/
        const points = [];
        path.i.forEach((item) => {
          arr = [ item.lng(), item.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        const polygon = {
          type: 'Polygon',
          coordinates: [points],
          address: '',
        };

        points.map(item => {
          const latLng = new google.maps.LatLng( item[1], item[0] );
          return bounds.extend(latLng);
        });
        polygonAddress = bounds.getCenter();
        const latitude = polygonAddress.lat();
        const longitude = polygonAddress.lng();
        const latLng = { lat: latitude, lng: longitude };
        if(GeocodingOption == 'google maps'){
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                polygon.address = results[0].formatted_address.toString();
              }
            }
            setShapeInfo(polygon);
          });
        }
        else{
          this.props.getAddressForMarker({position: latLng})
          .then((res) => {
             if(res.action.payload.data){
              const data = res.action.payload.data;
              polygon.address = data.display_name.toString();
              setShapeInfo(polygon);
            }
          })
        }
      });
    });

    this.polygon.addListener('click', (event) => {
      if (onClick) onClick(data, this.polygon, event);
      return false;
    });
  }

  render() {
    return null;
  }

}

PolygonComponent.propTypes = {
  map: PropTypes.object,
  paths: PropTypes.array,
  draggable: PropTypes.bool,
  editable: PropTypes.bool,
  setShapeInfo: PropTypes.func,
  fillColor: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

export default PolygonComponent;
