import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';
import { match } from 'ramda';

const GET_ACTIVITY_SPLITS = 'activities/activitySplit/GET_ACTIVITY_SPLITS';
const SET_ACTIVITY_SPLITS = 'activities/activitySplit/SET_ACTIVITY_SPLITS';
const GET_ACTIVITY_SPLITS_FULFILLED = 'activities/activitySplit/GET_ACTIVITY_SPLITS_FULFILLED';
const SET_ACTIVITY_SPLIT = 'activities/activitySplit/SET_ACTIVITY_SPLIT';
const PUT_ACTIVITY_SPLIT = 'activities/activitySplit/PUT_ACTIVITY_SPLIT';
const PUT_ACTIVITY_SPLIT_FULFILLED = 'activities/activitySplit/PUT_ACTIVITY_SPLIT_FULFILLED';
const POST_ACTIVITY_AND_TASK = 'activities/activitySplit/POST_ACTIVITY_AND_TASK';
const POST_ACTIVITY_AND_TASK_FULFILLED = 'activities/activitySplit/POST_ACTIVITY_AND_TASK_FULFILLED';
const POST_ADD_MEAL = 'activities/activitySplit/POST_ADD_MEAL';
const POST_ADD_MEAL_FULFILLED = 'activities/activitySplit/POST_ADD_MEAL_FULFILLED';
const DELETE_ACTIVITY_AND_TASK = 'activities/activitySplit/DELETE_ACTIVITY_AND_TASK';
const DELETE_ACTIVITY_AND_TASK_FULFILLED = 'activities/activitySplit/DELETE_ACTIVITY_AND_TASK_FULFILLED';
const RESET_ACTIVITY_SPLITS = 'activities/missingActivity/RESET_ACTIVITY_SPLITS';
const SET_SELECTED_SHIFT = 'activities/activitySplit/SET_SELECTED_SHIFT';
const GET_ACTIVITY_LOGS = 'activities/activitySplit/GET_ACTIVITY_LOGS';
const GET_ACTIVITY_LOGS_FULFILLED = 'activities/activitySplit/GET_ACTIVITY_LOGS_FULFILLED';
const RESET_ACTIVITY_LOGS = 'activities/activitySplit/RESET_ACTIVITY_LOGS';
const RESET_ACTIVITY_LOGS_FULFILLED = 'activities/activitySplit/RESET_ACTIVITY_LOGS_FULFILLED';
const PAY_MEAL = 'activities/activitySplit/PAY_MEAL';
const DELETE_PAID_TIME_OFF_REQUEST='activities/paidTimeOff/DELETE_PAID_TIME_OFF_REQUEST'
const DELETE_PAID_TIME_OFF_REQUEST_FULFILLED = 'activities/paidTimeOff/DELETE_PAID_TIME_OFF_REQUEST_FULFILLED';

const UPDATE_PAID_TIME_OFF_REQUEST='activities/paidTimeOff/UPDATE_PAID_TIME_OFF_REQUEST'
const UPDATE_PAID_TIME_OFF_REQUEST_FULFILLED = 'activities/paidTimeOff/UPDATE_PAID_TIME_OFF_REQUEST_FULFILLED';
const GET_PAID_TIME_OFF_REQUEST='activities/paidTimeOff/GET_PAID_TIME_OFF_REQUEST'


export function getActivitySplits(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_ACTIVITY_SPLITS,
    payload: axios.get(`${URLS.ACTIVITY}${id}/${URLS.ACTIVITY_SPLIT_V2}`, { params }),
  };
}

export function setActivitySplit(data) {
  return {
    type: SET_ACTIVITY_SPLIT,
    payload: data,
  };
}

export function setActivitySplits(data) {
  return {
    type: SET_ACTIVITY_SPLITS,
    payload: data,
  };
}


export function setSelectedShift(data) {
  return {
    type: SET_SELECTED_SHIFT,
    payload: data,
  };
}

export function putActivitySplit(data) {
  let url = `${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${data.id}/`;
  if (data.beta) url = `${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${data.id}/?beta=true&seconds=${data.seconds}`;
  return {
    type: PUT_ACTIVITY_SPLIT,
    payload: axios.put(url, data),
  };
}

export function postActivityAndTask(data) {
  return {
    type: POST_ACTIVITY_AND_TASK,
    payload: axios.post(`${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${URLS.ADD_ACTIVITY_TASK}`, data),
  };
}

export function postaddMeal(data) {
  let url = `${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${URLS.MEAL_PERIOD_SESSION}`;
  if (data.beta) url = `${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${URLS.MEAL_PERIOD_SESSION}?beta=true?seconds=${data.seconds}`;
  return {
    type: POST_ADD_MEAL,
    payload: axios.post(url, data),
  };
}

export function deleteActivityAndTask(data) {
  return {
    type: DELETE_ACTIVITY_AND_TASK,
    payload: axios.delete(`${URLS.ACTIVITY}${URLS.DELETE_ACTIVITY_TASK}${data.id}/`),
  };
}

export function resetActivitySplits() {
  return {
    type: RESET_ACTIVITY_SPLITS,
  };
}

export function getActivityLogs(params) {
  return {
    type: GET_ACTIVITY_LOGS,
    payload: axios.get(`${URLS.T2B}${URLS.AUDIT_LOG}/`, { params })
  };
}

export function payMeal(params) {
  const obj ={
    is_paid : params.is_paid,
    include_rt_ot_dot : params.include_rt_ot_dot
  }
  return {
    type: PAY_MEAL,
    payload: axios.put(`${URLS.PAY_MEAL}${params.id}/`, {...obj})
  };
}

export function resetActivityLogs() {
  return {
    type: RESET_ACTIVITY_LOGS,
  };
}

export function deletePaidTimeOffRequest(data) {
  return {
    type: DELETE_PAID_TIME_OFF_REQUEST,
    payload: axios.delete(`${URLS.PTO_REQUESTS}/${data.id}/`),
  };
}

export function updatePaidTimeOffRequest(params) {
  const obj ={
    status : params.status,
  }
  return {
    type: UPDATE_PAID_TIME_OFF_REQUEST,
    payload: axios.put(`${URLS.PTO_TIMECARD}/${params.id}/`,{...obj}),
  };
}

export function getPaidTimeOffRequest(data) {
  return {
    type: GET_PAID_TIME_OFF_REQUEST,
    payload: axios.get(`${URLS.PTO_REQUESTS}/${data}/`),
  };
}

const defaultState = {
  list: [],
  detail: {},
  shift: null,
  logs:[],
};

function GET_ACTIVITY_SPLITS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ACTIVITY_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    logs: action.payload,
  });
}

function RESET_ACTIVITY_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    logs: [],
  });
}


function SET_ACTIVITY_SPLIT_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function SET_SELECTED_SHIFT_REDUCER(state, action) {
  return Object.assign({}, state, {
    shift: action.payload,
  });
}

function SET_ACTIVITY_SPLITS_REDUCER(state, action) {
  const arr = state.list.map((item) =>{
    if(action.payload.selectedShiftId === item.id) {
      const arr1 = item.split_list.map((i) => {
        if(i.id === action.payload.id) {
          const { selectedShiftId, ...rest } = action.payload;
          return rest;
        }
       return i;
      });
      return { ...item, split_list: arr1 };
    }
   return item;
  })

  return Object.assign({}, state, {
    list: arr,
  });
}

function PUT_ACTIVITY_SPLIT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ACTIVITY_AND_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ADD_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function DELETE_ACTIVITY_AND_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_ACTIVITY_SPLITS_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function DELETE_PAID_TIME_OFF_REQUEST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
} 

function UPDATE_PAID_TIME_OFF_REQUEST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
} 
const handlers = {
  [GET_ACTIVITY_SPLITS_FULFILLED]: GET_ACTIVITY_SPLITS_FULFILLED_REDUCER,
  [GET_ACTIVITY_LOGS_FULFILLED]: GET_ACTIVITY_LOGS_FULFILLED_REDUCER,
  [SET_ACTIVITY_SPLIT]: SET_ACTIVITY_SPLIT_REDUCER,
  [SET_SELECTED_SHIFT]: SET_SELECTED_SHIFT_REDUCER,
  [SET_ACTIVITY_SPLITS]: SET_ACTIVITY_SPLITS_REDUCER,
  [PUT_ACTIVITY_SPLIT_FULFILLED]: PUT_ACTIVITY_SPLIT_FULFILLED_REDUCER,
  [POST_ACTIVITY_AND_TASK_FULFILLED]: POST_ACTIVITY_AND_TASK_FULFILLED_REDUCER,
  [POST_ADD_MEAL_FULFILLED]: POST_ADD_MEAL_FULFILLED_REDUCER,
  [DELETE_ACTIVITY_AND_TASK_FULFILLED]: DELETE_ACTIVITY_AND_TASK_FULFILLED_REDUCER,
  [RESET_ACTIVITY_SPLITS]: RESET_ACTIVITY_SPLITS_REDUCER,
  [RESET_ACTIVITY_LOGS_FULFILLED]: RESET_ACTIVITY_LOGS_FULFILLED_REDUCER,
  [DELETE_PAID_TIME_OFF_REQUEST_FULFILLED]:DELETE_PAID_TIME_OFF_REQUEST_FULFILLED_REDUCER,
  [UPDATE_PAID_TIME_OFF_REQUEST_FULFILLED]:UPDATE_PAID_TIME_OFF_REQUEST_FULFILLED_REDUCER

};


const activitySplitSelector = state => state.activities.activitySplit;

export const activitySplitList = createSelector(
  activitySplitSelector,
  instance => instance.list,
);

export const activitySplitDetail = createSelector(
  activitySplitSelector,
  instance => instance.detail,
);

export const activityShift = createSelector(
  activitySplitSelector,
  instance => instance.shift,
);

export const activityLogs = createSelector(
  activitySplitSelector,
  instance => instance.logs,
);

export default createReducer(defaultState, handlers);
