import React from 'react';

import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import DateTimePickerAtom from 'atoms/DateTimePicker';

import { FILTER_FORM_LAYOUT } from 'constants/layout';

const PluginSettingsTimecardFilterFormComponent = (props, context) => {
  const { router: { history } } = context;
  const {
    dateFormat,
    location: { pathname, query },
    handleSubmit,
    error,
  } = props;
  const { page, ...rest } = query;


  const submit = data => {
    const detail = { ...data };
    if (detail.start && detail.end) {
      const formattedStartDate = moment(detail.start)
      const formattedEndDate = moment(detail.end)
      if (formattedStartDate > formattedEndDate) {
        const errObj = {
          _error: 'The Start date should be less than or equal to end date.',
        };
        throw new SubmissionError(errObj);
      }
    }
    const { page: pageNo, ...restDetail } = detail;
    restDetail.start = moment(data.start).format('YYYY-MM-DD');
    restDetail.end = moment(data.end).format('YYYY-MM-DD');
    cookie.save('startDate', restDetail.start, { path: '/' });
    cookie.save('endDate', restDetail.end, { path: '/' });

    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail }),
    });
  };

  return (
    <Form inline onSubmit={handleSubmit(submit)} className="report-filter mt-3">
      <Field
        id="start"
        name="start"
        placeholder="Start Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Field
        id="end"
        name="end"
        placeholder="End Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Button
        type="submit"
        color="accent"
        style={{ marginBottom: '24px' }}
        size={FILTER_FORM_LAYOUT.size}
        className="pr-4 pl-4"
      >
        Apply
      </Button>
      <div style={{ color: '#d9534f', marginTop: '-20px', 'font-size': '12px'}}>
        {error}
      </div>
    </Form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  const formattedStartDate=moment(values.start_date).format();
  const formattedEndDate=moment(values.end_date).format();
  if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
  return errors;
};

PluginSettingsTimecardFilterFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
};

PluginSettingsTimecardFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'PluginSettingsFilterForm',
  validate,
  enableReinitialize: true,
})(PluginSettingsTimecardFilterFormComponent);
