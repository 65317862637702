import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  LOGIN: `${SERVER_URL}/t2b/accounts/login/`,
  REGISTER: `${SERVER_URL}/t2b/accounts/register/`,
  ACTIVATE: `${SERVER_URL}/t2b/accounts/activate/`,
  REQUEST_PASSWORD_RESET: `${SERVER_URL}/t2b/accounts/forgot-password/`,
  RESET_PASSWORD: `${SERVER_URL}/t2b/accounts/forgot-password/reset/`,
  CHANGE_PASSWORD: `${SERVER_URL}/t2b/accounts/password/change/`,
  CHANGE_EMAIL: `${SERVER_URL}/t2b/accounts/change-email/`,
  LOGOUT: `${SERVER_URL}/t2b/accounts/logout/`,
  PROFILE: `${SERVER_URL}/t2b/accounts/me/`,
  ACCESS: `${SERVER_URL}/t2b/access/`,
  EXTRA_DATA: `${SERVER_URL}/t2b/accounts/user/extra-data/`,
  OTP_ACTIVATION:`submit/`,
  OAUTH_LOGIN: `${SERVER_URL}/o/token/`,
  CONTEXT: 'permission/all',
  AUTH_TOKEN: `${SERVER_URL}/t2b/accounts/get-token/`,
  OTP_VERIFICATION: `${SERVER_URL}/company/activate/`,
  GET_OTP : `${SERVER_URL}/company/resend-activation/`,
  ORDER_USER_PREFERENCE : `${SERVER_URL}/t2b/accounts/user_preference/view/`,
  DONT_SHOW_PTO_WARNING: `${SERVER_URL}/t2b/accounts/user/`,

};
