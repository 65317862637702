import { routerReducer } from 'react-router-redux';
import combineReducers from 'redux/lib/combineReducers';
import { reducer as formReducer } from 'redux-form';

import accounts from './accounts';
import activities from './activities';
import employees from './employees';
import payrolls from './payrolls';
import reports from './reports';
import timecards from './timecards';
import vendors from './vendors';
import jobs from './jobs';
import info from './info';
import geoFences from './geoFences';
import businessAdmins from './businessAdmins';
import knowledgeBases from './knowledgeBases';
import customers from './customers';
import notifications from './notifications';

const appReducer = combineReducers({
  accounts,
  vendors,
  employees,
  payrolls,
  timecards,
  activities,
  reports,
  customers,
  businessAdmins,
  knowledgeBases,
  jobs,
  info,
  geoFences,
  notifications,
  form: formReducer,
  router: routerReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'accounts/user/RESET_STATE') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
