import React, {PureComponent} from 'react';
import payMealIcon from './paidMeal.png';
import unpaidMealIcon from './unpaidMeal.png';
import greenTickIcon from './greenTick.png'
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import EditIconAtom from 'atoms/EditIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';
import connect from 'react-redux/lib/connect/connect';
import reduxForm from 'redux-form/lib/reduxForm';
import NoNotificationIcon from './error.png';


class MealCardComponent extends PureComponent {

  render() {
  	const { mealRuleList, openDeleteFormModal, openEditForm} = this.props;
    return (
    	<div>
    	<Row>
    		{mealRuleList && mealRuleList.length ? mealRuleList.map((item)=> {
    			const extraData = item.extra_data;
      		return (
      			<Form noValidate>
	      			<Row className="pl-3">
		      			<Col sm="12">
		      				<div className="meal-card mr-2">
			       				<div style={{fontSize: '27px', fontWeight: 'bold'}}>{extraData.duration}
                      <DeleteIconAtom
                        className="cursor-pointer float-right mr-2 mt-2" onClick={() => openDeleteFormModal(item.id)} />
                      <EditIconAtom className="cursor-pointer float-right mr-2 mt-2" onClick={() => openEditForm(item.id)}/>
                  </div>

			       				<div>Meal Break Duration <span style={{color : '#242424'}}>{item.is_default ? '(default)' : null}</span></div>
			       				<div className="mt-3" style={{fontWeight: 'bold'}}>{extraData.start_time.hours}<span>  Hrs  </span>{extraData.start_time.mins}<span>  Mins</span></div>
			       				<div>Maximum Time Before Meal Start</div>
			       				<div className="mt-3" style={{fontWeight: 'bold'}}>{extraData.waiver.hours}<span>  Hrs  </span>{extraData.waiver.mins}<span>  Mins</span></div>
			       				<div>Waiver Period</div>
			       				<div className="mt-3" style={{fontWeight: 'bold'}}>{extraData.override_meal_payment ? 'Paid' : 'Unpaid'}{extraData.override_meal_payment ?  <span style={{marginLeft: '3px'}}><img src={payMealIcon} height="15px" width="15px" alt="Logo" /></span> : <span style={{marginLeft : '3px'}}><img src={unpaidMealIcon} height="15px" width="15px" alt="Logo" /> </span>}</div>
                    <div className="mt-3 mb-3" style={{fontWeight: 'bold'}}>Notifications-{item.notifications.length>0 ?  'Enable' : 'Disable'}{item.notifications.length>0 ?<span style={{marginLeft: '3px'}}> <img src={greenTickIcon} height="15px" width="15px" alt="Logo" /></span> : <span style={{marginLeft: '3px'}}> <img src={NoNotificationIcon} height="15px" width="15px" alt="Logo" /></span>}</div>
	    						</div>
	      				</Col>
	      			</Row>
      			</Form>
    				);})
					: null
				}
    	</Row>
    	</div>);
  		}
	}

	const MealCardForm = reduxForm({
  form: 'MealCardForm',
  enableReinitialize: true,
})(MealCardComponent);

export default connect(
)(MealCardForm);
