import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as ResetPasswordIcon} from './reset-password.svg';

const ResetPasswordIconAtom = props => (
  <ResetPasswordIcon {...props} />
);

ResetPasswordIconAtom.defaultProps = {
  height: 15,
  width: 15,
};

ResetPasswordIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default ResetPasswordIconAtom;
