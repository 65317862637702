import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_ROLES = 'vendors/role/GET_ROLES';
const GET_ROLES_FULFILLED = 'vendors/role/GET_ROLES_FULFILLED';
const GET_ALL_ROLES = 'vendors/role/GET_ALL_ROLES';
const GET_ALL_ROLES_FULFILLED = 'vendors/role/GET_ALL_ROLES_FULFILLED';
const GET_ROLE = 'vendors/role/GET_ROLE';
const GET_ROLE_FULFILLED = 'vendors/role/GET_ROLE_FULFILLED';
const POST_ROLE = 'vendors/role/POST_ROLE';
const POST_ROLE_FULFILLED = 'vendors/role/POST_ROLE_FULFILLED';
const PUT_ROLE = 'vendors/role/PUT_ROLE';
const PUT_ROLE_FULFILLED = 'vendors/role/PUT_ROLE_FULFILLED';
const DELETE_ROLE = 'vendors/role/DELETE_ROLE';
const GET_ROLE_IDS = 'vendors/role/GET_ROLE_IDS';
const GET_ROLE_IDS_FULFILLED = 'vendors/role/GET_ROLE_IDS_FULFILLED';

export function getRoles(params) {
  return {
    type: GET_ROLES,
    payload: axios.get(URLS.ROLE, { params }),
  };
}

export function getAllRoles(params) {
  return {
    type: GET_ALL_ROLES,
    payload: axios.get(URLS.ROLE, { params }),
  };
}

export function getRole(data) {
  return {
    type: GET_ROLE,
    payload: axios.get(`${URLS.ROLE}${data.id}/`, data),
  };
}

export function putRole(data) {
  return {
    type: PUT_ROLE,
    payload: axios.put(`${URLS.ROLE}${data.id}/`, data),
  };
}

export function postRole(data) {
  return {
    type: POST_ROLE,
    payload: axios.post(URLS.ROLE, data),
  };
}

export function deleteRole(data) {
  return {
    type: DELETE_ROLE,
    payload: axios.delete(`${URLS.ROLE}${data.id}/`, data),
  };
}

export function getRolesIds() {
  return {
    type: GET_ROLE_IDS,
    payload: axios.get(`${URLS.REORDERED_IDS}?entity=role`),
  };
}


const defaultState = {
  list: { results: [] },
  detail: {},
  all: [],
  rolesId: [],
};

function GET_ROLES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ALL_ROLES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_ROLE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ROLE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_ROLE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ROLE_IDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    rolesId: action.payload,
  });
}

const handlers = {
  [GET_ROLES_FULFILLED]: GET_ROLES_FULFILLED_REDUCER,
  [GET_ALL_ROLES_FULFILLED]: GET_ALL_ROLES_FULFILLED_REDUCER,
  [GET_ROLE_FULFILLED]: GET_ROLE_FULFILLED_REDUCER,
  [POST_ROLE_FULFILLED]: POST_ROLE_FULFILLED_REDUCER,
  [PUT_ROLE_FULFILLED]: PUT_ROLE_FULFILLED_REDUCER,
  [GET_ROLE_IDS_FULFILLED]: GET_ROLE_IDS_FULFILLED_REDUCER,
};


const roleSelector = state => state.vendors.role;
const groupSelector = state => state.vendors.group;

export const roleList = createSelector(
  roleSelector,
  instance => instance.list
);

export const roleAll = createSelector(
  roleSelector,
  instance => instance.all
);

export const roleDetail = createSelector(
  roleSelector,
  groupSelector,
  (role, group) => {
    if (Object.keys(role.detail).length > 0) {
      group.list.map(item => {
        const obj = {};
        if (role.detail.groups.indexOf(item.group.id) >= 0) {
          obj[item.group.name] = true;
          Object.assign(role.detail, obj);
          return role.detail;
        }
        obj[item.group.name] = false;
        Object.assign(role.detail, obj);
        return role.detail;
      });
    }
    return role.detail;
  }
);

export const roleIdsList = createSelector(
  roleSelector,
  instance => instance.rolesId
);

export default createReducer(defaultState, handlers);
