import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import KioskEmployeesAuditListSection from 'sections/employees/list/KioskEmployeesAuditList';
import KioskEmployeesAuditFilterSection from 'sections/employees/list/KioskEmployeesAuditFilter';

import { NAV } from 'constants/employees';

/**
 * KioskEmployeesAuditListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link KioskEmployeesAuditFilterSection}
 *    - {@link KioskEmployeesAuditListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class KioskEmployeesAuditListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.state = { isLoading: false, parent: '/dashboard', isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    this.props.InfoDucks.getInformations({route: 'employee.kiosk_audit_list'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        if (params.id) {
          this.setState({
            parent: `${NAV.EmployeeEditFormPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        } 
        else if(query.parent_url) {
          this.setState({ parent: JSON.parse(query.parent_url)})
        }
        else {
          this.setState({
            parent: `${NAV.EmployeeAddFormPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        }

      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(params) {
    const pageSize = cookie.load("kiosk_employees_page_size");

    this.setState({ isLoading: true });
    return this.props.EmployeeDucks.getEmployeesAuditLogs({...params, page_size: pageSize})
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { location } = this.props;
    const {  query } = location;
    this.setParentUrl(query);
    this.fetchData(query);
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("employee.kiosk_audit_edit", !isInfoOpen);
  }

  render() {
    const { isLoading, parent, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout
        navInfo={{ ...NAV.EmployeeAuditLogPage, parent }}
        fetchData={this.loadData}
        infoList={infoList}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
      >
        <KioskEmployeesAuditFilterSection />
        <KioskEmployeesAuditListSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

KioskEmployeesAuditListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

KioskEmployeesAuditListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KioskEmployeesAuditListPage);