import React from 'react';

import PropTypes from 'prop-types';

import EyeView from './EyeView.png';
import { UncontrolledTooltip } from 'reactstrap';

import styles from './styles.module.scss';

import EditorAtom from '../../surveys/components/editorAtom';


class EditorFloatAtom extends React.PureComponent {
  constructor(props){
    super(props);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {labelCls:false, labelValue: props.value}
  }

  handleOnFocus(){
    this.setState({labelCls: true, labelValue: '', containValue: null});
  }

  handleOnBlur(){
    if(!this.state.containValue){
      this.setState({labelValue: this.props.value, labelCls: false})
    }
  }

  handleChange(val){
    this.props.onChange(val);
    this.setState({containValue: val});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ labelValue: nextProps.value });
    }
  }

  render() {
    const {onChange , onBlur,item, index, placeholder, disabled, onFocus, mandatory, key } = this.props;
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}} className={this.state.labelCls ? "tiny-label-show" : "tiny-label"}>
          <div dangerouslySetInnerHTML={{ __html: this.props.value }} className={styles['float-editor-label']}></div>
          <div id={`label-target${index}`}><img src={EyeView} width="10" height="10"/></div>
          <UncontrolledTooltip placement="left" target={`label-target${index}`} dangerouslySetInnerHTML={{ __html: this.props.value }} style={mandatory ? {textTransform: 'uppercase'} : null}></UncontrolledTooltip>
        </div>
        {/* <Editor
          init={{
            selector: '#questionEditor',
            indentation: '20pt',
            content_style: "body {font-size: 12pt;  style_format: bold;}",
            placeholder: placeholder,
            height: 150,
            menubar: false,
            branding: false,
            statusbar: false,
            plugins: 'lists',
            toolbar: 'alignleft aligncenter alignright | bold italic | bullist numlist',
            formats: {
              'custom-wrapper': { block: 'div', classes: 'wrapper', wrapper: true }
            }
          }}
          onEditorChange={(val)=>this.handleChange(val)}
          value={this.state.labelValue}
          disabled={disabled}
          onBlur={this.handleOnBlur}
          onFocus={this.handleOnFocus}
          key={key}
        /> */}

<EditorAtom multilineToolbar={false}
         value={this.state.labelValue}
         placeholder={placeholder}
         disabled={disabled}
         onChange={(val)=>this.handleChange(val)}
          toolbarItems={'alignLeft,alignCenter,alignRight,alignJustify,|,bold,italic,|,orderedList,bulletList'}
          toolbarPosition={`#htmlEditorFooter${index}`}
        />
        <div id={`htmlEditorFooter${index}`}></div>
        
      </div>
    );
  }
}


export default EditorFloatAtom;
