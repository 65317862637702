import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as NotificationIcon} from './notification.svg';

const NotificationIconAtom = props => {
  const { ...rest } = props;
  return (
    <div {...rest} className="text-center cursor-pointer float-right d-inline refresh-style pt-2 pr-0 mr-3 text-right" style={{width : '200px'}}>
      <NotificationIcon height="22" width="16"/>
    </div>
  );
};

NotificationIconAtom.defaultProps = {
};

NotificationIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default NotificationIconAtom;