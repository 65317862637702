import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';
import styles from './styles.module.scss';

import EditIcon from 'components/jobs/jobGeofence/Edit.png';
import DeleteIcon from 'components/jobs/jobGeofence/Delete.png';
import SectionLoaderAtom from 'atoms/SectionLoader';
/**
 * JobTaskMappingListPage -> JobTaskMappingListSection -> JobTaskMappingTableComponent
 *
 * Props:
 *    - task list
 */
const JobTaskMappingTableComponent = (props, context) => {
  const { updateSelected, location, data, toggle, deleteTask,
    isTaskTableLoading, actionsRequired } = props;
  const { router: { route: { match } } } = context;
  const { query } = location;
  if(isTaskTableLoading) return <SectionLoaderAtom active/>;
  return (
    <div className="ml-3 mr-4">
      <Table hover size="sm" className={match.params.id ? "table-task mt-3" : "table-task"}
      style={{ boxShadow: "0px 3px 6px rgba(0,0,0,0.16)" }}>
      <thead>
        <tr className={styles.header_color}>
          {query &&query.view === 'false' && query.assigned !== '' ? <th>Action</th> : null }
          { !query.job_id && !actionsRequired && <th className="text-center"> Action </th>}
          <th className="text-left">Task Code</th>
          <th className="text-left">Name</th>
          <th className="text-left">Tags</th>
          <th className="text-left">Billable</th>
          <th className="text-right">Hourly Bill Rate</th>
          <th className="text-right pr-3">Employee Fringe %</th>
        </tr>
      </thead>
      <tbody className="white-background">
        {data && data.results.length ? data.results.map(item => {
          const tagNames = item.tags.map(tagName=>` ${tagName}`);
          const allTags=tagNames.join();
          return(
            <tr key={item.id}>
              {query && query.view === 'false' && query.assigned !== '' ?
                <td title="Select to Mark Assign/Unassign to job">
                  <input
                    type="checkbox"
                    value={item.isSelected}
                    onChange={() => updateSelected(item)}
                  />
                </td>
                : null }
              <td title="Delete task" className="text-center pl-1">
                {!query.setup &&
                  <span title="Edit Task" role='button' tabIndex={0} onClick={() => toggle(item)} className='cursor-pointer outline-none m-1'>
                    <img alt="" src={EditIcon} height="15" width="15" />
                  </span>
                }
                <span title="Delete Task"  role='button' tabIndex={0} onClick={() => deleteTask(item)} className='cursor-pointer outline-none m-1'>
                  <img alt="" src={DeleteIcon} height="15" width="15" />
                </span>
              </td>
              <td title={item.code} className="text-left">{item.code}</td>
              <td title={item.name} className="text-left">{item.name}</td>
              <td title={item.tags && item.tags.length && item.tags.map((item)=> ` ${item}`)} className="text-left">{allTags}</td>
              <td title={item.is_billable ? 'true' : 'false'} className="text-left">
                {item.is_billable ? 'Yes' : 'No'}
              </td>
              <td className="text-right">{item.billed_rate}</td>
              <td className="text-right pr-3">{item.employee_fringe}</td>
            </tr>
          )}) : null}
        {!data.results.length &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
      </Table>
    </div>
  );
};

JobTaskMappingTableComponent.propTypes = {
  updateSelected: PropTypes.func,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

JobTaskMappingTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JobTaskMappingTableComponent;
