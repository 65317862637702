import React from 'react';

import PropTypes from 'prop-types';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

let timeout;

class CircleComponent extends React.PureComponent {
  componentDidMount() {
    if (!this.circle) this.renderCircle();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map) ||
      (this.props.radius !== prevProps.radius) ||
      (this.props.fillColor !== prevProps.fillColor) ||
      (JSON.stringify(this.props.center) !== JSON.stringify(prevProps.center))
    ) {
      // The relevant props have changed
      if (Object.keys(this.props.center).length && this.props.radius) {
        this.renderCircle();
      }
      else if (this.circle) {
        this.circle.setMap(null);
      }
    }
  }

  componentWillUnmount() {
    if (this.circle) {
      this.circle.setMap(null);
    }
  }

  renderCircle() {
    const {
      map, google, center, radius, draggable, editable, fillColor,
      setShapeInfo, data, onClick,handlePositionChange
    } = this.props;
    const pref = {
      map,
      center,
      radius,
      fillOpacity: 0.5,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: fillColor || '#162d6e',
      strokeColor: fillColor || '#162d6e',
      draggable,
      editable,
      onClick,
    };
    if (this.circle) this.circle.setMap(null);
    this.circle = new google.maps.Circle(pref);

    this.circle.addListener('radius_changed', (event) => {
      const circle = {
        type: 'Circle',
        coordinates: [ this.circle.getCenter().lng(), this.circle.getCenter().lat() ],
        radius: this.circle.getRadius(),
      };
      handlePositionChange();
      setShapeInfo(circle);
    });

    this.circle.addListener('center_changed', () => {
      handlePositionChange();
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
      const circle = {
        type: 'Circle',
        coordinates: [ this.circle.getCenter().lng(), this.circle.getCenter().lat() ],
        radius: this.circle.getRadius(),
        address: '',
      };

      //setShapeInfo(circle);
      if(GeocodingOption == 'google maps'){
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: center }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              circle.address = results[0].formatted_address.toString();
              setShapeInfo(circle);
            }
          }
        });
      }
      else{
        this.props.getAddressForMarker({position: center})
          .then((res) => {
             if(res.action.payload.data){
              const data = res.action.payload.data;
              circle.address = data.display_name.toString();
              setShapeInfo(circle);
            }
          })
      }
    }, 500);
    });

    this.circle.addListener('click', (event) => {
      if (onClick) onClick(data, this.circle, event);
      return false;
    });
  }

  render() {
    return null;
  }
}

CircleComponent.propTypes = {
  map: PropTypes.object,
  radius: PropTypes.number,
  center: PropTypes.object,
  draggable: PropTypes.bool,
  editable: PropTypes.bool,
  setShapeInfo: PropTypes.func,
  fillColor: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object,
};

export default CircleComponent;
