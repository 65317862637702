import React from "react";

const NotificationMailIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.75 1.5H18.75C19.85 1.5 20.75 2.4 20.75 3.5V9.55493C20.4217 9.51863 20.088 9.5 19.75 9.5C15.1174 9.5 11.3024 13 10.8049 17.5H2.75C1.65 17.5 0.75 16.6 0.75 15.5V3.5C0.75 2.4 1.65 1.5 2.75 1.5ZM11.81 9.84L18.35 5.75C18.4503 5.69371 18.5381 5.61766 18.6081 5.52645C18.6781 5.43525 18.7289 5.33078 18.7574 5.21937C18.7859 5.10796 18.7915 4.99194 18.7738 4.87831C18.7562 4.76468 18.7157 4.65581 18.6547 4.5583C18.5938 4.46079 18.5137 4.37666 18.4193 4.311C18.3249 4.24533 18.2181 4.19951 18.1055 4.1763C17.9929 4.15309 17.8767 4.15297 17.764 4.17595C17.6514 4.19893 17.5445 4.24453 17.45 4.31L10.75 8.5L4.05 4.31C3.95546 4.24453 3.84863 4.19893 3.73595 4.17595C3.62328 4.15297 3.50712 4.15309 3.3945 4.1763C3.28187 4.19951 3.17513 4.24533 3.08073 4.311C2.98632 4.37666 2.90623 4.46079 2.84528 4.5583C2.78434 4.65581 2.74381 4.76468 2.72616 4.87831C2.7085 4.99194 2.71409 5.10796 2.74258 5.21937C2.77106 5.33078 2.82186 5.43525 2.89189 5.52645C2.96192 5.61766 3.04973 5.69371 3.15 5.75L9.69 9.84C10.34 10.25 11.16 10.25 11.81 9.84Z"
        fill="#027F9D"
      />
      <path
        d="M13.7083 20.7917C13.5549 20.7917 13.4263 20.7397 13.3227 20.6357C13.219 20.5317 13.167 20.4031 13.1667 20.25C13.1663 20.0969 13.2183 19.9683 13.3227 19.8643C13.427 19.7603 13.5556 19.7083 13.7083 19.7083H14.25V15.9167C14.25 15.1674 14.4757 14.5016 14.9271 13.9195C15.3785 13.3374 15.9653 12.9559 16.6875 12.775V12.3958C16.6875 12.1701 16.7666 11.9784 16.9247 11.8206C17.0829 11.6628 17.2747 11.5837 17.5 11.5833C17.7253 11.583 17.9173 11.6621 18.0758 11.8206C18.2343 11.9791 18.3132 12.1709 18.3125 12.3958V12.775C19.0347 12.9556 19.6215 13.3371 20.0729 13.9195C20.5243 14.502 20.75 15.1677 20.75 15.9167V19.7083H21.2917C21.4451 19.7083 21.5739 19.7603 21.6779 19.8643C21.7819 19.9683 21.8337 20.0969 21.8333 20.25C21.833 20.4031 21.781 20.5318 21.6773 20.6362C21.5737 20.7406 21.4451 20.7924 21.2917 20.7917H13.7083ZM17.5 22.4167C17.2021 22.4167 16.9471 22.3107 16.7352 22.0987C16.5232 21.8867 16.417 21.6316 16.4167 21.3333H18.5833C18.5833 21.6312 18.4773 21.8864 18.2654 22.0987C18.0534 22.311 17.7983 22.417 17.5 22.4167Z"
        fill="#027F9D"
      />
    </svg>
  );
};

export default NotificationMailIcon;
