import React from 'react';

import PropTypes from 'prop-types';

import Switch from "react-switch";

import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';

 
class Toggleswitch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleSwitch =  this.toggleSwitch.bind(this);
    this.state = { value: props.input.value };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.value) {
      this.setState({ value: nextProps.input.value });
    }
    // if(nextProps.isToggle) {
    //   this.setState({ value: !nextProps.isToggle });
    //   this.props.input.onChange(!nextProps.isToggle);
    // }
  }
  toggleSwitch(val) {
    const { onSwitch } = this.props;
    this.props.input.onChange(val);
    if (onSwitch) {
      onSwitch(val);
    }
    this.setState({ value: val });
  };


  render() {
    const { label, layout, input, ...rest } = this.props;
    const { value } = this.state;
    return (
      <FormGroup check>
        <Switch
          {...rest}
          onChange={this.toggleSwitch}
          checked={value}
          onColor='#388535'
          handleDiameter={15}
          uncheckedIcon={false}
          checkedIcon={false}
          className="toggle-switch"
          height={18}
          width={36}
          onColor='#09819A'
        />
        {label && Object.keys(layout).length > 0 &&
          <Label for={rest.id}>{label}</Label>
        }
      </FormGroup>
    );
  }
}

Toggleswitch.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default Toggleswitch;