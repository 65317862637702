import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Row from 'reactstrap/lib/Row';

import { UncontrolledTooltip } from 'reactstrap';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import ButtonGroupAtom from 'atoms/ButtonGroup';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';
import { NOTIFICATION_STATUS_OPTIONS } from 'constants/vendors';
import closeimage from 'components/vendors/CloseCross.png';
/**
 * NotificationSettingFormPage -> NotificationSettingFormSection -> NotificationSettingFormModalComponent
 *
 * Props:
 *    - update
 */
const  NotificationSettingFormModalComponent = (props) => {
  const { error, isOpen, submitting, handleSubmit, toggle, update, onToggle, notificationStatus } = props;

  const submit = (data) => {
    const detail = {
      ...data,
      notification_after: moment.duration(+data.hour, 'hours').asSeconds() +
        moment.duration(+data.minute, 'minutes').asSeconds(),
      is_active: notificationStatus
    };
    return update(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" className="form-modal">
      <ModalHeader>Edit Notification Setting
      <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
      </ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <ModalBody>
          <div className="error-text">{error}</div>
          <Row>
            <Col md={12}>
              <section className="float-right filter">
                <span id="status" className="btn-grp-color">
                  <ButtonGroupAtom
                    options={NOTIFICATION_STATUS_OPTIONS}
                    active={notificationStatus}
                    onToggle={(val) => onToggle(val)}
                  />
                </span>
                <UncontrolledTooltip placement="left" target="status">
                  Notification Status
                </UncontrolledTooltip>
              </section>
            </Col>
            <Col>
              <Field
                id="hour"
                name="hour"
                label="Notification Time (After)"
                component={InputAtom}
                placeholder="Hour"
                layout={FORM_LAYOUT}
                suffix="Hr"
              />
            </Col>
            <Col>
              <Field
                id="minute"
                name="minute"
                component={InputAtom}
                placeholder="Minute"
                label="&nbsp;"
                layout={FORM_LAYOUT}
                suffix="Min"
              />
            </Col>
          </Row>
          <Field
            id="notification_message"
            type="textarea"
            name="notification_message"
            label="Message"
            component={InputAtom}
            placeholder="Message"
            layout={FORM_LAYOUT}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.notification_message) errors.notification_message = 'Enter Message';
  return errors;
};

NotificationSettingFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  notificationStatus: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'NotificationSettingForm',
  validate,
})(NotificationSettingFormModalComponent);
