import React from 'react';

import PropTypes from 'prop-types';
import { MSGS } from 'constants/activities';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> ConfirmationModal
 *
 * Props:
 *    - update
 */
class ConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.proceed = this.proceed.bind(this);
  }
  
  proceed(){
    const { proceedToSubmit, goTo, error } = this.props;
    if(error){
      return goTo();
    }
    return proceedToSubmit();
  }

  render() {
    const { isOpen,
      toggle, error, isProceeding, closeModal } = this.props;
 
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Activity</ModalHeader>
        <ModalBody>
          { error ?
            <div> {MSGS.MANAGE_ACTIVITIES} </div>
            :<div>
              <div>Please note changing the activity status of the shift will automatically change 
              the  status of the meal period and tasks occurring within this shift.<br /> <br />Are you sure you want to continue ? 
              </div>
              <div className="donotshow-message">
                <input type="checkbox"
                  id="close_checkbox" 
                  name="close_checkbox" 
                  className="align-text-bottom" 
                  onChange={(e)=>closeModal(e)} 
                />
                <span className="vertical-align-text-top ml-1">Don&#39;t Ask Me Again</span>
              </div>
            </div>  
          }
        </ModalBody>
        <ModalFooter>
          <Button color="default" className={styles['cancel-btn']} onClick={() => toggle()} size={FORM_LAYOUT.size} >
            Cancel
          </Button>
          <Button type="submit" color="secondary" onClick={() => this.proceed()} size={FORM_LAYOUT.size} >
            Proceed <ButtonLoaderAtom active={isProceeding} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};


ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ConfirmationModal;