import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import ButtonLoaderAtom from 'atoms/ButtonLoader';


import { FORM_LAYOUT } from 'constants/layout';

/**
 * ProfileFormPage -> ProfileFormSection -> ProfileFormComponent
 *
 * Props:
 *    - update profile
 */
const ProfileFormComponent = (props) => {

  const { handleSubmit, submitting, error, timezonesList , update } = props;


  const submit = data => update(data)
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="pl-3 pr-4">
      <div className="error-text">{error}</div>
      <Row>
        <Col xs={12} md={6}>
          <Field
            id="account_no"
            name="account_no"
            label="Account Number"
            component={InputAtom}
            layout={FORM_LAYOUT}
            static
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Field
            id="first_name"
            name="first_name"
            placeholder="First Name"
            component={InputAtom}
            label="First Name"
            layout={FORM_LAYOUT}
            disabled
          />
          <Field
            id="username"
            name="username"
            label="Username"
            component={InputAtom}
            placeholder="Username"
            layout={FORM_LAYOUT}
            disabled
          />
        </Col>
        <Col xs={12} md={6}>
           <Field
            id="last_name"
            name="last_name"
            placeholder="Last Name (optional)"
            component={InputAtom}
            label="Last Name"
            layout={FORM_LAYOUT}
            disabled
          />
          <Field
            id="timezone"
            name="timezone"
            label="Timezone"
            component={SelectAtom}
            options={timezonesList.map(i => ({ label: i, value: i }))}
            placeholder="Timezone"
            layout={FORM_LAYOUT}
            simpleValue
          />
          <Row>
            <Col>
              <Button
                type="submit"
                color="secondary"
                className="float-sm-right my-4"
                size={FORM_LAYOUT.size}
              >
                Submit <ButtonLoaderAtom active={submitting} />
              </Button>
            </Col>
          </Row>
      </Col>
      </Row>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.first_name) errors.first_name = 'Enter First Name';
  if (!values.username) errors.username = 'Enter Username';
  if (!values.timezone) errors.timezone = 'Select Timezone';
  return errors;
};

ProfileFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  timezonesList: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
  userRole: PropTypes.string,
};

export default reduxForm({
  form: 'ProfileForm',
  validate,
})(ProfileFormComponent);
