import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import axiosInstance from 'axios';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

import { LOCATION_IQ_API_KEY } from 'constants/geoFences';

const GET_GEOFENCES = 'geoFences/geoFence/GET_GEOFENCES';
const GET_GEOFENCES_FULFILLED = 'geoFences/geoFence/GET_GEOFENCES_FULFILLED';
const SET_GEOFENCE = 'geoFences/geoFence/SET_GEOFENCE';
const GET_ALL_GEOFENCES = 'geoFences/geoFence/GET_ALL_GEOFENCES';
const RESET_GEOFENCE_DETAIL = 'geofences/geofence/RESET_GEOFENCE_DETAIL';
const GET_ALL_GEOFENCES_FULFILLED = 'geoFences/geoFence/GET_ALL_GEOFENCES_FULFILLED';
const GET_GEOFENCE = 'geoFences/geoFence/GET_GEOFENCE';
const GET_GEOFENCE_FULFILLED = 'geoFences/geoFence/GET_GEOFENCE_FULFILLED';
const POST_GEOFENCE = 'geoFences/geoFence/POST_GEOFENCE';
const POST_GEOFENCE_FULFILLED = 'geoFences/geoFence/POST_GEOFENCE_FULFILLED';
const PUT_GEOFENCE = 'geoFences/geoFence/PUT_GEOFENCE';
const PUT_GEOFENCE_FULFILLED = 'geoFences/geoFence/PUT_EVENT_LOCATION_FULFILLED';
const RESET_GEOFENCE = 'geoFences/geoFence/RESET_GEOFENCE';

const SET_GEOFENCE_POINTS = 'geoFences/geoFence/SET_GEOFENCE_POINTS';

const GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES= 'geoFences/geoFence/GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES';
const GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES_FULFILLED = 'geoFences/geoFence/GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES_FULFILLED';
const GET_ACTIVITY_TRACK_GEOFENCES= 'geoFences/geoFence/GET_ACTIVITY_TRACK_GEOFENCES';
const GET_ACTIVITY_TRACK_GEOFENCES_FULFILLED = 'geoFences/geoFence/GET_ACTIVITY_TRACK_GEOFENCES_FULFILLED';
const GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES= 'geoFences/geoFence/GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES';
const GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES_FULFILLED = 'geoFences/geoFence/GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES_FULFILLED';
const GET_ADDRESS = 'geoFences/geoFence/GET_ADDRESS';

export function getGeoFences(params) {
  return {
    type: GET_GEOFENCES,
    payload: axios.get(URLS.GEOFENCE, { params }),
  };
}


export function getAddress(params) {
    return {
      type: GET_ADDRESS,
      payload: axiosInstance.get(`${URLS.GEOCODING_LOCATION_IQ}`, { params }),
    };
}

export function getCurrentActivityTracksGeofences(params) {
  return {
    type: GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES,
    payload: axios.get(`${URLS.GEOFENCE}${URLS.ACTIVITY_TRACK}`, { params }),
  };
}

export function getActivityTrackGeofences(params) {
  return {
    type: GET_ACTIVITY_TRACK_GEOFENCES,
    payload: axios.get(`${URLS.GEOFENCE}${URLS.ACTIVITY_TRACK}${params.id}/`),
  };
}

export function getDailyTimecardActivityTrackGeofences(parameter) {
  const { id, ...params } = parameter;
  return {
    type: GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES,
    payload: axios.get(`${URLS.GEOFENCE}${id}${URLS.DAILY_TIMECARD}`, { params }),
  };
}

export function getALLGeoFences(params) {
  return {
    type: GET_ALL_GEOFENCES,
    payload: axios.get(URLS.GEOFENCE, { params }),
  };
}

export function setGeoFence(data) {
  return {
    type: SET_GEOFENCE,
    payload: data,
  };
}

export function getGeoFence(params) {
  return {
    type: GET_GEOFENCE,
    payload: axios.get(`${URLS.GEOFENCE}${params.id}/`),
  };
}

export function postGeoFence(data) {
  return {
    type: POST_GEOFENCE,
    payload: axios.post(URLS.GEOFENCE, data),
  };
}

export function putGeoFence(data) {
  return {
    type: PUT_GEOFENCE,
    payload: axios.put(`${URLS.GEOFENCE}${data.id}/`, data),
  };
}

export function resetGeoFence(data) {
  return {
    type: RESET_GEOFENCE,
  };
}

export function resetGeofenceDetail(data) {
  return {
    type: RESET_GEOFENCE_DETAIL,
  };
}

export function setGeoFencePoints(data) {
  return {
    type: SET_GEOFENCE_POINTS,
    payload: data,
  };
}

const defaultState = {
  list: { results: [] },
  detail: { lat_long: {} },
  all:[],
};

function GET_GEOFENCES_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.results.map(item =>
    ({ ...item, draggable: false, editable: false }));
  return Object.assign({}, state, {
    list: { ...action.payload, results: arr },
  });
}

function GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.results.map(item =>
    ({ ...item, draggable: false, editable: false }));
  return Object.assign({}, state, {
    list: { ...action.payload, results: arr },
  });
}


function GET_ACTIVITY_TRACK_GEOFENCES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ALL_GEOFENCES_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.map(item =>
    ({ ...item, draggable: false, editable: false }));
  return Object.assign({}, state, {
    all: arr ,
  });
}

function SET_GEOFENCE_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {
      ...state.detail,
      lat_long: action.payload,
      shape_type: action.payload.type,
      radius : action.payload.radius || 0
    },
  });
}

function GET_GEOFENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_GEOFENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_GEOFENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_GEOFENCE_DETAIL_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

function RESET_GEOFENCE_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

const handlers = {
  [SET_GEOFENCE]: SET_GEOFENCE_REDUCER,
  [RESET_GEOFENCE]: RESET_GEOFENCE_REDUCER,
  [GET_GEOFENCES_FULFILLED]: GET_GEOFENCES_FULFILLED_REDUCER,
  [GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES_FULFILLED]: GET_CURRENT_ACTIVITY_TRACKS_GEOFENCES_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACK_GEOFENCES_FULFILLED]: GET_ACTIVITY_TRACK_GEOFENCES_FULFILLED_REDUCER,
  [GET_ALL_GEOFENCES_FULFILLED]: GET_ALL_GEOFENCES_FULFILLED_REDUCER,
  [GET_GEOFENCE_FULFILLED]: GET_GEOFENCE_FULFILLED_REDUCER,
  [POST_GEOFENCE_FULFILLED]: POST_GEOFENCE_FULFILLED_REDUCER,
  [PUT_GEOFENCE_FULFILLED]: PUT_GEOFENCE_FULFILLED_REDUCER,
  [RESET_GEOFENCE_DETAIL] : RESET_GEOFENCE_DETAIL_REDUCER,
  [GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES_FULFILLED]: GET_DAILY_TIMECARD_ACTIVITY_TRACK_GEOFENCES_FULFILLED_REDUCER,
};

const geoFenceSelector = state => state.geoFences.geoFence;

export const geoFenceList = createSelector(
  geoFenceSelector,
  instance => instance.list,
);

export const geoFenceDetail = createSelector(
  geoFenceSelector,
  instance => instance.detail,
);

export const geoFenceAll = createSelector(
  geoFenceSelector,
  instance => instance.all,
);


export default createReducer(defaultState, handlers);
