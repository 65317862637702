import React from 'react';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import { toast } from 'react-toastify';
import moment from 'moment';

import listUseCase from 'surveys/usecases/surveyListUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';
import SectionLoaderAtom from 'atoms/SectionLoader';

import SurveyTemplateListComponent from 'surveys/components/surveyTemplateListComponent';
import SurveyTemplateFilterForm from 'surveys/components/templateFilterForm'
import GenericModal from 'components/common/GenericModal';
import PaginationComponent from 'components/common/Pagination';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';

import DetailedSurveyForm from 'surveys/components/detailForm';
import SurveyModalSliderComponent from 'surveys/components/surveySilderModal';
import DeleteTemplateConfirmationModal from 'surveys/components/deleteTemplateModal';
import SurveySliderEditableModalComponent from 'surveys/components/surveySliderEditableModal';
import { SURVEY_LANGUAGE_OPTIONS } from 'constants/surveys';


class SurveyTemplateListPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = { surveyTemplateList : null, isLoading: false , isSurveyModal: false , surveyTemplateListCount : 0 , surveyDetail : null, isModalLoading : false, isShowSurvey:false , templateDetails: null, isDeleteTemplate:null, isDeleteTemplateLoader:false, isShowEditableSurvey: false, surveyId: null, isEditableButtonLoader: false, isNavCollapsed: false, 
      surveyType: [{ value: "others", name: 'Others', id: 5 }]}
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new listUseCase(surveyAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.getSurveyDetails = this.getSurveyDetails.bind(this);
    this.goTo=this.goTo.bind(this);
    this.showSurvey=this.showSurvey.bind(this);
    this.hideSurveyModal=this.hideSurveyModal.bind(this);
    this.showEditableSurvey=this.showEditableSurvey.bind(this);
    this.hideEditableSurveyModal=this.hideEditableSurveyModal.bind(this);
    this.deleteSurveyTemplate = this.deleteSurveyTemplate.bind(this);
    this.editSurveyDetail = this.editSurveyDetail.bind(this);
    this.goToFirstPage= this.goToFirstPage.bind(this);
    this.deleteTemplateModal = this.deleteTemplateModal.bind(this);
    this.getNavStatus = this.getNavStatus.bind(this);
    this.getFilteredList = this.getFilteredList.bind(this);
  }

  componentWillMount(){
    const { location: { search, pathname } , history } = this.props;
    const query = queryString.parse(search);
    this.listenListUsecase();
    this.loadData();
      /*this.usecase.getAccountRoles();*/
  }

  componentWillReceiveProps(nextProps, prevProps){
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if(prevQuery !== nextQuery){
      this.fetchData(nextQuery);
    }
  }

  deleteTemplateModal(id){
    const {isDeleteTemplate} = this.state;
    this.setState({isDeleteTemplate:id});
  }

 
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  loadData(){
    const { history, location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("survey_template_page_size") || 10;
    if (
       !('page_size' in query)
      ) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: pageSize,
          page: query.page || 1,
          is_active  : true
        }),
      });
    }
    else{
      this.fetchData(query)
    }
  }

  goToFirstPage(){
    const { history, location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("survey_template_page_size") || 10;
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        page : 1
      }),
    });
  }

  fetchData(search){
    const pageSize = cookie.load("survey_template_page_size") || 10;
    // const { is_active, ...rest } = search;
    let survey_type_cookie = cookie.load('survey_type')    
    let survey_type_list = survey_type_cookie ? survey_type_cookie.map(i=> i.value) : this.state.surveyType.map(i => i.value)
    let obj = { page_size : pageSize , is_active : search?.is_active , list_survey_type: JSON.stringify(survey_type_list)};
    this.usecase.getSurveysTemplates(obj);
  }

  getFilteredList(survey_type){    
    this.setState({surveyType: survey_type})
    cookie.save('survey_type', survey_type)
    let survey_type_cookie = cookie.load('survey_type')    
    let survey_type_list = survey_type_cookie ? survey_type_cookie.map(i=> i.value) : survey_type.map(i => i.value)
    const pageSize = cookie.load("survey_template_page_size") || 10;
    const { history, location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    // const { is_active, ...rest } = query;
    let obj = { page_size : pageSize , is_active : query.is_active , list_survey_type: JSON.stringify(survey_type_list)};
    this.usecase.getSurveysTemplates(obj);
  }

  listenListUsecase() {
    const { history , location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'GET_SURVEY_TEMPLATE_LIST_SUCESS':
          this.updateState('surveyTemplateList', event.data.results);
          this.updateState('surveyTemplateListCount', event.data.count);
          break;
        case 'SHOW_TEMPLATE_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'HIDE_TEMPLATE_LOADER':
          this.updateState('isLoading', false);
          break;
        case 'SURVEY_DETAILS':
          this.updateState('surveyDetail', event.data);
          break;
        case 'SHOW_MODAL_LOADER':
          this.updateState('isModalLoading', true);
          break;
        case 'HIDE_MODAL_LOADER':
          this.updateState('isModalLoading', false);
          break;
        case 'DELETE_TEMPLATE_SUCCESS':
          this.goToFirstPage();
          break;
        case 'TEMPLATE_DETAILS':
          this.updateState('templateDetails', event.data);
          break;
        case 'SHOW_DELETE_TEMPLATE_LOADER':
          this.updateState('isDeleteTemplateLoader',true);
          break;
        case 'HIDE_DELETE_TEMPLATE_LOADER':
          this.updateState('isDeleteTemplateLoader',false);
          this.updateState('isDeleteTemplate',null);
          break;
        case 'SHOW_SUBMIT_SP_TEMPLATE_LOADER':
          this.updateState('isEditableButtonLoader', true);
          break;
        case 'HIDE_SUBMIT_SP_TEMPLATE_LOADER':
          this.updateState('isEditableButtonLoader', false);
          break;
        case 'SHOW_SP_TEMPLATE_SUCESS':
          toast.success('Survey updated successfully');
          this.updateState('isShowEditableSurvey', false);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  getSurveyDetails(id){
    const { isSurveyModal } = this.state;
    if(!isSurveyModal){
      this.usecase.getTemplateDetail(id);
    }
    else{
      this.updateState('surveyDetail', null)
    }
    
    this.setState({isSurveyModal : !isSurveyModal})
  }

  goTo(url){
    const { history, location: { search, pathname } } = this.props;
    history.push(url);

  }

  showSurvey(id){
    const {isShowSurvey} = this.state;
    this.usecase.getTemplateDetail(id)
    .then(()=>{
      this.setState({isShowSurvey:true, isShowEditableSurvey: false});
    })
  }

  hideSurveyModal(){
    const {isShowSurvey} = this.state;
    this.updateState('surveyDetail', null);
    this.setState({isShowSurvey:false});
  }

  showEditableSurvey(id){
    const {isShowEditableSurvey} = this.state;
    this.usecase.getTemplateDetail(id)
    .then(()=>{
      this.setState({isShowEditableSurvey:true, isShowSurvey: false, surveyId: id});
    })
  }

  hideEditableSurveyModal(){
    const {isShowEditableSurvey} = this.state;
    this.setState({isShowEditableSurvey:false});
  }

  deleteSurveyTemplate(id){
    this.usecase.deleteSurveyTemplate(id);
  }

  editSurveyDetail(id){
    const { history } = this.props;
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname : `/surveys/${id}/edit`, search: queryString.stringify({ ...query }),
    });
  }

  getNavStatus(val){
    this.setState({isNavCollapsed: val});
  }

  render() {
    const { dateFormat , location } = this.props;
    const { surveyTemplateList, isLoading , isSurveyModal , surveyTemplateListCount , surveyDetail, departmentList , teamList, isShowSurvey , templateDetails, isDeleteTemplate,isDeleteTemplateLoader, isShowEditableSurvey } = this.state;
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    const survey_type_cookie = cookie.load('survey_type')
    const survey_type_id = survey_type_cookie && survey_type_cookie.map(i=>i.id)
    return (
      <div className="wrapSurveyDetails">
        <div className={isShowEditableSurvey ? "showEditableSurveyModal" : isShowSurvey ? "showSurveyModal" : "hideSurveyModal" }>
          <TwoColumnLayout
            navInfo={NAV.SurveyTemplateListPage}
            fetchData={() => this.fetchData(query)}
            createSurvey="create_survey_template"
            goTo={this.goTo}
            hideContent = {isShowEditableSurvey ? true : false}
            getNavStatus={this.getNavStatus}
          >
            <SurveyTemplateFilterForm
              dateFormat={dateFormat}
              location={location}
              initialValues={{
                is_active : query.is_active ? query.is_active : true,
                survey_type : survey_type_id ? survey_type_id : [5]
              }}
              isShowSurvey={isShowSurvey}
              getFilteredList={this.getFilteredList}
              surveyType={this.state.surveyType}
            />
            <div>
              <SurveyTemplateListComponent
                list={surveyTemplateList}
                isLoading={isLoading}
                isSurveyModal={this.state.isSurveyModal}
                getSurveyDetails={this.getSurveyDetails}
                showSurvey={this.showSurvey}
                editSurveyDetail={this.editSurveyDetail}
                deleteTemplateModal={this.deleteTemplateModal}
                showEditableSurvey={this.showEditableSurvey}                
              />
              {surveyTemplateListCount && !isLoading ?
                <PaginationComponent
                  count={surveyTemplateListCount}
                  location={location}
                  cookieKey="survey_template_page_size"
                  history={this.props.history}
                /> : null}
            </div>
            { isSurveyModal &&
              <GenericModal toggle={this.getSurveyDetails} isOpen={isSurveyModal} heading="Surveys" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
                <DetailedSurveyForm
                  data = {templateDetails && templateDetails.results}
                  date={templateDetails && templateDetails.date}
                  label = {templateDetails && templateDetails.surveyName}
                  isLoading={this.state.isModalLoading}
                  name = ''
                  dateFormat={this.props.dateFormat}
                  timeFormat={this.props.timeFormat}
                  timeFormat24Hrs = {this.props.timeFormat24Hrs}
                />
              </GenericModal>
            }
          </TwoColumnLayout>
        </div>
        {isShowSurvey &&
          <SurveyModalSliderComponent 
            hideSurveyModal={this.hideSurveyModal}
            data = {templateDetails && templateDetails.results}
            date={templateDetails && templateDetails.date}
            label = {templateDetails && templateDetails.surveyName}
            name = ''
            dateFormat={this.props.dateFormat}
            timeFormat={this.props.timeFormat}
            timeFormat24Hrs = {this.props.timeFormat24Hrs}
            surveyTypeList={this.state.surveyType}
          />
        }
        {isShowEditableSurvey &&
          <SurveySliderEditableModalComponent
            hideEditableSurveyModal={this.hideEditableSurveyModal}
            data = {templateDetails && templateDetails.results}
            date={templateDetails && templateDetails.date}
            label = {templateDetails && templateDetails.surveyName}
            name=''
            initialValues={{survey_language: SURVEY_LANGUAGE_OPTIONS[0].value}}
            surveyLanguage={templateDetails && templateDetails.surveyLanguage}
            spanishLabel={templateDetails && templateDetails.spanishLabel}
            usecase={this.usecase}
            surveyId = {this.state.surveyId}
            isLoading={this.state.isEditableButtonLoader}
            dateFormat={this.props.dateFormat}
            timeFormat={this.props.timeFormat}
            timeFormat24Hrs = {this.props.timeFormat24Hrs}
            isNavCollapsed={this.state.isNavCollapsed}
          />
        }
        {isDeleteTemplate &&
          <DeleteTemplateConfirmationModal
            isOpen={isDeleteTemplate}
            toggle={this.deleteTemplateModal}
            deleteSurveyTemplate={this.deleteSurveyTemplate}
            isDeleteTemplateLoader={isDeleteTemplateLoader}
          />
        }
      </div>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  isSubCompany: UserDucks.isSubCompany(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyTemplateListPage);