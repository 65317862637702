import React from 'react';

import Link from 'react-router-dom/Link';
import SectionLoaderAtom from 'atoms/SectionLoader';
import Table from 'reactstrap/lib/Table';
import { Row, Col } from 'reactstrap';
import styles from './styles.module.scss';


const InvoiceListComponent = (props) => {
  const { invoiceList, isLoading , subscriptionToggle, planType} = props;
  if(isLoading) return <SectionLoaderAtom active />;
  return (
    <Row>
    {planType==='offline' &&
      <Row className="w-100">
        <Col md={6}></Col>
        <Col md={6} className="mt-3 float-right">
          <span className={`${styles['cancel_subscription']}`}
            onClick={() => subscriptionToggle()}>
            <i>Cancel Subscription</i>
          </span>
        </Col>
      </Row>
    }
    <section className="w-100 ml-4 mr-3 pl-1 pr-4">
      <Table responsive hover size="sm" className="invoice-table">
        <thead className="Header-col">
          <tr>
            <th>&nbsp;</th>
            <th className="text-left">Invoice</th>
            <th className="text-left">Issue Date</th>
            <th className="text-left">Payment status</th>
            <th className="text-right">Billed Users</th>
            <th className="text-right">Amount</th>
            <th className="text-left">Payment Date</th>        
          </tr>
        </thead>
        <tbody>
          {invoiceList.results && invoiceList.results.map((item, index) => {
            return (
              <tr key={index}>
                <th>&nbsp;</th>
                <td  className="text-left" title={item.invoice_number}>
                  <Link to={{
                    pathname: `/vendor/invoice/${item.id}/invoice-detail/`,
                  }}>
                    {item.invoice_number}</Link></td>
                <td className="text-left" title={item.create_date_display}>{item.create_date_display}</td>
                <td className="text-left" title={item.status}>{item.status}</td>
                <td className="text-right" title={item.billing_cycle && item.billing_cycle.quantity}>
                  {item.billing_cycle && item.billing_cycle.quantity}
                </td>
                <td className="text-right" title={item.billing_cycle && item.billing_cycle.total_amount}>
                  ${item.billing_cycle && item.billing_cycle.total_amount}
                </td>
                <td className="text-left" title={item.payment_date_display}>{item.payment_date_display}</td>
              </tr>
            );})}
          {!invoiceList.count &&
            <tr><td colSpan="7" className='text-center'> No Records Found </td></tr>
          }
        </tbody>
      </Table>
      </section>
    </Row>   
  );
}


export default InvoiceListComponent;