import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { UncontrolledTooltip } from 'reactstrap';

import * as RouteDucks from 'ducks/routes';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as BusinessAdminDucks from 'ducks/businessAdmins/businessAdmin';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import CustomerSummaryFilterComponent from 'components/generics/adminDashboard/CustomerSummaryFilter';

import { FILTER_OPTIONS } from 'constants/employees';
import { FORM_LAYOUT } from 'constants/layout';
import { DATE_FORMATS , COMMERCIAL_STATUS_LIST} from 'constants/vendors';
import { DATE_FORMAT_MAPPINGS } from 'constants/records';
import styles from './styles.module.scss';
import ActiveDefaultIcon from './company-active-default.png';
import InActiveDefaultIcon from './company-inactive-default.png';


/**
 * VendorListPage -> VendorFilterSection
 *
 * Components:
 *    - {@link VendorFilterComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    None
 */
class VendorFilterSection extends React.Component {

  render(){
    const { vendorSelectedList, location, businessAdminDetail, commercialStatusList , isOpen, isSubmit, isStatus} = this.props;
    const date = businessAdminDetail.date_format ?
      DATE_FORMATS.filter((item) => item.value === businessAdminDetail.date_format)[0].label : 'MMM DD, YYYY';
    const dateFormat = DATE_FORMAT_MAPPINGS[date];
    const { query } = location;
    const formattedDates = {};
    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    

    const newCommercialStatusList = [...commercialStatusList];
    newCommercialStatusList.splice(0,0, { id: "", status_name: "All" }); 
    return (
      <Row className="filter pl-3 pr-4">
        <Col md={8}>
          <div className="d-inline-block department-index mb-2" id="customer_status">
            <FilterDropdownComponent
              paramKey="is_active"
              location={location}
              options={FILTER_OPTIONS}
            />
            <UncontrolledTooltip placement="top" target="customer_status">
              Select Customer Status
            </UncontrolledTooltip>
          </div>      
            <div className="d-inline-block mb-2" id="commercial_status">
              <FilterDropdownComponent
                location={location}
                paramKey="status"
                options={COMMERCIAL_STATUS_LIST}
              />
              <UncontrolledTooltip placement="top" target="commercial_status">
                Select Commercial Status
              </UncontrolledTooltip>
            </div>         
          <div className="d-inline-block">
            <CustomerSummaryFilterComponent
              location={location}
              initialValues={formattedDates}
              dateFormat={dateFormat}
            />
          </div>
        </Col>
        <Col md={4}>
          <section className="float-right">
            <FilterFormComponent
              location={location}
              placeholder="Search name"
              initialValues={query}
            />
          </section>
          <section className="float-right">
            {query.is_active === "all" || query.is_active ==="true"
              ?<Button
                color="accent"
                disabled={!vendorSelectedList.length}
                onClick={() => this.props.toggle(false)}
              >
                <div id ="mark_inactive">
                  <img
                    className={styles['button-icon']}
                    src={InActiveDefaultIcon}
                    alt="Icon"
                  />
                  <UncontrolledTooltip placement="top" target="mark_inactive">
                    Mark Inactive
                  </UncontrolledTooltip>
                </div>
              </Button>
              :null
            }
            {query.is_active === "all" || query.is_active ==="false"
              ?<Button
                color="accent"
                disabled={!vendorSelectedList.length}
                onClick={() => this.props.toggle(true)}
              >
                <div id ="mark_active">
                  <img
                    className={styles['button-icon']}
                    src={ActiveDefaultIcon}
                    alt="Icon"
                  />
                  <UncontrolledTooltip placement="top" target="mark_active">
                    Mark Active
                  </UncontrolledTooltip>
                </div>
              </Button>
              : null
            }
            {isOpen &&
              <Modal isOpen={isOpen}>
                <ModalBody>
                  {isStatus
                    ?"Are you sure you want to Activate this company.This will only activate the parent company."
                    :"Are you sure you want to Inactivate this company.This will inactivate all the branch companies and all employees too."
                  }
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    size={FORM_LAYOUT.size}
                    onClick={() => this.props.updateActiveStatus(isStatus)}
                  >
                    Yes I am sure <ButtonLoaderAtom active={isSubmit} />
                  </Button>
                  <Button color="danger" onClick={this.props.toggle}>
                    Let me re-check
                  </Button>
                </ModalFooter>
              </Modal>
            }
          </section>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  vendorSelectedList: VendorDucks.vendorSelectedList(state),
  businessAdminDetail: BusinessAdminDucks.businessAdminDetail(state)
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

VendorFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  vendorSelectedList: PropTypes.array.isRequired,
};

VendorFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorFilterSection);
