import React from 'react';

import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as InfoDucks from 'ducks/info/info';
import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';
import * as UserDucks from 'ducks/accounts/user';

import AttributeToggler from "employees/components/AttributeToggler";
import TwoColumnLayout from 'layouts/TwoColumn';
import { toast } from 'react-toastify';

import CustomTimecardFilterSection from 'sections/timecards/customList/CustomTimecardFilter';
import CustomTimecardListSection from 'sections/timecards/customList/CustomList';
import { NAV } from 'constants/timecards';

/**
 * TimecardListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link TimecardFilterSection}
 *    - {@link TimecardListSection}
 *
 * Fetch Needs:
 *    - getPayrolls
 *    - getTimecards
 */
const monthStartDate = moment().startOf('month').format('YYYY-MM-DD');
const monthEndDate = moment().endOf('month').format('YYYY-MM-DD');

class CustomTimecardListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchTimecards = this.fetchTimecards.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, isInfoOpen: true, attributeToggler: false, showableAttributes: {}, userViewPreferenceLoading: false , isApproveAllEmployeesLoading: false};
    this.getBasicSettingsAndAllPayrolls = this.getBasicSettingsAndAllPayrolls.bind(this);
    this.getDepartmentPayrolls = this.getDepartmentPayrolls.bind(this);
    this.refresh = this.refresh.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.getUserPreference = this.getUserPreference.bind(this);
    this.approveAllEmployee = this.approveAllEmployee.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.updateAll = this.updateAll.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.updateAttributeValue = this.updateAttributeValue.bind(this)
    this.accessorToHeader = {
      first_name: "First Name",
      last_name: "Last Name",
      employee_id: "Employee Id",
      employee_title: "Employee Title",
      punch_in_time: "Punch-In Time",
      meal_period_time: "Meal-Period Time",
      total_time: "Total Time",
      regular_time: "Regular Time",
      overtime: "Overtime",
      double_overtime: "Double Overtime",
      adjustments: 'ADJ',
      paid_time_off: 'PTO',
    };
    if(this.props.userExtraData.company_permissions.mileage) {
      this.accessorToHeader = {...this.accessorToHeader, mileage : "Mileage"}
    }
  }

/**
 * @component
 * @description Lifecycle method that is called before the component mounts.
 * It initializes state, fetches user preferences, and manages cookie data.
 */
  componentWillMount() {
    const { location: { query },userViewPreference } = this.props;
    const { router: { history } } = this.context;



    const cookieDepartmentId = cookie.load('departmentId');
   
    let departmentIdsArray;
    if(cookieDepartmentId?.length){
     
      if(Array.isArray(cookieDepartmentId)){
        departmentIdsArray= cookieDepartmentId;
      } 
      else{
        departmentIdsArray= [cookieDepartmentId]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));


    const cookieKey = cookie.load("timecard_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'timecards.timecard.list'});
    this.setState({ isLoading: true });
    const cookiePayrollId = cookie.load('payrollId');
    if(!Object.keys(userViewPreference).length){
      this.getUserPreference();
    }else{
      this.updateAttributeValue();
    }

    if(!cookiePayrollId){
      // this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'}); since getting called in getUserPreference > getBasicSettingsAndAllPayrolls 
      if(history.action !== "PUSH"){
        this.fetchTimecards(query);
      }    
      if(query && query?.department_ids && !Array.isArray(query?.department_ids)){
        this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_ids: JSON.stringify(departmentIdsIntegers) })
      }
    }
    else{
      // this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'});  since getting calledd in loadDAta
      // this.getUserPreference();
      this.loadData();
    }
  }

/**
* @function
* @description Fetches user preferences and updates state accordingly.
* @param {boolean} isLoading - Indicates if the loading state should be set.
*/
  getUserPreference(isLoading) {
    this.props.TimecardDucks.getUserPreference({view_type: 'timecards'})
    .then((res) => {
      if(isLoading) this.setState({ userViewPreferenceLoading: false});
      this.updateAttributeValue();
      cookie.save("timecard_page_size", res.value.page_size, { path: '/' });
      this.getBasicSettingsAndAllPayrolls();
    })
    .catch(() => {
      if(isLoading) this.setState({ userViewPreferenceLoading: false});
      let attributes = {};
      for (const acc in this.accessorToHeader) {
          attributes[acc] = true;
      }
      this.setAttributes(attributes);
    })
  }
  

/**
* @function
* @description Updates the state with user view preferences.
*/
  updateAttributeValue(){
    const {userViewPreference} = this.props;
      let obj = {}
      let all_fields =userViewPreference.all_fields;
      let show_fields = userViewPreference.show_fields;
      all_fields.forEach(v => {
        if(show_fields.indexOf(v)!== -1) {
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
      this.setAttributes(obj);
  }

/**
* @function
* @description Toggles the attribute visibility based on user input.
* @param {boolean} bool - The new state of the toggler.
*/
  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    const {userViewPreference} = this.props;
    if(bool && !Object.keys(userViewPreference).length){
     this.setState({userViewPreferenceLoading: true})
     this.props.TimecardDucks.getUserPreference({view_type: 'timecards'})
      .then((res) => { 
        this.updateAttributeValue();
        setTimeout(() => this.setState({ userViewPreferenceLoading: false}), 1000);
      })
      .catch(() => {
        let attributes = {};
        for (const acc in this.accessorToHeader) {
            attributes[acc] = true;
        }
        this.setAttributes(attributes);
    })
    }else{
     this.updateAttributeValue();
    }
  }

/**
* @function
* @description Sets attributes in the state and saves them in a cookie.
* @param {Object} attributes - The attributes to set.
*/
  async setAttributes(attributes) {
    await this.setState({ showableAttributes: attributes })
    cookie.save("showableAttributesTimecardList", attributes, { path: '/' })
  }


/**
* @function
* @description Configures the columns based on user preferences.
* @param {Object} submitData - The data submitted by the user.
* @param {boolean} flag - Indicates whether to set the state for toggler.
*/
  setColumns(submitData,flag) {
    const { location: { query } } = this.props;
    const showFields = Object.keys(submitData).filter((item) => {
      if(submitData[item] === true)
        return item
      else if(submitData[item] === false && item === "first_name")
        return true
      else
        return false
    })
    const pageSize = cookie.load("timecard_page_size") || 10;
    const preferenceObj = {
      all_fields : Object.keys(this.accessorToHeader),
      show_fields: showFields,
      page_size: pageSize,
      view_type: 'timecards'
    }
    this.props.TimecardDucks.updateUserPreference(preferenceObj)
    .then(() => {
      // if(!submit) this.setState({attributeToggler: !this.state.attributeToggler})
      if(!flag){
        this.setState({ showableAttributes: submitData ,attributeToggler: false})
      }
    })
  }

/**
* @function
* @description Updates filters based on the query parameters.
* @param {Object} query - The query parameters to use for updating filters.
*/
  updateFilters(query){
    const { page, ...rest } = query;
    const { userTimecardFilters, basicSettingDetail } = this.props;

    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    const preferenceObj = {
      preference_type: 'user_preference_timecards',
      data : {
        manual_correction: query.manual_correction == "true" ? true : false,
        timecard_decimal_view: 
        userTimecardFilters && userTimecardFilters.data ? ((userTimecardFilters.data.timecard_decimal_view == "true" || userTimecardFilters.data.timecard_decimal_view == true) ? true : false) : basicSettingDetail.timecard_decimal_view,
        // (query.timecard_decimal_view == "true" || query.timecard_decimal_view == true) ? true : false,
        show_extra_info: query.show_extra_info == "true" ? true : false,
        ...rest,
        department_ids:departmentIdsIntegers,
        start_date: query.start_date,
        end_date: query.end_date,
      }
    }
    //  this.props.TimecardDucks.updateUserFilters(preferenceObj)
    //  .then(() => {
    //     //this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'});
    //  });
     this.props.TimecardDucks.updateUserFilters(preferenceObj)
    
  }

/**
* @function
* @description Lifecycle method that is called when the component receives new props.
* It updates the component state and fetches new data based on updated props.
* @param {Object} nextProps - The new props received by the component.
*/
  componentWillReceiveProps(nextProps) {
    const { location: { query: nextQuery } } = nextProps;
    const { location: { query: prevQuery, pathname } } = this.props;
    const { router: { history } } = this.context;
    let departmentIdsArray;
    if(nextQuery?.department_ids){
     
      if(Array.isArray(nextQuery.department_ids)){
        departmentIdsArray= nextQuery.department_ids;
      } 
      else{
        departmentIdsArray= [nextQuery.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    if(nextQuery && nextQuery.page_size && (nextQuery.page_size !== prevQuery.page_size)) {
      this.setColumns(this.state.showableAttributes,true);
    }
    if(nextQuery && nextQuery?.department_ids && (nextQuery?.department_ids?.length !==0 ) && (prevQuery.department_ids !== nextQuery.department_ids)){
      this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_ids: JSON.stringify(departmentIdsIntegers)})
    }

    if(JSON.stringify(prevQuery) !== JSON.stringify(nextQuery) && nextQuery && nextQuery.page && (nextQuery.page === prevQuery.page)  && (nextQuery.ordering === prevQuery.ordering) 
         && (nextQuery.show_extra_info === prevQuery.show_extra_info)
      ){
      history.push({
        pathname,
        search: queryString.stringify({
          ...nextQuery,
          page : 1
        }),
      });
    }

    if(JSON.stringify(prevQuery) !== JSON.stringify(nextQuery)){
        if(nextQuery.payroll_id){
          this.updateFilters(nextQuery);
        }       
        if(!('manual_correction' in nextQuery)){
          this.loadData(nextQuery);
        }
        else
          if(nextQuery && nextQuery.page && (nextQuery.page === prevQuery.page) && nextQuery.ordering && (nextQuery.ordering === prevQuery.ordering) 
            && nextQuery.show_extra_info && (nextQuery.show_extra_info === prevQuery.show_extra_info)){
              this.fetchData(this.props, {...nextProps, page: 1});
          }
          else{
            this.fetchData(this.props, nextProps);
          }
    }
  }

/**
* @function
* @description Lifecycle method that is called when the component is unmounted.
* It cleans up any side effects.
*/
  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }


/**
 * @function
 * @description Fetches basic settings and all payrolls. Updates the query parameters in the URL based on user filters and payroll data.
 */
  getBasicSettingsAndAllPayrolls(){
    const  { location: {query, pathname }, userTimecardFilters: {data} } = this.props;
    const { router: { history } } = this.context;
    this.setState({ isLoading: true });
    Promise.all([
      // this.props.BasicSettingDucks.getBasicSetting(),
      data ? Promise.resolve({}):this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'}),
      this.props.PayrollDucks.getAllPayrolls({ paginate: false,  default: true, valid: true })
    ])
      .then((resp) => {
        
        const {
          userTimecardFilters : {data}
        } = this.props;
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              ...data,
              ...(resp?.[1]?.value?.length===0? {payroll_id:'custom',startDate:monthStartDate,endDate:monthEndDate}: {}),
              manual_correction: data && data.manual_correction ? data.manual_correction : false,
              timecard_decimal_view: data && data.timecard_decimal_view && ((data.timecard_decimal_view == "true" || data.timecard_decimal_view == true) ? true : false),
              show_extra_info: data && data.show_extra_info ? data.show_extra_info : false,
            }),
          });
      })
      .catch(() => this.setState({ isLoading: false }));
  }
/**
* @function
* @description Fetches payroll data for a specific department based on the provided query parameters. Updates the URL with payroll and date information.
* @param {Object} query - The query parameters from the URL.
*/
  getDepartmentPayrolls(query){
    const { router: { history } } = this.context;
    const { location: { pathname } , userTimecardFilters : {data} , basicSettingDetail } = this.props;
    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query.department_ids)){
        departmentIdsArray= query.department_ids;
      } 
      else{
        departmentIdsArray= [query.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
    this.setState({ isLoading: true });
    return this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_ids: JSON.stringify(departmentIdsIntegers)})
      .then(() => {
        const { departmentPayroll, payrollAll, profile } = this.props;
        this.setState({ isLoading: false });
        let startDate;
        let endDate;
        if(departmentPayroll.length){
          startDate = moment(departmentPayroll[0].start_datetime).format('YYYY-MM-DD');
          endDate = moment(departmentPayroll[0].end_datetime).format('YYYY-MM-DD');
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              payroll_id: departmentPayroll[0].id,
              start_date: startDate,
              end_date: endDate,
            })
          });
        }
        else if (payrollAll.length && profile.company.is_default){
          startDate = moment(payrollAll[0].start_datetime).format('YYYY-MM-DD');
          endDate = moment(payrollAll[0].end_datetime).format('YYYY-MM-DD');
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              payroll_id: payrollAll[0].id,
              start_date: startDate,
              end_date: endDate,
            })
          });
        }
        else{
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              payroll_id: 'custom',
              start_date: monthStartDate,
              end_date: monthEndDate,
            })
          });
        }
      })
  }

/**
* @function
* @description Fetches data based on current props and next props. Handles URL updates and timecard fetching based on query parameters.
* @param {Object} props - The current props of the component.
* @param {Object} nextProps - The next props of the component.
*/
  fetchData(props, nextProps){
    const { location: { query, pathname } } = props;
    const { router: { history } } = this.context;
    const { location: { query: nextQuery }, payrollAll, profile } = nextProps;
    if(!('payroll_id' in nextQuery)){
      if(nextQuery?.department_ids){
        this.getDepartmentPayrolls(nextQuery)
      }
      else if (payrollAll.length && profile.company.is_default){
        const startDate = moment(payrollAll[0].start_datetime).format('YYYY-MM-DD');
        const endDate = moment(payrollAll[0].end_datetime).format('YYYY-MM-DD');
        cookie.save('startDate', startDate, { path: '/' });
        cookie.save('endDate', endDate, { path: '/' });
        cookie.save('payrollId', payrollAll[0].id, { path: '/' });
        history.push({
            pathname,
            search: queryString.stringify({
              ...nextQuery,
              payroll_id: payrollAll[0].id,
              start_date: startDate,
              end_date: endDate,
            })
          });
      }
      else{
        cookie.save('startDate', monthStartDate, { path: '/' });
        cookie.save('endDate', monthEndDate, { path: '/' });
        cookie.save('payrollId', payrollAll?.[0]?.id || 'custom', { path: '/' });
        history.push({
            pathname,
            search: queryString.stringify({
              ...nextQuery,
              payroll_id: payrollAll?.[0]?.id || 'custom',
              start_date: monthStartDate,
              end_date: monthEndDate,
            })
          });
      }
    }
    else{
      cookie.save('startDate', nextQuery.start_date ?? monthStartDate, { path: '/' });
      cookie.save('endDate', nextQuery.end_date?? monthEndDate, { path: '/' });
      cookie.save('payrollId', nextQuery.payroll_id, { path: '/' });
      this.fetchTimecards({...nextQuery, page: nextProps.page ? nextProps.page : nextQuery.page});
    }
  }

/**
* @function
* @description Fetches timecards based on the provided parameters. Handles loading state and updates the state with the fetched data.
* @param {Object} params - The parameters for fetching timecards.
*/
  fetchTimecards(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('timecard_page_size');
    const {parent,payroll_id, ...rest} = params;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : params?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : params?.team_ids;
    let departmentIdsArray;
    let teamIdsArray;
    
    if(department_ids){
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));


    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
    this.props.TimecardDucks.getCustomTimecards({...rest, page_size: pageSize,
      department_ids:departmentIdsIntegers?.length ? JSON.stringify(departmentIdsIntegers):JSON.stringify([]),
      team_ids:teamIdsIntegers?.length ? JSON.stringify(teamIdsIntegers) :JSON.stringify([]),payroll_id: departmentIdsIntegers.length >=0 ? 'custom': payroll_id})
      .finally(() => this.setState({ isLoading: false })) 
  }

/**
* @function
* @description Refreshes the timecards by re-fetching the current query.
*/
  refresh(){
    const { location: { query } } = this.props;
    return this.fetchTimecards(query);
  }

/**
* @function
* @description Toggles the visibility of additional info and saves the state to cookies.
*/
  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("timecard_info", !isInfoOpen);
  }

/**
* @function
* @description Loads data for the component, including basic settings and payrolls. Updates the URL based on user filters and payroll data.
* @param {Object} param - Optional parameter to determine how to load data.
*/
  loadData(param){
    const { location: { pathname, query } , userTimecardFilters : { data } , basicSettingDetail} = this.props;
    const { router: { history } } = this.context;
    const { page, ...rest } = query;
    Promise.all([
      basicSettingDetail ?  Promise.resolve({}):this.props.BasicSettingDucks.getBasicSetting(),
      data ?  Promise.resolve({}):this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'}),
      //this.props.BasicSettingDucks.getBasicSetting(),
      //this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'}),
      this.props.PayrollDucks.getAllPayrolls({ paginate: false,  default: true, valid: true })
    ]).then((resp) => {
      const { basicSettingDetail, userTimecardFilters } = this.props;
        const cookieDepartmentId = cookie.load('departmentId');
        const cookieTeamId = cookie.load('teamId');
        const cookiePayrollId = resp?.[2]?.value?.length===0 ? 'custom' : cookie.load('payrollId');
        const cookieStartDate = cookie.load('startDate');
        const cookieEndDate = cookie.load('endDate');

        let departmentIdsArray;
        if(cookieDepartmentId?.length){
     
          if(Array.isArray(cookieDepartmentId)){
            departmentIdsArray= cookieDepartmentId;
          } 
          else{
            departmentIdsArray= [cookieDepartmentId]
          }
        }
        else{
          departmentIdsArray=[];
        }
        const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

        let payrollId;
        let startDate;
        let endDate;
        let departmentId = cookieDepartmentId;
        let teamId = cookieTeamId;
        
        if(cookiePayrollId === 'custom'){
          payrollId = cookiePayrollId;
          startDate = cookieStartDate ? cookieStartDate : monthStartDate;
          endDate = cookieEndDate ? cookieEndDate : monthEndDate;
        }
        else{
          if(cookieDepartmentId?.length>0){
            this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_ids: JSON.stringify(departmentIdsIntegers)  })
              .then(() => {
                  const { departmentPayroll } = this.props;
                  const arr = departmentPayroll.filter((i) => i.id === cookiePayrollId);
                  if(arr.length){
                    payrollId = arr[0].id;
                    startDate = arr[0].start_date;
                    endDate = arr[0].end_date;
                  }
                  else if (departmentPayroll.length){
                    startDate = moment(departmentPayroll[0].start_datetime).format('YYYY-MM-DD');
                    endDate = moment(departmentPayroll[0].end_datetime).format('YYYY-MM-DD');
                    payrollId = departmentPayroll[0].id;
                  }
                  else if (!departmentPayroll.length){
                    payrollId = cookiePayrollId;
                    startDate = cookieStartDate;
                    endDate = cookieEndDate; 
                  }
                });   
            if(query.start_date){
            return this.fetchTimecards(query);
          }
          //return true;
          }
          else{
            const { payrollAll } = this.props;
            if(cookiePayrollId){
              const payroll = payrollAll.find((i) => i.id === cookiePayrollId);
              payrollId = payroll && payroll.id;
              startDate = payroll && moment(payroll.start_datetime).format('YYYY-MM-DD');
              endDate =  payroll && moment(payroll.end_datetime).format('YYYY-MM-DD'); 
            }
            else{
              payrollId = payrollAll[0].id;
              startDate =  payrollAll && payrollAll.length && moment(payrollAll[0].start_datetime).format('YYYY-MM-DD');
              endDate = payrollAll && payrollAll.length && moment(payrollAll[0].end_datetime).format('YYYY-MM-DD');
            }
          }

        }
          let newQueryParams = {};
          Object.keys(rest).forEach((key) => {
            newQueryParams[key] = rest[key];
          });
          data && Object.keys(data) && Object.keys(data).forEach((key) => {
            newQueryParams[key] = data[key];
          });

          newQueryParams['department_ids'] = departmentId ? departmentId : '';
          newQueryParams['team_ids'] = teamId ? teamId : ''


         if(param && !('payroll_id' in param )){
          return history.push({
              pathname,
              search: queryString.stringify({
                ...query,
                ...newQueryParams,
                manual_correction: query.manual_correction ? query.manual_correction : userTimecardFilters.data && userTimecardFilters.data.manual_correction ? userTimecardFilters.data.manual_correction : false,
                timecard_decimal_view: 
                userTimecardFilters && userTimecardFilters.data ? ((userTimecardFilters.data.timecard_decimal_view == "true" || userTimecardFilters.data.timecard_decimal_view == true) ? true : false ): basicSettingDetail.timecard_decimal_view,
                show_extra_info: query.show_extra_info ? query.show_extra_info : userTimecardFilters.data && userTimecardFilters.data.show_extra_info ? userTimecardFilters.data.show_extra_info : false,
                payroll_id:   userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.payroll_id ? userTimecardFilters.data.payroll_id :  payrollId,
                start_date: query.start_date ? query.start_date :   userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.start_date ? userTimecardFilters.data.start_date : startDate,
                end_date: query.end_date ? query.end_date :   userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.end_date ? userTimecardFilters.data.end_date : endDate,
              }),
            });
        }
        else if(param){
          this.fetchTimecards(query);
        }
        if(!('payroll_id' in query )){
          return history.push({
              pathname,
              search: queryString.stringify({
                ...query,
                ...newQueryParams,
                manual_correction: query.manual_correction ? query.manual_correction : userTimecardFilters.data && userTimecardFilters.data.manual_correction ? userTimecardFilters.data.manual_correction : 'false',
                timecard_decimal_view: 
                userTimecardFilters && userTimecardFilters.data ? ((userTimecardFilters.data.timecard_decimal_view == "true" || userTimecardFilters.data.timecard_decimal_view == true ) ? true : false) : basicSettingDetail.timecard_decimal_view,
                show_extra_info: query.show_extra_info ? query.show_extra_info : userTimecardFilters.data && userTimecardFilters.data.show_extra_info ? userTimecardFilters.data.show_extra_info : false,
                payroll_id:  userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.payroll_id ? userTimecardFilters.data.payroll_id : payrollId,
                start_date: query.start_date ? query.start_date :   userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.start_date ? userTimecardFilters.data.start_date : startDate,
                end_date: query.end_date ? query.end_date :   userTimecardFilters && userTimecardFilters.data && userTimecardFilters.data.end_date ? userTimecardFilters.data.end_date : endDate,
              }),
            });
        }
        else {
          this.fetchTimecards(query);
        }

      });
  }



/**
* @function
* @description Loads data for the component, including basic settings and payrolls. Updates the URL based on user filters and payroll data.
* @param {Object} param - Optional parameter to determine how to load data.
*/
  approveAllEmployee(){
    this.setState({isApproveAllEmployeesLoading: true});
    const { selectedEmployeeTimecards, location: { query } } = this.props;
    let empObj ={}
    if(query && ( query.payroll_id === 'custom' ||  !('payroll_id' in query) )) {
      empObj = {
        employee_ids : selectedEmployeeTimecards,
        start_date: query.start_date,
        end_date: query.end_date,
      }
    } else if( query && query.payroll_id) {
      empObj = {
        employee_ids : selectedEmployeeTimecards,
        payroll_id: query.payroll_id,
      }
    }
    if(empObj.employee_ids && empObj.employee_ids.length) {
      this.props.TimecardDucks.approveAllEmployee(empObj)
      .then(() => {
        toast.success("DailyTimecards of all selected employees have been approved!");
        this.setState({isApproveAllEmployeesLoading: false});
        this.refresh();
      })
      .catch((err) => {
        toast.error("Approval of dailytimecards of all the selected employees has been failed!");
        this.setState({isApproveAllEmployeesLoading: false})
      });
    }
  }


  /**
* @function
* @description update data for the component, including basic settings and payrolls. Updates the URL based on user filters and payroll data.
* @param {Object} param - Optional parameter to determine how to load data.
*/
  updateSelected(data) {
    return this.props.TimecardDucks.selectEmployeeTimecards(data);
  }

  updateAll(data){
    this.props.TimecardDucks.selectAllTimecards({ allSelected: !(data[0].allSelected) });
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout
        navInfo={NAV.CustomTimecardList}
        infoList={infoList}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.refresh}
      >
        <CustomTimecardFilterSection
          isLoading={isLoading}
          setAttributeToggler= {this.setAttributeToggler}
          attributeToggler={this.state.attributeToggler}
          approveAllEmployee={this.approveAllEmployee}
          selectedEmployeeTimecards={this.props.selectedEmployeeTimecards}
          isApproveAllEmployeesLoading={this.state.isApproveAllEmployeesLoading}
          userTimecardFilters={this.props.userTimecardFilters}
        />
        {this.state.attributeToggler &&
          <AttributeToggler
            setToggle={() => this.setAttributeToggler(!this.state.attributeToggler)}
            toggle={this.state.attributeToggler}
            data={{
              labels: Object.values(this.accessorToHeader),
              accessors: Object.keys(this.accessorToHeader),
              defaultVals: Object.values(this.state.showableAttributes)
            }}
            onChange={this.setAttributes}
            mandatoryField = 'first_name'
            setColumns={this.setColumns}
            isLoading={this.state.userViewPreferenceLoading}
          />
        }
        <CustomTimecardListSection
          isLoading={isLoading}
          showableAttributes={this.state.showableAttributes}
          updateSelected={this.updateSelected}
          updateAll={this.updateAll}
          />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  payrollAll: PayrollDucks.payrollAll(state),
  infoList: InfoDucks.infoList(state),
  userExtraData: UserDucks.userExtraData(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  profile: UserDucks.profile(state),
  userViewPreference: TimecardDucks.userViewPreference(state),
  selectedEmployeeTimecards: TimecardDucks.selectedEmployeeTimecards(state),
  userTimecardFilters : TimecardDucks.userTimecardFilters(state)
});

const mapActionsToProps = dispatch => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  //  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  //  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
});

CustomTimecardListPage.propTypes = {
  location: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  //  DepartmentDucks: PropTypes.object.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
  payrollAll: PropTypes.array.isRequired,
  TimecardDucks: PropTypes.object.isRequired,
  //  TeamDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

CustomTimecardListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomTimecardListPage);
