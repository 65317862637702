import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import CustomActivitySplitListPage from './CustomActivitySplitList';
import CustomActivitySplitForm from './CustomActivitySplitForm';
import ManualSessionPage from './ManualSession';
import TrackCurrentListPage from './TrackCurrentList';
import TrackHistoricListPage from './TrackHistoricList';
import ActivityHistoricSplitListPage from './ActivityHistoricSplitList'

const ActivityRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={`${match.url}/timecard/custom/list/:id/split`}
        name="custom.activity.split"
        component={CustomActivitySplitListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/timecard/list/:timecard/historic/:id/split`}
        name="activity.historic.split"
        component={ActivityHistoricSplitListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/timecard/custom/list/:id/split/add`}
        name="custom.split.add"
        component={CustomActivitySplitForm}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/timecard/list/:id/add-session`}
        name="activity.manual"
        component={ManualSessionPage}
      />
      <Redirect exact from={`${match.url}/tracking`} to={`${match.url}/tracking/current`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/tracking/current`}
        name="activity.tracking.current"
        component={TrackCurrentListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/tracking/historic`}
        name="activity.tracking.historic"
        component={TrackHistoricListPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

ActivityRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ActivityRoutes;
