import React from 'react';

import PropTypes from 'prop-types';

import Async from 'react-select/lib/Async';
import Select from 'react-select';
import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Label from 'reactstrap/lib/Label';

import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';

import _ from 'lodash';
import { server } from 'helpers/config';
import { LOCATION_IQ_API_KEY } from 'constants/geoFences';

const GeocodingOption = server.geocodingOption;

/**
 * GoogleAutoCompleteAtom
 *
 * - simpleValue: to return value only.
 * - getData: function which calls Google AutocompleteServiceAPI to get options.
 * - isLoading: show spinner until API is calling.
 */

class GoogleAutoCompleteAtom extends React.Component {
  constructor(props) {
    super(props);
    this.getOptions = this.getOptions.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  getOptions(text, callback){
    let params;
    const {
    input, viewbox, currentBrowserLocation , layout , label, onSelect, help, options, mapKeys,
    meta: { error, touched }, getData, onChange, google, ...rest
    } = this.props;
    if (text) {
      if(GeocodingOption == 'google maps'){
        const acService = new google.maps.places.AutocompleteService();
        acService.getPlacePredictions({
          input: text,
          bounds: new google.maps.LatLngBounds(),
          // types: [searchType === 'country' ? '(regions)' : '(cities)'],
        }, (places, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            callback(null, { options: places });
          }
        });
      }
      else 
      { 
        viewbox ?
        params = {
          q: text,
          bounded: '1',
          viewbox: `${viewbox.minLon},${viewbox.minLat},${viewbox.maxLon},${viewbox.maxLat}`,
          format: 'json' ,
          key : LOCATION_IQ_API_KEY
        }
        :
        params = {
          q: text,
          bounded: '1',
          countrycodes : currentBrowserLocation ? currentBrowserLocation : 'us',
          format: 'json' ,
          key : LOCATION_IQ_API_KEY
        };
        this.props.GeoFenceDucks.getAddress(params)
         .then((res)=> {
            callback(null, {
            options: res.value.data && res.value.data.map(i => {
                return ({ ...i , description: i.display_name, place_id:  i.place_id });             
            }) 
          });})
         .catch((err) => {
          callback(null, { options: [{description: "Can't find your address? Adjust map to the country where the address belongs", style: { color: 'red' }}] });
         });
      }
    }
    else {
      callback(null, { options: [] });
      console.log('optionss')
    }
  }

  handleChange(val){
    const {
      input ,  layout, label, onSelect, help, options, mapKeys,
      meta: { error, touched }, getData, onChange, google , ...rest
    } = this.props;
    if(GeocodingOption == 'google maps' && val){
      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails({
        placeId: val.place_id,
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          input.onChange({
            description: val.description,
            place_id: place.address_components,
            ...place.geometry,
          });
          onSelect({
            description: val.description,
            place_id: place.address_components,
            ...place.geometry,
          });
        }
      });
    }
    else if(val && val.lat){
      input.onChange(val);
      onSelect({location: {lat: parseFloat(val.lat), lng: parseFloat(val.lon)} , ...val});
    }
    else if(val == null){
      input.onChange(val);
      onSelect(null);
    }
  }

  handleBlur(){
    const {
    input, layout, label, onSelect, help, options, mapKeys,
    meta: { error, touched }, getData, onChange, ...rest
  } = this.props;
    input.onBlur();
  }


  
  render() {
    const {
    input, layout, label, onSelect, help, options, mapKeys,
    meta: { error, touched }, getData, onChange, ...rest
  } = this.props;

    return (
     <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol} className='select-input-width'>
          <Async
            {...input}
            {...rest}
            isLoading
            loadOptions={_.debounce(this.getOptions, 500)}
            menuContainerStyle={{ zIndex: 999 }}
            autoload
            autosize={false}
            onChange={(val) => this.handleChange(val)}
            onBlur={this.handleBlur}
            className="select-drop-color select-open select-hover"
            filterOption={() => true}
            cache = {false}
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<Col className='select-input-width'>
          <Async
            {...input}
            {...rest}
            isLoading
            loadOptions={_.debounce(this.getOptions, 500)}
            menuContainerStyle={{ zIndex: 999 }}
            autoload
            autosize={false}
            onChange={(val) => this.handleChange(val)}
            onBlur={this.handleBlur}
            className="select-drop-color select-open select-hover"
            filterOption={() => true}
            cache= {false}
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
      }
    </FormGroup>
    );
  };
}

const mapStateToProps = (state) => ({
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  currentBrowserLocation : ActivityTrackDucks.currentBrowserLocation(state),
});

const mapActionToProps = dispatch => ({
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(GoogleAutoCompleteAtom);
