import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';
import CountryCodeAtom from 'atoms/CountryCode';

/**
 * VendorSubCompanyFormPage -> VendorSubCompanyFormSection -> VendorSubCompanyFormComponent
 *
 * Props:
 *    - update
 */
const VendorSubCompanyFormComponent = (props) => {
  const {
    error,
    submitting,
    handleSubmit,
    createOrUpdate,
    isEdit
  } = props;

  const submit = data => createOrUpdate({ ...data, phone_ext: data.phone_ext ? data.phone_ext : '+1'})
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="ml-3 mr-4 pb-5">
      <div className="error-text">{error}</div>
        <Row>
          <Col xs={12} md={6}>
            <Field
              id="company_name"
              name="name"
              label="Business Name"
              component={InputAtom}
              placeholder="Business Name"
              layout={FORM_LAYOUT}
              />
          </Col>
          <Col md={6}>
            <Row>
              <Col md={3}>
                <Field
                  id="phone_ext"
                  name="phone_ext"
                  component={CountryCodeAtom}
                  label="Country Code"
                />
              </Col>
              <Col md={9}>
                <Field
                  id="phone_no"
                  name="phone_no"
                  label="Phone Number"
                  component={InputAtom}
                  placeholder="8055550123"
                  layout={FORM_LAYOUT}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Field
              id="first_name"
              name="first_name"
              label="First Name"
              component={InputAtom}
              placeholder="First Name"
              layout={FORM_LAYOUT}
            />
            <Field
              id="last_name"
              name="last_name"
              label="Last Name"
              component={InputAtom}
              placeholder="Last Name"
              layout={FORM_LAYOUT}
            />
          </Col>
            <Col xs={12} md={6}>                             
              <Field
              id="email"
              name="email"
              component={InputAtom}
              placeholder="Email"
              data-lpignore={true}
              disabled={isEdit}
              layout={FORM_LAYOUT}
              label={<span>
                      Email
                      <span className="float-right">
                        <div class="help-text"><i>We'll never share your email with anyone else.</i></div>
                      </span>
                     </span>}
            />
             
                        
            </Col>            
          </Row>         
          <Row className="float-sm-right">        
            <Button
              type="submit"
              color="primary"
              className="float-sm-right mr-4"
              size={FORM_LAYOUT.size}
            >
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>       
          </Row>
    </Form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Business Name';
  if (!values.first_name) errors.first_name = 'Enter First Name';
  if (!values.last_name) errors.last_name = 'Enter Last Name';
  if (!values.company_name) errors.company_name = 'Enter Company Name';
  if (!values.phone_no) errors.phone_no = 'Enter Phone Number';
  if (!values.email) errors.email = 'Enter Email';
  return errors;
};

VendorSubCompanyFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
};

VendorSubCompanyFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
}; 

export default reduxForm({
  form: 'subCompanyForm',
  validate,
})(VendorSubCompanyFormComponent);
