import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as VendorDucks from 'ducks/vendors/vendor';
import * as RouteDucks from 'ducks/routes';

import VendorTableComponent from 'components/vendors/list/Table';
import styles from './styles.module.scss';


/**
 * AdminDashBoardPage -> CustomerSummaryListSection
 *
 * Components:
 *    - {@link VendorTableComponent}
 *
 * State:
 *    - Customer Summary List
 *
 * Actions:
 *    None
 */

const CustomerSummaryListSection = (props, context) => {
  const { vendorList } = props;
  return (
    <Row>
      <Col sm="12" md="12">
        <Row>
          <Col sm="9" className="pt-4">
            <h5 className="font-styling">Top 10 Customer's Summary (Last 7 Days)</h5>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <VendorTableComponent
              data={vendorList}
              isCustomerTableFlag
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  vendorList: VendorDucks.vendorList(state),
});

const mapActionsToProps = () => ({});

CustomerSummaryListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

CustomerSummaryListSection.propTypes = {
  vendorList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerSummaryListSection);
