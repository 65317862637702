import React, { useState } from "react";

import Form from "reactstrap/lib/Form";

import styles from "sections/pto/styles.module.scss";
import { ReactComponent as ClearIcon } from "components/common/FilterForm/close.svg";

class  ViewTimeOffPoliciesHistorySlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  render() {
    const { oAuthToken, company_id, closeSlider,policy_name} = this.props;
    return (
      <div className={`${styles["view-time-off-policies-slider"]} w-30`}>
        <Form noValidate>
          <div>
            <div>
              <h4
                className={`${styles["add-activities-heading"]} d-inline-block mt-1 pl-4 mr-2 mb-0`}
              >
                History : {policy_name}
              </h4>
              <ClearIcon
                className={`${styles["clear-Icon"]}`}
                height="13"
                width="13"
                onClick={() => closeSlider({status:false,policy_id:'',policy_name:''})}
              />
            </div>
            <hr className={`${styles["horizontal-line"]} bg-primary`} />
            <div
              style={{
                height: "calc(100vh - 102px - 46px)",
              }}
            >
              <iframe
                id="scheduling"
                title="scheduling-iframe"
                width="100%"
                height="100%"
                frameBorder="0"
                src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-policy/employee-edit/timeOff-policies/?oAuthToken=${oAuthToken}&company_id=${company_id}&view_timeOff_policies=true&policy_id=${this.props.policy_id}&employee_id=${this.props.employee_id}`}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default ViewTimeOffPoliciesHistorySlider;
