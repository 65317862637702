import React from 'react';

import Field from 'redux-form/lib/Field';
import bindActionCreators from 'redux/lib/bindActionCreators';
import connect from 'react-redux/lib/connect/connect';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import SelectAtom from 'atoms/Select';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { EMPLOYEES_FILTER_OPTIONS, EMPLOYEES_FILTER_TYPE} from 'constants/vendors';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

import * as PluginDucks from 'ducks/vendors/plugin';
import * as TaskDucks from 'ducks/jobs/task';

class TaskTableComponent extends React.Component {

   constructor(props){
    super(props);
     this.state={
     selectedTasks:[],
     saveMappingLoader : false
   }
    this.handleTaskSelection = this.handleTaskSelection.bind(this);
    this.saveMapping = this.saveMapping.bind(this);
}


  saveMapping(){
    const { selectedTasks } = this.state;
    const { setTaskMapping } = this.props;

    const filterTasks = selectedTasks.filter(item => item.qbd_task_id);
    

    const detail = filterTasks && filterTasks.length && filterTasks.map((item) =>
       (
      { task_id: item.task_id,
        destination : { fullname : item.qbd_task_name , id : item.qbd_task_id}
      }
    ));

    if(detail!==0){
      this.setState({setMappingLoader : true});
      setTaskMapping(detail)
      .then(() => {
            this.setState({setMappingLoader : false})
        })
    }
    else
    {
      toast.error('Please select atleast one task to map');
    }
  }


  handleTaskSelection(selected, val){
    const { selectedTasks } = this.state;
    var index = selectedTasks.indexOf(selected);
    if(index === -1){
      selectedTasks.push(selected);
    }
    else{
      selectedTasks.splice(index, 1);
    }


  }



  render(){
    const { metcTasksList , source, location, qbdTasksList, onChangeTasks, removeTasksMapping, removeTaskId} = this.props;
    const { setMappingLoader } = this.state;
    const mapped = location.query.synced;
    return(
      <div>
              <Row>
                <Col>
                  <div className="filter">
                    <div className="d-inline-block">
                      <FilterDropdownComponent
                        paramKey="synced"
                        location={location}
                        options={EMPLOYEES_FILTER_OPTIONS}
                      />
                    </div>
                    <div className="d-inline-block">
                      <FilterDropdownComponent
                        paramKey="type"
                        location={location}
                        options={EMPLOYEES_FILTER_TYPE}
                      />
                    </div>
                    {mapped==='false' && metcTasksList.length>0  ?
                      <Button
                        type="submit"
                        color="secondary"
                        className={ `float-right`}
                        onClick={() => this.saveMapping()}
                      >
                        Save Association <ButtonLoaderAtom active={setMappingLoader} />
                      </Button>
                      :
                      null
                     /* <Button
                        type="submit"
                        color="secondary"
                        className={ `float-right`}
                        onClick={() => this.removeMapping()}
                      >
                        Remove Association
                      </Button>*/
                    }
                  </div>
                  </Col>
                  </Row>
                   <Table className={ location.query.synced === 'false' ? "table qbd-metc-table" : "table"} hover responsive size="sm">
                    <thead className={styles['table-header-main']}>
                      <tr>
                        <th
                          colSpan={location.query.synced === 'false' ? 2 : 1}
                          className={`${styles['border-right']} text-center ${styles['table-header-case']}`}>
                            Worksana Task(s)
                        </th>
                        <th
                          colSpan={location.query.synced === 'false' ? 3 : 4}
                          className={`text-center ${styles['table-header-case']}`}>
                            {source==='ADP'? 'ADP Task(s)' :'QuickBooks Task(s)'}
                        </th>
                      </tr>
                    </thead>
                        
                      <thead className={styles['table-header']}>
                      <tr>
                        {location.query.synced === 'false' && <th className="text-center">Select</th>}
                        <th className={styles['border-right']}>Name</th>
                        <th>Name</th>
                        <th></th><th></th>
                        {location.query.synced === 'true' && <th className="text-center">Remove</th>}
                      </tr>
                    </thead>
                   
                <tbody>
                {metcTasksList.length ? metcTasksList.map((item, index) => {
                    return(<tr key={index}>
                        {location.query.synced ==='false' &&
                        <td className="text-center">
                         <input
                            type="checkbox"
                            value={item.isSelected}
                            onChange={(val) =>this.handleTaskSelection(item, val)}
                          />
                          </td>
                        }
                        <td className={styles['border-right']}>{item.task.name ? item.task.name : null}</td>
                        {location.query.synced==='false' ?
                        <td className={styles['select-cell']}>
                          <Field
                             id={item.task_id}
                             name={item.task_id}
                             label=""
                             component={SelectAtom}
                             options={qbdTasksList && qbdTasksList.map(i => ({ label: i.task.fullname, value: i.task.id }))}
                             placeholder="Tasks"
                             labelKey="label"
                             valueKey="value"
                             layout={FORM_LAYOUT}
                             onChange={(i) => onChangeTasks({ ...item,
                                 qbd_task_id: i.value, qbd_task_name: i.label})}
                           />
                          </td>
                          : <td>{item.destination.fullname ? item.destination.fullname : null}</td>
                        }
                          <td></td>
                          {location.query.synced === 'true' ? <td></td> : null}
                          {location.query.synced==='true' ?
                          <td className="text-center">
                            <Button
                              role="button"
                              className={`btn btn-secondary btn-sm ${styles['remove-mapping']}`}
                              tabIndex="0"
                              onClick={() => removeTasksMapping({ task_id: item.task_id})}>
                              Remove Mapping <ButtonLoaderAtom active={removeTaskId === item.task_id} />
                            </Button>
                          </td> :
                          <td></td>
                        }
                        </tr>);
                        // eslint-enable 
                        }) : null}
                        {!metcTasksList.length && <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
                       </tbody>
                    </Table>
      </div>
    );
   }
  }
  
  const mapStateToProps = state => {
    return({  
      metcTasksList : PluginDucks.metcTasksList(state),
      qbdTasksList : PluginDucks.qbdTasksList(state),
    })
  };
          
  const mapActionToProps = dispatch => ({
    TaskDucks: bindActionCreators(TaskDucks, dispatch),
    PluginDucks: bindActionCreators(PluginDucks, dispatch),
  });

  export default connect(
   mapStateToProps,
   mapActionToProps,
  )(TaskTableComponent);