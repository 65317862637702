import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import GeoFenceFormPage from './Form';
import GeoFenceListPage from './List';

const GeoFenceRoutes = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/job/lists`}
        name="geofence.list"
        component={GeoFenceListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/job/lists/add`}
        name="geofence.add"
        component={GeoFenceFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/job/lists/edit`}
        name="geofence.edit"
        component={GeoFenceFormPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

GeoFenceRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default GeoFenceRoutes;
