import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  PAYROLL: `${SERVER_URL}/payrolls/`,
  PAYROLL_SETTING: `${SERVER_URL}/payrolls/settings/`,
  PAYROLL_CONTACT_US: `${SERVER_URL}/payroll/contact-us/`,
  PAYROLL_DEPARTMENT: `${SERVER_URL}/payrolls/department-payroll/`,
  CREATE_MEAL_RULE :`${SERVER_URL}/payrolls/payroll-configuration/`,
  PAYROLL_ADVANCED_SETTING: `${SERVER_URL}/payrolls/settings/`,
  CREATE_ADVANCED_SETTINGL_CONFIG :`${SERVER_URL}/payrolls/advance-configurations/`,
  ADVANCED_SETTINGS : '/advance-settings/'
};