import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import styles from './styles.module.scss';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';
import CloseCross from './CloseCross.png';

import { FORM_LAYOUT } from 'constants/layout';

class CustomerFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state={phone_ext_error:null}
  }

  submit(data) {
    const { create } = this.props;
    let detail = { user : {} };
    detail = { ...detail, ...data, tags: data.tags && data.tags.length ? data.tags.map(i => i.name) : []};
    if(!(data.user && data.user.is_active)){
      detail.is_active = false;
      detail.user.is_active=false;
    }
    detail.user.customer_name=data.customer_name;
    detail.user.poc = data.point_of_contact;
    detail.is_active = detail.user.is_active;
    return create(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
         if(!errObj.phone_ext)
        {
          throw new SubmissionError(errObj);
        }
        else
        {
          this.setState({phone_ext_error:errObj.phone_ext[0]});
        }
      });
  }

  render() {
    const { error, isOpen, tagList,
      toggle, handleSubmit, edit,
      submitting, toggleTagModal, isModalHidden } = this.props;
    const tags = tagList && tagList.length && tagList[0] ? (tagList[0].tag_collection && tagList[0].tag_collection.map((item) => {
      const data = {};
      data.id = item;
      data.name = item;
      return data;
    })) : [];

    return (
      <div>
        <Modal
          isOpen={isOpen}
          className={isModalHidden? "form-modal w-25 mx-auto hide-modal" :"form-modal w-25 mx-auto"}
          backdrop='static'
        >
          <ModalHeader className={styles.modal_header}>{edit ? 'Edit Customer' : 'Add Customer'}
          <img src={CloseCross} className={styles.close_button} height="14px" width="14px" alt="Close" onClick={() => toggle()} /> 
          </ModalHeader>
          <Row>
            <Col md={10} className="mx-auto pb-4 bg-white">
              <Form noValidate onSubmit={handleSubmit(this.submit)}>
                <ModalBody className={styles.modal_body_text}>
                {this.state.phone_ext_error &&
                  <div className="error-text">{this.state.phone_ext_error}</div>
                }
                  {error &&   
                    <div className="error-text">{error}</div>
                  }
                    <Field
                    id="customer_name"
                    name="customer_name"
                    label="Name"
                    component={InputAtom}
                    layout={FORM_LAYOUT}
                    placeholder="Customer Name"
                    />
                    <Field
                      id="point_of_contact"
                      name="point_of_contact"
                      label="Point Of Contact"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Point Of Contact"
                    />
                  {/* <Field
                    id="user.email"
                    name="user.email"
                    label="Email"
                    component={InputAtom}
                    layout={FORM_LAYOUT}
                    placeholder="Email"
                    data-lpignore={true}
                  /> */}
                    <Field
                      id="address"
                      name="address"
                      label="Address"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Address"
                    />
                  <Row>
                    <Col md={3}>
                      <Field
                        id="phone_ext"
                        name="phone_ext"
                        label="Code"
                        component={InputAtom}
                        layout={FORM_LAYOUT}
                        className={`${styles['phone-ext-fix']}`}
                      />
                    </Col>
                    <Col md={9} className="phoneFormPadding">
                      <Field
                        id="phone_no"
                        name="phone_no"
                        label="Phone Number"
                        component={InputAtom}
                        layout={FORM_LAYOUT}
                        placeholder="Phone Number"
                      />
                    </Col>
                  </Row>
                  {/* <Field
                    id="tags"
                    name="tags"
                    label="Tags"
                    component={CreateOptionMultiSelectAtom}
                    options={tagList.length ? tagList : []}
                    labelKey="name"
                    valueKey="id"
                    layout={FORM_LAYOUT}
                    isLoading={isTagLoading}
                    onCreateOption={createTag}
                    placeholder="Tags"
                    getOptions={getOptions}
                  /> */}
                    <Field
                      component={MultiSelectDropdownAtom}
                      id="tags"
                      name="tags"
                      placeholder="Select Tags"
                      items={tags}
                      itemToString={i => i && i.name}
                      label={<span>
                          Tags
                        <span className="float-right">
                          <div onClick={() => toggleTagModal(0)} tabIndex="0" role="button" className="link-name">
                            Manage Tags
                          </div>
                        </span>
                      </span>}
                    />
                  <div className={`mt-2`}>
                    <Field
                      id="user.is_active"
                      name="user.is_active"
                      label="Active"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                    />
                  </div>
                  <Button type="submit" color="primary" className="btn-block">
                    Submit <ButtonLoaderAtom active={submitting} />
                  </Button>
                </ModalBody>
              </Form>
            </Col>
          </Row>

        </Modal>
      </div>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.customer_name) errors.customer_name = 'Enter Name';
  return errors;
};

CustomerFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  tagList: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  isTagLoading: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createTag: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
};

CustomerFormModalComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'CustomerFormModal',
  validate,
  enableReinitialize: true,
})(CustomerFormModalComponent);
