import React from 'react';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { toast } from 'react-toastify';
import { UncontrolledTooltip } from 'reactstrap';
import SubmissionError from 'redux-form/lib/SubmissionError';

import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { FILTER_OPTIONS, MSGS } from 'constants/applicants';

import ResetPasswordIcon from 'applicants/assets/reset-password.png';
import AddEmployeeIcon from 'applicants/assets/add-employee.png';
import InActiveDefaultIcon from 'applicants/assets/inactive-d.png';
import ActiveIcon from 'applicants/assets/mark-active-default.png';

import ResetPasswordFormModalComponent from 'employees/components/ResetPasswordForm';
import styles from './styles.module.scss';

class EmployeeFilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, isLoading: false };
    this.goTo = this.goTo.bind(this);
    this.toggle = this.toggle.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }


  goTo(url) {
    const { history } = this.props;
    return history.push(url)
  }

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  resetPassword(data) {
    const { selectedApplicants } = this.props;

    this.props.usecase.resetPassword({
      employee_id: selectedApplicants.map((i) => i.id),
      password:data.password,
      re_password:data.confirm_password,

    })
      .then(() => {
        toast.success(MSGS.CHANGE_PASSWORD_SUCCESS);
        this.setState({ isOpen: !this.state.isOpen });
      })
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  updateStatus(status){
    const { selectedApplicants } = this.props;
    let obj = {
      employee_ids: selectedApplicants.filter(i => status !== i.is_active).map(i => i.id),
      is_active: status
    }
    this.props.usecase.updateStatus(obj);
  }

  render() {
    const { isOpen } = this.state;
    const { 
      location,
      location: { search, pathname } ,
      history,
      selectedApplicants,
    } = this.props;

    const query = queryString.parse(search);


    const selectedActiveEmployees = selectedApplicants.filter((i) => i.is_active);
    const selectedInActiveEmployees = selectedApplicants.filter((i) => !i.is_active);
    

    return (
      <div className="ml-3 mr-4">
        <Row className="filter">
          <Col md={12} className="pl-0 mb-2 pr-0">
            <Col className="float-left mb-2" md={2}>  
              <div className="float-left d-inline-block">
                <FilterDropdownComponent
                  options={FILTER_OPTIONS}
                  location={location}
                  paramKey="is_active"
                />
              </div>
            </Col>  
            <Col>
              <div className="float-right">
                <section className="float-left">
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedApplicants.length}
                    onClick={() => this.toggle()}
                  >
                    <div id="reset_password" className="mr-1">
                      <img
                        className={styles['button-icon']}
                        src={ResetPasswordIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="reset_password">
                        Reset Password
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                 
                 <Button
                    id="create_applicant"
                    color="accent"
                    className="mr-1"
                    onClick={() => this.goTo({
                      pathname: '/applicant/create',
                      search: queryString.stringify({
                        parent: `${pathname}${search}`,
                      })
                    })}
                  >
                    <img
                      className={styles['button-icon']}
                      src={AddEmployeeIcon}
                      alt="Icon"
                    />
                    <UncontrolledTooltip placement="top" target="create_applicant">
                      Create Applicant
                    </UncontrolledTooltip>
                  </Button> 
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedActiveEmployees.length}
                    onClick={() => this.updateStatus(false)}
                  >
                    <div id ="mark_inactive">
                      <img
                        className={styles['button-icon']}
                        src={InActiveDefaultIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="mark_inactive">
                        Mark Inactive
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                  <Button
                    color="accent"
                    className="mr-1"
                    disabled={!selectedInActiveEmployees.length}
                    onClick={() => this.updateStatus(true)}
                  >
                    <div id ="mark_active">
                      <img
                        className={styles['button-icon']}
                        src={ActiveIcon}
                        alt="Icon"
                      />
                      <UncontrolledTooltip placement="top" target="mark_active">
                        Mark Active
                      </UncontrolledTooltip>
                    </div>
                  </Button>
                </section>
                <div className="d-inline-block override-cross-bulk">
                  <FilterFormComponent
                    location={location}
                    placeholder="id,name,email,title,phone or tag"
                    initialValues={query}
                  />
                </div>
              </div>
            </Col>
          </Col>
        </Row>
        {isOpen &&
          <ResetPasswordFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            resetPassword={this.resetPassword}
          />
        }
      </div>            
    );
  }}

export default EmployeeFilterForm;
