import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import FieldArray from 'redux-form/lib/FieldArray';
import formValueSelector from 'redux-form/lib/formValueSelector';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DateTimePickerAtom from 'atoms/DateTimePicker';

import { FORM_LAYOUT } from 'constants/layout';

import AdjustmentHourFormComponent from './AdjustmentHourFormComponent';
import closeimage from './Close-Cross.png';

class  AdjustmentHourFormModalComponent extends React.Component {

  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
  }
  submit(data){
    const { update, dateSelected } = this.props;
    const newArray = data.adjustmentHour.map((item) => {
      const newItem = { ...item,
        regular_time_hours: (+item.regular_time_hours),
        regular_time_mins: (+item.regular_time_mins),
        over_time_hours: (+item.over_time_hours),
        over_time_mins: (+item.over_time_mins),
        double_over_time_hours: (+item.double_over_time_hours),
        double_over_time_mins: (+item.double_over_time_mins),
      };
      return newItem;
    });

/*    let regularHours =0;
    let regularMins = 0;
    let overtimeHours = 0;
    let overtimeMins = 0;
    let doubleOvertimeHours = 0;
    let doubleOvertimeMins = 0;

    const result = selectedValueArray && selectedValueArray.map((item,index)=>{
      if(item.regular_time_hours) regularHours+=parseInt(item.regular_time_hours,10);
      if(item.regular_time_mins) regularMins+=parseInt(item.regular_time_mins,10);
      if(item.over_time_hours) overtimeHours+=parseInt(item.over_time_hours,10);
      if(item.over_time_mins) overtimeMins+=parseInt(item.over_time_mins,10);
      if(item.double_over_time_hours) doubleOvertimeHours+=parseInt(item.double_over_time_hours,10);
      if(item.double_over_time_mins) doubleOvertimeMins+=parseInt(item.double_over_time_mins,10); 
      return true;
    });*/

   /* let sum = 0;
    sum = (+regularHours) + (+regularMins) + (+overtimeMins) + (+overtimeHours) + (+doubleOvertimeMins) + (+doubleOvertimeHours);
    if (sum<=0){
      return toast.error('Please add ajustment hours');
    }
    else{*/
    let error;
    data.adjustmentHour.forEach((item)=>{
      if(item.regular_time_hours>24) error = "Regular hours must be less than or equal to 24";
      if(item.regular_time_mins>59) error = "Regular mins must be less than 60";
      if(item.over_time_hours>24) error = "Overtime hours must be less than 24";
      if(item.over_time_mins>59) error = "Overtime mins must be less than 60";
      if(item.double_over_time_hours>24) error = "Double Overtime hours must be less than 24";
      if(item.double_over_time_mins>59) error = "Double Overtime mins must be less than 60";
      if(item.regular_time_hours && (isNaN(item.regular_time_hours) || item.regular_time_hours<0)) error = 'Enter valid hours in regular time';
      if(item.over_time_hours && (isNaN(item.over_time_hours) || item.over_time_hours<0)) error = 'Enter valid hours in overtime ';
      if(item.double_over_time_hours && (isNaN(item.double_over_time_hours) || item.double_over_time_hours<0)) error = 'Enter valid hours in double overtime';
      if(item.regular_time_mins && (isNaN(item.regular_time_mins) || item.regular_time_mins<0)) error = 'Enter valid minutes in regular time';
      if(item.over_time_mins && (isNaN(item.over_time_mins) || item.over_time_mins<0)) error = 'Enter valid minutes in overtime ';
      if(item.double_over_time_mins && (isNaN(item.double_over_time_mins) || item.double_over_time_mins<0)) error = 'Enter valid minutes in double overtime';
    });
    if(error){
      toast.error(error);
      return false;
    }
    const detail = { ...data,
      adjustmentHour: newArray,
      date: data.date ? data.date : dateSelected };
    return update(detail)
  }

  render() {

    let regularHours=0;
    let regularMins=0;
    let overtimeMins=0;
    let overtimeHours=0;
    let doubleOvertimeMins=0;
    let doubleOvertimeHours=0;

    const {  isOpen, submitting, selectedValueArray , handleSubmit, date, toggle,location,
       edit, adjustmentTypesList, adjustmentHourView, dateFormat, adjustmentTimecardList, custom } = this.props;
    const { query } = location;

    selectedValueArray && selectedValueArray.length && selectedValueArray.map((item,index)=>{
      if(item.regular_time_hours) regularHours+=parseInt(item.regular_time_hours,10);
      if(item.regular_time_mins) regularMins+=parseInt(item.regular_time_mins,10);
      if(item.over_time_hours) overtimeHours+=parseInt(item.over_time_hours,10);
      if(item.over_time_mins) overtimeMins+=parseInt(item.over_time_mins,10);
      if(item.double_over_time_hours) doubleOvertimeHours+=parseInt(item.double_over_time_hours,10);
      if(item.double_over_time_mins) doubleOvertimeMins+=parseInt(item.double_over_time_mins,10); 
      return true;
    });
    let startDate;
    let endDate;
    if(query && query.start_date)startDate=query.start_date;
    if(query && query.end_date) endDate=query.end_date;

    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static" className="form-modal modal-lg" autoComplete = "off">
        {!adjustmentHourView&&
          <ModalHeader>{edit ?"Edit Adjustment Hours":"Add Adjustment Hours"}
           <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
          </ModalHeader>
        }  
        {adjustmentHourView&&
          <ModalHeader>View Adjustment Hours
            <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
          </ModalHeader>
        }
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody>
            <Row>
              <Col md={5}>  
                {edit || custom ?
                  <p><b>Date : {date}</b></p>
                  :<Field
                    id="date"
                    name="date"
                    placeholder="Select Date"
                    component={DateTimePickerAtom}
                    options={{
                      dateFormat,
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (d) => moment(d).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(d).isBefore(moment(endDate).add(1, 'day')) 
                          // moment(d).isBefore(moment()),
                      ],
                    }}
                  />
                }
              </Col>
            </Row>    
            <FieldArray
              name="adjustmentHour"
              component={AdjustmentHourFormComponent}
              adjustmentTimecardList={adjustmentTimecardList}
              adjustmentTypesList={adjustmentTypesList}
              adjustmentHourView={adjustmentHourView}
              regularHours={regularHours}
              regularMins={regularMins}
              overtimeHours={overtimeHours}
              overtimeMins={overtimeMins}
              doubleOvertimeHours={doubleOvertimeHours}
              doubleOvertimeMins={doubleOvertimeMins}
            />
          </ModalBody>
          {(!adjustmentHourView && adjustmentTypesList.length>0) &&
          <ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>
          </ModalFooter>
          }
        </Form>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if(!values.date) errors.date = 'Select Date';
  return errors;
};

AdjustmentHourFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const withReduxForm = reduxForm({
  form: 'AdjustmentHourForm',
  validate,
  enableReinitialize: true,
})(AdjustmentHourFormModalComponent);


const selector = formValueSelector('AdjustmentHourForm');

export default connect(
  state => {
    const selectedValueArray = selector(state, 'adjustmentHour');
    return {
      selectedValueArray,
    };
  }
)(withReduxForm);