 import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import cookie from 'react-cookies';
import queryString from 'query-string';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';

import OvertimeSummaryFilterComponent from 'components/reports/overTimeSummary/FilterForm';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';

import { REPORT_KEY_MAPPINGS } from 'constants/reports';

import styles from './styles.module.scss';

/**
 * ReportDetailPage -> OvertimeFilterSection
 *
 * Components:
 *    - {@link OvertimeSummaryFilterComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - downloadReportData
 */
class OvertimeSummaryFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
    this.state = { isLoading : false };
  }

  export() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });

    return this.props.ReportDucks.downloadReportData({ ...query, ...params })
      .then((data) => {
        const date = moment().format('DD/MM/YYYY');
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = ((evx) => 
          {
            const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.navigator.msSaveOrOpenBlob(myBlob, `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`);
          });
          a.click();
        }
        // chrome ,safari and mozilla browsers 
        else {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const  url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`;
          element.click();
        }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { dateFormat, userCompany, location, departmentAll, userExtraData, teamAll , onDepartmentSelect} = this.props;
    const { query } = location;
    const formattedDates = {};
    const { isLoading } = this.state;

    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();

    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));
    }

    if (!userExtraData.department){
      departmentOptions.splice(0, 0, { value: "", label: "All Departments" })
    }
      
    if (teamAll && teamAll.length) {
      teamOptions = teamAll && teamAll.map(item => ({
        value: item.id, label: item.name,
      }));
    }
      
    if (!userExtraData.team) {
      teamOptions.splice(0, 0, { value: "", label: "All Teams" })
    }

    return (
      <Row className="report-filter ml-3 mr-4">
        <Col md={10} className="px-0">
          <section className={styles['custom-filter']}>
            {departmentOptions.length > 0 &&
              <div id ="department" className="d-inline-block department-index department-dropdown reportdropdown reportdropdown-override">
                <FilterDropdownComponent
                  paramKey="department_id"
                  location={location}
                  options={departmentOptions}
                  onChange={(val) => onDepartmentSelect(val)}
                />
                <UncontrolledTooltip placement="top" target="department">
                  Select Department
                </UncontrolledTooltip>
              </div>
            }
            {teamOptions.length > 0 &&
              <div id ="team" className="d-inline-block department-dropdown reportdropdown reportdropdown-override">
                <FilterDropdownComponent
                  paramKey="team_id"
                  location={location}
                  options={teamOptions}
                />
                <UncontrolledTooltip placement="top" target="team">
                  Select Team
                </UncontrolledTooltip>
              </div>
            }
          </section>
          <OvertimeSummaryFilterComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={{
              ...query,
              company_id: userCompany.id,
              ...formattedDates,
            }}
          />
        </Col>
        <Col md={2} className="px-0"> 
          {Object.keys(query).length > 0 &&  query.start_date &&
            <section className="float-right">
              <Button color="accent" className="mr-0" onClick={this.export}>
                Export <ButtonLoaderAtom active={isLoading} />
              </Button>
            </section>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
  userCompany: UserDucks.userCompany(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

OvertimeSummaryFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
};

OvertimeSummaryFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(OvertimeSummaryFilterSection); 
