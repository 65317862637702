import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';

import styles from './styles.module.scss';

/**
 * ManualSessionPage -> ManualSessionTimelineSection -> ManualSessionPunchOutComponent
 *
 * Props:
 *    None
 */
const ManualSessionPunchOutComponent = (props) => {
  const { dateFormat, endDate, item, startDate, timeFormat, timeFormat24Hrs, editEntry, reverseParseDate, browserParseDate } = props;

  return (
    <Card block className={`${styles.timelinePointer} activityCard`}>
      <CardTitle className="h5 f16">Out</CardTitle>
      <span className={styles['timeline-badge__punchOut']}></span>
      <Flatpickr
        className="form-control"
        value={item.end_datetime}
        options={{
          dateFormat: `${dateFormat}, ${timeFormat}`,
          enableTime: true,
          minuteIncrement: 1,
          time_24hr: timeFormat24Hrs,
          enable: [
            // Enable the dates b/w payroll startDate & endDate
            // Also no future date selection
            (date) => moment(date).isAfter(moment(startDate).subtract(1, 'day')) 
            // &&
            //   moment(date).isBefore(moment(endDate).add(1, 'day')) &&
            //   moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
           ],
        }}
        onChange={date => editEntry(item, date)}
      />
    </Card>
  );
};

ManualSessionPunchOutComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editEntry: PropTypes.func.isRequired,
};

export default ManualSessionPunchOutComponent;
