import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';
import SurveyEntity from 'surveys/entities/survey';
import SurveyDetailEntity from 'surveys/entities/surveyDetail';
import SurveyTemplateEntity from 'surveys/entities/surveyTemplateList';
import SurveyTemplateDetailEntity from 'surveys/entities/surveyTemplateDetail';

class SurveyAPIGateway {

  toString() {
    return 'SurveyAPIGateway';
  }

  async getSurveyList(params) {
    const listConfig =  AppConfig.server.apiHost + '/feedback/response/';
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map(i => new SurveyEntity(i));
    return { ...response.data, results: entity , count : response.data.count };
  }

  async getSurveyData(id){
    const listConfig =  AppConfig.server.apiHost + '/feedback/template/' + id + '/';
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load();
    const entity = response.data.template_data  && response.data.template_data.questions && (response.data.template_data.questions).length && (response.data.template_data.questions).map(i => new SurveyTemplateDetailEntity(i));
    return { ...response.data, template_data : { ...response.data.template_data , questions : entity }};
  }

  async getSurveyTemplates(params) {
    const templateConfig =  AppConfig.server.apiHost + '/feedback/template/';
    const apiGateway = new ApiGateway(templateConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map(i => new SurveyTemplateEntity(i));
    return { ...response.data, results: entity , count : response.data.count };
  }

   async getSurveyResponse(params) {
    const arr1 = [];
    let arr2 = [];
    let newAns = [];
    const listConfig =  AppConfig.server.apiHost + '/feedback/response/' + params + '/';
    const apiGateway = new ApiGateway(listConfig);
    const { data } = await apiGateway.load();
  
    arr1.push(data.template.template_data.questions);
    arr2.push(data.response.questions);

    let result = arr1.map((ques, qindex) =>{
      return ques.map((q)=>({...q , response : arr2[qindex].find((ans)=>ans.sequence_of_configuration == q.sequence_of_configuration).answer}))
    });
    result.forEach((res,resId)=>{
      res.forEach((newi)=>{
        const e = new SurveyDetailEntity(newi)
        newAns.push(e)
      })
     });
    return {results : newAns ,  surveyName : data.template.template_data.label , date : data.create_date_display, empName: data.user.full_name};
  }

  async getTemplateDetails(params){
    const arr1 = [];
    let newAns = [];
    const listConfig =  AppConfig.server.apiHost + '/feedback/template/' + params + '/';
    const apiGateway = new ApiGateway(listConfig);
    const { data } = await apiGateway.load();
  
    data.template_data.questions && arr1.push(data.template_data.questions);

    arr1.length && arr1.forEach((res,resId)=>{
      res.length && res.forEach((newi)=>{
        const e = new SurveyTemplateDetailEntity(newi)
        newAns.push(e)
      })
     });
    return {results : newAns ,  surveyName : data.template_data.label , date : data.create_date_display, surveyLanguage:data.template_data.survey_language, spanishLabel: data.template_data.sp_label && data.template_data.sp_label};
  }

  async getRolesList(params) {
    const roleConfig =  AppConfig.server.apiHost + '/company/employee/role/';
    const apiGateway = new ApiGateway(roleConfig);
    const response = await apiGateway.load(params);
    const roleList = response.data;
    return roleList;
  }

  async getDepartmentList(params) {
    const departmentListConfig =  AppConfig.server.apiHost + '/company/departments/'; 
    const apiGateway = new ApiGateway(departmentListConfig);
    const response = await apiGateway.load(params);
    const departmentList = response.data;
    return departmentList;
  }

  async getTeamList(params) {
    const teamListConfig =  AppConfig.server.apiHost + '/company/teams/';
    const apiGateway = new ApiGateway(teamListConfig);
    const response = await apiGateway.load(params);
    const teamList = response.data;
    return teamList;
  }

  async saveNewForm(params) {
    const postDataConfig =  AppConfig.server.apiHost + '/feedback/template/';
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.save(params);
    return response;
  }

  async updateSurveyData(data, id) {
    const updateDataConfig =  AppConfig.server.apiHost + '/feedback/template/' + id  + '/';
    const apiGateway = new ApiGateway(updateDataConfig);
    const response = await apiGateway.update(data);
    return response;
  }

  async deleteTemplate(id) {
    const updateDataConfig =  AppConfig.server.apiHost + '/feedback/template/' + id + '/';
    const apiGateway = new ApiGateway(updateDataConfig);
    const response = await apiGateway.delete();
    return response;
  }

}

export default SurveyAPIGateway;