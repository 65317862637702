import React from 'react';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import styles from './styles.module.scss';

import * as UserDucks from 'ducks/accounts/user';
import * as JobDucks from 'ducks/jobs/job';
import * as JobTaskDucks from 'ducks/jobs/jobTaskMapping';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as TaskTagDucks from 'ducks/jobs/taskTag';
import * as CustomerDucks from 'ducks/customers/customer';
import * as TaskDucks from 'ducks/jobs/task';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';
import { toast } from 'react-toastify';

import JobTaskMappingListSection from 'sections/jobs/jobTaskMappingList/List';

import GenericModal from 'components/common/GenericModal';
import SectionLoaderAtom from 'atoms/SectionLoader';
import JobTaskMappingTableComponent from 'components/jobs/jobTaskMappingList/Table';
import JobTaskFilterComponent from 'components/jobs/jobTaskEdit/Filter';
import PaginationComponent from 'components/common/Pagination';
import TagModalComponent from 'components/jobs/form/TagModal';
import JobTaskMappingFormModalComponent from 'components/jobs/jobTaskMappingList/FormModal';


class JobTaskEditSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.goTo = this.goTo.bind(this);
    this.getTasks = this.getTasks.bind(this);
    this.assignTask = this.assignTask.bind(this);
    this.assignByTag = this.assignByTag.bind(this);
    this.deleteTask=this.deleteTask.bind(this);
    this.jobList = this.jobList.bind(this);
    this.create = this.create.bind(this);
    this.createTag = this.createTag.bind(this);
    this.state = { status: null, job: {}, geoFences: [], isEmployeeTableLoading: false,
      isOpen: false, isTaskSubmitting: false ,isByTagSubmitting: false, isEmployeeSubmitting: false, isTaskTableLoading: false,isTaskLoading: false,
      isTeamSubmitting: false, isTagModal: false,tagSelected: null,tagTaskSelected: null,isModelOpen:false, selectedTask: null, taskDetail:null, isModalHidden: false}
    this.create = this.create.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.toggle = this.toggle.bind(this);
    this.editTask = this.editTask.bind(this);
    this.onTagSelect = this.onTagSelect.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.onTagTaskSelect = this.onTagTaskSelect.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.toggleTaskListModel = this.toggleTaskListModel.bind(this);
    this.isOpenToggle = this.isOpenToggle.bind(this);
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  componentWillMount(){
    this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
  }

  onTagSelect(val) {
    // const { tagSelected } =this.state;
    this.setState(({ tagSelected }) => ({ tagSelected: val }));
    this.setState({ isLoading: false });
  }

  getUpdatedList(){
    this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
  }

  isOpenToggle() {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen
    })
  }

  onTagTaskSelect(val) {
    this.setState({ tagTaskSelected: val });
    this.setState({ isTaskLoading: false });
  }

  getTasks(params) {
    const { jobDetail} = this.props;
    const { tagTaskSelected } =this.state;
    return this.props.TaskDucks.getTasks({ ...params, tag: tagTaskSelected && tagTaskSelected.value, assigned:false, job_id: jobDetail.id });
  }

  updateSelected(data) {
    return this.props.TaskDucks.selectTasks(data);
  }

  editTask(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen } = this.state;
    const { location } = this.props;
    const {query} = location;
    const param = {task_id: query.task_id};
    return this.props.TaskDucks.putTask(data,param)
      .then(() => {
        this.props.TaskDucks.getAssignedTasks({ job_id: params.id, assigned: true })
        this.setState({ isOpen: !isOpen });
        this.props.TaskDucks.getAllTasks({ paginate:false });
      });
  }

  toggle(data) {
    const { isOpen } = this.state;
    if(!data || !data.id) return this.setState({ isOpen: !isOpen });
    if(!isOpen){
      this.setState({taskDetail:data})
      this.setState({ isOpen: !isOpen });
    }
    else {
      this.setState({taskDetail:null})
    }
    return true;
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  toggleTaskListModel(item){
    const { isModelOpen } = this.state;
    return this.setState({ isModelOpen: !isModelOpen });
  }

  assignByTag() {
    const { jobDetail } = this.props;
    const { tagSelected } =this.state;
    const detail = {
      assigned: true,
      tag: tagSelected && tagSelected.value,
      id: jobDetail.id,
      task_ids:[],
    };
    if( tagSelected && tagSelected.value !== ""){
      this.setState({ isTaskLoading: true,isByTagSubmitting: true });
      return this.props.JobTaskDucks.putJobTaskMapping(detail)
        .then(() => {
          this.props.TaskDucks.getAssignedTasks({ job_id: jobDetail.id,assigned: true })
          this.setState({ isTaskLoading: false,isByTagSubmitting:false, tagSelected: null  });
        })
        .catch(() => this.setState({ isByTagSubmitting: false }));
    }
    return Promise.reject(toast.error('Please select a tag to assign'));
  }

  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }

  createTag(data) {
    return this.props.TaskTagDucks.postTaskTag({ ...data, tag_type: 1 })
  }

  create(data) {
    const { router: { history } } = this.context;
    const { location , jobDetail } = this.props;
    const {query, pathname} = location;
    return this.props.TaskDucks.postTask(data)
      .then((res) => {
        const addedTask = res.value;
        const tagsId = jobDetail && jobDetail.tags && jobDetail.tags.map(item => item.id );
        const detail = {
          assigned: true,
          customer: jobDetail.customer,
          tags: tagsId,
          task_ids: [addedTask.id],
          id: jobDetail.id,
        };
        const { page, pageSize, ...rest } = query;
        this.props.TaskDucks.getAllTasks({ paginate:false });
        return this.props.JobTaskDucks.putJobTaskMapping(detail)
          .then(() =>{
            history.push({ pathname, search: queryString.stringify(rest) });
          })
      });
  }

  assignTask(data) {
    const { router: { history } } = this.context;
    const { jobDetail, location: { pathname, query } } = this.props;
    if(!(data && data.task )) return Promise.reject(toast.error('Please select a task to assign'));
    const detail = {
      assigned: true,
      id: jobDetail.id,
      task_ids: data.task && data.task.value ? [data.task.value] : [],
    };
    const { page, pageSize, ...rest } = query;
    this.setState({ isTaskSubmitting: true });
    return this.props.JobTaskDucks.putJobTaskMapping(detail)
      .then(() =>{
        this.setState({ isTaskSubmitting: false });
        history.push({ pathname, search: queryString.stringify(rest) });
      })
      .catch(() => this.setState({ isTaskSubmitting: false }));
  }

  jobList() {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    const pathname = `/job/list?status=1`;
    const newPath = `/job/list/new?status=1`;
    query.admin_list ? history.push(newPath) : history.push(pathname);
  }

  deleteTask(task) {
    const { router: { history } } = this.context;
    const { jobDetail, location: { pathname, query } } = this.props;
    const tagsId = jobDetail && jobDetail.tags && jobDetail.tags.map(item => item.id );
    const detail = {
      assigned: false,
      id: jobDetail.id,
      tags: tagsId,
      task_ids: [task.id],
      customer: jobDetail.customer_data.id,
    };
    const { page, pageSize, ...rest } = query;
    this.props.JobTaskDucks.putJobTaskMapping(detail)
      .then(() => {
        history.push({ pathname, search: queryString.stringify(rest) });
      })
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  createOrUpdate(data) {
    const detail = data;
    return this.props.JobDucks.postJob(detail)
      .then((res) => {
        this.setState({ job: res.value });
        window.scroll(0,725);
        toast.success("Job successfully created !");
      });
  }


  goTo(url, data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    this.props.JobDucks.setJob(data);
    if(params.id){
      history.push(url);
    }
  }

  goToUrl(url) {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;

    if(query.setup){
      history.push({ pathname:`${url}`,
          search: queryString.stringify({...query, setup: true })});
    }else{
       history.push({ pathname:`${url}`,
        search : queryString.stringify({...query,parent: query.parent })
      });
    }
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { isTaskSubmitting, isByTagSubmitting , isTeamSubmitting,taskDetail,  } = this.state;
    const {
      loaded,
      location,
      jobDetail,
      taskAssignedList,
      taskTagList, isLoading,
      taskAll,
    } = this.props;
    let newTags;
    if(taskDetail && taskDetail.id) {
      newTags = taskDetail.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
    }
    const { isTaskTableLoading, isTagModal , isTaskLoading,isOpen, isModelOpen, isModalHidden} = this.state;
    const { query } = location;
    const tags = taskTagList&& taskTagList.length && taskTagList[0].tag_collection && taskTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    return (
      <section style={{padding: '0 80px', color: '#242424'}}>
        <JobTaskFilterComponent
          initialValues={jobDetail}
          loaded={loaded}
          isLoading={isLoading}
          location={location}
          goTo={this.goTo}
          getTask={this.getTasks}
          assignTask={this.assignTask}
          assignByTag={this.assignByTag}
          taskList={taskAssignedList}
          jobList={this.jobList}
          deleteTask={this.deleteTask}
          createTag={this.createTag}
          isTaskLoading={this.isTaskLoading}
          create={this.create}
          tagList = {taskTagList}
          isTaskSubmitting={isTaskSubmitting}
          isByTagSubmitting={isByTagSubmitting}
          isTaskTableLoading={isTaskTableLoading}
          isTeamSubmitting={isTeamSubmitting}
          jobDetail={jobDetail}
          isTagModal={isTagModal}
          toggleTagModal={this.toggleTagModal}
          onTagSelect={this.onTagSelect}
          onTagTaskSelect={this.onTagTaskSelect}
          toggleTaskListModel={this.toggleTaskListModel}
          taskAll={taskAll}
          isOpen={isOpen}
          isOpenToggle={this.isOpenToggle}
          isModalHidden={isModalHidden}
        />
        { isModelOpen &&
          <GenericModal
            toggle={this.toggleTaskListModel}
            isOpen={isModelOpen}
            heading="Task List"
            modelClassName={'session-modal'}
            bodyClassName={'session-modal-tasklist'}
            size="lg"
          >
            <JobTaskMappingListSection isLoading={isLoading} actionsRequired/>
          </GenericModal>
        }
        {isLoading || isTaskLoading
          ? <div style={{ position: "relative" }}><SectionLoaderAtom active /></div>
          :
          <section>
            <JobTaskMappingTableComponent
              data={taskAssignedList}
              location={location}
              updateSelected={this.updateSelected}
              deleteTask={this.deleteTask}
              toggle={this.toggle}
              isTaskTableLoading={isTaskTableLoading}
              jobDetail={jobDetail}
              toggleTagModal={this.toggleTagModal}
            />
            {isOpen &&
              <JobTaskMappingFormModalComponent
                tagList={taskTagList}
                isOpen={isOpen}
                toggle={this.toggle}
                create={this.editTask}
                initialValues={{
                  ...taskDetail,
                  tags: newTags,
                }}
                toggleTagModal={this.toggleTagModal}
                isModalHidden={isModalHidden}
                />
            }
            {isTagModal &&
              <TagModalComponent
                isModalOpen={isTagModal}
                toggle={this.toggleTagModal}
                createTag={this.createTag}
                tagsList={tags}
                deleteSelectedTags={this.deleteSelectedTags}
                updateTagName={this.updateTagName}
                tagsCollection={taskTagList}
                getUpdatedList={this.getUpdatedList}
                hideModal={this.hideModal}
              />
            }
            { isTaskTableLoading ? null
              :<PaginationComponent
                count={taskAssignedList.count}
                location={location}
                cookieKey="task_job_page_size"
              />}
            { query.setup ?
            <div>
              {/*
                <div className="float-left">
                  <Button
                    className="btn-job-primary btn-search btn btn-secondary button-padding"
                    onClick={() => this.goTo(`/job/list/${params.id}/edit?setup=true`)}
                  >
                    PREVIOUS
                  </Button>
                </div>
              */}
              <Row>
                <Col md={12} className="mt-2 mb-3 pr-0 text-right">
                  <Button
                    color="primary"
                    className={`${styles['btn_accent_width']} btn-accent`}
                    type='button'
                    onClick={() => this.goToUrl(`/job/list/`)}
                    >
                    Submit
                  </Button>
                  <Button
                    color="primary"
                    className={`${styles['btn_accent_width']} btn-accent`}
                    onClick={() => this.goToUrl(`/job/list/${params.id}/employee/edit`)}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
            :
            <div>

              <Row>
                <Col md={12} className="mt-2 mb-3 pr-0 text-right">
                  <Button
                    color="primary"
                    className={`${styles['btn_accent_width']} btn-accent`}
                    type='button'
                    onClick={() => this.goToUrl(query.admin_list ? `/job/list/new` :  `/job/list`)}
                    >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    className={`${styles['btn_accent_width']} btn-accent`}
                    onClick={() => this.goToUrl(`/job/list/${params.id}/employee/edit`)}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </div>
            }
          </section>}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  customerList: CustomerDucks.customerList(state),
  taskList: TaskDucks.taskList(state),
  taskAssignedList: TaskDucks.taskAssignedList(state),
  taskTagList: TaskTagDucks.taskTagList(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  taskAll: TaskDucks.taskAll(state),
});

const mapActionToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobTaskDucks: bindActionCreators(JobTaskDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

JobTaskEditSection.propTypes = {
  loaded: PropTypes.bool,
  jobDetail: PropTypes.object.isRequired,
  jobData: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array.isRequired,
  taskList: PropTypes.object,
  taskAssignedList: PropTypes.object,
};

JobTaskEditSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(JobTaskEditSection);
