import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import OvertimeDetailTableComponent from 'components/reports/overTimeSummary/DetailTable';

/**
 * OverTimeSummaryListSection -> OvertimeDetailListSection
 *
 * Components:
 *    - {@link OvertimeDetailTableComponent}
      - {@link PaginationComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */

const OvertimeDetailListSection = (props) => {
  const { data, isLoading, location } = props;
  const {query} = location;
  const cookieData = cookie.load("overtime_detail_page_size");
  let pageSize;

  if (query && query.page_size)  pageSize = +query.page_size;
  else if(cookieData) pageSize = +cookieData;
  else pageSize = 10;

  if (isLoading) return <SectionLoaderAtom active />;
  return (
    <section>
      <OvertimeDetailTableComponent
        data={data}
        location={location}
        pageSize={pageSize}
      />
      <PaginationComponent
        count={data.length}
        location={location}
        cookieKey="overtime_detail_page_size"
      />
    </section>
  );
};

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

OvertimeDetailListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

OvertimeDetailListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};


export default connect(
  mapStateToProps,
  mapActionsToProps,
)(OvertimeDetailListSection);

