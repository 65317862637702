import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import queryString from 'query-string';

import DateTimePickerAtom from 'atoms/DateTimePicker';


import { FILTER_FORM_LAYOUT } from 'constants/layout';

const EmployeeKioskTableFilterComponent = (props, context) => {
  const {
    dateFormat,
    handleSubmit,
    error,
    id,
    history, 
    location: { search }
  } = props;
 


  const submit = data => {
    const detail = { ...data };
    if (detail?.start_date && detail?.end_date) {
      const formattedStartDate = moment(detail?.start_date)
      const formattedEndDate = moment(detail?.end_date)
      if (formattedStartDate > formattedEndDate) {
        const errObj = {
          _error: 'The Start date should be less than or equal to end date.',
        };
        throw new SubmissionError(errObj);
      }
    }
   
    const query = queryString.parse(search);
    const newQueries = { ...query, start_date:moment(detail?.start_date).format('YYYY-MM-DD'),
                         end_date: moment(detail?.end_date).format('YYYY-MM-DD')};
    history.push({ pathname:`/employee/${id}/edit/kioskAuditLogs`,
                            search : queryString.stringify(newQueries) })
  };

  return (
    <Form inline onSubmit={handleSubmit(submit)} className="report-filter date-filter-width ml-3">
      <Field
        id="start_date"
        name="start_date"
        placeholder="Start Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
        value = {moment(new Date())}
      />
      <Field
        id="end_date"
        name="end_date"
        placeholder="End Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Button
        type="submit"
        color="accent"
        style={{ marginBottom: '24px'}}
        size={FILTER_FORM_LAYOUT.size}
        className="pr-4 pl-4"
      >
        Apply
      </Button>
      <div style={{ color: '#d9534f', marginTop: '-20px', 'font-size': '12px'}}>
        {error}
      </div>
    </Form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  const formattedStartDate=moment(values.start_date).format();
  const formattedEndDate=moment(values.end_date).format();
  if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
  return errors;
};

EmployeeKioskTableFilterComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
};

EmployeeKioskTableFilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'EmployeeKioskTableFilterComponent',
  validate,
  enableReinitialize: true,
})(EmployeeKioskTableFilterComponent);


