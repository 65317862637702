import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import DocuentSetupPage from './documentSetup';
import DocumentCompletionList from 'communicationCenter/pages/documentCompletionList';

const NotificationRoutes = (props) => {
  const { match } = props;
   return (
    <Switch>
      <Redirect exact from={match.url}/>
      <MatchWhenAuthorized
        path={`${match.url}/completion/report`}
        name="document.completion.report"
        exact
        component={DocumentCompletionList}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id`}
        name="documents.setup"
        component={DocuentSetupPage}
      />
     
      <Route component={NoMatch} />
    </Switch>
  );
};

NotificationRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default NotificationRoutes;