import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderComponent from 'components/common/SortHeader';


/**
 * ActivityTrackListPage -> ActivityTrackListSection -> ActivityTrackTableComponent
 *
 * Props:
 *    - activity track list
 */
const AbsentEmployeeTrackTableComponent = (props) => {
  const { data, session, toggle, locationArray } = props;
  
  return (
   <div className="ml-3 mr-4">
      <Table hover responsive size="sm" className="table-tracking">
      <thead className="Header-col">
        <tr>
          <SortHeaderComponent className="pl-3" ordering="user__first_name">First Name</SortHeaderComponent>
          <SortHeaderComponent ordering="user__last_name">Last Name</SortHeaderComponent>
          <th>Employee Id</th>
          <SortHeaderComponent ordering="title">Employee Title</SortHeaderComponent>
          <th className="pr-3">Department</th>
          <th className="pr-3">Team</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, index) => (
          <tr key={item.id}>
            <td title={item.first_name} className="pl-3">{item.first_name}</td>
            <td title={item.last_name}>{item.last_name}</td>
            <td title={item.employee_id}>{item.employee_id}</td>
            <td title={item.title}>{item.title}</td>
            <td title={item.department} className="pr-3">{item.department_name}</td>
            <td title={item.team} className="pr-3">{item.team_name}</td>
          </tr>
        ))}
        {!data?.length &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
    </Table> 
   </div> 
  );
};

AbsentEmployeeTrackTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  session: PropTypes.string.isRequired,
  toggle: PropTypes.func,
};

export default AbsentEmployeeTrackTableComponent;
