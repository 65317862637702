import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import { toast } from 'react-toastify';
import Table from 'reactstrap/lib/Table';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FileBrowserAtom from 'atoms/FileBrowser';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * VendorFormPage -> VendorFormSection -> BulkUploadFormComponent
 *
 * Props:
 *    - update
 */
 
class BulkUploadFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.downloadErrorFile = this.downloadErrorFile.bind(this);
    this.submit = this.submit.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.state = { file: null, logoUrl: null };
  }

  handleFileSelect(e) {
    const file = e.target.files[0];
    const reader = new FileReader();
    const fileExt = file && file.name.split('.').pop();
    const docxExt = ['csv', 'xls', 'xlsx'];

    if (docxExt.indexOf(fileExt) > -1){
      this.setState({ fileType: 'Document'});
    }  
    else {
      this.setState({ file: null, fileUrl: null });
      return toast.error('File type not supported');
    }
    reader.onload = () => this.setState({ file, logoUrl: reader.result });
    if (file) reader.readAsDataURL(file);
    return true;
  }
  
  downloadErrorFile() {
    const { csvData } = this.props;
    const fileData = csvData.join("\n");
    
    if (window.navigator.msSaveOrOpenBlob) {
      const a = document.createElement("download");
      document.body.appendChild(a);
      a.style.display = "none";
      a.onclick = ((evx) => 
      {
        const  myBlob =  new Blob([fileData] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        window.navigator.msSaveOrOpenBlob(myBlob, 'IncorrectRecords.csv');
      });
      a.click();
    }
    // chrome ,safari and mozilla browsers 
    else {
      const  myBlob =  new Blob([fileData] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const  url = window.URL.createObjectURL(myBlob);
      const element = document.createElement("a");
      document.body.appendChild(element);
      element.href = url;
      element.download = 'IncorrectRecords.csv';
      element.click();
    }
  }  

  exportFile(){
    const { sampleFile } = this.props;
    this.setState({ isExporting: true });
    return sampleFile()
      .then((data) => {
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = ((evx) => 
          {
            const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.navigator.msSaveOrOpenBlob(myBlob, 'sample.csv');
          });
          a.click();
        }
        // chrome ,safari and mozilla browsers 
        else {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const  url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = 'sample.csv';
          element.click();
        }
        this.setState({ isExporting: false });
      })
      .catch(() => this.setState({ isExporting: false }));
  }

  submit(data) {
    const { update } = this.props;
    const { file } = this.state;
    let userData = {}
    userData = new FormData();
    userData.append('job', file); 
    if(!file){
      return toast.error('File type not supported');
    }
    return update(userData)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const {
      error,
      submitting,
      handleSubmit,
      errorEmp,
      stats,
      isBulkLoading,
    } = this.props;
    
    return (
      <Form onSubmit={handleSubmit(this.submit)} noValidate className="ml-3 mr-4">
        <div className="error-text">{error}</div>
        <Row>
          <Col md={6}>
            <Field
              accept=".csv, .xls, .xlsx"
              id="file"
              name="file"
              label="Browse the file path (File should be in .csv , .xlsx, .xls format ONLY) "
              component={FileBrowserAtom}
              type="file"
              placeholder="Choose File"
              layout={FORM_LAYOUT}
              onChange={this.handleFileSelect}
            />
            <span className="button-a-tag" onClick={this.exportFile} role="button" tabIndex={-1}><i><u>Download sample file</u></i></span>
          </Col> 
          <Col className="pt-4 pl-0" md={1} style={{marginTop: '3.5px'}}>
            <Button
              type="submit"
              color="primary"
              className="btn-other"
              size={FORM_LAYOUT.size}
              disabled={isBulkLoading}
            >
              Upload <ButtonLoaderAtom active={submitting} />
            </Button>
          </Col>
          <Col className="text-right">
            <Row>
              <Col>
                <div
                  className={styles['employee-stats__box--success']}
                >
                  <p><span className={styles['stats-css-first']}>{stats && stats.created? stats.created : 0 }</span>/<span className={styles['stats-css']}>{stats && stats.total? stats.total : 0 }</span></p>
                  <p className={styles['subhead-font']}>created</p>
                  <p>SUCCESSFULLY</p>
                </div>
                <div
                  className={styles['employee-stats__box--danger']}
                >
                  <p><span className={styles['stats-css-first']}>{stats && stats.create_failed? stats.create_failed : 0 }</span>/<span className={styles['stats-css']}>{stats && stats.total? stats.total : 0 }</span></p>
                  <p className={styles['subhead-font']}>creation</p>
                  <p>FAILED</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  className={styles['employee-stats__box--success']}
                >
                  <p><span className={styles['stats-css-first']}>{stats && stats.updated? stats.updated : 0 }</span>/<span className={styles['stats-css']}>{stats && stats.total? stats.total : 0 }</span></p>
                  <p className={styles['subhead-font']}>updated</p>
                  <p>SUCCESSFULLY</p>
                </div>
                <div
                  className={styles['employee-stats__box--danger']}
                >
                  <p><span className={styles['stats-css-first']}>{stats && stats.update_failed? stats.update_failed : 0 }</span>/<span className={styles['stats-css']}>{stats && stats.total? stats.total : 0 }</span></p>
                  <p className={styles['subhead-font']}>update(s)</p>
                  <p>FAILED</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {isBulkLoading && <SectionLoaderAtom active />}    
        {errorEmp && errorEmp.length && !isBulkLoading && 
          <section>
            <Row className="mt-3">
              <Col>
                <h5> Please fix the errors below : </h5>
              </Col>
              <Col className="text-right">
                <span className="button-a-tag"  onClick={this.downloadErrorFile} role="button" tabIndex={-1}><i><u>Download incorrect records</u></i></span>
              </Col>
            </Row>     
            <Row>
              <Col>
                <Table responsive hover size="sm" className="table-timecard mt-0">
                  <thead>
                    <tr>
                      <th>Row</th>
                      <th>Error Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorEmp && errorEmp.length && errorEmp.map((item)=>{
                      const allErrors = Object.values(item.error[0]).join(', ');
                      return(<tr>
                        <td>Row {item.index + 2}</td>
                        <td title={allErrors} className="bulk-td">{allErrors}</td>
                      </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Col>  
            </Row>
          </section>  
        }
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.file) errors.file = 'Please select file to upload';
  return errors;
};

BulkUploadFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BulkUploadForm',
  validate,
  enableReinitialize: true,
})(BulkUploadFormComponent);
