import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import {exportFile} from 'helpers/utils';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import OvertimeDetailFilterComponent from 'components/reports/overTimeSummary/DetailFilterForm';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { REPORT_KEY_MAPPINGS } from 'constants/reports';

/**
 * ReportDataDetailPage -> OvertimeSummaryFilterDetailSection
 *
 * Components:
 *    - {@link OvertimeSummaryDetailFilterComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - downloadReportData
 */
class OvertimeDetailFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
    this.getParsedParams = this.getParsedParams.bind(this);
    this.state = { isLoading : false };
  }

  getParsedParams() {
    const { location: { query } } = this.props;
    let parsedParams;

    try {
      parsedParams = JSON.parse(query.reportDetail);
      parsedParams.start_date = moment(parsedParams.start_date).toISOString();
      parsedParams.end_date = moment(parsedParams.end_date).toISOString();
    } catch (e) {
      console.log(e);
    }
    return parsedParams;
  }

  export() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    let parsedParams;
      parsedParams = JSON.parse(query.reportDetail);

    return this.props.ReportDucks.downloadReportData({ ...parsedParams, ...params })
      .then((data) => {
        const date = moment().format('DD/MM/YYYY');
        exportFile(data, `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`);
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { dateFormat, location } = this.props;
    const { query } = location;
    const formattedDates = {};
    const { isLoading } = this.state;

    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    return (
      <Row className="filter ml-3 mr-4">
        <Col md={10} className="px-0">
          <OvertimeDetailFilterComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={{
              ...this.getParsedParams(),
              ...query,
              ...formattedDates,
            }}
          />
        </Col>
        <Col md={2} className="px-0">
          {Object.keys(query).length > 0 &&
            <section className="float-right">
              <Button color="accent" className="mr-0" onClick={this.export}>
                Export <ButtonLoaderAtom active={isLoading} />
              </Button>
            </section>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

OvertimeDetailFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

OvertimeDetailFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(OvertimeDetailFilterSection);
