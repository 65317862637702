import React from "react";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";

import * as RouteDucks from "ducks/routes";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import bindActionCreators from "redux/lib/bindActionCreators";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";

import PaginationComponent from "components/common/Pagination";
import GenericConfirmationModal from "components/common/GenericDeleteConfirmationModal";
import SendReminderModal from "forms/components/sendReminderModal";
import AssignEmployeeModal from "forms/components/assignEmployeeModal";

import FormsFilterForms from "forms/components/filterForms";
import AssessmentFormsListComponent from "forms/components/assessmentFormsList";
import moment from "moment";

class TrainingFormsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formList: [],
      formListCount: 0,
      isLoading: false,
      preview: false,
      formId: null,
      deleteFlag: false,
      isSubmitting: false,
      statusFlag: false,
      formData: null,
      selectedForms: [],
      sendReminderFlag: false,
      sendReminderLoader: false,
      assignEmployeeFlag: false,
      isAssigning: false,
      statusFlag: false,
    };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);

    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handleDeleteForm = this.handleDeleteForm.bind(this);
    this.toggleDelteModal = this.toggleDelteModal.bind(this);
    this.toggleStatusModal = this.toggleStatusModal.bind(this);
    this.handleStatusForm = this.handleStatusForm.bind(this);
    this.initialState = this.initialState.bind(this);
    this.onSelectForm = this.onSelectForm.bind(this);
    this.sendReminderToggler = this.sendReminderToggler.bind(this);
    this.assignEmployeeToggler = this.assignEmployeeToggler.bind(this);
    this.sendReminder = this.sendReminder.bind(this);
    this.assignEmployee = this.assignEmployee.bind(this);
  }

  initialState() {
    this.setState({ preview: false, selectedForms: [] });
  }

  componentDidMount() {
    this.listenListUsecase();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if(nextProps.refreshFlag){
      this.fetchData(nextQuery);
      this.props.updateState('refreshTrainingFormTabFlag',false)
    }
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  loadData() {
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    this.initialState();
    const pageSize = cookie.load("assesment_form_page_size") || 10;
    if (!("page_size" in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: pageSize,
          page: query.page || 1,
          is_active: true,
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  fetchData(params) {
    let obj = {
      ...params,
      form_type: "assessment",
    };
    this.usecase.getFormsList(obj);
    this.initialState();
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_FORMS_LIST_SUCCESS":
          this.updateState("formList", event.data.results);
          this.updateState("formListCount", event.data.count);
          break;

        case "GET_FORMS_LIST_FAILURE":
          this.updateState("formList", []);
          this.updateState("formListCount", 0);
          toast.error("Something went wrong!");
          break;

        case "DELETE_FORMS_SUCCESS":
          toast.success("Form Deleted Successfully !!!");
          this.setState({ isSubmitting: false });
          this.toggleDelteModal(null, false);
          this.loadData();
          break;

        case "DELETE_FORMS_FAILURE":
          this.setState({ isSubmitting: false });
          this.toggleDelteModal();
          toast.error(event.data? event.data : "Something went wrong!");
          break;

        case "STATUS_CHANGED_SUCCESS":
          toast.success("Status Changed Successfully !!!");
          this.setState({ isSubmitting: false })
          this.toggleStatusModal();
          this.loadData();
          break;

        case "STATUS_CHANGED_FAILURE":
          this.setState({ isSubmitting: false })
          this.toggleStatusModal();
          toast.error(event.data ?? "Status Change Failed !!!");
          break;

        case "SEND_REMINDER_SUCCESS":
          toast.success("Reminder send successfully!!!");
          this.setState({sendReminderLoader: false, sendReminderFlag: false});
          break;

        case "SEND_REMINDER_FAILURE":
          toast.error(event.data? event.data : "Something went wrong!");
          this.setState({sendReminderLoader: false, sendReminderFlag: false});
          break;

        case "MULTI_FORM_ASSIGN_SUCCESS":
          toast.success("Employee has been assigned successfully!!!");
          this.setState({isAssigning: false, assignEmployeeFlag: false});
          this.loadData();
          break;

        case "MULTI_FORM_ASSIGN_FAILURE":
          toast.error("Something went wrong!!!");
          this.setState({isAssigning: false, assignEmployeeFlag: false});
          break;  

        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;

        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;

        case "ERROR_TOAST":
          toast.error("Something went wrong!");
          break;

        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  toggleDelteModal(id, flag) {
    this.setState({ deleteFlag: flag, isSubmitting: false, formId: id });
  }

  handleDeleteForm() {
    const { formId } = this.state;
    this.setState({ isSubmitting: true });
    this.usecase.deleteForm({ id: formId });
  }

  toggleStatusModal(item, flag) {
    this.setState({ statusFlag: flag, formData: item });
  }
  handleStatusForm() {
    const { formData } = this.state;
    this.setState({ isSubmitting: true });
    let obj = {
      id: formData.id,
      is_active: !formData.status,
    };
    this.usecase.toggleStatusForm(obj);
  }

  onSelectForm(data){
    const { selectedForms, formList } = this.state;
    let formIds = [];
    if(data === 'all'){
      formIds = selectedForms.length === formList.length ? [] : formList.map(form => form.id);
      this.setState({selectedForms: formIds});
    }else{
      if(selectedForms.indexOf(data) > -1){
        let newIds = [...selectedForms];
        newIds.splice(selectedForms.indexOf(data), 1);
        this.setState({ selectedForms: [...newIds]});
      }else{
        this.setState({ selectedForms: [...selectedForms, data]});
      }
    }    
  }

  sendReminderToggler(){
    this.setState({sendReminderFlag: !this.state.sendReminderFlag});
  }

  assignEmployeeToggler(){
    this.setState({assignEmployeeFlag: !this.state.assignEmployeeFlag})
  }

  sendReminder(eventType, message){
    const {selectedForms} = this.state;
    this.setState({sendReminderLoader: true});
    this.usecase.sendReminders({
      event_type: eventType,
      message,
      template_ids: selectedForms,
    });
  }

  assignEmployee(selectedEmployees, allEmployeeSelected){
    const {selectedForms} = this.state;
    this.setState({ isAssigning: true });
    this.usecase.multiFormAssign({
      assign_employees: selectedEmployees,
      template_ids: selectedForms,
      assign_all_employees: allEmployeeSelected,
    })
  }

  render() {
    const {
      isLoading,
      preview,
      formList,
      formListCount,
      deleteFlag,
      isSubmitting,
      selectedForms,
      sendReminderFlag,
      sendReminderLoader,
      assignEmployeeFlag,
      isAssigning,
      statusFlag,
    } = this.state;
    const { dateFormat, location, history ,togglePreview} = this.props;
    const query = queryString.parse(location.search);
    return (
      <div style={{ display: preview ? "flex" : null }}>
        <div style={{ width: preview ? "70%" : "100%" }}>
          <div
            style={{ background: "white" }}
            className="mt-2 ml-3 mr-4 pb-3 pt-3"
          >
            <FormsFilterForms
              dateFormat={dateFormat}
              location={location}
              formType="trainingForms"
              initialValues={
                query.start_date && query.end_date
                  ? {
                      end_date: moment(query.end_date).toISOString(),
                      start_date: moment(query.start_date).toISOString(),
                    }
                  : { end_date: null, start_date: null }
              }
              selectedForms={selectedForms}
              sendReminderToggler={this.sendReminderToggler}
              assignEmployeeToggler={this.assignEmployeeToggler}
            />
            <AssessmentFormsListComponent
              list={formList}
              isLoading={isLoading}
              history={history}
              location={location}
              handlePreview={togglePreview}
              selectedForms={selectedForms}
              handleDelete={this.toggleDelteModal}
              handleStatus={this.toggleStatusModal}
              onSelectForm={this.onSelectForm}
            />
          </div>
          {formListCount && !isLoading ? (
            <PaginationComponent
              count={formListCount}
              location={location}
              cookieKey="assesment_form_page_size"
              history={this.props.history}
            />
          ) : null}
        </div>
        {deleteFlag && (
          <GenericConfirmationModal
            handleConfirmation={this.handleDeleteForm}
            toggle={this.toggleDelteModal}
            isSubmitting={isSubmitting}
            text={"Are you sure you want to delete it ?"}
            isOpen={deleteFlag}
          />
        )}
        {sendReminderFlag && (
          <SendReminderModal 
          sendReminderFlag={sendReminderFlag}
          sendReminderLoader={sendReminderLoader}
          sendReminderToggler={this.sendReminderToggler}
          sendReminder={this.sendReminder}
          />
        )}
        {assignEmployeeFlag && (
          <AssignEmployeeModal 
          assignEmployeeFlag={assignEmployeeFlag}
          isAssigning={isAssigning}
          assignEmployeeToggler={this.assignEmployeeToggler}
          assignEmployee={this.assignEmployee}
          />
        )}
        {statusFlag && (
          <GenericConfirmationModal
            handleConfirmation={this.handleStatusForm}
            toggle={this.toggleStatusModal}
            isSubmitting={isSubmitting}
            text={"Are you sure you want to change the status ?"}
            isOpen={statusFlag}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrainingFormsPage);
