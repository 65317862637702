const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/customers/list', label: 'Customers' },
];

export const NAV = {
  CustomerListPage: {
    title: 'Customers',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    parent: '/job/list',
    description: 'View Customers',
  },
  CustomerFormPage: {
    title: 'Customer Details',
    parent: '/customers/list',
    breadcrumb: [
      ...baseNav,
      { label: 'Details' },
    ],
  },
  CustomerBulkUploadPage: {
    title: 'Customer Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/customers/list',
    description: 'Upload all your customers',
  },
};

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];

export default { NAV, FILTER_OPTIONS };
