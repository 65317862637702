import React from "react";

import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import Field from "redux-form/lib/Field";
import formValueSelector from "redux-form/lib/formValueSelector";
import reduxForm from "redux-form/lib/reduxForm";
import SubmissionError from "redux-form/lib/SubmissionError";
import { toast } from "react-toastify";

import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Form from "reactstrap/lib/Form";
import Row from "reactstrap/lib/Row";

import { Card, Collapse } from "reactstrap";

import { toTitleCase } from "helpers/utils";

import ButtonLoaderAtom from "atoms/ButtonLoader";
import InputAtom from "atoms/Input";
import ToggleAtom from "atoms/Toggle";
import CheckboxAtom from "atoms/Checkbox";

import { FORM_LAYOUT } from "constants/layout";

import styles from "./styles.module.scss";
import caretArrowUp from "employees/components/caret-arrow-up.png";
import caretArrowDown from "employees/components/caret-down.png";
/**
 * VendorRoleListPage -> VendorRoleListSection -> VendorRoleListFormComponent
 *
 * Props:
 *    - update
 */
class VendorRoleListFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdminOpen: true,
      isKioskOpen: false,
      isSettingsOpen: false,
      isAppOpen: false,
      isNreOpen: false,
      isMiscellanousOpen: false,
      isSchedulingOpen: false,
      isPtoOpen: false,
    };
    this.submit = this.submit.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createAndUpdate = this.createAndUpdate.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handlePtoChange = this.handlePtoChange.bind(this);
  }

  createAndUpdate(data) {
    const { initialValues, createOrUpdate } = this.props;
    if (this.props.id) {
      const {
        name: prevName,
        description: prevDescription,
        ...prevRest
      } = initialValues;
      const { name, description, ...rest } = data;
      if (JSON.stringify(prevRest) !== JSON.stringify(rest)) {
        return createOrUpdate(data);
      }
      return createOrUpdate(data);
    }
    return createOrUpdate(data).catch((err) => {
      this.props.updateState("isConfirming", false);
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  }

  handleCheckboxChange(name, val) {
    const { change } = this.props;
    if (name == "geofence_punch_in_restriction") {
      if (
        !val &&
        !this.props.geofencePunchOut &&
        !this.props.geofenceMealStart &&
        !this.props.geofenceMealEnd &&
        !this.props.geofenceJobSwitch
      ) {
        change("geo_fence_restriction", false);
      }
    } else if (name == "geofence_punch_out_restriction") {
      if (
        !this.props.geofencePunchIn &&
        !val &&
        !this.props.geofenceMealStart &&
        !this.props.geofenceMealEnd &&
        !this.props.geofenceJobSwitch
      ) {
        change("geo_fence_restriction", false);
      }
    } else if (name == "geofence_meal_start_restriction") {
      if (
        !this.props.geofencePunchIn &&
        !this.props.geofencePunchOut &&
        !val &&
        !this.props.geofenceMealEnd &&
        !this.props.geofenceJobSwitch
      ) {
        change("geo_fence_restriction", false);
      }
    } else if (name == "geofence_meal_end_restriction") {
      if (
        !this.props.geofencePunchIn &&
        !this.props.geofencePunchOut &&
        !this.props.geofenceMealStart &&
        !val &&
        !this.props.geofenceJobSwitch
      ) {
        change("geo_fence_restriction", false);
      }
    } else if (name == "geofence_job_switch_restriction") {
      if (
        !this.props.geofencePunchIn &&
        !this.props.geofencePunchOut &&
        !this.props.geofenceMealStart &&
        !this.props.geofenceMealEnd &&
        !val
      ) {
        change("geo_fence_restriction", false);
      }
    } else if (name === "manual_mileage") {
      if (!val) {
        change("manual_mileage", false);
      }
    }
  }

  submit(data) {
    const {
      admin_panel_access,
      manage_employees,
      geo_fence_access,
      job_access,
      overview,
      reports,
      manage_timecards,
      manage_tracking,
      work_shift,
    } = data;
    if (admin_panel_access) {
      if (
        !manage_employees &&
        !geo_fence_access &&
        !job_access &&
        !overview &&
        !reports &&
        !manage_timecards &&
        !manage_tracking &&
        !work_shift
      ) {
        toast.error("Please grant access to at least one Admin Panel Module.");
      } else {
        this.createAndUpdate(data);
      }
    } else {
      this.createAndUpdate(data);
    }
  }

  handleChange(val, type) {
    const { change } = this.props;
    if (type === "admin" && !val) {
      change("manage_employees", false);
      change("geo_fence_access", false);
      change("job_access", false);
      change("overview", false);
      change("reports", false);
      change("manage_timecards", false);
      change("manage_tracking", false);
    }
    if (type === "geofence") {
      if (!val) {
        change("geofence_job_switch_restriction", false);
        change("geofence_meal_end_restriction", false);
        change("geofence_meal_start_restriction", false);
        change("geofence_punch_out_restriction", false);
        change("geofence_punch_in_restriction", false);
      } else {
        change("geofence_punch_in_restriction", true);
      }
    }
    if (type === "scheduling") {
      if (!val) {
        change("timefence_restriction_allow_override", false);
      }
    }
    if (type === "biometric") {
      if (!val) {
        change("pin_access", false);
      }
      change("enable_pincode_access", false);
    }
    if (type === "pincode") {
        change("biometric_access", false);
        change("pin_access", false);
    }
    if (type === "mileage") {
      if (!val) {
        change("manual_mileage", false);
      }
    }
  }

  handlePtoChange(name) {
    const { change } = this.props;
    switch (name) {
      case "pto_supervisor":
        change("pto_create", false);
        change("pto_view", false);
        break;
      case "pto_create":
        change("pto_supervisor", false);
        change("pto_view", false);
        break;
      case "pto_view":
        change("pto_create", false);
        change("pto_supervisor", false);
        break;
      default:
        break;
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const {
      error,
      handleSubmit,
      groupListByUse,
      adminPanel,
      isEdit,
      geofenceAccess,
      timefenceAccess,
      biometricAccess,
      isConfirming,
      userExtraData,
      mileageTrackingAccess,
    } = this.props;
    const {
      isAdminOpen,
      isAppOpen,
      isSettingsOpen,
      isKioskOpen,
      isMiscellanousOpen,
      isSchedulingOpen,
      isPtoOpen,
      isNreOpen,
    } = this.state;
    const geofenceRestrictionKey =
      groupListByUse &&
      groupListByUse[3] &&
      groupListByUse[3].groups &&
      groupListByUse[3].groups.find(
        (i) => i.group.name == "geo_fence_restriction"
      );
    const geofenceDependentKeys =
      groupListByUse &&
      groupListByUse[3] &&
      groupListByUse[3].groups &&
      groupListByUse[3].groups.filter(
        (i) => i.group.name != "geo_fence_restriction"
      );

    const manualMileageKey =
      groupListByUse &&
      groupListByUse[4] &&
      groupListByUse[4].groups &&
      groupListByUse[4].groups.find((i) => i.group.name === "manual_mileage");

    const mobileAppKey =
      groupListByUse &&
      groupListByUse[4] &&
      groupListByUse[4].groups &&
      groupListByUse[4].groups.filter((i) => i.group.name !== "manual_mileage");

    const timefenceRestrictionKey =
      groupListByUse &&
      groupListByUse[6] &&
      groupListByUse[6].groups &&
      groupListByUse[6].groups.find(
        (i) => i.group.name == "timefence_restriction_enabled"
      );
    const timefenceDependentKeys =
      groupListByUse &&
      groupListByUse[6] &&
      groupListByUse[6].groups &&
      groupListByUse[6].groups.filter(
        (i) => i.group.name != "timefence_restriction_enabled"
      );

    const biometricPinAccess =
      groupListByUse &&
      groupListByUse[2] &&
      groupListByUse[2].groups &&
      groupListByUse[2].groups.filter(
        (i) =>
          i.group.name !== "biometric_access" &&
          i.group.name !== "kiosk_access" &&
          i.group.name !== "enable_pincode_access"
      );

    const kioskAccessKey =
      groupListByUse &&
      groupListByUse[2] &&
      groupListByUse[2].groups &&
      groupListByUse[2].groups.filter((i) => i.group.name !== "pin_access");

    const ptoCheckboxList =
      groupListByUse && groupListByUse[7] && groupListByUse[7].groups;
    const miscellanousGroup =
      groupListByUse &&
      groupListByUse[5] &&
      groupListByUse[5].groups &&
      groupListByUse[5].groups.filter(
        (i) =>
          i.group.name === "task_time_allocation" ||
          i.group.name === "feedback_misc" ||
          userExtraData.company_permissions[i.group.name]
      );

    return (
      <Form
        className="ml-3 mr-4 pb-5"
        onSubmit={handleSubmit(this.submit)}
        noValidate
      >
        <div className="error-text">{error}</div>
        <Row className={styles["role-img"]}>
          <Col xs="12" md="6">
            <Field
              id="name"
              name="name"
              label="Name"
              component={InputAtom}
              placeholder="Role"
              layout={FORM_LAYOUT}
              disabled={isEdit === "false"}
            />
            <Field
              id="description"
              name="description"
              label="Description"
              component={InputAtom}
              placeholder="Description"
              layout={FORM_LAYOUT}
              disabled={isEdit === "false"}
            />
          </Col>
        </Row>
        <Card>
          <Row className="pl-3 mt-2">
            <Col
              md={4}
              onClick={() => this.updateState("isAdminOpen", !isAdminOpen)}
              className="remove-focus cursor-pointer"
            >
              <h5>
                {" "}
                Admin Panel
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isAdminOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isAdminOpen}>
                {groupListByUse &&
                  groupListByUse[0] &&
                  groupListByUse[0].groups &&
                  groupListByUse[0].groups.map((i) => (
                    <Row>
                      <Col md="6">
                        {i.label === "GEO FENCE"
                          ? "Geofence"
                          : i.label === "PAID TIME OFF"
                          ? "PTO (Paid Time Off)"
                          : toTitleCase(i.label)}
                      </Col>
                      <Col md="4">
                        <Field
                          id={`${i.group.name}`}
                          name={`${i.group.name}`}
                          type="boolean"
                          component={ToggleAtom}
                          layout={FORM_LAYOUT}
                          disabled={
                            (i.group.name !== "admin_panel_access" &&
                              !adminPanel) ||
                            isEdit === "false"
                          }
                          onSwitch={
                            i.group.name === "admin_panel_access"
                              ? (val) => this.handleChange(val, "admin")
                              : null
                          }
                        />
                      </Col>
                    </Row>
                  ))}
              </Collapse>
            </Col>
          </Row>
          <Row className="pl-3 mt-4">
            <Col
              md={4}
              onClick={() =>
                this.updateState("isSettingsOpen", !isSettingsOpen)
              }
              className="remove-focus cursor-pointer"
            >
              <h5>
                {" "}
                Settings
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isSettingsOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isSettingsOpen}>
                {groupListByUse &&
                  groupListByUse[1] &&
                  groupListByUse[1].groups &&
                  groupListByUse[1].groups.map((i) => (
                    <Row>
                      <Col md="6">{toTitleCase(i.label)}</Col>
                      <Col md="4">
                        <Field
                          id={`${i.group.name}`}
                          name={`${i.group.name}`}
                          type="boolean"
                          component={ToggleAtom}
                          disabled={isEdit === "false"}
                          layout={FORM_LAYOUT}
                        />
                      </Col>
                    </Row>
                  ))}
              </Collapse>
            </Col>
          </Row>
          <Row className="pl-3 mt-4">
            <Col
              md={4}
              onClick={() => this.updateState("isKioskOpen", !isKioskOpen)}
              className="cursor-pointer remove-focus"
            >
              <h5>
                {" "}
                Kiosk
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isKioskOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isKioskOpen}>
                {kioskAccessKey &&
                  kioskAccessKey.length &&
                  kioskAccessKey.map((i) => (
                    <>
                      <Row>
                        <Col md="6">{toTitleCase(i.label)}</Col>
                        <Col md="4">
                          <Field
                            id={`${i.group.name}`}
                            name={`${i.group.name}`}
                            type="boolean"
                            component={ToggleAtom}
                            layout={FORM_LAYOUT}
                            disabled={isEdit === "false"}
                            onSwitch={
                              i.group.name === "biometric_access"
                                ? (val) => this.handleChange(val, "biometric")
                                : i.group.name === "enable_pincode_access"
                                ? (val) => this.handleChange(val, "pincode")
                                : null
                            }
                          />
                        </Col>
                      </Row>
                      {i.group.name === "biometric_access" &&
                      biometricPinAccess &&
                      biometricPinAccess.length
                        ? biometricPinAccess.map((i) => (
                            <Row>
                              <Col md="6" className="pl-5">
                                {toTitleCase(i.label)}
                              </Col>
                              <Col md="4" className="ml-2">
                                <Field
                                  id={`${i.group.name}`}
                                  name={`${i.group.name}`}
                                  type="boolean"
                                  component={CheckboxAtom}
                                  layout={FORM_LAYOUT}
                                  disabled={
                                    isEdit === "false" || !biometricAccess
                                  }
                                  className={`${styles["checkbox-custom-height"]}`}
                                />
                              </Col>
                            </Row>
                          ))
                        : null}
                    </>
                  ))}
              </Collapse>
            </Col>
          </Row>

          <Row className="pl-3 mt-4">
            <Col
              md={4}
              onClick={() => this.updateState("isAppOpen", !isAppOpen)}
              className="remove-focus cursor-pointer"
            >
              <h5>
                {" "}
                Mobile App
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isAppOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isAppOpen}>
                {mobileAppKey && mobileAppKey.length
                  ? mobileAppKey.map((i) => (
                      <Row>
                        <Col md="6">{toTitleCase(i.label)}</Col>
                        <Col md="4">
                          <Field
                            id={`${i.group.name}`}
                            name={`${i.group.name}`}
                            type="boolean"
                            component={ToggleAtom}
                            layout={FORM_LAYOUT}
                            disabled={isEdit === "false"}
                            onSwitch={(val) =>
                              this.handleChange(
                                val,
                                i.label === "MILEAGE TRACKING"
                                  ? "mileage"
                                  : undefined
                              )
                            }
                          />
                        </Col>
                        {i.label === "MILEAGE TRACKING" && manualMileageKey ? (
                          <>
                            <Col md="6" className="pl-5">
                              {toTitleCase(manualMileageKey.label)}
                            </Col>
                            <Col md="4" className="ml-2">
                              <Field
                                id={`${manualMileageKey.group.name}`}
                                name={`${manualMileageKey.group.name}`}
                                type="boolean"
                                component={CheckboxAtom}
                                layout={FORM_LAYOUT}
                                disabled={
                                  isEdit === "false" || !mileageTrackingAccess
                                }
                                className={`${styles["checkbox-custom-height"]}`}
                                onSelect={(name, val) =>
                                  this.handleCheckboxChange(name, val)
                                }
                              />
                            </Col>
                          </>
                        ) : null}
                      </Row>
                    ))
                  : null}
                {geofenceRestrictionKey ? (
                  <Row>
                    <Col md="6">
                      {toTitleCase(geofenceRestrictionKey.label)}
                    </Col>
                    <Col md="4">
                      <Field
                        id={`${geofenceRestrictionKey.group.name}`}
                        name={`${geofenceRestrictionKey.group.name}`}
                        type="boolean"
                        component={ToggleAtom}
                        layout={FORM_LAYOUT}
                        disabled={isEdit === "false"}
                        onSwitch={(val) => this.handleChange(val, "geofence")}
                      />
                    </Col>
                  </Row>
                ) : null}
                {geofenceDependentKeys && geofenceDependentKeys.length
                  ? geofenceDependentKeys.map((i) => (
                      <Row>
                        <Col md="6" className="pl-5">
                          {toTitleCase(i.label)}
                        </Col>
                        <Col md="4" className="ml-2">
                          <Field
                            id={`${i.group.name}`}
                            name={`${i.group.name}`}
                            type="boolean"
                            component={CheckboxAtom}
                            layout={FORM_LAYOUT}
                            disabled={isEdit === "false" || !geofenceAccess}
                            className={`${styles["checkbox-custom-height"]}`}
                            onSelect={(name, val) =>
                              this.handleCheckboxChange(name, val)
                            }
                          />
                        </Col>
                      </Row>
                    ))
                  : null}
              </Collapse>
            </Col>
          </Row>
          {timefenceRestrictionKey ? (
            <div>
              <Row className="pl-3 mt-4">
                <Col
                  md={4}
                  onClick={() =>
                    this.updateState("isSchedulingOpen", !isSchedulingOpen)
                  }
                  className="cursor-pointer remove-focus"
                >
                  <h5>
                    {" "}
                    Scheduling
                    <span>
                      <img
                        className="ml-2 mr-2"
                        src={isSchedulingOpen ? caretArrowUp : caretArrowDown}
                        alt=""
                        height="10"
                        width="10"
                      />
                    </span>
                  </h5>
                </Col>
              </Row>
              <div className="">
                <hr className="mt-0 float-left" width="30%" />
              </div>
            </div>
          ) : null}
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isSchedulingOpen}>
                {timefenceRestrictionKey ? (
                  <Row>
                    <Col md="6">
                      {toTitleCase(timefenceRestrictionKey.label)}
                    </Col>
                    <Col md="4">
                      <Field
                        id={`${timefenceRestrictionKey.group.name}`}
                        name={`${timefenceRestrictionKey.group.name}`}
                        type="boolean"
                        component={ToggleAtom}
                        layout={FORM_LAYOUT}
                        disabled={isEdit === "false"}
                        onSwitch={(val) => this.handleChange(val, "scheduling")}
                      />
                    </Col>
                  </Row>
                ) : null}
                {timefenceDependentKeys && timefenceDependentKeys.length
                  ? timefenceDependentKeys.map((i) => (
                      <Row>
                        <Col md="6" className="pl-5">
                          {toTitleCase(i.label)}
                        </Col>
                        <Col md="4" className="ml-2">
                          <Field
                            id={`${i.group.name}`}
                            name={`${i.group.name}`}
                            type="boolean"
                            component={CheckboxAtom}
                            layout={FORM_LAYOUT}
                            disabled={isEdit === "false" || !timefenceAccess}
                            className={`${styles["checkbox-custom-height"]}`}
                          />
                        </Col>
                      </Row>
                    ))
                  : null}
              </Collapse>
            </Col>
          </Row>
          <Row className="pl-3 mt-4">
            <Col
              md={4}
              onClick={() =>
                this.updateState("isMiscellanousOpen", !isMiscellanousOpen)
              }
              className="cursor-pointer remove-focus"
            >
              <h5>
                {" "}
                Miscellaneous
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isMiscellanousOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isMiscellanousOpen}>
                {miscellanousGroup &&
                  miscellanousGroup.map((i) => (
                    <Row>
                      <Col md="6">{toTitleCase(i.label)}</Col>
                      <Col md="4">
                        <Field
                          id={`${i.group.name}`}
                          name={`${i.group.name}`}
                          type="boolean"
                          component={ToggleAtom}
                          layout={FORM_LAYOUT}
                          disabled={isEdit === "false"}
                        />
                      </Col>
                    </Row>
                  ))}
              </Collapse>
            </Col>
          </Row>
          <Row className="pl-3 mt-2">
            <Col
              md={4}
              onClick={() => this.updateState("isPtoOpen", !isPtoOpen)}
              className="remove-focus cursor-pointer"
            >
              <h5>
                {" "}
                PTO
                <span>
                  <img
                    className="ml-2 mr-2"
                    src={isPtoOpen ? caretArrowUp : caretArrowDown}
                    alt=""
                    height="10"
                    width="10"
                  />
                </span>
              </h5>
            </Col>
          </Row>
          <div className="">
            <hr className="mt-0 float-left" width="30%" />
          </div>
          <Row className="pl-3 mt-2">
            <Col>
              <Collapse isOpen={isPtoOpen}>
                {ptoCheckboxList && ptoCheckboxList.length
                  ? ptoCheckboxList.map((i) => (
                      <Row>
                        <Col md="6" className="">
                          {toTitleCase(i.label)}
                        </Col>
                        <Col md="4" className="">
                          <Field
                            id={`${i.group.name}`}
                            name={`${i.group.name}`}
                            type="boolean"
                            component={CheckboxAtom}
                            layout={FORM_LAYOUT}
                            className={`${styles["checkbox-custom-height"]}`}
                            onSelect={(name, val) =>
                              val && this.handlePtoChange(name)
                            }
                          />
                        </Col>
                      </Row>
                    ))
                  : null}
              </Collapse>
            </Col>
          </Row>
        </Card>
        <Row className="mt-2">
          {isEdit === "true" && (
            <Col className="text-right">
              <Button
                type="submit"
                color="secondary"
                size={FORM_LAYOUT.size}
                className="text-right"
              >
                Submit <ButtonLoaderAtom active={isConfirming} />
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Role Name";
  if (!values.description) errors.description = "Enter Description";
  return errors;
};

VendorRoleListFormComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
};

const roleForm = reduxForm({
  form: "VendorRoleForm",
  validate,
})(VendorRoleListFormComponent);

const selector = formValueSelector("VendorRoleForm");

export default connect((state) => {
  const adminPanel = selector(state, "admin_panel_access");
  const mobileApp = selector(state, "mobile_app_access");
  const kioskAccess = selector(state, "kiosk_access");
  const geofenceAccess = selector(state, "geo_fence_restriction");
  const geofencePunchIn = selector(state, "geofence_punch_in_restriction");
  const geofencePunchOut = selector(state, "geofence_punch_out_restriction");
  const geofenceMealStart = selector(state, "geofence_meal_start_restriction");
  const geofenceMealEnd = selector(state, "geofence_meal_end_restriction");
  const geofenceJobSwitch = selector(state, "geofence_job_switch_restriction");
  const timefenceAccess = selector(state, "timefence_restriction_enabled");
  const biometricAccess = selector(state, "biometric_access");
  const mileageTrackingAccess = selector(state, "mileage");
  const manualMileage = selector(state, "manual_mileage");
  return {
    adminPanel,
    mobileApp,
    kioskAccess,
    geofenceAccess,
    geofencePunchIn,
    geofencePunchOut,
    geofenceMealEnd,
    geofenceMealStart,
    geofenceJobSwitch,
    timefenceAccess,
    biometricAccess,
    mileageTrackingAccess,
    manualMileage,
  };
})(roleForm);
