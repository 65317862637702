import React from 'react';

import HomeHeroSection from 'sections/generics/home/Hero';

/**
 * Home Page
 *
 * Layout:
 *    {@link OneColumnLayout}
 *
 * Sections:
 *    {@link HomeHeroSection}
 *
 * Fetch Needs:
 *    None
 */
const HomePage = () => (
  <HomeHeroSection />
);

export default HomePage;
