import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';
import SortHeaderComponent from 'components/common/SortHeader';
import styles from './styles.module.scss';
/**
 * JobReportsPage -> JobReportsListSection ->  SummaryEmployeeTableComponent
 *
 * Props:
 *    - employee list
 */
const SummaryEmployeeTableComponent = (props, context) => {
  const { location: { query }, pageSize, data, showableAttributes, toggle, goTo} = props;
  const { page = 1 } = query;
  return (
    <div className="ml-3 mr-4">
      <Table responsive hover size="sm" className="table-reports">
      <thead>
        <tr>
          {showableAttributes.first_name &&
            <SortHeaderComponent ordering="first_name" className="pl-3">First</SortHeaderComponent>
          }
          {showableAttributes.last_name &&
            <SortHeaderComponent ordering="last_name">Last</SortHeaderComponent>
          }
          {showableAttributes.total_time &&
            <SortHeaderComponent ordering="total_time" className="text-right">Hours</SortHeaderComponent>
          }
          {showableAttributes.labor_cost &&
            <SortHeaderComponent ordering="labor_cost" className="text-right pr-3">Labor Cost</SortHeaderComponent>
          }
        </tr>
      </thead>
      <tbody>
        {data.length ? data.filter((item, index) =>
          index < page * pageSize && index >= (page - 1) * pageSize
        ).map(item => (
          <tr>
            <td className="text-left pl-3" hidden={!showableAttributes.first_name} title={item.first_name}>
              {item.first_name ? <span role="button" onClick={() => goTo(`/employee/${item.employee_id}/edit/detail`, "employee")} className={styles['clickable']}>{item.first_name}</span> : null}
            </td>
            <td className="text-left" hidden={!showableAttributes.last_name} title={item.last_name}>{item.last_name}</td>
            <td hidden={!showableAttributes.total_time} role="button" className={`${styles['clickable-timecard']} text-right`}><p onClick={() => toggle(item)} className="d-inline-block" style={{'marginBottom':'0'}}>{item.total_time}</p></td>
            <td className="text-right pr-3" hidden={!showableAttributes.labor_cost} title={item.labor_cost}>{item.labor_cost}</td>
          </tr>)
        ) : <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
      </tbody>
      </Table>
    </div>
  );
};

SummaryEmployeeTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SummaryEmployeeTableComponent;