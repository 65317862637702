import React from "react";

import PropTypes from "prop-types";

import styles from "./styles.module.scss";
import queryString from "query-string";

import MealDetailsBlue from "./MealDetailsBlue.svg";
import MealDetailsGrey from "./MealDetailsGrey.svg";
import MealSettingsBlue from "./MealSettingsBlue.svg";
import MealSettingsGrey from "./MealSettingsGrey.svg";
import EmployeeSelectionBlue from "./EmployeeSelectionBlue.svg";
import EmployeeSelectionGrey from "./EmployeeSelectionGrey.svg";

class MealProgressBarSection extends React.Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.state = {
      isGreen: false,
      selectedPage: "mealSettings",
    };
  }

  componentWillMount() {
    const { active } = this.props;
    if (active === 1) {
      this.setState({ selectedPage: "mealDetails" });
    } else if (active === 2) {
      this.setState({ selectedPage: "mealSettings" });
    } else if (active === 3) {
      this.setState({ selectedPage: "employeeSelection" });
    }
  }

  goTo(key) {
    const {
      router: { history },
    } = this.context;
    const { isInfoOpen, selectedId } = this.props;
    const {
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);

    if (key === "mealDetails") {
      this.setState({ selectedPage: "mealDetails" });
      if (!selectedId) {
        history.push({ pathname: "/vendor/meal-adjustments/mealDetails" });
      } else {
        history.push({
          pathname: `/vendor/meal-adjustments/${selectedId}/mealDetails`,
          search: queryString.stringify(query),
        });
      }
    }
    if (key === "mealSettings") {
      this.setState({ selectedPage: "mealSettings" });
      if (isInfoOpen) {
        history.push({
          pathname: `/vendor/meal-adjustments/${selectedId}/mealSettings`,
          search: queryString.stringify(query),
        });
      } else {
        history.push({
          pathname: `/vendor/meal-adjustments/${selectedId}/mealSettings`,
          search: queryString.stringify({ ...query, setup: true }),
        });
      }
    }
    if (key === "employeeSelection") {
      this.setState({ selectedPage: "employeeSelection" });
      if (isInfoOpen) {
        history.push({
          pathname: `/vendor/meal-adjustments/${selectedId}/employeeSelection`,
          search: queryString.stringify(query),
        });
      } else {
        history.push({
          pathname: `/vendor/meal-adjustments/${selectedId}/employeeSelection`,
          search: queryString.stringify({ ...query, setup: true }),
        });
      }
    }
  }

  render() {
    const { selectedId, rules } = this.props;
    let rule = JSON.stringify(rules);
    const { selectedPage } = this.state;
    return (
      <section>
        <div role="group">
          <button
            type="button"
            className={styles["progressBarborders"]}
            onClick={() => this.goTo("mealDetails")}
          >
            {selectedPage === "mealDetails" ? (
              <img
                src={MealDetailsBlue}
                height="18px"
                width="20px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            ) : !selectedId ? (
              <img
                src={MealDetailsGrey}
                height="18px"
                width="20px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            ) : (
              <img
                src={MealDetailsGrey}
                height="18px"
                width="20px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            )}
            <span
              style={
                selectedPage === "mealDetails"
                  ? { color: "#09819A" }
                  : !selectedId
                  ? { color: "#B1BABF" }
                  : { color: "black" }
              }
            >
              Meal Details
            </span>
          </button>
          <button
            disabled={!selectedId}
            type="button"
            className={styles["progressBar"]}
            onClick={() => this.goTo("mealSettings")}
          >
            {selectedPage === "mealSettings" ? (
              <img
                src={MealSettingsBlue}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            ) : !selectedId ? (
              <img
                src={MealSettingsGrey}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            ) : (
              <img
                src={MealSettingsGrey}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            )}
            <span
              style={
                selectedPage === "mealSettings"
                  ? { color: "#09819A" }
                  : !selectedId
                  ? { color: "#B1BABF" }
                  : { color: "black" }
              }
            >
              Meal Settings
            </span>
          </button>
          <button
            disabled={(!selectedId || (selectedId && (rule === "null" || rule === "{}" || rule === undefined)))}
            type="button"
            className={styles["progressBarEnd"]}
            onClick={() => this.goTo("employeeSelection")}
          >
            {selectedPage === "employeeSelection" ? (
              <img
                src={EmployeeSelectionBlue}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
                className={styles["progressBarIcons"]}
              />
            ) : (!selectedId || (selectedId && (rule === "null" || rule === "{}" || rule === undefined))) ? (
              <img
                src={EmployeeSelectionGrey}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            ) : (
              <img
                src={EmployeeSelectionGrey}
                height="20px"
                width="15px"
                alt="Logo"
                style={{ marginTop: "-6px", marginRight: "8px" }}
              />
            )}
            <span
              style={
                selectedPage === "employeeSelection"
                  ? { color: "#09819A" }
                  :(!selectedId || (selectedId && (rule === "null" || rule === "{}" || rule === undefined)))
                  ? { color: "#B1BABF" }
                  : { color: "black" }
              }
            >
              Employee Selection
            </span>
          </button>
        </div>
      </section>
    );
  }
}

MealProgressBarSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default MealProgressBarSection;
