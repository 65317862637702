const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/surveys/list', label: 'Surveys' },
];

export const NAV = {
  SurveyListPage: {
    title: 'Surveys',
    breadcrumb: [
      ...baseNav,
      { label: 'Surveys' },
    ],
    parent: '/dashboard',
    description: 'View & manage all your Employees responses',
  },
  CreateSurveyTemplate: {
    title: 'Create Survey Template',
    breadcrumb: [
      ...baseNav,
      { label: 'new' },
    ],
    parent: '/surveys/list',
    description: 'Create a new template for survey ',
  },
  EditSurveyTemplate: {
    title: 'Edit Survey Template',
    breadcrumb: [
      ...baseNav,
      { label: 'edit' },
    ],
    parent: '/surveys/list',
    description: 'Edit the survey template',
  },
  SurveyTemplateListPage: {
    title: 'Survey Template',
    breadcrumb: [
      ...baseNav,
      { label: 'Survey Template' },
    ],
    parent: '/dashboard',
    description: 'View date wise Survey',
  }
};

export const SURVEY_FILTER_OPTIONS = [
  { value: 'FAILED', label: 'Flagged' },
  { value: 'SUCCESS', label: 'Accepted' }
];

export const QUESTION_TYPE_OPTIONS = [
  { value: "SINGLE_SELECT", label: "Single Select" },
  { value: "MULTI_SELECT", label: "Multi Select" },
  { value:"FREE_TEXT", label: "Free Text" },
  { value:"DATE_TIME_SELECT", label: "Date & Time" },
  { value:"TIME_SELECT", label: "Time" }
];

export const QUESTION_TYPE_OPTIONS_OTHERS = [
  { value: "SINGLE_SELECT", label: "Single Select" },
  { value: "MULTI_SELECT", label: "Multi Select" },
  { value:"FREE_TEXT", label: "Free Text" },
]

export const ISACTIVE_FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];

export const SURVEY_TYPE_OPTIONS = [
  { value: "delayed_meal", label: 'Delayed Meal' },
  { value: "missing_meal", label: 'Missing Meal' },
  { value: "geofence_breach", label: 'Geofence Breach' },
  { value: "force_punch_out", label: 'Force Punch Out' },
  { value: "force_punch_out&delayed_meal", label: 'Force Punchout & Delayed Meal' },
  { value: "force_punch_out&missing_meal", label: 'Force Punchout & Missing Meal' },
  { value: "others", label: 'Others' },
];

export const SURVEY_LANGUAGE_OPTIONS = [
  { value: 'English', label: 'English' },
  { value: 'Spanish', label: 'Spanish' },
];

export const SURVEY_TYPE_FILTER_OPTIONS = [
  { value: "delayed_meal", name: 'Delayed Meal', id: 6 },
  { value: "missing_meal", name: 'Missing Meal', id: 1 },
  { value: "geofence_breach", name: 'Geofence Breach', id: 7 },
  { value: "force_punch_out", name: 'Force Punch Out', id: 2 },
  { value: "force_punch_out&delayed_meal", name: 'Force Punchout & Delayed Meal', id: 3 },
  { value: "force_punch_out&missing_meal", name: 'Force Punchout & Missing Meal', id: 4 },
  { value: "others", name: 'Others', id: 5 },
];

export default { NAV , SURVEY_FILTER_OPTIONS, QUESTION_TYPE_OPTIONS, ISACTIVE_FILTER_OPTIONS, SURVEY_TYPE_OPTIONS,SURVEY_LANGUAGE_OPTIONS, QUESTION_TYPE_OPTIONS_OTHERS, SURVEY_TYPE_FILTER_OPTIONS};
