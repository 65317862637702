import React from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import surveyFormUseCase from 'surveys/usecases/surveyFormUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import connect from 'react-redux/lib/connect/connect';

import SurveyFormQuestionsComponent from 'surveys/components/surveyFormQuestions';
import SurveyFormProgress from './surveyFormProgressBar';

import SectionLoaderAtom from 'atoms/SectionLoader';

class SurveyFormQuestions extends React.PureComponent {
  
  constructor(props) {
    super(props);
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new surveyFormUseCase(surveyAPIGateway);
    this.loadData = this.loadData.bind(this);
    this.listenFormUsecase = this.listenFormUsecase.bind(this);
    this.updateState = this.updateState.bind(this);
    this.submitData = this.submitData.bind(this);
    this.goToUrl=this.goToUrl.bind(this);
    this.state={ roleList : [] , formData : null, isLoading: false, isSaveLoaderActive:false, isNextLoaderActive: false }
  }

  componentWillMount(){
    this.loadData();
  }


  loadData(){
    const { match: { params } } = this.props;
    this.listenFormUsecase()
    if(params.id){
      this.usecase.getFormData(params.id)
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }
 
  goToUrl(){
    const { history } = this.props;
    const { match: { params } } = this.props;
    history.push(`/surveys/${params.id}/edit`);
  }


  listenFormUsecase() {
    const { history } = this.props;
    const { location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_SUBMIT_LOADER':
         if(event.buttonType && event.buttonType=='next'){
            this.updateState('isNextLoaderActive',true);
          }
          else{
            this.updateState('isSaveLoaderActive',true);
          }
          break;
        case 'HIDE_SUBMIT_LOADER':
          this.updateState('isNextLoaderActive',false);
          this.updateState('isSaveLoaderActive',false);
          break;
        case 'SUBMIT_QUES_FORM_SUCCESS':
          if(event.buttonType=='next'){
            {event.data && event.data.template_data && event.data.template_data.outcome == 'scoring' && event.data.survey_type.length && event.data.survey_type.find((i)=>i=='others') ? 
              history.push({
                pathname: `/surveys/${event.data.id}/outcomes`,
                search: queryString.stringify(query),
              })
              : 
              history.push('/surveys/list')
            }
          }
          else{
            history.push('/surveys/list')
          }          
          break;
        case 'SUBMIT_QUES_FORM_FAILURE':
          toast.error('Could not submit form.')
          break;
        case 'GET_SURVEY_DATA_SUCCESS':
          this.updateState('formData',event.data);
          break;
        case 'SHOW_FORM_LOADER':
          this.updateState('isLoading', true);
          break;  
        case 'HIDE_FORM_LOADER':
          this.updateState('isLoading', false);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  submitData(data, type){
    const { match: { params } } = this.props;
    this.usecase.updateTemplateData(data, params.id, type);
  }


  render() {
    const {roleList, isButtonLoader, isLoading, formData, isNextLoaderActive, isSaveLoaderActive} = this.state;
    const { match: { params } } = this.props;
    const { location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.EditSurveyTemplate : NAV.CreateSurveyTemplate}
        fetchData={() => this.loadData()}
      >
        <div className="ml-3 mr-4 pb-5">
        {isLoading ? 
          <SectionLoaderAtom active/> 
          :
          <div>
          <SurveyFormProgress 
            stepNumber={2}
            isStepThree = {formData && formData.template_data && formData.template_data.outcome == 'scoring' && formData.survey_type.length && formData.survey_type.find((i)=>i=='others') ? true : false} 
            selectedId = { !(query && query.setup == 'true') && formData && formData.id}
            history={this.props.history}
            query={query}
          />
          <SurveyFormQuestionsComponent 
            roleList={roleList}
            usecase={this.usecase}
            isNextButtonLoader={isNextLoaderActive}
            isSaveButtonLoader={isSaveLoaderActive}
            history={this.props.history}
            submitData={this.submitData}
            goToUrl={this.goToUrl}
            initialValues={ this.state.formData }
            query={query}
            isStepThree = {formData && formData.template_data && formData.template_data.outcome == 'scoring' && formData.survey_type.length && formData.survey_type.find((i)=>i=='others') ? true : false}
            dateFormat={this.props.dateFormat}
            timeFormat={this.props.timeFormat}
            timeFormat24Hrs = {this.props.timeFormat24Hrs}
          /> 
          </div>
        }
        </div>
        
      </TwoColumnLayout>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyFormQuestions);