import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import { reset, clearSubmitErrors } from 'redux-form';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as AddressDucks from 'ducks/vendors/address';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as RoleDucks from 'ducks/vendors/role';
import * as UserDucks from 'ducks/accounts/user';
import * as OnboardDucks from 'ducks/vendors/onboard';
import * as SystemSettingDucks from 'ducks/vendors/systemSetting';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import EmployeeFormModalComponent from 'components/generics/dashboardOnboard/EmployeeFormModal';
import { MSGS } from 'constants/employees';

/**
 * EmployeeFormPage -> EmployeeFormSection
 *
 * Components:
 *    - {@link EmployeeFormComponent}
 *
 * State:
 *    - employee detail for edit form
 *
 * Actions:
 *    - Form:
 *        - Create/update employee detail
 */
class EmployeeFormSection extends React.Component {

  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.state = { isLoadingRole: false, isModalLoading: false,
      isSaving: false, isSavingAndAddingNew: false, employeeUser: null, employeeName: null };
  }


  createOrUpdate(data, addNew) {
    const {  toggle } = this.props;

    return this.props.EmployeeDucks.postEmployee(data)
      .then((res) => {
        toast.success(MSGS.EMPLOYEE_ADD_SUCCESS)
        this.setState({ isModalLoading: true });
        if(res.value.phone_no){
         toggle(data);
        }
        else{
          this.setState({ employeeUser: res.value.user.username,
            employeeName: `${res.value.user.first_name} ${res.value.user.last_name}`,
          });
        }
        this.props.OnboardDucks.postTutorial({employee_management:"True"})
          .then(() => {
            this.props.OnboardDucks.getTutorial()
              .then(() => this.setState({ isModalLoading: false }));
          });
      });
  }

  onClose() {
    this.setState({employeeUser: null});
  }

  render() {
    const { tutorialDetail } = this.props;
    const {
      isLoading,
      isSubCompany,
      roleAll,
      userCompany,
      companyPermissions,
      toggle,
      isOpen,
      userExtraData,
      systemSettingList,
    } = this.props;

    const defaultRoleObj = roleAll.length && roleAll.find((item) => item.name === 'Employee');
    const defaultRole = defaultRoleObj && defaultRoleObj.id;
    const { isSavingAndAddingNew, isSaving, employeeUser,
      isModalLoading, employeeName } = this.state;
    let initialValues = {};
    initialValues = {
      phone_ext: userCompany.phone_ext,
      company_id : userExtraData.company.id,
      employee_role: defaultRole,
      is_active: true,
      user: { vendorCode: userCompany.code, is_active: true },
    };
    
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <EmployeeFormModalComponent
        initialValues={initialValues}
        isLoadingRole={this.state.isLoadingRole}
        createOrUpdate={this.createOrUpdate}
        roleAll={roleAll}
        isSubCompany={isSubCompany}
        companyPermissions={companyPermissions}
        handleStates={this.handleStates}
        isSavingAndAddingNew={isSavingAndAddingNew}
        isSaving={isSaving}
        toggle={toggle}
        isOpen={isOpen}
        systemSettingList={systemSettingList}
        employeeUser={employeeUser}
        onClose={this.onClose}
        isModalLoading={isModalLoading}
        employeeName={employeeName}
        tutorialDetail={tutorialDetail}
      />
    );
  };
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  roleAll: RoleDucks.roleAll(state),
  userCompany: UserDucks.userCompany(state),
  isSubCompany: UserDucks.isSubCompany(state),
  companyPermissions: UserDucks.companyPermissions(state),
  userExtraData: UserDucks.userExtraData(state),
  systemSettingList: SystemSettingDucks.systemSettingList(state),
  tutorialDetail:OnboardDucks.tutorialDetail(state),
});

const mapActionToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
  AddressDucks: bindActionCreators(AddressDucks, dispatch),
  OnboardDucks:bindActionCreators(OnboardDucks, dispatch),
  resetEmployeeForm: () => dispatch(reset('EmployeeForm')),
  resetErrors: () => dispatch(clearSubmitErrors('EmployeeForm')),
});

EmployeeFormSection.propTypes = {
  employeeDetail: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
  roleAll: PropTypes.array.isRequired,
  isSubCompany: PropTypes.number,
  systemSettingList: PropTypes.array,
};

EmployeeFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EmployeeFormSection);
