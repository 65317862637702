const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/knowledge-base/list', label: 'Knowledge Base' },
];

export const NAV = {
  KnowledgeBaseListPage: {
    title: 'Knowledge Base',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    description: 'View & manage all Knowledge Base',
  },
  KnowledgeBaseAddFormPage: {
    title: 'Add Knowledge Base',
    breadcrumb: [
      ...baseNav,
      { label: 'New' },
    ],
    parent: '/knowledge-base',
    description: 'Add New Knowledge-base',
  },
  KnowledgeBaseEditFormPage: {
    title: 'Edit Knowledge Base',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/knowledge-base',
    description: 'Edit Knowledge-base',
  },
};

export const BOOLEAN_OPTIONS = [
  { value: 'True', label: 'Yes' },
  { value: 'False', label: 'No' },
];

export const DATA_TYPE_OPTIONS = [
  { value: 'Image', label: 'Image' },
  { value: 'Video', label: 'Video' },
  { value: 'Document', label: 'Document' },
];

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];

export default { NAV, BOOLEAN_OPTIONS, DATA_TYPE_OPTIONS, FILTER_OPTIONS };
