import React from 'react';

import Table from 'reactstrap/lib/Table';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Button from 'reactstrap/lib/Button';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { EMPLOYEES_FILTER_OPTIONS } from 'constants/vendors';

import styles from './styles.module.scss';

class PayrollItemsComponent extends React.Component {

   constructor(props){
    super(props);
     this.state={
     selectedPayrolls:[],
   }
    this.handlePayrollSelection = this.handlePayrollSelection.bind(this);
    this.saveMapping = this.saveMapping.bind(this);
}


  saveMapping(){
    const { selectedPayrolls } = this.state;
    const { setPayrollMapping } = this.props;

    const detail = selectedPayrolls && selectedPayrolls.length ? selectedPayrolls.map((item) =>
       (
      { list_id: item.list_id,
        destination_payroll : { name : item.qbd_payroll_name , id : item.qbd_payroll_id}
      }
    )) : null ;
    setPayrollMapping(detail);
  }


  handlePayrollSelection(selected, val){
    const { selectedPayrolls } = this.state;
    var index = selectedPayrolls.indexOf(selected);
    if(index === -1){
      selectedPayrolls.push(selected);
    }
    else{
      selectedPayrolls.splice(selected);
    }
  }

  render(){
    const { source, metcaAdjustmentTypesList , location , removePayrollsMapping, removePayrollId} = this.props;
    return(
      <div>
        <Row>
          <Col>
            <div className="filter">
              <div className="d-inline-block">
                <FilterDropdownComponent
                  paramKey="synced"
                  location={location}
                  options={EMPLOYEES_FILTER_OPTIONS}
                />
              </div>
            </div>
          </Col>
        </Row>
          <Table className={ location.query.synced === 'false' ? "table qbd-metc-table" : "table"} hover responsive size="sm">
            <thead className={styles['table-header-main']}>
              <tr>
                <th
                  colSpan={location.query.synced === 'true' ? 1 : 2}
                  className={`${styles['border-right']} text-center ${styles['table-header-case']}`}>
                    Worksana Payroll(s)
                </th>
                <th
                  colSpan={location.query.synced === 'true' ? source==='ADP' ? 3 : 2 : 4}
                  className={`text-center ${styles['table-header-case']}`}>
                    {source==='ADP'? 'ADP Payroll(s)' : 'QuickBooks Payroll(s)'}
                </th>
              </tr>
            </thead>
            <thead className={styles['table-header']}>
              <tr>             
                <th className={styles['border-right']}>Name</th>
                <th>Name</th>
                {source==='ADP' && <th>Payroll Group Code</th>}
                {location.query.synced === 'true' && <th className="text-center">Remove</th>}
              </tr>
            </thead>
           
            <tbody>
              {metcaAdjustmentTypesList.length ? metcaAdjustmentTypesList.map((item, index) => {
                return(
                  <tr key={index}>                   
                    <td className={styles['border-right']}>
                    {item.payroll.name ? item.payroll.name : null}
                    </td>
                    {source==='ADP' ? 
                    <td title={item.metc_payroll && item.metc_payroll.length && item.metc_payroll.map((item)=> ` ${item.name}`).join()}>{item.metc_payroll!== null ? item.metc_payroll && item.metc_payroll.map((item)=> ` ${item.name}`).join() : null}</td>
                    :
                    <td>{item.metc_payroll!== null && item.metc_payroll.name ? item.metc_payroll.name :  null}</td>
                    }
                    {source==='ADP' && <td title={item.metc_payroll && item.metc_payroll.length ? item.metc_payroll.map((item)=> ` ${item.groupCode ? item.groupCode : ''}` ).join() : null}>{item.metc_payroll && item.metc_payroll.length ? item.metc_payroll.map((item)=> ` ${item.groupCode ? item.groupCode : ''}`).join() : null}</td>}
                    <td className="text-center">
                      <Button
                        role="button"
                        className={`btn btn-secondary btn-sm ${styles['remove-mapping']}`}
                        tabIndex="0"
                        onClick={() => removePayrollsMapping({ list_id: item.list_id})}>
                        Remove Mapping <ButtonLoaderAtom active={removePayrollId === item.list_id} />
                      </Button>                      
                    </td>                  
                  </tr>);
               // eslint-enable 
                  }) : null
              }
              {!metcaAdjustmentTypesList.length && <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
            </tbody>
          </Table>
        </div>
      );
  }
}

export default PayrollItemsComponent;