import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import styles from './styles.module.scss';
import { UncontrolledTooltip } from 'reactstrap';


const Subheader = (employeeData, formatPhoneNumber) => {
  return (
      <div>
        <Row className='ml-0 mt-1'>
          <Col md={9}>
            {employeeData && employeeData.phone_no &&
              <Row>
                <Col md={1} className='text-left pr-0'>
                  <div><b>Phone:</b></div> 
                </Col>
                <Col md={7} className="text-left">
                  <div className={`${styles['subheader-ellipses']} text-left`}>{formatPhoneNumber(employeeData.phone_no)} </div> 
                </Col>
              </Row>
            }
            {employeeData && employeeData.email &&
              <Row>
                <Col md={1} className='text-left pr-0'>
                  <div><b>Email:</b></div> 
                </Col>
                <Col md={7} className="text-left">
                  <div className={`${styles['subheader-ellipses']}`}>
                    <span id='email'>
                      <UncontrolledTooltip placement="bottom" target="email">
                        {employeeData.email}
                      </UncontrolledTooltip>
                      {employeeData.email}
                    </span>
                  </div> 
                </Col>
              </Row>
            }
            {employeeData && employeeData.address &&
              <Row>
                <Col md={1} className='text-left pr-0'>
                  <div><b>Address:</b></div> 
                </Col>
                <Col md={7} className="text-left">
                  <div className={`${styles['subheader-ellipses']}`}>{employeeData.address} </div> 
                </Col>
              </Row>
            }
          </Col>
        </Row>
    </div>
  );
};

export default Subheader;

