import React, { Component } from "react";

import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Formik } from "formik";
import { Label, Form, FormText, FormGroup, Input, Button } from "reactstrap";
import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import CloseCross from "atoms/Layer/CloseCross.png";
import EditIconAtom from "atoms/EditIcon";
import { toast } from "react-toastify";

class AddNotesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventType: [],
      initialmessage: "",
      editName: false,
    };
    this.formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(this.formsAPIGateway);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(values, { setSubmitting }) {
    const trimmedMessage = values.message.trim();

    if (!trimmedMessage) {
      toast.error("Please add some notes");
      setSubmitting(false);
      return;
    }

    let obj = {
      id: this.props.response_id,
      note: values.message,
    };
    this.usecase
      .updateResponceData(obj)
      .then(() => {
        toast.success('Notes updated successfully');
        this.props.addModalToggler();
        this.props.fetchData();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  render() {
    const {
      notesModal,
      addModalToggler,
      handleFormSubmit,
      response_id,
      initialMessage,
      fetchData,
    } = this.props;

    return (
      <Modal isOpen={notesModal} size="md">
        <ModalHeader>
          Notes
          <div style={{ float: "right" }}>
            <img
              height="15"
              width="15"
              onClick={() => {
                addModalToggler();
              }}
              title="close"
              src={CloseCross}
            ></img>
          </div>
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              message: initialMessage,
            }}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <FormGroup className="position-relative">
                    <Label className="mb-3" style={{ fontWeight: "bold" }}>
                      {this.props.initialMessage === ""
                        ? "Add Notes-:"
                        : this.state.editName === false
                        ? "Notes-:"
                        : "Edit Notes -:"}
                    </Label>
                    <div>
                      {this.props.initialMessage === "" ||
                      this.state.editName === true ? (
                        <>
                          <Input
                            type="textarea"
                            placeholder="Type your message here"
                            name="message"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.message}
                            className={
                              errors && errors.message
                                ? "form-control-error"
                                : null
                            }
                            rows={1}
                          />
                          <Button
                            className="float-right mt-2 "
                            size="md"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </>
                      ) : (
                        <div>
                          {this.props.initialMessage}
                          <EditIconAtom
                            style={{ float: "right" }}
                            onClick={() => this.setState({ editName: true })}
                          />
                        </div>
                      )}
                    </div>
                    <div style={{ minHeight: "3vh" }}>
                      <FormText color="danger">
                        {errors && errors.message}
                      </FormText>
                    </div>
                  </FormGroup>
                </Form>
              );
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}
export default AddNotesModal;
