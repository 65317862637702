import React from "react";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as RouteDucks from "ducks/routes";
import * as EmployeeDucks from "ducks/employees/employee";

import { NAV } from "constants/forms";
import TwoColumnLayout from "layouts/TwoColumn";
import { toast } from "react-toastify";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import SectionLoaderAtom from "atoms/SectionLoader";

import CreateFieldFormComponent from "forms/components/createFieldForm";
import FieldFormProgressComponent from "forms/components/fieldFormProgress";
import AssignedEmployeesComponent from "forms/components/assignedEmployeesComponent";

class CreateFieldFormPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      templateData: { label: "", questions: [] },
      initialValues: {},
      isLoading: true,
      modalFlag: false,
      isSubmitting: false,
      parent: NAV.CreateFieldFormsPage.parent,
      count: 1,
      saveAndNextButtonLoaderFlag: false, 
         };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.loadData = this.loadData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.handleQuestionDelete = this.handleQuestionDelete.bind(this);
    this.handleQuestionAdd = this.handleQuestionAdd.bind(this);
    this.handleQuestionUpdate = this.handleQuestionUpdate.bind(this);
    this.labelChange = this.labelChange.bind(this)
    this.createForm = this.createForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.backToListPage = this.backToListPage.bind(this);
    this.handleRecurringChange = this.handleRecurringChange.bind(this);
  }

  handleSort(result){
    if(result.destination !== null){
      const { templateData } = this.state;
      let questions = [...templateData.questions];
      questions.splice(result.destination?.index, 0, questions.splice(result.source.index, 1)[0]);
      questions = questions.map((item, idx) => ({
        ...item,
        sequence_of_configuration: idx + 1,
      }))
      this.setState({
        templateData: {
          ...templateData,
          questions,
        }
      })
    }
  }

  handleBack() {
    const { count } = this.state;
    this.setState({ count: count - 1 });
  }

  componentDidMount() {
    this.loadData();
    this.setParentUrl();
    this.listenListUsecase();
  }
  
  loadData() {
    const {
      match: { params },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);

    if (params.id !== "create") {
      this.usecase.getFormData({
        id: params.id !== "copy" ? params.id : query.form_id,
        form_type: "field_form",
      });
    }else{
      this.setState({ isLoading: false })
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenListUsecase() {
    const {
      location: { search },
      history
    } = this.props;

    const query = queryString.parse(search);

    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_TEMPLATE_DATA":
          this.updateState("templateData", event.data.template_data);
          this.updateState("initialValues", event.data.template_data);

          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        case "FORM_CREATED_SUCCESS":
          this.setState({ modalFlag: false });
          history.push({
            pathname: `/forms/${event.data.id}/fieldform`,
            search: queryString.stringify(query),
          });
          toast.success("Form Created Successfully !!!");
          this.setState({ count: 2, saveAndNextButtonLoaderFlag: false });
          break;
        case "FORM_CREATED_FAILURE":
          toast.error(event.data.e);
          this.setState({
            saveAndNextButtonLoaderFlag: false,
          });
          break;
        case "FORM_UPDATED_SUCCESS":
          toast.success("Form updated successfully !!!");
          this.updateState("templateData", event.data.template_data);
          this.updateState("initialValues", event.data.template_data);
          this.setState({ count: 2, saveAndNextButtonLoaderFlag: false });
          break;
        case "FORM_UPDATED_FAILURE":
          toast.error(event?.data?.e ?? "Somthing Went Wrong !!!");
          this.setState({
            saveAndNextButtonLoaderFlag: false,
          });

          break;
        case "ERROR_TOAST":
          toast.error("Something went wrong!");
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  handleQuestionDelete(idx) {
    const { templateData } = this.state;
    const newUpdateQuestion = templateData.questions.filter((ele) => {
      return ele.sequence_of_configuration !== idx;
    });
    this.setState({
      templateData: {
        ...this.state.templateData,
        questions: newUpdateQuestion.map((ele, index) => {
          return {
            ...ele,
            sequence_of_configuration: index + 1,
          };
        }),
      },
    });
  }

  handleQuestionAdd(newQus) {
    const { templateData } = this.state;
    this.setState({
      templateData: {
        ...templateData,
        questions: [...templateData.questions, newQus],
      },
    });
    this.toggleModal();
  }

  handleQuestionUpdate(updatedQus) {
    const { templateData } = this.state;
    const newUpdateQuestion = templateData.questions.map((ele) => {
      if (
        ele.sequence_of_configuration === updatedQus.sequence_of_configuration
      ) {
        return updatedQus;
      } else {
        return ele;
      }
    });
    this.setState({
      templateData: {
        ...this.state.templateData,
        questions: newUpdateQuestion,
      },
    });

    this.toggleModal();
  }

  createForm() {
    const { templateData} = this.state;
    this.setState({ isSubmitting: true, saveAndNextButtonLoaderFlag: true });
    const {recurring,...data} = templateData;
    let obj = {
      survey_type: ["others"],
      template_data: {
        ...data,
        label: data.label.trim(),
        questions:
          data.questions.length > 0
            ? [...data.questions]
            : undefined,
      },
      recurring:recurring,
    };
    this.usecase.createForm(obj);
  }

  updateForm(e) {
    const { initialValues, templateData, count } = this.state;
    if(JSON.stringify(initialValues) === JSON.stringify(templateData)){
      this.setState({ count: count + 1});
      return;
    }
   const {recurring,...data} = templateData;
    this.setState({ saveAndNextButtonLoaderFlag: true});
    const {
      match: { params },
    } = this.props;
    let obj = {
      survey_type: ["others"],
      template_data: {
        ...data,
        label: data.label.trim(),
      },
      id: params.id,
      recurring: recurring,
    };
    this.usecase.updateForm(obj);
  }

  labelChange(e) {
    this.setState({
      templateData: { ...this.state.templateData, label: e.target.value },
    });
  }

  handleRecurringChange(e){
    this.setState({
      templateData: { ...this.state.templateData, recurring: e.target.checked },
    });
    
  }

  toggleModal() {
    const { modalFlag } = this.state;
    this.setState({ modalFlag: !modalFlag });
  }

  setParentUrl() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  backToListPage(){
    const { history, location: {search} } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname: `/forms/fieldforms`,
      search: queryString.stringify(query),
    });
  }

  render() {
    const {
      match: { params },
      location,
      history,
    } = this.props;
    const {
      isLoading,
      templateData,
      modalFlag,
      isSubmitting,
      parent,
      count,
      saveAndNextButtonLoaderFlag,
      labelErrorTitle,
      initialValues,
    } = this.state;
    const query = queryString.parse(location.search);
     const title = `${query.form_name}`;
    return (
      <div>
        <div>
          <TwoColumnLayout
            navInfo={
              params.id === "create" || params.id === "copy"
                ? { ...NAV.CreateFieldFormsPage, parent }
                : { ...NAV.EditedFieldFormsPage, parent, title }
            }
          >
            {isLoading ? (
              <SectionLoaderAtom active />
            ) : (
              <div className="ml-3 mr-4 pb-5">
                <FieldFormProgressComponent
                  stepNumber={count}
                  history={history}
                />
                {count === 1 && (
                  <CreateFieldFormComponent
                    location={location}
                    templateData={templateData}
                    listDeleted={this.listDeleted}
                    labelChange={this.labelChange}
                    modalFlag={modalFlag}
                    toggleModal={this.toggleModal}
                    isSubmitting={isSubmitting}
                    handleQuestionAdd={this.handleQuestionAdd}
                    handleQuestionDelete={this.handleQuestionDelete}
                    handleQuestionUpdate={this.handleQuestionUpdate}
                    saveAndNextButtonLoaderFlag={saveAndNextButtonLoaderFlag}
                    labelErrorTitle={labelErrorTitle}
                    initialValues={initialValues}
                    createForm={this.createForm}
                    updateForm={this.updateForm}
                    id={params.id}
                    handleSort={this.handleSort}
                    handleRecurringChange={this.handleRecurringChange}
                  />
                )}
                {count === 2 && (
                  <AssignedEmployeesComponent
                    location={location}
                    count={count}
                    handleBack={this.handleBack}
                    id={params.id}
                    history={history}
                    backToListPage={this.backToListPage}
                  />
                )}
              </div>
            )}
          </TwoColumnLayout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = (dispatch) => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

export default connect(mapStateToProps, mapActionsToProps)(CreateFieldFormPage);
