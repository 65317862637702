import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_TIMECARDS = 'timecards/timecard/GET_TIMECARDS';
const GET_TIMECARDS_FULFILLED = 'timecards/timecard/GET_TIMECARDS_FULFILLED';
const GET_CUSTOM_TIMECARDS_FULFILLED = 'timecards/timecard/GET_CUSTOM_TIMECARDS_FULFILLED';
const GET_TIMECARD = 'timecards/timecard/GET_TIMECARD';
const GET_TIMECARD_PENDING = 'timecards/timecard/GET_TIMECARD_PENDING';
const RESET_TIMECARDS = 'timecards/timecard/RESET_TIMECARDS';
const GET_TIMECARD_FULFILLED = 'timecards/timecard/GET_TIMECARD_FULFILLED';
const PUT_TIMECARD = 'timecards/timecard/PUT_TIMECARD';
const PUT_TIMECARD_FULFILLED = 'timecards/timecard/PUT_TIMECARD_FULFILLED';
const GET_DOWNLOAD_CSV_TIMECARD = 'timecards/timecard/GET_DOWNLOAD_CSV_TIMECARD';
const GET_DOWNLOAD_PDF_TIMECARD = 'timecards/timecard/GET_DOWNLOAD_PDF_TIMECARD';
const GET_DOWNLOAD_CSV_CUSTOM_TIMECARD = 'timecards/timecard/GET_DOWNLOAD_CSV_CUSTOM_TIMECARD';
const GET_DOWNLOAD_PDF_CUSTOM_TIMECARD = 'timecards/timecard/GET_DOWNLOAD_PDF_CUSTOM_TIMECARD';
const SELECT_EMPLOYEE_TIMECARDS  =  'timecards/timecard/SELECT_EMPLOYEE_TIMECARDS';
const SELECT_ALL_TIMECARDS  =  'timecards/timecard/SELECT_ALL_TIMECARDS';
const GET_KONNECT_TIMECARDS = 'timecards/timecard/GET_KONNECT_TIMECARDS';
const GET_KONNECT_TIMECARDS_FULFILLED = 'timecards/timecard/GET_KONNECT_TIMECARDS_FULFILLED';
const CHECK_TIMECARD = 'timecards/timecard/CHECK_TIMECARD';
const CHECK_TIMECARD_FULFILLED = 'timecards/timecard/CHECK_TIMECARD_FULFILLED';
const RETRY_TIMECARD_SYNC = 'timecards/timecard/RETRY_TIMECARD_SYNC';
const APPROVE_TIMECARD = 'timecards/timecard/APPROVE_TIMECARD';
const APPROVE_TIMECARD_FULFILLED = 'timecards/timecard/APPROVE_TIMECARD_FULFILLED';
const GET_DOWNLOAD_JOB_PAYROLL_REPORT = 'timecards/timecard/GET_DOWNLOAD_JOB_PAYROLL_REPORT';
const GET_DOWNLOAD_DATA_TECH_REPORT='timecards/timecard/GET_DOWNLOAD_DATA_TECH_REPORT'
const GET_DOWNLOAD_SOUTH_EAST_EXPORT = 'timecards/timecard/GET_DOWNLOAD_SOUTH_EAST_EXPORT'
const GET_DOWNLOAD_ADP_REPORT = 'timecards/timecard/GET_DOWNLOAD_ADP_REPORT';
const GET_DEPARTMENT_LIST = 'timecards/timecard/GET_DEPARTMENT_LIST';
const GET_DEPARTMENT_LIST_FULFILLED = 'timecards/timecard/GET_DEPARTMENT_LIST_FULFILLED';
const DOWNLOAD_PAYDATA_CSV = 'timecards/timecard/DOWNLOAD_PAYDATA_CSV';
const DOWNLOAD_PAYDATA_CSV_FULFILLED = 'timecards/timecard/DOWNLOAD_PAYDATA_CSV_FULFILLED';
const DOWNLOAD_MILEAGE_CSV = 'timecards/timecard/DOWNLOAD_MILEAGE_CSV';
const DOWNLOAD_MILEAGE_CSV_FULFILLED = 'timecards/timecard/DOWNLOAD_MILEAGE_CSV_FULFILLED';
const GET_VIEW_PREFERENCE = 'timecards/timecard/GET_VIEW_PREFERENCE';
const GET_VIEW_PREFERENCE_FULFILLED = 'timecards/timecard/GET_VIEW_PREFERENCE_FULFILLED';
const UPDATE_VIEW_PREFERENCE = 'timecards/timecard/UPDATE_VIEW_PREFERENCE';
const UPDATE_VIEW_PREFERENCE_FULFILLED = 'timecards/timecard/UPDATE_VIEW_PREFERENCE_FULFILLED';
const APPROVE_ALL_EMPLOYEE = 'timecards/timecard/APPROVE_ALL_EMPLOYEE';
const DOWNLOAD_AUDIT_REPORT = 'timecards/timecard/DOWNLOAD_AUDIT_REPORT';
const DOWNLOAD_AUDIT_REPORT_FULFILLED = 'timecards/timecard/DOWNLOAD_AUDIT_REPORT_FULFILLED';
const DOWNLOAD_ADJUSTMENT_REPORT = 'timecards/timecard/DOWNLOAD_ADJUSTMENT_REPORT';
const DOWNLOAD_ADJUSTMENT_REPORT_FULFILLED = 'timecards/timecard/DOWNLOAD_ADJUSTMENT_REPORT_FULFILLED';
const DOWNLOAD_SAGE_CSV = 'timecards/timecard/DOWNLOAD_SAGE_CSV';
const DOWNLOAD_SAGE_CSV_FULFILLED = 'timecards/timecard/DOWNLOAD_SAGE_CSV_FULFILLED';
const DOWNLOAD_ISOLVED_REPORT = 'timecards/timecard/DOWNLOAD_ISOLVED_REPORT';
const DOWNLOAD_ISOLVED_REPORT_FULFILLED = 'timecards/timecard/DOWNLOAD_ISOLVED_REPORT_FULFILLED';
const GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD = 'timecards/timecard/GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD';
const GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD_FULFILLED = 'timecards/timecard/GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD_FULFILLED';

const DOWNLOAD_MMCHR_REPORT = 'timecards/timecard/DOWNLOAD_MMCHR_REPORT';
const DOWNLOAD_MMCHR_REPORT_FULFILLED = 'timecards/timecard/DOWNLOAD_MMCHR_REPORT_FULFILLED';

const GET_USER_FILTERS = 'timecards/timecard/GET_USER_FILTERS';
const GET_USER_FILTERS_FULFILLED = 'timecards/timecard/GET_USER_FILTERS_FULFILLED';
const UPDATE_USER_FILTERS = 'timecards/timecard/UPDATE_USER_FILTERS';
const UPDATE_USER_FILTERS_FULFILLED = 'timecards/timecard/UPDATE_USER_FILTERS_FULFILLED';
const GET_DOWNLOAD_IIF_REPORT='timecards/timecard/GET_DOWNLOAD_IIF_REPORT'; 
const GET_FAMOUS_EXPORT = 'timecards/timecard/GET_FAMOUS_EXPORT';
const GET_DOWNLOAD_MEALS_FLAG_REPORT = 'timecards/timecard/GET_DOWNLOAD_MEALS_FLAG_REPORT' 
const GET_DOWNLOAD_FENCE_FACTORY_REPORT = 'timecards/timecard/GET_DOWNLOAD_FENCE_FACTORY_REPORT';
const GET_DOWNLOAD_PTO_BALANCE_REPORT = 'timecards/timecard/GET_DOWNLOAD_PTO_BALANCE_REPORT';
const GET_DOWNLOAD_PAYLOCITY_REPORT = 'timecards/timecard/GET_DOWNLOAD_PAYLOCITY_REPORT';
const GET_DOWNLOAD_PDF_JOB_CASTING = 'timecards/timecard/GET_DOWNLOAD_PDF_JOB_CASTING';
const GET_DOWNLOAD_PDF_JOB_CASTING_DETAILS = 'timecards/timecard/GET_DOWNLOAD_PDF_JOB_CASTING_DETAILS';

export function getTimecards(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_TIMECARDS,
    payload: axios.get(`${URLS.TIMECARD}${id}/`, { params }),
  };
}

export function getKonnectTimecards(params) {
  return {
    type: GET_KONNECT_TIMECARDS,
    payload: axios.get(URLS.KONNECT_TIMECARD, { params }),
  };
}

export function getDepartmentList(params) {
  return {
    type: GET_DEPARTMENT_LIST,
    payload: axios.get(`${URLS.DEPARTMENT_LIST}?company_id=[${params}]&paginate=false`),
  };
}

export function retryTimecardSync(params) {
  return {
    type: RETRY_TIMECARD_SYNC,
    payload: axios.get(URLS.TIMECARD_SYNC, { params }),
  }
}

export function getCustomTimecards(parameters) {
  const params =  { ...parameters };
  if(params.department_id && !params.employee_list){
    delete params.department_id;
  }
  if(params.team_id && !params.employee_list){
    delete params.team_id;
  }
 if(params.employee_list){
  delete params.employee_list;
 }
  if(params.payroll_id === 'custom'){
    delete params.payroll_id;
  }else{
    delete params.start_date;
    delete params.end_date;
  }
  return {
    type: GET_TIMECARDS,
    payload: axios.get(`${URLS.CUSTOM_TIMECARD}`, { params }),
  };
}

export function downloadTimecardDataCsv(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_CSV_TIMECARD,
    payload: axios.get(
      `${URLS.TIMECARD}${id}/${URLS.EXPORT_CSV}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadTimecardDataPdf(parameters) {
  const { id, ...params } = parameters;

  return {
    type: GET_DOWNLOAD_PDF_TIMECARD,
    payload: axios.get(
      `${URLS.TIMECARD}${id}/${URLS.EXPORT_PDF}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadCustomTimecardDataCsv(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_CSV_CUSTOM_TIMECARD,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.DATE_RANGE_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}


export function downloadPayDatacsv(parameters) {
  const { id, ...params } = parameters;
  return {
    type: DOWNLOAD_PAYDATA_CSV,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_PAY_DATA_CSV}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}


export function downloadMileagecsv(parameters) {
  const { id, ...params } = parameters;
  return {
    type: DOWNLOAD_MILEAGE_CSV,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_MILEAGE_CSV}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}
export function downloadDataTechReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_DATA_TECH_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.DATA_TECH_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}
export function downloadIifReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_IIF_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.IIF_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}



export function downloadFamousExport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_FAMOUS_EXPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.FAMOUS_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}



export function downloadFenceFactoryReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_FENCE_FACTORY_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.FENCE_FACTORY_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadPtoBalanceReport(parameters) {
  const { ...params } = parameters;
  return {
    type: GET_DOWNLOAD_PTO_BALANCE_REPORT,
    payload: axios.get(
      `${URLS.PTO_BALANCE_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadPaylocityReport(parameters) {
  const { ...params } = parameters;
  return {
    type: GET_DOWNLOAD_PAYLOCITY_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.PAYLOCITY_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadJobCostingReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_JOB_PAYROLL_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.JOB_PAYROLL_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadJobCostingReport2(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_PDF_JOB_CASTING,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.JOB_CASTING_SUMMARY_BASE_URL}`,
      { params },
    ),
  };
}

export function downloadJobCostingDetailReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_JOB_PAYROLL_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.Job_PAYROLL_DETAIL_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadJobCostingDetailReport2(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_PDF_JOB_CASTING_DETAILS,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.JOB_CASTING_DETAILS_BASE_URL}`,
      { params },
    ),
  };
}

export function downloadMikeRovnerDetailReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_JOB_PAYROLL_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.MIKE_ROVNER_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadMelasFlagReport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_MEALS_FLAG_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.MEALS_FLAG_REPORTS}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadSouthEastExport(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_SOUTH_EAST_EXPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.SOUTH_EAST_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadCustomTimecardDataPdfandXls(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_PDF_CUSTOM_TIMECARD,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_PDF_XLS}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadCustomTimecardDataPdf(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_PDF_XLS}pdf2`,
      { params },
    ),
  };
}

export function downloadSAGEcsv(parameters) {
  const { id,company_id, ...params } = parameters;
  return {
    type: DOWNLOAD_SAGE_CSV,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_SAGE_CSV}${company_id}/`,
      { params, responseType: "arraybuffer" },
    ),
  };
}

export function downloadiSolvedReport(parameters){
  const { id, ...params } = parameters;
  return {
    type: DOWNLOAD_ISOLVED_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.EXPORT_ISOLVED}`,
      { params },
    ),
  };
}

export function downloadMMCHR(parameters){
  const { id, ...params } = parameters;
  return {
    type: DOWNLOAD_MMCHR_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.MMCHR_EXPORT}`,
      { params },
    ),
  };
}



export function getTimecard(params) {
  return {
    type: GET_TIMECARD,
    payload: axios.get(`${URLS.TIMECARD}${params.id}/detail/`),
  };
}

export function putTimecard(data) {
  return {
    type: PUT_TIMECARD,
    payload: axios.put(`${URLS.TIMECARD}${data.id}/detail/`, data),
  };
}

export function resetTimecards(data) {
  return {
    type: RESET_TIMECARDS,
    payload: data,
  };
}

export function selectEmployeeTimecards(data) {
  return {
    type: SELECT_EMPLOYEE_TIMECARDS,
    payload: data,
  };
}

export function selectAllTimecards(data) {
  return {
    type: SELECT_ALL_TIMECARDS,
    payload: data,
  };
}

export function checkTimecard(params) {
  return {
    type: CHECK_TIMECARD,
    payload: axios.post(`${URLS.TIMECARD}${params.id}/add-manual/?employee_id=${params.employee.value}`),
  };
}

export function approveTimecard(data) {
  return {
    type: APPROVE_TIMECARD,
    payload: axios.put(`${URLS.TIMECARD}${data.id}/${URLS.APPROVE_TIMECARD}`, data),
  };
}

export function downloadADPReport(parameters) {
  const { id, selectedFormat,...params } = parameters;
  if(selectedFormat === 'ADPXls')
  {
  return {
    type: GET_DOWNLOAD_ADP_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.ADP_REPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}else
{
    return {
      type: GET_DOWNLOAD_ADP_REPORT,
      payload: axios.get(
        `${URLS.CUSTOM_TIMECARD}${URLS.ADP_REPORT}${URLS.ADP_REPORT_V2}`,
        { params, responseType: "arraybuffer" },
      ),
    };
}
}

export function getUserPreference(params) {
  return {
    type: GET_VIEW_PREFERENCE,
    payload: axios.get(`${URLS.CUSTOM_TIMECARD}${URLS.USER_PREFERENCE}`, {params: params}),
  };
}

export function updateUserPreference(params) {
  return {
    type: UPDATE_VIEW_PREFERENCE,
    payload: axios.put(`${URLS.CUSTOM_TIMECARD}${URLS.USER_PREFERENCE}`, params),
  };
}

export function getUserFilters(params) {
  return {
    type: GET_USER_FILTERS,
    payload: axios.get(`${URLS.USER_FILTERS}?preference_type=${params.preference_type}`),
  };
}

export function updateUserFilters(params) {
  // params.data.timecard_decimal_view =
  //   (params.data.timecard_decimal_view == "true" || params.data.timecard_decimal_view  == true )
  //   ? true :
  //   (params.data.timecard_decimal_view == "false" || params.data.timecard_decimal_view == false) ? false : '';
  return {
    type: UPDATE_USER_FILTERS,
    payload: axios.put(`${URLS.USER_FILTERS}?preference_type=${params.preference_type}`, params ),
  };
}

export function approveAllEmployee(params) {
  return {
    type: APPROVE_ALL_EMPLOYEE,
    payload: axios.put(`${URLS.CUSTOM_TIMECARD}${URLS.BATCH_APPROVE}`, params),
  };
}

export function downloadSelectedReport(parameters){
  const { id, selectedFormat, ...params } = parameters;
  if (selectedFormat === 'auditXls' || selectedFormat == 'auditXls2' ){
  return {
    type: DOWNLOAD_AUDIT_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.AUDIT_SUMMARY_EXPORT}`,
      { params},
    ),
  };
} else if (selectedFormat === 'adjustmentXls'){
  return {
    type: DOWNLOAD_ADJUSTMENT_REPORT,
    payload: axios.get(
      `${URLS.CUSTOM_TIMECARD}${URLS.ADJUSTMENT_SUMMARY_EXPORT}`,
      { params, responseType: "arraybuffer" },
    ),
  };
}
}

const defaultState = {
  list: {},
  detail: {},
  departments: {},
  userViewPreference: {},
  userFilters : {}
};

function GET_TIMECARDS_FULFILLED_REDUCER(state, action) {
  const timecardList = action.payload.results && action.payload.results.map(item => ({ ...item, isSelected: false, allSelected: false }));
  return Object.assign({}, state, {
    list: {...action.payload, results: timecardList },
  });
}

function GET_DEPARTMENT_LIST_FULFILLED_REDUCER(state, action) {
  let departmentList = [];
  departmentList = action.payload && action.payload.map(item => ({ ...item }));
  departmentList.splice(0, 0, { id: "", name: "All Departments" });
  return Object.assign({}, state, {
    departments: {...action.payload, results: departmentList },
  });
}

function GET_CUSTOM_TIMECARDS_FULFILLED_REDUCER(state, action) {
  const timecardList = action.payload.results && action.payload.results.map(item => ({ ...item, isSelected: false, allSelected: false }));
  return Object.assign({}, state, {
    list: {...action.payload, results: timecardList },
  });
}

function RESET_TIMECARDS_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: {},
  });
}

function GET_TIMECARD_PENDING_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

function GET_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RETRY_TIMECARD_SYNC_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function SELECT_EMPLOYEE_TIMECARDS_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if(list.results[i].employee__id){
      if (list.results[i].employee__id === action.payload.employee__id) {
        list.results[i].isSelected = !list.results[i].isSelected;
      }
    }
    else if(list.results[i].employee.id){
      if (list.results[i].employee.id === action.payload.employee.id) {
        list.results[i].isSelected = !list.results[i].isSelected;
      }
    }
  }
  return Object.assign({}, state, { list });
}

function SELECT_ALL_TIMECARDS_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    list.results[i].allSelected = action.payload.allSelected;
    list.results[i].isSelected = action.payload.allSelected;
  }
  return Object.assign({}, state, { list });
}

function GET_KONNECT_TIMECARDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload });
}

function CHECK_TIMECARD_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function APPROVE_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_PAYDATA_CSV_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_MILEAGE_CSV_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function GET_VIEW_PREFERENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreference: action.payload,
  });
}

function UPDATE_VIEW_PREFERENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreference: action.payload,
  });
}

function GET_USER_FILTERS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userFilters: action.payload,
  });
}

function UPDATE_USER_FILTERS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userFilters: action.payload,
  });
}
function DOWNLOAD_AUDIT_REPORT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_ADJUSTMENT_REPORT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_SAGE_CSV_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_ISOLVED_REPORT_REDUCER(state, action) {
  return Object.assign({}, state);
}

function DOWNLOAD_MMCHR_REPORT_REDUCER(state, action) {
  return Object.assign({}, state);
}

const handlers = {
  [GET_TIMECARDS_FULFILLED]: GET_TIMECARDS_FULFILLED_REDUCER,
  [APPROVE_TIMECARD_FULFILLED]: APPROVE_TIMECARD_FULFILLED_REDUCER,
  [GET_CUSTOM_TIMECARDS_FULFILLED]: GET_CUSTOM_TIMECARDS_FULFILLED_REDUCER,
  [GET_TIMECARD_PENDING]: GET_TIMECARD_PENDING_REDUCER,
  [RESET_TIMECARDS]: RESET_TIMECARDS_REDUCER,
  [GET_TIMECARD_FULFILLED]: GET_TIMECARD_FULFILLED_REDUCER,
  [PUT_TIMECARD_FULFILLED]: PUT_TIMECARD_FULFILLED_REDUCER,
  [SELECT_EMPLOYEE_TIMECARDS] : SELECT_EMPLOYEE_TIMECARDS_REDUCER,
  [SELECT_ALL_TIMECARDS]: SELECT_ALL_TIMECARDS_REDUCER,
  [GET_KONNECT_TIMECARDS_FULFILLED]: GET_KONNECT_TIMECARDS_FULFILLED_REDUCER,
  [SELECT_ALL_TIMECARDS]: SELECT_ALL_TIMECARDS_REDUCER,
  [CHECK_TIMECARD_FULFILLED] : CHECK_TIMECARD_REDUCER,
  [RETRY_TIMECARD_SYNC]: RETRY_TIMECARD_SYNC_FULFILLED_REDUCER,
  [GET_DEPARTMENT_LIST_FULFILLED] : GET_DEPARTMENT_LIST_FULFILLED_REDUCER,
  [DOWNLOAD_PAYDATA_CSV_FULFILLED] : DOWNLOAD_PAYDATA_CSV_FULFILLED_REDUCER,
  [DOWNLOAD_MILEAGE_CSV_FULFILLED] : DOWNLOAD_MILEAGE_CSV_FULFILLED_REDUCER,
  [DOWNLOAD_SAGE_CSV_FULFILLED] : DOWNLOAD_SAGE_CSV_FULFILLED_REDUCER,
  [GET_VIEW_PREFERENCE_FULFILLED] : GET_VIEW_PREFERENCE_FULFILLED_REDUCER,
  [UPDATE_VIEW_PREFERENCE_FULFILLED] : UPDATE_VIEW_PREFERENCE_FULFILLED_REDUCER,
  [GET_USER_FILTERS_FULFILLED] : GET_USER_FILTERS_FULFILLED_REDUCER,
  [UPDATE_USER_FILTERS_FULFILLED] : UPDATE_USER_FILTERS_FULFILLED_REDUCER,
  [DOWNLOAD_AUDIT_REPORT_FULFILLED] : DOWNLOAD_AUDIT_REPORT_FULFILLED_REDUCER,
  [DOWNLOAD_ADJUSTMENT_REPORT_FULFILLED] : DOWNLOAD_ADJUSTMENT_REPORT_FULFILLED_REDUCER,
  [DOWNLOAD_ISOLVED_REPORT_FULFILLED] : DOWNLOAD_ISOLVED_REPORT_REDUCER,
  [DOWNLOAD_MMCHR_REPORT_FULFILLED] : DOWNLOAD_MMCHR_REPORT_REDUCER,
  [GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD_FULFILLED] : GET_DOWNLOAD_KONECT_PDF_CUSTOM_TIMECARD_FULFILLED_REDUCER
};


const timecardSelector = state => state.timecards.timecard;

export const timecardList = createSelector(
  timecardSelector,
  instance => instance.list,
);

export const departmentList = createSelector(
  timecardSelector,
  instance => instance.departments,
);

export const selectedEmployeeTimecards = createSelector(
  timecardList,
  instance => instance.results && instance.results.filter(item => item.isSelected).map(item =>
  {
    const id = item.employee && item.employee.id;
    if(item.employee__id) return item.employee__id;
    return id;
  }),
);

export const timecardDetail = createSelector(
  timecardSelector,
  instance => instance.detail,
);

export const activityCreated = createSelector(
  timecardSelector,
  instance => instance.detail,
);

export const userViewPreference = createSelector(
  timecardSelector,
  instance => instance.userViewPreference,
);

export const userTimecardFilters = createSelector(
  timecardSelector,
  instance => instance.userFilters,
);

export default createReducer(defaultState, handlers);
