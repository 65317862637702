import React from "react";

import cookie from "react-cookies";
import PropTypes from "prop-types";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as JobReportsDucks from "ducks/jobs/reports";
import * as JobDucks from "ducks/jobs/job";
import * as UserDucks from "ducks/accounts/user";
import * as RouteDucks from "ducks/routes";
import * as TaskDucks from "ducks/jobs/task";

import TwoColumnLayout from "layouts/TwoColumn";
import JobReportsFilterSection from "sections/jobs/reports/filter";
import JobReportsListSection from "sections/jobs/reports/list";
import AttributeToggler from "employees/components/AttributeToggler";

import { NAV } from "constants/jobs";

/**
 * JobReportsPage
 *
 * Layout:
 *    - {@link SSTCF Layout}
 *
 * Sections:
 *    - {@link JobReportsFilterSection}
 *    - {@link JobReportsListSection}
 */

class JobReportsPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setTimeOut = this.setTimeOut.bind(this);
    this.toogleTable = this.toogleTable.bind(this);
    this.toggleTableLoader = this.toggleTableLoader.bind(this);
    this.state = {
      isLoading: false,
      isInfoOpen: true,
      isTableLoading: false,
      groupBy: "employee",
      employeeReport: 1,
      attributeToggler: false,
      showableAttributes: {},
    };
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.getUserPreference = this.getUserPreference.bind(this);
    this.accessorToHeaderDetail = {
      date: "Date",
      first_name: "First",
      last_name: "Last",
      job_name: "Job",
      task_name: "Task",
      customer_name: "Customer",
      start_datetime: "Start Time",
      end_datetime: "End Time",
      total_time: "Hours",
      labor_cost: "Labor Cost",
    };
    this.accessorToHeaderSummaryEmployee = {
      first_name: "First",
      last_name: "Last",
      total_time: "Hours",
      labor_cost: "Labor Cost",
    };
    this.accessorToHeaderSummaryJob = {
      job_name: "Job",
      total_time: "Hours",
      labor_cost: "Labor Cost",
    };
    this.accessorToHeaderSummaryTask = {
      task_name: "Task",
      total_time: "Hours",
      labor_cost: "Labor Cost",
    };
    this.accessorToHeaderSummaryCustomer = {
      customer_name: "Customer Name",
      total_time: "Hours",
      labor_cost: "Labor Cost",
    };
  }

  componentWillMount() {
    const cookieKey = cookie.load("job_reports_info");
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.props.JobDucks.resetJobAll();
    // this.getUserPreference();
  }

  componentDidMount() {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    if (query) {
      history.push({
        pathname,
        search: queryString.stringify({
          type: "detail",
        }),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (prevQuery.page !== nextQuery.page) {
      this.setTimeOut(800);
    }
    if (
      prevQuery.type !== nextQuery.type ||
      prevQuery.grouped !== nextQuery.grouped
    ) {
      this.getUserPreference(nextProps);
    }
  }

  componentWillUnmount() {
    this.props.JobDucks.resetJob();
  }

  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    if (!bool) {
      this.getUserPreference();
    }
  }

  setAttributes(attributes) {
    this.setState({ showableAttributes: attributes });
  }

  setColumns(submit) {
    const {
      location: { search },
      jobReportsUserPreference,
    } = this.props;
    const parsedQuery = queryString.parse(search);
    let detail;
    this.setState({ attributeToggler: !this.state.attributeToggler });

    if (parsedQuery.type === "detail") {
       const showFields = Object.keys(this.state.showableAttributes).filter(
          (item) => {
            if (this.state.showableAttributes[item] === true || item === "first_name" || item === "total_time") return item;
            else return false;
          }
        );
       const {show_fields, ...rest} = jobReportsUserPreference.data ;
       detail = {
        show_fields: showFields, ...rest
      };
    }else if (parsedQuery.grouped == "job") {
      const showFields = Object.keys(this.state.showableAttributes).filter(
        (item) => {
            if (this.state.showableAttributes[item] === true || item === "job_name" || item === "total_time") return item;
            else return false;
          }
      );
      const {show_fields_job, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_job: showFields, ...rest
      };
    }else if (parsedQuery.grouped == "employee") {
      const showFields = Object.keys(this.state.showableAttributes).filter(
          (item) => {
            if (this.state.showableAttributes[item] === true || item === "first_name" || item === "total_time") return item;
            else return false;
          }
        );
      const {show_fields_employee, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_employee: showFields, ...rest
      };
    }else if (parsedQuery.grouped == "customer") {
      const showFields = Object.keys(this.state.showableAttributes).filter(
          (item) => {
            if (this.state.showableAttributes[item] === true || item === "customer_name" || item === "total_time") return item;
            else return false;
          }
        );
      const {show_fields_customer, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_customer: showFields, ...rest
      };
    }else if (parsedQuery.grouped == "task") {
      const showFields = Object.keys(this.state.showableAttributes).filter(
          (item) => {
            if (this.state.showableAttributes[item] === true || item === "task_name" || item === "total_time") return item;
            else return false;
          }
        );
      const {show_fields_task, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_task: showFields, ...rest
      };
    }else {
      const showFields = Object.keys(this.state.showableAttributes).filter(
          (item) => {
            if (this.state.showableAttributes[item] === true || item === "first_name" || item === "total_time") return item;
            else return false;
          }
        );
      const {show_fields_employee, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_employee: showFields, ...rest
      };
    }

    this.props.JobReportsDucks.putUserPreference({
      data: { ...detail },
      preference_type: "user_preference_job_reports",
    }).then(() => {
      this.getUserPreference();
    });
  }

  getUserPreference(nextProps) {
    const {
      location: { search },
    } = nextProps ? nextProps : this.props;
    const parsedQuery = queryString.parse(search);
    this.props.JobReportsDucks.getUserPreference({
      preference_type: "user_preference_job_reports",
    }).then(() => {
      const { jobReportsUserPreference } = this.props;
      const showFields =
        jobReportsUserPreference.data &&
        jobReportsUserPreference.data.show_fields;
      const showFieldsJob =
        jobReportsUserPreference.data &&
        jobReportsUserPreference.data.show_fields_job;
      const showFieldsEmployee =
        jobReportsUserPreference.data &&
        jobReportsUserPreference.data.show_fields_employee;
      const showFieldsCustomer =
        jobReportsUserPreference.data &&
        jobReportsUserPreference.data.show_fields_customer;
      const showFieldsTasks =
        jobReportsUserPreference.data &&
        jobReportsUserPreference.data.show_fields_task;
      if (!parsedQuery.type || parsedQuery.type === "detail") {
        if (showFields) {
          let attributes = {};
          for (const acc in this.accessorToHeaderDetail) {
            if (showFields.includes(acc) || acc === "first_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderDetail) {
            if (acc === "date") {
              attributes[acc] = false;
            } else {
              attributes[acc] = true;
            }
          }
          this.setAttributes(attributes);
        }
      } else if (parsedQuery.grouped === "employee") {
        if (showFieldsEmployee) {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryEmployee) {
            if (showFieldsEmployee.includes(acc)|| acc === "first_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryEmployee) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        }
      } else if (parsedQuery.grouped === "job") {
        if (showFieldsJob) {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryJob) {
            if (showFieldsJob.includes(acc)|| acc === "job_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryJob) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        }
      } else if (parsedQuery.grouped === "task") {
        if (showFieldsTasks) {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryTask) {
            if (showFieldsTasks.includes(acc)|| acc === "task_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryTask) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        }
      } else if (parsedQuery.grouped === "customer") {
        if (showFieldsCustomer) {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryCustomer) {
            if (showFieldsCustomer.includes(acc)|| acc === "customer_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryCustomer) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        }
      } else if (!parsedQuery.grouped)  {
        if (showFieldsEmployee) {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryEmployee) {
            if (showFieldsEmployee.includes(acc)|| acc === "first_name" || acc === "total_time") {
              attributes[acc] = true;
            } else {
              attributes[acc] = false;
            }
          }
          this.setAttributes(attributes);
        } else {
          let attributes = {};
          for (const acc in this.accessorToHeaderSummaryEmployee) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        }
      }
    });
  }

  toggleTableLoader() {
    this.setState({ isTableLoading: true });
  }

  setTimeOut(time) {
    this.setState({ isTableLoading: true });
    setTimeout(() => {
      this.setState({ isTableLoading: false });
    }, time);
  }

  loadData() {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    history.push({ pathname });
    this.setState({ isLoading: true });
    this.fetchData(query).then(() => this.setState({ isLoading: false }));
  }

  fetchData(params) {
    this.setState({ isTableLoading: true, employeeReport: params.report_view });
    /*    this.props.TaskDucks.getAllTasks({ paginate:false });
    this.props.JobDucks.getAllJobs({paginate: false});*/
    return this.props.JobReportsDucks.getJobReports({
      ...params,
      paginate: false,
    })
      .then(() => this.setState({ isTableLoading: false }))
      .catch(() => this.setState({ isTableLoading: false }));
  }

  toogleTable(item) {
    this.setState({ groupBy: item.value });
  }

  render() {
    const {
      isGeoFenceVisible,
      location: { search },
    } = this.props;
    const {
      isLoading,
      isInfoOpen,
      isTableLoading,
      groupBy,
      employeeReport,
    } = this.state;
    const tabs = [
      { href: "/job/list", label: "Customers/jobs" },
      { href: "/job/lists/task", label: "Tasks" },
    ];
    const parsedQuery = queryString.parse(search);
    const mandatoryField = (!parsedQuery.type || parsedQuery.type === "detail") ? "first_name" : (parsedQuery.grouped === "employee" || !parsedQuery.grouped) ? "first_name" : parsedQuery.grouped === "job" ? "job_name" : parsedQuery.grouped === "task" ? "task_name" : "customer_name";
    const required = "total_time";
    if (isGeoFenceVisible) {
      tabs.push({ href: "/geofence/job/lists", label: "Geofences" });
      tabs.push({ href: "/job/lists/jobreports", label: "Reports" });
    } else {
      tabs.push({ href: "/job/lists/jobreports", label: "Reports" });
    }
    return (
      <section>
        <TwoColumnLayout
          navInfo={NAV.JobReportsPage}
          isInfoOpen={isInfoOpen}
          tabItems={tabs.filter((item) => item)}
          fetchData={this.loadData}
          jobPage={true}
        >
          <JobReportsFilterSection
            isLoading={isLoading}
            fetchData={this.fetchData}
            toogleTable={this.toogleTable}
            employeeReport={employeeReport}
            setAttributeToggler={this.setAttributeToggler}
            groupBy={groupBy}
            toggleTableLoader={this.toggleTableLoader}
            showableAttributes={this.state.showableAttributes}
          />
          <JobReportsListSection
            isLoading={isTableLoading || isLoading}
            groupBy={groupBy}
            employeeReport={employeeReport}
            showableAttributes={this.state.showableAttributes}
          />
          {this.state.attributeToggler && (
            <AttributeToggler
              setToggle={() =>
                this.setAttributeToggler(!this.state.attributeToggler)
              }
              toggle={this.state.attributeToggler}
              data={{
                labels:
                  !parsedQuery.type || parsedQuery.type === "detail"
                    ? Object.values(this.accessorToHeaderDetail)
                    : parsedQuery.grouped === "employee" || !parsedQuery.grouped
                    ? Object.values(this.accessorToHeaderSummaryEmployee)
                    : parsedQuery.grouped === "job"
                    ? Object.values(this.accessorToHeaderSummaryJob)
                    : parsedQuery.grouped === "task"
                    ? Object.values(this.accessorToHeaderSummaryTask)
                    : Object.values(this.accessorToHeaderSummaryCustomer),
                accessors:
                  !parsedQuery.type || parsedQuery.type === "detail"
                    ? Object.keys(this.accessorToHeaderDetail)
                    : parsedQuery.grouped === "employee" || !parsedQuery.grouped
                    ? Object.keys(this.accessorToHeaderSummaryEmployee)
                    : parsedQuery.grouped === "job"
                    ? Object.keys(this.accessorToHeaderSummaryJob)
                    : parsedQuery.grouped === "task"
                    ? Object.keys(this.accessorToHeaderSummaryTask)
                    : Object.keys(this.accessorToHeaderSummaryCustomer),
                defaultVals: Object.values(this.state.showableAttributes),
              }}
              mandatoryField={mandatoryField}
              required={required}
              onChange={this.setAttributes}
              setColumns={this.setColumns}
            />
          )}
        </TwoColumnLayout>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
  jobReportsList: JobReportsDucks.jobReportsList(state),
  jobReportsUserPreference: JobReportsDucks.jobReportsUserPreference(state),
});

const mapActionsToProps = (dispatch) => ({
  JobReportsDucks: bindActionCreators(JobReportsDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
});

JobReportsPage.propTypes = {
  location: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
  jobReportsList: PropTypes.array,
};

JobReportsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(JobReportsPage);
