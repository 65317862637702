import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';
import CompanyEntity from 'company/entities/company';
import BillEntity from 'payments/bills/entities/bill';

class BillApiGateway {

  toString() {
    return 'BillApiGateway';
  }

  async getInfoList(params) {
    const infoListConfig =  AppConfig.server.apiHost + '/t2b/documents/kw-articles-list/';
    const apiGateway = new ApiGateway(infoListConfig);
    const response = await apiGateway.load(params);
    const infoList = response.data;
    return infoList;
  }


  async getCompanyAccountDetails(params) {
    const accountViewConfig =   AppConfig.server.apiHost + '/payment/customer/' + params.id + '/';
    const apiGateway = new ApiGateway(accountViewConfig);
    const response = await apiGateway.load();
    const companyEntity =  new CompanyEntity(response.data.company);
    /*const billEntity =  new BillEntity(response.data.trial_end_date);*/
    return { ...response.data, companyEntity };
    
  }

 async updateAccountDetails(data, companyID) {
    const updateDetailsConfig =   AppConfig.server.apiHost + '/payment/customer/' + companyID.id + '/';
    const apiGateway = new ApiGateway(updateDetailsConfig);
    const response = await apiGateway.update(data);
    const companyEntity =  new CompanyEntity(response.data.company);
    const billEntity =  new BillEntity(response.data.trial_end_date);
    return { ...response.data , companyEntity , billEntity };
  }

   async getCommercialStatusList(params) {
    const commercialStatusListConfig =  AppConfig.server.apiHost + '/t2b/fsm/fsm/status-master';
    const apiGateway = new ApiGateway(commercialStatusListConfig);
    const response = await apiGateway.load(params);
    const commercialStatusList = response.data;
    return commercialStatusList;
  }

  async getBillStatusList(params) {
    const billStatusListConfig =  AppConfig.server.apiHost + '/t2b/fsm/fsm/status-master';
    const apiGateway = new ApiGateway(billStatusListConfig);
    const response = await apiGateway.load(params);
    const billStatusList = response.data;
    return billStatusList;
  }

  async getBillList(params){
    const billListConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/all';
    const apiGateway = new ApiGateway(billListConfig);
    const response = await apiGateway.load(params);
    const billList = response.data;
    return billList;
  }

  async getCompanyBillList(params){
    const billListConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/';
    const apiGateway = new ApiGateway(billListConfig);
    const response = await apiGateway.load(params);
    const billList = response.data;
    return billList;
  }

  async getCompanyPlan(companyId){
    const companyPlanConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/current/' + companyId + '/';
    const apiGateway = new ApiGateway(companyPlanConfig);
    const response = await apiGateway.load();
    const planDetails = response.data;
    return planDetails;
  }

  async getCompanyBillDetails(params){
    const companyBillDetailConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/' + params.id + '/';
    const apiGateway = new ApiGateway(companyBillDetailConfig);
    const response = await apiGateway.load();
    const billDetails = response.data;
    return billDetails;
  }

  async updateCompanyDetails(discount, status, id, charge_rate, service_fees, discount_unit){
    const verifyObj = {
      discount : discount,
      charge_rate : charge_rate,
      service_fees : service_fees,
      discount_unit: discount_unit,
    };
    const updateBillDetailConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/' + id + '/';
    const apiGateway = new ApiGateway(updateBillDetailConfig);
    const response = await apiGateway.partialUpdate(verifyObj);
    const billDetails = response.data;
    return billDetails;
  }

  async cancelCompanyPlan(companyId){
    const data = {
      company_id: companyId
    };
    const cancelPlanConfig =  AppConfig.server.apiHost + '/payment/customer/cancel/';
    const apiGateway = new ApiGateway(cancelPlanConfig);
    const response = await apiGateway.save(data);
    const plan = response.data;
    return plan;
  }

  async authenticatePassword(email, password, device) {
    const signInReqObj = {
      email: email,
      password: password,
      device: device,
    };
    const signinConfig = AppConfig.server.apiHost + '/company/login/';
    const apiGateway = new ApiGateway(signinConfig);
    const response = await apiGateway.save(signInReqObj);
    return response.data;
  }

  async getAuditLogs(params){
    const auditListConfig =  AppConfig.server.apiHost + '/t2b/audits/audits/';
    const apiGateway = new ApiGateway(auditListConfig);
    const response = await apiGateway.load(params);
    const auditList = response.data;
    return auditList;
  }

  async updatePlanTypeDetails(planType, id){
     const data = {
      company_id: id,
      plan_type: planType
    };
    const updatePlanType =  AppConfig.server.apiHost + '/payment/customer/plan/' + id + '/';
    const apiGateway = new ApiGateway(updatePlanType);
    const response = await apiGateway.update(data);
    const updatedPlanType = response.data;
    return updatedPlanType;
  }
}



export default BillApiGateway;