import React from "react";

import PropTypes from "prop-types";
import moment from "moment";

import Button from "reactstrap/lib/Button";
import Card from "reactstrap/lib/Card";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import styles from "./styles.module.scss";
import hoursImage from "./hours.png";
import correctionImage from "./correction.png";
import punchinImage from "./punchin.png";
import timecardpendingImage from "./timecardpending.png";
import SurveyImage from "./survey.png";
import documentSubmitted from "./documentSubmitted.png";
import fieldForm from "./fieldForm.png";
import timeOff from "./timeOff.png";
import learningCenter from "./learningCenter.png"

/**
 * DashboardPage -> DashboardListSection -> DashboardGridComponent
 *
 * Props:
 *    - dashboardList
 */

const DashboardGridComponent = (props) => {
  const {
    data,
    mcsReportDetail,
    mvReportDetail,
    mvEntryDetail,
    osReportDetail,
    userCompany,
    goTo,
    location,
    employeeAccess,
    userExtraData,
  } = props;
  const { pathname } = location;
  console.log(userExtraData);
  return (
    <section>
      <Row>
        <Col sm="12" md="6">
          <Card block className={styles["dashboard--wrapper"]}>
            <Row className={styles["card-row"]}>
              <Col sm="3" className="icon-border">
                <img
                  className={styles["dashboard--icon"]}
                  src={punchinImage}
                  alt="Icon"
                />
              </Col>
              <Col sm="9" className={styles["dashboard--title"]}>
                {data.total_active_employees > 0 ? (
                  <div>
                    <span className="count-highlighted">
                      {data.currently_working}{" "}
                    </span>
                    <span className="count-highlighted">
                      <span>/</span>
                      {data.total_active_employees}
                    </span>
                    <div className="sub-text">Employees Currently Working</div>
                  </div>
                ) : (
                  <div>
                    <span className="count-highlighted">
                      {data.currently_working}{" "}
                    </span>
                    <div className="sub-text">You have no employees yet</div>
                  </div>
                )}
                <div className={styles["btn-grp"]}>
                  <Row md="6" className={styles["btn-green"]}>
                    {data.total_active_employees > 0 ? (
                      <Button
                        className={
                          data.face_login_failure_count >= 0
                            ? "btn-green-bg custom-my-3 pl-2 pr-2"
                            : "btn-margin btn-green-bg pl-2 pr-2"
                        }
                        onClick={() => goTo("/activity/tracking/current")}
                      >
                        Employee Tracking
                      </Button>
                    ) : (
                      <Button
                        className={
                          data.face_login_failure_count >= 0
                            ? "btn-green-bg custom-my-3 pl-2 pr-2"
                            : "btn-margin btn-green-bg pl-2 pr-2"
                        }
                        onClick={() => goTo("/employee/list")}
                      >
                        Create Employees Here
                      </Button>
                    )}
                  </Row>
                  {data.face_login_failure_count >= 0 && employeeAccess ? (
                    <Row md="6" className={styles["btn-warning"]}>
                      <Button
                        className="btn-warning-bg"
                        onClick={() =>
                          goTo("/employee/kiosk-audit/list", {
                            start_date: moment()
                              .subtract(6, "day")
                              .format("YYYY-MM-DD"),
                            end_date: moment().format("YYYY-MM-DD"),
                            parent_url: pathname,
                            status:
                              data.face_login_failure_count !== 0
                                ? "FAILURE"
                                : "ALL",
                          })
                        }
                      >
                        {data.face_login_failure_count} BIOMETRIC LOGIN FAILURES
                        IN LAST 7 DAYS
                      </Button>
                    </Row>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col sm="12" md="6">
          <Card block className={styles["dashboard--wrapper-right"]}>
            <Row className={styles["card-row"]}>
              <Col sm="3" className="icon-border">
                <img
                  className={styles["dashboard--icon"]}
                  src={hoursImage}
                  alt="Icon"
                />
              </Col>
              <Col sm="9" className={styles["dashboard--title"]}>
                <span className="count-highlighted">
                  {data.total_working_hours}{" "}
                </span>
                <div className="sub-text-padding">
                  Hours Worked in last 30 days
                </div>
                <Button
                  className="btn-green-bg btn-margin pl-2 pr-2"
                  onClick={() =>
                    goTo("/timecard/custom/list", {
                      start_date: moment()
                        .startOf("month")
                        .format("YYYY-MM-DD"),
                      end_date: moment()
                        .endOf("month")
                        .format("YYYY-MM-DD"),
                    })
                  }
                >
                  Timecards
                </Button>
                {data.meal_violation_count > 0 &&
                mvEntryDetail &&
                mvEntryDetail.value === "Enable" ? (
                  <span>
                    <Button
                      className="btn-warning-bg btn-margin mr-3"
                      onClick={() =>
                        goTo(
                          `/report/${mvReportDetail.id}/${mvReportDetail.code}/detail`,
                          {
                            start_date: moment()
                              .startOf("month")
                              .format("YYYY-MM-DD"),
                            end_date: moment()
                              .endOf("month")
                              .format("YYYY-MM-DD"),
                            company_id: userCompany.id,
                          }
                        )
                      }
                    >
                      {data.meal_violation_count} MEAL FLAGS
                    </Button>
                  </span>
                ) : null}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm="12" md="6">
          <Card block className={styles["dashboard--wrapper"]}>
            <Row className={styles["card-row"]}>
              <Col sm="3" className="icon-border">
                <img
                  className={styles["dashboard--icon"]}
                  src={correctionImage}
                  alt="Icon"
                />
              </Col>
              <Col sm="9" className={styles["dashboard--title"]}>
                <span className="count-highlighted">
                  {data.total_manual_entries}{" "}
                </span>
                <div className="sub-text-padding">
                  Manual Corrections in the Last 24 Hours
                </div>
                <div className={styles["btn-grp"]}>
                  <Row md="6" className={styles["btn-green"]}>
                    <Button
                      onClick={() =>
                        goTo(`/timecard/deviated-daily`, {
                          parent_url: pathname,
                          payroll_id: "custom",
                          start_date: moment()
                            .startOf("month")
                            .format("YYYY-MM-DD"),
                          end_date: moment()
                            .endOf("month")
                            .format("YYYY-MM-DD"),
                          page_size: 5,
                        })
                      }
                      className="btn-green-bg custom-my-3 pl-2 pr-2"
                    >
                      Review Deviations
                    </Button>
                  </Row>
                  <Row md="6" className={styles["btn-warning"]}>
                    {mcsReportDetail && (
                      <Button
                        className="btn-warning-bg pl-2 pr-2"
                        onClick={() =>
                          goTo(
                            `/report/${mcsReportDetail.id}/${mcsReportDetail.code}/detail`,
                            {
                              start_date: moment()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              end_date: moment()
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                              parent_url: pathname,
                              company_id: userCompany.id,
                            }
                          )
                        }
                      >
                        Manual Correction Report
                      </Button>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col sm="12" md="6">
          <Card block className={styles["dashboard--wrapper-right"]}>
            <Row className={styles["card-row"]}>
              <Col sm="3" className="icon-border">
                <img
                  className={styles["dashboard--icon"]}
                  src={timecardpendingImage}
                  alt="Icon"
                />
              </Col>
              <Col sm="9" className={styles["dashboard--title"]}>
                <span className="count-highlighted">
                  {data.total_overtime_hours}{" "}
                </span>
                <div className="sub-text-padding">
                  Overtime Hours Worked in last 30 days
                </div>
                <div>
                  {osReportDetail && (
                    <span>
                      <Button
                        className="btn-margin btn-green-bg pl-2 pr-2"
                        onClick={() =>
                          goTo(
                            `/report/${osReportDetail.id}/${osReportDetail.code}/detail`,
                            {
                              start_date: moment()
                                .startOf("month")
                                .format("YYYY-MM-DD"),
                              end_date: moment()
                                .endOf("month")
                                .format("YYYY-MM-DD"),
                              company_id: userCompany.id,
                              parent_url: pathname,
                            }
                          )
                        }
                      >
                        Overtime Report
                      </Button>
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {userExtraData?.company_permissions?.communication_center && (
        <Row>
          <Col sm="12" md="6">
            <Card block className={styles["dashboard--wrapper"]}>
              <Row className={styles["card-row"]}>
                <Col sm="3" className="icon-border">
                  <img
                    className={styles["dashboard--icon"]}
                    src={fieldForm}
                    alt="Icon"
                  />
                </Col>
                <Col sm="9" className={styles["dashboard--title"]}>
                  <span className="count-highlighted">
                    {data?.new_field_form}{" "}
                  </span>
                  <div className="sub-text-padding">
                    New Field Forms (last 7 days)
                  </div>
                  <div>
                    <span>
                      <Button
                        className="survey-btn btn-green-bg pl-2 pr-2"
                        onClick={() =>
                          goTo(`forms/fieldforms`, {
                            end_date: moment().format("YYYY-MM-DD"),
                            start_date: moment(new Date()).subtract(7, 'days').format("YYYY-MM-DD"),
                          })
                        }
                      >
                        Field Form Dashboard
                      </Button>
                    </span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>

          <Col sm="12" md="6">
            <Card block className={styles["dashboard--wrapper-right"]}>
              <Row className={styles["card-row"]}>
                <Col sm="3" className="icon-border">
                  <img
                    className={styles["dashboard--icon"]}
                    src={documentSubmitted}
                    alt="Icon"
                  />
                </Col>
                <Col sm="9" className={styles["dashboard--title"]}>
                  <span className="count-highlighted">
                    {data?.communication_document_submitted}{" "}
                  </span>
                  <div className="sub-text-padding">
                    Documents Submitted in Communication Center (last 7 days)
                  </div>
                  <div>
                    <span>
                      <Button
                        className="survey-btn btn-green-bg pl-2 pr-2"
                        onClick={() => goTo(`document/completion/report/`)}
                      >
                        Document Tracking
                      </Button>
                    </span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
      <Row>
        {userExtraData?.company_permissions?.learning_center && (
          <Col sm="12" md="6">
            <Card block className={styles["dashboard--wrapper"]}>
              <Row className={styles["card-row"]}>
                <Col sm="3" className="icon-border">
                  <img
                    className={styles["dashboard--icon"]}
                    src={learningCenter}
                    alt="Icon"
                  />
                </Col>
                <Col sm="9" className={styles["dashboard--title"]}>
                  <span className="count-highlighted">
                    {data?.learning_document_submitted}{" "}
                  </span>
                  <div className="sub-text-padding">
                    Documents Submitted in Learning Center (last 7 days)
                  </div>
                  <div>
                    <span>
                      <Button
                        className="survey-btn btn-green-bg pl-2 pr-2"
                        onClick={() =>
                          goTo(`learning/documents-completion/report`, {
                            end_date: moment().format("YYYY-MM-DD"),
                            start_date: moment().format("YYYY-MM-DD"),
                          })
                        }
                      >
                        Learning Tracking
                      </Button>
                    </span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
        {userExtraData?.company_permissions?.paid_time_off && (
          <Col sm="12" md="6">
            <Card
              block
              className={`${
                userExtraData?.company_permissions?.learning_center === false &&
                userExtraData?.company_permissions?.paid_time_off === true
                  ? styles["dashboard--wrapper"]
                  : styles["dashboard--wrapper-right"]
              }`}
            >
              <Row className={styles["card-row"]}>
                <Col sm="3" className="icon-border">
                  <img
                    className={styles["dashboard--icon"]}
                    src={timeOff}
                    alt="Icon"
                  />
                </Col>
                <Col sm="9" className={styles["dashboard--title"]}>
                  <span className="count-highlighted">
                    {data?.pto_requests}{" "}
                  </span>
                  <div className="sub-text-padding">
                    PTO Requests (last 7 days)
                  </div>
                  <div>
                    <span>
                      <Button
                        className="survey-btn btn-green-bg pl-2 pr-2"
                        onClick={() =>
                          goTo(`pto-policy/time-off/list/`, {
                            end_date: moment().format("YYYY-MM-DD"),
                            start_date: moment().format("YYYY-MM-DD"),
                          })
                        }
                      >
                        PTO Requests
                      </Button>
                    </span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      </Row>

      {userExtraData?.company_permissions?.feedback && (
        <Row>
          <Col sm="12" md="6">
            <Card block className={styles["dashboard--wrapper"]}>
              <Row className={styles["card-row"]}>
                <Col sm="3" className="icon-border">
                  <img
                    className={styles["dashboard--icon"]}
                    src={SurveyImage}
                    alt="Icon"
                  />
                </Col>
                <Col sm="9" className={styles["dashboard--title"]}>
                  <span className="count-highlighted">
                    {data.feedback_failure_count}{" "}
                  </span>
                  <div className="sub-text-padding">Flagged Surveys Today</div>
                  {/* <div className="sub-text-padding">since mid night</div> */}
                  <Button
                    className="survey-btn btn-green-bg pl-2 pr-2"
                    onClick={() =>
                      goTo(`surveys/dashboard/`, {
                        create_date_from: moment().format("YYYY-MM-DD"),
                        create_date_to: moment().format("YYYY-MM-DD"),
                        action_code: "FAILED",
                      })
                    }
                  >
                    Survey Report
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      )}
    </section>
  );
};

DashboardGridComponent.propTypes = {
  data: PropTypes.object.isRequired,
  mcsReportDetail: PropTypes.object,
  mvReportDetail: PropTypes.object,
  osReportDetail: PropTypes.object,
  userCompany: PropTypes.object.isRequired,
  jobOptions: PropTypes.array.isRequired,
  employeeAccess: PropTypes.number.isRequired,
};

export default DashboardGridComponent;
