import React from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as CustomerDucks from 'ducks/customers/customer';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import TwoColumnLayout from 'layouts/TwoColumn';
import CustomerFormSection from 'sections/customers/form/Form';

import { NAV } from 'constants/customers';

/**
 * CustomersListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link CustomerFilterSection}
 *    - {@link CustomerListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class CustomerFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.state = { isLoading: false,
      isInfoOpen: true,
      parent: NAV.CustomerFormPage.parent,
      isCustomerDetailsLoading: false,
      isJobsLoading: false,
    };
  }

  componentWillMount() {
    const cookieKey = cookie.load("customer_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  setParentUrl(query) {
    const { parent } = this.state;
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ parent });
    }
  }

  loadData(){
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { pathname, query } } = this.props;
    if (!('start_date' in query) || !('end_date' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          start_date: moment().subtract( 29, "days").format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
        }),
      });
    } 
    this.setParentUrl(query);
    this.setState({ isLoading: true });
    this.props.BasicSettingDucks.getBasicSetting();
    this.props.CustomerDucks.getCustomersDetails({ ...params, paginate:false })
      .then(() => this.setState({ isLoading: false }));
    this.fetchData(query);
  }

  fetchData(query){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isJobsLoading: true });
    this.props.JobDucks.getJobsIds({ ...query, customer_id: params.id, paginate: false })
      .then(() => this.setState({ isJobsLoading: false }))
      .catch(() => this.setState({ isJobsLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("employee_info", !isInfoOpen);
  }

  render() {
    const { isLoading, isInfoOpen, parent, isCustomerDetailsLoading, isJobsLoading } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout 
        infoList={infoList}
        navInfo={{...NAV.CustomerFormPage, parent }}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <CustomerFormSection
          isLoading={isLoading}
          isCustomerDetailsLoading={isCustomerDetailsLoading}
          isJobsLoading={isJobsLoading}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  customerList:CustomerDucks.customerList(state),
  customerDetails:CustomerDucks.customerDetails(state),
  /*jobList: JobDucks.jobList(state),*/
});

const mapActionsToProps = dispatch => ({
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

CustomerFormPage.propTypes = {
  location: PropTypes.object.isRequired,
  CustomerDucks: PropTypes.object.isRequired,
  /*jobList: PropTypes.object.isRequired,*/
};

CustomerFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerFormPage);
