import React from 'react';

import { FormGroup , Card  , Input , Row , Col} from 'reactstrap';
import styles from './styles.module.scss';

const MultiSelectComponent = (props) => {
  const { data , index, noCard} = props;
  return (
   <Card className={noCard ? `${styles['card-ui-blank']} mr-3 pl-3 pr-3 pt-2 pb-2` : `${styles['card-ui']} mr-3 pl-3 pr-3 pt-3 pb-3`}>
      <Row>
        <Col md={noCard ? 12 : 11} className={`${styles['custom-col']} pl-1`}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span className={(((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) && noCard) ? `${styles['questions-ui']} ${styles['questions-no-padding']} pr-0 pl-2` : (((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) && !noCard) ? `${styles['questions-ui']} pr-0 pl-2` : ((!(data.behaviour && data.behaviour === 'MANDATORY') || !(props.mandatory)) && noCard) ? `${styles['questions-no-padding']} pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: data.question }}></span>
        </Col>
      </Row>
      <div className={`${styles['options-margin']} mt-2 custom-input-label`}>
      {
        data && data.answer && data.answer.length ? data.answer.map((item, id)=>
          <FormGroup>
          {item.value
            ?
            <div>
              <input
                type="checkbox"
                id={`${index}_${id}`}
                name={item.value}
                label={item.value}
                checked={item.checked}
                disabled
              />
              <label for={item.value} className="ml-3" style={noCard ? {marginBottom: '0px'} : null}>{item.value}</label><br/>
            </div>
            :
            null
          }
          </FormGroup>
        ):<div>No Response Submitted</div>
      }
      </div>
    </Card>
  );
};

export default MultiSelectComponent;
