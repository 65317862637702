import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const EmployeeDailyimecardTableComponent = (props, context) => {
  const { data, location: { query }, pageSize } = props;
  const { page = 1 } = query;

  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="table-employee-daily-timecard-report align-table right-align-table right-align-col right-align-col4 right-align-col5 right-align-col6 align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."employee_id"'>
            Employee Id
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."first_name"'>
            First Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."last_name"'>
            Last Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."title"'>
            Employee Title
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='department_name'>
            Department Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='team_name'>
            Team Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='date'>
            Session Date
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='punch_in_time'>
            Total Punch In Time
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='meal_period_time'>
            Total Meal Period Time
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='regular_time'>
            Total Regular Time
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field=' over_time'>
            OverTime
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='double_over_time'>
            Double OverTime
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='total_paid_time'>
            Total Paid Time
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td title={item.employee_id} className="pl-3"> {item.employee_id}</td>
              <td title={item.first_name}> {item.first_name}</td>
              <td title={item.last_name}>{item.last_name}</td>
              <td title={item.title}>{item.title}</td>
              <td title={item.department_name}>{item.department_name}</td>
              <td title={item.team_name}>{item.team_name}</td>
              <td title={item.date}> {item.date}</td>
              <td title={item.total_punch_in_time} className="text-right">{item.punch_in_time}</td>
              <td title={item.meal_period_time} className="text-right">{item.meal_period_time}</td>
              <td title={item.regular_time} className="text-right">{item.regular_time}</td>
              <td title={item.over_time} className="text-right">{item.over_time}</td>
              <td title={item.double_overtime} className="text-right">{item.double_over_time}</td>
              <td title={item.total_paid_time} className="text-right pr-3">{item.total_paid_time}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

EmployeeDailyimecardTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

EmployeeDailyimecardTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EmployeeDailyimecardTableComponent;
