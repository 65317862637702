import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { toast } from 'react-toastify';

import * as MissingActivityDucks from 'ducks/activities/missingActivity';
import * as SessionDucks from 'ducks/activities/session';
// import * as TimecardDucks from 'ducks/timecards/timecard';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as UserDucks from 'ducks/accounts/user';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ManualSessionMealPeriodComponent from 'components/timecards/customList/MealPeriod';
import ManualSessionPunchInComponent from 'components/timecards/customList/PunchIn';
import ManualSessionPunchOutComponent from 'components/timecards/customList/PunchOut';
import CustomCreateTimecardFilterFormComponent from 'components/timecards/customList/CustomCreateTimecardFilterForm';
import TimecardWarning from 'sections/timecards/customList/TimecardWarning';

import { MSGS } from 'constants/activities';
import styles from './styles.module.scss';

/**
 * ManualSessionPage -> CustomCreateTimecardSection
 *
 * Components:
 *    - {@link ManualSessionPunchInComponent}
 *    - {@link ManualSessionPunchOutComponent}
 *    - {@link ManualSessionMealPeriodComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - addMissingActivity
 *    - updateMissingActivity
 *    - removeMissingActivity
 *    - postSession
 */
class CustomCreateTimecardSection extends React.Component {
  static isSessionValidated(activityList) {
    for (let i = 0; i < activityList.length; i += 1) {
      if (moment(activityList[i].start_datetime) > moment(activityList[i].end_datetime)) {
        toast.error('End datetime must be greater than start datetime');
        return false;
      }
      if (activityList[i].activity_code !== 'PUNCH-IN') {
        if (moment(activityList[i].start_datetime) < moment(activityList[0].start_datetime) ||
          moment(activityList[i].start_datetime) > moment(activityList[0].end_datetime) ||
          moment(activityList[i].end_datetime) < moment(activityList[0].start_datetime) ||
          moment(activityList[i].end_datetime) > moment(activityList[0].end_datetime)) {
          toast.error('Meal-Period must be between Punch-In session');
          return false;
        }
      }
      if (!activityList[i].end_datetime) {
        toast.error('select end datetime ');
        return false;
      }
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.addMealPeriodEntry = this.addMealPeriodEntry.bind(this);
    this.editEntry = this.editEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.reverseParseDate = this.reverseParseDate.bind(this);
    this.browserParseDate = this.browserParseDate.bind(this);
    this.toggleWarningModal = this.toggleWarningModal.bind(this);
    this.updateState = this.updateState.bind(this);
    this.submitManualSessionFuture = this.submitManualSessionFuture.bind(this);

    this.state = {isSubmitting: false , openWarningModal: false , isConfirm: false, futureData: {
      newMissingList: null,
      query: null,
  } };
  }

  reverseParseDate(dateString){
    // convert browser timezone date to user timezone date
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment(dateString);
    const newMoment = moment.tz([
      momentWithBrowserTimezone.year(),
      momentWithBrowserTimezone.month(),
      momentWithBrowserTimezone.date(),
      momentWithBrowserTimezone.hour(),
      momentWithBrowserTimezone.minute(),
      momentWithBrowserTimezone.second(),
      momentWithBrowserTimezone.millisecond(),
    ], userTimezone);
    return newMoment;
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

 browserParseDate(dateString){
    // convert browser timezone date to user timezone date
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment.tz(dateString, userTimezone);
    const newMoment = moment.tz([
      momentWithBrowserTimezone.year(),
      momentWithBrowserTimezone.month(),
      momentWithBrowserTimezone.date(),
      momentWithBrowserTimezone.hour(),
      momentWithBrowserTimezone.minute(),
      momentWithBrowserTimezone.second(),
      momentWithBrowserTimezone.millisecond(),
    ], moment.tz.guess());
    return newMoment;
  }

  componentWillMount() {
    const { location: { query } } = this.props;
    const data = {
      activity_code: "PUNCH-IN",
      start_datetime: moment(query.start_date).toISOString(),
      end_datetime: moment(query.end_date).toISOString()  ,
      index: 0,
    };

    this.props.MissingActivityDucks.updateMissingActivity(data);
  }

  componentWillUnmount() {
    this.props.MissingActivityDucks.resetMissingActivity();
  }

  getEmployees(params) {
    const newParams = { ...params, activated: true, paginate: false }
    return this.props.EmployeeDucks.getEmployees(newParams);
  }

  addMealPeriodEntry() {
    const { missingActivityList } = this.props;
    const currentDate = new Date();
    const data = {
      activity_code: "MEAL-PERIOD",
      start_datetime: moment(missingActivityList[0].start_datetime).toISOString(),
      end_datetime: moment(missingActivityList[0].end_datetime).toISOString(),
      is_future_dt: moment(missingActivityList[0].start_datetime)>currentDate || moment(missingActivityList[0].end_datetime)>currentDate ? true : undefined
    };
    this.props.MissingActivityDucks.addMissingActivity(data);
  }

  editEntry(item, date, status) {
    const data = { ...item };
    // if(this.reverseParseDate(date[0]).valueOf() > moment().valueOf()){
    //   toast.error('Selected date time should not be greater than current date time');
    // }
    if (status) data.start_datetime = date[0];
    else data.end_datetime = date[0];
    return this.props.MissingActivityDucks.updateMissingActivity(data);
  }

  removeEntry(data) {
    return this.props.MissingActivityDucks.removeMissingActivity(data);
  }

  submitManualSessionFuture(){
    const { router: { history } } = this.context;
    const {location: { query } } = this.props; 
    this.setState({openWarningModal:false , isConfirm: false})
    this.setState({isSubmitting: true});
    const {futureData} = this.state;
    this.props.SessionDucks.postSession(futureData?.newMissingList, futureData?.query.employee_id)
          .then((res) => {
            this.setState({ isSubmitting: false });
            toast.success(MSGS.MISSING_SESSION_SUCCESS);
            history.push(query.parent);
          })
          .catch((err) => {
            this.setState({ isSubmitting: false });
            if (Array.isArray(err)) {
              toast.error(err[0].non_field_errors[0]);
            } else {
              toast.error(err.non_field_errors[0]);
            }
          });
  }




  toggleWarningModal() {
    const { openWarningModal, isConfirm, futureData } = this.state;
    this.setState({ openWarningModal: !openWarningModal,isConfirm:false,futureData: {
      newMissingList:null,
        query:null,
    } });
  }
  

  submitManualSession() {
    const { router: { history } } = this.context;
    const { openWarningModal, isConfirm } = this.state;
    const { missingActivityList, location: { query } } = this.props;
    
    if (!query.employee_id) {
      toast.error('Please select an employee to create a custom timecard.');
      return false;
    }
  
    let newMissingList = missingActivityList.map(item => ({ ...item }));
  
    if (this.constructor.isSessionValidated(missingActivityList)) {
      this.setState({ isSubmitting: true }, () => {
        let futureDateTimeDetected = false; 
        for (let i in missingActivityList) {
          const start_datetime = this.reverseParseDate(newMissingList[i].start_datetime);
          const end_datetime = this.reverseParseDate(newMissingList[i].end_datetime);
          newMissingList[i].start_datetime = start_datetime.toISOString();
          newMissingList[i].end_datetime = end_datetime.toISOString();
  
          const currentDate = new Date();
          if ((start_datetime > currentDate || end_datetime > currentDate) && isConfirm === false ) {
            futureDateTimeDetected = true;
            this.setState({ isSubmitting: false });
            newMissingList[i].is_future_dt = true;
          }
        }
  
        if (futureDateTimeDetected) {
          this.setState({
              openWarningModal: true,
              futureData: {
                  newMissingList: newMissingList,
                  query: query,
              }
          }, () => {
              console.log();
          });
          return false; // Exit function
      }
        this.props.SessionDucks.postSession(newMissingList, query.employee_id)
          .then((res) => {
            this.setState({ isSubmitting: false });
            toast.success(MSGS.MISSING_SESSION_SUCCESS);
            history.push(query.parent);
          })
          .catch((err) => {
            this.setState({ isSubmitting: false });
            if (Array.isArray(err)) {
              toast.error(err[0].non_field_errors[0]);
            } else {
              toast.error(err.non_field_errors[0]);
            }
          });
      });
    }
    return true;
  }
  


  render() {
    const {
      dateFormat, isLoading, missingActivityList, timeFormat, timeFormat24Hrs,
      location: { query }
    } = this.props;
    const { isSubmitting,openWarningModal } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <Row className="ml-3 mr-4">
          <Col md={5} className="px-0">
            {!query.employee &&
              <CustomCreateTimecardFilterFormComponent
                getEmployees={this.getEmployees}
                location={this.props.location}
              />}
          </Col>
          <Col md={7} className="text-right pt-4 mt-2 px-0">
            <Button className="mr-2 py-2" size="sm" onClick={this.addMealPeriodEntry}>Add Meal Period</Button>
            <Button className="py-2" size="sm" onClick={this.submitManualSession} disabled={isSubmitting} >Submit <ButtonLoaderAtom active={isSubmitting} /></Button>
          </Col>
        </Row>
        <Row className="pb-5">
          <Col sm="4" className={`${styles.timeline} offset-md-1`}>
            {missingActivityList.map(item => {
              if (item.activity_code === 'PUNCH-IN') {
                return (<ManualSessionPunchInComponent
                  key={item.index}
                  dateFormat={dateFormat}
                  endDate={query.end_date}
                  item={{ ...item, start_datetime: moment(query.start_date).toISOString()}}
                  startDate={query.start_date}
                  timeFormat={timeFormat}
                  timeFormat24Hrs={timeFormat24Hrs}
                  editEntry={this.editEntry}
                  browserParseDate={this.browserParseDate}
                />);
              }
              return (<ManualSessionMealPeriodComponent
                key={item.index}
                dateFormat={dateFormat}
                endDate={query.end_date}
                item={item}
                startDate={query.start_date}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                editEntry={this.editEntry}
                removeEntry={this.removeEntry}
                browserParseDate={this.browserParseDate}
                reverseParseDate={this.reverseParseDate}
              />);
            })}
            {missingActivityList[0] &&
              (<ManualSessionPunchOutComponent
                dateFormat={dateFormat}
                endDate={query.end_date}
                item={{...missingActivityList[0] }}
                startDate={query.start_date}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                editEntry={this.editEntry}
                browserParseDate={this.browserParseDate}
                reverseParseDate={this.reverseParseDate}
              />)
            }
          </Col>
        </Row>
        {openWarningModal &&
          <TimecardWarning
            isOpen={openWarningModal}
            toggle={this.toggleWarningModal}
            submitManualSession= {this.submitManualSessionFuture}
            updateState = {this.updateState}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  missingActivityList: MissingActivityDucks.missingActivityList(state),
  userTimezone: UserDucks.userBATimezone(state),
});

const mapActionsToProps = dispatch => ({
  MissingActivityDucks: bindActionCreators(MissingActivityDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
});

CustomCreateTimecardSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  missingActivityList: PropTypes.array.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  MissingActivityDucks: PropTypes.object.isRequired,
  EmployeeDucks: PropTypes.object.isRequired,
  // SessionDucks: PropTypes.object.isRequired,
};

CustomCreateTimecardSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomCreateTimecardSection);
