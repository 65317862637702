import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  GEOFENCE: `${SERVER_URL}/geo-fence/`,
  ACTIVITY_TRACK: `tracking/`,
  DAILY_TIMECARD: `/daily-timecard/`,
  GEOCODING_LOCATION_IQ : 'https://us1.locationiq.com/v1/search.php',
  GEOCODING_HERE_MAPS: 'https://geocode.search.hereapi.com/v1/geocode',
  API_OPTION: `${SERVER_URL}/activity/activity-data/` 
};
