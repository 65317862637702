 /*import  GraniteError from 'lib/GraniteError'; */
class SurveyEntity {
  constructor(object={}){
  	this.question = object.question;
  	this.definition = object.definition;
  	this.sequence_of_configuration = object.sequence_of_configuration;
  	this.behaviour = object.behaviour;
  	this.answer =  object.answer;
  	this.mandatory_question = object.behaviour == 'MANDATORY' ? true : false;
	this.sp_question = object.sp_question;
 }
}

export default SurveyEntity;


