import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import moment from 'moment';

import SectionLoaderAtom from 'atoms/SectionLoader';
import VisibilitySensor from 'react-visibility-sensor';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import styles from './styles.module.scss';

class JobListTableComponent extends React.PureComponent {
  constructor(props){
    super(props);
    this.goTo = this.goTo.bind(this);
    this.handleCards = this.handleCards.bind(this);
    this.setData = this.setData.bind(this);
  }

  setData(params){
    const { router: { history } } = this.context;
    const { jobDetail, job, location: { query } } = this.props;
    const jobData = job && jobDetail[job.id];
    const detail = {
      jobs: [{ id: job.id, name: jobData.name }],
      start_date: moment(query.start_date).toISOString(),
      end_date: moment(query.end_date).toISOString(),
      customers: jobData.customer_data && jobData.customer_data.id ? [jobData.customer_data] : null,
      tasks: params && params.task && params.task.id ? [{ id: params.task.id, name: params.task.name }] : null,
      group_by: params && params.group_by,
    }
    this.props.setJobReportsData(detail);
    history.push( '/job/lists/jobreports/');
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  handleCards(visibility) {
    const { getJobDetails, job } = this.props;
    if (visibility) getJobDetails(job.id);
  }


  render(){
    const { location: {  pathname, search },
       toggle, isNameVisible, goTo, jobDetail, job } = this.props;
    const jobData = job && jobDetail[job.id];
    const hours = jobData && jobData.tasks && jobData.tasks.map((item) => <li className="listNone"><span onClick={() => this.setData({ task: item, group_by: 'job' })} tabIndex="0" role="button" className="link-name">{item.hours_display}</span></li>)
    const employeeCount= jobData && jobData.tasks && jobData.tasks.map((item) => <li className="listNone link-name"><span onClick={() => this.setData({ task: item })} tabIndex="0" role="button" className="link-name">{item.employee_count}</span></li>)
    const TaskName = jobData && jobData.tasks && jobData.tasks.map((item) => <li className="listNone elipsis-job">{item.name}</li>)
    return (
      <div className="job-card">
        <VisibilitySensor onChange={(visibility) => this.handleCards(visibility)}>
          {({ isVisible }) => (
            isVisible && !jobData
              ? <SectionLoaderAtom active />
              : (<div>
                <Row className="pt-4 pl-3">
                  <Col md={7}>
                    {isNameVisible ?
                      <p className={styles['customer-heading']}>
                        <Link to={{
                          pathname: `/customers/list/${jobData && jobData.customer_data.id}/details`,
                          search: queryString.stringify({ parent: `${pathname}${search}`}),
                        }} title="View details">{jobData && jobData.customer_data.customer_name}</Link></p>
                      :null}
                    <p className="mb-4"><span className={`${styles['job-heading']} pl-4`} title={jobData && jobData.name}>{jobData && jobData.name}</span>
                      {jobData && jobData.color==="yellow"?
                        <span className={`${styles['back-yellow']} ${styles['color-radius']}`} >&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="purple"?
                        <span className={`${styles['back-purple']} ${styles['color-radius-purple']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="blue1"?
                        <span className={`${styles['back-blue1']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="blue2"?
                        <span className={`${styles['back-blue2']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="green1"?
                        <span className={`${styles['back-green1']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="green2"?
                        <span className={`${styles['back-green2']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="red"?
                        <span className={`${styles['back-red']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="pink"?
                        <span className={`${styles['back-pink']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                      {jobData && jobData.color==="grey"?
                        <span className={`${styles['back-grey']} ${styles['color-radius']}`}>&nbsp;</span>:null
                      }
                    </p>
                  </Col>
                  <Col md={1} className="pr-0">
                    <Button color="primary" className="btn-other float-right btn-width" disabled={!jobData}
                      onClick={() => toggle(jobData)}
                    >QUICK VIEW
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button color="primary" className="btn-other btn-width" disabled={!jobData}
                      onClick={() => goTo(`/job/list/${jobData && jobData.id}/edit`)}
                    >EDIT
                    </Button>
                  </Col>
                </Row>
                <div className={`${styles['summary-div']} pl-3 pr-3`}>
                  <Row>
                    <Col className="text-left" md={4}>
                      <p className={styles['summary-heading']}> Job Summary </p>
                      <p> {jobData && jobData.start_date_display}</p>
                    </Col>
                    <Col className="text-right" md={4}>
                      <p className={styles['summary-other-heading']}> Hours </p>
                      <div onClick={() => this.setData({ group_by: 'job'})} tabIndex="0" role="button" className="link-name"> {jobData && jobData.hours_display}</div>
                    </Col>
                    <Col className="pl-3 text-right">
                      <p className={styles['summary-other-heading-last']}>Employees </p>
                      <div onClick={() => this.setData()} tabIndex="0" role="button" className="link-name">{jobData && jobData.employee_count}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left" md={4}>
                      <p className={styles['summary-heading']}> Task Summary </p>
                      <p title={TaskName} className="elipsis-job"> {TaskName}</p>
                    </Col>
                    <Col className="text-right" md={4}>
                      <p className={styles['summary-other-heading']}> Hours </p>
                      <p >{hours}</p>
                    </Col>
                    <Col className="pl-3 text-right">
                      <p className={styles['summary-other-heading-last']}>Employees </p>
                      <p>{employeeCount}</p>
                    </Col>
                  </Row>
                </div>  
              </div>)
          )}
        </VisibilitySensor>
      </div>
    );
  }  
};

JobListTableComponent.propTypes = {
  location: PropTypes.object.isRequired,
  getJobDetails: PropTypes.func,
  jobDetail: PropTypes.object,
};

JobListTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JobListTableComponent;
