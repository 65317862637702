import React from "react";
import { Modal , ModalBody } from "reactstrap";
import C3Chart from 'react-c3js';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Form from 'reactstrap/lib/Form';

//styles
import styles from "./styles.module.scss";

//assets
import clockImage from './total-hours.png';
import userImage from './employees.png';
import {ReactComponent as BackIcon} from './Back.svg';
import {ReactComponent as AddIcon} from './Add.svg';
import {ReactComponent as SendIcon} from './Send.svg';
import InputAtom from 'atoms/Input';
import JobNoteImageModal from './jobNoteImageModal';
import closeimage from './CloseCross.png';
import { UncontrolledTooltip } from 'reactstrap';
import SectionLoaderAtom from 'atoms/SectionLoader';
import DeleteNotesConfirmationModal from "./DeleteNotesConfirmationModal";

class JobInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.toggleJobNotes = this.toggleJobNotes.bind(this);
        this.submitNotes = this.submitNotes.bind(this);
        this.state = {
            isJobNotes: false,
            item: {},
        }
    }

    toggleJobNotes() {
        this.setState({ isJobNotes: !this.state.isJobNotes });
    }

    submitNotes(data){
        const { submit , change } = this.props;
        change('note', '');
        submit();
    }

    renderTableItems(items) {
        if (items && items.length) {
            return items.map(item => {
                return (<tr>
                    <td title={item.name}>{item.name}</td>
                    <td title={item.hours_display}>{item.hours_display}</td>
                    <td title={item.employee_count}>{item.employee_count}</td>
                </tr>)
            })
        }
        return (
            <tr>
                <td colSpan={3}>No Records Found</td>
            </tr>
        )
    }

    renderJobNotesDetailed(items) {
      const { handleSubmit } = this.props;
        return (
        <div>
          <Form onSubmit={handleSubmit(this.submitNotes)}>
            <div>
            {items && items.length ? 
              <ul style={{paddingLeft: '15px', paddingRight: '15px'}}>
                     {items.map(item => {
                          return (<li className={styles['job-info__job-notes-li']}>
                               <div className={styles['job-info__job-notes-li--header']}>
                                  <p className={styles['job-info__job-notes-li--header--main']}>
                                      {item.user.full_name}
                                  </p>
                                  <span className={styles['job_notes']}>
                                  <p className={styles['job-info__job-notes-li--header--sub']}>
                                      {item.create_date_display}
                                  </p>
                                  <img
                                    src={closeimage}
                                    className={styles['job_notes_close_image']}
                                    alt="Close"
                                    onClick={() => {this.setState({item: item}); this.props.openDeleteNoteModal();}}
                                  />
                                  </span>
                                </div>
                               <span>
                                {item.documents && item.documents.length ?
                                  item.documents.map(doc => {
                                    return <img src={doc.file} alt="" />
                                  }) : <p />
                                }
                                </span>
                                <p>{item.notes_display}</p>
                            </li>);
                      })
                     }
              </ul>
              :
              <div className={`${styles['no-records-found']}`}>No Records Found</div>
            }
              <div className={`${styles['post_comment_container']} ${styles['post_comment_container_image']} `}>
                <div>
                <label className={`${styles['custom-file-upload']}`}>
                  <AddIcon height="35" width="35" id="add" className={styles['button-role']}/>
                  <input type="file" accept="image/*"  onChange={this.props.onImageChange}/>
                </label>
                </div>
                <div className={`${styles['comment-box']}`}>
                  <Field component={InputAtom} placeholder="Add a comment" className="w-100 h-100 pl2 br2 bn" name='note'/>
                </div>
                <button type='submit'>
                  <SendIcon height="35" width="35"/>
                </button>
              </div>
            </div>
          </Form> 
            </div>       
        )
    }

    getChart(week_hours) {
        if (!week_hours || week_hours && !week_hours.length) return { x: 'x', columns: [[], []] };

        let weekHours = [];
        let dateAxis = [];
        if (week_hours && week_hours.length) {
            weekHours = week_hours.length && week_hours.map((i) => +i.total_hours / 3600);
            dateAxis = week_hours.length && week_hours.map((i) => i.date);
            weekHours.unshift('week');
            dateAxis.unshift('x');
        }
        return {
            x: 'x',
            columns: [
                dateAxis,
                weekHours,
            ],
        };
    }

    render() {
        const { isOpen, toggle, jobItem, isJobNotes, jobSummaryTask } = this.props;

        const {
            name,
            customer_data: {
                user: { full_name, email },
                customer_name
            },
            tasks,
            employee_count,
            week_hours,
            bill_type,
            hours_display
        } = this.props.jobItem
        const chart = this.getChart(week_hours)
        /*const hoursUsed = (jobItem.actual_hours/3600)*(130/jobItem.total_budget_hour);*/
        /*const budgetHours = 130 - hoursUsed; */      
        /*const profit = jobItem.billed_amount - jobItem.labour_cost;*/
        /*const labourRatio = (jobItem.labour_cost/jobItem.billed_amount) * 100;*/
        // Separate styles for quotedRecurring/Project and Time and Materials (would have Graph)
        return (
            <Modal isOpen={isOpen} toggle={() => toggle({})} size="lg" className={`${styles['job-info']} job-info-modal-height`}>
                    <div className={styles['job-info__header']}>
                        <div className={this.props.manageJob ? styles['job-info__header--manage-job-title'] :styles['job-info__header--title']}>
                            {
                                isJobNotes ?
                                    <span>
                                        <h7 style={{fontSize : '23px'}}>View Job Notes</h7>
                                    </span>
                                    :
                                    <span id="name" className={styles['job-name']}>{name}<UncontrolledTooltip placement="bottom" target="name">{name}</UncontrolledTooltip></span>
                            }
                        </div>
                        {this.props.startDate && this.props.endDate ?
                            <div className={`${styles['job-info__header--dates']}`}>
                                <span><b>From</b> - {this.props.startDate}</span>
                                <span><b>To&nbsp;</b> {this.props.endDate}</span>
                            </div>
                            :
                            null
                        }
                        <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle({})} />
                    </div>
                    <ModalBody className={styles['custom-modal-body']}>
                        { this.props.jobNotesLoader ? 
                            <SectionLoaderAtom active/>
                            :
                            <div>
                                <div className={isJobNotes ? styles['job-info__body--hidden'] : styles['job-info__body']}>
                                {/* Left Column */}
                                <div className={styles['job-info__left']}>
                                    <div className={styles['job-info__customer-info']}>
                                        <p><b>Customer name: </b> <span id="customer_name">{customer_name}</span> <UncontrolledTooltip placement="bottom" target="customer_name">{customer_name}</UncontrolledTooltip></p>
                                        <p><b>Point of contact: </b> <span id="point_of_contact">{full_name}</span> <UncontrolledTooltip placement="bottom" target="point_of_contact">{full_name}</UncontrolledTooltip></p>
                                        <p><b>Email: </b> {email}</p>
                                        <div className={styles['job-info__customer-info--dates']}>
                                            <p style={{width:'50%'}}><b>Start date: </b> {jobItem.start_date_display}</p>
                                            {jobItem.end_date_display &&
                                              <p style={{width: '48%','padding-left': '0.35rem'}}><b>End date: </b>{jobItem.end_date_display}</p>
                                            }
                                        </div>
                                    </div>
                                    <div className={styles['job-info__graph']}>
                                        {bill_type ===  "TIME_AND_MATERIAL" &&
                                            <C3Chart
                                                data={chart}
                                                size={{ height: 200, width: 250 }}
                                                color={{ pattern: ['#162d6e'] }}
                                                axis={{
                                                    y: {
                                                        label: {
                                                            text: 'weekly hours',
                                                            position: 'outer-middle',
                                                        },
                                                    },
                                                    x: {
                                                        type: 'timeseries',
                                                        tick: {
                                                            format: '%d-%m',
                                                            rotate: 65,
                                                            multiline: false
                                                        },
                                                    }
                                                }}
                                            />
                                        }
                                    </div>
                                    {(bill_type === "QUOTED") &&
                                        <div>
                                            <div className={styles['job-info__box-group-quoted']}>
                                                <span>
                                                    <h3> {jobItem.bill_type === 'QUOTED'  && jobItem.job_type === 'PROJECT'
                                                      ?  (jobItem.total_budget_hour || '0')
                                                      :  (jobItem.budgeted_hours || '0')
                                                      }
                                                    </h3>
                                                    <p>{jobItem.job_type === 'PROJECT' ? "Total Budgeted Hours" : "Budgeted Hours"}</p>
                                                </span>
                                                <span>
                                                    <h3> 
                                                         {jobItem.job_type === 'PROJECT'
                                                          ?     <span> $ {jobItem.total_quoted_amount ? (jobItem.total_quoted_amount).toFixed(2) : '0'}</span>
                                                          :     jobItem.actual_hours ? (jobItem.actual_hours/3600).toFixed(2) : '0'
                                                        }
                                                    </h3>
                                                    <p> {jobItem.job_type === 'PROJECT' ? "Total Quoted Amount" : "Actual Hours" }</p>
                                                </span>
                                            </div>

                                            <div className={styles['job-info__box-group-quoted']}>
                                                <span>
                                                    <h3>$ {jobItem.profit_variance ? (jobItem.profit_variance).toFixed(2) : '0'}</h3>
                                                    <p>Profit Variation</p>
                                                </span>
                                                <span>
                                                    <h3>$ {jobItem.labour_cost ? jobItem.labour_cost.toFixed(2) : '0'}</h3>
                                                    <p>Labor Cost</p>
                                                </span>
                                            </div>

                                            <div className={styles['job-info__box-group-quoted']}>
                                                <span>
                                                    {jobItem.job_type === 'RECURRING' ?
                                                      <h3>$ {jobItem.billed_amount ? (jobItem.billed_amount.toFixed(2)) : '0'}
                                                      </h3>
                                                      :
                                                      null
                                                    }
                                                    {jobItem.job_type !== 'RECURRING' ?
                                                     <h3>{jobItem.total_quoted_amount ?  (jobItem.total_quoted_amount.toFixed(2)) : '0'}</h3>
                                                      :
                                                      null
                                                    }
                                                    <p>Quoted Amount</p>
                                                </span>
                                                <span>
                                                    <h3>{ jobItem.labour_ratio ? (jobItem.labour_ratio).toFixed(2) : '0'} %</h3>
                                                    <p>Labour Ratio</p>
                                                </span>
                                            </div>
                                        </div>
                                    }

                                    {bill_type === "TIME_AND_MATERIAL" &&
                                        <div>
                                            <div className={styles['job-info__box-group']}>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>0</h3>
                                                    <p>Budgeted Hours</p>
                                                </span>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>$ 0</h3>
                                                    <p>Projected Bill Amount</p>
                                                </span>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>$ 0</h3>
                                                    <p>Profit Variation</p>
                                                </span>
                                            </div>

                                            <div className={styles['job-info__box-group']}>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>$ {jobItem.labour_cost ? jobItem.labour_cost.toFixed(2) : '0'}</h3>
                                                    <p>Labor Cost</p>
                                                </span>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>$ {jobItem.billed_amount ? jobItem.billed_amount.toFixed(2) : '0'}</h3>
                                                    <p>Quoted Amount</p>
                                                </span>
                                                <span className={styles['job-info__box-group--box']}>
                                                    <h3>0 %</h3>
                                                    <p>Labour Ratio</p>
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {/* Right Column */}
                                <div className={styles['job-info__right']}>
                                    <h6>
                                        {jobItem.bill_type === 'QUOTED' && jobItem.job_type ==='RECURRING' && 'QUOTED RECURRING'}
                                        {jobItem.bill_type === 'QUOTED' && jobItem.job_type ==='PROJECT' && 'QUOTED PROJECT'}
                                        {jobItem.bill_type === 'TIME_AND_MATERIAL' && 'TIME AND MATERIAL'}
                                    </h6>
                                    <div className={styles['job-info__tasks-table']}>
                                        <table>
                                            <thead>
                                                <tr style={{height: '25px'}}>
                                                    <th>Task</th>
                                                    <th>Hours</th>
                                                    <th>Employees</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.renderTableItems(jobSummaryTask)
                                                }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className={styles['job-info__job-type']}>
                                        <span className={styles['job-info__job-type--icon-container']}>
                                            <span className={styles['job-info__job-type--img-box']}>
                                                <img src={clockImage} width={40} height={40} alt=""/>
                                                <span>
                                                    <p className={styles['job-info__job-type--title']}>{hours_display ? hours_display : '0'}</p>
                                                    <p>Total Hours</p>
                                                </span>
                                            </span>
                                            <span className={styles['job-info__job-type--img-box']}>
                                                <img src={userImage} width={40} height={40} alt="" />
                                                <span>
                                                    <p className={styles['job-info__job-type--title']}>{employee_count}</p>
                                                    <p>Employees</p>
                                                </span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                </div>

                                <div className={isJobNotes ? styles['job-info__job-notes-view'] : styles['job-info__job-notes-view--hidden']}>
                                    {
                                        this.renderJobNotesDetailed(this.props.jobNotes)
                                    }
                                </div>
                            </div>
                        }
                        {this.props.image!== null &&
                          <JobNoteImageModal
                            image={this.props.image}
                            submit={this.props.submitImageAndComment}
                            onSubmit={this.props.submitImageAndComment}
                            showImageModal={this.props.showImageModal}
                            toggleImageModal={this.props.toggleImageModal}
                          />
                        }
                        { this.props.deleteNotesModal &&
                          <DeleteNotesConfirmationModal
                            isOpen={this.props.deleteNotesModal}
                            item={this.state.item}
                            toggle={this.props.toggleDeleteNotesModal}
                            deleteNotes={this.props.deleteNotes}
                            refreshLoader={this.props.enabledDeleteLoader}
                          />
                        }
                    </ModalBody>
            </Modal>
        )
    }
}



export default reduxForm({
  form: 'jobInfoForm',
})(JobInfoModal);

