import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { UncontrolledTooltip } from "reactstrap";

import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as ReportDucks from "ducks/reports/report";
import * as RouteDucks from "ducks/routes";
import * as UserDucks from "ducks/accounts/user";
import * as DepartmentDucks from "ducks/vendors/department";
import * as TeamDucks from "ducks/vendors/team";
import * as EmployeeDucks from "ducks/employees/employee";
import StorageGateway from "lib/storage-gateway";

import GeneralAuditReportFilterFormComponent from "components/reports/generalAuditReport/FilterForm";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import FilterDropdownComponent from "components/common/FilterDropdown";
import FormikMultiSelectAtom from "atoms/FormikMultiselectDropdown";

import { REPORT_KEY_MAPPINGS } from "constants/reports";

/**
 * ReportDetailPage -> GeneralAuditReportFilterSection
 *
 * Components:
 *    - {@link GeneralAudiReportFilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - downloadReportData
 */
let company_id = "";
let nre_company_id = "";
class GeneralAuditReportFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
    this.filterEmployees = this.filterEmployees.bind(this);
    this.state = { isLoading: false, employeesList: [], changedByOptions: [] };
  }

  async componentDidMount() {
    company_id = StorageGateway.get("company_id");
    nre_company_id = StorageGateway.get("nre_company_id");
    await this.props.EmployeeDucks.getChangedBy({
      paginate: false,
      company_id: company_id || nre_company_id,
      order_by: "name",
    });
    const changedByOptions = this.props?.changedByList?.results.map((item) => ({
      id: item.id,
      label: `${item?.first_name} ${item?.last_name}`,
    }));
    this.setState({ changedByOptions });

    await this.props.EmployeeDucks.getEmployees({ paginate: false });
    const employeesList = this.props.employeeList.results.map((item) => ({
      id: item.id,
      label: item.user.full_name,
    }));
    this.setState({ employeesList });
  }

  filterEmployees(params) {
    const { department_id } = params;
    this.props.EmployeeDucks.getEmployees({
      department_id,
      paginate: false,
    }).then((item) => {
      const employeesList = item.value.map((item) => ({
        id: item.id,
        label: item.user?.full_name,
      }));
      this.setState({ employeesList });
    });
  }
  export() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    this.setState({ isLoading: true });

    return this.props.ReportDucks.downloadReportData({ ...query, ...params })
      .then((data) => {
        const date = moment().format("DD/MM/YYYY");
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = (evx) => {
            const myBlob = new Blob([data.action.payload], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            window.navigator.msSaveOrOpenBlob(
              myBlob,
              `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`
            );
          };
          a.click();
        }
        // chrome ,safari and mozilla browsers
        else {
          const myBlob = new Blob([data.action.payload], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`;
          element.click();
        }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      dateFormat,
      userCompany,
      location,
      departmentAll,
      userExtraData,
      teamAll,
      onDepartmentSelect,
      onActivitySelect,
      onEmployeeSelect,
    } = this.props;
    const { query } = location;
    const formattedDates = {};
    const { isLoading, employeesList, changedByOptions } = this.state;
    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      departmentOptions =
        departmentAll &&
        departmentAll.map((item) => ({
          value: item.id,
          label: item.name,
        }));
    }

    if (!userExtraData.department) {
      departmentOptions.splice(0, 0, { value: "", label: "All Departments" });
    }

    if (teamAll && teamAll.length) {
      teamOptions =
        teamAll &&
        teamAll.map((item) => ({
          value: item.id,
          label: item.name,
        }));
    }

    if (!userExtraData.team) {
      teamOptions.splice(0, 0, { value: "", label: "All Teams" });
    }

    if (query.start_date)
      formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date)
      formattedDates.end_date = moment(query.end_date).toISOString();
    return (
      <Row className="report-filter ml-3 mr-4">
        <Col md={10} className="px-0">
          <section>
            {departmentOptions.length > 0 && (
              <div
                id="department"
                className="d-inline-block department-index department-dropdown reportdropdown reportdropdown-override"
              >
                <FilterDropdownComponent
                  paramKey="department_id"
                  location={location}
                  options={departmentOptions}
                  onChange={(val) => {
                    onDepartmentSelect(val);
                    this.filterEmployees({ department_id: val });
                  }}
                />
                <UncontrolledTooltip placement="top" target="department">
                  Select Department
                </UncontrolledTooltip>
              </div>
            )}
            {teamOptions.length > 0 && (
              <div
                id="team"
                className="d-inline-block department-dropdown reportdropdown reportdropdown-override"
              >
                <FilterDropdownComponent
                  paramKey="team_id"
                  location={location}
                  options={teamOptions}
                />
                <UncontrolledTooltip placement="top" target="team">
                  Select Team
                </UncontrolledTooltip>
              </div>
            )}

            <div
              id="changedBy"
              className="d-inline-block department-dropdown reportdropdown reportdropdown-override"
            >
              <FormikMultiSelectAtom
                id="ChangedBy"
                name="changedBy"
                items={changedByOptions}
                placeholder="Changed By"
                itemToString={(i) => i && i.label}
                onChange={(val) => onActivitySelect(val)}
              />

              <UncontrolledTooltip placement="top" target="changedBy">
                Changed By
              </UncontrolledTooltip>
            </div>

            <div
              id="employee"
              className="ml-2 d-inline-block department-dropdown reportdropdown reportdropdown-override"
            >
              <FormikMultiSelectAtom
                id="employees"
                name="employees"
                items={employeesList}
                placeholder="Select Employees"
                itemToString={(i) => i && i.label}
                onChange={(data) => onEmployeeSelect(data)}
              />
              <UncontrolledTooltip placement="top" target="employee">
                Select Employee
              </UncontrolledTooltip>
            </div>
          </section>
          <GeneralAuditReportFilterFormComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={{
              ...query,
              company_id: userCompany.id,
              ...formattedDates,
            }}
          />
        </Col>
        <Col md={2} className="px-0">
          {Object.keys(query).length > 0 && query.start_date && (
            <section className="float-right">
              <Button color="accent" className="mr-0" onClick={this.export}>
                Export <ButtonLoaderAtom active={isLoading} />
              </Button>
            </section>
          )}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
  datas: ReportDucks.reportData(state),
  userCompany: UserDucks.userCompany(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  employeeList: EmployeeDucks.employeeList(state),
  changedByList: EmployeeDucks.changedByList(state),
});

const mapActionsToProps = (dispatch) => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

GeneralAuditReportFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
};

GeneralAuditReportFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(GeneralAuditReportFilterSection);
