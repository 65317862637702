import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Link from 'react-router-dom/Link';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import EmployeeProificiencyTableComponent from 'components/reports/employeeProficiencyReport/Table';
import moment from 'moment';

class EmployeeProficiencyListSection extends React.Component {

  constructor(props){
    super(props);
    this.navigation = this.navigation.bind(this);
  }

  navigation(item){
    const { location : { pathname, query } } = this.props;
    const parentObj = {
      parent: `${pathname}?${queryString.stringify(query)}`,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date:  moment(query.end_date).format("YYYY-MM-DD"),
      payroll_id: 'custom',
      employee_id: item.id,
      first_name: item.employee_name.trim().split(" ")[0] ?? "",
      last_name: item.employee_name.trim().split(" ")[1] ?? "",
      custom : true,
      show_employee_list : false,
      empployee_dept_name: item?.department_name,
      employee_team_name: item?.team_name,
      employee_title: item?.employee_title,
      employee_id_show: item?.employee_id,
    };
    const url = `/timecard/custom/list/${item.id}/daily?${queryString.stringify(parentObj)}`;
    return window.open(url, '_blank');
  }

  render() {
    const { data, isLoading, location } = this.props;
    const {query} = location;
    const cookieData = cookie.load("employee_proficiency_detail_page_size");
    let pageSize;

    if (query && query.page_size)  pageSize = +query.page_size;
    else if(cookieData) pageSize = +cookieData;
    else pageSize = 10;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <EmployeeProificiencyTableComponent
          data={data}
          location={location}
          pageSize={pageSize}
          navigation={this.navigation}
        />
        <PaginationComponent
          count={data.length}
          location={location}
          cookieKey="employee_proficiency_detail_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state), 
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
});

const mapActionsToProps = dispatch => ({
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
});

EmployeeProficiencyListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

EmployeeProficiencyListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};


export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EmployeeProficiencyListSection);

