import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import ActivitySplitTimelineSection from 'sections/activities/activitySplitForm/Timeline';

import { NAV } from 'constants/activities';

/**
 * ActivitySplitFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ActivitySplitTimelineSection}
 *
 * Fetch Needs:
 *    None
 */
class CustomActivitySplitFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, parent: NAV.CustomActivitySplitFormPage.parent, isInfoOpen: true };
  }

  componentWillMount() {
    const cookieKey = cookie.load("custom_split_form_info");

    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'activity.split.add'});
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
    this.props.ActivitySplitDucks.resetActivitySplits();
  }


  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location } = this.props;
    const { state } = location;
    let parent;
    if (state) parent = state;
    else if ( query.split_id ) parent = `/timecard/custom/list/${query.employee}/daily`;
    else parent = `/activity/timecard/custom/list/${params.id}/split`;
    
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        this.setState({
          parent: `${parent}?${queryString.stringify(parsedParams)}`,
        });
      }
    } catch (e) {
      this.setState({ parent });
    }
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query }} = this.props;
    this.setState({ isLoading: true });
    this.setParentUrl(query);
    return this.props.ActivitySplitDucks.getActivitySplits({ id: params.id })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("activities_split_form_info", !isInfoOpen);
  }

  render() {
    const { isLoading, parent, isInfoOpen } = this.state;
    const { infoList, location: { query } } = this.props;
    let queryParent = {};
    try{
      queryParent = JSON.parse(query.parent);
    }
    catch(e){
      queryParent = {};
    }

    let breadcrumb;
    if(query.split_id){
      breadcrumb = [
          ...NAV.CustomActivitySplitListPage.breadcrumb,
          { href: parent, label: 'Daily Timecards' },
          { label: 'new' },
        ];
    }
    else{
      breadcrumb = [
          ...NAV.CustomActivitySplitListPage.breadcrumb,
          { href: `/timecard/custom/list/${queryParent.employee_id}/dailyV0?end_date=${queryParent.end_date}&start_date=${queryParent.start_date}&employee_id=${queryParent.employee_id}&custom=true&payroll_id=${queryParent.payroll_id}&first_name=${queryParent.first_name}&last_name=${queryParent.last_name}`, label: 'Daily Timecards' },
          { href: parent, label: 'Activities' },
          { label: 'new' },
        ];
    }

    const description = query.is_approved ==='true' 
      ? `${NAV.ViewCustomActivitySplitFormPage.description}`
      : NAV.CustomActivitySplitFormPage.description;
    const title = query.is_approved ==='true' 
      ? `${NAV.ViewCustomActivitySplitFormPage.title}`
      : NAV.CustomActivitySplitFormPage.title;


    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.CustomActivitySplitFormPage, parent, breadcrumb, description, title,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <ActivitySplitTimelineSection isLoading={isLoading}/>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

CustomActivitySplitFormPage.propTypes = {
  location: PropTypes.object.isRequired,
  ActivitySplitDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

CustomActivitySplitFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomActivitySplitFormPage);
