import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as TeamDucks from 'ducks/vendors/team';


import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import VendorDepartmentFormModalComponent from 'components/vendors/departmentList/FormModal';
import VendorTeamFormModalComponent from 'components/vendors/departmentList/TeamFormModal';
import VendorDepartmentListTableComponent from 'components/vendors/departmentList/Table';

import { MSGS } from 'constants/vendors';

/**
 * VendorDepartmentListPage -> VendorDepartmentListSection
 *
 * Components:
 *    - {@link VendorDepartmentListTableComponent}
 *
 * State:
 *    - departmentList
 *
 * Actions:
 *    None
 */
class VendorDepartmentListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isDepartmentOpen: false, isTeamOpen: false};
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.toggleDeptForm = this.toggleDeptForm.bind(this);
    this.toggleTeamForm = this.toggleTeamForm.bind(this);
  }

  toggleDeptForm(data) {
    const { isDepartmentOpen } = this.state;
    if (isDepartmentOpen) return this.setState({ isDepartmentOpen: !isDepartmentOpen });
    return this.props.DepartmentDucks.getDepartment(data)
      .then(() => this.setState({ isDepartmentOpen: !isDepartmentOpen }));
  }


  toggleTeamForm(data) {
    const { isTeamOpen } = this.state;
    if (isTeamOpen) return this.setState({ isTeamOpen: !isTeamOpen });
    return this.props.TeamDucks.getTeam(data)
      .then(() => {
        this.props.DepartmentDucks.getAllDepartments({paginate: false});
        this.setState({ isTeamOpen: !isTeamOpen });
      });
  }

  createOrUpdate(data, type) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    if (type === "department") {
      return this.props.DepartmentDucks.putDepartment(data)
        .then(() => {
          toast.success(MSGS.VENDOR_DEPARTMENT_UPDATE_SUCCESS);
          this.props.DepartmentDucks.getDepartments({ ...params, ...query});
          this.toggleDeptForm();
          history.push('/vendor/department');
        });
    }else if (type === "team") {
      return this.props.TeamDucks.putTeam(data)
        .then(() => {
          toast.success(MSGS.VENDOR_TEAM_UPDATE_SUCCESS);
          this.props.DepartmentDucks.getDepartments();
          this.toggleTeamForm();
          history.push('/vendor/department');
        });
    }
    return null;
  }



  render() {
    const {
      isLoading,
      location,
      departmentList,
      departmentDetail,
      payrollSettingAll,
      teamDetail,
      departmentAll,
      payrollAll,
      toggleIsLoading
    } = this.props;
    const { isDepartmentOpen, isTeamOpen } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <VendorDepartmentListTableComponent
          data={departmentList}
          toggleDeptForm={this.toggleDeptForm}
          toggleTeamForm={this.toggleTeamForm}
          deleteDepartment={this.props.deleteDepartment}
          deleteTeam={this.props.deleteTeam}
          saveDepartmentTeamOrderPreference = {this.props.saveDepartmentTeamOrderPreference}
          departmentIdsList={this.props.departmentIdsList}
          teamIdsList={this.props.teamIdsList}
          getTeamIds = {this.props.getTeamIds}
          getDepartmentIds = {this.props.getDepartmentIds}
          toggleIsLoading = {toggleIsLoading}
        />
        <PaginationComponent
          count={departmentList.count}
          location={location}
          cookieKey="department_page_size"
        />
        {isDepartmentOpen &&
          <VendorDepartmentFormModalComponent
            isOpen={isDepartmentOpen}
            toggleDeptForm={this.toggleDeptForm}
            createOrUpdate={this.createOrUpdate}
            initialValues={departmentDetail}
            payrollSettingAll={payrollSettingAll.length > 0 ? payrollSettingAll: null}
            payrollAll={payrollAll}
          />
        }
        {isTeamOpen &&
          <VendorTeamFormModalComponent
            isOpen={isTeamOpen}
            toggleTeamForm={this.toggleTeamForm}
            createOrUpdate={this.createOrUpdate}
            initialValues={teamDetail}
            departmentAll={departmentAll}
          />
        }
      </section>
    );
  }
}

const mapStateToProps = state => ({
  departmentList: DepartmentDucks.departmentList(state),
  departmentDetail: DepartmentDucks.departmentDetail(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  payrollSettingAll: PayrollSettingDucks.payrollSettingAll(state),
  payrollAll: PayrollDucks.payrollAll(state),
  location: RouteDucks.location(state),
  teamDetail: TeamDucks.teamDetail(state),
});

const mapActionsToProps = dispatch => ({
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

VendorDepartmentListSection.propTypes = {
  location: PropTypes.object.isRequired,
  departmentList: PropTypes.object.isRequired,
  departmentDetail: PropTypes.object.isRequired,
  departmentAll: PropTypes.array.isRequired,
  payrollSettingAll: PropTypes.array.isRequired,
  teamDetail:PropTypes.object.isRequired,
};

VendorDepartmentListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorDepartmentListSection);
