import React from 'react';

import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import ReportDashboardPage from './Dashboard';
import ReportDetailPage from './Detail';
import ReportDataDetailPage from './DataDetail';

const ReportRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={match.url}
        name="report.dashboard"
        component={ReportDashboardPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/:code/detail`}
        name="report.detail"
        component={ReportDetailPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/:code/detail/:detailId`}
        name="report.data_detail"
        component={ReportDataDetailPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

ReportRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ReportRoutes;
