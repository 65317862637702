import React from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as ReportDucks from "ducks/reports/report";
import * as RouteDucks from "ducks/routes";
import * as PayrollDucks from "ducks/payrolls/payroll";
import * as JobDucks from "ducks/jobs/job";
import * as DepartmentDucks from "ducks/vendors/department";
import * as TeamDucks from "ducks/vendors/team";
import * as UserDucks from "ducks/accounts/user";
import * as EmployeeDucks from "ducks/employees/employee";
import cookie from "react-cookies";

import TwoColumnLayout from "layouts/TwoColumn";

import ManualCorrectionSummaryFilterSection from "sections/reports/manualCorrectionSummary/Filter";
import ManualCorrectionSummaryListSection from "sections/reports/manualCorrectionSummary/List";
import MealViolationFilterSection from "sections/reports/mealViolation/Filter";
import MealViolationListSection from "sections/reports/mealViolation/List";
import OvertimeSummaryFilterSection from "sections/reports/overtimeSummary/Filter";
import OvertimeSummaryListSection from "sections/reports/overtimeSummary/List";
import EmployeeDailyTimecardFilterSection from "sections/timecards/customDailyTimecardList/Filter";
import EmployeeDailyTimecardListSection from "sections/reports/employeeDailyTimecard/List";
import GeoFenceBreachFilterSection from "sections/reports/geoFenceBreach/Filter";
import GeoFenceBreachListSection from "sections/reports/geoFenceBreach/List";

import CorrectionRequestSummaryFilterSection from "sections/reports/correctionRequestReport/Filter";
import CorrectionRequestSummaryListSection from "sections/reports/correctionRequestReport/List";

import MealDetailByEmployeeListSection from "sections/reports/mealFlagDetails/ListByEmployees";
import MealDetailByEmployeeFilterSection from "sections/reports/mealFlagDetails/Filter";

import GeneralAuditReportFilterSection from "sections/reports/generalAuditReport/Filter";
import GeneralAuditReportListSection from "sections/reports/generalAuditReport/List";

import EmployeeProficiencyReportFilterSection from "sections/reports/employeeProficiencyReport/Filter";
import EmployeeProficiencyReportListSection from "sections/reports/employeeProficiencyReport/List";

import { NAV, REPORT_KEY_MAPPINGS } from "constants/reports";

/**
 * ReportDetailPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ReportDetailSection}
 *
 * Fetch Needs:
 *    - getReport
 *    - getReportData
 */

class ReportDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.onActivitySelect = this.onActivitySelect.bind(this);
    this.onEmployeeSelect = this.onEmployeeSelect.bind(this);
    this.state = {
      isLoading: false,
      parent: null,
    };
  }

  componentDidMount() {
    const {
      router: {
        history,
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    if (params.code === "EMPLOYEE-WISE-DAILY-TIMECARDS") {
      if (!("payroll_id" in query)) {
        history.push({
          pathname,
          search: queryString.stringify({
            ...query,
            payroll_id: "custom",
            department_id: "",
            team_id: "",

            changed_by: "",
            employee_ids: "",
          }),
        });
      }
    }
    this.setParentUrl(query);
    this.props.ReportDucks.resetReportData();
    if (Object.keys(query).length > 1) this.fetchData({ ...query, ...params });
    this.props.PayrollDucks.getAllPayrolls({
      valid: true,
      paginate: false,
      default: true,
    });
    this.props.JobDucks.getJobsOfEmployee({ paginate: false, admin: true });
    this.props.ReportDucks.getReport(params);

    this.props.DepartmentDucks.getAllDepartments({ paginate: false });
    this.props.TeamDucks.getAllTeams({
      paginate: false,
      department_id: query.department_id,
    });
    this.props.EmployeeDucks.getEmployees({ paginate: false });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (
      prevQuery.department_id !== nextQuery.department_id &&
      nextQuery.department_id
    ) {
      this.props.PayrollDucks.getPayrollsOfDepartment({
        valid: true,
        paginate: false,
        department_id: nextQuery.department_id,
      });
    }
    if (prevQuery !== nextQuery && Object.keys(nextQuery).length) {
      this.fetchData({ ...nextQuery, ...params });
    }
  }

  componentWillUnmount() {
    this.props.ReportDucks.resetReportData();
  }

  setParentUrl(params) {
    try {
      if (params.parent_url) {
        this.setState({ parent: params.parent_url });
      } else {
        this.setState({ parent: "/report" });
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(params) {
    let jobIds;
    let parameter = { ...params };
    const {
      location: { query },
    } = this.props;
    if (!parameter.department_id) {
      delete parameter.department_id;
    }
    if (!parameter.team_id) delete parameter.team_id;
    if (parameter.payroll_id && parameter.payroll_id !== "custom") {
      delete parameter.start_date;
      delete parameter.end_date;
    }
    if (parameter.payroll_id === "custom" || !parameter.payroll_id)
      delete parameter.payroll_id;
    if (params.start_date && params.end_date) {
      this.setState({ isLoading: true });
      if (!params.job_ids && params.jobs) {
        const parsedJobArr = JSON.parse(params.jobs);
        jobIds = parsedJobArr.map((item) => item.id);
        parameter = { ...parameter, job_ids: JSON.stringify(jobIds) };
      }
      return this.props.ReportDucks.getReportData(parameter)
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  onDepartmentSelect(id) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const newQuery = { ...query };
    if (+query.department_id === id || query.department_id === id) {
      return true;
    }
    delete newQuery.team_id;
    delete newQuery.payroll_id;
    this.props.TeamDucks.getAllTeams({ department_id: id, paginate: false });

    return history.push({
      pathname,
      search: queryString.stringify({ ...newQuery, department_id: id }),
    });
  }

  onActivitySelect(data) {
    let changed_by_id = data.map((item) => item.id);
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const newQuery = { ...query };

    return history.push({
      pathname,
      search: queryString.stringify({
        ...newQuery,
        changed_by: `'${changed_by_id}'`,
      }),
    });
  }

  onEmployeeSelect(data) {
    let employee_ids = data.map((item) => item.id);
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const newQuery = { ...query };

    return history.push({
      pathname,
      search: queryString.stringify({
        ...newQuery,
        employee_ids: `'${employee_ids}'`,
      }),
    });
  }

  render() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    const { isLoading, parent } = this.state;
    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.ReportDetailPage,
          breadcrumb: [
            ...NAV.ReportDetailPage.breadcrumb,
            { label: REPORT_KEY_MAPPINGS[params.code] },
          ],
          title: REPORT_KEY_MAPPINGS[params.code],
          parent,
        }}
        fetchData={() => this.fetchData({ ...query, ...params })}
      >
        {(() => {
          switch (params.code) {
            case "MEAL-VIOLATION":
              return (
                <div>
                  <MealViolationFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <MealViolationListSection isLoading={isLoading} />
                </div>
              );

            case "OVERTIME-SUMMARY":
              return (
                <div>
                  <OvertimeSummaryFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <OvertimeSummaryListSection isLoading={isLoading} />
                </div>
              );

            case "EMPLOYEE-WISE-DAILY-TIMECARDS":
              return (
                <div>
                  <EmployeeDailyTimecardFilterSection 
                  onDepartmentSelect={this.onDepartmentSelect} type={"Report"} />
                  <EmployeeDailyTimecardListSection isLoading={isLoading} />
                </div>
              );

            case "GEO-FENCE-BREACH-SUMMARY":
              return (
                <div>
                  <GeoFenceBreachFilterSection />
                  <GeoFenceBreachListSection isLoading={isLoading} />
                </div>
              );

            case "MEAL-FLAG-DETAIL":
              return (
                <div>
                  <MealDetailByEmployeeFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <MealDetailByEmployeeListSection isLoading={isLoading} />
                </div>
              );

            case "CORRECTION-REQUEST-REPORT":
              return (
                <div>
                  <CorrectionRequestSummaryFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <CorrectionRequestSummaryListSection isLoading={isLoading} />
                </div>
              );

            case "GENERAL-AUDIT-REPORT":
              return (
                <div>
                  <GeneralAuditReportFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                    onActivitySelect={this.onActivitySelect}
                    onEmployeeSelect={this.onEmployeeSelect}
                    selectedActivityType={this.state.selectedActivityType}
                  />
                  <GeneralAuditReportListSection
                    isLoading={isLoading}
                    selectedActivityType={this.state.selectedActivityType}
                  />
                </div>
              );

            case "EMPLOYEE-PROFICIENCY-REPORT":
              return (
                <div>
                  <EmployeeProficiencyReportFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <EmployeeProficiencyReportListSection isLoading={isLoading} />
                </div>
              );

            // Manual Correction Summary
            default:
              return (
                <div>
                  <ManualCorrectionSummaryFilterSection
                    onDepartmentSelect={this.onDepartmentSelect}
                  />
                  <ManualCorrectionSummaryListSection isLoading={isLoading} />
                </div>
              );
          }
        })()}
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = (dispatch) => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

ReportDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
  ReportDucks: PropTypes.object.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
};

ReportDetailPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(ReportDetailPage);
