import React from 'react';
import { Popover } from 'reactstrap';
import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';
import styles from './styles.module.scss';

import EditIcon from 'components/jobs/jobGeofence/Edit.png';
import DeleteIcon from 'components/jobs/jobGeofence/Delete.png';
import SectionLoaderAtom from 'atoms/SectionLoader';
import closeimage from './close.png';
/**
 * JobTaskMappingListPage -> JobTaskMappingListSection -> JobTaskMappingTableComponent
 *
 * Props:
 *    - task list
 */
const JobTaskMappingTableComponent = (props, context) => {
  const { updateSelected, location, data, toggle, deleteTask,
    isTaskTableLoading, actionsRequired, associatedJob, isAssociated, togglePop,jobsAssociated,deleteAssociatedJob} = props;
  const { router: { route: { match } } } = context;
  const { query } = location;
  if(isTaskTableLoading) return <SectionLoaderAtom active/>;
  return (
   <div className="ml-3 mr-4">
      <Table responsive hover className={match.params.id ? "job-table-task mt-3" : "job-table-task"}
      style={{ boxShadow: "0px 3px 6px rgba(0,0,0,0.16)" }}>
      <thead>
        <tr className={styles.header_color}>
          {!query.setup &&   <th className="pl-3"> </th> }
          <th>&nbsp;</th>
          {query &&query.view === 'false' && query.assigned !== '' ? <th className="text-center">Action</th> : null }
          { !query.job_id && !actionsRequired && <th className="text-center"> Action </th>}
          <th>&nbsp;</th>
          <th className="text-left">Task Code</th>
          <th>&nbsp;</th>
          <th className="text-left">Task Name</th>
          <th>&nbsp;</th>
          <th className="text-left">Description</th>
          <th>&nbsp;</th>
          <th className="text-center">Associated Jobs</th>
          <th>&nbsp;</th>
          <th className="text-left">Tags</th>
          <th>&nbsp;</th>
          <th className="text-right">Hourly Bill Rate</th>
          <th>&nbsp;</th>
          <th className="text-right">Employee Fringe %</th>
          <th>&nbsp;</th>
          <th className="text-left pr-3">Billable</th>
        </tr>
      </thead>
      <tbody>
        {data && data.results.length ? data.results.map(item => {
          const tagNames = item.tags.map(tagName=>` ${tagName}`);
          const allTags=tagNames.join();
          return(
            <tr key={item.id}>
              {query && query.view === 'false' && query.assigned !== '' ?
                <td title="Select to Mark Assign/Unassign to job" className="pl-3">
                  <input
                    type="checkbox"
                    value={item.isSelected}
                    onChange={() => updateSelected(item)}
                  />
                </td>
                : null }
                <td title='Select task'  className='actionCol text-center'>
                  {!query.setup &&
                    <span>
                      <span title="Select Task" role='button' className='cursor-pointer p-1 m-1'>
                          <input
                            type="checkbox"
                            onChange={() => props.selectTask(item)}
                            checked={props.selectedTasks && props.selectedTasks.length && props.selectedTasks.find((i) => i == item.id)}
                          />
                      </span>
                    </span>
                  }
                </td>
              <td>&nbsp;</td>
              <td title="Delete task" className="text-center">
                {!query.setup &&
                  <span>
                    <span title="Edit Task" role='button' tabIndex={0} onClick={() => toggle(item)} className='cursor-pointer outline-none p-1 m-1'>
                      <img alt="" src={EditIcon} height="15" width="15" />
                    </span>
                  </span>
                }
                <span title="Delete Task"  role='button' tabIndex={0} onClick={() => deleteTask(item)} className='cursor-pointer outline-none m-1'>
                  <img alt="" src={DeleteIcon} height="15" width="15" />
                </span>
              </td>
              <td>&nbsp;</td>
              <td title={item.code} className="text-left">{item.code}</td>
              <td>&nbsp;</td>
              <td title={item.name} className="text-left">{item.name}</td>
              <td>&nbsp;</td>
              <td title={item.description} className="text-left">{item.description}</td>
              <td>&nbsp;</td>
              <td title="jobs" id={`a${item.id}`} onClick={()=>associatedJob(item)} className="associated-job text-center">View Jobs</td>
              <td>&nbsp;</td>
              <td title={item.tags && item.tags.length && item.tags.map((item)=> ` ${item}`)} className="text-left">{allTags}</td>
              <td>&nbsp;</td>
              <td className="text-right">{item.billed_rate}</td>
              <td>&nbsp;</td>
              <td className="text-right">{item.employee_fringe}</td>
              <td>&nbsp;</td>
              <td className="text-left pr-3" title={item.is_billable ? 'true' : 'false'}>
                {item.is_billable ? 'Yes' : 'No'}
              </td>
              {isAssociated &&
                <Popover placement="right" isOpen={item.id===isAssociated} target={`a${item.id}`} toggle={togglePop} className="associated-popup">
                {jobsAssociated && jobsAssociated.length ? 
                  jobsAssociated.map((item) => {
                  return(
                  <span className={styles.associated_link}>
                  <Link to={`/job/list/${item.id}/edit`} className="associated-link">{item && item.name}</Link>
                  <img alt="" src={closeimage} height="10" width="10" className={styles.close_image} onClick={()=>deleteAssociatedJob(item.id)}/>
                  </span>
                  )
                })
                :<div className={styles.no_records}>No Records Found</div>
                }
                </Popover>
              }
            </tr>
          )}) : null}
        {!data.results.length &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
      </Table>
   </div>
  );
};

JobTaskMappingTableComponent.propTypes = {
  updateSelected: PropTypes.func,
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

JobTaskMappingTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JobTaskMappingTableComponent;
