import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';

class InvoiceApiGateway {

  toString() {
    return 'InvoiceApiGateway';
  }

  async getInfoList(params) {
    const infoListConfig =  AppConfig.server.apiHost + '/t2b/documents/kw-articles-list/';
    const apiGateway = new ApiGateway(infoListConfig);
    const response = await apiGateway.load(params);
    const infoList = response.data;
    return infoList;
  }

   async getCompanyInvoiceList(params) {
    const companyInvoiceListConfig =  AppConfig.server.apiHost + '/payment/invoice/' ;
    const apiGateway = new ApiGateway(companyInvoiceListConfig);
    const response = await apiGateway.load({...params });
    const companyInvoiceList = response.data;
    return companyInvoiceList;
  
  }

  async getInvoiceDetails(invoiceId){
    const invoiceDetailConfig =  AppConfig.server.apiHost + '/payment/invoice/' +  invoiceId + '/' ;
    const apiGateway = new ApiGateway(invoiceDetailConfig);
    const response = await apiGateway.load();
    const companyInvoiceDetails = response.data;
    return companyInvoiceDetails;
  }

  async updateInvoiceDetails(data, invoiceId){
    const invoiceDetailConfig =  AppConfig.server.apiHost + '/payment/invoice/' +  invoiceId + '/' ;
    const apiGateway = new ApiGateway(invoiceDetailConfig);
    const response = await apiGateway.update(data);
    const companyInvoiceDetails = response.data;
    return companyInvoiceDetails;
  }

  async exportInvoice(params){
    const downloadInvoiceConfig =  AppConfig.server.apiHost + '/payment/invoice/' +  params + '/export/' ;
    const apiGateway = new ApiGateway(downloadInvoiceConfig);
    const response = await apiGateway.load(params);
    const data = response.data;
    return data;
  }

  async cancelCompanyPlan(companyId){
    const data = {
      company_id: companyId
    };
    const cancelPlanConfig =  AppConfig.server.apiHost + '/payment/customer/cancel/';
    const apiGateway = new ApiGateway(cancelPlanConfig);
    const response = await apiGateway.save(data);
    const plan = response.data;
    return plan;
  }

  async authenticatePassword(email, password, device) {
    const signInReqObj = {
      email: email,
      password: password,
      device: device,
    };
    const signinConfig = AppConfig.server.apiHost + '/company/login/';
    const apiGateway = new ApiGateway(signinConfig);
    const response = await apiGateway.save(signInReqObj);
    return response.data;
  }

  async getCompanyPlan(companyId){
    const companyPlanConfig =  AppConfig.server.apiHost + '/payment/billing-cycle/current/' + companyId + '/';
    const apiGateway = new ApiGateway(companyPlanConfig);
    const response = await apiGateway.load();
    const planDetails = response.data;
    return planDetails;
  }

}

export default InvoiceApiGateway;