import React, { Component } from 'react'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

class DoNotShowModal extends Component {
    constructor(props) {
        super(props);
        this.state = { checkBox: false, isLoading: false };
        this.handleCheckBox = this.handleCheckBox.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleCheckBox() {
        const { checkBox } = this.state;
        this.setState({ checkBox: !checkBox });
    }

    async onSubmit(){
        const {checkBox} = this.state;
        const { handleSubmit } = this.props;
        this.setState({isLoading: true});
        await handleSubmit(checkBox)
        this.setState({isLoading: false});
    }

    render() {
        const { checkBox, isLoading } = this.state;
        const { isOpen, message, toggle } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader className="text-warning" toggle={toggle}>Warning!</ModalHeader>
                <ModalBody>
                    {message}
                    <br />
                    <div className='mt-3 ml-4'>
                        <Input type="checkbox" value={checkBox} onChange={this.handleCheckBox} htmlId="donotshow"/>
                        <Label check className="pl-0" htmlFor="donotshow" style={{marginTop: '1px'}}>
                            Do not show this message again
                        </Label>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="warning" onClick={this.onSubmit}>
                        Submit <ButtonLoaderAtom active={isLoading} />
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default DoNotShowModal;