import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import BoyImage from './boy.png';
import ManImage from './man.png';
import Man1Image from './man1.png';
import GirlImage from './girl.png';
import Girl1Image from './girl1.png';
import styles from './styles.module.scss';


/**
 * ChangeEmailFormPage -> ChangeEmailFormSection -> ChangeEmailFormComponent
 *
 * Props:
 *    - changeEmail
 */
const NoRecordsComponent = (props, context) => {
  const { router: { history } } = context;
  return (
    <div className={`${styles['custom-white']} white-background`}>
      <div className={`${styles['main-heading']} text-center`}>
        This is where your employees will live once you add them.
      </div>
      <div className="text-center mt-3">
        <b>{`You will be able to edit, activate and deactivate all`} 
          <br />{`from this screen. It's pretty great!`}</b>
      </div>
      <Row className="mt-4">
        <Col md={3}>
          &nbsp;
        </Col>
        <Col>
          <img src={Man1Image} height="60" width="60"  alt=""/>
        </Col>
        <Col>
          <img src={GirlImage} height="60" width="60" alt=""/>
        </Col>
        <Col>
          <img src={ManImage} height="60" width="60" alt=""/>
        </Col>
        <Col>
          <img src={Girl1Image} height="60" width="60" alt=""/>
        </Col>
        <Col>
          <img src={BoyImage} height="60" width="60" alt=""/>
        </Col>
        <Col md={3}>
          &nbsp;
        </Col>
      </Row>
      <div className="text-center mt-5">
        <Button onClick={() => history.push('/employee/add')}>
          ADD EMPLOYEES NOW
        </Button>
      </div>
    </div>
  );
}

NoRecordsComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NoRecordsComponent;
