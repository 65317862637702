import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';

import * as RouteDucks from 'ducks/routes';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';


import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';

import { FILTER_OPTIONS } from 'constants/geoFences';

import ButtonGroupAtom from 'atoms/ButtonGroup';
import styles from './styles.module.scss'
import ListViewIcon from './list-view.png';
import MapViewIcon from './map-view.png';

/**
 * GeoFenceListPage -> GeoFenceFilterSection
 *
 * Components:
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - get payroll list
 */


class GeoFenceFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMapView = this.toggleMapView.bind(this);
    this.goTo = this.goTo.bind(this);
    this.state = { 
      isOpen: false, 
      listViewDisplay: true,
      GEOFENCE_VIEW_OPTIONS: [
        { value: true, icon: <img width="20px" src={ListViewIcon} alt='Map View' />, label: 'List View' },
        { value: false, icon: <img width="20px" src={MapViewIcon} alt='List View' />, label: 'Map View' },
      ]
    };
  }

  componentDidMount () {
    const { location: { query } } = this.props;
    let { GEOFENCE_VIEW_OPTIONS } = this.state;

    GEOFENCE_VIEW_OPTIONS[0].value = query.view? false: true;
    GEOFENCE_VIEW_OPTIONS[1].value = !GEOFENCE_VIEW_OPTIONS[0].value;

    this.setState({ GEOFENCE_VIEW_OPTIONS });
  }

  toggleMapView() {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;

    if (query.view) {
      const { view, ...rest } = query;
      history.push({ pathname, search: queryString.stringify({ ...rest }) });
    } else {
      history.push({
        pathname, search: queryString.stringify({ ...query, view: 'map' }),
      });
    }
  }

  goTo (url) {
    const { router: { history } } = this.context;
    const { location: { pathname, search } } = this.props;

    history.push({
      pathname: url,
      search: queryString.stringify({
        parent: `${pathname}${search}`
      })
    });
  }

  render(){
    const { listViewDisplay } = this.state;
    const { location } = this.props;
    const { query } = location;
    return (
      <Row className={ query.job_id ? "filter row ml-3 mr-4" : "filter row mt-3 ml-3 mr-4"}>
        <Col md={4} className="pl-0">
          <section className="float-left">
            <Button
              id="add_geo"
              color="primary"
              className="btn"
              onClick={() => this.goTo('/geofence/job/lists/add')}
            >
              Create New Geofence
              <UncontrolledTooltip placement="top" target="add_geo">
                Add Geofence
              </UncontrolledTooltip>
            </Button>

            <UncontrolledTooltip placement="top" target="geo-fence">
              Geofence Status
            </UncontrolledTooltip>
          </section>
        </Col>
        <Col md={8} className="pr-0">
          <Row className="float-right mr-0">
            <span id = "geo-fence" className={`${styles['custom_height']} mr-3`}>
              <FilterDropdownComponent
                paramKey="is_active"
                location={location}
                options={FILTER_OPTIONS}
                dropicon="Geofence"
              />
            </span>

            <ButtonGroupAtom
              options={this.state.GEOFENCE_VIEW_OPTIONS}
              onToggle={this.toggleMapView}
              active={listViewDisplay}
              className="mr-4"
              style={{ height: '33px' }}
            />

            <FilterFormComponent
              initialValues={query}
              location={location}
              placeholder="Name or type"
            />
          </Row>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
});

GeoFenceFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

GeoFenceFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceFilterSection);
