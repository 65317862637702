import React from 'react';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import surveyFormUseCase from 'surveys/usecases/surveyFormUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';

import SurveyFormComponent from 'surveys/components/surveyFormComponent';
import SurveyFormProgress from './surveyFormProgressBar';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import connect from 'react-redux/lib/connect/connect';

import SectionLoaderAtom from 'atoms/SectionLoader';

import { SURVEY_TYPE_OPTIONS } from 'constants/surveys';

class SurveyForm extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new surveyFormUseCase(surveyAPIGateway);
    this.listenFormUsecase = this.listenFormUsecase.bind(this);
    this.saveSurveyData = this.saveSurveyData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.state={ isLoading : true , isSaveLoaderActive:false, isNextLoaderActive: false, data: {roleList : [] ,departmentList: [], teamList:[]}}    
  }

  componentWillMount(){
    this.loadData();
  }


  loadData(){
    const { match: { params }, userExtraData } = this.props;
    const companyId = userExtraData && userExtraData.company && userExtraData.company.id;
    this.listenFormUsecase();
    this.usecase.getInitialFieldValues(companyId);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenFormUsecase() {
    const { history } = this.props;
    const { location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SET_DROPDOWN_VALUES':
          this.updateState('data', event.data);
          this.updateState('isLoading', false);
          break;
        case 'SHOW_SUBMIT_FORM_LOADER':
          if(event.data && event.data=='next'){
            this.updateState('isNextLoaderActive',true);
          }
          else{
            this.updateState('isSaveLoaderActive',true);
          }
          break;
        case 'HIDE_SUBMIT_FORM_LOADER':
          this.updateState('isNextLoaderActive',false);
          this.updateState('isSaveLoaderActive',false);
          break;
        case 'SUBMIT_SURVEY_FORM_SUCCESS':
          if(event.buttonType=='next'){
            history.push({
              pathname: `/surveys/${event.data.id}/questions`,
              search: queryString.stringify(query),
            });
          }
          else{
            history.push(`/surveys/list`);
          }
          break;
        case 'SUBMIT_SURVEY_FORM_FAILURE':
          if(event.data){
            toast.error(event.data);  
          }
          else{
            toast.error('Could not submit form.')
          }
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  async saveSurveyData(data,type){
    await this.usecase.postSurveyForm(data, type);
  }


  render() {
    const { isNextLoaderActive, isSaveLoaderActive , isLoading } = this.state;
    const { match: { params } } = this.props;
    return (
      <TwoColumnLayout
        navInfo={NAV.CreateSurveyTemplate}
        fetchData={() => this.loadData()}
      >
      {isLoading ? 
        <SectionLoaderAtom active/> 
        :
        <div className="ml-3 mr-4 pb-5">
          <SurveyFormProgress 
            stepNumber={1} 
            isStepThree={true}
          />
          <SurveyFormComponent 
            data={this.state.data}
            usecase={this.usecase}
            isNextButtonLoader={isNextLoaderActive}
            isSaveButtonLoader={isSaveLoaderActive}
            add={true}
            saveSurveyData={this.saveSurveyData}
            initialValues={{ outcome : 'scoring', mobile_check : true , kiosk_check : true , survey_type : SURVEY_TYPE_OPTIONS[6], is_active: true}}          
          />
        </div>
        }
        
      </TwoColumnLayout>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
});


export default connect(
  mapStateToProps,
)(SurveyForm);
