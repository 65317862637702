import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Collapse } from 'reactstrap';

import DateTimePickerAtom from 'atoms/DateTimePicker';
import SelectAtom from 'atoms/Select';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';
import AsyncMultiSelectDropdownAtom from 'atoms/AsyncMultiSelectDropdown';
import AsyncMultiSelectAtom from 'atoms/AsyncMultiSelect';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import ButtonGroupAtom from "atoms/ButtonGroup";
import CaretIcon from "./caret-down.png"

import { FILTER_FORM_LAYOUT, FORM_LAYOUT } from 'constants/layout';
import { FILTER_OPTIONS, BILLABLE_OPTIONS, REPORT_OPTIONS} from 'constants/jobs';
import styles from './styles.module.scss';

class JobReportsFilterFormComponent extends React.PureComponent{
  constructor(props){
    super(props);
    this.submit = this.submit.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.handleReportView = this.handleReportView.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.state={
      statusDropdownOpen: false,
      billableDropdownOpen:false,
      reportDetails:false,
      valueSummary: { value: '', label: "Details"},
      valueBillable: { value: '', label: "All"},
      value: { value: '', label: "Open"},
      reportView: "1", //Default report view "Details"
      filters: {},
      advancedView: false,
      isExporting: false,
      removedItem : null
    }
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.setAdvancedView = this.setAdvancedView.bind(this);
    this.export = this.export.bind(this);
  }

  componentDidMount(){
    const { initialValues } = this.props;
    if(Object.keys(initialValues).length){
      this.submit(initialValues);
    }
  }

  onSelection(val,num){
    if(num === 1){
      this.setState({ value: val });
    }
    else if(num === 2){
      this.setState({ valueBillable: val });
    }
    else if(num === 3){
      this.setState({ valueSummary: val} );
    }
  }

  dropdownToggle(num) {
    if(num === 1){
      this.setState(prevState => ({
        statusDropdownOpen: !prevState.statusDropdownOpen
      }));
    }
    else if(num === 2){
      this.setState(prevState => ({
        billableDropdownOpen: !prevState.billableDropdownOpen
      }));
    }
    else if(num === 3){
      this.setState(prevState => ({
        reportDetails: !prevState.reportDetails
      }));
    }
  }

  handleCheckboxChange(field, val){
    const { handleInactiveEmployees, change } = this.props;
    handleInactiveEmployees(val);
    change('employees', null);
  }

  async submit(data){
    const { router: { history } } = this.context;
    const { location: { pathname, query }, getReports } = this.props;
    const detail = {
      job_id: data.jobs && data.jobs.length ? JSON.stringify(data.jobs.map((i) => i.id)) : null,
      job_tag_name: data.job_tags && data.job_tags.length ? JSON.stringify(data.job_tags.map((i) => i.name)) : null,
      customer_tag_name: data.customer_tags && data.customer_tags.length ? JSON.stringify(data.customer_tags.map((i) => i.name)) : null,
      task_tag_name: data.task_tags && data.task_tags.length ? JSON.stringify(data.task_tags.map((i) => i.name)) : null,
      team_id: data.teams && data.teams.length ? JSON.stringify(data.teams.map((i) => i.id)) : null,
      is_billable: data.is_billable,
      employee_id: data.employees && data.employees.length ? JSON.stringify(data.employees.map((i) => i.id)) : null,
      department_id: data.departments && data.departments.length ? JSON.stringify(data.departments.map((i) => i.id)) : null,
      customer_id: data.customers && data.customers.length ? JSON.stringify(data.customers.map((i) => i.id)) : null,
      task_id: data.tasks && data.tasks.length ? JSON.stringify(data.tasks.map((i) => i.id)) : null,
      start_date: moment(data.start_date).format('YYYY-MM-DD'),
      end_date: moment(data.end_date).format('YYYY-MM-DD'),
      status: data.status && data.status.value !== '0' ? data.status.value : null,
      billable: data.billable ? data.billable.value : null,
      report_view: this.state.reportView,
      employee_tag_name : data.employee_tags && data.employee_tags.length ?  JSON.stringify(data.employee_tags.map((i) => i.name)) : null,
    };
    history.push({ pathname, search: queryString.stringify({ ...query, start_date: detail.start_date, end_date: detail.end_date, billable: detail.billable, report_view:detail.report_view, task_id:detail.task_id, job_id: detail.job_id, employee_id: detail.employee_ids})});

    if (data.isUpdateView) {
      return getReports({ ...query, ...this.state.filters, report_view: this.state.reportView });
    }

    await this.setFilters (detail);
    return getReports({...query,...detail});
  }

  async handleReportView (val) {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const { page, ...rest } = query;
    await this.setState({ reportView: val });
    this.submit({ ...this.state.filters, isUpdateView: true });
    history.push({
      pathname,
      search: queryString.stringify({
        ...rest,
        type:val === '1' ? 'detail' : 'summary',
        grouped: val  !== 1 && query.grouped ? query.grouped  : 'employee',
        report_view : val
      }),
    });
  }

  setFilters (filters) {
    this.setState({ filters });
  }

  setAdvancedView (bool) {
    this.setState({ advancedView: bool });
  }

  async export(){
    const { exportReport } = this.props;
    this.setState({ isExporting: true });
    
    try {
      const data = await exportReport();
      // Only for IE 11
      if (window.navigator.msSaveOrOpenBlob) {
        const a = document.createElement("download");
        document.body.appendChild(a);
        a.style.display = "none";
        a.onclick = ((evx) =>
        {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          window.navigator.msSaveOrOpenBlob(myBlob, 'jobReport.xlsx');
        });
        a.click();
      }
      // chrome ,safari and mozilla browsers
      else {
        const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const  url = window.URL.createObjectURL(myBlob);
        const element = document.createElement("a");
        document.body.appendChild(element);
        element.href = url;
        element.download = 'jobReport.xlsx';
        element.click();
      }
      this.setState({ isExporting: false });
    } catch (err) {
      this.setState({ isExporting: false })
      return 1
    }
    return 0
  }

  render(){
    const {
      dateFormat,
      handleSubmit,
      teamList,
      departmentList,
      onDepartmentSelect, onTeamSelect,
      employeeList,initialValues,
      jobTagList, customerTagList, taskTagList,
      customerList, getDepartments, getTeams, getJobs,
      getTasks, getCustomers, getEmployees, submitting,change, jobs, tasks, jobsAndCodesList, TasksAndCodesList,
      tagsList, selectedDepartment,
    } = this.props;

    const { advancedView, isExporting} = this.state;
    const jobCodeArray = jobsAndCodesList && jobsAndCodesList.length && jobsAndCodesList.map(i => ({ ...i, code: i.code , id: i.id }));
    const filteredJobCodeArray =   jobCodeArray && jobCodeArray.filter(i => i.code !== null);   
    const taskCodeArray = TasksAndCodesList && TasksAndCodesList.length && TasksAndCodesList.map(i => ({ ...i, code: i.code , id: i.id }));
    const filteredTaskCodeArray =   taskCodeArray && taskCodeArray.filter(i => i.code !== null); 
    const employeeTagsList = tagsList && tagsList.length && tagsList[0] && tagsList[0].tag_collection && tagsList[0].tag_collection.length && tagsList[0].tag_collection.map((item)=>({name: item,id: item}));
    return (
      <div>
        <div className={styles["filter-form__header"]}>
          <Button
            color="primary"
            onClick={() => this.export()}
            className={styles["filter-form__header--btn-export"]}>
            Export <ButtonLoaderAtom active={isExporting} />
          </Button>
          <ButtonGroupAtom
            options={REPORT_OPTIONS}
            onToggle={this.handleReportView}
            active={this.state.reportView}
            className={styles["filter-form__header--btn-detail-tab"]} />
        </div>
        <Form
          inline
          onSubmit={handleSubmit(this.submit)}
          autocomplete="off"
          className="job-report-filter-container"
        >
          <Row className={styles["filter-form__field"]}>
            <Col md={3} className="job-report-filter mt-1">
              <Field
                id="start_date"
                name="start_date"
                placeholder="Start Date"
                component={DateTimePickerAtom}
                options={{ dateFormat }}
                label="Start Date"
              />
            </Col>
            <Col md={3} className="job-report-filter mt-1">
              <Field
                id="end_date"
                name="end_date"
                placeholder="End Date"
                component={DateTimePickerAtom}
                options={{ dateFormat }}
                label="End Date"
              />
            </Col>
            <Col md={2} className="job-report-multiselect job-report-autocomplete">
              <Field
                id="jobs"
                name="jobs"
                label="Job(s)"
                component={AsyncMultiSelectAtom}
                itemToString={(i) => i && i.name}
                items={jobsAndCodesList && jobsAndCodesList.length ? jobsAndCodesList : [] }
                getData={getJobs}
                onChange={(val) => {
                  const array = Object.values(JSON.parse(JSON.stringify(val)))
                  const selectedJobIds = array && array.map(i => i.id);
                  const selectedJobCodeArray = array && array.map(i => ({...i, code: i.code , id: i.id }));
                  const filteredJobCodeArray = selectedJobCodeArray && selectedJobCodeArray.filter(i => (i.code !== undefined && i.code !== null));
                  change('code', filteredJobCodeArray);
                  selectedJobIds && selectedJobIds.length ?
                    getTasks({job_ids: JSON.stringify(selectedJobIds), paginate: false, admin: true})
                    :
                    getTasks({ paginate: false, admin: true});
                }}
                placeholder="Job(s)"
                defaultSelectedItem={initialValues && initialValues.jobs}
              />
            </Col> 
            <Col md={2} className="job-report-autocomplete job-report-multiselect">
              <Field
                id="tasks"
                name="tasks"
                label="Task(s)"
                component={MultiSelectDropdownAtom}
                itemToString={(i) => i && i.name}
                items={TasksAndCodesList && TasksAndCodesList.length ? TasksAndCodesList : []}
                placeholder="Task(s)"
                defaultSelectedItem={initialValues && initialValues.tasks}
                onChange={(val) => {
                  const array = Object.values(val);
                  const selectedJobCodeArray = array && array.map(i => ({...i, code: i.code , id: i.id }));
                  const filteredJobCodeArray = selectedJobCodeArray && selectedJobCodeArray.filter(i => (i.code !== undefined && i.code !== null));
                  change('task_code', filteredJobCodeArray);
                  /*getTasksForJob(val);*/
                }}
              />
            </Col>
            <Col md={2} className="job-report-multiselect">
              <Field
                id="employees"
                name="employees"
                label="Employee Name"
                component={AsyncMultiSelectDropdownAtom}
                itemToString={(i) => i && i.user && i.user.full_name}
                items={employeeList}
                getData={getEmployees}
                placeholder="Type to search"
              />
            {/*
              <div className={styles['inclue-inactive-employees-checkbox']}>
                <Field
                  id="inactive_employees"
                  name="inactive_employees"
                  label={
                    <small>Include Inactive Employees</small>
                  }
                  component={CheckboxAtom}
                  onSelect={this.handleCheckboxChange}
                />
              </div>
            */}
            </Col>
          </Row>
          <Collapse isOpen={advancedView}>
            <Row>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="departments"
                  name="departments"
                  component={AsyncMultiSelectAtom}
                  items={departmentList}
                  itemToString={(i) => i && i.name}
                  label="Department Name"
                  onChange={onDepartmentSelect}
                  getData={getDepartments}
                  placeholder="Department"
                />
              </Col>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="teams"
                  name="teams"
                  component={AsyncMultiSelectAtom}
                  items={teamList}
                  itemToString={(i) => i && i.name}
                  label="Team Name"
                  onChange={onTeamSelect}
                  getData={getTeams}
                  placeholder="Team"
                  disabled={selectedDepartment && selectedDepartment.length ? false : true}
                />
              </Col>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="customers"
                  name="customers"
                  label="Customer Name"
                  component={AsyncMultiSelectAtom}
                  itemToString={(i) => i && i.customer_name}
                  items={customerList}
                  getData={getCustomers}
                  placeholder="Enter customer name"
                  defaultSelectedItem={initialValues && initialValues.customer}
                />
              </Col>
              <Col md={3} className="job-report-multiselect">
                <Field
                  id="job_tags"
                  name="job_tags"
                  label="Job Tags"
                  component={MultiSelectDropdownAtom}
                  items={jobTagList}
                  itemToString={(i) => i && i.name}
                  placeholder="Job Tags"
                />
              </Col>
              <Col md={3} className="job-report-multiselect">
                <Field
                  id="customer_tags"
                  name="customer_tags"
                  label="Customer Tags"
                  component={MultiSelectDropdownAtom}
                  items={customerTagList}
                  itemToString={(i) => i && i.name}
                  placeholder="Customer Tags"
                />
              </Col>
            </Row>
            <Row>
               <Col md={3} className="job-report-multiselect">
                <Field
                  id="employee_tags"
                  name="employee_tags"
                  label="Employee Tags"
                  component={MultiSelectDropdownAtom}
                  items={employeeTagsList}
                  itemToString={(i) => i && i.name}
                  placeholder="Employee Tags"
                />
              </Col>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="task_tags"
                  name="task_tags"
                  label="Task Tags"
                  component={MultiSelectDropdownAtom}
                  items={taskTagList}
                  itemToString={(i) => i && i.name}
                  placeholder="Task Tags"
                />
              </Col>
              <Col md={2} className="job-report-multiselect remove-icon pr-2">
                <Field
                  id="billable"
                  name="billable"
                  label="Quoted"
                  component={SelectAtom}
                  options={BILLABLE_OPTIONS}
                  placeholder="billable"
                  labelKey="label"
                  valueKey="value"
                  layout={FORM_LAYOUT}
                />
              </Col>
              <Col md={2} className="job-report-multiselect remove-icon pr-2">
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  component={SelectAtom}
                  options={FILTER_OPTIONS}
                  placeholder="Status"
                  labelKey="label"
                  valueKey="value"
                  layout={FORM_LAYOUT}
                />
              </Col>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="code"
                  name="code"
                  label="Job Code"
                  component={AsyncMultiSelectAtom}
                  items={filteredJobCodeArray && filteredJobCodeArray.length ? filteredJobCodeArray : [] }
                  itemToString={(i) => i && i.code}
                  getData={getJobs}
                  placeholder="Job Code"
                  removedItem={(i) => {
                    var changeJobs = jobs && jobs.filter(item => item.id !== i.id);
                    change('jobs', changeJobs);
                  }
                  }
                  addItem={(i) => {
                    let b = [];
                    if (jobs) {
                      b = JSON.parse(JSON.stringify(jobs));
                      b.push(i); 
                    } else {
                      b = [i];
                    }
                    change('jobs', b);
                  }
                  }
                />
              </Col>
              
            </Row>
            <Row>
              <Col md={2} className="job-report-multiselect">
                <Field
                  id="task_code"
                  name="task_code"
                  label="Task Code"
                  component={MultiSelectDropdownAtom}
                  items={filteredTaskCodeArray}
                  itemToString={(i) => i && i.code}
                  placeholder="Task Code"
                  removedItem={(i) => {
                    this.setState({removedItem : i})
                    var changeTasks = tasks && tasks.filter(item => item.id !== i.id);
                    change('tasks', changeTasks);
                    }
                  }
                  addItem={(i) => {
                    let b = [];
                    if (tasks) {
                      b = JSON.parse(JSON.stringify(tasks));
                      b.push(i); 
                    } else {
                      b = [i];
                    }
                    change('tasks', b);
                  }
                  }
                />
              </Col>
            </Row>
          </Collapse>
          <div className={styles["filter-form__actions"]}>
            <Button
              color="primary"
              className={styles["filter-form__actions--btn-submit"]}
              type="submit"
              size={FILTER_FORM_LAYOUT.size}
            >
              Run <ButtonLoaderAtom active={submitting} />
            </Button>
            <div
              onClick={() => this.setAdvancedView(!advancedView)}
              className={styles["filter-form__actions--btn-advanced"]}>
              Advanced Search
              <img
                alt=""
                src={CaretIcon}
                className={advancedView ?
                  styles["filter-form__actions--btn-advanced--up"] :
                  styles["filter-form__actions--btn-advanced--down"]} />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  const formattedStartDate=moment(values.start_date).format();
  const formattedEndDate=moment(values.end_date).format();
  if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
  return errors;
};

JobReportsFilterFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  submitting: PropTypes.bool.isRequired,
};

JobReportsFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const JobReportFilterForm = reduxForm({
  form: 'JobReportFilterForm',
  validate,
  enableReinitialize: true,
})(JobReportsFilterFormComponent);

const selector = formValueSelector('JobReportFilterForm');

export default connect(
  (state) => {
    const jobs = selector(state, 'jobs');
    const tasks = selector(state, 'tasks');
    const teams = selector(state, 'teams');
    return { jobs , tasks, teams};
  },
)(JobReportFilterForm);