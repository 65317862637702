import React from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import * as RouteDucks from "ducks/routes";

import TwoColumnLayout from "layouts/TwoColumn";
import PtoBulkUploadSection from "sections/pto/bulkUpload/bulkUpload";

import { NAV } from "constants/pto";

/**
 * PtoBulkUploadPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link EmployeeFilterSection}
 *    - {@link EmployeeListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */

class PtoBulkUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parent: NAV.PtoBulkUploadPage.parent,
    };
    this.setParentUrl = this.setParentUrl.bind(this);
  }

  componentWillMount() {
    this.setParentUrl();
  }

  setParentUrl() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        if (params.id) {
          this.setState({
            parent: `${NAV.PtoBulkUploadPage.parent}?${queryString.stringify(
              parsedParams
            )}`,
          });
        } else {
          this.setState({
            parent: `${NAV.PtoBulkUploadPage.parent}?${queryString.stringify(
              parsedParams
            )}`,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { parent } = this.state;

    return (
      <TwoColumnLayout navInfo={{ ...NAV.PtoBulkUploadPage, parent }}>
        <PtoBulkUploadSection />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

PtoBulkUploadPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PtoBulkUploadPage);
