
import React, {PureComponent} from 'react';

import { Formik, Field } from 'formik';
import { Form,FormGroup, FormText, Label, Input, Button, Card, CardTitle, Row, Col } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CountryCodeFormik from 'generics/CountryCodeFormik';

class LongTextForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    this.props.usecase.sendSmsTesting({...values, phone_ext: values.phone_ext ? values.phone_ext : '+1'});
  }

  render() {
    const { usecase, smsButtonLoader } = this.props;
    return (
      <div>
        <Formik
          initialValues={''}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
         }) => (
            <Form onSubmit={handleSubmit} className="ml-3 mr-3">
              <div style={{fontSize: '20px'}}><FormText color="danger">{errors && errors.non_field_errors}</FormText></div>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Message</Label>
                      <Input
                        type="text"
                        name="message_body"
                        placeholder="Enter Message"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message_body}
                        invalid={errors && touched.message_body && errors.message_body}
                        className={errors && errors && errors.message_body ? "form-control-error" : null}
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.first_name}</FormText></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={4} className="pr-1">
                        <Field
                          id="phone_ext"
                          name="phone_ext"
                          label="Code"
                          component={CountryCodeFormik}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_ext}</FormText></div>
                      </Col>
                      <Col className="pl-0">
                         <Label>Phone Number</Label>
                        <FormGroup>
                          <Input
                            type="text"
                            name="phone_no"
                            placeholder="8055550123"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phone_no}
                            invalid={errors && touched.phone_no && errors.phone_no}
                            className={errors && errors.phone_no ? "form-control-error" : null}
                          />

                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_no}</FormText></div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Button
                      type="submit"
                      isColor='primary'
                      className="float-right"
                    >
                      Submit <ButtonLoaderAtom active={smsButtonLoader} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          />
      </div>
    );
  }
}
  
export default LongTextForm;
