import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';

import * as UserDucks from 'ducks/accounts/user';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import LogoImgBlueAtom from 'atoms/LogoImgBlue';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import TwoColumnLayout from 'layouts/TwoColumn';

import { NAV } from 'constants/employees';


class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
  }

  componentWillMount() {
    const { joyRidePerms } = this.props;
    this.setState({
      isLoading: false,
      ...joyRidePerms,
    });
  }

  componentDidMount() {
    setTimeout(() => this.setState({ isRunning: !cookie.load('demoCompleted') }), 1000);
  }

  callback(data) {
    // console.log('%ccallback', 'color: #47AAAC; font-weight: bold; font-size: 13px;'); // eslint-disable-line no-console

    if (data.type === 'finished') cookie.save('demoCompleted', true);
    this.setState({
      selector: data.type === 'tooltip:before' ? data.step.selector : '',
    });
  }

  render() {

    return (
      <section>
        {/* <Joyride
          ref={c => {this.joyride = c}}
          autoStart
          callback={this.callback}
          disableOverlay
          run={isRunning}
          scrollToSteps={false}
          showOverlay={joyrideOverlay}
          showSkipButton
          showStepsProgress
          steps={steps}
          type={joyrideType}
        /> */}
        <TwoColumnLayout navInfo={NAV.DashboardPage}>
          <Row className="mx-0 align-items-center" style={{ minHeight: '80vh' }}>
            <Col>
              <div className="text-center">
                <h2>Welcome to </h2><br />
                <LogoImgBlueAtom height="150" /><br />
                <LogoMainBlueAtom height="50" />
              </div>
            </Col>
          </Row>
        </TwoColumnLayout>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  joyRidePerms: UserDucks.joyRidePerms(state),
});


DashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
)(DashboardPage);
