import React, { Component } from "react";

import styles from "forms/components/styles.module.scss";
import Button from "reactstrap/lib/Button";

import SideButtonsComponent from "forms/components/SideButtonsComponent";
import OrderListComponent from "forms/components/orderListComponent";
import TempComponent from "forms/components/tempComponent";
import QuestionModal from "forms/components/questionModal";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import { toast } from "react-toastify";

import { PreviewComponent } from "forms/components/previewComponent";

class CreateFieldFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnType: null,
      isLoading: false,
      initialValue: {
        question: "",
        answer: [],
        definition: null,
        behaviour: "SKIPPABLE",
        sequence_of_configuration: 1,
      },
      type: "add",
    };
    this.openModal = this.openModal.bind(this);
    this.handleEditQuestion = this.handleEditQuestion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.resetInitialValue = this.resetInitialValue.bind(this);
  }

  resetInitialValue() {
    this.setState({
      initialValue: {
        question: "",
        answer: [],
        definition: null,
        behaviour: "SKIPPABLE",
        sequence_of_configuration: 1,
      },
      type: "add",
    });
  }

  onSubmit() {
    const { id, createForm, updateForm, templateData } = this.props;
    if (templateData.label.trim() === "") {
      toast.error("Please enter the form Title");
      return;
    }
    if (
      templateData?.questions?.length === undefined ||
      templateData?.questions?.length === 0
    ) {
      toast.error("Please add at least one question");
      return;
    }
    if (id === "create" || id === "copy") {
      createForm();
    } else {
      updateForm();
    }
  }
  openModal(btnType) {
    const { toggleModal, templateData } = this.props;
    this.resetInitialValue();
    setTimeout(() => {
      if (btnType === "SINGLE_SELECT" || btnType === "MULTI_SELECT") {
        this.setState({
          initialValue: {
            ...this.state.initialValue,
            ...{ definition: btnType, answer: [{ value: null, score: 0 }] },
            sequence_of_configuration: templateData?.questions?.length
              ? templateData?.questions?.length + 1
              : 1,
          },
        });
        toggleModal();
      } else {
        this.setState({
          initialValue: {
            ...this.state.initialValue,
            definition: btnType,
            sequence_of_configuration: templateData?.questions?.length
              ? templateData?.questions?.length + 1
              : 1,
          },
        });
        toggleModal();
      }
    }, 100);
  }

  handleEditQuestion(questions) {
    const { toggleModal } = this.props;
    this.setState({ initialValue: questions, type: "update" });
    toggleModal();
  }

  render() {
    const { btnType, type, initialValue } = this.state;
    const {
      location,
      templateData,
      listDeleted,
      labelChange,
      modalFlag,
      toggleModal,
      isSubmitting,
      handleQuestionAdd,
      handleQuestionDelete,
      handleQuestionUpdate,
      saveAndNextButtonLoaderFlag,
      handleSort,
      initialValues,
      handleRecurringChange,
    } = this.props;
    return (
      <div style={{ background: "white" }}>
        <div>
          <div className={`${styles["form-title-container"]}`}>
            <p className={`${styles["form-title"]}`}>Title</p>
            <input
              className={`${styles["form-subtitle"]} `}
              type="text"
              name=""
              id=""
              placeholder="Enter the form title"
              value={templateData?.label}
              onChange={(e) => labelChange(e)}
            />
            <div className={`${styles["recurring-container"]}`}>
              <input
                type="checkbox"
                name="recurring"
                id="recurring"
                checked={templateData?.recurring}
                onChange={(e) => handleRecurringChange(e)}
              />
              Recurring
            </div>
          </div>
          <div className={`${styles["create-form-main"]} d-flex flex-row`}>
            <SideButtonsComponent toggle={this.openModal} location={location} />
            <OrderListComponent
              location={location}
              listDeleted={listDeleted}
              templateData={templateData}
              handleEditQuestion={this.handleEditQuestion}
              handleQuestionDelete={handleQuestionDelete}
              handleSort={handleSort}
            />
            <div className="ml-auto pb-4" style={{ minWidth: "200px" }}>
              <PreviewComponent>
                <TempComponent templateData={templateData} />
              </PreviewComponent>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  type="submit"
                  color="primary"
                  style={{ height: "35px" }}
                  onClick={() => this.onSubmit()}
                >
                  {JSON.stringify(initialValues) ===
                  JSON.stringify(templateData)
                    ? "Next"
                    : "Save & Next"}{" "}
                  <ButtonLoaderAtom active={saveAndNextButtonLoaderFlag} />
                </Button>
              </div>
            </div>
          </div>
        </div>
        {modalFlag && (
          <QuestionModal
            isDropdown={modalFlag}
            location={location}
            btnType={btnType}
            toggle={toggleModal}
            initialValue={initialValue}
            isSubmitting={isSubmitting}
            handleQuestionAdd={handleQuestionAdd}
            handleQuestionUpdate={handleQuestionUpdate}
            type={type}
          />
        )}
      </div>
    );
  }
}

export default CreateFieldFormComponent;
