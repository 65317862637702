import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as SystemSettingDucks from 'ducks/vendors/systemSetting';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import SystemSettingTableComponent from 'components/vendors/systemSettingList/Table';
import SystemSettingFormModalComponent from 'components/vendors/systemSettingList/FormModal';

import { MSGS } from 'constants/vendors';

/**
 * SystemSettingListPage -> SystemSettingListSection
 *
 * Components:
 *    - {@link SystemSettingTableComponent}
 *    - {@link SystemSettingFormModalComponent}
 *
 * State:
 *    - systemSettingList
 *
 * Actions:
 *    None
 */
class SystemSettingListSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.toggleMileageKey = this.toggleMileageKey.bind(this);
    this.state = { isOpen: false , mileageKey : false};
  }

  toggle(data) {
    const { isOpen } = this.state;

    if (isOpen) return this.setState({ isOpen: !isOpen });
    return this.props.SystemSettingDucks.getSystemSetting(data)
      .then((res) => this.setState({ isOpen: !isOpen , mileageKey : res.value.extra_data && res.value.extra_data.enabled ? res.value.extra_data.enabled : false}));
  }

  toggleMileageKey(val){
    this.setState({mileageKey: val});
  }

  update(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;

    return this.props.SystemSettingDucks.putSystemSetting(data)
      .then(() => {
        toast.success(MSGS.SYSTEM_SETTING_UPDATE_SUCCESS);
        this.props.SystemSettingDucks.getSystemSettings({ ...params, ...query });
        this.toggle();
      });
  }

  render() {
    const { isLoading, systemSettingDetail, systemSettingList, location } = this.props;
    const { isOpen } = this.state;
    let detail;
    const updateSystemSettingList = systemSettingList && systemSettingList.results.map((item) => {
      if (item.key.key_code === 'MANDATORY_PHONE_NUMBER_ON_EMPLOYEE_PROFILE') {
        const newItem = { ...item, value: (item.value === 'True' ? 'YES' : 'NO') };
        return newItem;
      }
      return item;
    });
    if (isOpen) {
      switch(systemSettingDetail.key.data_type) {
        case "DURATION":
          detail = {
            hour: Math.floor(+systemSettingDetail.value / 3600),
            minute: Math.floor((+systemSettingDetail.value % 3600) / 60),
          }
          break;
        case "LIST":
          if (systemSettingDetail.key.key_code === 'MANDATORY_PHONE_NUMBER_ON_EMPLOYEE_PROFILE') {
            detail = {
              CONTROL_VALUES: systemSettingDetail.key.control_values
                .map(item => (
                  { value: item === 'True' ? 'YES' : 'NO', label:  item === 'True' ? 'YES' : 'NO' }
                )),
            };
            const newValue = systemSettingDetail.value === 'True' ? 'YES' : 'NO';
            detail = { ...detail, value: newValue };
          } else {
            detail = {
              CONTROL_VALUES: systemSettingDetail.key.control_values
                .map(item => ({ value: item, label: item})),
            };
          }
          break;
        case "BOOLEAN":
        case "CHAR":
        default:
      }
    }

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <SystemSettingTableComponent
          data={updateSystemSettingList}
          toggle={this.toggle}
        />
        {isOpen &&
          <SystemSettingFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            update={this.update}
            toggleMileageKey={this.toggleMileageKey}
            mileageKey={this.state.mileageKey}
            initialValues={{
              ...systemSettingDetail,
              ...detail,
            }}
          />
        }
        <PaginationComponent
          count={systemSettingList.count}
          location={location}
          cookieKey="system_setting_page_size"
          />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  systemSettingList: SystemSettingDucks.systemSettingList(state),
  systemSettingDetail: SystemSettingDucks.systemSettingDetail(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  SystemSettingDucks: bindActionCreators(SystemSettingDucks, dispatch),
});

SystemSettingListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  systemSettingDetail: PropTypes.object.isRequired,
  systemSettingList: PropTypes.object.isRequired,
  SystemSettingDucks: PropTypes.object.isRequired,
};

SystemSettingListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SystemSettingListSection);
