import React from 'react';

import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { Card } from 'reactstrap';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';
import { FORM_LAYOUT } from 'constants/layout';

import styles from './styles.module.scss';

import CorrectionCommentsIcon from './CorrectionComment.png';
import JobCommentsIcon from './JobComments.png';
import MileageCommentsIcon from './MileageComments.png';

import FeedbackQuestionModal from './FeedbackQuestionModal';


class CommentsComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.notes = [];
  }

  componentWillMount(){
    const { data } = this.props;
    let message = data.message && data.message.length ? (data.message).map((i) => ({ ...i, type:'message'})) : [];
    let mileage = data.mileage_notes && data.mileage_notes.length ? (data.mileage_notes).map(v => ({ ...v, type: 'mileage'})) : [];
    let splitNotes = [];
    let jobNotes = [];
    data.split_list && data.split_list.length && data.split_list.forEach(v => 
    {
      splitNotes.push((v.job_notes).flat())
    });
    splitNotes && splitNotes.length && splitNotes.forEach(v => 
    {
      v && v.length && v.map((item)=>{
        jobNotes.push({...item, type: 'job'});
      });
    });
    this.notes = [...jobNotes, ...message, ...mileage];

    //sort notes chronologically
    (this.notes).sort(function(a, b) {
      var keyA = new Date(a.timestamp_tz),
        keyB = new Date(b.timestamp_tz);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  }

  componentWillReceiveProps(nextProps){
    //update the list when the user adds a new comment
    if(nextProps.data !== this.props.data){
      const { data } = nextProps;
      let message = data.message && data.message.length ? (data.message).map((i) => ({ ...i, type:'message'})) : [];
      let mileage = data.mileage_notes && data.mileage_notes.length ? (data.mileage_notes).map(v => ({ ...v, type: 'mileage'})) : [];
      let splitNotes = [];
      let jobNotes = [];
      data.split_list && data.split_list.length && data.split_list.forEach(v => 
      {
        splitNotes.push((v.job_notes).flat())
      });
      splitNotes && splitNotes.length && splitNotes.forEach(v => 
      {
        v && v.length && v.map((item)=>{
          jobNotes.push({...item, type: 'job'});
        });
      });
      this.notes = [...jobNotes, ...message, ...mileage];

      //sort notes chronologically
      (this.notes).sort(function(a, b) {
        var keyA = new Date(a.timestamp_tz),
          keyB = new Date(b.timestamp_tz);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      }  
  }


  submit(data){
    const { change } = this.props;
     if(!data.comment){
      return toast.error('Please enter a comment');
    }
    const { splitData } = this.props;
    this.props.saveValidity(data.comment, splitData, 1, 'notes')
    .then(() =>{
      change('comment', null);
    });
  }
  
  render() {
    const { data , newStyle , handleSubmit , commentSubmitting , isSubmitting, feedbackResponse} = this.props;
    return (
     <Card className={!newStyle ? `pr-4 pl-4 mt-3 ${styles['comment-card']}` : `pr-4 pl-3 mt-2`} style={newStyle && {border: 'none'}} >
      {newStyle &&
        <Form noValidate  onSubmit={handleSubmit(this.submit)}>
          <Field
            id="comment"
            name="comment"
            component={InputAtom}
            className="commentCustom"
            layout={FORM_LAYOUT}
            placeholder="Enter A Comment"
          />
          <Button type="submit" color="primary" className="btn-block w-50" style={{float:'right'}}>
            Submit <ButtonLoaderAtom active={commentSubmitting} />
          </Button>
        </Form>
      }
      {
        isSubmitting ?
          <div className="loadingActivities" role="status">
            <span>Loading...</span>
          </div>
          :
          <div>
            {this.notes.length ? this.notes.map((item) => 
              <div className="mt-3">
                <Row>
                  <Col>
                    <img height={17} src={item.type == 'message' ? CorrectionCommentsIcon : item.type == 'mileage' ? MileageCommentsIcon : item.type == 'job' ? JobCommentsIcon : null}/>
                    <h5 className={`f16 mb-0 d-inline-block ${styles['username-style']}`}>{(item.user).charAt(0).toUpperCase() + (item.user).slice(1)}</h5>
                    <small className={`d-block ${styles['timestamp-style']}`}>{item.job && 'Job name : '}{item.job}</small>
                    <small className={`d-block mt-0 ${styles['timestamp-style']} `}>{item.timestamp}</small>
                  </Col>
                </Row>
                <Row className={`mt-2 ${styles['message-style']}`}><Col className={styles['break-word']}><small>{item.message}</small></Col></Row>
                <span>{item.document && item.document.length ?
                  item.document.map(doc => {
                      return <img className='mt-2' src={doc.file} alt="" height={110}/>
                  }) : <p />
                }</span>
                <hr width='100%' className="mb-0"/>
              </div>
            ) : (feedbackResponse && !feedbackResponse.length ? <div style={{marginTop: '30%'}} className="justify-content-center align-items-center mb-3 h-100 d-flex">No Comments And Feedbacks Found</div> : null)
            }
            {feedbackResponse && feedbackResponse.length ? feedbackResponse.map((i, index)=>
              <div>
                <FeedbackQuestionModal feedbackResponse={i} dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>
                <hr width='100%' className="mb-0"/>
              </div>
              ): null
            } 
            </div>
      }
     </Card>
    );
  }
};



export default reduxForm({
  form: 'shiftComment',
})(CommentsComponent);

