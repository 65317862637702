import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as VendorDucks from 'ducks/vendors/vendor';
import * as RouteDucks from 'ducks/routes';
import TwoColumnLayout from 'layouts/TwoColumn';

import SectionLoaderAtom from 'atoms/SectionLoader';
import CustomerSummaryListSection from 'sections/generics/adminDashboard/customerSummaryList';
import OnBoardSummaryListSection from 'sections/generics/adminDashboard/onBoardingSummaryList';
import PendingActionsListSection from 'sections/generics/adminDashboard/pendingActionsList';
import LastXDaysListSection from 'sections/generics/adminDashboard/lastXDaysList';

import { NAV } from 'constants/generics';


class AdminDashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false , isXdaysLoading:false };
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getAdminStats = this.getAdminStats.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  getAdminStats(params) {
    this.setState({ isXdaysLoading: true })
    return this.props.VendorDucks.getbusinessAdminStats(params)
      .then(() => this.setState({ isXdaysLoading: false }))
      .catch(() => this.setState({ isXdaysLoading: false }));
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const dates = {
      start_date: moment().subtract( 6, "days").format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    }
    return Promise.all([
      this.getAdminStats(params),
      this.props.VendorDucks.getVendors(dates),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    this.fetchData({ days: 1 });
  }

  render() {
    const { isLoading, isXdaysLoading } = this.state;

    return (
      <TwoColumnLayout
        navInfo={NAV.AdminDashboardPage}
        fetchData={this.loadData}>
        {isLoading
          ? <SectionLoaderAtom active />
          : (<section className="pl-3 pr-4 pb-5">
            <Row>
              <Col>
                <OnBoardSummaryListSection />
              </Col>
              <Col>
                <PendingActionsListSection />
              </Col>
              <Col>
                <LastXDaysListSection isXdaysLoading={isXdaysLoading} />
              </Col>
            </Row>
            <CustomerSummaryListSection />
          </section>)
        }
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

AdminDashboardPage.propTypes = {
  VendorDucks: PropTypes.object.isRequired,
};

AdminDashboardPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(AdminDashboardPage);

