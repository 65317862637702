import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as VendorDucks from 'ducks/vendors/vendor';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';

import { FILTER_OPTIONS } from 'constants/generics';

import styles from './styles.module.scss';

/**
 * AdminDashBoardPage -> LastXDaysListSection
 *
 * Components:
 *    - None
 *
 * State:
 *    - LastXDays List
 *
 * Actions:
 *    None
 */
const LastXDaysListSection = (props) => {
  const { isXdaysLoading, location, businessAdminStats } = props;

  return (
    <Row>
      <Col sm="12">
        <Card block className={`${styles['admindashboard--wrapper']} card-block`}>
          <Row>
            <Col sm="12" className={styles['admindashboard--title']}>
              <span>
                Last x Days
                <small className="float-right days-button">
                  <FilterDropdownComponent
                    paramKey="days"
                    location={location}
                    options={FILTER_OPTIONS}
                    size="sm"
                    tether={{
                      targetAttachment: 'bottom right',
                      offset: '0px 160px',
                    }}
                    adminDashboard="AdminDashBoardPage"
                  />
                </small>
              </span>
              <br />
              <br />
              {isXdaysLoading
                ? <SectionLoaderAtom active />
                : (
                <div>
                  <Row>
                    <Col md={8}>
                      <small className={styles['admindashboard--subtitle']}>
                        Customers Onboarded
                      </small>
                    </Col>
                    <Col md={4}>
                      <small> 
                       <span className="float-left">:</span>
                        <span className="float-right">{businessAdminStats.customer_onboarded}</span>
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <small className={styles['admindashboard--subtitle']}>
                        Users Onboarded
                      </small>
                    </Col>
                    <Col md={4}>
                      <small> 
                       <span className="float-left">:</span>
                        <span className="float-right">{businessAdminStats.user_onboarded}</span>
                      </small>
                    </Col>
                  </Row>
                </div>)
              }
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  businessAdminStats: VendorDucks.businessAdminStats(state),
});

const mapActionsToProps = () => ({});

LastXDaysListSection.propTypes = {
  isXdaysLoading: PropTypes.bool.isRequired,
  businessAdminStats: PropTypes.object.isRequired, 
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(LastXDaysListSection);
