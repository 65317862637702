import React from 'react';

import { Formik, Field } from 'formik';
import moment from 'moment';

import DateTimePickerAtom from 'generics/DateTimePickerFormik';
import SelectAtom from 'generics/FormikSelect';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { FORM_LAYOUT } from 'constants/layout';
import { COMMERCIAL_STATUS_LIST , PAYMENT_METHOD_LIST} from 'constants/vendors';
import { Form,FormGroup, FormText, Label, Input , Button , Row, Col } from 'reactstrap';


class AccountFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  

  async onSubmit(values, { setSubmitting, setErrors }){
    const trialEndDate = values.trial_end ? moment(values.trial_end).format('YYYY-MM-DD'): null;
    const suspensionDate = values.suspension_date ? moment(values.suspension_date ).format('YYYY-MM-DD'): null;
    const closeDate = values.close_date ? moment(values.close_date).format('YYYY-MM-DD') : null;
    const  { companyID }  = this.props;
     this.props.usecase.setup_data(values, trialEndDate, suspensionDate, closeDate);
     try {
      await this.props.usecase.execute(companyID);
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  render() {
    const { data, isLoading } = this.props; 
    const OnboardingDate = moment(data.create_date).format('ddd, DD MMM YYYY');
    if(isLoading) return <SectionLoaderAtom active />;
    return(
      <Formik
        initialValues={this.props.initialValues}
        onSubmit={this.onSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          }) => 
         {
          return(
            <Form onSubmit={handleSubmit}>
              <div style={{minHeight: '3vh'}}><FormText color="danger" style={{fontSize: '15px'}}>{errors &&  errors.details}</FormText></div>
              <Row>         
                <Col md={12} className="mt-2">
                  <Row>
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Account</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Company Name"
                          onChange={handleChange}
                          value={data && data.name ? data.name :  null}
                          disabled
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.name}</FormText></div>
                      </FormGroup>                  
                    </Col>    
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Onboarding Date</Label>
                        <Input
                          type="text"
                          name="create_date"
                          placeholder="Onboarding Date"
                          onChange={handleChange}
                          value={OnboardingDate ? OnboardingDate : null}
                          disabled
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.create_date}</FormText></div>
                      </FormGroup>                  
                    </Col>             
                  </Row>
                  <Row>
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Trial Period End Date</Label>
                        <Field
                        id="trial_end"
                        name="trial_end"
                        placeholder="Trial Date"
                        component={DateTimePickerAtom} 
                        options={{ dateFormat : 'Y/m/d'
                        }}
                        layout={FORM_LAYOUT}
                      />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.trial_end}</FormText></div>
                      </FormGroup>                  
                    </Col>    
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Account Suspension Date</Label>
                         <Field
                        id="suspension_date"
                        name="suspension_date"
                        placeholder="Suspension Date"
                        component={DateTimePickerAtom}
                        options={{ dateFormat : 'Y/m/d',                
                          /*enable: [
                            (date) => 
                            moment(date).isAfter(moment().subtract(1, 'day')),
                          ],*/
                        }}
                        layout={FORM_LAYOUT}                
                      />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.suspension_date}</FormText></div>
                      </FormGroup>                  
                    </Col>             
                  </Row>
                   <Row>
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Account Closing Date (if applicable)</Label>
                         <Field
                          id="close_date"
                          name="close_date"
                          placeholder="Closing Date"
                          component={DateTimePickerAtom} 
                          options={{ dateFormat : 'Y/m/d',                
                          /*enable: [
                            (date) => 
                            moment(date).isAfter(moment().subtract(1, 'day')),
                          ],*/
                          }}
                          layout={FORM_LAYOUT} 
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.closing_date}</FormText></div>
                      </FormGroup>                  
                    </Col>    
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Commercial Status</Label>
                        <Field
                          component={SelectAtom}
                          options={COMMERCIAL_STATUS_LIST}
                          name="commercial_status"
                          id="commercial_status"
                          placeholder="Commercial Status"
                          disabled
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.first_name}</FormText></div>
                      </FormGroup>                  
                    </Col>                                 
                  </Row>
                  <Row>  
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <Label className="mb-0">Payment Method</Label>
                        <Field
                          component={SelectAtom}                         
                          options={PAYMENT_METHOD_LIST}
                          name="plan_type"
                          id="plan_type"
                          placeholder="Payment Method"
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.first_name}</FormText></div>
                      </FormGroup>                  
                    </Col>             
                  </Row>
                  </Col>
                  </Row>           
                  <Row>
                    <Col md={12} className="mt-2 text-right">
                      <Button
                        className= "ml-1 mr-1"
                        color='primary'
                        type='submit'
                      >
                      Submit <ButtonLoaderAtom active = {isSubmitting} />
                      </Button>                     
                    </Col>
                  </Row>
            </Form>
          );}}
      />);
  }
};
export default AccountFormComponent;
