import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  REPORT: `${SERVER_URL}/t2b/reports/`,
  DETAIL: `data/`,
  DOWNLOAD: `download/`,
  CORRECTION_MESSAGE: `${SERVER_URL}/t2b/activity/list/`,
};
