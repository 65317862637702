import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import {ReactComponent as CompanyUserIcon} from './user.svg';
import {ReactComponent as BusinessCardIcon} from './business-card.svg';

const WizardSection = (props, context) => {
  const { isStep1, isStep2 } = props;
  const { router: { route: { match: { params } } } } = context;
  
  return (
    <section>
      <div className="wizard">
        <div className="wizard-inner">
          <div className="connecting-line" />
          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className={isStep1 ? "active" : "disabled"}>
              {params.id 
                ? (<Link to={`/vendor/sub-company/${params.id}/edit`}>
                  <span className="round-tab" title="Edit Company">
                    <BusinessCardIcon width="47" height="36" className="mt-3" />
                  </span>
                </Link>)
                : (<Link to={`/vendor/sub-company/add`}>
                  <span className="round-tab" title="Add Branch">
                    <BusinessCardIcon width="47" height="36" className="mt-3" />
                  </span>
                </Link>)
              } 
            </li>
            <li role="presentation" className={isStep2 ? "active" : "disabled"}>
              {params.id 
                ? (<Link to={`/vendor/sub-company/${params.id}/user`}>
                  <span className="round-tab round-step2-tab" title="Add Branch User">
                    <CompanyUserIcon width="37" height="32" className="mt-3" />
                  </span>
                </Link>)
                :(<span className="round-tab round-step2-tab" title="Add Branch User">
                  <CompanyUserIcon width="37" height="32" className="mt-3" />
                </span>)
              } 
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

WizardSection.propTypes = {
  isStep1: PropTypes.bool.isRequired,
  isStep2: PropTypes.bool.isRequired,
};

WizardSection.contextTypes = {
  router: PropTypes.object.isRequired,
}; 

export default WizardSection;
