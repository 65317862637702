import React from "react";

import PropTypes from "prop-types";
import Redirect from "react-router-dom/Redirect";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";

import MatchWhenAuthorized from "pages/MatchWhenAuthorized";
import NoMatch from "pages/NoMatch";
import LearningCenterSetupPage from "learningCenter/pages/learningCenterSetup";
import CreateAssessmentForm from "learningCenter/pages/createAssessmentForm";
import DocumentCompletionList from "learningCenter/pages/documentCompletionList";
import NotificationListPage from "notification/pages/notificationList";

const NotificationRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} />
      <MatchWhenAuthorized
        path={`${match.url}/documents-completion/report`}
        name="learning.completion.report"
        exact
        component={DocumentCompletionList}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/setup/:id`}
        name="learning.setup"
        component={LearningCenterSetupPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/trainingForms/:id`}
        name="documents.setup"
        component={CreateAssessmentForm}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/communication/list`}
        name="learning.communication.list"
        component={NotificationListPage}
      />

      <Route component={NoMatch} />
    </Switch>
  );
};

NotificationRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default NotificationRoutes;
