import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  CUSTOMERS: `${SERVER_URL}/customers/info/`,
  CUSTOMER_BULK_UPLOAD :`${SERVER_URL}/customers/bulk-customer-create/`,
  CUSTOMERS_DETAIL:`${SERVER_URL}/customers/`,
  TAG: 'tags/',
  JOB: `${SERVER_URL}/job/`,
}

