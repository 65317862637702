
import React, {PureComponent} from 'react';
import { Form , Modal, ModalBody , ModalHeader } from 'reactstrap';
import { Formik } from 'formik';
import closeimage from './CloseCross.png';

class ContactSupportModal extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    try {
      await this.props.usecase.postOtp(values);
    } catch (e) {
      console.log(e.errors);
      setErrors(e.errors);
    }
    setSubmitting(false);
  }


  render() {
    const {isOpen, toggle } = this.props;
   

    return (
       <Modal
        isOpen={isOpen}
        toggle={toggle}
        >
        <ModalHeader>CONTACT SUPPORT
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <Formik
            initialValues={this.props.data}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                <ModalBody className="pt-0">
                
                <h6>Thank you for contacting us. We will get back to you as soon as possible.</h6>
                
                </ModalBody>
                </Form>
                 )}
          />
             
             </Modal>
    );}
  }
  
export default ContactSupportModal;
