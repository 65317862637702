import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import MultiSelectAtom from 'atoms/MultiSelect';
import CheckboxAtom from 'atoms/Checkbox';

import AutoCompleteAtom from 'atoms/AutoComplete';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * JobFormPage -> JobFormSection -> JobFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class JobEmployeeFilterComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},isOpen: false, dropdownOpen: false,
      dropThird: false,
      dropOpen: false,
      dropdownTeamOpen: false,
      dropdownSecondTeamOpen: false,
      value: { value: '', label: "Select Department"},
      valueSecond: { value: '', label: "Select Department"},
      valueThird: { value: '', label: "Select Department"},
      valueTeam: { value: '', label: "Select Team"},
      valueTeamSecond: { value: '', label: "Select Team"},
      selectedDepartments : [],
      showableTeams: [],
      selectedTeams: [],
      selectedTags : [],
      checkedDepartment: [],
    };
    this.assignEmployee = this.assignEmployee.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onDeptSelection = this.onDeptSelection.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.assignByDept = this.assignByDept.bind(this);
    this.assignByTeam = this.assignByTeam.bind(this);
    this.handleDepartmentCheckbox = this.handleDepartmentCheckbox.bind(this);
    this.handleTeamCheckbox = this.handleTeamCheckbox.bind(this);
    this.populateDepartments = this.populateDepartments.bind(this);
    this.assignByTag = this.assignByTag.bind(this);
    this.assignAllEmployeesToJob = this.assignAllEmployeesToJob.bind(this);
    this.handleTagsCheckbox = this.handleTagsCheckbox.bind(this);
  }

  //Using componentDidUpdate instead of comoonentDidMount 
  //b/c departmentAll is not populated immediately on mount
  componentWillMount(){
    this.populateDepartments(this.props.departmentAll);
  }

  componentWillReceiveProps(nextProps, prevProps){
    const { tagsList } = nextProps;
     if (tagsList !== prevProps.tagsList && tagsList.length && tagsList[0].id) {
       const allTags = tagsList[0] && tagsList[0].tag_collection && tagsList[0].tag_collection.length && tagsList[0].tag_collection.map(item => {
        return {
        name: item, 
        id: item, 
      }
      });
      this.setState({ selectedTags :allTags ? allTags: []});
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.departmentAll !== prevProps.departmentAll) {
      this.populateDepartments(this.props.departmentAll);
    }
  }

  handleTagsCheckbox(checkedItem){
    const { selectedTags } = this.state;
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedTagsUpdated = selectedTags.map(item => {
      if (isSelectAll) {
        return { ...item, checked: true };
      }
      if( checkedItem && !checkedItem.id) {
        return {...item, checked: false };
      }

      if (item.id === checkedItem.id) {
        return { ...item, checked: !item.checked };
      }

      return item;
    })


    this.setState({ 
      selectedTags: selectedTagsUpdated, 
    });
  }

  populateDepartments (departments) {
    if (!departments.length)  return;

    const selectedDepartments = departments.map(item => {
      return {
        name: item.name, 
        id: item.id, 
        checked: false,
        teams: item.teams
      }
    });
    this.setState({ selectedDepartments });
  }


  handleDepartmentCheckbox (checkedItem) {
    //Update selectedDepartments
    //Update showableTeams
    //set selectedTeams
    const { selectedDepartments } = this.state;
    const {teamAll} = this.props;
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedDepartmentsUpdated = selectedDepartments.map(item => {
      if (isSelectAll) {
        return { ...item, checked: true };
      }
      if( checkedItem && !checkedItem.id) {
        return {...item, checked: false };
      }

      if (item.id === checkedItem.id) {
        return { ...item, checked: !item.checked };
      }

      return item;
    })

    const checkedSelectedDept =[];

    const showableTeams = [];

    selectedDepartmentsUpdated.length && selectedDepartmentsUpdated.forEach((i)=>{
      if(i.checked){
        teamAll && teamAll.length && teamAll.forEach(item=>{
          checkedSelectedDept.push(item)
          if(item.department === i.id){
            showableTeams.push(item)
          }
        })
      }
    })

    const selectedTeams = showableTeams.map(team => {
      return { name: team.name, id: team.id, checked: false };
    })

    this.setState({ 
      selectedDepartments: selectedDepartmentsUpdated, 
      showableTeams, 
      selectedTeams,
      checkedDepartment:checkedSelectedDept
    });
  }

  handleTeamCheckbox(checkedItem) {
    let isSelectAll;

    if (!checkedItem) isSelectAll = true;

    const selectedTeams = this.state.selectedTeams.map(team => {

      if (isSelectAll)                return { ...team, checked: true };
      if( checkedItem && !checkedItem.id) {
        return {...team, checked: false };
      }
      if (team.id === checkedItem.id) return { ...team, checked: !team.checked }

      return team;
    })

    this.setState({ selectedTeams });
  }

  onDeptSelection(val,num){
    if(num === 1){
      this.setState({ value: val });
    }
    else if(num === 2){
      this.setState({ valueSecond: val, valueTeam: { value: '', label: "Select Team"} });
    }
    else if(num === 3){
      this.setState({ valueThird: val, valueTeamSecond: { value: '', label: "Select Team"} });
    }
  }

  onTeamSelection(val,num){
    if(num===1){
      this.setState({ valueTeam: val });
    }
    else if (num === 2) {
      this.setState({ valueTeamSecond: val });
    }
  }

  assignAllEmployeesToJob(){
    this.props.assignAllEmployeesToJob();
  }

  getEmployees(params){
    const { getEmployees } = this.props;
    const { selectedTeams, selectedDepartments , selectedTags} = this.state;
    let selectedTeam = selectedTeams.filter(item => item.checked === true);
    let selectedDepartment = selectedDepartments.filter(item => item.checked === true);
    let department_ids = selectedDepartment && selectedDepartment.length ? selectedDepartment.map(item => item.id) : selectedDepartment.id;
    let team_ids = selectedTeam && selectedTeam.length ? selectedTeam.map(item => item.id) : selectedTeam.id;
    return getEmployees({ ...params, department_ids : JSON.stringify(department_ids) , team_ids: JSON.stringify(team_ids) })
  }

  assignEmployee(data) {
    const { selectedDepartments , selectedTeams , selectedTags} = this.state;
    let selectedTeam = selectedTeams.filter(item => item.checked === true);
    let selectedDepartment = selectedDepartments.filter(item => item.checked === true);
    let selectedTag = selectedTags.filter(item => item.checked === true);

    if(data.select_all_employees) {
      return this.assignAllEmployeesToJob();
    }

    if(!data.employee && !selectedTeam.length && !selectedDepartment.length && !selectedTags.length){
      return toast.error('Please select employee to assign');
    }
    if(!data.employee && !selectedTeam.length && selectedDepartment.length){      
      if(selectedDepartment){
        return this.assignByDept(selectedDepartment);
      }
    }
    else if(selectedTeam.length && !data.employee){
      return this.assignByTeam(selectedTeam);
    }
    else if(selectedTags.length && !selectedTeam.length && !selectedDepartment.length && !data.employee){
      return this.assignByTag(selectedTag);
    }
    const { assignByEmployee, change } = this.props;
    return assignByEmployee(data.employee)
      .then(() => change('employee', null));
  }

  dropdownToggle(num) {
    if(num === 1){
      this.setState(prevState => ({
        dropdownOpen: !prevState.dropdownOpen
      }));
    }
    else if(num === 2){
      this.setState(prevState => ({
        dropOpen: !prevState.dropOpen
      }));
    }
    else if(num === 3){
      this.setState(prevState => ({
        dropThird: !prevState.dropThird
      }));
    }
    else if(num === 4){
      this.setState(prevState => ({
        dropdownTeamOpen: !prevState.dropdownTeamOpen
      }));
    }
    else if(num === 5){
      this.setState(prevState => ({
        dropdownSecondTeamOpen: !prevState.dropdownSecondTeamOpen
      }));
    }
  }

  assignByDept(value){
    const departments = [];
    value && value.length && value.map((item, index) => {           
      const newItem = 
      {                 
        label : item.name,
        value : item.id
      }        
      return departments.push(newItem);
     });
    const { assignByDept } = this.props;
    return assignByDept(departments)
      .then(() => this.setState({ value: { value: '', label: "Select Department"} }));
  }

  assignByTag(value){
    const tags = [];
    value && value.length && value.map((item, index) => {           
      const newItem = 
      {                 
        label : item.name,
        value : item.id
      }        
      return tags.push(newItem);
     });
    const { assignByTag } = this.props;
    return assignByTag(tags)
      .then(() => this.setState({ value: { value: '', label: "Select Tags"} }));
  }

  assignByTeam(team){
    const { assignByTeam } = this.props;
    return assignByTeam(team)
      .then(() => this.setState({ valueSecond: { value: '', label: "Select Department"},
        valueTeam: { value: '', label: "Select Team" },
      }));
  }

  render() {
    const {
      handleSubmit,
      userExtraData,
      departmentAll,
      isOrganizationVisible,
      teamAll,
      assigningEmployee,
    } = this.props;

    const { valueSecond, valueThird, checkedDepartment  } = this.state;
    let departmentOptions = [];
    let teamFirstOptions = teamAll;
    let teamSecondOptions = teamAll;

    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));
      if (!userExtraData.department){
        departmentOptions.splice(0, 0, { value: "", label: "Select Department" })
      }
      if(valueSecond.value !== ''){
        teamFirstOptions = teamAll && teamAll.filter((i) => i.department === valueSecond.value);
      }
      if(valueThird.value !== ''){
        teamSecondOptions = teamAll && teamAll.filter((i) => i.department === valueThird.value);
      }
      teamFirstOptions = teamFirstOptions && teamFirstOptions.map(item => ({
        value: item.id, label: item.name,
      }));
      teamSecondOptions = teamSecondOptions && teamSecondOptions.map(item => ({
        value: item.id, label: item.name,
      }));
      if (!userExtraData.team) {
        teamFirstOptions.splice(0, 0, { value: "", label: "Select Team" });
        teamSecondOptions.splice(0, 0, { value: "", label: "Select Team" })
      }
    }
    
    // <span {valueThird.label === '' ? style={{color: '#aaa'}} : style={{color: '#242424'}}}>{valueThird.label}</span>
    return (
      <div>
        <Row>
          {/* First Column Department*/}
          <Col md={3} className="pr-0 dept-custom-width">
            <div className="text-left">Department</div>
            {isOrganizationVisible &&
              <div className="w-100 emp-job-override">
                {/* {<Dropdown isOpen={this.state.dropThird} toggle={()=>this.dropdownToggle(3)} className="w-100 float-custom">
                  <DropdownToggle caret color="primary" className = 'tag-dropdown' style={{width: '100%', boxShadow: "none" }}>
                    <span className={valueThird.value === '' ? "light-text" : null}>{valueThird.label}</span>
                  </DropdownToggle>
                  <DropdownMenu right className="scroll-edit-job w-100">
                    {departmentOptions && departmentOptions.map((item)=>(
                      <DropdownItem onClick={() => this.onDeptSelection(item,3)}>
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>} */}
                <MultiSelectAtom
                  menuItems={this.state.selectedDepartments}
                  allFields = {'All Departments'}
                  onItemSelect={data => this.handleDepartmentCheckbox(data)}
                  className={styles['department-dropdown']}
                />
              </div>
            }
          </Col>

          {/* Second Column Team */}
          <Col className="team-custom-width">
            <div className="text-left">Team</div>
            {isOrganizationVisible &&
              <div className="w-100 task-override emp-task-override">
                {/*
                  <Dropdown isOpen={this.state.dropdownSecondTeamOpen} toggle={()=>this.dropdownToggle(5)} className="w-100 float-custom">
                    <DropdownToggle caret color="primary" className = 'tag-dropdown' style={{width: '100%', boxShadow: "none"}}>
                      <span className={valueTeamSecond.value === '' ? "light-text" : null}>{valueTeamSecond.label}</span>
                    </DropdownToggle>
                    <DropdownMenu right className="scroll-edit-job w-100">
                      {teamSecondOptions && teamSecondOptions.map((item)=>(
                        <DropdownItem onClick={() => this.onTeamSelection(item,2)}>
                          {item.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                */}
                <MultiSelectAtom
                  menuItems={this.state.selectedTeams}
                  allFields = {'All Teams'}
                  onItemSelect={data => this.handleTeamCheckbox(data)}
                  disabled={checkedDepartment && checkedDepartment.length  ? false : true}
                />
              </div>
            }
          </Col>

           <Col md={3} className="team-custom-width">
            <div className="text-left">Tags</div>
            {isOrganizationVisible &&
              <div className="w-100 task-override emp-task-override">
                {/*
                  <Dropdown isOpen={this.state.dropdownSecondTeamOpen} toggle={()=>this.dropdownToggle(5)} className="w-100 float-custom">
                    <DropdownToggle caret color="primary" className = 'tag-dropdown' style={{width: '100%', boxShadow: "none"}}>
                      <span className={valueTeamSecond.value === '' ? "light-text" : null}>{valueTeamSecond.label}</span>
                    </DropdownToggle>
                    <DropdownMenu right className="scroll-edit-job w-100">
                      {teamSecondOptions && teamSecondOptions.map((item)=>(
                        <DropdownItem onClick={() => this.onTeamSelection(item,2)}>
                          {item.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                */}
                <MultiSelectAtom
                  menuItems={this.state.selectedTags}
                  allFields = {'All Tags'}
                  onItemSelect ={data => this.handleTagsCheckbox(data)}
                />
              </div>
            }
          </Col>
          {/* Third Column Assign employees and assign button */}
          <Col  className="pl-0">
            <Form className="d-inline" onSubmit={handleSubmit(this.assignEmployee)}>
              <Row>
                <Col md={8} className="pr-2">
                  <div className="text-left emp-custom-width pr-2">Employee</div>
                  <section className="emp-dropdown-override text-left w-100">
                    <Field
                      id="employee"
                      name="employee"
                      component={AutoCompleteAtom}
                      getData={this.getEmployees}
                      placeholder="Search by name,tag or id"
                      mapKeys={{ label: [ 'user', 'full_name' ], value: 'id' }}
                      layout={FORM_LAYOUT}
                      isCache={false}
                    />
                    <Field
                      id="checkBox"
                      className={`${styles['label-text-allign']} cursor-pointer`}
                      type="checkbox"
                      name="select_all_employees"
                      label={
                        <div className="cursor-pointer">
                          Select all employees
                          <UncontrolledTooltip placement="top" target="checkBox">
                            Check to select all employee of this company.
                          </UncontrolledTooltip>
                        </div>
                      }
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                    />
                  </section>
                </Col>
                <Col className="custom-assign-button pr-0 pl-0">
                  <Button
                    className="assign-button"
                    type="submit"
                    style={{marginTop: '1.2rem', paddingTop: '6.5px'}}
                  >
                  <span>
                    Assign
                  </span>
                  <ButtonLoaderAtom active={assigningEmployee}/>
                  </Button>
              </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}


JobEmployeeFilterComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  jobDetail: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTask: PropTypes.func,
  getEmployees: PropTypes.func,
  assignByEmployee: PropTypes.func,
  customerList: PropTypes.object,
  change: PropTypes.func,
  jobList: PropTypes.func,
};

JobEmployeeFilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const JobEmployee = reduxForm({
  form: 'JobEmployeeForm',
  enableReinitialize: true,
})(JobEmployeeFilterComponent);

const selector = formValueSelector('JobEmployeeForm');

export default connect(
  (state) => {
    const employee = selector(state, 'employee');
    const task = selector(state, 'task');
    return { employee, task };
  },
)(JobEmployee);
