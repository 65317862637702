export const NAV = {
  BasicSettingFormPage: {
    title: 'Basic Settings',
    parent: '/dashboard-admin',
  },
};

export const DATE_FORMATS = [
  { value: '%a, %d %b %Y', label: 'ddd, DD MMM YYYY' },
  { value: '%m/%d/%Y', label: 'MM/DD/YYYY' },
  { value: '%d/%m/%Y', label: 'DD/MM/YYYY' },
  { value: '%Y/%m/%d', label: 'YYYY/MM/DD' },
  { value: '%Y/%d/%m', label: 'YYYY/DD/MM' },
  { value: '%B %d, %Y', label: 'MMMM DD, YYYY' },
  { value: '%b %d, %Y', label: 'MMM DD, YYYY' },
];

export default { NAV, DATE_FORMATS };
