import React from "react";

import styles from "forms/components/styles.module.scss";
import { FORMS_QUESTIONS_TYPE } from "constants/forms";

import CalendarIcon from "forms/assets/calendar.png";
import NumberIcon from "forms/assets/number.png";
import NumberWhiteIcon from 'forms/assets/numberWhiteIcon.png'
import ImageUploadIcon from "forms/assets/imgupload.png";
import SignatureIcon from "forms/assets/signature.png";
import FileUploadIcon from "forms/assets/fileupload.png";
import OpenEndedIcon from "forms/assets/openended.png";

class SideButtonsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { toggle, location } = this.props;

    return (
      <div className={`${styles["create-form-left"]} p-1`}>
        {location.pathname.indexOf("/fieldform") > -1 ? (
          <div>
            <button onClick={() => toggle("FREE_TEXT")}>
              {/* <img
                src={OpenEndedIcon}
                alt="calendar icon"
                className="mr-1"
                width={12}
                height={12}
              /> */}
              Text
            </button>
            <button onClick={() => toggle("SINGLE_SELECT")}>
              {" "}
              Single Select{" "}
            </button>
            <button onClick={() => toggle("MULTI_SELECT")}>
              {" "}
              Multi Select{" "}
            </button>
            <button onClick={() => toggle("DATE")}>
              {/* <img
                src={CalendarIcon}
                alt="calendar icon"
                className="mr-1"
                width={15}
                height={15}
              /> */}
              Date
            </button>
            <button onClick={() => toggle("NUMBER")}>
              {/* <img
                src={NumberIcon}
                alt="calendar icon"
                className="mr-1"
                width={15}
                height={15}
              /> */}
              Number
            </button>
            <button onClick={() => toggle("FILE_UPLOAD")}>
              {/* <img
                src={ImageUploadIcon}
                alt="calendar icon"
                className="mr-1"
                width={15}
                height={15}
              /> */}
              File Upload
            </button>
            <button onClick={() => toggle("SIGNATURE")}>
              {/* <img
                src={SignatureIcon}
                alt="calendar icon"
                className="mr-1"
                width={15}
                height={15}
              /> */}
              Signature
            </button>
          </div>
        ) : (
          <div>
            <button onClick={() => toggle("SINGLE_SELECT")}>
              {" "}
              Single Select{" "}
            </button>
            <button onClick={() => toggle("MULTI_SELECT")}>
              {" "}
              Multi Select{" "}
            </button>
            <button onClick={() => toggle("SIGNATURE")}>
              {" "}
              {/* <img
                src={SignatureIcon}
                alt="calendar icon"
                className="mr-1"
                width={15}
                height={15}
              /> */}
              Signature{" "}
            </button>
            <a
              href="#"
              style={{
                color: "#3f9fff",
                textDecoration: "underline",
                marginTop: "10px",
              }}
            >
              Advance Training Fields
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default SideButtonsComponent;
