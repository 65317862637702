import React from 'react';

import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import styles from './styles.module.scss';

import MarkerIcon from './marker.png';
import {ReactComponent as EditIcon} from './edit.svg';


class ActivityLocationWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isWidgetOpen: true };
  }
  render() {
    const { sessionInfo, highlightMarker, dailyTimecardDetail,
      isLoading, data, toggle } = this.props;
    const newData = data && data.length ? data.filter((i) => i.activity_code !== 'TASK') : null;
    return (
      <section className={styles['location-widget']}>
        <div>
          {newData && newData.length ?
            <div className={styles['modal-data-wrap']}>
              {
                newData && newData.map((item) => {
                  let message = '';
                  if(item.message.length){
                    if(item.message[0].message && item.message[0].message.length < 50){
                      message = item.message[0].message;
                    }
                    else{
                      message = item.message[0].message && item.message[0].message.substring(0, 47).concat("...");
                    }
                  }
                  let markers = []
                  markers = sessionInfo && sessionInfo.locations && sessionInfo.locations.filter((i) => i.split_id === item.id );
                  if (Object.keys(sessionInfo).length === 0) {
                    markers = [];
                  }
                  if(markers.length > 2){
                    const arr = [];
                    const startMarker = markers.find((i) => (i.activity_name.indexOf('Start') > -1));
                    const endMarker = markers.find((i) => (i.activity_name.indexOf('End') > -1));
                    arr.push(startMarker);
                    arr.push(endMarker);
                    markers =  arr;
                  }
                  return (<Row className={`${styles['row-border']} m-0`} key={item.id}>

                    <Col className={`${styles['modal-data-image']} p-0`} md={1}>
                      {!dailyTimecardDetail.is_approved &&
                        <EditIcon className="ml-1 mt-2 cursor-pointer" height="24" width="24" onClick={() => toggle(item)}/>
                      }
                    </Col>
                    <Col className={`${styles['modal-data']} pl-0 pr-2`} md={6}>
                      <div>
                        <span className={styles['activity-name']}>
                          <b>{item.activity_name.toUpperCase()}</b>
                        </span>
                      </div>
                      {item.validity_display && <div> ({ item.validity_display }) </div>}
                      {item.status_display &&
                        <div>
                          <b>{ item.status_display }</b>
                        </div>}
                      {item.job_label && <div className={styles['job-name']}>Job Name: {item.job_label} </div>}
                      {item.resolved_as && <div>Resolved As: { item.resolved_as} </div>}
                      {item.manual_action_time_display && <div>Correction Date: {item.manual_action_time_display} </div>}
                      {message && <div>Comments: {message}</div>}
                    </Col>
                    <Col md={5} className="pl-0 pr-2">
                      <div className={styles['total-time']}>
                        {item.status_display && item.status_display === 'Awaited Approval' &&
                          <span>
                            <span className={styles['circle-wrap']} style={{ background: item.color }}/>
                          </span>
                        }
                        <b>
                          {item.total_time_display.split(':')[0]} hrs {item.total_time_display.split(':')[1]} min
                        </b>
                      </div>
                      <div>
                        {(markers.length === 1) && markers[0].activity_name.indexOf('End') > -1 &&
                          <div className={styles['image-wrap']}>
                            <span className={styles['image-label-wrap']}>
                              <span className={styles['marker-icon-label']}>&nbsp;</span>
                            </span>
                            <span className={styles['marker-icon-info-start']}>
                              {item.start_datetime_display}
                            </span>
                          </div>}
                        {markers && markers.map((i) => (
                          <div className={styles['image-wrap']}>
                            <span className={styles['image-label-wrap']} role="button" tabIndex="0" onClick={()=> highlightMarker(i)}>
                              <img src={MarkerIcon} alt= ''  height="30" width="30" />
                              <span className={styles['marker-icon-label']}>{i.id + 1}</span>
                            </span>
                            <span className={i.activity_name.indexOf('Start') > -1 ? styles['marker-icon-info-start'] : styles['marker-icon-info-end']}>
                              {i.activity_name.indexOf('Start') > -1 ? item.start_datetime_display : item.end_datetime_display}
                            </span>
                          </div>
                        ))}
                        {(markers.length === 1) && markers[0].activity_name.indexOf('Start') > -1 &&
                          <div className={styles['image-wrap']}>
                            <span className={styles['image-label-wrap']}>
                              <span className={styles['marker-icon-label']}>&nbsp;</span>
                            </span>
                            <span className={styles['marker-icon-info-end']}>
                              {item.end_datetime_display}
                            </span>
                          </div>}
                        {!markers.length &&
                          <div className={styles['no-marker-wrap']}>
                            <div className={styles['marker-icon-info-start']}>
                              {item.start_datetime_display}
                            </div>
                            <div className={styles['marker-icon-info-end']}>
                              {item.end_datetime_display}
                            </div>
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                  );
                })
              }
            </div>:
            <div className={styles['no-records']}>
              { !isLoading ? 'No Records Found' : '' }
            </div>}
        </div>
      </section>
    );
  }
};

ActivityLocationWidgetComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sessionInfo: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  toggle: PropTypes.func,
  highlightMarker: PropTypes.func,
  isLocationLoading: PropTypes.bool.isRequired,
  timecardDetail: PropTypes.object,
  dailyTimecardDetail: PropTypes.object,
};

export default ActivityLocationWidgetComponent;