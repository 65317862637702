import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as JobDucks from 'ducks/jobs/job';
import JobProgressBarSection from 'sections/jobs/form/ProgressBar';
import JobBudgetSection from 'sections/jobs/form/jobBudget';
import TwoColumnLayout from 'layouts/TwoColumn';

import { NAV } from 'constants/jobs';



class JobBudgetPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parent: NAV.JobEditFormPage.parent,
      isJobAddInfo: true, isJobEditInfo: true };
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    this.props.InfoDucks.getInformations({route: 'job.edit'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(data){
    this.setState({ isLoading: true });
    // this.props.JobDucks.getJob({ params  })
    //   .then(() => this.setState({ isLoading: false }))
    //   .catch(() => this.setState({ isLoading: false }));

  }

  loadData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: {  query } } = this.props;
    this.setState({ isLoading: true });
    this.props.JobDucks.getJob(params)
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    const cookieKey = cookie.load("jobs_edit_form_info");
    if(cookieKey) {
      this.setState({isJobEditInfo: JSON.parse(cookieKey)});
    }
    this.setParentUrl(query);
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isJobAddInfo, isJobEditInfo } = this.state;
    if (params.id) {
      this.setState({ isJobEditInfo: !isJobEditInfo });
      cookie.save("jobs_edit_form_info", !isJobEditInfo);
    }
    else {
      this.setState({ isJobAddInfo: !isJobAddInfo });
      cookie.save("job_add_form_info", !isJobAddInfo);
    }
  }

  render() {
    const { isLoading , isJobEditInfo, isJobAddInfo } = this.state;
    const { location: {  query }, infoList, jobDetail } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const selectedId = params ? params.id : null;
    return (
      <div>
        <TwoColumnLayout
          navInfo={query.setup ? NAV.JobAddFormPage : query.admin_list ? NAV.AdminJobEditFormPage : NAV.JobEditFormPage}
          infoList={infoList}
          isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          jobDetail={jobDetail}
        >
        <div className="pb-5">
          <Row className="pt-4 mr-4 ml-3" style={{backgroundColor: 'white'}}>
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <JobProgressBarSection
                    active={2}
                    selectedId={selectedId}
                    isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
                    addJob={query.setup}
                    location={this.props.location}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <JobBudgetSection isLoading={isLoading} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  jobDetail: JobDucks.jobDetail(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

JobBudgetPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

JobBudgetPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobBudgetPage);
