import React from 'react';

import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';


class DashboardOnboardPopUpModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { update } = this.props;
    return update();
  }
  

  
  render() {
    const { isOpen, toggle , handleSubmit ,submitting } = this.props;

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="pop-up-modal"
          backdrop='static'
        >
          <ModalHeader className="mb-0 text-center pb-0"> Congratulations !</ModalHeader>
          <Form noValidate onSubmit={handleSubmit(this.submit)} >
            <ModalBody className="text-center pt-2">
              <Row>
                <Col>
                  <h5>You have successfully completed the tutorial. </h5>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="text-right pt-0">
              <Button className="btn-other" type="submit">Got it! <ButtonLoaderAtom active={submitting}/></Button>  
            </ModalFooter>
          </Form>  
        </Modal>
      </div>  
    );
  }
};


export default reduxForm({
  form: 'DashboardOnboardPopUpModal',
})(DashboardOnboardPopUpModalComponent);
