import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import PayrollSettingFilterSection from 'sections/payrolls/setting/Filter';
import PayrollSettingListSection from 'sections/payrolls/setting/List';

import { NAV } from 'constants/payrolls';

/**
 * PayrollSettingListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link PayrollSettingFormSection}
 *    - {@link PayrollSettingFilterSection}
 *
 * Fetch Needs:
 *    - getPayrollSetting
 */
class PayrollSettingListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    
    const cookieKey = cookie.load("payroll_setting_list_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('payroll_setting_page_size');
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'payroll.setting.list'}),
      this.props.PayrollSettingDucks.getPayrollSettings({...params, page_size: pageSize})
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("payroll_setting_list_info", !isInfoOpen);
  }

  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  render() {
    const { infoList } = this.props;
    const { isInfoOpen } = this.state;
    return (
      <TwoColumnLayout
        navInfo={NAV.PayrollSettingListPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <PayrollSettingFilterSection />
        <PayrollSettingListSection isLoading={this.state.isLoading} />
      </TwoColumnLayout>
    );
  } 
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

PayrollSettingListPage.propTypes = {
  PayrollSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

PayrollSettingListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PayrollSettingListPage);
