import React from 'react';

import queryString from 'query-string';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as PluginListDucks from 'ducks/vendors/plugin';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';

import TwoColumnLayout from 'layouts/TwoColumn';
import QBContentSection from 'sections/vendors/pluginList/QBContent';

import { NAV } from 'constants/vendors';


class QBDesktopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true, parent: NAV.QBDesktopPage.parent };
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("plugin");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.fetchData();
  }

  setParentUrl(query) {
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.query);
        this.setState({
          parent: `${query.parent}?${queryString.stringify(parsedParams)}`,
        });
      }

    } catch (e) {
      console.log(e);
    }
  }

  fetchData(){
    const { location: { query }} = this.props;
    this.setState({ isLoading: true });
    this.setParentUrl(query);
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.plugin.quickbooks-desktop'}),
      this.props.PluginListDucks.getPluginList(),
      this.props.UserDucks.getProfileUser(),
      /*this.props.PluginListDucks.getProfile(),*/
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("plugin", !isInfoOpen);
  }

  render() {
    const { infoList } = this.props;
    const { isInfoOpen, parent } = this.state;
    return (
      <TwoColumnLayout 
        navInfo={{ ...NAV.QBDesktopPage, parent }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <QBContentSection
          isLoading={this.state.isLoading}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  PluginListDucks: bindActionCreators(PluginListDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

QBDesktopPage.propTypes = {
  PluginListDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(QBDesktopPage);