import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import InputAtom from 'atoms/Input';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';
import { FORM_LAYOUT } from 'constants/layout';

/**
 * ChangePasswordFormPage -> ChangePasswordFormSection -> ChangePasswordFormComponent
 *
 * Props:
 *    - changePassword
 */
const ChangePasswordFormComponent = (props) => {
  const { handleSubmit, submitting, reset, error, changePassword } = props;

  const submit = data => changePassword(data)
    .then(reset)
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="pl-3">
      <div className="error-text">{error}</div>
      <Row>
        <Col xs={10} md={6} className="pr-0">
          <Field
            id="current_password"
            type="password"
            name="current_password"
            label="Current Password"
            component={InputAtom}
            placeholder="Current Password"
            layout={FORM_LAYOUT}
            data-lpignore={true}
          />
          <Field
            id="password"
            type="password"
            name="password"
            label="New Password"
            component={InputAtom}
            placeholder="New Password"
            layout={FORM_LAYOUT}
            data-lpignore={true}
            onBlur={(e) => {props.strengthCheckToggle()}}
            onFocus = {props.strengthCheckToggle}
          />
          {props.showStrengthCheck &&
            <PasswordStrengthMeter
              values={{password : props.password}}
            />
          }
          <Field
            id="re_password"
            type="password"
            name="re_password"
            label="Confirm New Password"
            component={InputAtom}
            placeholder="Confirm New Password"
            layout={FORM_LAYOUT}
            data-lpignore={true}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Button
            type="submit"
            color="secondary"
            className="float-sm-right my-4"
            size={FORM_LAYOUT.size}
          >
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};
  const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
  const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
  const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
  if (!values.current_password) errors.current_password = 'Enter Current Password';
  if (!values.password) errors.password = 'Enter New Password';
  if (values.password && values.password.length < 8) errors.password = 'Password should contain atleast 8 characters';
  if (values.password && values.password.length >= 8 && values.password && (values.password.match(passwordRegex) || values.password.match(passwordSequential) || values.password.match(passwordRepeated )))errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
  if (!values.re_password) errors.re_password = 'Enter Confirm Password';
  if (values.re_password !== values.password) {
    errors.re_password = 'Password does not match';
  }
  return errors;
};

ChangePasswordFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  changePassword: PropTypes.func.isRequired,
};

const ChangePasswordForm= reduxForm({
  form: 'ChangePasswordForm',
  validate,
  enableReinitialize: true,
})(ChangePasswordFormComponent);

const selector = formValueSelector('ChangePasswordForm');

export default connect(
  (state) => {
    const password = selector(state, 'password');
    return { password };
  },
)(ChangePasswordForm);

