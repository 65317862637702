import EventEmitter from 'lib/event-emitter';
import GraniteError from 'lib/granite-error';

const EVENT_TYPES = {
  SHOW_SUBMIT_FORM_LOADER : 'SHOW_SUBMIT_FORM_LOADER',
  HIDE_SUBMIT_FORM_LOADER : 'HIDE_SUBMIT_FORM_LOADER',
  SUBMIT_SURVEY_FORM_SUCCESS : 'SUBMIT_SURVEY_FORM_SUCCESS',
  SUBMIT_SURVEY_FORM_FAILURE : 'SUBMIT_SURVEY_FORM_FAILURE',
  SHOW_SUBMIT_LOADER: 'SHOW_SUBMIT_LOADER',
  HIDE_SUBMIT_LOADER: 'HIDE_SUBMIT_LOADER',
  SUBMIT_QUES_FORM_SUCCESS: 'SUBMIT_QUES_FORM_SUCCESS',
  SUBMIT_QUES_FORM_FAILURE: 'SUBMIT_QUES_FORM_FAILURE',
  SHOW_OUTCOME_SUBMIT_LOADER: 'SHOW_OUTCOME_SUBMIT_LOADER',
  HIDE_OUTCOME_SUBMIT_LOADER: 'HIDE_OUTCOME_SUBMIT_LOADER',
  SUBMIT_OUTCOME_FORM_SUCCESS: 'SUBMIT_OUTCOME_FORM_SUCCESS',
  SUBMIT_OUTCOME_FORM_FAILURE: 'SUBMIT_OUTCOME_FORM_FAILURE',
  GET_SURVEY_DATA_SUCCESS : 'GET_SURVEY_DATA_SUCCESS',
  SHOW_FORM_LOADER: 'SHOW_FORM_LOADER',
  HIDE_FORM_LOADER: 'HIDE_FORM_LOADER',
  UPDATE_FORM_SUCCESS: 'UPDATE_FORM_SUCCESS',
  UPDATE_FORM_FAILURE: 'UPDATE_FORM_FAILURE',
  SET_DROPDOWN_VALUES: 'SET_DROPDOWN_VALUES',
  HIDE_LOADER : 'HIDE_LOADER',
  SHOW_LOADER : 'SHOW_LOADER'
};


class surveyFormUseCase{

  SurveyAPIGateway = null;

  constructor(SurveyAPIGateway){
    
    this.SurveyAPIGateway = SurveyAPIGateway;
    this.eventEmitter = new EventEmitter();

  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }


  toString(){
    return 'surveyFormUseCase';
  }

   async postSurveyForm(params,type) {
    let updatedParams = params;
    let errors = { };
    if (!params.template_data.label){
      errors.label = 'Enter Name';
    }
    if (params.template_data.label && !params.template_data.label.trim().length){
      errors.label = 'Enter Name';
    }
    if (params.schedule.frequency  && params.schedule.frequency < 0){
      errors.label = 'Hours entered should be greater than 0';
    }
    if(!params.oauth_roles.length){
      errors.role = 'Please select atleast one user role';
    }
    if (!params.survey_type){
      errors.survey_type = 'Please select the survey type';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    if(params.survey_type == 'delayed_meal'){
        updatedParams['survey_type'] = ['delayed_meal']
    }
    else if(params.survey_type == 'missing_meal'){
      updatedParams['survey_type'] = ['missing_meal']
    }
    else if(params.survey_type == 'force_punch_out'){
      updatedParams['survey_type'] = ['force_punch_out']
    }
    else if(params.survey_type == 'geofence_breach'){
      updatedParams['survey_type'] = ['geofence_breach']
    }
    else if(params.survey_type == 'force_punch_out&delayed_meal'){
      updatedParams['survey_type'] = ['force_punch_out', 'delayed_meal']
    }
    else if(params.survey_type == 'force_punch_out&missing_meal'){
      updatedParams['survey_type'] = ['force_punch_out', 'missing_meal']
    }
    else{
      updatedParams.survey_type = ['others']
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_SUBMIT_FORM_LOADER,
      data: type,
    });
    try{
      this.surveyDetails = await this.SurveyAPIGateway.saveNewForm(updatedParams);
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_SURVEY_FORM_SUCCESS,
        data: this.surveyDetails.data,
        buttonType: type,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_SURVEY_FORM_FAILURE,
        data: e,
      });
    }
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_SUBMIT_FORM_LOADER,
      data: type,
    });
  }

  async getFormData(id){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_FORM_LOADER,
    });
    try{
      this.formData = await this.SurveyAPIGateway.getSurveyData(id);
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_SURVEY_DATA_SUCCESS,
        data: this.formData,
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_FORM_LOADER
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_LOADER
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_FORM_LOADER
      });
      console.log('e', e)
    }
  }

  async updateTemplateData(data, id, type) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_SUBMIT_LOADER,
      buttonType: type,
    });
    try{
      this.surveyData = await this.SurveyAPIGateway.updateSurveyData(data, id);
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_QUES_FORM_SUCCESS,
        data: this.surveyData.data,
        buttonType: type,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_QUES_FORM_FAILURE,
        data: e,
      });
    }
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_SUBMIT_LOADER,
      buttonType: type,
    });
  }

  async updateSurveyFormData(data, id, type) {
    let updatedParams = data;
    let errors = { };
    if (!data.template_data.label){
      errors.label = 'Enter Name';
    }
    if (data.template_data.label && !data.template_data.label.trim().length){
      errors.label = 'Enter Name';
    }
    if (data.schedule.frequency  && data.schedule.frequency < 0){
      errors.frequency = 'Hours entered should be greater than 0';
    }
    if(!data.oauth_roles.length){
      errors.role = 'Please select atleast one user role';
    }
    if (!data.survey_type){
      errors.survey_type = 'Please select the survey type';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    if(data.survey_type == 'delayed_meal'){
        updatedParams.survey_type = ['delayed_meal'];
    }
    else if(data.survey_type == 'missing_meal'){
      updatedParams.survey_type = ['missing_meal']
    }
    else if(data.survey_type == 'geofence_breach'){
      updatedParams.survey_type = ['geofence_breach']
    }
    else if(data.survey_type == 'force_punch_out'){
      updatedParams.survey_type = ['force_punch_out']
    }
    else if(data.survey_type == 'force_punch_out&delayed_meal'){
      updatedParams.survey_type = ['force_punch_out', 'delayed_meal']
    }
    else if(data.survey_type == 'force_punch_out&missing_meal'){
      updatedParams.survey_type = ['force_punch_out', 'missing_meal']
    }
    else{
      updatedParams.survey_type = ['others']
    }
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_SUBMIT_LOADER,
      data: type,
    });
    try{
      this.surveyFormData = await this.SurveyAPIGateway.updateSurveyData(updatedParams, id);
      this.eventEmitter.emit({
        type: EVENT_TYPES.UPDATE_FORM_SUCCESS,
        data: this.surveyFormData.data,
        buttonType: type,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.UPDATE_FORM_FAILURE,
        data: e,
      });
    }
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_SUBMIT_LOADER,
      data: type,
    });
  }

  async updateTemplateOutcomeData(data, id) {
    let errors = { };
    if (!data[0].message){
      errors.success_message = 'Enter Success Message';
    }
    if (!data[1].message){
      errors.failed_message = 'Enter Failure Message';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_OUTCOME_SUBMIT_LOADER,
    });
    try{
      this.surveyOutcomeData = await this.SurveyAPIGateway.updateSurveyData({template_data : {scoring_actions: data}}, id);
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_OUTCOME_FORM_SUCCESS,
        data: this.surveyOutcomeData.data,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.SUBMIT_OUTCOME_FORM_FAILURE,
        data: e,
      });
    }
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_OUTCOME_SUBMIT_LOADER,
    });
  }

  setDropDownValues(){
    let departmentList = this.departmentList;
    let teamList = this.teamList;
    let rolesList = this.roleList && this.roleList.length ? this.roleList.filter((i)=> i.is_edit !== false) : [];
    departmentList = departmentList && departmentList.length ? departmentList.map(i => ({ name: i.name, id: i.id })) : []
    teamList = teamList && teamList.length ? teamList.map(i => ({ name: i.name, id: i.id })) : []
    rolesList = rolesList && rolesList.length ? rolesList.map(i => ({ name: i.name, id: i.id })) : []
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_DROPDOWN_VALUES,
      data: {
        roleList : rolesList,
        departmentList: departmentList,
        teamList: teamList,
      }
    });
  }

  async getInitialFieldValues(companyId) {
    const { context } = this.formData || {};
    Promise.all([
      this.roleDetails = this.SurveyAPIGateway.getRolesList({paginate:false}),
      this.SurveyAPIGateway.getDepartmentList({ company_id: JSON.stringify([companyId]), paginate: false, get_active: true, get_active_teams: true}),
      context &&  context.department_ids && context.department_ids.length && this.SurveyAPIGateway.getTeamList({  department_ids : context && context.department_ids ? JSON.stringify(context.department_ids) : null, company_id: JSON.stringify([companyId]), paginate: false, get_active: true}),
    ])
    .then((list) => {
        this.roleList = list[0];
        this.departmentList = list[1].filter((item)=> item.id !== -1);
        this.teamList = list[2] ? list[2] : [];
    })
    .then(() => this.setDropDownValues())
  }

  async handleDepartmentChange(departmentIds){
    if(departmentIds.length){
      this.teamList = await this.SurveyAPIGateway.getTeamList({ department_ids: JSON.stringify(departmentIds), paginate: false, get_active: true}) ;
      this.setDropDownValues();
    }
    else{
      this.teamList = await [];
      this.setDropDownValues();
    }
  }

}

export default surveyFormUseCase;
