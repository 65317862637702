import React from 'react';
import { Row, Col} from 'reactstrap';
import queryString from 'query-string';
import UserAPIGateway from 'accounts/gateways/user';
import VerifyOTPForm from 'accounts/components/verifyOtp';
import ContentLayout from 'layouts/Content';
import VerifyOTPUseCase from 'accounts/usecases/verifyOtp';
import ResetPasswordModal from 'accounts/components/ResetPasswordModal.jsx';
import { toast } from 'react-toastify';

class VerifyOtpPage extends React.PureComponent {
  constructor(props) {
    super(props);    
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new VerifyOTPUseCase(userAPIGateway);
    this.state = {  uid: '', password: null , ismodalState : false};
    this.listenVerifyOtpUsecase = this.listenVerifyOtpUsecase.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }
   componentWillMount() {
    const { location: { search, state } } = this.props;
    const query = queryString.parse(search);
    this.updateState('uid', query.uid);
    this.setState({uid: query.uid, password: state.password})
    this.listenVerifyOtpUsecase();
  }

    updateModalState(){
    const {ismodalState} = this.state;
    this.setState({ismodalState: true});
  }


   
  listenVerifyOtpUsecase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'VERIFY_SUCCESS':
          toast.success("OTP verified successfully");
          if(event.data.is_business_admin){
            this.props.history.push('/vendor/list/all');
          }
          else{
            this.props.history.push('/dashboard');
          }
          break;
          case 'VERIFY_FAILURE':
          toast.success("OTP verified successfully");
          if(event.data.is_business_admin){
            this.props.history.push('/vendor/list/all');
          }
          else{
            this.props.history.push('/dashboard');
          }
          break;
         default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    const { ismodalState } = this.state;
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col xs={12} md={{ size:4, offset:4 }} className="pt-5" >
            <VerifyOTPForm  usecase={this.usecase} uid={this.state.uid} password={this.state.password}/>
          </Col>
        </Row> 
        {ismodalState &&
        <ResetPasswordModal 
          isOpen = {ismodalState}
        />

      }  
      </ContentLayout> 
    );
  }
}


export default VerifyOtpPage;
