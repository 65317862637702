import React from 'react';
import PropTypes from 'prop-types';

import NavTabComponent from 'components/common/NavTab';

const TabsSection = (props) => {
  const { tabItems } = props;
  return (
    <NavTabComponent tabItems={tabItems} />
  );
};

TabsSection.defaultProps = {
  tabItems: [
    { href: '/vendor/basic-setting', label: 'Basic' },
    { href: '/vendor/system-setting', label: 'System' },
    { href: '/vendor/notification-setting', label: 'Notification' },
  ],
};

TabsSection.propTypes = {
  tabItems: PropTypes.array,
};

export default TabsSection;
