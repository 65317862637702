import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';
import cookie from 'react-cookies';

import { SORT_DIRS } from 'constants/records';
import {ReactComponent as SortIcon} from 'components/common/SortHeader/sort.svg';
import {ReactComponent as SortAscIcon} from 'components/common/SortHeader/sort_asc.svg';
import {ReactComponent as SortDescIcon} from 'components/common/SortHeader/sort_desc.svg';

class SortHeaderComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.sortDirection = this.sortDirection.bind(this);

    const { router: { route: { location: { search } } } } = context;
    const query = queryString.parse(search);
    const MaterialSorting =  cookie.load("materialSorting");
    this.state = { material_sort: query.material_sorting ?  query.material_sorting : MaterialSorting};
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { location: { search: prevSearch } } } } = this.context;
    const { router: { route: { location: { search: nextSearch } } } } = nextContext;
    const query = queryString.parse(nextSearch);
    const prevQuery = queryString.parse(prevSearch);
    const MaterialSorting =  cookie.load("materialSorting");

    if (prevQuery.material_sorting !== query.material_sorting || (this.state.material_sort == null && MaterialSorting)) {
      this.setState({ material_sort: query.material_sorting  ? query.material_sorting : MaterialSorting});
    }

  }

  handleChange() {
    const { router: { history, route: { location: { pathname, search } } } } = this.context;
    const { material_sorting } = this.props;
    const query = queryString.parse(search);

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        material_sorting: `${query.material_sorting && query.material_sorting[0] === SORT_DIRS.DESC
          ? SORT_DIRS.ASC : SORT_DIRS.DESC}${material_sorting}`,
      }),
    });
  }

  sortDirection() {
    const { router: { route: { location: { search } } } } = this.context;
    const { material_sorting } = this.props;
    const { material_sort } = this.state;
    const query = queryString.parse(search);
    const MaterialSorting =  cookie.load("materialSorting");
    const a = query.material_sorting  ? query.material_sorting : MaterialSorting;

    if (material_sort && (a === material_sorting || a === `-${material_sorting}` )) {
      return a[0] === SORT_DIRS.DESC 
        ? <SortDescIcon width="12" height="12" />
        : <SortAscIcon width="12" height="12" />;
    }
    return <SortIcon width="12" height="12" />;
  }

  render() {
    const { children , className} = this.props;
    return (
      <div style={{'marginTop':'-3px'}}>
        {children}
        <Button
          color="link"
          className="p-0 pl-1"
          onClick={this.handleChange}
        >
          {this.sortDirection()}
        </Button>
      </div>
    );
  }
}

SortHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  ordering: PropTypes.string.isRequired,
};

SortHeaderComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SortHeaderComponent;
