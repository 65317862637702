import React from 'react';
import moment from 'moment';

import {Card , Row, Col, Input, FormGroup } from 'reactstrap';
import styles from './styles.module.scss';
import Flatpickr from 'react-flatpickr';

const DateTimeCommentComponent = (props) => {
  const { data , index, timeFormat24Hrs, dateFormatDisplay, auditComponent } = props;
  return (
    <Card className={`${styles['card-ui-blank']} mr-3 pl-3 pr-3 pt-1 pb-1`}>
      <Row>
      <Col md={12} className={`${styles['custom-col']} pl-1`}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span className={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? `${styles['questions-ui']} ${styles['questions-no-padding']} pr-0 pl-2` : `${styles['questions-no-padding']} pl-2`} dangerouslySetInnerHTML={{ __html: data.question }}></span>
        </Col>
      </Row>
        {!auditComponent && data && data.response && data.response.length ? data.response.map((i, index)=>
            <div>
                {i.date ? 
                <div className={`${styles['resize-disabled']}`} style={{marginTop: '0.8rem'}}>
                    <FormGroup>
                        <Input
                            type="textarea"
                            id={`date_${index}`}
                            name={`date_${index}`}
                            placeholder="Date"
                            rows="1"
                            value={i.date ? moment(i.date).format(dateFormatDisplay) : null}
                            disabled
                        />
                    </FormGroup>
                </div>
                : null }
                {i.time ? 
                <div className={`${styles['resize-disabled']} mt-2`}>
                    <FormGroup>
                        <Input
                            type="textarea"
                            id={`time_${index}`}
                            name={`time_${index}`}
                            placeholder="Time"
                            rows="1"
                            value={i.time ? timeFormat24Hrs ? moment(i.time).format('HH:mm') :  moment(i.time).format('hh:mm a') : null}
                            disabled
                        />
                    </FormGroup>
                </div>
                : null }
            </div>
        ): null
      }
      { auditComponent && data && data.answer && data.answer.length ? data.answer.map((i, index)=>
            <div>
                {i.date ? 
                <div className={`${styles['resize-disabled']}`} style={{marginTop: '0.8rem'}}>
                    <FormGroup>
                        <Input
                            type="textarea"
                            id={`date_${index}`}
                            name={`date_${index}`}
                            placeholder="Date"
                            rows="1"
                            value={i.date ? moment(i.date).format(dateFormatDisplay) : null}
                            disabled
                        />
                    </FormGroup>
                </div>
                : null }
                {i.time ? 
                <div className={`${styles['resize-disabled']} mt-2`}>
                    <FormGroup>
                        <Input
                            type="textarea"
                            id={`time_${index}`}
                            name={`time_${index}`}
                            placeholder="Time"
                            rows="1"
                            value={i.time ? timeFormat24Hrs ? moment(i.time).format('HH:mm') :  moment(i.time).format('hh:mm a') : null}
                            disabled
                        />
                    </FormGroup>
                </div>
                : null }
            </div>
        ): null
      }
    </Card>
  );
};

export default DateTimeCommentComponent;