import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';

/**
 * ForgotPasswordPage -> ForgotPasswordFormSection -> VerificationFormModalComponent
 *
 * Props:
 *    - update
 */
class VerificationFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { submitOtp } = this.props;
    if(!data.code){
      const errObj = {
          _error: ['Enter OTP'],
        };
        throw new SubmissionError(errObj);
    }
    return submitOtp(data)
      .catch((err) => {
        const errObj = {
          _error: [err.code],
        };
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const { handleSubmit, submitting, error, isOpen, toggle, vendorDetail} = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
      >
        <ModalHeader toggle={toggle}> {vendorDetail.is_phone_verified ? 'Email' : 'Phone'} Verification</ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody className="pt-0">
            <div className="error-text">{error}</div>
            <Row sm={12} className="pt-2">
              <Col md={6}>
                <div >
                  <Field id="code" name="code"  label="OTP" component={InputAtom}/>
                </div>
              </Col>
          </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size} >
              VERIFY <ButtonLoaderAtom active={submitting} />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
};

VerificationFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitOtp: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'VerificationFormModal',
  validate,
})(VerificationFormModalComponent);