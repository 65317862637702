import { queryHelpers } from '@testing-library/dom';
import React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import styles from './styles.module.scss';

class EmployeeListingList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {data, location, isShowDeviation, onEmployeeClickRedirect, selectedEmployeeIds} = this.props;
    const {query} = location;
    return (
      <section>
        <div style={{fontWeight: 'bold'}} className="mt-2 mb-3">Employee Listing</div>
        <InfiniteScroll
          className={selectedEmployeeIds && selectedEmployeeIds.length ? styles['infinite-scroll-length-selected-employee'] : styles['infinite-scroll-length']}
        >
          {data && data.results && data.results.length ? data.results.map(item => {
            return(<div>
              <div className={`${styles['employee-list']} d-flex  pb-2`} style={query.employee_id==item.employee__id?{backgroundColor:'rgb(204, 210, 213)'}:null}>
                <div className={`${styles['employee-circle']} mt-2`}> <div className={styles['employee-text']}>{item.employee__user__first_name.charAt(0).toUpperCase()}</div></div>
                <div className={`${styles['employee-name-timecard-lsting']} mt-2 d-flex align-items-sm-center`} onClick={()=>onEmployeeClickRedirect(item)}>{item.employee__user__first_name}&nbsp;{item.employee__user__last_name}</div>
                {item.color && isShowDeviation && <span className={styles['circle-wrap']} style={{ background: item.color }}/>}
              </div>
            </div>)
          }) 
            :<div className={styles['no-employee-records']}>No Records Found</div>
          }
        </InfiniteScroll>
      </section>
    );
  }
}


export default EmployeeListingList;