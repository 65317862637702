import React from 'react';

import { Popover } from 'reactstrap';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import Button from 'reactstrap/lib/Button';

import { STATUSES } from 'constants/activities';
import styles from './styles.module.scss';

class TooltipFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen : false}
  }


  render() {
    const { 
      data, 
      onValiditySelection, 
      dropdownToggle, 
      saveValidity,
      valueValidity,
      dropdownOpen,
      updateBoxes,
      ApprovedIcon,
      AwaitingIcon,
      InvalidatedIcon,
      selectedId,
      openState,
      ValidityName,
      selectedItem,
      stateText,
      isValidityBoxLoading,
      childItem,
      keyname,
      showCorrectionMessage
    } = this.props;
    const isApproved = childItem && childItem.is_approved ? childItem.is_approved : false;
    return (
      <section className="d-inline-block">
        <div id={`${stateText}${keyname}`} className='d-inline-block tooltip-override-div'>
          <span role="button" tabIndex={0} title= {ValidityName} className="" onClick={() => updateBoxes(stateText, !openState, `${stateText}${keyname}`)}> 
            <img src = { (data.status === "1" && ApprovedIcon) || (data.status ==="3" && AwaitingIcon) || (data.status==="2" && InvalidatedIcon)} 
              height="18"
              width="18"
              className="cursor-pointer mr-1"
              alt="validity"
            />
          </span>
        </div>
        <Popover placement="bottom" target={`${stateText}${keyname}`} isOpen={(`${stateText}${keyname}`=== selectedId) && openState} className={styles['tooltip-wrapper']} >
          <div className="custom-task-override">
            {data && data.extra_data && data.extra_data.correction_message && showCorrectionMessage ?
              <div>
                <div>Timefence Breach Message   <button type="button" className="close d-inline-block close-icon" aria-label="Close" onClick={() => updateBoxes(stateText, !openState, `${stateText}${keyname}`)}><span aria-hidden="true">×</span></button> </div>
                  <div style={{fontSize : '12px'}}>{data.extra_data.correction_message}</div>
                  <div className="mt-2">{ValidityName}</div>
              </div>
              :
              <div>{ValidityName}   <button type="button" className="close d-inline-block close-icon" aria-label="Close" onClick={() => updateBoxes(stateText, !openState, `${stateText}${keyname}`)}><span aria-hidden="true">×</span></button> </div>
            }
            
            <Dropdown isOpen={dropdownOpen} toggle={()=>dropdownToggle()} className="pt-2">
              <DropdownToggle caret color="primary" className={styles['button-export']}>
                { (selectedItem === data.id)? valueValidity.label:data.status_display}
              </DropdownToggle>
              <DropdownMenu className="popover-override">
                {STATUSES && STATUSES.map((vailidity)=>(
                  <DropdownItem key={keyname}  onClick={()=>onValiditySelection(vailidity, data.id)}>
                    {vailidity.label}
                  </DropdownItem>
                ))}  
              </DropdownMenu>
            </Dropdown>
            <div className="text-right">
              <Button className={`${styles['tooltip-btn']} mt-2`} onClick={()=>saveValidity(valueValidity, data)} disabled={isValidityBoxLoading || isApproved}>

              Save <ButtonLoaderAtom active={isValidityBoxLoading}/></Button>
            </div>  
          </div>  
        </Popover>
      </section>      
    );
  }
}


export default TooltipFormComponent;
