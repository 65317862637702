import React from 'react';

import TwoColumnLayout from 'layouts/TwoColumn';
import PluginADPComponent from 'components/vendors/plugins/ADPContent';

import { NAV } from 'constants/vendors';


class ADPDesktopPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, parent: NAV.ADPDesktopPage.parent };
    this.setTimeOut = this.setTimeOut.bind(this);
  }

  componentWillMount(){
    this.setTimeOut();
  }

  setTimeOut(){
    this.setState({ isLoading: true });
    setTimeout(() => { 
      this.setState({ isLoading: false });
    }, 1000);
  }

  render() {
    const { parent, isLoading } = this.state;
    return (
      <TwoColumnLayout 
        navInfo={{ ...NAV.ADPDesktopPage, parent }}
      >
        <PluginADPComponent isLoading={isLoading}/>
      </TwoColumnLayout>
    );
  }
}

export default ADPDesktopPage;