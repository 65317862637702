import combineReducers from 'redux/lib/combineReducers';

import activity from './activity';
import activitySplit from './activitySplit';
import activityTrack from './activityTrack';
import missingActivity from './missingActivity';
import missingEntry from './missingEntry';
import session from './session';

export default combineReducers({
  activity,
  activitySplit,
  activityTrack,
  missingActivity,
  missingEntry,
  session,
});
