import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';
import closeimage from './Close-Cross.png';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> ConfirmationModal
 *
 * Props:
 *    - update
 */
class ConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log('');
  }

  render() {
    const { isOpen,
      toggle, deleteActivity } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader>
          Confirm Delete
          <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <ModalBody>
          <h5>Are you sure you want to delete the activity/task?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="default" className={styles['cancel-btn']} onClick={() => toggle()} size={FORM_LAYOUT.size} >
            Cancel
          </Button>
          <Button type="submit" color="secondary" onClick={() => deleteActivity()} size={FORM_LAYOUT.size} >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};


ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default ConfirmationModal;