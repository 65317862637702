import EventEmitter from 'lib/event-emitter';
import {EVENT_TYPES} from 'employees/usecases/string.js';


class employeeJobsListUseCase{

  EmployeeJobsAPIGateway = null;

  /**
   * @constructor
   * @param {Object} EmployeeJobsAPIGateway - API gateway instance for managing employee jobs.
   */
  constructor(EmployeeJobsAPIGateway){
    
    this.EmployeeJobsAPIGateway = EmployeeJobsAPIGateway;
    this.eventEmitter = new EventEmitter();

  }

  /**
   * @function getObservable
   * @description Returns the observable instance for event handling.
   * @returns {Observable} - Observable instance for events.
   */
  getObservable() {
    return this.eventEmitter.getObservable();
  }

  /**
   * @function toString
   * @description Returns a string representation of the use case.
   * @returns {string} - Name of the use case.
   */
  toString() {
    return 'employeeJobsListUseCase';
  }

  /**
   * @function setup_data
   * @description Sets up the data for assigned and unassigned jobs.
   * @param {Object} assignJobObj - Object containing details for assigning jobs.
   * @param {Object} unassignObj - Object containing details for unassigning jobs.
   */
  setup_data(assignJobObj, unassignObj) {
    this.assignJobObj = assignJobObj;
    this.unassignObj= unassignObj

  }

  /**
   * @function getAssignedJobs
   * @description Fetches the list of assigned jobs and emits relevant events.
   * @param {Object} params - Parameters for fetching assigned jobs.
   */
  async getAssignedJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.assignedJobs = await this.EmployeeJobsAPIGateway.getAssignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  /**
   * @function getUnassignedJobs
   * @description Fetches the list of unassigned jobs and emits relevant events.
   * @param {Object} params - Parameters for fetching unassigned jobs.
   */
  async getUnassignedJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.EmployeeJobsAPIGateway.getUnassignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
  }

  /**
   * @function fetchJobs
   * @description Fetches both assigned and unassigned jobs and emits relevant events.
   * @param {Object} assignObj - Parameters for fetching assigned jobs.
   * @param {Object} unassignObj - Parameters for fetching unassigned jobs.
   */
  async fetchJobs(assignObj, unassignObj) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.EmployeeJobsAPIGateway.getUnassignedJobsList(unassignObj);
    this.assignedJobs = await this.EmployeeJobsAPIGateway.getAssignedJobsList(assignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  /**
   * @function assignJobs
   * @description Assigns jobs to employees and emits relevant events.
   * @param {Object} params - Parameters for assigning jobs.
   */
  async assignJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGN_BUTTON_LOADER,
    });
    this.postAssignJobsList = await this.EmployeeJobsAPIGateway.assignJobs(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ASSIGN_SUCCESS,
    });
    await this.fetchJobs(this.assignJobObj, this.unassignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_ASSIGN_BUTTON_LOADER,
    });
  }

  /**
   * @function deleteJob
   * @description Deletes a job assignment and emits relevant events.
   * @param {Object} params - Parameters for deleting the job.
   */
  async deleteJob(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.updatedEmployeeJobsList = await this.EmployeeJobsAPIGateway.deleteEmployeeJob(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.DELETE_SUCCESS,
    });
    let assignFetch = {...this.assignJobObj, page: 1};
    await this.fetchJobs(assignFetch, this.unassignObj);
  }
}


export default employeeJobsListUseCase;