import React from "react";

import queryString from "query-string";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import cookie from "react-cookies";

import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Button from "reactstrap/lib/Button";
import * as UserDucks from "ducks/accounts/user";
import * as JobDucks from "ducks/jobs/job";
import * as JobEmployeeDucks from "ducks/jobs/jobEmployeeMapping";
import * as RouteDucks from "ducks/routes";
import * as CustomerDucks from "ducks/customers/customer";
import * as JobTaskDucks from "ducks/jobs/jobTaskMapping";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as EmployeeDucks from "ducks/employees/employee";
import * as DepartmentDucks from "ducks/vendors/department";
import * as TeamDucks from "ducks/vendors/team";
import { toast } from "react-toastify";
import styles from "sections/jobs/jobEmployeeEdit/styles.module.scss";

import SectionLoaderAtom from "atoms/SectionLoader";
import EmployeeTableComponent from "components/jobs/jobEmployeeEdit/EmployeeTable";
import MealEmpFilter from "./MealEmpFilter";
import MealEmpTable from "./MealEmpTable";
import PaginationComponent from 'components/common/Pagination';
import EmployeeDeleteModal from "components/jobs/jobEmployeeEdit/EmployeeDeleteModal";

class MealEmpSelectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.goTo = this.goTo.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.assignByDept = this.assignByDept.bind(this);
    this.jobList = this.jobList.bind(this);
    this.state = {
      isEmployeeTableLoading: false,
      isEmployeeSubmitting: false,
      assigningDepartment: false,
      assigningTeam: false,
      assigningEmployee: false,
      isTeamSubmitting: false,
      nextLoader: false,
      isdeleteModal: null,
      isSubmittingModal: false,
      mealTypes: {},
      employeeassignedList: [],
      is_active:null,
      count:null,
    };
    this.deleteEmployeeAssignment = this.deleteEmployeeAssignment.bind(this);
    this.assignByEmployee = this.assignByEmployee.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.assignByTeam = this.assignByTeam.bind(this);
    this.assignToJob = this.assignToJob.bind(this);
    this.assignAllEmployeesToJob = this.assignAllEmployeesToJob.bind(this);
    this.assignByTag = this.assignByTag.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
    this.setSelectedRow = this.setSelectedRow.bind(this);
  }
  componentWillMount() {
    const {
      location: { query },
    } = this.props;
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({
        paginate: false,
        get_active: true,
        get_active_teams: true,
      }),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        get_active: true,
      }),
      this.props.EmployeeDucks.getEmployeeTags({
        paginate: false,
        tag_type: 3,
      }),
      this.props.getMealTypes({
        company_id: this.props.userExtraData?.company?.id,
        page_size: query.page_size,
        paginate: true,
        id: this.props.selectedId,
      }),
    ])
      .then((responses) => {
        const mealTypesResponse = responses[3];
        this.setState({
          is_active: mealTypesResponse?.value?.is_active,
          mealTypes: mealTypesResponse?.value?.rule_configuration,
        });
      })
      .catch((error) => {
        toast.error(error.non_field_errors[0]);
      });

      this.props.getAssignedEmployees({
        company_id: this.props.userExtraData?.company?.id,
        page_size: query?.page_size,
        paginate: true,
        id: this.props.selectedId,
        page: query?.page
      })
      .then((responses) => {
        const mealTypesResponse = responses;
        this.setState({
          employeeassignedList: mealTypesResponse?.value?.results,
          count: mealTypesResponse?.value?.count,
        });
      })
      .catch((error) => {
        toast.error(error.non_field_errors[0]);
      });
  }



  componentWillReceiveProps(nextProps) {
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (prevQuery !== nextQuery) {
      this.props.getAssignedEmployees({
        company_id: this.props.userExtraData?.company?.id,
        page_size: nextQuery?.page_size,
        paginate: true,
        id: this.props.selectedId,
        page: nextQuery?.page
      })
      .then((responses) => {
        const mealTypesResponse = responses;
        this.setState({
          employeeassignedList: mealTypesResponse?.value?.results,
          count: mealTypesResponse?.value?.count,
        });
      })
      .catch((error) => {
        toast.error(error.non_field_errors[0]);
      });
    }
  }


  getEmployees(data) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    return this.props.EmployeeDucks.getEmployees({
      ...data,
      assigned: false,
      is_active: true,
      job_id: params.id,
      paginate: false,
    });
  }

  assignByDept(dept) {
    let department_ids = [];
    department_ids =
      dept && dept.length ? dept.map((item) => item.value) : dept.value;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const detail = {
      assigned: true,
      department_ids: department_ids,
      id: params.id,
    };
    if (dept.value === "") {
      return Promise.reject(toast.error("Please select department to assign"));
    }
    this.setState({ assigningDepartment: true });
    return this.assignToJob(detail);
  }

  assignByTag(tag) {
    let tags = [];
    tags = tag && tag.length ? tag.map((item) => item.value) : tag.value;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const detail = {
      assigned: true,
      tags: tags,
      id: params.id,
    };
    if (tag.value === "") {
      return Promise.reject(toast.error("Please select department to assign"));
    }
    this.setState({ assigningDepartment: true });
    return this.assignToJob(detail);
  }

  assignByTeam(team) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    let team_ids = [];
    team_ids = team && team.length ? team.map((item) => item.id) : team.id;
    const detail = {
      assigned: true,
      team_ids: team_ids,
      id: params.id,
    };
    /*if(dept.value === ''){
      return Promise.reject(toast.error('Please select department to assign'));
    }
    if(team.value === ''){
      return Promise.reject(toast.error('Please select team to assign'));
    }*/
    this.setState({ assigningTeam: true });
    return this.assignToJob(detail);
  }

  assignToJob(detail) {
    const {
      location: { pathname, query },
    } = this.props;
    const {
      router: { history },
    } = this.context;
    return this.props
      .putMealTypes(detail)
      .then(() => {
        toast.success("Employees added successfully to adjustments!!!")
        const { page, pageSize, ...rest } = query;
        this.setState({
          isEmployeeSubmitting: false,
          isTeamSubmitting: false,
          assigningDepartment: false,
          assigningTeam: false,
          assigningEmployee: false,
        });
        this.props
          .getAssignedEmployees({
            company_id: this.props.userExtraData?.company?.id,
            page_size: query?.page_size,
            paginate: true,
            is_active: true,
            id: this.props.selectedId,
            page: query?.page
          })
          .then((responses) => {
            const mealTypesResponse = responses;
            this.setState({
              employeeassignedList: mealTypesResponse?.value?.results,
            });
          })
          .catch((error) => {
            toast.error(error.non_field_errors[0]);
          });
        history.push({ pathname, search: queryString.stringify(rest) });
      })
      .catch((e) => {
        this.setState({
          isEmployeeSubmitting: false,
          isTeamSubmitting: false,
          assigningTeam: false,
          assigningEmployee: false,
        });
        toast.error(e.non_field_errors[0]);
      });
  }

  assignByEmployee(data) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const { userExtraData } = this.props;
    const company_id = userExtraData.company.id;
    if (!(data))
      return Promise.reject(toast.error("Please select employee to assign"));
    const detail = {
      company_id: company_id,
      id: params.id,
      employee_ids: data,
      rule_configuration: this?.state?.mealTypes,
      operation: "add",
    };
    this.setState({ assigningEmployee: true });
    return this.assignToJob(detail);
  }

  jobList() {
    const {
      router: { history },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    const pathname = `/job/list?status=1`;
    const newPath = `/job/list/new?status=1`;
    query.admin_list ? history.push(newPath) : history.push(pathname);
  }

  createOrUpdate(data) {
    const detail = data;
    return this.props.JobDucks.postJob(detail).then((res) => {
      this.setState({ job: res.value });
      toast.success("Job successfully created !");
    });
  }

  goTo(url, data) {
    const {
      router: {
        history,
        route: {
          match: { params },
        },
      },
    } = this.context;
    this.props.JobDucks.setJob(data);
    if (params.id) {
      history.push(url);
      this.setState({ nextLoader: true });
    }
  }

  deleteEmployeeAssignment(employee) {
    const {
      router: {
        history,
        route: {
          match: { params: newParams },
        },
      },
    } = this.context;
    const {
      location: { pathname, query },
      jobDetail,
      selectedId,
      userExtraData,
    } = this.props;
    const { isdeleteModal, isSubmittingModal } = this.state;
    const tagsId =
      jobDetail && jobDetail.tags && jobDetail.tags.map((item) => item);
    const company_id = userExtraData.company.id;
    const params = {
      company_id: company_id,
      id: selectedId,
      employee_ids: [employee?.employee_id],
      rule_configuration: this?.state?.mealTypes,
      operation: "remove",
    };
    this.setState({ isSubmittingModal: true });
    const { page, pageSize, ...rest } = query;
    return this.props
      .putMealTypes(params)
      .then(() => {
        toast.success("Employees removed successfully from adjustments!!!")
        this.props
          .getAssignedEmployees({
            company_id: this.props.userExtraData?.company?.id,
            page_size: query?.page_size,
            paginate: true,
            is_active: true,
            id: this.props.selectedId,
            page: query?.page
          })
          .then((responses) => {
            const mealTypesResponse = responses;
            this.setState({
              employeeassignedList: mealTypesResponse?.value?.results,
            });
          })
          .catch((error) => {
            toast.error(error.non_field_errors[0]);
          })
          .then(() => {
            history.push({ pathname, search: queryString.stringify(rest) });
            this.setState({
              isEmployeeTableLoading: false,
              isdeleteModal: null,
              isSubmittingModal: false,
            });
          });
      })
      .catch((error) => {
        toast.error(error.non_field_errors[0]);
      });
  }

  deleteEmployee(data) {
    const { isdeleteModal } = this.state;
    this.setState({ isdeleteModal: data });
  }

  goToUrl(url) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    if (query.setup) {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query, setup: true }),
      });
    } else {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query }),
      });
    }
  }

  assignAllEmployeesToJob() {
    const {
      router: {
        history,
        route: {
          match: { params: newParams },
        },
      },
    } = this.context;
    const {
      location: { pathname, query },
      jobDetail,
      userExtraData,
      selectedId,
    } = this.props;
    const company_id = userExtraData.company.id;
    const assignEmployeeToJobObj = {
      // id: [jobDetail.id],
      company_id: company_id,
      employee_ids: [],
      id: selectedId,
      rule_configuration: this?.state?.mealTypes,
      select_all_employees: true,
      operation: "add",
    };
    const { page, pageSize, ...rest } = query;
    return this.props
      .putMealTypes(assignEmployeeToJobObj)
      .then(() => {
        toast.success("Employees added successfully to adjustments!!!")
        const { page, pageSize, ...rest } = query;
        this.setState({
          isEmployeeSubmitting: false,
          isTeamSubmitting: false,
          assigningDepartment: false,
          assigningTeam: false,
          assigningEmployee: false,
        });
        this.props
          .getAssignedEmployees({
            company_id: this.props.userExtraData?.company?.id,
            page_size: query?.page_size,
            paginate: true,
            is_active: true,
            id: this.props.selectedId,
            page: query?.page
          })
          .then((responses) => {
            const mealTypesResponse = responses;
            this.setState({
              employeeassignedList: mealTypesResponse?.value?.results,
            });
          })
          .catch((error) => {
            toast.error(error.non_field_errors[0]);
          });
      })
      .catch((e) => {
        this.setState({
          isEmployeeSubmitting: false,
          isTeamSubmitting: false,
          assigningTeam: false,
          assigningEmployee: false,
        });
        toast.error(e.non_field_errors[0]);
      });
  }

  setSelectedRow(data) {
    const {
      router: {
        history,
        route: {
          match: { params: newParams },
        },
      },
    } = this.context;
    const {
      location: { pathname, query },
      jobDetail,
    } = this.props;
    const pageSizeCookie = cookie.load("employee_job_page_size") || 10;
    let detail = {
      wage: data.wage,
    };
    this.setState({ isEmployeeTableLoading: true });
    this.props.EmployeeDucks.updateEmployeeWage(data.id, detail)
      .then(() => {
        setTimeout(() => {
          this.props.EmployeeDucks.getAssignedEmployees({
            ...query,
            ...newParams,
            job_id: jobDetail.id,
            assigned: true,
            page_size: query.page_size ? query.page_size : pageSizeCookie,
            page: query?.page
          }).then(() => {
            this.setState({ isEmployeeTableLoading: false });
          });
        }, 1000);
      })
      .catch((e) => {
        this.setState({ isEmployeeTableLoading: false });
        e.wage && toast.error(e.wage);
      });
  }

  render() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      isEmployeeSubmitting,
      isTeamSubmitting,
      isdeleteModal,
      isSubmittingModal,
      employeeassignedList,
    } = this.state;
    const {
      location,
      departmentAll,
      teamAll,
      isOrganizationVisible,
      userExtraData,
      isLoading,
      selectedId,
    } = this.props;
    const {
      assigningDepartment,
      isEmployeeTableLoading,
      assigningTeam,
      assigningEmployee,
    } = this.state;
    const { query } = location;
    return (
      <section style={{ padding: "0 50px", color: "#242424" }}>
        <MealEmpFilter
          isLoading={isLoading}
          location={location}
          getEmployees={this.getEmployees}
          assignByEmployee={this.assignByEmployee}
          departmentAll={departmentAll}
          teamAll={teamAll}
          isOrganizationVisible={isOrganizationVisible}
          userExtraData={userExtraData}
          assignByDept={this.assignByDept}
          assignByTeam={this.assignByTeam}
          assigningTeam={assigningTeam}
          assigningEmployee={assigningEmployee}
          assignAllEmployeesToJob={this.assignAllEmployeesToJob}
          tagsList={this.props.tagsList}
          assignByTag={this.assignByTag}
          is_active={this.state.is_active}
          employeeList={this.props.employeeList}
        />
        {isLoading ? (
          <div style={{ position: "relative" }}>
            <SectionLoaderAtom active />
          </div>
        ) : (
          <section>
            <MealEmpTable
              data={employeeassignedList}
              location={location}
              deleteEmployeeAssignment={this.deleteEmployeeAssignment}
              isEmployeeTableLoading={isEmployeeTableLoading}
              deleteEmployee={this.deleteEmployee}
              setSelectedRow={this.setSelectedRow}
              is_active={this.state.is_active}

            />
              <PaginationComponent
          count={this.state.count}
          location={this.props.location}
        />
            <EmployeeDeleteModal
              isOpen={isdeleteModal}
              toggle={this.deleteEmployee}
              deleteEmployeeAssignment={this.deleteEmployeeAssignment}
              isSubmitting={isSubmittingModal}
              title={"Remove employee"}
              description={"Are you sure you want to remove the employee from this Meal Adjustment?"}
            />
           

            <div>
              <Row>
                <Col md={12} className="mt-2 mb-3 pr-0 text-right">
                  <Button
                    color="primary"
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                    className={`${styles["btn_accent_width"]} btn-accent`}
                    onClick={() =>
                      this.goToUrl(
                        `/vendor/meal-adjustments/${selectedId}/mealSettings`
                      )
                    }
                  >
                    previous
                  </Button>
                  <Button
                    color="primary"
                    className={`${styles["btn_accent_width"]} btn-accent`}
                    onClick={() => this.goToUrl(`/vendor/meal-adjustments`)}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </section>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  customerList: CustomerDucks.customerList(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  employeeList: EmployeeDucks.employeeList(state),
  allEmployeeList: EmployeeDucks.employeeAll(state),
  tagsList: EmployeeDucks.tagsList(state),
});

const mapActionToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  JobEmployeeDucks: bindActionCreators(JobEmployeeDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  JobTaskDucks: bindActionCreators(JobTaskDucks, dispatch),
  putMealTypes: bindActionCreators(AdjustmentTypesDucks.putMealTypes, dispatch),
  getAssignedEmployees: bindActionCreators(AdjustmentTypesDucks.getAssignedEmployees, dispatch),
  getMealTypes: bindActionCreators(AdjustmentTypesDucks.getMealTypes, dispatch),

});

MealEmpSelectionForm.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  jobData: PropTypes.object.isRequired,
  customerList: PropTypes.array.isRequired,
};

MealEmpSelectionForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionToProps)(MealEmpSelectionForm);
