import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_KNOWLEDGE_BASES = 'knowledgeBases/knowledgeBase/GET_KNOWLEDGE_BASES';
const GET_KNOWLEDGE_BASES_FULFILLED = 'knowledgeBases/knowledgeBase/GET_KNOWLEDGE_BASES_FULFILLED';
const GET_KNOWLEDGE_BASE = 'knowledgeBases/knowledgeBase/GET_KNOWLEDGE_BASE';
const GET_KNOWLEDGE_BASE_FULFILLED = 'knowledgeBases/knowledgeBase/GET_KNOWLEDGE_BASE_FULFILLED';
const PUT_KNOWLEDGE_BASE = 'knowledgeBase/knowledgeBases/PUT_KNOWLEDGE_BASE';
const PUT_KNOWLEDGE_BASE_FULFILLED = 'knowledgeBases/knowledgeBase/PUT_KNOWLEDGE_BASE_FULFILLED';
const POST_KNOWLEDGE_BASE = 'knowledgeBase/knowledgeBases/POST_KNOWLEDGE_BASE';
const POST_KNOWLEDGE_BASE_FULFILLED = 'knowledgeBases/knowledgeBase/POST_KNOWLEDGE_BASE_FULFILLED';


export function getKnowledgeBases(params) {
  return {
    type: GET_KNOWLEDGE_BASES,
    payload: axios.get(URLS.KNOWLEDGE_BASE, { params }),
  };
}

export function getKnowledgeBase(data) {
  return {
    type: GET_KNOWLEDGE_BASE,
    payload: axios.get(`${URLS.KNOWLEDGE_BASE}${URLS.UPDATE}${data.id}/`, { data }),
  };
}

export function putKnowledgeBase(data) {
  const object = {};
  data.forEach((value, key) => {
    object[key] = value;
  });
  return {
    type: PUT_KNOWLEDGE_BASE,
    payload: axios.put(`${URLS.KNOWLEDGE_BASE}${URLS.UPDATE}${object.id}/`, data),
  };
}

export function postKnowledgeBase(data) {
  return {
    type: POST_KNOWLEDGE_BASE,
    payload: axios.post(URLS.KNOWLEDGE_BASE, data),
  };
}


const defaultState = {
  list: {},
  detail: {},
};

function GET_KNOWLEDGE_BASES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_KNOWLEDGE_BASE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_KNOWLEDGE_BASE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_KNOWLEDGE_BASE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_KNOWLEDGE_BASES_FULFILLED]: GET_KNOWLEDGE_BASES_FULFILLED_REDUCER,
  [GET_KNOWLEDGE_BASE_FULFILLED]: GET_KNOWLEDGE_BASE_FULFILLED_REDUCER,
  [PUT_KNOWLEDGE_BASE_FULFILLED]: PUT_KNOWLEDGE_BASE_FULFILLED_REDUCER,
  [POST_KNOWLEDGE_BASE_FULFILLED]: POST_KNOWLEDGE_BASE_FULFILLED_REDUCER,
};


const knowledgeBaseSelector = state => state.knowledgeBases.knowledgeBase;


export const knowledgeBaseList = createSelector(
  knowledgeBaseSelector,
  instance => instance.list,
);

export const knowledgeBaseDetail = createSelector(
  knowledgeBaseSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
