import React from "react";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";
import { exportFile } from "helpers/utils";
import { toast } from "react-toastify";

import * as RouteDucks from "ducks/routes";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import bindActionCreators from "redux/lib/bindActionCreators";

import { NAV } from "constants/forms";
import TwoColumnLayout from "layouts/TwoColumn";

import PaginationComponent from "components/common/Pagination";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";

import FormResponseFilterComponent from "forms/components/formResponseFilter";
import FormResponseTableComponent from "forms/components/formResponseTable";

import moment from "moment";
class FormResponseList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      templateData: [],
      formResponseListCount: 0,
      isLoading: true,
      parent: null,
      isExporting: false,
    };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.handleExport = this.handleExport.bind(this);
  }

  componentDidMount() {
    this.listenListUsecase();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  fetchData(payload) {
    const {
      match: { params },
    } = this.props;
    let obj = {
      ...payload,
      id: params.id,
    };
    delete obj?.parent;
    delete obj?.resp_end_date;
    delete obj?.resp_start_date;
    this.usecase.getResponceData(obj);
  }

  componentWillMount() {
    this.setParentUrl();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  loadData() {
    const {
      match: { params },
      location: { search, pathname },
      history,
    } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("form_response_page_size") || 10;
    if (!("page_size" in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: pageSize,
          page: query.page || 1,
          is_active: true,
          end_date: moment().format("YYYY-MM-DD"),
          start_date: moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_RESPONCE_DATA_SUCCESS":
          this.updateState("templateData", event.data);
          this.updateState("formResponseListCount", event.data.count);
          break;
        case "GET_RESPONCE_DATA_FAILED":
          this.updateState("templateData", []);
          this.updateState("formResponseListCount", 0);
          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  setParentUrl() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    if (query?.parent) {
      this.setState({
        parent: query.parent,
      });
    }
  }

  handleExport() {
    const {
      match: { params },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("form_response_page_size") || 10;
    let obj = {
      id: params.id,
      page_size: pageSize,
      page: query.page || 1,
      start_date: query.start_date,
      end_date: query.end_date,
    };
    this.setState({ isExporting: true });
    this.usecase
      .handleExport(obj)
      .then((res) => {
        exportFile(
          { action: { payload: res } },
          `${query.form_name} ${
            query.start_date ? query.start_date + " to" : ""
          }  ${query.end_date ? query.end_date : ""}.xlsx`
        );
        this.setState({ isExporting: false });
      })
      .catch((e) => {
        toast.error("Something went wrong!");
        this.setState({ isExporting: false });
      });
  }

  render() {
    const { dateFormat, location, history } = this.props;
    const query = queryString.parse(location.search);
    const {
      isLoading,
      templateData,
      parent,
      formResponseListCount,
      isExporting,
    } = this.state;
    const navInfo = {
      ...NAV.AnswersList,
      parent,
      title: query?.form_name,
    };
    return (
      <div>
        <div>
          <TwoColumnLayout
            navInfo={navInfo}
            fetchData={() => this.fetchData(query)}
          >
            <div>
              <div
                style={{ background: "white" }}
                className="mt-2 ml-3 mr-4 pb-3 pt-3"
              >
                <FormResponseFilterComponent
                  location={location}
                  history={history}
                  dateFormat={dateFormat}
                  handleExport={this.handleExport}
                  isExporting={isExporting}
                  initialValues={
                    query.start_date && query.end_date
                      ? {
                          resp_end_date: moment(query.end_date).toISOString(),
                          resp_start_date: moment(
                            query.start_date
                          ).toISOString(),
                        }
                      : {
                          resp_end_date: moment().toISOString(),
                          resp_start_date: moment()
                            .subtract(7, "days")
                            .toISOString(),
                        }
                  }
                />
                <FormResponseTableComponent
                  headers={templateData?.headers ?? []}
                  data={templateData?.data ?? []}
                  isLoading={isLoading}
                  fetchData={() => this.fetchData(query)}
                />
              </div>
              {!isLoading && formResponseListCount ? (
                <PaginationComponent
                  count={formResponseListCount}
                  location={location}
                  cookieKey="form_response_page_size"
                  history={this.props.history}
                />
              ) : null}
            </div>
          </TwoColumnLayout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormResponseList);
