import React from 'react';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as CustomerTagDucks from 'ducks/customers/tag';
import * as UserDucks from 'ducks/accounts/user';
import * as CustomerDucks from 'ducks/customers/customer';
import * as JobDucks from 'ducks/jobs/job';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import CustomerTableComponent from 'components/customers/list/Table';
import CustomerFormModalComponent from 'components/customers/list/FormModal';
import TagModalComponent from 'components/jobs/form/TagModal';

/**
 * CustomerListPage -> CustomerListSection
 *
 * Components:
 *    - {@link CustomerTableComponent}
 *
 * State:
 *    - customerDetails
 *
 * Actions:
 *    None
 */
class CustomerListSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.create = this.create.bind(this);
    this.createTag = this.createTag.bind(this);
    this.state = { isOpen: false, isTagLoading: false, isTagModal: false, isModalHidden:false};
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  toggle(data) {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.props.CustomerDucks.getCustomersDetails(data)
        .then(() => this.setState({ isOpen: !isOpen })
        );
    }
    else{
      this.setState({ isOpen: !isOpen });
    }
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  getUpdatedList(){
    return this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  create(data) {
    const { isOpen } = this.state;
    const {location:{pathname, search} } = this.props;
    const { router: { history } } = this.context;
    const query = queryString.parse(search);
    return this.props.CustomerDucks.putCustomersDetails(data)
      .then(() => {
         history.push({
           pathname,
          search:queryString.stringify({...query,page:1})
        });
        this.props.CustomerDucks.getCustomers({...query,page:1});
        this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
        this.setState({ isOpen: !isOpen });
      });  
  }

  createTag(data) {
    const { change } = this.props;
    this.setState({ isTagLoading: true });
    return this.props.CustomerTagDucks.postCustomerTag({ ...data, tag_type: 0 })
  }

  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }


  render() {
    const { isLoading,customerList, location, customerDetails, customerTagList } = this.props;
    const { isOpen, isTagLoading, isTagModal, isModalHidden } = this.state;
    let newTags;
    if (customerDetails.id) {
      newTags = customerDetails.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
    }
    const tags = customerTagList&& customerTagList.length && customerTagList[0].tag_collection && customerTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="customer-list">
        <CustomerTableComponent
          data={customerList}
          location={location}
          toggle={this.toggle}
        />
        {isOpen &&
          <CustomerFormModalComponent
            isOpen={isOpen}
            tagList={customerTagList}
            toggle={this.toggle}
            create={this.create}
            createTag={this.createTag}
            isTagLoading={isTagLoading}
            toggleTagModal={this.toggleTagModal}
            initialValues={{
              ...customerDetails,
              point_of_contact:customerDetails.user.full_name,
              phone_ext: customerDetails.phone_ext,
              tags: newTags,
            }}
            edit
            isModalHidden={isModalHidden}
          />
        }
        {isTagModal &&
          <TagModalComponent
            isModalOpen={isTagModal}
            toggle={this.toggleTagModal}
            createTag={this.createTag}
            tagType="0"
            tagsList = {tags}
            tagsCollection={customerTagList}
            deleteSelectedTags={this.deleteSelectedTags}
            updateTagName={this.updateTagName}
            getUpdatedList={this.getUpdatedList}
            hideModal={this.hideModal}
          />
        }
        <PaginationComponent
          count={customerList.count}
          location={location}
          cookieKey="customer_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  customerList: CustomerDucks.customerList(state),
  customerDetails: CustomerDucks.customerDetails(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
  location: RouteDucks.location(state),
  userCompany: UserDucks.userCompany(state),
  companyPermissions: UserDucks.companyPermissions(state),
});

const mapActionsToProps = dispatch => ({
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

CustomerListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userCompany: PropTypes.object.isRequired,
  location: PropTypes.object,
  customerTagList: PropTypes.array,
  customerDetails:PropTypes.object,
};

CustomerListSection.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerListSection);