import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as InfoDucks from 'ducks/info/info';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import TwoColumnLayout from 'layouts/TwoColumn';
import ActivitySplitFilterSection from 'sections/activities/activitySplitList/Filter';
import ActivitySplitListSection from 'sections/activities/activitySplitList/List';

import { NAV } from 'constants/activities';

/**
 * ActivityHistoricSplitListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ActivitySplitFilterSection}
 *    - {@link ActivitySplitListSection}
 *
 * Fetch Needs:
 *    - getActivitySplits
 */
class ActivityHistoricSplitListPage extends React.Component {
  constructor(props) {
    super(props);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false,
      isLocationLoading:false,
      parent: NAV.ActivityHistoricSplitListPage.parent,
      isInfoOpen:true,
      permission:true, };
  }

  componentWillMount() {
    const cookieKey = cookie.load("activities_historic_info");

    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'activity.historic.split'});
    this.fetchData();
  }


  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    const parsedParams = JSON.parse(query.parent);
    const parent = `/timecard/historic`;
    try {
      if (query.parent) {
        this.setState({
          parent: `${parent}?${queryString.stringify(parsedParams)}`,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ parent });
    }
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    this.props.TimecardDucks.getTimecard({ id: params.timecard })
      .then(() => this.setParentUrl(query));
    this.props.ActivityTrackDucks.getJobActivityTrack({id: params.id})
      .then(() => {
        this.setState({ permission: true });
      })
      .catch(() => {
        this.setState({ permission: false });
      });
    Promise.all([
      this.props.DailyTimecardDucks.getDailyTimecardsDetail({ id: params.id }),
      this.props.GeoFenceDucks.getDailyTimecardActivityTrackGeofences({id: params.id, paginate:false }),
      this.props.ActivitySplitDucks.getActivitySplits({ id: params.id })
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("activities_historic_info", !isInfoOpen);
  }

  render() {
    const { timecardDetail, infoList, location:{query}} = this.props;
    const { isLoading, parent, isInfoOpen, isLocationLoading, permission } = this.state;
    const parsedParam = JSON.parse(query.parent);
    const breadcrumb = [
      ...NAV.ActivitySplitListPage.breadcrumb,
      { href: `/timecard/historic?employee_id=${parsedParam.employee_id}`, label: 'Historic Timecards' },
      { label: 'Activities' },
    ];
    const title = Object.keys(timecardDetail).length
      ? `${NAV.ActivityHistoricSplitListPage.title} (${timecardDetail.employee.user.first_name} ${timecardDetail.employee.user.last_name})`
      : NAV.ActivityHistoricSplitListPage.title;
    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.ActivityHistoricSplitListPage, title, breadcrumb,parent
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <ActivitySplitFilterSection />
        <ActivitySplitListSection
          isLoading={isLoading} 
          isLocationLoading={isLocationLoading}
          permission={permission}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  location: RouteDucks.location(state),
  timecardDetail: TimecardDucks.timecardDetail(state),
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

ActivityHistoricSplitListPage.propTypes = {
  activitySplitList: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  timecardDetail: PropTypes.object.isRequired,
  ActivitySplitDucks: PropTypes.object.isRequired,
  TimecardDucks: PropTypes.object.isRequired,
  DailyTimecardDucks: PropTypes.object.isRequired,
  dailyTimecardDetail: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList:PropTypes.object.isRequired,
};

ActivityHistoricSplitListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivityHistoricSplitListPage);
