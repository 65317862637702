import React from 'react';
import SubmissionError from 'redux-form/lib/SubmissionError';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import { Formik,Field } from 'formik';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';

import reduxForm from 'redux-form/lib/reduxForm';
import Form from 'reactstrap/lib/Form';
import moment from 'moment';
import PropTypes from 'prop-types';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { FILTER_FORM_LAYOUT } from 'constants/layout';

import { ISACTIVE_FILTER_OPTIONS, SURVEY_TYPE_FILTER_OPTIONS } from 'constants/surveys';
import MultiSelectAtom from 'atoms/FormikMultiselectDropdown';

import styles from './styles.module.scss';


class SurveyTemplateFilterFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data){
    const { router: { history } } = this.context;
    const {
      dateFormat,
      location: { pathname, query },
      handleSubmit,
    } = this.props;
    const { page, ...rest } = query;
    const detail = { ...data };
    const { page: pageNo, ...restDetail } = detail;
    restDetail.create_date_from = moment(data.create_date_from).format('YYYY-MM-DD');
    restDetail.create_date_to = moment(data.create_date_to).format('YYYY-MM-DD');
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail }),
    });
  }

  render() {
    const {dateFormat, location, handleSubmit, initialValues } = this.props;
    const { router: { history } } = this.context;
    return (
      <div className="ml-3 mr-4">
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            render={({
              values,
            }) => (
        <Row className="filter">
          <Col className="float-left" md={8}>
          <div className="float-left d-inline-block">
            <FilterDropdownComponent
              options={ISACTIVE_FILTER_OPTIONS}
              location={location}
              paramKey="is_active"
            />
          </div>
          <div className="float-left d-inline-block" style={{width: '255px'}}>
            <Field
              id="survey_type"
              name="survey_type"
              value={values.survey_type}
              component={MultiSelectAtom}
              items={SURVEY_TYPE_FILTER_OPTIONS}
              placeholder="Select Survey Types"
              itemToString={i => i && i.name}
              onChange={(i) => this.props.getFilteredList(i)}
            />
          </div>
          </Col>
          <Col md={4}>
            <div className="float-right">
              <FilterFormComponent
                location={location}
                placeholder="Search by survey name"
                // initialValues={query}
              />
            </div>
          </Col>
        </Row>
        )}/>
      </div>
    );
  }}

const validate = (values) => {
  const errors = {};
  const formattedStartDate=moment(values.create_date_from);
  const formattedEndDate=moment(values.create_date_to);
  if (!values.create_date_from) errors.create_date_from = 'Select Start Date';
  else if (!values.create_date_to) errors.create_date_to = 'Select End Date'; 
  else if(formattedStartDate > formattedEndDate) errors.create_date_from ='Start date should be less than end date.';
  return errors;
};


SurveyTemplateFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const SurveyTemplateFilterForm = reduxForm({
  form: 'SurveyTemplateFilterForm',
  validate,
})(SurveyTemplateFilterFormComponent);

const selector = formValueSelector('SurveyTemplateFilterForm');

export default connect(
  (state) => {
    const job = selector(state, 'job');
    const task = selector(state, 'task');
    return { job, task };
  },
)(SurveyTemplateFilterForm);