 /*import  GraniteError from 'lib/GraniteError'; */
class BillEntity {
  constructor(object={}){

    this.trial_end_date = object.trial_end_date;
 }

 


  
  toJSON() {
    const { trial_end_date } = this;
    return {trial_end_date };
  }
}

export default BillEntity;


