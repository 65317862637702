import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import MealFlagDetailEmployeeTableComponent from 'components/reports/mealFlagDetails/EmployeeDetailTable';

/**
 * MeanViolationListSection -> OverTimeSummaryListSection
 *
 * Components:
 *    - {@link MealViolationDetailTableComponent}
 *    - {@link PaginationComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */

class MealDetailByEmployeeListSection extends React.Component {

  constructor(props){
    super(props);
    this.navigation = this.navigation.bind(this);
    
  }

  navigation(item){
    const { location : { pathname, query }, data } = this.props;
    const { router: {  route: { match: { params } } } } = this.context;
    const parentObj = {
      parent: `${pathname}?${queryString.stringify(query)}`,
      start_date: query.start_date,
      end_date: query.end_date,
      payroll_id: 'custom',
      employee_id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      custom : true,
      show_employee_list : false,
      empployee_dept_name: data[0]?.department_name,
      employee_team_name: data[0]?.team_name,
      employee_title: data[0]?.employee_title,
      employee_id_show: data[0]?.employee_id,

    };
    //const url = `/activity/timecard/custom/list/${item.daily_timecard_id}/split?${queryString.stringify(parentObj)}`;
    const url = `/timecard/custom/list/${item.id}/daily?${queryString.stringify(parentObj)}`;
    
    
    return window.open(url, '_blank');
  }

  render() {
    const { data, isLoading, location } = this.props;
    const {query} = location;
    const cookieData = cookie.load("meal_flag_detail_page_size");
    let pageSize;

    if (query && query.page_size)  pageSize = +query.page_size;
    else if(cookieData) pageSize = +cookieData;
    else pageSize = 10;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <MealFlagDetailEmployeeTableComponent
          data={data}
          location={location}
          pageSize={pageSize}
          navigation={this.navigation}
        />
        <PaginationComponent
          count={data.length}
          location={location}
          cookieKey="meal_flag_detail_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state), 
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
});

const mapActionsToProps = dispatch => ({
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
});

MealDetailByEmployeeListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  //  DailyTimecardDucks: PropTypes.object.isRequired,
};

MealDetailByEmployeeListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};


export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MealDetailByEmployeeListSection);

