class FieldFormsEntity {
    constructor(object={}){
        this.name = object?.name || '';
        this.status = object?.is_active;
        this.assigned_to = object?.assigned_to || 0;
        this.count_of_responses = object?.count_of_responses || '';
        this.created_by = object?.created_by || '';
        this.date_created = object?.create_date_display || '';
        this.id = object?.id || null;
        this.template_data = object?.template_data || [];
        this.employee_ids = object?.employee_ids || [];
   }
  }
  class formsResponseHeaderEntity {
    constructor(object={}){
        this.name = object?.name || '';
        this.status = object?.is_active ? 'active' : 'inactive';
        this.assigned_to = object?.assigned_to || 0;
        this.count_of_responses = object?.count_of_responses || '';
        this.created_by = object?.created_by || '';
        this.date_created = object?.create_date_display || '';
        this.id = object?.id || null;
        this.template_data = object?.template_data || []
   }
  }
  
  class formsResponceDataEntity {
    constructor(object={}){
        this.name = object?.name || '';
        this.status = object?.is_active ? 'active' : 'inactive';
        this.assigned_to = object?.assigned_to || 0;
        this.count_of_responses = object?.count_of_responses || '';
        this.created_by = object?.created_by || '';
        this.date_created = object?.create_date_display || '';
        this.id = object?.id || null;
        this.template_data = object?.template_data || []
   }
  }
  
  export  {FieldFormsEntity,formsResponseHeaderEntity,formsResponceDataEntity};