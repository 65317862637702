import React from 'react';

import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import SectionLoaderAtom from 'atoms/SectionLoader';  

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import TwoColumnLayout from 'layouts/TwoColumn';
import JobFilterSection from 'sections/jobs/list/Filter';
import JobListSection from 'sections/jobs/list/List';

import { NAV } from 'constants/jobs';

/**
 * JobListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobSchedulesFilterSection}
 *    - {@link EmployeeListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class JobListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.cardViewToggle = this.cardViewToggle.bind(this);
    this.updateList =this.updateList.bind(this);
    this.updateQuery =this.updateQuery.bind(this);
    this.refresh = this.refresh.bind(this);
    this.refreshDisable = this.refreshDisable.bind(this);
    this.goTo = this.goTo.bind(this);
    this.state = {  isInfoOpen: true, cardView: false, params: null , isLoadingSection: false, refreshLoader : false, isLoading: false};
  }

  componentWillMount() {
    const cookieKey = cookie.load("job_list_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'job.list'});
    this.props.JobDucks.getJobsUserPreference({preference_type: 'user_preference_job_center' })
    .then((res) => {
      let jobPreference =  res.value.data;
      this.loadData(jobPreference);
    });
  }

  
  componentWillReceiveProps(nextProps) {
    const { router: { history } } = this.context;
    const { location: { pathname, query: prevQuery } , jobsUserPreferences } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const status = cookie.load('job_status');
    const jobStartDate = cookie.load('job_start_date');
    const jobEndDate = cookie.load('job_end_date');
    const recurringSearchCookie = cookie.load('recurring_search');
    const projectSearchCookie = cookie.load('project_search');
    const materialSearchCookie = cookie.load('material_search');
    let data = { status : nextQuery.status , 
        start_date : nextQuery.start_date, end_date : nextQuery.end_date , 
        recurring_search : nextQuery.recurring_search , project_search : nextQuery.project_search , material_search : nextQuery.material_search,
        recurring_grouped_by : nextQuery.recurring_grouped_by , project_grouped_by : nextQuery.project_grouped_by ,
        material_grouped_by : nextQuery.material_grouped_by
    }
    if (prevQuery !== nextQuery && nextQuery) {
      this.fetchData(nextQuery);
      this.props.JobDucks.updateUserJobPreference( data, {preference_type: 'user_preference_job_center' });
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  refresh() {
    this.setState({refreshLoader : true});
    const RecurringpageSizeCookie = cookie.load('RecurringpageSize');
    const ProjectpageSizeCookie = cookie.load('ProjectpageSize');
    const MaterialPageSizeCookie = cookie.load('MaterialPageSize');
    const cookieKey = cookie.load('tableView');
    const { location: { query } } = this.props;
    let bill_type;
    let job_type;
    let params;
    let pageSize;
    const { recurring_search, project_search, material_search,search, ...rest} = query;
    if(cookieKey && cookieKey.quotedRecurring === true ){
      bill_type="QUOTED";
      job_type="RECURRING";
      pageSize = RecurringpageSizeCookie;
        params = { ...rest, search : recurring_search ? recurring_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }
    else if (cookieKey && cookieKey.quotedProject === true){
      bill_type="QUOTED";
      job_type="PROJECT";
      pageSize = ProjectpageSizeCookie;
      params = { ...rest, search : project_search ? project_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }
    else if (cookieKey && cookieKey.timeAndMaterials == true){
      bill_type="TIME_AND_MATERIAL";
      pageSize = MaterialPageSizeCookie;
      params = { ...rest, search : material_search ? material_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }

    if(cookieKey && cookieKey.quotedRecurring === true){
      this.props.JobDucks.getQuotedRecurringJobs(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else if(cookieKey&&cookieKey.quotedProject === true){
      this.props.JobDucks.getQuotedProjectJobs(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else if(cookieKey && cookieKey.timeAndMaterials == true){
      this.props.JobDucks.getTimeAndMaterialJobs(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else{
      this.setState({refreshLoader : false})
    }
  }

  loadData(jobPreference){
    const { router: { history } } = this.context;
    const { location: { pathname, query } , jobsUserPreferences } = this.props;
    this.props.JobDucks.resetJob();
    const jobStattus = cookie.load('job_status') || jobPreference.status;
    const jobStartDate = cookie.load('job_start_date') || jobPreference.start_date;
    const jobEndDate = cookie.load('job_end_date') || jobPreference.end_date
    const recurringSearchCookie = cookie.load('recurring_search') || jobPreference.recurring_search;
    const projectSearchCookie = cookie.load('project_search') || jobPreference.project_search;
    const materialSearchCookie = cookie.load('material_search') || jobPreference.material_search;
    const recurringGroupBy = cookie.load('recurring_grouped_by') || jobPreference.recurring_grouped_by;
    const projectGroupBy = cookie.load('project_grouped_by') || jobPreference.project_grouped_by;
    const materialGroupBy = cookie.load('material_grouped_by') || jobPreference.material_grouped_by;
    if (!('status' in query) || !('start_date' in query) || !('end_date' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          recurring_search: query.recurring_search ? query.recurring_search : recurringSearchCookie,
          project_search: query.project_search ? query.project_search : projectSearchCookie,
          material_search: query.material_search ? query.material_search : materialSearchCookie,
          status: jobStattus || "1",
          start_date: jobStartDate ||  moment().startOf('month').format('YYYY-MM-DD'),
          end_date: jobEndDate ||  moment().format('YYYY-MM-DD'),
          recurring_grouped_by : recurringGroupBy || 'no_grouping',
          project_grouped_by : projectGroupBy || 'no_grouping',
          material_grouped_by : materialGroupBy || 'no_grouping'
        }),
      });
    }
    else {
      this.fetchData(query, jobPreference);
    }
  }

  fetchData(params, jobPreference) {
    const { location: {  query } } = this.props;  
    const { recurring_search, project_search, material_search , status , ...rest} = params;
    this.props.BasicSettingDucks.getBasicSetting();
    this.setState({ isLoadingSection: true });
    const jobStatus = cookie.load('job_status') || jobPreference && jobPreference.status;
    const jobStartDate = cookie.load('job_start_date') || jobPreference && jobPreference.start_date;
    const jobEndDate = cookie.load('job_end_date') || jobPreference && jobPreference.end_date;
    const data = {
      status: params && params.status && params.status !== '0' ? params.status : null,
      page_size: params.page_size,
      page : params.page,
    };
    delete data.page_size;
    if(!params.status){
      params.status= jobStatus || '1';
      params.end_date= jobStartDate || query.end_date;
      params.start_date= jobEndDate || query.start_date;
    }
    const updatedParams = {status: status !== '0' ? status : null,...rest}
    this.setState({ isLoadingSection: true });
    this.props.JobDucks.getTableCounts(updatedParams);
    this.props.JobDucks.getJobsIds({ ...data, paginate: false })
    .then( () => this.setState({isLoadingSection : false}))
    /*this.props.JobDucks.getTimeAndMaterialJobs(params)
    this.props.JobDucks.getQuotedProjectJobs(params)
    this.props.JobDucks.getQuotedRecurringJobs(params)*/
  }

  updateList() {
    const { location: { query } } = this.props;
    this.setState({ isLoadingSection: true });
    this.props.JobDucks.getJobs({...query, })
      .then(() => this.setState({ isLoadingSection: false }))
      .catch(() => this.setState({ isLoadingSection: false}));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("job_list_info", !isInfoOpen);
  }

  refreshDisable(boolean){
    this.setState({isLoading: boolean});
  }
  
  cardViewToggle() {
    const { cardView } = this.state;
    this.setState ({ cardView: !cardView });
  }

  updateQuery(d) {
    this.setState({ params: d});
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const {  isInfoOpen, cardView, params , isLoading} = this.state;
    const { infoList, isGeoFenceVisible } = this.props;    
    const { location: { query } } = this.props;
    const tabs = [
      { href: '/job/list', label: 'Customers/jobs' },
      { href: '/job/lists/task', label: 'Tasks'},
    ];
    if(isGeoFenceVisible){
      tabs.push({ href: '/geofence/job/lists', label: 'Geofences' });
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    else{
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    return (
      <section>
        <TwoColumnLayout
          infoList={infoList}
          navInfo={NAV.JobListPage}
          tabItems={tabs.filter(item => item)}
          toggleInfo={this.toggleInfo}
          isInfoOpen={isInfoOpen}
          isLoading={isLoading}
          createJob="createJob"
          goTo={this.goTo}
        >
        {!(query.start_date && query.end_date && query.status) ?
          <SectionLoaderAtom active />
          :
          <div>
            <JobFilterSection
              cardViewToggle={this.cardViewToggle}
              queryparams={params}
              jobStatusCookieKey="job_status"
              jobStartDateCookieKey="job_start_date"
              jobEndDateCookieKey="job_end_date"
            />
            <JobListSection
              isLoading={isLoading}
              isLoadingProject={this.state.isLoadingProject}
              isLoadingMaterial={this.state.isLoadingMaterial}
              cardView={cardView}
              updateList={this.updateList}
              updateQuery={this.updateQuery}
              isLoadingSection={this.state.isLoadingSection}
              refreshLoader={this.state.refreshLoader}
              refreshDisable={this.refreshDisable}
            />
          </div>          
        }
        </TwoColumnLayout>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
  /*jobList: JobDucks.jobList(state),*/
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  jobsUserPreferences : JobDucks.jobsUserPreferences(state)
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

JobListPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
};

JobListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobListPage);
