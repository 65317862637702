import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  RESENT_SUCCESS: 'RESENT_SUCCESS',
  RESENT_FAILURE: 'RESENT_FAILURE',
};


class ResetPasswordUseCase {
 
  userAPIGateway = null;
  

  constructor(userAPIGateway) {
    
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'resetPasswordUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data) {
    this.password = data.password;
    this.re_password = data.re_password;
    this.uid = data.uid;
    this.inputType = data.inputType;
  }
     
    
   
  async execute() {
    let errors = {};
    const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';

    if(!this.password){
      errors.password = 'Enter Password';
    }
    if(!this.re_password && this.inputType==="password"){
      errors.re_password = 'Enter Confirm Password';
    }
    if(this.password && this.re_password && (this.password !== this.re_password) && this.inputType==="password"){
      errors.re_password = 'Password and Confirm Password does not match';
    }
    if (this.password && this.password.length < 8){
      errors.password = 'Password should contain atleast 8 characters';
    }
    if (this.password && this.password.length >= 8 && this.password && (this.password.match(passwordRegex) || this.password.match(passwordSequential) || this.password.match(passwordRepeated ))) {
      errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
    } 
    
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    if(this.inputType === "text"){
        this.re_password = this.password;
    }
    await this.userAPIGateway.resetPassword(this.password, this.re_password , this.uid );
     this.eventEmitter.emit({
      type: EVENT_TYPES.RESENT_SUCCESS,
    });
  }}
   



export default ResetPasswordUseCase;
