import React from 'react';
import queryString from 'query-string';

class SurveyFormProgress extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.goToUrl = this.goToUrl.bind(this);
  }

  goToUrl(selectedId, type){
    const { history , query } = this.props;
    if(type == 'step1'){
      history.push({
        pathname: `/surveys/${selectedId}/edit`,
        search: queryString.stringify(query),
      });
    }
    else if(type == 'step2'){
      history.push({
        pathname: `/surveys/${selectedId}/questions`,
        search: queryString.stringify(query),
      });
    }
    else{
      history.push({
        pathname: `/surveys/${selectedId}/outcomes`,
        search: queryString.stringify(query),
      });
    }
  }

  render() {
    const { stepNumber,isStepThree, selectedId } = this.props;
    return (
      <div className="step_progressbar">       
          {isStepThree ?
            <ul className="progressbar-ul pl-0 pt-5">
              <li onClick={selectedId ? ()=> this.goToUrl(selectedId , 'step1') : null} className={
                !selectedId 
                ?
                  (stepNumber===2 || stepNumber == 3) 
                    ? "progressbar-li active selected-progressbar-li" 
                    : "progressbar-li selected-progressbar-li selected-progressbar-li-active"
                :
                  (stepNumber == 1)
                    ? "cursor-pointer progressbar-li selected-progressbar-li active selected-progressbar-li-active"
                    : "cursor-pointer progressbar-li selected-progressbar-li active"
                }>Details</li>
              <li onClick={selectedId ? ()=> this.goToUrl(selectedId , 'step2') : null} className={
                !selectedId ?
                  stepNumber===3 
                    ? "progressbar-li active selected-progressbar-li" 
                    : stepNumber === 2 
                    ? "progressbar-li selected-progressbar-li selected-progressbar-li-active" 
                    : "progressbar-li"
                :
                    stepNumber===2
                      ? "cursor-pointer progressbar-li active selected-progressbar-li selected-progressbar-li-active" 
                      : "cursor-pointer progressbar-li active selected-progressbar-li"
              }>Questions</li>
              <li onClick={selectedId ? ()=> this.goToUrl(selectedId , 'step3') : null} className={
                !selectedId ?
                  stepNumber===3 
                  ? "progressbar-li selected-progressbar-li selected-progressbar-li-active"
                  : "progressbar-li"
                :
                  stepNumber===3 
                  ? "cursor-pointer progressbar-li active selected-progressbar-li selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li active selected-progressbar-li"
              }>Outcome</li>
            </ul>
            :
            <ul className="progressbar-ul pl-0 pt-5">
              <li onClick={selectedId ? ()=> this.goToUrl(selectedId , 'step1') : null} className={
                !selectedId 
                ?
                  stepNumber ===2 
                    ? "progressbar-li-two-steps active selected-progressbar-li" 
                    : "progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active"
                :
                  stepNumber == 1
                    ? "cursor-pointer progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active active"
                    : "cursor-pointer progressbar-li-two-steps active selected-progressbar-li"
                }>Details</li>
              <li onClick={selectedId ? ()=> this.goToUrl(selectedId , 'step2') : null} className={
                !selectedId 
                ?
                stepNumber === 2 
                  ? "progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active" 
                  : "progressbar-li-two-steps"
                :
                stepNumber === 2
                  ? "cursor-pointer progressbar-li-two-steps active selected-progressbar-li selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li-two-steps active  selected-progressbar-li"
                }>Questions</li>
            </ul>
          }
      </div>
    );
  }

}

export default SurveyFormProgress;