import React from 'react';
import queryString from 'query-string';
import SSTCFLayout from 'layouts/SSTCF';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { NAV } from 'constants/vendors';
import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import PaginationComponent from 'components/common/Pagination';

import InvoiceListComponent from 'payments/invoices/components/table';
import InvoiceUsecase from 'payments/invoices/usecases/listUseCase';
import InvoiceApiGateway from 'payments/invoices/gateways/invoice';
import ConfirmPasswordModal from 'payments/bills/components/confirmPasswordModal';


class VendorInvoicePage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    const invoiceApiGateway = new InvoiceApiGateway();
    this.usecase = new InvoiceUsecase(invoiceApiGateway);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.updateState = this.updateState.bind(this);
    this.listenCompanyInvoiceUseCase = this.listenCompanyInvoiceUseCase.bind(this);
    this.subscriptionToggle = this.subscriptionToggle.bind(this);
    this.updatePlan = this.updatePlan.bind(this);
    this.state={
      infoList: [],
      isInfoOpen: true,
      invoiceList : [],
      isLoading: false,
      parent : NAV.VendorInvoicePage.parent,
      cancelSubscriptionModalOpen: false
    };
    this.loadData = this.loadData.bind(this);

  }

  componentDidMount() {
    const cookieKey = cookie.load("company_invoice_info");
    if(cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.listenCompanyInvoiceUseCase();
    this.usecase.getInfoList();
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    if (prevSearch !== nextSearch) {
      this.fetchData(nextProps);
    }
  }

  updatePlan(){
    const { profile } = this.props;
    this.usecase.getCompanyPlan(profile.company.id);
  }


  subscriptionToggle() {
    const { cancelSubscriptionModalOpen } = this.state;
    this.setState({
      cancelSubscriptionModalOpen: !cancelSubscriptionModalOpen
    })
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  fetchData(props){
    const { profile, location: { search } } = props;
    const companyId = profile.company.id;
    const params = queryString.parse(search);

    this.usecase.getCompanyInvoiceList({ ...params , company_id : companyId});
  }

  loadData(){
    this.fetchData(this.props);
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("company_invoice_info", !isInfoOpen);
  }

  listenCompanyInvoiceUseCase() {
     this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'GET_COMPANY_INVOICE_LIST_SUCCESS':{
          this.setState({ 'invoiceList': event.data, isLoading: false });
          break;
        }
        case 'CANCEL_PLAN_SUCCESS': {
          toast.success('Your plan cancellation is in progress');
          this.updateState('cancelSubscriptionModalOpen', !this.state.cancelSubscriptionModalOpen);
          this.updatePlan();
          break;
        }
        case 'GET_COMPANY_PLAN_SUCCESS': {
          this.props.history.push('/accounts/cancellation');
          break;
        }
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    const { profile, customerDetail } = this.props;
    const { infoList, isInfoOpen , invoiceList, parent, isLoading, cancelSubscriptionModalOpen } = this.state;
    const planType = customerDetail && customerDetail.plan && customerDetail.plan.plan_type;
    let tabs;
    planType ==='online'?
    tabs = [
      { href: '/vendor/plan', label: 'Plan' },
      { href: '/vendor/invoice', label: 'Invoices' },
      { href: '/vendor/payment-method', label: 'Payment Methods' }
    ]
    :
    tabs = [
      { href: '/vendor/plan', label: 'Plan' },
      { href: '/vendor/invoice', label: 'Invoices' },
    ];

    return(
      <SSTCFLayout
        navInfo={{...NAV.VendorInvoicePage, parent}}
        tabItems={tabs}
        fetchData={this.loadData}
        infoList={infoList}
        toggleInfo = {this.toggleInfo}
        isInfoOpen={isInfoOpen}
      >
        <InvoiceListComponent 
          invoiceList={invoiceList}
          isLoading={isLoading}
          subscriptionToggle = {this.subscriptionToggle}
          planType={planType}
        />
       {!isLoading && <PaginationComponent
          count={invoiceList.count}
          location={this.props.location}
          cookieKey="employee_page_size"
          history={this.props.history}
        />}
        {cancelSubscriptionModalOpen &&
          <ConfirmPasswordModal
            isOpen={cancelSubscriptionModalOpen}
            toggle={this.subscriptionToggle}
            usecase={this.usecase}
            initialValues={{ email: profile.username,
              company_id: profile.company.id }}
          />
        }
      </SSTCFLayout>
    );
  }
}

const mapStatetoProps = (state) => ({
  profile: UserDucks.profile(state),
  customerDetail: PaymentDucks.customerDetail(state),
});

VendorInvoicePage.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
)(VendorInvoicePage);
