import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import NotificationList from 'notification/pages/notificationList';

//import DocumentCompletionList from 'notification/pages/documentCompletionList';

const NotificationRoutes = (props) => {
  const { match } = props;
   return (
    <Switch>
      <Redirect exact from={match.url}/>
      <MatchWhenAuthorized
        path={`${match.url}/list`}
        name="communication.list"
        exact
        component={NotificationList}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

NotificationRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default NotificationRoutes;