import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Label from 'reactstrap/lib/Label';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import ButtonGroupAtom from 'atoms/ButtonGroup';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import ColorAtom from 'atoms/Color';
import GoogleAutoCompleteAtom from 'atoms/GoogleAutoComplete';

import MapComponent from 'components/common/googleMap/Map';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import CircleComponent from 'components/common/googleMap/Circle';

import { FORM_LAYOUT } from 'constants/layout';
import {ReactComponent as MarkerImg} from './marker.svg';
import {ReactComponent as PolygonImg} from './polygon.svg';
import {ReactComponent as RectangleImg} from './rectangle.svg';
import {ReactComponent as CircleImg} from './circle.svg';

import { server } from 'helpers/config';
import { COUNTRY_CODES_ARRAY } from 'constants/geoFences';

const GeocodingOption = server.geocodingOption;

/**
 * GeoFenceFormPage -> GeoFenceFormSection -> GeoFenceFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class GeoFenceFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleShapeChange = this.handleShapeChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.getCurrentCountryBounds = this.getCurrentCountryBounds.bind(this);
    this.setRestrictedViewbox = this.setRestrictedViewbox.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.state = {
      drawingMode: props.geoFenceDetail.shape_type || 'Point',
      fillColor: props.geoFenceDetail.color,
      address: null,
      bounds: this.getCurrentCountryBounds(),
      viewbox : null
    };
  }

  componentDidUpdate(prevProps, prevState){
    const { address } = this.state;
    const { google , selectedGeofence , currentBrowserLocation } = this.props;
    let shape , shapeType;
    if(address !== null) {
      const latCoord = GeocodingOption == 'google maps' ? address.location.lat() : address.location.lat;
      const lngCoord = GeocodingOption == 'google maps' ? address.location.lng() : address.location.lng;
      shape = {
        address: address.description,
        coordinates: [lngCoord , latCoord],
        radius: 100,
        type: 'Circle'
      };
      shapeType = 'Circle';
      this.props.setShapeInfo(shape)
    } else if(!(selectedGeofence && selectedGeofence.id) && (prevProps.currentBrowserLocation !== currentBrowserLocation)){
      this.setState({bounds: this.getCurrentCountryBounds()})
    }
  }

  getCurrentCountryBounds() {
    const { google, currentBrowserLocation } = this.props;
    if (!currentBrowserLocation) return null;
    
    const currentCountry = COUNTRY_CODES_ARRAY.find((i) => i.short_name == currentBrowserLocation.toUpperCase() )
      var index_country = parseInt(currentCountry.id) - 1;
      var myOptions = {
      center: new google.maps.LatLng(
        COUNTRY_CODES_ARRAY[index_country].center_lat,
        COUNTRY_CODES_ARRAY[index_country].center_lng)
      }
      // var map = new google.maps.Map(document.getElementById("map"), myOptions);
      // set the bounds of the map
      var bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(COUNTRY_CODES_ARRAY[index_country].sw_lat, COUNTRY_CODES_ARRAY[index_country].sw_lng),
        new google.maps.LatLng(COUNTRY_CODES_ARRAY[index_country].ne_lat, COUNTRY_CODES_ARRAY[index_country].ne_lng) );

      return bounds;
  }

  setRestrictedViewbox(params){
    this.setState({viewbox : params});
  }

  handlePositionChange(){
    this.setState({ address: null });
  }

  handleShapeChange(type) {
    const { setShapeInfo } = this.props;
    this.setState({ drawingMode: type, address: null });
    setShapeInfo({});
  }



  handleAddressSelect(value) {
    const { setShapeInfo } = this.props;
    this.setState({ address: value });
    if(value) {
      this.setState({ drawingMode: 'Circle' });
    }
    else {
      setShapeInfo({});
    }
  }

  resetFillColor(event, value) {
    this.setState({ fillColor: value });
  }

  submit(data) {
    const { createOrUpdate, associateNewGeofence } = this.props;
    const detail = { ...data, color: data.color || '#162d6e' };

    if(!associateNewGeofence){
      return createOrUpdate(detail)
        .catch((err) => {
          const errObj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errObj);
        });
      }
    else{
      return createOrUpdate(detail);
    }
  }

  render() {
    const {
      loaded,
      google,
      handleSubmit,
      submitting,
      initialValues,
      geoFenceDetail,
      setShapeInfo,
      selectedGeofence,
      associateNewGeofence
    } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const { drawingMode, fillColor, address } = this.state;
    let shape = geoFenceDetail.lat_long;
    let shapeType =  geoFenceDetail.shape_type;
    return (
      <Form onSubmit={handleSubmit(this.submit)} className="pl-3 pr-4 pb-5">
        <Row className="black-text">
          <Col md={selectedGeofence && selectedGeofence.id ? 7 : 6} style={{ height: '55vh' }}>
            {loaded &&
              <MapComponent
                google={google}
                locations={((params.id || (selectedGeofence && selectedGeofence.id)) ) ? [geoFenceDetail] : []}
                center={address}
                drawable={shape && !shape.coordinates}
                mode={drawingMode}
                setShapeInfo={setShapeInfo}
                fillColor={fillColor}
                bounds={this.state.bounds && !selectedGeofence && this.state.bounds}
                setRestrictedViewbox = {this.setRestrictedViewbox}
                selectedGeofence = {selectedGeofence && selectedGeofence.id}
                getAddressForMarker={this.props.getAddressForMarker}
              >
                {(() => {
                  if (shapeType === 'Point') {
                    return (<MarkerComponent
                      id={geoFenceDetail.id}
                      getAddressForMarker={this.props.getAddressForMarker}
                      position={shape && 
                        shape.coordinates.length
                          ? {
                            lat: shape.coordinates[1],
                            lng: shape.coordinates[0],
                          }
                          : {}
                      }
                      setShapeInfo={setShapeInfo}
                      draggable
                      editable
                    />);
                  } else if (shapeType === 'Polygon') {
                    return (<PolygonComponent
                      id={geoFenceDetail.id}
                      getAddressForMarker={this.props.getAddressForMarker}
                      paths={shape && 
                        shape.coordinates[0] && shape.coordinates[0].length
                          ? shape.coordinates[0].map(latLng => ({
                            lat: latLng[1],
                            lng: latLng[0],
                          }))
                          : []
                      }
                      draggable
                      editable
                      fillColor={fillColor}
                      setShapeInfo={setShapeInfo}
                    />);
                  } else if (shapeType === 'Rectangle') {
                    return (<RectangleComponent
                      id={geoFenceDetail.id}
                      getAddressForMarker={this.props.getAddressForMarker}
                      bounds={shape && shape.coordinates}
                      draggable
                      editable
                      fillColor={fillColor}
                      setShapeInfo={setShapeInfo}
                    />);
                  } else if (shapeType === 'Circle') {
                    return (<CircleComponent
                      id={geoFenceDetail.id}
                      getAddressForMarker={this.props.getAddressForMarker}
                      draggable
                      editable
                      radius={shape.radius || geoFenceDetail.radius}
                      handlePositionChange={this.handlePositionChange}
                      center={shape && 
                        shape.coordinates.length
                          ? {
                            lat: shape.coordinates[1],
                            lng: shape.coordinates[0],
                          }
                          : {}
                      }
                      fillColor={fillColor}
                      setShapeInfo={setShapeInfo}
                    />);
                  }
                  return null;
                })()}
              </MapComponent>
            }
          </Col>
          <Col md={selectedGeofence && selectedGeofence.id ? 5 :6} style={{paddingTop: '-1em'}}>
          {/*  <div className="error-text">{error}</div>*/}
            <Field
              id="name"
              name="name"
              label="Name"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Name"
            />
            <Field  
              className={!initialValues.address && !address ? 'remove-icon select-width' : 'select-width'}
              id="address"
              name="address"
              label="Address"
              component={GoogleAutoCompleteAtom}
              viewbox = {this.state.viewbox}
              google={google}
              labelKey="description"
              layout={FORM_LAYOUT}
              valueKey="place_id"
              onSelect={value => this.handleAddressSelect(value)}
              placeholder="Enter Google Address"
            />
          <Row>
            <Col md={8} >
              <Label className="d-block mb-1">Type</Label>
              <ButtonGroupAtom 
                options={[
                  { value: 'Point', label: 'Point', icon: <MarkerImg className="mb-2" height="18" width="18" /> },
                  { value: 'Polygon', label: 'Polygon', icon: <PolygonImg className="mb-2" height="18" width="18" /> },
                  { value: 'Rectangle', label: 'Rectangle', icon: <RectangleImg className="mb-2" height="18" width="18" /> },
                  { value: 'Circle', label: 'Circle', icon: <CircleImg className="mb-2" height="18" width="18" /> },
                ]}
                active={drawingMode}
                onToggle={this.handleShapeChange}
                className="button_group_container mb-4"
                geofence
              />
            </Col>
            <Col md={4}>
              {shapeType && shapeType !== 'Point' &&
                <div>
                <Label className="d-block mb-1">Color</Label>
                <Field
                  id="color"
                  name="color"
/*                  label="Color"*/
                  component={ColorAtom}
                  layout={FORM_LAYOUT}
                  onChange={(e, v) => this.resetFillColor(e, v)}
                  onFocus={(e,v) => this.resetFillColor(e,v)}
                />
                </div>
              }
            </Col>
          </Row>
            <Field
              id="description"
              name="description"
              label="Description"
              component={InputAtom}
              layout={FORM_LAYOUT}
              type="textarea"
              placeholder="Description (Optional)"
            />
            <Field
              id="is_active"
              type="checkbox"
              name="is_active"
              label="Active?"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
            />
            <Button
              type="submit"
              color="secondary"
              className="btn-block"
              size={FORM_LAYOUT.size}
            >
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Name';
  if(!values.address) errors.address = "Please select an address"
  return errors;
};

GeoFenceFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  google: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  geoFenceDetail: PropTypes.object.isRequired,
  setShapeInfo: PropTypes.func.isRequired,
};

GeoFenceFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'GeoFenceForm',
  validate,
  enableReinitialize: false,
})(GeoFenceFormComponent);
