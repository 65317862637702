import React from 'react';

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as CustomerDucks from 'ducks/customers/customer';
import SectionLoaderAtom from 'atoms/SectionLoader';

import CustomerBulkUploadFormComponent from 'components/customers/bulkUpload/Form';
/**
 * CustomerBulkListPage -> CustomerBulkUploadSection
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class CustomerBulkUploadSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.sampleFile = this.sampleFile.bind(this);
    this.state={errorEmp:{}, stats:{}, csvData : "" , isBulkLoading: false}
  }
  
  update(data){
    this.setState({isBulkLoading: true});
    return this.props.CustomerDucks.postBulkCustomer(data)
      .then((res) => {
        if(res.value.stats && (res.value.stats.created === res.value.stats.total)){
          toast.success("Customers Uploaded Succesfully")
        }
        if(res.value.stats && (res.value.stats.updated === res.value.stats.total)){
          toast.success("Customers Updated Succesfully")
        }
        this.setState({ errorEmp : {}, stats : res.value.stats, csvData : {}, isBulkLoading: false})
      })
      .catch((err) => {
        if(err.error){
          toast.error(err.error)
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }  
        else if(err.stats && !(err.stats.created) && !(err.stats.updated)){
          toast.error("Unable to upload customers");
        }
        else if(err.stats && (err.stats.updated === err.stats.total)){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        else if(err.stats && ((err.stats.create_failed !== err.stats.total) || (err.stats.update_failed !== err.stats.total))){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        this.setState({ errorEmp : err.data, stats: err.stats, csvData : err.data_csv, isBulkLoading: false})
      })
  }

  sampleFile(){
    return this.props.CustomerDucks.getBulkCustomer();
  }


  render() {
    const { isLoading } = this.props;
    const { errorEmp , stats, csvData, isBulkLoading} = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <CustomerBulkUploadFormComponent 
          update={this.update}
          errorEmp={errorEmp}
          sampleFile={this.sampleFile}
          stats={stats}
          csvData={csvData}
          isBulkLoading={isBulkLoading}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

CustomerBulkUploadSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // EmployeeDucks: PropTypes.object.isRequired,
};

CustomerBulkUploadSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerBulkUploadSection);