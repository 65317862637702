import React from 'react';

import PropTypes from 'prop-types';

//import MultiSelectAtom from 'atoms/FormikMultiselectDropdown';
import MultiSelectAtom from 'atoms/AdvancedMultiSelectDropDown';

class EmployeeJobFilterComponent extends React.Component {

  render() {/*
    const { unassignedJobs } = this.props;
    return (
      <MultiSelectAtom
        id="jobs"
        name="jobs"
        placeholder="Select Jobs"
        items={unassignedJobs}
        itemToString={i => i && i.name}
        itemCodeToString={i => i && i.code}
        onChange={this.props.toggleJobs}
      />
    );
    */
    const { unassignedJobs } = this.props;
    return (
      <MultiSelectAtom
        id="jobs"
        name="jobs"
        placeholder="Search by Job Name, Code, Tag and Customer Name"
        items={unassignedJobs}
        itemToString={i => i && i.name}
        itemCodeToString={i => i && i.code}
        itemCoustomerToString={i => i && i?.customer_data?.customer_name}
        itemTagToString={i => i && i?.tags}
        onChange={this.props.toggleJobs}
      />
    );}
};

EmployeeJobFilterComponent.propTypes = {
  unassignedJobs: PropTypes.array.isRequired,
};

export default EmployeeJobFilterComponent;
