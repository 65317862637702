import React from 'react';

import Field from 'redux-form/lib/Field';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import SelectAtom from 'atoms/Select';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { EMPLOYEES_FILTER_OPTIONS } from 'constants/vendors';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

class PayrollItemsComponent extends React.Component {

   constructor(props){
    super(props);
     this.state={
     selectedPayrolls:[],
     saveMappingLoader : false
   }
    this.handlePayrollSelection = this.handlePayrollSelection.bind(this);
    this.saveMapping = this.saveMapping.bind(this);
    this.handleChange=this.handleChange.bind(this);
}

  handleChange(e, val) {
    console.log('value',val);
  }

  saveMapping(){
    this.setState({setMappingLoader : true});
    const { selectedPayrolls } = this.state;
    const { setPayrollMapping, source } = this.props;
    let details='';
    if(!selectedPayrolls.length){
      this.setState({setMappingLoader : false})
      return toast.error('Please select atleast one payroll item to map');
    }
    const detail = selectedPayrolls && selectedPayrolls.length ? selectedPayrolls.map((item) =>
       (
      { list_id: item.list_id,
        destination_payroll : (source==='ADP' ? item.qbd_payroll_name.map((name, i) => ({name: name, id: item.qbd_payroll_id[i]})) : { name : item.qbd_payroll_name , id : item.qbd_payroll_id} )
      }
    )) : null ;

    { source==='ADP' ? 
      details = detail.filter(item => item.destination_payroll.map((item)=>item.name)) 
    : 
      details = detail.filter(item => item.destination_payroll.name);
    }

    if(!details.length){
      this.setState({setMappingLoader : false})
      return toast.error('Please select payroll items to map');
    }
    setPayrollMapping(details)
    .then(() => {
          this.setState({setMappingLoader : false})
      })
  }
 
  
  handlePayrollSelection(selected, val){
    const { selectedPayrolls } = this.state;
    var index = selectedPayrolls.indexOf(selected);
    if(index === -1){
      selectedPayrolls.push(selected);
    }
    else{
      selectedPayrolls.splice(index, 1);
    }
  }

  render(){
    const { data ,source, metcaAdjustmentTypesList , location, onChangePayrolls} = this.props;
    const { setMappingLoader } = this.state;
    const mapped = location.query.synced;
    let filteredArray =  data.length && data.map(i => ({ label:i.payroll.Name, id: i.list_id , groupCode : i.payroll.groupCode}));
    return(
      <div>
              <Row>
                <Col>
                  <div className="filter">
                    <div className="d-inline-block">
                      <FilterDropdownComponent
                        paramKey="synced"
                        location={location}
                        options={EMPLOYEES_FILTER_OPTIONS}
                      />
                    </div>
                    {mapped==='false' && metcaAdjustmentTypesList.length>0  ?
                      <Button
                        type="submit"
                        color="secondary"
                        className={ `float-right`}
                        onClick={() => this.saveMapping()}
                      >
                        Save Association <ButtonLoaderAtom active={setMappingLoader} />
                      </Button>
                      :
                      null
                     /* <Button
                        type="submit"
                        color="secondary"
                        className={ `float-right`}
                        onClick={() => this.removeMapping()}
                      >
                        Remove Association
                      </Button>*/
                    }
                  </div>
                  </Col>
                  </Row>
                   <Table className={ location.query.synced === 'false' ? "table qbd-metc-table" : "table"} hover responsive size="sm">
            <thead className={styles['table-header-main']}>
              <tr>
                <th
                  colSpan={location.query.synced === 'false' ? 2 : 1}
                  className={`${styles['border-right']} text-center ${styles['table-header-case']}`}>
                    Worksana Payroll(s)
                </th>
                <th
                  colSpan={location.query.synced === 'false' ? 3 : 4}
                  className={`text-center ${styles['table-header-case']}`}>
                    { source==='ADP' ? 'ADP Payroll(s)' :'QuickBooks Payroll(s)' }
                </th>
              </tr>
            </thead>
                
              <thead className={styles['table-header']}>
              <tr>
                {location.query.synced === 'false' && <th className="text-center">Select</th>}
                <th className={styles['border-right']}>Name</th>
                <th>Name</th>
                <th></th><th></th>
                {location.query.synced === 'true' && <th>Remove</th>}
              </tr>
            </thead>
           
        <tbody>
        {metcaAdjustmentTypesList.length ? metcaAdjustmentTypesList.map((item, index) => {
            return(<tr key={index}>
              <td className="text-center">
             <input
                type="checkbox"
                value={item.isSelected}
                onChange={(val) =>this.handlePayrollSelection(item, val)}
              />
              </td>
              <td className={styles['border-right']}>{item.payroll.name ? item.payroll.name : null}</td>
              <td className={styles['select-cell']}>
              {source!=='ADP' ?
                <Field
                   id={index}
                   name={index}
                   label=""
                   component={SelectAtom}
                   options={data.length && data.map(i => ({ label:i.payroll.Name, value: i.list_id }))}
                   placeholder="Payroll Items"
                   labelKey="label"
                   valueKey="value"
                   layout={FORM_LAYOUT}
                   onChange={(i) => onChangePayrolls({ ...item,
                       qbd_payroll_id: i.value, qbd_payroll_name: i.label})}
                 />
                :
                <div className="adp-payroll-dropdown">
                  <Field
                    component={MultiSelectDropdownAtom}
                    id={index}
                    name={index}
                    placeholder="Payroll Items"
                    items={filteredArray.length ? filteredArray : []}
                    itemToString={i => i && i.groupCode ?`${i.label} (${i.groupCode})` : i ? `${i.label}` : null}
                    label=""
                    onChange={(i,val) =>  onChangePayrolls({ ...item,
                         qbd_payroll_id: val.length && val.map((item)=>item.id) , qbd_payroll_name:val.length && val.map((item)=>item.label)})}
                  />
                </div>
              }
                </td>
              <td></td>
              <td>
              </td>
              
            </tr>);
           // eslint-enable 
        }) : null}
        {!metcaAdjustmentTypesList.length && <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
      </tbody>
      </Table>
    </div>
    );
  }
}

export default PayrollItemsComponent;