import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import JobFormPage from './Form';
import EditJobFormPage from './FormEditJob';
import JobListPage from './List';
import JobTaskMappingListPage from './JobTaskMappingList';
import JobGeofencePage from './jobGeofence';
import JobTaskEditPage from './JobTaskEdit';
import JobReportsPage from './jobReports';
import JobEmployeeEditPage from './jobEmployeeEdit';
import JobBudgetPage from './jobBudget';
import TaskBulkUploadPage from './TaskBulkUpload';
import JobBulkUploadPage from './JobBulkUpload';
import ManageJobsPage from './ManageJobsPage';
import JobDocumentsPage from './JobDocumentsPage'

const JobScheduleRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="job.list"
        component={JobListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/add`}
        name="job.add"
        component={JobFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/edit`}
        name="job.edit"
        component={EditJobFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/lists/task/`}
        name="job.task.assign"
        component={JobTaskMappingListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/geofence/add`}
        name="job.add"
        component={JobGeofencePage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/documents/edit`}
        name="job.edit"
        component={JobDocumentsPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/task/edit`}
        name="job.edit"
        component={JobTaskEditPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/employee/edit`}
        name="job.employee.edit"
        component={JobEmployeeEditPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/lists/jobreports`}
        name="job.reports"
        component={JobReportsPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/budget/edit`}
        name="job.edit"
        component={JobBudgetPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/task/bulk-upload`}
        name="customer.bulkupload"
        component={TaskBulkUploadPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/bulk-upload`}
        name="customer.bulkupload"
        component={JobBulkUploadPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/new`}
        name="job.list"
        component={ManageJobsPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

JobScheduleRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default JobScheduleRoutes;
