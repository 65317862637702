import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const POST_SESSION = 'activities/session/POST_SESSION';
const POST_SESSION_FULFILLED = 'activities/session/POST_SESSION_FULFILLED';
const POST_MEAL_PERIOD_SESSION = 'activities/session/POST_MEAL_PERIOD_SESSION';
const POST_MEAL_PERIOD_SESSION_FULFILLED = 'activities/session/POST_MEAL_PERIOD_SESSION_FULFILLED';


export function postSession(data, id, beta, urlParams) {
  let url = `${URLS.ACTIVITY}${URLS.MANUAL}?employee_id=${id}`;
  if (beta === 'true') {
    url = `${URLS.ACTIVITY}${URLS.MANUAL}?employee_id=${id}&beta=true&department_id=${urlParams.department_id}&team_id=${urlParams.team_id}&seconds=${urlParams.seconds}`;
  };
  return {
    type: POST_SESSION,
    payload: axios.post(url, data),
  };
}

export function postMealPeriodSession(data, id) {
  return {
    type: POST_MEAL_PERIOD_SESSION,
    payload: axios.post(`${URLS.ACTIVITY}${URLS.ACTIVITY_SPLIT}${URLS.MEAL_PERIOD_SESSION}`, data),
  };
}


const defaultState = {
  detail: {},
};

function POST_SESSION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_MEAL_PERIOD_SESSION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [POST_SESSION_FULFILLED]: POST_SESSION_FULFILLED_REDUCER,
  [POST_MEAL_PERIOD_SESSION_FULFILLED]: POST_MEAL_PERIOD_SESSION_FULFILLED_REDUCER,
};

export default createReducer(defaultState, handlers);
