const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/job', label: 'Job Analytics' },
];

const baseNavNew = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/job/list/new', label: 'Manage Jobs' },
];

export const NAV = {
  JobListPage: {
    title: 'Job Analytics',
    breadcrumb: [
      ...baseNav,
      { label: 'All' },
    ],
    parent: '/dashboard',
    description: 'View & manage jobs',
  },
  ManageJobsPage: {
    title: 'Manage Jobs',
    breadcrumb: [
      ...baseNavNew,
      { label: 'All' },
    ],
    parent: '/dashboard',
    description: 'View & manage jobs',
  },
  JobAddFormPage: {
    title: 'Create Job',
    breadcrumb: [
      ...baseNav,
      { label: 'New' },
    ],
    parent: '/job/list',
    description: 'Add New Job',
  },
  JobTaskMappingListPage: {
    title: 'Tasks',
    breadcrumb: [
      ...baseNav,
    ],
    parent: '/job/list',
    description: 'View and Manage task(s)',
  },
  JobReportsPage: {
    title: 'Reports',
    breadcrumb: [
      ...baseNav,
      { label: 'Reports' },
    ],
    parent: '/job/list',
    description: 'View and Manage Reports',
  },
  JobCommentListPage: {
    title: 'Job History',
    breadcrumb: [
      ...baseNav,
    ],
    parent: '/job/list',
  },
  JobEditFormPage: {
    title: 'Edit Job',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/job/list',
    description: 'Edit your job details here',
  },
  AdminJobEditFormPage: {
    title: 'Edit Job',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/job/list/new',
    description: 'Edit your job details here',
  },
  JobTaskEditPage: {
    title: 'Tasks and Employees',
    breadcrumb: [
      ...baseNav,
      { label: 'Edit' },
    ],
    parent: '/job/list',
    description: 'Edit Tasks and Employees',
  },
  JobEmployeeMappingListPage: {
    breadcrumb: [
      ...baseNav,
    ],
    parent: '/job/list',
    description: 'Check and save to un-assign employee(s)',
    title: 'Associated Employees to Job',
    // Dynamic description, title
  },

  JobEmployeeTaskTimeAllocatedListPage: {
    breadcrumb: [
      ...baseNav,
    ],
    parent: '/job/list',
    title: 'Job - Time Allocation',
    // Dynamic description, title
  },
  JobsEmployeeTaskTimeAllocatedListPage: {
    breadcrumb: [
      ...baseNav,
      { label: 'Job - Time Allocation' },
    ],
    title: 'Job - Time Allocation',
    // Dynamic description, title
  },
  TaskBulkUploadPage: {
    title: 'Task Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/job/lists/task',
    description: 'Upload all your tasks',
  },
  JobBulkUploadPage: {
    title: 'Job Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/job/list',
    description: 'Upload all your jobs',
  },
  NewJobBulkUploadPage: {
    title: 'Job Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/job/list/new',
    description: 'Upload all your jobs',
  },
};

export const WEEKDAYS = [
  { name: 'monday', value: 'Monday' },
  { name: 'tuesday', value: 'Tuesday' },
  { name: 'wednesday', value: 'Wednesday' },
  { name: 'thursday', value: 'Thursday' },
  { name: 'friday', value: 'Friday' },
  { name: 'saturday', value: 'Saturday' },
  { name: 'sunday', value: 'Sunday' },
];

export const WEEKDAYS_IN_SHORT = [
  { name: 'SUN', value: 6, isSelected: false },
  { name: 'MON', value: 0, isSelected: false },
  { name: 'TUE', value: 1, isSelected: false },
  { name: 'WED', value: 2, isSelected: false },
  { name: 'THU', value: 3, isSelected: false },
  { name: 'FRI', value: 4, isSelected: false },
  { name: 'SAT', value: 5, isSelected: false },
];

export const DAYS = [
  { id: '0', name: 'Monday' },
  { id: '1', name: 'Tuesday' },
  { id: '2', name: 'Wednesday' },
  { id: '3', name: 'Thursday' },
  { id: '4', name: 'Friday' },
  { id: '5', name: 'Saturday' },
  { id: '6', name: 'Sunday' },
];

export const FILTER_OPTIONS = [
  { value: '0', label: 'All' },
  { value: '1', label: 'Open' },
  { value: '3', label: 'Completed' },
  { value: '2', label: 'Deleted' },
];

export const DATE_RANGE_OPTIONS = [
  { value: '0', label: 'Today' },
  { value: '1', label: 'Yesterday' },
  { value: '2', label: 'This Week' },
  { value: '3', label: 'Last Week' },
  { value: '4', label: 'This Month' },
  { value: '5', label: 'Last Month' },
  { value: '6', label: 'Custom Date Range' },
];

export const BILLABLE_OPTIONS = [
  { value: '1', label: 'All' },
  { value: '2', label: 'True' },
  { value: '3', label: 'False' },
];

export const FILTER_TASK_OPTIONS = [
  { value: 'true', label: 'Assigned' },
  { value: 'false', label: 'Unassigned' },
  { value: '', label: 'All' }
];

export const REPORT_OPTIONS = [
  { value: '1', label: 'Details' },
  { value: '2', label: 'Summary' },
];

export const JOB_VIEW_OPTIONS = [
  { value: true, label: 'Job View' },
  { value: false, label: 'Customer View' },
];

export const TAG_TYPE = [
  { id: '0', name: 'Customer'},
  { id: '1', name: 'Task'},
  { id: '2', name: 'Job'}
]

export const REPEAT_OPTIONS = [
  { value: "WEEKLY", label: "Weekly" },
  { value:"MONTHLY", label: "Monthly" }
]

export const BILL_TYPE_OPTIONS = [
  { value: "QUOTED_RECURRING", label: "Quoted Recurring" },
  { value: "QUOTED_PROJECT", label: "Quoted Project" },
  { value:"TIME_AND_MATERIAL", label: "Time and Material" }
]

export const FILTER_BILL_TYPE_OPTIONS = [
  { value: '', label: "All" },
  { value: "QUOTED", label: "Quoted" },
  { value:"TIME_AND_MATERIAL", label: "Time and Material" }
]

export const JOB_TYPE_OPTIONS = [
  { value: "RECURRING", label: "Recurring" },
  { value:"PROJECT", label: "Project" }
]

export const FILTER_JOB_TYPE_OPTIONS = [
  { value: '', label: "All" },
  { value: "RECURRING", label: "Recurring" },
  { value:"PROJECT", label: "Project" }
]

export const REPEAT_EVERY_WEEKLY_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
]

export const REPEAT_EVERY_MONTHLY_OPTIONS = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
]

export const GROUP_BY_OPTIONS = [
  { value: 'employee', label: 'Employee' },
  { value: 'job', label: 'Job' },
  { value: 'task', label: 'Task' },
  { value: 'customer', label: 'Customer' },
]

export const CREATE_UPDATE_JOB_FILTER_OPTIONS = [
  { value: '1', label: 'Open' },
  { value: '3', label: 'Completed' },
  { value: '2', label: 'Deleted' },
];

export const ADVANCE_GROUP_BY_OPTIONS = [
  { value: 'no_grouping', label: 'No Grouping' , id : 'no_grouping'},
  { value: 'customer__customer_name', label: 'Customer' , id : 'customer_name'},
  { value: 'status', label: 'Status', id: 'status' },
  { value: 'name', label: 'Job', id: 'name' },
  { value: 'code', label: 'Job Code' , id : 'code'},
  { value: 'job_tags', label: 'Job Tag' , id : 'tags_count'},
];

export default { NAV, FILTER_OPTIONS, DAYS, FILTER_TASK_OPTIONS, JOB_VIEW_OPTIONS, TAG_TYPE,
  WEEKDAYS_IN_SHORT, REPEAT_OPTIONS, REPEAT_EVERY_WEEKLY_OPTIONS, BILLABLE_OPTIONS, REPORT_OPTIONS,
  REPEAT_EVERY_MONTHLY_OPTIONS, GROUP_BY_OPTIONS, BILL_TYPE_OPTIONS, JOB_TYPE_OPTIONS, DATE_RANGE_OPTIONS,
  FILTER_BILL_TYPE_OPTIONS, FILTER_JOB_TYPE_OPTIONS, CREATE_UPDATE_JOB_FILTER_OPTIONS,ADVANCE_GROUP_BY_OPTIONS };
