import axiosInstance from 'axios';
import { createSelector } from 'reselect';

import { LOCATION_IQ_API_KEY } from 'constants/geoFences';

import { server } from 'helpers/config';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';


const GeocodingOption = server.geocodingOption;

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

const GET_ACTIVITY_TRACKS = 'activities/activityTrack/GET_ACTIVITY_TRACKS';
const GET_ACTIVITY_TRACKS_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACKS_FULFILLED';
const GET_ACTIVITY_TRACK = 'activities/activityTrack/GET_ACTIVITY_TRACK';
const GET_ACTIVITY_TRACK_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACK_FULFILLED';
const GET_ACTIVITY_TRACK_SESSION = 'activities/activityTrack/GET_ACTIVITY_TRACK_SESSION';
const GET_ACTIVITY_TRACK_SESSION_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACK_SESSION_FULFILLED';
const GET_ACTIVITY_LOCATION_ADDRESS = 'activities/activityTrack/GET_ACTIVITY_LOCATION_ADDRESS';
const GET_ACTIVITY_LOCATION_ADDRESS_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_LOCATION_ADDRESS_FULFILLED';
const GET_ACTIVITY_TRACKS_HISTORIC = 'activities/activityTrack/GET_ACTIVITY_TRACKS_HISTORIC';
const GET_ACTIVITY_TRACKS_HISTORIC_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACKS_HISTORIC_FULFILLED';
const GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE = 'activities/activityTrack/GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE';
const GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE_FULFILLED';
const GET_ACTIVITY_TRACK_HISTORIC = 'activities/activityTrack/GET_ACTIVITY_TRACK_HISTORIC';
const GET_ACTIVITY_TRACK_HISTORIC_FULFILLED = 'activities/activityTrack/GET_ACTIVITY_TRACK_HISTORIC_FULFILLED';
const TOGGLE_INFO_WINDOW = 'activities/activityTrack/TOGGLE_INFO_WINDOW';
const RESET_SESSION_INFO = 'activities/activityTrack/RESET_SESSION_INFO';
const SET_SELECTED_MARKER_ADDRESS = 'activities/activityTrack/SET_SELECTED_MARKER_ADDRESS';
const GET_ONBOARD_ACTIVITY_TRACKS = 'activities/activityTrack/GET_ONBOARD_ACTIVITY_TRACKS';
const GET_ONBOARD_ACTIVITY_TRACKS_FULFILLED = 'activities/activityTrack/GET_ONBOARD_ACTIVITY_TRACKS_FULFILLED';
// const SET_SESSION_INFO = 'activities/activityTrack/SET_SESSION_INFO';
const TOGGLE_SESSION_INFO_WINDOW = 'activities/activityTrack/TOGGLE_SESSION_INFO_WINDOW';
const OPEN_SESSION_INFO_WINDOW = 'activities/activityTrack/OPEN_SESSION_INFO_WINDOW';
const SET_SESSION_SELECTED_MARKER_ADDRESS = 'activities/activityTrack/SET_SESSION_SELECTED_MARKER_ADDRESS';
const SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS='activities/activityTrack/SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS';
const TOGGLE_ON_BOARD_INFOWINDOW = 'activities/activityTrack/TOGGLE_ON_BOARD_INFOWINDOW';
const SET_PUNCH_OUT_LOCATION = 'activities/activityTrack/SET_PUNCH_OUT_LOCATION';
const RESET_BROWSER_LOCATION = 'activities/activityTrack/RESET_BROWSER_LOCATION';
const GET_CURRENT_COUNTRY = 'activities/activityTrack/GET_CURRENT_COUNTRY';
const GET_CURRENT_COUNTRY_FULFILLED = 'activities/activityTrack/GET_CURRENT_COUNTRY_FULFILLED';
const GET_ABSENT_ACTIVITY_TRACKS = 'activities/activityTrack/GET_ABSENT_ACTIVITY_TRACKS'
const GET_ABSENT_ACTIVITY_TRACKS_FULFILLED = 'activities/activityTrack/GET_ABSENT_ACTIVITY_TRACKS_FULFILLED' 

export function getActivityTracks(params) {
  return {
    type: GET_ACTIVITY_TRACKS,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK}`, { params }),
  };
}

export function getAbsentActivityTracks(params) {
  return {
    type: GET_ABSENT_ACTIVITY_TRACKS,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ABSENT_ACTIVITY_TRACK}`, { params }),
  };
}


export function getOnboardActivityTracks(params) {
  return {
    type: GET_ONBOARD_ACTIVITY_TRACKS,
    payload: axios.get(`${URLS.ONBOARD_ACTIVITY}`, { params }),
  };
}

export function getActivityTracksHistoric(params) {
  return {
    type: GET_ACTIVITY_TRACKS_HISTORIC,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK}${URLS.HISTORIC}`, { params }),
  };
}

export function getJobActivityTrack(data) {
  return {
    type: GET_ACTIVITY_TRACK_SESSION,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK_SESSION}${data.id}/${URLS.ACTIVITY_DAILY_TIMECARD}`),
  };
}

export function getActivityTrack(data) {
  return {
    type: GET_ACTIVITY_TRACK,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK}${data.id}/`),
  };
}

export function getActivityTrackHistoric(data) {
  return {
    type: GET_ACTIVITY_TRACK_HISTORIC,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK}${data.id}${URLS.SESSION}`),
  };
}


export function getActivityTrackHistoricGeofence(data) {
  return {
    type: GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE,
    payload: axios.get(`${URLS.ALL_GEOFENCES}?employee_id=${data?.employee?.id}&company=${data?.company}`),
  };
}

export function getActivityTrackingSession(data) {
  return {
    type: GET_ACTIVITY_TRACK,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.ACTIVITY_TRACK}${data.id}/${URLS.ACTIVITY_TRACKING_SESSION}`),
  }
}

export function toggleInfoWindow(data) {
  return {
    type: TOGGLE_INFO_WINDOW,
    payload: data,
  };
}

export function resetSessionInfo(data) {
  return {
    type: RESET_SESSION_INFO,
    payload: data,
  };
}

export function resetBrowserLocation(data) {
  return {
    type: RESET_BROWSER_LOCATION,
    payload: data,
  };
}

export function getActivityLocationAddress(data, apiOption) {
  if(GeocodingOption){
    if(GeocodingOption == 'google maps'){
      return {
        type: GET_ACTIVITY_LOCATION_ADDRESS,
         payload: axiosInstance.get(
        `${URLS.LOCATION_ADDRESS}?latlng=${data.position ? data.position.lat : data[0].position.lat},${data.position ? data.position.lng : data[0].position.lng}&key=${REACT_APP_GOOGLE_API_KEY_BASE}&v=${REACT_APP_GOOGLE_API_VERSION}`),
      };
    }
    else if(GeocodingOption == 'here.com'){
      return {
        type: GET_ACTIVITY_LOCATION_ADDRESS,
        payload: axios.get(
          `${URLS.HERE_API_GEOCODING}?at=${data.position.lat}%2C${data.position.lng}&lang=en-US`,
        ),
      };
    }
    else if(GeocodingOption == 'locationIq'){
      return {
        type: GET_ACTIVITY_LOCATION_ADDRESS,
        payload: axiosInstance.get(
          `${URLS.LOCATION_IQ_GEOCODING}?key=${LOCATION_IQ_API_KEY}&lat=${data.position.lat}&lon=${data.position.lng}&format=json`,
        ),
      };
    }
  }
  else{
      return {
        type: GET_ACTIVITY_LOCATION_ADDRESS,
        payload: axiosInstance.get(
          `${URLS.LOCATION_IQ_GEOCODING}?key=${LOCATION_IQ_API_KEY}&lat=${data.position.lat}&lon=${data.position.lng}&format=json`,
        ),
      };
  }
}

export function getUserCurrentCountry(data, apiOption) {
  if(GeocodingOption){
    if(GeocodingOption == 'google maps'){
      return {
        type: GET_CURRENT_COUNTRY,
         payload: axiosInstance.get(
        `${URLS.LOCATION_ADDRESS}?latlng=${data.position ? data.position.lat : data[0].position.lat},${data.position ? data.position.lng : data[0].position.lng}&key=${REACT_APP_GOOGLE_API_KEY_BASE}&v=${REACT_APP_GOOGLE_API_VERSION}`),
      };
    }
    else if(GeocodingOption == 'here.com'){
      return {
        type: GET_CURRENT_COUNTRY,
        payload: axios.get(
          `${URLS.HERE_API_GEOCODING}?at=${data.position.lat}%2C${data.position.lng}&lang=en-US`,
        ),
      };
    }
    else if(GeocodingOption == 'locationIq'){
      return {
        type: GET_CURRENT_COUNTRY,
        payload: axiosInstance.get(
          `${URLS.LOCATION_IQ_GEOCODING}?key=${LOCATION_IQ_API_KEY}&lat=${data.position.lat}&lon=${data.position.lng}&format=json`,
        ),
      };
    }
  }
  else{
      return {
        type: GET_CURRENT_COUNTRY,
        payload: axiosInstance.get(
          `${URLS.LOCATION_IQ_GEOCODING}?key=${LOCATION_IQ_API_KEY}&lat=${data.position.lat}&lon=${data.position.lng}&format=json`,
        ),
      };
  }
}

export function setActivityLocationAddress(data) {
  return {
    type: SET_SELECTED_MARKER_ADDRESS,
    payload: data,
  };
}

// export function setSessionInfo(data) {
//   return {
//     type: SET_SESSION_INFO,
//     payload: data,
//   };
// }

export function toggleSessionInfoWindow(data) {
  return {
    type: TOGGLE_SESSION_INFO_WINDOW,
    payload: data,
  };
}

export function toggleOnboardInfoWindow(data){
  return {
    type: TOGGLE_ON_BOARD_INFOWINDOW,
    payload: data,
  };
}

export function openSessionInfoWindow(data) {
  return {
    type: OPEN_SESSION_INFO_WINDOW,
    payload: data,
  };
}

export function setSessionActivityLocationAddress(data) {
  return {
    type: SET_SESSION_SELECTED_MARKER_ADDRESS,
    payload: data,
  };
}

export function setOnboardingSessionActivityLocationAddress(data) {
  return {
    type: SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS,
    payload: data,
  };
}

export function setPunchOutLocation(data) {
  return {
    type: SET_PUNCH_OUT_LOCATION,
    payload: data,
  };
}

const defaultState = {
  list: { results: [] },
  selectedMarkerAddress: '',
  sessionInfo: {},
  historicSessionInfo:{},
  userCurrentPosition: '',
  userCountryCode : '',
  absentEmployeeData : {}
};

function GET_ACTIVITY_TRACKS_FULFILLED_REDUCER(state, action) {
  // Adding 3 keys { id, showInfo, position } for each location
  let obj = {};
  const trackList = action.payload.results.map((item, itemIndex) => {
    obj = {...item};
    if (Object.keys(item.locations).length && item.punch_in_type.app_user){
      obj.locations = item.locations && item.locations.map((loc, locIndex) => ({
        ...loc,
        id: `${itemIndex}${locIndex}`,
        showInfo: false,
        position: { lng: loc.location.coordinates && loc.location.coordinates[0], lat: loc.location.coordinates && loc.location.coordinates[1] },
      }))
    }
    else {
      obj.locations = item.locations;
    }
    return obj;
  });  
  return Object.assign({}, state, {
    list: { ...action.payload, results: trackList },
  });
}

function GET_ABSENT_ACTIVITY_TRACKS_FULFILLED_REDUCER(state, action) {
  // Adding 3 keys { id, showInfo, position } for each location
  return Object.assign({}, state, {
    absentEmployeeData: { ...action.payload },
  });
}


function GET_ACTIVITY_TRACKS_HISTORIC_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload
  });
}

function GET_ONBOARD_ACTIVITY_TRACKS_FULFILLED_REDUCER(state, action) {
  const array = action.payload.tracking && action.payload.tracking.length && action.payload.tracking.map((item,index)=> ({...item, id:index }))
  return Object.assign({}, state, {
    list: {...action.payload,tracking: array},
  });
}

function GET_ACTIVITY_TRACK_FULFILLED_REDUCER(state, action) {
  // Adding 3 keys { id, showInfo, position } for each location
  let sessionTrackList = [];
  if(action.payload.punch_in_type && !action.payload.punch_in_type.app_user ){
    sessionTrackList = [ ...action.payload.locations ]; 
  }
  else {
    sessionTrackList = action.payload.locations && action.payload.locations.map((loc, locIndex) => ({
      ...loc,
      id: locIndex,
      showInfo: false,
      position: { lng: loc && loc.location && loc.location.coordinates && loc.location.coordinates[0], lat:  loc && loc.location && loc.location.coordinates && loc.location.coordinates[1] },
    }));
  }
  return Object.assign({}, state, {
    sessionInfo: { ...action.payload, locations: sessionTrackList },
  });
}

function GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE_FULFILLED_REDUCER(state,action) {
  let sessionTrackList = [];
  sessionTrackList = action.payload.locations
    && action.payload.locations.map((loc, locIndex) => {
      if(Object.keys(loc).length){
        let item = {
          ...loc,
          id: locIndex,
          showInfo: false,
        };
        if(Object.keys(loc.location).length){
          item.position = { lng:  loc.location.coordinates && loc.location.coordinates[0], lat: loc.location.coordinates && loc.location.coordinates[1] };
        }
        return item;
      }
      return loc;
    });
  return Object.assign({}, state, {
    historicSessionInfo: { ...action.payload, locations: sessionTrackList },
  });
}

function GET_ACTIVITY_TRACK_HISTORIC_FULFILLED_REDUCER(state, action) {
  // Adding 3 keys { id, showInfo, position } for each location
  let sessionTrackList = [];
  sessionTrackList = action.payload.locations
    && action.payload.locations.map((loc, locIndex) => {
      if(Object.keys(loc).length){
        let item = {
          ...loc,
          id: locIndex,
          showInfo: false,
        };
        if(Object.keys(loc.location).length){
          item.position = { lng:  loc.location.coordinates && loc.location.coordinates[0], lat: loc.location.coordinates && loc.location.coordinates[1] };
        }
        return item;
      }
      return loc;
    });
  return Object.assign({}, state, {
    sessionInfo: { ...action.payload, locations: sessionTrackList },
  });
}

function GET_ACTIVITY_TRACK_SESSION_FULFILLED_REDUCER(state, action) {
  // Adding 3 keys { id, showInfo, position } for each location
  const sessionTrackList = action.payload.locations && action.payload.locations.map((loc, locIndex) => ({
    ...loc,
    id: locIndex,
    showInfo: false,
    position: { lng: loc.location.coordinates && loc.location.coordinates[0], lat: loc.location.coordinates && loc.location.coordinates[1] },
  }));

  return Object.assign({}, state, {
    sessionInfo:  { ...action.payload, locations: sessionTrackList }
  });
}

function TOGGLE_INFO_WINDOW_REDUCER(state, action) {
  const trackList = state.list.results.map(item => ({
    ...item,
    locations: item.locations.map(loc => {
      if (loc.id === action.payload.id) {
        return { ...loc, showInfo: !loc.showInfo };
      }
      return { ...loc, showInfo: false };
    }),
  }));

  return Object.assign({}, state, {
    list: { ...state.list, results: trackList },
  });
}

function RESET_SESSION_INFO_REDUCER(state, action) {
  return Object.assign({}, state, {
    sessionInfo: {},
  });
}

function RESET_BROWSER_LOCATION_REDUCER(state, action) {
  return Object.assign({}, state, {
    userCurrentPosition: '',
    userCountryCode : ''
  });
}

function GET_ACTIVITY_LOCATION_ADDRESS_FULFILLED_REDUCER(state, action) {
   if (action.payload.data &&  action.payload.data.status && action.payload.data.status === 'OK') {
     return Object.assign({}, state, {
      selectedMarkerAddress: action.payload.data.results[0].formatted_address,
     });
   }

  else if (action.payload.items && action.payload.items.length) {
    return Object.assign({}, state, {
      selectedMarkerAddress: action.payload.items[0].address.label,
    });
  }
  
  else if (action.payload.data.display_name) {
    return Object.assign({}, state, {
      selectedMarkerAddress: action.payload.data.display_name,
    });
  }
}

function GET_CURRENT_COUNTRY_FULFILLED_REDUCER(state, action) {
   if (action.payload.data &&  action.payload.data.status && action.payload.data.status === 'OK') {
     return Object.assign({}, state, {
      selectedMarkerAddress: action.payload.data.results[0].formatted_address,
     });
   }

  else if (action.payload.items && action.payload.items.length) {
    return Object.assign({}, state, {
      selectedMarkerAddress: action.payload.items[0].address.label,
    });
  }
  
  else if (action.payload.data.display_name) {
    return Object.assign({}, state, {
      userCountryCode : action.payload.data.address.country_code,
      position : {lat:action.payload.data.lat, lng: action.payload.data.lon}
    });
  }

  
  return Object.assign({}, state, {
    userCountryCode: '',
  });
}

function SET_SELECTED_MARKER_ADDRESS_REDUCER(state, action) {
  const trackList = state.list.results.map(item => ({
    ...item,
    locations: item.locations.map(loc => {
      if (loc.id === action.payload.id) {
        return { ...loc, address: action.payload.address };
      }
      return loc;
    }),
  }));

  return Object.assign({}, state, {
    list: { ...state.list, results: trackList },
  });
}

function TOGGLE_SESSION_INFO_WINDOW_REDUCER(state, action) {
  const sessionTrackList = state.sessionInfo && state.sessionInfo.locations && state.sessionInfo.locations.map(item => {
    if (action.payload && item.id === action.payload.id) {
      return { ...item, showInfo: !item.showInfo };
    }
    return { ...item, showInfo: false };
  });
  return Object.assign({}, state, {
    sessionInfo: { ...state.sessionInfo, locations: sessionTrackList },
  });
}

function TOGGLE_ON_BOARD_INFOWINDOW_REDUCER(state, action) {
  const sessionTrackList = state.list.tracking && state.list.tracking.map(item => {
    if (action.payload && item.id === action.payload.id) {
      return { ...item, showInfo: !item.showInfo };
    }
    return { ...item, showInfo: false };
  });
  return Object.assign({}, state, {
    list: { ...state.list, tracking: sessionTrackList },
  });
}

function OPEN_SESSION_INFO_WINDOW_REDUCER(state, action) {
  const sessionTrackList = state.sessionInfo && state.sessionInfo.locations && state.sessionInfo.locations.map(item => {
    if (action.payload && item.id === action.payload.id) {
      return { ...item, showInfo: true };
    }
    return { ...item, showInfo: false };
  });
  return Object.assign({}, state, {
    sessionInfo: { ...state.sessionInfo, locations: sessionTrackList },
  });
}

function SET_SESSION_SELECTED_MARKER_ADDRESS_REDUCER(state, action) {
  const sessionTrackList = state.sessionInfo && state.sessionInfo.locations
  && state.sessionInfo.locations.map(item => {
    const data = {...item};
    data.showInfo = false;
    if (data.id === action.payload.id) {
      data.address = action.payload.address;
      data.showInfo = true;
    }
    return data;
  });

  return Object.assign({}, state, {
    sessionInfo: { ...state.sessionInfo, locations: sessionTrackList },
  });
}

function SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS_REDUCER(state, action) {
  const sessionTrackList = state.list && state.list.tracking
  && state.list.tracking.map(item => {
    const data = {...item};
    data.showInfo = false;
    if (data.id === action.payload.id) {
      data.address = action.payload.address;
      data.showInfo = true;
    }
    return data;
  });
  return Object.assign({}, state, {
    list: { ...state.list, tracking: sessionTrackList },
  });
}

function SET_PUNCH_OUT_LOCATION_REDUCER(state, action) {
  const trackList = state.list.results.map(item => {
    if(item.id === action.payload.id){
      return { ...item, punch_out_address: action.payload.address }
    }
    return item;
  });

  return Object.assign({}, state, {
    list: { ...state.list, results: trackList },
  });
}

const handlers = {
  [GET_ACTIVITY_TRACKS_FULFILLED]: GET_ACTIVITY_TRACKS_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACKS_HISTORIC_FULFILLED]: GET_ACTIVITY_TRACKS_HISTORIC_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACK_HISTORIC_FULFILLED]: GET_ACTIVITY_TRACK_HISTORIC_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE_FULFILLED]: GET_ACTIVITY_TRACK_HISTORIC_GEOFENCE_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACK_FULFILLED]: GET_ACTIVITY_TRACK_FULFILLED_REDUCER,
  [GET_ACTIVITY_TRACK_SESSION_FULFILLED]: GET_ACTIVITY_TRACK_SESSION_FULFILLED_REDUCER,
  [GET_ONBOARD_ACTIVITY_TRACKS_FULFILLED]: GET_ONBOARD_ACTIVITY_TRACKS_FULFILLED_REDUCER, 

  [TOGGLE_INFO_WINDOW]: TOGGLE_INFO_WINDOW_REDUCER,
  [TOGGLE_ON_BOARD_INFOWINDOW]: TOGGLE_ON_BOARD_INFOWINDOW_REDUCER,
  [RESET_SESSION_INFO]: RESET_SESSION_INFO_REDUCER,
  [RESET_BROWSER_LOCATION]: RESET_BROWSER_LOCATION_REDUCER,
  [GET_ACTIVITY_LOCATION_ADDRESS_FULFILLED]: GET_ACTIVITY_LOCATION_ADDRESS_FULFILLED_REDUCER,
  [SET_SELECTED_MARKER_ADDRESS]: SET_SELECTED_MARKER_ADDRESS_REDUCER,

  // [SET_SESSION_INFO]: SET_SESSION_INFO_REDUCER,
  [TOGGLE_SESSION_INFO_WINDOW]: TOGGLE_SESSION_INFO_WINDOW_REDUCER,
  [OPEN_SESSION_INFO_WINDOW]: OPEN_SESSION_INFO_WINDOW_REDUCER,
  [SET_SESSION_SELECTED_MARKER_ADDRESS]: SET_SESSION_SELECTED_MARKER_ADDRESS_REDUCER,
  [SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS]: SET_ONBOARDING_SESSION_SELECTED_MARKER_ADDRESS_REDUCER,
  [SET_PUNCH_OUT_LOCATION]: SET_PUNCH_OUT_LOCATION_REDUCER,
  [GET_CURRENT_COUNTRY_FULFILLED] : GET_CURRENT_COUNTRY_FULFILLED_REDUCER,
  [GET_ABSENT_ACTIVITY_TRACKS_FULFILLED]: GET_ABSENT_ACTIVITY_TRACKS_FULFILLED_REDUCER,
};


const activityTrackSelector = state => state.activities.activityTrack;

export const activityTrackList = createSelector(
  activityTrackSelector,
  instance => instance.list,
);

export const absentEmpActivityTrackList = createSelector(
  activityTrackSelector,
  instance => instance.absentEmployeeData,
);

// Last Location (current) for each currently punched-in employee
export const currentLocationList = createSelector(
  activityTrackList,
  instance => ({
    ...instance,
    results: instance.results.map(item => ({
      ...item.locations[item.locations.length - 1],
    })),
  }),
);

export const currentBrowserLocation = createSelector(
  activityTrackSelector,
  instance => instance.userCountryCode,
);

export const userCurrentPosition = createSelector(
  activityTrackSelector,
  instance => instance.position,
);

export const selectedMarkerAddress = createSelector(
  activityTrackSelector,
  instance => instance.selectedMarkerAddress,
);

export const sessionInfo = createSelector(
  activityTrackSelector,
  instance => instance.sessionInfo,
);

export const historicSessionInfo = createSelector(
  activityTrackSelector,
  instance => instance.historicSessionInfo,
);

export default createReducer(defaultState, handlers);
