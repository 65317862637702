import React from 'react';
import PropTypes from 'prop-types';
import NavLink from 'react-router-dom/NavLink';

import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';

import styles from './styles.module.scss';


const NavTabComponent = (props) => {
  const { tabItems } = props;
  return (
    <Nav tabs className={styles['tabs-nav']}>
      {tabItems.map(item => (
        <NavItem key={item.href}>
          <NavLink
            to={item.href}
            className={`nav-link ${styles['tab-link']}`}
            activeClassName={`active ${styles['tab-active']}`}
          >
            {item.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

NavTabComponent.propTypes = {
  tabItems: PropTypes.array.isRequired,
};

export default NavTabComponent;
