import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const SectionLoaderAtom = (props) => {
  const { active } = props;
  if (!active) return null;
  return (
    <div className={styles['section-loader']} style={{left:props.left?props.left:'50%'}}>
      <div className={styles['ball-pulse-sync']}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

SectionLoaderAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default SectionLoaderAtom;
