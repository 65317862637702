import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import closeimage from './Close-Cross.png';

import styles from './styles.module.scss';

const HelpTextModal = (props) => {
  const { isOpen, toggle } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" backdrop="static">
      <ModalHeader>Quickbooks Failures
        <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() =>toggle()} />
      </ModalHeader>
      <ModalBody className={styles['help-content']}>
        <div className="mb-3">
          <p className="mb-1"><strong>&bull; Why do we ever have failures and what are their types?</strong></p>
          <p className="pl-2 mb-2">
            There are few minor cases in which the sync of the employees might fail either way i.e. Worksana to QuickBooks or QuickBooks to Worksana:
          </p>
          <ul className="pl-3 ml-2">
            <li className="mb-2">There could be bad data like duplicate employees are there in the system which cannot be uniquely identified therefore this causes the failure of these types of employees.</li>
            <li className="mb-2">In the Worksana & QuickBooks there are mandatory fields which needs to be entered both ways and are mapped while we are doing the sync, if these mandatory fields are missing in any of employees then the sync for these employees are failed.</li>
            <li className="mb-2">Very rare case where the sync is failed due to low internet connectivity or irregular network which causes the server time out or session is failed.</li>
          </ul>    
        </div>
        <div className="mb-2">
          <p className="mb-1"><strong>&bull; What can be the possible fixes to these failures?</strong></p>
          <p className="pl-2 mb-2">
            Well the best possible solution to these failures is just the correctness of maintaining the data. Still few preventive measures which can be taken care of:
          </p>
          <ul className="pl-3 ml-2">
            <li className="mb-2">Do not create employees with the same name to avoid redundancies.</li>
            <li className="mb-2">All the mandatory information is being filled while creating and editing employees.</li>
            <li className="mb-2">Avoid performing the sync activity in the area of low network.</li>
          </ul>
        </div>
      </ModalBody>
    </Modal>
  );
  
};

HelpTextModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default HelpTextModal;
