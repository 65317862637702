import React from "react";

import PropTypes from "prop-types";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import { toast } from "react-toastify";

import * as RouteDucks from "ducks/routes";
import * as JobDucks from "ducks/jobs/job";
import * as InfoDucks from "ducks/info/info";
import * as CustomerDucks from "ducks/customers/customer";
import * as NoteDucks from "ducks/jobs/comment";
import * as TaskDucks from "ducks/jobs/task";
import * as EmployeeDucks from "ducks/employees/employee";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";

import TwoColumnLayout from "layouts/TwoColumn";
import MealEmpSelectionForm from "../../components/vendors/mealAdjustments/MealEmpSelectionForm";
import MealProgressBarSection from "../../sections/vendors/form/ProgressBar";

import { NAV } from "constants/vendors";

/**
 * JobFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class MealAdjustmentsEmpSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parent: NAV.VendorMealAdjustmentsEditFormPage.parent,
      isMealAddInfo: true,
    };

    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  setParentUrl(query) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleInfo() {
    const { isMealAddInfo } = this.state;
    this.setState({ isMealAddInfo: !isMealAddInfo });
    cookie.save("meal_employee_form_info", !isMealAddInfo);
  }

  render() {
    const { isLoading, isJobEditInfo, isMealAddInfo, mealTypes } = this.state;
    const {
      jobDetail,
      infoList,
      location: { query },
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const selectedId = params ? params.id : null;

    return (
      <div>
        <TwoColumnLayout
          navInfo={NAV.VendorMealAdjustmentsEditFormPage}
          infoList={infoList}
          isInfoOpen={isMealAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          editJob
          jobDetail={jobDetail}
        >
          <Row
            className="pt-4 ml-3 mr-4"
            style={{ backgroundColor: "white", borderTop: "2px solid #09819A" }}
          >
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <MealProgressBarSection
                    active={3}
                    selectedId={selectedId}
                    isInfoOpen={isMealAddInfo}
                    location={this.props.location}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MealEmpSelectionForm selectedId={selectedId} />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-5" />
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

MealAdjustmentsEmpSelection.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

MealAdjustmentsEmpSelection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MealAdjustmentsEmpSelection);
