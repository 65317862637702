import React from 'react';

import PropTypes from 'prop-types';

import Collapse from 'reactstrap/lib/Collapse';

import CollapsableInfoComponent from 'components/common/CollapsableInfo/Info';

import styles from './styles.module.scss';

class collapsableInfoSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isVideoModalOpen: false };
  }

  render(){
    const { infoList, isInfoOpen } = this.props;

    return (
      <div className={styles['info-video']}>
        <Collapse isOpen={isInfoOpen} className={styles['slick-height']}>
          <CollapsableInfoComponent infoList={infoList} />
        </Collapse>
      </div>
    );
  }
}

collapsableInfoSection.propTypes = {
  isInfoOpen: PropTypes.bool.isRequired,
  infoList: PropTypes.array.isRequired,
};

export default collapsableInfoSection;