import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  GET_INVOICE_DETAIL_SUCCESS : 'GET_INVOICE_DETAIL_SUCCESS',
  INFO_LIST : 'INFO_LIST',
  SHOW_LOADER: 'SHOW_LOADER',
  INVOICE_UPDATE_SUCCESS: 'INVOICE_UPDATE_SUCCESS',
  DOWNLOAD_INVOICE_SUCCESS : 'DOWNLOAD_INVOICE_SUCCESS',
};

class invoiceDetailUsecase {
 
  invoiceApiGateway = null;

  constructor(invoiceApiGateway) {
    this.invoiceApiGateway = invoiceApiGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'invoiceDetailUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  async getInfoList(){
    this.infoList = await this.invoiceApiGateway.getInfoList({ route: 'vendor.invoice'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async getInvoiceDetails(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.invoiceDetails = await this.invoiceApiGateway.getInvoiceDetails(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_INVOICE_DETAIL_SUCCESS,
      data: this.invoiceDetails,
    });
  }

  async downloadInvoice(params){
    this.exportInvoice = await this.invoiceApiGateway.exportInvoice(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.DOWNLOAD_INVOICE_SUCCESS,
      data: this.exportInvoice,
    });
  }

  async execute(data, invoiceId){
    const status = data.payment_received ? 'PAYMENT RECEIVED' : 'INVOICE RAISED';
    this.invoiceDetails = await this.invoiceApiGateway.updateInvoiceDetails({ status }, invoiceId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.INVOICE_UPDATE_SUCCESS,
    });
  }
}

export default invoiceDetailUsecase;