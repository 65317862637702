import React from "react";
import { Modal } from "reactstrap";
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Form from 'reactstrap/lib/Form';

//styles
import styles from "./styles.module.scss";

//assets
import {ReactComponent as SendIcon} from './Send.svg';
import {ReactComponent as ToggleIcon} from './close.svg';
import InputAtom from 'atoms/Input';


class JobNoteImageModal extends React.Component {
  render() {
      const { showImageModal, handleSubmit, toggleImageModal} = this.props;
        return (
          <Modal isOpen={showImageModal}>
            <Form onSubmit={handleSubmit(this.props.submit)} className="mt-0">
              <div className = {`${styles['show_image_modal']}`}>
               <ToggleIcon width="20" height="20" onClick={() => toggleImageModal()} style={{position: 'absolute' , top: '15px' , right: '15px'}}/>
                <img src={this.props.image} width="98%" height="225" alt=""/>
              </div>
              <div className={`${styles['post_comment_container']} ${styles['post_comment_container_no_image']}`} style={{marginLeft: '5px'}}>
                <div className={`${styles['comment-box']} ${styles['comment-box-modal']}`}>
                  <Field component={InputAtom} placeholder="Add a comment" className="w-100 h-100 pl2 br2 bn" name='note'/>
                </div>
                <button type='submit' className={`${styles['send-image']}`}>
                  <SendIcon height="40" width="40"/>
                </button>
            </div>
            </Form>
          </Modal>  
        )
    }
}



export default reduxForm({
  form: 'jobNoteImageForm',
})(JobNoteImageModal);

