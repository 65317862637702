import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import ManualCorrectionDetailTableComponent from 'components/reports/manualCorrectionSummary/DetailTable';

/**
 * ManualCorrectionSummaryListSection -> ManualCorrectionDetailListSection
 *
 * Components:
 *    - {@link ManualCorrectionDetailTableComponent}
 *    - {@link PaginationComponent}
 *
 * State:
 *    - reportData
 *    - reportDetail
 *
 * Actions:
 *    None
 */
const ManualCorrectionDetailListSection = (props) => {
  const { data, isLoading, location, reportDetail } = props;
  const {query} = location;
  const cookieData = cookie.load("manaul_correction_detail_page_size");
  let pageSize;

  if (query && query.page_size)  pageSize = +query.page_size;
  else if(cookieData) pageSize = +cookieData;
  else pageSize = 10;

  if (isLoading) return <SectionLoaderAtom active />;
  return (
    <section>
      <ManualCorrectionDetailTableComponent
        data={data}
        location={location}
        child={reportDetail.parent_report}
        pageSize={pageSize}
      />
      <PaginationComponent
        count={data.length}
        location={location}
        cookieKey="manaul_correction_detail_page_size"
      />
    </section>
  );
};

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  reportDetail: ReportDucks.reportDetail(state), 
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

ManualCorrectionDetailListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  reportDetail: PropTypes.object.isRequired,
};

ManualCorrectionDetailListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ManualCorrectionDetailListSection);
