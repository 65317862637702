 /*import  GraniteError from 'lib/GraniteError'; */
class SurveyTemplateEntity {
  constructor(object={}){
    this.survey_name = object.template_data && object.template_data.label || '';
    this.date_time = object.create_date_display || '';
    this.id = object.id;
    this.is_active = object.is_active;
    this.is_deletable = object.is_deletable;
 }
}

export default SurveyTemplateEntity;