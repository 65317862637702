import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_PLUGIN_LIST = 'vendors/plugin/GET_PLUGIN_LIST';
const GET_PLUGIN_LIST_FULFILLED = 'vendors/plugin/GET_PLUGIN_LIST_FULFILLED';

const GET_PLUGIN_SETTING = 'vendors/plugin/GET_PLUGIN_SETTING';
const GET_PLUGIN_SETTING_FULFILLED = 'vendors/plugin/GET_PLUGIN_SETTING_FULFILLED';

const POST_DISCONNECT_PLUGIN_INTEGRATION = 'vendors/plugin/POST_DISCONNECT_PLUGIN_INTEGRATION';

const GET_UPDATE_PLUGIN_SETTING = 'vendors/plugin/GET_UPDATE_PLUGIN_SETTING';
const GET_UPDATE_PLUGIN_SETTING_FULFILLED = 'vendors/plugin/GET_UPDATE_PLUGIN_SETTING_FULFILLED';

const POST_PLUGIN_SETTING ='vendors/plugin/POST_PLUGIN_SETTING';
const POST_PLUGIN_SETTING_FULFILLED = 'vendors/plugin/POST_PLUGIN_SETTING_FULFILLED';

const GET_PLUGIN_FAILURE_LIST = 'vendors/plugin/GET_PLUGIN_FAILURE_LIST';
const GET_PLUGIN_FAILURE_LIST_FULFILLED = 'vendors/plugin/GET_PLUGIN_FAILURE_LIST_FULFILLED';

const GET_PLUGIN_FAILURE_TIMECARD_LIST = 'vendors/plugin/GET_PLUGIN_FAILURE_TIMECARD_LIST';
const GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED = 'vendors/plugin/GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED';

const GET_PLUGIN_AUTH = 'vendors/plugin/GET_PLUGIN_AUTH';
const GET_PLUGIN_AUTH_FULFILLED = 'vendors/plugin/GET_PLUGIN_AUTH_FULFILLED';

const GET_METC_TASKS = 'vendors/plugin/GET_METC_TASKS';
const GET_METC_TASKS_FULFILLED = 'vendors/plugin/GET_METC_TASKS_FULFILLED';

const GET_QBD_TASKS = 'vendors/plugin/GET_QBD_TASKS';
const GET_QBD_TASKS_FULFILLED = 'vendors/plugin/GET_QBD_TASKS_FULFILLED';

const POST_QBD_CONFIGURATION = 'vendors/vendor/POST_QBD_CONFIGURATION';
const POST_QBD_CONFIGURATION_FULFILLED = 'vendors/vendor/POST_QBD_CONFIGURATION_FULFILLED';

const GET_FILEPATH = 'vendors/plugin/GET_FILEPATH';
const GET_FILEPATH_FULFILLED = 'vendors/plugin/GET_FILEPATH_FULFILLED';

const GET_QBD_METC_EMPLOYEES = 'vendors/plugin/GET_QBD_METC_EMPLOYEES';
const GET_QBD_METC_EMPLOYEES_FULFILLED = 'vendors/plugin/GET_QBD_METC_EMPLOYEES_FULFILLED';

const GET_METC_EMPLOYEES = 'vendors/plugin/GET_METC_EMPLOYEES';
const GET_METC_EMPLOYEES_FULFILLED = 'vendors/plugin/GET_METC_EMPLOYEES_FULFILLED';

const SET_SELECTED_EMPLOYEE = 'vendors/plugin/SET_SELECTED_EMPLOYEE';

const SET_SELECTED_JOB = 'vendors/plugin/SET_SELECTED_JOB';

const POST_MAPPING_SET = 'vendors/vendor/POST_MAPPING_SET';
const POST_MAPPING_SET_FULFILLED = 'vendors/vendor/POST_MAPPING_SET_FULFILLED';

const PUT_UNMAPPING_SET = 'vendors/vendor/PUT_UNMAPPING_SET';
const PUT_UNMAPPING_SET_FULFILLED = 'vendors/vendor/PUT_UNMAPPING_SET_FULFILLED';

const JOB_UNMAPPING_SET = 'vendors/vendor/JOB_UNMAPPING_SET';
const JOB_UNMAPPING_SET_FULFILLED = 'vendors/vendor/JOB_UNMAPPING_SET_FULFILLED';

const CREATE_PROFILE = 'vendors/plugins/CREATE_PROFILE'
const CREATE_PROFILE_FULFILLED = 'vendors/plugins/CREATE_PROFILE_FULFILLED'
const DISCONNECT_PROFILE = 'vendors/plugins/DISCONNECT_PROFILE'
const DISCONNECT_PROFILE_FULFILLED = 'vendors/plugins/DISCONNECT_PROFILE_FULFILLED'

const GET_SYNC = 'vendors/plugins/GET_SYNC'
const GET_SYNC_FULFILLED = 'vendors/plugins/GET_SYNC_FULFILLED'

const GET_PROFILE = 'vendors/plugin/GET_PROFILE'
const GET_PROFILE_FULFILLED = 'vendors/plugin/GET_PROFILE_FULFILLED'

const GET_ALL_QBD_JOBS = 'vendors/plugin/GET_ALL_QBD_JOBS'
const GET_ALL_QBD_JOBS_FULFILLED = 'vendors/plugin/GET_ALL_QBD_JOBS_FULFILLED'

const GET_PAYROLL_ITEMS = 'vendors/plugin/GET_PAYROLL_ITEMS'
const GET_PAYROLL_ITEMS_FULFILLED = 'vendors/plugin/GET_PAYROLL_ITEMS_FULFILLED'

const GET_METC_PAYROLL_ITEMS = 'vendors/plugin/GET_METC_PAYROLL_ITEMS'
const GET_METC_PAYROLL_ITEMS_FULFILLED = 'vendors/plugin/GET_METC_PAYROLL_ITEMS_FULFILLED'

const UPDATE_PROFILE = 'vendors/vendor/UPDATE_PROFILE';
const UPDATE_PROFILE_FULFILLED = 'vendors/vendor/UPDATE_PROFILE_FULFILLED';

const UPDATE_SYNC = 'vendors/plugins/UPDATE_SYNC'
const UPDATE_SYNC_FULFILLED = 'vendors/plugins/UPDATE_SYNC_FULFILLED'

const GET_KONNECT_JOBS = 'vendors/plugin/GET_KONNECT_JOBS'
const GET_KONNECT_JOBS_FULFILLED = 'vendors/plugin/GET_KONNECT_JOBS_FULFILLED'

const AUTHENTICATE = 'vendors/plugin/AUTHENTICATE'
const AUTHENTICATE_FULFILLED = 'vendors/plugin/AUTHENTICATE_FULFILLED'

const SET_JOB_MAPPING = 'vendors/plugin/SET_JOB_MAPPING'
const SET_JOB_MAPPING_FULFILLED = 'vendors/plugin/SET_JOB_MAPPING_FULFILLED'

const GET_METC_JOBS_FULFILLED = 'vendors/plugin/GET_METC_JOBS_FULFILLED'

const SET_SELECTED_PAYROLL = 'vendors/plugin/SET_SELECTED_PAYROLL'

const SET_SELECTED_TASKS = 'vendors/plugin/SET_SELECTED_TASKS'

const GET_ADJUSTMENT_TYPES = 'vendors/plugin/GET_ADJUSTMENT_TYPES';
const GET_ADJUSTMENT_TYPES_FULFILLED = 'vendors/plugin/GET_ADJUSTMENT_TYPES_FULFILLED';

const PAYROLL_MAPPING_SET = 'vendors/plugin/PAYROLL_MAPPING_SET';
const PAYROLL_MAPPING_SET_FULFILLED = 'vendors/plugin/PAYROLL_MAPPING_SET_FULFILLED';

const ADP_PAYROLL_MAPPING_SET = 'vendors/plugin/ADP_PAYROLL_MAPPING_SET';
const ADP_PAYROLL_MAPPING_SET_FULFILLED = 'vendors/plugin/ADP_PAYROLL_MAPPING_SET_FULFILLED';

const TASK_MAPPING_SET = 'vendors/plugin/TASK_MAPPING_SET';
const TASK_MAPPING_SET_FULFILLED = 'vendors/plugin/TASK_MAPPING_SET_FULFILLED';

const PAYROLL_MAPPING_REMOVE = 'vendors/plugin/PAYROLL_MAPPING_REMOVE';
const PAYROLL_MAPPING_REMOVE_FULFILLED = 'vendors/plugin/PAYROLL_MAPPING_REMOVE_FULFILLED';

const ADP_PAYROLL_MAPPING_REMOVE = 'vendors/plugin/ADP_PAYROLL_MAPPING_REMOVE';
const ADP_PAYROLL_MAPPING_REMOVE_FULFILLED = 'vendors/plugin/ADP_PAYROLL_MAPPING_REMOVE_FULFILLED';

const TASK_MAPPING_REMOVE = 'vendors/plugin/TASK_MAPPING_REMOVE';
const TASK_MAPPING_REMOVE_FULFILLED = 'vendors/plugin/TASK_MAPPING_REMOVE_FULFILLED';

const GET_NOTIFICATION_LIST = 'vendors/plugin/GET_NOTIFICATION_LIST';
const GET_NOTIFICATION_LIST_FULFILLED = 'vendors/plugin/GET_NOTIFICATION_LIST_FULFILLED';

// Pass destination_flatform and profile_id in params
export function createProfile(data) {
  return {
    type: CREATE_PROFILE,
    payload: axios.post(URLS.CREATE_PROFILE , {...data}),
  }
}

export function setJobMapping(data) { 
  return {
    type: SET_JOB_MAPPING,
    payload: axios.post(URLS.SAVE_JOB_MAPPING , [...data]),
  }
}

// Get Profile Method called.
export function getProfile(data) {
  return {
    type: GET_PROFILE,
    payload: axios.get(URLS.GET_PROFILE, {...data})
  }
}

export function getAllQbdJobs(params) {
  return {
    type: GET_ALL_QBD_JOBS,
    payload: axios.get(`${URLS.GET_KONNECT_JOBS}`, {params}),
  }
}

export function getPayrollItems(params) {
  return {
    type: GET_PAYROLL_ITEMS,
    payload: axios.get(`${URLS.GET_PAYROLL_ITEMS}`, {params}),
  }
}

export function getMetcPayrollItems(params) {
  return {
    type: GET_METC_PAYROLL_ITEMS,
    payload: axios.get(`${URLS.GET_PAYROLL_ITEMS}`, {params}),
  }
}

export function getMetcTasks(params) {
  return {
    type: GET_METC_TASKS,
    payload: axios.get(`${URLS.GET_METC_TASKS}`, {params}),
  }
}

export function getQbdTasks(params) {
  return {
    type: GET_QBD_TASKS,
    payload: axios.get(`${URLS.GET_METC_TASKS}`, {params}),
  }
}



// Get Sync API called
export function getSync() {
  return {
    type: GET_SYNC,
    payload: axios.get(URLS.GET_SYNC)
  }
}

export function authenticate(data) {
  return {
    type: AUTHENTICATE,
    payload: axios.post(URLS.AUTHENTICATE, data)
  }
}


// Update profile
export function updateProfile(data) {
  return {
    type: UPDATE_PROFILE,
    payload: axios.put(URLS.UPDATE_PROFILE, {...data})
  }
}

// DisConnect Function for plugin Integration
export function disconnectProfile(data) {
  return {
    type: DISCONNECT_PROFILE,
    payload: axios.delete(URLS.DISCONNECT_PROFILE , {data})
  }
}

// Add Employee API called
export function addEmployee(data) {
  return {
    type: DISCONNECT_PROFILE,
    payload: axios.delete(URLS.DISCONNECT_PROFILE, {...data})
  }
}

export function getPluginList(params) {
  return {
    type: GET_PLUGIN_LIST,
    payload: axios.get(URLS.PLUGIN_LIST, { params }),
  };
}

export function getAdjustmentTypes(params) {
  return {
    type: GET_ADJUSTMENT_TYPES,
    payload: axios.get(URLS.ADJUSTMENT_TYPES, { params }),
  };
}

export function getPluginSetting(params) {
  return {
    type: GET_PLUGIN_SETTING,
    payload: axios.get(URLS.GET_PLUGIN_SETTING, { params }),
  };
}


export function getPluginAuth(params) {
  return {
    type: GET_PLUGIN_AUTH,
    payload: axios.get(URLS.PLUGIN_AUTH, { params }),
  };
}

export function postQbdConfiguration(data) {
  return {
    type: POST_QBD_CONFIGURATION,
    payload: axios.post(`${URLS.CREATE_PROFILE}?end_point=QBD`, data),
  };
}

export function disconnectIntegration(data) {
  return {
    type: POST_DISCONNECT_PLUGIN_INTEGRATION,
    payload: axios.delete(`${URLS.DISCONNECT_PLUGIN_INTEGRATION}`, {data}),
  };
}

export function updatePluginSetting(params) {
  return {
    type: GET_UPDATE_PLUGIN_SETTING,
    payload: axios.get(URLS.UPDATE_PLUGIN_INTEGRATION, { params }),
  };
}

export function postPluginSetting(data) {
  return {
    type: POST_PLUGIN_SETTING,
    payload: axios.put(URLS.PLUGIN_SETTING, data),
  };
}

export function getPluginFailureList(params) {
  return {
    type: GET_PLUGIN_FAILURE_LIST,
    payload: axios.get(URLS.PLUGIN_FAILURE_LIST, { params }),
  };
}

export function getPluginTimecardFailureList(params) {
  return {
    type: GET_PLUGIN_FAILURE_TIMECARD_LIST,
    payload: axios.get(URLS.PLUGIN_FAILURE_LIST, { params }),
  };
}

export function getFilepath(params) {
  return {
    type: GET_FILEPATH,
    payload: axios.get(URLS.FILEPATH, { params }),
  };
}

export function getQbdMetcEmployees(params) {
  return {
    type: GET_QBD_METC_EMPLOYEES,
    payload: axios.get(URLS.QBD_METC_EMPLOYEES, { params }),
  };
}


export function getKonnectJobs(params, end_point) {
  return {
    type: GET_KONNECT_JOBS,
    payload: axios.get(`${URLS.GET_KONNECT_JOBS}`, {params}),
  };
}


export function getMetcEmployees(params) {
  return {
    type: GET_METC_EMPLOYEES,
    payload: axios.get(URLS.METC_EMPLOYEES, { params }),
  };
}

export function setSelectedEmployee(data) {
  return {
    type: SET_SELECTED_EMPLOYEE,
    payload: data,
  };
}

export function setSelectedJob(data) {
  return {
    type: SET_SELECTED_JOB,
    payload: data,
  };

}

export function setSelectedPayroll(data) {
  return {
    type: SET_SELECTED_PAYROLL,
    payload: data,
  };

}

export function setSelectedTasks(data) {
  return {
    type: SET_SELECTED_TASKS,
    payload: data,
  };

}

export function postMappingSet(data) {
  return {
    type: POST_MAPPING_SET,
    payload: axios.post( `${URLS.SET_MAPPING}`, data),
  };
}

export function putUnmappingSet(data) {
  return {
    type: PUT_UNMAPPING_SET,
    payload: axios.delete(`${URLS.UNSET_MAPPING}`, { data }),
  };
}

export function JobsMappingUnset(data) {
  return {
    type: JOB_UNMAPPING_SET,
    payload: axios.delete(`${URLS.UNSET_JOB_MAPPING}`, { data} ),
  };
}

export function setPayrollsMapping(data) {
  return {
    type: PAYROLL_MAPPING_SET,
    payload: axios.post(`${URLS.SET_PAYROLL_MAPPING}`,{ sync : data} ),
  };
}

export function setAdpPayrollsMapping(data) {
  return {
    type: ADP_PAYROLL_MAPPING_SET,
    payload: axios.post(`${URLS.SET_ADP_PAYROLL_MAPPING}`,{ sync : data} ),
  };
}

export function setTasksMapping(data) {
  return {
    type: TASK_MAPPING_SET,
    payload: axios.post(`${URLS.SET_TASK_MAPPING}`,{ mapping : data} ),
  };
}

export function removePayrollsMapping(data) {
  return {
    type: PAYROLL_MAPPING_REMOVE,
    payload: axios.post(`${URLS.SET_PAYROLL_MAPPING}`,{ async : data} ),
  };
}

export function removeAdpPayrollsMapping(data) {
  return {
    type: ADP_PAYROLL_MAPPING_REMOVE,
    payload: axios.post(`${URLS.SET_ADP_PAYROLL_MAPPING}`,{ async : data} ),
  };
}

export function removeTaskMapping(data) {
  return {
    type: TASK_MAPPING_REMOVE,
    payload: axios.post(`${URLS.SET_TASK_MAPPING}`, {remove_mapping : data}),
  };
}

export function updateSync(data) {
  return {
    type: UPDATE_SYNC,
    payload: axios.put(URLS.UPDATE_SYNC, data)
  }
}

export function getNotificationList(params) {
  return {
    type: GET_NOTIFICATION_LIST,
    payload: axios.get(URLS.GET_NOTIFICATION_LIST,  { params })
  }
}

const defaultState = {
  list: { results: [] },
  metcAndQbdEmployeeList: { results: [] },
  detail: {},
  authInfo: "",
  pluginSettingList: {},
  updatePluginData: {},
  pluginEmployeeList: { results: [] },
  pluginTimecardList: { results: [] },
  filePath: {},
  employeeList: {},
  konnectJobsList : { results: [] },
  qbdmetcJobsList: { results: [] },
  qbdDropdownList : { results: [] },
  payrollItems : { results: [] },
  adjustmentList: {},
  metcPayrollItems : {},
  metcTasks : {},
  qbdTasks : {},
  notification_list: [],
};

function GET_PLUGIN_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_FILEPATH_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    filePath: action.payload,
  });
}

function GET_PLUGIN_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    pluginSettingList: action.payload,
  });
}

function GET_PLUGIN_FAILURE_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    pluginEmployeeList: action.payload,
  });
}

function GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    pluginTimecardList: action.payload,
  });
}

function GET_UPDATE_PLUGIN_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    updatePluginData: action.payload,
  });
}


function POST_PLUGIN_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PAYROLL_MAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function ADP_PAYROLL_MAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function TASK_MAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PAYROLL_MAPPING_REMOVE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function ADP_PAYROLL_MAPPING_REMOVE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function TASK_MAPPING_REMOVE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_PLUGIN_AUTH_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    authInfo: action.payload,
  });
}

function POST_QBD_CONFIGURATION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_QBD_METC_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.map((item) => ({...item, isOpen: false}));
  return Object.assign({}, state, {
    metcAndQbdEmployeeList : { results: arr}
  });
}

function GET_METC_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    employeeList: action.payload,
  });
}

function SET_SELECTED_EMPLOYEE_REDUCER(state, action) {
  const list = [...state.metcAndQbdEmployeeList.results];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].source__employee_id === action.payload.source__employee_id) {
      list[i].suggestions.id = action.payload.suggested_employee_id;
    }
  }
  return Object.assign({}, state, { metcAndQbdEmployeeList:  { results: list } });
}

function SET_SELECTED_JOB_REDUCER(state, action) {
  const jobList = [...state.qbdDropdownList];
  const list = [...state.konnectJobsList.results];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i].job_id === action.payload.job_id) {
      list[i].destination_job = action.payload.suggested_employee_id ? jobList.find((i) => (action.payload.suggested_employee_id === i.job_id)).job : {};
     /* list[i].destination_job.job.full_name = jobList.find((i) => (action.payload.suggested_employee_id === i.id)).full_name;*/
      /*list[i].suggestion.customer_name = jobList.find((i) => (action.payload.suggested_employee_id === i.id)).customer_name;*/
      /*list[i].suggestion.name = jobList.find((i) => (action.payload.suggested_employee_id === i.id)).name;
      list[i].suggestion.employee_count = jobList.find((i) => (action.payload.suggested_employee_id === i.id)).employee_count;
      list[i].suggestion.task_count = jobList.find((i) => (action.payload.suggested_employee_id === i.id)).task_count;*/
    }
  }
  return Object.assign({}, state, { konnectJobsList:  { results: list } });
}

function SET_SELECTED_PAYROLL_REDUCER(state, action) {
 const adjustmentTypesList = [...state.metcPayrollItems];
 for (let i = 0; i < adjustmentTypesList.length; i += 1) {
    if (adjustmentTypesList[i].list_id === action.payload.list_id) {
      adjustmentTypesList[i].qbd_payroll_name =  action.payload.qbd_payroll_name;
      adjustmentTypesList[i].qbd_payroll_id = action.payload.qbd_payroll_id;
     }
  }
  return Object.assign({}, state, {  metcPayrollItems: adjustmentTypesList  });
}

function SET_SELECTED_TASKS_REDUCER(state, action) {
 const metcTasksList = [...state.metcTasks];
 for (let i = 0; i < metcTasksList.length; i += 1) {
    if (metcTasksList[i].task_id === action.payload.task_id) {
      metcTasksList[i].qbd_task_id =  action.payload.qbd_task_id;
      metcTasksList[i].qbd_task_name = action.payload.qbd_task_name;
    }
  }
  return Object.assign({}, state, {  metcTasks: metcTasksList  });
}

// Reducer to execute create_profile
function DISCONNECT_PROFILE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

// Reducer to get Sync variables
function GET_SYNC_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function CREATE_PROFILE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_MAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_UNMAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function JOB_UNMAPPING_SET_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_PROFILE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ALL_QBD_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    qbdDropdownList: action.payload,
  });
}

function GET_PAYROLL_ITEMS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    payrollItems: action.payload,
  });
}

function GET_METC_PAYROLL_ITEMS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    metcPayrollItems: action.payload,
  });
}


function GET_METC_TASKS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    metcTasks: action.payload,
  });
}

function GET_QBD_TASKS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    qbdTasks: action.payload,
  });
}

function GET_METC_JOBS_FULFILLED_REDUCER(state, action) {
  const metcJobsList = action.payload.results.map(item => ({ ...item }));
  return Object.assign({}, state, {
    qbdmetcJobsList: { ...action.payload, results: metcJobsList },
  });
}


function UPDATE_SYNC_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_KONNECT_JOBS_FULFILLED_REDUCER(state, action) {
   const arr = action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    konnectJobsList: { ...action.payload, results: arr },
  });
}

function UPDATE_PROFILE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function AUTHENTICATE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function SET_JOB_MAPPING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ADJUSTMENT_TYPES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    adjustmentList: action.payload,
  });
}

function GET_NOTIFICATION_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    notification_list: action.payload,
  });
}

const handlers = {
  [GET_PLUGIN_LIST_FULFILLED]: GET_PLUGIN_LIST_FULFILLED_REDUCER,
  [GET_PLUGIN_SETTING_FULFILLED]: GET_PLUGIN_SETTING_FULFILLED_REDUCER,
  [GET_UPDATE_PLUGIN_SETTING_FULFILLED]: GET_UPDATE_PLUGIN_SETTING_FULFILLED_REDUCER,
  [POST_PLUGIN_SETTING_FULFILLED]: POST_PLUGIN_SETTING_FULFILLED_REDUCER,
  [GET_PLUGIN_FAILURE_LIST_FULFILLED]: GET_PLUGIN_FAILURE_LIST_FULFILLED_REDUCER,
  [GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED]: GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED_REDUCER,
  [GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED]: GET_PLUGIN_FAILURE_TIMECARD_LIST_FULFILLED_REDUCER,
  [GET_PLUGIN_AUTH_FULFILLED]: GET_PLUGIN_AUTH_FULFILLED_REDUCER,
  [POST_QBD_CONFIGURATION_FULFILLED]: POST_QBD_CONFIGURATION_FULFILLED_REDUCER,
  [GET_FILEPATH_FULFILLED]: GET_FILEPATH_FULFILLED_REDUCER,
  [GET_QBD_METC_EMPLOYEES_FULFILLED]: GET_QBD_METC_EMPLOYEES_FULFILLED_REDUCER,
  [GET_METC_EMPLOYEES_FULFILLED]: GET_METC_EMPLOYEES_FULFILLED_REDUCER,
  [SET_SELECTED_EMPLOYEE]: SET_SELECTED_EMPLOYEE_REDUCER,
  [POST_MAPPING_SET_FULFILLED]:POST_MAPPING_SET_FULFILLED_REDUCER,
  [PUT_UNMAPPING_SET_FULFILLED]:PUT_UNMAPPING_SET_FULFILLED_REDUCER,
  [JOB_UNMAPPING_SET_FULFILLED]:JOB_UNMAPPING_SET_FULFILLED_REDUCER,
  [CREATE_PROFILE_FULFILLED]: CREATE_PROFILE_FULFILLED_REDUCER,
  [DISCONNECT_PROFILE_FULFILLED] : DISCONNECT_PROFILE_FULFILLED_REDUCER,
  [GET_SYNC_FULFILLED] : GET_SYNC_FULFILLED_REDUCER,
  [GET_PROFILE_FULFILLED] : GET_PROFILE_FULFILLED_REDUCER,
  [GET_ALL_QBD_JOBS_FULFILLED]: GET_ALL_QBD_JOBS_FULFILLED_REDUCER,
  [GET_PAYROLL_ITEMS_FULFILLED] : GET_PAYROLL_ITEMS_FULFILLED_REDUCER,
  [UPDATE_PROFILE_FULFILLED] : UPDATE_PROFILE_FULFILLED_REDUCER,
  [UPDATE_SYNC_FULFILLED] : UPDATE_SYNC_FULFILLED_REDUCER,
  [GET_KONNECT_JOBS_FULFILLED] : GET_KONNECT_JOBS_FULFILLED_REDUCER,
  [AUTHENTICATE_FULFILLED] : AUTHENTICATE_FULFILLED_REDUCER,
  [SET_JOB_MAPPING_FULFILLED] : SET_JOB_MAPPING_FULFILLED_REDUCER,
  [GET_METC_JOBS_FULFILLED] : GET_METC_JOBS_FULFILLED_REDUCER,
  [SET_SELECTED_JOB] : SET_SELECTED_JOB_REDUCER,
  [SET_SELECTED_PAYROLL] : SET_SELECTED_PAYROLL_REDUCER,
  [SET_SELECTED_TASKS] : SET_SELECTED_TASKS_REDUCER,
  [GET_ADJUSTMENT_TYPES_FULFILLED]: GET_ADJUSTMENT_TYPES_FULFILLED_REDUCER,
  [PAYROLL_MAPPING_SET_FULFILLED] : PAYROLL_MAPPING_SET_FULFILLED_REDUCER,
  [TASK_MAPPING_SET_FULFILLED] : TASK_MAPPING_SET_FULFILLED_REDUCER,
  [PAYROLL_MAPPING_REMOVE_FULFILLED] : PAYROLL_MAPPING_REMOVE_FULFILLED_REDUCER,
  [TASK_MAPPING_REMOVE_FULFILLED] : TASK_MAPPING_REMOVE_FULFILLED_REDUCER,
  [GET_METC_PAYROLL_ITEMS_FULFILLED] : GET_METC_PAYROLL_ITEMS_FULFILLED_REDUCER,
  [GET_METC_TASKS_FULFILLED] : GET_METC_TASKS_FULFILLED_REDUCER,
  [GET_QBD_TASKS_FULFILLED] : GET_QBD_TASKS_FULFILLED_REDUCER,
  [GET_NOTIFICATION_LIST_FULFILLED]: GET_NOTIFICATION_LIST_FULFILLED_REDUCER,
  [ADP_PAYROLL_MAPPING_SET_FULFILLED] : ADP_PAYROLL_MAPPING_SET_FULFILLED_REDUCER,
  [ADP_PAYROLL_MAPPING_REMOVE_FULFILLED] : ADP_PAYROLL_MAPPING_REMOVE_FULFILLED_REDUCER,
};

const pluginSelector = state => state.vendors.plugin;

export const pluginList = createSelector(
  pluginSelector,
  instance => instance.list,
);

export const pluginFailureEmployeeList = createSelector(
  pluginSelector,
  instance => instance.pluginEmployeeList,
);

export const pluginAuthInfo = createSelector(
  pluginSelector,
  instance => instance.authInfo,
);

export const pluginFailureTimecardList = createSelector(
  pluginSelector,
  instance => instance.pluginTimecardList,
);
export const pluginSettingList = createSelector(
  pluginSelector,
  instance => instance.pluginSettingList
);
export const updatePluginSettingData = createSelector(
  pluginSelector,
  instance => instance.updatePluginData
);

export const pluginSetting = createSelector(
  pluginSelector,
  instance => instance.detail
);

export const filePath = createSelector(
  pluginSelector,
  instance => instance.filePath,
);

export const metcEmployeeList = createSelector(
  pluginSelector,
  instance => instance.employeeList,
);

export const syncInfoData = createSelector(
  pluginSelector,
  instance => instance.syncInfo,
);

export const metcAndQbdEmployeeList = createSelector(
  pluginSelector,
  instance => instance.metcAndQbdEmployeeList,
);

export const qbdJobsList = createSelector(
  pluginSelector,
  instance => instance.konnectJobsList,
);

export const searchMetcJobsList = createSelector(
  pluginSelector,
  instance => instance.qbdmetcJobsList,
);

export const qbdDropdownJobsList = createSelector(
  pluginSelector,
  instance => instance.qbdDropdownList,
);

export const payrollItemsList = createSelector(
  pluginSelector,
  instance => instance.payrollItems,
);

export const adjustmentTypesList = createSelector(
  pluginSelector,
  instance => instance.adjustmentList,
);

export const metcTasksList = createSelector(
  pluginSelector,
  instance => instance.metcTasks,
);

export const qbdTasksList = createSelector(
  pluginSelector,
  instance => instance.qbdTasks,
);

export const metcPayrollList = createSelector(
  pluginSelector,
  instance => instance.metcPayrollItems,
);

export const notificationList = createSelector(
  pluginSelector,
  instance => instance.notification_list,
);

export default createReducer(defaultState, handlers);
