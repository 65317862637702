import React from 'react';

import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

class InfoWindowComponent extends React.Component {
  componentDidMount() {
    this.renderInfoWindow();
  }

  componentDidUpdate(prevProps, prevState) {
    this.closeWindow();
    if (this.props.map !== prevProps.map) {
      this.renderInfoWindow();
    }
    if (this.props.children !== prevProps.children) {
      this.updateContent();
    }
    if (
      (this.props.visible !== prevProps.visible) ||
      (this.props.shape !== prevProps.shape) ||
      (this.props.shapeEvent !== prevProps.shapeEvent)
    ) {
      if (this.props.visible) this.openWindow();
      else this.closeWindow();
    }
  }

  updateContent() {
    const content = this.renderChildren();
    if (this.infowindow) this.infowindow.setContent(content);
  }

  openWindow() {
    const { map, shape, shapeEvent, data } = this.props;
    if (data.shape_type === 'Point') {
      this.infowindow.open(map, shape);
    } else {
      this.infowindow.setPosition(shapeEvent.latLng);
      this.infowindow.open(map);
    }
  }

  closeWindow() {
    this.infowindow.close();
  }

  renderInfoWindow() {
    const { google } = this.props;
    const iw = new google.maps.InfoWindow({
      content: ''
    });
    this.infowindow = iw;
  }

  renderChildren() {
    const { children } = this.props;
    return ReactDOMServer.renderToString(children);
  }

  render() {
    return null;
  }
}

InfoWindowComponent.propTypes = {
  shape: PropTypes.object,
  data: PropTypes.object,
  shapeEvent: PropTypes.object,
  visible: PropTypes.bool,
};

export default InfoWindowComponent;
