import React from "react";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import StorageGateway from "lib/storage-gateway";
import * as PayrollSettingDucks from "ducks/payrolls/setting";
import * as RouteDucks from "ducks/routes";
import * as InfoDucks from "ducks/info/info";
import TwoColumnLayout from "layouts/TwoColumn";
import { NAV } from "constants/pto";
import { toast } from "react-toastify";

let oAuthToken = "";
let company_id = "";
class PtoPolicyPage extends React.Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
    this.eventListnerFunction=this.eventListnerFunction.bind(this)
    this.postMessageToChild=this.postMessageToChild.bind(this)
  }
  /**
   * @description Navigates to the specified URL using the router's history object.
   * @param {string} url - The URL to navigate to.
   */
  goTo(url) {
    const {
      router: { history },
    } = this.context;
    history.push(url);
  }

  /**
   * @description Retrieves stored OAuth token and company ID before the component mounts.
   */
  componentWillMount() {
    oAuthToken = StorageGateway.get("oAuthToken");
    company_id = StorageGateway.get("company_id");
  }

  /**
   * @description Handles events from the postMessage API and navigates or displays toasts based on the event message.
   * @param {object} event - The event object containing the message data.
   */
  eventListnerFunction(event){
    const data =
          event.data &&
          typeof event.data !== "object" &&
          JSON.parse(event.data);
    const message = data?.message;
    if (message === "EDIT_POLICY") {
      this.props.history.push(`/pto-policy/${data?.content?.policyId}/policyDetails`);
      //this.policyTab(data,'edit');
    }else if (message === "EDIT_EMPLOYEE") {
      this.props.history.push({pathname:`/pto-policy/${data?.content?.policyId}/employee&policy_type=${data?.content?.policyType}`});
    }else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage)
    }
    else if (message === "FAILURE_TOAST") {
      toast.error(data?.content?.toastMessage);
    }
      
  }


  /**
   * @description Adds an event listener for messages when the component mounts.
   */
  componentDidMount(){
    window.addEventListener(
      "message",
      this.eventListnerFunction,
      false
    );

  }

  /**
   * @description Removes the message event listener when the component unmounts.
   */
  componentWillUnmount() {
    window.removeEventListener("message",this.eventListnerFunction);
  }

  /**
   * @description Sends a message to an iframe with the ID 'scheduling'.
   * @param {string} messageType - The type of message to send to the iframe.
   */
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }


  render() {
    return (
      <TwoColumnLayout
        navInfo={NAV.PTOSettingPage}
        addPolicy="addPolicy"
        ptoBulkUpload="ptoBulkUpload"
        goTo={this.goTo}
        fetchData={()=>this.postMessageToChild("REFRESH_TIMEOFF_LIST")}
      >
        <div
          style={{
            height: "calc(100vh - 102px - 46px)",
            marginRight: "-15px",
            marginTop: "-1rem",
          }}
        >
          <iframe
            id="scheduling"
            title="scheduling-iframe"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/timeoff-setting/?oAuthToken=${oAuthToken}&company_id=${company_id}`}
          />
        </div>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

PtoPolicyPage.propTypes = {
  PayrollSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

PtoPolicyPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(PtoPolicyPage);
