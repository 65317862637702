import React from "react";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as RouteDucks from "ducks/routes";
import * as EmployeeDucks from "ducks/employees/employee";

import { NAV } from "constants/forms";
import TwoColumnLayout from "layouts/TwoColumn";
import { toast } from "react-toastify";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import SectionLoaderAtom from "atoms/SectionLoader";

import CreateAssessmentFormComponent from "forms/components/createAssessmentForm";
import FieldFormProgressComponent from "forms/components/fieldFormProgress";
import AssessmentFormOutcome from "forms/components/assessmentFormOutCome";

class CreateAssessmentFormPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      templateData: { label: "", questions: [], outcome: "scoring" },
      initialValues: {},
      isLoading: true,
      count: 1,
      modalFlag: false,
      isSubmitting: false,
      parent: NAV.CreateAssessmentFormsPage.parent,
      saveAndNextButtonLoaderFlag: false,
    };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.loadData = this.loadData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.handleQuestionDelete = this.handleQuestionDelete.bind(this);
    this.handleQuestionAdd = this.handleQuestionAdd.bind(this);
    this.handleQuestionUpdate = this.handleQuestionUpdate.bind(this);
    this.labelChange = this.labelChange.bind(this);
    this.createForm = this.createForm.bind(this);
    this.updateForm = this.updateForm.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.updateOutcomeData = this.updateOutcomeData.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.backToListPage = this.backToListPage.bind(this);
  }

  handleSort(result) {
    if (result.destination !== null) {
      const { templateData } = this.state;
      let questions = [...templateData.questions];
      questions.splice(
        result.destination?.index,
        0,
        questions.splice(result.source.index, 1)[0]
      );
      questions = questions.map((item, idx) => ({
        ...item,
        sequence_of_configuration: idx + 1,
      }));
      this.setState({
        templateData: {
          ...templateData,
          questions,
        },
      });
    }
  }

  handleBack(stepNum) {
    const { count } = this.state;
    this.setState({ count: count - 1 });
  }
  componentDidMount() {
    this.loadData();
    this.setParentUrl();
    this.listenListUsecase();
  }

  loadData() {
    const {
      match: { params },
      location: { search },
    } = this.props;
    const query = queryString.parse(search);

    if (params.id !== "create") {
      this.usecase.getFormData({
        id: params.id !== "clone" ? params.id : query.form_id,
        form_type: "assessment",
      });
    } else {
      this.setState({ isLoading: false });
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenListUsecase() {
    const { history } = this.props;
    const {
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_TEMPLATE_DATA":
          this.updateState("templateData", event.data.template_data);
          this.updateState("initialValues", event.data.template_data);
          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        case "FORM_CREATED_SUCCESS":
          this.setState({ modalFlag: false });
          history.push({
            pathname: `/learning/trainingForms/${event.data.id}`,
            search: queryString.stringify(query),
          });
          toast.success("Form Created Successfully !!!");
          this.setState({ count: 2, saveAndNextButtonLoaderFlag: false });
          break;
        case "FORM_CREATED_FAILURE":
          toast.error(event?.data?.e ?? "Somthing Went Wrong !!!");
          this.setState({
            saveAndNextButtonLoaderFlag: false,
          });
          break;
        case "FORM_UPDATED_SUCCESS":
          toast.success("Form updated successfully !!!");
          this.updateState("templateData", event.data.template_data);
          this.updateState("initialValues", event.data.template_data);
          this.setState({
            count: this.state.count + 1,
            saveAndNextButtonLoaderFlag: false,
          });
          break;
        case "FORM_UPDATED_FAILURE":
          toast.error(event?.data?.e ?? "Somthing Went Wrong !!!");
          this.setState({
            saveAndNextButtonLoaderFlag: false,
          });
          break;
        case "ERROR_TOAST":
          toast.error("Something went wrong!");
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  handleQuestionDelete(idx) {
    const { templateData } = this.state;
    const newUpdateQuestion = templateData.questions.filter((ele) => {
      return ele.sequence_of_configuration !== idx;
    });
    this.setState({
      templateData: {
        ...this.state.templateData,
        questions: newUpdateQuestion.map((ele, index) => {
          return {
            ...ele,
            sequence_of_configuration: index + 1,
          };
        }),
      },
    });
  }

  handleQuestionAdd(newQus) {
    const { templateData } = this.state;
    this.setState({
      templateData: {
        ...templateData,
        questions: [...templateData.questions, newQus],
      },
    });
    this.toggleModal();
  }
  handleQuestionUpdate(updatedQus) {
    const { templateData } = this.state;
    const newUpdateQuestion = templateData.questions.map((ele) => {
      if (
        ele.sequence_of_configuration === updatedQus.sequence_of_configuration
      ) {
        return updatedQus;
      } else {
        return ele;
      }
    });
    this.setState({
      templateData: {
        ...this.state.templateData,
        questions: newUpdateQuestion,
      },
    });

    this.toggleModal();
  }

  createForm() {
    const { templateData } = this.state;
    this.setState({ saveAndNextButtonLoaderFlag: true });
    const submitData = { ...templateData };
    if (submitData.label) {
      submitData.label = submitData.label.trim();
    }
    let obj = {
      survey_type: ["assessment"],
      template_data: {
        ...submitData,
      },
    };
    this.usecase.createForm(obj);
  }

  updateForm() {
    const {
      match: { params },
    } = this.props;
    const { initialValues, templateData, count } = this.state;
    if (JSON.stringify(initialValues) === JSON.stringify(templateData)) {
      this.setState({ count: count + 1 });
      return;
    }
    this.setState({ saveAndNextButtonLoaderFlag: true });
    let obj = {
      survey_type: ["assessment"],
      template_data: {
        ...templateData,
        label: templateData.label.trim(),
      },
      id: params.id,
    };
    this.usecase.updateForm(obj);
  }

  async updateOutcomeData(data) {
    const {
      history,
      location: { search },
      match: { params },
    } = this.props;
    const query = queryString.parse(search);
    const { templateData, count } = this.state;
    if (JSON.stringify(data) !== JSON.stringify(templateData.scoring_actions)) {
      this.setState({ saveAndNextButtonLoaderFlag: true });
      await this.usecase.updateAssessmentOutcomeData(data, params.id);
    }
    history.push({
      pathname: `/learning/setup/assessments`,
      search: queryString.stringify(query),
    });
  }

  labelChange(e) {
    this.setState({
      templateData: { ...this.state.templateData, label: e.target.value },
    });
  }

  toggleModal() {
    const { modalFlag } = this.state;
    this.setState({ modalFlag: !modalFlag });
  }

  setParentUrl() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  backToListPage() {
    const {
      history,
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname: `/document/trainingForms`,
      search: queryString.stringify(query),
    });
  }

  render() {
    const {
      match: { params },
      location,
      history,
    } = this.props;
    const {
      isLoading,
      templateData,
      modalFlag,
      isSubmitting,
      parent,
      count,
      saveAndNextButtonLoaderFlag,
      initialValues,
    } = this.state;
    const query = queryString.parse(location.search);
    const title = `${query.form_name}`;

    return (
      <div>
        <div>
          <TwoColumnLayout
            navInfo={
              params.id === "create" || params.id === "clone"
                ? { ...NAV.CreateAssessmentFormsPage, parent }
                : { ...NAV.EditedAssessmentFormsPage, parent, title }
            }
          >
            {isLoading ? (
              <SectionLoaderAtom active />
            ) : (
              <div className="ml-3 mr-4 pb-5">
                <FieldFormProgressComponent
                  stepNumber={count}
                  // isStepThree={true}
                  history={history}
                />
                {count === 1 && (
                  <CreateAssessmentFormComponent
                    location={location}
                    templateData={templateData}
                    createForm={this.createForm}
                    updateForm={this.updateForm}
                    labelChange={this.labelChange}
                    handleQuestionAdd={this.handleQuestionAdd}
                    handleQuestionDelete={this.handleQuestionDelete}
                    handleQuestionUpdate={this.handleQuestionUpdate}
                    modalFlag={modalFlag}
                    toggleModal={this.toggleModal}
                    isSubmitting={isSubmitting}
                    id={params.id}
                    saveAndNextButtonLoaderFlag={saveAndNextButtonLoaderFlag}
                    handleSort={this.handleSort}
                    initialValues={initialValues}
                  />
                )}
                {count === 2 && (
                  <AssessmentFormOutcome
                    location={location}
                    count={count}
                    handleBack={this.handleBack}
                    submitData={this.updateOutcomeData}
                    saveAndNextButtonLoaderFlag={saveAndNextButtonLoaderFlag}
                    initialValues={{
                      success_message:
                        templateData.scoring_actions &&
                        templateData.scoring_actions.length &&
                        templateData.scoring_actions.find((i) => {
                          return i.action_code === "SUCCESS";
                        }).message,
                      failed_message:
                        templateData.scoring_actions &&
                        templateData.scoring_actions.length &&
                        templateData.scoring_actions.find(
                          (i) => i.action_code === "FAILED"
                        ).message,
                      total_questions: templateData?.questions?.length ?? 0,
                      correct_question:
                        templateData.scoring_actions &&
                        templateData.scoring_actions.length &&
                        templateData.scoring_actions.find(
                          (i) => i.action_code === "SUCCESS"
                        ).limit[0],
                    }}
                  />
                )}
                {/* {count === 3 && (
                  <AssignedEmployeesComponent
                    location={location}
                    count={count}
                    handleBack={this.handleBack}
                    history={history}
                    id={params.id}
                    backToListPage={this.backToListPage}
                  />
                )} */}
              </div>
            )}
          </TwoColumnLayout>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = (dispatch) => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CreateAssessmentFormPage);
