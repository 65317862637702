import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;
const KONNECT_URL = server.konnectHost;

export default {
  ACTIVITY: `${SERVER_URL}/activity/`,
  ACTIVITY_SPLIT: 'activity-split/',
  T2B: `${SERVER_URL}/t2b/`,
  MANUAL: 'manual/',
  AUDIT_LOG:'audits/audits',
  MEAL_PERIOD_SESSION: 'add-meal/',
  ACTIVITY_TRACK: 'tracking/',
  ACTIVITY_TRACK_SESSION: 'track/',
  ACTIVITY_DAILY_TIMECARD: 'daily-timecard/',
  IN_PROGRESS: `in-progress/`,
  LOCATION_ADDRESS: 'https://maps.googleapis.com/maps/api/geocode/json',
  ACTIVITY_TRACKING_SESSION: 'session/',
  HISTORIC: 'historic/',
  SESSION: '/session/',
  ACTIVITY_SPLIT_V2: 'activity-split/v2/',
  ADD_ACTIVITY_TASK: 'add-activity/v2/',
  DELETE_ACTIVITY_TASK : 'task/v2/deleted/',
  PUNCH_OUT: 'punch-out/',
  ONBOARD_ACTIVITY:`${SERVER_URL}/activity/last-session/`,
  PAY_MEAL:`${SERVER_URL}/activity/activity-splits/`,
  LOCATION_IQ_GEOCODING : `https://us1.locationiq.com/v1/reverse.php`,
  HERE_API_GEOCODING: `https://revgeocode.search.hereapi.com/v1/revgeocode`,
  ACTIVITY_ACCEPT_REJECT_SESSION:`${KONNECT_URL}/core/process-user-session/`,
  PTO_TIMECARD:`${SERVER_URL}/pto/sub-request`,
  PTO_REQUESTS:`${SERVER_URL}/pto/request`,
  ABSENT_ACTIVITY_TRACK: 'tracking-absent/',
  ALL_GEOFENCES: `${SERVER_URL}/geo-fence/mobile`
};
