import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';

import { SORT_DIRS } from 'constants/records';
import {ReactComponent as SortIcon} from './sort.svg';
import {ReactComponent as SortAscIcon} from './sort_asc.svg';
import {ReactComponent as SortDescIcon} from './sort_desc.svg';

class SortHeaderComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.sortDirection = this.sortDirection.bind(this);

    const { router: { route: { location: { search } } } } = context;
    const query = queryString.parse(search);
    this.state = { order: query.ordering && query.ordering[0] };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { location: { search: prevSearch } } } } = this.context;
    const { router: { route: { location: { search: nextSearch } } } } = nextContext;
    const query = queryString.parse(nextSearch);

    if (prevSearch !== nextSearch) {
      this.setState({ order: query.ordering && query.ordering[0] });
    }
  }

  handleChange() {
    const { router: { history, route: { location: { pathname, search } } } } = this.context;
    const { ordering } = this.props;
    const query = queryString.parse(search);

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        ordering: `${query.ordering && query.ordering[0] === SORT_DIRS.DESC
          ? SORT_DIRS.ASC : SORT_DIRS.DESC}${ordering}`,
      }),
    });
  }

  sortDirection() {
    const { router: { route: { location: { search } } } } = this.context;
    const { ordering } = this.props;
    const { order } = this.state;
    const query = queryString.parse(search);

    if (order && (query.ordering === ordering || query.ordering === `-${ordering}`)) {
      return query.ordering && query.ordering[0] === SORT_DIRS.DESC 
        ? <SortDescIcon width="12" height="12" />
        : <SortAscIcon width="12" height="12" />;
    }
    return <SortIcon width="12" height="12" />;
  }

  render() {
    const { children , className} = this.props;
    return (
      <th className={className}>
        {children}
        <Button
          color="link"
          className="p-0 pl-1"
          onClick={this.handleChange}
        >
          {this.sortDirection()}
        </Button>
      </th>
    );
  }
}

SortHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  ordering: PropTypes.string.isRequired,
};

SortHeaderComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SortHeaderComponent;
