import React from 'react';
import moment from 'moment';

import {Card , Row, Col, FormGroup, Input } from 'reactstrap';
import styles from './styles.module.scss';

const TimeCommentComponent = (props) => {
  const { data , index, timeFormat24Hrs, auditComponent } = props;
  return (
    <Card className={`${styles['card-ui-blank']} mr-3 pl-3 pr-3 pt-1 pb-1`}>
      <Row>
      <Col md={12} className={`${styles['custom-col']} pl-1`}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span className={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? `${styles['questions-ui']} ${styles['questions-no-padding']} pr-0 pl-2` : `${styles['questions-no-padding']} pl-2`} dangerouslySetInnerHTML={{ __html: data.question }}></span>
        </Col>
      </Row>
        { !auditComponent && data && data.response && data.response.length ? data.response.map((i)=>
        i.time ? 
            <div className={`${styles['resize-disabled']}`} style={{marginTop: '0.8rem'}}>
                <FormGroup>
                    <Input
                        type="textarea"
                        id={`${index}`}
                        name={`${index}`}
                        placeholder="Time"
                        rows="1"
                        value={i.time ? timeFormat24Hrs ? moment(i.time).format('HH:mm') :  moment(i.time).format('hh:mm a') : null}
                        disabled
                    />
                </FormGroup>
            </div>
            : null
          ) : 
            null
        }
        { auditComponent && data && data.answer && data.answer.length ? data.answer.map((i)=>
        i.time ? 
            <div className={`${styles['resize-disabled']}`} style={{marginTop: '0.8rem'}}>
                <FormGroup>
                    <Input
                        type="textarea"
                        id={`${index}`}
                        name={`${index}`}
                        placeholder="Time"
                        rows="1"
                        value={i.time ? timeFormat24Hrs ? moment(i.time).format('HH:mm') :  moment(i.time).format('hh:mm a') : null}
                        disabled
                    />
                </FormGroup>
            </div>
            : null
          ) : 
            null
        }
    </Card>
  );
};

export default TimeCommentComponent;