import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import StorageGateway from 'lib/storage-gateway';

import { server } from 'helpers/config';

const EVENT_TYPES = {
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  VERIFY_FAILURE: 'VERIFY_FAILURE',
  RESEND_CODE_SUCCESS: 'RESEND_CODE_SUCCESS',
};


class VerifyOTPUseCase {
 
  userAPIGateway = null;

  constructor(userAPIGateway) {
    
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  toString() {
    return 'verifyOTPUseCase';
  }

  setup_data(data) {
    this.code = data.code;
    this.uid = data.uid;
    this.password = data.password;
    this.device = 'WEB';
    this.grant_type= "password";
    this.client_id = server.ClientId;
    this.client_secret= server.ClientSecret;
  }

  async execute() {
    let errors = {};
    if (!this.code) {
      errors.code = 'Enter Verification Code To Verify It';
    } 
  
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }
    let response = null;
    response = await this.userAPIGateway.verify_otp(this.code, this.uid);
    StorageGateway.set('Token', response.data.token);
    const oAuthToken = await this.userAPIGateway.postAuthentication(this.client_id, this.client_secret, this.device, this.grant_type, this.password, response.data.username );
    StorageGateway.set('oAuthToken', oAuthToken);
    const profile = await this.userAPIGateway.getProfile()
    this.eventEmitter.emit({
     type: profile.password_expiry < 90 ? EVENT_TYPES.VERIFY_SUCCESS : EVENT_TYPES.VERIFY_FAILURE,
     data: profile
    });
  }

  async resendActivationCodes() {
    await this.userAPIGateway.resendCodes();
    this.eventEmitter.emit({
      type: EVENT_TYPES.RESEND_CODE_SUCCESS,
    });
  }
}

export default VerifyOTPUseCase;