 
//import { useParams } from "react";

//const params = useParams();

const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
];

export const NAV = {

  LearningCenterCategoriesPage: {
    title: 'Learning Bundles',
    breadcrumb: [
      ...baseNav,
      { label: 'Learning Bundles' },
    ],
    parent: '/dashboard',
    
  },
  EDocsPage: {
    title: 'Video/Content',
    breadcrumb: [
      ...baseNav,
      { label: 'Video/Content' },
    ],
    parent: '/dashboard',
    
  },
  TrainingPage: {
    title: 'Assessments',
    breadcrumb: [
      ...baseNav,
      { label: 'Assessments' },
    ],
    parent: '/dashboard',
  },
  LearninDocumentCompletionReportListPage: {
    title: 'Learning Documents Completion Report',
    breadcrumb: [
      ...baseNav,
      { label: 'list' },
    ],
    parent: '/dashboard',
    description: 'View your Employees learning documents completion status',
  },
  
};




export const TABS = [
  { key: 'categories', label: 'Learning Bundles' },
  { key: 'training-content', label: 'Videos/Content'},
  { key: 'assessments', label: 'Assessments'},
];


export default { NAV,  TABS, };
