import React from "react";

import PropTypes from "prop-types";
import { Modal, ModalHeader } from "reactstrap";
import closeIcon from "./Cross_icon.png";
import "./styles.css";
class LayerAtom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  render() {
    const { path, id = "scheduling" } = this.props;
    console.log(path, "path");
    return (
      <Modal
        isOpen={this.state.isOpen}
        contentClassName={"modal-content-css"}
        modalClassName={"modal-css"}
        className={"modal-dialog-css"}
      >
        <div>
          <div
            style={{
              position: "absolute",
              marginLeft: "-10px",
              cursor: "pointer",
              zIndex: "1056",
            }}
            onClick={() => this.props.setToggle()}
          >
            <img
              src={closeIcon}
              alt="Close"
              style={{ height: "21px", width: "21px", marginTop: "20px" }}
            />
          </div>
          <ModalHeader>Preview</ModalHeader>
          <div style={{ height: "90vh", overflow: "hidden", padding: "10px" }}>
            <iframe
              id={id}
              title="worksana_nre"
              width="100%"
              height="100%"
              frameBorder="0"
              src={path}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

LayerAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default LayerAtom;
