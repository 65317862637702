import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormText from 'reactstrap/lib/FormText';

import styles from './styles.module.scss';

class MultiSelectDropdownAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.state = {
      isOpen: false,
      selectedItems: [],
      items: props.items || [],
      inputValue: ""
    };
  }

  componentWillMount(){
     const {
      field, layout, label, help, options,
      getOptions, onChange, form: { errors, setFieldValue }, ...rest
    } = this.props;
    this.setState({items: this.props.items});
    let selectedItems = [];
    this.props.items && this.props.items.forEach((item)=>{
      this.props.value && this.props.value.length && this.props.value.forEach((i)=>{
        if(i === item.id){
          selectedItems.push(item);
        }
      })
    });
    this.setState({ selectedItems: selectedItems });
  }


  componentWillReceiveProps(nextProps) {
    const {
      field, selectItems, layout, label, help, options,
      getOptions, onChange, form: { errors, setFieldValue }, items,...rest
    } = this.props;
    const {selectedItems} = this.state
    if(JSON.stringify(nextProps.items) !==JSON.stringify(items)){
    const updatedSelectedItem = selectedItems.filter((item)=>{
      if(nextProps.items.findIndex(e => e.name === item.name)>-1){
        return true
      }else {
        return false
      }
    })
    this.setState({selectedItems:updatedSelectedItem})
    onChange([...updatedSelectedItem]);
    selectItems([...updatedSelectedItem]);
   }

   
    this.setState({items: nextProps.items});
  }

  handleChange(item) {
    const { selectedItems } = this.state;
    if (selectedItems.filter(i => item.id === i.id).length) {
      this.removeItem(item);
    } else {
      this.addSelectedItem(item);
    }
  }

  handleInputChange(e) {
    const { itemToString } = this.props;
    this.setState({inputValue: e.target.value})
    var a = (e.target.value).replace(/\\/g, "|")
    const rx = new RegExp(a, 'gi');
    e.stopPropagation();
    this.setState({ items: this.props.items.filter(i => itemToString(i).match(rx)) });
  }

  addSelectedItem(item) {
    const { onChange , selectItems } = this.props;
    const { input } = this.props;
    console.log({item})
    this.props.addItem && this.props.addItem(item);
    this.setState({inputValue: ""});
    this.setState({items: this.props.items});
    this.setState(({selectedItems}) => ({
      selectedItems: [...selectedItems, item],
    }));
    onChange([...this.state.selectedItems, item]);
    selectItems([...this.state.selectedItems, item]);
  }

  /* eslint-disable */
  removeItem(item) {
     const { onChange , selectItems } = this.props;
    const { input } = this.props;
    this.props.removedItem && this.props.removedItem(item);
    this.setState(({ selectedItems }) => {
      return {
        selectedItems: selectedItems.filter(i => item.id !== i.id),
      };
    });
    onChange(this.state.selectedItems.filter(i => item.id !== i.id));
    selectItems(this.state.selectedItems.filter(i => item.id !== i.id));
  }


  handleToggleMenu() {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const {
      input,
      label,
      id,
      itemToString,
      help,
      placeholder,
    } = this.props;
    const { isOpen, items, selectedItems, inputValue } = this.state;
    const filteredSelected = selectedItems.filter((elem) => items.find(({ id }) => elem.id === id));
    return (
      /* eslint-disable */
      <Downshift
        itemToString={itemToString}
        onSelect={this.handleChange}
        // onStateChange={this.handleStateChange}
        defaultSelectedItem={filteredSelected}
        selectedItem={filteredSelected}
        isOpen={isOpen}
        onOuterClick={this.handleToggleMenu}
        render={({
          getInputProps,
          getButtonProps,
          getItemProps,
          getLabelProps,
          isOpen,
          selectedItem,
          highlightedIndex,
        }) => {
          return (<div style={{ position: 'relative' }} className="custom-multi-select">
            {label &&
              <label
                {...getLabelProps({
                  htmlFor: id,
                  className: 'col-form-label',
                  style: { width: '100%' },
                })}
              >
                {label}
              </label>
            }
            {/* eslint-enable */}
            <div
              {...getButtonProps({
                className: `${styles['multi-select']} muti-select-wrap`,
                onClick: this.handleToggleMenu,
              })}
            >
              <div className={styles['multi-select__content']}>
                {selectedItem && selectedItem.length > 0 &&
                  selectedItem.map(item =>
                    <label
                      key={item.id}
                      className={styles['multi-select__chip']}
                      htmlFor="chip"
                    >
                      {itemToString(item)}
                      <span
                        className="pointer"
                        role="button"
                        tabIndex="0"
                        onClick={e => {
                          e.stopPropagation();
                          this.removeItem(item);
                        }}
                      >
                        &nbsp;x
                      </span>
                    </label>,
                  )
                }
                <input
                  {...getButtonProps({
                    placeholder: selectedItem.length ? '' : placeholder,
                    onChange: this.handleInputChange,
                    onClick: (e) => {
                      if (isOpen) e.stopPropagation();
                    },
                    value: inputValue,
                    className: styles['multi-select__input']
                  })}
                />
              </div>
            </div>
            <span onClick={this.handleToggleMenu} style={{ position: 'absolute' , top: '12px'}} className="dropdown-arrow d-inline-block" />
            {isOpen ? (
              <div className={styles['multi-select__dropdown']}>
                {items.length
                  ? items.map((item, index) => (
                    <div
                      key={item.id}
                      {...getItemProps({item})}
                      style={{
                        backgroundColor:
                          highlightedIndex === index ? '#9CDAD7' : 'white',
                        fontWeight: filteredSelected.filter(i => item.id === i.id).length > 0
                          ? 'bold' : 'normal',
                        lineHeight: '1.1',
                        fontSize: '14px',
                        padding: '0.5rem',
                      }}
                      className="cursor-pointer"
                    >
                      {itemToString(item)}
                    </div>
                  ))
                  : <div className={styles['multi-select__no-results']}>No Results Found</div>
                }
              </div>
            ) : null}
            <FormText color="muted">{help}</FormText>
            
          </div>);
        }}
      />
    );
  }
}

MultiSelectDropdownAtom.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
};

export default MultiSelectDropdownAtom;
