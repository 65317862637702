import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { UncontrolledTooltip } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { toast } from 'react-toastify';


import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';
import * as UserDucks from 'ducks/accounts/user';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as JobDucks from 'ducks/jobs/job';
import * as JobTagDucks from 'ducks/jobs/tag';
import * as TaskTagDucks from 'ducks/jobs/taskTag';
import * as CustomerTagDucks from 'ducks/customers/tag';
import * as TaskDucks from 'ducks/jobs/task';
import * as CustomerDucks from 'ducks/customers/customer';
import * as JobReportsDucks from 'ducks/jobs/reports';

import SectionLoaderAtom from 'atoms/SectionLoader';
import JobReportsFilterFormComponent from 'components/jobs/reports/FilterForm';
import showHideIcon from './showHideIcon.png'
import ButtonGroupAtom from 'atoms/ButtonGroup';

import { GROUP_BY_OPTIONS } from 'constants/jobs';
import { DECIMAL_VIEW_OPTIONS } from 'constants/timecards';


class JobReportsFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.onTeamSelect = this.onTeamSelect.bind(this);
    this.getDepartments = this.getDepartments.bind(this);
    this.getTeams = this.getTeams.bind(this);
    this.getJobs = this.getJobs.bind(this);
    this.getTasks = this.getTasks.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.getEmployees =  this.getEmployees.bind(this);
    this.getReports = this.getReports.bind(this);
    this.toggleSummaryView = this.toggleSummaryView.bind(this);
    this.onDecimalHHMMToggle = this.onDecimalHHMMToggle.bind(this);
    this.state = {
      deptIds: [],
      teamIds: [],
      dataSelected: {},
      dropdownOpen: false,
      selectedItem: GROUP_BY_OPTIONS[0],
      inactiveEmployees: false,
      detailViewDisplay: true,
    };
    this.exportReport = this.exportReport.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.handleInactiveEmployees = this.handleInactiveEmployees.bind(this);
  }



  componentWillMount(){
    const { reportInitialValues } = this.props;
    this.props.EmployeeDucks.getEmployeeTags({paginate: false, tag_type: 3})
    this.props.TaskDucks.getAssignedTaskAndTaskCodes({ admin: true, paginate : false});
    this.props.CustomerTagDucks.getCustomerTags({ paginate:false, tag_type: 0 });
    this.props.JobTagDucks.getJobTags({ paginate:false, tag_type: 2 });
    this.props.TaskTagDucks.getTaskTags({ paginate:false, tag_type: 1 });
    if(reportInitialValues && reportInitialValues.group_by){
      const item = GROUP_BY_OPTIONS.find((i) => i.value === reportInitialValues.group_by);
      this.setState(({ selectedItem }) => ({ selectedItem: item }));
    }
  }

  componentWillReceiveProps(nextProps){
    const { router: { history } } = this.context;
    const { location: { query: prevQuery }, fetchData } = this.props;
    const { location: { query: nextQuery , pathname } } = nextProps;
    if(this.props.isLoading !== nextProps.isLoading){
      this.setState({ deptIds: [], teamIds: [] });
    }
    if(JSON.stringify(this.props.jobReportsUserPreference) !== JSON.stringify(nextProps.jobReportsUserPreference) && nextProps.jobReportsUserPreference && nextProps.jobReportsUserPreference.data && nextProps.jobReportsUserPreference.data.ordering && this.props.jobReportsUserPreference.data && nextProps.jobReportsUserPreference.data.ordering !== this.props.jobReportsUserPreference.data.ordering){
       history.push({
        pathname,
        search: queryString.stringify({
          ordering: nextProps.jobReportsUserPreference.data.ordering,
          ...this.state.dataSelected,
          ...nextQuery
        }),
      });
    }
    if(nextQuery.ordering !== prevQuery.ordering){     
      fetchData({ordering : nextQuery.ordering, ...this.state.dataSelected, ...nextQuery});
    }
  }



  toggleSummaryView(value) {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;

    if(query){
      history.push({
        pathname,
        search: queryString.stringify({
          type:value ? 'detail' : 'summary',
        }),
      });
    }
    // dataSelected.report_view = value ? 1 : 2;
    // this.setState({
    //   detailViewDisplay: value,
    //   dataSelected: dataSelected
    // });
    // this.handleSelection(selectedItem);
  }

  onDecimalHHMMToggle(val) {
    const { router: { history } } = this.context;
    const { location: { pathname, query }, fetchData } = this.props;
    const { dataSelected } = this.state;
    this.props.toggleTableLoader();
    return this.props.BasicSettingDucks.putBasicSetting({ timecard_decimal_view: val })
      .then(() =>
      {
         history.push({
          pathname,
          search: queryString.stringify({ ...query, timecard_decimal_view: val }),
          });
          fetchData({...dataSelected, ...query});

      });
  }

  onDepartmentSelect(val, dept) {
    const departmentIds = dept.map((i) => i.id);
    this.setState({ deptIds: departmentIds });
   
    if(departmentIds.length){
      this.props.TeamDucks.getTeams({ department_ids: JSON.stringify(departmentIds), paginate: false });
    }
    else{
      this.props.TeamDucks.resetTeam();
    }
  }

  onTeamSelect(val, team) {
    const teamId = team.map((i) => i.id);
    this.setState({ teamIds: teamId });
  }

  getDepartments(val){
    return this.props.DepartmentDucks.getAllDepartments({ paginate: false});
  }

  getTeams(val){
    const { deptIds } = this.state;
    let detail = { paginate: false };
    if (deptIds.length){
      detail = { department_ids: JSON.stringify(deptIds), paginate: false }
    }
    return this.props.TeamDucks.getTeams(detail);
  }

  getJobs(val){
    return this.props.JobDucks.getJobsAndJobCodes({admin: true, paginate: false});
    /*return this.props.JobDucks.getAllJobs({ paginate: false });*/
  }

  getTasks(val){
      return this.props.TaskDucks.getAssignedTaskAndTaskCodes({ ...val});
  }

  getCustomers(val){
    return this.props.CustomerDucks.getCustomers({ paginate: false });
  }

  getEmployees(val){
    const { deptIds, teamIds, inactiveEmployees } = this.state;
    const detail = { search: val, is_active: true , name_search : true };
    if(inactiveEmployees){
      delete detail['is_active'];
    }
    if(deptIds.length){
      detail.department_ids = JSON.stringify(deptIds);
    }
    if(teamIds.length){
      detail.team_ids = JSON.stringify(teamIds);
    }
    return this.props.EmployeeDucks.getEmployees(detail);
  }

  getReports(data){
    const { selectedItem } = this.state;
    const { fetchData } = this.props;
    const  detail = { ...data, grouped: selectedItem.value, group_by: selectedItem.value };
    this.setState({ dataSelected: detail });
    return fetchData(detail);
  }

  exportReport(){
    const { jobReportsList , showableAttributes, location: { query } } = this.props;
    const { dataSelected, selectedItem } = this.state;
    const { ordering, ...rest } = dataSelected;
    const showFields = Object.keys(showableAttributes).filter((item) => {
      if(this.props.showableAttributes[item] === true)
        return item
      else
        return false
    })


    if(!jobReportsList.length) return Promise.reject(toast.error('There are no records to download'));
    return this.props.JobReportsDucks.exportReports({ ...rest, ...query, group_by: selectedItem.value, paginate: false, export: 'csv' , show :  JSON.stringify(showFields) });
  }

  dropdownToggle(){
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  handleSelection(item){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const { fetchData, toogleTable } = this.props;
    const { dataSelected } = this.state;
    this.setState({ selectedItem: item });
    toogleTable(item);
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
      grouped:item.value,
      group_by: item.value
      }),
    });
    return fetchData({ ...dataSelected, grouped: item.value, group_by: item.value , ordering: query.ordering});
  }

  handleInactiveEmployees(val){
    this.props.EmployeeDucks.resetEmployee();
    this.setState({ inactiveEmployees: val });
  }

  render() {
    const { location,dateFormat , departmentAll, jobAll, jobTagList, customerTagList, taskTagList,
      isOrganizationVisible, TasksAndCodesList, teamList, employeeList, isLoading, taskAll, customerList, reportInitialValues, employeeReport, taskAssignedList, setAttributeToggler , basicSettingDetail } = this.props;
    const { selectedItem, detailViewDisplay } = this.state;
    if(isLoading) return <SectionLoaderAtom active />;

    const newJobTags = jobTagList[0] ? (jobTagList[0].tag_collection.map((item) => {
      const list = {};
      list.name = item;
      list.id = item;
      return list;
    })) : [];

    const newTaskTags = taskTagList[0] ? (taskTagList[0].tag_collection.map((item) => {
      const list = {};
      list.name = item;
      list.id = item;
      return list;
    })) : [];

    const newCustomerTags = customerTagList[0] ? (customerTagList[0].tag_collection.map((item) => {
      const list = {};
      list.name = item;
      list.id = item;
      return list;
    })) : [];

    return (
      <div>
        <Row className="ml-3 mr-4">
          <Col md={12} className="px-0">
            <JobReportsFilterFormComponent
              toggleSummaryView={this.toggleSummaryView}
              detailViewDisplay={detailViewDisplay}
              location={location}
              dateFormat={dateFormat}
              isOrganizationVisible={isOrganizationVisible}
              departmentList={departmentAll}
              teamList={teamList}
              onDepartmentSelect={this.onDepartmentSelect}
              onTeamSelect={this.onTeamSelect}
              employeeList={employeeList.results}
              jobList={jobAll}
              jobsAndCodesList ={this.props.jobsAndCodesList}
              jobTagList={newJobTags}
              taskTagList={newTaskTags}
              customerTagList={newCustomerTags}
              taskList={taskAll && taskAll.results}
              taskAssignedList={taskAssignedList && taskAssignedList.results}
              customerList={customerList}
              getDepartments={this.getDepartments}
              getTeams={this.getTeams}
              getJobs={this.getJobs}
              getTasks={this.getTasks}
              getCustomers={this.getCustomers}
              getEmployees={this.getEmployees}
              TasksAndCodesList={TasksAndCodesList}
              getReports={this.getReports}
              initialValues={{ ...reportInitialValues,
                status: { value: '0', label: 'All' },
                billable: { value: '1', label: 'All' },
                report_view: { value: '1', label: 'Details' },
              }}
              handleInactiveEmployees={this.handleInactiveEmployees}
              exportReport={this.exportReport}
              tagsList={this.props.tagsList}
              selectedDepartment = {this.state.deptIds}
            />
          </Col>
        </Row>
        <Row className="filter mt-4 ml-3 mr-4">
          <Col className="pt-1 pl-0">
            {selectedItem.value === 'employee' && +employeeReport === 1 && <h5 className="text-left black-text">Employee Reports </h5>}
            {selectedItem.value === 'job' && +employeeReport === 1 && <h5 className="text-left black-text">Job Reports </h5>}
            {selectedItem.value === 'customer' && +employeeReport === 1 && <h5 className="text-left black-text">Customer Reports </h5>}
            {selectedItem.value === 'task' && +employeeReport === 1 && <h5 className="text-left black-text">Task Reports </h5>}
            {selectedItem.value === 'employee' && +employeeReport === 2 && <h5 className="text-left black-text">Employee Summary </h5>}
            {selectedItem.value === 'job' && +employeeReport === 2 && <h5 className="text-left black-text">Job Summary </h5>}
            {selectedItem.value === 'task' && +employeeReport === 2 && <h5 className="text-left black-text">Task Summary </h5>}
            {selectedItem.value === 'customer' && +employeeReport === 2 && <h5 className="text-left black-text">Customer Summary </h5>}
          </Col>
          <Col className="pr-0">
            <Button
              onClick={() => setAttributeToggler(!this.props.attributeToggler)}
              color="accent"
              className="mr-0 ml-2 py-1"
              style={{float: 'right'}}
             >
              <div id="show_hide" style={{padding: '1.5px'}}>
                <img
                  src={showHideIcon}
                  alt="Icon"
                  height="15"
                  width="15"
                />
                <UncontrolledTooltip placement="top" target="show_hide">
                  Show/Hide
                </UncontrolledTooltip>
              </div>
            </Button>
            <section className="float-right ml-3 button-job-override">
                <span id="decimal-view">
                  <ButtonGroupAtom
                    options={DECIMAL_VIEW_OPTIONS}
                    active={basicSettingDetail.timecard_decimal_view}
                    onToggle={this.onDecimalHHMMToggle}
                  />
                </span>
                <UncontrolledTooltip placement="top" target="decimal-view">
                  Time Format
                </UncontrolledTooltip>
              </section>
            <div className="d-inline-block float-right grouped-by-btn mt-1">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.dropdownToggle()}>
                <DropdownToggle caret>
                  { selectedItem.label }
                </DropdownToggle>
                <DropdownMenu right>
                  {GROUP_BY_OPTIONS.map((item) => (
                    <DropdownItem onClick={() => this.handleSelection(item)}>
                      {item.label}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <span className="float-right pr-2 mt-2 ml-2 black-text">Grouped by </span>
          </Col>
        </Row>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  userExtraData: UserDucks.userExtraData(state),
  teamList: TeamDucks.teamList(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  employeeList: EmployeeDucks.employeeList(state),
  jobAll: JobDucks.jobAll(state),
  jobTagList: JobTagDucks.jobTagList(state),
  taskTagList: TaskTagDucks.taskTagList(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
  taskAll: TaskDucks.taskAll(state),
  taskAssignedList: TaskDucks.taskAssignedList(state),
  TasksAndCodesList: TaskDucks.TasksAndCodesList(state),
  customerList: CustomerDucks.customerList(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  jobReportsList: JobReportsDucks.jobReportsList(state),
  reportInitialValues: JobDucks.reportInitialValues(state),
  jobsAndCodesList: JobDucks.jobsAndCodesList(state),
  tagsList: EmployeeDucks.tagsList(state),
  jobReportsUserPreference: JobReportsDucks.jobReportsUserPreference(state),
});

const mapActionsToProps = dispatch => ({
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  JobTagDucks: bindActionCreators(JobTagDucks, dispatch),
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  JobReportsDucks: bindActionCreators(JobReportsDucks, dispatch),
});

JobReportsFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  toogleTable: PropTypes.func,
};

JobReportsFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobReportsFilterSection);
