import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import GeoFenceBreachDetailTableComponent from 'components/reports/geoFenceBreach/DetailTable';
import SessionMapModalComponent from 'components/activities/activityTrackList/SessionMapModal';

import { PAGE_COUNT } from 'constants/records';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

/**
 * ReportDetailPage -> GeoFenceBreachDetailListSection
 *
 * Components:
 *    - {@link JobSummaryTableComponent}
 *    - {@link PaginationComponent}
 *
 * State:
 *    - reportData
 *
 * Actions:
 *    None
 */
class GeoFenceBreachDetailListSection extends React.Component {
  constructor(props) {
    super(props);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.resetModalData = this.resetModalData.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
    this.state = { isOpen: false, isModalLoading: false,
      isLocationLoading: false,
      permission: true };
  }

  getLocationAddress(marker) {
    if(!marker.address){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker, GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }


  resetModalData(){
    return this.props.ActivityTrackDucks.resetSessionInfo();
  }

  handleMarkerClick(marker, showBeacon) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
    if(showBeacon) return this.getLocationAddress(marker);
  }

  toggle(data) {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
    if (data && data.session_id) {
      this.setState({ isModalLoading: true, isLocationLoading: true });
      this.props.ActivityTrackDucks.getActivityTrackingSession({id: data.session_id})
        .then(() => {
          const { sessionInfo } = this.props;
          const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
          this.codeLatLng(arr);
          if (Object.keys(sessionInfo).length > 0) {
            this.props.GeoFenceDucks.getActivityTrackGeofences({id: sessionInfo.id})
              .then(() => this.setState({ isModalLoading: false }))
              .catch(() => this.setState({ isModalLoading: false }));
          }
          else this.setState({ isModalLoading: false })
        })
        .catch(() => this.setState({ isModalLoading: false,
          isLocationLoading: false,
          permission: false }));
    }
  }

  codeLatLng(array) {
    const updatedArr = array.length ? array.filter((i)=> i.position && i.position.lat !== undefined && i.position.lng !== undefined) : array;
    if(!updatedArr || !updatedArr.length) return this.setState({isLocationLoading: false });
    this.setState({ isLocationLoading:  true });
    Promise.all(updatedArr.map((item, index) =>
      setTimeout(()=>{this.props.ActivityTrackDucks.getActivityLocationAddress(item, GeocodingOption)
        .then((res) => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...item,
            address: selectedMarkerAddress,
          });
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })  
             
          }
        })
        .catch(() => {
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })            
          }
        })
      },100*index)
    ))
      .catch(() => this.setState({ isLocationLoading:  false }));
    return null;
  }

  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
  }

  render () {
    const {
      data,
      isLoading,
      location,
      reportDetail,
      sessionInfo,
      selectedMarkerAddress,
      geoFenceDetail
    } = this.props;
    const {query} = location;
    const { isOpen, isModalLoading, isLocationLoading, permission } =this.state;
    const cookieData = cookie.load("geo_fence_breach_detail_page_size");
    let pageSize;

    if(cookieData) pageSize = +cookieData;
    else if (query && query.page_size)  pageSize = +query.page_size;
    else pageSize = 10;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <GeoFenceBreachDetailTableComponent
          data={data}
          location={location}
          pageSize={pageSize}
          child={reportDetail.parent_report}
          toggle={this.toggle}
        />
        <PaginationComponent
          count={data.length}
          location={location}
          pageCount={PAGE_COUNT}
          cookieKey="geo_fence_breach_detail_page_size"
        />
        {isOpen &&
          <SessionMapModalComponent
            isOpen={isOpen}
            sessionInfo={sessionInfo}
            onMarkerClick={this.handleMarkerClick}
            onMarkerClose={this.handleMarkerClick}
            toggle={this.toggle}
            selectedMarkerAddress={selectedMarkerAddress}
            geoFenceDetail={geoFenceDetail}
            isModalLoading={isModalLoading}
            toggleInfoWindow={this.toggleInfoWindow}
            isLocationLoading={isLocationLoading}
            resetModalData={this.resetModalData}
            getLocationAddress={this.getLocationAddress}
            permission={permission}
            userExtraData={this.props.userExtraData}
            getAddressForMarker = {this.getAddressForMarker}
            accessSidemenu={this.props.accessSidemenu}
          />
        }
      </section>
    );
  }
};

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state),
  reportDetail: ReportDucks.reportDetail(state),
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
  userExtraData: UserDucks.userExtraData(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

GeoFenceBreachDetailListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  selectedMarkerAddress: PropTypes.string,
  sessionInfo: PropTypes.object,
  geoFenceDetail: PropTypes.object,
};

GeoFenceBreachDetailListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceBreachDetailListSection);
