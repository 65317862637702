import React from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';
import * as JobReportsDucks from 'ducks/jobs/reports';
import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import SectionLoaderAtom from 'atoms/SectionLoader';
import TimecardOverlayModal from "components/jobs/reports/TimecardOverlayModal";
import EmployeeReportsDetail from 'components/jobs/reports/Table';
import CustomerSummary from 'components/jobs/reports/summaryCustomerTable';
import JobSummary from 'components/jobs/reports/summaryJobTable';
import TaskSummary from 'components/jobs/reports/summaryTaskTable';
import EmployeeReportsSummary from 'components/jobs/reports/summaryEmployeeTable';
import PaginationComponent from 'components/common/Pagination';


class JobReportsListSection extends React.PureComponent {

  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
    this.saveOrdering = this.saveOrdering.bind(this);
    this.state = {
      isOpen : false,
      timecardItem: null,
      JobUrlClicked: null,
      CustomerUrlClicked: null,
      timecardUrlClicked: null,
      employeeUrlClicked: null,
    }
  }

  toggle(item) {
    const { isOpen } = this.state;   
    this.setState({ isOpen: !isOpen, timecardItem: item });
  }

  componentWillReceiveProps(nextProps){
    const { location: { query } } = this.props;
    const { location: { search } } = nextProps;
    const nextQuery = queryString.parse(search);
     if(query.ordering !== nextQuery.ordering && nextQuery.ordering && nextProps.jobReportsUserPreference.data.ordering !== nextQuery.ordering  ){
      this.saveOrdering(nextQuery);
    }
  }

  saveOrdering(nextQuery) {
    const { location: {  query, pathname } , jobReportsUserPreference } = this.props;
    const { router: { history } } = this.context;
    let detail;
    const showFields = Object.keys(this.props.showableAttributes).filter((item) => {
      if(this.props.showableAttributes[item] === true)
        return item
      else if(this.props.showableAttributes[item] === false && item === "name")
        return true
      else
        return false
    })
    if(nextQuery.type == 'detail'){
      const {show_fields, ...rest} = jobReportsUserPreference.data ;
      detail = {
       show_fields: showFields, ...rest
     };
    }
    else if(nextQuery.grouped == 'job'){
      const {show_fields_job, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_job: showFields, ...rest
      };
    }
    else if(nextQuery.grouped == 'employee'){
      const {show_fields_employee, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_employee: showFields, ...rest
      };
    }
    else if(nextQuery.grouped == 'customer'){
      const {show_fields_customer, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_customer: showFields, ...rest
      };
    }
    else if(nextQuery.grouped == 'task'){
      const {show_fields_task, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_task: showFields, ...rest
      };
    }
    else{
      const {show_fields_employee, ...rest} = jobReportsUserPreference.data ;
      detail = {
        show_fields_employee: showFields, ...rest
      };
    }
    this.props.JobReportsDucks.putUserPreference({data: {...detail , ordering : nextQuery.ordering} , preference_type : 'user_preference_job_reports'})
  }

  goTo(url, type, item){
    const { router: { history } } = this.context;
    const { location: { pathname, query, search } } = this.props;
    if(type === "timecards"){
      let start = item.start_datetime ? moment(item.start_datetime).format('YYYY-MM-DD') : queryString.parse(search).start_date;
      let end = item.end_datetime ? moment(item.end_datetime).format('YYYY-MM-DD') : queryString.parse(search).end_date;
      let parsedQuery = queryString.stringify({start_date: start, end_date: end, employee_id: item.employee_id, first_name: item.first_name, last_name: item.last_name, custom: true});
      if(this.state.timecardUrlClicked === null || (this.state.timecardUrlClicked !== null && this.state.timecardUrlClicked.parent === null)){
        this.setState({timecardUrlClicked: window.open(`${url}?${parsedQuery}`)})
      }else{
        history.push({ pathname: this.state.timecardUrlClicked.location.href = `${url}?${parsedQuery}` });
        this.state.timecardUrlClicked.focus();
        history.push({pathname: pathname, search: queryString.stringify({ ...query })});
      }
    }else if(type === "job"){
      if(this.state.JobUrlClicked === null || (this.state.JobUrlClicked !== null && this.state.JobUrlClicked.parent === null)){
        this.setState({JobUrlClicked: window.open(url)})
      }else{
        history.push({ pathname: this.state.JobUrlClicked.location.href = url });
        this.state.JobUrlClicked.focus();
        history.push({pathname: pathname, search: queryString.stringify({...query})});
      }
    }else if(type === "customer"){
      if(this.state.CustomerUrlClicked === null || (this.state.CustomerUrlClicked !== null && this.state.CustomerUrlClicked.parent === null)){
        this.setState({CustomerUrlClicked: window.open(url)})
      }else{
        history.push({ pathname: this.state.CustomerUrlClicked.location.href = url });
        this.state.CustomerUrlClicked.focus();
        history.push({pathname: pathname, search: queryString.stringify({ ...query })});
      }
    }else if(type === "employee"){
      if(this.state.employeeUrlClicked === null || (this.state.employeeUrlClicked !== null && this.state.employeeUrlClicked.parent === null)){
        this.setState({employeeUrlClicked: window.open(url)})
      }else{
        history.push({ pathname: this.state.employeeUrlClicked.location.href = url });
        this.state.employeeUrlClicked.focus();
        history.push({pathname: pathname, search: queryString.stringify({...query})});
      }
    }
  }

  render() {
    const { location, jobReportsList, dateFormat, timecardDecimalView, isLoading, groupBy, employeeReport, showableAttributes } = this.props;
    const { query } = location;
    const { isOpen, timecardItem } = this.state;
    const cookieData = cookie.load("job_reports_page_size");
    let pageSize;

    if (query && query.page_size)  pageSize = +query.page_size;
    else if(cookieData) pageSize = +cookieData;
    else pageSize = 10;
    const dataLength = jobReportsList && jobReportsList.length ? jobReportsList.filter((i) => i.start_datetime !== 'Total').length : 0;
    if (isLoading) return (<div style={{ position: "relative",bottom:"50px"}}>
        <SectionLoaderAtom active />
        </div>)
    return (
      <section>
        {(groupBy === 'employee' || groupBy === 'job' || groupBy === 'task' || groupBy === 'customer') && +employeeReport === 1 &&
          <EmployeeReportsDetail
            data={jobReportsList}
            location={location}
            pageSize={pageSize}
            groupBy={groupBy}
            showableAttributes={showableAttributes}
            toggle={this.toggle}
            goTo ={this.goTo}
          />
        }
        {groupBy === 'job' && +employeeReport === 2 &&
          <JobSummary
            data={jobReportsList}
            location={location}
            pageSize={pageSize}
            groupBy={groupBy}
            showableAttributes={showableAttributes}
            toggle={this.toggle}
            goTo ={this.goTo}
          />
        }
        {groupBy === 'task' && +employeeReport === 2 &&
          <TaskSummary
            data={jobReportsList}
            location={location}
            pageSize={pageSize}
            groupBy={groupBy}
            showableAttributes={showableAttributes}
            toggle={this.toggle}
            goTo ={this.goTo}
          />
        }
        {groupBy === 'customer' && +employeeReport === 2 &&
          <CustomerSummary
            data={jobReportsList}
            location={location}
            pageSize={pageSize}
            groupBy={groupBy}
            showableAttributes={showableAttributes}
            toggle={this.toggle}
            goTo ={this.goTo}
          />
        }
        {groupBy === 'employee' && +employeeReport === 2 &&
          <EmployeeReportsSummary
            data={jobReportsList}
            location={location}
            pageSize={pageSize}
            groupBy={groupBy}
            showableAttributes={showableAttributes}
            toggle={this.toggle}
            goTo ={this.goTo}
          />
        }
        <PaginationComponent
          count={jobReportsList.length}
          location={location}
          cookieKey="job_reports_page_size"
          displayCount={dataLength}
        />
        {isOpen &&
            <TimecardOverlayModal
              isOpen={isOpen}
              toggle={this.toggle}
              timecardItem={timecardItem}
              location={location}
              dateFormat={dateFormat}
              timecardDecimalView={timecardDecimalView}
            />
          }
      </section>
    );
  }
}

const mapStateToProps = state => ({
  jobReportsList: JobReportsDucks.jobReportsList(state),
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormatDisplay(state),
  timecardDecimalView: BasicSettingDucks.timecardDecimalView(state),
  jobReportsUserPreference: JobReportsDucks.jobReportsUserPreference(state),
});

const mapActionsToProps = dispatch => ({
  JobReportsDucks: bindActionCreators(JobReportsDucks, dispatch),
});

JobReportsListSection.propTypes = {
  location: PropTypes.object,
  jobReportsList: PropTypes.array,
  groupBy: PropTypes.string,
  dateFormat: PropTypes.string.isRequired,
};

JobReportsListSection.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobReportsListSection);
