import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';


import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';

import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as JobDucks from 'ducks/jobs/job';

import FilterDropdownComponent from 'components/common/FilterDropdown';
import FilterFormComponent from 'components/common/FilterForm';
import JobFilterFormComponent from 'components/jobs/list/JobFilterForm';

import { FILTER_OPTIONS } from 'constants/jobs';
/*
 *
 * JobScheduleListPage -> JobScheduleFilterSection
 *
 * Components:
 *    - {@link FilterDropdownComponent}
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    postActiveStatus
 */

class JobFilterSection extends React.Component {
  constructor(props) {
    super(props);
    /*this.goTo = this.goTo.bind(this);*/
    this.enableCardView=this.enableCardView.bind(this);
    this.state = { isOpen: false, listViewDisplay: true };
  }


  /*goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }*/

  enableCardView(){
    const { cardViewToggle } = this.props;
    cardViewToggle();
    const {listViewDisplay } = this.state;
    this.setState({listViewDisplay : !listViewDisplay});
  }


  render() {
    const { location, dateFormat, jobStatusCookieKey, jobStartDateCookieKey, jobEndDateCookieKey } = this.props;
    const { listViewDisplay } = this.state;
    const { query } = location;
    return (
      <section>
        <Row>

          {/* First Column */}
          <Col md={2} className="pl-0" >
            {/*
              <span id="job-view" className="button-job-override ">
                <ButtonGroupAtom
                  options={JOB_VIEW_OPTIONS}
                  onToggle={this.enableCardView}
                  active={listViewDisplay}
                />
              </span>
             */}
            {/*<section className="float-left">
              <Button
                id="add_job"
                color="primary"
                //className="btn-accent"
                className="pr-3 pl-3"
                onClick={() => this.goTo('/job/list/add')}
              >Create New Job
              </Button>
            </section>*/}
          </Col>

          {/* Second column starts from here */}
         {/* <Col md={10} className="d-flex justify-content-end pr-0">
            <div id="jobSchedule" className="mr-3">
              <FilterDropdownComponent
                paramKey="status"
                location={location}
                options={FILTER_OPTIONS}
                className="task-override"
                dropicon="joblist"
                cookieKey={jobStatusCookieKey}
              />
              <UncontrolledTooltip placement="top" target="jobSchedule">
                Job Status
              </UncontrolledTooltip>
            </div>

            <JobFilterFormComponent
              location={location}
              dateFormat={dateFormat}
              initialValues={query.start_date && query.end_date ?
                { end_date: moment(query.end_date).toISOString(),
                  start_date: moment(query.start_date).toISOString()} : null}
              jobStartDateCookieKey={jobStartDateCookieKey}
              jobEndDateCookieKey={jobEndDateCookieKey}
            />           
            {!listViewDisplay &&
              <div className="ml-2">
                <FilterFormComponent
                  location={location}
                  placeholder="Search by job name"
                  initialValues={query}
                />
              </div>
            }           
          </Col>*/}
        </Row>

        {/*
          <Row className="filter pb-4">
            <Col className="d-flex">
              <div id="jobSchedule">
                <FilterDropdownComponent
                  paramKey="status"
                  location={location}
                  options={FILTER_OPTIONS}
                />
                <UncontrolledTooltip placement="top" target="jobSchedule">
                  Job Status
                </UncontrolledTooltip>
              </div>
              <div id="jobType">
                <FilterDropdownComponent
                  paramKey="jobType"
                  location={location}
                  options={FILTER_JOB_TYPE_OPTIONS}
                />
                <UncontrolledTooltip placement="top" target="jobType">
                  Job Type
                </UncontrolledTooltip>
              </div>
              <div id="billType" style={{ width: '90px' }}>
                <FilterDropdownComponent
                  paramKey="billType"
                  location={location}
                  options={FILTER_BILL_TYPE_OPTIONS}
                />
                <UncontrolledTooltip placement="top" target="billType">
                  Bill Type
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        */}
      </section>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

JobFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobFilterSection);
