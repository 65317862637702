import React from "react";
import { toast } from "react-toastify";
import StorageGateway from "lib/storage-gateway";
import LayerAtom from "../../atoms/Layer";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */
class LearningDocumentsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isInfoOpen: true,
      docsPreview: false,
      authToken: null,
      user_id: null,
      id: null,
      learning_doc_id:null,
      uploadHistorySliderFlag: false,
      documentTypeId: null,
      uploadSliderFlag: false,
      documentId: null,
      nre_company_id: null,
      company_id: null,
      historyDocsid: null,
      docsHistoryPreview: false,
      notificationLayer:false,
      gridViewPreview: false,
      formID:null
    };

    this.fetchData = this.fetchData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.postMessageToChild = this.postMessageToChild.bind(this);
    //this.postMessageToChild2 = this.postMessageToChild2.bind(this);
  }

  fetchData(params) {}
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "OPEN_DOCUMENTS_PREVIEWER") {
      this.setState({ docsPreview: true, id: data.content.id,formID:data.content.formId });
    } else if(message === "OPEN_GRID_VIEW_PREVIEWER") {
      this.setState({ gridViewPreview: true, learning_doc_id: data.content.id });
    }if(message === "CLOSE_GRID_VIEW_PREVIEWER") {
      this.setState({ gridViewPreview: true, learning_doc_id: data.content.id });
    } else if (message === "CLOSE_DOCUMENTS_PREVIEWER") {
      this.setState({ docsPreview: false });
    } else if (message === "SENDS_REMINDER") {
      toast.success("In Progress !!!");
    } else if (message === "DOWNLOADS_DOCUMENTS") {
      toast.success("In Progress !!!");
    } else if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "OPEN_UPLOAD_HISTORY_SLIDER") {
      this.setState({
        uploadHistorySliderFlag: true,
        documentTypeId: data.content.id,
      });
    } else if (message === "UPLOAD_LAYER") {
      this.setState({ uploadSliderFlag: true, documentId: data.content.id });
    } else if (message === "CLOSE_UPLOAD_SLIDER") {
      this.postMessageToChild("REFRESH_DOCUMENTS_TAB");
      toast.success(data?.content?.toastMessage);
      this.setState({
        uploadSliderFlag: false,
        documentId: null,
        id: data?.content?.id,
      });
    } else if (message === "OPEN_DOCUMENT_HISTORY_PREVIEWER") {
      this.setState({
        docsHistoryPreview: true,
        historyDocsid: data.content.id,
      });
    } else if (message === "DOCUMENT_UPDATE_SUCEES_TOAST") {
      toast.success(data?.content?.toastMessage);
      this.postMessageToChild("REFRESH_DOCUMENTS_TAB");
    } else if (message === "OPEN_BROADCAST_LAYER") {
      this.setState({
        notificationLayer:true,
      })
    }
  }

  componentWillReceiveProps(props) {
    if (props.refrshFlag) {
      this.postMessageToChild("REFRESH_DOCUMENTS_TAB");
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userID = StorageGateway.get("userId");
    const nre_company_id = StorageGateway.get("nre_company_id");
    const company_id = StorageGateway.get("company_id");
    this.setState({
      authToken: oAuthToken,
      user_id: userID,
      nre_company_id: nre_company_id,
      company_id: company_id,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  render() {
    const {
      docsPreview,
      authToken,
      user_id,
      id,
      uploadHistorySliderFlag,
      documentTypeId,
      uploadSliderFlag,
      documentId,
      nre_company_id,
      company_id,
      docsHistoryPreview,
      historyDocsid,
      gridViewPreview,
      learning_doc_id,
      formID,
      notificationLayer
    } = this.state;
    const { employeeId } = this.props;
    return (
      <div
        style={{
          height: "calc(90vh - 102px - 80px)",
          marginRight: "-15px",
        }}
      >
        <iframe
          id="scheduling"
          title="worksana_nre"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ width: "100%", height: "100%" }}
          src={`${
            process.env.REACT_APP_WMAPPER_BASE_URL
          }/employee/documents?access_token=${authToken}&user_id=${user_id}&employee_id=${employeeId}&companyId=${company_id ||
            nre_company_id}&content_type=LEARNING_CENTER`}
        />

        {docsPreview && (
          <LayerAtom
            id="scheduling"
            active={true}
            backButtonFlag={gridViewPreview}
            path={`/documents/preview?access_token=${authToken}&user_id=${user_id}&id=${id}&action=true&companyId=${company_id ||
              nre_company_id}&content_type=LEARNING_CENTER&formId=${formID}`}
            setToggle={() => this.setState({ docsPreview: false })}
          />
        )}
        {gridViewPreview && (
          <LayerAtom
            id="scheduling"
            active={true}
            path={`/learninng-center/grid-view/preview?access_token=${authToken}&user_id=${user_id}&id=${learning_doc_id}&action=true&companyId=${company_id ||
              nre_company_id}&content_type=LEARNING_CENTER`}
            setToggle={() => this.setState({ gridViewPreview: false })}
          />
        )}
        {docsHistoryPreview && (
          <LayerAtom
            id="scheduling"
            active={true}
            backButtonFlag={true}
            path={`/documents/preview?access_token=${authToken}&user_id=${user_id}&id=${historyDocsid}&action=false&companyId=${company_id ||
              nre_company_id}`}
            setToggle={() => this.setState({ docsHistoryPreview: false })}
          />
        )}
        {uploadHistorySliderFlag && (
          <LayerAtom
            backButtonFlag={true}
            id="schedulingHistory"
            active={true}
            path={`/documents/uploaded/history?access_token=${authToken}&user_id=${user_id}&id=${documentTypeId}&employeeId=${employeeId}&companyId=${company_id ||
              nre_company_id}`}
            setToggle={() => this.setState({ uploadHistorySliderFlag: false })}
          />
        )}
        {uploadSliderFlag && (
          <LayerAtom
            id="scheduling"
            backButtonFlag={true}
            active={true}
            path={`/documents/upload/?access_token=${authToken}&user_id=${user_id}&id=${documentId}&employeeId=${employeeId}&companyId=${company_id ||
              nre_company_id}`}
            setToggle={() => this.setState({ uploadSliderFlag: false })}
          />
        )}
         {notificationLayer && (
          <LayerAtom
            id="scheduling"
            active={true}
            path={`/employees/notificationHistory/?access_token=${authToken}&user_id=${user_id}&id=${documentId}&employeeId=${employeeId}&companyId=${company_id ||
              nre_company_id}&filter=false&content_type=LEARNING_CENTER`}
            setToggle={() => this.setState({ notificationLayer: false })}
          />
        )}
      </div>
    );
  }
}

export default LearningDocumentsTab;
