import React from 'react';

import { FormGroup, Input , Card , Row, Col } from 'reactstrap';
import styles from './styles.module.scss';

const FreeSelectInlineComponent = (props) => {
  const { data , index, noCard, auditComponent } = props;
  return (
    <Card className={`${styles['card-ui-blank']} mr-3 pl-3 pr-3 pt-1 pb-1`}>
      <Row>
        <Col md={noCard ? 12 : 11} className={`${styles['custom-col']} pl-1`}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span className={(((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) && noCard) ? `${styles['questions-ui']} ${styles['questions-no-padding']} pr-0 pl-2` : (((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) && !noCard) ? `${styles['questions-ui']} pr-0 pl-2` : ((!(data.behaviour && data.behaviour === 'MANDATORY') || !(props.mandatory)) && noCard) ? `${styles['questions-no-padding']} pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: data.question }}></span>
        </Col>
      </Row>
      {
       !auditComponent && data.response && data.response.length && data.response[0].value ? 
      <div className={noCard ? `${styles['resize-disabled']} ${styles['resize-margin-top']}` : `${styles['resize-disabled']} mt-2`}>
        <FormGroup>
          <Input
            type="textarea"
            id={`${index}`}
            name={`${index}`}
            placeholder="Write Here.."
            value={data.response && data.response.length ? data.response[0].value : null}
            disabled
          />
        </FormGroup>
      </div>
    : null}
    {
       auditComponent && data.answer && data.answer.length && data.answer[0].value ? 
      <div className={noCard ? `${styles['resize-disabled']} ${styles['resize-margin-top']}` : `${styles['resize-disabled']} mt-2`}>
        <FormGroup>
          <Input
            type="textarea"
            id={`${index}`}
            name={`${index}`}
            placeholder="Write Here.."
            value={data.answer && data.answer.length ? data.answer[0].value : null}
            disabled
          />
        </FormGroup>
      </div>
    : null}
    </Card>
  );
};

export default FreeSelectInlineComponent;
