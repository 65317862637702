import React from 'react';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import MultiSelectInlineComponent from 'components/common/MultiSelectInlineComponent';
import SingleSelectInlineComponent from 'components/common/SingleSelectInlineComponent';
import FreeSelectInlineComponent from 'components/common/FreeSelectInlineComponent';
import DateTimeCommentComponent from 'components/common/DateTimeCommentComponent';
import TimeCommentComponent from 'components/common/TimeCommentComponent';

import styles_common from 'components/common/styles.module.scss';

class FeedbackAuditModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { feedbackResponse, timeFormat24Hrs, dateFormatDisplay, date} = this.props
    return (
      <>
        <span className="float-right managingDTInfodate">{date}</span>
        <div style={{fontSize: '12px'}} className="mb-3 pt-1">
            {/* <div>Feedback :</div>
            <div className={`${styles['custom-survey-label-comment']}`}>{feedbackResponse[0].label}</div> */}
            <div>
            {feedbackResponse && feedbackResponse.length && feedbackResponse.map((item,index)=>
            <div>
              {(item.definition == 'single_select' || item.definition == 'SINGLE_SELECT') &&
                <div>
                  <SingleSelectInlineComponent data={item} index={index} noCard={true} auditComponent={true}/>
                </div>
              }
              {(item.definition == 'multi_select' || item.definition == 'MULTI_SELECT') &&
                <div>
                  <MultiSelectInlineComponent data={item} index={index} noCard={true} auditComponent={true}/>
                  </div>
              }
              {(item.definition == 'free_text' || item.definition == 'FREE_TEXT') &&
                <div>
                  <FreeSelectInlineComponent data={item} index={index} noCard={true} auditComponent={true}/>
                </div>
              }
              {(item.definition == 'boolean' || item.definition == 'BOOLEAN') &&
                <div>
                  <SingleSelectInlineComponent data={item} index={index} noCard={true} auditComponent={true}/>
                </div>
              }
              {(item.definition == 'date_time_select' || item.definition == 'DATE_TIME_SELECT') &&
                <div>
                  <DateTimeCommentComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} auditComponent={true}/>
                </div>
              }   
              {(item.definition == 'time_select' || item.definition == 'TIME_SELECT') &&
                <div>
                  <TimeCommentComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} auditComponent={true}/>
                </div>
              }
            </div>
          )}
        </div>
        </div>
      </>
    );
  }
};

export default FeedbackAuditModal;