import React from 'react';

import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import SchedulingDashboardPage from './Dashboard';
import ShiftManagementPage from './ShiftManagement'


const SchedulingRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={`${match.url}/dashboard`}
        name="scheduling.dashboard"
        component={SchedulingDashboardPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/shift-management`}
        name="scheduling.shift-management"
        component={ShiftManagementPage}
      />
      
      <Route component={NoMatch} />
    </Switch>
  );
};

SchedulingRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SchedulingRoutes;
