import React, { Component } from "react";


import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";

import EmployeesFilter from "./employeesFilter";

export default class AssignEmployeeModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { assignEmployeeFlag, assignEmployeeToggler, assignEmployee, isAssigning } = this.props;

    return (
      <Modal isOpen={assignEmployeeFlag} size="md">
        <ModalHeader toggle={assignEmployeeToggler}>
            Assign Employee
        </ModalHeader>
        <ModalBody>
            <EmployeesFilter modalEmployeeAssign={assignEmployee} isAssigning={isAssigning} verticalStyle/>
        </ModalBody>
      </Modal>
    );
  }
}
