import React from 'react';

import moment from 'moment';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { UncontrolledTooltip } from 'reactstrap';
import formValueSelector from 'redux-form/lib/formValueSelector';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import closeimage from './Close-Cross.png';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import Button from 'reactstrap/lib/Button';
import { FORM_LAYOUT } from 'constants/layout';
import { toast } from 'react-toastify';

import AllocateTaskModalContent from 'components/activities/activitySplitForm/AllocateTaskModalContent';
import AllocateMealModalContent from 'components/activities/activitySplitForm/AllocateMealModalContent'

import styles from './styles.module.scss';

class AddNewActivityModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit=this.submit.bind(this);
    this.selectActvityType = this.selectActvityType.bind(this);
    this.validate = this.validate.bind(this);
    this.state={selectedActvity:'job_task',startTime: null,endTime: null}
    this.update = this.update.bind(this);
  }

  // function to call after successfull validation of editable fields ans setting the state of formatted start and end time
  update(data){
    const {startTime , endTime} = this.state;
    if(data.start_time){
      this.setState({startTime: data.start_time})
    }
    else if(data.end_time){
      this.setState({endTime: data.end_time})
    }

  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime && data.start_datetime_tz.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }

  submit(data) {
    const { saveTask, initialValues , submitMeal } = this.props;
    const {selectedActvity, startTime, endTime} = this.state;
    const updatedStartTime = moment(startTime ? startTime : data.start_time, "h:mm:ss A").format("HH:mm:ss");
    const updatedStartDateTime = moment(`${moment(data.start_datetime).format('YYYY-MM-DD')}T${updatedStartTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedEndTime = moment(endTime ? endTime : data.end_time, "h:mm:ss A").format("HH:mm:ss");
    let updatedEndDateTime = moment(`${moment(data.end_datetime).format('YYYY-MM-DD')}T${updatedEndTime}`, 'YYYY-MM-DD HH:mm:ss')
    if(selectedActvity==='job_task'){
      const { id, ...rest } = data;
      const saveData = initialValues && initialValues.id !== null  ? {...rest , activity_code: 'TASK', id: initialValues && initialValues.id , start_datetime : moment(updatedStartDateTime).toISOString(), end_datetime : moment(updatedEndDateTime).toISOString()} : {...rest, activity_code: 'TASK' , start_datetime : moment(updatedStartDateTime).toISOString(), end_datetime : moment(updatedEndDateTime).toISOString()};
      return saveTask({...saveData});
    }
    else{
      return submitMeal({...data, start_datetime : moment(updatedStartDateTime).toISOString(), end_datetime : moment(updatedEndDateTime).toISOString()});
    }
    }

  selectActvityType(val){
    this.setState({selectedActvity: val, startTime: null, endTime:null});
  }

  render() {
    const { isOpen, toggle , handleSubmit, taskList, timeFormat24Hrs, dateFormat, change, jobList, job, getTasksForJob, task, timeFormat , initialValues } = this.props;
    const { selectedActvity } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
        className="w-50"
      >
        <ModalHeader>
          Add New Activity
          <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody className={styles['modal-body-padding']}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label class="container">Add Job and Task
                    <input 
                      type="radio"
                      id="task_type" 
                      value="job_task" 
                      name="new_activity"
                      onChange={(e)=>this.selectActvityType(e.target.value)}
                      checked={selectedActvity==='job_task' ? true : false}
                    />
                    <span class="checkmark"></span>
                  </label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label class="container">Add Meal Period
                    <input 
                      type="radio" 
                      name="new_activity"
                      id="meal_type" 
                      value="meal_task"
                      onChange={(e)=>this.selectActvityType(e.target.value)}
                      checked={selectedActvity==='meal_task' ? true : false} 
                    />
                    <span class="checkmark"></span>
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {selectedActvity && selectedActvity === 'job_task' ?
              <Row>
                <AllocateTaskModalContent
                  handleSubmit={handleSubmit}
                  taskList={taskList}
                  timeFormat24Hrs={timeFormat24Hrs}
                  dateFormat={dateFormat}
                  change={change}
                  job={job}
                  jobList={jobList}
                  getTasksForJob={getTasksForJob}
                  task={task}
                  timeFormat={timeFormat}
                  submit={this.submit}
                  validateInput={this.validate}
                  addNewActivity="addNewActivity"
                  initialValues={{...initialValues, start_time: moment(initialValues.start_datetime).format('LT') , end_time : moment(initialValues.end_datetime).format('LT')}}
                  update={this.update}
                  browserParseDate={this.props.browserParseDate}
                />
            </Row>
            :
            <Row>
              <AllocateMealModalContent
                timeFormat24Hrs={timeFormat24Hrs}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                addNewActivity="addNewMeal"
                validateInput={this.validate}
                initialValues={{...initialValues, start_time: moment(initialValues.start_datetime).format('LT') , end_time : moment(initialValues.end_datetime).format('LT')}}
                update={this.update}
                browserParseDate={this.props.browserParseDate}
              />
            </Row>
            }
          </ModalBody>
          <ModalFooter className="ml-3 mr-4 mb-3">
            <Button color="default" className={styles['cancel-btn']} onClick={toggle} size={FORM_LAYOUT.size} >
              Cancel
            </Button>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size} disabled={this.props.submitLoader} >
              Save <ButtonLoaderAtom active={selectedActvity === 'job_task' ? this.props.submitLoader : this.props.submitMealLoader} />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.job) errors.job = 'Enter Job';
  if (!values.task) errors.task = 'Enter Task'
  if (!values.start_datetime) errors.start_datetime = 'Select Start Date';
  if (!values.end_datetime) errors.end_datetime = 'Select End Date';
  return errors;
};

AddNewActivityModal.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  saveTask: PropTypes.func,
  dateFormat: PropTypes.string.isRequired,
};

const AllocateNewActivityModal = reduxForm({
  form: 'AllocateNewActivity',
  validate,
  enableReinitialize: true,
})(AddNewActivityModal);

const selector = formValueSelector('AllocateNewActivity');

export default connect(
  (state) => {
    const job = selector(state, 'job');
    const task = selector(state, 'task');
    return { job, task };
  },
)(AllocateNewActivityModal);