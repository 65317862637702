import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Col from 'reactstrap/lib/Col';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import SelectAtom from 'atoms/Select';

import { FORM_LAYOUT } from 'constants/layout';
import { DATE_FORMATS } from 'constants/vendors';

/**
 * BasicSettingFormPage -> BasicSettingFormSection -> BasicSettingFormComponent
 *
 * Props:
 *    - update
 */
const BasicSettingFormComponent = (props) => {
  const { handleSubmit, submitting, error, update } = props;

  const submit = data => update(data)
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="mt-3 ml-3 mr-4 pb-5">
      <div className="error-text">{error}</div>
      <Row>
        <Col xs={12} md={6}>
          <Field
            id="date_format"
            name="date_format"
            label="Date Format"
            component={SelectAtom}
            placeholder="Date Format (optional)"
            options={DATE_FORMATS}
            layout={FORM_LAYOUT}
            simpleValue
          />
          <Field
            id="time_format_24_hrs"
            type="checkbox"
            name="time_format_24_hrs"
            label="24 Hours Format"
            component={CheckboxAtom}
            layout={FORM_LAYOUT}
          />
          <Field
            id="timecard_decimal_view"
            type="checkbox"
            name="timecard_decimal_view"
            label="Timecard Decimal View"
            component={CheckboxAtom}
            layout={FORM_LAYOUT}
          />
          <Button
            type="submit"
            color="secondary"
            className="float-sm-right my-4"
            size={FORM_LAYOUT.size}
          >
            Save <ButtonLoaderAtom active={submitting} />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};
  return errors;
};

BasicSettingFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  update: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'BasicSettingForm',
  validate,
  enableReinitialize: true,
})(BasicSettingFormComponent);
