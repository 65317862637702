import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import { toast } from 'react-toastify';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { Label } from 'reactstrap';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import DateTimePickerAtom from 'components/common/JobCenterDateTimePicker';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';

import { FORM_LAYOUT } from 'constants/layout';
import { WEEKDAYS_IN_SHORT, CREATE_UPDATE_JOB_FILTER_OPTIONS } from 'constants/jobs';

import styles from './styles.module.scss';


class EditJobFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      color: props.initialValues && props.initialValues.color,
      day:[],
      files: [],
      fileUrl: props.initialValues ? props.initialValues.external_reference : null,
      fileType:  props.initialValues ? props.initialValues.kb_data_type : null,
      fileName: null,
      weekDays: WEEKDAYS_IN_SHORT,
      isLoading: false,
      photoIndex: 0,
      isOverlayOpen: false,
      selectedId: null,
      disable : false,
      isNextPage: false,
      submitButtonLoader: false,
      nextButtonLoader: false,
      submitButtonClicked : false
    };
    this.submit = this.submit.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.selectColor = this.selectColor.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.handleNote = this.handleNote.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateStats = this.updateStats.bind(this);
    this.handleRecurringChange = this.handleRecurringChange.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.disable = this.disable.bind(this);
    }

  componentWillMount(){
    const { initialValues: { days_of_week: values }, initialValues } = this.props;
    const weekDays = WEEKDAYS_IN_SHORT.map((i) => {
      const item = { ...i };
      if(values.indexOf(i.value.toString()) > -1){
        item.isSelected = true;
      }
      else item.isSelected = false;
      return item;
    });
    this.setState({ weekDays });
    if(initialValues && initialValues.is_ongoing){
      this.setState({disable : true});
    }
  }

  disable(val){
    const { change } = this.props;
    const { disable } = this.state;
    this.setState({disable : !disable});
    if(val.target.checked){
      change('end_date', null)
    }
    else{
      change('end_date',this.props.initialValues.end_date)
    }
  }

  selectColor(color) {
    this.setState({ color });
  }

  selectDay(day) {
    const { weekDays } = this.state;
    const newArr = weekDays.map((i) => {
      const item = { ...i};
      if(i.name === day){
        item.isSelected = !i.isSelected;
        return item;
      }
      return item;
    });
    this.setState({ weekDays: newArr });
  }

  handleFileSelect(e){
    const fileArray = e.target.files;
    for (let i = 0; i < fileArray.length; i += 1) {
      const reader = new FileReader();
      reader.readAsDataURL(fileArray[i]);
      reader.onloadend = (e1) => {
        this.setState((state) => ({ files: [ ...state.files, { file: fileArray[i], path: e1.target.result } ] }));
      }
    }
  }

  handleFileRemove(val) {
    const { files } = this.state;
    const valueRetain = files.length && files.filter((item,index) => {
      if(index !== val) {
        return item;
      }
      return null;
    });
    this.setState({ files: valueRetain});
  }

  handleChange(data) {
    const { change } = this.props;
    if(data) {
      change('email', data.user ? data.user.email : null);
      change('phone', data.phone_no ? data.phone_no : null);
      change('phn_ext', data.phone_ext ? data.phone_ext : null);
      change('contact', data.user && data.user.full_name ? data.user.full_name : null);
    }
  }

  handleNote(data) {
    const { addNote, change, notes } = this.props;
    const { files } = this.state;
    if(!notes) {
      return toast.error('Please add notes.');
    }
    const formData = {};
    let documentData;

    if (files.length > 0) {
      documentData = new FormData();
      documentData.append('notes', notes);
      this.state.files.map(item => {
        documentData.append('files', item.file);
        return item;
      });
    } else {
      formData.files = [];
      formData.notes = notes;
    }
    addNote(documentData || formData)
    change('notes', null);
    this.setState({ files: [] });
    return true;
  }

  handleRecurringChange(a,val) {
    const { change } = this.props;
    if(!val){
      change('repeats', null);
      change('every', null);
    }
  }

  nextPage() {
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location } = this.props;
    const { query } = location;
    history.push({ pathname:`/job/list/${params.id}/budget/edit`, search : queryString.stringify(query)})
  }

  submit(data) {
    const { createOrUpdate , location } = this.props;
    const { color , nextButtonClicked , submitButtonClicked } = this.state;
    const { router: { history } } = this.context;
    const { query } = location;
    const detail = { ...data, tags: data.tags && data.tags.length ? data.tags.map(i => i.name) : [] };
    nextButtonClicked && this.setState({ nextButtonLoader: !this.state.nextButtonLoader });
    submitButtonClicked && this.setState({ submitButtonLoader: !this.state.submitButtonLoader });
    delete detail.days_of_week;
    detail.estimated_billed_amount = data.estimated_billed_amount ? data.estimated_billed_amount : null;
    detail.budgeted_hours = data.budgeted_hours ? data.budgeted_hours : null;
    detail.daily_billing_rate = data.daily_billing_rate ? data.daily_billing_rate : null;
    detail.budgeted_hours_per_day = data.budgeted_hours_per_day ? data.budgeted_hours_per_day : null;
    
    detail.customer = data.customer && data.customer.id;
    detail.color = color;
    detail.status = data.status.value? data.status.value : data.status;
    detail.current_date = moment().format('YYYY-MM-DD');

    detail.every = detail.every && detail.every.value;
    detail.repeat = data.repeats && data.repeats.value;
    detail.days_of_week = this.state.weekDays.filter((i) => i.isSelected).map((i) => i.value);
    
    if((!(moment(data.start_date).isValid())) || (!data.is_ongoing && !(moment(data.end_date).isValid()))){
      return toast.error('Enter a valid date');
    }
    detail.start_date = data.start_date && moment(data.start_date).format('YYYY-MM-DD');
    detail.end_date = data.end_date && moment(data.end_date).format('YYYY-MM-DD');

    return createOrUpdate(detail)
      .then(() =>{ 
        nextButtonClicked ? this.nextPage() : query.admin_list ? history.push("/job/list/new") : history.push("/job/list")
        this.setState({submitButtonLoader:false , nextButtonClicked : false, submitButtonClicked : false, nextButtonLoader : false})
      })
      .catch((err) => {
        this.setState({submitButtonLoader:false , nextButtonClicked : false, submitButtonClicked : false, nextButtonLoader : false})
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  updateStats(){
    const { createOrUpdate, jobValues } = this.props;
    const detail = {
      ...jobValues,
      id: jobValues.id,
      start_date: moment(jobValues.start_date).format('YYYY-MM-DD'),
      end_date: moment(jobValues.end_date).format('YYYY-MM-DD'),
      days_of_week: this.state.weekDays.filter((i) => i.isSelected).map((i) => i.value),
      repeat: jobValues.repeats && jobValues.repeats.value,
      every: jobValues.every && jobValues.every.value,
      customer: jobValues.customer && jobValues.customer.id,
    };
    this.setState({ isLoading: true });
    return createOrUpdate(detail)
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      handleSubmit,
      dateFormat,jobTagList,
      submitting,toggleTagModal,
    } = this.props;
    const { disable, submitButtonLoader} = this.state;
    const { location, customerList, customerName, toggle } = this.props;
    const { query } = location;
    const newTag = jobTagList[0] ? (jobTagList[0].tag_collection.map((item) => {
      const list = {};
      list.id = item;
      list.name = item;
      return list;
    })) : [];
    const customer = customerList.length && customerList.map(item => (
      { ...item, name: item.customer_name }
    ));
    return (
      <Form onSubmit={handleSubmit(this.submit)} className="job-detail" noValidate>
        <Row>
          <Col md={3} className={!customerName ? 'remove-icon' : null}>
            <Field
              id="customer"
              name="customer"
              label="Customer"
              component={SelectAtom}
              options={customer}
              placeholder="Customer"
              labelKey="customer_name"
              valueKey="id"
              layout={FORM_LAYOUT}
              onChange={(item) => this.handleChange(item)}
            />
            <div
              tabIndex="0"
              role="button"
              onClick={() => toggle(customerName, customerName ? 'edit' : 'add')}
              className="link-name mb-3"
            >
              { customerName ? '+ Edit Customer Details' : 'Add Customer' }
            </div>
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="name"
              name="name"
              label="Job Name"
              maxLength="50"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Job Name"
            />
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="code"
              name="code"
              label="Job Code"
              maxLength="50"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Job Code"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              id="start_date"
              name="start_date"
              label="Start Date"
              layout={FORM_LAYOUT}
              component={DateTimePickerAtom}
              placeholder="Start Date"
              options={{ dateFormat, }}
            />
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="end_date"
              name="end_date"
              label="End Date"
              layout={FORM_LAYOUT}
              component={DateTimePickerAtom}
              placeholder="Projected End"
              options={{ dateFormat, }}
              disabled = {disable}
            />
            <Field
              id="is_ongoing"
              name="is_ongoing"
              label="Ongoing"
              value="Checkbox"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
              onClick={(val) => this.disable(val)}
            />
          </Col>
          <Col md={3} className="offset-1 remove-icon">
            {!query.setup &&
              <Field
                id="status"
                name="status"
                label="Status"
                component={SelectAtom}
                options={CREATE_UPDATE_JOB_FILTER_OPTIONS}
                placeholder="Status"
                labelKey="label"
                valueKey="value"
                layout={FORM_LAYOUT}
              />
            }
          </Col>
        </Row>
        <Row>
          <Col md={7} className="override-dropdown-tags">
            <Label className="w-100 mb-0">Job tag<span className="" onClick={() => toggleTagModal(2)} tabIndex="0" role="button" className=" float-right link-name mb-2">Manage tags</span> </Label>
            <Field
              component={MultiSelectDropdownAtom}
              id="tags"
              name="tags"
              placeholder="Select Tags"
              items={newTag}
              itemToString={i => i && i.name}
            />
          </Col>
        </Row>
        {/* Buttons */}
        <Row>
          {query.setup &&
            <Col md={12} className="mt-2 mb-3 pr-0 text-right">
              <Button
                className="btn-secondary float-right mt-0"
                type='submit'
              >
                NEXT <ButtonLoaderAtom active={submitting} />
              </Button>
            </Col>
          }
          {!query.setup &&
            <Col md={12} className="mt-2 mb-3 pr-0 text-right">
              <Button
                color="primary"
                className={`${styles['btn_accent_width']} float-right m-2`}
                type='submit'
                onClick={() => {
                  this.setState({nextButtonClicked: true , submitButtonClicked : false})
                }}
              >
              Next<ButtonLoaderAtom active={this.state.nextButtonLoader} />
              </Button>

              <Button
                color="primary"
                className={`${styles['btn_accent_width']} float-right m-2`}
                type='submit'
                onClick={() => {
                  this.setState({nextButtonClicked: false, submitButtonClicked:true})
                }}
              >
                Save <ButtonLoaderAtom active={submitButtonLoader} />
              </Button>
            </Col>
          }
        </Row>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Job Name';
  if(values.name && values.name.length >50) errors.name='Job name cannot have more than 50 characters';
  if(values.code && values.code.length >50) errors.code='Job Code cannot have more than 50 characters';
  if (!values.start_date) errors.start_date = 'Enter Start Date';
  if (values.end_date) {
    if (values.is_ongoing) {
      errors.end_date = 'On Going Job cannot have end date';
    }
  }
  if (!values.is_ongoing && !values.end_date) {
    errors.end_date = 'Enter End Date';
  }
  if(values.start_date) {
    if(moment(values.start_date).year()<1990) errors.start_date = 'Selected Year should be greater than 1990'
   }
   
   if(values.end_date){
   if(moment(values.end_date).year()<1990) errors.end_date = 'Selected Year should be greater than 1990'
 }
  if(values.start_date && values.end_date){
    const formattedStartDate=moment(values.start_date);
    const formattedEndDate=moment(values.end_date);
    const currentDate = moment().format();
     // if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
    if(formattedEndDate < formattedStartDate) errors.end_date ='End date should be greater than start date.';
  }
  return errors;
};

EditJobFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array,
  addNote: PropTypes.func,
  notes: PropTypes.string,
  commentList: PropTypes.object,
  jobTagList: PropTypes.array,
  createTag: PropTypes.func,
  change: PropTypes.func,
  toggle: PropTypes.func,
  toggleModal: PropTypes.func,
  toggleTagModal: PropTypes.func,
};

EditJobFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const form = reduxForm({
  form: 'JobForm',
  enableReinitialize: true,
  validate,
})(EditJobFormComponent);

const selector = formValueSelector('JobForm');

export default connect(
  state => {
    const customerName = selector(state, 'customer');
    const ongoingCheck = selector(state, 'is_ongoing');
    return {customerName , ongoingCheck};
  }
)(form);
