import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import EditIconAtom from 'atoms/EditIcon';
import { SYSTEM_HOVER_MESSAGE_OPTIONS } from 'constants/vendors';
/**
 * SystemSettingListPage -> SystemSettingListSection -> SystemSettingTableComponent
 *
 * Props:
 *    - System setting list
 */
const SystemSettingTableComponent = (props) => {
  const { data, toggle } = props;
  const hhmm = sec => `${Math.floor(sec / 3600)} hours ${Math.floor((sec % 3600) / 60)} minutes`;
  const getValue = (item) => {
    switch (item.key.data_type) {
      case "DURATION":
        return hhmm(+item.value);
      case "BOOLEAN": return item.value === "True" ? 'Yes' : 'No';
      default: if ( item.value === 'Correction Request' ) {
        return 'Edit Jobs Only';
      } else if ( item.value === 'No Manual Entry Allowed' ) {
        return 'Text Correction Only';
      } else {
        return item.value;
      }
    }
  }

  return (
   <div className="ml-3 mr-4">
      <Table hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <th width="25%" className="text-center">Action</th>
          <th width="30%">Key</th>
          <th>value</th>
        </tr>
      </thead>
      <tbody className="font-colorInput timeCard-table">
        {data && data.map(item =>{
        const isMileageOn = item.extra_data && item.extra_data.enabled;
        return (
          <tr key={item.id}>
            <td title="Edit Setting" className="text-center">
              <EditIconAtom className="cursor-pointer" onClick={() => toggle(item)} />
            </td>
            <td title={SYSTEM_HOVER_MESSAGE_OPTIONS[item.key.key_code]}>
              {item.key.key}
            </td>
            <td title={getValue(item)}>
              {isMileageOn == false ? 'OFF' : item.value} 
            </td>
          </tr>
        )})}
        {!data.length &&
          <tr><td className="text-center" colSpan="5">No Records Found</td></tr>
        }
      </tbody>
      </Table>
   </div>
  );
};

SystemSettingTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SystemSettingTableComponent;
