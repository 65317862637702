import React from 'react';

import PropTypes from 'prop-types';

import LogoMainBlue from './logo_blue.png';

const LogoMainBlueAtom = props => (
  <img src={LogoMainBlue} alt="logo" {...props} />
);

LogoMainBlueAtom.defaultProps = {
  height: 100,
  width: 170,
};

LogoMainBlueAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default LogoMainBlueAtom;