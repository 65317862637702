import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  INFO_LIST: 'INFO_LIST',
  CARD_DETAILS: 'CARD_DETAILS',
  SHOW_LOADER: 'SHOW_LOADER',
  GET_CARD_DETAILS: 'GET_CARD_DETAILS',
};

class addCardUsecase {
 
  paymentMethodApiGateway = null;
  

  constructor(paymentMethodApiGateway) {
    this.paymentMethodApiGateway = paymentMethodApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'addCardUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  async getInfoList(){
    this.infoList = await this.paymentMethodApiGateway.getInfoList({ route: 'vendor.payment-method'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async saveCard(stripe, companyId, email, name, phoneNumber){
    let response  = await stripe.createSource({
      type: 'card',
    });
    this.saveOrSetDefault(companyId, response.source.id, email, name, phoneNumber);
  }

  async getCardDetails(companyId){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.details = await this.paymentMethodApiGateway.getCardDetails(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CARD_DETAILS,
      detail: this.details,
    });
  }

  async saveOrSetDefault(companyId, sourceId, email, name, phoneNo){
    await this.paymentMethodApiGateway.saveCard(
      companyId,
      sourceId,
      email, name, phoneNo);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_CARD_DETAILS,
    });
  }
}

export default addCardUsecase;