import React from 'react';
import Link from 'react-router-dom/Link';
import exclamationMark from './exclamation-mark.png';
import styles from './styles.module.scss';

const TrialPeriodNotification = (props) => {
  const { customerDetail, tutorialDetail, showToast , userExtraData} = props;
  const companyOwner = userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.company_owner;
  return (<div className={`${styles['verify']} pt-2 pl-0 w-100`}>
    <img
      className={styles['icon']}
      src={exclamationMark}
      alt="Icon"
    />
      <span className="d-inline-block align-middle">Your account is on free trial. Your trial period will end on {customerDetail.trial_end}.</span>
      {customerDetail.sources && 
        !customerDetail.sources.length && customerDetail.plan.plan_type === 'online' && companyOwner && 
        <div
          className={styles['okay']}
        >     
        {tutorialDetail ?   
          <Link to='/vendor/payment-method'>
            <span className={styles['verify-now']}>ADD CARD DETAILS</span>
          </Link>
          :
          <span className={styles['verify-now']} onClick={() => showToast()}>ADD CARD DETAILS</span>
        }
        </div>}
  </div>);
};

export default TrialPeriodNotification;
