import React from 'react';

import PropTypes from 'prop-types';

import { SketchPicker } from 'react-color';

import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormText from 'reactstrap/lib/FormText';
import Label from 'reactstrap/lib/Label';

import styles from './styles.module.scss';

class ColorAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      value: props.input.value || '#162d6e',
      displayColorPicker: false,
      color: { r: '22', g: '45', b: '110', a: '1' },
    };
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    const { onChange, input } = this.props;

    this.setState({ color: color.rgb, value: color.hex });
    input.onChange(color.hex);
    if (onChange) onChange(color.hex);
  }

  render() {
    const { input, layout, onChange, label, help, meta: { touched, error }, ...rest } = this.props;
    const { value, color } = this.state;

    return (
      <div>
        {label && <Label className="d-block" for={rest.id}>{label}</Label>}
        <div
          className={styles.swatch}
          onClick={this.handleClick}
          tabIndex="0" role="button"
        >
          <div 
            style={{
              width: '72px',
              height: '24px',
              borderRadius: '2px',
              backgroundColor: `${value}`
            }}
          />
        </div>
        {this.state.displayColorPicker 
          ? <div className={styles.popover} >
            <div
              className={styles.cover}
              onClick={this.handleClose}
              tabIndex="0" role="button"
            />
            <SketchPicker
              color={color}
              onChange={this.handleChange}
            />
          </div>
          : null
        }
        <FormText color="muted">{help}</FormText>
        <FormFeedback>{touched && error}</FormFeedback>
      </div>
    );
  }
}

ColorAtom.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  layout: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  getOptions: PropTypes.func,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default ColorAtom;
