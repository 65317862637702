import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import ButtonGroup from 'reactstrap/lib/ButtonGroup';

class ButtonGroupAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleActive = this.handleActive.bind(this);
    this.state = { value: props.active };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      this.setState({ value: nextProps.active });
    }
  }

  handleActive(value) {
    const { input, onToggle, geofence } = this.props;
    if(geofence) {
      if(onToggle) this.props.onToggle(value);
    } else {
      if (value !== this.state.value) {
        if(onToggle) this.props.onToggle(value);
      }
    }
    this.setState({ value });
    if (input) input.onChange(value);
  }

  render() {
    const { options,  ...rest } = this.props;
    const { value } = this.state;
    return (
      <ButtonGroup {...rest}>
        {options && options.map(item => (
          <Button
            key={item.value}
            onClick={() => this.handleActive(item.value)}
            color={item.value === value ? 'primary' : 'defaultColor'}
            title={item.label}
            className="btn-grp-color"
          >
            {item.icon || item.label}
          </Button>
        ))}
      </ButtonGroup>
    );
  }
}

ButtonGroupAtom.propTypes = {
  options: PropTypes.array.isRequired,
  active: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

export default ButtonGroupAtom;
