import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { REPORT_KEY_MAPPINGS } from 'constants/reports';

/**
 * ReportDataDetailPage -> ReportActivitySplitFilterSection
 *
 * Components:
 *    - {@link ReportActivitySplitFilterSection}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - downloadReportData
 */
class ReportActivitySplitFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
    this.getParsedParams = this.getParsedParams.bind(this);
    this.state = { isLoading : false };
  }

  getParsedParams() {
    const { location: { query } } = this.props;
    let parsedParams;

    try {
      parsedParams = JSON.parse(query.reportDetail);
    } catch (e) {
      console.log(e);
    }
    return parsedParams;
  }

  export() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });

    return this.props.ReportDucks.downloadReportData({ ...this.getParsedParams(), ...query, ...params })
      .then((data) => {
        const date = moment().format('DD/MM/YYYY');
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = ((evx) => 
          {
            const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.navigator.msSaveOrOpenBlob(myBlob, `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`);
          });
          a.click();
        }
        // chrome ,safari and mozilla browsers 
        else {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const  url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`;
          element.click();
        }
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { location: { query } } = this.props;
    const { isLoading } = this.state;

    return (
      <Row className="filter ml-3 mr-4">
        <Col md={10} />
        <Col md={2} className="px-0">
          {Object.keys(query).length > 0 &&
            <section className="float-right">
              <Button color="accent" className="mr-0" onClick={this.export}>Export <ButtonLoaderAtom active={isLoading} /></Button>
            </section>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

ReportActivitySplitFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

ReportActivitySplitFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ReportActivitySplitFilterSection);
