import React from "react";
import PropTypes from "prop-types";

import StorageGateway from "lib/storage-gateway";

import LayerAtom from "../../atoms/Layer";
import { toast } from "react-toastify";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */

class DocumentTypesListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createGroupLayer: false,
      editDocumentLayer: false,
      authToken: null,
      userId: null,
      id: "create",
      contentId: null,
      groupId: null,
      nre_company_id: null,
      company_id: null,
      EdocLayer: false,
      webView: false,
      eDocId: null,
    };
    this.loadData = this.loadData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.closeLayer = this.closeLayer.bind(this);
    this.postMessageToChild = this.postMessageToChild.bind(this);
  }
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }
  loadData() {
    this.postMessageToChild("REFRESH_DOCUMENT_TYPE_TAB");
  }
  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "REFRESH_GROUP_TABLE") {
      this.postMessageToChild("REFRESH_GROUP_TABLE");
    } else if (message === "OPEN_GROUP_LAYER") {
      this.setState({
        createGroupLayer: true,
        id: data?.content?.id ?? "create",
        contentId: data?.content?.documentContentId ?? null,
      });
    } else if (message === "CLOSE_GROUP_LAYER") {
      this.setState({
        createGroupLayer: false,
      });
    } else if (message === "CLOSE_DOCUMENT_LAYER") {
      setTimeout(() => this.setState({ editDocumentLayer: false }), 1000);
      this.postMessageToChild("REFRESH_DOCUMENTS_TABLE");
    } else if (message === "OPEN_DOCUMENT_LAYER") {
      this.setState({
        editDocumentLayer: true,
        id: data?.content?.id ?? "create",
        groupId: data?.content?.groupId ?? null,
      });
    } else if (message === "DELETE_DOCUMENT_GROUP") {
      toast.success("Document group deleted succesfully");
    } else if (message === "DELETE_DOCUMENT_TYPE") {
      toast.success("Document delete succesfully");
    } else if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    } else if (message === "OPEN_E_DOCS_LAYER") {
      this.props.history.push("/document/eDocs");
    } else if (message === "OPEN_EDOCS_SLIDER") {
      this.setState({
        EdocLayer: true,
        eDocId: data?.content?.group_id ?? "create",
      });
    } else if (message === "CLOSE_EDOCS_SLIDER") {
      toast.success(data?.content?.message);
      setTimeout(() => {
        this.setState({ EdocLayer: false });
      }, 1000);
      this.loadData();
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userId = StorageGateway.get("userId");
    const nre_company_id = StorageGateway.get("nre_company_id");
    const company_id = StorageGateway.get("company_id");
    this.setState({
      authToken: oAuthToken,
      userId: userId,
      nre_company_id: nre_company_id,
      company_id: company_id,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  closeLayer(state) {
    if (state === "createGroupLayer") {
      this.setState({ createGroupLayer: false });
    } else if (state === "EdocLayer") {
      this.setState({ EdocLayer: false });
    } else {
      this.setState({ editDocumentLayer: false });
    }
  }

  componentWillReceiveProps(props) {
    if (props.refreshFlag) {
      this.postMessageToChild("REFRESH_DOCUMENT_TYPE_TAB");
      props.updateState("refrshDocTabFlag", false);
    }
  }
  render() {
    const {
      createGroupLayer,
      editDocumentLayer,
      id,
      authToken,
      userId,
      contentId,
      groupId,
      nre_company_id,
      company_id,
      EdocLayer,
      eDocId,
      webView,
    } = this.state;
    return (
      <>
        <div
          style={{
            height: "calc(98vh - 102px - 75px)",
            marginRight: "-15px",
          }}
        >
          <iframe
            id="scheduling"
            title="worksana-nre"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${
              process.env.REACT_APP_WMAPPER_BASE_URL
            }/documents-type/?access_token=${authToken}&user_id=${userId}&companyId=${company_id ||
              nre_company_id}`}
          />
        </div>
        {createGroupLayer && (
          <LayerAtom
            active={true}
            path={`/document-group/${id}/?access_token=${authToken}&user_id=${userId}&content_id=${contentId}&companyId=${company_id ||
              nre_company_id}`}
            setToggle={() => this.closeLayer("createGroupLayer")}
          />
        )}
        {editDocumentLayer && (
          <LayerAtom
            active={true}
            path={`/document/${id}/?access_token=${authToken}&user_id=${userId}&group_id=${groupId}&companyId=${company_id ||
              nre_company_id}&content_type=COMMUNICATION_CENTER`}
            setToggle={() => this.closeLayer("editDocumentLayer")}
          />
        )}
        {EdocLayer && (
          <LayerAtom
            active={true}
            path={`/webview/e-doc/form/?access_token=${authToken}&user_id=${userId}&eDocId=${eDocId}&companyId=${company_id ||
              nre_company_id}&webView=${webView}`}
            setToggle={() => this.closeLayer("EdocLayer")}
          />
        )}
      </>
    );
  }
}

DocumentTypesListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

DocumentTypesListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default DocumentTypesListPage;
