import React from 'react';

import PropTypes from 'prop-types';

import FilterDropdownComponent from 'components/common/FilterDropdown';

import FieldArray from 'redux-form/lib/FieldArray';
import Form from 'reactstrap/lib/Form';
import { toast } from 'react-toastify';

import Table from 'reactstrap/lib/Table';
import Button from 'reactstrap/lib/Button';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import reduxForm from 'redux-form/lib/reduxForm';
import { EMPLOYEES_FILTER_OPTIONS } from 'constants/vendors';
import EmployeeFormComponent from './Employee';
import JobSubTableComponent from './JobSubTable';
import AssociatedPayrollsComponent from './PayrollsSubtable';
import PayrollItemsComponent from './payrollItemsComponent';
import TaskTableComponent from './taskTable';
import styles from './styles.module.scss';

const QuickBooksTableComponent = (props, context) => {
  const { router: { history, route: { match: { params } } } } = context;
  const { source, employeeList, location, metcEmployeeList, selectedItemId, handleCheckboxes, selectedEmployee,selectedList,
    onChangeEmployee,AllemployeeList, handleSubmit, saveMapping, removeMapping, submitting, timecardList, employeeAndQbdList, basicSettingDetail } = props;
  const submit = (data) => {
    if(!selectedEmployee.length){
      return toast.error('Please select employees to map');
    }
    else{
    selectedEmployee.forEach((i, index) => {
      if (i) {
        selectedList.push(employeeList[index])
      }
    });
    const employeesList = AllemployeeList.results ? AllemployeeList.results : AllemployeeList;
    let employeesExtraData;
    const dataArrayId = selectedList && selectedList.map((item) => item.suggestions.id ? item.suggestions.id : null);
    employeesExtraData = employeesList.filter((i) => dataArrayId.indexOf(i.id) > -1 );
    const detail = selectedList ? selectedList.map((item) => (
      { source__emp_id: item.suggestions.id,
        destination__emp_id: item.source__employee_id,
        extra_data : employeesExtraData.find((i) => ((item.suggestions.id).toString()).indexOf(i.id) > -1 ? i : null)}
    )) : null ;
    if(!detail) return toast.error('Please select employees to map')
    return saveMapping(detail)
    }  
  };


  let arr;
   arr = timecardList && timecardList.length >0 && timecardList.map((item)=>{
    if(item.source_dt.adjustments.length){
      return [item, ...item.source_dt.adjustments]}
    else return item;
    }
  ).flat()

  return (
    <div>
      {params.tab === 'employee' &&
        <Form onSubmit={handleSubmit(submit)}>
          <div className="filter mt-5">
            <div className="d-inline-block">
              <FilterDropdownComponent
                paramKey="mapped"
                location={location}
                options={EMPLOYEES_FILTER_OPTIONS}
              />
            </div>
            {location.query.mapped === 'false' &&
              <Button
                type="submit"
                color="secondary"
                className={ `${styles['save-mapping']} float-right`}
                disabled={!employeeAndQbdList.length}
              >
                Save Association <ButtonLoaderAtom active={submitting} />
              </Button>}
          </div>
          <Table className={ location.query.mapped === 'false' ? "table qbd-metc-table" : "table"}  hover responsive size="sm">
            <thead className={styles['table-header-main']}>
              <tr>
                <th
                  colSpan={source==='ADP' ? location.query.mapped === 'false' ? 3 : 2 : location.query.mapped === 'false' ? 2 : 1 }
                  className={`${styles['border-right']} text-center ${styles['table-header-case']}`}>
                    { source === 'ADP' ? "ADP Employee(s)" : "QuickBooks Employee(s)" }
                </th>
                <th
                  colSpan={location.query.mapped === 'false' ? 3 : 4}
                  className={`text-center ${styles['table-header-case']}`}>
                    Worksana Employee(s)
                </th>
              </tr>
            </thead>
            <thead className={styles['table-header']}>
              <tr>
                {location.query.mapped === 'false' && <th className="text-center">Select</th>}
                <th className={source!=='ADP' && styles['border-right']}>Name</th>
                {source==='ADP' && <th className={styles['border-right']} >Payroll Group Code</th>}
                <th>Name</th>
                <th>Username</th>
                <th>Title</th>
                {location.query.mapped === 'true' && <th className="text-center">Remove</th>}
              </tr>
            </thead>
            {location.query.mapped === 'true' &&
            <tbody>
              {employeeList && employeeList.length  > 0 ? employeeList.map(item => {
                return (<tr key={item.employee_id}>
                  <td className={source!=='ADP' && styles['border-right']}>{item.employee.name}</td>
                  {source==='ADP' && <td className={styles['border-right']}>{item.employee.payrollGroupCode}</td>}
                  <td>
                    { item.suggestions.user ? item.suggestions.user.full_name : null}
                  </td>
                  <td>{item.suggestions.user ? item.suggestions.user.username : null}</td>
                  <td>{item.suggestions ? item.suggestions.title : null}</td>
                  {location.query.mapped !== 'false' && <td className="text-center">
                    <Button
                      role="button"
                      className={`btn btn-secondary btn-sm ${styles['remove-mapping']}`}
                      tabIndex="0"
                      onClick={() => removeMapping({ destination_emp_id: item.employee_id})}>
                      Remove Mapping <ButtonLoaderAtom active={selectedItemId === item.employee_id} />
                    </Button>
                  </td>}
                </tr>);})
                : <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
            </tbody>}
            {location.query.mapped === 'false' && params.tab === 'employee' &&
              <FieldArray
                name="employeeList"
                data={employeeList}
                component={EmployeeFormComponent}
                metcEmployeeList={metcEmployeeList}
                onChangeEmployee={onChangeEmployee}
                employeeAndQbdList = {employeeAndQbdList}
                getEmployees = {props.getEmployees}
                handleCheckboxes={handleCheckboxes}
                location={location}
                history= {history}
                openSearchEmployeeModal={props.openSearchEmployeeModal}
                AllemployeeList ={AllemployeeList.results ? AllemployeeList.results : AllemployeeList}
                source={source}
              />
            }
          </Table>
        </Form>}

      {params.tab === 'timecard' &&
        <Table responsive hover size="sm" className="table">
          <thead className="Header-col">
            <tr>
              <th className="pl-4">Date</th>
              <th>Name</th>
              <th>Username</th>
              <th>Title</th>
              <th className="text-right">RT</th>
              <th className="text-right">OT</th>
              <th className="text-right">DOT</th>
              <th className="text-right pr-3">Meal</th>
            </tr>
          </thead>
          <tbody>
            {
              arr && arr && arr.length > 0 ?
                arr.map((item) =>
                {
                  return(
                    <tr key={item.id}>
                    <td className="pl-4">{item.profile_id__platform ? item.source_dt.date  : item.name }</td>
                    <td>{item.profile_id__platform ? item.source_dt.employee.user.full_name  : '---' }</td>
                    <td>{item.profile_id__platform ? item.source_dt.employee.user.username  : '---' }</td>
                    <td>{item.profile_id__platform ? item.source_dt.employee.title ? item.source_dt.employee.title : null   : '---' }</td>
                    <td className="text-right">{item.profile_id__platform ? item.source_dt.regular_time_display  : item.regular_time_display }</td>
                    <td className="text-right">{item.profile_id__platform ? item.source_dt.over_time_display  : item.over_time_display }</td>
                    <td className="text-right">{item.profile_id__platform ? item.source_dt.double_over_time_display  : item.double_over_time_display }</td>
                    <td className="text-right pr-3">{item.profile_id__platform ? item.source_dt.meal_period_time_display  : '---' }</td>
                    </tr>
                  )
                }
                  )
                : <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
            }
          </tbody>
        </Table>
      }
      {params.tab === 'jobs' &&
        <div>
          <JobSubTableComponent
            location={location}
            qbdJobsList = {props.qbdJobsList}
            setMapping = {props.setMapping}
            openEmployeeSummary = {props.openEmployeeSummary}
            openTaskSummary = {props.openTaskSummary}
            openSearchModal = {props.openSearchModal}
            fetchData = {props.fetchData}
            getQbdJobs={props.getQbdJobs}
            onChangeEmployee={onChangeEmployee}
            onChangeJobs={props.onChangeJobs}
            removeJobsMapping={props.removeJobsMapping}
            qbdDropdownJobsList={props.qbdDropdownJobsList}
            source={source}
            basicSettingDetail={basicSettingDetail}
          />
        </div>
      }
      {params.tab === 'Payroll_items' && location.query.synced === 'false' &&            
       <PayrollItemsComponent
         data={props.payrollItemsList}
         metcaAdjustmentTypesList ={props.metcPayrollList}
         location={location}
         onChangePayrolls={props.onChangePayrolls}
         setPayrollMapping = {props.setPayrollMapping}
         source={source}
       />           
      }
      {params.tab === 'Payroll_items' && location.query.synced === 'true' &&            
         <AssociatedPayrollsComponent
           data={props.payrollItemsList}
           metcaAdjustmentTypesList ={props.metcPayrollList}
           location={location}
           onChangePayrolls={props.onChangePayrolls}
           setPayrollMapping = {props.setPayrollMapping}
           removePayrollsMapping = {props.removePayrollsMapping}
           removePayrollId = {props.removePayrollId}
           source={source}
         />           
      }
      {params.tab === 'tasks' &&            
        <TaskTableComponent 
          metcTasksList = {props.metcTasksList}
          location={location}
          qbdTasksList ={props.qbdTasksList}
          onChangeTasks ={props.onChangeTasks}
          setTaskMapping={props.setTaskMapping}
          removeTasksMapping = {props.removeTasksMapping}
          removeTaskId = {props.removeTaskId}
          source={source}
        /> 
      }
    </div>

  );
};

QuickBooksTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {}
  const ArrayErrors = []
  if(values.employeeList && values.employeeList.length){
    values.employeeList.forEach((item, index) => {
      const memberErrors = {}
      if (item.isSelected) {

        if(!item.suggested_employee){
          memberErrors.suggested_employee = 'Select Employee';
          ArrayErrors[index] = {...memberErrors};
        }
      }
    });
  }
  if(ArrayErrors.length) {
    errors.employeeList = ArrayErrors;
  }
  return errors;
};

export default reduxForm({
  form: 'QuickbooksTableForm',
  enableReinitialize: true,
  validate,
})(QuickBooksTableComponent);
