import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import EmployeeBulkUploadSection from 'sections/employees/bulkUpload/bulkUpload';

import { NAV } from 'constants/employees';

/**
 * EmployeeBulkUploadPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link EmployeeFilterSection}
 *    - {@link EmployeeListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class EmployeeBulkUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.state = { isLoading: false, isInfoOpen: true, parent: NAV.EmployeeBulkUploadPage.parent };
    this.setParentUrl = this.setParentUrl.bind(this);
  }

  componentWillMount() {
    this.props.InfoDucks.getInformations({route: 'employee.bulkupload'});
    this.setParentUrl();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;

    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        if (params.id) {
          this.setState({
            parent: `${NAV.EmployeeBulkUploadPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        } else {
          this.setState({
            parent: `${NAV.EmployeeBulkUploadPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }



  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("employee_bulk_info", !isInfoOpen);
  }

  render() {
    const { isInfoOpen, parent } = this.state;
    const { infoList } = this.props;
  
    return (
      <TwoColumnLayout 
        navInfo={{...NAV.EmployeeBulkUploadPage, parent }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
      >
        <EmployeeBulkUploadSection />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

EmployeeBulkUploadPage.propTypes = {
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

EmployeeBulkUploadPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EmployeeBulkUploadPage);
