import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as TaskDucks from 'ducks/jobs/task';
import * as TaskTagDucks from 'ducks/jobs/taskTag';
import JobProgressBarSection from 'sections/jobs/form/ProgressBar';
import JobTaskEditSection from 'sections/jobs/jobTaskEdit/jobTaskEdit';
import TwoColumnLayout from 'layouts/TwoColumn';

import { NAV } from 'constants/jobs';

/**
 * JobTasKEditPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class JobTaskEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, parent: NAV.JobEditFormPage.parent,
      isJobAddInfo: true, isJobEditInfo: true };
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    this.props.TaskDucks.getAllTasks({ paginate:false });
    this.props.InfoDucks.getInformations({route: 'job.edit'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
    this.props.TaskDucks.resetTask();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(data){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isLoading: true });
    this.props.TaskDucks.getAssignedTasks({ ...data, job_id: params.id, assigned: true })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));

  }

  loadData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location} = this.props;
    const { query } = location;
    this.setState({ isLoading: true });
    Promise.all([
      this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 }),
      this.props.TaskDucks.getAssignedTasks({ job_id: params.id, assigned: true }),
      this.props.JobDucks.getJob(params),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    const cookieKey = cookie.load("jobs_edit_form_info");
    if(cookieKey) {
      this.setState({isJobEditInfo: JSON.parse(cookieKey)});
    }
    // this.fetchData();
    this.setParentUrl(query);
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isJobAddInfo, isJobEditInfo } = this.state;
    if (params.id) {
      this.setState({ isJobEditInfo: !isJobEditInfo });
      cookie.save("jobs_edit_form_info", !isJobEditInfo);
    }
    else {
      this.setState({ isJobAddInfo: !isJobAddInfo });
      cookie.save("job_add_form_info", !isJobAddInfo);
    }
  }

  render() {
    const { isLoading , isJobEditInfo, isJobAddInfo } = this.state;
    const { location: {  query }, infoList ,jobDetail} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const selectedId = params ? params.id : null;

    return (
      <div>
        <TwoColumnLayout
          navInfo={query.setup ? NAV.JobAddFormPage : query.admin_list ? NAV.AdminJobEditFormPage : NAV.JobEditFormPage}
          infoList={infoList}
          isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          jobDetail={jobDetail}
        >
        <div className="pb-5">
           <Row className="pt-4 mr-4 ml-3" style={{backgroundColor: 'white'}}>
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <JobProgressBarSection
                    active={3}
                    selectedId={selectedId}
                    isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
                    addJob={query.setup}
                    location={this.props.location}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <JobTaskEditSection isLoading={isLoading} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  jobDetail: JobDucks.jobDetail(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
});

JobTaskEditPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

JobTaskEditPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobTaskEditPage);
