import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Link from 'react-router-dom/Link';

import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import * as UserDucks from 'ducks/accounts/user';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as SystemSettingDucks from 'ducks/vendors/systemSetting';
import * as JobDucks from 'ducks/jobs/job';

import SectionLoaderAtom from 'atoms/SectionLoader';
import DashboardGridComponent from 'components/generics/dashboard/Grid';

class DashboardListSection extends React.Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
  }

  goTo(pathname, params) {
    const { router: { history } } = this.context;
    history.push({ pathname, search: queryString.stringify(params) });
  }

  render() {
    const {
      isLoading,
      mcsReportDetail,
      mvReportDetail,
      osReportDetail,
      stats,
      mvEntryDetail,
      userCompany,
      gbReportDetail,
      jobAll,
      location,
      accessSidemenu,
      userExtraData,
    } = this.props;

    let startDate;
    let endDate;
    let jobOptions = [];

    if (userCompany.active_payroll && userCompany.active_payroll.is_active) {
      startDate = moment(userCompany.active_payroll.start_datetime);
      endDate = moment(userCompany.active_payroll.end_datetime)//.subtract(1, "days");
    }

    if (jobAll.length) {
      jobOptions = jobAll && jobAll.map(item => ({
        name: item.name, id: item.id
      }));
    }
    const employeeAccess = accessSidemenu.filter((item) => item.name === 'employee.list').length;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="pb-5 mr-5">
        <DashboardGridComponent 
          data={stats}
          mcsReportDetail={mcsReportDetail}
          mvReportDetail={mvReportDetail}
          mvEntryDetail={mvEntryDetail}
          osReportDetail={osReportDetail}
          gbReportDetail={gbReportDetail}
          userCompany={userCompany}
          jobOptions={jobOptions}
          goTo={this.goTo}
          location={location}
          employeeAccess={employeeAccess}
          userExtraData={userExtraData}
        />
        <Row className="mt-2">
          <Col xs={12} md={12} className="page-footer-padding pl-4 ml-3">
              {startDate && endDate
                ? <span >
                  Pay Period: {startDate.format('MMMM D')} to {endDate.format('MMMM D')} (Day {((moment().diff(startDate, 'days') + 1) > (endDate.diff(startDate, 'days') + 1)) ? (endDate.diff(startDate, 'days') + 1) : (moment().diff(startDate, 'days') + 1)} of {endDate.diff(startDate, 'days') + 1}) 
                  <u className="payroll-underline">
                    <a className="payroll-setting pl-4" href="/payroll/setting/list">View Payroll Settings</a>
                  </u>
                </span>
                : <span>
                  There is no default payroll. You could create one <u><Link to="/payroll">here</Link></u>
                </span>
              }
          </Col>
        </Row>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  mcsReportDetail: ReportDucks.mcsReportDetail(state),
  mvReportDetail: ReportDucks.mvReportDetail(state),
  mvEntryDetail: SystemSettingDucks.mvEntryDetail(state),
  osReportDetail: ReportDucks.osReportDetail(state),
  gbReportDetail: ReportDucks.gbReportDetail(state),
  stats: EmployeeDucks.stats(state),
  userCompany: UserDucks.userCompany(state),
  jobAll: JobDucks.jobAll(state),
  location: RouteDucks.location(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
});

DashboardListSection.propTypes = {
  mcsReportDetail: PropTypes.object,
  mvReportDetail: PropTypes.object,
  stats: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

DashboardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardListSection);
