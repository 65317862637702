import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Form from 'reactstrap/lib/Form';

import AutoCompleteAtom from 'atoms/AutoComplete';

import { FORM_LAYOUT } from 'constants/layout';

const CustomCreateTimecardFilterFormComponent = (props, context) => {
  const { router: { history } } = context;
  const { getEmployees, location: { pathname, query } } = props; 

  return (
    <Form>
      <Field
        component={AutoCompleteAtom}
        getData={(val) => getEmployees(val)}
        id="employee"
        mapKeys={{ label: [ 'user', 'full_name' ], value: 'id' }}
        name="employee"
        label="Search employee"
        layout={FORM_LAYOUT}
        placeholder="Employee"
        onChange={(item) => history.push({ pathname,
          search: queryString.stringify({ ...query, employee_id: item.value })})}
        isCache={false}
      />
    </Form>
  );
}

CustomCreateTimecardFilterFormComponent.propTypes = {
  location: PropTypes.object.isRequired,
  getEmployees: PropTypes.func.isRequired,
};

CustomCreateTimecardFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'CustomCreateTimecardFilterForm',
})(CustomCreateTimecardFilterFormComponent);
