import React, {PureComponent} from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

import styles from './styles.module.scss';
import ButtonLoaderAtom from "atoms/ButtonLoader";

class DeleteNotesConfirmationModal extends PureComponent {

  render() {
    const {isOpen, toggle, item, deleteNotes, refreshLoader } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
       >
        <ModalHeader toggle={()=>toggle()}>Confirm Delete</ModalHeader>
        <ModalBody>
          <div style={{fontSize: '16px'}}> Are you sure you want to delete the job note ? </div>
        </ModalBody>
        <ModalFooter>
          <Button color="default" className={styles.cancel_btn} onClick={toggle}>Cancel</Button>
          <Button type="submit" color="secondary" onClick={() => deleteNotes(item)}>
            Yes <ButtonLoaderAtom active={refreshLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );}
  }
  
export default DeleteNotesConfirmationModal;