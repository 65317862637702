import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as VendorDucks from 'ducks/vendors/vendor';

import styles from './styles.module.scss';

/**
 * AdminDashBoardPage -> PendingActionsListSection
 *
 * Components:
 *    - None
 *
 * State: 
 *    - Pending Action List
 *
 * Actions: 
 *    None
 *    
 */
const PendingActionsListSection = (props) => {
  const { businessAdminStats } = props;

  return (
    <Row>
      <Col sm="12" md="12">
        <Card block className={`${styles['admindashboard--wrapper']} card-block`}>
          <Row>
            <Col sm="12" className={styles['admindashboard--title']}>
              <span >Pending Actions</span>
              <br/>
              <br/>
              <Row>
              <Col md={8}>
              <small className={styles['admindashboard--subtitle']}>
                Timecards To Be Approved 
              </small>
              </Col>
              <Col md={4}>
              <small> 
               <span className="float-left">:</span>
                <span className="float-right">{businessAdminStats.t_cards_to_be_approved_count}</span>
              </small>
              </Col>
              </Row>
              <Row>
              <Col md={8}>
              <small className={styles['admindashboard--subtitle']}>
                Entries To Be Corrected 
              </small>
              </Col>
              <Col md={4}>
              <small> 
               <span className="float-left">:</span>
                <span className="float-right">{businessAdminStats.entries_to_be_corrected_count}</span>
              </small>
              </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  businessAdminStats: VendorDucks.businessAdminStats(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

PendingActionsListSection.propTypes = {
  businessAdminStats: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PendingActionsListSection);
