import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';
import cookie from 'react-cookies';

import { SORT_DIRS } from 'constants/records';
import {ReactComponent as SortIcon} from 'components/common/SortHeader/sort.svg';
import {ReactComponent as SortAscIcon} from 'components/common/SortHeader/sort_asc.svg';
import {ReactComponent as SortDescIcon} from 'components/common/SortHeader/sort_desc.svg';

class SortHeaderComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.sortDirection = this.sortDirection.bind(this);
    const { router: { route: { location: { search } } } } = context;
    const query = queryString.parse(search);
    const projectSortCookie =  cookie.load("projectSorting");
    this.state = { project_sort: query.project_sorting  ? query.project_sorting : projectSortCookie};
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { location: { search: prevSearch } } } } = this.context;
    const { router: { route: { location: { search: nextSearch } } } } = nextContext;
    const query = queryString.parse(nextSearch);
    const prevQuery = queryString.parse(prevSearch);
    const projectSortCookie =  cookie.load("projectSorting");

    if (prevQuery.project_sorting !== query.project_sorting || (this.state.project_sort == null && projectSortCookie)) {
      this.setState({ project_sort: query.project_sorting  ? query.project_sorting : projectSortCookie});
    }

  }

  handleChange() {
    const { router: { history, route: { location: { pathname, search } } } } = this.context;
    const { project_sorting } = this.props;
    const query = queryString.parse(search);

    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        project_sorting: `${query.project_sorting && query.project_sorting[0] === SORT_DIRS.DESC
          ? SORT_DIRS.ASC : SORT_DIRS.DESC}${project_sorting}`,
      }),
    });
  }

  sortDirection() {
    const { router: { route: { location: { search } } } } = this.context;
    const { project_sorting } = this.props;
    const { project_sort } = this.state;
    const query = queryString.parse(search);
    const projectSortCookie =  cookie.load("projectSorting");
    const a = query.project_sorting ? query.project_sorting : projectSortCookie;

    if (project_sort && (a === project_sorting || a === `-${project_sorting}`)) {
      return a[0]  ===  SORT_DIRS.DESC 
        ? <SortDescIcon width="12" height="12" />
        : <SortAscIcon width="12" height="12" />;
    }
    return <SortIcon width="12" height="12" />;
  }

  render() {
    const { children , className} = this.props;
    return (
      <div style={{'marginTop':'-3px'}}>
        {children}
        <Button
          color="link"
          className="p-0 pl-1"
          onClick={this.handleChange}
        >
          {this.sortDirection()}
        </Button>
      </div>
    );
  }
}

SortHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  ordering: PropTypes.string.isRequired,
};

SortHeaderComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SortHeaderComponent;
