import React from 'react';
import moment from 'moment';

import {Card , Row, Col } from 'reactstrap';
import styles from './styles.module.scss';
import Flatpickr from 'react-flatpickr';

const DateTimeComponent = (props) => {
  const { data , index, dateFormat, timeFormat, timeFormat24Hrs, slider } = props;
  return (
    <Card className={`${styles['card-ui']} mr-3 pl-3 pr-3 pt-3 pb-3`}>
      <Row>
      <Col md={11} className={`${styles['custom-col']} pl-1`}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span className={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? `${styles['questions-ui']} pr-0 pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: data.question }}></span>
        </Col>
      </Row>
      {slider ?
        data && data.response && data.response.length ? data.response.map((i)=>
          <Row>
            <Col className="pl-2 pr-1" >
              <Flatpickr
                  className="form-control mb-2 mt-1"
                  options={{
                  dateFormat: `${dateFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  }}
                  aria-label="Start Date"
                  disabled={true}
                  placeholder="Date"
                  value={i.date ? i.date : ''}
              />
            </Col>
            <Col className="pl-1 pr-2">
              <Flatpickr
                  className="form-control mb-2 mt-1"
                  options={{
                  dateFormat: `${timeFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  }}
                  aria-label="Start Date"
                  disabled={true}
                  placeholder="Time"
                  value={i.time ? i.time : ''}
              />
            </Col>
          </Row>
        ):<Row>
            <Col className="pl-2 pr-1" >
              <Flatpickr
                  className="form-control mb-2 mt-1"
                  options={{
                  dateFormat: `${dateFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  }}
                  aria-label="Start Date"
                  disabled={true}
                  placeholder="Date"
              />
            </Col>
            <Col className="pl-1 pr-2">
              <Flatpickr
                  className="form-control mb-2 mt-1"
                  options={{
                  dateFormat: `${timeFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  }}
                  aria-label="Start Time"
                  disabled={true}
                  placeholder="Time"
              />
            </Col>
          </Row>:
          <Row>
            <Col>
              <Flatpickr
                  className="form-control mb-2 mt-1"
                  options={{
                  dateFormat: `${dateFormat} ${timeFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  }}
                  aria-label="Start Date"
                  disabled={true}
                  placeholder="Date & Time"
              />
            </Col>
        </Row>
      }
    </Card>
  );
};

export default DateTimeComponent;