import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip} from 'reactstrap';

import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SectionLoaderAtom from 'atoms/SectionLoader';
import InfoGreyIconAtom from 'atoms/InfoIcon';

import { FORM_LAYOUT } from 'constants/layout';

import AdvancePayrollTableComponent from './advancePayrollTable';
import OvertimeRuleComponent from './OvertimeRuleComponent';
import PaginationComponent from 'components/common/Pagination';

import * as RouteDucks from 'ducks/routes';

/**
 * PayrollSettingFormPage -> PayrollSettingFormSection -> PayrollSettingFormComponent
 *
 * Props:
 *    createOrUpdate function
 */

const PayrollSettingFormComponent = (props) => {

  const { handleSubmit, error, createOrUpdate,  timeFormat, timeFormat24Hrs,  californiaRuleMessage, initialValues, userCompany , OvertimeInfo, toggleOvertimeAccordian, mealBreakInfo, toggleMealBreakAccordian,toggleAddRuleModal ,  mealRuleList, showAdvancedSettings, isAdvancedSettingsVisible,
    payroll_id, params, openDeleteFormModal, openEditForm, mealList, openAddRuleModal, isEditPayroll, showPayrollDetails, userExtraData, location, showPastRecords, showPastRecordsLoader} = props;

  const submit = (data) => {
    const detail = {
      ...data,
      cal_7_day_rule_message : californiaRuleMessage,
      first_meal_threshold: moment.duration(+data.hour || 0, 'hours').asSeconds() +
        moment.duration(+data.minute || 0, 'minutes').asSeconds(),
      meal_waiver_value: moment.duration(+data.wavier_hour || 0, 'hours').asSeconds() +
        moment.duration(+data.wavier_minute || 0, 'minutes').asSeconds(),
      daily_split: data.daily_split,
      week_overtime_threshold: { hours: data.week_overtime_hours ? (+data.week_overtime_hours) : 0, minutes: data.week_overtime_minutes ? (+data.week_overtime_minutes) : 0},
      daily_overtime_threshold: { hours: data.daily_overtime_hours ? (+data.daily_overtime_hours) : 0, minutes: data.daily_overtime_minutes ? (+data.daily_overtime_minutes) : 0},
      daily_double_overtime_threshold: { hours: data.daily_double_overtime_hours ? (+data.daily_double_overtime_hours) : 0, minutes: data.daily_double_overtime_minutes ? (+data.daily_double_overtime_minutes) : 0},
      work_day_start_time: data.work_day_start_time && data.daily_split ? (moment(data.work_day_start_time).format('HH:mm')) : null,
      company_id: userCompany && userCompany.id,
    };
    if(data.daily_split){
      if(moment(data.work_day_start_time).isValid()){
        detail.work_day_start_time = moment(data.work_day_start_time).format('HH:mm');
      }
      else detail.work_day_start_time = data.work_day_start_time;
    }
    else{
      detail.work_day_start_time = null;
    }

    return createOrUpdate(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  };

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="pl-3 pr-4">
      {error && <div className="error-text">{error}</div>}
      <Row>
        <Col md={isEditPayroll ? "10" : "6"}>
          <Field
            id="name"
            name="name"
            label={
              <div>
                Name
                <InfoGreyIconAtom id="payName" className="pl-2 align-text-top" />
                <UncontrolledTooltip placement="right" target="payName">
                  Name this field whatever you like. If you have multiple payroll settings, choose a name related to the subset of individuals to whom the rule will apply. E.g. Farm Laborers.
                </UncontrolledTooltip>
              </div>
            }
            component={InputAtom}
            layout={FORM_LAYOUT}
            disabled={props.isAdvancedSettingsVisible && true}
          />
          <Row>
            <Col className="pr-0">
              <Field
                id="is_default"
                type="checkbox"
                name="is_default"
                label={
                  <div>
                    Set as Default Rule
                    <InfoGreyIconAtom id="defRule" className="pl-2 align-text-top" />
                        <UncontrolledTooltip placement="right" target="defRule">
                          Default role applied to subsets of employees. Leave checked unless you have multiple payroll settings.
                    </UncontrolledTooltip>
                  </div>
                }
                component={CheckboxAtom}
                layout={FORM_LAYOUT}
                disabled={props.isAdvancedSettingsVisible && true}
              />
            </Col>
            {userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.advance_payroll_settings && (params.id || payroll_id) &&
              <Col className="pr-0">
                <Field
                  id="is_advance_payroll"
                  name="is_advance_payroll"
                  component={CheckboxAtom}
                  layout={FORM_LAYOUT}
                  checked={props.isAdvancedSettingsVisible}
                  onClick={(val)=>showAdvancedSettings(val)}
                  label={
                    <div>
                      Advanced Payroll Settings
                    </div>
                  }
                />
              </Col>
            }
          </Row>
        </Col>
      </Row>
      {props.isAdvancedSettingsVisible ?
        (<div md={12} className="mt-0 mb-0 pt-3 pb-0">
          <Row>
            <Col md={!props.newLayout ? 6 : 12}>
              <Field
                id="is_pastRecords"
                name="is_pastRecords"
                component={CheckboxAtom}
                layout={FORM_LAYOUT}
                checked={props.showPastData}
                onClick={(val)=>showPastRecords(val)}
                label={
                  <div>
                    Past Records
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Button
                color="secondary"
                className="float-sm-right mr-4"
                size={FORM_LAYOUT.size}
                onClick={()=>showPayrollDetails('add')}
              >
                Add New
              </Button>
            </Col>
          </Row>
          <AdvancePayrollTableComponent
            showPayrollDetails={showPayrollDetails}
            advancedPayrollSettingList={props.advancedPayrollSettingList}
            showPastRecordsLoader={showPastRecordsLoader}
            dateFormatDisplay={props.dateFormatDisplay}
          />
          <PaginationComponent
            count={props.advancedPayrollCount}
            location={location}
            cookieKey="advanced_payroll_table_size"
          />
        </div>) :
        <OvertimeRuleComponent
          initialValues={initialValues}
          toggle={props.toggle}
          createOrUpdate={createOrUpdate}
          californiaRuleMessage={californiaRuleMessage}
          timeFormat={timeFormat}
          timeFormat24Hrs={timeFormat24Hrs}
          userCompany={userCompany}
          OvertimeInfo={OvertimeInfo}
          toggleOvertimeAccordian={toggleOvertimeAccordian}
          toggleMealBreakAccordian={toggleMealBreakAccordian}
          mealBreakInfo={mealBreakInfo}
          toggleAddRuleModal={toggleAddRuleModal}
          openAddRuleModal={openAddRuleModal}
          mealsList={mealList}
          mealRuleList={mealRuleList}
          openEditForm={openEditForm}
          openDeleteFormModal={openDeleteFormModal}
          params={params}
          payroll_id={payroll_id}
          newLayout = {props.newLayout}
          form={props.form}
          key={props.form}
        />
      }
    </Form>
  );
};


PayrollSettingFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const payrollForm = reduxForm({
})(PayrollSettingFormComponent);

export default connect(
 
)(payrollForm);
