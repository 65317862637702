import React from "react";

import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import { toast } from "react-toastify";
import cookie from "react-cookies";

import * as RouteDucks from "ducks/routes";
import * as ActivityDucks from "ducks/activities/activity";
import * as DailyTimecardDucks from "ducks/timecards/dailyTimecard";
import ActivityInProgressComponent from "components/timecards/dailyTimecardList/ActivityInProgress";

/**
 * DailyTimecardListPage -> ActivityInProgressSection
 *
 * Components:
 *    - {@link DailyTimecardTableComponent}
 *
 * State:
 *    - daily timecard list
 *
 * Actions:
 *    None
 */
class ActivityInProgressSection extends React.Component {
  constructor(props) {
    super(props);
    this.punchOutAndApprove = this.punchOutAndApprove.bind(this);
    this.acceptAndRejectActivity = this.acceptAndRejectActivity.bind(this);
    this.state = { isLoading: false,acceptActivityFlag:false,rejectActivityFlag:false };
  }


  acceptAndRejectActivity(type,sessionId=null)
  {
    const {
      location: { query },
      active,
    } = this.props;

    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
    
    const empId = query.employee_id;
    this.setState({ isLoading: true });
    //type==='Approve'?this.setState({ acceptActivityFlag: true }): sessionId ? this.setState({ rejectActivityFlag: true }):null;
    
    if(type==='Approve' && sessionId===null){
      this.setState({ acceptActivityFlag: true })
    }else if(type==='Reject' && sessionId===null){
      this.setState({ rejectActivityFlag: true })
    }
    return this.props.ActivityDucks.acceptAndRejectInProgressActivity({
      reject_session: type==='Approve'?false:true,
      accept_session: type==='Approve'?true : false,
      session_id : sessionId,
      employee_id: empId,
    }).then(() => {
      let msg=''
      if(sessionId){
        msg = type==='Approve' ?"Manually activity created succesfully":"Session successfully rejected";
      }else{
        msg = type==='Approve' ?"Activity successfully approved":"Activity successfully rejected";
      }
      toast.success(msg);
      this.props.ActivityDucks.getInProgressActivity({ employee_id: empId, start_date: query.start_date,
        end_date: query.end_date, });
      if (active === "old") {
        this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({
          ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
          id: params.id,
          paginate: false,
          admin: true,
        });
      } else {
        this.props.DailyTimecardDucks.getCustomDailyTimecards({
          ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
          id: params.id,
          paginate: false,
          admin: true,
          beta: "true",
        });
      }
      this.setState({ isLoading: false , acceptActivityFlag: false,rejectActivityFlag:false});
    });
  }

  punchOutAndApprove(item) {
    const {
      location: { query },
      active,
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));



    const empId = query.employee_id;
    this.setState({ isLoading: true });
    return this.props.ActivityDucks.approveInProgressActivity({
      id: item.id,
      employee_id: empId,
    }).then(() => {
      let msg = "Activity successfully approved";
      if (!item.end_datetime) {
        msg = "Activity successfully punched out and approved";
      }
      toast.success(msg);
      this.props.ActivityDucks.getInProgressActivity({ employee_id: empId, start_date: query.start_date,
        end_date: query.end_date, });
      if (active === "old") {
        this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({
          ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
          id: params.id,
          paginate: false,
          admin: true,
        });
      } else {
        this.props.DailyTimecardDucks.getCustomDailyTimecards({
          ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers),
          id: params.id,
          paginate: false,
          admin: true,
          beta: "true",
        });
      }
      this.setState({ isLoading: false });
    });
  }

  render() {
    const {
      activityInProgressList,
      showAllocateTasksPopup,
      toggleActivityInProgressSection,
    } = this.props;
    const { isLoading ,acceptActivityFlag,rejectActivityFlag} = this.state;
    return (
      <section className="mt-2 ml-3 mr-4">
        <ActivityInProgressComponent
          data={activityInProgressList.results}
          noSqlKey={activityInProgressList.nosql_data}
          punchOutAndApprove={this.punchOutAndApprove}
          isLoading={isLoading}
          showAllocateTasksPopup={showAllocateTasksPopup}
          isShowEmployee={this.props.isShowEmployee}
          toggleActivityInProgressSection={toggleActivityInProgressSection}
          acceptAndRejectActivity={this.acceptAndRejectActivity}
          acceptActivityFlag={acceptActivityFlag}
          rejectActivityFlag={rejectActivityFlag}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  activityInProgressList: ActivityDucks.activityInProgressList(state),
});

const mapActionsToProps = (dispatch) => ({
  ActivityDucks: bindActionCreators(ActivityDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
});

ActivityInProgressSection.propTypes = {
  activityInProgressList: PropTypes.object,
};

ActivityInProgressSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ActivityInProgressSection);
