const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
];

export const NAV = {
  ActivitySplitListPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard', label: 'Pay Cycle View' },
    ],
    description: 'Activities for session date',
    parent: '/timecard/list',
    title: 'Activities',
    // Dynamic breadcrumb, description, parent, title
  },
  CustomActivitySplitListPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard/custom/list', label: 'Timecards' },
    ],
    description: 'Activities for session date',
    parent: '/timecard/custom/list',
    title: 'Activities',
    // Dynamic breadcrumb, description, parent, title
  },

  ActivityHistoricSplitListPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard', label: 'Pay Cycle View' },
    ],
    description: 'Activities for session date',
    parent: '/timecard/historic',
    title: 'Activities',
    // Dynamic breadcrumb, description, parent, title
  },
  ManualSessionPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard', label: 'Pay Cycle View' },
    ],
    description: 'Create missing timecard for',
    parent: '/timecard/list',
    title: 'Add Missing Timecard',
    // Dynamic breadcrumb, description, parent
  },
  ActivitySplitFormPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard', label: 'Pay Cycle View' },
    ],
    description: 'Manage activities and tasks for a shift',
    parent: '/timecard/list',
    title: 'Add Activities and Tasks',
    // Dynamic breadcrumb, parent
  },
  TrackCurrentListPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/activity/tracking', label: 'Activity' },
      { label: 'Current Tracking' },
    ],
    description: 'View current punch-in sessions of employees',
    parent: '/dashboard',
    title: 'Current Tracking',
  },
  TrackHistoricListPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/activity/tracking', label: 'Activity' },
      { label: 'Historic Tracking' },
    ],
    description: 'View historic punch-in sessions of employees',
    parent: '/dashboard',
    title: 'Historic Tracking',
  },
  CustomActivitySplitFormPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard/list', label: 'Pay Cycle View'},
      { href: '/timecard/custom/list', label: 'Timecards' },
    ],
    description: 'Manage activities and tasks for a shift',
    parent: '/timecard/custom/list',
    title: 'Add Activities and Tasks',
    // Dynamic breadcrumb, parent
  },
  ViewCustomActivitySplitFormPage: {
    breadcrumb: [
      ...baseNav,
      { href: '/timecard/list', label: 'Pay Cycle View'},
      { href: '/timecard/custom/list', label: 'Timecards' },
    ],
    description: 'View activities and tasks for a shift',
    parent: '/timecard/custom/list',
    title: 'View Activities and Tasks',
    // Dynamic breadcrumb, parent
  },
};

export const STATUSES = [
  { value: '1', label: 'Approved' },
  { value: '3', label: 'Awaited Approval' },
  { value: '2', label: 'Invalidated' },
  { value: '4', label: 'Delete' }
];

export const RESOLVED_AS = [
  { value: 'User Error', label: 'User Error' },
  { value: 'Violation', label: 'Violation' },
  { value: 'Others', label: 'Others' },
];

export const FILTER_OPTIONS = [
  { value: 'true', label: 'Active' },
  { value: 'false', label: 'Inactive' },
  { value: 'all', label: 'All' },
];

export const PUNCH_IN_TYPES = [
  { value: '', label: 'All' },
  { value: 'manual_entry', label: 'Manual Entry' },
  { value: 'kiosk', label: 'Kiosk Entry' },
  { value: 'app_user', label: 'App Entry' },
];

export const PUNCH_IN_TYPES_CURRENT = [
  { value: '', label: 'All' },
  { value: 'kiosk', label: 'Kiosk Entry' },
  { value: 'app_user', label: 'App Entry' },
];

export const SHIFT_VIEW_OPTIONS = [
  { value: '1', label: 'Timeline' },
  { value: '2', label: 'Shift Notes'}
];

export const PUNCH_IN_OPTIONS = [
  { value: '1', label: 'Punched-in' },
  { value: '2', label: 'Not Punched-in'}
]

export default { NAV, STATUSES, FILTER_OPTIONS, RESOLVED_AS, PUNCH_IN_TYPES, PUNCH_IN_TYPES_CURRENT, SHIFT_VIEW_OPTIONS ,PUNCH_IN_OPTIONS};
