import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import TwoColumnLayout from 'layouts/TwoColumn';
import GeoFenceFormSection from 'sections/geoFences/form/Form';

import { NAV } from 'constants/geoFences';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

/**
 * GeoFenceFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link GeoFenceFormSection}
 *
 * Fetch Needs:
 *    - get payroll detail
 */
class GeoFenceFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isGeoFenceAddOpen: true, isGeoFenceEditOpen: true, parent: NAV.GeoFenceAddFormPage.parent };
    this.fetchData = this.fetchData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.findMe = this.findMe.bind(this);
    this.locationAccessed = this.locationAccessed.bind(this);
  }

  componentWillMount() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location : { query }} = this.props;

    this.setParentUrl(query);
    this.findMe();
    if(!params.id){
      const cookieKey = cookie.load("geofence_add_form_info");
      if(cookieKey) {
        this.setState({isGeoFenceAddOpen: JSON.parse(cookieKey)});
      }
    }
    if (params.id) {
      const cookieKey = cookie.load("geofence_edit_form_info");
      if(cookieKey) {
        this.setState({isGeoFenceEditOpen: JSON.parse(cookieKey)});
      }
    }
    this.fetchData();
  }

  componentWillUnmount() {
    this.props.GeoFenceDucks.resetGeoFence();
    this.props.InfoDucks.resetInformations();
    this.props.ActivityTrackDucks.resetBrowserLocation();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;

    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
      // this.setState({ parent });
    }
  }

  locationAccessed(position) {
    var latitude  = position.coords.latitude;
    var longitude = position.coords.longitude;
    const data= {position : {lat : latitude, lng : longitude }};
    this.props.ActivityTrackDucks.getUserCurrentCountry(data , 'locationIq');
  }

  findMe() {
  if (!navigator.geolocation){
   console.log("Geolocation is not supported by your browser");
    return;
  }
  navigator.geolocation.getCurrentPosition(this.locationAccessed);
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isGeoFenceEditOpen, isGeoFenceAddOpen } = this.state;
    if (params.id) {
      this.setState({ isGeoFenceEditOpen: !isGeoFenceEditOpen });
      cookie.save("employee_edit_form_info", !isGeoFenceEditOpen);
    }
    else {
      this.setState({ isGeoFenceAddOpen: !isGeoFenceAddOpen });
      cookie.save("employee_add_form_info", !isGeoFenceAddOpen); 
    }
  }

  fetchData(){
    this.setState({ isLoading: true });
    const { router: { route: { match: { params } } } } = this.context;
    if(!params.id){
      this.props.InfoDucks.getInformations({route: 'geofence.add'})
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
    if (params.id) {
      Promise.all([
        this.props.GeoFenceDucks.getGeoFence(params),
        this.props.InfoDucks.getInformations({route: 'geofence.edit'}),
      ])
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { infoList } = this.props;
    const { isGeoFenceEditOpen, isGeoFenceAddOpen, parent } = this.state;

    return (
      <TwoColumnLayout
        navInfo={params.id ? {...NAV.GeoFenceEditFormPage, parent} : {...NAV.GeoFenceAddFormPage, parent}}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={params.id ? isGeoFenceEditOpen : isGeoFenceAddOpen}
        fetchData={this.fetchData}
      >
        <GeoFenceFormSection isLoading={this.state.isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

GeoFenceFormPage.propTypes = {
  GeoFenceDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

GeoFenceFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceFormPage);
