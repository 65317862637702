import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';

import TwoColumnLayout from 'layouts/TwoColumn';
import { NAV, MSGS } from 'constants/vendors';
import { toast } from 'react-toastify';
import StorageGateway from 'lib/storage-gateway';
import moment from 'moment-timezone';

import * as RouteDucks from 'ducks/routes';
import * as PluginDucks from 'ducks/vendors/plugin';
import * as InfoDucks from 'ducks/info/info';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as UserDucks from 'ducks/accounts/user';
import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';

import PluginSettingSection from 'sections/vendors/pluginSetting/PluginSetting';

/**
 * PluginSettingPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link PluginSettingFilterSection}
 *    - {@link PluginSettingSection}
 *
 *
 */

class PluginSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.updatePluginSetting = this.updatePluginSetting.bind(this);
    this.createRepititiveCall = this.createRepititiveCall.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { notification: true, notification_list: [], popoverOpen: false, isLoading: false, isInfoOpen: true, isTableLoading: false, endPoint: null, syncData: null};
    this.interval = null;
    this.toggle = this.toggle.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.getData = this.getData.bind(this);
    this.getpluginData = this.getpluginData.bind(this);
    this.authenticateProfile = this.authenticateProfile.bind(this);
    this.getSyncing = this.getSyncing.bind(this);
    this.getNotificationList = this.getNotificationList.bind(this);
    this.source = null;
  }

  componentWillMount() {
    const cookieKey = cookie.load("plugin_setting");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.setState({ isLoading: true });
    this.getpluginData();
  }
  
  getpluginData(){
    this.props.PluginDucks.getPluginList()
      .then((response) => {
        const plugin = (response.value.results).find((item) => item.activated === true);
        if(plugin.master.id === 1){
          this.source = 'QBO';
        }
        else if(plugin.master.id === 2){
          this.source = 'QBD';
        }
        else{
          this.source = 'ADP';
        }
        this.authenticateProfile();
      }
    );
  }

  getSyncing(){
    this.getNotificationList();
    this.props.PluginDucks.getSync()
      .then((response) =>  {
        const { pluginSettingList } = this.props;
        this.setState({ endPoint: pluginSettingList.destination_end_point &&
          pluginSettingList.destination_end_point.name });
        this.fetchData();
      });
  }

  authenticateProfile(){
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const company_id = StorageGateway.get('company');
    const token = StorageGateway.get('Token');
    const data = {
      profile_id: company_id,
      profile_uuid: uniqueUuid,
      destination_platform: this.source,
      token: token
    }
    this.props.PluginDucks.authenticate(data)
      .then((response) =>  {
        const pluginToken = response && response.value.token;
        StorageGateway.set('pluginToken', pluginToken);
      })
      .finally(() => this.getSyncing());
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { router: { route: { match: { params: prevParams } } } } = this.context;
    const { location: { query: nextQuery } } = nextProps;
    const { router: { route: { match: { params: nextParams } } } } = nextContext;
    if (prevQuery !== nextQuery || (prevParams !== nextParams)) {
      this.getData(nextQuery, nextParams);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  getData(query, params){
    const newParams = {  paginate:false}
    const { endPoint } = this.state;
    this.setState({ isTableLoading: true });
    if(params.tab === 'sync'){  
      Promise.all([    
        this.props.PluginDucks.getSync()
      ])
        .then(() => this.setState({ isTableLoading: false }))
        .catch(() => this.setState({ isTableLoading: false }));
    }
    if(params.tab === 'employee'){
      const pageSize = cookie.load("qb_metc_employee_page_size");    
      Promise.all([    
        this.props.EmployeeDucks.getAllEmployees(newParams),        
        this.props.PluginDucks.getQbdMetcEmployees({ ...query, end_point: endPoint, page_size: pageSize }),
        /*this.props.PluginDucks.getMetcEmployees({ ...query, end_point: endPoint, paginate: false }),*/
      ])
        .then(() => this.setState({ isTableLoading: false }))
        .catch(() => this.setState({ isTableLoading: false }));
    }
    else if (params.tab === 'timecard'){

      const timecardPageSize = cookie.load("qb_timecard_page_size");
      this.props.TimecardDucks.getKonnectTimecards({...query, end_point: endPoint, page_size: timecardPageSize })
        .then(() => this.setState({ isTableLoading: false }))
        .catch(() => this.setState({ isTableLoading: false }));
    }
    else if (params.tab === 'jobs'){
      const {status,...rest} = query;
      if(query.synced === 'false'){       
        this.props.PluginDucks.getAllQbdJobs({...rest, source: this.source})
      }
      this.props.PluginDucks.getKonnectJobs({...query, source: 'METC', status : status == '0' ? null : status})      
        .then((response) => {
          this.setState({ isTableLoading: false })
      })
        .catch(() => this.setState({ isTableLoading: false }));
    }
    else if (params.tab === 'Payroll_items'){
      this.props.PluginDucks.getMetcPayrollItems({...query, source: 'METC'})
      this.props.PluginDucks.getPayrollItems({ source: this.source, synced: 'false'})
        .then(() => this.setState({ isTableLoading: false }))
        .catch(() => this.setState({ isTableLoading: false }));
    }
    else if (params.tab === 'tasks'){
      this.props.PluginDucks.getMetcTasks({...query, source: 'METC'})
      this.props.PluginDucks.getQbdTasks({ source: this.source})
        .then(() => this.setState({ isTableLoading: false }))
        .catch(() => this.setState({ isTableLoading: false }));
    }
  }

  fetchData(){
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { pathname, query } } = this.props;
    if(params.tab === 'timecard' && ((query && !( 'start' in query)) || !query)){
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })});
    }
    if(params.tab === 'employee' && ((query && !( 'mapped' in query)) || !query)){
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          mapped: false,
        })});
    }

    this.getData(query, params);
    if(query && query.loading === 'True') {
      toast.success(MSGS.SYNC_LOADING_MESSAGE);
    }
    this.setState({ isLoading: false });
    /*this.props.InfoDucks.getInformations({route: 'vendor.plugin-settings'})
      .then((res) => {
        const { pluginSettingList } = this.props;
        if(pluginSettingList.destination_end_point.name === 'QUICKBOOKS'){
          this.props.PluginDucks.getPluginAuth()
            .then(() => {
              const { pluginAuthInfo } = this.props;
              if(pluginAuthInfo === "Failure") {
                history.push('/vendor/plugin');
              }
              else {
                this.updatePluginSetting();
                /*this.props.PluginDucks.getPluginFailureList({type:'employee'});
                this.props.PluginDucks.getPluginTimecardFailureList({type:'timecard'});*/
             /* }
            });
        }*/
        /*else {
          this.updatePluginSetting();
          this.setState({ isLoading: false });
        }
      })*/
      /*.catch(() => {
        this.setState({ isLoading: false })
      });*/
  }

  getNotificationList(){
    return this.props.PluginDucks.getNotificationList();
  }

  toggle() {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  }

  updatePluginSetting(){
    const { endPoint } = this.state;
    return this.props.PluginDucks.updatePluginSetting({ end_point: endPoint})
      .then(() => {
        this.createRepititiveCall();
        this.setState({ isLoading: false })
      });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("plugin_setting", !isInfoOpen);
  }

  createRepititiveCall() {
    const {updatePluginSettingData} = this.props;
    if(updatePluginSettingData.refresh_time) {
      clearInterval(this.interval);
      this.interval = null;
      this.interval = setInterval(() => {
        this.updatePluginSetting();
        this.props.PluginDucks.getPluginFailureList({type:'employee'});
        this.props.PluginDucks.getPluginTimecardFailureList({type:'timecard'});
      }, updatePluginSettingData.refresh_time * 1000);
    }
  }

  render() {
    const { notification, popoverOpen, isLoading, isInfoOpen, isTableLoading, endPoint } = this.state;
    const { infoList } = this.props;
    return (

      <TwoColumnLayout
        navInfo={(this.source == 'QBD' || this.source == 'QBO') ?  NAV.PluginSettingPage :  (this.source == 'ADP') ? NAV.PluginSettingAdpPage : NAV.CommonPluginSettingsPage}
        infoList={infoList}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
        toggle={this.toggle}
        notification={notification}
      >
        <PluginSettingSection
          isLoading={isLoading}
          isTableLoading={isTableLoading}
          fetchData={this.fetchData}
          popoverOpen={popoverOpen}
          endPoint={endPoint}
          source={this.source}
          userExtraData = {this.props.userExtraData}
          companyId = {this.props.userProfile.company.id}
          getData={this.getData}
          qbdDropdownJobsList={this.props.qbdDropdownJobsList}
          notification_list={this.props.notification_list}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return({
  pluginSettingList: PluginDucks.pluginSettingList(state),
  location: RouteDucks.location(state),
  updatePluginSettingData: PluginDucks.updatePluginSettingData(state),
  pluginAuthInfo: PluginDucks.pluginAuthInfo(state),
  infoList: InfoDucks.infoList(state),
  qbdJobsList : PluginDucks.qbdJobsList(state),
  employeeAll: EmployeeDucks.employeeAll(state),
  userProfile: UserDucks.profile(state),
  userExtraData: UserDucks.userExtraData(state),
  qbdDropdownJobsList: PluginDucks.qbdDropdownJobsList(state),
  notification_list : PluginDucks.notificationList(state),
})
};

const mapActionsToProps = dispatch => ({
  PluginDucks: bindActionCreators(PluginDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
});

PluginSettingPage.propTypes = {
  location: PropTypes.object.isRequired,
  PluginDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

PluginSettingPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PluginSettingPage);
