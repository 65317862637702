import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';
import moment from 'moment';
import Link from 'react-router-dom/Link';
import queryString from 'query-string';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const GeneralAuditReportTableComponent = (props) => {
  const {  data, location: { query }, pageSize, userTimezone, selectedActivityType } = props;
  const { page = 1 } = query;
  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='user_first_name'>
            First Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='user_last_name'>
            Last Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='actor_first_name'>
            Changed By
          </SortHeaderWithSeparateKeysComponent>
          <th>Old Value</th>
          <th>New Value</th>
          <th className="text-left">Activity Type</th>
          <th className="text-left">Changed on</th>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => {
            const oldData = item.old_value;
            const newData = item.new_value;
            const oldStartTime = oldData && oldData.start_datetime && moment(oldData.start_datetime).tz(userTimezone).format('hh:mm a');
            const newStartTime = newData && newData.start_datetime && moment(newData.start_datetime).tz(userTimezone).format('hh:mm a');
            const oldEndTime = oldData && oldData.end_datetime && moment(oldData.end_datetime).tz(userTimezone).format('hh:mm a');
            const newEndTime = newData && newData.end_datetime && moment(newData.end_datetime).tz(userTimezone).format('hh:mm a');
            
            let oldStatus = '';
            let newStatus = '';

            if(oldData && oldData.status ==='1') oldStatus = 'Approved';
            else if (oldData && oldData.status ==='2') oldStatus = 'Invalidated';
            else oldStatus = 'Awaited Approval';
            
            if(newData && newData.status ==='1') newStatus = 'Approved';
            else if (newData && newData.status ==='2') newStatus = 'Invalidated';
            else newStatus = 'Awaited Approval'; 
            
            let startTimeChanged = false;
            let endTimeChanged = false;
            if(oldStartTime !== newStartTime){
              startTimeChanged = true;
            }
            if(oldEndTime !== newEndTime){
              endTimeChanged = true;
            }


            let renderRows = [];
              
            if(newData.activity_type === 'PUNCH-IN' &&  (startTimeChanged || endTimeChanged)  ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={'Punch-in'} >{'Punch-in'}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === 'MEAL-PERIOD' && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={'Meal Period'} >{'Meal Period'}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === "Shift Added" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={newData.activity_type} >{newData.activity_type}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }
            
            if(newData.activity_type === "Meal Added" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={newData.activity_type} >{newData.activity_type}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newStatus !== oldStatus){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={`${oldStatus}`}>{`${oldStatus}`}</td>
                  <td title={`${newStatus}`}>{`${newStatus}`}</td>
                  <td title={'Status Changed'} >{'Status Changed'}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === "Task added" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={'Task Added'} >{'Task Added'}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === "TASK" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={'Task Edited'} >{'Task Edited'}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === "Task Deleted" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={newData.activity_type} >{newData.activity_type}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.activity_type === "Meal Deleted" && (startTimeChanged || endTimeChanged) ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  {(oldStartTime && oldEndTime)? <td title={`${oldStartTime} to ${oldEndTime}`}>{`${oldStartTime} to ${oldEndTime}`}</td> : <td title={''}>{''}</td> }
                  {(newStartTime && newEndTime)? <td title={`${newStartTime} to ${newEndTime}`}>{`${newStartTime} to ${newEndTime}`}</td> : <td title={''}>{''}</td> }
                  {newData.activity_type? <td title={newData.activity_type} >{newData.activity_type}</td>: <td title={''}>{''}</td>}
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.message && newData.message.length  ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={newData.message[0].message}>{newData.message[0].message}</td>
                  <td title={newData.message[0].message ? 'Comment added' :  'Daily Compliance Message'} >{newData.message[0].message ? 'Comment added' :  'Daily Compliance Message'}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }
            
            if(newData.miles_created_by_app || newData.miles_updated_by_user || newData.miles_updated_by_admin || newData.miles_accepted_by_user){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={''}>{''}</td>
                  <td title={newData.miles_created_by_app || newData.miles_updated_by_user || newData.miles_updated_by_admin || newData.miles_accepted_by_user} >{newData.miles_created_by_app || newData.miles_updated_by_user || newData.miles_updated_by_admin || newData.miles_accepted_by_user}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.geo_fence_breached_by_user  ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={newData.geo_fence_breached_by_user}>{newData.geo_fence_breached_by_user}</td>
                  <td title={'Geofence breach'} >{'Geofence breach'}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.unscheduled && newData.unscheduled.length  ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={newData.unscheduled.length && newData.unscheduled}>{newData.unscheduled.length && newData.unscheduled}</td>
                  <td title={newData.correction_message ? newData.correction_message : ''} >{newData.correction_message ? newData.correction_message : ''}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.correction_request ){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td> 
                  <td title={newData.correction_message}>{newData.correction_message}</td>
                  <td title={newData.correction_request} >{newData.correction_request}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.image_url){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={''}>{''}</td>
                  <td title={'Facial Recognition History'} >{'Facial Recognition History'}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            if(newData.feedback_response){
              renderRows.push(
                <tr key={item.index}>
                  <td title={item.user_first_name} className="pl-3">{item.user_first_name}</td>
                  <td title={item.user_last_name}>{item.user_last_name}</td>
                  <td title={`${item.actor_first_name} ${item.actor_last_name}`}>{`${item.actor_first_name} ${item.actor_last_name}`}</td>
                  <td title={''}>{''}</td>
                  <td title={''}>{''}</td>
                  <td title={'Feedback Response'} >{'Feedback Response'}</td>
                  <td title={item.changed_on}>{item.changed_on}</td>
                </tr>
              )
            }

            return renderRows;
          })
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

GeneralAuditReportTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

GeneralAuditReportTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default GeneralAuditReportTableComponent;
