import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import ChangePasswordFormSection from 'sections/accounts/changePassword/Form';

import { NAV } from 'constants/accounts';

/**
 * ChangePasswordFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ChangePasswordFormSection}
 *
 * Fetch Needs:
 *    None
 */
class ChangePasswordPage extends React.Component {

  constructor(props){
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
  }


  componentWillMount() {
    const { location } = this.props;
    const {  query } = location;
    this.fetchData(query);
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  fetchData(){
    this.setState({isLoading : false});
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("accounts.change_password", !isInfoOpen);
  }


  render() {
    const { infoList } = this.props;
    const { isLoading, isInfoOpen } = this.state;
    return(
      <TwoColumnLayout 
        navInfo={NAV.ProfileChangePasswordFormPage}
        fetchData={this.fetchData}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
      >
        <ChangePasswordFormSection isLoading ={isLoading}/>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

ChangePasswordPage.propTypes = {
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

ChangePasswordPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ChangePasswordPage);
