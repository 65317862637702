import React from 'react';
import { Row, Col} from 'reactstrap'
import queryString from 'query-string';

import UserAPIGateway from 'accounts/gateways/user';
import SignInUseCase from 'accounts/usecases/signIn';
import SignInForm from 'accounts/components/signIn';
import ContentLayout from 'layouts/Content';

class SignInPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.updateModalState = this.updateModalState.bind(this);
    this.listenSignInUseCase = this.listenSignInUseCase.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new SignInUseCase(userAPIGateway);
    this.state = {ismodalState:false};
  }

  componentWillMount() {
    this.listenSignInUseCase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  updateModalState(){
    const {ismodalState} = this.state;
    this.setState({ismodalState: true});
  }

  listenSignInUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'LOGIN_SUCCESS':
          if(event.data.is_business_admin){
            this.props.history.push('/vendor/list/all');
          }
          else{
            this.props.history.push('/dashboard');
            }
          break;
        case 'LOGIN_FAILURE' :
          this.updateModalState();
          break;
        case 'TWO_FACTOR_AUTHENTICATE':
          const uid = event.data;
          this.props.history.push({
            pathname: '/accounts/login-otp',
            search: queryString.stringify({uid: uid}),
            state: {password: event.password},
          });
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    const {ismodalState} = this.state;
    return (
       <ContentLayout>
        <Row className="content-area justify-center">
          <Col xs={12} md={{ size:4 }} className="align-self-center">
            <SignInForm data={this.state} usecase={this.usecase} ismodalState={ismodalState}/>
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}


export default SignInPage;
