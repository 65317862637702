import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { toast } from 'react-toastify';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import SelectAtom from 'atoms/Select';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import JobTaskMappingFormModalComponent from 'components/jobs/jobTaskMappingList/FormModal';
import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';


class JobTaskComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},isOpen: false,dropdownOpen: false,
      value:{ value: '', label: "Select Tags" },
      dropOpen: false, valueTask: { value: '', label: "Select Tags" }
    };
    // this.assignTask = this.assignTask.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onTagSelection = this.onTagSelection.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.dropdownSecondToggle = this.dropdownSecondToggle.bind(this);
    this.createTask = this.createTask.bind(this);
    this.assignTask = this.assignTask.bind(this);
    this.assignByTag = this.assignByTag.bind(this);
  }

  onTagSelection(val){
    const { onTagSelect } = this.props;
    if(val.label == 'No records'){
      return true;
    }
    else if(val){
      this.setState({value: val});
    }
    else{
      this.setState({value:"Select Tags"});
    }
    onTagSelect(val);
  }

  onTagTaskSelection(val){
    const { onTagTaskSelect } = this.props;
    if(val){
      this.setState({ valueTask: val });
    }
    else{
      this.setState({ valueTask: "Select Tags" });
    }
    onTagTaskSelect(val);
  }

  toggle() {
    const { jobDetail } = this.props;
    const { isOpen } = this.state;
    if(!isOpen && !jobDetail.id) return toast.error('Please create a job to assign task.');
    return this.setState({ isOpen: !isOpen });
  }

  createTask(data) {
    const { create } = this.props;
    return create(data)
      .then(() => this.toggle());
  }

  dropdownToggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  dropdownSecondToggle() {
    this.setState(prevState => ({
      dropOpen: !prevState.dropOpen
    }));
  }

  assignTask(data){
    const { assignTask, change , assignByTag} = this.props;
    if(this.state.valueTask && !this.state.value.value){
      return assignTask(data)
      .then(() => change('task', null ));
    }
    if(this.state.value){
      return assignByTag()
      .then(() => this.setState({ value: { value: '', label: 'Select Tags' } }))
      .catch(() => console.log(''));
    }
     if(this.state.valueTask && this.state.value.value){
      assignByTag();
      return assignTask(data)
      .then(() => {
        change('task', null );
        this.setState({ value: { value: '', label: 'Select Tags' }});
      })
    }
  }

  assignByTag(data){
    const { assignByTag } = this.props;
    return assignByTag()
      .then(() => this.setState({ value: { value: '', label: 'Select Tags' } }))
      .catch(() => console.log(''));
  }

  render() {
    const {
      handleSubmit,
      getOptions,
      isTaskSubmitting,
      tagList,
      createTag,
      deleteTask,
      taskAll
    } = this.props;

    const { isOpen, value  } = this.state;
    const { toggleTagModal, isModalHidden } = this.props;

    let tagOptions = [];
    if (tagList.length && tagList[0].tag_collection && tagList[0].tag_collection.length){
      tagOptions = tagList[0].tag_collection && tagList[0].tag_collection.map(item => ({
        value: item ,label:  item,
      }));
    }
    else{
      tagOptions.splice(0, 0, { value: "", label: "No records" });
    }
    return (
      <div>
        <Form onSubmit={handleSubmit(this.assignTask)} className="d-inline" autocomplete="off">
          <Row className="filter">
            <Col md={3}>
              <div className="text-left">Task Tags</div>
              <div>
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.dropdownToggle} className="float-custom task-tag-dropdown">
                  <DropdownToggle caret color="primary" className = 'tag-dropdown'>
                    <span className={value.value === '' ? 'light-text' : null}>{value.label}</span>
                  </DropdownToggle>
                  <DropdownMenu right className="scroll-edit-job w-100">
                    {tagOptions && tagOptions.map((item)=>(
                      <DropdownItem onClick={() => this.onTagSelection(item)}  className="w-100">
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
            <Col md={9}>
                <Row>
                  <Col md={4} >
                    <div className="text-left">Tasks</div>
                    <div>
                      <section className="button-padding w-100 mt-0 text-left task-override">
                        <Field
                          id="task"
                          name="task"
                          component={SelectAtom}
                          placeholder="Select Tasks"
                          layout={FORM_LAYOUT}
                          options={taskAll && taskAll.results && taskAll.results.length && taskAll.results.map((i) => ({ value: i.id, label: i.name }) )}
                        />
                      </section>
                    </div>

                    <div>
                      <span role="button" tabIndex={0} className={styles['task-list']} onClick={() => this.toggle()}> <div className='pl-1 mt-2'> + Create a Task</div></span>
                    </div>
                  </Col>

                  <Col md={5} className="text-left">
                    <Button
                      className="assign-button"
                      color="primary"
                      type="submit"
                      style={{marginTop: '1.2rem', paddingTop: '0.2rem'}}
                    >
                      <span>
                        Assign
                      </span>
                      <ButtonLoaderAtom active={isTaskSubmitting}/>
                    </Button>
                  </Col>
                </Row>
            </Col>
          </Row>
        </Form>
              {/*
                <div className="d-inline-block mt-1 task-override">
                  <Dropdown isOpen={this.state.dropOpen} toggle={this.dropdownSecondToggle}>
                    <DropdownToggle caret color="primary" className={styles['button-export']}>
                      { valueTask.label }
                    </DropdownToggle>
                    <DropdownMenu right className="scroll-edit-job">
                      {tagOptions && tagOptions.map((item)=>(
                        <DropdownItem onClick={() => this.onTagTaskSelection(item)}>
                          {item.label}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              <div>
                Create a task <span role="button" tabIndex={0} className={styles['task-list']} onClick={() => toggleTaskListModel()}> <u><i className='pl-1'> + Create a Task</i></u></span>
              </div> */}

              {/*
                <span className="pl-0 d-inline-block">
                  <Button
                    className="btn-task ml-2 mt-0"
                    onClick={() => this.toggle()}
                  >
                    Create and Assign Task
                  </Button>
                </span>
              */}


          {isOpen &&
            <JobTaskMappingFormModalComponent
              isOpen={isOpen}
              toggle={this.toggle}
              createTag={createTag}
              create={this.createTask}
              tagList ={tagList}
              getOptions ={getOptions}
              deleteTask={deleteTask}
              toggleTagModal={toggleTagModal}
              isModalHidden={isModalHidden}
            />
          }

      </div>
    );
  }
}


JobTaskComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  jobDetail: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTask: PropTypes.func,
  taskList: PropTypes.object,
  jobList: PropTypes.func,
  change: PropTypes.func,
};

JobTaskComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const JobTask = reduxForm({
  form: 'JobTaskForm',
  enableReinitialize: true,
})(JobTaskComponent);

const selector = formValueSelector('JobTask');

export default connect(
  (state) => {
    const employee = selector(state, 'employee');
    const task = selector(state, 'task');
    return { employee, task };
  },
)(JobTask);
