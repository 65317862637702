const baseNav = [
    { href: '/dashboard', label: 'Dashboard' },
    { href: '/timecard', label: 'Pay Cycle View' },
  ];
  
  export const NAV = {
    SchedulingDashboardPage: {
      description: "View and manage daily shifts",
      title: 'Shift Dashboard',
    },
    ShiftManagementPage: {
        description: "View and manage daily shifts",
        title: 'Scheduling',
      },
   
  };
  
  
  export default { NAV };
  