export const MSGS = {
  JOB_ADD_SUCCESS: "Job added successfully",
  JOB_UPDATE_SUCCESS: "Job updated successfully",
  JOB_EMPLOYEE_ASSIGN: "Employee assigned successfully",
  JOB_EMPLOYEE_UNASSIGN: "Employee unassigned successfully",
  JOB_TASK_ASSIGN: "Task assigned successfully",
  JOB_TASK_UNASSIGN: "Task unassigned successfully",
};

export default MSGS;
