import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';
import moment from 'moment-timezone';

const GET_PAYROLL_SETTING = 'payrolls/setting/GET_PAYROLL_SETTING';
const GET_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/GET_PAYROLL_SETTING_FULFILLED';
const GET_ADVANCED_PAYROLL_SETTING = 'payrolls/setting/GET_ADVANCED_PAYROLL_SETTING';
const GET_ADVANCED_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/GET_ADVANCED_PAYROLL_SETTING_FULFILLED';
const GET_ADVANCED_PAYROLL_SETTING_DETAIL = 'payrolls/setting/GET_ADVANCED_PAYROLL_SETTING_DETAIL';
const GET_ADVANCED_PAYROLL_SETTING_DETAIL_FULFILLED = 'payrolls/setting/GET_ADVANCED_PAYROLL_SETTING_DETAIL_FULFILLED';
const GET_ALL_PAYROLL_SETTINGS = 'payrolls/setting/GET_ALL_PAYROLL_SETTINGS';
const GET_ALL_PAYROLL_SETTINGS_FULFILLED = 'payrolls/setting/GET_ALL_PAYROLL_SETTINGS_FULFILLED';
const GET_PAYROLL_SETTINGS = 'payrolls/setting/GET_PAYROLL_SETTINGS';
const GET_PAYROLL_SETTINGS_FULFILLED = 'payrolls/setting/GET_PAYROLL_SETTINGS_FULFILLED';
const POST_PAYROLL_SETTING = 'payrolls/setting/POST_PAYROLL_SETTING';
const POST_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/POST_PAYROLL_SETTING_FULFILLED';
const PUT_PAYROLL_SETTING = 'payrolls/setting/PUT_PAYROLL_SETTING';
const PUT_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/PUT_PAYROLL_SETTING_FULFILLED';
const CREATE_MEAL = 'payrolls/setting/CREATE_MEAL';
const CREATE_MEAL_FULFILLED = 'payrolls/setting/CREATE_MEAL_FULFILLED';
const UPDATE_MEAL = 'payrolls/setting/UPDATE_MEAL';
const UPDATE_MEAL_FULFILLED = 'payrolls/setting/UPDATE_MEAL_FULFILLED';
const POST_ADVANCED_PAYROLL_SETTING = 'payrolls/setting/POST_ADVANCED_PAYROLL_SETTING';
const POST_ADVANCED_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/POST_ADVANCED_PAYROLL_SETTING_FULFILLED';
const UPDATE_ADVANCED_PAYROLL_MEAL = 'payrolls/setting/UPDATE_ADVANCED_PAYROLL_MEAL';
const UPDATE_ADVANCED_PAYROLL_MEAL_FULFILLED = 'payrolls/setting/UPDATE_ADVANCED_PAYROLL_MEAL_FULFILLED';
const CREATE_ADVANCED_PAYROLL_MEAL = 'payrolls/setting/CREATE_ADVANCED_PAYROLL_MEAL';
const CREATE_ADVANCED_PAYROLL_MEAL_FULFILLED = 'payrolls/setting/CREATE_ADVANCED_PAYROLL_MEAL_FULFILLED';
const PUT_ADVANCED_PAYROLL_SETTING = 'payrolls/setting/PUT_ADVANCED_PAYROLL_SETTING';
const PUT_ADVANCED_PAYROLL_SETTING_FULFILLED = 'payrolls/setting/PUT_ADVANCED_PAYROLL_SETTING_FULFILLED';

// Reducers for deleting meal
const DELETE_MEAL = 'payrolls/setting/DELETE_MEAL';
const DELETE_MEAL_FULFILLED = 'payrolls/setting/DELETE_MEAL_FULFILLED';
const DELETE_ADVANCED_PAYROLL_MEAL = 'payrolls/setting/DELETE_ADVANCED_PAYROLL_MEAL';
const DELETE_ADVANCED_PAYROLL_MEAL_FULFILLED = 'payrolls/setting/DELETE_ADVANCED_PAYROLL_MEAL_FULFILLED';

const GET_MEALS = 'payrolls/setting/GET_MEALS';
const GET_MEALS_FULFILLED = 'payrolls/setting/GET_MEALS_FULFILLED';
const GET_ADVANCED_PAYROLL_MEALS = 'payrolls/setting/GET_ADVANCED_PAYROLL_MEALS';
const GET_ADVANCED_PAYROLL_MEALS_FULFILLED = 'payrolls/setting/GET_ADVANCED_PAYROLL_MEALS_FULFILLED';
const GET_MEAL_DETAILS = 'payrolls/setting/GET_MEAL_DETAILS';
const GET_MEAL_DETAILS_FULFILLED = 'payrolls/setting/GET_MEAL_DETAILS_FULFILLED';
const GET_ADVANCED_PAYROLL_MEAL_DETAILS = 'payrolls/setting/GET_ADVANCED_PAYROLL_MEAL_DETAILS';
const GET_ADVANCED_PAYROLL_MEAL_DETAILS_FULFILLED = 'payrolls/setting/GET_ADVANCED_PAYROLL_MEAL_DETAILS_FULFILLED';

const RESET_ADVANCE_PAYROLL_DETAIL = 'payrolls/setting/RESET_ADVANCE_PAYROLL_DETAIL';
const RESET_PAYROLL_DETAIL = 'payrolls/setting/RESET_PAYROLL_DETAIL';

export function getPayrollSettings(params) {
  return {
    type: GET_PAYROLL_SETTINGS,
    payload: axios.get(URLS.PAYROLL_SETTING, { params }),
  };
}

export function getAllPayrollSettings(params) {
  return {
    type: GET_ALL_PAYROLL_SETTINGS,
    payload: axios.get(URLS.PAYROLL_SETTING, { params }),
  };
}

export function getPayrollSetting(params) {
  return {
    type: GET_PAYROLL_SETTING,
    payload: axios.get(`${URLS.PAYROLL_SETTING}${params.id}/`),
  };
}

export function getAdvancedPayrollSetting(data, params) {
  return {
    type: GET_ADVANCED_PAYROLL_SETTING,
    payload: axios.get(`${URLS.PAYROLL_ADVANCED_SETTING}${data.id}${URLS.ADVANCED_SETTINGS}`, { params}),
  };
}

export function getAdvancedPayrollSettingDetail(data, params, id){
  return {
    type: GET_ADVANCED_PAYROLL_SETTING_DETAIL,
    payload: axios.get(`${URLS.PAYROLL_ADVANCED_SETTING}${data.id}${URLS.ADVANCED_SETTINGS}${id}/`, { params}),
  };
}

export function postPayrollSetting(data) {
  return {
    type: POST_PAYROLL_SETTING,
    payload: axios.post(URLS.PAYROLL_SETTING, data),
  };
}

export function putPayrollSetting(data, payrollId) {
  const id = data.id ? data.id : payrollId; 
  return {
    type: PUT_PAYROLL_SETTING,
    payload: axios.put(`${URLS.PAYROLL_SETTING}${id}/`, data),
  };
}

export function putAdvancedPayrollSetting(payrollId, data, configId) {
  return {
    type: PUT_ADVANCED_PAYROLL_SETTING,
    payload: axios.put(`${URLS.PAYROLL_ADVANCED_SETTING}${payrollId}${URLS.ADVANCED_SETTINGS}${configId}/`, data),
  };
}


export function postAdvancedPayrollSetting(data, params) {
  return {
    type: POST_ADVANCED_PAYROLL_SETTING,
    payload: axios.post(`${URLS.PAYROLL_ADVANCED_SETTING}${params.id}${URLS.ADVANCED_SETTINGS}`, data),
  };
}

export function createMeal(data) {
  return {
    type: CREATE_MEAL,
    payload: axios.post(`${URLS.CREATE_MEAL_RULE}`, {...data}),
  };
}

export function createAdvancedPayrollMeal(data, params) {
  return {
    type: CREATE_ADVANCED_PAYROLL_MEAL,
    payload: axios.post(`${URLS.CREATE_ADVANCED_SETTINGL_CONFIG}`, {...data}),
  };
}

export function updateMeal(data, id) {
  return {
    type: UPDATE_MEAL,
    payload: axios.put(`${URLS.CREATE_MEAL_RULE}${id}/`, data),
  };
}

export function updateAdvancedPayrollMeal(data, id) {
  return {
    type: UPDATE_ADVANCED_PAYROLL_MEAL,
    payload: axios.put(`${URLS.CREATE_ADVANCED_SETTINGL_CONFIG}${id}/`, data),
  };
}

// Reducer for delete Meal
export function deleteMeal(id) {
  return {
    type: DELETE_MEAL,
    payload: axios.delete(`${URLS.CREATE_MEAL_RULE}${id}/`),
  };
}

export function deleteAdvancedPayrollMeal(id) {
  return {
    type: DELETE_ADVANCED_PAYROLL_MEAL,
    payload: axios.delete(`${URLS.CREATE_ADVANCED_SETTINGL_CONFIG}${id}/`),
  };
}

export function getMealList(data) {
  return {
    type: GET_MEALS,
    payload: axios.get(`${URLS.CREATE_MEAL_RULE}?payroll_setting_id=${data}`),
  };
}

export function getAdvancedPayrollMealList(data) {
  return {
    type: GET_ADVANCED_PAYROLL_MEALS,
    payload: axios.get(`${URLS.CREATE_ADVANCED_SETTINGL_CONFIG}?payroll_setting_id=${data}`),
  };
}

export function getMealDetails(params) {
  return {
    type: GET_MEAL_DETAILS,
    payload: axios.get(`${URLS.CREATE_MEAL_RULE}${params}/`),
  };
}

export function getAdvancedPayrollMealDetails(params) {
  return {
    type: GET_ADVANCED_PAYROLL_MEAL_DETAILS,
    payload: axios.get(`${URLS.CREATE_ADVANCED_SETTINGL_CONFIG}${params}/`),
  };
}

export function resetAdvancePayrollDetail(data) {
  return {
    type: RESET_ADVANCE_PAYROLL_DETAIL,
    payload: data,
  };
}

export function resetPayrollDetail(data) {
  return {
    type: RESET_PAYROLL_DETAIL,
    payload: data,
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
  all: [],
  mealsList : {},
  mealDetail:{},
  deletePayroll: {},
  advancedPayrollList : [],
  advancedPayrolldetail: {},
  advancedPayrollMealsList: {},
  advancedPayrollMealDetail: {},
  advancedPayrollCount : null
};

function GET_PAYROLL_SETTINGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ALL_PAYROLL_SETTINGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollList: action.payload.results ? action.payload.results : action.payload,
    advancedPayrollCount : action.payload.count
  });
}

function POST_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrolldetail: action.payload,
  });
}

function CREATE_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
   /* detail: action.payload,*/
  });
}

function CREATE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollMealDetail: action.payload,
  });
}

function GET_ADVANCED_PAYROLL_SETTING_DETAIL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrolldetail: action.payload,
  });
}

function UPDATE_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
  /*  detail: action.payload,*/
  });
}

function UPDATE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollMealDetail: action.payload,
  });
}


// Fulfilled reducer for Delete Meal

function DELETE_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
   /* detail: action.payload,*/
  });
}

function DELETE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollMealDetail: action.payload,
  });
}


function GET_MEALS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    mealsList: action.payload,
  });
}

function GET_ADVANCED_PAYROLL_MEALS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollMealsList: action.payload,
  });
}


function GET_MEAL_DETAILS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    mealDetail: action.payload,
  });
}

function GET_ADVANCED_PAYROLL_MEAL_DETAILS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrollMealDetail: action.payload,
  });
}

function POST_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrolldetail: action.payload,
  });
}

function RESET_ADVANCE_PAYROLL_DETAIL_REDUCER(state, action) {
  return Object.assign({}, state, {
    advancedPayrolldetail: {},
  });
}

function RESET_PAYROLL_DETAIL_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

const handlers = {
  [GET_PAYROLL_SETTINGS_FULFILLED]: GET_PAYROLL_SETTINGS_FULFILLED_REDUCER,
  [GET_ALL_PAYROLL_SETTINGS_FULFILLED]: GET_ALL_PAYROLL_SETTINGS_FULFILLED_REDUCER,
  [GET_PAYROLL_SETTING_FULFILLED]: GET_PAYROLL_SETTING_FULFILLED_REDUCER,
  [POST_PAYROLL_SETTING_FULFILLED]: POST_PAYROLL_SETTING_FULFILLED_REDUCER,
  [PUT_PAYROLL_SETTING_FULFILLED]: PUT_PAYROLL_SETTING_FULFILLED_REDUCER,
  [CREATE_MEAL_FULFILLED]: CREATE_MEAL_FULFILLED_REDUCER,
  [GET_MEALS_FULFILLED] : GET_MEALS_FULFILLED_REDUCER,
  [GET_MEAL_DETAILS_FULFILLED] : GET_MEAL_DETAILS_FULFILLED_REDUCER,
  [UPDATE_MEAL_FULFILLED] : UPDATE_MEAL_FULFILLED_REDUCER,
  [DELETE_MEAL_FULFILLED] : DELETE_MEAL_FULFILLED_REDUCER,
  [POST_ADVANCED_PAYROLL_SETTING_FULFILLED] : POST_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER,
  [GET_ADVANCED_PAYROLL_SETTING_FULFILLED] : GET_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER,
  [UPDATE_ADVANCED_PAYROLL_MEAL_FULFILLED] : UPDATE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER,
  [CREATE_ADVANCED_PAYROLL_MEAL_FULFILLED] : CREATE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER,
  [DELETE_ADVANCED_PAYROLL_MEAL_FULFILLED] : DELETE_ADVANCED_PAYROLL_MEAL_FULFILLED_REDUCER,
  [GET_ADVANCED_PAYROLL_MEALS_FULFILLED] : GET_ADVANCED_PAYROLL_MEALS_FULFILLED_REDUCER,
  [GET_ADVANCED_PAYROLL_MEAL_DETAILS_FULFILLED] : GET_ADVANCED_PAYROLL_MEAL_DETAILS_FULFILLED_REDUCER,
  [PUT_ADVANCED_PAYROLL_SETTING_FULFILLED] : PUT_ADVANCED_PAYROLL_SETTING_FULFILLED_REDUCER,
  [GET_ADVANCED_PAYROLL_SETTING_DETAIL_FULFILLED] : GET_ADVANCED_PAYROLL_SETTING_DETAIL_FULFILLED_REDUCER,
  [RESET_ADVANCE_PAYROLL_DETAIL]: RESET_ADVANCE_PAYROLL_DETAIL_REDUCER,
  [RESET_PAYROLL_DETAIL]: RESET_PAYROLL_DETAIL_REDUCER,
};


const payrollSettingSelector = state => state.payrolls.setting;

export const payrollSettingList = createSelector(
  payrollSettingSelector,
  instance => instance.list,
);

export const payrollSettingDetail = createSelector(
  payrollSettingSelector,
  instance => instance.detail,
);

export const advancedPayrollSettingList = createSelector(
  payrollSettingSelector,
  instance => instance.advancedPayrollList,
  );

export const advancedPayrollCount = createSelector(
  payrollSettingSelector,
  instance => instance.advancedPayrollCount,
  );


export const advancedPayrollSettingDetail = createSelector(
  payrollSettingSelector,
  instance => instance.advancedPayrolldetail,
);

export const payrollSettingAll = createSelector(
  payrollSettingSelector,
  instance => instance.all
);

export const mealList = createSelector(
  payrollSettingSelector,
  instance => instance.mealsList
);

export const advancedPayrollMealsList = createSelector(
  payrollSettingSelector,
  instance => instance.advancedPayrollMealsList
);

export const mealDetails = createSelector(
  payrollSettingSelector,
  instance => instance.mealDetail
);

export const advancedPayrollMealDetails = createSelector(
  payrollSettingSelector,
  instance => instance.advancedPayrollMealDetail
);

export default createReducer(defaultState, handlers);
