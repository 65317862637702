import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as SubCompanyDucks from 'ducks/vendors/subCompany';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';

import VendorSubCompanyUserFormSection from 'sections/vendors/subCompanyUserForm/Form';
import WizardSection from 'sections/common/Wizard'

import { NAV } from 'constants/vendors';

/**
 * VendorSubCompanyUserFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link CompanyFilterSection}
 *    - {@link CompanyListSection}
 *
 * Fetch Needs:
 *    None
 */
class VendorSubCompanyUserFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isCompany: false, isCompanyUser: true, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    
    const cookieKey = cookie.load("vendor_sub_company_user_add");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_sub_company_user_add", !isInfoOpen);
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isLoading: true });
    Promise.all([
      this.props.SubCompanyDucks.getSubCompany({ id: params.id }),
      this.props.InfoDucks.getInformations({route: 'vendor.sub_company_user.add'}),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, isCompany, isCompanyUser, isInfoOpen} = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout
        navInfo={NAV.VendorSubCompanyUserFormPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <WizardSection isStep1={isCompany} isStep2={isCompanyUser} />
        <VendorSubCompanyUserFormSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  SubCompanyDucks: bindActionCreators(SubCompanyDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

VendorSubCompanyUserFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorSubCompanyUserFormPage);
