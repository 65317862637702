const baseNav = [{ href: "/dashboard", label: "Dashboard" }];

export const NAV = {
  FieldFormsPage: {
    title: "Field Forms",
    breadcrumb: [
      ...baseNav,
      { href: "/forms/fieldforms", label: "field forms" },
      { label: "list" },
    ],
    parent: "/dashboard",
    description: "",
  },
  SystemFormsPage: {
    title: "System Forms",
    breadcrumb: [...baseNav, { label: "System Forms" }],
    parent: "/dashboard",
    description: "",
  },
  TrainingPage: {
    title: "Training Forms",
    breadcrumb: [...baseNav, { label: "Training Forms" }],
    parent: "/dashboard",
    description: "",
  },
  CreateFieldFormsPage: {
    title: "Create Field Form",
    breadcrumb: [...baseNav, { label: "Create Field Form" }],
    parent: "/forms/fieldforms",
    description: "View and manage field form",
  },
  EditedFieldFormsPage: {
    title: "Edit Field Form",
    breadcrumb: [...baseNav, { label: "Edit Field Form" }],
    parent: "/forms/fieldforms",
    description: "View and manage field form",
  },
  CreateAssessmentFormsPage: {
    title: "Create Training Form",
    breadcrumb: [...baseNav, { label: "Create Training Form" }],
    parent: "/learning/setup/assessments",
    description: "View and manage training form",
  },
  EditedAssessmentFormsPage: {
    title: "Edit Training Form",
    breadcrumb: [...baseNav, { label: "Edit Training Form" }],
    parent: "/learning/setup/assessments",
    description: "View and manage training form",
  },
  AnswersList: {
    title: "Answers List",
    breadcrumb: [...baseNav, { label: "Answers List" }],
    parent: "/forms/fieldforms",
    description: "View form responses submitted by employees",
  },
};

export const FORMS_FILTER_OPTIONS = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

export const FORMS_QUESTIONS_TYPE = {
  SINGLE_SELECT: "Single Select",
  MULTI_SELECT: "Multi Select",
  DATE: "Date",
  NUMBER: "Number",
  IMAGE_UPLOAD: "Image Upload",
  SIGNATURE: "Signature",
  FILE_UPLOAD: "File Upload",
  FREE_TEXT: "Text",
};

export const STATUS_OPTIONS = [
  { value: "", label: "All" },
  { value: "NEW", label: "New" },
  { value: "REVIEWED", label: "Reviewed" },
  { value: "APPROVED", label: "Approved" },
];


export const status_options = [
  { value: "NEW", label: "New" },
  { value: "REVIEWED", label: "Reviewed" },
  { value: "APPROVED", label: "Approved" },
];

export const NOTIFICATION_TYPE = [
  { name: "App Notification", checked: false, id: "PUSH" },
];

export const Response_Count_Circle_Colors = {
  APPROVED:"green",
  REVIEWED:"#90EE90",
  NEW:"#088F8F",
}

export default { NAV, FORMS_FILTER_OPTIONS, STATUS_OPTIONS };
