import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip} from 'reactstrap';

import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';

import InfoGreyIconAtom from 'atoms/InfoIcon';

import { FORM_LAYOUT } from 'constants/layout';

import AdvancePayrollTableComponent from './advancePayrollTable';
import OvertimeRuleComponent from './OvertimeRuleComponent';

import ToggleAtom from 'atoms/Toggle';
import DateTimePickerAtom from 'atoms/DateTimePicker';

import * as RouteDucks from 'ducks/routes';

/**
 * PayrollSettingFormPage -> PayrollSettingFormSection -> PayrollSettingFormComponent
 *
 * Props:
 *    createOrUpdate function
 */

const PayrollSettingFormComponent = (props) => {

  const { handleSubmit, error, createOrUpdate,  timeFormat, timeFormat24Hrs,  californiaRuleMessage, initialValues, userCompany , OvertimeInfo, toggleOvertimeAccordian, mealBreakInfo, toggleMealBreakAccordian,toggleAddRuleModal ,  mealRuleList, showAdvancedSettings, isAdvancedSettingsVisible,
    payroll_id, params, openDeleteFormModal, openEditForm, mealList, openAddRuleModal, isEditPayroll, showPayrollDetails, userExtraData , dateFormat, advancedPayrollModalType } = props;
  const submit = (data) => {
    const detail = {
      ...data,
      is_active : props.isActivePayroll,
      start_date : moment(data.start_date).format('YYYY-MM-DD'),
      cal_7_day_rule_message : californiaRuleMessage,
      first_meal_threshold: moment.duration(+data.hour || 0, 'hours').asSeconds() +
        moment.duration(+data.minute || 0, 'minutes').asSeconds(),
      meal_waiver_value: moment.duration(+data.wavier_hour || 0, 'hours').asSeconds() +
        moment.duration(+data.wavier_minute || 0, 'minutes').asSeconds(),
      daily_split: data.daily_split,
      week_overtime_threshold: { hours: data.week_overtime_hours ? (+data.week_overtime_hours) : 0, minutes: data.week_overtime_minutes ? (+data.week_overtime_minutes) : 0},
      daily_overtime_threshold: { hours: data.daily_overtime_hours ? (+data.daily_overtime_hours) : 0, minutes: data.daily_overtime_minutes ? (+data.daily_overtime_minutes) : 0},
      daily_double_overtime_threshold: { hours: data.daily_double_overtime_hours ? (+data.daily_double_overtime_hours) : 0, minutes: data.daily_double_overtime_minutes ? (+data.daily_double_overtime_minutes) : 0},
      work_day_start_time: data.work_day_start_time && data.daily_split ? (moment(data.work_day_start_time).format('HH:mm')) : null,
      company_id: userCompany && userCompany.id,
    };
    if(data.daily_split){
      if(moment(data.work_day_start_time).isValid()){
        detail.work_day_start_time = moment(data.work_day_start_time).format('HH:mm');
      }
      else detail.work_day_start_time = data.work_day_start_time;
    }
    else{
      detail.work_day_start_time = null;
    }
    return createOrUpdate(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  };

  return (
    <Form onSubmit={handleSubmit(submit)} noValidate className="pl-3 pr-4">
      {error && <div className="error-text">{error}</div>}
      <Row>
        <Col md={isEditPayroll ? "12" : "6"}>
          <Field
            id="name"
            name="name"
            label={
              <div>
                Name
              </div>
            }
            component={InputAtom}
            layout={FORM_LAYOUT}
            placeholder="Name"
          />
          {initialValues.is_default !== true ?
            <Row>
              <Col md={8} className="corner-round">
                <Field
                  id="start_date"
                  name="start_date"
                  label="Start Date"
                  layout={FORM_LAYOUT}
                  placeholder="Start Date"
                  component={DateTimePickerAtom}
                  options={{
                    format: dateFormat,
                    altFormat: dateFormat,
                    altInput: true
                  }}
                  disabled={initialValues.is_default}
                />
              </Col>
            </Row>
            :
            null
          }
          <Row>
            <Col>
              <Field
                id="active"
                type="checkbox"
                name="active"
                label={
                  <div>
                    Is Active?
                  </div>
                }
                component={CheckboxAtom}
                layout={FORM_LAYOUT}
                checked={props.isActivePayroll}
                onClick={(val)=>props.isPayrollActive(val)}
                disabled={initialValues.is_default}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <OvertimeRuleComponent
        initialValues={initialValues}
        toggle={props.toggleModal}
        createOrUpdate={createOrUpdate}
        californiaRuleMessage={californiaRuleMessage}
        timeFormat={timeFormat}
        timeFormat24Hrs={timeFormat24Hrs}
        userCompany={userCompany}
        OvertimeInfo={OvertimeInfo}
        toggleOvertimeAccordian={toggleOvertimeAccordian}
        toggleMealBreakAccordian={toggleMealBreakAccordian}
        mealBreakInfo={mealBreakInfo}
        toggleAddRuleModal={toggleAddRuleModal}
        openAddRuleModal={openAddRuleModal}
        mealsList={mealList}
        mealRuleList={mealRuleList}
        openEditForm={openEditForm}
        openDeleteFormModal={openDeleteFormModal}
        params={params}
        payroll_id={payroll_id}
        key={props.form}
        newLayout = {props.newLayout}
        form={props.form}
        disableOvertime={true}
      />
    </Form>
  );
};


PayrollSettingFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const payrollForm = reduxForm({
})(PayrollSettingFormComponent);

export default connect(
 
)(payrollForm);


