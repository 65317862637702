import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import moment from 'moment';

const EVENT_TYPES = {
  GET_COMPANY_DETAILS_SUCCESS : 'GET_COMPANY_DETAILS_SUCCESS',
  SET_COMPANY_DETAILS_SUCCESS : 'SET_COMPANY_DETAILS_SUCCESS',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  INFO_LIST: 'INFO_LIST',
  SET_DROPDOWN_VALUES : 'SET_DROPDOWN_VALUES',
  GET_COMPANY_PLAN_SUCCESS : 'GET_COMPANY_PLAN_SUCCESS',
  SHOW_AUDIT_LOGS_LOADER: 'SHOW_AUDIT_LOGS_LOADER',
  HIDE_AUDIT_LOGS_LOADER: 'HIDE_AUDIT_LOGS_LOADER',
  AUDIT_LIST: 'AUDIT_LIST',
};

class planFormUsecase {
 
  billApiGateway = null;
  

  constructor(billApiGateway) {
    this.billApiGateway = billApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'planFormUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }


  setup_data(data, trial_end_date, suspension_date, closing_date) {
    this.trial_end_date = trial_end_date;
    this.suspension_date = suspension_date;
    this.closing_date = closing_date;
    this.plan_type = data.plan_type.value;
    this.id = data.company_id;
  }

  
  async getCompanyAccountDetails(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.accountDetails = await this.billApiGateway.getCompanyAccountDetails(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_COMPANY_DETAILS_SUCCESS,
      data: this.accountDetails,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });

    /*this.billApiGateway.getCommercialStatusList({entity : 'companyprofile', paginate: false})*/
      /*.then((list) => {
          this.CommercialStatusList = list.results;
          this.eventEmitter.emit({
            type: EVENT_TYPES.SET_DROPDOWN_VALUES,
            data: this.CommercialStatusList
          });     
          this.eventEmitter.emit({
              type: EVENT_TYPES.HIDE_LOADER,
            });
          }); */
    }

  async getInfoList(){
    this.infoList = await this.billApiGateway.getInfoList({ route: 'vendor.account'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async execute(companyID) {
    let errors = {};
    if (!this.trial_end_date) {
      errors.trial_end_date = 'Enter Trial End Date';
    }
    if(this.trial_end_date && this.suspension_date && 
      (moment(this.suspension_date) < moment(this.trial_end_date))
      ){
      errors.trial_end = 'Trial end date should be less than suspension date';
    }
    if(this.suspension_date && this.closing_date && 
      (moment(this.suspension_date) > moment(this.closing_date))
      ){
      errors.closing_date = 'Closing date should be greater than suspension date';
    }

    if(this.suspension_date && this.closing_date && 
      (this.suspension_date === this.closing_date)
      ){
      errors.closing_date = 'Closing date should not be the same as suspension date';
    }

    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    let data = {
      trial_end: this.trial_end_date,
      suspension_date: this.suspension_date,
      close_date: this.closing_date,
    };

    this.billApiGateway.updatePlanTypeDetails(this.plan_type, this.id);
    await this.billApiGateway.updateAccountDetails(data, companyID);
    
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_COMPANY_DETAILS_SUCCESS,

    });
  }

  async getAuditLogs(id){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_AUDIT_LOGS_LOADER,
    });
    this.auditLogsList = await this.billApiGateway.getAuditLogs({ entity_id: JSON.stringify([id]), entity_type: JSON.stringify(['customer']), paginate: false });
    this.eventEmitter.emit({
      type: EVENT_TYPES.AUDIT_LIST,
      auditLogsList: this.auditLogsList,
    });
  }
}

export default planFormUsecase;