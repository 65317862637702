import React from 'react';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as AddressDucks from 'ducks/vendors/address';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as UserDucks from 'ducks/accounts/user';

import VendorFormComponent from 'components/vendors/form/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/vendors';
import VerificationComponent from 'components/vendors/form/verificationModal';


/**
 * VendorFormPage -> VendorFormSection
 *
 * Components:
 *    - {@link VendorFormComponent}
 *
 * State:
 *    - vendorDetail
 *
 * Actions:
 *    - Form:
 *        - putVendor
 */
class VendorSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.onCountrySelect = this.onCountrySelect.bind(this);
    this.onMailingCountrySelect = this.onMailingCountrySelect.bind(this);
    this.state = { timezones: moment.tz.names(),
      isOpen: false, isSectionLoading: false, mediaType: ''};
    this.toggle = this.toggle.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.submitOtp = this.submitOtp.bind(this);
    this.getProfile =  this.getProfile.bind(this);
    this.updatePhone = this.updatePhone.bind(this);
  };

  onCountrySelect(country) {
    return this.props.AddressDucks.getStates({ country_id: country });
  }

  onMailingCountrySelect(country) {
    return this.props.AddressDucks.getMailingStates({ country_id: country });
  }

  update(userData, formData,companyId, hasImage) {
    if(hasImage){
      return this.props.VendorDucks.putVendor(userData, companyId)
        .then(() => {
          this.props.VendorDucks.patchVendor(formData, companyId)
            .then(() => {
              this.props.VendorDucks.getVendor({ id: companyId });
              toast.success(MSGS.VENDOR_UPDATE_SUCCESS);
            });
        });
    }else{
      return this.props.VendorDucks.putVendor(userData, companyId)
        .then(() => {
          toast.success(MSGS.VENDOR_UPDATE_SUCCESS);
        });
    }
  }

  updatePhone(data, companyId){
    return this.props.VendorDucks.patchVendor(data, companyId)
      .then(() => {
          this.props.VendorDucks.getVendor({ id: companyId });
          toast.success(MSGS.VENDOR_PHONE_UPDATE_SUCCESS);
        });
  }

  resendOtp(media){
      this.setState({ mediaType: media });
      return this.props.VendorDucks.resendOtp()
        .then(() => this.toggle())   
  }  

  toggle(){
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }


  submitOtp(data){
    const { vendorDetail } = this.props;
    const { mediaType } = this.state;
    return this.props.VendorDucks.verifyOtp({ ...data, media: mediaType })
      .then(() => {
        this.toggle();
        toast.success('OTP Successfully verified !');
        this.setState({ isSectionLoading: true });
        Promise.all([
          this.props.VendorDucks.getVendor({ id: vendorDetail.id }),
          this.props.UserDucks.getProfileUser(),
        ])
          .then(() => {
            this.setState({ isSectionLoading: false });
          });
      })
  }

  
  getProfile(){
    return this.props.UserDucks.getProfileUser();
  }

  render() {
    const {
      profile,
      countriesList,
      industriesList,
      isLoading,
      mailingStatesList,
      statesList,
      vendorDetail,
    } = this.props;
    const { timezones , isOpen, isSectionLoading } = this.state;
    if (isLoading || isSectionLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <VendorFormComponent
          profile={profile}
          countriesList={countriesList}
          industriesList={industriesList}
          initialValues={vendorDetail}
          mailingStatesList={mailingStatesList}
          statesList={statesList}
          timezonesList={timezones}
          onCountrySelect={this.onCountrySelect}
          onMailingCountrySelect={this.onMailingCountrySelect}
          update={this.update}
          resendOtp={this.resendOtp}
          updatePhone={this.updatePhone}
        />
        {isOpen && <VerificationComponent
            isOpen={isOpen}
            vendorDetail = { vendorDetail }
            toggle={this.toggle}
            submitOtp={this.submitOtp}
        />}
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  profile: UserDucks.profile(state),
  vendorDetail: VendorDucks.vendorDetail(state),
  countriesList: AddressDucks.countriesList(state),
  industriesList: VendorDucks.industriesList(state),
  statesList: AddressDucks.statesList(state),
  mailingStatesList: AddressDucks.mailingStatesList(state),
});

const mapActionToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
  AddressDucks: bindActionCreators(AddressDucks, dispatch),
});

VendorSection.propTypes = {
  countriesList: PropTypes.array.isRequired,
  industriesList: PropTypes.array.isRequired,
  mailingStatesList: PropTypes.array.isRequired,
  statesList: PropTypes.array.isRequired,
  vendorDetail: PropTypes.object.isRequired,
  VendorDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
  mapActionToProps,
)(VendorSection);
