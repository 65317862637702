import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import MatchWhenUnauthorized from 'pages/MatchWhenUnauthorized';

import SurveyListPage from 'surveys/pages/list';
import SurveyFormPage from 'surveys/pages/surveyForm';
import SurveyFormQuestionsPage from 'surveys/pages/surveyFormQuestions';
import SurveyFormOutcomesPage from 'surveys/pages/surveyFormOutcomes';
import SurveyFormEditPage from 'surveys/pages/surveyFormEdit';
import SurveyTemplateListPage from 'surveys/pages/surveyTemplateList';

const SurveyRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url}/>
      <MatchWhenAuthorized
        path={`${match.url}/list`}
        name="surveys.list"
        component={SurveyTemplateListPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/add`}
        name="surveys.add"
        component={SurveyFormPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/:id/questions`}
        name="surveys.questions"
        component={SurveyFormQuestionsPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/:id/outcomes`}
        name="surveys.outcomes"
        component={SurveyFormOutcomesPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/:id/edit`}
        name="surveys.edit"
        component={SurveyFormEditPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/dashboard`}
        name="surveys.dashboard"
        component={SurveyListPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

SurveyRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SurveyRoutes;