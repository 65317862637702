import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';

import AllBillsPage from 'payments/bills/pages/list';
import BillFormPage from 'payments/bills/pages/form';

const BillRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="bill.list"
        component={AllBillsPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/:tab`}
        name="bill.edit"
        component={BillFormPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

BillRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BillRoutes;