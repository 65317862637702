import React from 'react';

import PropTypes from 'prop-types';
import { Button, Row, Col, Table } from 'reactstrap';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DeleteIconAtom from 'atoms/DeleteIcon';
import moment from 'moment-timezone';
import CardExpiryNotification from 'components/common/CardExpiryNotification/cardExpiryNotification';

class CardTableComponent extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {  selectedCard: props.defaultCard, isLoading: false, isModalOpen: false };
    this.selectCard = this.selectCard.bind(this);
    this.changeCard = this.changeCard.bind(this);
    this.deleteCard = this.deleteCard.bind(this);
    this.showWarning = this.showWarning.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { isModalOpen } = this.state;
    this.setState({
      isModalOpen: !isModalOpen
    })
  }

  showWarning(){
    return toast.error('Cannot add more than 10 cards');
  }

  selectCard(card){
    this.setState({ selectedCard: card });
  }

  changeCard(){
    const { selectedCard } = this.state;
    const { usecase, profile } = this.props;
    this.setState({ isLoading: true });
    usecase.setDefault(
      profile.company.id,
      selectedCard.src_id,
      profile.company.email,
      profile.company.name,
      profile.company.phone_no
    )
    .then(() => this.setState({ isLoading: false }));
  }

  deleteCard(sourceId){
    const { usecase, data, companyId, commercialStatus } = this.props;
    if(data.length === 1 && (commercialStatus !== 'Trial Period')){
      return toast.error('This card has been set as default. You cannot delete this card.');
    }
    return usecase.deleteCard(companyId, sourceId);
  }

  render(){
    const { data, updateState, isAddCardModalOpen , subscriptionToggle, planType } = this.props;
    const { selectedCard, isLoading } = this.state;
    const expiryMonth = data && data.exp_month;
    const expiryYear = data && data.exp_year;
    const currentMonth = moment().add(1, 'months');
    const startOfMonth = moment(currentMonth).startOf('month');
    const currentYear = moment();
    const expiryDate = moment(startOfMonth).subtract(5,'d');
    return (
      <div>
        <Row className='mt-3 ml-3 mr-4'>
            <Col md={12} className ="mt-0 px-0">
            {expiryYear===currentYear && expiryMonth===currentMonth && moment() >= expiryDate && moment() < startOfMonth &&
              <CardExpiryNotification
                customerDetail = { data }/>
            }
              <span className="h5"> Your Saved Cards</span>
              <Button
                onClick={() => data.length <10 ? updateState('isAddCardModalOpen', !isAddCardModalOpen) : this.showWarning()}
                type="button"
                className="float-right btn-width ml-2"
                size="sm"
              >
                Add Card
              </Button>
              {data && data.length > 0 &&
                <Button
                  color="primary"
                  className="float-right btn-width ml-2"
                  onClick={() => this.changeCard()}
                  size="sm"
                >
                Change Card <ButtonLoaderAtom active={isLoading} />
                </Button>
              }
            </Col>
          </Row>
          {planType === 'online' &&
          <Row className="mr-4">
            <Col md={12} className="pr-0">
              <span className={`${styles['cancel_subscription']} float-right mt-2 mb-1`}
                onClick={() => subscriptionToggle()}>
                Cancel Subscription
              </span>
            </Col>
          </Row>
          }
          <Row className="mt-2 ml-3 mr-4 pb-5">
            <Col md={12} className="px-0">
              <Table responsive hover size="sm" className='mt-0' >
                <thead className="Header-col">
                  <tr>
                    <th className="pl-3" />
                    <th className="text-center">Action</th>
                    <th>Card</th>
                    <th>Type</th>
                    <th className="text-right pr-3">Expires on</th>
                  </tr>
                </thead>
                <tbody>
                  { data && data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="pl-3">
                          <input
                            id={item.src_id}
                            type="radio"
                            checked={item.src_id === selectedCard.src_id}
                            onChange={() => this.selectCard(item)}
                          />
                        </td>
                        <td title="Delete" className="text-center">
                          <DeleteIconAtom
                            className="cursor-pointer"
                            onClick={() => this.deleteCard(item.src_id)}
                          />
                        </td>
                        <td title={`Card ending in ${item.last4}`}>
                          Card ending in {item.last4}
                        </td>
                        <td title={item.brand}>{item.brand}</td>
                        <td title={`${item.exp_month}/${item.exp_year}`} className="text-right pr-3">
                          {item.exp_month}/{item.exp_year}
                        </td>
                      </tr>
                    )})
                  }
                  {!(data && data.length > 0) &&
                    <tr><td colSpan="5" className='text-center'> No Records Found </td></tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      );
    }
  };

CardTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  updateSelected: PropTypes.func,
};

export default CardTableComponent;
