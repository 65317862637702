 /*import  GraniteError from 'lib/GraniteError'; */
class SurveyEntity {
  constructor(object={}){
  	this.question = object.question;
  	this.definition = object.definition;
  	this.response = object.response;
  	this.sequence_of_configuration = object.sequence_of_configuration;
  	this.behaviour = object.behaviour;

    this.answer = object.answer && object.answer.length && object.answer.map((newA)=> {
       return {...newA , checked : object.response.find((j)=>j.value == newA.value) ? true : false} 
    })
 }
}

export default SurveyEntity;


