import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';
import { toTitleCase } from 'helpers/utils';
import styles from './styles.module.scss';

import DragIcon from 'components/vendors/Drag.png'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * VendorDepartmentListPage -> VendorDepartmentListSection -> VendorDepartmentTeamTableComponent
 *
 * Props:
 *    - departmentTeamList
 */
const VendorDepartmentTeamTableComponent = (props) => {

  const {toggleTeamForm, deleteTeam, type, teamArray, isLoading } = props;

  return (
    <Table hover responsive size="sm" className={`${styles['team-table']} m-0`}>
      <thead className={styles['table-nested']}>
        <tr className="ml-3">
          <th className="text-center" width="5%">&nbsp;</th>
          <th className="text-center" width="20%">Action</th>
          <th >Team Name</th>
          <th width="40%">Team Description</th>
          <th className="text-center">Is Active</th>
        </tr>
      </thead>
      {isLoading ? <tbody className={styles['table-nested']}><tr><td className="text-center" colSpan="5">Loading...</td></tr></tbody> :
        <Droppable droppableId={type} type={`droppableSubItem-${type}`}>
          {(provided, snapshot) => (
            <tbody ref = {provided.innerRef} {...provided.droppableProps} className={styles['table-nested']}>
              {teamArray && teamArray.map((item, idx) => {
                return (
                  <Draggable
                    draggableId={item.id.toString()+'n'}
                    index={idx}
                    key={item.id}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref = {provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        key={item.id}
                      >
                        <td width="5%" className="text-center"><img className={`${styles['drag-icon-hover']}`} src={DragIcon} width="20" height="20" alt="dragIcon"/></td>
                        <td  className="text-center" title="Edit Team">
                          <EditIconAtom
                            className="cursor-pointer"
                            onClick={() => toggleTeamForm(item)}
                          />
                          <span title="Delete Team" role="button" className="ml-2">
                          <DeleteIconAtom
                            className="cursor-pointer"
                            onClick={() => deleteTeam(item)}
                          />
                          </span>
                        </td>
                        <td title={item.name}>{item.name}</td>
                        <td title={item.description}>{item.description}</td>
                        <td className="text-center"><ActiveIconAtom active={item.is_active} /></td>
                      </tr>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
              {teamArray && !teamArray.length &&
                <tr><td className="text-center" colSpan="5">No Records Found</td></tr>
              }
            </tbody>
          )}
        </Droppable>
      }
    </Table>
  );
}

VendorDepartmentTeamTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  toggleTeamForm: PropTypes.func.isRequired,
};

export default VendorDepartmentTeamTableComponent;
