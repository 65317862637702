import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { UncontrolledDropdown } from 'reactstrap/lib/Uncontrolled';

import { toast } from 'react-toastify';

import * as MissingEntryDucks from 'ducks/activities/missingEntry';
// import * as SessionDucks from 'ducks/activities/session';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as TaskDucks from 'ducks/jobs/task';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as UserDucks from 'ducks/accounts/user';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ActivitySplitMealPeriodComponent from 'components/activities/activitySplitForm/MealPeriod';
import ActivitySplitPunchInComponent from 'components/activities/activitySplitForm/PunchIn';
import ActivitySplitPunchOutComponent from 'components/activities/activitySplitForm/PunchOut';
import TaskComponent from 'components/activities/activitySplitForm/Task';
import ConfirmationDeleteComponent from 'components/activities/activitySplitForm/ConfirmationModal';
import AllocateTaskModalComponent from 'components/activities/activitySplitForm/AllocateTaskModal';
import AllocateMealModalComponent from 'components/activities/activitySplitForm/AllocateMealModal';
import CommentsComponent from 'components/activities/activitySplitForm/Comments';
import AllocateTaskPopupSection from 'components/timecards/dailyTimecardList/AllocateTaskPopup';
import AddNewActivityModal from 'components/activities/activitySplitForm/AddNewActivityModal';

import { MSGS } from 'constants/activities';
import styles from './styles.module.scss';

/*
 *
 * ActivitySplitFormPage -> ActivitySplitTimelineSection
 *
 * Components:
 *    - {@link ManualSessionMealPeriodComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - addSplitMissingEntry
 *    - updateSplitMissingEntry
 *    - removeSplitMissingEntry
 *    - postSession
 */

let keys = ['start_datetime', 'start_datetime_tz', 'end_datetime', 'end_datetime_tz']
class ActivitySplitTimelineSection extends React.Component {
  static isSessionValidated(activityList, selectedShift, shiftArr) {
    /*for (let i = 0; i < activityList.length; i += 1) {
      if (moment(activityList[i].start_datetime) > moment(activityList[i].end_datetime)) {
        toast.error('End datetime must be greater than start datetime');
        return false;
      }
      if (moment(activityList[i].start_datetime) < moment(shiftArr.start_datetime) ||
        moment(activityList[i].start_datetime) > moment(shiftArr.end_datetime) ||
        moment(activityList[i].end_datetime) < moment(shiftArr.start_datetime) ||
        moment(activityList[i].end_datetime) > moment(shiftArr.end_datetime)) {
        if(activityList[i].activity_code === 'MEAL-PERIOD'){
          toast.error('Meal-Period must be between Punch-In session');
        }
        else{
          toast.error('Time allocated for the task should be within the Punch-In session')
        }
        return false;
      }
    }*/
    return true;
  }

  constructor(props) {
    super(props);
    this.addMealPeriodEntry = this.addMealPeriodEntry.bind(this);
    this.editEntry = this.editEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.editTask = this.editTask.bind(this);
    this.removeTask = this.removeTask.bind(this);
    this.setPunchInDate = this.setPunchInDate.bind(this);
    this.setPunchOutDate = this.setPunchOutDate.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.getTasksForJob = this.getTasksForJob.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.setSelectedShift = this.setSelectedShift.bind(this);
    this.saveTask = this.saveTask.bind(this);
    this.initiateSelectedShiftValues = this.initiateSelectedShiftValues.bind(this);
    this.state = { savedId: null, editActivityEntry : null, isMealModalOpen: false, selectedShift: null, deleteId: null, PunchOutDateTime : null , PunchInDateTime : null,
         commentSubmitting: false, isOpen: false, jobTasklist: [], isDeleting: false, isDeleteModalOpen: false,isNewActivityModalOpen: false,};
    this.setActivitySplits = this.setActivitySplits.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.goTo = this.goTo.bind(this);
    this.resetJobList= this.resetJobList.bind(this);
    this.saveValidity = this.saveValidity.bind(this);
    this.toggleMealModal = this.toggleMealModal.bind(this);
    this.addMealToDT = this.addMealToDT.bind(this);
    this.editTaskEntry = this.editTaskEntry.bind(this);
    this.toggleAddNewActivityModal = this.toggleAddNewActivityModal.bind(this);
    this.browserParseDate = this.browserParseDate.bind(this);
    this.browserParseDateISO = this.browserParseDateISO.bind(this);
    this.userParseDate = this.userParseDate.bind(this);
    this.transformPropDates = this.transformPropDates.bind(this);
    this.transformValue = this.transformValue.bind(this);
    this.modifiedProps = this.transformPropDates(props);
  }

  browserParseDate(dateString) {
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment.tz(dateString, userTimezone);
    const newMoment = moment.tz([
    momentWithBrowserTimezone.year(),
    momentWithBrowserTimezone.month(),
    momentWithBrowserTimezone.date(),
    momentWithBrowserTimezone.hour(),
    momentWithBrowserTimezone.minute(),
    momentWithBrowserTimezone.second(),
    momentWithBrowserTimezone.millisecond(),
    ], moment.tz.guess());
    return newMoment;
    }

    browserParseDateISO(dateString, browserTimezone) {
      return browserTimezone
      ? this.browserParseDate(dateString).toISOString()
      :  this.userParseDate(dateString).toISOString();
    }
    
    userParseDate(dateString){
    // convert browser timezone date to user timezone date
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment(dateString);
    const newMoment = moment.tz([
    momentWithBrowserTimezone.year(),
    momentWithBrowserTimezone.month(),
    momentWithBrowserTimezone.date(),
    momentWithBrowserTimezone.hour(),
    momentWithBrowserTimezone.minute(),
    momentWithBrowserTimezone.second(),
    momentWithBrowserTimezone.millisecond(),
    ], userTimezone);
    return newMoment;
    }
    transformPropDates(props, browserTimezone = true) {
      let newObj = {};
  
      Object.entries(props).forEach(([key,value]) => {
        if(keys.includes(key)){
          newObj[key] = this.browserParseDateISO(value, browserTimezone)
        } else{
          newObj[key] =this.transformValue(value, browserTimezone)
        }
      })
     return newObj; 
    }
    transformValue(val, browserTimezone) {
      if(Array.isArray(val)) return val.map(i => this.transformValue(i, browserTimezone));
      else if (val && typeof val === 'object') return this.transformPropDates(val, browserTimezone);
      else return val;
    }

  componentWillReceiveProps(nextProps) {
    const { activitySplitList: prevActivitySplitList } = this.modifiedProps;
   const modifiedNextProps = this.transformPropDates(nextProps);
    const { activitySplitList: nextActivitySplitList, location: { query } } = modifiedNextProps
    if(((prevActivitySplitList.length) !== (nextActivitySplitList.length)))
    {

      let prevIds = prevActivitySplitList.map(item => item.id);
      let newIds = nextActivitySplitList.map(item => item.id);

      let difference = newIds.filter(x => !prevIds.includes(x));
      this.setState({savedId: difference[0]})
      if(this.modifiedProps.activityShift){
        this.initiateSelectedShiftValues(this.modifiedProps.activityShift);
      }
      else if(query.shiftId){

        const shift = nextActivitySplitList &&
        nextActivitySplitList.filter(item => item.id === (+ query.shiftId));
        this.initiateSelectedShiftValues(shift[0]);
      }
      else{
        const shift = nextActivitySplitList &&
        nextActivitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
        this.initiateSelectedShiftValues(shift);
      }
    }
    this.modifiedProps = modifiedNextProps;
  }



  componentWillUnmount() {
    this.props.MissingEntryDucks.resetMissingEntry();
  }

  componentDidMount(){
    const { activitySplitList } = this.modifiedProps;
    const shift = activitySplitList && activitySplitList.length &&
      activitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
    shift && this.initiateSelectedShiftValues(shift);
  }

  getTasksForJob(job){
    this.props.TaskDucks.getTasksOfEmployee({ job_id: job.value, paginate: false });
  }

  toggleMealModal(){
    const { isMealModalOpen } = this.state;
    this.setState({isMealModalOpen : !isMealModalOpen });
  }


  toggleAddNewActivityModal(params){
    const { isNewActivityModalOpen } = this.state
    const { location: { query }, activityShift, activitySplitList } = this.modifiedProps;
    if(!isNewActivityModalOpen && this.props.new_layout){
      const shift = activitySplitList && activitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
      const start_date =  moment(shift.start_datetime_tz).format('YYYY-MM-DD')
      this.props.JobDucks.getJobsOfEmployee({ employee_id: query.employee_id, paginate: false, start_date: start_date });
      const initialData = {start_datetime: params.start_datetime, end_datetime : params.end_datetime};
      this.setState({addNewEntry : initialData });
    }
    this.setState({isNewActivityModalOpen: !isNewActivityModalOpen});
  }

  editTaskEntry(item){
    const { isOpen } = this.state;
    const { location: { query }, activitySplitList } = this.modifiedProps;  
    if(!isOpen && this.props.new_layout){
      const shift = activitySplitList && activitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
      const start_date =  moment(shift.start_datetime_tz).format('YYYY-MM-DD')
      this.props.JobDucks.getJobsOfEmployee({ employee_id: query.employee_id, paginate: false, start_date: start_date });
      this.getTasksForJob({value:item.job.id});
    }
    const initialData = {start_datetime: item.start_datetime, end_datetime : item.end_datetime, job: {label: item.job.name, value: item.job.id}, task : {label: item.task.name , value: item.task.id}, id : item.id};
    this.setState({editActivityEntry : initialData, isOpen : true});
  }

  saveValidity(valueValidity, item, id, notes){
    this.setState({commentSubmitting : true});
    const { splitData, shiftData } =this.modifiedProps;
    const detail = { ...item}
    this.props.new_layout && this.props.startSubmitting('loadingHover');

    if (id) {
      detail.reason = valueValidity;
      detail.status = item.status;
      this.setState({isCommentLoading :true, loadedShift : id, confirmdeleteLoader: true});
    }
    else if(valueValidity.value && !id){
      detail.status = valueValidity.value;
      this.setState({isValidityBoxLoading :true, loadedShift : id, confirmdeleteLoader: true});
    }
    return this.props.ActivitySplitDucks.putActivitySplit({...detail, beta: 'true'})
      .then(()=>{
        this.props.new_layout && this.props.endSubmitting('loadingHover', false, false);
        this.props.showTasksModal(splitData, shiftData);
        !notes && this.props.fetchData();
        !notes ? toast.success('Shift Updated Successfully') : toast.success('Comment added Successfully');
        this.setState({commentSubmitting : false});
      })
      .catch((error)=>{
        notes && this.props.new_layout && this.props.endSubmitting('loadingHover', false, false);
        this.props.showTasksModal(splitData, shiftData);
        this.props.fetchData();
        this.setState({commentSubmitting : false});
        error && error.reason && toast.error(error.reason[0]);
        error && error.split_in_session && toast.error(error.split_in_session && error.split_in_session[0])
      })
  }


  goTo(url, data, childItem) {
    this.props.hideTaskModal();
    const { location: { query }   } = this.props;
    const { router: { history, route: { match: { params } } } } = this.context;
    history.push({
      pathname: url,
      search: queryString.stringify({
        employee: params.id,
        parent: JSON.stringify(query),
        split_id:data.id,
        is_approved:childItem.is_approved,
        first_name: query.first_name,
        last_name: query.last_name,
      }),
    })
  }

  setSelectedShift(data) {
    const selectedShift = this.modifiedProps.activitySplitList.filter(item => data.value === item.id)[0];
    this.props.MissingEntryDucks.resetMissingEntry();
    this.initiateSelectedShiftValues(selectedShift);
  }

  setActivitySplits(data){
    const { selectedShift } = this.state;
    const { splitData } = this.modifiedProps;
    if(splitData && splitData.id) this.props.ActivitySplitDucks.setActivitySplits({ ...data, selectedShiftId: splitData.id }) 
   else if(selectedShift && selectedShift.id) this.props.ActivitySplitDucks.setActivitySplits({ ...data, selectedShiftId: selectedShift.id })
  }

  setPunchInDate(data, id){
    this.setState({ PunchInDateTime : data.start_datetime })
  }


  setPunchOutDate(data){
    this.setState({ PunchOutDateTime : data.end_datetime })
  }

  initiateSelectedShiftValues(shift) {
    const data = {
      start_datetime: shift && moment(shift.start_datetime).toISOString(),
      end_datetime: shift && moment(shift.end_datetime).toISOString(),
      index: 0,
    };
    this.setState({ selectedShift: shift });
    this.props.ActivitySplitDucks.setSelectedShift(shift);
    this.props.MissingEntryDucks.updateMissingEntry(data);
  }

  async addMealToDT(data){
    this.props.new_layout && this.props.startSubmitting('loadingHover' , false, 'isSubmitting', 'submitDisabled', false, 'submitMeal');
    await this.addMealPeriodEntry(data)
    this.props.new_layout && this.submitManualSession(true);
  }

  addMealPeriodEntry(editedEntries) {
    const { activitySplitList, splitData} = this.modifiedProps;
    const { location : {query}} = this.props;
    const { selectedShift } = this.state;
    let selectedActivityShift;
    let selected;
    selectedActivityShift = this.props.new_layout
     ?  
     activitySplitList && activitySplitList.length  && activitySplitList.filter((item)=> +item.id === +splitData.id)[0] 
     :
     activitySplitList && activitySplitList.length  && activitySplitList.filter((item)=> +item.id === +query.split_id)[0]      
    selected =  this.props.new_layout ? splitData :  query.split_id ? selectedActivityShift : selectedShift;
    
    const data = {
      start_datetime:editedEntries && editedEntries.start_datetime ?  this.browserParseDateISO(editedEntries.start_datetime,false) : this.browserParseDateISO(selected.start_datetime, false),
      end_datetime: editedEntries && editedEntries.end_datetime ? this.browserParseDateISO(editedEntries.end_datetime, false) : this.browserParseDateISO(selected.end_datetime, false),
      activity_code: 'MEAL-PERIOD',
    };

     this.props.MissingEntryDucks.addMissingEntry(data);
     return true;

  }

  editEntry(data) {
    return this.props.MissingEntryDucks.updateMissingEntry(data);
  }

  removeEntry(data) {
    return this.props.MissingEntryDucks.removeMissingEntry(data);
  }

  deleteActivity(){
    this.props.new_layout && this.props.startSubmitting('loadingHover', false, 'isSubmitting', 'submitDisabled');
    const { deleteId } = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    this.toggleDelete();
    this.setState({ isDeleting: true });
    this.props.ActivitySplitDucks.deleteActivityAndTask({ id: deleteId })
      .then(() => {
        this.setState({ deleteId: null, isDeleteModalOpen: false});
        this.props.ActivitySplitDucks.getActivitySplits({ id:this.props.new_layout ? this.props.shiftData.id : params.id })
          .then(() => {
            this.props.new_layout &&  this.props.fetchData();
            this.props.new_layout && this.props.showTasksModal(this.modifiedProps.splitData, this.modifiedProps.shiftData);
            this.setState({ isDeleting: false});
          })
          .catch(() => {
            this.setState({ isDeleting: false });
            this.props.endSubmitting&&this.props.endSubmitting(false, false, 'isLoading')
          })
      })
      .catch(() => 
        {
          this.setState({ isDeleting: false, deleteId: null, isDeleteModalOpen: false });
          this.props.endSubmitting&&this.props.endSubmitting(false, false, 'isLoading');
        });
  }

  submitManualSession(saveNewTask) {
    this.props.new_layout  && !saveNewTask && this.props.startSubmitting('loadingHover', 'submitSession', 'isSubmitting', 'submitDisabled');
    const { router: { history, route: { match: { params } } } } = this.context;
    const { activitySplitList, missingEntryList, location,splitData, shiftData } = this.modifiedProps;
    const { search, query } = location;
    const { selectedShift, jobTasklist, PunchOutDateTime, PunchInDateTime , isOpen , editActivityEntry} = this.state;
    const missingEntryArr = missingEntryList.filter((i) => i.activity_code).map((item) => {
      const { index, ...rest } = item;
      return rest;
    });


    const selectedActivityShift = activitySplitList && activitySplitList.length  && activitySplitList.filter((item)=> +item.id === +query.split_id)[0];
    let data;
    data = splitData ?
     activitySplitList.filter((i) =>  i.id === splitData.id) && activitySplitList.filter((i) =>  i.id === splitData.id)[0] && activitySplitList.filter((i) =>  i.id === splitData.id)[0].split_list
    :
    selectedShift && selectedShift.id ?
      activitySplitList.filter((i) => i.id === selectedShift.id)[0].split_list
     :
     query.split_id ? 
      activitySplitList.filter((i) => i.id === selectedActivityShift.id)[0].split_list
     :
     [];

    data = splitData&& data && data.length && editActivityEntry&& editActivityEntry !==null ? data.filter((i) => i.id !==editActivityEntry.id) : data;
    
    
    const newJobTaskList = [...data, ...jobTasklist];
    let jobTaskArray = newJobTaskList && newJobTaskList.length &&  newJobTaskList[0] !== undefined && newJobTaskList.length && newJobTaskList.map((item) => {
      const { job, task, ...rest } = item;
      let newItem = {};
      newItem = { ...rest, job_id: item.job && item.job.value ? item.job.value : item.job && item.job.id ? item.job.id : null, task_id: item.task && item.task.value ? item.task.value : item.task && item.task.id ? item.task.id : null};
      return newItem;
    });

    jobTaskArray =  jobTaskArray === 0 ? [] : jobTaskArray;

    // if split id exists theen only selected shift from managingDt to be shown else dropdown
    let punchArray = [];
    if(query.split_id ) {
      punchArray = selectedActivityShift;
    }
    else{
      punchArray =  splitData ? splitData : selectedShift ? selectedShift : null;
    }

    const shiftArr = {
      start_datetime : PunchInDateTime!==null ? PunchInDateTime : punchArray &&  punchArray.start_datetime,
      end_datetime :  PunchOutDateTime!==null ? PunchOutDateTime : punchArray &&  punchArray.end_datetime,
    }

    const activityData = this.transformPropDates({
      split_id: punchArray.id,
      data: [...missingEntryArr, ...jobTaskArray],
      punch_in: shiftArr,
    }, false);

    const queryParams = queryString.parse(search);
    const searchParams = queryParams.parent ? JSON.parse(queryParams.parent): null;
    const queryParent = query.parent ? JSON.parse(query.parent) : null;
    if (this.constructor.isSessionValidated([...missingEntryArr, ...jobTaskArray], splitData ? splitData : selectedShift ? selectedShift : selectedActivityShift ? selectedActivityShift : null, shiftArr)) {      
      this.props.ActivitySplitDucks.postActivityAndTask(activityData, splitData ? splitData.id : selectedShift ? selectedShift.id : selectedActivityShift ? selectedActivityShift.id : null)
        .then((res) => {
          editActivityEntry!==null && editActivityEntry.id &&  this.setState({savedId : editActivityEntry.id});
          toast.success(MSGS.ACTIVITY_AND_TASKS_SUCCESS);
          isOpen && this.toggle();
          this.state.isMealModalOpen && this.toggleMealModal();
          this.state.isNewActivityModalOpen && this.toggleAddNewActivityModal();
          this.props.endSubmitting&&this.props.endSubmitting(false, false, false, false, 'submitTask', 'submitMeal');
          if(query.view === 'old'){
            history.push({
              pathname: `/activity/timecard/custom/list/${params.id}/split`,
              search : queryString.stringify({
                ...searchParams
              }),
            });
          }
          else if(query.split_id) {
            history.push({pathname :`/timecard/custom/list/${query.employee}/daily`,
              search : queryString.stringify({ ...queryParent }),
            });
          }
          else if(this.props.new_layout){     
            this.setState({jobTasklist:  []});
            this.props.MissingEntryDucks.resetMissingEntry();
            this.props.showTasksModal(splitData, shiftData, res.value.daily_timecard_id);
            this.props.updateErrorState();
            this.props.fetchData();
          }
        })
        .catch((err) => {
          this.setState({jobTasklist:  []});
          this.props.MissingEntryDucks.resetMissingEntry();
          this.props.endSubmitting&&this.props.endSubmitting('isSubmitting', 'submitSession', 'isLoading', 'submitDisabled', 'submitTask', 'submitMeal');
          this.setState({jobTasklist:  []});
          if(Array.isArray(err)){
            toast.error(err[0].non_field_errors[0]);
          }
          else{
            toast.error(err.non_field_errors[0]);
          }
        });
    } 
    else{
      this.setState({jobTasklist:  []});
      this.props.new_layout && this.props.MissingEntryDucks.resetMissingEntry();
      this.props.endSubmitting&&this.props.endSubmitting('isSubmitting', 'submitSession', 'isLoading', 'submitDisabled', 'submitTask', 'submitMeal');
    }
  }

  toggle(){
    const { isOpen } = this.state;
    const { location: { query }, activityShift, activitySplitList } = this.modifiedProps;  
    if(!isOpen && !this.props.new_layout){
      const StartDate = moment(activityShift.start_datetime_tz).format('YYYY-MM-DD');
      this.props.JobDucks.getJobsOfEmployee({ employee_id: query.employee, paginate: false, start_date: StartDate });
    }
    if(!isOpen && this.props.new_layout){
      const shift = activitySplitList && activitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
      const start_date =  moment(shift.start_datetime_tz).format('YYYY-MM-DD')
      this.props.JobDucks.getJobsOfEmployee({ employee_id: query.employee_id, paginate: false, start_date: start_date });
    }
    this.setState({ isOpen: !isOpen });
    this.setState({editActivityEntry:null});
  }

  toggleDelete(item, index){
    const { isDeleteModalOpen } = this.state;
    if(!isDeleteModalOpen) {
      return this.setState({ deleteId: item.id, isDeleteModalOpen: true });
    }
    return this.setState({ isDeleteModalOpen: false });
  }

  saveTask(data){
    const { jobTasklist } = this.state;

    this.setState({ jobTasklist: [...jobTasklist, data] });
    this.props.new_layout && this.props.startSubmitting('loadingHover', false, 'isSubmitting', 'submitDisabled', 'submitTask');
    setTimeout(() => {
      this.props.new_layout ? this.submitManualSession(true) : this.toggle()
     }, 1000);
   /* this.toggle();*/

  }

  editTask(item, index) {
    const { jobTasklist } = this.state;
    const newarr = jobTasklist.map((i, ind) => {
      if(ind === index) return item;
      return i;
    });
    this.setState({ jobTasklist: newarr });
  }

  removeTask(item, index) {
    const { jobTasklist } = this.state;
    jobTasklist.splice(index, 1);
    this.setState({ jobTasklist });
  }

  resetJobList(splitData, shiftData, type){
    this.props.startSubmitting('loadingHover');
    this.setState({ jobTasklist : [] });
    this.props.showPreviousShift(splitData, shiftData, type);
    // this.setState({isLoading: false});
  }

  render() {
    const {
      dateFormat,
      timecardDecimalView,
      isLoading,
      missingEntryList,
      activitySplitList,
      timeFormat,
      timeFormat24Hrs,
      jobList,
      taskList,
      location : { query },
      splitData,
      isSubmitting,
      userTimecardFilters
    } = this.modifiedProps;
    const { selectedShift, isOpen, jobTasklist, isDeleting, isDeleteModalOpen, editActivityEntry, isNewActivityModalOpen , addNewEntry} = this.state;
    const shifts = activitySplitList &&
      activitySplitList.filter(item => item.activity_code === 'PUNCH-IN');
    const options = shifts && shifts.map(item => ({
      value: item.id, label: `${item.start_datetime_display} to ${item.end_datetime_display}`,
    }));

    let selectedActivityShift={}
    if(query.split_id){
      selectedActivityShift = activitySplitList && activitySplitList.length  && activitySplitList.filter((item)=> +item.id === +query.split_id)[0]
    }
    /*const list = selectedShift && selectedShift.split_list.map(data => data);*/
    let activityList = {};

    let punchArray = [];
    if(query.split_id ) {
      punchArray = selectedActivityShift;
    }
    else{
      punchArray =  selectedShift;
    }
    if(punchArray){
      activityList = activitySplitList.length ? activitySplitList.filter((i)=> i.id === punchArray.id)[0] : [];
    }

    const punchIn = selectedActivityShift && selectedActivityShift.start_datetime_display;
    const punchOut = selectedActivityShift && selectedActivityShift.end_datetime_display;
    
    if (isLoading && !this.props.new_layout) return <SectionLoaderAtom active />;
    return (
      <div style={this.props.new_layout && {width : '30%'}}>
      {((!this.props.new_layout && ((selectedActivityShift && selectedActivityShift.id) || selectedShift !== null)) || (this.props.new_layout && this.props.splitData)) &&
      <div>
      {!this.props.new_layout ?
        <Row>
          <Col md={9} className="pr-0">
            { !query.split_id ? <div className="mr-2">Choose your shift : </div> : null}
            <Row className="filter mb-3">
              {!query.split_id ? <Col md={7} className="d-flex">
                <section>
                  <UncontrolledDropdown>
                    <DropdownToggle caret className="activity" color="accent">
                      {(selectedShift &&
                          `${selectedShift.start_datetime_display} to ${selectedShift.end_datetime_display}`)
                        || 'Select'
                      }
                    </DropdownToggle>
                    <DropdownMenu>
                      <span>
                        {options.map(item =>
                          <DropdownItem key={item.value} onClick={() => this.setSelectedShift(item)}>
                            {item.label}
                          </DropdownItem>
                        )}
                      </span>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </section>
              </Col>
                :<Col className={`mt-2 shift_info`}>
                  <b>Shift : {punchIn && punchIn} to {punchOut && punchOut}</b>
                </Col>
              }
              {((query.split_id && query.is_approved === 'false') || !query.split_id)&&
                <Col md={5} className="text-right">
                  <Button className="mr-2" onClick={this.toggle}>Allocate Task</Button>
                  <Button className="mr-2" onClick={this.addMealPeriodEntry}>Add Meal Period</Button>
                </Col>}
            </Row>

            {isDeleting ?
              <SectionLoaderAtom active />
              : <Row>
                <Col md={11} className={`${styles.timeline} offset-md-1 pl-1`}>
                  <ActivitySplitPunchInComponent
                    dateFormat={dateFormat}
                    item={query.split_id ? selectedActivityShift : selectedShift}
                    timeFormat={timeFormat}
                    timeFormat24Hrs={timeFormat24Hrs}
                    editEntry={this.setPunchInDate}
                    split={query.split_id}
                    disabled={((query.is_approved === 'true') && query.split_id) || (query.view === 'old')}
                    browserParseDate={this.browserParseDate}
                    userTimezone={this.props.userTimezone}
                  />
                  { activityList && activityList.split_list && activityList.split_list.length ?
                    activityList.split_list.map((item) => {
                      if (item.activity_code === 'MEAL-PERIOD') {
                        return (<ActivitySplitMealPeriodComponent
                          key={item.index}
                          dateFormat={dateFormat}
                          endDate={item.end_datetime}
                          item={item}
                          startDate={item.start_datetime}
                          timeFormat={timeFormat}
                          timeFormat24Hrs={timeFormat24Hrs}
                          editEntry={this.setActivitySplits}
                          removeEntry={this.toggleDelete}
                          selectedShift={selectedShift}
                          approved={"is_approved" in query ? query.is_approved : "false"}
                          browserParseDate={this.browserParseDate}
                          userTimezone={this.props.userTimezone}
                        />
                        );
                      }
                      else if(item.activity_code === 'TASK') {
                        return (<TaskComponent
                          key={item.id}
                          index={item.id}
                          dateFormat={dateFormat}
                          endDate={item.end_date}
                          item={item}
                          startDate={item.start_date}
                          timeFormat={timeFormat}
                          timecardDecimalView={timecardDecimalView}
                          timeFormat24Hrs={timeFormat24Hrs}
                          jobName={item.job.name}
                          taskName={item.task && item.task.name}
                          editTask={this.setActivitySplits}
                          removeTask={this.toggleDelete}
                          selectedShift={selectedShift}
                          task={activitySplitList.find((i) => i.id === item.id)}
                          isApproved={"is_approved" in query ? query.is_approved : "false"}
                          userTimecardFilters={userTimecardFilters}
                          browserParseDate={this.browserParseDate}
                          userTimezone={this.props.userTimezone}
                        />
                        );
                      }
                      return null;
                    }
                    )
                    : null }
                  {
                    jobTasklist.map((item, index) => {
                      if (item.activity_code === 'TASK') {
                        return (<TaskComponent
                          key={item.id}
                          index={index}
                          dateFormat={dateFormat}
                          endDate={item.end_date}
                          item={item}
                          startDate={item.start_date}
                          timeFormat={timeFormat}
                          timeFormat24Hrs={timeFormat24Hrs}
                          timecardDecimalView={timecardDecimalView}
                          jobName={item.job.label}
                          taskName={item.task && item.task.label}
                          editTask={this.editTask}
                          removeTask={this.removeTask}
                          selectedShift={selectedShift}
                          isApproved = {"is_approved" in query ? query.is_approved : "false"}
                          userTimecardFilters={userTimecardFilters}
                          browserParseDate={this.browserParseDate}
                          userTimezone={this.props.userTimezone}
                        />
                        );
                      }
                      return null;
                    })}
                  { missingEntryList.map((item) => {
                    if (item.activity_code === 'MEAL-PERIOD') {
                      return (<ActivitySplitMealPeriodComponent
                        key={item.index}
                        dateFormat={dateFormat}
                        endDate={item.end_datetime}
                        item={item}
                        startDate={item.start_datetime}
                        timeFormat={timeFormat}
                        timeFormat24Hrs={timeFormat24Hrs}
                        editEntry={this.editEntry}
                        removeEntry={this.removeEntry}
                        selectedShift={selectedShift}
                        approved={query.is_approved}
                        browserParseDate={this.browserParseDate}
                        userTimezone={this.props.userTimezone}
                      />
                      );
                    }
                    return null;
                  }
                  )}
                  <ActivitySplitPunchOutComponent
                    dateFormat={dateFormat}
                    item={query.split_id ? selectedActivityShift : selectedShift}
                    timeFormat={timeFormat}
                    timeFormat24Hrs={timeFormat24Hrs}
                    startDate={query.split_id ? selectedActivityShift : selectedShift}
                    endDate={query.split_id ? selectedActivityShift : selectedShift}
                    editEntry={this.setPunchOutDate}
                    approved={query.is_approved}
                    disabled={((query.is_approved === 'true') && query.split_id) || (query.view === 'old')}
                    browserParseDate={this.browserParseDate}
                    userTimezone={this.props.userTimezone}
                  />
                </Col>
              </Row>}
              <Row>
                {((query.split_id && query.is_approved === 'false') || !query.split_id)&&
                <Col className="text-right mt-3 pb-5">
                  <Button onClick={() => this.submitManualSession(false)} disabled={ isSubmitting || isDeleting }>
                    Submit <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                </Col>}
              </Row>

            {isOpen && <AllocateTaskModalComponent
              isOpen={isOpen}
              toggle={this.toggle}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              timeFormat24Hrs={timeFormat24Hrs}
              jobList={jobList}
              getTasksForJob={this.getTasksForJob}
              taskList={taskList}
              selectedShift={query.split_id ? selectedActivityShift : selectedShift}
              saveTask={this.saveTask}
              browserParseDate={this.browserParseDate}
              userTimezone={this.props.userTimezone}
              initialValues={{ start_datetime: query.split_id ? selectedActivityShift.start_datetime : selectedShift.start_datetime, end_datetime: query.split_id ? selectedActivityShift.end_datetime : selectedShift.end_datetime , start_time: query.split_id ? moment(selectedActivityShift.start_datetime).format('LT') : moment(selectedShift.start_datetime).format('LT'), end_time :  query.split_id ? moment(selectedActivityShift.end_datetime).format('LT') :  moment(selectedShift.end_datetime).format('LT') }}
            />}
            {isDeleteModalOpen && <ConfirmationDeleteComponent toggle={this.toggleDelete} isOpen={isDeleteModalOpen} deleteActivity={this.deleteActivity}/>}
          </Col>
            <Col md={3} className={ !query.split_id ? "mt-5 pt-2" : "mt-4 pt-2"}>
              <CommentsComponent data={query.split_id && selectedActivityShift ? selectedActivityShift : selectedShift  ? selectedShift : null} />
            </Col>
          </Row>
          :
          <div className="w-30">
          <AllocateTaskPopupSection
            dateFormat={this.props.dateFormat}
            splitData= {this.modifiedProps.splitData}
            shiftData = {this.modifiedProps.shiftData}
            showTasksModal = {this.props.showTasksModal }
            toggle={this.toggle}
            timeFormat={this.props.timeFormat}
            timeFormat24Hrs={this.props.timeFormat24Hrs}
            activityList={activitySplitList && activitySplitList.length  && activitySplitList.filter((item)=> +item.id === +splitData.id)[0]}
            activitySplitList ={activitySplitList}
            setPunchInDate={this.setPunchInDate}
            submitManualSession={this.submitManualSession}
            setPunchOutDate={this.setPunchOutDate}
            setActivitySplits = {this.setActivitySplits}
            editTask={this.editTask}
            jobTasklist={JSON.parse(JSON.stringify(jobTasklist))}
            missingEntryList ={missingEntryList}
            addMealPeriodEntry = {this.addMealToDT}
            timecardDecimalView={timecardDecimalView}
            editMeal={this.editEntry}
            hideTaskModal={this.props.hideTaskModal}
            goTo={this.goTo}
            showPreviousShift={this.props.showPreviousShift}
            submitSession={this.props.submitSession}
            toggleDelete= {this.toggleDelete}
            removeEntry = {this.removeEntry}
            resetJobList = {this.resetJobList}
            isSubmitting = {this.props.isLoading}
            removeTask={this.removeTask}
            isPreviousShift = {this.props.isPreviousShift}
            isNextShift = {this.props.isNextShift}
            saveValidity={this.saveValidity}
            commentSubmitting={this.state.commentSubmitting}
            submitDisabled ={this.props.submitDisabled}
            toggleMealModal = {this.toggleMealModal}
            editTaskEntry = {this.editTaskEntry}
            savedId ={this.state.savedId ? this.state.savedId : null}
            shiftId={this.props.shiftId}
            toggleAddNewActivityModal={this.toggleAddNewActivityModal}
            feedbackResponse={this.props.feedbackResponse}
            dateFormatDisplay={this.props.dateFormatDisplay}
            userTimecardFilters={userTimecardFilters}
            browserParseDateISO={this.browserParseDateISO}
            activitySplitsLoading={this.props.activitySplitsLoading}
            browserParseDate={this.browserParseDate}
            userTimezone={this.props.userTimezone}
          />
          {(isOpen || this.state.editActivityEntry !== null) && <AllocateTaskModalComponent
              isOpen={isOpen}
              toggle={this.toggle}
              dateFormat={dateFormat}
              timeFormat={timeFormat}
              timeFormat24Hrs={timeFormat24Hrs}
              jobList={jobList}
              getTasksForJob={this.getTasksForJob}
              taskList={taskList}
              selectedShift={splitData}
              saveTask={this.saveTask}
              initialValues={{ start_datetime: editActivityEntry&& editActivityEntry.start_datetime ? editActivityEntry.start_datetime : splitData.start_datetime,
               end_datetime: editActivityEntry&& editActivityEntry.end_datetime ? editActivityEntry.end_datetime : splitData.end_datetime,
               job: editActivityEntry && editActivityEntry.job,
               task: editActivityEntry && editActivityEntry.task,
               id: editActivityEntry && editActivityEntry.id,
               start_time : editActivityEntry&& editActivityEntry.start_datetime ? moment(editActivityEntry.start_datetime).format('LT') : moment(splitData.start_datetime).format('LT'),
               end_time : editActivityEntry&& editActivityEntry.end_datetime ? moment(editActivityEntry.end_datetime).format('LT') : moment(splitData.end_datetime).format('LT'),
            }}
              submitLoader={this.props.submitTask}
              splitData = {splitData}
              browserParseDate={this.browserParseDate}
              userTimezone={this.props.userTimezone}

            />}
            {
              this.state.isMealModalOpen && <AllocateMealModalComponent
                isOpen={this.state.isMealModalOpen}
                toggle={this.toggleMealModal}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                selectedShift={splitData}
                initialValues={{ start_datetime: splitData.start_datetime, end_datetime: splitData.end_datetime ,  start_time: moment(splitData.start_datetime).format('LT') , end_time : moment(splitData.end_datetime).format('LT') }}
                submit = {this.addMealToDT}
                submitMeal = {this.addMealToDT}
                onSubmit = {this.addMealToDT}
                submitMealLoader = {this.props.submitMeal}
                splitData = {splitData}
                userTimezone={this.props.userTimezone}
                browserParseDate={this.browserParseDate}

              />
            }
            {isDeleteModalOpen && <ConfirmationDeleteComponent toggle={this.toggleDelete} isOpen={isDeleteModalOpen} deleteActivity={this.deleteActivity}/>}
            {
              isNewActivityModalOpen && <AddNewActivityModal
                isOpen={isNewActivityModalOpen}
                toggle={this.toggleAddNewActivityModal}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                jobList={jobList}
                getTasksForJob={this.getTasksForJob}
                taskList={taskList}
                selectedShift={splitData}
                saveTask={this.saveTask}
                submitLoader={this.props.submitTask}
                splitData = {splitData}
                initialValues = {{start_datetime : addNewEntry && addNewEntry.start_datetime ? addNewEntry.start_datetime : splitData.start_datetime , 
                  end_datetime : addNewEntry && addNewEntry.end_datetime ? addNewEntry.end_datetime : splitData.end_datetime,
                  start_time : addNewEntry && addNewEntry.start_datetime ? moment(addNewEntry.start_datetime).format('LT') : moment(splitData.start_datetime).format('LT'),
                  end_time : addNewEntry && addNewEntry.end_datetime ? moment(addNewEntry.end_datetime).format('LT') : moment(splitData.end_datetime).format('LT'),
                }}
                submit = {this.addMealToDT}
                onSubmit = {this.addMealToDT}
                submitMealLoader = {this.props.submitMeal}
                submitMeal = {this.addMealToDT}
                userTimezone={this.props.userTimezone}
                browserParseDate={this.browserParseDate}
              />
            }     
        </div>
      }
      </div>
    }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activityShift: ActivitySplitDucks.activityShift(state),
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timecardDecimalView: BasicSettingDucks.timecardDecimalView(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  missingEntryList: MissingEntryDucks.missingEntryList(state),
  location: RouteDucks.location(state),
  jobList: JobDucks.jobAll(state),
  taskList: TaskDucks.taskAll(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
  userTimecardFilters : TimecardDucks.userTimecardFilters(state),
  userTimezone: UserDucks.userBATimezone(state)
});

const mapActionsToProps = dispatch => ({
  MissingEntryDucks: bindActionCreators(MissingEntryDucks, dispatch),
  // SessionDucks: bindActionCreators(SessionDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

ActivitySplitTimelineSection.propTypes = {
  activityShift: PropTypes.object,
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  missingEntryList: PropTypes.array.isRequired,
  activitySplitList: PropTypes.array.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timecardDecimalView: PropTypes.bool.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  MissingEntryDucks: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  TaskDucks: PropTypes.object.isRequired,
  dailyTimecardList: PropTypes.array,
};

ActivitySplitTimelineSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivitySplitTimelineSection);
