import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import CheckBox from 'atoms/Checkbox';

import { FORM_LAYOUT } from 'constants/layout';
import {
  PAYROLL_CYCLES,
  PAYROLL_CYCLE_OBJ,
  FIRST_HALF_MONTH_DATES,
  SECOND_HALF_MONTH_DATES,
} from 'constants/payrolls';

/**
 * PayrollFormPage -> PayrollFormSection -> PayrollFormComponent
 *
 * Props:
 *    - create
 *    - remove
 */
class PayrollFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.deletePayrolltoggle = this.deletePayrolltoggle.bind(this);
  }

  submit(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { create, updatePayroll, userCompany } = this.props;
    const userData = { ...data, company_id: userCompany.id };

    userData.start_date = moment(data.start_date).format('YYYY-MM-DD');
    if(!('is_active' in userData)) userData.is_active = false;
    // Delete fields before submission depending on payroll cycle
    switch (data.payroll_cycle) {
      case PAYROLL_CYCLE_OBJ.TWICE_A_MONTH:
        delete userData.week_frequency;
        break;
      case PAYROLL_CYCLE_OBJ.MONTHLY:
        delete userData.week_frequency;
        delete userData.first_half_of_month_closing_day;
        delete userData.second_half_of_month_closing_day;
        break;
      default:
        delete userData.first_half_of_month_closing_day;
        delete userData.second_half_of_month_closing_day;
        break;
    }

    if(params.id){
      return updatePayroll(userData)
        .catch((err) => {
          const errObj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errObj);
        });
    }
    return create(userData)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  deletePayrolltoggle(data){
    const { deletePayrolltoggle } = this.props;
    return deletePayrolltoggle();
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const {
      dateFormat,
      deletePayrolltoggle,
      basicSettingDetail,
      error,
      initialValues,
      isDeletePayroll,
      payrollCycle,
      submitting,
      handleSubmit,
      remove,
      isDeleting
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.submit)} noValidate>
        <div className="error-text">{error}</div>
        <Row className="ml-3 mr-4 pb-5">
          <Col xs={12} md={7} className="px-0">
            <Field
              id="name"
              name="name"
              placeholder="Payroll Name"
              component={InputAtom}
              label="Payroll Name"
              layout={FORM_LAYOUT}
              disabled={{}.hasOwnProperty.call(params, 'id')}
              simpleValue
            />
            <Field
              id="payroll_cycle"
              name="payroll_cycle"
              placeholder="Payroll Cycle"
              component={SelectAtom}
              options={PAYROLL_CYCLES}
              label="Payroll Cycle"
              layout={FORM_LAYOUT}
              disabled={{}.hasOwnProperty.call(params, 'id')}
              simpleValue
            />
            <Field
              id="start_date"
              name="start_date"
              label="Start Date"
              layout={FORM_LAYOUT}
              placeholder="Start Date"
              component={DateTimePickerAtom}
              disabled={{}.hasOwnProperty.call(params, 'id')}
              options={{
                dateFormat,
                enable: [
                // Enable the dates 30 days before from today
                  (date) => moment(date).isAfter(moment().subtract(30, 'day')),
                ],
              }}
            />
            {payrollCycle === 'TWICE_A_MONTH' &&
              <Row>
                <Col>
                  <Field
                    component={SelectAtom}
                    id="first_half_of_month_closing_day"
                    label="First Half Of Month Payroll Closes On"
                    layout={FORM_LAYOUT}
                    name="first_half_of_month_closing_day"
                    options={FIRST_HALF_MONTH_DATES}
                    placeholder="First Half Closing Day"
                    simpleValue
                    disabled={{}.hasOwnProperty.call(params, 'id')}
                  />
                </Col>
                <Col className="pl-0">
                  <Field
                    component={SelectAtom}
                    id="second_half_of_month_closing_day"
                    label="Second Half Of Month Payroll Closes On"
                    layout={FORM_LAYOUT}
                    name="second_half_of_month_closing_day"
                    options={SECOND_HALF_MONTH_DATES}
                    placeholder="Second Half Closing Day"
                    simpleValue
                    disabled={{}.hasOwnProperty.call(params, 'id')}
                  />
                </Col>
              </Row>}
            <Row>
              <Col>
                <Field
                  id="is_active"
                  name="is_active"
                  label="Active"
                  layout={FORM_LAYOUT}
                  component={CheckBox}
                  disabled={{}.hasOwnProperty.call(params, 'id') && moment(initialValues.end_date) < moment()}

                />
              </Col>
              <Col>
                <div className="filter">
                  <Field
                    id="default"
                    name="default"
                    label="Set as default rule"
                    layout={FORM_LAYOUT}
                    component={CheckBox}
                    disabled={{}.hasOwnProperty.call(params, 'id') && moment(initialValues.end_date) < moment()}
                  />
                </div>
              </Col>
            </Row>
            { ((!initialValues.id) || (initialValues.id && !(moment(initialValues.end_date) < moment()))) &&
              <Button
                type="submit"
                color="secondary"
                className="float-sm-right pt-2 pb-2 ml-2"
                size={FORM_LAYOUT.size}
                onClick={handleSubmit(values =>
                  this.submit(values))
                }
              >
                Submit <ButtonLoaderAtom active={submitting} />
              </Button>}
            {params.id &&
              moment(initialValues.start_date, basicSettingDetail.date_format_display) > moment() &&
              <Button
                type="button"
                color="danger"
                className="float-sm-right pt-2 pb-2"
                size={FORM_LAYOUT.size}
                onClick={handleSubmit(deletePayrolltoggle)}
              >
                Delete
              </Button>
            }
            {isDeletePayroll &&
              <Modal isOpen={isDeletePayroll}>
                <ModalBody>
                  Are you sure you would like to delete this payroll ?
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    size={FORM_LAYOUT.size}
                    onClick={() => remove()}
                  >
                    Yes, Delete It  <ButtonLoaderAtom active={isDeleting} />
                  </Button>
                  <Button color="danger" onClick={() => deletePayrolltoggle()}>
                    No, Do Not Delete It
                  </Button>
                </ModalFooter>
              </Modal>
            }
          </Col>
        </Row>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Payroll Name';
  if (!values.payroll_cycle) errors.payroll_cycle = 'Select Payroll Cycle';
  if (!values.week_frequency) errors.week_frequency = 'Select Frequency';
  if (!values.start_date) errors.start_date = 'Enter Start Date';
  if (!values.week_day) errors.week_day = 'Enter Start Day';
  if (!values.first_half_of_month_closing_day) {
    errors.first_half_of_month_closing_day = 'Select First Half Closing Day';
  }
  if (!values.second_half_of_month_closing_day) {
    errors.second_half_of_month_closing_day = 'Select Second Half Closing Day';
  }
  if (!values.work_day_start_time) errors.work_day_start_time = 'Enter Start Time';
  if(values.default && !values.is_active) errors.is_active = 'Default payroll cannot be inactive.'
  return errors;
};

PayrollFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  deletePayrolltoggle: PropTypes.func.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  initialValues: PropTypes.object,
  isDeletePayroll: PropTypes.bool,
  payrollCycle: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  create: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  remove: PropTypes.func,
};

PayrollFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const payrollForm = reduxForm({
  form: 'PayrollForm',
  validate,
})(PayrollFormComponent);

const selector = formValueSelector('PayrollForm');

export default connect(
  (state) => {
    const payrollCycle = selector(state, 'payroll_cycle');
    return { payrollCycle };
  },
)(payrollForm);
