import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as RouteDucks from 'ducks/routes';

import FilterFormComponent from 'components/common/FilterForm';

/**
 * IncompleteSignUpListPage -> IncompleteSignUpListSection
 *
 * Components:
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    None
 */
const IncompleteSignUpFilterSection = (props) => {
  const { location } = props;
  const { query } = location;

  return (
    <Row className="filter pl-3 pr-4">
      <Col md={8} />
      <Col md={4}>
        <section className="float-right">
          <FilterFormComponent
            location={location}
            placeholder="Search name"
            initialValues={query}
          />
        </section>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

IncompleteSignUpFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

IncompleteSignUpFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(IncompleteSignUpFilterSection);
