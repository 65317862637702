import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';


import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import GeoFenceTableComponent from 'components/geoFences/list/Table';
import GenericModal from 'components/common/GenericModal';
import GeoFenceFormSection from 'sections/geoFences/form/Form';
import styles from './styles.module.scss'


/**
 * GeoFenceListPage -> GeoFenceListSection
 *
 * Components:
 *    - {@link GeoFenceTableComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class GeoFenceListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: false , selectedGeofence : null};
    this.updateStatus= this.updateStatus.bind(this);
    this.updateSelected = this.updateSelected.bind(this);
    this.getJobGeofence = this.getJobGeofence.bind(this);
  }

  updateSelected(data) {
    return this.props.EmployeeDucks.selectEmployees(data);
  }

  getJobGeofence(){
    console.log('heyyyyyyyyyyyyyyyyy');
  }

  updateStatus(data){
    const { status } = this.state;
    if(!status) {
      this.setState({ selectedGeofence: data });
      return this.props.GeoFenceDucks.getGeoFence(data)
        .then(() => this.setState({ status: !status }));
    }
    else if (status){
      this.props.fetchData();
    }
    return this.setState({ status: !status });
  }

  
  componentWillUnmount() {
    this.props.GeoFenceDucks.resetGeoFence();
  }



  render() {
    const { isLoading, geoFenceList, location} = this.props;
    const { status } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="geo-fence-list">
        {status &&
          <GenericModal className="modal_header_color" toggle={this.updateStatus} isOpen={status} bodyClassName={styles.modal_container_body} heading="Edit Geofence" modelClassName={'session-modal'}
            style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
            <GeoFenceFormSection
              selectedGeofence={this.state.selectedGeofence}
              getJobGeofence={this.getJobGeofence}
              updateStatus={this.updateStatus}
              size="lg"
            />
          </GenericModal>
        }
        <GeoFenceTableComponent
              location={location}
              data={geoFenceList}
              updateStatus = { this.updateStatus}
            />
        <PaginationComponent
          count={geoFenceList.count}
          location={location}
          cookieKey="geo_fence_list_page_size"
        /> 
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  geoFenceList: GeoFenceDucks.geoFenceList(state),
});

const mapActionsToProps = dispatch => ({
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

GeoFenceListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

GeoFenceListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceListSection);