import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';

import { toast } from 'react-toastify';

import * as GroupDucks from 'ducks/vendors/group';
import * as RoleDucks from 'ducks/vendors/role';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import RoleFormComponent from 'components/vendors/roleForm/Form';
import ConfirmationModalComponent from 'components/vendors/roleForm/ConfirmationModal';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/vendors';


/**
 * VendorFormPage -> VendorFormSection
 *
 * Components:
 *    - {@link VendorFormComponent}
 *
 * State:
 *    - vendorDetail
 *
 * Actions:
 *    - Form:
 *        - putVendor
 */
class RoleFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, data: {}, isConfirming: false };
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.updateState = this.updateState.bind(this);
    /*this.toggle = this.toggle.bind(this);*/
  };


  /*toggle(data){
    const { isOpen } = this.state;
    if(data && data.id) this.setState({ data }); 
    this.setState({ isOpen: !isOpen });
  }*/

  createOrUpdate(data) {
    const { router:  { history, route: { match: { params } } } } = this.context;
    const { location: { query }, groupList } = this.props;
    const singleRowData = data;
    const selectedGroup =[];
    groupList.map(itemGroup => {
      if (singleRowData[itemGroup.group.name]) {
        selectedGroup.push(itemGroup.group.id);
      }
      return itemGroup;
    })
    delete singleRowData.groups;
    singleRowData.groups = selectedGroup;


    if(params.id){
      this.setState({ isConfirming: true });
      return this.props.RoleDucks.putRole(singleRowData)
        .then(() => {
          this.setState({ isConfirming: false });
          toast.success(MSGS.VENDOR_ROLE_UPDATE_SUCCESS);
          history.push(query.parent);         
        });
    }
    return this.props.RoleDucks.postRole(singleRowData)
      .then(() => {
        toast.success(MSGS.VENDOR_ROLE_ADD_SUCCESS);
        history.push(query.parent);
      });

  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    const { groupListByUse, roleDetail, isLoading, userExtraData } = this.props;
    const { isOpen, data, isConfirming } = this.state;
    const { router:  { route: { match: { params } } } } = this.context;
    const { location: { search } } = this.props;
    const nextQuery = queryString.parse(search);
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <RoleFormComponent
          groupListByUse={groupListByUse}
          createOrUpdate={this.createOrUpdate}
          initialValues={params.id ? roleDetail : {}}
          id={params.id}
          isEdit={nextQuery.edit || "true"}
          isConfirming={isConfirming}
          updateState={this.updateState}
          userExtraData={userExtraData}
        />
        {/*<ConfirmationModalComponent
          isOpen={isOpen}
          toggle={this.toggle}
          createOrUpdate={this.createOrUpdate}
          data={data}
          isConfirming={isConfirming}
        />*/}
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({
  groupListByUse: GroupDucks.groupListByUse(state),
  groupList: GroupDucks.groupList(state),
  location: RouteDucks.location(state),
  roleDetail: RoleDucks.roleDetail(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionToProps = dispatch => ({
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
});

RoleFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

RoleFormSection.propTypes = {
};

export default connect (
  mapStatetoProps,
  mapActionToProps,
)(RoleFormSection);