import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import Field from "redux-form/lib/Field";
import reduxForm from "redux-form/lib/reduxForm";

import Button from "reactstrap/lib/Button";
import Form from "reactstrap/lib/Form";

import { UncontrolledTooltip } from "reactstrap";
import FilterDropdownComponent from "components/common/FilterDropdown";
import { LOGIN_STATUS_FILTER_OPTIONS } from "constants/employees";

import DateTimePickerAtom from "atoms/DateTimePicker";

import { FILTER_FORM_LAYOUT } from "constants/layout";

/**
 * KioskEmployeesAuditListPage -> KioskEmployeesAuditFilterSection -> KioskEmployeesAuditFilterFormComponent
 *
 * Components:
 *    - {@link KioskEmployessAuditListTableComponent}
 *
 * State:
 *    - employee audit list
 *
 * Actions:
 *    None
 */

const KioskEmployeesAuditFilterFormComponent = (props, context) => {
  const { dateFormat, handleSubmit, update, location } = props;

  const submit = (data) => {
    const detail = { ...data };
    detail.start_date = moment(data.start_date).format("YYYY-MM-DD");
    detail.end_date = moment(data.end_date).format("YYYY-MM-DD");
    return update(detail);
  };

  return (
    <Form inline onSubmit={handleSubmit(submit)}>
      <div className="report-filter">
        <Field
          id="start_date"
          name="start_date"
          placeholder="Start Date"
          component={DateTimePickerAtom}
          options={{ dateFormat }}
        />
      </div>
      <div className="report-filter">
        <Field
          id="end_date"
          name="end_date"
          placeholder="End Date"
          component={DateTimePickerAtom}
          options={{ dateFormat }}
        />
      </div>
      <div id="loginStatus" className="login-status-dropdown">
        <div>
          <FilterDropdownComponent
            paramKey="status"
            location={location}
            options={LOGIN_STATUS_FILTER_OPTIONS}
          />
          <UncontrolledTooltip placement="top" target="loginStatus">
            Login status type
          </UncontrolledTooltip>
        </div>
      </div>
      <div className="report-filter">
        <Button
          type="submit"
          color="accent"
          className="pr-4 pl-4"
          size={FILTER_FORM_LAYOUT.size}
        >
          Apply
        </Button>
      </div>
    </Form>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = "Select Start Date";
  if (!values.end_date) errors.end_date = "Select End Date";
  return errors;
};

KioskEmployeesAuditFilterFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

KioskEmployeesAuditFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: "KioskEmployeesAuditFilterForm",
  validate,
})(KioskEmployeesAuditFilterFormComponent);
