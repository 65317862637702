import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

class DeleteMealModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
    this.deleteMeal = this.deleteMeal.bind(this);
  }

  deleteMeal(){
    const { openDeleteForm } = this.props;
    openDeleteForm()
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  render() {
    const { isOpen, toggle , deleteMealLoader , isSubmitting} = this.props;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={toggle} className={this.props.className}>
          <ModalHeader toggle={toggle}>Delete Meal</ModalHeader>
          <ModalBody>
            <h5>
              Are you sure you want to delete this meal ?
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button 
              color="danger" 
              onClick={() => this.deleteMeal()}
              disabled={isSubmitting}
            >Delete <ButtonLoaderAtom active={deleteMealLoader}/></Button>{' '}
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default DeleteMealModalComponent;
