import React from 'react';
import queryString from 'query-string';
import TwoColumnLayout from 'layouts/TwoColumn';
import cookie from 'react-cookies';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import { NAV } from 'constants/vendors';

import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import InvoiceDetailFormComponent from 'payments/invoices/components/invoiceDetailForm';
import invoiceDetailUsecase from 'payments/invoices/usecases/invoiceDetailUseCase';
import InvoiceApiGateway from 'payments/invoices/gateways/invoice';

class InvoiceDetailFormPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.listenInvoiceDetailFormUseCase = this.listenInvoiceDetailFormUseCase.bind(this);
    const invoiceApiGateway = new InvoiceApiGateway();
    this.usecase = new invoiceDetailUsecase(invoiceApiGateway);
    this.fetchData = this.fetchData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.downloadInvoice = this.downloadInvoice.bind(this);
    this.downloadPDF = this.downloadPDF.bind(this);
    this.state={
      companyDetailList: {},
      isLoading: false,
      infoList: [],
      isInfoOpen: true,
      parent: NAV.InvoiceDetailFormPage.parent,
      commercialStatusList : [],
      invoiceDetails : [],
    }
    this.setParentUrl = this.setParentUrl.bind(this);
  }

  componentDidMount() {
    this.usecase.getInfoList();
    this.listenInvoiceDetailFormUseCase();
    this.fetchData();   
    this.setParentUrl();
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  downloadInvoice(){
    const { match: { params } } = this.props;
    if(params.id){
      this.usecase.downloadInvoice(params.id);
    }
  }

  downloadPDF(response,filename ){
    if (window.navigator.msSaveOrOpenBlob) {
      const a = document.createElement("download");
      document.body.appendChild(a);
      a.style.display = "none";
      a.onclick = ((evx) => 
      {
        const  myBlob =  new Blob([response] ,
        { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        window.navigator.msSaveOrOpenBlob(myBlob, filename);
      });
      a.click();
    }
    // chrome ,safari and mozilla browsers 
    else {
      const  myBlob =  new Blob([response] ,
        {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const  url = window.URL.createObjectURL(myBlob);
        const element = document.createElement("a");
        document.body.appendChild(element);
        element.href = url;
        element.download = filename;
        element.click();
    }
}
  
  

  fetchData(){
    const { match: { params } } = this.props;
    this.usecase.getInvoiceDetails(params.id);
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.updateState('isInfoOpen', !isInfoOpen );
    cookie.save("company_invoice_detail_info", !isInfoOpen);
  }

  listenInvoiceDetailFormUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'GET_INVOICE_DETAIL_SUCCESS':
          this.setState({ invoiceDetails: event.data, isLoading: false });
          break;
        case 'INVOICE_UPDATE_SUCCESS':
          toast.success('Invoice updated successfully.');
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'DOWNLOAD_INVOICE_SUCCESS':
          this.downloadPDF(event.data, `invoiceDetail.pdf`);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  setParentUrl() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        this.updateState('parent', query.parent);
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { parent , infoList, isInfoOpen, invoiceDetails, isLoading } = this.state;
    const { isBusinessAdmin, match: { params } } = this.props;
    const { location: { search } } = this.props;
    return(
      <TwoColumnLayout 
        navInfo={{...NAV.InvoiceDetailFormPage, parent}}
        fetchData={this.fetchData}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
      > 
       <InvoiceDetailFormComponent 
          invoiceDetails ={invoiceDetails}
          search = {search}
          isLoading={isLoading}
          isBusinessAdmin={isBusinessAdmin}
          invoiceId={params.id}
          usecase={this.usecase}
          downloadInvoice={this.downloadInvoice}
          customerDetail={this.props.customerDetail}
          initialValues={{ payment_received: invoiceDetails.status === 'PAYMENT RECEIVED' }}
       />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  customerDetail: PaymentDucks.customerDetail(state),
});


export default connect(
  mapStateToProps,
)(InvoiceDetailFormPage);