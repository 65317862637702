import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';
import styles from './styles.module.scss';

import SectionLoaderAtom from 'atoms/SectionLoader'

import BlueDeleteIcon from 'components/jobs/jobGeofence/Delete.png';
import BlueEditIcon from 'components/jobs/jobGeofence/Edit.png';
/**
 * GeoFenceListPage -> GeoFenceListSection -> JobGeoFenceTableComponent
 *
 * Props:
 *    - Geo Fence list
 */
const JobGeoFenceTableComponent = (props, context) => {
  const { data, deleteGeofence, isTableLoading, toggle} = props;
  if(isTableLoading) return <SectionLoaderAtom active />;
  return (
    <div style={{ boxShadow: "0px 3px 6px rgba(0,0,0,0.16)", marginTop: '8px' }}>
      <Table responsive hover size="sm" className={`${styles['geofence_table']} table-top`}>
        <thead>
          <tr className={styles.header_color}>
            <th className="text-center">Action</th>
            <th className="text-left">Name</th>
            <th className="text-left">Type</th>
            <th className="text-left">Address</th>
          </tr>
        </thead>
        <tbody className={styles.geofence_table_body}>
          {data && data.map(item => (
            <tr className="text-center" key={item.id}>
                <td>
                  {
                    <span className="cursor-pointer m-1" title = "Edit">
                      <img alt="" src={BlueEditIcon} height="15" width="15" onClick={() => toggle(item)} />
                    </span>
                  }
                  <span className="cursor-pointer m-1" title = "Delete">
                    <img alt="" src={BlueDeleteIcon} height="15" width="12" onClick={() => deleteGeofence(item)} />
                  </span>
                </td>
                <td title={item.name} className="text-left">{item.name}</td>
                <td title={item.shape_type} className="text-left">{item.shape_type}</td>
                <td title={item.address} className="text-left">{item.address}</td>
            </tr>
          ))}
          {!data.length &&
            <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
          }
        </tbody>
      </Table>
    </div>
  );
};

JobGeoFenceTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

JobGeoFenceTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default JobGeoFenceTableComponent;
