import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  ACTIVATION_SUCCESS: 'ACTIVATION_SUCCESS',
  ACTIVATION_FAILURE: 'ACTIVATION_FAILURE',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  RESEND_CODE_SUCCESS: 'RESEND_CODE_SUCCESS',
  UPDATE_FAILURE: 'UPDATE_FAILURE',
  COMPANY_SUCCESS: 'COMPANY_SUCCESS',
  CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
};


class ActivateUseCase {
 
  userAPIGateway = null;
  

  constructor(userAPIGateway, paymentAPIGateway) {
    
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
    this.paymentAPIGateway = paymentAPIGateway;
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  toString() {
    return 'activateUseCase';
  }

  setup_data(data) {
    this.code = data.code;
    this.media = data.media;
    
  }

  async execute() {
    let errors = {};
    if (!this.code) {
      errors.code = 'Enter Verification Code To Verify It';
    } 
  
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }
     await this.userAPIGateway.activation(this.code, this.media);
     this.eventEmitter.emit({
       type: EVENT_TYPES.ACTIVATION_SUCCESS,
     });
  }


  async updateCompanyProfile(values, type, companyId) {
    await this.userAPIGateway.updateCompanyProfile(values, companyId);
    const profile = await this.userAPIGateway.getProfile(values, companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_SUCCESS,
      data: type,
      profile: profile,
    });
    
  }

  async resendActivationCodes() {
    await this.userAPIGateway.resendCodes();
    this.eventEmitter.emit({
      type: EVENT_TYPES.RESEND_CODE_SUCCESS,
    });
  }

  async createCustomer(companyId,email, name, phoneNumber) {
    this.customerDetails = await this.paymentAPIGateway.createCustomer(companyId,email, name, phoneNumber);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CREATE_CUSTOMER_SUCCESS,
      detail: this.customerDetails,
    });
  }
}
  

 


export default ActivateUseCase;