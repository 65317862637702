import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_VENDORS = 'vendors/vendor/GET_VENDORS';
const GET_VENDORS_FULFILLED = 'vendors/vendor/GET_VENDORS_FULFILLED';
const SELECT_VENDORS = 'vendors/vendor/SELECT_VENDORS';
const GET_VENDORS_INCOMPLETE_SIGNUP = 'vendors/vendor/GET_VENDORS_INCOMPLETE_SIGNUP';
const GET_VENDORS_INCOMPLETE_SIGNUP_FULFILLED = 'vendors/vendor/GET_VENDORS_INCOMPLETE_SIGNUP_FULFILLED';
const VENDORS_INCOMPLETE_SIGNUP_RESET_PASSWORD = 'vendors/vendor/VENDORS_INCOMPLETE_SIGNUP_RESET_PASSWORD';
const GET_VENDOR = 'vendors/vendor/GET_VENDOR';
const GET_VENDOR_FULFILLED = 'vendors/vendor/GET_VENDOR_FULFILLED';
const GET_BUSINESS_ADMIN_STATS = 'vendors/vendor/GET_BUSINESS_ADMIN_STATS';
const GET_BUSINESS_ADMIN_STATS_FULFILLED = 'vendors/vendor/GET_BUSINESS_ADMIN_STATS_FULFILLED';
const GET_INDUSTRIES = 'vendors/vendor/GET_INDUSTRIES';
const GET_INDUSTRIES_FULFILLED = 'vendors/vendor/GET_INDUSTRIES_FULFILLED';
const POST_VENDOR_FULFILLED = 'vendors/vendor/POST_VENDOR_FULFILLED';
const POST_ACTIVE_STATUS = 'vendors/vendor/POST_ACTIVE_STATUS';
const POST_ACTIVE_STATUS_FULFILLED = 'vendors/vendor/POST_ACTIVE_STATUS_FULFILLED';
const POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK ='vendors/vendor/POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK';
const POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK_FULFILLED ='vendors/vendor/POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK_FULFILLED';
const PUT_VENDOR = 'vendors/vendor/PUT_VENDOR';
const PATCH_VENDOR = 'vendors/vendor/PATCH_VENDOR';
const REQUEST_FEATURES = 'vendors/vendor/REQUEST_FEATURES';
const PUT_VENDOR_FULFILLED = 'vendors/vendor/PUT_VENDOR_FULFILLED';
const GET_STATS = 'vendors/vendor/GET_STATS';
const GET_STATS_FULFILLED = 'vendors/vendor/GET_STATS_FULFILLED';
const SHOW_OTP = 'vendors/vendor/SHOW_OTP';
const SHOW_OTP_FULFILLED = 'vendors/vendor/SHOW_OTP_FULFILLED';
const VERIFY_OTP = 'vendors/vendor/VERIFY_OTP';
const RESEND_OTP = 'vendors/vendor/RESEND_OTP';
const RESEND_OTP_FULFILLED = 'vendors/vendor/RESEND_OTP_FULFILLED';
const BILL_STATUS_DROPDOWN = 'vendor/vendor/PAYMENT_STATUS_DROPDOWN';
const PLAN_CHANGE = 'vendor/vendor/PLAN_CHANGE';
const PLAN_REJECT = 'vendor/vendor/PLAN_REJECT';
const POST_CUSTOM_SETTING = 'vendor/vendor/POST_CUSTOM_SETTING';
const GET_CUSTOM_SETTING = 'vendor/vendor/GET_CUSTOM_SETTING';
const GET_CUSTOM_SETTING_FULFILLED = 'vendor/vendor/GET_CUSTOM_SETTING_FULFILLED';


export function getVendors(params) {
  return {
    type: GET_VENDORS,
    payload: axios.get(URLS.VENDORS, { params }),
  };
}

export function getBillStatusList(){
  return {
    type: BILL_STATUS_DROPDOWN,
    payload: axios.get(`${URLS.BILL_STATUS_DROPDOWN}?entity=companyprofile`),
  };
}

export function verifyOtp(data) {
  return {
    type: VERIFY_OTP,
    payload: axios.post(`${URLS.OTP_VERIFICATION}?media=${data.media}`, data),
  };
}

export function planChange(id) {
  return {
    type: PLAN_CHANGE,
    payload: axios.delete(`${URLS.CANCEL_PLAN}${id}/`),
  };
}

export function planReject(data) {
  return {
    type: PLAN_REJECT,
    payload: axios.post(`${URLS.REJECT_CANCELLATION}`, data),
  };
}

export function selectVendors(data) {
  return {
    type: SELECT_VENDORS,
    payload: data,
  };
}

export function getVendorsIncompleteSignUp(params) {
  return {
    type: GET_VENDORS_INCOMPLETE_SIGNUP,
    payload: axios.get(URLS.VENDORS_INCOMPLETE_SIGNUP, { params }),
  };
}

export function postVendorsIncompleteSignUpActivateLink(data) {
  return {
    type: POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK,
    payload: axios.post(URLS.VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK, data),
  };
}

export function postRequestVendorsIncompleteSignUpResetPassword(data) {
  return {
    type: VENDORS_INCOMPLETE_SIGNUP_RESET_PASSWORD,
    payload: axios.post(URLS.VENDORS_INCOMPLETE_SIGNUP_RESET_PASSWORD, data),
  };
}

export function getVendor(params) {
  return {
    type: GET_VENDOR,
    payload: axios.get(`${URLS.VENDOR}${params.id}/`),
  };
}

export function getbusinessAdminStats(params) {
  return {
    type: GET_BUSINESS_ADMIN_STATS,
    payload: axios.get(URLS.BUSINESS_ADMIN_STATS, { params }),
  };
}

export function putVendor(data, id) {
  return {
    type: PUT_VENDOR,
    payload: axios.put(`${URLS.VENDOR}${id}/`, data),
  };
}

export function patchVendor(data, id) {
  return {
    type: PATCH_VENDOR,
    payload: axios.patch(`${URLS.VENDOR}${id}/`, data),
  };
}

export function getIndustries() {
  return {
    type: GET_INDUSTRIES,
    payload: axios.get(`${URLS.INDUSTRIES}`),
  };
}

export function postVendor(data) {
  return {
    type: PUT_VENDOR,
    payload: axios.post(URLS.VENDOR_REGISTER, data),
  };
}

export function postRequestFeatures(data) {
  return {
    type: REQUEST_FEATURES,
    payload: axios.post(URLS.REQUEST_FEATURES, data),
  };
}

export function postActiveStatus(data) {
  return {
    type: POST_ACTIVE_STATUS,
    payload: axios.post(URLS.UPDATE_ACTIVE_STATUS, data)
  }
}

export function getStats(params) {
  return {
    type: GET_STATS,
    payload: axios.get(`${URLS.STATS}${params.id}/`),
  };
}

export function showOtp(companyId) {
  return {
    type: SHOW_OTP,
    payload: axios.get(`${URLS.SHOW_OTP}${companyId}/`),
  };
}

export function resendOtp() {
  return {
    type: RESEND_OTP,
    payload: axios.put(URLS.RESEND_OTP, {}),
  };
}

export function postCustomSetting(data) {
  return {
    type: POST_CUSTOM_SETTING,
    payload: axios.post(URLS.CUSTOM_SETTING, data)
  }
}

export function getCustomSetting(data) {
  return {
    type: GET_CUSTOM_SETTING,
    payload: axios.get(URLS.CUSTOM_SETTING,  {params: data}),
  }
}

const defaultState = {
  list: {results: []},
  detail: {},
  industries: [],
  activation: { activation_link: '' },
  otpInfo : {},
  vendorsCount : {},
  custom_setting: {},
};

function VERIFY_OTP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}

function SHOW_OTP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}


function GET_VENDORS_FULFILLED_REDUCER(state, action) {
  const vendorList = action.payload && action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    list: vendorList,
    vendorsCount : action.payload.count
  });
}

function GET_VENDORS_INCOMPLETE_SIGNUP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function SELECT_VENDORS_FULFILLED_REDUCER(state, action) {
  const newList = [ ...state.list ];
  for (let i = 0; i < newList.length; i += 1) {
    if (newList[i].id === action.payload.id) {
      newList[i].isSelected = !newList[i].isSelected;
    }
  }
  return Object.assign({}, state, { list: newList});
}

function GET_VENDOR_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_BUSINESS_ADMIN_STATS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_INDUSTRIES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    industries: action.payload,
  });
}

function POST_VENDOR_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    activation: action.payload,
  });
}

function POST_ACTIVE_STATUS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function PUT_VENDOR_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_STATS_FULFILLED_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].timecards_to_be_approved = action.payload.timecards_to_be_approved;
      list.results[i].entries_to_be_corrected = action.payload.entries_to_be_corrected;
    }
  }
  return Object.assign({}, state, { list });
}


function RESEND_OTP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}

function GET_CUSTOM_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    custom_setting: action.payload,
  });
}

const handlers = {
  [GET_VENDORS_FULFILLED]: GET_VENDORS_FULFILLED_REDUCER,
  [GET_VENDORS_INCOMPLETE_SIGNUP_FULFILLED]: GET_VENDORS_INCOMPLETE_SIGNUP_FULFILLED_REDUCER,
  [SELECT_VENDORS]: SELECT_VENDORS_FULFILLED_REDUCER,
  [GET_VENDOR_FULFILLED]: GET_VENDOR_FULFILLED_REDUCER,
  [GET_BUSINESS_ADMIN_STATS_FULFILLED]: GET_BUSINESS_ADMIN_STATS_FULFILLED_REDUCER,
  [GET_INDUSTRIES_FULFILLED]: GET_INDUSTRIES_FULFILLED_REDUCER,
  [POST_VENDOR_FULFILLED]: POST_VENDOR_FULFILLED_REDUCER,
  [POST_ACTIVE_STATUS_FULFILLED]: POST_ACTIVE_STATUS_FULFILLED_REDUCER,
  [POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK_FULFILLED]:
  POST_VENDORS_INCOMPLETE_SIGNUP_ACTIVATE_LINK_FULFILLED_REDUCER,
  [PUT_VENDOR_FULFILLED]: PUT_VENDOR_FULFILLED_REDUCER,
  [GET_STATS_FULFILLED]: GET_STATS_FULFILLED_REDUCER,
  [SHOW_OTP_FULFILLED]: SHOW_OTP_FULFILLED_REDUCER,
  [RESEND_OTP_FULFILLED]: RESEND_OTP_FULFILLED_REDUCER,
  [VERIFY_OTP_FULFILLED_REDUCER] : VERIFY_OTP_FULFILLED_REDUCER,
  [GET_CUSTOM_SETTING_FULFILLED] : GET_CUSTOM_SETTING_FULFILLED_REDUCER,
};


const vendorSelector = state => state.vendors.vendor;

export const vendorList = createSelector(
  vendorSelector,
  instance => instance.list,
);

export const vendorSelectedList = createSelector(
  vendorList,
  instance => instance && instance.length ? instance.filter(item => item.isSelected).map(item => item.id) : [],
);

export const vendorIncompleteSignUpList = createSelector(
  vendorSelector,
  instance => instance.list
);

export const vendorDetail = createSelector(
  vendorSelector,
  instance => instance.detail,
);

export const businessAdminStats = createSelector(
  vendorSelector,
  instance => instance.detail,
);

export const industriesList = createSelector(
  vendorSelector,
  instance => instance.industries,
);

export const vendorActivateLink = createSelector(
  vendorSelector,
  instance => instance.activation,
);

export const otpInfo = createSelector(
  vendorSelector,
  instance => instance.otpInfo,
);

export const vendorCount = createSelector(
  vendorSelector,
  instance => instance.vendorsCount,
);

export const customSetting = createSelector(
  vendorSelector,
  instance => instance.custom_setting,
);

export default createReducer(defaultState, handlers);
