import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import moment from 'moment';

import Table from 'reactstrap/lib/Table';

import SortHeaderComponent from 'components/common/SortHeader';
import NoRecordsComponent from 'employees/components/NoRecords';

import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';
import SectionLoaderAtom from 'atoms/SectionLoader'

import styles from './styles.module.scss';
import {ReactComponent as TimecardIcon} from './timecards.svg';
import {ReactComponent as JobIcon} from './Jobs.svg';


const EmployeeTableComponent = (props) => {
  const { data , isLoading, showExtraInfo, usecase,
    subCompanyEmployeeCount, isOrganizationVisible, location: { pathname ,search }, showableAttributes, dateFormat, timeFormat24Hrs, formatPhoneNumber } = props;
  const query = queryString.parse(search);
  if(isLoading) return <SectionLoaderAtom active />;
  if(!subCompanyEmployeeCount) return <NoRecordsComponent />;
  return (
   <div className="ml-3 mr-4">
      <Table responsive hover className="table-emp">
      <thead className="Header-col">
        <tr>
          <th className="pl-3">&nbsp;</th>
          <th className="text-center">
            Action
          </th>
          {showableAttributes.employee_id &&
            <SortHeaderComponent  ordering="employee_id">Id</SortHeaderComponent>
          }
          {showableAttributes["*first_name"] &&
            <SortHeaderComponent  ordering="user__first_name">First Name</SortHeaderComponent>
          }
          {showableAttributes.last_name &&
            <SortHeaderComponent  ordering="user__last_name">Last Name</SortHeaderComponent>
          }
          <th hidden={!showableAttributes["*username"]}>Username</th>
          {showableAttributes.title &&
            <SortHeaderComponent  ordering="title">Title</SortHeaderComponent>
          }
          {showableAttributes["*user_role"] &&
            <SortHeaderComponent  ordering="employee_role">Role</SortHeaderComponent>
          }
          <th className="text-right" hidden={!showableAttributes["*phone_no"]} >Phone</th>
          {showableAttributes.email &&
            <SortHeaderComponent  ordering="user__email">Email</SortHeaderComponent>
          }
          <th hidden={!showableAttributes.is_active}>Status</th>
          <th hidden={!showableAttributes.tags}>Tags</th>
          <th hidden={!showableAttributes.address}>Address</th>
          <th hidden={!showableAttributes.last_activity}>Last Activity</th>

          <th hidden={!showableAttributes.department}>Department</th>
          <th hidden={!showableAttributes.team}>Team</th>
          <th hidden={!showableAttributes["wage $/hr"]} className="text-right">Wage $/hr</th>
          <th hidden={!showableAttributes.hire_date} className="text-left pr-3">Hire Date</th>
          <th hidden={!showableAttributes.kiosk_user} className="text-center">Kiosk User</th>
          <th hidden={!showableAttributes.offline_mode} className="text-center">Offline Mode</th>
          <th hidden={!showableAttributes.dual_access} className="text-left pr-3">Dual Access</th>
          

        </tr>
      </thead>
      <tbody>
        { data.results && data.results.map((item, index) => {
          return (<tr key={index}>
            <td colSpan="21" className="p-0">
              <Table
                className={`${styles['child-table']} mb-0 mt-0`}
              >
                <tbody>
                  <tr key={item.id}>
                    <td title="Select to Mark Active/Inactive">
                      <input
                        type="checkbox"
                        value={item.isSelected}
                        onChange={() => usecase.toggleSelection(item, props.selectedEmployees)}
                        checked={props.selectedEmployees && props.selectedEmployees.length && props.selectedEmployees.find((i) => i.id == item.id)}
                      />
                    </td>
                    <td className="text-center">
                      <Link to={{
                        pathname: `/employee/${item.id}/edit/detail`,
                        search: queryString.stringify({
                          parent: `${pathname}${search}`,
                        })
                      }} title="Edit Employee" >
                        <EditIconAtom />
                      </Link>
                      <Link to={{
                        pathname: `/timecard/historic`,
                        search: queryString.stringify({
                          payroll_id: 'custom',
                          employee_id:item.id,
                          parent: JSON.stringify(query),
                          first_name: item.first_name,
                          last_name: item.last_name,
                          end_date: moment().endOf('month').format('YYYY-MM-DD'),
                          start_date : moment().startOf('month').format('YYYY-MM-DD'),
                          empployee_dept_name: item.department_name,
                          employee_team_name: item.team_name,
                          employee_id_show: item.employee_id,
                          employee_title: item.title,
                        }),
                      }} className="ml-2" title="View Timecard" >
                        <TimecardIcon height="16" width="16" />
                      </Link>
                      <Link to={{
                        pathname: `/employee/${item.id}/jobs/list`,
                        search: queryString.stringify({
                          parent: JSON.stringify(query),
                          first_name: item.first_name,
                          last_name: item.last_name,
                        }),
                      }} className="ml-2" title="Edit Job" >
                        <JobIcon height="16" width="16" />
                      </Link>
                    </td>
                    <td hidden={!showableAttributes.employee_id} title={item.employee_id}>{item.employee_id}</td>
                    <td hidden={!showableAttributes["*first_name"]} title={item.first_name}>{item.first_name}</td>
                    <td hidden={!showableAttributes.last_name} title={item.last_name}>{item.last_name}</td>
                    <td hidden={!showableAttributes["*username"]} title={item.username}>{item.username}</td>
                    <td hidden={!showableAttributes.title} title={item.title}>{item.title}</td>
                    <td hidden={!showableAttributes["*user_role"]} title={item.employee_role}>{item.employee_role}</td>
                    <td hidden={!showableAttributes["*phone_no"]} title={formatPhoneNumber(item.phone_no)} className="text-right">{item.phone_no && <span>{item.phone_ext} {formatPhoneNumber(item.phone_no)}</span>} </td>
                    <td hidden={!showableAttributes.email} title={item.email}>{item.email}</td>
                    {item.is_active ? <td hidden={!showableAttributes.is_active}>Active</td> : <td hidden={!showableAttributes.is_active}>Inactive</td> }
                    <td hidden={!showableAttributes.tags} title={item.tags && item.tags.length && item.tags.map((item)=> `${item}  `)}>{item.tags && item.tags.length ? item.tags.map((item, index)=> `${item} `) : null}</td>
                    <td hidden={!showableAttributes.address} title={`${item.address_line_one} ${item.address_line_two}`}>{item.address_line_one} {item.address_line_two}</td>
                    <td hidden={!showableAttributes.last_activity} title={timeFormat24Hrs ? item.modified_date_display && moment(item.modified_date_display).format(`${dateFormat}, HH:mm`) : item.modified_date_display && moment(item.modified_date_display).format(`${dateFormat}, hh:mm a`)}>{timeFormat24Hrs ? item.modified_date_display && moment(item.modified_date_display).format(`${dateFormat}, HH:mm`) : item.modified_date_display && moment(item.modified_date_display).format(`${dateFormat}, hh:mm a`)}</td>
                    <td hidden={!showableAttributes.department} title={item.department_name}>{item.department_name}</td>
                    <td hidden={!showableAttributes.team} title={item.team_name}>{item.team_name}</td>
                    <td 
                        hidden={!showableAttributes["wage $/hr"]} title={item.wage}
                        className="text-right">
                          {item.wage}
                    </td>
                    <td hidden={!showableAttributes.hire_date} title={item.hire_date_display}>{item.hire_date_display}</td>
                    <td 
                        hidden={!showableAttributes.kiosk_user} title={item.kiosk_user ? 'Yes' : 'No'}
                        className="text-center">
                      <ActiveIconAtom active={item.kiosk_user} />
                    </td>
                    <td 
                        hidden={!showableAttributes.offline_mode} title={item.offline_mode ? 'Yes' : 'No'}
                        className="text-center">
                      <ActiveIconAtom active={item.offline_mode} />
                    </td>
                    <td 
                        hidden={!showableAttributes.dual_access} title={item.dual_login ? 'Yes' : 'No'}
                        className="text-left pr-3">{item.dual_login ? 'Yes' : 'No'}
                    </td>
                    </tr>
                </tbody>
              </Table>
              {showExtraInfo &&
                <div className={`${styles['highlight-text']}`}>
                  <span>{isOrganizationVisible && item.company_name ?
                    (<span><i>{'('}{item.company_name}</i>
                    </span>):null}
                  </span>
                  <span>{!isOrganizationVisible  && item.company_name && item.department_name ?
                    (<span><i>{'('}</i>
                    </span>):null}
                  </span>
                  <span>{isOrganizationVisible && item.company_name && !item.department_name ?
                    (<span><i>{')'}</i>
                    </span>):null}
                  </span>
                  <span>{item.company_name && item.department_name && isOrganizationVisible ?
                    (<span><i>{'>'}</i>
                    </span>):null}
                  </span>
                  <span>{item.department_name && item.company_name ?
                    (<span><i>{item.department_name}</i>
                    </span>):null}
                  </span>
                  <span>{item.department_name && !item.company_name ?
                    (<span><i> {'('} {item.department_name}</i>
                    </span>):null}
                  </span>
                  <span>{item.department_name && !item.team_name ?
                    (<span><i>{')'}</i>
                    </span>):null}
                  </span>
                  <span>{item.department_name && item.team_name ?
                    (<span><i> {'>'} {item.team_name}{')'}</i>
                    </span>):null}
                  </span>
                </div>
              }
            </td>
          </tr>
        )})
        }
        {!data.count &&
          <tr><td colSpan="10" className='text-center'> No Records Found </td></tr>
        }
      </tbody>
    </Table>
   </div>
  );
};

EmployeeTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  updateSelected: PropTypes.func,
  location: PropTypes.object.isRequired,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
};

EmployeeTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EmployeeTableComponent;