import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormText from 'reactstrap/lib/FormText';
import Loader from 'atoms/Loader';
import styles from './styles.module.scss';

class AsyncMultiSelectAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.state = {
      isOpen: false,
      selectedItems: props.input.value || [],
      //items: props.items || [],
      items:(Array.isArray(props.items)? props.items:[props.items])|| [],
      inputValue: "",
      isLoading: false,
    };
  }


  componentWillMount(){
    if(this.props.defaultSelectedItem){
      this.setState(({ selectedItems }) =>  ({ selectedItems : this.props.defaultSelectedItem }));
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({items: nextProps.items });
    const {selectedItems} = this.state;
    const { input } = this.props;
    const filteredSelected = selectedItems.length && nextProps.items && nextProps.items.length ?  selectedItems.filter((elem) => (nextProps.items).find(({ id }) => elem.id === id)) : [];
    if(nextProps.input.value !== this.props.input.value){
      this.setState({ selectedItems: nextProps.input.value ? nextProps.input.value : [] });
    }
    if(filteredSelected.length !== this.state.selectedItems.length){
      this.setState({selectedItems: filteredSelected});
      input.onChange(filteredSelected);
    }
    
  }

  shouldComponentUpdate(nextProps){
    if((this.props.items && this.props.items.length) !== (nextProps.items && nextProps.items.length)){
      this.setState(({ items }) => ({ items: nextProps.items }));
      return false;
    }
    return true;
  }

  handleChange(item) {
    const { selectedItems } = this.state;
    if (selectedItems.filter(i => item.id === i.id).length) {
      this.removeItem(item);
    } else {
      this.addSelectedItem(item);
      
    }
  }

  handleInputChange(e) {
    const { itemToString } = this.props;
    this.setState({inputValue: e.target.value})
    e.stopPropagation();
    const rx = new RegExp(e.target.value, 'gi');
    this.setState({ items: this.props.items.filter(i => itemToString(i).match(rx)) });
  }

  addSelectedItem(item) {
    const { input } = this.props;
    this.props.addItem && this.props.addItem(item);
    this.setState({inputValue: ""});
    this.setState({items: this.props.items});
    this.setState(({selectedItems}) => ({
      selectedItems: [...selectedItems, item],
    }));
    input.onChange([...this.state.selectedItems, item]);
  }

  /* eslint-disable */
  removeItem(item) {
    const { input } = this.props;
    this.props.removedItem && this.props.removedItem(item);
    this.setState(({ selectedItems }) => {
      return {
        selectedItems: selectedItems.filter(i => item.id !== i.id),
      };
    });
    input.onChange(this.state.selectedItems.filter(i => item.id !== i.id));
  }
  /* eslint-enable */

  // handleStateChange(changes) {
  //   const { isOpen, type } = changes;
  //   if (type === Downshift.stateChangeTypes.mouseUp) {
  //     this.setState({ isOpen });
  //   }
  // }

  handleToggleMenu() {
    const { items, getData } = this.props;

    if(!(items && items.length)) {
      this.setState({ isLoading: true });
      return getData()
        .then(() => this.setState(({ isOpen, isLoading }) => ({ isOpen: !isOpen, isLoading: false })));
    }
    return this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  }

  render() {
    const {
      input,
      label,
      id,
      itemToString,
      help,
      placeholder,
      disabled,
      meta: { touched, error },
    } = this.props;
    const { isOpen, items, selectedItems, inputValue, isLoading } = this.state;

    const filteredSelected = selectedItems.filter((elem) => items.find(({ id }) => elem.id === id));
    return (
      /* eslint-disable */
      <Downshift
        itemToString={itemToString}
        onSelect={this.handleChange}
        // onStateChange={this.handleStateChange}
        defaultSelectedItem={filteredSelected}
        selectedItem={filteredSelected}
        isOpen={isOpen}
        onOuterClick={this.handleToggleMenu}
        render={({
          getInputProps,
          getButtonProps,
          getItemProps,
          getLabelProps,
          isOpen,
          selectedItem,
          highlightedIndex,
        }) => {
          return (<div style={{ position: 'relative' }} className="custom-multi-select">
            {label &&
              <label
                {...getLabelProps({
                  htmlFor: id,
                  className: 'col-form-label',
                  style: { width: '100%' },
                })}
              >
                {label}
              </label>
            }
            {/* eslint-enable */}
            <div
              {...getButtonProps({
                className: disabled ? `${styles['multi-select']} ${styles['multi-select-disabled']} muti-select-wrap` :`${styles['multi-select']} muti-select-wrap`,
                onClick: !disabled && this.handleToggleMenu,
                disabled: disabled
              })}
            >
              <div className={styles['multi-select__content']}>
                {selectedItem && selectedItem.length > 0 &&
                  selectedItem.map(item =>
                    <label
                      key={item.id}
                      className={styles['multi-select__chip']}
                      htmlFor="chip"
                    >
                      {itemToString(item)}
                      <span
                        className="pointer"
                        role="button"
                        tabIndex="0"
                        onClick={e => {
                          e.stopPropagation();
                          this.removeItem(item);
                        }}
                      >
                        &nbsp;x
                      </span>
                    </label>,
                  )
                }
                <input
                  {...getButtonProps({
                    placeholder: selectedItem.length ? '' : placeholder,
                    onChange: this.handleInputChange,
                    onClick: (e) => {
                      if (isOpen) e.stopPropagation();
                    },
                    disabled: disabled,
                    value: inputValue,
                    className: styles['multi-select__input']
                  })}
                    data-lpignore={true}
                    autoComplete="off"
                />
              </div>
              { isLoading ? 
                <span style={{ position: 'absolute', top: '29px', right: '9px' }} className="d-inline-block loader-pos"><Loader active /></span>
                : <span onClick = {!disabled && this.handleToggleMenu } style={{ position: 'absolute' }} className={disabled ? `${styles['dropdown-arrow-disabled']} dropdown-arrow d-inline-block` : "dropdown-arrow d-inline-block"} />}
            </div>
            {isOpen ? (
              <div className={styles['multi-select__dropdown']}>
                {items && items.length
                  ? items.map((item, index) => (
                    <div
                      key={item.id}
                      {...getItemProps({item})}
                      style={{
                        backgroundColor:
                          highlightedIndex === index ? '#9CDAD7' : 'white',
                        fontWeight: filteredSelected.filter(i => item.id === i.id).length > 0
                          ? 'bold' : 'normal',
                      }}
                      className="cursor-pointer advance-filter-drop"
                    >
                      {itemToString(item)}
                    </div>
                  ))
                  : <div className={styles['multi-select__no-results']}>No Results Found</div>
                }
              </div>
            ) : null}
            <FormText color="muted">{help}</FormText>
            <FormFeedback>{touched && error}</FormFeedback>
          </div>);
        }}
      />
    );
  }
}

AsyncMultiSelectAtom.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
  getData: PropTypes.func,
};

export default AsyncMultiSelectAtom;
