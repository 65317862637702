import React from 'react';

import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import SectionLoaderAtom from 'atoms/SectionLoader';

import JobBulkUploadFormComponent from 'components/jobs/list/BulkUploadForm';
/**
 * JobBulkListPage -> JobBulkUploadSection
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class JobBulkUploadSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.sampleFile = this.sampleFile.bind(this);
    this.state={errorEmp:{}, stats:{}, csvData : "" , isBulkLoading: false}
  }
  
  update(data){
    let billType, jobType;
    const {location: { query } } = this.props;
    this.setState({isBulkLoading: true});
    if(query.type == 'quotedRecurring'){
      billType = 'QUOTED';
      jobType = 'RECURRING';
    }
    else if(query.type == 'quotedProject'){
      billType = 'QUOTED';
      jobType = 'PROJECT';
    }
    else if(query.type == 'timeAndMaterials'){
      billType = 'TIME_AND_MATERIAL';
    }
    else{
      billType = 'QUOTED';
      jobType = 'RECURRING';
    }
    return this.props.JobDucks.postBulkJob(data, {bill_type : billType, job_type: jobType})
      .then((res) => {
        if(res.value.stats && (res.value.stats.created === res.value.stats.total)){
          toast.success("Jobs Uploaded Succesfully")
        }
        else if(res.value.stats && (res.value.stats.updated === res.value.stats.total)){
          toast.success("Jobs Updated Succesfully")
        }
        else{
          toast.success("Jobs Updated Succesfully")
        }
        this.setState({ errorEmp : {}, stats : res.value.stats, csvData : {}, isBulkLoading: false})
      })
      .catch((err) => {
        if(err.error){
          toast.error(err.error)
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }  
        else if(err.job){
          toast.error(err.job)
          this.setState({ errorEmp : err, stats : err.stats, csvData : err.data_csv})
        }  
        else if(err.stats && !(err.stats.created) && !(err.stats.updated)){
          toast.error("Unable to upload jobs");
        }
        else if(err.stats && (err.stats.updated === err.stats.total)){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        else if(err.stats && ((err.stats.create_failed !== err.stats.total) || (err.stats.update_failed !== err.stats.total))){
          toast.error("Some values are not updated successfully. Please review the errors below and resubmit");
        }
        this.setState({ errorEmp : err.data, stats: err.stats, csvData : err.data_csv, isBulkLoading: false})
      })
  }

  sampleFile(){
    const {location: { query } } = this.props;
    let billType, jobType;
    if(query.type == 'quotedRecurring'){
      billType = 'QUOTED';
      jobType = 'RECURRING';
    }
    else if(query.type == 'quotedProject'){
      billType = 'QUOTED';
      jobType = 'PROJECT';
    }
    else if(query.type == 'timeAndMaterials'){
      billType = 'TIME_AND_MATERIAL';
    }
    else{
      billType = 'QUOTED';
      jobType = 'RECURRING';
    }
    return this.props.JobDucks.getBulkJob({bill_type : billType, job_type: jobType});
  }


  render() {
    const { isLoading } = this.props;
    const { errorEmp , stats, csvData, isBulkLoading} = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <JobBulkUploadFormComponent 
          update={this.update}
          errorEmp={errorEmp}
          sampleFile={this.sampleFile}
          stats={stats}
          csvData={csvData}
          isBulkLoading={isBulkLoading}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobBulkUploadSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

JobBulkUploadSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobBulkUploadSection);