import React from 'react';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { NAV } from 'constants/employees';
import TwoColumnLayout from 'layouts/TwoColumn';
import { toast } from 'react-toastify';

import EmployeeJobsFilterFormComponent from 'employees/components/employeeJobsFilterForm';
import EmployeeJobsTableComponent from 'employees/components/employeesJobTable.jsx'
import PaginationComponent from 'components/common/Pagination';
import employeeJobsListUseCase from 'employees/usecases/employeeJobsListUseCase';
import EmployeeAPIGateway from 'employees/gateways/employee';

// page for mass jobs assign
class EmployeeJobsListPage extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state={
			assignedJobs: {},
			unAssignedJobs: [],
			assignedJobscount: null,
			isLoading: false,
			isInfoOpen: true,
			infoList: [],
			assignedStatus: true,
			isButtonLoading: false,
		}
		this.updateState = this.updateState.bind(this);
		const employeeJobsAPIGateway = new EmployeeAPIGateway();
		this.usecase = new employeeJobsListUseCase(employeeJobsAPIGateway);
		this.listenListUsecase = this.listenListUsecase.bind(this);
		this.fetchData= this.fetchData.bind(this);
		this.deleteEmployeeJob= this.deleteEmployeeJob.bind(this);
		this.toggleInfo = this.toggleInfo.bind(this);
	}

	updateState(key, value) {
		this.setState({
			[key]: value,
		});
	}

	componentWillMount(){
		const { location: { search } } = this.props;
		const query = queryString.parse(search);
		this.listenListUsecase();
		this.fetchData(query);
	}

	componentWillReceiveProps(nextProps) {
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    const { show_extra_info: prevInfo,  ...prevRest } = prevQuery;
    const { show_extra_info: nextInfo,  ...rest } = nextQuery;

    if((prevInfo !== nextInfo) && (JSON.stringify(prevRest) === JSON.stringify(rest))){
      this.updateState('showExtraInfo', JSON.parse(nextInfo));
    }
    else if (prevSearch !== nextSearch) {
      this.fetchData(nextQuery);
    }
  }


	fetchData(query){
		const pageSize = cookie.load("jobs_page_size");
		const { router: { route: { match: { params } } } } = this.context;
		const unassignJobObj = {
			status: 1,
			admin: true,
			employee_id: params.id,
			assigned: !this.state.assignedStatus,
			paginate: false,
		};
		const assignJobObj = {
			status: 1,
			employee_id: params.id,
			assigned: this.state.assignedStatus,
			search: query  ? query.search : null,
			page : query && query.page ? query.page : 1,
      		page_size : pageSize,
      		admin:true,
      		ordering: "name"
		};
		/*this.usecase.getAssignedJobs(assignJobObj);
		this.usecase.getUnassignedJobs(unassignJobObj);*/
		this.usecase.setup_data(assignJobObj, unassignJobObj);
		this.usecase.fetchJobs(assignJobObj, unassignJobObj);
	}	

	listenListUsecase() {
		this.usecase.getObservable().subscribe((event) => {
			switch (event.type) {
				case 'SHOW_ASSIGNED_JOBS':
					this.updateState('assignedJobs', event.data.results);
					this.updateState('assignedJobscount', event.data.count)
					break;
				case 'SHOW_UNASSIGNED_JOBS':
					this.updateState('unAssignedJobs', event.unassignedJobs);
					break;
				case 'ASSIGN_SUCCESS':
					toast.success('Job has been assigned successfully!!!');
					break;
				case 'DELETE_SUCCESS':
					toast.success('Job has been deleted successfully!!!');
					break;
				case 'SHOW_LOADER':
					this.updateState('isLoading', true);
					break;
				case 'HIDE_LOADER':
					this.updateState('isLoading', false);
					break;
				case 'HIDE_ASSIGN_BUTTON_LOADER':
					this.updateState('isButtonLoading', false);
					break;
				case 'SHOW_ASSIGN_BUTTON_LOADER':
					this.updateState('isButtonLoading', true);
					break;
				default:
					console.log('Sorry, we are not handling this');
			}
		});
	}

	deleteEmployeeJob(job){
		const { router: { route: { match: { params } } } } = this.context;
		const { history, location: { search , pathname} } = this.props;
		const query = queryString.parse(search);
		const deleteJobObj = {
			assigned: !this.state.assignedStatus,
			employee_ids: [params.id],
			id: job.id ,
		};
		this.usecase.deleteJob(deleteJobObj)
		.then(() =>{
			const { page , ...rest } = query;
			history.push({
	      pathname, search: queryString.stringify({ ...rest, page: 1 }),
	    });
		});
	}

	toggleInfo(){
		const { isInfoOpen } = this.state;
		this.updateState('isInfoOpen', !isInfoOpen );
	}

	render() {
		const { match: { params }, location: { search } } = this.props;
		const query = queryString.parse(search);
		const { assignedJobs, unAssignedJobs, assignedJobscount,  infoList, isInfoOpen, isLoading, isButtonLoading  } = this.state;
		let title;
		title = NAV.EmployeeJobsListPage.title;
		title += ` (${query.first_name +" "+ query.last_name}) `;
		return (
			<TwoColumnLayout
				navInfo={{ ...NAV.EmployeeJobsListPage, title }}
				fetchData={() => this.fetchData(query)}
				infoList={infoList}
				toggleInfo={this.toggleInfo}
				isInfoOpen={isInfoOpen}
				isLoading={isLoading}
			>
				<EmployeeJobsFilterFormComponent
					location={this.props.location}
					history={this.props.history}
					usecase={this.usecase}
					unAssignedJobs={unAssignedJobs}
					params={params}
					isButtonLoading={isButtonLoading}
					isLoading={isLoading}
					assignedJobscount={assignedJobscount}
				/> 
				<EmployeeJobsTableComponent
				assignedJobs={assignedJobs}
				deleteEmployeeJob={this.deleteEmployeeJob}
				isLoading={isLoading}
				/>
				{assignedJobscount && !isLoading ?
				<PaginationComponent
					count={assignedJobscount}
					location={this.props.location}
					cookieKey="jobs_page_size"
					history={this.props.history}
				/> : null}
			</TwoColumnLayout>
		);
	}

}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
	dispatch: dispatch
});

EmployeeJobsListPage.contextTypes = {
	router: PropTypes.object.isRequired,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EmployeeJobsListPage);
