import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as SubCompanyDucks from 'ducks/vendors/subCompany';

import VendorSubCompanyUserFormComponent from 'components/vendors/subCompanyUserForm/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/vendors';

/**
 * VendorSubCompanyUserFormPage -> VendorSubCompanyUserFormSection
 *
 * Components:
 *    - {@link VendorSubCompanyUserFormComponent}
 *
 * State:
 *    - vendorSubCompanyUserDetail
 *
 * Actions:
 *    - Add Sub Company User
 */
class VendorSubCompanyUserFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.create = this.create.bind(this);
    this.goTo = this.goTo.bind(this);
  };

  create(data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const  detail = data;
    detail.company_id = params.id;
    return this.props.SubCompanyDucks.postSubCompanyUser(detail)
      .then(() => {
        toast.success(MSGS.VENDOR_ADD_SUCCESS);
        history.push(`/vendor/sub-company/`);
      }); 
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const { isLoading , subCompanyDetail} = this.props;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <VendorSubCompanyUserFormComponent
        create={this.create}
        goTo={this.goTo}
        initialValues={{...subCompanyDetail, first_name:subCompanyDetail && subCompanyDetail.user && subCompanyDetail.user.first_name,last_name:subCompanyDetail && subCompanyDetail.user &&  subCompanyDetail.user.last_name, email:subCompanyDetail && subCompanyDetail.user &&  subCompanyDetail.user.email, username:subCompanyDetail && subCompanyDetail.user &&  subCompanyDetail.user.username }}
      />
    );
  }
}

const mapStatetoProps = (state) => ({
  subCompanyDetail: SubCompanyDucks.subCompanyDetail(state),
});

const mapActionToProps = dispatch => ({
  SubCompanyDucks: bindActionCreators(SubCompanyDucks, dispatch),

});

VendorSubCompanyUserFormSection.propTypes = {
  SubCompanyDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

VendorSubCompanyUserFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
  mapActionToProps,
)(VendorSubCompanyUserFormSection);
