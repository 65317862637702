import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Button from 'reactstrap/lib/Button';

/**
 * HomePage -> HomeHeroSection -> HomeGetStartedComponent
 *
 * Props:
 *    None
 */
const HomeGetStartedComponent = (props) => (
  <Link to={Object.keys(props.profile).length ? !props.profile.is_business_admin  ? '/dashboard' : '/vendor/list/all' : '/accounts/login'}>
    <Button>
      Go to {Object.keys(props.profile).length ? 'Dashboard' : 'Login'}
    </Button>
  </Link>
);

HomeGetStartedComponent.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default HomeGetStartedComponent;