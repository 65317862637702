import React from 'react';

import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';

class CheckboxAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { value: props.input.value };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.value) {
      this.setState({ value: nextProps.input.value });
    }
  }

  // handleChange(val) {
  //   this.props.input.onChange(val.target.checked);
  //   this.setState({ value: val.target.checked });
  // }

  handleChange(val) {
    const {onSelect} = this.props;
    this.props.input.onChange(val.target.checked);
    if (onSelect) {
      onSelect(val.target.name,val.target.checked);
    }
    this.setState({ value: val.target.checked });
  };

  render() {
    const { input, checked , layout, label, help,onSelect, meta: { touched, error }, ...rest } = this.props;
    const { value } = this.state;
    return (
      <FormGroup
        row={Object.keys(layout).length > 0}
        color={touched && error ? 'danger' : ''}
      >
        <Col>
          {Object.keys(layout).length > 0
            ? (<span>
              <FormGroup check>
                <Label check>
                  <Input
                    {...rest}
                    name={input.name}
                    checked={(checked == false || checked == true) ? checked : value}
                    type="checkbox"
                    sm={80}
                    onChange={(val) => this.handleChange(val)}
                  />
                </Label>
              </FormGroup>
            </span>)
            : (<span>
              <FormGroup check>
                <Label check>
                  <Input
                    {...rest}
                    name={input.name}
                    checked={(checked == false || checked == true) ? checked : value}
                    type="checkbox"
                    onChange={(val) => this.handleChange(val)}
                  /> {label}
                </Label>
              </FormGroup>
            </span>)
          }
          {label && Object.keys(layout).length > 0 &&
            <Label for={rest.id}>{label}</Label>
          }
          <FormText color="muted">{help}</FormText>
          {touched && error &&
            <FormFeedback>{touched && error}</FormFeedback>
          }
        </Col>
      </FormGroup>
    );
  }
}

CheckboxAtom.defaultProps = {
  layout: {},
};

CheckboxAtom.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  layout: PropTypes.object,
  label: PropTypes.string,
  help: PropTypes.string,
  onChange: PropTypes.func,
};

export default CheckboxAtom;




// onChange={this.handleChange}
