import React from "react";

const MaintenanceMode = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#09819A",
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "150px",
          height: "150px",
          background: "white",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="https://img.icons8.com/ios-filled/75/09819A/maintenance.png"
          alt="Maintenance Icon"          
        />
      </div>
      <h1 style={{ margin: "20px 0 10px", fontSize: "32px", color: "white" }}>
        Sorry, we're down for maintenance.
      </h1>
      <p style={{ margin: 0, fontSize: "18px", color: "white" }}>
        We'll be back shortly.
      </p>
    </div>
  );
};

export default MaintenanceMode;
