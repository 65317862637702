import ApiGateway from "lib/api-gateway";
import AppConfig from "helpers/config";
import axios from "helpers/interceptor";

import {
  FieldFormsEntity,
  formsResponseHeaderEntity,
  formsResponceDataEntity,
} from "forms/entities/fieldform";
import TemplateDataEntity from "forms/entities/templateData";
import AssignedEmployeeEntity from "forms/entities/assignedEmployee";

class FormsAPIGateway {
  toString() {
    return "FormsAPIGateway";
  }

  async getDepartmentList(params) {
    const departmentListConfig =
      AppConfig.server.apiHost + "/company/departments/";
    const apiGateway = new ApiGateway(departmentListConfig);
    const response = await apiGateway.load(params);
    const departmentList = response.data;
    return departmentList;
  }

  async getTeamList(params) {
    const teamListConfig = AppConfig.server.apiHost + "/company/teams/";
    const apiGateway = new ApiGateway(teamListConfig);
    const response = await apiGateway.load(params);
    const teamList = response.data;
    return teamList;
  }

  async getFormsList(params) {
    const listConfig = AppConfig.server.apiHost + "/feedback/form/";
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map((i) => new FieldFormsEntity(i));
    return { ...response.data, results: entity, count: response.data.count };
  }

  async createForm(params) {
    const postDataConfig = AppConfig.server.apiHost + "/feedback/template/";
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.save(params);
    return response;
  }

  async sendReminders(params) {
    const postDataConfig = AppConfig.server.apiHost + "/t2b/nre/send-reminder/";
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.save(params);
    return response;
  }

  async multiFormAssign(params) {
    const postDataConfig =
      AppConfig.server.apiHost + "/feedback/form/bulk-assign-employees/";
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.save(params);
    return response;
  }

  async updateForm(params) {
    const { id, ...rest } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/template/${id}/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.update(rest);
    const entity = new TemplateDataEntity(response.data);
    return {
      data: {
        template_data: entity,
      },
    };
  }

  async getFormData(params) {
    const { id, ...payload } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/template/${id}/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.load(payload);
    const entity = new TemplateDataEntity(response.data);
    return {
      data: {
        template_data: entity,
      },
    };
  }

  async deleteForm(params) {
    const { id } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/template/${id}/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.delete();
    return response;
  }

  async getResponceData(params) {
    const { id, ...payload } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/form/${id}/response/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.load(payload);
    const headerEntity = response.data?.results?.header.map((i) => i.question);

    const answerEntity = response.data?.results?.responses.map((i) =>
      getAnswerList(response.data?.results?.header, i)
    );
    return {
      headers: headerEntity,
      data: answerEntity,
      count: response.data.count,
    };
  }

  async updateResponceData(params) {
    const { id, ...payload } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/response/${id}/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.partialUpdate(payload);
    return response;
  }



  async toggleStatusForm(params) {
    const { id, ...payload } = params;
    const changeStatusConfig =
      AppConfig.server.apiHost + `/feedback/template/${id}/`;
    const apiGateway = new ApiGateway(changeStatusConfig);
    const response = await apiGateway.update(payload);
    return response;
  }

  async getCountOfResponses(params) {
    const { id, ...payload } = params;
    const listConfig =
      AppConfig.server.apiHost + `/feedback/form/response-count/${id}/`;
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(payload);
    return response.data;
  }

  async assignAndDeleteEmployeesFromForms(params) {
    const { id, ...payload } = params;
    const postDataConfig =
      AppConfig.server.apiHost + `/feedback/form/assign-employees/${id}/`;
    const apiGateway = new ApiGateway(postDataConfig);
    const response = await apiGateway.save(payload);
    return response;
  }

  async getAssignedEmployeesList(params) {
    const listConfig =
      AppConfig.server.apiHost +
      `/feedback/form/assign-employees/${params.form_id}/`;
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map(
      (i) => new AssignedEmployeeEntity(i)
    );
    return {
      results: entity,
      count: response.data.count,
    };
  }

  async handleExport(params) {
    const { id, ...payload } = params;
    const response = await axios.get(
      AppConfig.server.apiHost + `/feedback/form-export/${id}/`,
      {
        responseType: "arraybuffer",
        params: payload,
      }
    );
    return response;
  }
}

const getAnswerList = (headers, entity) => {
  let arr = [];
  headers.forEach((item) => {
    if (item.definition === "SIGNATURE" || item.definition === "FILE_UPLOAD") {
      arr.push(`form_file${entity[item.key][0]?.value ?? ""}`);
    } else if (
      item.key !== "name" &&
      item.key !== "status" &&
      item.key !== "submit_date"
    ) {
      const value = entity[item.key].reduce((prvValue, curValue) => {
        if (prvValue === "") {
          return prvValue + curValue.value;
        } else {
          return prvValue + " , " + curValue.value;
        }
      }, "");
      arr.push(value === "" ? "-" : value);
    } else {
      arr.push(entity[item.key]);
      
    }
  });
  arr.push(entity.id,entity.note)
  return arr;
};

export default FormsAPIGateway;
