import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import DateTimePickerAtom from 'atoms/DateTimePicker';

import { FILTER_FORM_LAYOUT } from 'constants/layout';

const OvertimeDetailFilterComponent = (props, context) => {
  const { router: { history } } = context;
  const { dateFormat, location: { pathname, query }, handleSubmit } = props;
  const { page, ...rest } = query;

  const submit = data => {
    const detail = {};
    detail.start_date = moment(data.start_date).format('YYYY-MM-DD');
    detail.end_date = moment(data.end_date).format('YYYY-MM-DD');
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...detail }),
    });
  };

  return (
    <Form inline onSubmit={handleSubmit(submit)} className="report-filter">
      <Field
        id="start_date"
        name="start_date"
        placeholder="Start Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Field
        id="end_date"
        name="end_date"
        placeholder="End Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Button type="submit" color="accent" size={FILTER_FORM_LAYOUT.size} className="mb-4 pl-4 pr-4">
        Apply
      </Button>
    </Form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  const formattedStartDate=moment(values.start_date).format();
  const formattedEndDate=moment(values.end_date).format();
  if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
  return errors;
};

OvertimeDetailFilterComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

OvertimeDetailFilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'OvertimeSummaryFilterForm',
  validate,
})(OvertimeDetailFilterComponent);
