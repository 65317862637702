import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import TwoColumnLayout from 'layouts/TwoColumn';
import GeoFenceListSection from 'sections/geoFences/list/List';
import GeoFenceFilterSection from 'sections/geoFences/list/Filter';
import GeoFenceMapSection from 'sections/geoFences/list/Map';

import { NAV } from 'constants/geoFences';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

/**
 * GeoFenceListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link GeoFenceFilterSection}
 *    - {@link GeoFenceListSection}
 *
 * Fetch Needs:
 *    - getPayrolls
 */
class GeoFenceListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true, parent: NAV.GeoFenceListPage };
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.findMe = this.findMe.bind(this);
    this.locationAccessed = this.locationAccessed.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("geofence_info");
    this.findMe();

    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }

    this.props.InfoDucks.getInformations({route: 'geofence.list'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }
  
  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
    this.props.ActivityTrackDucks.resetBrowserLocation();
  }

  setParentUrl(query) {
    let parent;
    if (query.job_id) {
      parent = `/job/${query.job_id}/edit`;
    } else {
      parent = `/job/add`;
    }

    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ parent });
    }
  }

  locationAccessed(position) {
    var latitude  = position.coords.latitude;
    var longitude = position.coords.longitude;
    const data= {position : {lat : latitude, lng : longitude }};
    this.props.ActivityTrackDucks.getActivityLocationAddress(data , 'locationIq');
  }

  findMe() {
    if (!navigator.geolocation){
     console.log("Geolocation is not supported by your browser");
      return;
    }
    navigator.geolocation.getCurrentPosition(this.locationAccessed);
  }

  fetchData(params) {
    let pageSize;
    if (params.view) pageSize = cookie.load('geo_fence_map_page_size');
    else pageSize = cookie.load('geo_fence_list_page_size');

    this.setState({ isLoading: true });

    return this.props.GeoFenceDucks.getGeoFences({...params, page_size: pageSize})
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { location: { pathname, query } } = this.props;
    const { router: { history } } = this.context;

    this.setState({ isLoading: true });
    this.setParentUrl(query);
    if (!('is_active' in query) || !('page_size' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          is_active: true,
        }),
      });
    } else {
      this.fetchData(query);
    }  
  }


  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("geofence_info", !isInfoOpen);
  }

  render() {
    const { infoList, isGeoFenceVisible, location: { query } } = this.props;
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: '/job/list', label: 'Customers/jobs' },
      { href: '/job/lists/task', label: 'Tasks'},
    ];
    if(isGeoFenceVisible){
      tabs.push({ href: '/geofence/job/lists', label: 'Geofences' });
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    else{
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    return (
      <TwoColumnLayout
        navInfo={{...NAV.GeoFenceListPage}}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
        jobPage={true}
        isLoading={isLoading}
      >
        <GeoFenceFilterSection />
        {query.view
          ? <GeoFenceMapSection isLoading={isLoading} />
          : <GeoFenceListSection
            isLoading={isLoading}
            fetchData={this.loadData}
          />
        }
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
});

const mapActionsToProps = dispatch => ({
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

GeoFenceListPage.propTypes = {
  location: PropTypes.object.isRequired,
  GeoFenceDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
};

GeoFenceListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceListPage);
