import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import JobBulkUploadSection from 'sections/jobs/list/bulkUpload';

import { NAV } from 'constants/jobs';

class JobBulkUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.state = { isLoading: false, isInfoOpen: true };
  }

  componentWillMount() {
    this.props.InfoDucks.getInformations({route: 'customer.bulkupload'});
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }


  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("job_bulk_info", !isInfoOpen);
  }

  render() {
    const { isInfoOpen } = this.state;
    const { infoList } = this.props;
    const { location: { query } } = this.props;
    return (
      <TwoColumnLayout 
        navInfo={query.admin_list ? NAV.NewJobBulkUploadPage : NAV.JobBulkUploadPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
      >
        <JobBulkUploadSection />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

JobBulkUploadPage.propTypes = {
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

JobBulkUploadPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobBulkUploadPage);
