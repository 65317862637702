import React from "react";

import cookie from "react-cookies";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import { toast } from "react-toastify";

import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as InfoDucks from "ducks/info/info";
import * as UserDucks from "ducks/accounts/user";
import * as RouteDucks from "ducks/routes";

import SSTCFLayout from "layouts/SSTCF";
import MealAdjustmentsListSection from "sections/vendors/mealAdjustments/List";
import MealAdjustmentsFilterSection from "sections/vendors/mealAdjustments/Filter";
import PaginationComponent from "components/common/Pagination";

import { NAV } from "constants/vendors";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */
class AdjustmentTypesListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true, mealRule: []};
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    // this.saveUserActivityOrderPreference = this.saveUserActivityOrderPreference.bind(this);
    // this.getAdjustmentIds = this.getAdjustmentIds.bind(this);
  }

  componentWillMount() {
    const {
      location: { query },
    } = this.props;
    const cookieKey = cookie.load("meal_adjustments");
    this.setState({ isLoading: true });
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.fetchData(query);
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { query: prevQuery },
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  loadData() {
    const {
      location: { query },
    } = this.props;
    this.fetchData(query);
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("meal_adjustments", !isInfoOpen);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const { userExtraData } = this.props;
    const pageSize = cookie.load("meal_adjustments_page_size");
    Promise.all([
      this.props.InfoDucks.getInformations({ route: "vendor.basic_setting" }),
      this.props.AdjustmentTypesDucks.getMealTypes({
        ...params,
        company_id: userExtraData.company.id,
        page_size: params?.page_size,
        paginate: true,
        is_active: params?.status !== undefined && params?.status !== null ? params?.status : "true"
      }),
    ])
      .then((responses) => {
        const mealTypesResponse = responses[1];
        this.setState({ mealRule: mealTypesResponse?.value, isLoading: false});
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        toast.error(error.non_field_errors[0]);
      });
  }

  render() {
    const { isLoading, isInfoOpen, mealRule } = this.state;
    const {
      location: { query },userExtraData
    } = this.props;
    const tabs = [
      { href: "/vendor/basic-setting", label: "Basic" },
      { href: "/vendor/system-setting", label: "System" },
      { href: "/vendor/notification-setting", label: "Notification" },
      { href: "/vendor/adjustment-types", label: "Adjustment Types" },
      { href: "/vendor/system-forms", label: "System Forms" },
    ];

    const condition = userExtraData?.company_permissions?.meal_adjustments_enabled;   

    if (condition) {
      tabs.push({href: "/vendor/meal-adjustments", label: "Meal Adjustments"},);
    }
    const { infoList } = this.props;
    return (
      <SSTCFLayout
        navInfo={NAV.MealAdjustmentsListPage}
        tabItems={tabs.filter((item) => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <MealAdjustmentsFilterSection
          isLoading={isLoading}
          fetchData={this.fetchData}
        />
        <MealAdjustmentsListSection
          isLoading={isLoading}
          data={mealRule}
          deleteAssignedEmp={this.props.deleteAssignedEmp}
          fetchData={this.fetchData}
          company_id={this?.props?.userExtraData?.company?.id}
          pageSize={query?.page_size}
          status={query?.status}
          putMealTypes={this.props.putMealTypes}
        />
        <PaginationComponent
          count={mealRule.count}
          location={this.props.location}
          // cookieKey="employee_page_size"
        />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  userExtraData: UserDucks.userExtraData(state),
  adjustmentIdsList: AdjustmentTypesDucks.adjustmentIdsList(state),
});

const mapActionsToProps = (dispatch) => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  deleteAssignedEmp: bindActionCreators(
    AdjustmentTypesDucks.deleteAssignedEmp,
    dispatch
  ),
  putMealTypes: bindActionCreators(AdjustmentTypesDucks.putMealTypes, dispatch),

});

AdjustmentTypesListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

AdjustmentTypesListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AdjustmentTypesListPage);
