import React from "react";

import moment from "moment";
import PropTypes from "prop-types";
import cookie from "react-cookies";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import * as RouteDucks from "ducks/routes";
import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as DailyTimecardDucks from "ducks/timecards/dailyTimecard";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as InfoDucks from "ducks/info/info";
import * as UserDucks from "ducks/accounts/user";
import * as PayrollDucks from "ducks/payrolls/payroll";
import * as ActivityDucks from "ducks/activities/activity";
import * as DepartmentDucks from "ducks/vendors/department";
import * as MissingEntryDucks from "ducks/activities/missingEntry";
import * as ActivitySplitDucks from "ducks/activities/activitySplit";
import * as GeoFenceDucks from "ducks/geoFences/geoFence";
import * as TimecardDucks from "ducks/timecards/timecard";

import AttributeToggler from "employees/components/AttributeToggler";
import TwoColumnLayout from "layouts/TwoColumn";
import DailyTimecardFilterSection from "sections/timecards/customDailyTimecardList/Filter";
import DailyTimecardListSection from "sections/timecards/customDailyTimecardList/List";
import ActivityInProgressSection from "sections/timecards/dailyTimecardList/ActivityInProgress";
import ActivitySplitTimelineSection from "sections/activities/activitySplitForm/Timeline";

import EmployeeListingSlider from "sections/timecards/customDailyTimecardList/employeeListing";

import styles from "./styles.module.scss";

import { NAV } from "constants/timecards";

class CustomDailyTimecardListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchDailyTimecards = this.fetchDailyTimecards.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = {
      isLoading: false,
      parent: NAV.CustomDailyTimecardListPage.parent,
      isInfoOpen: true,
      permission: true,
      listView: true,
      attributeToggler: false,
      showHideIconToggle: true,
      showableAttributes: {},
      showAllocateTasksPopup: false,
      splitData: null,
      shiftData: null,
      isPreviousShift: false,
      isNextShift: false,
      editDisabled: false,
      isSubmitting: false,
      submitSession: false,
      loadingHover: false,
      submitDisabled: false,
      userViewPreferenceLoading: false,
      submitTask: false,
      submitMeal: false,
      shiftId: "",
      isError: true,
      activityInProgressSection: true,
    };
    this.getPayrollsOfDepartment = this.getPayrollsOfDepartment.bind(this);
    this.setWeekAndDate = this.setWeekAndDate.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.showTasksModal = this.showTasksModal.bind(this);
    this.hideTaskModal = this.hideTaskModal.bind(this);
    this.endSubmitting = this.endSubmitting.bind(this);
    this.showPreviousShift = this.showPreviousShift.bind(this);
    this.startSubmitting = this.startSubmitting.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.updateErrorState = this.updateErrorState.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.showEmployeeListing = this.showEmployeeListing.bind(this);
    this.showFeedbackQuestions = this.showFeedbackQuestions.bind(this);
    this.updateAttributeValue = this.updateAttributeValue.bind(this)
    this.toggleActivityInProgressSection = this.toggleActivityInProgressSection.bind(
      this
    );
    this.accessorToHeader = {
      punch_in: "Punch In",
      meal_start: "Meal Start",
      meal_end: "Meal End",
      punch_out: "Punch Out",
      total_shift_time: "Total Shift Time",
      total_day_time: "Total Day Time",
      meal_period: "Meal Period",
      rt: "RT",
      ot: "OT",
      dot: "DOT",
      edit: "Edit",
      approve: "Approve",
    };
    if (this.props.userExtraData.company_permissions.mileage) {
      this.accessorToHeader = { ...this.accessorToHeader, mileage: "Mileage" };
    }
  }

  showEmployeeListing(val) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    cookie.save("isShowEmployeeListCookie", val);
    history.push({
      pathname,
      search: queryString.stringify({ ...query, show_employee_list: val }),
    });
  }

  componentWillMount() {
    const {
      location: { query },
      userViewPreference
    } = this.props;
    this.fetchData();
    const cookieKey = cookie.load("daily_timecard_info");
    if (cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    // this.props.TimecardDucks.getUserFilters({
    //   preference_type: "user_preference_timecards",
    // });
    this.props.UserDucks.getProfileUser();
    this.props.InfoDucks.getInformations({
      route: "timecards.timecard.daily_timecard.list",
      beta: "true",
    });
    this.props.PayrollDucks.getAllPayrolls({
      paginate: false,
      default: true,
      valid: true,
      beta: "true",
    });
    if (query && query?.department_ids && !Array.isArray(query?.department_ids) ) {
      this.loadData(query).then(() => this.setWeekAndDate(query));
    }
    if(!Object.keys(userViewPreference).length){
      this.props.DailyTimecardDucks.getUserPreference({
        view_type: "inline_view",
      })
        .then(() => {
          this.updateAttributeValue();
          //cookie.save("timecard_page_size", userViewPreference.page_size);
        })
        .catch((err) => {
          this.setState({ userViewPreferenceLoading: false });
          let attributes = {};
          for (const acc in this.accessorToHeader) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        });
    }else{
      this.updateAttributeValue();
    }
    
  }

  toggleActivityInProgressSection() {
    const {activityInProgressSection} = this.state  
    this.setState({ activityInProgressSection: !activityInProgressSection });
  }

  updateFilters(query) {
    const { userTimecardFilters, basicSettingDetail } = this.props;

    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    const preferenceObj = {
      preference_type: "user_preference_timecards",
      data: {
        timecard_decimal_view:
          userTimecardFilters && userTimecardFilters.data
            ? userTimecardFilters.data.timecard_decimal_view == "true" ||
              userTimecardFilters.data.timecard_decimal_view == true
              ? true
              : false
            : basicSettingDetail.timecard_decimal_view,
        // (query.timecard_decimal_view == "true" || query.timecard_decimal_view == true) ? true : false,
        department_ids: departmentIdsIntegers,
        team_id: query.team_id,
        start_date: query.start_date,
        end_date: query.end_date,
        payroll_id: query.payroll_id,
        show_extra_info:
          userTimecardFilters &&
          userTimecardFilters.data &&
          userTimecardFilters.data.show_extra_info
            ? userTimecardFilters.data.show_extra_info
            : false,
        manual_correction:
          userTimecardFilters &&
          userTimecardFilters.data &&
          userTimecardFilters.data.manual_correction
            ? userTimecardFilters.data.manual_correction
            : false,
        ordering:
          userTimecardFilters &&
          userTimecardFilters.data &&
          userTimecardFilters.data.ordering
            ? userTimecardFilters.data.ordering
            : "",
        search:
          userTimecardFilters &&
          userTimecardFilters.data &&
          userTimecardFilters.data.search
            ? userTimecardFilters.data.search
            : "",
      },
    };
    // this.props.TimecardDucks.updateUserFilters(preferenceObj).then(() => {
    //   this.props.TimecardDucks.getUserFilters({
    //     preference_type: "user_preference_timecards",
    //   });
    // });
    this.props.TimecardDucks.updateUserFilters(preferenceObj)
  }

  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    const { userViewPreference } = this.props;
    if (bool && !Object.keys(userViewPreference).length) {
      this.setState({ userViewPreferenceLoading: true });
      this.props.DailyTimecardDucks.getUserPreference({
        view_type: "inline_view",
      })
        .then(() => {
          this.updateAttributeValue();
          //cookie.save("timecard_page_size", userViewPreference.page_size);
          this.setState({ userViewPreferenceLoading: false });
        })
        .catch((err) => {
          this.setState({ userViewPreferenceLoading: false });
          let attributes = {};
          for (const acc in this.accessorToHeader) {
            attributes[acc] = true;
          }
          this.setAttributes(attributes);
        });
    } else {
     this.updateAttributeValue()
    }
  }

  updateAttributeValue(){
    const { userViewPreference } = this.props;
    let obj = {};
    let all_fields = userViewPreference.all_fields;
    let show_fields = userViewPreference.show_fields;
    all_fields.forEach((v) => {
      if (show_fields.indexOf(v) !== -1) {
        obj[v] = true;
      } else {
        obj[v] = false;
      }
    });
    this.setAttributes(obj);
  }

  async setAttributes(attributes) {
    await this.setState({ showableAttributes: attributes });
    cookie.save("showableAttributesDailyTimeCardList", attributes);
  }

  startSubmitting(
    loadingHover,
    submitSession,
    isSubmitting,
    submitDisabled,
    submitTask,
    submitMeal
  ) {
    if (isSubmitting) {
      this.setState({ isSubmitting: true });
    }
    if (submitSession) {
      this.setState({ submitSession: true });
    }
    if (loadingHover) {
      this.setState({ loadingHover: true });
    }
    if (submitDisabled) {
      this.setState({ submitDisabled: true });
    }
    if (submitTask) {
      this.setState({ submitTask: true });
    }
    if (submitMeal) {
      this.setState({ submitMeal: true });
    }
  }

  endSubmitting(
    isSubmitting,
    submitSession,
    loadingHover,
    submitDisabled,
    submitTask,
    submitMeal
  ) {
    if (isSubmitting) {
      this.setState({ isSubmitting: false });
    }
    if (submitSession) {
      this.setState({ submitSession: false });
    }
    if (loadingHover) {
      this.setState({ loadingHover: false });
    }
    if (submitDisabled) {
      this.setState({ submitDisabled: false });
    }
    if (submitTask) {
      this.setState({ submitTask: false });
    }
    if (submitMeal) {
      this.setState({ submitMeal: false });
    }
  }

  setColumns(checkedData) {
    const showFields = Object.keys(checkedData).filter(
      (item) => {
        if (checkedData[item] === true) return item;
        else return false;
      }
    );
    const pageSize = cookie.load("employee_page_size") || 10;
    const preferenceObj = {
      all_fields: Object.keys(this.accessorToHeader),
      show_fields: showFields,
      page_size: pageSize,
      view_type: "inline_view",
    };
    this.props.DailyTimecardDucks.updateUserPreference(preferenceObj).then(
      () => {
        this.setState({ showableAttributes:checkedData,attributeToggler: !this.state.attributeToggler });
      }
    );
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = nextContext;
    const {
      location: { query: prevQuery },
      profile,
    } = this.props;
    const {
      location: { query: nextQuery },
    } = nextProps;
    const { showAllocateTasksPopup } = this.state;

    if (prevQuery.department_ids !== nextQuery.department_ids) {
      this.updateFilters(nextQuery);
      if (nextQuery.department_ids && !Array.isArray(nextQuery?.department_ids)) {
        this.getPayrollsOfDepartment(nextQuery);
      } else if (this.props.payrollAll.length && profile.company.is_default) {
        this.setQuery(nextQuery, this.props.payrollAll[0]);
      } else {
        this.setQuery(nextQuery, "custom");
      }
      this.setWeekAndDate(nextQuery);
      this.fetchDailyTimecards({ ...nextQuery, ...params, beta: "true" });
    } else if (prevQuery !== nextQuery) {
      this.updateFilters(nextQuery);
      this.setWeekAndDate(nextQuery);
      this.fetchDailyTimecards({ ...nextQuery, ...params, beta: "true" });
      this.setParentUrl(nextQuery);
      if (prevQuery.show_employee_list != nextQuery.show_employee_list) {
        {
          nextQuery.show_employee_list == "true" &&
            this.setState({ showAllocateTasksPopup: false });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations({ beta: "true" });
  }

  updateErrorState(inline) {
    if (inline) {
      this.setState({ isError: true });
    } else {
      this.setState({ isError: false });
    }
  }

  setParentUrl(query) {
    const {
      location: { pathname },
    } = this.props;
    const nav =
      pathname.indexOf("historic") > -1
        ? NAV.HistoricTimecardListPage
        : NAV.CustomDailyTimecardListPage;
    try {
      if (query.parent) {
        let parsedParams = JSON.parse(query.parent);
        const newParsedParams = {
          ...parsedParams,
          department_ids: query.department_ids,
          team_id: query.team_id,
          start_date: query.start_date,
          end_date: query.end_date,
          payroll_id: query.payroll_id,
        };
        this.setState({
          parent: `${nav.parent}?${queryString.stringify(newParsedParams)}`,
        });
      } else {
        this.setState({ parent: nav.parent });
      }
    } catch (e) {
      console.log(e);
    }
  }

  showPreviousShift(item, childItem, type) {
    this.props.MissingEntryDucks.resetMissingEntry();
    const { dailyTimecardList } = this.props;

    let splits = dailyTimecardList.map((i) => i.child);

    splits = splits.flat();

    splits = splits.filter(
      (i) => i.daily_timecard === true && !i.weekStartDate
    );

    let activityList = splits.map((i) => i.activity_split);

    activityList = activityList.flat();

    activityList = activityList.filter((i) => !i.adjustment_type);

    let splitIndex;
    activityList.forEach((i, index) => {
      if (i.id === item.id) {
        splitIndex = index;
      }
    });
    // let itemIndex;
    // splits.filter((i, index) => {
    //   if(i.id === childItem.id){
    //     itemIndex = index;
    //   }
    // });
    let previousshiftData;
    let nextShiftData;
    previousshiftData =
      type === "previous" && activityList[splitIndex - 1]
        ? splits.filter(
            (i) => i.id === activityList[splitIndex - 1].daily_timecard_id
          )
        : null;
    nextShiftData =
      type === "next" && activityList[splitIndex + 1]
        ? splits.filter(
            (i) => i.id === activityList[splitIndex + 1].daily_timecard_id
          )
        : null;
    if (type === "previous" && previousshiftData) {
      this.props.ActivitySplitDucks.getActivitySplits({
        id: previousshiftData[0].id,
      }).then((res) => {
        const sD = res.value.filter(
          (i) =>
            i.activity_name === "Shift" &&
            i.id === activityList[splitIndex - 1].id
        )[0];
        const surveyData = previousshiftData[0].activity_split.find(
          (i) => i.id == sD.id
        );
        this.showFeedbackQuestions(surveyData);
        this.setState({
          showAllocateTasksPopup: true,
          splitData: sD,
          shiftData:
            previousshiftData &&
            previousshiftData.length &&
            previousshiftData[0],
          isPreviousShift: true,
        });
        this.endSubmitting(false, false, "isLoading");
      });
    } else if (nextShiftData) {
      this.props.ActivitySplitDucks.getActivitySplits({
        id: nextShiftData[0].id,
      }).then((res) => {
        const sD = res.value.filter(
          (i) =>
            i.activity_name === "Shift" &&
            i.id === activityList[splitIndex + 1].id
        )[0];
        const surveyData = nextShiftData[0].activity_split.find(
          (i) => i.id == sD.id
        );
        this.showFeedbackQuestions(surveyData);
        this.setState({
          showAllocateTasksPopup: true,
          splitData: sD,
          shiftData: nextShiftData && nextShiftData.length && nextShiftData[0],
          isNextShift: true,
        });
        this.endSubmitting(false, false, "isLoading");
      });
      return;
    } else {
      this.endSubmitting(false, false, "isLoading");
    }
  }

  showTasksModal(item, childItem, id) {
    this.setState({ 
      editDisabled: true,
      showAllocateTasksPopup: true,
      splitData: []
    });
    const {
      location: { query },
    } = this.props;
    const { shiftData, splitData } = this.state;
    let splitsId =
      item && item.id
        ? item.id
        : splitData && splitData.id
        ? splitData.id
        : null;
    let shiftsData = childItem ? childItem : shiftData;
    let shiftId = id ? id : childItem ? childItem.id : shiftData.id;
    this.setState({ shiftId: shiftId, activitySplitsLoading: true });
    this.props.ActivitySplitDucks.getActivitySplits({ id: shiftId }).then(
      (res) => {
        {
          item && item.session_id && this.showFeedbackQuestions(item);
        }
        this.setState({
          editDisabled: false,
          splitData: res.value.filter(
            (i) => i.activity_name === "Shift" && i.id === splitsId
          )[0],
          shiftData: shiftsData,
          activitySplitsLoading: false,
        });
        this.endSubmitting(false, false, "isLoading", "submitDisabled");
      }
    );
  }

  hideTaskModal() {
    this.setState({
      showAllocateTasksPopup: false,
      splitData: null,
      shiftData: null,
    });
  }

  setQuery(query, payroll) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname },
    } = this.props;
    const monthStartDate = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
    const monthEndDate = moment()
      .endOf("month")
      .format("YYYY-MM-DD");

    let startDate = monthStartDate;
    let endDate = monthEndDate;
    let payrollId;
    if (payroll && payroll.id) {
      startDate = moment(payroll.start_datetime).format("YYYY-MM-DD");
      endDate = moment(payroll.end_datetime).format("YYYY-MM-DD");
      payrollId = payroll.id;
    } else {
      payrollId = "custom";
    }

    cookie.save("payrollId", payrollId, { path: "/" });
    cookie.save("startDate", startDate, { path: "/" });
    cookie.save("endDate", endDate, { path: "/" });

    if (!("payroll_id" in query)) {
      return history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: payrollId,
          start_date: startDate,
          end_date: endDate,
        }),
      });
    }
    return true;
  }

  getPayrollsOfDepartment(query) {
    this.setState({ isLoading: true });

    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    return this.props.PayrollDucks.getPayrollsOfDepartment({
      valid: true,
      paginate: false,
      department_ids: JSON.stringify(departmentIdsIntegers),
      beta: "true",
    }).then(() => {
      const { departmentPayroll, payrollAll, profile } = this.props;
      if (departmentPayroll.length) {
        this.setQuery(query, departmentPayroll[0]);
      } else if (payrollAll.length && profile.company.is_default) {
        this.setQuery(query, payrollAll[0]);
      } else {
        this.setQuery(query, "custom");
      }
    });
  }

  loadData(query) {
    const {
      location: { pathname },
    } = this.props;
    const {
      router: { history },
    } = this.context;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query?.department_ids;
      } else {
        departmentIdsArray = [query?.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    this.setState({ isLoading: true });
    if (departmentIdsIntegers?.length === 1) {
      return this.props.PayrollDucks.getPayrollsOfDepartment({
        valid: true,
        paginate: false,
        department_ids: JSON.stringify(departmentIdsIntegers),
        beta: "true",
      })
        .then(() => {
          let payrollId = cookie.load("payrollId");
          let startDate = cookie.load("startDate");
          let endDate = cookie.load("endDate");
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              start_date: query.start_date ? query.start_date : startDate,
              end_date: query.end_date ? query.end_date : endDate,
              payroll_id: payrollId,
            }),
          });
        })
        .catch((error) => {
          // Handle the error here
          console.error("Error in loadData:", error);
          // Optionally handle the error state or display an error message
        });
    }
  }

  setWeekAndDate(query) {
    const { departmentAll, defaultPayrollSetting } = this.props;
    const startDate = query.start_date;
    const endDate = query.end_date;
    let weekDay = 0;
    if (query.emp_dept_id) {
      const dept = departmentAll.find((i) => i.id === +query.emp_dept_id);
      weekDay = dept && dept.payroll_setting_week_day;
    } else {
      weekDay = defaultPayrollSetting.week_day
        ? defaultPayrollSetting.week_day
        : 0;
    }
    this.props.DailyTimecardDucks.setWeekAndDate({
      week: weekDay,
      startDate,
      endDate,
      beta: "true",
    });
  }

  fetchData() {
    const {
      location: { pathname, query },
      userExtraData,
      userTimecardFilters:{data}
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
        history,
      },
    } = this.context;
    this.setState({ isLoading: true,activityInProgressSection:true });
    this.setParentUrl(query);
    Promise.all([
      this.props.BasicSettingDucks.getBasicSetting({ beta: "true" }),
      data ? Promise.resolve({}): this.props.TimecardDucks.getUserFilters({
        preference_type: "user_preference_timecards",
      }),
      this.props.AdjustmentTypesDucks.getAdjustmentTypes({
        paginate: false,
        company_id: userExtraData.company.id,
        is_active: true,
        beta: "true",
      }),
      // this.fetchDailyTimecards({ ...query, ...params }),
    ])
      .then(() => {
        const { basicSettingDetail, userTimecardFilters } = this.props;
        if (!("timecard_decimal_view" in query)) {
          history.push({
            pathname,
            search: queryString.stringify({
              ...query,
              timecard_decimal_view:
                userTimecardFilters && userTimecardFilters.data
                  ? userTimecardFilters.data.timecard_decimal_view == "true" ||
                    userTimecardFilters.data.timecard_decimal_view == true
                    ? true
                    : false
                  : basicSettingDetail.timecard_decimal_view,
            }),
          });
          this.setState({
            isSubmitting: false,
            submitSession: false,
            loadingHover: false,
          });
        } else {
          this.fetchDailyTimecards({ ...query, ...params });
        }
      })
      .catch(() =>
        this.setState({
          isLoading: false,
          isSubmitting: false,
          submitSession: false,
          loadingHover: false,
        })
      );
  }

  fetchDailyTimecards(params) {
    const {payroll_id} = params;
    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : params?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : params?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
    this.setState({ isLoading: true });
    Promise.all([
      this.props.DailyTimecardDucks.getCustomDailyTimecards({
        ...params,
        admin: true,
        beta: "true",
        department_ids: JSON.stringify(departmentIdsIntegers),
        team_ids: JSON.stringify(teamIdsIntegers),
        payroll_id: departmentIdsIntegers.length >=2 ? 'custom': payroll_id
      }),
      this.props.ActivityDucks.getInProgressActivity({
        employee_id: params.employee_id,
        beta: "true",
        start_date: params?.start_date,
        end_date: params?.end_date,
      }),
    ])
      .then(() =>
        this.setState({
          isLoading: false,
          isSubmitting: false,
          submitSession: false,
        })
      )
      .catch(() =>
        this.setState({
          isLoading: false,
          isSubmitting: false,
          submitSession: false,
          loadingHover: false,
        })
      );
  }

  toggleInfo() {
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("daily_timecard_info", !isInfoOpen);
  }

  toggleView(val) {
    const {
      router: { history },
    } = this.context;
    const {
      match: { params },
      location: { query },
    } = this.props;
    let url = "";
    if (val === "old") {
      this.setState({ showHideIconToggle: false });
      url = `/timecard/custom/list/${params.id}/dailyV0`;
      cookie.save("listView", true);
    } else {
      this.setState({ showHideIconToggle: true });
      url = `/timecard/custom/list/${params.id}/daily`;
      cookie.save("listView", false);
    }

    history.push({
      pathname: url,
      search: queryString.stringify({ ...query }),
    });
  }

  showFeedbackQuestions(item, childitem) {
    const context_ids = { session_id: item.session_id };
    this.props.DailyTimecardDucks.feedbackTemplate({
      context_ids: context_ids,
    });
  }

  render() {
    const {
      infoList,
      location: { pathname, query },
      activityInProgressList,
    } = this.props;
    const {
      isLoading,
      parent,
      isInfoOpen,
      showHideIconToggle,
      showAllocateTasksPopup,
    } = this.state;
    const nav =
      pathname.indexOf("historic") > -1
        ? NAV.HistoricTimecardListPage
        : NAV.CustomDailyTimecardListPage;
    let title = nav.title;
    let employeeName;
    if (!isLoading) {
      title = `${nav.title} (${query.first_name} ${query.last_name})`;
      employeeName = `${query.first_name} ${query.last_name}`;
    }
    const showEmployeeCookie =
      cookie.load("isShowEmployeeListCookie") &&
      cookie.load("isShowEmployeeListCookie") == "true"
        ? true
        : false;
    let isEmployeeCheck = false;
    if (Object.keys(query).length > 0) {
      if (query.show_employee_list === "true" && showEmployeeCookie) {
        isEmployeeCheck = true;
      }
    }
    const description = <div>
      {<span> <strong>Deparment: </strong>{query?.empployee_dept_name?query.empployee_dept_name:'-'}&emsp;</span>}
      {<span><strong>Team: </strong> {query?.employee_team_name?query.employee_team_name:'-'}&emsp;</span>}
      {<span><strong>Employee ID: </strong> {query?.employee_id_show?query.employee_id_show:'-'}&emsp;</span>}
      {<span><strong>Employee Title: </strong> {query?.employee_title?query.employee_title:'-'}</span>}
    </div>;

    return (
      <div className={`${styles["wrapTimecardDetails"]}`}>
        <div
          className={
            showAllocateTasksPopup
              ? `${styles["showEditModal"]} abc`
              : `${styles["hideModal"]} abc`
          }
        >
          <TwoColumnLayout
            navInfo={{
              ...nav,
              parent,
              title,
              description,
            }}
            infoList={infoList}
            toggleInfo={this.toggleInfo}
            isInfoOpen={isInfoOpen}
            fetchData={this.fetchData}
          >
            {isEmployeeCheck && (
              <EmployeeListingSlider
                showAllocateTasksPopup={this.state.showAllocateTasksPopup}
              />
            )}
            <div
              className={
                isEmployeeCheck && showAllocateTasksPopup
                  ? `${styles["showEmployeeModalWithSlider"]}`
                  : isEmployeeCheck && !showAllocateTasksPopup
                  ? `${styles["showEmployeeModal"]}`
                  : `${styles["hideEmployeeModal"]} `
              }
            >
              <DailyTimecardFilterSection
                setWeekAndDate={this.setWeekAndDate}
                toggleView={this.toggleView}
                active="new"
                showAllocateTasksPopup={showAllocateTasksPopup}
                setAttributeToggler={this.setAttributeToggler}
                showHideIconToggle={showHideIconToggle}
                attributeToggler={this.state.attributeToggler}
                isEmployeeCheck={isEmployeeCheck}
                userTimecardFilters={this.props.userTimecardFilters}
                activityInProgressList={activityInProgressList}
                pathname={pathname}
                isLoading={this.state.isLoading}
                toggleActivityInProgressSection={
                  this.toggleActivityInProgressSection
                }
                fetchData={this.fetchData}
              />
              {this.state.attributeToggler && (
                <AttributeToggler
                  setToggle={() =>
                    this.setAttributeToggler(!this.state.attributeToggler)
                  }
                  toggle={this.state.attributeToggler}
                  data={{
                    labels: Object.values(this.accessorToHeader),
                    accessors: Object.keys(this.accessorToHeader),
                    defaultVals: Object.values(this.state.showableAttributes),
                  }}
                 // onChange={this.setAttributes}
                  setColumns={this.setColumns}
                />
              )}
              {activityInProgressList && activityInProgressList.results.length && !activityInProgressList.nosql_data && !isLoading && (pathname.indexOf('historic') < 0)  ?
              <ActivityInProgressSection showAllocateTasksPopup={showAllocateTasksPopup} active="new" isShowEmployee={isEmployeeCheck}/> : null
              }
              {activityInProgressList && activityInProgressList.results.length && activityInProgressList.nosql_data && !isLoading && this.state.activityInProgressSection ? (
                <ActivityInProgressSection
                  showAllocateTasksPopup={showAllocateTasksPopup}
                  active="new"
                  isShowEmployee={isEmployeeCheck}
                  toggleActivityInProgressSection={
                    this.toggleActivityInProgressSection
                  }
                />
              ) : null}

              {
                <DailyTimecardListSection
                  showTasksModal={this.showTasksModal}
                  isLoading={isLoading}
                  showAllocateTasksPopup={showAllocateTasksPopup}
                  showableAttributes={this.state.showableAttributes}
                  editDisabled={this.state.editDisabled}
                  isError={this.state.isError}
                  updateErrorState={this.updateErrorState}
                  title={employeeName}
                  showEmployeeListing={this.showEmployeeListing}
                  isShowEmployee={isEmployeeCheck}
                  showEmployeeCookie={showEmployeeCookie}
                  fetchData={this.fetchData}
                />
              }
            </div>
          </TwoColumnLayout>
        </div>
        {showAllocateTasksPopup && this.state.splitData !== null && (
          <ActivitySplitTimelineSection
            dateFormat={this.props.dateFormat}
            splitData={this.state.splitData ?? []}
            shiftData={this.state.shiftData ?? []}
            hideTaskModal={this.hideTaskModal}
            showPreviousShift={this.showPreviousShift}
            isPreviousShift={this.state.isPreviousShift}
            isNextShift={this.state.isNextShift}
            fetchData={this.fetchData}
            showTasksModal={this.showTasksModal}
            isSubmitting={this.state.isSubmitting}
            isLoading={this.state.loadingHover}
            submitSession={this.state.submitSession}
            endSubmitting={this.endSubmitting}
            startSubmitting={this.startSubmitting}
            submitDisabled={this.state.submitDisabled}
            submitTask={this.state.submitTask}
            submitMeal={this.state.submitMeal}
            shiftId={this.state.shiftId}
            new_layout
            updateErrorState={this.updateErrorState}
            feedbackResponse={this.props.feedbackResponseList}
            activitySplitsLoading={this.state.activitySplitsLoading}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  location: RouteDucks.location(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  infoList: InfoDucks.infoList(state),
  payrollAll: PayrollDucks.payrollAll(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  activityInProgressList: ActivityDucks.activityInProgressList(state),
  userExtraData: UserDucks.userExtraData(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  defaultPayrollSetting: UserDucks.defaultPayrollSetting(state),
  profile: UserDucks.profile(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  userTimecardFilters: TimecardDucks.userTimecardFilters(state),
  userViewPreference: DailyTimecardDucks.userViewPreference(state),
  feedbackResponseList: DailyTimecardDucks.feedbackResponseList(state),
});

const mapActionsToProps = (dispatch) => ({
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  ActivityDucks: bindActionCreators(ActivityDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  MissingEntryDucks: bindActionCreators(MissingEntryDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

CustomDailyTimecardListPage.propTypes = {
  location: PropTypes.object.isRequired,
  DailyTimecardDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
  payrollAll: PropTypes.array.isRequired,
};

CustomDailyTimecardListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomDailyTimecardListPage);
