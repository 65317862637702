import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';
import { toast } from 'react-toastify';

import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as NotificationSettingDucks from 'ducks/vendors/notificationSetting';

import { MSGS } from 'constants/payrolls';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PayrollSettingFormComponent from 'components/payrolls/setting/Form';
import CaliforniaRuleModal from 'components/payrolls/setting/CaliforniaRuleModal';
import AddMealRuleModal from 'components/payrolls/setting/AddMealRuleModal';
import DeleteMealModalComponent from 'components/payrolls/setting/DeleteMealModal';
/**
 * PayrollSettingFormPage -> PayrollSettingFormSection
 *
 * Components:
 *    - {@link PayrollSettingFormComponent}
 *
 * State:
 *    - payroll detail
 *
 * Actions:
 *    - Form:
 *        - Update payroll detail
 */
class PayrollSettingFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.state = {deletingMeal: false,isOpen: false,  californiaRuleMessage: null , OvertimeInfo: false, mealBreakInfo: false,
      openAddRuleModal: false, openDeleteRuleModal: false, editMealDetail:null, addMealLoader:false, deleteMealId: null, deleteMealLoader: false, payroll_id : null, mealRuleList: null,}
    this.toggleModal = this.toggleModal.bind(this);
    this.updateCaliforniaRuleMessage = this.updateCaliforniaRuleMessage.bind(this);
    this.toggleOvertimeAccordian = this.toggleOvertimeAccordian.bind(this);
    this.toggleMealBreakAccordian = this.toggleMealBreakAccordian.bind(this);
    this.toggleAddRuleModal = this.toggleAddRuleModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.openEditForm= this.openEditForm.bind(this);
    this.openDeleteFormModal = this.openDeleteFormModal.bind(this);
    this.openDeleteForm = this.openDeleteForm.bind(this);
    this.updateMeal = this.updateMeal.bind(this);
    this.getMealRulesList = this.getMealRulesList.bind(this);
  };

  getMealRulesList(){
     const { router: { route: { match: { params } } } } = this.context;
     this.props.PayrollSettingDucks.getMealList(params.id ? params.id : this.state.payroll_id)
     .then((res) => {
      this.setState({mealRuleList : res.value})
    })
  }

  componentWillReceiveProps(nextProps){
    const { router: { route: { match: { params } } } } = this.context;
    if(params.id){
      this.setState({ californiaRuleMessage : nextProps.detail.cal_7_day_rule_message });
    }else{
      this.setState({ californiaRuleMessage: MSGS.CALIFORNIA_DEFAULT_MESSAGE });
    }
  }

  updateMeal(notification, extraData, data, id){
    const { mealList } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({addMealLoader : true});
    const detail ={
     payroll_setting: params.id ? params.id : this.state.payroll_id,
     config: 'Meal',
     sequence_of_configurations: (+mealList.length) + 1,
     include_rt_ot_dot: data.override_meal_payment === true ?  data.include_rt_ot_dot : false,
     is_default: data.is_default,
     extra_data: extraData,
     notifications: notification,
     is_meal_start_notification: data.is_meal_start_notification,
     is_meal_end_notification: data.is_meal_end_notification

  }
  return this.props.PayrollSettingDucks.updateMeal(detail, id)
  .then(() => {
    this.getMealRulesList();
    toast.success('Meal Updated Successfully');
    this.setState({addMealLoader : false, openAddRuleModal : false})})
  .catch((err) => 
  {
    toast.error(err.non_field_errors[0]);
    this.setState({ openAddRuleModal: false, addMealLoader: false })});
  }

  openEditForm(params){
    this.props.PayrollSettingDucks.getMealDetails(params)
    .then((response) => {
      const {openAddRuleModal} = this.state;
      this.setState({editMealDetail: response.value});
      this.setState({openAddRuleModal: !openAddRuleModal});})
  }

  openDeleteFormModal(params) {
    const { openDeleteRuleModal } = this.state;
    this.setState({ deleteMealId: params})
    this.setState({ openDeleteRuleModal: !openDeleteRuleModal })
  }

  openDeleteForm() {
    const { deleteMealId } = this.state;
    this.setState({deleteMealLoader : true , deletingMeal: true});
    this.props.PayrollSettingDucks.deleteMeal(deleteMealId)
    .then(() => {
    this.setState({deleteMealLoader : false, deletingMeal:false});
    this.getMealRulesList();
    this.setState({openDeleteRuleModal: false});
    return toast.success('Meal deleted successfully');
    })
    .catch((err) => {
      this.setState({deleteMealLoader : false, deletingMeal:false});
      this.setState({openDeleteRuleModal: false});
      return toast.error(err.non_field_errors[0]);
    })
  }

  toggleMealBreakAccordian(){
    const { router: { route: { match: { params } } } } = this.context;
    const {mealBreakInfo, payroll_id} = this.state;
    this.setState({mealBreakInfo: !mealBreakInfo});
    if(params.id || payroll_id !== null){
    this.getMealRulesList();
    }
  }

  toggleAddRuleModal(){
    const {openAddRuleModal} = this.state;
    this.setState({editMealDetail : null});
    this.setState({openAddRuleModal: !openAddRuleModal});
  }

  toggleDeleteModal() {
    const { openDeleteRuleModal } = this.state;
    this.setState({
      openDeleteRuleModal: !openDeleteRuleModal
    })
  }

  saveData(notifications, extraData, data){
    const { mealList } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({addMealLoader : true});
    const detail ={
     payroll_setting: params.id ? params.id : this.state.payroll_id,
     config: 'Meal',
     sequence_of_configurations: (+mealList.length) + 1,
     include_rt_ot_dot: data.override_meal_payment === true ?  data.include_rt_ot_dot : false,
     is_default: data.is_default,
     extra_data: extraData,
     notifications: notifications,
     is_meal_start_notification: data.is_meal_start_notification,
     is_meal_end_notification: data.is_meal_end_notification
  }
  this.props.PayrollSettingDucks.createMeal(detail)
  .then(() => {
    toast.success('Meal Added Successfully');
    this.getMealRulesList();
    this.setState({openAddRuleModal: false, addMealLoader:false});
    })
  .catch(() => this.setState({ openAddRuleModal: false, addMealLoader: false }));
  }


  toggleOvertimeAccordian(){
    const {OvertimeInfo} = this.state;
    this.setState({OvertimeInfo: !OvertimeInfo});
  }

  toggleModal(){
    const { isOpen } = this.state;
    return this.setState({ isOpen: !isOpen });
  }

  updateCaliforniaRuleMessage(data) {
    this.setState({ californiaRuleMessage: data.cal_7_day_rule_message, isOpen: false });
  }

  createOrUpdate(data) {
    const { payroll_id } = this.state;
    const { userCompany } = this.props;
    const { router: { history, route: { match: { params } } } } = this.context;
    if (params.id) {
      return this.props.PayrollSettingDucks.putPayrollSetting(data, params.id)
        .then((response) => {
          toast.success("Saved Successfully");
          history.push('/payroll/setting/list');
        })
        .catch((err) => {
          if(err.non_field_errors.length){
            toast.error(err.non_field_errors[0]);
          }
        });
    }
    else if(this.state.payroll_id){
        this.props.PayrollSettingDucks.putPayrollSetting(data ,payroll_id)
        return this.props.PayrollSettingDucks.getPayrollSettings()
        .then(() => {
          history.push('/payroll/setting/list');
        });
      }
    else{
    return this.props.PayrollSettingDucks.postPayrollSetting({ ...data, company_id: userCompany.id })
      .then((response) => {
        this.setState({payroll_id: response.value.id});
        history.push(`/payroll/setting/${response.value.id}/edit`);
        toast.success("Payroll Setting Added Successfully. Now you can add your meal break preferences");
        /*history.push('/payroll/setting/list');*/
      });
    }
  }

  render() {
    const { location: { pathname, query }, userExtraData , advancedPayrollModalType } = this.props;
    const isAdvancedSettingsVisible = query.isAdvancedSettingsVisible == 'true' ? true : false;
    const showPastData = query.past_records == 'true' ? true : false;
    const {editMealDetail } = this.state;
    const notifications = editMealDetail ? editMealDetail.notifications : null;
    const mealStartNotification = notifications ? notifications.find((i) =>
      i.notification_type === 'MEAL_START'):null;
    const mealEndNotification = notifications ? notifications.find((i) =>
      i.notification_type === 'MEAL_END'):null;
    const forceMealEndNotification = notifications ? notifications.find((i) =>
      i.notification_type === 'MEAL_FORCE_END'):null;
    const { isLoading, detail, timeFormat, userCompany, timeFormat24Hrs, showPayrollDetails, isEditPayroll} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen, californiaRuleMessage , openAddRuleModal, openDeleteRuleModal} = this.state;
    let mealBreakDuration = editMealDetail && editMealDetail.extra_data.duration ? editMealDetail.extra_data.duration : null;
    mealBreakDuration = mealBreakDuration ? mealBreakDuration.split(':') :null;
    let MealBreakHour = mealBreakDuration && mealBreakDuration[0] ? mealBreakDuration[0] : null;
    let MealBreakMinute = mealBreakDuration && mealBreakDuration[1] ? mealBreakDuration[1] : null;
    let initialValues = {};

    if (params.id && detail.id) {
      initialValues = { ...detail,
        hour: Math.floor(detail.first_meal_threshold / 3600),
        minute: Math.floor((detail.first_meal_threshold % 3600) / 60),
        wavier_hour: Math.floor(detail.meal_waiver_value / 3600),
        wavier_minute: Math.floor((detail.meal_waiver_value % 3600) / 60),
        daily_split: detail.daily_split,
        week_overtime_hours: detail.week_overtime_threshold && detail.week_overtime_threshold.hours,
        week_overtime_minutes: detail.week_overtime_threshold && detail.week_overtime_threshold.minutes,
        daily_overtime_hours: detail.daily_overtime_threshold && detail.daily_overtime_threshold.hours,
        daily_overtime_minutes: detail.daily_overtime_threshold && detail.daily_overtime_threshold.minutes,
        daily_double_overtime_hours: detail.daily_double_overtime_threshold && detail.daily_double_overtime_threshold.hours,
        daily_double_overtime_minutes: detail.daily_double_overtime_threshold && detail.daily_double_overtime_threshold.minutes,
        work_day_start_time: detail && detail.work_day_start_time ? detail.work_day_start_time : moment().set({ hour: 0, minute: 0 }).toISOString(),
      };
    }
    else {
      initialValues = {
        is_meal_paid : false,
        is_overtime_applicable: false,
        is_additional_overtime_applicable: false,
        is_cal_7_day_rule: false,
        week_overtime_threshold: 40,
        daily_overtime_threshold: 8,
        daily_double_overtime_threshold:12,
        daily_split: false,
        work_day_start_time : '00:00'
      }
    }

    /*console.log('isLoading', isLoading)*/

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <PayrollSettingFormComponent
          initialValues={initialValues}
          isAdvancedSettingsVisible={isAdvancedSettingsVisible}
          toggle={this.toggleModal}
          createOrUpdate={this.createOrUpdate}
          californiaRuleMessage={californiaRuleMessage}
          timeFormat={timeFormat}
          timeFormat24Hrs={timeFormat24Hrs}
          userCompany={userCompany}
          OvertimeInfo={this.state.OvertimeInfo}
          toggleOvertimeAccordian={this.toggleOvertimeAccordian}
          toggleMealBreakAccordian={this.toggleMealBreakAccordian}
          mealBreakInfo={this.state.mealBreakInfo}
          toggleAddRuleModal={this.toggleAddRuleModal}
          openAddRuleModal={openAddRuleModal}
          mealsList={this.props.mealList}
          mealRuleList={this.state.mealRuleList}
          openEditForm={this.openEditForm}
          openDeleteFormModal={this.openDeleteFormModal}
          params={params}
          payroll_id={this.state.payroll_id}
          showAdvancedSettings={this.props.showAdvancedSettings}
          showPayrollDetails={showPayrollDetails}
          isEditPayroll={isEditPayroll}
          userExtraData={userExtraData}
          newLayout={this.props.newLayout}
          advancedPayrollSettingList={this.props.advancedPayrollSettingList}
          location={this.props.location}
          showPastRecords={this.props.showPastRecords}
          showPastRecordsLoader={this.props.showPastRecordsLoader}
          showPastData={showPastData}
          form="form1"
          advancedPayrollCount={this.props.advancedPayrollCount}
          dateFormatDisplay ={this.props.dateFormatDisplay}
        />
        {isOpen &&
          <CaliforniaRuleModal
            initialValues={{ ...initialValues, cal_7_day_rule_message: californiaRuleMessage }}
            isOpen={isOpen}
            toggle={this.toggleModal}
            updateCaliforniaRuleMessage={this.updateCaliforniaRuleMessage}
          />
        }
        {openDeleteRuleModal &&
          <DeleteMealModalComponent
            isOpen={openDeleteRuleModal}
            toggle={this.openDeleteFormModal}
            openDeleteForm={this.openDeleteForm}
            deleteMealId={this.state.deleteMealId}
            deleteMealLoader ={this.state.deleteMealLoader}
            isSubmitting={this.state.deletingMeal}
          />
        }
        {openAddRuleModal &&
          <AddMealRuleModal
            isOpen={openAddRuleModal}
            payroll_id ={this.state.payroll_id}
            toggle={this.toggleAddRuleModal}
            saveData={this.saveData}
            company_id={userCompany.id}
            params={params}
            updateMeal={this.updateMeal}
            addMealLoader={this.state.addMealLoader}
            mealDetails={this.props.mealDetails}
            initialValues={{ ...editMealDetail, override_meal_payment: editMealDetail ? editMealDetail.extra_data.override_meal_payment : null,
              MealStarthour: editMealDetail ? editMealDetail.extra_data.start_time.hours : null, MealStartminute: editMealDetail ? editMealDetail.extra_data.start_time.mins : null
              ,wavier_hour: editMealDetail ? editMealDetail.extra_data.waiver.hours : null, wavier_minute: editMealDetail ? editMealDetail.extra_data.waiver.mins : null,
              notification: (editMealDetail && editMealDetail.notifications.length)>0 ? true : false,
              mealStartNotificationTime: mealStartNotification && mealStartNotification.notification_after, mealStartNotification : mealStartNotification && mealStartNotification.notification_message,
              mealEndNotificationTime:mealEndNotification && mealEndNotification.notification_after, mealEndNotification: mealEndNotification && mealEndNotification.notification_message,
              AutomaticMealEndNotificationTime: forceMealEndNotification && forceMealEndNotification.notification_after, automaticMealEndNotification: forceMealEndNotification && forceMealEndNotification.notification_message,
              automatic_meal_end: editMealDetail && editMealDetail.extra_data.automatic_meal_end, MealBreakHour: MealBreakHour ? MealBreakHour : null, MealBreakMinute : MealBreakMinute ? MealBreakMinute : null
            }}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  detail: PayrollSettingDucks.payrollSettingDetail(state),
  advancedPayrollSettingList : PayrollSettingDucks.advancedPayrollSettingList(state),
  userCompany: UserDucks.userCompany(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  mealList: PayrollSettingDucks.mealList(state),
  mealDetails:PayrollSettingDucks.mealDetails(state),
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
  advancedPayrollCount: PayrollSettingDucks.advancedPayrollCount(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
});

const mapActionToProps = dispatch => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  NotificationSettingDucks: bindActionCreators(NotificationSettingDucks, dispatch),
});

PayrollSettingFormSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  detail: PropTypes.object.isRequired,
  PayrollSettingDucks: PropTypes.object.isRequired,
};

PayrollSettingFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PayrollSettingFormSection);
