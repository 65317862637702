import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import MatchWhenUnauthorized from 'pages/MatchWhenUnauthorized';
import NoMatch from 'pages/NoMatch';

import ActivationPage from 'accounts/pages/activation';
import ForgotPasswordPage from 'accounts/pages/forgotPassword';
import SetPasswordPage from 'accounts/pages/setPassword';
import LoginPage from 'accounts/pages/signIn';
import RegisterPage from 'accounts/pages/register';
import ResetPasswordPage from 'accounts/pages/resetPassword';
import AccountSuspensionPage from 'accounts/pages/accountSuspension';
import AccountCancellationPage from 'accounts/pages/accountCancellation';
import ChangeExpPasswordPage from 'accounts/pages/accountChangeExpPass';
import VerifyOtpPage from 'accounts/pages/verifyOtp';
import LongTextPage from 'accounts/pages/longText';
import TermsConditionPage from 'accounts/pages/termsConditionPage';

import ChangePassword from './ChangePassword';
import ProfilePage from './Profile';

const AccountsRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/login`} />
      <MatchWhenUnauthorized
        exact
        path={`${match.url}/login`}
        name="accounts.login"
        component={LoginPage}
      />
      <MatchWhenUnauthorized
        exact
        path={`${match.url}/register`}
        name="accounts.register"
        component={RegisterPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/suspension`}
        name="accounts.suspension"
        component={AccountSuspensionPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/cancellation`}
        name="accounts.cancellation"
        component={AccountCancellationPage}
      />
      <MatchWhenUnauthorized
        exact
        path={`${match.url}/forgot-password`}
        name="accounts.forgot_password"
        component={ForgotPasswordPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/change-password`}
        name="accounts.change_password"
        component={ChangePassword}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/activate-user`}
        name="accounts.activate_user"
        component={ActivationPage}
      />
      <MatchWhenUnauthorized
        exact
        path={`${match.url}/reset-password`}
        name="accounts.reset_password"
        component={ResetPasswordPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/profile`}
        name="accounts.profile"
        component={ProfilePage}
      />
      <MatchWhenUnauthorized
        exact
        path={`${match.url}/set-password`}
        name="accounts.set_password"
        component={SetPasswordPage}
      />

      <MatchWhenAuthorized
        exact
        path={`${match.url}/change-expired-password`}
        name="accounts.change-expired-password"
        component={ChangeExpPasswordPage}
      />

      <MatchWhenUnauthorized
        exact
        path={`${match.url}/login-otp`}
        name="accounts.logotp"
        component={VerifyOtpPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/terms&condition`}
        name="accounts.terms&condn"
        component={TermsConditionPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

AccountsRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AccountsRoutes;
