import React from 'react';
import {connect} from 'react-redux';
import { Row, Col} from 'reactstrap'
import ContentLayout from 'layouts/Content';
import UserAPIGateway from 'accounts/gateways/user';
import BillAPIGateway from 'payments/bills/gateways/bill';
import ContactSupportModal from 'accounts/components/contactSupportModal';
import AccountCancellationUsecase from 'accounts/usecases/accountCancellation';

import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import AccountCancellationComponent from 'accounts/components/accountCancellation';

class AccountCancellationPage extends React.PureComponent {
  constructor(props) {
    super(props);
     this.state={
      isOpen: false,
      logout : false,
      billList: [] ,
    }
    const userAPIGateway = new UserAPIGateway();
    const billAPIGateway = new BillAPIGateway();
    this.usecase = new AccountCancellationUsecase(userAPIGateway, billAPIGateway);
    this.openModal = this.openModal.bind(this);
    this.toggle= this.toggle.bind(this);
    this.listenAccountSuspensionUsecase = this.listenAccountSuspensionUsecase.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount(){
    const { userProfile } = this.props;
    this.listenAccountSuspensionUsecase();
    this.usecase.getBillList({ billed: false, company_id: userProfile.company.id, paginate:false });
  }

  openModal(){
    const data = {      
     enquiry_query : 'Cancellation Of Account'
    } 
    this.usecase.sendEmail(data)
    .then(() => {
      this.setState({isOpen: true});
    })
    .catch((e) => {    
      this.setState({isOpen: true});
    });   
  }

  logout(){
    this.setState({logout: true});
    this.usecase.logout();
  }

  toggle(){
      this.setState({ isOpen: !this.state.isOpen });  
  }

  listenAccountSuspensionUsecase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'LOGOUT_SUCCESS': {
          this.props.dispatch({
            type: 'accounts/user/RESET_STATE',
          });
          this.props.history.push('/');
          break;
        }
          case 'GET_BILL_LIST_SUCCESS':{
          this.setState({ billList: event.data,
            isLoading: false });
          break;
        }
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    const { userProfile, isSubCompany, isLoggedInAdmin , userExtraData} = this.props;
    const { isOpen } = this.state;
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col md={12}>
            <AccountCancellationComponent 
              usecase={this.usecase}
              userProfile={userProfile}
              isSubCompany={isSubCompany}
              isLoggedInAdmin={isLoggedInAdmin}
              openModal={this.openModal}
              logOutOfAccount ={this.logout}
              logoutLoader={this.state.logout}
              billList = { this.state.billList}
              customerDetail={this.props.customerDetail}
              userExtraData = {userExtraData}
            />
            {isOpen && <ContactSupportModal 
              isOpen={isOpen}
              toggle={this.toggle}
              usecase={this.usecase}/>}
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: UserDucks.profile(state),
  isLoggedInAdmin: UserDucks.isLoggedInAdmin(state),
  isSubCompany: UserDucks.isSubCompany(state),
  customerDetail: PaymentDucks.customerDetail(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountCancellationPage);
