import React from "react";

import styles from "./styles.module.scss";

const UsernameCircle = ({ name,id ,color}) => {
 
const response = (number)=>{
  return <span className={`${styles["username-text"]}`}>{number}</span>
}

  const splitString = (string) => {
    const subString = string.trim().split(" ");
    return (<text className={`${styles["username-text"]}`}>
        {`${subString[0].charAt(0).toUpperCase()}`}
        {subString[1] && `${subString[1].charAt(0).toUpperCase()}`}
    </text>)
  };
  return (

    
    <div className={`${styles["username-circle"]}`} style={{backgroundColor:color}}>
      {name ?
      splitString(name):
      response(id)
      }
      
    </div>
  );
};

export default UsernameCircle;
