import React from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import { UncontrolledTooltip } from 'reactstrap';


import RefreshIconAtom from 'atoms/RefreshIcon';
import NotificationIconAtom from 'atoms/NotificationIcon';
import {ReactComponent as BackIcon} from 'sections/common/SubHeader/back.svg';
import NotificationMailIcon from 'sections/common/SubHeader/NotificationMailIcon';
import BreadcrumbComponent from 'components/common/Breadcrumb';

import StorageGateway from 'lib/storage-gateway';

/**
 * SubHeaderSection
 *
 * Components:
 *    - {@link BreadcrumbComponent}
 *    - Title (With back navigation)
 *    - Description
 *
 * State:
 *    - navInfo
 *
 * Actions:
 *    - route push
 */
const SubHeaderSection = (props, context) => {
  const { notification, toggle, navInfo ,editJob, fetchData, isLoading, jobDetail,goTo,createJob, createSurvey,addPolicy,ptoBulkUpload,updateState} = props;
  const { router: { history } } = context;
  const isPluginToken = StorageGateway.get('pluginToken');
  /* Style only to be applied if page is part of jobs section */
  // const jobStyles = {
  //   borderBottom: '1px solid #46b147',
  //   paddingBottom: '0.5rem'
  // }
  const goBack = path => {
    if(path==='timeOffPolicies')
    {
      const empId=(window.location.pathname).split('/')[2]
      history.push(`/employee/${empId}/edit/employeeTimeOff`);
    }else
    {
       history.push(path);
    }
  }

  return (
    <section className="mb-3 ml-3 mr-4" /*style={jobPage ? jobStyles : null}*/>
      <BreadcrumbComponent breadcrumbItems={navInfo.breadcrumb} />
      <div className="mb-1">
        {navInfo.parent ?
          <BackIcon width="21" height="21" className="cursor-pointer mt-2 vertical-sub mb-1" onClick={() => goBack(navInfo.parent)} />
        : null
        }
        <h3 className={navInfo.parent ? "ml-2 d-inline black-text parent-heading" : "d-inline black-text parent-heading"}>{navInfo.title}</h3>
        {navInfo.title==="Edit Job" &&
          <h3 id="job_name" className="ml-2 black-text parent-heading long-job">|&nbsp;{jobDetail.name}
            <UncontrolledTooltip placement="bottom" target='job_name'>
              {jobDetail.name}
            </UncontrolledTooltip>
          </h3>
        }
        {fetchData ?
          <RefreshIconAtom
            className="cursor-pointer float-sm-right"
            onClick={() => fetchData()}
            id="refresh"
            isLoading={isLoading}
            disabled={isLoading}
          />
        : null}
        {createJob &&
          <section className={createJob == 'createJobNew' ? "float-right mr-3 mt-2" : "float-right mt-2"}>
            <Button
              id="add_job"
              color="primary"
              //className="btn-accent"
              className="pr-3 pl-3 create-job-font"
              onClick={() => goTo(createJob == 'createJobNew' ? '/job/list/add?admin_list=true' : '/job/list/add')}
            >Create New Job
            </Button>
          </section>
        }
        {addPolicy &&
          <section className={addPolicy == 'addPolicy' ? "float-right mr-3 mt-2" : "float-right mt-2"}>
            <Button
              id="add_job"
              color="primary"
              //className="btn-accent"
              className="pr-3 pl-3 create-job-font"
              onClick={() => goTo(addPolicy == 'addPolicy' ? '/pto-policy/add' : '')}
            >Add Policy
            </Button>
          </section>
        }
         {ptoBulkUpload &&
          <section className={"float-right mr-3 mt-2"} >
            <Button
              id="bulk_upload"
              color="primary"
              className="pr-3 pl-3 create-job-font"
              onClick={()=> goTo(`/pto-policy/bulk-upload`)}
            >Bulk Upload
            </Button>
          </section>
        }
        {ptoBulkUpload &&
          <section className={"float-right mr-3 mt-2"} >
            <div 
              id='mail' 
              style={{ 
                paddingTop:'8px',
                cursor:'pointer', 
                boxShadow:'none', 
                outline:'none' 
              }}
              onClick={()=> updateState('toggleMailLayer', true)}
            >
              <NotificationMailIcon />
            </div>
          </section>
        }
        {createSurvey &&
          <section className="float-right mr-3 mt-2">
            <Button
              id="add_survey_template"
              color="primary"
              //className="btn-accent"
              className="pr-3 pl-3 create-job-font"
              onClick={() => goTo('/surveys/add/?setup=true')}
            >Add New Template
            </Button>
          </section>
        }

        {notification && isPluginToken ?
          <NotificationIconAtom
            className="cursor-pointer float-sm-right mt-2"
            onClick={() => toggle()}
            id="notification"
          />
        : null}
        {navInfo.description && <div className={navInfo.parent ? "pl-4 ml-1 pb-1 parent-desc" : "pl-1 pb-1 parent-desc"}>{navInfo.description}</div>}
      </div>
      {!editJob &&
        <div>
        <hr className="hr-common" />
        </div>
      }
    </section>

  );
};

SubHeaderSection.defaultProps = {
  navInfo: {},
};

SubHeaderSection.propTypes = {
  navInfo: PropTypes.object,
};

SubHeaderSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SubHeaderSection;