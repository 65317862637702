import React from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { UncontrolledDropdown } from 'reactstrap/lib/Uncontrolled';

class FilterDropdownComponent extends React.Component {

    constructor(props) {
        super(props);
        this.submit = this.submit.bind(this);
    }


    submit(data){
        const { cookieKey, onChange } = this.props;
        if (cookieKey) cookie.save(cookieKey, data, { path: '/' });
        if (onChange) onChange(data);
    }

    render(){
        const {options, size, tether, header, valueLabel, keyLabel, disabled, value  } = this.props;
        const filteredOption = options && options.filter(item => value === item.value);
        return (
            <UncontrolledDropdown size={size} tether={tether}>
                <div className="dropdown-override pr-1">
                    <DropdownToggle
                        caret color="accent"
                        disabled={disabled}
                        title={(filteredOption && filteredOption[0].label) || options[0] && options[0][valueLabel]}
                        >  
                        {(filteredOption && filteredOption[0].label) || options[0] && options[0][valueLabel]}
                    </DropdownToggle>
                </div>  
                <DropdownMenu className="drop-item" style={{marginTop:'33px'}}>
                    {header
                    ? (<span>
                        <DropdownItem header>{header}</DropdownItem>
                        <DropdownItem divider />
                        {options.map(item =>
                        <DropdownItem title={item[valueLabel]} key={item[keyLabel]} onClick={() => this.submit(item[keyLabel])}>
                            {item[valueLabel]}
                        </DropdownItem>
                        )}
                    </span>)
                    : (<span>
                        {options.map(item =>
                        <DropdownItem title={item[valueLabel]} key={item[keyLabel]} onClick={() => this.submit(item[keyLabel])}>
                            {item[valueLabel]}
                        </DropdownItem>
                        )}
                    </span>)
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

FilterDropdownComponent.defaultProps = {
  options: [
    { value: '', label: 'Select' },
  ],
  keyLabel: 'value',
  valueLabel: 'label'
};

FilterDropdownComponent.propTypes = {
  options: PropTypes.array,
  size: PropTypes.string,
  tether: PropTypes.object,
  onChange: PropTypes.func,
};

FilterDropdownComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default FilterDropdownComponent;