import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import ViewIconAtom from 'atoms/ViewIcon';
import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';


const ActivitySplitTableComponent = (props) => {
  const { data, location: { query }, pageSize ,navigation} = props;
  const { page = 1 } = query;

  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table">
      <thead className="Header-col">
        <tr>
          <th className="text-center pl-3">Action</th>
          <th className="pl-3">Employee Name</th>
          <SortHeaderWithSeparateKeysComponent field='"activity_activitymaster"."name"'>
            Activity
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."start_datetime"'>
            Start
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."end_datetime"'>
            End
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."total_time"'>
            Time
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td className="text-center pl-3">
                <span title="View Activity">
                  <ViewIconAtom onClick={() => navigation(item)} className="cursor-pointer" />
                </span>
              </td>
              <td title={`${item.first_name} ${item.last_name}`} className="pl-3">
                {item.first_name} {item.last_name}
              </td>
              <td title={item.name}>{item.name}</td>
              <td title={item.start_datetime}>{item.start_datetime}</td>
              <td title={item.end_datetime}>{item.end_datetime}</td>
              <td title={item.total_time} className="text-right pr-3">{item.total_time}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

ActivitySplitTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default ActivitySplitTableComponent;
