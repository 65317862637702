import React from "react";

import cookie from "react-cookies";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as InfoDucks from "ducks/info/info";
import * as UserDucks from "ducks/accounts/user";
import * as RouteDucks from "ducks/routes";
import JobProgressBarSection from "sections/jobs/form/ProgressBar";

import SSTCFLayout from "layouts/SSTCF";
import { NAV } from "constants/jobs";
import LayerAtom from "../../atoms/Layer";
import StorageGateway from "lib/storage-gateway";
import { toast } from "react-toastify";
import TwoColumnLayout from "layouts/TwoColumn";

import * as JobDucks from "ducks/jobs/job";
import * as EmployeeDucks from "ducks/employees/employee";
import * as TaskDucks from "ducks/jobs/task";
import * as CustomerDucks from "ducks/customers/customer";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */
class JobDocumentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isInfoOpen: true,
      docsPreview:false,
      authToken: null,
      user_id:null,
      path: "",
    };

    this.fetchData = this.fetchData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.closeLayer = this.closeLayer.bind(this);
  }

  fetchData(params) {}

  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "OPEN_DOCUMENTS_PREVIEW_SLIDER") {
      this.setState({ docsPreview: true });
    } else if (message === "CLOSE_OPEN_DOCUMENTS_PREVIEW_SLIDER") {
      this.setState({ docsPreview: false });
    } else if (message === "SENDS_REMINDER") {
      toast.success("Reminders Sends to Selected Employees");
    }
  }

  closeLayer(state) {
    console.log("state", state);
    if (state === "createGroupLayer") {
      this.setState({ createGroupLayer: false });
    } else {
      this.setState({ editDocumentLayer: false });
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userID = StorageGateway.get("userId");
    this.setState({ authToken: oAuthToken, user_id: userID });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

  render() {
    const { isJobEditInfo, isJobAddInfo, authToken ,user_id,docsPreview} = this.state;
    const {
      location: { query },
      infoList,
      jobDetail,
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const selectedId = params ? params.id : null;

    return (
      <div>
        <TwoColumnLayout
          navInfo={
            query?.setup
              ? NAV.JobAddFormPage
              : query?.admin_list
              ? NAV.AdminJobEditFormPage
              : NAV.JobEditFormPage
          }
          infoList={infoList}
          isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          jobDetail={jobDetail}
        >
          <div className="pb-5">
            <Row
              className="pt-4 mr-4 ml-3"
              style={{ backgroundColor: "white" }}
            >
              <Col>
                <Row>
                  <Col className="text-center mb-4">
                    <JobProgressBarSection
                      active={5}
                      selectedId={selectedId}
                      isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
                      addJob={query?.setup}
                      location={this.props.location}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        height: "calc(100vh - 102px - 75px)",
                        marginRight: "-15px",
                      }}
                    >
                      <iframe
                        id="scheduling"
                        title="scheduling-iframe"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        style={{ width: "100%", height: "100%" }}
                        src={`${process.env.REACT_APP_WMAPPER_BASE_URL}/jobs/documents/?access_token=${authToken}&user_id=${user_id}`}
                      />
                      {docsPreview && (
                        <LayerAtom
                          active={true}
                          path={`/documents/preview?access_token=${authToken}&user_id=${user_id}`}
                          setToggle={() =>
                            this.setState({ docsPreview: false })
                          }
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </TwoColumnLayout>
      </div>
    );
  }
}

JobDocumentsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

export default connect(mapStateToProps, mapActionsToProps)(JobDocumentsPage);
