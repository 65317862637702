import React from 'react';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import Step1 from './Step1.jpg';
import Step2 from './Step2.JPG';
import Step4 from './Step4.jpg';
import Step5 from './Step5.jpg';

import SectionLoaderAtom from 'atoms/SectionLoader';

import styles from './styles.module.scss';

class PluginADPComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.selectStep = this.selectStep.bind(this);
    this.state = {stepNumber:'1'}
  }

  selectStep(type){
    const { stepNumber } = this.state;
    this.setState({stepNumber:type})
  }

  render(){
    const { stepNumber } = this.state;
    const { isLoading } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className="pb-5 mt-4">
        <Row sm={12} className={styles.steps_container}>
          <Col sm={3} className={styles.steps_sidebar}>
            <Row className={stepNumber==='1' ? styles.selected_stepcount_first : styles.stepcount_first} role="button"  onClick={()=>this.selectStep('1')}><div>Step 1</div></Row>
            <Row className={stepNumber==='2' ? styles.selected_stepcount : styles.stepcount} role="button" onClick={()=>this.selectStep('2')}><div>Step 2</div></Row>
            <Row className={stepNumber==='3' ? styles.selected_stepcount : styles.stepcount} role="button" onClick={()=>this.selectStep('3')}><div>Step 3</div></Row>
            <Row className={stepNumber==='4' ? styles.selected_stepcount : styles.stepcount} role="button" onClick={()=>this.selectStep('4')}><div>Step 4</div></Row>
            <Row className={stepNumber==='5' ? styles.selected_stepcount : styles.stepcount} role="button" onClick={()=>this.selectStep('5')}><div>Step 5</div></Row>
            <Row className={stepNumber==='6' ? styles.selected_stepcount_last : styles.stepcount_last} role="button" onClick={()=>this.selectStep('6')}><div>Step 6</div></Row>
          </Col>
          <Col sm={9} className={styles.steps_description}>
            {stepNumber === '1' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 1 :</div>
                <div className={styles.step_information}>First , buy the subscription of Worksana Connector ( verify your existing worksana account's email ) from the ADP Market Place.</div>
                <div className="mt-4">
                  <img className={styles.step_image_shadow} src={Step1} width="90%"/>
                </div>
              </div>
            }
            {stepNumber === '2' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 2 :</div>
                <div className={styles.step_information}>On successful Subscription, go to My Apps Section on ADP Dashboard and click on Worksana Connector App.</div>
                <div className={`${styles.step_image} mt-4`}>
                  <img src={Step2} width="200" height="200"/>
                </div>
              </div>
            }
            {stepNumber === '3' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 3 :</div>
                <div className={styles.step_information}>On clicking on the App, it will redirect to Worksana prior confirming the consent.</div>
              </div>
            }
            {stepNumber === '4' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 4 :</div>
                <div className={styles.step_information}>In WorkSana, Go to Plugin Sections and Click on Connect to ADP.</div>
                <div className="mt-4 ml-5">
                  <img className={styles.step_image_shadow} src={Step4} width="550" height="235"/>
                </div>
              </div>
            }
            {stepNumber==='5' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 5 :</div>
                <div className={styles.step_information}> On clicking, it will redirect to ADP data flow consent screen. After that, It will successfully connect to the Worksana.</div>
                <div className="mt-4 ml-5">
                  <img className={styles.step_image_shadow} src={Step5} width="550" height="215"/>
                </div>
              </div>
            }
            {stepNumber === '6' &&
              <div className={styles.desc_container}>
                <div className={styles.step_text}>Step 6 :</div>
                <div className={styles.step_information}>Now click on the settings icon, which leads to Plugin Dashboard.</div>
              </div>
            }
          </Col>
        </Row>
      </div>
    );
  };
}
export default PluginADPComponent;
