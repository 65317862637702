import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import cookie from 'react-cookies';

import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import { UncontrolledDropdown } from 'reactstrap/lib/Uncontrolled';
import styles from './styles.module.scss';


/**
 * FilterDropdownComponent
 *
 * Props:
 *    - location
 */
const FilterDropdownComponent = (props, context) => {
  const { router: { history } } = context;
  const { paramKey, options, location: { pathname, search }, size, tether, header, onChange, valueLabel, keyLabel, cookieKey, disabled  } = props;
  const query = search ? queryString.parse(search) : {};
  const { page, ...rest } = query;
  const filteredOption = options && options.filter(item => query[paramKey] === item[keyLabel].toString())[0];

  const submit = ((data) => {
    if (cookieKey) cookie.save(cookieKey, data, { path: '/' });
    history.push({
      pathname, search: queryString.stringify({ ...rest, [paramKey]: data }),
    });
    if (onChange) onChange(data);
  });

  return (
    <UncontrolledDropdown size={size} tether={tether}>
      <div className="dropdown-override pr-1">
        <DropdownToggle
          caret color="accent"
          disabled={disabled}
          title={(filteredOption && filteredOption[valueLabel]) || options[0] && options[0][valueLabel]}
        >  
          {(filteredOption && filteredOption[valueLabel]) || options[0] && options[0][valueLabel]}
        </DropdownToggle>
      </div>  
      <DropdownMenu className="drop-item">
        {header
          ? (<span>
            <DropdownItem header>{header}</DropdownItem>
            <DropdownItem divider />
            {options.map(item =>
              <DropdownItem title={item[valueLabel]} key={item[keyLabel]} onClick={() => submit(item[keyLabel])}>
                {item[valueLabel]}
              </DropdownItem>
            )}
          </span>)
          : (<span>
            {options.map(item =>
              <DropdownItem title={item[valueLabel]} key={item[keyLabel]} onClick={() => submit(item[keyLabel])}>
                {item[valueLabel]}
              </DropdownItem>
            )}
          </span>)
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

FilterDropdownComponent.defaultProps = {
  options: [
    { value: '', label: 'Select' },
  ],
  keyLabel: 'value',
  valueLabel: 'label'
};

FilterDropdownComponent.propTypes = {
  paramKey: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  options: PropTypes.array,
  size: PropTypes.string,
  tether: PropTypes.object,
  onChange: PropTypes.func,
};

FilterDropdownComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default FilterDropdownComponent;