import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_SYSTEM_SETTINGS = 'vendors/systemSetting/GET_SYSTEM_SETTINGS';
const GET_SYSTEM_SETTINGS_FULFILLED = 'vendors/systemSetting/GET_SYSTEM_SETTINGS_FULFILLED';
const GET_SYSTEM_SETTING = 'vendors/systemSetting/GET_SYSTEM_SETTING';
const GET_SYSTEM_SETTING_FULFILLED = 'vendors/systemSetting/GET_SYSTEM_SETTING_FULFILLED';
const POST_SYSTEM_SETTING = 'vendors/systemSetting/POST_SYSTEM_SETTING';
const POST_SYSTEM_SETTING_FULFILLED = 'vendors/systemSetting/POST_SYSTEM_SETTING_FULFILLED';
const PUT_SYSTEM_SETTING = 'vendors/systemSetting/PUT_SYSTEM_SETTING';
const PUT_SYSTEM_SETTING_FULFILLED = 'vendors/systemSetting/PUT_SYSTEM_SETTING_FULFILLED';


export function getSystemSettings(params) {
  return {
    type: GET_SYSTEM_SETTINGS,
    payload: axios.get(URLS.SYSTEM_SETTING, { params }),
  };
}

export function getSystemSetting(params) {
  return {
    type: GET_SYSTEM_SETTING,
    payload: axios.get(`${URLS.SYSTEM_SETTING}${params.id}/`),
  };
}

export function postSystemSetting(data) {
  return {
    type: POST_SYSTEM_SETTING,
    payload: axios.post(URLS.SYSTEM_SETTING, data),
  };
}

export function putSystemSetting(data) {
  return {
    type: PUT_SYSTEM_SETTING,
    payload: axios.put(`${URLS.SYSTEM_SETTING}${data.id}/`, data),
  };
}


const defaultState = {
  list: { results: [] },
  detail: {},
};

function GET_SYSTEM_SETTINGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_SYSTEM_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_SYSTEM_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_SYSTEM_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_SYSTEM_SETTINGS_FULFILLED]: GET_SYSTEM_SETTINGS_FULFILLED_REDUCER,
  [GET_SYSTEM_SETTING_FULFILLED]: GET_SYSTEM_SETTING_FULFILLED_REDUCER,
  [POST_SYSTEM_SETTING_FULFILLED]: POST_SYSTEM_SETTING_FULFILLED_REDUCER,
  [PUT_SYSTEM_SETTING_FULFILLED]: PUT_SYSTEM_SETTING_FULFILLED_REDUCER,
};


const systemSettingSelector = state => state.vendors.systemSetting;

export const systemSettingList = createSelector(
  systemSettingSelector,
  instance => instance.list,
);

export const manualEntryDetail = createSelector(
  systemSettingList,
  instance => instance.results.filter(item => item.key.key_code === "MANUAL_ENTRY_BY_USER")[0],
);

export const mvEntryDetail = createSelector(
  systemSettingList,
  instance => instance.results.filter(item => item.key.key_code === "MEAL_VIOLATION")[0],
);

export const systemSettingDetail = createSelector(
  systemSettingSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
