import React from "react";
import queryString from "query-string";
import Button from "reactstrap/lib/Button";

import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Field from "redux-form/lib/Field";

import reduxForm from "redux-form/lib/reduxForm";
import Form from "reactstrap/lib/Form";
import moment from "moment";
import PropTypes from "prop-types";
import formValueSelector from "redux-form/lib/formValueSelector";
import connect from "react-redux/lib/connect/connect";

import FilterDropdownComponent from "components/common/FilterDropdown";
import FilterFormComponent from 'components/common/FilterForm';


import { FORMS_FILTER_OPTIONS } from "constants/forms";

import DateTimePickerAtom from "atoms/DateTimePicker";
import { FILTER_FORM_LAYOUT } from "constants/layout";
import styles from "./styles.module.scss";

class FormsFilterFormsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
  }
  handleAdd(history, formType) {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    let pathname = "";
    if (formType === "trainingForms") {
      pathname = `/learning/${formType}/create`;
    } else {
      pathname = `/forms/create/${formType}`;
    }
    history.push({
      pathname,
      search: queryString.stringify(query),
    });
  }
  submit(data) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const { page, ...rest } = query;
    const detail = { ...data };
    const { page: pageNo, ...restDetail } = detail;
    restDetail.start_date = moment(data.start_date).format("YYYY-MM-DD");
    restDetail.end_date = moment(data.end_date).format("YYYY-MM-DD");
    history.push({
      pathname,
      search: queryString.stringify({ ...rest, ...restDetail }),
    });
  }

  render() {
    const {
      dateFormat,
      location,
      handleSubmit,
      formType,
      selectedForms,
      sendReminderToggler,
      assignEmployeeToggler,
    } = this.props;
    const {
      router: { history },
    } = this.context;
    return (
      <>
      <Row className="ml-3 mr-2 filter d-flex justify-content-between flex-wrap">
        <div className="d-flex flex-wrap">
          <span className="mb-2">
            <FilterDropdownComponent
              paramKey="is_active"
              location={location}
              options={FORMS_FILTER_OPTIONS}
            />
          </span>
          <Form
            inline
            onSubmit={handleSubmit(this.submit)}
            className="report-filter date-filter-width survey-status"
          >
            <Field
              id="star_date"
              name="start_date"
              placeholder="Start Date"
              component={DateTimePickerAtom}
              options={{ dateFormat }}
            />
            <Field
              id="end_date"
              name="end_date"
              placeholder="End Date"
              component={DateTimePickerAtom}
              options={{ dateFormat }}
            />
            <Button
              type="submit"
              color="accent"
              style={{ marginBottom: "24px" }}
              size={FILTER_FORM_LAYOUT.size}
              className="pr-4 pl-4"
            >
              Search
            </Button>
          </Form>
        </div>
        {formType !== "system" && (
          <div className={`${styles["gap-css"]}`}>
            <Button
              color="accent"
              style={{ marginBottom: "24px", height: "34px" }}
              size={FILTER_FORM_LAYOUT.size}
              className="pr-4 pl-4"
              disabled={selectedForms.length === 0}
              onClick={sendReminderToggler}
            >
              Send Reminders
            </Button>
            {formType !== "trainingForms" && (
              <Button
                color="accent"
                style={{ marginBottom: "24px", height: "34px" }}
                size={FILTER_FORM_LAYOUT.size}
                className="pr-4 pl-4"
                disabled={selectedForms.length === 0}
                onClick={assignEmployeeToggler}
              >
                Assign Employee
              </Button>
            )}
            <Button
              color="accent"
              style={{ marginBottom: "24px", height: "34px" }}
              size={FILTER_FORM_LAYOUT.size}
              className="pr-4 pl-4"
              onClick={() => this.handleAdd(history, formType)}
            >
              Add New
            </Button>
          </div>
        )}
      </Row>
      <div className="mb-2 mr-3 filter d-flex justify-content-end flex-wrap">
            <FilterFormComponent
              location={location}
              placeholder="Search name"
            />
          </div>
      </>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const formattedStartDate = moment(values.start_date);
  const formattedEndDate = moment(values.end_date);
  if (!values.start_date) errors.start_date = "Select Start Date";
  else if (!values.end_date) errors.end_date = "Select End Date";
  else if (formattedStartDate > formattedEndDate)
    errors.start_date = "Start date should be less than end date.";
  return errors;
};

FormsFilterFormsComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const FormsFilterForm = reduxForm({
  form: "FormsFilterFormsComponent",
  validate,
})(FormsFilterFormsComponent);

const selector = formValueSelector("FormsFilterFormsComponent");

export default connect((state) => {
  const job = selector(state, "job");
  const task = selector(state, "task");
  return { job, task };
})(FormsFilterForm);
