import React from 'react';
import { UncontrolledTooltip, Tooltip , Row, Col, Popover } from 'reactstrap';
import Form from 'reactstrap/lib/Form';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import Button from "reactstrap/lib/Button";
import cookie from "react-cookies";
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';
import moment from 'moment-timezone';

import styles from './styles.module.scss';
import StorageGateway from 'lib/storage-gateway';

import {ReactComponent as FilterIcon} from './filter.svg';
import {ReactComponent as BackIcon} from './showFilters.svg';

import closeimage from './close.png';

import ManageAdvancedFiltersDetailedForm from './ManageJobAdvancedFilterDetails';
import DateTimePickerAtom from 'components/common/JobCenterDateTimePicker';
import FilterDropdownComponent from 'components/common/GroupByFilterComponent';

import AsyncMultiSelectAtom from 'atoms/AsyncMultiSelect';
import AsyncMultiSelectDropdownAtom from 'atoms/AsyncMultiSelectDropdown';
import SelectAtom from 'atoms/Select';
import {  FORM_LAYOUT } from 'constants/layout';
import {  FILTER_OPTIONS , DATE_RANGE_OPTIONS } from 'constants/jobs';


class ManageJobAdvancedFilterFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleFilterList = this.toggleFilterList.bind(this);
    this.showSelectedFilter = this.showSelectedFilter.bind(this);
    this.applySelectedFilter = this.applySelectedFilter.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.selectedFilterList = this.selectedFilterList.bind(this);
    this.removeSelectedFilter= this.removeSelectedFilter.bind(this);
    this.updateFilterValues = this.updateFilterValues.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state={ error: null, isListOpen : false , selectedFilter : null , filters : null , initialValues : this.props.initialValues, openFilterPopup: false};
    this.filterAll = null;
    this.filterList = {
      job_name  :  'Job Name',
      job_tag : 'Job Tags',
      task_name : 'Task Name',
      task_tag: 'Task Tags',
      employee_name: 'Employee Name',
      employee_tags : 'Employee Tags',
      customer_name : 'Customer Name',
      status : 'Job Status',
    }
  }

  componentWillMount(){
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const advancedFlters = StorageGateway.get(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`);
    this.filterAll = advancedFlters && this.selectedFilterList(JSON.parse(advancedFlters));
    this.setState({filters: this.filterAll && this.filterAll.length && this.filterAll.filter((item)=>item.value !== undefined && item.value !== null && item !== null)});
  }

  updateFilter(item){
    this.setState({openFilterPopup : item, isListOpen : false, selectedFilter : null});
  }

  toggle(){
    this.setState({openFilterPopup:false});
  }

  toggleFilterList(val){
    const { isListOpen } = this.state;
    if(val){
      this.setState({isListOpen: this.props.advancedFilterCookie, openFilterPopup : false , error : null});
    }
    else{
      this.setState({isListOpen: false});
      this.setState({selectedFilter : null, error : null});
    }   
  }

  showSelectedFilter(item){
    this.setState({selectedFilter : item, error : null});
  }

  updateFilterValues(data){
    const { dateFormatDisplay } = this.props;
    const { change } = this.props;
    data && Object.keys(data) && Object.keys(data).length && Object.keys(data).forEach((item)=>{
      change(`${item}`, data[`${item}`])
    });
  }

  resetForm(){
    const { change , userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    let advancedFilters = StorageGateway.get(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`);
    advancedFilters = advancedFilters && JSON.parse(advancedFilters);
    this.filterList && Object.keys(this.filterList).length &&  Object.keys(this.filterList).forEach((item)=>{
      change(`${this.props.advancedFilterCookie}${item}`, advancedFilters[`${this.props.advancedFilterCookie}${item}`] ? advancedFilters[`${this.props.advancedFilterCookie}${item}`] : null)
    });
  }

  removeSelectedFilter(data){
    const { filters } = this.state;
    const { change , userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    let advancedFilterCookie =  StorageGateway.get(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`);
    advancedFilterCookie = advancedFilterCookie && JSON.parse(advancedFilterCookie);
    Object.keys(advancedFilterCookie).forEach(key => key == `${this.props.advancedFilterCookie}${data}` ? delete advancedFilterCookie[`${this.props.advancedFilterCookie}${data}`] : {});
    StorageGateway.set(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`, advancedFilterCookie);
    let remainingFilters = filters && filters.length && filters.filter((item)=>item.key !==data);  
    this.setState({filters: remainingFilters, openFilterPopup : false});
    this.applySelectedFilter(advancedFilterCookie);
    change(`${this.props.advancedFilterCookie}${data}`, null)
  }

  selectedFilterList(data){
    const { dateFormat } = this.props;
    let filterAll = [
      {label : 'Job Status', value: data[`${this.props.advancedFilterCookie}status`] &&  data[`${this.props.advancedFilterCookie}status`].label, key: 'status', filterName : 'Job Status'},
      {label : 'Employees', value : data[`${this.props.advancedFilterCookie}employee_name`] && `${data[`${this.props.advancedFilterCookie}employee_name`].length} Employee(s)`, key: 'employee_name', filterName : 'Employee Name'},
      {label : 'Job Codes', value : data[`${this.props.advancedFilterCookie}job_code`] && `${data[`${this.props.advancedFilterCookie}job_code`].length} Job Code(s)`, key : 'job_code', filterName : 'Job Code'},
      {label : 'Job Tags', value : data[`${this.props.advancedFilterCookie}job_tag`] && `${data[`${this.props.advancedFilterCookie}job_tag`].length} Job Tag(s)`, key : 'job_tag', filterName : 'Job Tags'},
      {label : 'Tasks', value : data[`${this.props.advancedFilterCookie}task_name`] && `${data[`${this.props.advancedFilterCookie}task_name`].length} Task(s)`, key : 'task_name', filterName : 'Task Name'},
      {label : 'Task Tag', value : data[`${this.props.advancedFilterCookie}task_tag`] &&`${data[`${this.props.advancedFilterCookie}task_tag`].length} Task Tag(s)`, key : 'task_tag', filterName : 'Task Tags'},
      {label : 'Jobs', value : data[`${this.props.advancedFilterCookie}job_name`] &&`${data[`${this.props.advancedFilterCookie}job_name`].length} Job(s)`, key : 'job_name', filterName : 'Job Name'},
      {label : 'Customer Name', value : data[`${this.props.advancedFilterCookie}customer_name`] &&`${data[`${this.props.advancedFilterCookie}customer_name`].length} Customer(s)`, key : 'customer_name', filterName : 'Customer Name'},
      {label : 'Employee Tags', value : data[`${this.props.advancedFilterCookie}employee_tags`] &&`${data[`${this.props.advancedFilterCookie}employee_tags`].length} Employee Tag(s)`, key : 'employee_tags', filterName : 'Employee Tags'},
    ]
    return filterAll;
  }


  applySelectedFilter(data, type){
    const { dateFormatDisplay , userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    let detail = { 
      customer_ids: data[`${this.props.advancedFilterCookie}customer_name`] && data[`${this.props.advancedFilterCookie}customer_name`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}customer_name`].map((i) => i.id)) : null,
      status: data[`${this.props.advancedFilterCookie}status`] && data[`${this.props.advancedFilterCookie}status`].value,
      job_tags: data[`${this.props.advancedFilterCookie}job_tag`] && data[`${this.props.advancedFilterCookie}job_tag`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}job_tag`].map((i) => i.name)) : null,
      task_tags: data[`${this.props.advancedFilterCookie}task_tag`] && data[`${this.props.advancedFilterCookie}task_tag`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}task_tag`].map((i) => i.name)) : null,
      employee_ids : data[`${this.props.advancedFilterCookie}employee_name`] && data[`${this.props.advancedFilterCookie}employee_name`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}employee_name`].map((i) => i.id)) : null,
      job_ids : data[`${this.props.advancedFilterCookie}job_name`] && data[`${this.props.advancedFilterCookie}job_name`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}job_name`].map((i) => i.id)) : null,
      task_ids : data[`${this.props.advancedFilterCookie}task_name`] && data[`${this.props.advancedFilterCookie}task_name`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}task_name`].map((i) => i.id)) : null,
      employee_tags: data[`${this.props.advancedFilterCookie}employee_tags`] && data[`${this.props.advancedFilterCookie}employee_tags`].length ? JSON.stringify(data[`${this.props.advancedFilterCookie}employee_tags`].map((i) => i.name)) : null,
    };
    if(data[`${this.props.advancedFilterCookie}job_code`] && data[`${this.props.advancedFilterCookie}job_code`].length){
      if(detail.job_ids && detail.job_ids.length){
        let job_code_ids = data[`${this.props.advancedFilterCookie}job_code`].map((i) => i.id);
        detail['job_ids'] = JSON.stringify(JSON.parse(detail.job_ids).concat(job_code_ids))
      }
      else{
        let job_code_ids = JSON.stringify(data[`${this.props.advancedFilterCookie}job_code`].map((i) => i.id));
        detail.job_ids = job_code_ids
      }
    }
    
    Object.keys(detail).forEach(key => detail[key] === undefined  || detail[key] === null  ? delete detail[key] : {});
    this.props.applyAdvancedFilters(detail);
    this.filterAll = this.selectedFilterList(data);
    const initialData = {...data };

    this.setState({filters: this.filterAll.filter((item)=>item.value !== undefined && item.value !== null && item !== null), isListOpen : false, selectedFilter : null , initialValues : initialData, openFilterPopup : false});
    StorageGateway.set(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`, JSON.stringify(data));
    this.updateFilterValues(data);
  }

  renderFilters(item){
    const { selectedFilter , filters } = this.state;
    const { getCustomers , customerList , getEmployees , employeeList , getJobs , jobsAndCodesList , jobTagList , getJobTags , getTasks , TasksAndCodesList , taskTagList , getTaskTags , handleSubmit , change, job_name , date_range , dateFormat, dateFormatDisplay , getEmployeeTags , employeeTagsList} = this.props;
    const jobCodeArray = jobsAndCodesList && jobsAndCodesList.length && jobsAndCodesList.map(i => ({ ...i, code: i.code , id: i.id }));
    let filteredJobCodeArray =   jobCodeArray && jobCodeArray.filter(i => i.code !== null && i.code !== undefined && (i.code).trim() !== ""); 
    filteredJobCodeArray =  filteredJobCodeArray && filteredJobCodeArray.length && filteredJobCodeArray.sort(this.props.sortJobCodes);
    const newJobTags = jobTagList[0] ? (jobTagList[0].tag_collection.map((item) => {
      const list = {};
      list.name = item;
      list.id = item;
      return list;
    })) : [];

    const newTaskTags = taskTagList[0] ? (taskTagList[0].tag_collection.map((item) => {
      const list = {};
      list.name = item;
      list.id = item;
      return list;
    })) : [];
    return (
      <div>
        {
          (selectedFilter == 'Customer Name' || item == 'Customer Name')&&
            <div className='custom-filter-dropdown  job-center-advance-filter'>
              <Field
                id={`${this.props.advancedFilterCookie}customer_name`}
                name={`${this.props.advancedFilterCookie}customer_name`}
                label='Customer Name'
                component={AsyncMultiSelectAtom}
                itemToString={(i) => i && i.customer_name}
                items={customerList}
                getData={getCustomers}
                placeholder="Enter customer name"
              />
            </div>
        }
        {
         (selectedFilter == 'Employee Name' || item == 'Employee Name') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}employee_name`}
              name={`${this.props.advancedFilterCookie}employee_name`}
              label="Employee Name"
              component={AsyncMultiSelectDropdownAtom}
              itemToString={(i) => i && i.full_name}
              items={employeeList}
              getData={getEmployees}
              placeholder="Type to search"
            />
          </div>
        }
        {
          (selectedFilter == 'Job Code' || item == 'Job Code') &&
          <div className='custom-filter-dropdown  job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}job_code`}
              name={`${this.props.advancedFilterCookie}job_code`}
              label="Job Code"
              component={AsyncMultiSelectAtom}
              items={filteredJobCodeArray && filteredJobCodeArray.length ? filteredJobCodeArray : [] }
              itemToString={(i) => i && i.code}
              getData={(val)=>getJobs(val, this.props.advancedFilterCookie)}
              placeholder="Job Code"
            />
          </div>
        }
        {
          (selectedFilter == 'Job Name' || item == 'Job Name') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}job_name`}
              name={`${this.props.advancedFilterCookie}job_name`}
              label="Job(s)"
              component={AsyncMultiSelectAtom}
              itemToString={(i) => i && i.name}
              items={jobsAndCodesList && jobsAndCodesList.length ? jobsAndCodesList : [] }
              getData={(val)=>getJobs(val, this.props.advancedFilterCookie)}
              placeholder="Job(s)"
            />
          </div>
        }
        {
          (selectedFilter == 'Job Tags' || item == 'Job Tags') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}job_tag`}
              name={`${this.props.advancedFilterCookie}job_tag`}
              label="Job tags(s)"
              component={AsyncMultiSelectAtom}
              itemToString={(i) => i && i.name}
              items={newJobTags && newJobTags.length ? newJobTags : [] }
              getData={getJobTags}
              placeholder="Job tags(s)"
            />
          </div>
        }
        {
          (selectedFilter == 'Task Name' || item == 'Task Name') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}task_name`}
              name={`${this.props.advancedFilterCookie}task_name`}
              label="Task(s)"
              component={AsyncMultiSelectAtom}
              itemToString={(i) => i && i.name}
              items={TasksAndCodesList && TasksAndCodesList.length ? TasksAndCodesList : [] }
              getData={(val) => getTasks(val, this.props.advancedFilterCookie)}
              placeholder="Task(s)"
            />
          </div>
        }
        {
          (selectedFilter == 'Task Tags' || item == 'Task Tags') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}task_tag`}
              name={`${this.props.advancedFilterCookie}task_tag`}
              label="Task Tags"
              component={AsyncMultiSelectAtom}
              itemToString={(i) => i && i.name}
              items={newTaskTags && newTaskTags.length ? newTaskTags : [] }
              getData={getTaskTags}
              placeholder="Task Tags"
            />
          </div>
        }
        {
          (selectedFilter == 'Employee Tags' || item == 'Employee Tags') &&
          <div className='custom-filter-dropdown job-center-advance-filter'>
            <Field
              id={`${this.props.advancedFilterCookie}employee_tags`}
              name={`${this.props.advancedFilterCookie}employee_tags`}
              label="Employee Tags"
              component={AsyncMultiSelectAtom}
              itemToString={(i) => i && i.name}
              items={employeeTagsList && employeeTagsList.length ? employeeTagsList : [] }
              getData={getEmployeeTags}
              placeholder="Employee Tags"
            />
          </div>
        }
        {
          (selectedFilter == 'Job Status' || item == 'Job Status') &&
          <div className='custom-filter-dropdown job-center-advance-select'>
            <Field
              id={`${this.props.advancedFilterCookie}status`}
              name={`${this.props.advancedFilterCookie}status`}
              label="Status"
              component={SelectAtom}
              options={FILTER_OPTIONS}
              placeholder="Status"
              labelKey="label"
              valueKey="value"
              layout={FORM_LAYOUT}
            />
          </div>
        }
      </div>
      );
  }

  render() {
    const { selectedFilter , filters , error} = this.state;
    const { getCustomers , customerList , getEmployees , employeeList , getJobs , jobsAndCodesList , jobTagList , getJobTags , getTasks , TasksAndCodesList , taskTagList , getTaskTags , handleSubmit , job_name, change , date_range} = this.props;
    return (
        <div className='w-100'>
          <Row>
            <Col md={2}>
              <div onClick={()=>this.toggleFilterList(this.props.advancedFilterCookie)} id='filters'>
                <span id={this.props.advancedFilterCookie} className={`${styles['advanced-filter-text']} link-name`}>Filter Dashboard</span>
                <FilterIcon className='link-name' width="20" height="17"/>
              </div>
            </Col>
            <Col md={8}>
              <ManageAdvancedFiltersDetailedForm
                filters={filters}
                key={this.props.advancedFilterCookie}
                renderFilters ={this.renderFilters}
                initialValues={this.state.initialValues }
                applySelectedFilter={this.applySelectedFilter}
                advancedFilterCookie = {this.props.advancedFilterCookie}
                onSubmit = {this.applySelectedFilter}
                removeSelectedFilter = {this.removeSelectedFilter}
                openFilterPopup = {this.state.openFilterPopup}
                updateFilter = {this.updateFilter}
                toggle= {this.toggle}
                resetForm = {this.resetForm}
                dateFormatDisplay = {this.props.dateFormatDisplay}
                userProfile={this.props.userProfile}
                form={this.props.advancedFilterCookie}
              />
            </Col>
          </Row>
          { this.state.isListOpen == this.props.advancedFilterCookie && 
            <Popover placement="right" isOpen={this.state.isListOpen == this.props.advancedFilterCookie} target={this.props.advancedFilterCookie}>
              <Form onSubmit={handleSubmit(this.applySelectedFilter)} className='w-100'>
                <div className={`${styles['filter-dashboard-popover']} box arrow-left pr-3`} tabIndex="0">
                  <span className={`${styles['filter-heading']}`}>Choose filters</span>
                  <span><img src={closeimage} height="10" width="10" className={`${styles['filter-heading-cross']}`} onClick={()=> {this.toggleFilterList() ; this.resetForm()}}/></span>
                  <div>
                  {selectedFilter == null &&
                    <div className={`${styles['filter-list']}`}>
                    {
                      this.filterList && (Object.values(this.filterList)).map((item)=>(
                        <span className= 'd-block link-name' onClick={()=> this.showSelectedFilter(item)}>{item}</span>
                        ))
                    }
                  </div>
                  }
                  </div>
                  <div>
                  {
                    selectedFilter && 
                    <div className={`${styles['custom-position-back']} pos-relative f-12 mb-3`}>
                      <BackIcon className={`${styles['back-icon-pos']}`} width="20" height="8"/>
                      <div className={`${styles['back-text-pos']} ${styles['back-text-underline']} cursor-pointer`} onClick={()=> {this.setState({selectedFilter : null, error : null}); this.resetForm()}}>Back</div>
                    </div>
                  }
                  </div>
                  <div>
                  {
                    error && selectedFilter == 'Date Range' && this.state.isListOpen == this.props.advancedFilterCookie && date_range && date_range.value == '6'  && <div className={`${styles['error-text-filters']}`}>{error}</div>
                  }
                  </div>
                  <div>
                  {
                    selectedFilter &&
                    <div>{this.renderFilters()}</div>
                  }
                  {
                    selectedFilter &&
                    <Button type="submit" color="secondary" className='f-12 mt-3' style={{lineHeight: '1'}}>
                      Apply
                    </Button>
                  }
                </div>
                </div>
              </Form>
            </Popover>
          }
        </div>
    );
  }
};


const ManageJobAdvancedFilterForm = reduxForm({
  enableReinitialize: true,
})(ManageJobAdvancedFilterFormComponent);

const quotedRecurringSelector = formValueSelector('form1');
const quotedProjectSelector = formValueSelector('form2');
const timeAndMaterialSelector = formValueSelector('form3');


export default connect(
  (state, props) => {
    const job_name = props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}job_name`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}job_name`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}job_name`);
    const date_range =  props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}date_range`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}date_range`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}date_range`);
    const tasks = props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}tasks`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}tasks`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}tasks`);
    return { job_name , tasks , date_range };
  },
)(ManageJobAdvancedFilterForm);

