import React from 'react';

import StorageGateway from 'lib/storage-gateway';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { toast } from 'react-toastify';

import * as PluginListDucks from 'ducks/vendors/plugin';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PluginQBComponent from 'components/vendors/plugins/QBContent';
import PluginModalComponent from 'components/vendors/plugins/PluginModal';

class QBContentSection extends React.Component {
  constructor(props) {
    super(props);
    this.goToSetting = this.goToSetting.bind(this);
    this.state = { isOpen: false, isModalLoading: false, isSubmitting: false, isCreatingProfile: false };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.downloadConnectors = this.downloadConnectors.bind(this);
    this.redirect = this.redirect.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.createProfile = this.createProfile.bind(this);
    this.disconnectProfile = this.disconnectProfile.bind(this);
  }

  goToSetting(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  save(data){
    this.setState({ isSubmitting: true });
    return this.props.PluginListDucks.updateProfile(data)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.toggle();
        toast.success('QuickBooks Desktop profile successfully created');
      })
  }

  handleModalClose(){
    this.setState({ isSubmitting: false });
  }

  toggle(){
    const { isOpen , isSubmitting } = this.state;
    if(isSubmitting) return null;
    if(!isOpen) {
      this.setState({ isModalLoading: true });
      this.props.PluginListDucks.getProfile()
        .finally(() => this.setState({ isOpen: !isOpen, isModalLoading: false }))
    }
    else{
      this.setState({ isOpen: !isOpen });
    }
    return true;
  }

  downloadConnectors(){
    this.props.PluginListDucks.getFilepath()
     .then((data) => {
        const filename =  `webconnectors.qwc`;
        if (window.navigator.msSaveOrOpenBlob) {
        const a = document.createElement("download");
        document.body.appendChild(a);
        a.style.display = "none";
        a.onclick = ((evx) => 
        {
          const  myBlob =  new Blob([data.action.payload.data] ,
            {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          window.navigator.msSaveOrOpenBlob(myBlob, filename);
        });
        a.click();
      }
      // chrome ,safari and mozilla browsers 
      else {
        const  myBlob =  new Blob([data.action.payload.data] ,
          {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const  url = window.URL.createObjectURL(myBlob);
        const element = document.createElement("a");
        document.body.appendChild(element);
        element.href = url;
        element.download = filename;
        element.click();
  }
})
      }
  

  createProfile() {
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const profile_id = StorageGateway.get('company');
    const Token = StorageGateway.get('Token');
    const data = {
      plugin_name: 'QUICKBOOKS DESKTOP',
      token: Token,
      profile_id: profile_id,
      profile_uuid: uniqueUuid,
      destination_platform: 'QBD'
    };
    this.setState({ isCreatingProfile: true });
    this.props.PluginListDucks.createProfile(data)
      .then((response) => {
        const pluginToken = response && response.value && response.value.token;
        StorageGateway.set('pluginToken', pluginToken);
        toast.success('Quickbooks profile successfully created');
      })
      .catch((err) => toast.error(err.message))
      .finally(() => this.setState({ isCreatingProfile: false }));
  }

  disconnectProfile() {
    const oAuthToken = StorageGateway.get('oAuthToken');
    const data = {
      token: oAuthToken,
    }
    this.props.PluginListDucks.disconnectProfile(data);
  }

  redirect(){
    const url = process.env.REACT_APP_QBD_EXE_BASE || '';
    window.open(url);
  }

  render() {
    const { isLoading , pluginDetail } = this.props;
    const { isOpen, isModalLoading, isCreatingProfile } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <PluginQBComponent
          toggle={this.toggle}
          createProfile={this.createProfile}
          downloadConnectors={this.downloadConnectors}
          redirect={this.redirect}
          isLoading={isModalLoading}
          filePath={pluginDetail.destination_profile && pluginDetail.destination_profile.file_path}
          isCreatingProfile={isCreatingProfile}
        />
      {isOpen &&
        <PluginModalComponent
          isOpen={isOpen}
          toggle={this.toggle}
          save={this.save}
          handleModalClose={this.handleModalClose}
          updateProfile={this.updateProfile}
          initialValues={{ file_path: pluginDetail.destination_profile && pluginDetail.destination_profile.file_path }} />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // location: RouteDucks.location(state),
  pluginDetail: PluginListDucks.pluginSetting(state),
  filePath: PluginListDucks.filePath(state),
  authorization: UserDucks.authorization(state),
  profile: UserDucks.profile(state),
});

const mapActionsToProps = dispatch => ({
  PluginListDucks: bindActionCreators(PluginListDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

QBContentSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  // location: PropTypes.object.isRequired,
  PluginListDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  authorization: PropTypes.string.isRequired,
};

QBContentSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(QBContentSection);
