import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as RouteDucks from 'ducks/routes';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import CircleComponent from 'components/common/googleMap/Circle';
import MapComponent from 'components/common/googleMap/Map';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import InfoWindowComponent from 'components/common/googleMap/InfoWindow';

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

/**
 * GeoFenceListPage -> GeoFenceMapSection
 *
 * Components:
 *    - {@link GeoFenceMapTableComponent}
 *
 * State:
 *    - activity track list
 *
 * Actions:
 *    None
 */
class GeoFenceMapSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: false,
      activeShape: {},
      shapeEvent: {},
      data: {},
    };
    this.onShapeClick = this.onShapeClick.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
  }

  onShapeClick(data, shape, event) {
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data,
    });
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }


  render() {
    const { geoFenceList, isLoading, location, google, loaded } = this.props;
    const { activeShape, showInfoWindow, shapeEvent, data } = this.state;
    // const shapeTypes = geoFenceList.results.map(item => item.shape_type);

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="geo-fence-map" >
        {isLoading && !loaded
          ? <SectionLoaderAtom active />
          :
          <MapComponent
            google={google}
            locations={geoFenceList.results}
            getAddressForMarker = {this.getAddressForMarker}
            customHeight = 'true'
          >
            {!isLoading && geoFenceList.results.map((item, index) => {
              if (item.shape_type === 'Point') {
                return <MarkerComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  position={{
                    lat: item.lat_long && item.lat_long.coordinates[1],
                    lng: item.lat_long && item.lat_long.coordinates[0],
                  }}
                  onClick={this.onShapeClick}
                />;
              } else if (item.shape_type === 'Polygon') {
                return <PolygonComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  paths={
                    item.lat_long &&
                      item.lat_long.coordinates[0].map(latLng => ({
                        lat: latLng[1],
                        lng: latLng[0],
                      }))
                  }
                  fillColor={item.color}
                  onClick={this.onShapeClick}
                />;
              }
              else if (item.shape_type === 'Rectangle') {
                return <RectangleComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  bounds={item.lat_long.coordinates}
                  fillColor={item.color}
                  onClick={this.onShapeClick}
                />;
              }
              else if (item.shape_type === 'Circle') {
                return <CircleComponent
                  key={item.id}
                  data={item}
                  radius={item.radius}
                  getAddressForMarker = {this.getAddressForMarker}
                  center={{
                    lat: item.lat_long && item.lat_long.coordinates[1],
                    lng: item.lat_long && item.lat_long.coordinates[0],
                  }}
                  fillColor={item.color}
                  onClick={this.onShapeClick}
                />;
              }
              return null;
            })}

            <InfoWindowComponent
              shape={activeShape}
              visible={showInfoWindow}
              shapeEvent={shapeEvent}
              data={data}
            >
              <div>{data.name}</div>
            </InfoWindowComponent>
          </MapComponent>
        }
        <PaginationComponent
          count={geoFenceList.count}
          location={location}
          cookieKey="geo_fence_map_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  geoFenceList: GeoFenceDucks.geoFenceList(state),
  location: RouteDucks.location(state),
});

const mapActionToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

GeoFenceMapSection.propTypes = {
  geoFenceList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  google: PropTypes.object,
  loaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

GeoFenceMapSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withConnect = connect(
  mapStateToProps,
  mapActionToProps,
)(GeoFenceMapSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(withConnect);
