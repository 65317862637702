import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as RouteDucks from 'ducks/routes';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import ActivityTrackFilterSection from 'sections/activities/activityTrackList/Filter';
import ActivityTrackListSection from 'sections/activities/activityTrackList/List';
import ActivityTrackMapSection from 'sections/activities/activityTrackList/LocationTrackMap';

import { NAV } from 'constants/activities';

/**
 * TrackCurrentListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ActivityTrackFilterSection}
 *    - {@link ActivityTrackListSection}
 *
 * Fetch Needs:
 *    - getActivityTracks
 */
class TrackCurrentListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.fetchNotPunchInData = this.fetchNotPunchInData.bind(this)

    this.state = { isLoading: false,
      session: 'current',
      isInfoOpen: true,
      permission: true,
      absentEmployee : false
    };
  }

  componentWillMount() {
    const cookieKey = cookie.load("current_tracking_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'activity.tracking.current'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const { session } = this.state;

    if (prevQuery !== nextQuery && nextQuery?.punch_in_options !== '2') {
      this.fetchData({ session, ...nextQuery });
    }
    if(nextQuery?.punch_in_options === '2'){
     this.setState({absentEmployee:true})
     this.fetchNotPunchInData({ session, ...nextQuery })
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  fetchNotPunchInData(params) {
    const pageSize = cookie.load('current_absent_emp_tracking_page_size');
    this.setState({ isLoading: true });
    this.props.ActivityTrackDucks.getAbsentActivityTracks({...params, page_size: pageSize , pagination : true})
      .then((res) => {
        console.log({res})
        this.setState({ isLoading: false, permission: true })})
      .catch(() => this.setState({ isLoading: false, permission: false }));
  }

  fetchData(params) {
    const pageSize = cookie.load('current_tracking_page_size');
    this.setState({ isLoading: true });
    if (params && params.view) {
      this.props.GeoFenceDucks.getCurrentActivityTracksGeofences();
    }
    this.props.ActivityTrackDucks.getActivityTracks({...params, page_size: pageSize})
      .then(() => this.setState({ isLoading: false, permission: true }))
      .catch(() => this.setState({ isLoading: false, permission: false }));
  }

  loadData(){
    const { location: { query } } = this.props;
    const { session } = this.state;
    if(query?.punch_in_options !== '2'){
      this.fetchData({ session, ...query });
    }
    if(query?.punch_in_options === '2'){
      this.fetchNotPunchInData({ session, ...query })
     }
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("current_tracking_info", !isInfoOpen);
  }

  toggleTable(type){
    this.setState({ absentEmployee: type === '2'? true:false });
  }

  render() {
    const { infoList, location: { query } } = this.props;
    const { isLoading, session, isInfoOpen, permission,absentEmployee } = this.state;

    return (
      <TwoColumnLayout
        navInfo={NAV.TrackCurrentListPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <ActivityTrackFilterSection session={session}  toggleTable={this.toggleTable} absentEmployee={absentEmployee}  view={query.view}/>
        {query.view
          ? <ActivityTrackMapSection isLoading={isLoading} permission={permission} />
          : (<ActivityTrackListSection
            isLoading={isLoading}
            session={session}
            absentEmployee={absentEmployee}
          />)
        }
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  //  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  //  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

TrackCurrentListPage.propTypes = {
  location: PropTypes.object.isRequired,
  ActivityTrackDucks: PropTypes.object.isRequired,
  //  DepartmentDucks: PropTypes.object.isRequired,
  //  TeamDucks: PropTypes.object.isRequired,
  GeoFenceDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

TrackCurrentListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(TrackCurrentListPage);
