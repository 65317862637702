import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  EMPLOYEE: `${SERVER_URL}/employee/`,
  EXPORT_EMPLOYEE: `${SERVER_URL}/employee/employee-csv/`,
  EMPLOYEE_BULK_UPLOAD :`${SERVER_URL}/employee/employee-bulk-upload/`,
  UPDATE_ACTIVE_STATUS: `${SERVER_URL}/employee/bulk/active/`,
  DASHBOARD_DATA: `${SERVER_URL}/company/stats/`,
  RESET_PASSWORD: `${SERVER_URL}/employee/reset-password/`,
  KIOSK_EMPLOYEE_AUDIT_LOGS: `face-login/fail/`,
  KIOSK_EMPLOYEES_AUDIT_LOGS: `${SERVER_URL}/company/face-login/`,
  VALIDATE_KIOSK_AUDITS: `${SERVER_URL}/t2b/faces/face/add-remove/`,
  RESEND_LINK : `${SERVER_URL}/employee/resend-sms/`,
  EMPLOYEE_TAGS : `${SERVER_URL}/job/tags/`,
  KIOSK_EMPLOYEES_ACTIVITY_LOGS: `face/details/`,
  CHANGED_BY: `${SERVER_URL}/company/users/`
}
