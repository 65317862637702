import React, { Component } from 'react'

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ReportDucks from 'ducks/reports/report';

import Table from 'reactstrap/lib/Table';

import ViewIconAtom from 'atoms/ViewIcon';
import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';
import FeedbackBlue from './FeedbackBlue.png';
import { Popover } from 'reactstrap';
import styles from './styles.module.scss';


class CorrectionActivitySplitTable extends Component {
  constructor(props){
    super(props);
    this.updateBoxes = this.updateBoxes.bind(this);
    this.state = {selectedId: null, isCommentLoader: false, commentMsgs:[]};
  }

  updateBoxes(id, item){
    this.setState({selectedId : id});
    if(item){
      this.setState({isCommentLoader: true});
      this.props.ReportDucks.getCorrectionMessage({session_id: item.session_id})
      .then((res)=>{
        this.setState({commentMsgs: res.value, isCommentLoader: false})
      })
      .catch((e)=>{
        this.setState({isCommentLoader: false});
      })
    }
  }

  render() {
    const {selectedId, isCommentLoader, commentMsgs} = this.state;
    const { data, location: { query }, pageSize , navigation } = this.props;
    const { page = 1 } = query;
    return (
      <div className="ml-3 mr-4">
      <Table hover responsive size="sm" className="align-table">
        <thead className="Header-col">
          <tr>
            <th className="text-center pl-3">Action</th>
            <th className="pl-3">Employee Name</th>
            <SortHeaderWithSeparateKeysComponent field='"activity_activitymaster"."name"'>
              Activity
            </SortHeaderWithSeparateKeysComponent>
            <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."start_datetime"'>
              Start
            </SortHeaderWithSeparateKeysComponent>
            <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."end_datetime"'>
              End
            </SortHeaderWithSeparateKeysComponent>
            <SortHeaderWithSeparateKeysComponent field='"activity_activitysplit"."total_time"'>
              Time
            </SortHeaderWithSeparateKeysComponent>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((item, index) =>
              index < page * pageSize && index >= (page - 1) * pageSize
              )
              .map(item => (
              <tr key={item.index}>
                <td className="text-center pl-3">
                  <div id={`commentout${item.index}`} className='d-inline-block' role="button" onClick={() => this.updateBoxes(`commentout${item.index}`, item)}>
                    <img 
                      src ={FeedbackBlue}
                      height="16"
                      width="16"
                      alt = "comment-icon"
                    />
                  </div>
                    <Popover placement="bottom" target={`commentout${item.index}`} isOpen={(`commentout${item.index}`===selectedId)} className={`${styles['comment-popover']}`}>
                        <div className="p-2" >
                          <div className="mb-2 d-inline-block">Comment :</div><button type="button" className="close d-inline-block" aria-label="Close" onClick = {() =>  this.updateBoxes(null)} ><span aria-hidden="true">×</span></button>
                          {isCommentLoader ? <div className={styles['comment-feedback-loader']}>Loading...</div> : 
                          <div>
                            {commentMsgs.length !== 0 ? commentMsgs.map((i) => 
                              <div className='mb-2'>
                                <div>by {i?.user}</div>
                                <p className="mb-1">{i?.timestamp}</p>
                                <div style={{'white-space':'pre-line'}}>{i?.message?.replace(";","\r\n").replace("-","\r\n")}</div>
                              </div>
                              ):
                                <div className='text-center'>No Correction Message!</div>
                              }
                          </div>
                          }
                        </div>
                    </Popover>
                  <span title="View Activity">
                    <ViewIconAtom onClick={() => navigation(item)} className="cursor-pointer align-middle ml-2" />
                  </span>
                </td>
                <td title={`${item.first_name} ${item.last_name}`} className="pl-3">
                  {item.first_name} {item.last_name}
                </td>
                <td title={item.name}>{item.name}</td>
                <td title={item.start_datetime}>{item.start_datetime}</td>
                <td title={item.end_datetime}>{item.end_datetime}</td>
                <td title={item.total_time} className="text-right pr-3">{item.total_time}</td>
              </tr>
            ))
          }
          {!data.length && Object.keys(query).length > 0 &&
            <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
          }
          {!data.length && Object.keys(query).length === 0 &&
            <tr>
              <td className="text-center" colSpan="10">Search above for results</td>
            </tr>
          }
        </tbody>
      </Table>
      </div>
    );
  }
}


const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

CorrectionActivitySplitTable.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  null,
  mapActionsToProps,
)(CorrectionActivitySplitTable);
