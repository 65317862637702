import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const GreenLoaderAtom = (props) => {
  const { active } = props;
  if (!active) return null;
  return (
    <span className={styles['ball-clip-rotate']}>
      <div />
    </span>
  );
};

GreenLoaderAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default GreenLoaderAtom;
