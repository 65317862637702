import React from 'react';

import queryString from 'query-string';

import FilterDropdownComponent from 'components/common/FilterDropdown';
import FilterFormComponent from 'components/common/FilterForm';
import { YEARS, MONTHS } from 'constants/vendors';
import { BILL_STATUS_LIST } from 'constants/vendors';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';


class BillFilterFormComponent extends React.Component {

  render() { 
    const { history, location: { search } } = this.props;    
    const query = queryString.parse(search);
    return (
      <section>
        <Row className="filter pt-4 pl-3 pr-4">
          <Col className="d-flex pr-0 pl-0">
            <Col md={8}>
              <div className="float-left d-inline-block">              
                <div id="billing_status">
                  <FilterDropdownComponent
                    location={this.props.location}
                    history ={history}
                    paramKey="status"
                    valueLabel="name"
                    keyLabel="id"
                    options={BILL_STATUS_LIST}              
                  />
                  <UncontrolledTooltip placement="top" target="billing_status">
                    Select Billing Status
                  </UncontrolledTooltip>
                </div>
              </div>
              <div className="float-left d-inline-block" id="year">  
                <FilterDropdownComponent
                  location={this.props.location}
                  history ={history}
                  paramKey="year"
                  valueLabel="name"
                  keyLabel="id"
                  options={YEARS}
                />
                <UncontrolledTooltip placement="top" target="year">
                  Select Year
                </UncontrolledTooltip>
              </div>
              <div className="float-left d-inline-block" id="month">
                <FilterDropdownComponent
                  location={this.props.location}
                  history ={history}
                  paramKey="month"
                  valueLabel="name"
                  keyLabel="id"
                  options={MONTHS}
                />
                <UncontrolledTooltip placement="top" target="month">
                  Select Month
                </UncontrolledTooltip>
              </div>
            </Col>
            <Col>
              <div className="float-right">
                <FilterFormComponent
                  location={this.props.location}
                  placeholder="Search Clients"
                  initialValues={query}
                />
              </div>
            </Col>
          </Col>

        </Row>
      </section>  
    );
  }
};



export default BillFilterFormComponent;
