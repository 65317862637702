import React from 'react';
import { Row, Col} from 'reactstrap'
import cookie from 'react-cookies';
import queryString from 'query-string';
import Redirect from 'react-router-dom/Redirect';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';

import UserAPIGateway from 'accounts/gateways/user';
import TermsConditionUseCase from 'accounts/usecases/termsConditionUsecase';
import StorageGateway from 'lib/storage-gateway';
import * as UserDucks from 'ducks/accounts/user';

import TermsConditionForm from 'accounts/components/termsCondition';
import ContentLayout from 'layouts/Content';

class TermsConditionPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.executeCta = this.executeCta.bind(this);
    this.listenTermsConditionUseCase = this.listenTermsConditionUseCase.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new TermsConditionUseCase(userAPIGateway);
    this.hideLoader = this.hideLoader.bind(this);
    this.state = {isLoading:true, isAcceptButtonLoader: false, isDeclineButtonLoader: false};
  }

  componentWillMount(){
    this.listenTermsConditionUseCase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  hideLoader(){
    this.updateState('isLoading', false)
  }

  listenTermsConditionUseCase() {
    const { isLoggedInAdmin, isSubCompany } = this.props;
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_ACCEPT_BUTTON_LOADER':
          this.setState({isAcceptButtonLoader: true});
        break;
        case 'SHOW_DECLINE_BUTTON_LOADER':
          this.setState({isDeclineButtonLoader: true});
        break;
        case 'CTA_SUCCESS':
          this.props.history.push('/dashboard');
          this.props.dispatch({
            type: 'accounts/user/RESET_STATE',
          });
        break;
        case 'CTA_FAILURE':
          if(isLoggedInAdmin){
            this.usecase.backToMyDashboard(isLoggedInAdmin, isSubCompany)
          }
          else{
            StorageGateway.remove('oAuthToken');
            StorageGateway.remove('Token');
            StorageGateway.remove('uniqueUuid');
            StorageGateway.remove('graniteToken');
            StorageGateway.remove('userId');
            cookie.remove('Token', { path: '/' });
            cookie.remove('oAuthToken', { path: '/' });
            toast.error('Your account has been marked inactive. Please accept the terms and conditions to create an account.')
            this.props.dispatch({
              type: 'accounts/user/RESET_STATE',
            });
            this.props.history.push('/');
          }              
        break;
        case 'HIDE_BUTTON_LOADER':
          this.setState({isAcceptButtonLoader: false, isDeclineButtonLoader: false});
        break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  executeCta(ctaCode){
    this.usecase.ctaExecution(ctaCode);
  }


  render() {
    return (
       <ContentLayout>
        <Row className="content-area justify-center" style={{background : '#f2f5f8'}}>
          <Col md={{ size:12 }} className="p-0" >
            <TermsConditionForm
              hideLoader={this.hideLoader}
              isLoading={this.state.isLoading}
              executeCta = {this.executeCta}
              isAcceptButtonLoader = {this.state.isAcceptButtonLoader}
              isDeclineButtonLoader= {this.state.isDeclineButtonLoader}
            />
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}

const mapStateToProps = state => ({
  isLoggedInAdmin: UserDucks.isLoggedInAdmin(state),
  isSubCompany: UserDucks.isSubCompany(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsConditionPage);


