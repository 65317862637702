import React from 'react';
import cookie from 'react-cookies';
import queryString from 'query-string';
import bindActionCreators from 'redux/lib/bindActionCreators';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';

import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';
import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as EmployeeDucks from 'ducks/employees/employee';

import EmployeeListingFilter from 'components/timecards/customDailyTimecardList/employeeListingFilter';
import EmployeeListingList from 'components/timecards/customDailyTimecardList/employeeListingList';

import styles from './styles.module.scss';

class EmployeeListingSlider extends React.Component {
  constructor(props) {
    super(props);
    this.onDepartmentChange = this.onDepartmentChange.bind(this);
    this.onTeamChange = this.onTeamChange.bind(this);
    this.showDeviation = this.showDeviation.bind(this);
    this.fetchData= this.fetchData.bind(this);
    this.onEmployeeClickRedirect=this.onEmployeeClickRedirect.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.selectedEmployees = this.selectedEmployees.bind(this);
    this.state={
      departmentId:"",
      teamId: "",
      isShowDeviation: false,
      items:{},
      selectedEmployeeIds: []
    }
  }

  componentWillMount(){
    const { departmentId} = this.state;
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({paginate : false}),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        department_id: departmentId,
      }),
    ]);
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState){
   if(prevState.departmentId !== this.state.departmentId 
    || prevState.teamId !== this.state.teamId 
    || prevState.isShowDeviation !== this.state.isShowDeviation 
    || prevState.selectedEmployeeIds !== this.state.selectedEmployeeIds){
    this.fetchData();
   }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const { departmentId, teamId, isShowDeviation, selectedEmployeeIds} = this.state;
    let departmentIdsArray;
    if(nextQuery?.department_ids){
     
      if(Array.isArray(nextQuery.department_ids)){
        departmentIdsArray= nextQuery.department_ids;
      } 
      else{
        departmentIdsArray= [nextQuery.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));

    let teamIdsArray;
    if(nextQuery?.team_ids){
     
      if(Array.isArray(nextQuery.team_ids)){
        teamIdsArray= nextQuery.team_ids;
      } 
      else{
        teamIdsArray= [nextQuery.team_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));

    if (prevQuery !== nextQuery) {
      if(prevQuery.payroll_id !== nextQuery.payroll_id || prevQuery.start_date !== nextQuery.start_date || prevQuery.end_date !== nextQuery.end_date){
        const timecardData = {
          department_ids: JSON.stringify(departmentIdsIntegers),
          team_ids: JSON.stringify(teamIdsIntegers),
          end_date:nextQuery.end_date,
          start_date: nextQuery.start_date,
          paginate: false,
          manual_correction: isShowDeviation,
          payroll_id: nextQuery.payroll_id,
          employees: JSON.stringify(selectedEmployeeIds)
        }
        this.props.TimecardDucks.getCustomTimecards(timecardData)
      }
    }
  }

  fetchData(){
    const { departmentId, teamId, isShowDeviation, selectedEmployeeIds} = this.state;
    const { location: { query } } = this.props;
    let departmentIdsArray;
    let teamIdsArray;
    
    if(query?.department_ids){
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));


    if(query?.team_ids){
      if(Array.isArray(query?.team_ids)){
        teamIdsArray= query?.team_ids;
      } 
      else{
        teamIdsArray= [query?.team_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));
    const timecardData = {
      department_id: departmentId,
      employee_list:'true',
      include_pto_employees:false,
      team_id: teamId,
      end_date:query.end_date,
      start_date: query.start_date,
      paginate: false,
      manual_correction: isShowDeviation,
      payroll_id: query.payroll_id,
      employees: JSON.stringify(selectedEmployeeIds)
    }
    this.props.TimecardDucks.getCustomTimecards(timecardData)
  }

  onDepartmentChange(val){
    this.setState({departmentId: val, teamId: ""});
    this.props.TeamDucks.getAllTeams({
      paginate: false,
      department_id: val,
    });
  }

  onTeamChange(val){
    this.setState({teamId: val});
  }

  showDeviation(){
    const {isShowDeviation} = this.state;
    this.setState({isShowDeviation: !isShowDeviation})
  }

  onEmployeeClickRedirect(emp){
    const { location: { pathname, query }} = this.props;
    const {router: {history}} = this.context;
    const { departmentId, teamId} = this.state;
    const showEmployeeCookie = cookie.load('isShowEmployeeListCookie') && cookie.load('isShowEmployeeListCookie') == 'true' ? true : false;
    history.push({
      pathname,
      search: queryString.stringify({
        custom: true,
        first_name: emp.employee__user__first_name,
        last_name: emp.employee__user__last_name,
        employee_id: emp.employee__id,
        emp_dept_id: emp.employee__department_id,
        timecard_decimal_view: true,
        payroll_id: query.payroll_id,
        admin: true,
        beta: true,
        show_employee_list: showEmployeeCookie,
        start_date: query.start_date,
        end_date: query.end_date,
        department_ids: query.department_ids,
        team_ids: query.team_ids ,
      })
    })
  }

  getEmployees(data){
    const detail = { search: data, is_active: true , name_search : true };
    return this.props.EmployeeDucks.getEmployees(detail);
  }

  selectedEmployees(val){
    let selectedIds = [];
    val && val.map((item)=>{
      selectedIds.push(item.id);
    })
    this.setState({selectedEmployeeIds: selectedIds});
  }

  render() {
    const {departmentAll, teamAll, timecardList, showAllocateTasksPopup} = this.props;
    const {departmentId, teamId} = this.state;
    return (
      <section className={showAllocateTasksPopup ? `${styles['employee-list-slider-with-timecard']} ml-3` : `${styles['employee-list-slider']} ml-3`} style={{top: '137px', bottom: '55px'}}>
        <EmployeeListingFilter 
            departmentAll={departmentAll}
            teamAll={teamAll}
            onDepartmentChange={this.onDepartmentChange}
            onTeamChange={this.onTeamChange}
            teamId={teamId}
            departmentId={departmentId}
            showDeviation={this.showDeviation}
            getEmployees={this.getEmployees}
            employeeList={this.props.employeeList.results}
            selectedEmployees={this.selectedEmployees}
        />
        <EmployeeListingList data={timecardList} location={this.props.location} isShowDeviation={this.state.isShowDeviation} onEmployeeClickRedirect={this.onEmployeeClickRedirect} selectedEmployeeIds={this.state.selectedEmployeeIds}/>
      </section>
    );
  }
}

const mapStateToProps = state =>({
    departmentAll: DepartmentDucks.departmentAll(state),
    teamAll: TeamDucks.teamAll(state),
    location: RouteDucks.location(state),
    timecardList: TimecardDucks.timecardList(state),
    employeeList: EmployeeDucks.employeeList(state),
});

const mapActionsToProps = action =>({
    DepartmentDucks: bindActionCreators(DepartmentDucks, action),
    TeamDucks: bindActionCreators(TeamDucks, action),
    RouteDucks: bindActionCreators(RouteDucks, action),
    TimecardDucks: bindActionCreators(TimecardDucks, action),
    EmployeeDucks: bindActionCreators(EmployeeDucks, action),
})

EmployeeListingSlider.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapActionsToProps
)(EmployeeListingSlider);
