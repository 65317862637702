import React, { Component } from 'react';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Field from 'redux-form/lib/Field';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import SelectAtom from 'atoms/Select';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import Button from 'reactstrap/lib/Button';
import { FORM_LAYOUT } from 'constants/layout';
import { EMPLOYEES_FILTER_OPTIONS, EMPLOYEES_FILTER_TYPE , EMPLOYEES_STATUS_OPTIONS} from 'constants/vendors';
import MapsToIcon from './mapsto.png';
import UnlinkIcon from './unlink.png';
import { toast } from 'react-toastify';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import moment from 'moment'

import * as PluginDucks from 'ducks/vendors/plugin';

import styles from './styles.module.scss';

class JobSubtableComponent extends Component {

  constructor(props){
    super(props);
    this.saveMapping= this.saveMapping.bind(this);
    this.handleJobSelection = this.handleJobSelection.bind(this);
    this.searchJobs= this.searchJobs.bind(this);
    this.setJob= this.setJob.bind(this);
    this.removeMapping = this.removeMapping.bind(this);
    this.state={
     selectedJobs:[],
     isOpen : null,
     saveMappingLoader: false,
     unlinkLoader: null,
   }
  }

  setJob(JobId){
     this.setState({isOpen: null});
  }

  searchJobs(jobId){
    this.setState({isOpen: jobId});
  }

  handleJobSelection(selected, val){
    const { fetchData } = this.props;
    this.setState({unlinkLoader : selected})
    const { selectedJobs } = this.state;
    var index = selectedJobs.indexOf(selected);
    if(index === -1){
      selectedJobs.push(selected);
    }
    this.props.removeJobsMapping(selectedJobs)
    .then(() => {
          fetchData();
          this.setState({unlinkLoader: null});
        });
  }

  removeMapping(){
    const { removeJobsMapping, fetchData } = this.props;
    const { selectedJobs } = this.state;
    return removeJobsMapping(selectedJobs)    
       .then(() => {
          fetchData();
        });
  }

  saveMapping(){
    const { setMapping, fetchData, qbdJobsList } = this.props;
    this.setState({saveMappingLoader : true});
    let jobs;
    jobs = qbdJobsList && qbdJobsList.filter(item => item.destination_job.name);
    if (jobs.length === 0){
      this.setState({saveMappingLoader : false});
      toast.error('Please set atleast one job for association');
      return
     }
    else 
    return setMapping(jobs)
       .then(() => {
          this.setState({saveMappingLoader : false});
          fetchData();
        })
       .catch((e) => {
        this.setState({saveMappingLoader : false});
        toast.error('Please set atleast one job for association');
    });
  }

  render() {
    const { source, qbdJobsList, location , openEmployeeSummary, openTaskSummary, onChangeJobs, qbdDropdownJobsList,basicSettingDetail} = this.props;
    const { saveMappingLoader, unlinkLoader} = this.state;
    const { isOpen } = this.state;
    const mapped = location.query.synced;
    return(
     <Row>
      <Col>
        <Row>
          <Col>
            <Row>
              <Col>

                <div className="filter">
                <div className="d-inline-block">
                  <FilterDropdownComponent
                    paramKey="synced"
                    location={location}
                    options={EMPLOYEES_FILTER_OPTIONS}
                  />
                </div>
                <div className="d-inline-block">
                  <FilterDropdownComponent
                    paramKey="type"
                    location={location}
                    options={EMPLOYEES_FILTER_TYPE}
                  />
                </div>
                <div className="d-inline-block">
                  <FilterDropdownComponent
                    paramKey="status"
                    location={location}
                    options={EMPLOYEES_STATUS_OPTIONS}
                    className="task-override"
                    dropicon="joblist"
                  />
                </div>

                  {mapped==='false' && qbdJobsList.length>0  ?
                    <Button
                      type="submit"
                      color="secondary"
                      className="float-right"
                      onClick={() => this.saveMapping()}
                    >
                      Save Association <ButtonLoaderAtom active={saveMappingLoader} />
                    </Button>
                    :
                    null
                   /* <Button
                      type="submit"
                      color="secondary"
                      className={ `float-right`}
                      onClick={() => this.removeMapping()}
                    >
                      Remove Association
                    </Button>*/
                  }

                  </div>

              </Col>
            </Row>
            {qbdJobsList.length>0 &&
            <Row>
              <Col md="6" className = "text-left pl-5 ml-2 pt-3 pb-2">
               <h5><b>Jobs in Worksana</b></h5>
              </Col>
              <Col className="text-left pl-5 ml-2 pt-3 pb-2">                
                <h5><b>{source==='ADP'?'Jobs in ADP':'Jobs in Quickbooks'}</b></h5>
              </Col>
            </Row>
          }
            </Col>
        </Row>
       {qbdJobsList && qbdJobsList.map((item, index) => (
          <Row>
            <Col className={`${styles['checkbox-col']}`}>
           {/*{mapped==='true' &&
            <span className={`${styles['icon-checkbox-height']} d-table`}>
              <div className="d-table-cell" style={{verticalAlign:'middle'}}>
              <input
                type="checkbox"
                value={item.isSelected}
                onChange={(val) =>this.handleJobSelection(item, val)}
              />
              </div>
             </span>

             }*/}
             </Col>
            <Col md="5">
                <Row>
                  <Col md="11"  className={styles['job']}>
                    <p className={styles['card-view-div']}>
                      {item.job.name}</p> 
                    <h6 color="info" style={{textTransform: 'uppercase',}} >{item.job.customer_data.customer_name}</h6>
                    <h6 color="info"><span>
                  {item.job.employee_count>=0 ? `Number of employees :` : null}</span><span style={{ color: '#031955', cursor: 'pointer', paddingLeft: '10px' }} onClick={() => openEmployeeSummary(item.job.id)}>{item.job.employee_count>=0 ? item.job.employee_count : null}</span></h6>
                  <h6 color="info">{item.job.task_count>=0 ? `Number of tasks :` : null}<span style={{ color: '#031955', cursor: 'pointer', paddingLeft: '10px'}} onClick={() => openTaskSummary(item.job.id)}>{item.job.task_count>0 ? item.job.task_count : '0'}</span></h6>
                  <h6 color="info"><span>{item.job.create_date_display && moment(item.job.create_date_display).format(basicSettingDetail.date_format_display)}</span></h6>
                  </Col>
                </Row>
            </Col>
            <Col md="auto" className={`${styles['icon-checkbox-height']}`} style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'center', paddingLeft:'0px', paddingRight:'15px'}}>
              <div>
              <div>
                <img src={MapsToIcon} height="30px" width="80px" alt="Logo" />
              </div>
              {mapped==='true' &&
              <div className="filter mt-1 text-center">
              <Button
                type="button"
                onClick={() => this.handleJobSelection(item, item)}
                style={{lineHeight: '1.3px', padding: '4px 8px', fontSize: '13px'}}
              >
              <span style={{paddingRight: '2px'}}><img src={UnlinkIcon} height="17px" width="17px" alt="Logo" /></span>Unlink <span style={{height: '10px !important', width:'10px !iimportant'}}><ButtonLoaderAtom active={unlinkLoader === item}/></span></Button>
              </div>
            }
              </div>
            </Col>
            <Col md="5">
              <Row>
                <Col md="11" className={`${styles['job']} ml-4`}>
                {item.destination_job.name && isOpen !== item.job_id &&
                  <div>
                   <p className={`${styles['card-view-div']}`}>
                      {item.destination_job.name ? item.destination_job.name : null}</p>
                  <h6 color="info" style={{textTransform: 'uppercase'}}>{item.destination_job.full_name ? item.destination_job.full_name : null}</h6>
                  <Col style={{height:'45px'}}></Col>
                  {mapped==='false' &&
                 <Row>
                  <Col style={{float:'right'}} className="filter">
                  <Button
                    type="button"
                    color="primary"
                    style={{float:'right'}}
                    className="btn-width"
                    onClick={() => this.searchJobs(item.job_id)}
                  >
                    More Jobs
                  </Button>
                  </Col>
                  </Row>

                }
                </div>
                  }
                  {!item.destination_job.name && isOpen !== item.job_id &&
                <div>
                <Col>
                  <Row>
                  <Col className="text-left">
                  <h6 style={{
                    color : '#B1BABF'
                  }}><i>No Suggestions Found</i></h6>
                  </Col>
                  </Row>

                {mapped==='false' &&
                  <Row>
                  <Col md='12' style={{minHeight: '69px'}} className="pr-0">
                  </Col>
                  <Col style={{float:'right'}} className="filter pr-0">
                  <Button
                    type="button"
                    color="primary"
                    style={{float:'right'}}
                    className="btn-width"
                    onClick={() => this.searchJobs(item.job_id)}
                  >
                    More Jobs
                  </Button>
                  </Col>
                  </Row>


                }
                </Col>
                </div>
                }
                  {isOpen === item.job_id &&
                    <div>
                    <div>
                      {/*<Field
                        component={AutoCompleteAtom}
                        getData={(val) => getQbdJobs(val)}
                        id={`${item}.job_id`}
                        mapKeys={{ label: 'name' , value: 'id'}}
                        name={`${index}`}
                        layout={FORM_LAYOUT}
                        placeholder="Jobs"
                        onChange={(i) => onChangeJobs({ ...item,
                            suggested_employee_id: i.value})}
                      />*/}
                      <Field
                        id={`${item}.job_id`}
                        name={`${item}.job_id`}
                        label=""
                        component={SelectAtom}
                        options={qbdDropdownJobsList &&  qbdDropdownJobsList.length >0 ? qbdDropdownJobsList.map(i => ({ label: i.job.name, value: i.job_id })) : ''}
                        placeholder="Jobs"
                        labelKey="label"
                        valueKey="value"
                        layout={FORM_LAYOUT}
                        onChange={(i) => onChangeJobs({ ...item,
                            suggested_employee_id: i.value})}
                      />

                    </div>
                    <Row>
                  <Col md='12' style={{minHeight: '30px'}}>
                  </Col>
                  <Col style={{float:'right'}} className="filter">
                  <Button
                    type="button"
                    color="primary"
                    style={{float:'right'}}
                    className="btn-width"
                    onClick={() => this.setJob(item.job_id)}
                  >
                    Set job
                  </Button>
                  </Col>
                  </Row>
                  </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
       {qbdJobsList.length<=0 && <Row><Col style={{color: '#B1BABF', fontSize:'18px',paddingTop:'120px'}}className="text-center" colSpan="10">No Records Found</Col></Row>}
       </Col>
    </Row>
  );
  }
}

const mapStateToProps = state => {
  return({
  qbdJobsList: PluginDucks.qbdJobsList(state).results,  
})};

const mapActionToProps = dispatch => ({
  PluginDucks: bindActionCreators(PluginDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(JobSubtableComponent);
// export default JobSubtableComponent;
