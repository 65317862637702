import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";
import queryString from "query-string";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as InfoDucks from "ducks/info/info";
import * as UserDucks from "ducks/accounts/user";
import * as RouteDucks from "ducks/routes";

import { Button, Col, Form, Row } from "reactstrap";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import InputAtom from "atoms/Input";
import SelectAtom from "atoms/Select";
import { FORM_LAYOUT } from "constants/layout";
import { WEEKDAYS_IN_SHORT } from "constants/jobs";
import { withRouter } from "react-router-dom";
import styles from "sections/jobs/jobEmployeeEdit/styles.module.scss";

const MEAL_TYPE_LIST = [
  { value: "AUTOMATIC_MEAL_DEDUCT", label: "Automatic Meal Deduct" },
  { value: "MEAL_PREMIUM_ADJUSTMENT", label: "Meal Premium Adjustments" },
];

class MealDetailsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disable: false,
      nextLoader: false,
    };
    this.submit = this.submit.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
  }

  //In this function we are getting redirected to path which this function receives

  goToUrl(url) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { query },
    } = this.props;
    if (query.setup) {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query, setup: true }),
      });
    } else {
      history.push({
        pathname: `${url}`,
        search: queryString.stringify({ ...query }),
      });
    }
  }

  // this function is called when we submit the form

  async submit(data) {
    const {
      location: { search },
    } = this.props;

    const { history, selectedId,is_active } = this.props;

    if(is_active===false){
      history.push({
        pathname: `/vendor/meal-adjustments/${selectedId}/mealSettings`,
        search: queryString.stringify({
          ...queryString.parse(search),
          setup: true,
        }),
      });
      return;
    }

    const errors = validate(data);
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => toast.error(error));
      return;
    }

    let obj = {
      name: data.name,
      description: data.description,
      meal_rule_type: data.meal_type.value || data.meal_type,
      id: selectedId ? selectedId : undefined,
      rule_configuration: this.props.mealTypes,
    };

    this.setState({ nextLoader: true });
    let res;
    try {
      if (!selectedId) {
        res = await this.props.postMealTypesDetails(obj);
      } else {
        res = await this.props.putMealTypes(obj);
      }

      if (res && res.value && res.value.id) {
        toast.success("Meal successfully created!");
        history.push({
          pathname: `/vendor/meal-adjustments/${res.value.id}/mealSettings`,
          search: queryString.stringify({
            ...queryString.parse(search),
            setup: true,
          }),
        });
      } else {
        toast.error(
          "Failed to create meal due to unexpected response structure!"
        );
      }
    } catch (e) {
      toast.error(e.non_field_errors[0]);
    } finally {
      this.setState({ nextLoader: false });
    }
  }

  render() {
    const { handleSubmit, initialValues, check,is_active ,employees} = this.props;
    const { nextLoader } = this.state;

    return (
      <Form
        onSubmit={handleSubmit(this.submit)}
        className="job-detail"
        inValidate
      >
        <Row>
          <Col md={5}>
            <Field
              id="meal_type"
              name="meal_type"
              label="Meal Type"
              component={SelectAtom}
              options={MEAL_TYPE_LIST}
              placeholder="Select Meal Type"
              labelKey="label"
              valueKey="value"
              disabled={is_active===false || employees?.length>0 || this.props.selectedId}
              layout={FORM_LAYOUT}
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Field
              id="name"
              name="name"
              label="Name"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Enter Name"
              disabled={is_active===false ||employees?.length>0}
              maxLength="50"
            />
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            <Field
              id="description"
              name="description"
              label="Description"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Enter Description"
              disabled={is_active===false || employees?.length>0}
              maxLength="50"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="mt-2 mb-3 pr-0 text-right">
            <Button
              color="primary"
              style={{ paddingRight: "0px", paddingLeft: "0px" }}
              className={`${styles["btn_accent_width"]} btn-accent`}
              onClick={() => this.goToUrl(`/vendor/meal-adjustments`)}
            >
              Previous
            </Button>
            <Button
              color="primary"
              className={`${styles["btn_accent_width"]} btn-accent`}
              type="submit"
            >
              Next <ButtonLoaderAtom active={nextLoader} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.name) errors.name = "Name is required";
  if (!values.meal_type) errors.meal_type = "Meal Type is required";

  return errors;
};

const mapDispatchToProps = (dispatch) => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
  postMealTypesDetails: bindActionCreators(
    AdjustmentTypesDucks.postMealTypesDetails,
    dispatch
  ),
  putMealTypes: bindActionCreators(AdjustmentTypesDucks.putMealTypes, dispatch),
});

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

MealDetailsForm.defaultProps = {
  initialValues: {
    meal_type: null,
    name: null,
    description: null,
  },
};

MealDetailsForm.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tagList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array.isRequired,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  toggleTagModal: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  postMealTypesDetails: PropTypes.func.isRequired,
};

MealDetailsForm.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: "JobAddForm",
  validate,
  enableReinitialize: true,
})(MealDetailsForm);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withReduxForm)
);
