import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const ManualCorrectionDetailTableComponent = (props, context) => {
  const { router: { route: { match: { params } } } } = context;
  const { child, data, location: { query }, pageSize } = props;
  const { page = 1, reportDetail } = query;
  const {field,...reportQuery}=query

  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='"timecards_dailytimecard"."date"'>
            Date
          </SortHeaderWithSeparateKeysComponent>
          <th>Employee Name</th>
          <SortHeaderWithSeparateKeysComponent field='Count_of_Correction'>
            Count Of Corrections
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td title={item.date} className="pl-3">
                {child.length > 0 &&
                  <Link to={{
                    pathname: `/report/${child[0].id}/${child[0].code}/detail/${item.id}`,
                    search: queryString.stringify({
                      reportDetail,
                      reportDataDetail: JSON.stringify({ ...reportQuery, ...params }),
                    }),
                  }}>
                    {item.date}
                  </Link>
                }
              </td>
              <td title={`${item.first_name} ${item.last_name}`}>
                {item.first_name} {item.last_name}
              </td>
              <td title={item.count_of_correction} className="text-right pr-3">{item.count_of_correction}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

ManualCorrectionDetailTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

ManualCorrectionDetailTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ManualCorrectionDetailTableComponent;
