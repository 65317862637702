import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import BulkUploadPage from './BulkUpload';
import KioskEmployeesAuditListPage from './KioskEmployeesAuditList';
//import ViewTimeOffPOliciesPage from './ViewTimeOffPOlicies'

import EmployeeForm from 'employees/pages/form';
import ListPage from 'employees/pages/list';
import JobsListPage from 'employees/pages/jobsList';

const EmployeeRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="employee.list"
        component={ListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/add`}
        name="employee.add"
        component={EmployeeForm}
      />
      {/*<MatchWhenAuthorized
        exact
        path={`${match.url}/:id/edit/timeOffPolices`}
        name="employee.timeOffPolicices"
        component={ViewTimeOffPOliciesPage}
      />*/}
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/edit/:tab`}
        name="employee.edit"
        component={EmployeeForm}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/kiosk-audit/list`}
        name="employee.kiosk_audit_list"
        component={KioskEmployeesAuditListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/bulk-upload`}
        name="employee.bulkupload"
        component={BulkUploadPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/jobs/list`}
        name="employee.bulkupload"
        component={JobsListPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

EmployeeRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EmployeeRoutes;
