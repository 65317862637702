import React from 'react';

import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';

/**
 * ForgotPasswordPage -> ForgotPasswordFormSection -> OtpFormModalComponent
 *
 * Props:
 *    - update
 */
class ActivationLinkFormModalComponent extends React.PureComponent {

  render() {
    const { handleSubmit , error , toggle, showModal, inputType } = this.props;
    
    return (
      <Modal
        isOpen={showModal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>OTP Activation</ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody className="pt-0">
            <div className="error-text">{error}</div>
            <h6>Check your email for the OTP and enter the 6 digit code below to verify your email address.</h6>
            <Field
              id="password"
              type={inputType}
              name="token"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Enter otp"
            />
           
             
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size} >
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.token) errors.token = 'Enter OTP Code';
  /* eslint-disable */
  if (values.token && isNaN(values.token)) errors.token = 'Otp code contains only numeric values';
  /* eslint-enable */
  return errors;
};



export default reduxForm({
  form: 'ActivationLinkFormModalComponent',
  validate,
})(ActivationLinkFormModalComponent);
