import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';
import cookie from 'react-cookies';
import { Input } from 'reactstrap';
import { Form } from 'reactstrap';
import ModalComponent from 'components/common/GenericModal';
import { SORT_DIRS } from 'constants/records';
import {ReactComponent as SortIcon} from 'components/common/SortHeader/sort.svg';
import {ReactComponent as SortAscIcon} from 'components/common/SortHeader/sort_asc.svg';
import {ReactComponent as SortDescIcon} from 'components/common/SortHeader/sort_desc.svg';
import styles from "./styles.module.scss";

class SortHeaderComponent extends React.PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);
    this.sortDirection = this.sortDirection.bind(this);
    const { router: { route: { location: { search } } } } = context;
    const query = queryString.parse(search);
    const recurringSortingCookie =  cookie.load("recurringSorting");
    this.state = { recurring_sort: query.recurring_sorting  ? query.recurring_sorting : recurringSortingCookie};
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { location: { search: prevSearch } } } } = this.context;
    const { router: { route: { location: { search: nextSearch } } } } = nextContext;
    const query = queryString.parse(nextSearch);
    const prevQuery = queryString.parse(prevSearch);
    const recurringSortingCookie =  cookie.load("recurringSorting");

    if (prevQuery.recurring_sorting !== query.recurring_sorting || (this.state.recurring_sort == null && recurringSortingCookie)) {
      this.setState({ recurring_sort: query.recurring_sorting  ? query.recurring_sorting : recurringSortingCookie});
    }

  }

  handleChange() {
    const { router: { history, route: { location: { pathname, search } } } } = this.context;
    const { recurring_sorting } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        recurring_sorting: `${query.recurring_sorting && query.recurring_sorting[0] === SORT_DIRS.DESC
          ? SORT_DIRS.ASC : SORT_DIRS.DESC}${recurring_sorting}`,
      }),
    });
  }

  sortDirection() {
    const { router: { route: { location: { search } } } } = this.context;
    const { recurring_sorting , handleSubmit} = this.props;
    const { recurring_sort } = this.state;
    const query = queryString.parse(search);
    const recurringSortingCookie =  cookie.load("recurringSorting");
    const a = query.recurring_sorting  ? query.recurring_sorting : recurringSortingCookie;

    if (recurring_sort && (a === recurring_sorting || a === `-${recurring_sorting}`)) {
      return a[0] === SORT_DIRS.DESC 
        ? <SortDescIcon width="12" height="12" />
        : <SortAscIcon width="12" height="12" />;
    }
    return <SortIcon width="12" height="12" />;
  }

  render() {
    const { children , className, handleSubmit} = this.props;
    return (
      <div>
        <div style={{'marginTop':'-3px'}}>
          {children}
          <Button
            color="link"
            className="p-0 pl-1"
            onClick={this.handleChange}
          >
            {this.sortDirection()}
          </Button>
        </div>
      </div>
    );
  }
}

SortHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
  ordering: PropTypes.string.isRequired,
};

SortHeaderComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default SortHeaderComponent;
