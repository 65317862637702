
import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';
import UserEntity from 'accounts/entities/user';
import cookie from 'react-cookies';

class UserAPIGateway {

  toString() {
    return 'UserAPIGateway';
  }

  async register(email, password, first_name, last_name, confirm_password,company_name, phone_no, phone_ext, timezone) {
    const registerReqObj = {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      phone_no: phone_no,
      confirm_password: confirm_password,
      phone_ext: phone_ext,
      timezone: timezone,
    };
    const signinConfig =  AppConfig.server.apiHost + '/company/register/';
    const apiGateway = new ApiGateway(signinConfig);
    const response = await apiGateway.save(registerReqObj);
    const userEntity = new UserEntity(response.data);
    return userEntity;
  }

  async signIn(email, password, device) {
    const signInReqObj = {
      email: email,
      password: password,
      device: device,
    };
    const signinConfig = AppConfig.server.apiHost + '/company/login/';
    const apiGateway = new ApiGateway(signinConfig);
    const response = await apiGateway.save(signInReqObj);
    return response.data;
  }


  async forgotPasswordSubmit(data) {
    const forgotPasswordSubmitObj = {
      token : data.token,
    };
    const forgotPasswordSubmitConfig = AppConfig.server.apiHost + '/t2b/accounts/forgot-password/submit/';
    const apiGateway = new ApiGateway(forgotPasswordSubmitConfig);
    const response = await apiGateway.save(forgotPasswordSubmitObj);
    return response.data;
   
  }

  async forgotPassword(email) {
    const forgotPasswordObj = {
      email: email,
    };
    const forgotPasswordConfig = AppConfig.server.apiHost + '/t2b/accounts/forgot-password/';
    const apiGateway = new ApiGateway(forgotPasswordConfig);
    const response = await apiGateway.save(forgotPasswordObj);
    return response.data;
   
  }

  async postAuthentication(client_id, client_secret, device, grant_type, password, username){
    const tokenObj = {
      client_id: client_id,
      client_secret: client_secret,
      device: device,
      grant_type: grant_type,
      password: password.trim(),
      username: username.trim(),
    };
    const tokenConfig = AppConfig.server.apiHost + '/o/token/';
    const apiGateway = new ApiGateway(tokenConfig);
    const response = await apiGateway.save(tokenObj);
    return response.data.access_token;
  }

  async getProfile(){   
    const getProfileConfig = AppConfig.server.apiHost + '/t2b/accounts/me/';
    const apiGateway = new ApiGateway(getProfileConfig);
    const response = await apiGateway.load();
    return response.data;
  }

  async activation(code, media){ 
    const verifyObj = {
      code: code,
      media : media,
    };
    const verifyConfig = AppConfig.server.apiHost + `/company/activate/?media=${media}`;
    const apiGateway = new ApiGateway(verifyConfig);
    const response = await apiGateway.save(verifyObj);
    return response;

  }

   async updateCompanyProfile(values, id){ 
    const editConfig = AppConfig.server.apiHost + '/company/'+id+'/';
    const apiGateway = new ApiGateway(editConfig);
    const response = await apiGateway.partialUpdate(values);
    return response.data;

  }

  async resendCodes(){ 
    const verifyConfig = AppConfig.server.apiHost + '/company/resend-activation/';
    const apiGateway = new ApiGateway(verifyConfig);
    const response = await apiGateway.update({});
    return response;
  }

  async setPassword(password, uid){ 
    const setPaswswordObj = {
      password: password,
      uid: uid,
    };
    const setPasswordConfig = AppConfig.server.apiHost + '/company/set-password/';
    const apiGateway = new ApiGateway(setPasswordConfig);
    const response = await apiGateway.update(setPaswswordObj);
    return response.data;
  }

  async getUser(uid){ 
    const getUserObj = {
      uid: uid,
    };
    const getUserConfig = AppConfig.server.apiHost + '/company/decode-user/';
    const apiGateway = new ApiGateway(getUserConfig);
    const response = await apiGateway.update(getUserObj);
    return response.data;
  }

  async resetPassword(password , re_password , uid ){   
    const resetPasswordObj = {      
      password : password,
      re_password : re_password,
      uid : uid,
    }    
    const resetPasswordConfig = AppConfig.server.apiHost + '/t2b/accounts/forgot-password/reset/';
    const apiGateway = new ApiGateway(resetPasswordConfig);
    const response = await apiGateway.save(resetPasswordObj);
    return response.data;
  }
  async logout(){
    const logoutConfig = AppConfig.server.apiHost + '/t2b/accounts/logout/';
    const apiGateway = new ApiGateway(logoutConfig);
    document.cookie.split('; ').forEach(function(c) {
    cookie.remove(c.trim().split('=')[0], { path: '/'});
  } );

    const response = await apiGateway.save();
    return response.data;
  }

  async triggerEmail(data){
    const enquiryQuery = data.enquiry_query;
    const sendEmailConfig = AppConfig.server.apiHost + '/company/contact-us/?enquiry_query='+enquiryQuery+'';
    const apiGateway = new ApiGateway(sendEmailConfig);
    const response = await apiGateway.load();
    return response.data;
  }
  
  async changePassword(current_password , password , re_password ){   
    const changePasswordObj = {      
      current_password : current_password,
      password : password,
      re_password : re_password,
    }    
    const changePasswordConfig = AppConfig.server.apiHost + '/t2b/accounts/password/change/';
    const apiGateway = new ApiGateway(changePasswordConfig);
    const response = await apiGateway.save(changePasswordObj);
    return response.data;
  }

  async verify_otp(code, uid){ 
    const verifyObj = {
      code: code
    };
    const verifyConfig = AppConfig.server.apiHost + `/company/login/otp/?uid=` + uid;
    const apiGateway = new ApiGateway(verifyConfig);
    const response = await apiGateway.save(verifyObj);
    return response;

  }

  async sendMessageTesting(params) {
    const sendMessageConfig = AppConfig.server.apiHost + '/t2b/notification/sms/';
    const apiGateway = new ApiGateway(sendMessageConfig);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }

  async executeCTA(params) {
    const termsConditionConfig = AppConfig.server.apiHost + '/company/cta/'+params+'/execute/';
    const apiGateway = new ApiGateway(termsConditionConfig);
    const response = await apiGateway.save({});
    const data = response.data;
    return data;
  }
 
  async getGraniteToken(){
    const graniteTokenConfig = AppConfig.server.apiHost + '/t2b/accounts/wmapper-token/';
    const apiGateway = new ApiGateway(graniteTokenConfig);
    const response = await apiGateway.load({});
    const data = response.data;
    return data;
  }
}

export default UserAPIGateway;