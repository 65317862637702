import React from 'react';
import {connect} from 'react-redux';
import { Row, Col} from 'reactstrap'
import ContentLayout from 'layouts/Content';
import AccountSuspensionUsecase from 'accounts/usecases/accountSuspension';
import ContactSupportModal from 'accounts/components/contactSupportModal';
import UserAPIGateway from 'accounts/gateways/user';
import BillAPIGateway from 'payments/bills/gateways/bill';

import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import AccountSuspensionComponent from 'accounts/components/accountSuspension';
/* eslint-disable */

class AccountSuspensionPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const userAPIGateway = new UserAPIGateway();
    const billAPIGateway = new BillAPIGateway();
    this.usecase = new AccountSuspensionUsecase(userAPIGateway, billAPIGateway);
    this.listenAccountSuspensionUsecase = this.listenAccountSuspensionUsecase.bind(this);
    this.state = { isLoading: false, billList: [] , logoutLoader : false, isOpen: false};
    this.updateState = this.updateState.bind(this);
    this.logOutOfAccount = this.logOutOfAccount.bind(this);
    this.openModal = this.openModal.bind(this);
    this.toggle = this.toggle.bind(this);  
  }

  componentWillMount(){
    const { userProfile } = this.props;
    this.listenAccountSuspensionUsecase();
    this.usecase.getBillList({ billed: false, company_id: userProfile.company.id, paginate:false });
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }


  toggle(){
      this.setState({ isOpen: !this.state.isOpen });  
  }

  openModal(){
    const data = {      
     enquiry_query : 'Suspension Of Account'
    }   
    this.usecase.sendEmail(data)
    .then(() => {
      const {isOpen} = this.state;
      this.setState({isOpen: true});
    })
    .catch((e) => {    
      setErrors(e.errors);
      const {isOpen} = this.state;
      this.setState({isOpen: true});
    });   
  }

  logOutOfAccount(){
    const { logoutLoader} = this.state;
    this.setState({logoutLoader: true});
    this.usecase.logout();
  }

  listenAccountSuspensionUsecase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'LOGOUT_SUCCESS': {
          this.props.dispatch({
            type: 'accounts/user/RESET_STATE',
          });
          this.props.history.push('/');
          break;
        }
        case 'GET_BILL_LIST_SUCCESS':
          this.setState({ billList: event.data,
            isLoading: false });
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    const { userProfile, isSubCompany, isLoggedInAdmin, customerDetail , userExtraData} = this.props;
    const { billList, isLoading , isOpen} = this.state;
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col md={12}>
            <AccountSuspensionComponent 
              usecase={this.usecase}
              userProfile={userProfile}
              customerDetail={customerDetail}
              isSubCompany={isSubCompany}
              isLoggedInAdmin={isLoggedInAdmin}
              billList={billList}
              isLoading={isLoading}
              logoutLoader = {this.state.logoutLoader}
              logOutOfAccount= {this.logOutOfAccount}
              openModal = {this.openModal}
              userExtraData = {userExtraData}
            />
            {isOpen && <ContactSupportModal 
              isOpen={isOpen}
              toggle={this.toggle}
              usecase={this.usecase}/>}
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}

const mapStateToProps = (state) => ({
  userProfile: UserDucks.profile(state),
  isLoggedInAdmin: UserDucks.isLoggedInAdmin(state),
  isSubCompany: UserDucks.isSubCompany(state),
  customerDetail: PaymentDucks.customerDetail(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSuspensionPage);
