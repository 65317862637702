import React, {PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import EditableCellAtom from 'atoms/EditableCell';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row'
import Label from 'reactstrap/lib/Label';
import styles from './styles.module.scss';
/**
 * ActivitySplitFormPage -> ActivitySplitTimelineSection -> ActivitySplitPunchInComponent
 *
 * Props:
 *    None
 */
class ActivitySplitPunchInComponent extends PureComponent {

  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.updatable=this.updatable.bind(this);
    this.updateEntry= this.updateEntry.bind(this);
    this.state = { startDate : props.item && props.item.start_datetime , startTime :  props.item && moment(props.item.start_datetime).format('LT')};
  }

  updatable(data , key, value){
    const { editEntry } = this.props;
    const { startDate } = this.state;
    if(!value){
      return toast.error('Please enter time');
    }
    const updatedTime = moment(value, "h:mm:ss A").format("HH:mm:ss");
    const updatedDateTime = moment(`${moment(startDate).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedItem = {...data, start_datetime : updatedDateTime};
    // const updatedItem = {...data, start_datetime : browserParseDateISO(updatedDateTime, false),end_datetime : browserParseDateISO(data.end_datetime, false)};
    this.setState({startTime : value })
    editEntry(updatedItem);
  }

  updateEntry(item, date){
    const { editEntry } = this.props;
    const { startTime } = this.state;
    this.setState({startDate : date});
    const updatedTime = moment(startTime, "h:mm:ss A").format("HH:mm:ss");
    const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedItem = {...item, start_datetime : updatedDateTime};
    editEntry({ ...updatedItem, start_datetime: moment(updatedItem.start_datetime).toISOString()});
    // editEntry({ ...updatedItem, start_datetime: browserParseDateISO(updatedItem.start_datetime, false), end_datetime : browserParseDateISO(item.end_datetime, false)});
  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime && data.start_datetime.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }

render(){
  const { dateFormat , item , timeFormat, timeFormat24Hrs, editEntry, disabled , newStyle, browserParseDate} = this.props;
  return (
    <div className='override_alignment'>
    {!newStyle ?
      <Card block className={`${styles.timelinePointer} mb-2 p-2 pb-4 activityCard`}>
        <span className={styles['timeline-badge__punchIn']}></span>
        <Row className={`${styles['block-display']}`}>
          <Col md={2}>
            <CardTitle className={`${styles['fw']} f16 mb-0 pt-2 pl-2`}>In</CardTitle>
          </Col>
          <Col md={6}>
            <div className={`${styles['in-out-width']} f16 mb-0 pt-3 pl-2`}>
              <Label className={`${styles['start-time-label']} mb-0`}> Start Time </Label>
              <Flatpickr
                className="form-control mb-2 mt-1"
                value={item.start_datetime}
                options={{
                  dateFormat: `${dateFormat}, ${timeFormat}`,
                  enableTime: true,
                  minuteIncrement: 1,
                  time_24hr: timeFormat24Hrs,
                  enable: [
                    // Enable the dates b/w payroll startDate & endDate
                    // Also no future date selection
                    (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                      moment(date).isBefore(moment(endDate)) &&*/
                      moment(date).isBefore(moment())
                  ],
                }}
                onChange={date => editEntry({ ...item, start_datetime: date[0] }, 1)}
                aria-label="Start Date"
                disabled={disabled}
              />
            </div>
          </Col>
        </Row>
      </Card>
      :
      <div className={`${styles['punch_in_div']}`}>
        <div className={`${styles['fw']} f16 mb-0 pt-2 pl-2`}>In</div>
        <Row>
          <Col><div className={`${styles['start-time-label']} mb-0 pl-2 pt-2`}>Start Date</div></Col>
          <Col><div className={`${styles['start-time-label']} mb-0 pt-2`}>Start Time</div></Col>
        </Row>
        <Row>
          <Col md={6} className='datePicker'>
            <Flatpickr
              className={`${styles['punch-date-label']} form-control ml-2 mb-0 mt-1`}
              value={this.state.startDate}
              options={{
                dateFormat: `${dateFormat}`,
                enableTime: false,
                minuteIncrement: 1,
                time_24hr: timeFormat24Hrs,
                enable: [
                  // Enable the dates b/w payroll startDate & endDate
                  // Also no future date selection
                  (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                    moment(date).isBefore(moment(endDate)) &&*/
                    moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                ],
              }}
              onChange={(date)=> this.updateEntry(item, date[0])}
              aria-label="Start Date"
              disabled={disabled}
            />
          </Col>
          <Col md={6} className="mt-1 edit-start-time">
            <EditableCellAtom
              value={this.state.startTime}
              valueKey="start_time"
              details={{...item , start_datetime : moment(this.state.startDate).toISOString()}}
              editable={true}
              validate={this.validate}
              update={this.updatable}
            />
          </Col>
        </Row>
      </div>
    }
    </div>
  );
}}

ActivitySplitPunchInComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editEntry: PropTypes.func,
};

export default ActivitySplitPunchInComponent;
