import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  GET_BILL_LIST_SUCCESS : 'GET_BILL_LIST_SUCCESS',
  INFO_LIST: 'INFO_LIST',
  SET_DROPDOWN_VALUES : 'SET_DROPDOWN_VALUES',
  GET_BILL_STATUS_SUCCESS :'GET_BILL_STATUS_SUCCESS',
  SHOW_LOADER: 'SHOW_LOADER',
};

class billUseCase {
 
  billApiGateway = null;
  

  constructor(billApiGateway) {
    this.billApiGateway = billApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'billUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }
  
  async getInfoList(){
    this.infoList = await this.billApiGateway.getInfoList({ route: 'bill.list' });
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }
  
  async getBillList(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.billList = await this.billApiGateway.getBillList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_BILL_LIST_SUCCESS,
      data: this.billList,
    });
  }

  async getCommercialStatusList(params){
    this.statusList = await this.billApiGateway.getCommercialStatusList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_BILL_STATUS_SUCCESS,
      data: this.statusList,
    });
  }
}

export default billUseCase;