import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as KnowledgeBaseDucks from 'ducks/knowledgeBases/knowledgeBase';

import TwoColumnLayout from 'layouts/TwoColumn';
import KnowledgeBaseFilterSection from 'sections/knowledgeBases/list/Filter';
import KnowledgeBaseListSection from 'sections/knowledgeBases/list/List';

import { NAV } from 'constants/knowledgeBases';

/**
 * KnowledgeBaseListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link VendorFilterSection}
 *    - {@link KnowledgeBaseListSection}
 *
 * Fetch Needs:
 *    - getVendors
*/
class KnowledgeBaseListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false };
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    this.props.KnowledgeBaseDucks.getKnowledgeBases(params)
      .then(()=> this.setState({ isLoading: false }))
      .catch(()=> this.setState({ isLoading: false }));
  }

  loadData(){
    const { location: { pathname, query } } = this.props;
    const { router: { history } } = this.context;
    this.setState({ isLoading: true });

    if (!('is_active' in query) || !('page_size' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          is_active: true,
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <TwoColumnLayout
        navInfo={NAV.KnowledgeBaseListPage}
        fetchData={this.loadData}
      >
        <KnowledgeBaseFilterSection />
        <KnowledgeBaseListSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  KnowledgeBaseDucks: bindActionCreators(KnowledgeBaseDucks, dispatch),
});

KnowledgeBaseListPage.propTypes = {
  KnowledgeBaseDucks: PropTypes.object.isRequired,
};

KnowledgeBaseListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KnowledgeBaseListPage);
