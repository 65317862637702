import React from 'react';
import CloseImage from './Close-Cross.png';

import cookie from 'react-cookies';

import moment from 'moment';

import SingleSelectFloatComponent from 'components/common/SingleSelectFloatComponent';
import MultiSelectFloatComponent from 'components/common/MultiSelectFloatComponent';
import FreeSelectFloatComponent  from 'components/common/FreeSelectFloatComponent';
import DateTimeFloatComponent from 'components/common/DateTimeFloatComponent';
import TimeFloatComponent from 'components/common/TimeSelectFloatComponent';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import FloatLabelInput from 'components/common/FloatingLabelInput';

import { Form, Button, Row } from 'reactstrap';
import { Formik,Field } from 'formik';

import { toTitleCase } from 'helpers/utils';
import FormikSelectAtom from 'generics/FormikSelect';

import { SURVEY_LANGUAGE_OPTIONS } from 'constants/surveys';

import styles from './styles.module.scss';
import Col from 'reactstrap/lib/Col';

class SurveySliderEditableModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.surveyLanguageChange = this.surveyLanguageChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state={showSpLabel: false}
  }

  async onSubmit(values){
    const {data,surveyId, surveyLanguage, label, spanishLabel} = this.props;
    const languageArray =  [...surveyLanguage, values.survey_language ];
    const updateArray = data && data.length && data.map((item, index)=>{
      const {answer,sp_question, ...rest} = item;
      const updatedAnswer = answer && answer.length && answer.map((ans, ind)=>{
        return {...ans, sp_value: values[`answer_${index}_${ind}`] ? values[`answer_${index}_${ind}`] : ans.sp_value ? ans.sp_value : ans.value }
      })
      return { sp_question: values[`question_${index}`] ? values[`question_${index}`] : sp_question ? sp_question : item.question, answer:updatedAnswer, ...rest}
    })
    this.props.usecase.updateSpanishTemplateData({template_data : {questions : updateArray,survey_language: languageArray, sp_label: values.survey_label ? values.survey_label : spanishLabel ? spanishLabel : label}}, surveyId);
  }

  surveyLanguageChange(val){
    const {surveyLanguage} = this.props;
    if(val=="Spanish"){
      if(surveyLanguage && surveyLanguage.length && surveyLanguage.find((i)=>i=="Spanish")){
        this.setState({showSpLabel: true});
      }
      else{
        this.setState({showSpLabel: false});
      }
    }
    else{
      this.setState({showSpLabel: false});
    }
  }

  render() {
    const {hideEditableSurveyModal, initialValues, data, date, label, isLoading, isNavCollapsed, spanishLabel, userExtraData, customerDetail, isBusinessAdmin} = this.props;
    const {showSpLabel} = this.state;
    const isBanner = cookie.load('isBannerFlag');
     return (
      <div className={isBanner && isBanner== 'true' ? styles['survey-editable-slider-container-banner'] : styles['survey-editable-slider-container']}>
        <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        enableReinitialize={true}
        validator={() => ({})}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} className="mt-0">
            <div className="d-flex" style={isNavCollapsed ? {marginLeft: '250px'} : {marginLeft: '55px'}}>
              <h5 className="d-inline-block mt-3 ml-3 mb-4 mr-3" style={{color:'#09819a'}}>Select Language</h5>
              <div style={{width:'40%'}} className="mt-2">
                <Field
                  component={FormikSelectAtom}                         
                  options={SURVEY_LANGUAGE_OPTIONS}
                  name="survey_language"
                  id="survey_language"
                  simpleValue
                  clearable={false}
                  searchable={false}
                  onChange={(val)=>this.surveyLanguageChange(val)}
                />
              </div>
              <img src={CloseImage} className={`${styles['close-icon-editable']} mt-4 mr-3`} height="13" width="13" onClick={() => hideEditableSurveyModal()}/>
            </div>
            <hr className={`${styles['survey-horizontal-line']} bg-primary`}/>
            <div className={styles['survey-editable-slider-content']} style={isNavCollapsed ? {marginLeft: '230px'} : {marginLeft: '55px'}}>
            <div className="text-center" style={{fontSize : '15px'}}>     
              <span className={styles['survey-employee-name']}>({date})</span>
            </div>
            <div className={`${styles['custom-float-label']} ${styles['survey-employee-name']}`}>
              <Field
                component={FloatLabelInput}
                id='survey_label'
                name='survey_label'
                handleChange={handleChange}
                surveyLabel="true"
                label={showSpLabel ? spanishLabel : label}
                disabled={values.survey_language == "English" ? true : false}
              />
            </div>
              {data && data.length ? data.map((item,index)=>
                <div className="ml-3 mr-3 mb-3">
                  {(item.definition == "SINGLE_SELECT" || item.definition == 'single_select') &&
                    <div>
                      <Field
                        component={SingleSelectFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        disabled={values.survey_language == "English" ? true : false}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        showSpLabel={showSpLabel}
                      />   
                    </div>
                  }
                  {(item.definition == 'MULTI_SELECT' || item.definition == 'multi_select') &&
                    <div>
                      <Field
                        component={MultiSelectFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        disabled={values.survey_language == "English" ? true : false}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        showSpLabel={showSpLabel}
                      />
                    </div>
                  }
                  {(item.definition == 'FREE_TEXT' ||  item.definition == 'free_text') &&
                    <div>
                      <Field
                        component={FreeSelectFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        disabled={values.survey_language == "English" ? true : false}
                        showSpLabel={showSpLabel}
                      />
                    </div>
                  }
                  {(item.definition == 'BOOLEAN' || item.definition == 'boolean') &&
                    <div>
                      <Field
                        component={SingleSelectFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        disabled={values.survey_language == "English" ? true : false}
                        showSpLabel={showSpLabel}
                      />
                    </div>
                  }
                  {(item.definition == 'DATE_TIME_SELECT' || item.definition == 'date_time_select') &&
                    <div>
                      <Field
                        component={DateTimeFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        disabled={values.survey_language == "English" ? true : false}
                        dateFormat={this.props.dateFormat}
                        timeFormat = {this.props.timeFormat}
                        timeFormat24Hrs={this.props.timeFormat24Hrs}
                        showSpLabel={showSpLabel}
                      />
                    </div>
                  }
                  {(item.definition == 'TIME_SELECT' || item.definition == 'time_select') &&
                    <div>
                      <Field
                        component={TimeFloatComponent}
                        data={item}
                        index={index}
                        id={`question_${index}`}
                        name={`question_${index}`}
                        handleChange={handleChange}
                        onChange={(val) => setFieldValue(`question_${index}`, val)}
                        disabled={values.survey_language == "English" ? true : false}
                        dateFormat={this.props.dateFormat}
                        timeFormat = {this.props.timeFormat}
                        timeFormat24Hrs={this.props.timeFormat24Hrs}
                        showSpLabel={showSpLabel}
                      />
                    </div>
                  }         
                </div>
                  )
                  :
                  <section><div className={styles['no-template-records']}>No Records Found</div></section>
                }
                <Row className="ml-0 mr-0 pb-5">
                  <Col>
                    <Button type="submit" className="float-right" disabled={values.survey_language == "English" ? true : false}>Submit <ButtonLoaderAtom active={isLoading} /></Button>
                  </Col>
                </Row>
            </div>
            
            
            </Form>
            )}/>
      </div>
    );
  }
};


export default SurveySliderEditableModalComponent;