import React from "react";

import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import closeIcon from "./Cross_icon.png";
import { ReactComponent as BackIcon } from "./back.svg";
import "./styles.css";
class LayerAtom extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  render() {
    const { path, backButtonFlag = false,id='scheduling' } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          contentClassName={"modal-content-css"}
          modalClassName={"modal-css"}
          className={"modal-dialog-css"}
        >
          <div
            style={{
              height: "calc(100vh - 6px )",
              overflow: "auto",
            }}
          >
            {backButtonFlag ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: "1056",
                  marginTop: " 0.8rem",
                  marginLeft: "0.5rem",
                }}
              >
                <BackIcon
                  width="18"
                  height="18"
                  style={{ marginTop: "0.8rem !important" }}
                  className="cursor-pointer  vertical-sub "
                  alt="Close"
                  onClick={() => this.props.setToggle()}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  marginLeft: "-10px",
                  cursor: "pointer",
                  zIndex: "1056",
                }}
                onClick={() => this.props.setToggle()}
              >
                <img
                  src={closeIcon}
                  alt="Close"
                  style={{ height: "21px", width: "21px", marginTop: "10px" }}
                  //onClick={() => this.props.setToggle()}
                />
              </div>
            )}

            <iframe
              id={id}
              title="worksana_nre"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ width: "100%", height: "100%", position: "absolute" }}
              src={`${process.env.REACT_APP_WMAPPER_BASE_URL}${path}`}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

LayerAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default LayerAtom;
