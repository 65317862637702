import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as UserDucks from 'ducks/accounts/user';
import * as SubCompanyDucks from 'ducks/vendors/subCompany';

import VendorSubCompanyFormComponent from 'components/vendors/subCompanyForm/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/vendors';

/**
 * VendorSubCompanyFormPage -> VendorSubCompanyFormSection
 *
 * Components:
 *    - {@link VendorSubCompanyFormComponent}
 *
 * State:
 *    - vendorSubCompany
 *
 * Actions:
 *    - Form:
 *        - post and put VendorSubComapny
 */
class VendorSubCompanyFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
  };

  createOrUpdate(data) {
    const { userProfile } = this.props;
    const companyId = userProfile.company && userProfile.company.id;
    const { router: { history, route: { match: { params } } } } = this.context;
    const detail = {...data, image:null}
    if (params.id) {
/*      detail.user.first_name=first_name.value;
      detail.user.last_name=last_name.value;*/
      return this.props.SubCompanyDucks.putSubCompany(detail)
        .then(() => {
          toast.success(MSGS.VENDOR_UPDATE_SUCCESS);
          history.push(`/vendor/sub-company/${params.id}/user`);
        });
    }
    return this.props.SubCompanyDucks.postSubCompany(data, companyId)
      .then(() => {
        toast.success(MSGS.VENDOR_ADD_SUCCESS);
        history.push('/vendor/sub-company');
      }); 
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const {
      isLoading,
      subCompanyDetail,
      isEdit,
    } = this.props;
    let initialValues = {}
    if (params.id && subCompanyDetail.id) {
      initialValues = { ...subCompanyDetail,
        first_name: subCompanyDetail.user && subCompanyDetail.user.first_name,
        last_name: subCompanyDetail.user && subCompanyDetail.user.last_name,
       };
    } 
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <VendorSubCompanyFormComponent
        initialValues={initialValues}
        createOrUpdate={this.createOrUpdate}
        isEdit={isEdit}
      />
    );
  }
}

const mapStatetoProps = (state) => ({
  subCompanyDetail: SubCompanyDucks.subCompanyDetail(state),
  userProfile : UserDucks.profile(state),
});

const mapActionToProps = dispatch => ({
  SubCompanyDucks: bindActionCreators(SubCompanyDucks, dispatch),

});

VendorSubCompanyFormSection.propTypes = {
  SubCompanyDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  subCompanyDetail: PropTypes.object.isRequired,
};

VendorSubCompanyFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
  mapActionToProps,
)(VendorSubCompanyFormSection);
