import React from  'react';

import Proptypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as UserDucks from 'ducks/accounts/user';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as SessionDucks from 'ducks/activities/session';


import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import DeviatedDaliyTimeCardTableComponent from 'components/timecards/deviatedDailyTimecardList/Table';
import ActivitySplitFormModalComponent from 'components/activities/activitySplitList/FormModal';

import { MSGS } from 'constants/activities';
/**
 * DeviatedDaliyTimeCardListPage -> DeviatedDaliyTimeCardListSection
 *
 * Components:
 *    - {@link DeviatedDaliyTimeCardTableComponent}
 *
 * State:
 *    - timecard list
 *
 * Actions:
 *    None
 */

class DeviatedDaliyTimeCardListSection extends React.Component {
  constructor(props){
    super(props)
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.state = { isOpen: false, isTableLoading: false, isModalLoading: false, selectedItemId: null };
  }
  

  toggle(data) {
    const { isOpen } = this.state;
    if (!isOpen) this.props.ActivitySplitDucks.setActivitySplit(data);
    this.setState({ isOpen: !isOpen });
  }

  update(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    return this.props.ActivitySplitDucks.putActivitySplit(data)
      .then(() => {
        this.setState({ isTableLoading : true});
        toast.success(MSGS.SPLIT_SESSION_SUCCESS);
        this.props.DailyTimecardDucks.getDeviateDailyTimecards({ ...params, ...query, manual_correction: true, paginate:false })
          .then(() =>this.setState({ isTableLoading : false}))
          .catch(() => this.setState({ isTableLoading : false}));
        this.toggle();
      });
  }


  toggleConfirmationModal(error, data){
    const { isOpen } = this.state;
    if(error === 'true') {
      this.setState({ error: true, detail: data });
    }
    else {
      this.setState({ error: false, detail: data });
    }
    this.setState({ isOpen: !isOpen });
  }

  goTo(url, params){
    const { router: { history } } = this.context;
    const { location } = this.props;
    const { query } = location;

    return history.push({
      pathname: url,
      search: queryString.stringify({
        ...params,
        parent: JSON.stringify(query),

      }),
      state: location.pathname
    })
  }

  submitManualSession(detail) {
    if (moment(detail.meal_start_datetime) >= moment(detail.meal_end_datetime)) {
      return Promise.reject({
        non_field_errors: 'Meal end datetime must be greater than meal start datetime.',
      });
    }
    if (moment(detail.meal_start_datetime) <= moment(detail.start_datetime) ||
        moment(detail.meal_start_datetime) >= moment(detail.end_datetime) ||
        moment(detail.meal_end_datetime) <= moment(detail.start_datetime) ||
        moment(detail.meal_end_datetime) >= moment(detail.end_datetime)) {
      return Promise.reject({
        non_field_errors: 'Meal-Period must be between Punch-In session.',
      });
    }
    const mealPeriodSessionData = {
      split_id: detail.id,
      data: [{
        end_datetime: detail.meal_end_datetime,
        start_datetime: detail.meal_start_datetime,
      }],
    };

    return this.props.SessionDucks.postMealPeriodSession(mealPeriodSessionData, detail.id);
  }

  render() {
    const { 
      dailyDeviatedTimecardList,
      activitySplitDetail,
      dateFormat,
      timeFormat,
      timeFormat24Hrs,
      userTimezone, 
      isLoading,
      location,
    } = this.props;
    const { isOpen, isTableLoading, isModalLoading, selectedItemId } = this.state;

    if (isLoading || isTableLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <DeviatedDaliyTimeCardTableComponent
          data={dailyDeviatedTimecardList.results}
          toggle={this.toggle}
          timeAllocationModal={this.timeAllocationModal}
          location={location}
          goTo={this.goTo}
          isModalLoading={isModalLoading}
          selectedItemId={selectedItemId}
        />
        <PaginationComponent
          count={dailyDeviatedTimecardList.count}
          location={location}
          cookieKey="deviation_daily_timecard_page_size"
          hidePageSizeChange
        />
        {isOpen &&
          <ActivitySplitFormModalComponent
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            timeFormat24Hrs={timeFormat24Hrs}
            initialValues={{
              ...activitySplitDetail,
              meal_start_datetime_tz: activitySplitDetail.start_datetime_tz,
              meal_end_datetime_tz: activitySplitDetail.end_datetime_tz,
            }}
            isOpen={isOpen}
            userTimezone={userTimezone}
            formData={activitySplitDetail}
            toggle={this.toggle}
            update={this.update}
            submitManualSession={this.submitManualSession}
            toggleConfirmationModal ={this.toggleConfirmationModal}
          />
        }
      </section>
    )
  };
}

const mapStateToProps = state => ({
  activitySplitDetail: ActivitySplitDucks.activitySplitDetail(state),
  dailyDeviatedTimecardList: DailyTimecardDucks.dailyDeviatedTimecardList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  location: RouteDucks.location(state),
  userTimezone: UserDucks.userBATimezone(state),
});

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  DailyTimecardDucks:bindActionCreators(DailyTimecardDucks, dispatch),
});

DeviatedDaliyTimeCardListSection.propTypes = {
  isLoading: Proptypes.bool.isRequired,
  dailyDeviatedTimecardList: Proptypes.array.isRequired,
  location: Proptypes.object.isRequired,
  dateFormat: Proptypes.string.isRequired,
  timeFormat: Proptypes.string.isRequired,
  timeFormat24Hrs: Proptypes.bool.isRequired,
  userTimezone: Proptypes.string.isRequired,
  DailyTimecardDucks: Proptypes.object.isRequired,
  SessionDucks: Proptypes.object.isRequired,
};

DeviatedDaliyTimeCardListSection.contextTypes = {
  router: Proptypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DeviatedDaliyTimeCardListSection);
