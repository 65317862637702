import React, { Component } from "react";
import LoaderAtom from "atoms/Loader";
import styles from "./styles.module.scss";
import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import UsernameCircle from "atoms/UsernameCircle/UsernameCircle";
import { Response_Count_Circle_Colors } from "constants/forms/records";

export default class loadCOR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countOfResponses: 0,
      isLoading: true,
      responseCounts: {
        approved: 0,
        reviewed: 0,
        new: 0,
      },
    };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.listenListUsecase = this.listenListUsecase.bind(this);
  }

  componentDidMount() {
    this.usecase.getCountOfResponses({ id: this.props.formId });
    this.listenListUsecase();
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      console.log(event.data.response_count.APPROVED, "count of response");
      switch (event.type) {
        case "GET_COUNT_OF_RESPONSES_SUCCESS":
          this.setState({
            isLoading: false,
            responseCounts: {
              approved: event.data.response_count.APPROVED,
              reviewed: event.data.response_count.REVIEWED,
              new: event.data.response_count.NEW,
            },
          });
          break;
        case "GET_COUNT_OF_RESPONSES_FAILED":
          console.log("Count of response fetching failed!");
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  render() {
    const { responseCounts, countOfResponses, isLoading } = this.state;
    return (
      <>
        {isLoading ? (
          <LoaderAtom active />
        ) : (
          <div className={`${styles["responseCount-parent"]}`}>
            <div className={`${styles["responseCount-child"]}`}>
              <div title="NEW">
                <UsernameCircle
                  id={responseCounts.new}
                  color={Response_Count_Circle_Colors.NEW}
                />
              </div>
              <div title="REVIEWED">
                <UsernameCircle
                  id={responseCounts.reviewed}
                  color={Response_Count_Circle_Colors.REVIEWED}
                />
              </div>
              <div title='APPROVED'>
                <UsernameCircle
                  id={responseCounts.approved}
                  color={Response_Count_Circle_Colors.APPROVED}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
