import React from 'react';

import Field from 'redux-form/lib/Field';

import CheckboxAtom from 'atoms/Checkbox';
import AutoCompleteAtom from 'atoms/AutoComplete';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

class EmployeeFormComponent extends React.Component {

   constructor(props){
    super(props);
    this.showField=this.showField.bind(this);
    this.state={
      open: null,
      isSelected: false
  }
}

  showField(arr){
    this.setState({open: arr.source__employee_id});
  }

  render(){
    const { employeeAndQbdList , onChangeEmployee, fields, getEmployees, handleCheckboxes, AllemployeeList , data, source} = this.props;
    const { open } = this.state;
    return(
      <tbody>
        {fields.length ? fields.map((item, index, arr) => {
            const suggestedEmployee =  AllemployeeList.find((i) => i.id === data[index].suggestions.id);
            return(<tr key={index}>
              <td className="text-center">
               <Field
                  component={CheckboxAtom}
                  type="checkbox"
                  id="isSelected"
                  onSelect={(val, e) =>  handleCheckboxes(index, e)}
                  name={index}
                  layout={FORM_LAYOUT}
              />
              </td>
              <td className={source!=='ADP' && styles['border-right']}>{data[index].employee.name ? data[index].employee.name : null}</td>
              {source==='ADP' && <td className={styles['border-right']}>{data[index].employee.payrollGroupCode ? data[index].employee.payrollGroupCode : null}</td>}
              <td className={styles['select-cell']}>
                {(data[index].suggestions.user && (open !== data[index].source__employee_id))
                  ? (suggestedEmployee ? suggestedEmployee.user.full_name : null)
                  :
                 <Field
                    component={AutoCompleteAtom}
                    getData={(val) => getEmployees(val)}
                    id={`${item}.employee_id`}
                    mapKeys={{ label: [ 'user', 'full_name' ], value: 'id' }}
                    name={`${item}.employee_id`}
                    layout={FORM_LAYOUT}
                    placeholder="Employee"
                    onChange={(item) => onChangeEmployee({ ...data[index],
                        suggested_employee_id: item.value   }) }  
                  />
                }
                </td>
              <td>{suggestedEmployee  ? suggestedEmployee.username : null}</td>
              <td>{suggestedEmployee  ? suggestedEmployee.title  : null}
              {data[index].suggestions.user && 
              <div style={{ color: '#006CD1', cursor: 'pointer', marginTop : suggestedEmployee && suggestedEmployee.title ? '20px' : '36px', fontSize : '12px'}} onClick={() => this.showField(data[index])}><i>Click to search more employees</i></div>
            }
              </td>
              
            </tr>);
          /* eslint-enable */
        }) : null}
        {!employeeAndQbdList.length && <tr><td className="text-center" colSpan="10">No Records Found</td></tr>}
      </tbody>
    );
  }
}

export default EmployeeFormComponent;