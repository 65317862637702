import React from 'react';
import { Row, Col} from 'reactstrap'
import UserAPIGateway from 'accounts/gateways/user';
import ForgotPasswordUseCase from 'accounts/usecases/forgotPassword';
import ForgotPasswordForm from 'accounts/components/forgotPassword';
import ContentLayout from 'layouts/Content';
import OtpFormModalForm from 'accounts/components/OtpFormModal';
import queryString from 'query-string';
import { toast } from 'react-toastify';


class ForgotPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new ForgotPasswordUseCase(userAPIGateway);
    this.state = {isOpen: false};
    this.sendOtp= this.sendOtp.bind(this);
    this.listenForgotPasswordUsecase = this.listenForgotPasswordUsecase.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
      this.setState({ isOpen: !this.state.isOpen });
    }
    
  componentWillMount() {
    this.listenForgotPasswordUsecase();
    
  }

  sendOtp(){
    const {isOpen}=this.state;
    this.updateState('isOpen',!isOpen);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenForgotPasswordUsecase() {
    const { history } = this.props;
    const { isOpen } = this.state;
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'FORGOT_PASSWORD_SUCCESS':
          toast.success("Resend password mail sent successfully");
          this.updateState('isOpen',!isOpen);
          break;
          case 'FORGOT_PASSWORD_SUBMIT_SUCCESS':
          const { uid } = event.data;
          history.push({
          pathname: '/accounts/reset-password',
          search: queryString.stringify({uid: uid}),
        });
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    const {isOpen}=this.state;
    return (
       <ContentLayout>
        <Row className="content-area justify-center">
          <Col xs={12} md={{ size:4 }} className="align-self-center">
            <ForgotPasswordForm data={this.state} usecase={this.usecase} />
            {isOpen && <OtpFormModalForm 
              isOpen={isOpen}
              toggle={this.toggle}
              usecase={this.usecase}/>}
          </Col>
        </Row>
      </ContentLayout>
       );
  }

}


export default ForgotPasswordPage;
