import React from 'react';

import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import closeimage from './Close-Cross.png';

import styles from './styles.module.scss';

class TimecardDeleteComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit= this.submit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    this.setState({
      isOpen: false
    })
  }

  submit(){
    const valueValidity={value:"4", label:"delete"};
    const {data, saveData} = this.props;
    saveData(valueValidity, data)
    .then(() => this.closeModal());
  }


  render() {
    const { isOpen, toggle, confirmdeleteLoader } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader>
          Confirm Delete
          <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <ModalBody>
          <h5>Are you sure you want to delete this session?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="default" className={styles['cancel-btn']} className="btn-width" onClick={toggle} >
            Cancel
          </Button>
          <Button type="submit" color="secondary" className="btn-width" onClick={() => this.submit()}>
             Yes <ButtonLoaderAtom active={confirmdeleteLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

TimecardDeleteComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TimecardDeleteComponent;
