import EventEmitter from "lib/event-emitter";
import FormsAPIGateway from "forms/gateways/forms";
import GraniteError from "lib/granite-error";
import { toast } from "react-toastify";

const EVENT_TYPES = {
  GET_FORMS_LIST_SUCCESS: "GET_FORMS_LIST_SUCCESS",
  GET_FORMS_LIST_FAILURE: "GET_FORMS_LIST_FAILURE",
  GET_TEMPLATE_DATA: "GET_TEMPLATE_DATA",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  SHOW_MODAL_LOADER: "SHOW_MODAL_LOADER",
  HIDE_MODAL_LOADER: "HIDE_MODAL_LOADER",
  DEPARTMENT_LIST: "DEPARTMENT_LIST",
  TEAM_LIST: "TEAM_LIST",
  ERROR_TOAST: "ERROR_TOAST",
  GET_ASSIGNED_EMPLOYEES_LIST_SUCCESS: "GET_ASSIGNED_EMPLOYEES_LIST_SUCCESS",
  GET_ASSIGNED_EMPLOYEES_LIST_FAILED: "GET_ASSIGNED_EMPLOYEES_LIST_FAILED",
  FORM_CREATED_SUCCESS: "FORM_CREATED_SUCCESS",
  FORM_CREATED_FAILURE: "FORM_CREATED_FAILURE",
  FORM_UPDATED_SUCCESS: "FORM_UPDATED_SUCCESS",
  FORM_UPDATED_FAILURE: "FORM_UPDATED_FAILURE",
  DELETE_FORMS_SUCCESS: "DELETE_FORMS_SUCCESS",
  DELETE_FORMS_FAILURE: "DELETE_FORMS_FAILURE",
  SUBMIT_OUTCOME_FORM_SUCCESS: "SUBMIT_OUTCOME_FORM_SUCCESS",
  SUBMIT_OUTCOME_FORM_FAILURE: "SUBMIT_OUTCOME_FORM_FAILURE",
  GET_RESPONCE_DATA_SUCCESS: "GET_RESPONCE_DATA_SUCCESS",
  GET_COUNT_OF_RESPONSES_SUCCESS: "GET_COUNT_OF_RESPONSES_SUCCESS",
  GET_COUNT_OF_RESPONSES_FAILED: "GET_COUNT_OF_RESPONSES_FAILED",
  DELETE_ASSIGNED_EMPLOYEE_SUCCESS: "DELETE_ASSIGNED_EMPLOYEE_SUCCESS",
  DELETE_ASSIGNED_EMPLOYEE_FAILURE: "DELETE_ASSIGNED_EMPLOYEE_FAILURE",
  STATUS_CHANGED_SUCCESS: "STATUS_CHANGED_SUCCESS",
  STATUS_CHANGED_FAILURE: "STATUS_CHANGED_FAILURE",
  ASSIGN_DELETE_EMPLOYEE_SUCCESS: "ASSIGN_DELETE_EMPLOYEE_SUCCESS",
  ASSIGN_DELETE_EMPLOYEE_FAILURE: "ASSIGN_DELETE_EMPLOYEE_FAILURE",
  SEND_REMINDER_SUCCESS: "SEND_REMINDER_SUCCESS",
  SEND_REMINDER_FAILURE: "SEND_REMINDER_FAILURE",
  MULTI_FORM_ASSIGN_SUCCESS: "MULTI_FORM_ASSIGN_SUCCESS",
  MULTI_FORM_ASSIGN_FAILURE: "MULTI_FORM_ASSIGN_FAILURE",
};

class listUseCase {
  FormsAPIGateway = null;

  constructor(FormsAPIGateway) {
    this.FormsAPIGateway = FormsAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  toString() {
    return "listUseCase";
  }

  async getDepartmentList(params) {
    this.departmentList = await this.FormsAPIGateway.getDepartmentList({
      ...params,
      paginate: false,
    });
    this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.DEPARTMENT_LIST,
      departmentList: this.departmentList,
    });
  }

  async getTeamList(params) {
    this.teamList = await this.FormsAPIGateway.getTeamList({
      ...params,
      paginate: false,
    });
    this.teamList.splice(0, 0, { id: "", name: "All Teams" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEAM_LIST,
      teamList: this.teamList,
    });
  }

  async getFormsList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    try {
      this.fieldFormsList = await this.FormsAPIGateway.getFormsList({
        ...params,
        paginate: true,
      });

      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_FORMS_LIST_SUCCESS,
        data: this.fieldFormsList,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_FORMS_LIST_FAILURE,
        data: error,
      });
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async getFormData(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    try {
      this.templateData = await this.FormsAPIGateway.getFormData(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_TEMPLATE_DATA,
        data: this.templateData.data,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.ERROR_TOAST,
      });
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async getAssignedEmployeesList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    try {
      this.assignedEmployees = await this.FormsAPIGateway.getAssignedEmployeesList(
        {
          ...params,
          paginate: true,
        }
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_ASSIGNED_EMPLOYEES_LIST_SUCCESS,
        data: this.assignedEmployees,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_ASSIGNED_EMPLOYEES_LIST_FAILED,
        data: e,
      });
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async createForm(params) {
    //let updatedParams = params;
    try {
      this.formData = await this.FormsAPIGateway.createForm(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_CREATED_SUCCESS,
        data: this.formData.data,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_CREATED_FAILURE,
        data: e,
      });
    }
  }

  async updateForm(params) {
    // let updatedParams = params;
    try {
      this.formData = await this.FormsAPIGateway.updateForm(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_UPDATED_SUCCESS,
        data: this.formData.data,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_UPDATED_FAILURE,
        data: e,
      });
    }
  }

  async getResponceData(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    try {
      this.templateData = await this.FormsAPIGateway.getResponceData(params);

      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_RESPONCE_DATA_SUCCESS,
        data: this.templateData,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_RESPONCE_DATA_FAILED,
      });
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async updateResponceData(params){
     try{
       this.templateData = await this.FormsAPIGateway.updateResponceData(params);
     }catch(error){
      toast.error(error);
     }
    }

  async getCountOfResponses(params) {
    try {
      this.countOfResponses = await this.FormsAPIGateway.getCountOfResponses(
        params
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_COUNT_OF_RESPONSES_SUCCESS,
        data: this.countOfResponses,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_COUNT_OF_RESPONSES_FAILED,
      });
    }
  }

  async toggleStatusForm(params) {
    try {
      await this.FormsAPIGateway.toggleStatusForm(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.STATUS_CHANGED_SUCCESS,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.STATUS_CHANGED_FAILURE,
        data: e
      });
    }
  }

  async deleteForm(params) {
    try {
      this.formData = await this.FormsAPIGateway.deleteForm(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.DELETE_FORMS_SUCCESS,
        data: this.formData.data,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.DELETE_FORMS_FAILURE,
        data: e,
      });
    }
  }

  async updateAssessmentOutcomeData(data, id) {
    try {
      this.surveyOutcomeData = await this.FormsAPIGateway.updateForm({
        template_data: { scoring_actions: data },
        id,
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_UPDATED_SUCCESS,
        data: this.surveyOutcomeData.data,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.FORM_UPDATED_FAILURE,
        data: e,
      });
    }
  }

  async deleteAssignedEmployee(params) {
    try {
      this.employeeData = await this.FormsAPIGateway.deleteAssignedEmployee(
        params
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.DELETE_ASSIGNED_EMPLOYEE_SUCCESS,
        data: this.employeeData.data,
      });
    } catch (e) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.DELETE_ASSIGNED_EMPLOYEE_FAILURE,
        data: e,
      });
    }
  }

  async handleExport(params) {
    try {
      this.response = await this.FormsAPIGateway.handleExport(params);
      return this.response;
    } catch (errors) {

      throw new GraniteError(errors);
    }
  }

  async assignAndDeleteEmployeesFromForms(params, type) {
    try {
      this.assignedEmployeeData = await this.FormsAPIGateway.assignAndDeleteEmployeesFromForms(
        params
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.ASSIGN_DELETE_EMPLOYEE_SUCCESS,
        data: {
          type,
        },
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.ASSIGN_DELETE_EMPLOYEE_FAILURE,
      });
    }
  }

  async sendReminders(params) {
    try {
      this.sendRemindersData = await this.FormsAPIGateway.sendReminders(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.SEND_REMINDER_SUCCESS,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.SEND_REMINDER_FAILURE,
        data: error?.errors?.message,
      });
    }
  }

  async multiFormAssign(params) {
    try {
      this.multiFormAssignData = await this.FormsAPIGateway.multiFormAssign(
        params
      );
      this.eventEmitter.emit({
        type: EVENT_TYPES.MULTI_FORM_ASSIGN_SUCCESS,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.MULTI_FORM_ASSIGN_FAILURE,
      });
    }
  }
}

export default listUseCase;
