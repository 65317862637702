import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_SUBMIT_SUCCESS : 'FORGOT_PASSWORD_SUBMIT_SUCCESS',
};

class ForgotPasswordUseCase {
 
  userAPIGateway = null;
  

  constructor(userAPIGateway) {
    
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'forgotPasswordUseCase';
  }

   
  getObservable() {
    return this.eventEmitter.getObservable();
  }


  setup_data(data) {
    this.email = data.email; 

 }

  async postOtp(data) { 
    let OTP = null;
    OTP = await this.userAPIGateway.forgotPasswordSubmit(data);    
    this.eventEmitter.emit({
      type: EVENT_TYPES.FORGOT_PASSWORD_SUBMIT_SUCCESS,
      data : OTP,
    });
  }    
    

  async execute() {
    let errors = {};
    if (!this.email) {
      errors.email = 'email ID is required';
    } 
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }
    await this.userAPIGateway.forgotPassword(this.email); 
    this.eventEmitter.emit({
      type: EVENT_TYPES.FORGOT_PASSWORD_SUCCESS,
    });
  }
}

export default ForgotPasswordUseCase; 