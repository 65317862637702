import React from 'react';

import moment from 'moment';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as JobDucks from 'ducks/jobs/job';
import * as NoteDucks from 'ducks/jobs/comment';
import * as JobTagDucks from 'ducks/jobs/tag';
import * as CustomerTagDucks from 'ducks/customers/tag';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as CustomerDucks from 'ducks/customers/customer';
import * as UserDucks from 'ducks/accounts/user';

import { toast } from 'react-toastify';
import { REPEAT_OPTIONS, REPEAT_EVERY_WEEKLY_OPTIONS, REPEAT_EVERY_MONTHLY_OPTIONS } from 'constants/jobs';

import SectionLoaderAtom from 'atoms/SectionLoader';
import EditJobFormComponent from 'components/jobs/form/EditJob';
import CustomerFormModalComponent from 'components/customers/list/FormModal';
import ImageModalComponent from 'components/jobs/form/ModalComponent';
import TagModalComponent from 'components/jobs/form/TagModal';


class EditJobFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.createTag = this.createTag.bind(this);
    this.goTo = this.goTo.bind(this);
    this.addNote = this.addNote.bind(this);
    this.state = { status: null,
      isTagLoading: false,
      isOpen: false,
      customerSelected: null,
      isSubmittingNotes: false,
      isModalOpen: false,
      image: null,
      isTagModal: false,
      tagType: null,
      isModalHidden:false,
    };
    this.getOptions = this.getOptions.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateCreate = this.updateCreate.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }


  componentWillMount(nextProps) {
    this.props.JobTagDucks.getJobTags({ paginate: false, tag_type: 2 });
    this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  getUpdatedList(){
    const {  tagType } = this.state;
    if(tagType === 2){
      return this.props.JobTagDucks.getJobTags({ paginate: false, tag_type: 2 });
    }
    if(tagType === 0){
      this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
    }
  }

  getOptions(val){
    return this.props.JobTagDucks.getJobTags({ search: val, paginate: false });
  }

  toggleModal(image) {
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen, image });
  }

  toggleTagModal(tagType) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal, tagType  });
  }

  createTag(data) {
    const {  tagType } = this.state;
    if(tagType === 2){
      return this.props.JobTagDucks.postJobTag({ ...data, tag_type: 2 })
    }
    if(tagType === 0){
      return this.props.CustomerTagDucks.postCustomerTag({ ...data, tag_type: 0 })
    }
    return true;
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  addNote(data, file) {
    const { jobDetail } = this.props;
    if(!data && !file) return;
    this.setState({ isSubmittingNotes: true });
    this.props.NoteDucks.postComment(data, jobDetail.id)
      .then(() => {
        this.props.NoteDucks.getComments({ job_id: jobDetail.id })
          .then(() => this.setState({ isSubmittingNotes: false }));
      })
      .catch(() => this.setState({ isSubmittingNotes: false }));
  }

  createOrUpdate(data) {
    const { router: { history } } = this.context;
    const { location: { query } } = this.props;
    return this.props.JobDucks.putJob(data)
      .then((res) => {
        toast.success('Job successfully updated.');
        this.props.CustomerDucks.resetCustomer();
        if(query.setup){
          history.push({ pathname: `/job/list/${res.value.id}/task/edit`, search: queryString.stringify({ ...query , setup: true }) });
        }
      })
  }

  goTo(url, data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const {location: { query } } = this.props;
    this.props.JobDucks.setJob(data);
    if(params.id){
      history.push({
        pathname: url ,
      });
    }
    else {
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job: 'new',
        }),
      });
    }
  }

  toggle(customer, type) {
    const { isOpen } = this.state;
    const { jobDetail, customerDetails } = this.props;
    let customerData;
    if (customer && customer.user) {
      customerData = customer;
    } else if(customerDetails.id) {
      customerData = customerDetails;
    } else {
      customerData = jobDetail.customer_data;
    }
    const tag = customerData && customerData.tags && customerData.tags.length && customerData.tags.map((item) => {
      const data = {};
      data.id = item;
      data.name = item;
      return data;
    });
    const newCustomer = { ...customerData, tags: tag };
    this.setState({ isOpen: !isOpen, customerSelected: newCustomer });
    if(type === 'add' ) {
      this.setState({ customerSelected: null })
    }
  }

  updateCreate(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen } = this.state;
    if(data.id){
      return this.props.CustomerDucks.putCustomersDetails(data)
        .then(() => {
          this.setState({ isOpen: !isOpen });
          this.props.JobDucks.getJob(params);
          this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true });
        });
    }
    return this.props.CustomerDucks.postCustomers(data)
      .then(() => {
        const { customerDetails } = this.props;
        this.props.JobDucks.getJob(params);
        this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true });
        this.setState({ isOpen: !isOpen, customerSelected: customerDetails });
      });
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { isTagLoading , customerSelected, isOpen,
      isSubmittingNotes, isModalOpen, image, isTagModal, tagType, isModalHidden} = this.state;
    const { isLoading, dateFormat, location, jobDetail, jobData,customerTagList ,
      basicSettingDetail, customerList, jobTagList, commentList, customerDetails,
      companyPermissions, userCompany } = this.props;
    
    const options = [
      { label: 'Open', value: 1 },
      { label: 'Completed', value: 3 },
      { label: 'Deleted', value: 2 },
    ];
    let customer;
    if(customerDetails.id && customerDetails.is_active ){
      customer = customerDetails;
    }
    else if(customerDetails.id && !customerDetails.is_active){
      customer = null;
    }
    else if (jobDetail && jobDetail.id){
      customer = customerList.length ? customerList.find((i) => i.id === jobDetail.customer) : {};
    }
    let newTags;
    if (jobDetail.id) {
      newTags = jobDetail.tags && jobDetail.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
    }
    let every;
    const repeats = REPEAT_OPTIONS.find((i) => i.value === jobDetail.repeat);
    if(repeats && (repeats.value === 'WEEKLY')){
      every = REPEAT_EVERY_WEEKLY_OPTIONS.find((i) => i.value === (+jobDetail.every));
    }
    else {
      every = REPEAT_EVERY_MONTHLY_OPTIONS.find((i) => i.value === (+jobDetail.every));
    }
    const userInitialValue = customerSelected && customerSelected.user ? customerSelected.user : { is_active: true };

    const jobtags = jobTagList&& jobTagList.length && jobTagList[0].tag_collection && jobTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));

    const customertags = customerTagList&& customerTagList.length && customerTagList[0].tag_collection && customerTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    const tags = tagType == 0 ? customertags : jobtags;

    return (
      <section>
        {isLoading &&
          <SectionLoaderAtom active />
        }
        {!isLoading &&
          <EditJobFormComponent
            initialValues={
              params.id
                ? {
                  ...jobDetail,
                  customer,
                  tags: newTags,
                  start_date: jobDetail.start_date &&
                    moment(jobDetail.start_date).toISOString(),
                  end_date: jobDetail.end_date &&
                    moment(jobDetail.end_date).toISOString(),
                  phone: customer && customer.phone_no,
                  phn_ext: customer && customer.phn_ext,
                  contact: customer && customer.user && customer.user.full_name,
                  email: customer && customer.user && customer.user.email,
                  repeats,
                  every,
                }
                : {
                  ...jobData,
                  status: '1',
                  'geo_fences': jobData && jobData.geo_fences ? jobData.geo_fences: [],
                  'start_date': jobData && jobData.start_date
                    ? moment(jobData.start_date).format(basicSettingDetail.date_format_display)
                    : null
                }
            }
            options={options}
            isLoading={isLoading}
            location={location}
            dateFormat={dateFormat}
            createOrUpdate={this.createOrUpdate}
            goTo={this.goTo}
            customerList={customerList}
            jobTagList={jobTagList}
            isTagLoading={isTagLoading}
            getOptions={this.getOptions}
            addNote={this.addNote}
            commentList={commentList}
            createTag={this.createTag}
            toggle={this.toggle}
            isOpen={isOpen}
            isSubmittingNotes={isSubmittingNotes}
            toggleModal={this.toggleModal}
            toggleTagModal={this.toggleTagModal}
            customerSelected={customerSelected}
          />}
        {isOpen &&
          <CustomerFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            create={this.updateCreate}
            createTag={this.createTag}
            isTagLoading={isTagLoading}
            tagList={customerTagList}
            getOptions={this.getOptions}
            companyPermissions={companyPermissions}
            initialValues={{
              ...customerSelected,
              point_of_contact: customerSelected && customerSelected.user.full_name,
              phone_ext: userCompany.phone_ext,
              user: userInitialValue,
            }}
            edit={customerSelected && customerSelected.id}
            toggleTagModal={this.toggleTagModal}
            isModalHidden={isModalHidden}
          />
        }
        {isModalOpen &&
          <ImageModalComponent
            isModalOpen={isModalOpen}
            toggle={this.toggleModal}
            image={image}
          />
        }
        {isTagModal &&
          <TagModalComponent
            isModalOpen={isTagModal}
            toggle={this.toggleTagModal}
            createTag={this.createTag}
            tagType={tagType}
            tagsList = {tags}
            tagsCollection={tagType == '0' ? customerTagList : jobTagList}
            deleteSelectedTags={this.deleteSelectedTags}
            updateTagName={this.updateTagName}
            getUpdatedList = {this.getUpdatedList}
            hideModal = {this.hideModal}
          />
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  customerList: CustomerDucks.customerList(state),
  jobTagList: JobTagDucks.jobTagList(state),
  commentList: NoteDucks.commentList(state),
  companyPermissions: UserDucks.companyPermissions(state),
  customerDetails: CustomerDucks.customerDetails(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
  userCompany: UserDucks.userCompany(state),
});

const mapActionToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  JobTagDucks: bindActionCreators(JobTagDucks, dispatch),
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

EditJobFormSection.propTypes = {
  jobDetail: PropTypes.object.isRequired,
  jobData: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array.isRequired,
  commentList: PropTypes.object,
  isLoading: PropTypes.bool,
};

EditJobFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps,
)(EditJobFormSection);
