import React from "react";

import PropTypes from "prop-types";
import Card from "reactstrap/lib/Card";
import Button from "reactstrap/lib/Button";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import styles from "./styles.module.scss";
import closeimage from "./Cancel.png";
import clock from "./clock.png";

/**
 * DailyTimecardListSectioncardListPage -> DailyTimecardListSection -> ActivityInProgressCard
 *
 * Props:
 *    - daily timecard list
 */
const ActivityInProgressCard = (props) => {
  const {
    data,
    punchOutAndApprove,
    isLoading,
    showAllocateTasksPopup,
    isShowEmployee,
    toggleActivityInProgressSection,
    noSqlKey,
    acceptAndRejectActivity,
    acceptActivityFlag,
    rejectActivityFlag,
  } = props;

  const filteredData =
    data &&
    data.filter((item) => {
      if (
        item.activity_master_name !== "Task" &&
        item.activity_master_name !== "Continue" &&
        item.failed_sessions === undefined
      ) {
        return true;
      }
      return false;
    });

  const failedSessionData =
    data &&
    data.filter((item) => {
      if (item.failed_sessions) {
        return true;
      }
      return false;
    });

  const failedSessionArray = [];

  failedSessionData &&
    failedSessionData[0] &&
    failedSessionData[0].failed_sessions &&
    failedSessionData[0].failed_sessions.forEach((item) => {
      if (item?.punch_in) {
        failedSessionArray.push({
          ...item.punch_in,
          master_name: "Shift",
          session_id: item.session_id,
        });
      }
      if (item?.meals) {
        item.meals.forEach((subItem) => {
          failedSessionArray.push({
            ...subItem,
            master_name: "Meal Period",
            session_id: item.session_id,
          });
        });
      }
    });

  return (
    <div>
      <Card className={styles["card-container"]}>
        <div className="pt-3 ml-3 mr-3">
          <div>
            {noSqlKey ? (
              <h5
                className={`mt-1 mb-0 ${styles["activity-progress-heading"]}`}
              >
                {(filteredData.length) ? 'Activities In Progress' :  failedSessionArray.length ?'Failed Sessions':null}
                <img
                  src={closeimage}
                  className={`${styles["activity-in-progress-close-icon"]}`}
                  height="14px"
                  width="14px"
                  alt="Close"
                  onClick={(e) => toggleActivityInProgressSection()}
                />
              </h5>
            ) : (
              <h5
                className={`mt-1 mb-0 ${styles["activity-progress-heading"]}`}
              >
                Activities In Progress
              </h5>
            )}
          </div>
          <div>
            {filteredData.map((item) => {
              return (
                <div className={`${styles["activity-in-progress-container"]}`}>
                  <div
                    className={`${styles["border-list"]} ${styles["activity-in-progress-content"]} mt-3 pt-1 pb-2  flex`}
                  >
                    <div
                      className={`d-inline-block pt-1 pl-0 pr-0`}
                      style={{ width: "15%", alignSelf: "center" }}
                    >
                      <b>{item.activity_master_name}</b>
                    </div>
                    <div
                      className={
                        isShowEmployee && showAllocateTasksPopup
                          ? `d-block pt-1 pl-0 pr-0 ${styles["custom-activity-width"]}`
                          : "d-block pt-1 text-left"
                      }
                      style={{ width: "41%", marginRight: "1%" }}
                    >
                      <b style={{ color: "#5cb85c" }}>Start Time</b>
                      <div className="pt-1">
                        <img
                          className="mr-1 pt-0"
                          src={clock}
                          height="18px"
                          width="18px"
                          alt="Close"
                        />
                        {item.start_datetime_display}
                      </div>
                    </div>

                    <div
                      className={
                        isShowEmployee & showAllocateTasksPopup
                          ? `d-block pt-1 pl-1 pr-0 ${styles["custom-activity-width"]}`
                          : "d-block pt-1 text-left"
                      }
                      style={{ width: "41%" }}
                    >
                      <b style={{ color: "#d9534f" }}>End Time</b>
                      <div className="pt-1">
                        <img
                          src={clock}
                          className="mr-1 pt-0"
                          height="18px"
                          width="18px"
                          alt="Close"
                        />
                        {item.end_datetime_display
                          ? item.end_datetime_display
                          : "In Progress"}
                      </div>
                    </div>
                  </div>
                  <div>
                    {noSqlKey && item.activity_master_name === "Shift" ? (
                      <div
                        className={
                          isShowEmployee && showAllocateTasksPopup
                            ? `${styles["activity-btn-container-width"]} ${styles["nosql-activity-accept-reject-button-container"]} mb-3 mt-3`
                            : `mb-3 mt-3 ${styles["nosql-activity-accept-reject-button-container"]}`
                        }
                        style={
                          showAllocateTasksPopup
                            ? {
                                float: "right",
                                textAlign: "left",
                              }
                            : { textAlign: "right" }
                        }
                      >
                        <Button
                          size="sm"
                          color="success"
                          className={
                            isShowEmployee && showAllocateTasksPopup
                              ? `${styles["btn-progress"]} ${styles["btn-progress-alignment"]} mr-1`
                              : ` ${styles["btn-progress"]}  mr-3`
                          }
                          onClick={() => acceptAndRejectActivity("Approve")}
                        >
                          Accept Activity &nbsp;
                          <ButtonLoaderAtom
                            active={acceptActivityFlag && isLoading}
                          />
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          className={
                            isShowEmployee && showAllocateTasksPopup
                              ? `${styles["btn-progress"]} ${styles["btn-progress-alignment"]} `
                              : `${styles["btn-progress"]} ${styles["temp1"]}`
                          }
                          onClick={() => acceptAndRejectActivity("Reject")}
                        >
                          Reject Activity &nbsp;
                          <ButtonLoaderAtom
                            active={rejectActivityFlag && isLoading}
                          />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
            {!noSqlKey ?
              filteredData.map((item) => {
                if (item.activity_master_code === "PUNCH-IN")
                  return (
                    <div
                      className={
                        isShowEmployee && showAllocateTasksPopup
                          ? `${styles["activity-btn-container-width"]}  mb-3 mt-3`
                          : `mb-3 mt-3 `
                      }
                      style={
                        showAllocateTasksPopup
                          ? {
                              float: "right",
                              textAlign: "left",
                            }
                          : { textAlign: "right" }
                      }
                    >
                      <Button
                        size="sm"
                        className={
                          isShowEmployee && showAllocateTasksPopup
                            ? `${styles["btn-progress"]} ${styles["btn-progress-alignment"]}`
                            : styles["btn-progress"]
                        }
                        onClick={() => punchOutAndApprove(item)}
                      >
                        {item.end_datetime_display
                          ? "Approve"
                          : "Force Punch Out and Approve"}
                        &nbsp;
                        <ButtonLoaderAtom active={isLoading} />
                      </Button>
                    </div>
                  );
              }):null}
          </div>
          <div>
            {(noSqlKey && filteredData.length && failedSessionArray.length ) ? (
              <h5
                className={`mt-2 mb-0 ${styles["activity-progress-heading"]}`}
              >
                Failed Sessions
              </h5>
            ):null}
            {failedSessionArray.map((item) => {
              return (
                <div className={`${styles["activity-in-progress-container"]}`}>
                  <div
                    className={`${styles["border-list"]} ${styles["activity-in-progress-content"]} mt-3 pt-1 pb-2  flex`}
                  >
                    <div
                      className={`d-inline-block pt-1 pl-0 pr-0`}
                      style={{ width: "15%", alignSelf: "center" }}
                    >
                      <b>{item.master_name}</b>
                    </div>
                    <div
                      className={
                        isShowEmployee && showAllocateTasksPopup
                          ? `d-block pt-1 pl-0 pr-0 ${styles["custom-activity-width"]}`
                          : "d-block pt-1 text-left"
                      }
                      style={{ width: "41%", marginRight: "1%" }}
                    >
                      <b style={{ color: "#5cb85c" }}>Start Time</b>
                      <div className="pt-1">
                        <img
                          className="mr-1 pt-0"
                          src={clock}
                          height="18px"
                          width="18px"
                          alt="Close"
                        />
                        {item?.start_datetime_display}
                      </div>
                    </div>

                    <div
                      className={
                        isShowEmployee & showAllocateTasksPopup
                          ? `d-block pt-1 pl-1 pr-0 ${styles["custom-activity-width"]}`
                          : "d-block pt-1 text-left"
                      }
                      style={{ width: "41%" }}
                    >
                      <b style={{ color: "#d9534f" }}>End Time</b>
                      <div className="pt-1">
                        <img
                          src={clock}
                          className="mr-1 pt-0"
                          height="18px"
                          width="18px"
                          alt="Close"
                        />
                        {item?.end_datetime_display}
                      </div>
                    </div>
                  </div>
                  {item.master_name === "Shift" && (
                    <div
                      className={
                        isShowEmployee && showAllocateTasksPopup
                          ? `${styles["activity-btn-container-width"]}  mb-3 mt-3`
                          : `mb-3 mt-3 `
                      }
                      style={
                        showAllocateTasksPopup
                          ? {
                              float: "right",
                              textAlign: "left",
                              display: 'flex'
                            }
                          : { textAlign: "right" ,    display: 'flex'}
                      }
                    >
                      <Button
                          size="sm"
                          color="success"
                          disabled={isLoading}
                          className={
                            isShowEmployee && showAllocateTasksPopup
                              ? `${styles["btn-progress"]} ${styles["btn-progress-alignment"]} mr-1`
                              : ` ${styles["btn-progress"]}  mr-3`
                          }
                          onClick={() => acceptAndRejectActivity("Approve",item.session_id)}
                        >
                          Manually Create Activity &nbsp;
                      </Button>
                      <Button
                        size="sm"
                        color="danger"
                        disabled={isLoading}
                        className={
                          isShowEmployee && showAllocateTasksPopup
                            ? `${styles["btn-progress"]} ${styles["btn-progress-alignment"]} `
                            : `${styles["btn-progress"]} ${styles["temp1"]}`
                        }
                        onClick={() => acceptAndRejectActivity("Reject",item.session_id)}
                      >
                        Reject Session &nbsp;
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </Card>
    </div>
  );
};

ActivityInProgressCard.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ActivityInProgressCard;
