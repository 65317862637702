import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import GeoFenceBreachTableComponent from 'components/reports/geoFenceBreach/Table';

import { PAGE_COUNT } from 'constants/records';

/**
 * ReportDetailPage -> GeoFenceBreachListSection
 *
 * Components:
 *    - {@link JobSummaryTableComponent}
 *    - {@link PaginationComponent}
 *
 * State:
 *    - reportData
 *
 * Actions:
 *    None
 */
const GeoFenceBreachListSection = (props) => {
  const { data, isLoading, location, reportDetail } = props;
  const {query} = location;
  const cookieData = cookie.load("geo_fence_breach_page_size");
  let pageSize;

  if(cookieData) pageSize = +cookieData;
  else if (query && query.page_size)  pageSize = +query.page_size;
  else pageSize = 10;

  if (isLoading) return <SectionLoaderAtom active />;
  return (
    <section>
      <GeoFenceBreachTableComponent
        data={data}
        location={location}
        pageSize={pageSize}
        child={reportDetail.parent_report}
      />
      <PaginationComponent
        count={data.length}
        location={location}
        pageCount={PAGE_COUNT}
        cookieKey="geo_fence_breach_page_size"
      />
    </section>
  );
};

const mapStateToProps = state => ({
  data: ReportDucks.reportData(state),
  location: RouteDucks.location(state),
  reportDetail: ReportDucks.reportDetail(state),
});

const mapActionsToProps = dispatch => ({});

GeoFenceBreachListSection.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

GeoFenceBreachListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceBreachListSection);
