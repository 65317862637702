import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';
import DeleteIconAtom from 'atoms/DeleteIcon';

import DragIcon from 'components/vendors/Drag.png';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

/**
 * AdjustmentTypesListPage -> AdjustmentTypesListSection -> AdjustmentTypesTableComponent
 *
 * Props:
 *    - notification setting list
 */
class AdjustmentTypesTableComponent extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      sortedArray: this.props.data.results && this.props.data.results,
    }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  //This function calls after droping the item at destination index.
  onDragEnd(result){
    const { destination, source } = result;
    const {sortedArray} = this.state;
    const {adjustmentIdsList} = this.props;

    // return true if item was dropped outside
    if (!destination) return true;
    
    // return true if the item was dropped in the same place
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return true;
    }

    const results = Object.assign([], sortedArray);
    const item = sortedArray[source.index]; //save the draggable item in item constant
    results.splice(source.index, 1); //remove the source item from the existing index.
    results.splice(destination.index, 0, item); //add the source item at destination index.
    this.setState({
      sortedArray: results
    });

    const selectedItemPk = sortedArray[source.index] && sortedArray[source.index].id;
    const selectedItemIndex = adjustmentIdsList.indexOf(selectedItemPk);
    let updatedArray = adjustmentIdsList;
    const difference = destination.index - source.index;
    updatedArray.splice(selectedItemIndex, 1);
    updatedArray.splice(selectedItemIndex + difference, 0, selectedItemPk ) 

    updatedArray && updatedArray.length && this.props.saveUserOrderPreference(updatedArray)
    .then(() => this.props.getAdjustmentIds());
  }

  render(){
    const { sortedArray } = this.state;
    const { data, toggle , deleteActivity } = this.props;
    console.log('in render');

    return (
      <div className="ml-3 mr-4">
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Table hover responsive size="sm" className="mt-3 table-emp">
            <thead className="Header-col">
              <tr>
                <th className="text-center" width="5%">&nbsp;</th>
                <th className="text-center">Action</th>
                <th>Active</th>
                <th>Name</th>
                <th>Codes</th>
              </tr>
            </thead>
            <Droppable droppableId="adjustmentTable">
              {(provided, snapshot) => (
                <tbody ref = {provided.innerRef} {...provided.droppableProps} className="timeCard-table font-colorInput">
                  {sortedArray && sortedArray.map((item, idx) => {
                    return (
                      <Draggable
                        draggableId={item.id.toString()}
                        index={idx}
                        key={item.id}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref = {provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            key={item.id}
                          >
                            <td className="text-center"><img className="drag-icon" src={DragIcon} width="20" height="20" alt="dragIcon"/></td>
                            <td className="text-center">
                              <span title="Edit Adjustment Types">
                                <EditIconAtom className="cursor-pointer" onClick={() => toggle(item)} />
                              </span>  
                              <span title="Delete Task" className="pl-2">
                                <DeleteIconAtom 
                                  className="cursor-pointer" 
                                  onClick ={() => deleteActivity(item)}
                                />
                              </span>
                            </td>
                            {item.is_active ? <td>Yes</td> : <td>No</td>}
                            <td title={item.name}>{item.name}</td>
                            <td title={item.code}>{item.code}</td>
                          </tr>
                        )}
                    </Draggable>)
                  })}
                  {provided.placeholder}
                  {!data.count &&
                    <tr><td className="text-center" colSpan="5">No Records Found</td></tr>
                  }
                </tbody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </div>
    );
  }
};


AdjustmentTypesTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AdjustmentTypesTableComponent;
