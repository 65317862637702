import React from "react";

import Form from "reactstrap/lib/Form";

import styles from "../styles.module.scss";
import { ReactComponent as ClearIcon } from "components/common/FilterForm/close.svg";

class AddTimeOffSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  render() {
    const { oAuthToken, company_id, closeSlider,employeeName,employeeId} = this.props;
    return (
      <div className="w-30">
        <Form noValidate>
          <div>
            <div>
              <h4
                className={`${styles["add-activities-heading"]} d-inline-block mt-1 pl-4 mr-2 mb-0`}
              >
                Add Time Off Request
              </h4>
              <ClearIcon
                className={`${styles["clear-Icon"]}`}
                height="13"
                width="13"
                onClick={() => closeSlider()}
              />
            </div>
            <p
              className={`${styles["add-activities-sub-heading"]} pl-4 pr-4 mb-0`}
            >
              Manage time off request
            </p>
            <hr className={`${styles["horizontal-line"]} bg-primary`} />
            <div
              style={{
                height: "calc(100vh - 102px - 46px)",
              }}
            >
              <iframe
                id="scheduling"
                title="scheduling-iframe"
                width="100%"
                height="100%"
                frameBorder="0"
                src={`${process.env.REACT_APP_SCHEDULING_BASE_URL}/pto-request/add?oAuthToken=${oAuthToken}&company_id=${company_id}&employee_id=${employeeId??''}&employee_name=${employeeName??''}&tabId=add`}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default AddTimeOffSlider;
