import React from 'react';
import Table from 'reactstrap/lib/Table';

import SectionLoaderAtom from 'atoms/SectionLoader';
import DeleteIconAtom from "atoms/DeleteIcon";
import styles from "forms/components/styles.module.scss";


class AssignedEmployeesTable extends React.PureComponent {
 
  render() {
    const { list, isLoading, handleDeleteEmployee  } = this.props;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
      <div className={`ml-3 mr-3 pt-0 `}>
      <Table responsive hover className={`${styles['table-style']}`}>
            <thead className="Header-col">
              <tr>
                <th className="pl-4">Name</th>
                <th className="">Title</th>
                <th className="">Username</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? list.map(item => (
                <tr key={item.id}>
                  <td title={`${item.name}`} className="pl-4">{`${item.name}`}</td>
                  <td title={item.title} className="">{item.title}</td>
                  <td title={item.username} className="">{item.username}</td>
                  <td title={item.action} className="text-center">
                    <DeleteIconAtom
                      className="cursor-pointer"
                      height="15px"
                      width="15px"
                      title="Delete Question"
                      onClick={() => handleDeleteEmployee(item.id)}
                    />
                  </td>
                </tr>
              ))
              :
              <tr><td className="text-center" colSpan="6">No Records Found</td></tr>
              }
            </tbody>
          </Table>
        </div>                
    );
  }
};


export default AssignedEmployeesTable;
