import queryString from 'query-string';
import { createSelector } from 'reselect';

// Selectors
const routerSelector = state => state.router;

export const location = createSelector(
  routerSelector,
  router => {
    const routeLocation = {
      ...router?.location,
      query: queryString.parse(router?.location?.search),
    };
    return routeLocation;
  },
);

export default { location };
