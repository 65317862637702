import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Card from 'reactstrap/lib/Card';
import CardText from 'reactstrap/lib/CardText';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { UncontrolledTooltip } from 'reactstrap';

import QuickBooksLogo from './quickbooks_icon.png';
import settingsImage from './settings.png';
import QuickbooksButtonImage from './qb_button.png';

import ADPLogo from './adp_icon.png';
import styles from './styles.module.scss';
import StorageGateway from 'lib/storage-gateway';


const PluginGridComponent = (props, context) => {
  const { data/* ,goToSetting */ } = props;
  if (data.results.length > 1){
    StorageGateway.set('company', data.results[0].company);
  }
  else{
    StorageGateway.set('company', data.results[0].konnect_user_json.user_id);
  }

  const goTo = (url) => {
    const { router: { history } } = context;

    history.push(url);
  }
  return (
    <Row>
      {data.results && data.results.map(item => {
        if(item.master.name === 'QUICKBOOKS')
          return <Col sm="12" md="6" key={item.id}>
            <Card block className={styles['report-wrap']}>
              <Row>
                <Col sm="9">
                  <div>
                    <img
                      className={styles['report-wrap--qbicon']}
                      src={QuickBooksLogo} alt="Icon"
                    />
                    <span className={styles['report-wrap--heading']} >QuickBooks Online</span>
                  </div>
                </Col>
                {item.activated ?
                  <Col sm="3" className="text-right">
                    <div id="plugin-setting" className="d-inline-block">
                      <Link to={{
                        pathname: `/vendor/plugin/settings/Quickbooks/sync`
                      }}>
                        <img
                          className={styles['report-wrap--icon']}
                          src={settingsImage} alt="Icon"
                        />
                      </Link>
                      <UncontrolledTooltip placement="top" target="plugin-setting">
                        Plugin Settings
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  : null
                }
              </Row>
              <div className={styles['report-wrap--title']}>
                <CardText className="mb-0">
                  <small className={styles['report-wrap__desc']}>
                    QuickBooks integration helps you seamlessly pass on employee
                    information and their activities from Worksana to QuickBooks.
                  </small>
                </CardText>
                <div className={styles['report-wrap__btn']}>
                  {!item.activated ?
                    <Link className={`btn ${styles['steps__connect-btn']}`} to={'/vendor/plugin/install/Quickbooks'}>
                      Connect to QuickBooks
                    </Link>
                  :null
                  }
                </div>
              </div>
            </Card>
          </Col>;
        if(item.master.name === 'QUICKBOOKS DESKTOP')
          return <Col sm="12" md="6" key={item.id}>
            <Card block className={styles['report-wrap']}>
              <Row>
                <Col sm="9">
                  <div>
                    <img
                      className={styles['report-wrap--qbicon']}
                      src={QuickBooksLogo} alt="Icon"
                    />
                    <span className={styles['report-wrap--heading']} >QuickBooks Desktop</span>
                  </div>
                </Col>
                {item.activated ?
                  <Col sm="3" className="text-right">
                    <div id="plugin-setting" className="d-inline-block">
                      <Link to={'/vendor/plugin/settings/Quickbooks/sync'}>
                        <img
                          className={styles['report-wrap--icon']}
                          src={settingsImage} alt="Icon"
                        />
                      </Link>
                      <UncontrolledTooltip placement="top" target="plugin-setting">
                        Plugin Settings
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  : null
                }
              </Row>
              <div className={styles['report-wrap--title']}>
                <CardText className="mb-0">
                  <small className={styles['report-wrap__desc']}>
                    QuickBooks Desktop integration helps you seamlessly pass on employee
                    information and their activities from Worksana to QuickBooks.
                    Compatible with Enterprise/Pro/Premier versions of 2015 or above.
                  </small>
                </CardText>
                <div className={styles['report-wrap__btn']}>
                  {!item.activated ? <Link className={`btn ${styles['steps__connect-btn']}`} to={'/vendor/plugin/quickbooks-desktop'}>
                    Steps to connect
                  </Link> : null}
                </div>
              </div>
            </Card>
          </Col>;
        if(item.master.name === 'ADP')
          return <Col sm="12" md="6" key={item.id}>
            <Card block className={styles['report-wrap']}>
              <Row>
                <Col sm="9">
                  <div>
                    <img
                      className={styles['report-wrap--qbicon']}
                      src={ADPLogo} alt="Icon"
                    />
                    <span className={styles['report-wrap--heading']} >ADP Integration</span>
                  </div>
                </Col>
                {item.activated ?
                  <Col sm="3" className="text-right">
                    <div id="plugin-setting" className="d-inline-block">
                      <Link to={{
                        pathname: `/vendor/plugin/settings/Quickbooks/sync`
                      }}>
                        <img
                          className={styles['report-wrap--icon']}
                          src={settingsImage} alt="Icon"
                        />
                      </Link>
                      <UncontrolledTooltip placement="top" target="plugin-setting">
                        Plugin Settings
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  : null
                }
              </Row>
              <div className={styles['report-wrap--title']}>
                <CardText className="mb-0">
                  <small className={styles['report-wrap__desc']}>
                    ADP integration helps you seamlessly pass on employee
                    information and their activities from myEtimecard to ADP.
                  </small>
                </CardText>
                <div className={styles['report-wrap__btn']}>
                  {!item.activated ? <Link className={`btn ${styles['steps__connect-btn']}`} to={'/vendor/plugin/install/Adp'}>
                    Connect with ADP
                  </Link> : null}
                </div>
              </div>
            </Card>
          </Col>;
        return true;
      })}
    </Row>
  );
};

PluginGridComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

PluginGridComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PluginGridComponent;
