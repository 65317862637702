import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class TreeView extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = { collapsed: props.defaultCollapsed };
  }

  handleClick(...args) {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.props.onClick) {
      this.props.onClick(...args);
    }
  }

  render() {
    const { nodeLabel, nodeIcon, children, treeId } = this.props;
    const { collapsed } = this.state;
    let containerClassName = (treeId === 'activity' || treeId === 'vendor') ? 'tree-view_tracking' : 'tree-view_children';
    if (collapsed) containerClassName += ' tree-view_children-collapsed';
    return (
      <ul className={styles['tree-view']}>
        <li id={treeId}>
          <button
            className={styles['tree-view_item']}
            onClick={this.handleClick}
          >
            {nodeIcon}
            <span className={styles.node}>
              {nodeLabel}
            </span>
          </button>
        </li>
        <ul className={styles[containerClassName]}>
          {collapsed ? null : children}
        </ul>
      </ul>
    );
  }
}

TreeView.propTypes = {
  children: PropTypes.node.isRequired,
  defaultCollapsed: PropTypes.bool,
  nodeLabel: PropTypes.node.isRequired,
  nodeIcon: PropTypes.node,
  treeId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default TreeView;
