import ApiGateway from "lib/api-gateway";
import AppConfig from "helpers/config";

import ApplicantsListEntity from "applicants/entities/applicantsEntity";

class ApplicantsAPIGateway {
  toString() {
    return "ApplicantsAPIGateway";
  }

  async getDepartmentList(params) {
    const departmentListConfig =
      AppConfig.server.apiHost + "/company/departments/";
    const apiGateway = new ApiGateway(departmentListConfig);
    const response = await apiGateway.load(params);
    const departmentList = response.data;
    return departmentList;
  }

  async getTeamList(params) {
    const teamListConfig = AppConfig.server.apiHost + "/company/teams/";
    const apiGateway = new ApiGateway(teamListConfig);
    const response = await apiGateway.load(params);
    const teamList = response.data;
    return teamList;
  }

  async getApplicantsList(params) {
    const listConfig =  AppConfig.server.apiHost + '/employee/';
    const apiGateway = new ApiGateway(listConfig);
    const response = await apiGateway.load(params);
    const entity = response.data.results.map((i) => new ApplicantsListEntity(i));
    return { ...response.data, results: entity, count: response.data.count };
  }

  async getApplicantDetails(employeeId) {
    const employeeConfig =  AppConfig.server.apiHost + '/employee/' + employeeId + '/'; 
    const apiGateway = new ApiGateway(employeeConfig);
    const response = await apiGateway.load();
    const applicantEntity = new ApplicantsListEntity(response.data);
    return applicantEntity;
  }

  async getUserPreference(params){
    const getUserPreferenceConfig = AppConfig.server.apiHost + '/employee/user_preference/view/';
    const apiGateway = new ApiGateway(getUserPreferenceConfig);
    const response = await apiGateway.load(params);
    const  employeePreferenceData = response.data;
    return employeePreferenceData;
  }

  async getAllSubCompanies(params) {
    const subcompanyListConfig =  AppConfig.server.apiHost + '/company/all/';
    const apiGateway = new ApiGateway(subcompanyListConfig);
    const response = await apiGateway.load(params);
    const subcompanyList = response.data;
    return subcompanyList;
  }

  async createApplicant(applicant){
    const applicantObj = {
      ...applicant,
      user: {
        first_name: applicant?.first_name,
        last_name: applicant?.last_name,
        password: applicant?.password,
        
      },
      company_id: applicant?.company,
      department: applicant?.department && applicant?.department,
      team: applicant?.team && applicant?.team,
      employee_role: applicant?.employee_role,
    }

    const createApplicantConfig =  AppConfig.server.apiHost + '/employee/';
    const apiGateway = new ApiGateway(createApplicantConfig);
    const response = await apiGateway.save(applicantObj);
    return response;
  }

  async updateApplicant(applicant){
    const applicantObj = {
      ...applicant,
      user: {
        first_name: applicant?.first_name,
        last_name: applicant?.last_name,
        password: applicant?.password,
        
      },
      company_id: applicant?.company,
      department: applicant?.department && applicant?.department,
      team: applicant?.team && applicant?.team,
      employee_role: applicant?.employee_role,
    }

    const createApplicantConfig =  AppConfig.server.apiHost + `/employee/` + applicant.id + '/';
    const apiGateway = new ApiGateway(createApplicantConfig);
    const response = await apiGateway.update(applicantObj);
    return response;
  }

  async getEmployeesTags(params) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/';
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.load(params);
    const tagsList = response.data
    return tagsList;
  }

  async addEmployeeTags(params) {
    const addTag =  AppConfig.server.apiHost + '/job/tags/';
    const apiGateway = new ApiGateway(addTag);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }

  async deleteMarkedTags(id, data) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/' + id + '/'; 
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.delete({...data});
    return response;
  }

  async updateTagName(id, data) {
    const tagsConfig =  AppConfig.server.apiHost + '/job/tags/' + id + '/'; 
    const apiGateway = new ApiGateway(tagsConfig);
    const response = await apiGateway.update({...data});
    return response;
  }

  async getCountryList(params) {
    const countryListConfig =  AppConfig.server.apiHost + '/address/country/';
    const apiGateway = new ApiGateway(countryListConfig);
    const response = await apiGateway.load(params);
    const countryList = response.data;
    return countryList;
  }
  
  async getStateList(params) {
    const stateListConfig =  AppConfig.server.apiHost + '/address/state/'; 
    const apiGateway = new ApiGateway(stateListConfig);
    const response = await apiGateway.load(params);
    const stateList = response.data;
    return stateList;
  }

  async getRoleList(params) {
    const roleListConfig =  AppConfig.server.apiHost + '/company/employee/role/';
    const apiGateway = new ApiGateway(roleListConfig);
    const response = await apiGateway.load(params);
    const roleList = response.data;
    return roleList;
  }

  async resetPassword(data){
    const resetConfig =  AppConfig.server.apiHost + '/employee/reset-password/';
    const apiGateway = new ApiGateway(resetConfig);
    const response = await apiGateway.save(data);
    const success = response.data;
    return success;
  }

  async updateStatus(params){
    const postStatusConfig =  AppConfig.server.apiHost + '/employee/bulk/active/';
    const apiGateway = new ApiGateway(postStatusConfig);
    const response = await apiGateway.save(params);
    const data = response.data;
    return data;
  }
}


export default ApplicantsAPIGateway;
