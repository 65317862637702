import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_SUB_COMPANIES = 'vendors/sub_company/GET_SUB_COMPANIES';
const GET_ALL_SUB_COMPANIES = 'vendors/vendor/GET_ALL_SUB_COMPANIES';
const GET_ALL_SUB_COMPANIES_FULFILLED = 'vendors/vendor/GET_ALL_SUB_COMPANIES_FULFILLED';
const GET_SUB_COMPANIES_FULFILLED = 'vendors/sub_company/GET_SUB_COMPANIES_FULFILLED';
const GET_SUB_COMPANY = 'vendors/sub_company/GET_SUB_COMPANY';
const GET_SUB_COMPANY_FULFILLED = 'vendors/sub_company/GET_SUB_COMPANY_FULFILLED';
const POST_SUB_COMPANY = 'vendors/sub_company/POST_SUB_COMPANY';
const POST_SUB_COMPANY_FULFILLED = 'vendors/sub_company/POST_SUB_COMPANY_FULFILLED';
const POST_SUB_COMPANY_USER = 'vendors/sub_company/POST_SUB_COMPANY_USER';
const POST_SUB_COMPANY_USER_FULFILLED = 'vendors/sub_company/POST_SUB_COMPANY_USER_FULFILLED';
const PUT_SUB_COMPANY = 'vendors/sub_company/PUT_SUB_COMPANY';
const PUT_SUB_COMPANY_FULFILLED = 'vendors/sub_company/PUT_SUB_COMPANY_FULFILLED';

export function getSubCompanies(params) {
  return {
    type: GET_SUB_COMPANIES,
    payload: axios.get(URLS.CHILD_COMPANY, { params }),
  };
}

export function getAllSubCompanies(params) {
  return {
    type: GET_ALL_SUB_COMPANIES,
    payload: axios.get(`${URLS.COMPANY_ALL}`, { params }),
  };
}

export function getSubCompany(params) {
  return {
    type: GET_SUB_COMPANY,
    payload: axios.get(`${URLS.VENDOR}${params.id}/`),
  };
}

export function putSubCompany(data) {
  return {
    type: PUT_SUB_COMPANY,
    payload: axios.put(`${URLS.VENDOR}${data.id}/`, data),
  };
}

export function postSubCompany(data, id) {
  const detail ={...data, company_id : id};
  return {
    type: POST_SUB_COMPANY,
    payload: axios.post(URLS.SUB_COMPANY, detail),
  };
}

export function postSubCompanyUser(data) {
  return {
    type: POST_SUB_COMPANY_USER,
    payload: axios.post(URLS.SUB_COMPANY_USER, data),
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
  all: [],
};

function GET_SUB_COMPANIES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ALL_SUB_COMPANIES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_SUB_COMPANY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_SUB_COMPANY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_SUB_COMPANY_USER_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_SUB_COMPANY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_SUB_COMPANIES_FULFILLED]: GET_SUB_COMPANIES_FULFILLED_REDUCER,
  [GET_ALL_SUB_COMPANIES_FULFILLED]: GET_ALL_SUB_COMPANIES_FULFILLED_REDUCER,
  [GET_SUB_COMPANY_FULFILLED]: GET_SUB_COMPANY_FULFILLED_REDUCER,
  [POST_SUB_COMPANY_FULFILLED]: POST_SUB_COMPANY_FULFILLED_REDUCER,
  [POST_SUB_COMPANY_USER_FULFILLED]: POST_SUB_COMPANY_USER_FULFILLED_REDUCER,
  [PUT_SUB_COMPANY_FULFILLED]: PUT_SUB_COMPANY_FULFILLED_REDUCER,
};


const subCompanySelector = state => state.vendors.subCompany;

export const subCompanyList = createSelector(
  subCompanySelector,
  instance => instance.list
);

export const subCompanyDetail = createSelector(
  subCompanySelector,
  instance => instance.detail
);

export const subCompanyAll = createSelector(
  subCompanySelector,
  instance => instance.all
);

export const subCompanyAllEmployeeCount = createSelector(
  subCompanySelector,
  instance => {
    let employeeCount =0;
    instance.all.forEach((i) => {
      employeeCount += i.employee_count;
    });
    return employeeCount;
  }
);

export default createReducer(defaultState, handlers);
