import EventEmitter from 'lib/event-emitter';
import {EVENT_TYPES} from 'employees/usecases/string.js';


class listUseCase {

  // Constructor to initialize the class
  constructor(employeeAPIGateway) {
    this.employeeAPIGateway = employeeAPIGateway; // API gateway for employee-related operations
    this.eventEmitter = new EventEmitter(); // Event emitter to handle events
  }

  /**
   * Returns the observable for the event emitter.
   * @returns {Observable} The observable for the event emitter.
   */
  getObservable() {
    return this.eventEmitter.getObservable();
  }

  /**
   * Provides a string representation of the use case.
   * @returns {string} The string representation of the use case.
   */
  toString(){

    return 'listUseCase';
  }

  /**
   * Sets up initial data. This method currently does nothing.
   * @param {Object} data - The data to set up.
   */
  setup_data(data) { 
    // Method to initialize or set up data; currently not implemented.
  }

  /**
   * Retrieves a list of employees and emits an event with the data.
   * @param {Object} params - The parameters for retrieving the employee list.
   * @returns {Promise<void>} A promise that resolves when the employee list has been retrieved and emitted.
   */
  async getEmployeeList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.employeeList = await this.employeeAPIGateway.employeeList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.EMPLOYEE_LIST,
      data: this.employeeList,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  /**
   * Retrieves all subcompanies and emits an event with the data.
   * @returns {Promise<void>} A promise that resolves when the subcompanies have been retrieved and emitted.
   */
  async getAllSubcompanies() {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.subCompanies = await this.employeeAPIGateway.getAllSubCompanies({ paginate: false });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SUBCOMPANY_LIST,
      data: this.subCompanies,
    });
  }

  /**
   * Toggles the vendor selection and retrieves updated department and team lists.
   * @param {Object|boolean|null} data - The data used to toggle vendor selection or reset selection.
   * @param {number} companyId - The ID of the company.
   * @param {number} departmentId - The ID of the department.
   * @returns {Promise<void>} A promise that resolves when the department and team lists have been updated and emitted.
   */
  async toggleVendor(data, companyId, departmentId) {
    let queryCompanyId = companyId;
    let index;
    if (!data) {
      queryCompanyId = this.subCompanies.map(item => item.id);
    } else if (data === true) {
      queryCompanyId = [];
    } else if (data.checked) {
      index = queryCompanyId.indexOf(data.id);
      queryCompanyId.splice(index, 1);
    } else {
      queryCompanyId.push(data.id);
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    Promise.all([
      this.employeeAPIGateway.getDepartmentList({
        company_id: JSON.stringify(queryCompanyId),
        paginate: false,
      }),
      this.employeeAPIGateway.getTeamList({
        company_id: JSON.stringify(queryCompanyId),
        department_id: departmentId,
        paginate: false,
      }),
    ])
    .then((list) => {
      this.departmentList = list[0];
      this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
      this.teamList = list[1];
      this.teamList.splice(0, 0, { id: "", name: "All Teams" });
    })
    .then(() => {
      this.eventEmitter.emit({
        type: EVENT_TYPES.CHANGE_COMPANIES,
        teamList: this.teamList,
        departmentList: this.departmentList,
        companyIds: queryCompanyId,
      });
    });
  }

  /**
   * Retrieves a list of departments and emits an event with the data.
   * @param {Object} params - The parameters for retrieving the department list.
   * @returns {Promise<void>} A promise that resolves when the department list has been retrieved and emitted.
   */
  async getDepartmentList(params) {
    this.departmentList = await this.employeeAPIGateway.getDepartmentList({ ...params, paginate: false });
    this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.DEPARTMENT_LIST,
      departmentList: this.departmentList,
    });
  }

  /**
   * Retrieves a list of teams and emits an event with the data.
   * @param {Object} params - The parameters for retrieving the team list.
   * @returns {Promise<void>} A promise that resolves when the team list has been retrieved and emitted.
   */
  async getTeamList(params) {
    this.teamList = await this.employeeAPIGateway.getTeamList({ ...params, paginate: false });
    this.teamList.splice(0, 0, { id: "", name: "All Teams" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEAM_LIST,
      teamList: this.teamList,
    });
  }

  /**
   * Retrieves a list of informational data and emits an event with the data.
   * @returns {Promise<void>} A promise that resolves when the info list has been retrieved and emitted.
   */
  async getInfoList() {
    this.infoList = await this.employeeAPIGateway.getInfoList({ route: 'employee.list' });
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  /**
   * Updates the status of employees and emits an event to indicate the update.
   * @param {boolean} status - The new status to set for the employees (active or inactive).
   * @param {number[]} employeeIds - The IDs of the employees to update.
   * @param {Object} params - Additional parameters for the update.
   * @returns {Promise<void>} A promise that resolves when the status has been updated and the event has been emitted.
   */
  async updateStatus(status, employeeIds, params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    await this.employeeAPIGateway.updateStatus({
      is_active: status,
      employee_ids: employeeIds
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_STATUS,
    });
  }

  /**
   * Toggles the selection status of an employee and updates the list of selected employees.
   * @param {Object} employee - The employee object whose selection status is being toggled.
   * @param {Object[]} prevState - The previous state of the employee list.
   * @returns {Promise<void>} A promise that resolves when the selection status has been toggled and the event has been emitted.
   */
  async toggleSelection(employee, prevState) {
    employee.toggleSelection(!employee.isSelected);
    let updatedList = this.employeeList.results.filter((item) => item.isSelected && item.id === employee.id);
    let previousSelectedEmp = prevState && prevState.length && prevState.filter((item) => item.isSelected && item.id !== employee.id);
    previousSelectedEmp && previousSelectedEmp.length && updatedList.push(...previousSelectedEmp);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SELECTED_EMPLOYEES,
      data: updatedList,
    });
  }

  /**
   * Exports employee data based on the provided query and attributes, and handles file download.
   * @param {Object} query - The query parameters for exporting data.
   * @param {number[]} employeeIds - The IDs of the employees to include in the export.
   * @param {Object} showableAttributes - The attributes to include in the export.
   * @returns {Promise<void>} A promise that resolves when the export is complete and the file is downloaded.
   */
  async export(query, employeeIds, showableAttributes){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_EXPORT_LOADER,
    });
    let showableKeys = ""
    let idx = 0;
    for (let [key, val] of Object.entries(showableAttributes)) {
      if (idx === 0) {
        if (val)  {
          showableKeys = showableKeys.concat(key)
        }
        idx = idx + 1;
        continue;
      }
      else if (key === 'address' && val) {
        showableKeys = showableKeys.concat(",address_line_one,address_line_two,country,state,city,zip_code");
        idx= idx + 1;
      }
      else if (val) {
        showableKeys = showableKeys.concat(",").concat(key);
        idx = idx + 1;
      }
    }
    showableKeys = showableKeys.concat(",").concat('password');
    const data = await this.employeeAPIGateway.export({...query, employee_ids: JSON.stringify(employeeIds), show: showableKeys })
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_EXPORT_LOADER,
    });
        // Only for IE 11
    if (window.navigator.msSaveOrOpenBlob) {
      const a = document.createElement("download");
      document.body.appendChild(a);
      a.style.display = "none";
      a.onclick = ((evx) => 
      {
        const  myBlob =  new Blob([data] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        window.navigator.msSaveOrOpenBlob(myBlob, 'employees.csv');
      });
      a.click();
    }
    // chrome ,safari and mozilla browsers 
    else {
      const  myBlob =  new Blob([data] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      const  url = window.URL.createObjectURL(myBlob);
      const element = document.createElement("a");
      document.body.appendChild(element);
      element.href = url;
      element.download = 'employees.csv';
      element.click();
    }
  }

  /**
   * Resets the password for an employee.
   * @param {Object} data - The data for resetting the password.
   * @returns {Promise<void>} A promise that resolves when the password has been reset.
   */
  async resetPassword(data) {
    await this.employeeAPIGateway.resetPassword(data);
  }

  /**
   * Retrieves a list of all jobs and emits an event with the data.
   * @param {Object} params - The parameters for retrieving the jobs list.
   * @returns {Promise<void>} A promise that resolves when the list of jobs has been retrieved and emitted.
   */
  async getAllJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_MODAL_LOADER,
    });
    this.allJobs = await this.employeeAPIGateway.getAllJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ALL_JOBS,
      data: this.allJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_MODAL_LOADER,
    });
  }

  /**
   * Assigns jobs to employees and emits an event indicating success.
   * @param {Object} params - The parameters for assigning jobs.
   * @returns {Promise<void>} A promise that resolves when the jobs have been assigned and the event has been emitted.
   */
  async assignJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGN_BUTTON_LOADER,
    });
    this.postAssignJobsList = await this.employeeAPIGateway.assignJobs(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ASSIGN_SUCCESS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_ASSIGN_BUTTON_LOADER,
    });
  }

  /**
   * Updates user preferences and emits an event with the updated data.
   * @param {Object} params - The parameters for updating user preferences.
   * @returns {Promise<void>} A promise that resolves when the user preferences have been updated and the event has been emitted.
   */
  async updateUserPreference(params) {
    this.userPreference = await this.employeeAPIGateway.updateUserPreference(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_USER_PREFERENCE,
      data: this.userPreference
    });
  }

  /**
   * Retrieves user preferences and emits an event with the data.
   * @param {Object} params - The parameters for retrieving user preferences.
   * @returns {Promise<Object>} A promise that resolves with the retrieved user preferences.
   */
  async getUserPreference(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.userPreference = await this.employeeAPIGateway.getUserPreference(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_USER_PREFERENCE,
      data: this.userPreference
    });
    return this.userPreference;
  }

  /**
   * Updates user job assignments.
   * @param {Object} params - The parameters for updating user job assignments.
   * @returns {Promise<void>} A promise that resolves when the user job assignments have been updated.
   */
  async updateUserJobAssignment(params) {
    await this.employeeAPIGateway.updateUserJobAssignment(params);
    // Event emission code commented out; consider re-adding if needed.
  }

  }

export default listUseCase;
