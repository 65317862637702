import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

/**
 * CompanyListPage -> CompanyFilterSection
 *
 * Components:
 *    - Add Company
 *
 * State:
 *    None
 *
 * Actions:
 *    None
 */
class CompanyFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.goTo = this.goTo.bind(this);
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {

    return (
      <Row className="filter ml-3 mr-4">
        <Col className="px-0">
          <section className="float-right mt-4">
            <Button
              className="pr-4 pl-4 mr-0"
              color="accent"
              onClick={() => this.goTo(`/vendor/sub-company/add`)}
            >
              Add Branch
            </Button>
          </section>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({});

const mapActionsToProps = dispatch => ({});

CompanyFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CompanyFilterSection);
