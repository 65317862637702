import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import closeimage from './Close-Cross.png'

const  GenericModal = (props) => {
  const { isOpen, toggle, children, heading, modelClassName, bodyClassName, size, style, headStyle} = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      className={modelClassName}
      backdrop="static"
      style={style ? style : null}
    >
      <ModalHeader style={headStyle ? headStyle : null}>{heading}
        <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
      </ModalHeader>
      <ModalBody className={bodyClassName}>
        {children}
      </ModalBody>
    </Modal>
  );
};



GenericModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  heading: PropTypes.string,
};

export default GenericModal;