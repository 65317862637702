import { createSelector } from 'reselect';

import createReducer from 'helpers/createReducer';

const ADD_MISSING_ACTIVITY = 'activities/missingActivity/ADD_MISSING_ACTIVITY';
const UPDATE_MISSING_ACTIVITY = 'activities/missingActivity/UPDATE_MISSING_ACTIVITY';
const REMOVE_MISSING_ACTIVITY = 'activities/missingActivity/REMOVE_MISSING_ACTIVITY';
const RESET_MISSING_ACTIVITY = 'activities/missingActivity/RESET_MISSING_ACTIVITY';


export function addMissingActivity(data) {
  return {
    type: ADD_MISSING_ACTIVITY,
    payload: data,
  };
}

export function updateMissingActivity(data) {
  return {
    type: UPDATE_MISSING_ACTIVITY,
    payload: data,
  };
}

export function removeMissingActivity(data) {
  return {
    type: REMOVE_MISSING_ACTIVITY,
    payload: data,
  };
}

export function resetMissingActivity() {
  return {
    type: RESET_MISSING_ACTIVITY,
  };
}


const defaultState = {
  list: [
    {
      activity_code: "PUNCH-IN",
      start_datetime: null,
      end_datetime: null,
      index: 0,
      is_future_dt: null,
    },
  ],
};

function ADD_MISSING_ACTIVITY_REDUCER(state, action) {
  const arr = [ ...state.list ];
  arr.push(action.payload);
  const entries = arr.map((item, index) => ({ ...item, index }));
  return Object.assign({}, state, {
    list: entries,
  });
}

function UPDATE_MISSING_ACTIVITY_REDUCER(state, action) {
  const entries = [ ...state.list ];
  for (let i = 0; i < entries.length; i += 1) {
    if (entries[i].index === action.payload.index) {
      entries[i] = action.payload;
    }
  }
  return Object.assign({}, state, {
    list: entries,
  });
}

function REMOVE_MISSING_ACTIVITY_REDUCER(state, action) {
  const entries = state.list.filter(item => item.index !== action.payload.index);
  return Object.assign({}, state, {
    list: entries,
  });
}

function RESET_MISSING_ACTIVITY_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

const handlers = {
  [ADD_MISSING_ACTIVITY]: ADD_MISSING_ACTIVITY_REDUCER,
  [UPDATE_MISSING_ACTIVITY]: UPDATE_MISSING_ACTIVITY_REDUCER,
  [REMOVE_MISSING_ACTIVITY]: REMOVE_MISSING_ACTIVITY_REDUCER,
  [RESET_MISSING_ACTIVITY]: RESET_MISSING_ACTIVITY_REDUCER,
};


const missingActivitySelector = state => state.activities.missingActivity;

export const missingActivityList = createSelector(
  missingActivitySelector,
  instance => instance.list,
);

export default createReducer(defaultState, handlers);
