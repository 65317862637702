import React from 'react';
import {  Collapse} from 'reactstrap';
import moment from 'moment';
import StorageGateway from 'lib/storage-gateway';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Popover } from 'reactstrap';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import JobFilterFormComponent from 'components/common/JobFilterForm';
import SortHeaderComponent from './TimeAndMaterialSortHeaderComponent';
import AttributeToggler from "employees/components/AttributeToggler";
import ManageJobAdvancedFilterForm from './ManageJobAdvancedFilters';
import { UncontrolledTooltip, Tooltip } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import {ReactComponent as SortIcon} from './sort.svg';
import Button from 'reactstrap/lib/Button';
import ReactTable from "react-table";
import { FILTER_OPTIONS, ADVANCE_GROUP_BY_OPTIONS } from 'constants/jobs';
import caretArrowUp from './caret-arrow-up.png';
import reload from './refresh-page-option.png';
import showHideIcon from './showHideIcon.png'
import cookie from 'react-cookies';

import styles from './styles.module.scss';
/**

 * JobListPage -> JobListSection -> CustomerListTableComponent
 *
 * Props:
 *    - employee list
 */

class ManageTimeAndMaterialJobs extends React.Component {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.pageChange = this.pageChange.bind(this);
    this.pageSizeChange = this.pageSizeChange.bind(this);
    this.setData = this.setData.bind(this);
    this.search = this.search.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onStatusSelection = this.onStatusSelection.bind(this);
    this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
    this.setAttributeToggler = this.setAttributeToggler.bind(this);
    this.setAttributes = this.setAttributes.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.saveOrdering = this.saveOrdering.bind(this);
    this.getResized = this.getResized.bind(this);
    this.popToggle = this.popToggle.bind(this);
    this.isToolTipOpen = this.isToolTipOpen.bind(this);
    this.getEmployeeList = this.getEmployeeList.bind(this);
    this.applyAdvancedFilters = this.applyAdvancedFilters.bind(this);
    this.getTaskList = this.getTaskList.bind(this);
    this.toggleTable = this.toggleTable.bind(this);
    this.getTagsList = this.getTagsList.bind(this);
    this.prevJobId = null;
    this.prevTaskId = null;
    this.prevEmployeeId = null;
    this.resultemployee = [];
    this.advancedFilters = null;
    this.resultTask = [];
    this.accessorToHeader = {
      customer_name: "Customer",
      code : 'Job Code',
      name: "Job",
      start_date: "Start Date",
      end_date: "End Date",
      employee: "Employees",
      task_count: "Task",
      tags_count: "Tags Count",
      status : 'Status'
    };
    this.widthToHeader = {
      customer__customer_name: 140,
      code : 140,
      name: 140,
      start_date: 140,
      end_date: 140,
      employee_count: 140,
      task_count: 140,
      tags_count: 140,
      status: 140,
    };
    this.state = { 
      loading: false,
      currentPage: 0,
      tableView: null,
      currentPageSize: 10,
      pageChange : false,
      pageSize : null,
      dropdownOpen : false,
      valueStatus: { value: '', label: "Open"},
      selectedId : null,
      attributeToggler: false,
      showableAttributes: cookie.load('showableAttributesTimeNew') || {},
      userViewPreferenceLoading: false,
      isEmployeeLoading: false,
      isTaskLoading: false,
      associatedEmployees : {},
      associatedTasks : {},
      associatedTags : {},
    };
  }


  componentWillMount() {
    const { router: { history } } = this.context;
    const { location: { query , pathname }, userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const widthChange = cookie.load(`materialTableWidthNew${uniqueUuid}${userProfile.company.id}`);
    if(widthChange){
      this.widthToHeader=widthChange;
    }
    const advancedFlters = StorageGateway.get(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`); 
    
    this.advancedFilters = advancedFlters ? JSON.parse(advancedFlters) : {}
    if((this.advancedFilters && !this.advancedFilters.MaterialFiltersCookieNewstatus)){
      this.advancedFilters['MaterialFiltersCookieNewstatus'] = {value : "1",label : "Open"}
    }

    StorageGateway.set(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`, JSON.stringify(this.advancedFilters));

    this.props.getNewUserPreference({preference_type : 'user_preference_manage_material'})
    .then(() => {
      const {newUserViewPreferenceTimeAndMaterial} = this.props;
      let obj = {}
      let show_fields = newUserViewPreferenceTimeAndMaterial.show_fields;
      Object.keys(this.accessorToHeader).forEach(v => {
        if(show_fields.indexOf(v)!== -1) {
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
      this.setAttributes(obj);
      cookie.save("pageSizeTimeNew", newUserViewPreferenceTimeAndMaterial.page_size || 10, { path: '/'});
      cookie.save("materialSortingNew", newUserViewPreferenceTimeAndMaterial.default_ordering || null, { path: '/'});
      const pageSizeCookie = cookie.load('pageSizeTimeNew');
      if(pageSizeCookie){
        this.setState({currentPageSize: pageSizeCookie});
      } else {
        this.setState({currentPageSize: 10});
      }
      //cookie.save("timecard_page_size", newUserViewPreferenceTimeAndMaterial.page_size);
    })
    .catch(() => {
      let attributes = {};
      for (const acc in this.accessorToHeader) {
        if(acc === 'labour_ratio')
        {
          attributes[acc] = false;
        }
        else
        {
          attributes[acc] = true;
        }
      }
      this.setAttributes(attributes);
    });

    this.props.openAccordian();
  }

  componentWillReceiveProps(nextProps){
    const { location: { query } } = this.props;
    const { location: { search } } = nextProps;
    const MaterialpageCookie=cookie.load("MaterialpageNew");
    const { currentPage } = this.state;
    const nextQuery = queryString.parse(search);
    if(query.material_search !== nextQuery.material_search){
      this.setState({currentPage : 0});
    }
    if(query.material_sorting !== nextQuery.material_sorting && nextQuery.material_sorting && this.state.showableAttributes.name){
      this.saveOrdering(nextQuery);
    }
    if( JSON.stringify(nextProps.timeAndMaterialList) !== JSON.stringify(this.props.timeAndMaterialList)){
      let temp = {...this.state.associatedEmployees};
      nextProps.timeAndMaterialList.forEach((item, index)=> {
        temp[`material${index}`] = 'Loading...';
      });
      this.setState({associatedEmployees : temp});
    }
    if( JSON.stringify(nextProps.timeAndMaterialList) !== JSON.stringify(this.props.timeAndMaterialList)){
      let temp = {...this.state.associatedTasks};
      nextProps.timeAndMaterialList.forEach((item, index)=> {
        temp[`material${index}`] = 'Loading...';
      });
      this.setState({associatedTasks : temp});
    }
    if( JSON.stringify(nextProps.timeAndMaterialList) !== JSON.stringify(this.props.timeAndMaterialList)){
      let temp = {...this.state.associatedTags};
      nextProps.timeAndMaterialList.forEach((item, index)=> {
        temp[`material${index}`] = 'Loading...';
      });
      this.setState({associatedTags : temp});
    }
  }

  toggleTable(key, tableData){
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const advancedFlters = StorageGateway.get(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`); 
    
    this.advancedFilters = advancedFlters ? JSON.parse(advancedFlters) : {}
    if((this.advancedFilters && !this.advancedFilters.MaterialFiltersCookiedate_range) || !this.advancedFilters){
      this.advancedFilters['MaterialFiltersCookiedate_range'] = {label: "This Month" , value: "4"}
    }
    if((this.advancedFilters && !this.advancedFilters.MaterialFiltersCookieNewstatus)){
      this.advancedFilters['MaterialFiltersCookieNewstatus'] = {value : "1",label : "Open"}
    }

    StorageGateway.set(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`, JSON.stringify(this.advancedFilters));
    this.props.toggleAccordian(key, tableData);
  }

  applyAdvancedFilters(params){
    const { getJobList } = this.props;
    cookie.save('MaterialpageNew',  1, { path: '/'})
    this.setState({ currentPage: 0 });
    return getJobList(this.props , this.state.tableData, 'material_search' , params);
  }
  
  search(){
    const { quotedRecurringSearch } = this.state;
    this.setState({quotedRecurringSearch : !quotedRecurringSearch});
  }

  toggle(){ 
    this.setState({ currentPage: 0, currentPageSize: 10 });
  }


  onStatusSelection(val, id){
    if(val){
      this.setState({valueStatus: val, selectedId: id});
    }
    else{
      this.setState({valueStatus:""});
    }
  }

 
  setData(params,total) {
    const {
      router: { history }
    } = this.context;
    const {
      location: { query },
      quotedRecurringList , userProfile 
    } = this.props;
    if(params.group_by == 'task'){
      history.push(`/job/list/${params.data.id}/task/edit/?admin_list=true`);
    }
    else{
       history.push(`/job/list/${params.data.id}/employee/edit/?admin_list=true`);
    }
  }

  pageChange(item) {
    cookie.save('MaterialpageNew',  (+item) + 1 , { path: '/'})
    this.setState({ currentPage: item });
  }

  componentWillUnmount(){
    cookie.save('MaterialpageNew',  1 , { path: '/'})
  }


  dropdownToggle() {
    const { dropdownOpen } = this.state;
    this.setState({dropdownOpen: !dropdownOpen});
  }

/*

  togglePopOver(id, item){
    const { PopOverOpen } = this.state;
    this.setState({ PopOverOpen : !PopOverOpen, selectedItem: item})
  }*/


  pageSizeChange(pageSize, pageIndex) {
    cookie.save('pageSizeTimeNew', pageSize, { path: '/'});
    cookie.save('MaterialpageNew', pageIndex + 1, { path: '/'})
    this.setState({ currentPageSize: pageSize, currentPage: pageIndex });
    this.setColumns('submit');
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

   popToggle(targetName){
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen
        }
      });
    }
  };

  isToolTipOpen(targetName) {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

 getEmployeeList(jobId, index){
    let results = [];
    const { associatedEmployees } = this.state;
    if(this.prevEmployeeId !== `material${index}` && associatedEmployees[`material${index}`] == 'Loading...'){
      this.props.setEmployee({job_id: jobId, assigned: true})
      .then((res)=>{
          let temp = {...this.state.associatedEmployees };
          res.value.forEach((item, index)=>
          results.push(item.user.full_name));
          temp[`material${index}`] = results;
          this.setState({associatedEmployees : temp});
          this.prevEmployeeId = `material${index}`;
    });}
  }

  getTaskList(jobId, index){
    let results = [];
    const { associatedTasks } = this.state;
    if(this.prevTaskId !== `material${index}` && associatedTasks[`material${index}`] == 'Loading...'){
      this.props.setTask({job_id: jobId, assigned: true})
      .then((res)=>{
        let temp = {...this.state.associatedTasks };
         res.value.forEach((item, index)=>
          results.push(item.name)
        ) 
        temp[`material${index}`] = results;
        this.setState({associatedTasks : temp});
        this.prevTaskId = `material${index}`;      
      })
    }
  }

  getTagsList(jobId, index){
    let results = [];
    const { associatedTags } = this.state;
    if(this.prevJobId !== `material${index}` &&  associatedTags[`material${index}`] && associatedTags[`material${index}`] == 'Loading...'){
      this.props.setTags({job_id: jobId, assigned: true})
      .then((res)=>{
        let temp = {...this.state.associatedTags };
        res && res.value && res.value.tags && res.value.tags.length && res.value.tags.forEach((item, index)=>
        results.push(item)
        );
        temp[`material${index}`] = results;
        this.setState({associatedTags : temp});
        this.prevJobId = `material${index}`;
      });
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  setAttributeToggler(bool) {
    this.setState({ attributeToggler: bool });
    if(bool) {
      this.setState({ userViewPreferenceLoading: true})
      this.props.getNewUserPreference({preference_type : 'user_preference_manage_material'})
      .then(() => {
        const {newUserViewPreferenceTimeAndMaterial} = this.props;
        let obj = {}
        let show_fields = newUserViewPreferenceTimeAndMaterial.show_fields;
        Object.keys(this.accessorToHeader).forEach(v => {
        if(show_fields.indexOf(v)!== -1) {
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
        this.setAttributes(obj);
        this.setState({ userViewPreferenceLoading: false});
      })
      .catch((err) => {
        this.setState({ userViewPreferenceLoading: false});
        let attributes = {};
        for (const acc in this.accessorToHeader) {
        if(acc === 'labour_ratio')
        {
          attributes[acc] = false;
        }
        else
        {
          attributes[acc] = true;
        }
      }
        this.setAttributes(attributes);
      })
    }else{
      const {newUserViewPreferenceTimeAndMaterial} = this.props;
      let obj = {}
      let show_fields = newUserViewPreferenceTimeAndMaterial.show_fields;
      Object.keys(this.accessorToHeader).forEach(v => {
        if(show_fields.indexOf(v)!== -1) {
          obj[v] = true;
        }
        else {
          obj[v] = false;
        }
      }
      )
      this.setAttributes(obj);
    }
  }

  saveOrdering(nextQuery) {
    const showFields = Object.keys(this.state.showableAttributes).filter((item) => {
      if(this.state.showableAttributes[item] === true)
        return item
      else if(this.state.showableAttributes[item] === false && item === "name")
        return true
      else
        return false
    })
    const pageSize = cookie.load("pageSizeTimeNew") || 10;
    const preferenceObj = {
      show_fields: showFields,
      page_size: pageSize,
      bill_type: 'TIME_AND_MATERIAL',
      default_ordering : nextQuery.material_sorting
    }
    this.props.setUserPreference({...preferenceObj, preference_type : 'user_preference_manage_material'})
    cookie.save("materialSortingNew", nextQuery.material_sorting, { path: '/'});
  }

  async setAttributes(attributes) {
    await this.setState({ showableAttributes: attributes });
    cookie.save("showableAttributesTimeNew", attributes, { path: '/'})
  }

  setColumns(submit) {
    const showFields = Object.keys(this.state.showableAttributes).filter((item) => {
      if(this.state.showableAttributes[item] === true)
        return item
      else if(this.state.showableAttributes[item] === false && item === "name")
        return true
      else
        return false
    })
    const pageSize = cookie.load("pageSizeTimeNew") || 10;
    const preferenceObj = {
      show_fields: showFields,
      page_size: pageSize,
      bill_type: 'TIME_AND_MATERIAL',
    }
    this.props.setUserPreference({...preferenceObj, preference_type : 'user_preference_manage_material'})
    .then(() => {
      if(!submit) this.setState({attributeToggler: !this.state.attributeToggler})
    })
  }

  getResized(newSize){
    const {userProfile} = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    newSize.map((item,index)=>{
      this.widthToHeader[item.id]=item.value;
    })
    cookie.save(`materialTableWidthNew${uniqueUuid}${userProfile.company.id}`,this.widthToHeader, { path: '/'});
  }

  render() {
    const { location, goTo, pageTimeAndMaterials, getJob, saveStatus, isStatusLoading,  toggle, originalCount, isLoadingMaterial, exportCSV, exportTimeMaterials , timeAndMaterials, toggleAccordian, timeAndMaterialList, refresh, PopOverOpen, selectedItem} = this.props;
    const { query } = location;
    const {currentPage, currentPageSize , dropdownOpen, valueStatus, selectedId} = this.state;
    let updatedTimeMaterial = timeAndMaterialList.map(item => {
      const s = item.status && FILTER_OPTIONS.filter(i => i.value === item.status)[0].label;
      return { ...item, status: s };
    });
    updatedTimeMaterial = updatedTimeMaterial.filter(item => item.status || item.total)
    
    
    return(
      <div>
        <div className={timeAndMaterials? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
          <div 
            className={styles['card-header__label--quoted']} 
            onClick={() => this.toggleTable("timeAndMaterials", this.props.tableData)}>
              <p className={styles['card-header__p']} style={{marginBottom: '1rem'}}>Time and Material</p>
              <img alt=""
                src={caretArrowUp} 
                className={
                  timeAndMaterials? styles['card-header__label--quoted--down']: styles['card-header__label--quoted--up']} 
                  height="10" 
              />
          </div>
          {
            timeAndMaterials &&
            <div className={styles['card-header__search-bar']}>
                <JobFilterFormComponent 
                  location={location}
                  id="material_search"
                  placeholder="Job Name and Job code" 
                  initialValues={{...query}}
                  materialSearchCookie="material_search_new"
                />
            </div>
          }
          { 
            timeAndMaterials &&
            <Button
              color="primary"
              onClick={() => this.setAttributeToggler(!this.state.attributeToggler)}
              className={styles['show-hide-button-custom']}
              >
              <div id="show_hide">
                <img
                  className={styles['button-icon']}
                  src={showHideIcon}
                  alt="Icon"
                />
                <UncontrolledTooltip placement="top" target="show_hide">
                  Show/Hide
                </UncontrolledTooltip>
              </div>
            </Button>
          }
          <div className={styles['card-header__btn']}>
            { timeAndMaterials &&
              <Button
                color="primary"
                id="add_job"
                onClick={() => exportCSV(this.props.tableData, "timeAndMaterials", this.state.showableAttributes)}
                disabled={updatedTimeMaterial && !updatedTimeMaterial.length}
              >
                Export <ButtonLoaderAtom active={exportTimeMaterials} />
              </Button>
            }
            { timeAndMaterials &&
              <Button
                color="primary"
                className="align-top ml-2"
                onClick={() => this.goTo({
                  pathname: '/job/list/bulk-upload',
                  search: queryString.stringify({
                    type: 'timeAndMaterials',
                    admin_list: true
                  })
                })}
              >
                Bulk Upload
              </Button>
            }
          </div>
          {
            timeAndMaterials &&
            <div className={styles['card-header__btn--refresh']}>
              <img src={reload} width="13" alt="" onClick={() => refresh("timeAndMaterials")} />
            </div>
          }
          {this.state.attributeToggler &&
            <AttributeToggler
              setToggle={() => this.setAttributeToggler(!this.state.attributeToggler)}
              toggle={this.state.attributeToggler}
              data={{
                labels: Object.values(this.accessorToHeader),
                accessors: Object.keys(this.accessorToHeader),
                defaultVals: Object.values(this.state.showableAttributes)
              }}
              onChange={this.setAttributes}
              mandatoryField = 'name'
              setColumns={this.setColumns}
              isLoading={this.state.userViewPreferenceLoading}
            />
          }
        </div>
        {timeAndMaterials && (!isLoadingMaterial) &&            
          <div style={{border:'none', paddingLeft: '1.4rem', position: 'relative', marginTop:'-10px'}} className={timeAndMaterials? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
            <p>Total: {timeAndMaterials ? this.props.totalRecords : originalCount} Records</p>
          </div>
        }
        {timeAndMaterials &&
          <div style={{border:'none', paddingLeft: '1.1rem', paddingBottom: '0.8rem', position: 'relative'}} className={timeAndMaterials? styles['card-header']: [styles['card-header--closed'], styles['card-header']].join(' ')}>
            <ManageJobAdvancedFilterForm
              getCustomers={this.props.getCustomers}
              customerList={this.props.customerList}
              getEmployees={this.props.getEmployees}
              employeeList={this.props.employeeList}
              getJobs = {this.props.getJobs}
              jobsAndCodesList={this.props.jobsAndCodesList}
              getJobTags={this.props.getJobTags}
              jobTagList={this.props.jobTagList}
              getTasks={this.props.getTasks}
              TasksAndCodesList={this.props.TasksAndCodesList}
              getTaskTags={this.props.getTaskTags}
              taskTagList={this.props.taskTagList}
              applyAdvancedFilters = {this.applyAdvancedFilters}
              initialValues = {this.advancedFilters}
              dateFormat= {this.props.dateFormat}
              form="form3"
              advancedFilterCookie = {'MaterialFiltersCookieNew'}
              sortJobCodes={this.props.sortJobCodes}
              dateFormatDisplay={this.props.dateFormatDisplay}
              getEmployeeTags= {this.props.getEmployeeTags}
              employeeTagsList={this.props.employeeTagsList}
              userProfile={this.props.userProfile}
              location={location}
              showableAttributes={this.state.showableAttributes}
            />
          </div>
        }
        <Collapse isOpen={timeAndMaterials}>
        <div className={styles['loader-z-index']}>
        </div>
          <ReactTable
            data={updatedTimeMaterial.length ? updatedTimeMaterial : []}
            noDataText="No Records Found"
            pages={pageTimeAndMaterials}
            onFetchData={(item)=>getJob("timeAndMaterials", item)}  
            loading={isLoadingMaterial}
            manual
            pageSizeOptions= {[5, 10, 20, 25, 50, 100, 500, 1000]}
            minRows={1}
            onPageChange={this.pageChange}
            page= {this.state.currentPage}
            pageSize={currentPageSize}
            onPageSizeChange={this.pageSizeChange}
            className="text-center mb-3"
            style={{ background: '#FFF' ,maxHeight:'750px'}}
            onResizedChange={(newResized, event) => {
              this.getResized(newResized);
            }}
            columns={[
              {
                Header: row => {return (
                    <SortHeaderComponent material_sorting="customer__customer_name">CUSTOMER</SortHeaderComponent>
                    )},
                accessor: "customer__customer_name",
                sortable: false,
                width: this.widthToHeader.customer__customer_name,
                id: 'customer__customer_name',
                show: this.state.showableAttributes.customer_name,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div
                    title={row.original.customer_data.customer_name}
                    className="text-ellipsis text-left pl-3"
                  >
                    <b role="button" onClick={() => goTo(`/customers/list/${row.original.customer_data.id}/details`)}>{ row.original.customer_data.customer_name }</b>
                  </div>)
                }
              },
              {
                Header: row => {return (
                  <SortHeaderComponent material_sorting="code">JOB CODE</SortHeaderComponent>
                )},
                accessor: "code",
                sortable: false,
                id: 'code',
                width: this.widthToHeader.code,
                show: this.state.showableAttributes.code,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div className='text-ellipsis text-left pl-3'
                  >
                    <div>{ row.original.code }</div>
                  </div>)
                }
              },
              {
                Header:row => {return (
                    <SortHeaderComponent  material_sorting="name">JOB</SortHeaderComponent>
                    )},
                accessor: "name",
                id: 'name',
                sortable: false,
                width: this.widthToHeader.name,
                show: this.state.showableAttributes.name,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return (<div
                    title={row.original.name}
                    className="text-ellipsis text-left pl-3"
                  >
                    <b role="button"
                    onClick={() => goTo(`/job/list/${row.original.id}/edit`)}>{ row.original.name }</b>
                  </div>)
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent material_sorting="start_date">START DATE</SortHeaderComponent>
                    )},
                accessor: 'start_date',
                id: 'start_date',
                width: this.widthToHeader.start_date,
                sortable: false,
                show: this.state.showableAttributes.start_date,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return <div  className="text-left pl-3">{ row.original.start_date_display }</div>
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent material_sorting="end_date">END DATE</SortHeaderComponent>
                    )},
                accessor: 'end_date',
                id: 'end_date',
                width: this.widthToHeader.end_date,
                sortable: false,
                show: this.state.showableAttributes.end_date,
                headerClassName: 'text-left pl-4',
                Cell: row => {
                  return <div className="text-left pl-3">{ row.original.end_date_display }</div>
                }
              },
              {
                Header: "EMPLOYEES",
                accessor: 'employee_count',
                id: 'employee_count',
                width: this.widthToHeader.employee_count,
                sortable: false,
                className:'employee-div',
                show: this.state.showableAttributes.employee,
                headerClassName: 'text-right pr-4',
                Cell: row => {
                  return (
                    <div key={`empmaterail${row.index}`} className={`${styles['employee-div']} pr-4`}>
                      <div
                        tabIndex="0"
                        id={`empmaterail${row.index}`}
                        onMouseOver={()=>this.getEmployeeList(row.original.id, row.index)}
                        className={`hover-employee-tooltip-div link-name d-inline-block`}
                        style={{width:'20%', cursor:'default'}}
                      >
                        <b role="button" onClick={() => this.setData({ data: row.original })}>{row.original.employee_count}</b>
                        <Tooltip
                          placement="left"
                          isOpen={this.isToolTipOpen(`empmaterail${row.index}`)}
                          autohide={false} 
                          hideArrow={false}
                          target={`empmaterail${row.index}`}
                          toggle={() => this.popToggle(`empmaterail${row.index}`)}
                          className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                        >
                         {
                          this.state.associatedEmployees && this.state.associatedEmployees[`material${row.index}`] && this.state.associatedEmployees[`material${row.index}`] !== 'Loading...' ?
                           this.state.associatedEmployees && this.state.associatedEmployees[`material${row.index}`] && this.state.associatedEmployees[`material${row.index}`].length ? this.state.associatedEmployees[`material${row.index}`].map((item,index)=>
                              <li>{item}</li>
                              )
                            :
                              <li>No records</li>
                           :
                           <li>{this.state.associatedEmployees &&  this.state.associatedEmployees[`material${row.index}`] ? this.state.associatedEmployees[`material${row.index}`] : null}</li>
                          }
                        </Tooltip>
                      </div>
                    </div>
                    );
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent  material_sorting="task_count">TASKS</SortHeaderComponent>
                    )},
                accessor: 'task_count',
                id:'task_count',
                width: this.widthToHeader.task_count,
                sortable: false,
                className:'employee-div',
                show: this.state.showableAttributes.task_count,
                headerClassName: 'text-right pr-4',
                 Cell: row => {
                    return (
                      <div key={`taskmaterial${row.index}`} className={`${styles['employee-div']} pr-4`}>
                        <div
                          tabIndex="0"
                          id={`taskmaterial${row.index}`}
                          onMouseOver={()=>this.getTaskList(row.original.id, row.index)}
                          className={`hover-employee-tooltip-div link-name d-inline-block`}
                          style={{width:' 20%', cursor:'default'}}
                        >
                          <b role="button" onClick={() => this.setData({ data: row.original, group_by: "task" })}>{row.original.task_count}</b>
                          <Tooltip
                            placement="left"
                            isOpen={this.isToolTipOpen(`taskmaterial${row.index}`)}
                            autohide={false} 
                            hideArrow={false}
                            target={`taskmaterial${row.index}`}
                            toggle={() => this.popToggle(`taskmaterial${row.index}`)}
                            className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                          >
                          {
                          this.state.associatedTasks && this.state.associatedTasks[`material${row.index}`] && this.state.associatedTasks[`material${row.index}`] !== 'Loading...' ?
                           this.state.associatedTasks && this.state.associatedTasks[`material${row.index}`] && this.state.associatedTasks[`material${row.index}`].length ? this.state.associatedTasks[`material${row.index}`].map((item,index)=>
                              <li>{item}</li>
                              )
                            :
                              <li>No records</li>
                           :
                           <li>{this.state.associatedTasks && this.state.associatedTasks[`material${row.index}`] ? this.state.associatedTasks[`material${row.index}`] : null}</li>
                          }
                          </Tooltip>
                        </div>
                    </div>
                    );
                }
              },
              {
                Header: row => {return (
                    <SortHeaderComponent material_sorting="tags_count">TAGS COUNT</SortHeaderComponent>
                  )},
                accessor: "tags_count",
                id:"tags_count",
                sortable: false,
                width: this.widthToHeader.tags_count,
                show: this.state.showableAttributes.tags_count,
                headerClassName: 'text-right pr-4',
                Cell: row => {
                  return (  
                    <div key={`tagsmaterial${row.index}`} className={`${styles['employee-div']} pr-4`}>
                      <div
                        tabIndex="0"
                        id={`tagsmaterial${row.index}`}
                        onMouseOver={()=>this.getTagsList(row.original.id,row.index)}
                        className={`hover-employee-tooltip-div link-name d-inline-block`}
                        style={{width:'20%', cursor:'default'}}
                      >
                        <b onClick={() => goTo(`/job/list/${row.original.id}/edit`)} role="button">{row.original.tags_count}</b>
                        <Tooltip
                          placement="left"
                          isOpen={this.isToolTipOpen(`tagsmaterial${row.index}`)}
                          autohide={false} 
                          hideArrow={false}
                          target={`tagsmaterial${row.index}`}
                          toggle={() => this.popToggle(`tagsmaterial${row.index}`)}
                          className={`${styles['hover-employee-div']} hover-employee-tooltip`}
                        >
                          {
                          this.state.associatedTags && this.state.associatedTags[`material${row.index}`] && this.state.associatedTags[`material${row.index}`] !== 'Loading...' ?
                          this.state.associatedTags&&  this.state.associatedTags[`material${row.index}`] && this.state.associatedTags[`material${row.index}`].length ? this.state.associatedTags[`material${row.index}`].map((item,index)=>
                            <li>{item}</li>
                            )
                            :
                            <li>No records</li>
                          :
                           <li>{this.state.associatedTags && this.state.associatedTags[`material${row.index}`] ? this.state.associatedTags[`material${row.index}`] : null}</li>
                          }
                        </Tooltip>
                      </div>
                    </div>
                  );
                }
              },
              {
                Header: 'STATUS',
                accessor: 'status',
                id: 'status',
                width: this.widthToHeader.status,
                sortable: false,
                show: this.state.showableAttributes.status,
                Cell: row => {
                  if (row.original.start_date_display && row.original.bill_type==="TIME_AND_MATERIAL") {
                    return (
                      <div>
                        <div id={`a${row.original.id}`} className='d-inline-block tooltip-override-div'>
                          <span className="link-name" role="button" tabIndex={0} onClick={() => this.props.togglePopOver("timeAndMaterials",row.original.id, row.original)}>{row.original.status}</span>
                        </div>
                      </div>
                    );
                  }
                  return null;
                }
              },
            ]}
          />
        </Collapse>
      </div>
    )
  }
}


ManageTimeAndMaterialJobs.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

ManageTimeAndMaterialJobs.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ManageTimeAndMaterialJobs;