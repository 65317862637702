import React, { Component } from "react";

import GridItemImage from "forms/assets/gridItem.png";

import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import DeleteIconAtom from "atoms/DeleteIcon";
import { toast } from "react-toastify";

import styles from "forms/components/styles.module.scss";
import { FORMS_QUESTIONS_TYPE } from "constants/forms";
import { Form, Input, Button, Row, Col } from "reactstrap";

import { Formik, Field, FieldArray } from "formik";

export default class QuestionModal extends Component {
  constructor(props) {
    super(props);
    this.handleRemoveOptions = this.handleRemoveOptions.bind(this);
    this.handleCheckOption = this.handleCheckOption.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleRemoveOptions(arrayHelpers, index) {
    if (index > 0) {
      arrayHelpers.remove(index);
    }
  }

  handleCheckOption(values, setFieldValue, id, e) {
    
    if (values.definition === "SINGLE_SELECT") {
      values.answer.forEach((item,index) => {
        if (index === id) {
          setFieldValue(`answer[${id}].score`, e.target.checked ? 1 : 0);
        } else {
          setFieldValue(`answer[${index}].score`, 0);
        }
      });
    } else {
      setFieldValue(`answer[${id}].score`, e.target.checked ? 1 : 0);
    }
  }

  async onSubmit(values, { setSubmitting, setErrors }) {
    const { type, handleQuestionAdd, handleQuestionUpdate } = this.props;
    if (values.question === "") {
      toast.error("Please enter the question name");
      setSubmitting(false);
      return;
    }
    if (
      (values.definition === "SINGLE_SELECT" ||
        values.definition === "MULTI_SELECT") &&
      (values.answer[0].value === null || values.answer[0].value === '')
    ) {
      toast.error("Please add atleast one answer");
      setSubmitting(false);
      return;
    }

    if (type === "add") {
      handleQuestionAdd(values);
    } else {
      handleQuestionUpdate(values);
    }
  }
  render() {
    const { toggle, isDropdown, initialValue } = this.props;
    return (
      <Modal isOpen={isDropdown} size="md" toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {FORMS_QUESTIONS_TYPE[initialValue.definition]}
        </ModalHeader>
        <ModalBody>
          <Formik
            onSubmit={this.onSubmit}
            initialValues={initialValue}
            enableReinitialize={true}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} className="mt-0 pt-2">
                <div
                  className={`${styles["modal-input-group"]}`}
                  style={{
                    pointerEvents: isSubmitting ? "none" : null,
                    opacity: isSubmitting ? "0.5" : null,
                  }}
                >
                  <Input
                    placeholder="Enter Question Name"
                    className={`${styles["modal-input"]}`}
                    type="text"
                    value={values.question}
                    name="question"
                    autoComplete="off"
                    onChange={handleChange}
                  />

                  {(values.definition === "SINGLE_SELECT" ||
                    values.definition === "MULTI_SELECT") && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Options</span>
                        <span style={{ width: "55px" }}>Correct Answer</span>
                      </div>

                      {values.definition === "SINGLE_SELECT" ||
                      values.definition === "MULTI_SELECT" ? (
                        <FieldArray
                          name={`answer`}
                          render={(arrayHelpers) => (
                            <div>
                              {values.answer.length > 0 &&
                                values.answer.map((q, r) => (
                                  <Row className="pb-2" key={r}>
                                    <Col
                                      md={10}
                                      className={`${styles["input_answer_section"]}`}
                                    >
                                      <img
                                        src={GridItemImage}
                                        width="15"
                                        height="15"
                                        alt=""
                                      />

                                      <Field
                                        type="text"
                                        name={`answer[${r}].value`}
                                        placeholder={`Option ${r + 1}`}
                                        data-lpignore={true}
                                        autoComplete="off"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        style={{ width: "inherit" }}
                                        value={q.value == null ? "" : q.value}
                                      />

                                      <DeleteIconAtom
                                        className="cursor-pointer"
                                        handleRemoveOptions
                                        height="18px"
                                        width="18px"
                                        name="delAns"
                                        onClick={() =>
                                          this.handleRemoveOptions(
                                            arrayHelpers,
                                            r
                                          )
                                        }
                                      />
                                    </Col>

                                    <Col
                                      md={1}
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                      }}
                                      className={`${styles["correct_answer_section"]}`}
                                    >
                                      <Input
                                        type="checkbox"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                        checked={
                                          values.answer[r].score === 1
                                            ? true
                                            : false
                                        }
                                        onClick={(item) => {
                                          this.handleCheckOption(
                                            values,
                                            setFieldValue,
                                            r,
                                            item
                                          );
                                        }}
                                        name={`answer[${r}].score`}
                                      />
                                    </Col>
                                  </Row>
                                ))}
                              <Row>
                                <Col className="mt-2">
                                  <Button
                                    className={`${styles["modal-add-btn"]}`}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        value: null,
                                        score: 0,
                                      })
                                    }
                                  >
                                    + Add Option
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        />
                      ) : null}
                    </div>
                  )}

                  <div className={`${styles["modal-confirm-box"]}`}>
                    <label
                      htmlFor="required"
                      className={`${styles["modal-input-checkbox"]}`}
                    >
                      <Input
                        type="checkbox"
                        name="behaviour"
                        id="required"
                        checked={
                          values.behaviour === "SKIPPABLE" ? false : true
                        }
                        onClick={(item) =>
                          setFieldValue(
                            "behaviour",
                            item.target.checked ? "MANDATORY" : "SKIPPABLE"
                          )
                        }
                      />
                      Required
                    </label>
                  </div>
                </div>
                <Button
                  className="float-right mt-2"
                  size="md"
                  type="submit"
                  color="primary"
                >
                  Confirm
                </Button>
              </Form>
            )}
          />
        </ModalBody>
      </Modal>
    );
  }
}
