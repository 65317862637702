import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Switch from "react-switch";
import styles from "./styles.module.scss"
import SectionLoaderAtom from 'atoms/SectionLoader';
import closeimage from "./Close-Cross.png";

import PropTypes from "prop-types";

class AttributeToggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: [] };
  }

  async componentDidMount() {
    const {
      data: { defaultVals, accessors }
    } = this.props;

    const checked = {};
    if (defaultVals && defaultVals.length === accessors.length) {
      await defaultVals.map((item, idx) => (checked[accessors[idx]] = item));
      await this.setState({ checked });
    }
  }

  async handleChange(accessor, bool) {
    await this.setState({ checked: { ...this.state.checked, [accessor]: bool } });
    //this.props.onChange(this.state.checked);
  }

  render() {
    const {checked} = this.state
    return (
      <Modal 
        isOpen={this.props.toggle} 
        //toggle={this.props.setToggle}
        className={styles["attribute-form"]}
      >
        <ModalHeader className={styles["attribute-form__header"]}>
          Select from below
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => this.props.setToggle()} />
        </ModalHeader>
        {this.props.isLoading ? <SectionLoaderAtom active /> :
          <ModalBody className={`${styles["attribute-form__body"]}`}>
            <div className={styles["attribute-form__container"]}>
              {this.props.data.accessors.map((item, idx) => (
                <li key={idx} className={styles["attribute-form__action"]}>
                  <span className={styles["attribute-form__action--label"]}>
                    {this.props.data.labels[idx]}
                    {(item === this.props.mandatoryField && this.props.data.defaultVals[idx] === true) || item === this.props.required ?
                      <div>
                        <f6 className="align-middle">
                          <small className={`${styles['label-part']}`}>(Mandatory field, can't be hidden)</small>
                        </f6>
                      </div> : null
                    }
                  </span>
                  <Switch 
                    onChange={checked => this.handleChange(item, checked)} 
                    checked={this.state.checked[item]} 
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={18}
                    width={36}
                    onColor='#09819A'
                    disabled={(item === this.props.mandatoryField && this.props.data.defaultVals[idx] === true) || (item === this.props.required && this.props.data.defaultVals[idx] === true)}
                    />
                </li>
              ))}
            </div>
          </ModalBody>
        } 
        <ModalFooter className="pr-4">
          <Button className={`${styles['form-button']}`} disabled={this.props.isLoading} onClick={() => this.props.setColumns(checked)} color="secondary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AttributeToggler.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    accessors: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultVal: PropTypes.arrayOf(PropTypes.bool)
  }),
  onChange: PropTypes.func
};

export default AttributeToggler;