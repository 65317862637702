import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PluginListDucks from 'ducks/vendors/plugin';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PluginGridComponent from 'components/vendors/plugins/PluginGrid';

class PluginListSection extends React.Component {
  constructor(props) {
    super(props);
    this.goToSetting = this.goToSetting.bind(this);

  }
  
  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.props.PluginListDucks.getPluginList(nextQuery);
    }
  }

  goToSetting(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const { isLoading, pluginList, location } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <PluginGridComponent
        data={pluginList}
        goToSetting={this.goToSetting} 
        location={location}
      />
    );
  }
}

const mapStateToProps = state => ({
  pluginList: PluginListDucks.pluginList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  PluginListDucks: bindActionCreators(PluginListDucks, dispatch),
});

PluginListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pluginList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  PluginListDucks: PropTypes.object.isRequired,
};

PluginListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PluginListSection);
