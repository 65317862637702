import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';

import Lightbox from 'react-image-lightbox';
import InfoGreyIconAtom from 'atoms/InfoIcon';


import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SortHeaderComponent from 'components/common/SortHeader';

import {ACTIVITY_TYPE} from 'constants/employees'

import styles from './styles.module.scss';

/**
 * KioskEmployeesAuditListPage -> KioskEmployeesAuditListSection -> KioskEmployeesAuditTableComponent
 *
 * Components:
 *    - {@link KioskEmployessAuditTableComponent}
 *
 * State:
 *    - employee audit list
 *
 * Actions:
 *    None
 */
class KioskEmployeesAuditTableComponent  extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOverlayOpen: false,
      selectedId: null,
    };
  }
  render(){
    const { data, location :{ pathname, search} , onSelectImage, selectedItemId,toggleActivityModal } = this.props;
   
    const { isOverlayOpen, selectedId } = this.state;
    return (
      <Table className="table-emp table table-sm table-hover table-kiosk-audit-employees" hover responsive size="sm">
        <thead className="Header-col">
          <tr>
            <th className="text-center">Image</th>
            <SortHeaderComponent ordering="user__first_name">First Name</SortHeaderComponent>
            <SortHeaderComponent ordering="user__last_name">Last Name</SortHeaderComponent>
            <SortHeaderComponent ordering="create_date">Date &amp; time</SortHeaderComponent>
            <th>Type</th>
            <th className="text-center">Action</th>
            <th className="text-center">View Activity Logs</th>
          </tr>
        </thead>
        <tbody>
          {data && data.results.map(item => {
            let message = '';
            if(item.note.notes === 'Invalid user'){
              message = 'Verified';
            }
            else if(item.note.notes === 'Facial Image added'){
              message = 'Added successfully';
            }
            else if(item.note.notes === 'Facial Image reset'){
              message = 'Reset successfully';
            }
            else if(item.verified){
              message = 'Verified';
            }
            return (<tr key={item.file_name}>
              <td>
                <div   
                  onClick={() => this.setState({ selectedId: item.id, isOverlayOpen: true })} 
                  role="button" 
                  tabIndex="0" 
                  className={`${styles['img-wrap']} outline-none`} 
                >
                  <img src={item.file} alt=""  />
                </div>
                {isOverlayOpen && (selectedId === item.id) && (
                  <Lightbox
                    mainSrc={item.file}
                    onCloseRequest={() => this.setState({ isOverlayOpen: false })}
                  />
                )}
              </td>
              <td title={item.user.first_name}>
                <Link to={{
                  pathname: `/employee/${item.user.employee_id}/edit/detail`,
                  search: queryString.stringify({
                    parent: `${pathname}${search}`
                  }),
                }}>
                  {item.user.first_name}
                </Link>
              </td>
              <td>{item.user && item.user.last_name}</td>
              <td title={item.create_date_display}>{item.create_date_display}</td>
              <td title={item?.note?.notes ? ACTIVITY_TYPE[item.note.notes] : null }>{item?.note?.notes ? ACTIVITY_TYPE[item.note.notes] : null }</td>
              <td className="text-center">
                {item.verified ?
                  <div>
                    <span className={styles['verify-audit']}>
                      {message}
                    </span>
                    <div>
                      {item.verified_datetime_display ? `on ${item.verified_datetime_display}` : null }
                    </div>
                    {item.note && item.note.notes === 'Invalid user' ?
                      <Button
                        color="default"
                        onClick={() => onSelectImage(item)}
                        className={styles['unvalid-button']}
                        disabled={item.id === selectedItemId}
                      >
                        Mark as Invalid <ButtonLoaderAtom active={item.id === selectedItemId} />
                      </Button> : null
                    }
                  </div>
                  :
                  <div>
                    {item.note && item.note.notes === 'Invalid user' ? <Button
                      color="primary"
                      type="button"
                      className={styles['valid-button']}
                      onClick={() => onSelectImage(item)}
                      disabled={item.id === selectedItemId}
                    >
                      Mark as Valid <ButtonLoaderAtom active={item.id === selectedItemId} />
                    </Button> : null }
                  </div>
                }
                {item.note.notes === 'Face not setup' ? 'Setup facial recognition from kiosk' : null}
              </td>
              {/*<td className="text-center align-middle"><div onClick={()=>toggleActivityModal(true,item.create_date_display.split(',')[0],item.user.employee_id,item.id)}><InfoGreyIconAtom  height='18px'/></div></td>*/}
              <td className="text-center align-middle"><div onClick={()=>toggleActivityModal(true,item)}><InfoGreyIconAtom  height='18px'/></div></td>
            
            </tr>);
          })}
          {!data.count &&
            <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
          }
        </tbody>
      </Table>
    );
  }
};

KioskEmployeesAuditTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

KioskEmployeesAuditTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default KioskEmployeesAuditTableComponent;

