import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_TASKS = 'jobs/task/GET_TASKS';
const GET_TASKS_FULFILLED = 'jobs/task/GET_TASKS_FULFILLED';
const GET_ALL_TASKS = 'jobs/task/GET_ALL_TASKS';
const GET_ALL_TASKS_FULFILLED = 'jobs/task/GET_ALL_TASKS_FULFILLED';
const GET_ASSIGNED_TASKS = 'jobs/task/GET_ASSIGNED_TASKS';
const GET_ASSIGNED_TASKS_FULFILLED = 'jobs/task/GET_ASSIGNED_TASKS_FULFILLED';
const GET_TASKS_OF_EMPLOYEE = 'jobs/task/GET_TASKS_OF_EMPLOYEE';
const GET_TASKS_OF_EMPLOYEE_FULFILLED = 'jobs/task/GET_TASKS_OF_EMPLOYEE_FULFILLED';
const GET_TASK = 'jobs/task/GET_TASK';
const GET_TASK_FULFILLED = 'jobs/task/GET_TASK_FULFILLED';
const SELECT_TASKS = 'jobs/task/SELECT_TASKS';
const POST_TASK = 'jobs/task/POST_TASK';
const POST_TASK_FULFILLED = 'jobs/task/POST_TASK_FULFILLED';
const PUT_TASK = 'jobs/task/PUT_TASK';
const PUT_TASK_FULFILLED = 'jobs/task/PUT_TASK_FULFILLED';
const DELETE_TASK = 'jobs/task/DELETE_TASK';
const RESET_TASK = 'jobs/task/RESET_TASK';
const GET_ASSIGNED_TASKS_AND_CODES = 'jobs/task/GET_ASSIGNED_TASKS_AND_CODES';
const GET_ASSIGNED_TASKS_AND_CODES_FULFILLED = 'jobs/task/GET_ASSIGNED_TASKS_AND_CODES_FULFILLED';
const GET_ASSIGNED_JOB = 'jobs/task/GET_ASSIGNED_JOB';
const GET_ASSIGNED_JOB_FULFILLED = 'jobs/task/GET_ASSIGNED_JOB_FULFILLED';
const EXPORT_TASKS = 'jobs/task/EXPORT_TASKS';
const POST_BULK_TASK = 'jobs/task/POST_BULK_TASK';
const POST_BULK_TASK_FULFILLED = 'jobs/task/POST_BULK_TASK_FULFILLED';
const GET_BULK_TASK = 'jobs/task/GET_BULK_TASK';
const GET_BULK_TASK_FULFILLED = 'jobs/task/GET_BULK_TASK_FULFILLED';

const GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES = 'jobs/task/GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES';
const GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES_FULFILLED = 'jobs/task/GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES_FULFILLED';
const GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES = 'jobs/task/GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES';
const GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES_FULFILLED = 'jobs/task/GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES_FULFILLED';
const GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES = 'jobs/task/GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES';
const GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES_FULFILLED = 'jobs/task/GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES_FULFILLED';

export function getTasks(params) {
  return {
    type: GET_TASKS,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}`, {params}),
  };
}

export function getAllTasks(params) {
  return {
    type: GET_ALL_TASKS,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}`, {params}),
  };
}

export function getQuotedRecurringAssignedTaskAndTaskCodes(params) {
  return {
    type: GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}v2/`, {params}),
  };
}

export function getQuotedProjectAssignedTaskAndTaskCodes(params) {
  return {
    type: GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}v2/`, {params}),
  };
}

export function getTimeAndMaterialtAssignedTaskAndTaskCodes(params) {
  return {
    type: GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}v2/`, {params}),
  };
}

export function getAssignedTaskAndTaskCodes(params) {
  return {
    type: GET_ASSIGNED_TASKS_AND_CODES,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}v2/`, {params}),
  };
}

export function exportTask(params) {
  return {
    type: EXPORT_TASKS,
    payload: axios.get(`${URLS.JOB}task/export-csv/`, {params}),
  };
}

export function getAssignedTasks(params) {
  return {
    type: GET_ASSIGNED_TASKS,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}`, {params}),
  };
}

export function getTasksOfEmployee(params) {
  return {
    type: GET_TASKS_OF_EMPLOYEE,
    payload: axios.get(URLS.TASKS_OF_EMPLOYEE, {params}),
  };
}

export function getTask(params) {
  return {
    type: GET_TASK,
    payload: axios.get(`${URLS.JOB}${URLS.TASK}${params.id}/`),
  };
}

export function postTask(data, params) {
  return {
    type: POST_TASK,
    payload: axios.post(`${URLS.JOB}${URLS.TASK}`, data, {params}),
  };
}

export function putTask(data) {
  return {
    type: PUT_TASK,
    payload: axios.put(`${URLS.JOB}${URLS.TASK}${data.id}/`, data),
  };
}

export function selectTasks(data) {
  return {
    type: SELECT_TASKS,
    payload: data,
  };
}
export function deleteTask(data) {
  return {
    type: DELETE_TASK,
    payload: axios.put(`${URLS.JOB_DELETE}${data.id}/`, data),
  };
}

export function resetTask(data) {
  return {
    type: RESET_TASK,
  };
}

export function assignedJob(params){
  return {
    type: GET_ASSIGNED_JOB,
    payload: axios.get(`${URLS.JOBS_OF_EMPLOYEE}`, {params}),
  };
}

export function postBulkTask(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: POST_BULK_TASK,
    payload: axios.post(URLS.TASK_BULK_UPLOAD, data, config),
  };
}

export function getBulkTask(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: GET_BULK_TASK,
    payload: axios.get(URLS.TASK_BULK_UPLOAD, config),
  };
}

const defaultState = {
  list: { results: [] },
  assignedList: { results: [] },
  assignedTaskAndCodeList: { results: [] },
  detail: {},
  all: [],
  assignedJob: {},
  quotedRecurringassignedTaskAndCodeList: { results: [] },
  quotedProjectassignedTaskAndCodeList: { results: [] },
  timeAndMaterialassignedTaskAndCodeList: { results: [] },
};

function GET_TASKS_FULFILLED_REDUCER(state, action) {
  let taskList = [];
  taskList = action.payload.results.length && action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    list: {...action.payload, results: taskList },
  });
}

function GET_ALL_TASKS_FULFILLED_REDUCER(state, action) {
  let taskList = [];
  taskList = action.payload.length && action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    all: { results: taskList },
  });
}

function GET_ASSIGNED_TASKS_FULFILLED_REDUCER(state, action) {
  let taskList = [];
  taskList = action.payload.results.length && action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    assignedList: {...action.payload, results: taskList },
  });
}

function GET_TASKS_OF_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function PUT_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_TASK_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function GET_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    assignedTaskAndCodeList: action.payload,
  });
}

function GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedProjectassignedTaskAndCodeList: action.payload,
  });
}

function GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedRecurringassignedTaskAndCodeList: action.payload,
  });
}

function GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    timeAndMaterialassignedTaskAndCodeList: action.payload,
  });
}

function GET_ASSIGNED_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    assignedJob: action.payload,
  });
}

function SELECT_TASKS_REDUCER(state, action) {
  const arrayList = [ ...state.list.results ];
  for (let i = 0; i < arrayList.length; i += 1) {
    if (arrayList[i].id === action.payload.id) {
      arrayList[i].isSelected = !arrayList[i].isSelected;
    }
  }
  return Object.assign({}, state, { list: {results: arrayList} });
}

function POST_BULK_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_BULK_TASK_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_TASKS_FULFILLED]: GET_TASKS_FULFILLED_REDUCER,
  [GET_ALL_TASKS_FULFILLED]: GET_ALL_TASKS_FULFILLED_REDUCER,
  [GET_TASK_FULFILLED]: GET_TASK_FULFILLED_REDUCER,
  [PUT_TASK_FULFILLED]: PUT_TASK_FULFILLED_REDUCER,
  [SELECT_TASKS]: SELECT_TASKS_REDUCER,
  [POST_TASK_FULFILLED]: POST_TASK_FULFILLED_REDUCER,
  [GET_TASKS_OF_EMPLOYEE_FULFILLED]: GET_TASKS_OF_EMPLOYEE_FULFILLED_REDUCER,
  [GET_ASSIGNED_TASKS_FULFILLED]: GET_ASSIGNED_TASKS_FULFILLED_REDUCER,
  [RESET_TASK]: RESET_TASK_REDUCER,
  [GET_ASSIGNED_TASKS_AND_CODES_FULFILLED] : GET_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER,
  [GET_ASSIGNED_JOB_FULFILLED] : GET_ASSIGNED_JOB_FULFILLED_REDUCER,
  [POST_BULK_TASK_FULFILLED]: POST_BULK_TASK_FULFILLED_REDUCER,
  [GET_BULK_TASK_FULFILLED]: GET_BULK_TASK_FULFILLED_REDUCER,
  [GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES_FULFILLED] : GET_QUOTED_RECURRING_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER,
  [GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES_FULFILLED] : GET_QUOTED_PROJECT_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER,
  [GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES_FULFILLED] : GET_TIME_AND_MATERIAL_ASSIGNED_TASKS_AND_CODES_FULFILLED_REDUCER,
};


const taskSelector = state => state.jobs.task;

export const taskList = createSelector(
  taskSelector,
  instance => instance.list,
);

export const taskAll = createSelector(
  taskSelector,
  instance => instance.all,
);

export const taskAssignedList = createSelector(
  taskSelector,
  instance => instance.assignedList,
);

export const taskSelectedList = createSelector(
  taskList,
  instance => instance.results && instance.results.length ? instance.results.filter(item => item.isSelected).map(item => item.id) : {},
);

export const taskDetail = createSelector(
  taskSelector,
  instance => instance.detail
)

export const TasksAndCodesList = createSelector(
  taskSelector,
  instance => instance.assignedTaskAndCodeList
)

export const quotedRecurringTasksAndCodesList = createSelector(
  taskSelector,
  instance => instance.quotedRecurringassignedTaskAndCodeList
)

export const quotedProjectTasksAndCodesList = createSelector(
  taskSelector,
  instance => instance.quotedProjectassignedTaskAndCodeList
)

export const timeAndMaterialTasksAndCodesList = createSelector(
  taskSelector,
  instance => instance.timeAndMaterialassignedTaskAndCodeList
)

export const AssignedJob = createSelector(
  taskSelector,
  instance => instance.assignedJob
  )

export default createReducer(defaultState, handlers);
