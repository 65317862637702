import React from "react";

import { toast } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import styles from "forms/components/styles.module.scss";
import DeleteIconAtom from "atoms/DeleteIcon";
import EditIconAtom from "../../atoms/EditIcon";

class OrderListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.checkTagsInString = this.checkTagsInString.bind(this);
  }
  checkTagsInString(str) {
    let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    return isHTML(str);
  }

  showToast(msg) {
    if (msg === "delete") {
      toast.error("Delete !", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  render() {
    const {
      location,
      templateData,
      handleEditQuestion,
      handleQuestionDelete,
      handleSort,
    } = this.props;
    return (
      <div className={`${styles["create-form-mid"]} pl-3 pr-1`}>
        <DragDropContext onDragEnd={handleSort}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ol
                {...provided.droppableProps}
                ref={provided?.innerRef}
                className="p-2"
              >
                {templateData?.questions?.map((item, index) => (
                  <Draggable key={item.sequence_of_configuration} draggableId={item.sequence_of_configuration} index={index}>
                    {(provided) => (
                      <li
                        className="d-flex justify-content-between"
                        style={{ gap: "10px" }}
                        ref={provided?.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={`${styles["circle-question-index"]}`}>
                          {index + 1}
                        </div>
                        <div className={`${styles["order-list-text"]}`}>
                        <div
                            style={
                              this.checkTagsInString(item.question)
                                ? {
                                    marginBottom: "-16px",
                                  }
                                : null
                            }
                          >
                            <span dangerouslySetInnerHTML={{ __html: item.question }} />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-between"
                          style={{ gap: "5px" }}
                        >
                          <EditIconAtom
                            className="cursor-pointer"
                            height="14px"
                            width="14px"
                            title="Edit Question"
                            onClick={() => {
                              handleEditQuestion(item);
                            }}
                          />
                          <DeleteIconAtom
                            className="cursor-pointer"
                            height="15px"
                            width="15px"
                            title="Delete Question"
                            onClick={() =>
                              handleQuestionDelete(item.sequence_of_configuration)
                            }
                          />
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ol>
            )}
          </Droppable>
        </DragDropContext>
        {location.pathname.indexOf("createassessmentform") > -1 && (
          <div className={`${styles["outcome-msg"]}`}>
            <strong className="outcome-msg-header">Outcome messages</strong>
            <div className={`${styles["result-text"]}`}>You have passed!</div>
          </div>
        )}
      </div>
    );
  }
}

export default OrderListComponent;
