import React, { Component } from "react";


import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { Formik, Field } from 'formik';
import { Label, Form, FormText, FormGroup, Input, Button } from 'reactstrap';


import FormikMultiSelectAtom from 'atoms/FormikMultiselectDropdown';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import { NOTIFICATION_TYPE } from "constants/forms";

export default class SendReminderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
        eventType: [],
    }
    this.handleNotificationSelect = this.handleNotificationSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleNotificationSelect(data){
    let events = data.map(item => item.id);
    this.setState({eventType: events})
  }
  
  onSubmit(values, {setSubmitting, setErrors}){
    const {eventType} = this.state;
    const {sendReminder} = this.props;
    if(eventType.length === 0){
        setErrors({notification_type: "Please select the notification type."})
        setSubmitting(false);
        return;
    }
    if (values.message.trim() === "") {
        setErrors({message: "Please enter the message."})
        setSubmitting(false);
        return;
    }
    sendReminder(eventType, values.message.trim());
  }

  render() {
    const { sendReminderFlag, sendReminderToggler, sendReminderLoader } = this.props;

    return (
      <Modal isOpen={sendReminderFlag} size="md">
        <ModalHeader toggle={sendReminderToggler}>
            Send Reminders
        </ModalHeader>
        <ModalBody>
        <Formik
          initialValues={{
            message: '',
          }}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            }) => 
           {
            return(
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label>Notification Type</Label>
                    <Field
                        component={FormikMultiSelectAtom}
                        name="notification_type"
                        id="notification_type"
                        items={NOTIFICATION_TYPE}
                        placeholder="Select Notification Type"
                        itemToString={i => i && i.name}
                        onChange={data => this.handleNotificationSelect(data)}
                        isDisableSelectAll={true}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.notification_type}</FormText></div>
                </FormGroup>

                <FormGroup>
                  <Label className="mb-2">Reminder Message</Label>
                  <Input
                    type="textarea"
                    placeholder="Type your message here"
                    name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    className={errors && errors.message ? "form-control-error" : null} 
                    rows={5}
                  />
                  <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.message}</FormText></div>
                </FormGroup>

                <Button
                className="float-right mt-2 "
                size="md"
                type="submit"
                color="primary"
                disabled={sendReminderLoader}
                >
                    Send <ButtonLoaderAtom active={sendReminderLoader} />
                </Button>
             </Form>
            )}}
          />
        </ModalBody>
      </Modal>
    );
  }
}
