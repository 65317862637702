import React from "react";

import PropTypes from "prop-types";
import StorageGateway from "lib/storage-gateway";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";

import Nav from "reactstrap/lib/Nav";
import cookie from "react-cookies";

import * as UserDucks from "ducks/accounts/user";
import * as PaymentDucks from "ducks/vendors/payment";

import NavComponent from "components/common/Nav";
import NavDropdownComponent from "components/common/NavDropdown";
import NavFooterComponent from "components/common/NavFooter";
import SideMenuNotificationComponent from "components/common/Notification";

import { NAV_DROPDOWN } from "constants/records";
import styles from "./styles.module.scss";
import logo from "./logo.png";
import BrandName from "./BrandName.png";

class SidenavSection extends React.Component {
  constructor(props) {
    super(props);
    this.backToMyDashboard = this.backToMyDashboard.bind(this);
    this.goTo = this.goTo.bind(this);
    this.logout = this.logout.bind(this);
    this.dropdownMenuResize = this.dropdownMenuResize.bind(this);
    this.pageClick = this.pageClick.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.state = {
      navItems: [],
      accessData: null,
    };
  }

  componentWillMount() {
    const {
      router: { history },
    } = this.context;
    // const { isSubCompany } = this.props;

    let selectedCompany = [];
    const {
      isBusinessAdmin,
      userCompany,
      userFullName,
      companyPermissions,
      userExtraData,
      customerDetail,
    } = this.props;
    const companyOwner =
      userExtraData &&
      userExtraData.company_permissions &&
      userExtraData.company_permissions.company_owner;
    // const isSubCompanyOwner = userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.sub_company_owner;
    if (companyOwner && customerDetail.commercial_status !== "No Billing") {
      selectedCompany = NAV_DROPDOWN.COMPANY.filter((item) => {
        if (
          item.manage_payroll ||
          item.manage_settings ||
          item.plugin_setting
        ) {
          if (
            item.manage_payroll === companyPermissions.manage_payroll ||
            item.manage_settings === companyPermissions.manage_settings ||
            item.plugin_setting === companyPermissions.plugin_setting ||
            (userExtraData &&
              item.pto_settings ===
                (companyPermissions.paid_time_off &&
                  (companyPermissions.company_owner ||
                    userExtraData.pto_supervisor)))
          ) {
            return true;
          }
          return false;
        }
        return true;
      });
    } else {
      selectedCompany = NAV_DROPDOWN.SUB_COMPANY.filter((item) => {
        if (
          item.manage_payroll ||
          item.manage_settings ||
          item.plugin_setting ||
          item.sub_company_owner
        ) {
          if (
            item.manage_payroll === companyPermissions.manage_payroll ||
            item.manage_settings === companyPermissions.manage_settings ||
            item.plugin_setting === companyPermissions.plugin_setting ||
            item.sub_company_owner === companyPermissions.sub_company_owner ||
            (userExtraData &&
              item.pto_settings ===
                (companyPermissions.paid_time_off &&
                  (companyPermissions.company_owner ||
                    userExtraData.pto_supervisor)))
          ) {
            return true;
          }
          return false;
        }
        return true;
      });
    }

    Promise.resolve(this.props.UserDucks.getAccessUser()).then((data) => {
      this.setState({ accessData: data?.value?.permissions });
    });

    // const companyId = StorageGateway.get('company_id');

    // when logged in from parent company
    /* if(companyId && isSubCompany){
      selectedCompany = selectedCompany.filter((i) => {
        if(i.label === 'Edit Company Profile'){
          return false;
        }
        return true;
      });
    }*/

    let obj;
    if (
      Object.keys(userExtraData).length &&
      userExtraData.is_plugin_activated
    ) {
      selectedCompany = selectedCompany.map((item) => {
        obj = { ...item };
        if (item.label === "Plugins") {
          obj.action = function() {
            return history.push("/vendor/plugin/");
          };
        }
        return obj;
      });
    }

    this.setState({
      navItems: [
        { label: userFullName, children: NAV_DROPDOWN.USER },
        isBusinessAdmin
          ? NAV_DROPDOWN.ADMIN[0]
          : { label: userCompany.name, children: selectedCompany },
        { label: "Logout", action: this.logout },
      ],
    });
  }

  backToMyDashboard() {
    const { isLoggedInAdmin, isSubCompany } = this.props;
    cookie.remove("survey_type", { path: "/" });
    cookie.remove("survey_type_dashboard", { path: "/" });

    if (isLoggedInAdmin && isSubCompany) {
      let domain = window.location.hostname;
      const host = window.location.hostname.split(".");
      if (host[0].indexOf("admin") >= 0) domain = `.${host[1]}.${host[2]}`;
      StorageGateway.set("company_id", isSubCompany);
      cookie.save("company_id", isSubCompany, { path: "/", domain });
    } else StorageGateway.remove("company_id", { path: "/" });
    cookie.remove("isBannerFlag", { path: "/" });
    setTimeout(() => {
      window.location.href = "/vendor/list/all";
    }, 100);
  }

  goTo(url) {
    const {
      router: { history },
    } = this.context;
    history.push(url);
  }

  logout() {
    const {
      router: { history },
    } = this.context;
    cookie.remove("survey_type", { path: "/" });
    cookie.remove("survey_type_dashboard", { path: "/" });
    return this.props.UserDucks.postLogoutUser().then(() => {
      this.props.UserDucks.resetState();
      history.push("/");
    });
  }

  dropdownMenuResize() {
    const { isBusinessAdmin, companyPermissions } = this.props;
    const tetherObj = { targetAttachment: "bottom right" };

    if (isBusinessAdmin) {
      tetherObj.offset = "200px 10px";
    } else if (
      companyPermissions.manage_payroll &&
      !companyPermissions.manage_settings &&
      !companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "228px 10px";
    } else if (
      companyPermissions.manage_payroll &&
      companyPermissions.manage_settings &&
      !companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "332px 10px";
    } else if (
      !companyPermissions.manage_payroll &&
      companyPermissions.manage_settings &&
      !companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "303px 10px";
    } else if (
      !companyPermissions.manage_payroll &&
      companyPermissions.manage_settings &&
      companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "332px 10px";
    } else if (
      !companyPermissions.manage_payroll &&
      !companyPermissions.manage_settings &&
      companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "228px 10px";
    } else if (
      companyPermissions.manage_payroll &&
      !companyPermissions.manage_settings &&
      companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "263px 10px";
    } else if (
      companyPermissions.manage_payroll &&
      companyPermissions.manage_settings &&
      companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "368px 10px";
    } else if (
      !companyPermissions.manage_payroll &&
      !companyPermissions.manage_settings &&
      !companyPermissions.plugin_setting
    ) {
      tetherObj.offset = "194px 10px";
    } else {
      tetherObj.offset = "375px 10px";
    }

    return tetherObj;
  }

  startTimer() {
    const {
      router: { history },
    } = this.context;
    const lastActivityTime = cookie.load("lastActivityTime");
    const currentTime = new Date();
    const currentDateString = currentTime.toString();
    let diff = currentTime - new Date(lastActivityTime);
    const oAuthToken = StorageGateway.get("oAuthToken");
    diff = diff / 1000;
    diff = diff / 60;
    if ((diff < 20 && !isNaN(diff)) || (!lastActivityTime && oAuthToken)) {
      cookie.save("lastActivityTime", currentDateString, { path: "/" });
    } else if (diff >= 20) {
      this.logout();
    }
  }

  pageClick(e) {
    this.startTimer();
  }

  componentDidMount() {
    this.startTimer();
    window.addEventListener("mousedown", this.pageClick, false);
    window.addEventListener("mouseup", this.pageClick, false);
    window.addEventListener("mousemove", this.pageClick, false);
    window.addEventListener("keypress", this.pageClick, false);
    window.addEventListener("touchmove", this.pageClick, false);
    window.addEventListener("onscroll", this.pageClick, false);
  }

  componentWillUnmount() {
    clearTimeout(this._timeOut);
    window.removeEventListener("mousedown", this.pageClick, false);
    window.removeEventListener("mouseup", this.pageClick, false);
    window.removeEventListener("mousemove", this.pageClick, false);
    window.removeEventListener("keypress", this.pageClick, false);
    window.removeEventListener("touchmove", this.pageClick, false);
    window.removeEventListener("onscroll", this.pageClick, false);
  }

  render() {
    const {
      accessSidemenu,
      isLoggedInAdmin,
      isSubCompany,
      userCompany,
      userTimezone,
      profile,
      isFinished,
      sidemenuCollapsed,
      customerDetail,
      isBusinessAdmin,
    } = this.props;
    const { navItems, accessData } = this.state;

    let newNavItems = navItems;

    if (accessData) {
      const isShowPaidTime = [
        "paid_time_off.policies",
        "paid_time_off.list",
        "paid_time_off.addEditPolicies",
      ].every((item) => accessData?.includes(item));

      if (!(isShowPaidTime && this.props?.userExtraData?.company_permissions?.paid_time_off) ) {
        newNavItems = newNavItems?.map((navItem) => {
          return {
            ...navItem,
            children: navItem?.children?.filter((child) => {
              if (child?.label === "Paid Time Off Setting") {
                return false;
              }
              return true;
            }),
          };
        });
      }
    }

    const accountSuspended =
      customerDetail && customerDetail.commercial_status === "Suspended";

    const isCancellationInProgress =
      customerDetail &&
      customerDetail.commercial_status === "Cancellation in progress";

    return (
      <section>
        {!sidemenuCollapsed ? (
          <div>
            <img
              src={logo}
              className={styles["logo-image"]}
              alt="Logo"
              width="100%"
            />
            <img
              src={BrandName}
              className=" pb-3 pr-4 pl-4 pt-2 "
              alt="Logo"
              width="100%"
            />
          </div>
        ) : null}
        <Nav vertical>
          <div
            className={
              isFinished || accountSuspended || isCancellationInProgress
                ? "side-nav-wrapper-onboard"
                : null
            }
          >
            <NavComponent
              nav={accessSidemenu}
              sidemenuCollapsed={sidemenuCollapsed}
              userExtraData={this.props.userExtraData}
              showFlap={this.props.showFlap}
            />
          </div>
          <div className={styles["sidenav-footer-alignment"]}>
            {newNavItems && newNavItems.length && (
              <NavDropdownComponent
                dropdownItems={newNavItems}
                tether={this.dropdownMenuResize()}
                title={profile.username}
                userCompany={userCompany}
                goTo={this.goTo}
                isFinished={isFinished}
                isSuspended={accountSuspended}
                isCancellationInProgress={isCancellationInProgress}
                sidemenuCollapsed={sidemenuCollapsed}
              />
            )}
            {
              <NavFooterComponent
                isLoggedInAdmin={isLoggedInAdmin}
                isSubCompany={isSubCompany}
                userTimezone={userTimezone}
                backToMyDashboard={this.backToMyDashboard}
                sidemenuCollapsed={sidemenuCollapsed}
                tether={this.dropdownMenuResize()}
                isBusinessAdmin={isBusinessAdmin}
              />
            }
          </div>
        </Nav>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  isLoggedInAdmin: UserDucks.isLoggedInAdmin(state),
  isSubCompany: UserDucks.isSubCompany(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
  companyPermissions: UserDucks.companyPermissions(state),
  userCompany: UserDucks.userCompany(state),
  userFullName: UserDucks.userFullName(state),
  userTimezone: UserDucks.userBATimezone(state),
  userExtraData: UserDucks.userExtraData(state),
  profile: UserDucks.profile(state),
  customerDetail: PaymentDucks.customerDetail(state),
});

const mapActionsToProps = (dispatch) => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

SidenavSection.propTypes = {
  isBusinessAdmin: PropTypes.bool,
  isLoggedInAdmin: PropTypes.bool,
  isSubCompany: PropTypes.number,
  accessSidemenu: PropTypes.array.isRequired,
  companyPermissions: PropTypes.object.isRequired,
  userCompany: PropTypes.object.isRequired,
  userFullName: PropTypes.string,
  userTimezone: PropTypes.string.isRequired,
  UserDucks: PropTypes.object.isRequired,
  profile: PropTypes.object,
};

SidenavSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapActionsToProps)(SidenavSection);
