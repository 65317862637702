import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';
/**
 * VendorSubCompanyUserFormPage -> VendorSubCompanyUserFormSection -> VendorSubCompanyUserFormComponent
 *
 * Props:
 *    - create
 */
class VendorSubCompanyUserFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.state = { inputType: 'password' };
  }

  submit(data) {
    const { create } = this.props;
    
    const errors = {};
    const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
    let isError = false;
    
    if(data){
      if (data.password && data.password.length < 8){
        isError = true;
        errors.password = 'Password should contain atleast 8 characters';
      }
      if (data.password && data.password.length >= 8 && data.password && (data.password.match(passwordRegex) || data.password.match(passwordSequential) || data.password.match(passwordRepeated ))) {
        isError = true;
        errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
      }
    }  
    
    if(isError){
      const errObject = {
        _error: errors.non_field_errors,
        ...errors,
      };
      throw new SubmissionError(errObject);
    }
    else{
      return create(data)
        .catch((err) => {
          const errObj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errObj);
        });
    }    
  }
  
  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  render(){
    const {
      error,
      submitting,
      handleSubmit,
      goTo,
    } = this.props;

    const { router: { route: { match: { params } } } } = this.context;
    
    return (
      <Form onSubmit={handleSubmit(this.submit)} noValidate autoComplete="off" className='pl-3'>
        <div className="error-text">{error}</div>
        <Row>
          <Col>
            <Field
              id="first_name"
              name="first_name"
              label="First Name"
              component={InputAtom}
              placeholder="First Name"
              layout={FORM_LAYOUT}
            />
            <Field
              id="last_name"
              name="last_name"
              label="Last Name"
              component={InputAtom}
              placeholder="Last Name (optional)"
              layout={FORM_LAYOUT}
            />
            <Field
              id="email"
              name="email"
              label="Email"
              component={InputAtom}
              placeholder="Email"
              layout={FORM_LAYOUT}
              data-lpignore={true}
              disabled ={true}
            />
          </Col>
          <Col>
            <Field
              id="username"
              name="username"
              label="Username"
              component={InputAtom}
              placeholder="Username"
              layout={FORM_LAYOUT}
              autofill="off"
              disabled = {true}
            />
            {/*<Field
              id="password"
              name="password"
              type={inputType}
              label="Password"
              component={InputAtom}
              placeholder="Password"
              layout={FORM_LAYOUT}
              autofill="off"
            />*/}
          </Col>
        </Row>
        <Row className="float-right">
          <Button
            type="submit"
            color="primary"
            size={FORM_LAYOUT.size}
            onClick={()=> goTo(`/vendor/sub-company/${params.id}/edit`)}
            className="float-sm-right my-4 mr-3"
          >
            Previous
          </Button>
          <Button
            type="submit"
            color="secondary"
            className="float-sm-right my-4 mr-3"
            size={FORM_LAYOUT.size}
          >
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </Row>
      </Form>
    );
  }  
}

const validate = (values) => {
  const errors = {};
  if (!values.first_name) errors.first_name = 'Enter First Name';
  if (!values.email) errors.email = 'Enter Email';
  if (!values.password) errors.password = 'Enter Password';
  if (!values.username) errors.username = 'Enter Username';

  // if (values.password && values.password.length < 8) errors.password = 'Password should contain atleast 8 characters';
  // if (values.password && values.password.length >= 8 && values.password && !values.password.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\\d$@$!%*#?&]{8,123}$')) errors.password = 'Password should have at least 8 characters, 1 capital alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
  return errors;
};

VendorSubCompanyUserFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  goTo: PropTypes.func.isRequired,
};

VendorSubCompanyUserFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
}; 

export default reduxForm({
  form: 'VendorSubCompanyUserForm',
  validate,
})(VendorSubCompanyUserFormComponent);
