import React from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import Link from "react-router-dom/Link";

import { toast } from "react-toastify";
import Table from "reactstrap/lib/Table";
import DeleteIcon from "components/jobs/jobGeofence/Delete.png";
import SortHeaderComponent from "components/common/SortHeader";
import SectionLoaderAtom from "atoms/SectionLoader";
import EditableCellAtom from "atoms/EditableCell";

import styles from "components/jobs/jobEmployeeEdit/styles.module.scss";
// import TimecardIcon from './timecards.svg';

/**
 * EmployeeListPage -> EmployeeListSection -> EmployeeTableComponent
 *
 * Props:
 *    - employee list
 */
const MealEmpTable = (props, context) => {
  const {
    router: {
      route: {
        location: { pathname, search },
      },
    },
  } = context;
  const {
    data,
    isEmployeeTableLoading,
    deleteEmployeeAssignment,
    deleteEmployee,
    setSelectedRow,
    is_active,
  } = props;

  const validateWage = (val) => {
    const wageRegex = /^[+]?([0-9]*(?:[\\.][0-9]*)?|\\.[0-9]+)$/;
    if (wageRegex.test(val)) {
      return true;
    } else {
      toast.error("Please enter a valid wage");
      return false;
    }
  };

  const updateTable = (data) => {
    setSelectedRow(data);
  };

  if (isEmployeeTableLoading) return <SectionLoaderAtom active />;
  return (
    <div
      style={{ boxShadow: "0px 3px 6px rgba(0,0,0,0.16)" }}
      className="ml-3 mr-4"
    >
      <Table
        responsive
        hover
        size="sm"
        className={`table-emp mt-0 ${styles["job-employee-table"]}`}
      >
        <thead>
          <tr>
            <th className="text-left pl-3">Name</th>
            <SortHeaderComponent ordering="title" className="text-left">
              Title
            </SortHeaderComponent>
            <th className="text-left">Tags</th>
            <th className="text-left">Username</th>
            {/* <th className="text-right">Wage</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item.id}>
                <td title={item.name} className="text-left pl-3">
                  {/* <Link to={{
                    pathname: `/employee/${item.id}/edit/detail`,
                    search: queryString.stringify({
                      parent: `${pathname}${search}`,
                    }),
                  }}> */}
                  {item.name}
                  {/* </Link> */}
                </td>
                <td title={item.title} className="text-left">
                  {item.title}
                </td>
                <td
                  title={
                    item.tags && item.tags.length
                      ? item.tags.map((item) => ` ${item}`)
                      : null
                  }
                  className="text-left"
                >
                  {item.tags && item.tags.length
                    ? item.tags.map((item) => `${item}  `)
                    : null}
                </td>
                <td title={item.username} className="text-left">
                  {item.username}
                </td>
                {/* <td className="text-right editable-job-wrapper editable-cell-wrapper">
                  <div
                    className="d-inline-block editable-text-right"
                    role="button"
                  >
                    <EditableCellAtom
                      value={item.wage}
                      valueKey="wage"
                      update={updateTable}
                      details={item}
                      validate={validateWage}
                      editable={true}
                      mileageValue
                      wageEditable
                    />
                  </div>
                </td> */}
                <td className="text-center cursor-pointer">
                  <span title="Delete Employee">
                    <img
                      src={DeleteIcon}
                      height="15"
                      width="12"
                      className="cursor-pointer"
                      alt=""
                      onClick={() => is_active && deleteEmployee(item)}
                    />
                  </span>
                </td>
              </tr>
            ))}
          {data?.length === 0 && (
            <tr>
              <td className="text-center" colSpan="10">
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

MealEmpTable.propTypes = {
  data: PropTypes.object.isRequired,
  updateSelected: PropTypes.func,
  location: PropTypes.object.isRequired,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
};

MealEmpTable.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default MealEmpTable;
