import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import InputAtom from 'atoms/Input';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import closeimage from './Close-Cross.png';

import { FORM_LAYOUT } from 'constants/layout';

import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';

/**
 * IncompleteSignUpListPage -> IncompleteSignUpListSection -> IncompleteSignUpResetPasswordFormModalComponent
 *
 * Props:
 *    - Reset Incomple SignUp Vendor Password
 */
class IncompleteSignUpResetPasswordFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password' , showStrengthCheck : false};
  }

  submit(data) {
    const { vendorId, resetPassword } = this.props;
    const detail = data;
    detail.user_id = vendorId;
    if(!detail.re_password) detail.re_password = data.password;
    return resetPassword(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  render() {
    const { handleSubmit , error, isOpen, toggle} = this.props;
    const { inputType } = this.state;
    return (
      <Modal isOpen={isOpen} toggle={toggle} className="form-modal" backdrop="static">
        <ModalHeader>Reset Password
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate className="ml-4 mr-4 pl-1 pr-2 pb-2">
          <ModalBody>
            <div className="error-text">{error}</div>
            <Field
              id="password"
              type={inputType}
              name="password"
              component={InputAtom}
              label="New Password"
              layout={FORM_LAYOUT}
              placeholder="Password"
              onBlur={(e) => { this.strengthCheckToggle()}}
              onFocus = {this.strengthCheckToggle}
            />
            {inputType === 'password'
              ? (<EyeOpenIconAtom
                className="input-icon"
                height="22" width="22"
                onClick={this.toggleInputType}
              />)
              : (<EyeCloseIconAtom
                className="input-icon"
                height="22" width="22"
                onClick={this.toggleInputType}
              />)
            }
            {this.state.showStrengthCheck &&
              <PasswordStrengthMeter
                values={{password : this.props.password}}
              />
            }

            {inputType === 'password'
              ? (<Field
                name="re_password"
                component={InputAtom}
                type="password"
                label="Confirm New Password"
                placeholder="Confirm New Password"
                layout={FORM_LAYOUT}
              />)
              : null
            }
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size} >
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.password) errors.password = 'Enter New Password';
  if (!values.re_password) errors.re_password = 'Enter Confirm New Password';
  if ((values.password !== values.re_password) &&
    (values.password && values.re_password)) {
    errors.re_password = 'Password mismatch';
  }
  return errors;
};

IncompleteSignUpResetPasswordFormModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  resetPassword: PropTypes.func.isRequired,
};

const IncompleteSignUpResetPasswordFormModal= reduxForm({
  form: 'IncompleteSignUpResetPasswordFormModal',
  validate,
})(IncompleteSignUpResetPasswordFormModalComponent);

const selector = formValueSelector('IncompleteSignUpResetPasswordFormModal');

export default connect(
  (state) => {
    const password = selector(state, 'password');
    return { password };
  },
)(IncompleteSignUpResetPasswordFormModal);
