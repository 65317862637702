import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';

/**
 * SystemSettingFormPage -> SystemSettingFormSection -> CalifornialRuleModal
 *
 * Props:
 *    - update
 */
const CalifornialRuleModal = (props) => {
  const {
    error,
    isOpen, submitting, handleSubmit, toggle, updateCaliforniaRuleMessage,
  } = props;

  const submit = (data) => (
    updateCaliforniaRuleMessage(data)
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="form-modal" backdrop="static" >
      <ModalHeader toggle={toggle}>California 7 Day Rule</ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <ModalBody className="pt-0">
          {error && <div className="error-text">{error}</div>}
          <Field
            id="cal_7_day_rule_message"
            type="textarea"
            name="cal_7_day_rule_message"
            label={<div>Confirmation message for the Employee
              <div className="f-4">
                (shown at Punch In, on the 7th Consecutive Day of Work Week )
              </div>
            </div>}
            component={InputAtom}
            placeholder="Message"
            layout={FORM_LAYOUT}
            rows="4"
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Ok <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.cal_7_day_rule_message) errors.cal_7_day_rule_message = 'Enter Message';
  if (values.cal_7_day_rule_message && !values.cal_7_day_rule_message.trim().length)
    errors.cal_7_day_rule_message = 'Enter Message';
  return errors;
};

CalifornialRuleModal.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  updateCaliforniaRuleMessage: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SystemSettingForm',
  validate,
})(CalifornialRuleModal);