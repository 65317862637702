import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import { Label } from 'reactstrap';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import DateTimePickerAtom from 'components/common/JobCenterDateTimePicker';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';
import { FORM_LAYOUT } from 'constants/layout';
import { WEEKDAYS_IN_SHORT } from 'constants/jobs';

import styles from './styles.module.scss';
/**
 * JobFormPage -> JobFormSection -> JobFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class JobFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      weekDays: WEEKDAYS_IN_SHORT,
      disable : false,
      createJob : false,
      nextPage: false,
    };
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.disable = this.disable.bind(this);
  }

  disable(val){
    const { change } = this.props;
    const { disable } = this.state;
    this.setState({disable : !disable});
    if(val.target.checked){
      change('end_date', null)
    }
    else{
      change('end_date',this.props.initialValues.end_date)
    }
  }

  handleSelect(val) {
    // if(val){
    //   change('end_date', null);
    // }
  }

  handleChange(data) {
    const { change } = this.props;
    if(data) {
      change('email', data.user ? data.user.email : null);
      change('phone', data.phone_ext ? `${data.phone_ext}${data.phone_no}` : null);
      change('contact', data.user && data.user.full_name ? data.user.full_name : null);
    }
  }

  submit(data) {
    const { createJob, nextPage } = this.state;

    if(createJob)
    {
      this.setState({
        submitLoader: true
      })
    }
    else if(nextPage) {
      this.setState({
        nextLoader: true
      })
    }
    //Remove jobcodeRegex as job code not accepting code as in edit job
    {/*const jobCodeRegex = /^[A-Za-z0-9-_@./+#&]*$/;
    if(data.code){
      if(!jobCodeRegex.test(data.code)){
        toast.error('Job code can only contain alphnumeric characters');
        this.setState({createJob: false, nextPage: false, nextLoader: false, submitLoader: false});
        return true;
      }
    }*/}
    const {  createOrUpdate } = this.props;
    const detail = { ...data, tags: data.tags && data.tags.length ? data.tags.map(i => i.name) : []};
    
    detail.customer = data.customer_name && data.customer_name.id;
    detail.code = data.code;

    if((!(moment(data.start_date).isValid())) || (!data.is_ongoing && !(moment(data.end_date).isValid()))){
      return toast.error('Enter a valid date');
    }
    detail.start_date = data.start_date && moment(data.start_date).format('YYYY-MM-DD');
    detail.end_date = data.end_date && moment(data.end_date).format('YYYY-MM-DD');

    return createOrUpdate(detail, createJob === true ? createJob : null)
      .then(() => {
        this.setState({createJob: false, nextPage: false, nextLoader: false, submitLoader: false});
      })
      .catch((err) => {
        this.setState({createJob: false, nextPage: false, nextLoader: false, submitLoader: false});
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const { handleSubmit,
      customerList, dateFormat, tagList,
      toggle, customerName, toggleTagModal } = this.props;
    const { disable, submitLoader, nextLoader } = this.state;

    const customer = customerList.length && customerList.map(item => (
      { ...item, name: item.customer_name }
    ));
    const tags = tagList.length && tagList[0].tag_collection && tagList[0].tag_collection.map(item => (
      { id: item , name: item }
    ));


    return (
      <Form onSubmit={handleSubmit(this.submit)} className="job-detail" inValidate>
        <Row>
          <Col md={3} className={!customerName ? 'remove-icon' : null}>
            <Field
              id="customer_name"
              name="customer_name"
              label="Customer"
              component={SelectAtom}
              options={customer}
              placeholder="Customer"
              labelKey="name"
              valueKey="id"
              layout={FORM_LAYOUT}
              onChange={(item) => this.handleChange(item)}
              className={styles.fixed_form_height}
            />
            <div
              tabIndex="0"
              role="button"
              onClick={() => toggle(null, 'add')}
              className="link-name mb-3"
            >
              + Add New Customer
            </div>
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="name"
              name="name"
              label="Job Name"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Job Name"
              maxLength="50"
              className={styles.fixed_form_height}
            />
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="code"
              name="code"
              label="Job Code"
              type="text"
              component={InputAtom}
              layout={FORM_LAYOUT}
              placeholder="Job Code"
              maxLength="50"
              className={styles.fixed_form_height}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Field
              id="start_date"
              name="start_date"
              label="Start Date"
              layout={FORM_LAYOUT}
              component={DateTimePickerAtom}
              placeholder="Start Date"
              options={{ dateFormat, }}
            />
          </Col>
          <Col md={3} className="offset-1">
            <Field
              id="end_date"
              name="end_date"
              label="End Date"
              layout={FORM_LAYOUT}
              component={DateTimePickerAtom}
              placeholder="Projected End"
              options={{ dateFormat, }}
              disabled = {disable}
            />
            <Field
              id="is_ongoing"
              name="is_ongoing"
              label="Ongoing"
              value="Checkbox"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
              onClick={(val) => this.disable(val)}
            />
          </Col>
        </Row>
        <Row>
          <Col md={7} className="override-dropdown-tags">
          <Label className="w-100 mb-0">Job tag<span className="" onClick={() => toggleTagModal(2)} tabIndex="0" role="button" className=" float-right link-name mb-2">Manage tags</span> </Label>
            <span>
              <Field
                component={MultiSelectDropdownAtom}
                id="tags"
                name="tags"
                placeholder="Select Tags"
                items={tags}
                itemToString={i => i && i.name}
                className="customised-arrow"
              />
            </span>
          </Col>
        </Row>
        {/*
          <div>
            <div>
              { customerName &&
                <Row className="mt-2">
                  <Col md={3} className="pr-0">
                    <div><b>Point of Contact: </b></div>
                  </Col>
                  <Col md={9} className="pl-3">
                    <div className="elipsis-job">
                      {customerName && customerName.user && customerName.user.full_name ? customerName.user.full_name : ''}
                    </div>
                  </Col>
                  <Col md={3} className="pr-0">
                    <div><b>Address: </b></div>
                  </Col>
                  <Col md={9} className="pl-3">
                    <div className="elipsis-job">
                      {customerName && customerName.address ? customerName.address: '' }
                    </div>
                  </Col>
                  <Col md={3} className="pr-0">
                    <div><b>Phone Number:</b></div>
                  </Col>
                  <Col md={9} className="pl-3">
                    <div className="elipsis-job">
                      {customerName && customerName.phone_no ? customerName.phone_no : '' }
                    </div>
                  </Col>
                  <Col md={3} className="pr-0">
                    <div><b>E-mail:</b></div>
                  </Col>
                  <Col md={9} className="pl-3">
                    <div className="elipsis-job">
                      {customerName && customerName.user  ? customerName.user.email : '' }
                    </div>
                  </Col>
                </Row>
              }
            </div>
          </div>
        */}
        <Row>
          <Col md={12} className="mt-2 mb-3 pr-0 text-right">
            <Button
              color="primary"
              className={`${styles['btn_accent_width']} btn-accent m-2`}
              type='submit'
              onClick = { () => this.setState({createJob : true})}
            >
              Submit <ButtonLoaderAtom active={submitLoader} />
            </Button>
            <Button
              color="primary"
              className={`${styles['btn_accent_width']} btn-accent m-2`}
              type='submit'
              onClick = { () => this.setState({nextPage : true})}
            >
              Next <ButtonLoaderAtom active={nextLoader} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}


const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Job Name';
  if (values.name && values.name.length > 50) errors.name = 'Job name cannot have more than 50 characters';
  if(values.code && values.code.length > 50) errors.code='Job Code cannot have more than 50 characters';
  if (!values.start_date) errors.start_date = 'Enter Start Date';
  if (values.end_date) {
    if (values.is_ongoing) {
      errors.end_date = 'On Going Job cannot have end date';
    }
  }
  if (!values.is_ongoing && !values.end_date) {
    errors.end_date = 'Enter End Date';
  }
  if(values.start_date) {
   if(moment(values.start_date).year()<1990) errors.start_date = 'Selected Year should be greater than 1990'
  }
  
  if(values.end_date){
  if(moment(values.end_date).year()<1990) errors.end_date = 'Selected Year should be greater than 1990'
}

  if(values.start_date && values.end_date){
    const formattedStartDate=moment(values.start_date).format();
    const formattedEndDate=moment(values.end_date).format();
    const currentDate = moment().format();
    if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
    if(formattedEndDate < currentDate) errors.end_date ='End date could not be less then current date.';
  }
  return errors;
};

JobFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tagList: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  customerList: PropTypes.array.isRequired,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  toggleTagModal: PropTypes.func,
};

JobFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: 'JobAddForm',
  validate,
  enableReinitialize: true,
})(JobFormComponent);

const selector = formValueSelector('JobAddForm');

export default connect(
  (state) => {
    const customerName = selector(state, 'customer_name');
    return { customerName };
  },
)(withReduxForm);
