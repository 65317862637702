import React from 'react';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import Form from 'reactstrap/lib/Form';

import FilterDropdownComponent from 'components/common/FilterDropComponent';
import { UncontrolledTooltip } from 'reactstrap';
import bindActionCreators from 'redux/lib/bindActionCreators';
import connect from 'react-redux/lib/connect/connect';
import AsyncMultiSelectDropdownAtom from 'atoms/AsyncMultiSelectDropdown';

import SelectAtom from 'atoms/Select';

class EmployeeListingFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {location, departmentAll, userExtraData, teamAll, employeeList} = this.props;
    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));

      if(!userExtraData.department){
        departmentOptions.splice(0, 0, { value: "", label: "All Departments" })
      }

    teamOptions = teamAll && teamAll.map(item => ({
        value: item.id, label: item.name,
      }));

      if (!userExtraData.team) {
        teamOptions.splice(0, 0, { value: "", label: "All Teams" })
      }
    }
    return (
        <section>
          <Form
          inline
          autocomplete="off"
          className="employee-list-filter-container"
        >
            {departmentAll.length > 0 &&
                <div id ="department" className="department-dropdown employee_listing_sidebar" style={{width: '100%'}}>
                    <FilterDropdownComponent
                        paramKey="employee_department_id"
                        options={departmentOptions}
                        onChange={(val) => this.props.onDepartmentChange(val)}
                        value={this.props.departmentId}
                    />
                    <UncontrolledTooltip placement="top" target="department">
                        Select Department
                    </UncontrolledTooltip>
                </div>
            }
            {departmentAll.length > 0 &&
                <div id ="team" className="department-dropdown employee_listing_sidebar mt-2" style={{width: '100%'}}>
                    <FilterDropdownComponent
                        paramKey="employee_team_id"
                        options={teamOptions}
                        onChange={(val) => this.props.onTeamChange(val)}
                        value={this.props.teamId}
                    />
                    <UncontrolledTooltip placement="top" target="team">
                        Select Team
                    </UncontrolledTooltip>
                </div>
            }
            <div className="custom-employee-filter pr-1 pt-1">
              <Field
                id="employees"
                name="employees"
                label="Employee Name"
                component={AsyncMultiSelectDropdownAtom}
                itemToString={(i) => i && i.user && i.user.full_name}
                items={employeeList}
                getData={this.props.getEmployees}
                placeholder="Type to search"
                getSelectedItems={(val)=>this.props.selectedEmployees(val)}
              />
            </div>
                
            <div className="mt-2 pt-1">
                <input
                    id="show_deviation"
                    type="checkbox"
                    name="show_deviation"
                    onChange={(e) => this.props.showDeviation(e.target.checked)}
                    className=" mr-1 align-middle"
                    checked={this.props.isShowEmployee}
                />
                <span className="align-middle font-colorInput">Show Deviations</span>
            </div>
            </Form>
        </section>
        
    );
  }
}

const mapStateToProps = state =>({
    location: RouteDucks.location(state),
    userExtraData: UserDucks.userExtraData(state),
})

const mapActionsToProps = dispatch =>({
    RouteDucks: bindActionCreators(RouteDucks, dispatch),
    UserDucks: bindActionCreators(UserDucks, dispatch)
})


const EmployeeListFilterForm = reduxForm({
  form: 'EmployeeListFilterForm',
  enableReinitialize: true,
})(EmployeeListingFilter);


export default connect(
  mapStateToProps,
  mapActionsToProps
)(EmployeeListFilterForm);