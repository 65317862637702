import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';

import FieldFormsPage from 'forms/pages/fieldForms';
import CreateFieldForm from 'forms/pages/createFieldForm';
import FormResponseList from 'forms/pages/formResponseList';

const FormRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url}/>
      <MatchWhenAuthorized
        path={`${match.url}/fieldforms`}
        name="forms.field"
        component={FieldFormsPage}
      />
      <MatchWhenAuthorized
        path={`${match.url}/:id/fieldform`}
        name="forms.createfieldform"
        component={CreateFieldForm}
      />
      <MatchWhenAuthorized
        path={`${match.url}/:id/responces`}
        name="forms.answerslist"
        component={FormResponseList}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

FormRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default FormRoutes;