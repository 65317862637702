import React from 'react';

import PropTypes from 'prop-types';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { toast } from 'react-toastify';

import styles from './styles.module.scss';

import reduxForm from 'redux-form/lib/reduxForm';


/**
 * DashboardPage -> DashboardListSection -> DashboardGridComponent
 *
 * Props:
 *    - dashboardList
 */


class DashboardOnboardGridComponent extends React.Component{
  

  constructor(props) {
    super(props);
    this.state={
     resendOtp : false,
    };
    this.resendLink = this.resendLink.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.toggle = this.toggle.bind(this);
    
  }

   toggle(){
    const { editpno } = this.state;
     this.setState({
      editpno :  !editpno,
    });
   }

  resendLink(){
    this.setState({
      resendOtp :  true,
    });
    const { getOtp } = this.props;
    return getOtp()
     .then(() => {
        this.setState({
          resendOtp :  false,
        })})
        .then(() => {
        toast.success("SMS succesfully sent")
      })
  }

   changePhone(values ){
    let detail = {};
      this.updateState('updatingPhone', true);
      detail.phone_ext = values.company.phone_ext ? values.company.phone_ext : '+1';
      detail.phone_no = values.phone_no;    

    this.props.usecase.updateCompanyProfile(detail)
      .then(() => {
        this.updateState('updatingEmail', false);
        this.updateState('updatingPhone', false);
      })
  }

  render(){
    const {
      toggle,
      toggleTracking,
      toggleVideoModal,
      toggleAddCardModal,
      tutorialDetail,
      extraData,
      updateTutorial,
      customerDetail,
      onboardDetail,
    } = this.props;
    const planType = onboardDetail && onboardDetail.plan && onboardDetail.plan.plan_type;
    const companyOwner = extraData && extraData.company_permissions && extraData.company_permissions.company_owner;
    const { resendOtp } = this.state;
    let  phoneNo  =  'your number';
    let phoneExt = '';
    if(Object.keys(tutorialDetail).length && tutorialDetail.employee && tutorialDetail.employee.phone_no){
      phoneNo = tutorialDetail.employee.phone_no; 
      phoneExt = tutorialDetail.employee.phone_ext;
    }
    return (
      <section className="pb-5">
        <Row className={styles['wrapper-db']}>
          <Col sm="12" md={4}>
            <Card block className="card-border ui-card">
              <Row>
                <Col sm="12">
                  <div className="text-center">
                    <div>
                      <span className="text-center steps-font">1</span>
                    </div>
                  </div>
                  <div>
                    <div className={`${styles["text-heading"]} text-center`}>
                     ADD YOURSELF AS AN EMPLOYEE
                    </div>
                    <p className={`${styles["sub-text"]} text-center`}>This module will give you a quick preview of employee
                      management and allows you to begin using the mobile app.</p>
                    <div className="text-center">
                    { !tutorialDetail.employee_management ? 
                      <Button className="btn-job-primary btn-width" onClick={() => toggleVideoModal()}>START</Button>
                      :
                        <Button className="btn-job-primary btn-width" onClick={() => toggleVideoModal()}>RESTART</Button>
                    }
                    </div>
                  </div>  
                </Col>              
              </Row>
            </Card>
          </Col>
          <Col sm="12" md={4} >
            <Card block className="card-border ui-card">
              <Row>
                <Col sm="12">
                  <div className="text-center">
                    <div>
                      <span className="text-center steps-font">2</span>
                    </div>
                  </div> 
                  <div> 
                    <div className={`${styles["text-heading"]} pr-2 text-center`}>
                      DOWNLOAD THE APP & AND CLOCK-IN
                    </div>                      
                    <p className={`${styles["sub-text"]} text-center`}>Use the link you received via SMS at {`${phoneExt}`} {`${phoneNo}`} to download the app and clock-in <br/> <br/>Didn't receive link? 
                      <span onClick={() => {this.resendLink()}} className="ml-2 link-name font-styles">Resend link</span></p>                                         

                    <div className="text-center">
                      { !tutorialDetail.app_download ?
                        <Button className="btn-job-primary btn-width"  onClick={() => toggle()} disabled={!tutorialDetail.employee_management }>START</Button>
                        :
                        <Button className="btn-job-primary btn-width"  onClick={() => toggle()}>RESTART</Button>
                      } 
                    </div>
                  </div>  
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm="12" md={4}>
            <Card block className="card-border ui-card">
              <Row>
                <Col sm="12">
                  <div className="text-center">
                    <div>
                      <span className="text-center steps-font">3</span>
                    </div>
                  </div> 
                  <div> 
                    <div className={`${styles["text-heading"]} text-center`}>VIEW TRACKING </div>
                    <p className={`${styles["sub-text"]} text-center`}>See your location in real time in an interactive map view. </p>
                    <div className="text-center">
                     { !tutorialDetail.view_tracking ?
                      <Button className="btn-job-primary btn-width"  onClick={() => toggleTracking()} disabled={!tutorialDetail.app_download }>START</Button>
                      :
                        <Button className="btn-job-primary btn-width"  onClick={() => toggleTracking()}>RESTART</Button>
                    }
                      
                    </div>
                  </div>  
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row className="text-center pb-4">
          <Col>
            <Button className="btn-job mt-3" onClick={()=> companyOwner && planType==='online' && !customerDetail.sources.length  && onboardDetail.comercial_status !== 'No Billing' ? toggleAddCardModal() : updateTutorial()}>{tutorialDetail.view_tracking ? "FINISHED" : "SKIP TUTORIAL"}</Button>  
          </Col>  
        </Row>
      </section>
    );
  }
}

DashboardOnboardGridComponent.propTypes = {
  location: PropTypes.object.isRequired,
};
export default reduxForm({
  form: 'resendForm',
})(DashboardOnboardGridComponent);
