import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';

import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import { FORM_LAYOUT } from 'constants/layout';

import SectionLoaderAtom from 'atoms/SectionLoader';

import styles from './styles.module.scss';

import cookie from 'react-cookies';
import CountryCodeAtom from 'atoms/CountryCode';
import closeimage from './CloseCross.png';

import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';


/**
 * EmployeeFormPage -> EmployeeFormSection -> EmployeeFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class EmployeeFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSectionVisibility = this.handleSectionVisibility.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state= {isEmployeeSectionOpen:true, isOrgInfoSectionOpen:false,
      isContactInfoSectionOpen:false, inputType: 'password' ,  showStrengthCheck : false}
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

   strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  handleSectionVisibility(value) {
    const  { isEmployeeSectionOpen, isOrgInfoSectionOpen, isContactInfoSectionOpen}= this.state;
    switch (value){
      case "isEmployeeSectionOpen" :
        this.setState({ isEmployeeSectionOpen: !isEmployeeSectionOpen });
        break;
      case "isOrgInfoSectionOpen" :
        this.setState({ isOrgInfoSectionOpen: !isOrgInfoSectionOpen });
        break;
      case "isContactInfoSectionOpen" :
        this.setState({ isContactInfoSectionOpen: !isContactInfoSectionOpen });
        break;
      default:
        break;
    }
  }

  submit(data) {
    const { createOrUpdate } = this.props;
    const errors = { user: {} };
    let isError = false;
    
    if(isError){
      const errObject = {
        _error: errors.non_field_errors,
        ...errors,
      };

      this.setState({ isSaving:false,isSavingAndAddingNew:false });
      throw new SubmissionError(errObject);
    }
    else{
      const user = {
        first_name: data.first_name,
        last_name: data.last_name,
        password : data.password,
      };
      const detail = { ...data, wage: data.wage ? data.wage : null , user };
      return createOrUpdate(detail,"isFinished")
        .then((response) => {
          this.setState({ isContactInfoSectionOpen: false,
            isOrgInfoSectionOpen: false,
            isSaving:false,isSavingAndAddingNew:false,
            inputType: 'password' });
          cookie.save('data',data.phone_no);
        })
        .catch((err) => {
          const errObj = {
            _error: err.non_field_errors,
            ...err,
          };
          this.setState({ isSaving:false,isSavingAndAddingNew:false });
          throw new SubmissionError(errObj);
        });
    }
  };


  render() {
    const {
      error,
      handleSubmit,
      roleAll,
      submitting,
      companyPermissions,
      toggle,
      isOpen,
      EmployeeRole,
      systemSettingList,
      employeeUser,
      onClose,
      isModalLoading,
      employeeName,
      tutorialDetail
    } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const { inputType } = this.state;
    const roleSelected = roleAll.find((i) => i.id === ((EmployeeRole && EmployeeRole.value) || EmployeeRole));
    const passwordFieldVisible = systemSettingList.results &&
      systemSettingList.results.find((i) => i.key.key_code === 'MANDATORY_PHONE_NUMBER_ON_EMPLOYEE_PROFILE' );
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal emp-dashboard-modal"
        backdrop='static'
        onExit={() => onClose()}
      >
        <ModalHeader className={styles['modal-heading']}>{employeeUser ? `Employee: ${employeeName}` : 'Add Employee'}
         <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form>
          <ModalBody className={employeeUser ? `${styles['view-general-modal']} pb-0` : `${styles["general-modal"]} pb-0`}>
            <div className="error-text">{error}</div>
            {isModalLoading ? <SectionLoaderAtom active />
              :(!employeeUser ?
                <div>
                  <Row className="ml-3 mr-3">
                    <Col md={12}>
                      <div className="pb-2">
                      </div>
                      <Row className="mb-3">
                        <Col>
                          <Field
                            id="first_name"
                            name="first_name"
                            label="First Name*"
                            component={InputAtom}
                            layout={FORM_LAYOUT}
                            placeholder="First Name"
                          />
                        </Col>
                        <Col>
                          <Field
                            id="last_name"
                            name="last_name"
                            label="Last Name"
                            component={InputAtom}
                            layout={FORM_LAYOUT}
                            placeholder="Last Name (optional)"
                          />
                        </Col>
                      </Row>
                      <Row>                                  
                      </Row> 
                       <Row className="mb-3">
                        <Col>
                        <div className="col d-flex justify-content-center mb-1 pl-0">                   
                          <span className="mr-2">
                           <Field
                              name="phone_ext"
                              label="Code"
                              component={CountryCodeAtom}
                            />
                          </span>                     
                          <Field
                            id="phone_no"
                            name="phone_no"
                            component={InputAtom}
                            placeholder="Phone Number"
                            label="Phone Number"
                            layout={FORM_LAYOUT}
                          />
                       </div>
                        </Col>
                        <Col md={6}>
                          <Field
                            id="email"
                            name="email"
                            label="Email"
                            component={InputAtom}
                            layout={FORM_LAYOUT}
                            placeholder="example@example.com (optional)"
                            data-lpignore={true}

                          />
                        </Col>

                      </Row>
                      <Row className="mb-3">  
                        <Col md={6}>
                          <Field
                            id="employee_role"
                            name="employee_role"
                            label="User Role*"
                            component={SelectAtom}
                            placeholder="User Role"
                            options={roleAll}
                            labelKey="name"
                            valueKey="id"
                            layout={FORM_LAYOUT}
                            disabled={{}.hasOwnProperty.call(params, 'id') && companyPermissions.manage_settings === false}
                            simpleValue
                          />
                        </Col> 
                         <Col md={6}>
                        </Col>
                      </Row>
                      {((roleSelected && roleSelected.admin_access) || passwordFieldVisible.value === 'False') && !employeeUser &&
                          <Row className="mb-3">
                           <Col md={6}>                        
                              <div className="pos-relative">
                                <Field
                                  type={inputType}
                                  id="password"
                                  name="password"
                                  label="Password"
                                  component={InputAtom}
                                  layout={FORM_LAYOUT}
                                  placeholder="Password"
                                  data-lpignore={true}
                                  onBlur={(e) => { this.strengthCheckToggle()}}
                                  onFocus = {this.strengthCheckToggle}
                                />
                                  {inputType === 'password'
                                    ? (<EyeOpenIconAtom
                                      className="password-icon"
                                      height="22" width="22"
                                      onClick={this.toggleInputType}
                                    />)
                                    : (<EyeCloseIconAtom
                                      className="password-icon"
                                      height="22" width="22"
                                      onClick={this.toggleInputType}
                                  />)
                                } 
                                {this.state.showStrengthCheck &&
                                <PasswordStrengthMeter
                                  values={{password : this.props.Password}}
                                /> 
                              }
                              </div>                                                  
                          </Col>
                          <Col md={6}>                        
                           { inputType === 'password' && <Field
                              id="confirm_password"
                              name="confirm_password"
                              label="Confirm Password"
                              type="password"
                              component={InputAtom}
                              layout={FORM_LAYOUT}
                              placeholder="Confirm Password"
                              data-lpignore={true}
                          />}
                          </Col>                      
                        </Row>}
                      </Col>
                    </Row>            
                    <Row>                  
                      <Col md={6}>                       
                      </Col>
                    </Row>
                  </div>
                  :
                   (tutorialDetail && tutorialDetail.employee && !tutorialDetail.employee.phone_no &&  
                    <Row className=" d-block ml-4 pl-1 mr-4">
                      <div className="d-flex">
                        <div className='mt-2 mr-1'>Your username is :</div>
                          <div className={`${styles['username-div']} p-1`}> <b>{`${employeeUser}`}</b></div>  
                      </div>
                      <div className="mt-4" style={{ fontSize: '11px' , color: '#707070' }}>
                          **Please note down your username for future reference.
                      </div>
                    </Row>)
                )
              }
          </ModalBody>
          <ModalFooter className="py-0">
            {!employeeUser &&
              <Button
                type="submit"
                onClick={handleSubmit(values =>
                  this.submit({
                    ...values,
                    type: 'isFinished'
                  }))}
                className={`${styles['form-button']} ml-3 mr-4 mt-3 float-right mb-3`}
                size={FORM_LAYOUT.size}
              >
                Save and Finish  <ButtonLoaderAtom active={submitting} />
              </Button>
            } 
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = { user: {} };
  if (!values.first_name) errors.first_name = 'Enter First Name';  
  if (!values.password) errors.password = 'Enter Password';

  if (!values.confirm_password) errors.confirm_password = 'Enter Confirm Password';

  if (values.confirm_password !== values.password) {
    errors.confirm_password = 'Password does not match';
  }
  if (!values.company_id) errors.company_id = 'Select Company';
  if (!values.employee_role) errors.employee_role = 'Enter User Role';
  return errors;
};

EmployeeFormModalComponent.propTypes = {
  change: PropTypes.func.isRequired,
  createOrUpdate: PropTypes.func.isRequired,
  departmentAll: PropTypes.array.isRequired,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  onDepartmentSelect: PropTypes.func.isRequired,
  roleAll: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  teamAll: PropTypes.array.isRequired,
  isSubCompany: PropTypes.number,
  isSavingAndAddingNew: PropTypes.bool,
};

EmployeeFormModalComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: 'EmployeeFormModal',
  validate,
})(EmployeeFormModalComponent);

const selector = formValueSelector('EmployeeFormModal');

export default connect(
  (state) => {
    const EmployeeRole = selector(state, 'employee_role');
    const Password = selector(state, 'password');
    return { EmployeeRole, Password };
  },
)(withReduxForm);
