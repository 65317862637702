import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  INFO_LIST: 'INFO_LIST',
  CARD_DETAILS: 'CARD_DETAILS',
  SHOW_LOADER: 'SHOW_LOADER',
  GET_CARD_DETAILS: 'GET_CARD_DETAILS',
  SAVE_CARD_SUCCESS: 'SAVE_CARD_SUCCESS',
  DELETE_CARD_DETAILS: 'DELETE_CARD_DETAILS',
  CARD_DEFAULT_SUCCESS: 'CARD_DEFAULT_SUCCESS',
  CANCEL_PLAN_SUCCESS: 'CANCEL_PLAN_SUCCESS',
  GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS'
};

class paymentMethodUseCase {
  paymentMethodApiGateway = null;

  constructor(paymentMethodApiGateway) {
    this.paymentMethodApiGateway = paymentMethodApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'paymentMethodUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  async getInfoList(){
    this.infoList = await this.paymentMethodApiGateway.getInfoList({ route: 'vendor.payment-method'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async saveCard(stripe, companyId, email, name, phoneNumber){
    let response  = await stripe.createSource({
      type: 'card',
    });
    await this.paymentMethodApiGateway.saveCard(
      companyId,
      response.source.id,
      email, name, phoneNumber
    );

    this.eventEmitter.emit({
      type: EVENT_TYPES.SAVE_CARD_SUCCESS,
    });
  }

  async getCardDetails(companyId){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.detail = await this.paymentMethodApiGateway.getCardDetails(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CARD_DETAILS,
      detail: this.detail,
    });
  }

  async setDefault(companyId, sourceId, email, name, phoneNo){
    await this.paymentMethodApiGateway.saveCard(
      companyId,
      sourceId,
      email, name, phoneNo);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_CARD_DETAILS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.CARD_DEFAULT_SUCCESS,
    });
  }

  async deleteCard(companyId, sourceId){
    await this.paymentMethodApiGateway.deleteCard(companyId, sourceId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.DELETE_CARD_DETAILS,
    });
  }

  async cancelPlan(email, password, companyId){
    await this.paymentMethodApiGateway.authenticatePassword(email, password, 'web');
    let customerDetail = await this.paymentMethodApiGateway.cancelCompanyPlan(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CANCEL_PLAN_SUCCESS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_CUSTOMER_DETAIL_SUCCESS,
      detail: customerDetail,
    });
  }


}

export default paymentMethodUseCase;
