import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Table, Button, Form, FormGroup, FormText, Label, Input } from 'reactstrap';
import { Formik} from 'formik';
import queryString from 'query-string';
import { UncontrolledTooltip } from 'reactstrap';

import SectionLoaderAtom from 'atoms/SectionLoader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import logo from 'sections/common/Sidenav/logo.png';

import styles from './styles.module.scss';
import downloadLogo from './download.png';
import logoname from './Logoname.png';


class InvoiceDetailFormComponent extends React.PureComponent{
  
  constructor(props){
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values, { setSubmitting, setErrors }){
    const  { usecase, invoiceId }  = this.props;
    try {
      await usecase.execute(values, invoiceId);
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  render(){   
    const query = queryString.parse(this.props.search);
    const bAdmin = query.badmin;
    const { isLoading, invoiceDetails, downloadInvoice} = this.props;
    const extraData = invoiceDetails && invoiceDetails.extra_data && invoiceDetails.extra_data.bill_to_address;
    const billingCycle = invoiceDetails && invoiceDetails.billing_cycle;
    const customerData = invoiceDetails.customer;
    const planDetail = customerData && customerData.plan;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <Row className='w-100 text-center'>
        <div className={`${styles['logodiv']}  `}>
        <img src={logo} height="94px" width="130px" alt="Logo" /><br />
        <img src={logoname} height="20px" width="130px" alt="Logo"/>
        </div>
        </Row>
        <p className={`${styles['address']}  pt-3 text-center`}>800 Quintana Rd., MorroBay, CA 93442, United States</p>
         <p className={`${styles['address']}  pb-1 text-center`}>Email : info@worksana.com</p>
          <Row className='mt-3'>
            <Col md={6}>
              <Col md={6} className={`${styles['mailing_address']}pl-0 pt-1 `}>
              <div className={`${styles['mailing_client_name']}`}>{extraData && extraData.name}</div>
                <div className={`${styles['mailing_address']}`}>{extraData && extraData.address_line1}</div>
                <div className={`${styles['mailing_address']}`}>{extraData && extraData.address_line2 ? extraData.address_line2 : null}</div>
                <div className={`${styles['mailing_address']}`}>
                  {extraData && extraData.city }{extraData && extraData.state ? '  ,  ' : null}{extraData && extraData.state}{extraData && extraData.postal_code ? '  -  ' : null}{extraData && extraData.postal_code }
                </div>
                <div className={`${styles['mailing_address']}`}>
                  {extraData && extraData.country}
                </div>
                <div className={`${styles['mailing_address']}`}><b>Email</b> : {invoiceDetails && invoiceDetails.extra_data && invoiceDetails.extra_data.email}</div>
              </Col>
            </Col>
            <Col md={6} className="float-right ">
            <Row>
              <Col md={6}>
              </Col>
              <Col md={6} className="mt-3">
              <Row>
                <Col className={`${styles['mailing_address']} text-right pr-0`} style={{height: '30px'}} >
                  <p><b>Invoice #:</b></p>
                </Col>
                <Col className={`${styles['mailing_address']} text-left `} style={{height: '30px'}}>
                  {invoiceDetails && invoiceDetails.invoice_number}
                </Col>
              </Row>
              <Row>
                <Col className={`${styles['mailing_address']} text-right pr-0`} style={{height: '30px'}} >
                  <p><b>Billed On:</b></p>
                </Col>
                <Col className={`${styles['mailing_address']} text-left`} style={{height: '30px'}} >
                  {invoiceDetails && invoiceDetails.create_date_display}
                </Col>
              </Row>
              <Row>
                <Col className={`${styles['mailing_address']} text-right pr-0`} style={{height: '30px'}}>
                  <p><b>Due On:</b></p>
                </Col>
                <Col className={`${styles['mailing_address']} text-left `} style={{height: '30px'}}>
                  {billingCycle && billingCycle.payment_due_date}
                </Col>
              </Row>
              <Row>
                <Col className={`${styles['mailing_address']} text-right pr-0`} style={{height: '30px'}}>
                  <p><b>Payment Mode:</b></p>
                </Col>
                <Col className={`${styles['mailing_address']} text-left`} style={{height: '30px'}}>
                {planDetail && planDetail.plan_type==='online' ?
                  <p>Credit Card</p>
                  :
                  <p>Check</p>
                }
                </Col>
              </Row>
              </Col>
              </Row>
            </Col>
            <Col>
            <Table responsive hover size="sm" className="table-invoice" >
                <thead className="Header-col">
                  <tr>
                    <th className="text-left pl-3">Date</th>
                    <th className="text-left">Plan</th>
                    <th className="text-right">Per user/month</th>
                    <th className="text-right">Billed Users</th>
                    <th className="text-right pr-3">Amount</th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td className="text-left pl-3" title="Date">{invoiceDetails && invoiceDetails.create_date_display}</td>
                      <td  className="text-left" title={billingCycle && billingCycle.amount === 4 ? 'Standard plan' : 'Custom plan'}>
                        {billingCycle && billingCycle.charge_rate === 4 ? 'Standard plan' : 'Custom plan'}
                      </td>
                      <td className="text-right" title={billingCycle && billingCycle.total_amount}>
                        ${billingCycle && billingCycle.charge_rate}
                      </td>
                      <td className="text-right" title={billingCycle && billingCycle.quantity}>
                        {billingCycle && billingCycle.quantity}
                      </td>
                      <td className="text-right pr-3" title={billingCycle && billingCycle.total}>
                        ${billingCycle && billingCycle.total}
                      </td>
                    </tr>
                  </tbody>
              </Table>
              </Col> 
              <Col md={9}>
                {bAdmin && planDetail && planDetail.plan_type === 'offline' &&
                  <Formik
                    initialValues={this.props.initialValues}
                    onSubmit={this.onSubmit}
                    render={({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      }) => 
                     {
                    return(
                      <Form onSubmit={handleSubmit}>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.non_field_errors}</FormText></div>
                        <FormGroup className="ml-4">   
                          <Input
                            type="checkbox"
                            id="payment_received"
                            onChange={handleChange}
                            className={errors && errors.status ? "form-control-error" : null}
                            checked={values.payment_received}   
                          />
                          <Label for="payment_received" name="payment_received" className="mr-5">Payment Received</Label>
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.status}</FormText></div>
                        </FormGroup>
                        <Button
                          className= "ml-1 mr-1"
                          color='primary'
                          type='submit'
                        >
                          Submit <ButtonLoaderAtom active = {isSubmitting} />
                        </Button>
                      </Form>
                      );}}
                    />}
              </Col>
              <Col md={3} className="float-right pt-2">
                <Row>
                  <Col md={7} className={`${styles['mailing_address']} text-right `} style={{height: '30px'}} >
                    <p>Subtotal:</p>
                  </Col>
                  <Col md={5} className={`${styles['mailing_address']} text-left pl-0`} style={{height: '30px'}}>
                    <p className={`${styles['text_overflow']}`} id="total">
                      <UncontrolledTooltip placement="left" target="total">
                        ${billingCycle && billingCycle.total}
                      </UncontrolledTooltip>
                      ${billingCycle && billingCycle.total}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={7} className="text-right" style={{height: '30px'}}>
                    <p>Discount:</p>
                  </Col>
                  <Col md={5} className="text-left pl-0" style={{height: '30px'}}>
                    <p className={`${styles['text_overflow']}`} id="discount">
                      <UncontrolledTooltip placement="left" target="discount">
                        {billingCycle && billingCycle.discount_unit === 'Amount' ? '$' : null}
                        {billingCycle &&  billingCycle.discount}{billingCycle && billingCycle.discount_unit === 'Amount' ? null : '%'}
                      </UncontrolledTooltip>
                      {billingCycle && billingCycle.discount_unit === 'Amount' ? '$' : null}
                      {billingCycle &&  billingCycle.discount}{billingCycle && billingCycle.discount_unit === 'Amount' ? null : '%'}
                    </p>
                  </Col>
                  <Col md={7} className= "text-right mb-1" style={{height: '30px'}} >
                    <p>Monthly Service Fee:</p>
                  </Col>
                  <Col md={5} className="text-left pl-0 mb-1" style={{height: '30px'}}>
                    <p className={`${styles['text_overflow']}`} id="service_fees">
                      <UncontrolledTooltip placement="left" target="service_fees">
                        ${billingCycle &&  billingCycle.service_fees}
                      </UncontrolledTooltip>
                      ${billingCycle &&  billingCycle.service_fees}
                    </p>
                  </Col>
                  <Col md={7} className="text-right" style={{height: '30px'}}>
                    <b>Total:</b>
                  </Col>
                  <Col md={5} style={{height: '30px'}} className={`${styles['text_overflow']} text-left pl-0 `} id="total_amount">
                     <UncontrolledTooltip placement="left" target="total_amount">
                        ${billingCycle &&  billingCycle.total_amount}
                      </UncontrolledTooltip>
                    <b>${billingCycle &&  billingCycle.total_amount}</b>
                  </Col>
                  <Col md={7} className="text-right" style={{height: '30px'}}>
                  <span onClick={() => downloadInvoice()} className = "cursor-pointer">
                    <img src={downloadLogo} height="15px" width="15px" alt="Logo"/> 
                    </span>                   
                  </Col>
                  <Col className="text-left pl-0" style={{height: '30px'}} >
                    <b><i>Download</i></b>
                  </Col>
                </Row>
              </Col>
          </Row>
          <Row className='w-100 text-center'>
          <div className={`${styles['logodiv']}  `} style={{fontSize : '20px'}}>
        <b>THANK YOU FOR YOUR BUSINESS!</b>
        </div>
        </Row>
      </div>
    );
  }
}

export default InvoiceDetailFormComponent;