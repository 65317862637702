import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as RouteDucks from 'ducks/routes';
import * as KnowledgeBaseDucks from 'ducks/knowledgeBases/knowledgeBase';

import SectionLoaderAtom from 'atoms/SectionLoader';
import KnowledgeBaseListTableComponent from 'components/knowledgeBases/list/Table';
import PaginationComponent from 'components/common/Pagination';
import ModalComponent from 'components/knowledgeBases/ViewModal';
/**

 * KnowledgeBaseListPage -> KnowledgeBaseListSection
 *
 * Components:
 *    - {@link KnowledgeBaseListTableComponent}
 *
 * State:
 *    - knowledge base list
 *
 * Actions:
 *    None
 */
class KnowledgeBaseListSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { isModalOpen: false, videoOrImage: null };
  }

  toggle(item){
    this.setState(({ isModalOpen, videoOrImage }) => ({ isModalOpen: !isModalOpen,  videoOrImage: item }));
  }


  render() {
    const { isLoading, knowledgeBaseList, location } = this.props;
    const { isModalOpen, videoOrImage } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <KnowledgeBaseListTableComponent
          location={location}
          data={knowledgeBaseList}
          toggle={this.toggle}
        />
        <ModalComponent
          isModalOpen={isModalOpen}
          toggle={this.toggle}
          videoOrImage={videoOrImage}
        />
        <PaginationComponent
          count={knowledgeBaseList.count}
          location={location}
          cookieKey="knowledge_base_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  knowledgeBaseList: KnowledgeBaseDucks.knowledgeBaseList(state),
});

const mapActionsToProps = dispatch => ({
  //  nowledgeBaseDucks: bindActionCreators(KnowledgeBaseDucks, dispatch),
});

KnowledgeBaseListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  knowledgeBaseList: PropTypes.object.isRequired,
};

KnowledgeBaseListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KnowledgeBaseListSection);