
export const NAV = {
  PayrollListPage: {
    title: 'Payrolls',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'List' },
    // ],
    parent: '/payroll/setting/list',
    description: 'View all your Payrolls',
  },
  PayrollAddFormPage: {
    title: 'Add Payroll Schedule',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Add' },
    // ],
    parent: '/payroll',
    description: 'Create new Payroll Schedule',
  },
  PayrollDetailFormPage: {
    title: 'Payroll Detail',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Detail' },
    // ],
    parent: '/payroll',
    description: 'View or delete Payroll',
  },
  PayrollSettingListPage: {
    title: 'Payroll Settings',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Settings' },
    // ],
    description: 'Manage your Payroll settings',
    parent: '/dashboard',
  },
  PayrollSettingAddFormPage: {
    title: 'Add Payroll Setting',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'New' },
    // ],
    parent: '/payroll/setting/list',
    description: 'Add new Payroll Setting',
  },
  PayrollSettingEditFormPage: {
    title: 'Edit Payroll Setting',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Edit' },
    // ],
    parent: '/payroll/setting/list',
    description: 'Edit Payroll Setting',
  },
};

export const DAYS = [
  { value: '0', label: 'Monday' },
  { value: '1', label: 'Tuesday' },
  { value: '2', label: 'Wednesday' },
  { value: '3', label: 'Thursday' },
  { value: '4', label: 'Friday' },
  { value: '5', label: 'Saturday' },
  { value: '6', label: 'Sunday' },
];

export const DAYS_OF_WEEK = [
  'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
];

export const PAYROLL_CYCLES = [
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'EVERY_TWO_WEEKS', label: 'Every Two Weeks' },
  { value: 'TWICE_A_MONTH', label: 'Twice monthly' },
  { value: 'MONTHLY', label: 'Monthly' },
];

export const PAYROLL_CYCLE_OBJ = {
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  TWICE_A_MONTH: 'TWICE_A_MONTH',
};

export const PAYROLL_FREQUENCIES = [
  { value: 1, label: 'One' },
  { value: 2, label: 'Two' },
  { value: 3, label: 'Three' },
  { value: 4, label: 'Four' },
];

export const PAYROLL_FREQUENCIES_OBJ = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
};

export const FIRST_HALF_MONTH_DATES = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
];

export const SECOND_HALF_MONTH_DATES = [
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
  { value: 29, label: 29 },
  { value: 30, label: 30 },
  { value: 31, label: 'End of the Month' },
];

export const SHIFT_SPLIT_OPTIONS = [
  { label: 'Date of Punch In', value: false},
  { label: 'Custom Work Day', value: true },
];

export default {
  NAV,
  DAYS,
  DAYS_OF_WEEK,
  PAYROLL_CYCLES,
  PAYROLL_FREQUENCIES,
  PAYROLL_CYCLE_OBJ,
  FIRST_HALF_MONTH_DATES,
  SECOND_HALF_MONTH_DATES,
  SHIFT_SPLIT_OPTIONS,
};
