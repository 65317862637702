import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import { ListGroup, ListGroupItem } from 'reactstrap';

import { FILTER_FORM_LAYOUT } from 'constants/layout';
import {ReactComponent as ClearIcon} from 'components/common/FilterForm/close.svg';
import {ReactComponent as SearchIcon} from 'components/common/FilterForm/search.svg';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { toast } from 'react-toastify';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import styles from './styles.module.scss';
import closeimage from 'employees/components/Close-Cross.png';

/**
 * EmployeeListPage -> EmployeeFilterSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class JobListFormModalComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.toggleInputType = this.toggleInputType.bind(this);
		this.submit = this.submit.bind(this);
		this.assignJobToEmployees = this.assignJobToEmployees.bind(this);
		this.toggleJobs = this.toggleJobs.bind(this);
		this.clear = this.clear.bind(this);
		this.state = { inputType: 'password', filter: "", data: this.props.allJobs, isCancelButton: false, isOpen: null, checkedCount: 0};
	}


	submit(data) {
		this.setState({ filter: data && data.search ? data.search : ""});

	}

	clear() {
		const { change } = this.props;
		change('search', '');
		this.setState({ filter: ""});

	}

	toggleInputType() {
		const { inputType } = this.state;
		this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
	}

	CheckBoxhandleChange = (val) => {
		this.toggleJobs(val);
	};

	handleChange = event => {
		this.setState({ isCancelButton: event.target.value ? true : false});
	}

	toggleJobs(checkedItem){
		const { data } = this.state;
		let checkedData =  [];
		data && data.length && data.map((item) => {
		if (checkedItem === 'Select All') {
			const newItem = {
				...item,
				checked: true 
			};
			return checkedData.push(newItem);
		}
		if( checkedItem === 'Deselect All') {
			 const newItem = {
				...item,
				checked: false 
			};
			return checkedData.push(newItem);
		}

		if (checkedItem.id === item.id) {
			const newItem = {
				...item,
				checked : item.checked ? !item.checked : true
			};
			return checkedData.push(newItem);
		}
		return checkedData.push(item);
		});

		const checked = (checkedData.filter(i => i.checked)).length;

		this.setState({data : checkedData, checkedCount: checked});

	}

	async assignJobToEmployees(){
		const { data } = this.state;
		const { selectedEmployees } = this.props;
		const jobsarray = data.filter(i => i.checked);
		const jobsSelected = jobsarray && jobsarray.length ? jobsarray.map((i) => {
			return i.id;
		}): null;
		const emp_ids = selectedEmployees.map(item => {
			return item.id;
		});
		if (jobsSelected && jobsSelected.length > 0 ) {
		const ReqObj = {
		employee_id: 0,
		job_ids: jobsSelected,
		employee_ids: emp_ids,
		}
		await this.props.usecase.assignJobs(ReqObj)
		.then(() => toast.success('Jobs are assigned to employees'))
		this.props.toggle();
		}
		else {toast.error('Choose a job to assign');
		}
	}

	render() {
		const { handleSubmit, isOpen, toggle, isButtonLoading} = this.props;
		const { filter, data, isCancelButton, checkedCount } = this.state;
		const lowercasedFilter = filter.toLowerCase();
		const filteredData = data.filter(item => {
			return Object.keys(item).some(key =>
				item.name.toLowerCase().includes(lowercasedFilter)
			);
		});

		return (
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				className="form-modal translate-modal"
				backdrop="static"
			>
				<ModalHeader className={`${styles['modal-custom-header']}`}>Mass Assign Job
					<img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
				</ModalHeader>				
					<ModalBody>
						<div className="d-inline-block w-100">
							<Form inline onSubmit={handleSubmit(this.submit) } className="search w-100">
								<div className="search-position input-group w-100">
									<Field
										component={InputAtom}
										id="search"
										size={FILTER_FORM_LAYOUT.size}
										name="search"
										placeholder="Search Job name"
										prefix={<span className={styles['search-allign']}  role="button" tabIndex={0} onClick={handleSubmit(this.submit)}> <SearchIcon width={16} height={15} className={styles['search-Icon']}/></span>}
										onChange={this.handleChange}
									/>
									{isCancelButton &&
										<ClearIcon
											className={styles['input-icon-clear']}
											height="13" width="13"
											onClick={this.clear}
										/>
									}
								</div>
							</Form>
						</div>
						<div className="mt-3">
							<span>
								{checkedCount === data.length ?
									<span style={{color: '#9a9898'}}>Select All</span> :
								<span className="button-a-tag" onClick={()=> this.CheckBoxhandleChange('Select All')} ><u>Select All</u></span>}&nbsp;&nbsp;&nbsp; <b> | </b>&nbsp;&nbsp;&nbsp;
								{checkedCount === 0 ?
									<span style={{color: '#9a9898'}}>Deselect All</span> :
								<span className="button-a-tag" onClick={()=> this.CheckBoxhandleChange('Deselect All')}><u>Deselect All</u></span>} &nbsp;&nbsp;&nbsp;  <b> | </b>&nbsp;&nbsp;&nbsp;
								<span style={{color: '#9a9898'}}>{checkedCount} Jobs Selected</span>
							</span><br></br><br></br>
						</div>
						<ListGroup flush className={`${styles['uoList-custom']}`}>
							{filteredData && filteredData.length > 0 ? filteredData.map(item => (
								<ListGroupItem className="cursor-pointer" onClick={() => this.CheckBoxhandleChange(item)} >
									<span title={item.name} >{item.name}
									</span>
									<input type="checkbox"
										id="item.id" 
										name="item.name" 
										className={`${styles['checkBox-custom-size']} cursor-pointer align-text-bottom`}
										onChange={() => this.CheckBoxhandleChange(item)}
										checked= {item.checked}
									/>   
								</ListGroupItem>)) : 
								<ListGroupItem className={`${styles['record-custom']}`} > No Record Found
								</ListGroupItem>
							}
						</ListGroup>
					</ModalBody>
					<ModalFooter className="modal-footer-assignJobs">

						<Button
							color="secondary"
							className={`${styles['modal-assign-buttom-custom-width']}`}
							//disabled={!selectedJobs.length}
							onClick={() => this.assignJobToEmployees()}
						>
						Assign Jobs <ButtonLoaderAtom active={isButtonLoading} />
						</Button>

						{/*<Button type="submit" color="secondary" size={FORM_LAYOUT.size} >
							Assign Jobs
						</Button>*/}
					</ModalFooter>
				
			</Modal>
		);
	}
};

const validate = (values) => {
	const errors = {};
	const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
  	const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
  	const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
	if (!values.password) errors.password = 'Enter Password';
	if (values.password && values.password.length < 8) errors.password = 'Password should contain atleast 8 characters';
	if (values.password && values.password.length >= 8 && values.password && (values.password.match(passwordRegex) || values.password.match(passwordSequential) || values.password.match(passwordRepeated ))) errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
	if (!values.confirm_password) errors.confirm_password = 'Enter Confirm Password';
	if ((values.password !== values.confirm_password) &&
		(values.password && values.confirm_password)) {
		errors.confirm_password = 'Password mismatch';
	}
	return errors;
};

JobListFormModalComponent.propTypes = {
	error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
	isOpen: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
	resetPassword: PropTypes.func.isRequired,
};

export default reduxForm({
	form: 'JobListFormModalComponent',
	validate,
})(JobListFormModalComponent);
