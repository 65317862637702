import React from "react";
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import FormikSelectAtom from 'generics/FormikSelect';
import { Formik, Field } from 'formik';
import moment from 'moment';
import { convertToBase64 } from "helpers/utils";
import queryString from "query-string";

import CountryCodeFormik from 'generics/CountryCodeFormik';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import EditIconAtom from 'atoms/EditIcon';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';
import DateTimePickerAtom from 'generics/DateTimePickerFormik'; 
import MultiSelectDropdownAtom from 'atoms/FormikMultiselect';

import styles from './styles.module.scss'
import caretArrowUp from 'applicants/assets/caret-arrow-up.png';
import caretArrowDown from 'applicants/assets/caret-down.png';
import defaultProfile from 'applicants/assets/defaultProfile.jpg';
import TagModal from 'employees/components/TagModal';
import { toast } from "react-toastify";



class ApplicantForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      applicantInfoOpen: true,
      oganizationInfoOpen: false,
      contactInfoOpen: false,
      file: null,
      inputType: 'password',
      showStrengthCheck : false,
      openTagModal : false,
      submitTag : false,
      imgSrc: '',
      showPasswordFields: false,
      tagsList: this.props.initialValues?.tags || [],
      submitLoader: false,
      hireLoader: false,
      hireAsEmployee: false,
    };
    this.updateState = this.updateState.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleFileInputChange = this.handleFileInputChange.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    // this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.selectTags = this.selectTags.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleCountryChange(country){
    this.props.handleCountrySelect(country.value);
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  handleFileInputChange(e){
    this.setState({file: e.target.files[0]});
    this.setState({ imgSrc: URL.createObjectURL(e.target.files[0])})
  };

  // handleCompanyChange(company, setFieldValue){
  //   setFieldValue('applicant_role', '');
  //   this.props.usecase.handleCompanyChange(company.value);
  // }

  toggleTagModal(){
    const { openTagModal } = this.state;
    this.setState({openTagModal : !openTagModal});
  }

  selectTags(tags){  
    const tagsId = tags  && tags.map(item => item.id );
    this.setState({tagsList: tagsId});
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  async onSubmit(values, { setErrors }){
    const { file, tagsList, inputType, hireAsEmployee} = this.state;
    const {params, phoneNumberPlain, history, initialValues, roleList} = this.props;
    const employeeRole = roleList.length ? roleList.find((i) => i.name === 'Employee') : {};
    let errors = {};

    if (!values.first_name) {
      errors = {
        ...errors,
        first_name: "First name is required",
      }
    } else if (values.first_name.length > 15) {
      errors = {
        ...errors,
        first_name: 'Must be 15 characters or less',
      }
    }

    if(!params.id && !values.password){
      errors = {
        ...errors,
        password: 'Enter Password',
      }
    }
    if(values.password && values.password.length < 8){
      errors = {
        ...errors,
        password: 'Password should contain atleast 8 characters',
      }
    }
    if(inputType === 'password' && values.password && !values.confirm_password){
      errors = {
        ...errors,
        confirm_password: 'Enter Confirm Password',
      }
    }
    if(inputType === 'password' && values.confirm_password && values.confirm_password !== values.password){
      errors = {
        ...errors,
        confirm_password: 'Password does not match',
      }
    }

    setErrors(errors)
    if(Object.keys(errors).length > 0){
      return;
    }

    const base64 = file && await convertToBase64(file);

    let obj = {
      ...values,
      first_name: values.first_name.trim(),
      company: values?.company.value ? values.company.value : initialValues.company,
      company_id: values?.company.value ? values.company.value : initialValues.company,
      country: values?.country?.value ?? undefined,
      state: values?.state?.value ??  undefined,
      phone_no: values?.phone_no ? phoneNumberPlain : undefined,
      hire_date: values?.hire_date ? (/^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(values.hire_date)?values.hire_date:moment(values.hire_date).format("DD/MM/YYYY")): undefined, 
      image: file ? {
        ext: file?.name,
        value: base64,
      } : undefined,
      tags: tagsList,
      employee_role: hireAsEmployee ? employeeRole.id : values?.employee_role,
    }

    if(hireAsEmployee){
      this.setState({hireLoader: true})
    }else{
      this.setState({submitLoader: true})
    }

    try {
      if(!params.id){
        await this.props.usecase.createApplicant(obj);
      }else{
        await this.props.usecase.updateApplicant({...obj, id: params.id});
      }
      toast.success(!params.id ? "Applicant has been added successfully" : "Applicant successfully updated.");
      history.push({
        pathname: `/applicant/list`,
      });
    } catch (e) {
      if(typeof e === "string"){
        toast.error(e);
        this.setState({submitLoader: false})
        return;
      }else setErrors(e.errors);
    }

    if(hireAsEmployee){
      this.setState({hireLoader: false, hireAsEmployee:false})
    }else{
      this.setState({submitLoader: false})
    }
  }

  
  render() {
    const {
      params,
      initialValues,
      isOrganizationVisible,
      companyList,
      employeeTagsList,
      dateFormat,
      countryList,
      stateList,
      formatPhoneNumber,
    } = this.props;
    const {
      applicantInfoOpen,
      oganizationInfoOpen,
      contactInfoOpen,
      inputType,
      openTagModal,
      imgSrc,
      showPasswordFields,
      submitLoader,
      hireLoader,
    } = this.state;

    const tags = employeeTagsList&& employeeTagsList.length && employeeTagsList[0].tag_collection && employeeTagsList[0].tag_collection.map(item => (
      { id: item , name: item }
    ));

    return (
      <div className="col-md-6 p-0">
      <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          enableReinitialize={true}
          render={({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
         }) => {
            return (<Form  autocomplete = "off" onSubmit={handleSubmit}>
              <div style={{minHeight: '3vh', fontSize: '20px'}}>
                <FormText color="danger">{errors && errors.non_field_errors}</FormText>
              </div>
              <div>
                <b>Applicant Information</b>
                <span onClick={() => this.updateState('applicantInfoOpen', !applicantInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                  <img className='ml-2 mr-2' src= {applicantInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                </span>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={applicantInfoOpen}>
                <div className = "toggleCard">
                  <Row className={`mx-auto mx-md-0`}>
                    <Col md={2} className={`p-0`}>
                      <div className={`${styles['profile-container']}`}>
                        <img className={`${styles['profile-img']}`} src={imgSrc ? imgSrc : (initialValues.image ?  initialValues.image : defaultProfile)} />
                        <label htmlFor="file-input" className={`${styles['edit-btn-container']}`}>
                          <EditIconAtom className={`${styles['profile-edit']}`}/>
                        </label>
                        <input id="file-input" type="file" onChange={(e) => this.handleFileInputChange(e)} />
                      </div>
                    </Col>
                  </Row>
                  <Row className={`mt-3`}>
                    <Col md={6}>
                      <FormGroup>
                        <Label>First Name</Label>
                        <Input
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          onChange={handleChange}
                          value={values && values.first_name ? values.first_name : ''}
                          className={errors && errors.first_name ? "form-control-error" : null}
                          data-lpignore={true}
                          autoComplete="off"
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.first_name}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          name="last_name"
                          value={values && values.last_name ? values.last_name : ''}
                          placeholder="Last name (optional)"
                          onChange={handleChange}
                          className={errors && errors.last_name ? "form-control-error" : null}
                          data-lpignore={true}
                          autoComplete="off"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col md={4} className="pr-0">
                          <Field
                            id="phone_ext"
                            name="phone_ext"
                            label="Code"
                            component={CountryCodeFormik}
                          />
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_ext}</FormText></div>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label>Phone Number</Label>
                            <Input
                              type="text"
                              name="phone_no"
                              placeholder="(805) 555-0123"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phone_no ? formatPhoneNumber(values.phone_no) : ''}
                              invalid={errors && touched.phone_no && errors.phone_no}
                              className={errors && errors.phone_no ? "form-control-error" : null}
                              data-lpignore={true}
                              autoComplete="off"
                            /> 
                            <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_no}</FormText></div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Applicant Role</Label>
                        <Field
                          component={FormikSelectAtom}
                          name="applicant_role"
                          id="applicant_role"
                          placeholder="Applicant"
                          disabled={true}
                        />
                        <Input
                          component={FormikSelectAtom}                     
                          className={styles['hidden-field']}              
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.applicant_role}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>            
                  {params.id && initialValues &&
                    <div className="mb-3">
                      <Label className="mb-0">Applicant's Username:</Label>
                      <div className={`${styles['username-div']} p-1`}> <b>{`${initialValues.username}`}</b></div>  
                    </div>
                  }
                  {params.id &&
                    <div>
                      <span  className="link-name mb-2" onClick={() => this.updateState('showPasswordFields', true)}><u><i>Change Password</i></u></span>
                    </div>}
                  {(!params.id || showPasswordFields)&& <Row className={`mt-3`}>
                    <Col className="pr-0 mr-2">
                      <FormGroup>
                        <div className="pos-relative">
                          <Label>Password*</Label>
                          <Input
                              type={inputType}
                              name="password"
                              placeholder="Password"
                              onChange={handleChange}
                              onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                              onFocus = {this.strengthCheckToggle}
                              value={values.password}
                              className={errors && errors.password  ? "form-control-error" : null}
                              data-lpignore={true}
                              autoComplete="new-password"
                            />
                            {inputType === 'password'
                              ? (<EyeOpenIconAtom
                                className="password-icon"
                                height="22" width="22"
                                onClick={this.toggleInputType}
                              />)
                              : (<EyeCloseIconAtom
                                className="password-icon"
                                height="22" width="22"
                                onClick={this.toggleInputType}
                            />)
                            }
                        </div>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.password}</FormText></div>
                        {this.state.showStrengthCheck &&
                          <PasswordStrengthMeter
                            values={values}
                          />
                        }
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        {inputType === 'password'
                          ?(
                          <div>
                            <Label>Confirm Password*</Label>
                            <Input
                              type="password"
                              name="confirm_password"
                              placeholder="Confirm Password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="new-password"
                              value={values.confirm_password}
                              className={errors && errors.confirm_password ? "form-control-error" : null}
                              data-lpignore={true}
                            /> 
                            <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.confirm_password}</FormText></div>
                          </div>): null }
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="ml-4">
                      <FormGroup>   
                        <Input
                          type="checkbox"
                          id="is_active"
                          onChange={handleChange}
                          className={errors && errors.is_active ? "form-control-error" : null}
                          checked={values && values.is_active} 
                        />
                        <Label for="is_active" name="active" className="mr-5">Active?</Label>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.is_active}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className={`d-flex justify-content-center align-items-center mb-4`}>
                      <Col>
                        <Button
                          className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment`}
                          isColor='primary'
                          disabled={params.id ? false : true}
                          onClick={() => this.setState({hireAsEmployee: true})}
                          type="submit"
                        >
                          Hire as Employee <span className={`${styles['roleButtonLoaderPos']}`}><ButtonLoaderAtom active={hireLoader}/></span>
                        </Button>
                      </Col>
                  </Row>
                </div>
              </Collapse>

              <div>
                <b>Oganizational Information (optional)</b>
                <span onClick={() => this.updateState('oganizationInfoOpen', !oganizationInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                  <img className='ml-2 mr-2' src= {oganizationInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                </span>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={oganizationInfoOpen}>
                <div className = "toggleCard"> 
                  <Row>
                    { isOrganizationVisible && companyList.length &&
                      <Col>
                        <FormGroup>
                          <Label>Company Name</Label>
                          <Field
                            id="company"
                            name="company"
                            component={FormikSelectAtom}
                            options={companyList.map((i) => ({ value: i.id, label: i.name }))}
                            placeholder="Company Name"
                            // onChange={(v) => this.handleCompanyChange(v, setFieldValue)}
                          />
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.company}</FormText></div>
                        </FormGroup>
                      </Col>}
                    <Col>
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          name="title"
                          placeholder="Title"
                          onChange={handleChange}
                          value={values && values.title ? values.title : ''}
                          className={errors && errors.title ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.title}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Applicant ID</Label>
                        <Input
                          type="text"
                          name="employee_id"
                          placeholder="Applicant ID"
                          value={values && values.employee_id ? values.employee_id : ''}
                          onChange={handleChange}
                          className={errors && errors.employee_id ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.employee_id}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                    <FormGroup>
                        <Label className="w-100">Tags <span tabIndex="0" role="button" className="link-name float-right" onClick={() => this.toggleTagModal()}>Manage tags</span></Label>
                        <span>
                          <Field
                            component={MultiSelectDropdownAtom}
                            id="tags"
                            name="tags"
                            onChange={handleChange}
                            placeholder="Select Tags"
                            items={tags}
                            itemToString={i => i && i.name}
                            className={styles.fixed_form_height}
                            value={values.tags ? values.tags : []}
                            selectItems={this.selectTags}
                          />
                         </span>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.employee_id}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                   <Col>
                      <FormGroup>
                        <Label>Hire Date</Label>
                        <Field
                            id="hire_date"
                            name="hire_date"
                            placeholder="Hire Date"
                            component={DateTimePickerAtom}
                            value={values && values.hire_date ? moment(values.hire_date, "DD/MM/YYYY").toISOString() : null}
                            onChange={handleChange}
                            className={{height:"10px"}}
                            options={{dateFormat: `${dateFormat}`,
                              enable: [
                              (date) => moment(date).isBefore(moment()),
                            ]}}
                          />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.hire_date}</FormText></div>
                         
                         </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Collapse>

              <div>
                <b>Contact Information (optional)</b>
                <span onClick={() => this.updateState('contactInfoOpen', !contactInfoOpen)} role="button" tabIndex="0" className="remove-focus" >
                  <img className='ml-2 mr-2' src= {contactInfoOpen ? caretArrowUp : caretArrowDown } alt= ''  height="10" width="10" />
                </span>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={contactInfoOpen}>
                <div className = "toggleCard"> 
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Email</Label>
                        <Input
                          type="text"
                          name="email"
                          onChange={handleChange}
                          placeholder="Example@example.com"
                          value={values && values.email ? values.email : ''}
                          className={errors && errors.email ? "form-control-error" : null}
                          data-lpignore={true}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.email}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address (Line 1)</Label>
                        <Input
                          type="text"
                          name="address_line_one"
                          placeholder="Address"
                          onChange={handleChange}
                          value={values && values.address_line_one ? values.address_line_one : ''}
                          className={errors && errors.address_line_one ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.address_line_one}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Address (Line 2)</Label>
                        <Input
                          type="text"
                          name="address_line_two"
                          onChange={handleChange}
                          value={values && values.address_line_two ? values.address_line_two : ''}
                          placeholder="Apartment,Suite,Unit,Building,Floor"
                          className={errors && errors.address_line_two ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.address_line_two}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Country</Label>
                        <Field
                          id="country"
                          name="country"
                          component={FormikSelectAtom}
                          options={countryList.map((i) => ({ value: i.id, label: i.name }) )}
                          onChange={(v) => this.handleCountryChange(v)}
                          placeholder="Country"
                        /> 
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.country}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>State</Label>
                        <Field
                          id="state"
                          name="state"
                          component={FormikSelectAtom}
                          options={stateList.map((i) => ({ value: i.id, label: i.name }) )}
                          placeholder="State"
                          onChange={handleChange}
                          disabled={!stateList.length}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.state}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>City</Label>
                        <Input
                          type="text"
                          name="city"
                          placeholder="City"
                          onChange={handleChange}
                          value={values && values.city ? values.city : ''}
                          className={errors && errors.city ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.city}</FormText></div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>Zip Code</Label>
                        <Input
                          type="text"
                          name="zip_code"
                          placeholder="Zip Code"
                          onChange={handleChange}
                          value={values && values.zip_code ? values.zip_code : ''}
                          className={errors && errors.zip_code ? "form-control-error" : null}
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.zip_code}</FormText></div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Collapse>
              
              <Row>
                <Col className="pb-5 mb-4 pt-3">
                  <FormGroup> 
                    <Button
                      className={`${styles['form-button']} ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                      isColor='primary'
                      isLoading={submitLoader}
                      disabled={submitLoader}
                      type="submit"
                    >
                      Save<span className={`${styles['saveButtonLoaderPos']}`}><ButtonLoaderAtom active={submitLoader} /></span>
                    </Button>
                  </FormGroup>
                </Col>
              </Row> 
            </Form> 
          )}}
          />
          {
              openTagModal &&
              <TagModal
                isOpen={openTagModal}
                toggle={this.toggleTagModal}
                tagsList = {tags}
                usecase={this.props.usecase}
                submitTag = {this.state.submitTag}
                tagsCollection = {this.props.employeeTagsList}
              />
          }  
      </div>
    );
  }
}


export default ApplicantForm;
