import React from 'react';

import moment from 'moment';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import SectionLoaderAtom from 'atoms/SectionLoader';  

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import TwoColumnLayout from 'layouts/TwoColumn';
import ManageJobsSection from 'sections/jobs/list/ManageJobsSection';

import { NAV } from 'constants/jobs';

/**
 * ManageJobsPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobSchedulesFilterSection}
 *    - {@link EmployeeListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class ManageJobsPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.cardViewToggle = this.cardViewToggle.bind(this);
    this.updateList =this.updateList.bind(this);
    this.updateQuery =this.updateQuery.bind(this);
    this.refresh = this.refresh.bind(this);
    this.refreshDisable = this.refreshDisable.bind(this);
    this.goTo = this.goTo.bind(this);
    this.state = {  cardView: false, params: null , isLoadingSection: false, refreshLoader : false, isLoading: false};
  }

  componentWillMount() {
    /*console.log('sjksj');*/
    this.props.JobDucks.getAdminJobsUserPreference({preference_type: 'user_preference_manage_jobs' })
    .then((res) => {
      let jobPreference =  res.value.data;
      this.loadData(jobPreference);
    });
  }

  
  componentWillReceiveProps(nextProps) {
    const { router: { history } } = this.context;
    const { location: { pathname, query: prevQuery } , adminJobsUserPreferences } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const status = cookie.load('job_status_new');
    const recurringSearchCookie = cookie.load('recurring_search_new');
    const projectSearchCookie = cookie.load('project_search_new');
    const materialSearchCookie = cookie.load('material_search_new');
    let data = { status : nextQuery.status , 
        recurring_search : nextQuery.recurring_search , project_search : nextQuery.project_search , material_search : nextQuery.material_search
    }
    if (prevQuery !== nextQuery && nextQuery) {
      this.fetchData(nextQuery);
      this.props.JobDucks.updateUserJobPreference( data, {preference_type: 'user_preference_manage_jobs' });
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  refresh() {
    this.setState({refreshLoader : true});
    const RecurringpageSizeCookie = cookie.load('RecurringpageSizeNew');
    const ProjectpageSizeCookie = cookie.load('ProjectpageSizeNew');
    const MaterialPageSizeCookie = cookie.load('MaterialPageSizeNew');
    const cookieKey = cookie.load('tableViewNew');
    const { location: { query } } = this.props;
    let bill_type;
    let job_type;
    let params;
    let pageSize;
    const { recurring_search, project_search, material_search,search, ...rest} = query;
    if(cookieKey && cookieKey.quotedRecurring === true ){
      bill_type="QUOTED";
      job_type="RECURRING";
      pageSize = RecurringpageSizeCookie;
        params = { ...rest, search : recurring_search ? recurring_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }
    else if (cookieKey && cookieKey.quotedProject === true){
      bill_type="QUOTED";
      job_type="PROJECT";
      pageSize = ProjectpageSizeCookie;
      params = { ...rest, search : project_search ? project_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }
    else if (cookieKey && cookieKey.timeAndMaterials == true){
      bill_type="TIME_AND_MATERIAL";
      pageSize = MaterialPageSizeCookie;
      params = { ...rest, search : material_search ? material_search : null, page: 1,
      page_size: pageSize ? pageSize : 10, billType : bill_type, jobType: job_type };
    
    }

    if(cookieKey && cookieKey.quotedRecurring === true){
      this.props.JobDucks.getNewRecurringJobsList(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else if(cookieKey&&cookieKey.quotedProject === true){
      this.props.JobDucks.getNewProjectJobsList(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else if(cookieKey && cookieKey.timeAndMaterials == true){
      this.props.JobDucks.getNewTimeAndMaterialJobsList(params)
        .finally(() => this.setState({  refreshLoader: false }));
    }
    else{
      this.setState({refreshLoader : false})
    }
  }

  loadData(jobPreference){
    /*console.log('jobPreference',jobPreference)*/
    const { router: { history } } = this.context;
    const { location: { pathname, query } , adminJobsUserPreferences } = this.props;
    this.props.JobDucks.resetJob();
    const jobStattus = cookie.load('job_status_new') || jobPreference.status;
    const recurringSearchCookie = cookie.load('recurring_search_new') || jobPreference.recurring_search;
    const projectSearchCookie = cookie.load('project_search_new') || jobPreference.project_search;
    const materialSearchCookie = cookie.load('material_search_new') || jobPreference.material_search;
    /*console.log('nnnnnnnnn', recurringSearchCookie)*/
    if ((!'status' in query) || !('recurring_search' in query) || !('project_search' in query) || !('material_search' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          recurring_search: query.recurring_search ? query.recurring_search : recurringSearchCookie,
          project_search: query.project_search ? query.project_search : projectSearchCookie,
          material_search: query.material_search ? query.material_search : materialSearchCookie,
          status: jobStattus || "1",
        }),
      });
    }
    else {
      this.fetchData(query, jobPreference);
    }
  }

  fetchData(params, jobPreference) {
    const { location: {  query } } = this.props;  
    const { recurring_search, project_search, material_search , status , ...rest} = params;
    this.props.BasicSettingDucks.getBasicSetting();
    this.setState({ isLoadingSection: true });
    const jobStatus = cookie.load('job_status_new') || jobPreference && jobPreference.status;
    const data = {
      status: params && params.status && params.status !== '0' ? params.status : null,
      page_size: params.page_size,
      page : params.page,
    };
    delete data.page_size;
    if(!params.status){
      params.status= jobStatus || '1';
    }
    const updatedParams = {status: status !== '0' ? status : null,...rest}
    this.setState({ isLoadingSection: true });
    this.props.JobDucks.getJobsIds({ ...data, paginate: false })
    .then( () => this.setState({isLoadingSection : false}))
    /*this.props.JobDucks.getTimeAndMaterialJobs(params)
    this.props.JobDucks.getQuotedProjectJobs(params)
    this.props.JobDucks.getQuotedRecurringJobs(params)*/
  }

  updateList() {
    const { location: { query } } = this.props;
    this.setState({ isLoadingSection: true });
    this.props.JobDucks.getJobs({...query, })
      .then(() => this.setState({ isLoadingSection: false }))
      .catch(() => this.setState({ isLoadingSection: false}));
  }

  refreshDisable(boolean){
    this.setState({isLoading: boolean});
  }
  
  cardViewToggle() {
    const { cardView } = this.state;
    this.setState ({ cardView: !cardView });
  }

  updateQuery(d) {
    this.setState({ params: d});
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const {  isInfoOpen, cardView, params , isLoading} = this.state;
    const { infoList, isGeoFenceVisible } = this.props;    
    const { location: { query } } = this.props;
    const tabs = [
      { href: '/job/list', label: 'Customers/jobs' },
      { href: '/job/lists/task', label: 'Tasks'},
    ];
    if(isGeoFenceVisible){
      tabs.push({ href: '/geofence/job/lists', label: 'Geofences' });
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    else{
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    return (
      <section>
        <TwoColumnLayout
          infoList={infoList}
          navInfo={NAV.ManageJobsPage}
          tabItems={tabs.filter(item => item)}
          isInfoOpen={isInfoOpen}
          fetchData={this.refresh}
          isLoading={isLoading}
          createJob="createJobNew"
          goTo={this.goTo}
        >
        {!(query.status) ?
          <SectionLoaderAtom active />
          :
          <div>
            <ManageJobsSection
              isLoading={isLoading}
              isLoadingProject={this.state.isLoadingProject}
              isLoadingMaterial={this.state.isLoadingMaterial}
              cardView={cardView}
              updateList={this.updateList}
              updateQuery={this.updateQuery}
              isLoadingSection={this.state.isLoadingSection}
              refreshLoader={this.state.refreshLoader}
              refreshDisable={this.refreshDisable}
            />
          </div>          
        }
        </TwoColumnLayout>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
  /*jobList: JobDucks.jobList(state),*/
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  adminJobsUserPreferences : JobDucks.adminJobsUserPreferences(state)
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

ManageJobsPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
};

ManageJobsPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ManageJobsPage);
