import React from "react";
import queryString from "query-string";
import Button from "reactstrap/lib/Button";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import styles from "./styles.module.scss";

class FormsProgressBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.goToUrl = this.goToUrl.bind(this);
  }

  goToUrl(selectedId, type) {
    const { history, query } = this.props;
    if (type == "step1") {
      
    } else if (type == "step2") {
     

    }
  }

  render() {
    const {
      stepNumber,
      isStepThree,
      selectedId,
      history: {location: {pathname}}
    } = this.props;
    return (
      <div
        className="step_progressbar"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {isStepThree ? (
          <ul className="progressbar-ul pl-0 pt-1" style={{ width: "100%" }}>
            <li
              className={
                !selectedId
                  ? stepNumber === 2 || stepNumber === 3
                    ? "progressbar-li active selected-progressbar-li"
                    : "progressbar-li selected-progressbar-li selected-progressbar-li-active"
                  : stepNumber === 1
                  ? "cursor-pointer progressbar-li selected-progressbar-li active selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li selected-progressbar-li active"
              }
            >
              Details
            </li>
            <li
              className={
                !selectedId
                  ? stepNumber === 3
                    ? "progressbar-li active selected-progressbar-li"
                    : stepNumber === 2
                    ? "progressbar-li selected-progressbar-li selected-progressbar-li-active"
                    : "progressbar-li"
                  : stepNumber === 2
                  ? "cursor-pointer progressbar-li active selected-progressbar-li selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li active selected-progressbar-li"
              }
            >
              Outcome
            </li>
            <li
              className={
                !selectedId
                  ? stepNumber === 3
                    ? "progressbar-li selected-progressbar-li selected-progressbar-li-active"
                    : "progressbar-li"
                  : stepNumber === 3
                  ? "cursor-pointer progressbar-li active selected-progressbar-li selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li active selected-progressbar-li"
              }
            >
              <span>Assign and Notify</span>
            </li>
          </ul>
        ) : (
          <ul className="progressbar-ul pl-0 pt-1" style={{ width: "100%" }}>
            <li
              className={
                !selectedId
                  ? stepNumber === 2
                    ? "progressbar-li-two-steps active selected-progressbar-li"
                    : "progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active"
                  : stepNumber === 1
                  ? "cursor-pointer progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active active"
                  : "cursor-pointer progressbar-li-two-steps active selected-progressbar-li"
              }
            >
              {pathname.indexOf("fieldform") > -1 ? "Details" : "Build Training Form" }
            </li>
            <li
              onClick={
                selectedId ? () => this.goToUrl(selectedId, "step2") : null
              }
              className={
                !selectedId
                  ? stepNumber === 2
                    ? "progressbar-li-two-steps selected-progressbar-li selected-progressbar-li-active"
                    : "progressbar-li-two-steps"
                  : stepNumber === 2
                  ? "cursor-pointer progressbar-li-two-steps active selected-progressbar-li selected-progressbar-li-active"
                  : "cursor-pointer progressbar-li-two-steps active  selected-progressbar-li"
              }
            >
              <span>{pathname.indexOf("fieldform") > -1 ? "Assign and Notify" : "Scoring" } </span>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

export default FormsProgressBar;
