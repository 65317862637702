import React from 'react';

import PropTypes from 'prop-types';
import { secondsToHours } from 'helpers/utils';

import Table from 'reactstrap/lib/Table';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Popover } from 'reactstrap';
import EditableCellAtom from 'atoms/EditableCell';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EditIconAtom from 'atoms/EditIcon';
import GreenLoaderAtom from 'atoms/GreenLoader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DeleteIconAtom from 'atoms/DeleteIcon'


import InfoFormModalComponent from 'components/timecards/customDailyTimecardList/infoFormModal';
import TooltipFormComponent from 'components/timecards/dailyTimecardList/TooltipForm';
import FeedbackCommentModal from './FeedbackCommentModal';
// import AddShiftIcon from 'components/timecards/dailyTimecardList/plus.png';

import styles from './styles.module.scss';
import InfoIcon from './info.png';
import ForcePunchOutApprovedIcon from './forcepunchout-approved.png';
import ForcePunchOutAwaitingIcon from './forcepunchout-awaiting approval.png';
import ForcePunchOutInvalidatedIcon from './forcepunchout-Invalidated.png';
import ValidApprovedIcon from './Valid-approved.png';
import ValidAwaitingIcon from './Valid-awaitingapproval.png';
import ValidInvalidatedIcon from './Valid-invalidated.png';
import MissingMealApprovedIcon from './missingmeal-approved.png';
import MissingMealAwaitingIcon from './missingmeal-awaitingapproval.png';
import MissingMealInvalidatedIcon from './missingmeal-invalidated.png';
import DelayedMealApprovedIcon from './DelayedMeal-approved.png';
import DelayedMealAwaitingIcon from './DelayedMeal-awaitingapproval.png';
import DelayedMealInvalidatedIcon from './DelayedMeal-invalidated.png';
import ManualCorrectionApprovedIcon from './ManualCorrection-approved.png';
import ManualCorrectionAwaitingIcon from './ManualCorrection-awaitingapproval.png';
import MileageDeviationApprovedIcon from './Mileagedeviation-approved.png';
import MileageDeviationInvalidatedIcon from './Mileagedeviation-invalidated.png';
import MileageDeviationAwaitingIcon from './Mileagedeviation-awaitingapproval.png';
import ManualEntryApprovedIcon from './ManualEntry-approved.png';
import ManualEntryAwaitingIcon from './ManualEntry-awaitingapproval.png';
import ManualEntryInvalidatedIcon from './ManualEntry-invalidated.png';
import TimefenceApprovedIcon from './TimefenceOverrideGreen.png';
import TimefenceAwaitingIcon from './TimefenceOverrideYellow.png';
import TimefenceInvalidatedIcon from './TimefenceOverrideRed.png';
import CommentIcon from './comment-bubble.png';
import GreenCommentIcon from './greenComment.png';
import MealAddIcon from './plus.png';
import AddShiftIcon from './white-plus.png';
import PayMealIcon from './payMeal.png';
import GreyPayMealIcon from './greyPayMeal.png';
import TrackingIcon from '../customDailyTimecardList/tracking.png';
import FeedbackBlue from './FeedbackBlue.png';
import FeedbackGrey from './Feedbackgrey.png';
/*
 * DailyTimecardListPage -> DailyTimecardListSection -> DailyTimecardTableComponent -> DailyTimecardSubTableComponent
 *
 * Props:
 *    - daily timecard list
 *
 */
class DailyTimecardSubTableComponent extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      valueValidity: { value: '', label: "Awaited Approval"},
      comment: null,
      editable: false,
      selectedItem:null,
      Time: null,
      main:null,
      approvedRejectIndex: null,
    }
    this.textInput = this.textInput.bind(this);
    this.validate = this.validate.bind(this);
    this.onValiditySelection = this.onValiditySelection.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.addMeal = this.addMeal.bind(this);
    this.validateMileage = this.validateMileage.bind(this);
    this.saveCommentValidity = this.saveCommentValidity.bind(this);
  }

  onValiditySelection(val, id){
    if(val){
      this.setState({valueValidity: val, selectedItem: id});
    }
    else{
      this.setState({valueValidity:""});
    }
  }
  addMeal(data, detail){
    const { addMeal } = this.props;
    this.setState({ mealShift : data});
    if(detail.is_approved){
      toast.error('Meals can not be added as daily timecard is already approved');
    }
    else addMeal(data);
  }

  saveCommentValidity(valueValidity, item, id){
    const {comment} = this.state;
    if(!valueValidity){
      return toast.error("Comment field cannot be empty")
    }
    else if(valueValidity && !valueValidity.trim().length){
      return toast.error("Comment field cannot be empty")
    }
    else{
      const { saveData } = this.props;
      saveData(valueValidity, item, id)
      .then(()=>{
        this.setState({comment: null});
      })
    }
  }

  // function to call after successfull validation of editable fields
  updateTable(data, key){
    const { updateTable, childItem, shift, addNewShift, PunchIn, PunchOut, updateNewShift, mealIn, mealOut, updateMileage} = this.props;
    // to identify whether added data is meal or shift
    if((data.new_shift || data.new_meal) && !data.shift_exist){
      addNewShift(data);
      if(PunchIn && data.end_time && mealIn && mealOut) {
        updateNewShift(data, PunchIn, data.end_time, childItem, mealIn, mealOut, 1);
      }
      if(PunchOut && data.start_time && mealIn && mealOut) {
        updateNewShift(data, data.start_time, PunchOut, childItem, mealIn, mealOut, 1);
      }
      if(PunchIn && data.end_time && !mealIn && !mealOut) {
        updateNewShift(data, PunchIn, data.end_time, childItem);
      }
      if(PunchOut && data.start_time && !mealIn && !mealOut) {
        updateNewShift(data, data.start_time, PunchOut, childItem)
      }
    }
    else if(data.new_meal && data.shift_exist){
      addNewShift(data);
      if(mealIn && data.end_time) {
        updateNewShift(data, mealIn, data.end_time, childItem);
      }
      if(mealOut && data.start_time) {
        updateNewShift(data, data.start_time, mealOut, childItem)
      }
    }
    // to match shift id of punched in with the shift id of punched out to call api and there exists no previous shift i.e empty DT
    else if(shift.activity_split && shift.activity_split[0] && (shift.activity_split[0].id === data.id)){
      addNewShift(data);
      if(PunchIn && data.end_time) {
        updateNewShift(data, PunchIn, data.end_time, childItem);
      }
      if(PunchOut && data.start_time) {
        updateNewShift(data, data.start_time, PunchOut, childItem)
      }
    }
    else if (key === 'mileage') {
      updateMileage(data, childItem);
    }
    else {
      updateTable(data, childItem, null, key);
    }
  }

  // to show last comment on the UI of comment info pop over
  textInput(e, data){
    this.setState({ comment: e.target.value});
  }


  // validate function to validate time from editable fields
  validate(val,data, key){
    const { updateTime, userTimezone} = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime_tz && data.start_datetime_tz.split(" ");
    const endDateTz = data && data.end_datetime_tz && data.end_datetime_tz.split(" ");
    const startDate = data && data.start_date && data.start_date;
    const endDate = data && data.end_date && data.end_date;
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      // if(key && key == 'start_time' ){
      //   if((startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) || (startDate && moment(startDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))){        
      //     if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
      //       toast.error('Time entered should be less than current time');
      //       return false;
      //     }
      //   }
      // }
      // else if(key && key == 'end_time'){
      //   if((endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) || (endDate && moment(endDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))){
      //     if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
      //       toast.error('Time entered should be less than current time');
      //       return false;
      //     }
      //   } 
      // }
      updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    this.setState({editable: false});
    return false;
  }

  validateMileage(val, data){
    const mileageRegex = /^[0-9]\d{0,9}(\.\d{1,9})?%?$/;
    if (mileageRegex.test(val)){
      return true;
    }
    else{
      toast.error('Please enter a valid mileage');
      this.setState({editable: false});
      return false;
    }
  }

  render(){
    const {
      dropdownToggle,
      dropdownOpen,
      openMissingMealState,
      openForceOutState,
      openPunchOutState,
      openCommentState,
      selectedId,
      selectedShiftData,
      basicSettingDetail,
      toggleModal,
      updateBoxes,
      childItem,
      openManualCorrectionState,
      openMileageDeviationState,
      isValidityBoxLoading,
      isShiftLoading,
      saveValidity,
      activityLogs,
      toggleInfo,
      isInfoLoading,
      isInfoModalOpen,
      errorId,
      selectedShift,
      loadedShift,
      fetchNextData,
      items,
      showMore,
      isCommentLoading,
      trackingToggle,
      addShift,
      approveDailyTimecard,
      dtNumber,
      disapproveDailyTimecard,
      openPayMealModal,
      departmentAll,
      changeMealStatus,
      defaultPayrollSetting,
      showableAttributes,
      userExtraData,
      isError,
      feedbackModal,
      openTimefenceBreachState,
      userTimecardFilters,
      togglePtoModal,
      deletePtoTimecard,
      savePtoTimecardData,
      isApproving,
    } = this.props;
    const empDeptId = this.props.query.emp_dept_id ;
    const employeeDept = departmentAll.filter(item => item.id === empDeptId);
    // const empDeptPayrollSetting = employeeDept.length ? employeeDept[0].payroll_setting_daily_split : null;
    const mealOverrideSettings = childItem.is_meal_override || false;
    const { valueValidity, comment, editable, selectedItem, approvedRejectIndex} = this.state;
    const shiftData = childItem && childItem.activity_split && childItem.activity_split.filter((item) => item.activity_name==="Shift" || item.adjustment_type || (userExtraData.company_permissions.paid_time_off && item.pto_requests_type));
    //     const checkDuplicateShift = (data,index) => {
//     if(data[index]?.start_datetime_display !==undefined && data[index]?.start_datetime_display !== '' && data[index]?.activity_code === "PUNCH-IN")
//     {  
//       if(data.length===0 || data.length===1)
//       {
//         return false
//       }else if(index===0) {
//         if( (data[index]?.start_datetime_display === data[index+1]?.start_datetime_display) )
//         {
//           return true
//         }else{
//           return false
//         }
//       }else if(index === (data.length-1)){
//         if( (data[index].start_datetime_display === data[index-1].start_datetime_display))
//         {
//           return true
//         }else{
//           return false
//         }
//       }else if( ((data[index].start_datetime_display === data[index+1].start_datetime_display)
//               || (data[index].start_datetime_display === data[index-1].start_datetime_display))){
//         return true
//       }else{
//         return false
//       }
//     }
//  }
// const parseDate = (dateString) => {
//   if (!dateString) return null;
//   console.log("dateString",dateString)
//   const [date, time, modifier] = dateString.split(/[\s,]+/);
//   const [day, month, year] = date.split('/').map(Number);
//   let [hours, minutes] = time.split(':').map(Number);

//   if (modifier === 'PM' && hours !== 12) {
//     hours += 12;
//   } else if (modifier === 'AM' && hours === 12) {
//     hours = 0;
//   }

//   return new Date(year, month - 1, day, hours, minutes);
// };

const parseDate = (dateString) => {
  if (!dateString) return null;

  const [datePart, timePartWithOffset] = dateString.split(' ');
  let timePart, offsetSign, offsetHours, offsetMinutes;

  if (timePartWithOffset.endsWith('Z')) {
    timePart = timePartWithOffset.slice(0, -1);
    offsetSign = '+';
    offsetHours = 0;
    offsetMinutes = 0;
  } else {
    const match = timePartWithOffset.match(/([+-])(\d{2}):?(\d{2})$/);
    if (match) {
      timePart = timePartWithOffset.slice(0, match.index);
      offsetSign = match[1];
      offsetHours = parseInt(match[2], 10);
      offsetMinutes = parseInt(match[3], 10);
    }
  }

  const [year, month, day] = datePart.split('-').map(Number);

  const [hours, minutes, seconds] = timePart.split(':').map(Number);

  const date = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));

  const offsetInMinutes = offsetHours * 60 + offsetMinutes;

  if (offsetSign === '+') {
    date.setUTCMinutes(date.getUTCMinutes() - offsetInMinutes);
  } else if (offsetSign === '-') {
    date.setUTCMinutes(date.getUTCMinutes() + offsetInMinutes);
  }

  return date;
};

const checkOverlap = (shift1, shift2) => {
  const start1 = parseDate(shift1.start_datetime_tz);
  const end1 = parseDate(shift1.end_datetime_tz);
  const start2 = parseDate(shift2.start_datetime_tz);
  const end2 = parseDate(shift2.end_datetime_tz);

  if (!start1 || !end1 || !start2 || !end2) return false;

  return start1 < end2 && start2 < end1;
};

const checkDuplicateShift = (data, index) => {
  if (data.length === 0 || data.length === 1) return false;
  if (data[index]?.start_datetime_tz && data[index]?.end_datetime_tz) {
    for (let i = 0; i < data.length; i++) {
      if (i !== index && checkOverlap(data[index], data[i])) {
        return true;
      }
    }
  }
  return false;
};

    const genrateTaskIcons = (item, index) => {
      const {feedbackResponseList, isFeedbackCmntLoader, showMessage,showFeedback,userTimezone} = this.props;
      var message = item.message && item.message[0];
      let messageTime ="";
      if(message){
        messageTime = moment(message.timestamp).format('MMMM Do YYYY, h:mm A');
      }
      if (!item.adjustment_type ) {
        return (
          <span className="vertical-sub mr-2 d-inline-block">
            {!item.new_shift &&
              <div
                onClick={() =>toggleInfo(item.session_id, item)}
                tabIndex={-1}
                role="button"
                className="d-inline-block"
              >
                <img src = {InfoIcon}
                  height="18"
                  width="18"
                  className="cursor-pointer mr-1"
                  alt="info"
                />
              </div>}



            {item.multi_validity && item.multi_validity.map((validityItem,index) => {
              if(validityItem === "1") {
                return(
                  <TooltipFormComponent
                    data={item}
                    keyname={`${index}${item.id}`}
                  key={`${index}${item.id}`}
                    saveValidity={saveValidity}
                    onValiditySelection={this.onValiditySelection}
                    ApprovedIcon={ValidApprovedIcon}
                    AwaitingIcon={ValidAwaitingIcon}
                    InvalidatedIcon={ValidInvalidatedIcon}
                    selectedItem={selectedItem}
                    valueValidity={valueValidity}
                    selectedId={selectedId}
                    openState={openMissingMealState}
                    dropdownOpen={dropdownOpen}
                    updateBoxes={updateBoxes}
                    dropdownToggle={dropdownToggle}
                    ValidityName="Valid"
                    stateText="openMissingMeal"
                    isValidityBoxLoading={isValidityBoxLoading}
                  />);
              } else if(validityItem === "5") {
                return(
                  <TooltipFormComponent
                    data={item}
                    keyname={`${index}${item.id}`}
                    key={`${index}${item.id}`}
                    saveValidity={saveValidity}
                    onValiditySelection={this.onValiditySelection}
                    ApprovedIcon={ForcePunchOutApprovedIcon}
                    AwaitingIcon={ForcePunchOutAwaitingIcon}
                    InvalidatedIcon={ForcePunchOutInvalidatedIcon}
                    selectedItem={selectedItem}
                    valueValidity={valueValidity}
                    selectedId={selectedId}
                    openState={openForceOutState}
                    dropdownOpen={dropdownOpen}
                    updateBoxes={updateBoxes}
                    dropdownToggle={dropdownToggle}
                    isValidityBoxLoading={isValidityBoxLoading}
                    ValidityName="Force Punch Out"
                    stateText="openForceOut"
                  />);
              } else if(validityItem==="4") {
                return(<TooltipFormComponent
                  data={item}
                  keyname={`${index}${item.id}`}
                  key={`${index}${item.id}`}
                  saveValidity={saveValidity}
                  onValiditySelection={this.onValiditySelection}
                  ApprovedIcon={ManualEntryApprovedIcon}
                  AwaitingIcon={ManualEntryAwaitingIcon}
                  InvalidatedIcon={ManualEntryInvalidatedIcon}
                  selectedItem={selectedItem}
                  valueValidity={valueValidity}
                  selectedId={selectedId}
                  openState={openPunchOutState}
                  dropdownOpen={dropdownOpen}
                  updateBoxes={updateBoxes}
                  dropdownToggle={dropdownToggle}
                  isValidityBoxLoading={isValidityBoxLoading}
                  ValidityName="Manual Entry"
                  stateText="openPunchOut"
                  childItem={childItem}
                />);
              } else if(validityItem==="7") {
                return(<TooltipFormComponent
                  data={item}
                  keyname={`${index}${item.id}`}
                  key={`${index}${item.id}`}
                  saveValidity={saveValidity}
                  onValiditySelection={this.onValiditySelection}
                  ApprovedIcon={MissingMealApprovedIcon}
                  AwaitingIcon={MissingMealAwaitingIcon}
                  InvalidatedIcon={MissingMealInvalidatedIcon}
                  selectedItem={selectedItem}
                  valueValidity={valueValidity}
                  selectedId={selectedId}
                  isValidityBoxLoading={isValidityBoxLoading}
                  openState={openMissingMealState}
                  dropdownOpen={dropdownOpen}
                  updateBoxes={updateBoxes}
                  dropdownToggle={dropdownToggle}
                  ValidityName="Missing Meal"
                  stateText="openMissingMeal"
                />);
              } else if(validityItem==="6") {
                return(<TooltipFormComponent
                  data={item}
                  keyname={`${index}${item.id}`}
                  key={`${index}${item.id}`}
                  saveValidity={saveValidity}
                  onValiditySelection={this.onValiditySelection}
                  ApprovedIcon={ManualCorrectionApprovedIcon}
                  AwaitingIcon={ManualCorrectionAwaitingIcon}
                  InvalidatedIcon={ManualEntryInvalidatedIcon}
                  selectedItem={selectedItem}
                  valueValidity={valueValidity}
                  selectedId={selectedId}
                  openState={openManualCorrectionState}
                  dropdownOpen={dropdownOpen}
                  isValidityBoxLoading={isValidityBoxLoading}
                  updateBoxes={updateBoxes}
                  dropdownToggle={dropdownToggle}
                  ValidityName="Manual Correction"
                  stateText="openManualCorrection"
                />)
              } else if(validityItem==="8") {
                return(<TooltipFormComponent
                  data={item}
                  keyname={`${index}${item.id}`}
                  key={`${index}${item.id}`}
                  saveValidity={saveValidity}
                  onValiditySelection={this.onValiditySelection}
                  ApprovedIcon={DelayedMealApprovedIcon}
                  AwaitingIcon={DelayedMealAwaitingIcon}
                  InvalidatedIcon={DelayedMealInvalidatedIcon}
                  selectedItem={selectedItem}
                  valueValidity={valueValidity}
                  selectedId={selectedId}
                  openState={openPunchOutState}
                  dropdownOpen={dropdownOpen}
                  isValidityBoxLoading={isValidityBoxLoading}
                  updateBoxes={updateBoxes}
                  dropdownToggle={dropdownToggle}
                  ValidityName="Delayed Meal"
                  stateText="openPunchOut"
                />);
              }
              return null;
            })}
             {items.multi_validity && items.multi_validity.map((validityItem,index) => {
              if(validityItem === "9") {
                return(
                  <TooltipFormComponent
                    data={item}
                    keyname={`${index}${item.id}`}
                    key={`${index}${item.id}`}
                    saveValidity={saveValidity}
                    onValiditySelection={this.onValiditySelection}
                    ApprovedIcon={MileageDeviationApprovedIcon}
                    AwaitingIcon={MileageDeviationAwaitingIcon}
                    InvalidatedIcon={MileageDeviationInvalidatedIcon}
                    selectedItem={selectedItem}
                    valueValidity={valueValidity}
                    selectedId={selectedId}
                    openState={openMileageDeviationState}
                    dropdownOpen={dropdownOpen}
                    updateBoxes={updateBoxes}
                    dropdownToggle={dropdownToggle}
                    ValidityName="Mileage deviation"
                    stateText="openMileageDeviation"
                    isValidityBoxLoading={isValidityBoxLoading}
                  />);
              } else if(validityItem === "10") {
                return(
                  <TooltipFormComponent
                    data={item}
                    keyname={`${index}${item.id}`}
                    key={`${index}${item.id}`}
                    saveValidity={saveValidity}
                    onValiditySelection={this.onValiditySelection}
                    ApprovedIcon={TimefenceApprovedIcon}
                    AwaitingIcon={TimefenceAwaitingIcon}
                    InvalidatedIcon={TimefenceInvalidatedIcon}
                    selectedItem={selectedItem}
                    valueValidity={valueValidity}
                    selectedId={selectedId}
                    openState={openTimefenceBreachState}
                    dropdownOpen={dropdownOpen}
                    updateBoxes={updateBoxes}
                    dropdownToggle={dropdownToggle}
                    ValidityName="Timefence Breach"
                    stateText="openTimefenceBreach"
                    isValidityBoxLoading={isValidityBoxLoading}
                    showCorrectionMessage = "showCorrectionMessage"
                  />);
                }
              })}
            {!item.new_shift && <div id={`commentout${item.id}`} className='d-inline-block' role="button"  tabIndex={-1} onClick={() => updateBoxes("openComment", !openCommentState, `commentout${item.id}`, item)}>
              <img src ={message && !("is_daily_compliance_message" in message) ? GreenCommentIcon : message && message.is_daily_compliance_message && item.feedback_non_others == false ? CommentIcon : message && message.is_daily_compliance_message && item.feedback_non_others == true ? GreenCommentIcon : !message && item.feedback_non_others ? GreenCommentIcon : item.feedback_non_others ? GreenCommentIcon : CommentIcon}
                height="18"
                width="18"
                alt = "comment-icon"
                className="cursor-pointer"
              />
            </div>}
            {`commentout${item.id}`===selectedId&&openCommentState ?
            <Popover placement="top-left" target={`commentout${item.id}`} isOpen={(`commentout${item.id}`===selectedId)&&openCommentState} className={`${styles['comment-popover-tooltip-wrapper']} ${styles['comment-screen-tooltip-wrapper']}`} >
              {isFeedbackCmntLoader ?
                <div className={styles['comment-feedback-loader']}>Loading...</div> : 
                <div className="task-override">
                  <div className="mb-2 d-inline-block">Comment :</div><button type="button" className="close d-inline-block" aria-label="Close" onClick = {() =>  updateBoxes("openComment", !openCommentState, `commentout${item.id}`)} ><span aria-hidden="true">×</span></button>
                  {/* {showMessage ?
                    : showFeedback ? feedbackResponseList && feedbackResponseList.length ?
                    */}
                    {item && item.message && item.message.map(i=>
                    <div className="comment-box-dt mb-3">
                      <div>by {i.user}</div>
                      <p className="mb-1">{moment(i.timestamp).tz(userTimezone).format('MMMM Do YYYY, h:mm A')}</p>
                      <div style={{'white-space':'pre-line'}}>{i.message.replace(";","\r\n").replace("-","\r\n")}</div>
                    </div>
                    )}
                    {feedbackResponseList && feedbackResponseList.length>0 && feedbackResponseList.map(i=>
                    <div>
                     <div className="comment-box-dt mb-2">
                      <div>by {i[0].created_by}</div>
                      {/*<p className="mb-1">{moment(i[0].create_date).format('MMMM Do YYYY, h:mm A')}</p>*/}
                      {<p className="mb-1">{i[0].create_date}</p>}
                     </div>
                     <FeedbackCommentModal feedbackResponse={i} dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} timeFormat24Hrs={this.props.timeFormat24Hrs}/>
                    </div>
                    )}
                  <div className="mt-1">
                  <Input
                    id="comment"
                    name="comment"
                    label="Comment"
                    type="text"
                    placeholder=" Add Comment"
                    onBlur={this.textInput}
                  />
                  </div>
                  <div className="text-right">
                    <Button className={`${styles['tooltip-btn']} mt-2`} onClick={() => this.saveCommentValidity(comment, item, 1)} disabled={isCommentLoading}>Save  <ButtonLoaderAtom active={isCommentLoading}/> </Button>
                  </div>
                  {/*feedbackResponseList && feedbackResponseList.length ? feedbackResponseList.map((i, index)=>
                    <div>
                      {i[0].surveyType && i[0].surveyType.find(item=>item!=='others') !== undefined && <FeedbackCommentModal feedbackResponse={i} dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>}
                    </div>
                    ): null
                  */}
                </div>
              }
            </Popover>
            :
            null}
            {userExtraData.company_permissions.feedback && !item.new_shift &&
              (item.feedback_others && item.feedback_others ?
                <div className='d-inline-block ml-1' role="button" onClick={()=>this.props.feedbackModal(item, childItem)}>
                  <img src={FeedbackBlue}
                    height="18"
                    width="18"
                    alt = "comment-icon"
                    className="cursor-pointer"
                  />
                </div>
                :
                <div className='d-inline-block ml-1' role="button">
                  <img src={FeedbackGrey}
                    height="18"
                    width="18"
                    alt = "comment-icon"
                    className="disabled-feedback"
                  />
                </div>
              )
            }
          </span>
        );
      } else {
        return (<span className="subtable-ui"/>);
      }
    }

    const genrateDateColTemplate = (item, i) => {
      const errorRow = errorId && errorId.find((errorItem) => (errorItem === item.id));
      let mode = 'Add';
     
      if(childItem.is_approved) mode = 'View';
      else if (childItem.adjustments && childItem.adjustments.length) mode = 'Edit';
      if (i === 0) {
        return (
          <td className="vertical-center date_col">
            <div>
              <span className="date-span">
                {childItem.date_display}
              </span>
              {item.color && <span className={styles['circle-wrap']} style={{ background: item.color }}/>}
              <span>
                <span title="Add Shift" className="shift-icon pt-1 ml-1" onClick={() => addShift(childItem)} role="button" tabIndex={-1}>
                  <img
                    src ={(errorRow===item.id)? AddShiftIcon : MealAddIcon}
                    alt="add shift"
                    height="18"
                    width="18"
                  />
                </span>
                <span className="shift-icon pt-1 ml-1" onClick={() => toggleModal(item, mode, childItem)}>
                  <span
                    title={ ` ${mode} Adjustments` }
                    className={`cursor-pointer ${styles['adjustment-icon']}`}
                  >
                   ADJ
                  </span>
                </span>
                {(userExtraData.company_permissions.paid_time_off && (userExtraData.company_permissions.company_owner || userExtraData.pto_supervisor))&& <span className="shift-icon pt-1 ml-1" onClick={() => togglePtoModal(item, 'Add', childItem)}>
                  <span
                    title={ `Add Paid Time Off Request` }
                    className={`cursor-pointer ${styles['adjustment-icon']}`}
                  >
                   PTO
                  </span>
                </span>
               }
                <span className="float-right">
                  {!item.new_shift &&
                    <span title="View Tracking" className="pb-0 pl-1 d-inline-block vertical-sub" onClick={() => trackingToggle(childItem)} role="button" tabIndex={-1}>
                      <img src={TrackingIcon}
                        height="18"
                        width="18"
                        className="cursor-pointer mr-1"
                        alt="tracking"
                      />
                    </span>}
                  {genrateTaskIcons(item)}
                </span>
              </span>
              <span className ="loader-subtable mr-2 float-right mt-1">
                {errorRow && (errorRow === item.id) && (loadedShift=== item.id)  ? 
                  <ButtonLoaderAtom active={isShiftLoading} /> : null}
                {(loadedShift=== item.id) && !(errorRow === item.id) ?
                  <GreenLoaderAtom active={isShiftLoading} color="primary"/> : null}
              </span>
            </div>
          </td>
        );
      } else if (item.adjustment_type ) {
        return (<td className="br-right date_col">
          <span className="ml-3 date-span">
            {item.name}
          </span></td>);
      } else if(item.pto_requests_type){
        return (<td className="br-right date_col">
        <span className="ml-3 date-span">
          {item.name}
        </span>
        <span className="float-right mr-5 mt-1">
        <DeleteIconAtom height="15" width="15" cursor={"pointer"} onClick={()=>deletePtoTimecard(item.request_master_id)}/>
        </span>
        </td>);
      }
      return (
        <td className= "vertical-center date_col">
          <div>
            <div className="d-inline-block ml-3 float-right ">
              {genrateTaskIcons(item)}
            </div>
            <div className ="loader-subtable float-right mr-2 mt-1">
              {(loadedShift=== item.id) && errorRow && (errorRow === item.id) &&
                  <ButtonLoaderAtom active={isShiftLoading} />}
                {(loadedShift=== item.id) && !(errorRow === item.id) &&
                  <GreenLoaderAtom active={isShiftLoading} color="primary"/>}
            </div>
          </div>
        </td>
      );
    }
    return (
      <Table hover size="sm" className="mt-0">
        {shiftData && shiftData.map((item, i) => {
          const startDateDisplay = moment(item.start_datetime_display).format('MMM, D');
          const endDateDisplay = moment(item.end_datetime_display).format('MMM, D');
          const startDate= moment(startDateDisplay);
          const endDate =moment(endDateDisplay);
         /* const message = item.message && item.message[0];*/
         /* let messageTime ="";*/
          const currentDate = moment().format('YYYY-MM-DD');
          /*if(message){
            messageTime = moment(message.timestamp).format('MMMM Do YYYY, h:mm A');
          }*/
          const errorRow = errorId && errorId.find((errorItem) => (errorItem === item.id));
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const d = new Date(childItem.date);
          d.setHours(0, 0, 0, 0);
          const disableApprove = today <= d;
          const shiftNumber = i + 1;
          const isDuplicateShift =checkDuplicateShift(shiftData,i)
          return(<tbody style={isDuplicateShift ?{backgroundColor:'rgb(255, 204, 210)'}:null} >
            <tr key={item.id} id={item.id} className={(isError && errorRow===item.id)? "vertical-center red-bg-error dt-row" : "vertical-center dt-row"} >
              {/* COL 1 START */}
              {genrateDateColTemplate(item, i)}
              {/* COL 2 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.punch_in} className="subtable-ui vertical-center punch_in_col"><b>--</b></td>
                : <td title={item.start_time} hidden={!showableAttributes.punch_in} className="text-ellipsis editable-wrapper vertical-center pt-0 pb-0 pr-0 punch_in_col timecard-td-padding">
                  <div className={`${styles['custom-width']} d-inline-block editable-text-left`} onFocus={() => selectedShift(item, 'start')} role = "button">
                    <EditableCellAtom
                      value={item.start_time}
                      valueKey="start_time"
                      update={this.updateTable}
                      details={item}
                      validate= {this.validate}
                      editable={editable}
                      disabled={childItem.is_approved ||
                        //  (childItem.date > currentDate) || 
                        ((loadedShift === item.id) && isShiftLoading) }
                      tabIndex={`${dtNumber}${shiftNumber}01`}
                    />
                  </div>
                  <div className="managingDTdate pl-1 text-left">
                    { (endDate > startDate  || (childItem.date !== item.date)) && item.end_datetime ?
                      <div>
                      ({moment(item.start_datetime).format('MMM, DD')})
                      </div> : null}
                  </div>
                </td>
              }
              {/* COL 3 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.meal_start} className="subtable-ui vertical-center punch_in_col"><b>--</b></td>
                : <td hidden={!showableAttributes.meal_start} className={item.meals.length >1 ? "vertical-center editable-wrapper p-0 punch_in_col" : "vertical-center subtable-ui editable-wrapper p-0 punch_in_col"} >
                  <Table className="mt-0 ml-0 bg-transparent sub-table-meal table-layout-fixed">
                    {item.meals.map((mealItem, index) => {
                      const mealStartDisplay = moment(mealItem.start_datetime_display).format('MMM, D');
                      const mealEndDisplay = moment(mealItem.end_datetime_display).format('MMM, D');
                      const mealStart = moment(mealStartDisplay);
                      const mealEnd = moment(mealEndDisplay);
                      return(<tbody className="border-none">
                        <tr key={mealItem.start_time} className ="subtable-meal-hover">
                          <td title={mealItem.start_time} hidden={!showableAttributes.meal_start} className={((item.meals.length >1) && (index+1)!==item.meals.length)? "border-none meal-editable vertical-center":"border-none vertical-center timecard-td-padding"} style={{overflow: 'visible', position: 'relative'}}>
                            <div role="button" tabIndex="0" className={`${styles['pay-meal']} d-inline-block outline-none`} onClick={!childItem.is_approved ? !mealItem.is_meal_paid ? () => openPayMealModal(mealItem, childItem) : () => changeMealStatus(mealItem, childItem): null} style={childItem.is_approved ? {cursor: 'not-allowed', position: 'absolute', left: '4px', top:'8px'} : {position: 'absolute', left: '4px', top:'8px'}}>
                            {
  mealItem?.extra_data?.meal_rule_is_meal_override === true ? (
    <img src={PayMealIcon} alt="pay meal" height="13" width="13"/>
  ) : (
    childItem.daily_timecard === true && !mealItem.new_meal && mealOverrideSettings ? (
      mealItem.is_meal_paid ? (
        <img src={PayMealIcon} alt="pay meal" height="13" width="13"/>
      ) : (
        <img src={GreyPayMealIcon} alt="pay meal" height="14" width="14"/>
      )
    ) : null
  )
}

                            </div>
                            <div
                              className={`${styles['custom-width']} d-inline-block editable-text-left`}
                              onFocus={() => selectedShift(mealItem, 'meal-start', item)}
                              id={`start_time_${mealItem.id}`}
                              role = "button"
                            >
                              <EditableCellAtom
                                value={mealItem.start_time}
                                update={this.updateTable}
                                valueKey="start_time"
                                id={`start_time_${mealItem.id}`}
                                details={mealItem}
                                validate= {this.validate}
                                disabled={childItem.is_approved ||
                                  //  (childItem.date > currentDate) || 
                                  ((loadedShift === item.id) && isShiftLoading)}
                                tabIndex={`${dtNumber}${shiftNumber}${index+1}2`}
                              />
                            </div>
                            {!mealItem.new_meal && <div className="managingDTdate text-left pl-1" >
                              {(mealItem.start_datetime && ((mealStart>startDate) || (mealEnd > mealStart) || (childItem.date !== item.date))) ?
                                `(${moment(mealItem.start_datetime).format('MMM, DD')})`
                                 : null
                              }
                            </div>}
                          </td>
                        </tr>
                      </tbody>)
                    })}
                  </Table>
                </td>
              }
              {/* COL 4 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.meal_end} className="subtable-ui vertical-center punch_in_col"><b>--</b></td>
                : <td hidden={!showableAttributes.meal_end} className={item.meals.length>1 ? "p-0 vertical-center editable-wrapper punch_in_col" : "p-0 vertical-center subtable-ui editable-wrapper punch_in_col"}>
                  <Table className="mt-0 ml-0 bg-transparent sub-table-meal table-layout-fixed">
                    {item.meals.map((mealItem, index) => {
                      const mealStartDisplay = moment(mealItem.start_datetime_display).format('MMM, D');
                      const mealEndDisplay = moment(mealItem.end_datetime_display).format('MMM, D');
                      const mealStart = moment(mealStartDisplay)
                      const mealEnd = moment(mealEndDisplay)
                      return(<tbody className="border-none pr-1">
                        <tr key={mealItem.end_time} className ="subtable-meal-hover">
                          <td title={mealItem.end_time} hidden={!showableAttributes.meal_end} className={((item.meals.length >1) && (index+1)!==item.meals.length)? "border-none meal-editable vertical-center":"border-none vertical-center timecard-td-padding"} style={{overflow: 'visible', position: 'relative'}}>
                            <div
                              className={`${styles['custom-width']} d-inline-block editable-text-left`}
                              onFocus={() => selectedShift(mealItem, 'meal-end', item)}
                              role = "button"
                              id={`end_time_${mealItem.id}`}
                            >
                              <EditableCellAtom
                                value={mealItem.end_time}
                                update={this.updateTable}
                                valueKey="end_time"
                                details={mealItem}
                                id={`end_time_${mealItem.id}`}
                                validate= {this.validate}
                                tabIndex={`${dtNumber}${shiftNumber}${index+1}3`}
                                disabled={childItem.is_approved ||
                                  //  (childItem.date > currentDate) || 
                                  ((loadedShift === item.id) && isShiftLoading)}
                              />
                            </div>
                            {!mealItem.new_meal && mealItem.end_datetime && ((mealEnd>mealStart) || (mealEnd > startDate) || (childItem.date !== item.date)) &&
                              <div className="managingDTdate pl-1 text-left">
                                ({moment(mealItem.end_datetime).format('MMM, DD')})
                              </div>}
                            {!item.new_shift && <div title="Add meal" className="shift-icon" onClick={() => this.addMeal(item, childItem)} role="button" tabIndex={-1} disabled={childItem.is_approved} style={{position: 'absolute', right: '1px', top:'8px'}}>
                              <img src ={(errorRow===item.id) ? AddShiftIcon : MealAddIcon} alt= "add meal" height="12" width="12"/>
                            </div>}
                          </td>
                        </tr>
                      </tbody>)
                    })}
                  </Table>
                </td>
              }
              {/* COL 5 START */}
              
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.punch_out} className="subtable-ui vertical-center punch_in_col"><b>--</b></td>
                : <td title={item.end_time} hidden={!showableAttributes.punch_out} className="editable-wrapper vertical-center pr-0 pt-0 pb-0 punch_in_col timecard-td-padding">
                  <div className={`${styles['custom-width']} d-inline-block editable-text-left`}  onFocus={() => selectedShift(item, 'end')} role = "button" tabIndex={-1}>
                    <EditableCellAtom
                      value={item.end_time}
                      update={this.updateTable}
                      valueKey="end_time"
                      details={item}
                      validate = {this.validate}
                      disabled={childItem.is_approved ||
                        //  (childItem.date > currentDate) ||
                         ((loadedShift === item.id) && isShiftLoading)}
                      tabIndex={`${dtNumber}${shiftNumber}${item.meals.length+1}4`}
                    />
                  </div>
                  <div className="managingDTdate pl-1 text-left">
                    { ((endDate > startDate)  || (childItem.date !== item.date)) && item.end_datetime ?
                      <div>
                       ({moment(item.end_datetime).format('MMM, DD')})
                      </div> : null}
                  </div>
                </td>
              }
              {/* COL 6 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.total_shift_time}
                  className="vertical-center subtable-ui punch_in_col"
                >

                  <b>--</b>
                </td>
                : <td hidden={!showableAttributes.total_shift_time}
                  className="vertical-center subtable-ui punch_in_col text-right"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.total_time) : item.total_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(item.total_time)
                      : item.total_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.total_time) : item.total_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(item.total_time)
                    : item.total_time_display
                  }
                </td>
              }
              {/* COL 6.5 START */}
              {(childItem.daily_timecard) ? <td hidden={!showableAttributes.total_day_time} className="vertical-center subtable-ui text-right punch_in_col"
                title={
                  userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.total_time_seconds) : childItem.total_time)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.total_time_seconds)
                    : childItem.total_time
                }
              >
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.total_time_seconds) : childItem.total_time)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(childItem.total_time_seconds)
                  : childItem.total_time
                }
              </td> : <td hidden={!showableAttributes.total_day_time} className="vertical-center subtable-ui punch_in_col" />}

              {/* COL 7 START */}
              {(!item.adjustment_type && !item.pto_requests_type)&& <td hidden={!showableAttributes.meal_period} className="vertical-center subtable-ui pt-0 punch_in_col" style={{paddingLeft: '8px', paddingRight: '8px'}}>
                  {(item.meal_paid_time_display && item.meals.length >=1 && (item.meal_paid_time!==0 || item.meal_unpaid_time !==0)) &&
                    <div style={{fontSize: '11px', marginTop:'6px', textAlign: 'right'}}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? (
                          (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? <span>
                        {item.meal_paid_time!== 0 &&
                          <div style={item.meal_unpaid_time === 0 ? {fontSize: '14px'}: null}>{item.meal_unpaid_time!==0  ? 'P: ' : null }{item.meal_paid_time ? secondsToHours(item.meal_paid_time) : 0}</div>
                        }
                        {item.meal_unpaid_time !==0 &&
                          <div style={item.meal_paid_time === 0 ? {color: '#EC4409', fontSize: '14px'} : {color: '#EC4409'}}>{item.meal_paid_time!==0 ? 'U: ': null}{(item.meal_paid_time === 0 && item.meal_unpaid_time !== 0) ? '-' : null}{item.meal_unpaid_time ? secondsToHours(item.meal_unpaid_time) : 0}</div>
                        }
                        </span>
                        : <span>
                        {item.meal_paid_time!== 0 &&
                          <div style={item.meal_unpaid_time === 0 ? {fontSize: '14px'}: null}>{item.meal_unpaid_time!==0  ? 'P: ' : null }{item.meal_paid_time_display ? item.meal_paid_time_display : null}</div>
                        } 
                        {item.meal_unpaid_time !==0 &&
                          <div style={item.meal_paid_time === 0 ? {color: '#EC4409', fontSize: '14px'} : {color: '#EC4409'}}>{item.meal_paid_time!==0 ? 'U: ': null}{(item.meal_paid_time === 0 && item.meal_unpaid_time !== 0) ? '-' : null}{item.meal_unpaid_time_display ? item.meal_unpaid_time_display : null}</div>
                        }
                        </span>)
                        : basicSettingDetail.timecard_decimal_view 
                        ? <span>
                          {item.meal_paid_time!== 0 &&
                            <div style={item.meal_unpaid_time === 0 ? {fontSize: '14px'}: null}>{item.meal_unpaid_time!==0  ? 'P: ' : null }{item.meal_paid_time ? secondsToHours(item.meal_paid_time) : 0}</div>
                          }
                          {item.meal_unpaid_time !==0 &&
                            <div style={item.meal_paid_time === 0 ? {color: '#EC4409', fontSize: '14px'} : {color: '#EC4409'}}>{item.meal_paid_time!==0 ? 'U: ': null}{(item.meal_paid_time === 0 && item.meal_unpaid_time !== 0) ? '-' : null}{item.meal_unpaid_time ? secondsToHours(item.meal_unpaid_time) : 0}</div>
                          }
                          </span>
                        : <span>
                          {item.meal_paid_time!== 0 &&
                            <div style={item.meal_unpaid_time === 0 ? {fontSize: '14px'}: null}>{item.meal_unpaid_time!==0  ? 'P: ' : null }{item.meal_paid_time_display ? item.meal_paid_time_display : null}</div>
                          } 
                          {item.meal_unpaid_time !==0 &&
                            <div style={item.meal_paid_time === 0 ? {color: '#EC4409', fontSize: '14px'} : {color: '#EC4409'}}>{item.meal_paid_time!==0 ? 'U: ': null}{(item.meal_paid_time === 0 && item.meal_unpaid_time !== 0) ? '-' : null}{item.meal_unpaid_time_display ? item.meal_unpaid_time_display : null}</div>
                          }
                          </span>
                      }
                    </div>
                  }
                   {/*<div>
                    {basicSettingDetail.timecard_decimal_view
                      ? <span>{item.meal_time ? `-${secondsToHours(item.meal_time)}` : null}</span>
                      : <span>{item.meal_time_display ? `-${item.meal_time_display}` : null }</span>
                    }
                  </div>*/}
              </td>}
              { (item.adjustment_type || item.pto_requests_type) && <td hidden={!showableAttributes.meal_period} className="vertical-center subtable-ui punch_in_col" />}
              {/* COL 7 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.rt}
                  className="vertical-center subtable-ui punch_in_col text-right"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.regular_time) : item.regular_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(item.regular_time)
                      : item.regular_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.regular_time) : item.regular_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(item.regular_time)
                    : item.regular_time_display
                  }
                </td>
                : ((childItem.daily_timecard && i === 0) ? <td hidden={!showableAttributes.rt} className="vertical-center subtable-ui text-right punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.regular_time) : childItem.regular_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(childItem.regular_time)
                      : childItem.regular_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.regular_time) : childItem.regular_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.regular_time)
                    : childItem.regular_time_display
                  }
                </td>: <td hidden={!showableAttributes.rt} className="vertical-center subtable-ui punch_in_col" />)
              }
              {/* COL 10 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.ot}
                  className="vertical-center subtable-ui punch_in_col text-right"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.over_time) : item.over_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(item.over_time)
                      : item.over_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ( (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.over_time) : item.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(item.over_time)
                    : item.over_time_display
                  }
                </td>
                : (childItem.daily_timecard && i === 0) ? <td hidden={!showableAttributes.ot} className="vertical-center subtable-ui text-right punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.over_time)
                    : childItem.over_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.over_time)
                    : childItem.over_time_display
                  }
                </td> : <td hidden={!showableAttributes.ot} className="vertical-center subtable-ui punch_in_col" />
              }
              {/* COL 11 START */}
              {item.adjustment_type || item.pto_requests_type
                ? <td hidden={!showableAttributes.dot}
                  className="vertical-center text-right subtable-ui punch_in_col"
                  title={
                    userTimecardFilters && userTimecardFilters.data
                      ? ( (userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.double_over_time) : item.double_over_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(item.double_over_time)
                      : item.double_over_time_display
                  }
                >
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(item.double_over_time) : item.double_over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(item.double_over_time)
                    : item.double_over_time_display
                  }
                </td>
                : (childItem.daily_timecard && i === 0)
                  ? <td hidden={!showableAttributes.dot} className="vertical-center subtable-ui text-right punch_in_col"
                    title={
                      userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                        : basicSettingDetail.timecard_decimal_view
                        ? secondsToHours(childItem.double_over_time)
                        : childItem.double_over_time_display
                    }
                  >
                    { userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(childItem.double_over_time)
                      : childItem.double_over_time_display
                    }
                  </td> : <td hidden={!showableAttributes.dot} className="vertical-center subtable-ui punch_in_col" />
              }

            {/* COL 12 START */}
              {userExtraData.company_permissions.mileage ? ((childItem.daily_timecard && i === 0)
                ? <td title={childItem.mileage.toFixed(1)} hidden={!showableAttributes.mileage} className="mileage editable-wrapper vertical-center p-0 mileage_col">
                  <div className="d-inline-block p-1 editable-text-right" onFocus={() => selectedShift(item, 'mileage')} role = "button">
                    <EditableCellAtom
                      value={childItem.mileage.toFixed(1)}
                      valueKey="mileage"
                      update={this.updateTable}
                      details={childItem}
                      validate= {this.validateMileage}
                      editable={true}
                      disabled={isShiftLoading}
                      tabIndex={`${dtNumber}${shiftNumber}`}
                      mileageValue
                    />
                  </div>
                </td> : <td hidden={!showableAttributes.mileage} className="subtable-ui vertical-center mileage_col"></td> ) : null}

            {/* COL 13 START */}
              {item.adjustment_type
                ? <td hidden={!showableAttributes.edit}
                    className="vertical-center text-center subtable-ui punch_in_col"
                  />

                :  item.pto_requests_type ?
                <td className="vertical-center subtable-ui text-center punch_in_col">
                <EditIconAtom
                     /* onClick={() => goTo(`/activity/timecard/custom/list/${childItem.id}/split/add`,item, childItem)}*/
                     onClick={() => togglePtoModal(item,'Edit', childItem)}
                     disabled={!(userExtraData.company_permissions.paid_time_off && (userExtraData.company_permissions.company_owner || userExtraData.pto_supervisor))}
                     className="cursor-pointer"
                     height="15"
                     width="15"
                 />
                </td>
                :childItem.daily_timecard
                  ? <td disabled = {this.props.editDisabled} hidden={!showableAttributes.edit} className="vertical-center subtable-ui text-center punch_in_col"
                  >
                    {!item.new_shift && !(isError && errorRow===item.id) && !childItem.is_approved &&
                    <EditIconAtom
                     /* onClick={() => goTo(`/activity/timecard/custom/list/${childItem.id}/split/add`,item, childItem)}*/
                      onClick={() => {
                        sessionStorage.setItem("remember_daily_timecard_row", item.id)
                        !this.props.editDisabled && this.props.showTasksModal(item, childItem);
                      }}
                      disabled = {this.props.editDisabled}
                      className="cursor-pointer"
                      height="15"
                      width="15"
                    />}

                    {childItem.is_approved &&
                    <EyeOpenIconAtom
                      height="18"
                      width="18"
                      onClick={() => this.props.showTasksModal(item, childItem)}
                      className="cursor-pointer"
                    />}
                  </td> : <td hidden={!showableAttributes.edit} className="vertical-center subtable-ui punch_in_col" />
              }
            {/* COL 11 START */}
            <td hidden={!showableAttributes.approve} className="vertical-center subtable-ui text-center pt-0 pb-0 punch_in_col">
              {((childItem.daily_timecard || (childItem.adjustments && childItem.adjustments.length)) && (i===0) ) ? <div title={childItem.is_approved ? childItem.approved_datetime_display : "Click to approve daily timecard"} className="pb-0">
                {childItem.is_approved 
                  ? <Button
                    type="button"
                    size="sm"
                    color={
                      childItem.need_correction || disableApprove
                        ? 'default'
                        : 'secondary'
                    }
                    disabled={childItem.need_correction || disableApprove || (isApproving && i===approvedRejectIndex)}
                    onClick={() =>{
                        this.setState({approvedRejectIndex: i});
                        disapproveDailyTimecard(childItem)
                    }}
                    style={{ fontSize: '11px' }}
                    title="Click to disapprove the daily timecard"
                    className={`${styles['approve-btn-width']} d-flex justify-content-center align-items-center`}
                  >
                    Reject <span style={{height:'14px'}}><ButtonLoaderAtom active={isApproving && i===approvedRejectIndex}/> </span>
                  </Button>
                  : ( <Button
                    type="button"
                    size="sm"
                    color={
                      childItem.need_correction || disableApprove
                        ? 'default'
                        : 'secondary'
                    }
                    disabled={childItem.need_correction || disableApprove || (isApproving && i===approvedRejectIndex)}
                    onClick={() => {
                      this.setState({approvedRejectIndex: i});
                      approveDailyTimecard(childItem)
                    }}
                    style={{ fontSize: '11px' }}
                    title="Click to approve the daily timecard"
                    className={`${styles['approve-btn-width']} d-flex justify-content-center align-items-center`}
                  >
                    Approve <span style={{height:'14px'}}><ButtonLoaderAtom active={isApproving && i===approvedRejectIndex}/> </span>
                   </Button>)
                }
                </div> : 
                item.pto_requests_type ? ( <Button
                  type="button"
                  size="sm"
                  color={'secondary'}
                  onClick={() => savePtoTimecardData(item?.request_id , item , item?.request_status === 'APPROVED' ?'REJECTED' : 'APPROVED')}
                  style={{ fontSize: '11px' }}
                  title={`Click to ${item?.request_status  === 'APPROVED' ? 'Reject' :'Approve'} the pto request`}
                  className={`${styles['approve-btn-width']}`}
                  disabled={item?.policy_type==='PAID_HOLIDAYS' || !(userExtraData.company_permissions.paid_time_off && (userExtraData.company_permissions.company_owner || userExtraData.pto_supervisor))}
                >
                  {item?.request_status  === 'APPROVED' ? 'Reject' :'Approve'}
                 </Button>) : null }
                </td>
            </tr>
            {(isInfoLoading && ((selectedId === item.id) || (selectedId === item.session_id))) &&
              <InfoFormModalComponent
                isOpen={isInfoLoading}
                toggle={toggleInfo}
                data={selectedShiftData}
                activityLogs={activityLogs}
                isInfoModalOpen={isInfoModalOpen}
                fetchNextData={fetchNextData}
                items={items}
                showMore={showMore}
                dateDisplay={childItem.date_display}
                dateFormatDisplay={this.props.dateFormatDisplay}
                timeFormat24Hrs={this.props.timeFormat24Hrs}
                userTimezone={this.props.userTimezone}
              />
            }
          </tbody>)
       })}
      </Table>
    );
  }
};

DailyTimecardSubTableComponent.propTypes = {
  PunchIn: PropTypes.bool,
  PunchOut: PropTypes.bool,
  basicSettingDetail: PropTypes.object.isRequired,
  updateTable: PropTypes.func.isRequired,
  isValidityBoxLoading: PropTypes.bool,
  isShiftLoading: PropTypes.bool,
  isInfoLoading: PropTypes.bool,
  toggleModal: PropTypes.func,
  childItem: PropTypes.object,
  goTo: PropTypes.func,
  saveValidity: PropTypes.func.isRequired,
  activityLogs: PropTypes.object,
  isInfoModalOpen: PropTypes.bool,
  openMissingMealState: PropTypes.bool,
  openForceOutState: PropTypes.bool,
  openPunchOutState: PropTypes.bool,
  openCommentState: PropTypes.bool,
  openManualCorrectionState: PropTypes.bool,
  timeFormat24Hrs: PropTypes.object,
};

export default DailyTimecardSubTableComponent;