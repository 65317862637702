import React from 'react';

import PropTypes from 'prop-types';

import LogoImgBlue from './metc-blue-logo.png';

const LogoImgBlueAtom = props => (
  <img src={LogoImgBlue} alt="logo" {...props} />
);

LogoImgBlueAtom.defaultProps = {
  height: 220,
};

LogoImgBlueAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default LogoImgBlueAtom;
