import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as SubCompanyDucks from 'ducks/vendors/subCompany';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';

import VendorSubCompanyFormSection from 'sections/vendors/subCompanyForm/Form';

import { NAV } from 'constants/vendors';

/**
 * VendorSubCompanyFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link CompanyFilterSection}
 *    - {@link CompanyListSection}
 *
 * Fetch Needs:
 *    None
 */
class VendorSubCompanyFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isCompany: true, isCompanyUser: false, isSubCompanyAddOpen: true, isSubCompanyEditOpen: true,isEdit:false };
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    const { router: { route: { match: { params } } } } = this.context;
    if (params.id) {
      const cookieKey = cookie.load("sub_company_edit_form_info");
      if(cookieKey) {
        this.setState({isSubCompanyEditOpen: JSON.parse(cookieKey)});
      }
    }
    if(!params.id){
      const cookieKey = cookie.load("sub_company_add_form_info");
      if(cookieKey) {
        this.setState({isSubCompanyAddOpen: JSON.parse(cookieKey)});
      }
    }
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isSubCompanyEditOpen, isSubCompanyAddOpen } = this.state;
    if (params.id) {
      this.setState({ isSubCompanyEditOpen: !isSubCompanyEditOpen });
      cookie.save("sub_company_edit_form_info", !isSubCompanyEditOpen);
    }
    else {
      this.setState({ isSubCompanyAddOpen: !isSubCompanyAddOpen });
      cookie.save("sub_company_add_form_info", !isSubCompanyAddOpen);
    }
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isLoading: true });
    if (params.id) {
      Promise.all([
        this.props.InfoDucks.getInformations({route: 'vendor.sub_company.edit'}),
        this.props.SubCompanyDucks.getSubCompany({ id: params.id })
      ])
        .then(() => {
            this.setState({ isLoading: false, isEdit:true });
          })
        .catch(() => this.setState({ isLoading: false }));
    }
    if(!params.id){
      this.props.InfoDucks.getInformations({route: 'vendor.sub_company.add'})
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { isLoading, isSubCompanyEditOpen, isSubCompanyAddOpen, isEdit} = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.VendorSubCompanyEditFormPage : NAV.VendorSubCompanyAddFormPage}
        infoList={infoList}
        isInfoOpen={params.id ? isSubCompanyEditOpen :isSubCompanyAddOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.fetchData}
      >
        <VendorSubCompanyFormSection isLoading={isLoading} isEdit={isEdit}/>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  subCompanyDetail: SubCompanyDucks.subCompanyDetail(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  SubCompanyDucks: bindActionCreators(SubCompanyDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

VendorSubCompanyFormPage.propTypes = {
  subCompanyDetail: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

VendorSubCompanyFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorSubCompanyFormPage);
