class TemplateDataEntity {
    constructor(object={}){
        this.label = object?.template_data?.label || '';
        this.outcome = object?.template_data?.outcome || '';
        this.questions = object?.template_data?.questions || [];
        this.scoring_actions = object?.template_data?.scoring_actions
        this.id = object.id;
        this.recurring = object?.recurring ?? false;
   }
  }
  
  export default TemplateDataEntity;