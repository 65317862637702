import ApiGateway from 'lib/api-gateway';
import AppConfig from 'helpers/config';


class PaymentMethodGateway {

  toString() {
    return 'PaymentMethodGateway';
  }

  async getInfoList(params) {
    const infoListConfig =  AppConfig.server.apiHost + '/t2b/documents/kw-articles-list/';
    const apiGateway = new ApiGateway(infoListConfig);
    const response = await apiGateway.load(params);
    const infoList = response.data;
    return infoList;
  }

  async saveCard(companyId, sourceId, email, name, phoneNumber){
    const data = {
      email: email,
      name: name,
      phone: phoneNumber,
      source_id: sourceId,
    };
    const saveCardConfig =  AppConfig.server.apiHost + '/payment/customer/' + companyId + '/';
    const apiGateway = new ApiGateway(saveCardConfig);
    const response = await apiGateway.update(data);
    return response.data;
  }

  async getCardDetails(companyId){
    const cardConfig =  AppConfig.server.apiHost + '/payment/customer/' + companyId + '/';
    const apiGateway = new ApiGateway(cardConfig);
    const response = await apiGateway.load();
    return response.data;
  }

  async createCustomer(companyId,email, name, phoneNumber){
    const data = {
      email: email,
      name: name,
      phone: phoneNumber,
      company_id: companyId,
    };
    const saveCardConfig =  AppConfig.server.apiHost + '/payment/customer/';
    const apiGateway = new ApiGateway(saveCardConfig);
    const response = await apiGateway.save(data);
    return response.data;
  }

  async deleteCard(companyId, sourceId){
    const deleteCardConfig =  AppConfig.server.apiHost + '/payment/customer/' + companyId + '/card/' + sourceId + '/';
    const apiGateway = new ApiGateway(deleteCardConfig);
    const response = await apiGateway.delete();
    return response.data;
  }

  async cancelCompanyPlan(companyId){
    const data = {
      company_id: companyId
    };
    const cancelPlanConfig =  AppConfig.server.apiHost + '/payment/customer/cancel/';
    const apiGateway = new ApiGateway(cancelPlanConfig);
    const response = await apiGateway.save(data);
    const plan = response.data;
    return plan;
  }

  async authenticatePassword(email, password, device) {
    const signInReqObj = {
      email: email,
      password: password,
      device: device,
    };
    const signinConfig = AppConfig.server.apiHost + '/company/login/';
    const apiGateway = new ApiGateway(signinConfig);
    const response = await apiGateway.save(signInReqObj);
    return response.data;
  }
}



export default PaymentMethodGateway;
