import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Breadcrumb from 'reactstrap/lib/Breadcrumb';
import BreadcrumbItem from 'reactstrap/lib/BreadcrumbItem';

const backG={
  backgroundColor:"transparent",
}

const BreadcrumbComponent = (props) => {
  const { breadcrumbItems } = props;
  return (
    <Breadcrumb style={backG} className="pb-4">
      {breadcrumbItems.map((item, index) => {
        if (index === breadcrumbItems.length - 1) {
          return (<BreadcrumbItem key={item.label} active>{item.label}</BreadcrumbItem>);
        }
        return (
          <BreadcrumbItem key={item.label}>
            <Link to={item.href}>{item.label}</Link>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

BreadcrumbComponent.defaultProps = {
  breadcrumbItems: [],
};

BreadcrumbComponent.propTypes = {
  breadcrumbItems: PropTypes.array.isRequired,
};

export default BreadcrumbComponent;
