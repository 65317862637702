import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import {EVENT_TYPES} from 'employees/usecases/string.js';



class employeeFormUsecase {

  employeeAPIGateway = null;

  // Constructor initializes the use case with API gateway and event emitter
  constructor(employeeAPIGateway) {
    this.employeeAPIGateway = employeeAPIGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }


/**
 * Returns the string representation of the use case.
 * @returns {string} The string 'employeeFormUsecase'.
 */
  toString() {
    return 'employeeFormUsecase';
  }

  /**
   * Returns the observable from the event emitter.
   * @returns {Observable} The observable from the event emitter.
   */
  getObservable() {
    return this.eventEmitter.getObservable();
  }

  /**
   * Sets up the initial data and configuration for the use case.
   *
   * @param {Object} data - The initial data to set up.
   * @param {string} inputType - The type of input (e.g., 'password').
   * @param {boolean} invite - Whether an invite is needed.
   * @param {boolean} phoneFieldMandatory - Whether the phone field is mandatory.
   * @param {Array} tagsList - The list of tags.
   * @param {Array} policiesList - The list of policies.
   */
  setup_data(data, inputType, invite, phoneFieldMandatory, tagsList,policiesList) {
    this.data = {
      ...data,
      employee_role: (data.employee_role && data.employee_role.value) || data.employee_role,
      country: (data.country && data.country.value) || data.country,
      company: (data.company && data.company.value) || data.company,
      department: (data.department && data.department.value) || data.department,
      team: (data.team && data.team.value) || data.team,
      state: (data.state && data.state.value) || data.state,
      wage : data.wage ? data.wage : null,
      email : data.email ? data.email : null,
      tags: tagsList,
      policies : policiesList
    };
    this.inputType = inputType;
    this.invite = invite;
    this.phoneFieldMandatory = phoneFieldMandatory;
  }

  /**
   * Sets up the job assignment and unassignment objects.
   *
   * @param {Object} assignJobObj - The job assignment object.
   * @param {Object} unassignObj - The job unassignment object.
   */
  setup_data1(assignJobObj, unassignObj){
    this.assignJobObj = assignJobObj;
    this.unassignObj= unassignObj
  }

  /**
   * Emits an event to set the dropdown values for various lists.
   */
  setDropDownValues(){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_DROPDOWN_VALUES,
      data: {
        roleList: this.roleList,
        countryList: this.countryList,
        companyList: this.subCompanies,
        departmentList: this.departmentList,
        teamList: this.teamList,
        multiTeamList: this.multiTeamList,
        multiDepartmentList: this.multiDepartmentList,
        stateList: this.stateList,
        infoList: this.infoList,
        employeeData: this.employeeData,
      }
    });
  }

  /**
   * Retrieves the login link for the specified employee.
   *
   * @param {number} employeeId - The ID of the employee.
   * @returns {Promise<void>} A promise that resolves when the login link is retrieved.
   */
 async getLoginLink(employeeId){
    const employeeData = await this.employeeAPIGateway.getLoginLink(employeeId);
     this.eventEmitter.emit({
      type: EVENT_TYPES.GET_LINK_SUCCESS,
      login_url : employeeData.login_url,
    });

  }

  /**
   * Deletes specified tags for the given employee.
   *
   * @param {number} id - The ID of the employee.
   * @param {Array} tags - The list of tags to delete.
   * @returns {Promise<void>} A promise that resolves when the tags are deleted.
   */
  async deleteTags(id, tags){
   return this.employeeAPIGateway.deleteMarkedTags(id, {tag_collection: tags});
  }

  /**
   * Updates the name of the specified tags for the given employee.
   *
   * @param {number} id - The ID of the employee.
   * @param {Array} tags - The list of tags with updated names.
   * @returns {Promise<void>} A promise that resolves when the tag names are updated.
   */
  async updateTagName(id, tags){
    return this.employeeAPIGateway.updateTagName(id, tags);
   }

  /**
   * Shows a toast notification with the specified type and message.
   *
   * @param {string} type - The type of the toast (e.g., 'SUCCESS', 'ERROR').
   * @param {string} msg - The message to display in the toast.
   */
  showToast(type, msg){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_TOAST,
      toastType: type,
      message: msg,
    });
  }

  /**
   * Retrieves the data for a specific employee and emits an event with the data.
   *
   * @param {number} employeeId - The ID of the employee.
   * @returns {Promise<void>} A promise that resolves when the employee data is retrieved.
   */
  async getEmployeeData(employeeId){
    this.employeeData = await this.employeeAPIGateway.getEmployee(employeeId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_EMPLOYEE_DATA,
      data: this.employeeData,
    });
  }

  /**
   * Retrieves the tags associated with the specified employee.
   *
   * @param {Object} data - The parameters for retrieving the tags.
   * @returns {Promise<void>} A promise that resolves when the tags are retrieved.
   */
   async getEmployeeTags(data){
    this.tagsList = await this.employeeAPIGateway.getEmployeesTags(data);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_TAGS_LIST,
      data: this.tagsList,
    });
  }

  /**
   * Retrieves the policies associated with the specified employee.
   *
   * @param {Object} data - The parameters for retrieving the policies.
   * @returns {Promise<void>} A promise that resolves when the policies are retrieved.
   */
  async getEmployeesPolicies(data){
    this.policiesList = await this.employeeAPIGateway.getEmployeesPolicies(data);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_POLICIES_LIST,
      data: this.policiesList,
    });
  }

  /**
   * Adds a new tag for the employee and emits an event if successful.
   *
   * @param {Object} data - The tag data.
   * @returns {Promise<void>} A promise that resolves when the tag is added.
   * @throws {GraniteError} Throws an error if validation fails.
   */
  async addEmployeeTag(data){
    let errors = { };
    if (data.name && data.name.length > 25){
      errors.name = 'Tag name cannot have more than 25 characters';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    await this.employeeAPIGateway.addEmployeeTags(data);
    await this.getEmployeeTags({ paginate: false, tag_type: 3 });
    this.eventEmitter.emit({
      type: EVENT_TYPES.NEW_TAG_ADDED,
    });
  }

  /**
   * Retrieves initial field values based on the company ID and emits events for dropdown values.
   *
   * @param {number} companyId - The ID of the company.
   * @returns {Promise<void>} A promise that resolves when the initial values are retrieved and set.
   */
  async getInitialFieldValues(companyId) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.subCompanies = await this.employeeAPIGateway.getAllSubCompanies({ paginate: false });
    const subCompanyIds = this.subCompanies.map((i) => i.id);
    Promise.all([
      this.employeeAPIGateway.getRoleList({ company_id: JSON.stringify([companyId]), paginate: false }),
      this.employeeAPIGateway.getDepartmentList({ company_id: JSON.stringify([companyId]), paginate: false, get_active: true, get_active_teams: true }),
      this.employeeAPIGateway.getTeamList({ company_id: JSON.stringify(subCompanyIds), paginate: false, get_active: true }),
      this.employeeAPIGateway.getCountryList({ paginate: false }),
      this.employeeAPIGateway.getInfoList({ route: 'employee.add' }),
      this.employeeAPIGateway.getMultiDepartmentList({ company_id: JSON.stringify([companyId]), paginate: false, get_active: true, get_active_teams: true }),
    ])
      .then((list) => {
        this.roleList = list[0];
        this.departmentList = list[1].filter((item)=> item.id !== -1);
        this.teamList = list[2];
        this.multiTeamList = list[2];
        this.countryList = list[3];
        this.infoList = list[4];
        this.multiDepartmentList = list[5];
        // this.multiDepartmentList.splice(0, 0, { id: -1, name: "No Department" });

        this.employeeAPIGateway.getStateList({ country_id: this.countryList[0].id, paginate: false })
          .then((stateList) => this.stateList = stateList)
          .then(() => this.setDropDownValues())
          .then(() => this.eventEmitter.emit({
            type: EVENT_TYPES.HIDE_LOADER,
       }))
      });
    }

  /**
   * Retrieves employee data and initial field values for a specific employee.
   *
   * @param {number} employeeId - The ID of the employee.
   * @returns {Promise<void>} A promise that resolves when the data and initial values are retrieved and set.
   */
  async getEmployeeDataAndInitialFieldValues(employeeId) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    await this.getEmployeeData(employeeId);
    Promise.all([
      this.employeeAPIGateway.getAllSubCompanies({ paginate: false }),
      this.employeeAPIGateway.getRoleList({ company_id: JSON.stringify([this.employeeData.company]), paginate: false }),
      this.employeeAPIGateway.getDepartmentList({ company_id: JSON.stringify([this.employeeData.company]), paginate: false, get_active: true, get_active_teams: true}),
      this.employeeAPIGateway.getTeamList({ department_id:this?.employeeData?.department,paginate: false, get_active: true}),
      this.employeeAPIGateway.getMultiTeamList({ department_ids:JSON.stringify(this?.employeeData?.supervisory_departments.filter((i)=>i!=="true")),paginate: false, get_active: true}),
      this.employeeAPIGateway.getCountryList(),
      this.employeeAPIGateway.getInfoList({ route: 'employee.edit' }),
      this.employeeAPIGateway.getMultiDepartmentList({ company_id: JSON.stringify([this.employeeData.company]), paginate: false, get_active: true, get_active_teams: true}),

    ])
    .then((list) => {
      this.subCompanies = list[0];
      this.roleList = list[1];
      this.departmentList = list[2].filter((item)=> item.id !== -1);
      this.teamList = list[3];
      this.multiTeamList = list[4];
      this.countryList = list[5];
      this.infoList = list[6];
      this.multiDepartmentList = list[7];
      // this.multiDepartmentList.splice(0, 0, { id: -1, name: "No Department" });

      this.employeeAPIGateway.getStateList({ country_id: this.employeeData.country || this.countryList[0].id, paginate: false })
        .then((stateList) => this.stateList = stateList )
        .then(() => this.setDropDownValues())
        .then(() => this.eventEmitter.emit({
            type: EVENT_TYPES.HIDE_LOADER,
          })
        );
    });
  }


/**
 * Handles changes to the department selection.
 * @param {number|string|string[]} departmentId - The ID of the department or an array of department IDs.
 * @returns {Promise<void>} A promise that resolves when the department change has been processed.
 */
  async handleDepartmentChange(departmentId) {
    if (Array.isArray(departmentId)) {
      this.multiTeamList = await this.employeeAPIGateway.getTeamList({ department_ids: JSON.stringify(departmentId), paginate: false, get_active: true });
    } else {
      this.teamList = await this.employeeAPIGateway.getTeamList({ department_id: departmentId, paginate: false, get_active: true });
    }
    this.setDropDownValues();
  }

  /**
   * Handles changes to the country selection.
   * @param {number} countryId - The ID of the country.
   * @returns {Promise<void>} A promise that resolves when the country change has been processed.
   */
  async handleCountryChange(countryId) {
    this.stateList = await this.employeeAPIGateway.getStateList({ country_id: countryId, paginate: false });
    this.setDropDownValues();
  }

  /**
   * Retrieves system settings and emits an event with the data.
   * @returns {Promise<void>} A promise that resolves when the system settings have been retrieved and emitted.
   */
  async getSystemSettings() {
    this.systemSettingList = await this.employeeAPIGateway.getSystemSettings();
    this.eventEmitter.emit({
      type: EVENT_TYPES.SYSTEM_SETTINGS,
      data: this.systemSettingList,
    });
  }

  /**
   * Handles changes to the company selection and updates dropdown lists.
   * @param {number} companyId - The ID of the company.
   * @returns {Promise<void>} A promise that resolves when the company change has been processed.
   */
  async handleCompanyChange(companyId) {
    Promise.all([
      this.employeeAPIGateway.getDepartmentList({ company_id: companyId ? JSON.stringify([companyId]) : '', paginate: false, get_active: true }),
      this.employeeAPIGateway.getRoleList({ company_id: companyId ? JSON.stringify([companyId]) : '', paginate: false }),
      this.employeeAPIGateway.getMultiDepartmentList({ company_id: companyId ? JSON.stringify([companyId]) : '', paginate: false, get_active: true }),
    ])
    .then((list) => {
      this.departmentList = list[0].filter((item)=> item.id !== -1);
      this.roleList = list[1];
      this.multiDepartmentList = list[2];
      // this.multiDepartmentList.splice(0, 0, { id: -1, name: "No Department" });

    })
    .then(() => this.setDropDownValues());
  }

  /**
   * Handles clicks on audit items and updates the status of the audit.
   * @param {Object} item - The audit item that was clicked.
   * @returns {Promise<void>} A promise that resolves when the audit status has been updated.
   */
  async handleAuditClick(item) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_SELECTED_ITEM,
      selectedItem: item
    });
    this.employeeAPIGateway.postKioskAudits({ id: item.id, add: !item.verified })
      .then((response) => {
        if (response) {
          if (!item.verified) this.showToast('SUCCESS', 'Image is marked as valid');
          else this.showToast('SUCCESS', 'Image is marked as invalid now.');
          this.getKioskList(this.employeeData.id);
        }
      })
      .catch((e) => {
        if (!item.verified) this.showToast('ERROR', e.errors[0]);
        else this.showToast('ERROR', 'Unable to mark image as invalid.');
        this.eventEmitter.emit({
          type: EVENT_TYPES.SET_SELECTED_ITEM,
          selectedItem: null
        });
        this.eventEmitter.emit({
          type: EVENT_TYPES.KIOSK_LIST,
          kioskList: this.kioskList,
        });
      });
  }

  /**
   * Retrieves kiosk audit logs based on the provided parameters.
   * @param {Object} params - The parameters for retrieving the kiosk audit logs.
   * @returns {Promise<void>} A promise that resolves when the kiosk audit logs have been retrieved and emitted.
   */
  async getKioskList(params) {
    const { id, start_date, end_date, ...rest } = params;
    this.kioskList = await this.employeeAPIGateway.getKioskAuditLogs(id, { ordering: "-create_date", paginate: false, start_date, end_date });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SET_SELECTED_ITEM,
      selectedItem: null
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.KIOSK_LIST,
      kioskList: this.kioskList,
    });
  }

  /**
   * Loads the kiosk list with a loader.
   * @param {Object} params - The parameters for retrieving the kiosk list.
   * @returns {Promise<void>} A promise that resolves when the kiosk list has been retrieved and the loader is hidden.
   */
  async loadKioskList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_KIOSK_LOADER,
    });
    await this.getKioskList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_KIOSK_LOADER,
    });
  }

  /**
   * Retrieves audit logs based on the employee ID and emits an event with the logs.
   * @param {number} id - The ID of the employee.
   * @returns {Promise<void>} A promise that resolves when the audit logs have been retrieved and emitted.
   */
  async getAuditLogs(id) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_AUDIT_LOGS_LOADER,
    });
    this.auditLogsList = await this.employeeAPIGateway.getAuditLogs({ entity_id: id, entity_type: 'employeeprofile', paginate: false });
    this.eventEmitter.emit({
      type: EVENT_TYPES.AUDIT_LIST,
      auditLogsList: this.auditLogsList,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_AUDIT_LOGS_LOADER,
    });
  }

  /**
   * Executes form submission or update for an employee.
   * @param {boolean} addNewEmployee - Whether to add a new employee or update an existing one.
   * @returns {Promise<Object|null>} A promise that resolves to the employee object if successful, otherwise null.
   * @throws {GraniteError} Throws an error if validation fails.
   */

  async execute(addNewEmployee) {

    let errors = { };
    const wage = '^[-+]?[0-9]*\\.?[0-9]+$';
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
    if (this.data.email && !emailRegex.test(this.data.email)) {
      errors.email = 'Enter valid email address';
    }

    if (!this.data.first_name) {
      errors.first_name = 'Enter First Name';
    }
    if(!this.data.employee_role){
      errors.employee_role = 'Enter Employee Role';
    }
    if(!this.data.company){
      errors.company = 'Select Company';
    }

    if(this.data.password && this.data.password.length < 8){
      errors.password = 'Password should contain atleast 8 characters';
    }
    if (this.password && this.password.length >= 8 && this.password && (this.password.match(passwordRegex) || this.password.match(passwordSequential) || this.password.match(passwordRepeated ))) {
      errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
    }
    const employeeRoleObj = this.roleList.find((i) => i.id === this.data.employee_role);
   // kiosk_user_access key is for user to have kiosk login accesss
    if (
      ((this.data.kiosk_user)
        || (employeeRoleObj && employeeRoleObj.admin_access)
        || (employeeRoleObj && employeeRoleObj.kiosk_user_access)
        || ( !this.phoneFieldMandatory)) && !this.data.id
      && !this.data.password) {
      errors.password = 'Enter Password';
    }

    if (
        (
        ((
          this.data.kiosk_user
          || (employeeRoleObj && employeeRoleObj.admin_access)
          || (employeeRoleObj && employeeRoleObj.kiosk_user_access)
          || ( !this.phoneFieldMandatory)
          ) && !this.data.id)
        || (this.data.showPasswordFields && this.data.password)
        )
        && !this.data.confirm_password && (this.inputType==="password")
      ){
        errors.confirm_password = 'Enter Confirm Password';
      }

    if(
        ( (!this.data.id && this.data.kiosk_user)
          || (employeeRoleObj && employeeRoleObj.admin_access)
          || (employeeRoleObj && employeeRoleObj.kiosk_user_access)
          || (this.data.showPasswordFields && this.data.password)
          || ( !this.phoneFieldMandatory)
        )
        && this.data.password && this.data.confirm_password &&
        (this.data.password !== this.data.confirm_password) && (this.inputType==="password")){
      errors.confirm_password = 'Password does not match';
    }

    if(!this.data.kiosk_user && !this.data.phone_no
        && this.phoneFieldMandatory && (employeeRoleObj && employeeRoleObj.mobile_app_access)){
      errors.phone_no = 'Enter Phone Number';
    }

    if (this.data.wage && !this.data.wage.toString().match(wage)) {
      errors.wage = 'Enter a valid number';
    }


    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    let employee = null;

    if(this.data.id){

       employee = await this.employeeAPIGateway.updateEmployee(this.data , this.invite );
       if(employee && employee.id){
        this.showToast('SUCCESS', 'Employee successfully updated.');
       }
       else this.showToast('ERROR', 'Unable to update employee information');
    }
    else{

       employee = await this.employeeAPIGateway.createEmployee(this.data);
        if(employee){
          const roleSelected = this.roleList.find((i) => i.id === employee.employee_role);
          if(employee.phone_no && !employee.kiosk_user && roleSelected.mobile_app_access){
            this.showToast('SUCCESS', 'Employee has been notified via text message to login to Worksana.');
          }
          else this.showToast('SUCCESS', 'Employee has been added successfully');
        }
        else this.showToast('ERROR', 'Unable to create employee');
    }

    if(addNewEmployee){
      this.eventEmitter.emit({
        type: EVENT_TYPES.ADD_NEW,
      });
    }
    else{
      this.eventEmitter.emit({
        type: EVENT_TYPES.LIST_VIEW,
        data: employee.id,
      });
    }
    return employee;
  }

  /**
   * Retrieves assigned jobs and emits an event with the job data.
   * @param {Object} params - The parameters for retrieving the assigned jobs.
   * @returns {Promise<void>} A promise that resolves when the assigned jobs have been retrieved and emitted.
   */
  async getAssignedJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.assignedJobs = await this.employeeAPIGateway.getAssignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  /**
   * Retrieves unassigned jobs and emits an event with the job data.
   * @param {Object} params - The parameters for retrieving the unassigned jobs.
   * @returns {Promise<void>} A promise that resolves when the unassigned jobs have been retrieved and emitted.
   */
  async getUnassignedJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.employeeAPIGateway.getUnassignedJobsList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
  }

  /**
   * Fetches both assigned and unassigned jobs and emits events with the job data.
   * @param {Object} assignObj - The parameters for retrieving assigned jobs.
   * @param {Object} unassignObj - The parameters for retrieving unassigned jobs.
   * @returns {Promise<void>} A promise that resolves when both assigned and unassigned jobs have been retrieved and emitted.
   */
  async fetchJobs(assignObj, unassignObj) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.unassignedJobs = await this.employeeAPIGateway.getUnassignedJobsList(unassignObj);
    this.assignedJobs = await this.employeeAPIGateway.getAssignedJobsList(assignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_ASSIGNED_JOBS,
      data: this.assignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_UNASSIGNED_JOBS,
      unassignedJobs: this.unassignedJobs,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  /**
   * Assigns jobs to employees and updates the job lists.
   * @param {Object} params - The parameters for assigning jobs.
   * @returns {Promise<void>} A promise that resolves when the jobs have been assigned and the job lists updated.
   */
  async assignJobs(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_BUTTON_LOADER,
    });
    this.postAssignJobsList = await this.employeeAPIGateway.assignJobs(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.ASSIGN_SUCCESS,
    });
    await this.fetchJobs(this.assignJobObj, this.unassignObj);
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_BUTTON_LOADER,
    });
  }

  /**
   * Deletes a job assignment and updates the job lists.
   * @param {Object} params - The parameters for deleting the job assignment.
   * @returns {Promise<void>} A promise that resolves when the job has been deleted and the job lists updated.
   */
  async deleteJob(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.updatedEmployeeJobsList = await this.employeeAPIGateway.deleteEmployeeJob(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.DELETE_SUCCESS,
    });
    let assignFetch = { ...this.assignJobObj, page: 1 };
    await this.fetchJobs(assignFetch, this.unassignObj);
  }

  /**
   * Enables resetting of biometric authentication.
   * @param {Object} data - The data for enabling biometric reset.
   * @returns {Promise<void>} A promise that resolves when the biometric reset has been enabled.
   */
  async enableResetBiometric(data) {
    return this.employeeAPIGateway.enableResetBiometric(data);
  }

  /**
   * Enables resetting of PIN code authentication.
   * @param {Object} data - The data for enabling PIN code reset.
   * @returns {Promise<void>} A promise that resolves when the PIN code reset has been enabled.
   */
  async enableResetPincode(data) {
    return this.employeeAPIGateway.enableResetPincode(data);
  }

  /**
   * Updates user job assignments.
   * @param {Object} params - The parameters for updating user job assignments.
   * @returns {Promise<void>} A promise that resolves when the user job assignments have been updated.
   */
  async updateUserJobAssignment(params) {
    await this.employeeAPIGateway.updateUserJobAssignment(params);
    // this.eventEmitter.emit({
    //   type: EVENT_TYPES.UPDATE_USER_PREFERENCE,
    // });
  }


}

export default employeeFormUsecase;
