import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';

import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';

import styles from './styles.module.scss';
import cookie from 'react-cookies';


/**
 * PayrollSettingListPage -> PayrollSettingListSection -> PayrollSettingTableComponent
 *
 * Props:
 *    - payroll Setting list
 */
const PayrollSettingTableComponent = (props) => {
  const { data, userExtraData } = props;
  const payrollTablePageSize = cookie.load('advanced_payroll_table_size') || 10;
  return (
    <div className="ml-3 mr-4">
      <Table hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <th width="8%" className="text-center">Action</th>
          <th>name</th>
          <th>Default</th>
          <th>California 7 day rule</th>
          <th>Overtime Applicable</th>
          <th>Additional Overtime Applicable</th>
        </tr>
      </thead>
      { <tbody>
        {data.results && data.results.map(item => (
          <tr key={item.id}>
            <td className="text-center" title="Edit Payroll Setting">
              <Link to={`/payroll/setting/${item.id}/edit?isAdvancedSettingsVisible=${item.extra_data && item.extra_data.payroll_applicability && userExtraData && userExtraData.company_permissions && userExtraData.company_permissions.advance_payroll_settings == true && item.extra_data.payroll_applicability == true ? true : false}&page=1&page_size=${payrollTablePageSize}`}>
                <EditIconAtom />
              </Link>
            </td>
            <td title={item.name} className={styles['name-td-width']}>{item.name}</td>
            {item.is_default ? <td>Yes</td> : <td>No</td>}
            {item.is_cal_7_day_rule && item.is_overtime_applicable
              ? <td>Yes</td>
              :<td>No</td>
            }
            {item.is_overtime_applicable ? <td>Yes</td> : <td>No</td>} 
            {item.is_additional_overtime_applicable && item.is_overtime_applicable
               ? <td>Yes</td> : <td>No</td>
            }
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody> }
      </Table>
    </div>
  );
};

PayrollSettingTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PayrollSettingTableComponent;
