import React, { PureComponent } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import styles from "./styles.module.scss";
import ButtonLoaderAtom from "atoms/ButtonLoader";

class EmployeeInactiveConfirmationModal extends PureComponent {
  render() {
    const {
      isOpen,
      toggle,
      item,
      updateStatusofEmployee,
      refreshLoader,
      cancelLoader,
      updateJobAssignmentofEmployee,
      editForm,
      type,
      updateEditFormFalse
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader toggle={() => toggle()}> {editForm ?"Warning":"Delete Confirmation"}</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "16px" }}>
          { editForm?
          'If you mark Employees as inactive, paid holidays related to them (if any) will also be deleted.'
          :
          'Do you want to remove the job allocation from the employees as well ?'
          }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className={styles.cancel_btn}
            onClick={() => {
              editForm 
                ? toggle()
                //  updateJobAssignmentofEmployee(true)
                : updateStatusofEmployee(false);
            }}
            disabled = {refreshLoader}
          >
            {editForm?'Cancel':'No'}
          </Button>
          <Button
            type="submit"
            color="secondary"
            onClick={() => {
              if(editForm) {
                updateEditFormFalse(false)
              } else {
                updateJobAssignmentofEmployee(false)
              }
            }}
          >
            {editForm?'Ok':'Yes'} <ButtonLoaderAtom active={refreshLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmployeeInactiveConfirmationModal;
