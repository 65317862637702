import React from 'react';

import PropTypes from 'prop-types';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

class NoMatch extends React.Component {
  componentWillMount() {
    const { router: { history } } = this.context;
    history.push('/dashboard');
  }

  render() {
    const { location } = this.props;
    return (
      <Row>
        <Col xs={8}>
          <h2>No match found for <code>{location.pathname}</code></h2>
          <p>Please recheck the url you are trying to access.</p>
        </Col>
      </Row>
    );
  }
}

NoMatch.propTypes = {
  location: PropTypes.object.isRequired,
};

NoMatch.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NoMatch;
