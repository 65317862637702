import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';

import * as RouteDucks from 'ducks/routes';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

class VendorRoleFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.goToAddRole = this.goToAddRole.bind(this);
  }

  goToAddRole(){
    const { router: { history } } = this.context;
    const { location: { pathname, search } } = this.props;
    history.push({
      pathname: '/vendor/role-setting/add',
      search: queryString.stringify({
        parent: `${pathname}${search}`,
      })
    });
  }

  render() {
    return (
      <Row className="filter ml-3 mr-4">
        <Col className="px-0">
          <section className="float-right mt-4">
            <Button
              className="pr-4 pl-4 mr-0"
              color="accent"
              onClick={() => this.goToAddRole()}
            >
              Add Role
            </Button>
          </section>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({});

VendorRoleFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

VendorRoleFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorRoleFilterSection);