import React from 'react';
import { server } from 'helpers/config';

import PropTypes from 'prop-types';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

const GeocodingOption = server.geocodingOption;

class MarkerComponent extends React.PureComponent {
  componentDidMount() {
    if (!this.marker) this.renderMarker();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map) ||
      (JSON.stringify(this.props.position) !== JSON.stringify(prevProps.position))
    ) {
      // The relevant props have changed
      if (Object.keys(this.props.position).length) {
        this.renderMarker();
      }
      else if (this.marker) {
        this.marker.setMap(null);
        // this.marker = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  renderMarker() {
    const { map, google,draggable, mapCenter, setShapeInfo, data, onClick, label, zIndex } = this.props;
    let { position } = this.props;
    const pos = position || mapCenter;
    position = new google.maps.LatLng(pos.lat, pos.lng);

    const pref = {
      map,
      position,
      draggable,
      label,
      zIndex,
    };

    if (this.marker) this.marker.setMap(null);
    this.marker = new google.maps.Marker(pref);

    google.maps.event.addListener(this.marker, 'dragend', (event) => {
      const marker = {
        type: 'Point',
        coordinates: [ event.latLng.lng(), event.latLng.lat() ],
        address: '',
      };
      const latLng = {
        lat: parseFloat(marker.coordinates[1]),
        lng: parseFloat(marker.coordinates[0]),
      };
      if(GeocodingOption == 'google maps'){
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              marker.address = results[0].formatted_address.toString();
              setShapeInfo(marker);
            }
          }
        });
      }
      else{
        this.props.getAddressForMarker({position: latLng})
         .then((res) => {
           if(res.action.payload.data){
            const data = res.action.payload.data;
            marker.address = data.display_name.toString();
            setShapeInfo(marker);
          }
        })
      }
    });

    this.marker.addListener('click', (event) => {
      if (onClick) onClick(data, this.marker, event);
    });
  }

  render() {
    return null;
  }
}

MarkerComponent.propTypes = {
  map: PropTypes.object,
  draggable: PropTypes.bool,
  position: PropTypes.object,
  label: PropTypes.string,
  setShapeInfo: PropTypes.func,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

export default MarkerComponent;
