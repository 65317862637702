 import React from 'react';
import moment from 'moment';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Card } from 'reactstrap';

import SectionLoaderAtom from 'atoms/SectionLoader';

import PlanImage from './standard-plan.png';
import ActiveUsersImage from './active-users.png';
import TotalMoneyImage from './dollar-bag.png';
import styles from './styles.module.scss';
import { UncontrolledTooltip } from 'reactstrap';

const CompanyPlanComponent = ({ companyPlanDetails, toggleModal, isLoading, customerDetail }) => {
  if(isLoading) return <div style={{ height: '80vh' }}><SectionLoaderAtom active /></div>;
  const billingStartDate = companyPlanDetails.billing_start_date ? moment(companyPlanDetails.billing_start_date).format('DD MMM YYYY') : null;
  const billingEndDate = companyPlanDetails.billing_end_date ? moment(companyPlanDetails.billing_end_date).format('DD MMM YYYY') : null;
  /*const paymentPlans = companyPlanDetails && companyPlanDetails.plan;*/
  const charge = (companyPlanDetails && companyPlanDetails.charge_rate)*(companyPlanDetails && companyPlanDetails.quantity);
  const trialEndDate = moment(customerDetail && customerDetail.trial_end);
  const today = moment();
  let trial_period_days;
  let trialPeriodDays;
  if(trialEndDate < today){
   trial_period_days = 0;
  }
  else{
    trial_period_days= (moment().diff(trialEndDate, 'days') - 2);
    trialPeriodDays = Math.abs(trial_period_days);   
  }
  return (
      <div className="pb-5">
        <Row className="pl-3">
          <Col className="pl-0 mt-3">
            <span className="mb-3 mt-3" style={{fontSize: '115%'}}>Current Bill Cycle: <span style={{fontWeight: 'bold'}}>{billingStartDate}  -  {billingEndDate}</span></span>
          </Col>
        </Row>
        <Row className="mt-5">
          <Card className={`text-center d-inline-block ui-card w-25 mr-5 h-100 ${styles['plan-card']} pos-relative`}>
            <div className={styles['img-wrapper']}>
              <img src={PlanImage} alt='' height="72" width="72" />
            </div>
            <div className="mt-3 pt-2">
              <h2 id="charge_rate" className={`${styles['text_overflow']}`}>
                <UncontrolledTooltip placement="left" target="charge_rate">
                  ${companyPlanDetails && companyPlanDetails.charge_rate}
                </UncontrolledTooltip>
                ${companyPlanDetails && companyPlanDetails.charge_rate} 
              </h2>
              <h5>per User/Month</h5>
            </div>
          </Card>
          <h2 className={styles['sign']}>X</h2>
          <Card className={`text-center d-inline-block ui-card w-25 mr-5 h-100 ${styles['plan-card']}`}>
            <div className={styles['img-wrapper']}>
              <img src={ActiveUsersImage} alt='' height="72" width="72" />
            </div>
            <div className="mt-3 pt-2">              
              <h2 className={`${styles['text_overflow']} `}> {companyPlanDetails && companyPlanDetails.quantity} </h2>
              <h5>Number of Active Users Billed</h5>
            </div>
          </Card>
          <h2 className={styles['sign']}>=</h2>
          <Card className={`text-center d-inline-block ui-card w-25 h-100 ${styles['plan-card']}`}>
            <div className={styles['img-wrapper']}>
              <img src={TotalMoneyImage} alt='' height="72" width="72" />
            </div>
            <div className="mt-5">
              <h2 id="charge" className={`${styles['charge_overflow']} mt-5`}>
                <UncontrolledTooltip placement="left" target="charge">
                  ${charge}
                </UncontrolledTooltip>
                ${charge.toFixed(2)}
              </h2>
            </div>
          </Card>
        </Row>
        <Row className="mt-4 custom_line_height w-50">
              <Col md={4} className="text_align pr-0">
                <div>Trial Period Days </div>
                <div>Discount</div>
                <div>Monthly Service Fee</div>
                <div style={{fontWeight:'bold'}}>Current Amount Due</div>
              </Col>
              <Col md={1} className="px-0">
                <div>: </div>
                <div>:</div>
                <div>:</div>
                <div style={{fontWeight:'bold'}}>:</div>
              </Col>
              <Col className="pr-0">
                <div>{trialPeriodDays ? trialPeriodDays  : 0}</div>
                <div className={`${styles['text_bottom_overflow']}`} id="discount">
                  <UncontrolledTooltip placement="left" target="discount">
                    {companyPlanDetails.discount_unit === 'Amount' ? '$': null}
                    {companyPlanDetails && companyPlanDetails.discount}
                    {companyPlanDetails.discount_unit === 'Amount' ? null : '%'}
                  </UncontrolledTooltip>
                  {companyPlanDetails.discount_unit === 'Amount' ? '$': null}
                  {companyPlanDetails && companyPlanDetails.discount}
                  {companyPlanDetails.discount_unit === 'Amount' ? null : '%'}
                </div>
                <div id="service_fees" className={`${styles['text_bottom_overflow']}`}>
                   <UncontrolledTooltip placement="left" target="service_fees">
                    ${companyPlanDetails && companyPlanDetails.service_fees}
                  </UncontrolledTooltip>
                  ${companyPlanDetails && companyPlanDetails.service_fees}
                </div>
                <div>
                  {customerDetail.commercial_status==='Trial Period' || moment(customerDetail.trial_end) >= moment()  ?
                  '$0':
                  <div className={`${styles['text_bottom_overflow']}`} id="total_amount" style={{fontWeight:'bold'}}>
                    <UncontrolledTooltip placement="left" target="total_amount">
                    ${companyPlanDetails && companyPlanDetails.total_amount}
                  </UncontrolledTooltip>
                    ${companyPlanDetails && companyPlanDetails.total_amount}
                  </div>
                  }
                </div>
              </Col>
            </Row>
    </div>
  );
};

export default CompanyPlanComponent;
