import React from 'react';

import PropTypes from 'prop-types';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

let timeout;
class RectangleComponent extends React.PureComponent {
  componentDidMount() {
    if (!this.rectangle) this.renderRectangle();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map) ||
      (this.props.fillColor !== prevProps.fillColor) ||
      (JSON.stringify(this.props.bounds) !== JSON.stringify(prevProps.bounds))
    ) {
      // The relevant props have changed
      if (this.props.bounds.length) {
        this.renderRectangle();
      }
      else if (this.rectangle) {
        this.rectangle.setMap(null);
      }
    }
  }

  componentWillUnmount() {
    if (this.rectangle) {
      this.rectangle.setMap(null);
    }
  }

  renderRectangle() {
    const {
      map,
      google,
      draggable,
      editable,
      bounds,
      fillColor,
      onClick,
      setShapeInfo,
      data, 
    } = this.props;
    const pref = {
      map,
      bounds: new google.maps.LatLngBounds(
        { lat: bounds[0][2][1], lng: bounds[0][2][0] }, // SW
        { lat: bounds[0][0][1], lng: bounds[0][0][0] }, // NE
      ),
      fillOpacity: 0.5,
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: fillColor || '#162d6e',
      strokeColor: fillColor || '#162d6e',
      draggable,
      editable,
      onClick,
    };
    if (this.rectangle) {
      this.rectangle.setMap(null);
    }
    this.rectangle = new google.maps.Rectangle(pref);
    this.rectangle.addListener('bounds_changed', () => {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(() => {
        const coords = this.rectangle.getBounds();
        const SW = coords.getSouthWest();
        const NE = coords.getNorthEast();
        const latLng = new google.maps.LatLng(
          (SW.lat() + NE.lat()) / 2,
          (SW.lng() + NE.lng()) / 2,
        );
        const latLong = {lat: (SW.lat() + NE.lat()) / 2 , lng: (SW.lng() + NE.lng()) / 2}
        const rectangle = {
          type: 'Rectangle',
          coordinates: [
            [
              [NE.lng(), NE.lat()],
              [SW.lng(), NE.lat()],
              [SW.lng(), SW.lat()],
              [NE.lng(), SW.lat()],
              [NE.lng(), NE.lat()],
            ]
          ],
          address: '',
        };
        if(GeocodingOption == 'google maps'){
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ 'location': latLng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              rectangle.address = results[0].formatted_address.toString();
              setShapeInfo(rectangle);
            }
          }
        });
        }
        else{
          this.props.getAddressForMarker({position: latLong})
          .then((res) => {
             if(res.action.payload.data){
              const data = res.action.payload.data;
              rectangle.address = data.display_name.toString();
              setShapeInfo(rectangle);
            }
          })
        }
      }, 500);
    });

    this.rectangle.addListener('click', (event) => {
      if (onClick) onClick(data, this.rectangle, event);
      return false;
    });
  }

  render() {
    return null;
  }
}

RectangleComponent.propTypes = {
  map: PropTypes.object,
  bounds: PropTypes.array,
  draggable: PropTypes.bool,
  editable: PropTypes.bool,
  setShapeInfo: PropTypes.func,
  onClick: PropTypes.func,
};

export default RectangleComponent;
