import React from 'react';

import PropTypes from 'prop-types';

import InfoGreyIcon from './grey-info-icon.png';

const InfoGreyIconAtom = props => (
  <img src={InfoGreyIcon} alt="Info" {...props} />
);

InfoGreyIconAtom.defaultProps = {
  height: 14,
};

InfoGreyIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default InfoGreyIconAtom;