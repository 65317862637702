import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";
import queryString from "query-string";
import Link from "react-router-dom/Link";

import { toast } from "react-toastify";
import Table from "reactstrap/lib/Table";
import { getMealTypes } from "ducks/vendors/adjustmentTypes";
import connect from "react-redux/lib/connect/connect";

import DeleteIcon from "components/jobs/jobGeofence/Delete.png";
import SortHeaderComponent from "components/common/SortHeader";
import SectionLoaderAtom from "atoms/SectionLoader";
import EditableCellAtom from "atoms/EditableCell";

import styles from "components/jobs/jobEmployeeEdit/styles.module.scss";
import EmployeeDeleteModal from "components/jobs/jobEmployeeEdit/EmployeeDeleteModal";

// import TimecardIcon from './timecards.svg';

/**
 * EmployeeListPage -> EmployeeListSection -> EmployeeTableComponent
 *
 * Props:
 *    - employee list
 */
const MealAdjustmentsListSection = (props, context) => {
  const {
    router: {
      route: {
        location: { pathname, search, query },
      },
    },
  } = context;
  const {
    data,
    isLoading,
    fetchData,
    putMealTypes,
    status
  } = props;
  const [isdeleteModal, setIsdeleteModal] = useState(false);
  const [isSubmittingModal, setIsSubmittingModal] = useState(false);
  const [id, setId] = useState({mealId:null , empIds:null, mealRule:{}});
  const [count, setCount] = useState(0);

  const handleDelete = async (data) => {
    const params = {
      id: id.mealId,
    };
    const obj = {
      page_size: props?.pageSize,
      status:status
    };
    const empObj = {
      id: id.mealId,
      employee_ids: id.empIds,
      rule_configuration: id.mealRule,
      operation: "remove",
    };
    

    try {
      setIsSubmittingModal(true);
      await props.deleteAssignedEmp(params).then(() => {
        fetchData(obj);
      });
    } catch (e) {
      toast.error(e.non_field_errors[0] || "An error occurred");
    }
    setIsSubmittingModal(false);
    setIsdeleteModal(false);
  };

  const handleClick = (data) => {
    if (data) {
      const empIds = data?.employee_list_names.map((item)=>item.employee_id);
      setId({
        empIds: empIds,
        mealId: data?.id,
        mealRule: data?.rule_configuration,
      });
      setCount(data?.enrolled_employees_count || 0);
      }

    setIsdeleteModal(!isdeleteModal);
  };

  if (isLoading) return <SectionLoaderAtom active />;
  return (
    <div
      style={{ boxShadow: "0px 3px 6px rgba(0,0,0,0.16)" }}
      className="ml-3 mr-4"
    >
      <Table
        responsive
        hover
        size="sm"
        className={`table-emp mt-0 ${styles["job-employee-table"]}`}
      >
        <thead>
          <tr>
            <th className="text-left pl-3">Action</th>
            <th className="text-left pl-3">Name</th>
            <th className="text-left pl-3">Active</th>
            <th className="text-left pl-3">Description</th>
            <th className="text-left">Meal Rule Type</th>
            <th className="text-left">Enrolled Employees</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.results &&
            data.results.map((item) => (
              <tr key={item.id}>
                <td className="text-center cursor-pointer text-left pl-3">
                  <span title={item.is_active===true && "Delete Meal Adjustments"}>
                    <img
                      src={DeleteIcon}
                      height="15"
                      width="12"
                      style={{
                        cursor: item.is_active===true ? 'pointer' : 'not-allowed',
                        opacity: item.is_active===true ? 1 : 0.5,
                      }}
                      alt=""
                      onClick={() => {
                        if (item.is_active===true) {
                          handleClick(item);
                        }
                      }}
                      disable ={item.is_active === false}
                    />
                  </span>
                </td>
                <td title={item.name} className="text-left pl-3">
                  <Link
                    to={{
                      pathname: `/vendor/meal-adjustments/${item.id}/mealDetails`,
                      search: queryString.stringify({
                        parent: `${pathname}${search}`,
                      }),
                    }}
                  >
                    {item.name}
                  </Link>
                </td>
                <td title={item.is_active} className="text-left">
                  {item.is_active===false ? 'No' : 'Yes'}
                </td>
                <td title={item.description} className="text-left">
                  {item.description}
                </td>
                <td title={item.meal_rule_type} className="text-left">
                  {item.meal_rule_type}
                </td>
                <td title={item.enrolled_employees_count} className="text-left">
                  {item.enrolled_employees_count}
                </td>
              </tr>
            ))}
          {data?.length === 0 && (
            <tr>
              <td className="text-center" colSpan="10">
                No Records Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {isdeleteModal && (
        <EmployeeDeleteModal
          isOpen={isdeleteModal}
          toggle={handleClick}
          deleteEmployeeAssignment={handleDelete}
          isSubmitting={isSubmittingModal}
          title={"Remove Meal Adjustment"}
          description={count>0?"All the assigned employees will be removed from this Meal Adjustment. Are you sure you want to remove it ? .":"Are you sure you want to remove this Meal Adjustment?"}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getMealTypes: () => dispatch(getMealTypes()), // Map your action to props here
});

MealAdjustmentsListSection.propTypes = {
  data: PropTypes.object.isRequired,
  updateSelected: PropTypes.func,
  location: PropTypes.object.isRequired,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
};

MealAdjustmentsListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(mapDispatchToProps)(MealAdjustmentsListSection);










