import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import CustomerBulkUploadPage from './BulkUpload';
import CustomerListPage from './List';
import CustomerFormPage from './Form';

const CustomerRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="customer.list"
        component={CustomerListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list/:id/details`}
        name="customer.details"
        component={CustomerFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/bulk-upload`}
        name="customer.bulkupload"
        component={CustomerBulkUploadPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

CustomerRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default CustomerRoutes;
