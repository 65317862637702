import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import BasicSettingFormComponent from 'components/vendors/basicSetting/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/vendors';

/**
 * BasicSettingFormPage -> BasicSettingFormSection
 *
 * Components:
 *    - {@link BasicSettingFormComponent}
 *
 * State:
 *    - setting detail
 *
 * Actions:
 *    - Form:
 *        - update basic settings
 */
class BasicSettingFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
  }

  update(data) {
    return this.props.BasicSettingDucks.putBasicSetting(data)
      .then(() => toast.success(MSGS.BASIC_SETTING_UPDATE_SUCCESS));
  }

  render() {
    const { isLoading, basicSettingDetail } = this.props;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <BasicSettingFormComponent
        update={this.update}
        initialValues={basicSettingDetail}
      />
    );
  }
}

const mapStateToProps = state => ({
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
});

const mapActionToProps = dispatch => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

BasicSettingFormSection.propTypes = ({
  BasicSettingDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(BasicSettingFormSection);
