import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const PUT_JOB_TASK_MAPPING = 'jobs/jobTaskMapping/PUT_JOB_TASK_MAPPING';
const PUT_JOB_TASK_MAPPING_FULFILLED = 'jobs/jobTaskMapping/PUT_JOB_TASK_MAPPING_FULFILLED';


export function putJobTaskMapping(data) {
  return {
    type: PUT_JOB_TASK_MAPPING,
    payload: axios.put(`${URLS.JOB}${data.id}/${URLS.TASK}`, data),
  };
}

const defaultState = {
  detail: {},
};

function PUT_JOB_TASK_MAPPING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [PUT_JOB_TASK_MAPPING_FULFILLED]: PUT_JOB_TASK_MAPPING_FULFILLED_REDUCER,
};


const jobTaskMappingSelector = state => state.jobs.jobTaskMapping;


export const jobTaskMappingDetail = createSelector(
  jobTaskMappingSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
