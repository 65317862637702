import React from 'react';
import Table from 'reactstrap/lib/Table';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import { Formik } from 'formik';

import Link from 'react-router-dom/Link';
import SectionLoaderAtom from 'atoms/SectionLoader';
import SelectAtom from 'atoms/Select';
import Field from 'redux-form/lib/Field';
import PlusIcon from './addquestion.png';

class AddSurveyQuestion extends React.PureComponent {
  constructor(props) {
    super(props);    
    this.state = { noOfQuestions : 1 , noOfOptions : 1 , noOfOutcomes : 1 }
    this.updateQuestionNumber = this.updateQuestionNumber.bind(this);
    this.updateOutcomes = this.updateOutcomes.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    console.log('valueKey', values)
  }


  updateQuestionNumber(){
    const { noOfQuestions } = this.state;
    let count = noOfQuestions + 1;
    this.setState({noOfQuestions : count})
  }

  updateOutcomes(){
    const { noOfOutcomes } = this.state;
    let count = noOfOutcomes + 1;
    this.setState({noOfOutcomes : count})
  }

  render() {
    const {surveyQuestionSubmit,roleList} = this.props;
    return (
      <div>
      <Formik
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form  onSubmit={handleSubmit}>
              <Row className="ml-0 mr-0">
                <Col md={12}>
                  <FormGroup>
                    <Label>Feedback Form Heading</Label>
                    <Input
                      type="text"
                      name="label"
                      placeholder="Heading of the feedback form"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.label}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="ml-0 mr-0 mt-3">
                <Col md={6}>
                  <Row className="ml-0 mr-0 mb-3">
                    <div>Channels</div>
                  </Row>
                  <Row className="ml-1 mr-0">
                    <Col md={4}>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          id="kiosk_check"
                          name='kiosk_check'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.kiosk_check}
                        />
                        <Label>Kiosk App</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Input
                          type="checkbox"
                          id="admin_check"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.admin_check}
                        />
                        <Label>Admin</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Field
                    id="role"
                    name="role"
                    label="Roles"
                    component={SelectAtom}
                    options={roleList.results.map(i => ({ label: i.name, value: i.name }))}
                    labelKey="label"
                    valueKey="value"
                  />
                </Col>
              </Row>
              {Array.from({ length: this.state.noOfOutcomes }, (_, k) => (
                <div className='mt-3'>
                  <Row className="mr-0 ml-2 mb-2 pl-2">
                    <div>Outcome</div>
                  </Row>
                  <Row className="ml-0 mr-0">
                  <Col md={4}>
                    <FormGroup>
                      <label className="container custom-template-radio">Scorable Action
                        <input 
                          type="radio"
                          id="scoring_action" 
                          name="scoring_action"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.scoring_action}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <label className="container custom-template-radio">Non-Scorable Action
                        <input 
                          type="radio"
                          id="scoring_action" 
                          name="scoring_action"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.non_scorable}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0">
                  <Col md={4}>
                    <div>Limit</div>
                  </Col>
                  <Col md={6}>
                    <div>Action</div>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0 mt-2">
                  <Col md={2}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="upper_limit"
                        placeholder="Upper limit"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.upper_limit}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                  <FormGroup>
                    <Input
                      type="text"
                      name="lower_limit"
                      placeholder="Lower Limit"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lower_limit}
                    />
                  </FormGroup>

                  </Col>
                  <Col md={3} className="mt-2">
                    <FormGroup>
                      <label className="container custom-template-radio">Success
                        <input 
                          type="radio"
                          id="success" 
                          name="action_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.action_code}
                          defaultChecked={true}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="mt-2">
                    <FormGroup>
                      <label className="container custom-template-radio">Failed
                        <input 
                          type="radio"
                          id="success" 
                          name="action_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.action_code}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0 mt-2">
                  <Col md={12}>
                    <FormGroup>
                      <Label>Message</Label>
                      <Input
                        type="text"
                        name="message"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              ))}
              {this.state.noOfOutcomes < 2 ?
                <Row className="ml-0 mr-0 mt-2">
                <Col md={12}>
                  <Button onClick={()=>this.updateOutcomes()} color="secondary" className="pl-1 pr-1">
                    <img src={PlusIcon} width="18" height="18" className="mr-2"/>Add Outcome
                  </Button>
                </Col>
                </Row>
                :
                null
              }
              {Array.from({ length: this.state.noOfQuestions }, (_, k) => (
                <div>
                <Row className="ml-0 mr-0 mt-3">
                  <Col md={12}>
                    <FormGroup>
                      <Label>Question {k+1}</Label>
                      <Input
                        type="text"
                        name="question"
                        placeholder="Type your question here"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.question}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-3 mr-0 mt-3 pl-1">
                  <Col md={12}>
                    <FormGroup>
                      <Input
                        type="checkbox"
                        id="mandatory_question"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mandatory_question}
                      />
                      <Label>Mandatory?</Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0 mt-2">
                  <Col md={2} className="pr-0">
                    <div>Question Type</div>
                  </Col>
                  <Col md={1}>
                    <div>:</div>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label className="container custom-template-radio">Single Select
                        <input 
                          type="radio"
                          id="single" 
                          name="q_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.single}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label className="container custom-template-radio">Multi Select
                        <input 
                          type="radio"
                          id="multi" 
                          name="q_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.multi}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <label className="container custom-template-radio">Free Text
                        <input 
                          type="radio"
                          id="free_text" 
                          name="q_type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.free_text}
                        />
                        <span className="checkmark custom-template-checkmark"></span>
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0 mt-2">
                  <Col md={2} className="pr-0">
                    <div>Answer Option</div>
                  </Col>
                  <Col md={1}>
                    <div>:</div>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="option_first"
                        placeholder="Option 1"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.option_first}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="option_second"
                        placeholder="Option 2"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.option_second}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-0 mr-0 mt-2">
                  <Col md={3}>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="option_third"
                        placeholder="Option 3"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.option_third}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="text"
                        name="option_fourth"
                        placeholder="Option 4"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.option_fourth}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              ))}
              <div className="ml-3 mt-4">
                <Button onClick={()=>this.updateQuestionNumber()} color="secondary" className="pl-1 pr-1">
                  <img src={PlusIcon} width="18" height="18" className="mr-2"/>Add Question
                </Button>
              </div>
              <Row className="ml-3 mr-0 mt-3 pl-1">
                <Col md={12}>
                  <FormGroup>
                    <Input
                      type="checkbox"
                      id="mandatory"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mandatory}
                    />
                    <Label>Mandatory?</Label>
                  </FormGroup>
                </Col>
              </Row>
              <div className="ml-2 mt-5 float-right">
                <Button type="submit" color="secondary">
                  Submit
                </Button>
              </div>

            </Form>           
      )}/>
      </div>     
    );
  }
};


export default AddSurveyQuestion;
