import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PluginDucks from 'ducks/vendors/plugin';
import * as RouteDucks from 'ducks/routes';
import StorageGateway from 'lib/storage-gateway';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { FORM_LAYOUT } from 'constants/layout';

/**
 * VendorListPage -> VendorListSection
 *
 * Components:
 *    - {@link VendorTableComponent}
 *
 * State:
 *    - vendorList
 *
 * Actions:
 *    None
 */
class disconnectIntegrationModalListSection extends React.Component {

  constructor(props) {
    super(props);
    this.disconnectIntegration = this.disconnectIntegration.bind(this);
    this.goTo = this.goTo.bind(this);
    this.state = {isOpen: true, submitting: false};
  }

  disconnectIntegration() {
    const { router: { history } } = this.context;
    const token = StorageGateway.get('Token');
    this.setState({ submitting: true });
    this.props.PluginDucks.disconnectIntegration({ token})
      .then((res) => {
        this.setState({ isOpen: false });
        this.setState({ submitting: false });
        history.push('/vendor/plugin/');
        window.open('https://www.myetimecard.com/disconnected-with-quickbooks/');
      })
      .catch((error) => {
        this.setState({ submitting: false })
      });
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const { isOpen, submitting } = this.state;

    return (
      <section>
        <Modal
          isOpen={isOpen}
          backdrop="static"
        >
          <ModalBody >
            <div style={{ fontSize: '16px' }}>Are you sure you would like to disconnect the integration with QuickBooks?  This would stop all data flow between Worksana and QuickBooks. </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              size={FORM_LAYOUT.size}
              onClick={() => this.disconnectIntegration()}
            >
              Yes I am sure <ButtonLoaderAtom active={submitting} />
            </Button>
            <Button color="danger" onClick={() => this.goTo('/vendor/plugin/settings/Quickbooks/employee')}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  PluginDucks: bindActionCreators(PluginDucks, dispatch),
});

disconnectIntegrationModalListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(disconnectIntegrationModalListSection);
