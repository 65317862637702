import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as DeleteIcon} from './delete.svg';

const DeleteIconAtom = props => (
  <DeleteIcon {...props} style={{cursor:'pointer'}} />
);

DeleteIconAtom.defaultProps = {
  height: 15,
  width: 15,
};

DeleteIconAtom.propTypes = {
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default DeleteIconAtom;
