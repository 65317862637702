import React from 'react';
import cookie from 'react-cookies';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment-timezone';
import queryString from 'query-string';
import SubmissionError from 'redux-form/lib/SubmissionError';

import { toast } from 'react-toastify';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as RouteDucks from 'ducks/routes';
import * as PluginDucks from 'ducks/vendors/plugin';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as TaskDucks from 'ducks/jobs/task';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import PluginSettingsTimecardFilterFormComponent from './pluginSettingsTimecardFilter';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PluginSettingComponent from 'components/vendors/pluginSettings/Form';
import HelpTextModalComponent from 'components/vendors/pluginSettings/HelpTextModal';
import QuickBooksTableComponent from 'components/vendors/pluginSettings/Table';
import TabsComponent from 'components/common/Tabs';
import PluginSettingFilterSection from 'sections/vendors/pluginSetting/list/Filter';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import { MSGS, TIMECARDS_FILTER_OPTIONS, ADP_TIMECARDS_FILTER_OPTIONS} from 'constants/vendors';
import * as EmployeeDucks from 'ducks/employees/employee';
import ModalComponent from 'components/common/GenericModal';

import { Popover } from 'reactstrap';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import ListGroupItemHeading from 'reactstrap/lib/ListGroupItemHeading';
import styles from 'styles/custom/popover.scss';
/**
 * OverTimeSummaryListSection -> OvertimeDetailListSection
 *
 * Components:
 *    - {@link OvertimeDetailTableComponent}
      - {@link PaginationComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */

class PluginSettingSection extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handlePluginSettingChange = this.handlePluginSettingChange.bind(this);
    this.updatePluginSetting = this.updatePluginSetting.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onChangeEmployee = this.onChangeEmployee.bind(this);
    this.setMapping = this.setMapping.bind(this);
    this.state = { submitFlag: false,
      isOpen: false,
      selectedItemId: null,
      isSyncing: false,
      employeeAndQbdList : [],
      openEmployeeModal : false,
      openTaskModal : false,
      openSearchModal: false,
      openSearchEmployee: false,
      employeeInfo : null,
      selectedEmployee: [],
      selectedList: [],
      removePayrollId: null,
      removeTaskId : null,
      sortedEmployeeList: [],
      sortedTimecardList : [],
      sortedJobsList : [],
      sortedTaskList : [],
    };
    this.retrySync = this.retrySync.bind(this);
    this.saveMapping = this.saveMapping.bind(this);
    this.removeMapping = this.removeMapping.bind(this);
    this.getTable = this.getTable.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.openEmployeeSummary = this.openEmployeeSummary.bind(this);
    this.toggleEmployeeModal = this.toggleEmployeeModal.bind(this);
    this.toggleTaskModal = this.toggleTaskModal.bind(this);
    this.openTaskSummary = this.openTaskSummary.bind(this);
    this.openSearchModal = this.openSearchModal.bind(this);
    this.openSearchEmployeeModal = this.openSearchEmployeeModal.bind(this);
    this.toggleSearchEmployee = this.toggleSearchEmployee.bind(this);
    this.getQbdJobs = this.getQbdJobs.bind(this);
    this.onChangeJobs= this.onChangeJobs.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.removeJobsMapping = this.removeJobsMapping.bind(this);
    this.handleCheckboxes = this.handleCheckboxes.bind(this);
    this.onChangePayrolls = this.onChangePayrolls.bind(this);
    this.setPayrollMapping = this.setPayrollMapping.bind(this);
    this.setTaskMapping = this.setTaskMapping.bind(this);
    this.removePayrollsMapping = this.removePayrollsMapping.bind(this);
    this.onChangeTasks = this.onChangeTasks.bind(this);
    this.removeTasksMapping = this.removeTasksMapping.bind(this);
    this.dynamicSort = this.dynamicSort.bind(this);
    this.sortEmployeeList = this.sortEmployeeList.bind(this);
    this.sortTimecardList = this.sortTimecardList.bind(this);
    this.sortJobsList = this.sortJobsList.bind(this);
    this.sortTasksList = this.sortTasksList.bind(this);
    this.selectPayroll = this.selectPayroll.bind(this);
    this.sortNewestJobs = this.sortNewestJobs.bind(this);
    this.sortNewestTasks =this.sortNewestTasks.bind(this);
  }

  componentWillMount(){
    this.props.PayrollDucks.getAllPayrolls({ paginate: false,  default: true, valid: true });

  }
  // sorting of all the lists received have been done and values set in state in the ComponentDidUpdate function
  componentDidUpdate(){
     const { timecardList, metcAndQbdEmployeeList, qbdJobsList, metcTasksList, location} = this.props;

     metcAndQbdEmployeeList && metcAndQbdEmployeeList.results.length ? this.setState({
      sortedEmployeeList: (metcAndQbdEmployeeList.results).sort(this.sortEmployeeList)
     })
     :
     this.setState({
      sortedEmployeeList: metcAndQbdEmployeeList.results
     })
     timecardList && timecardList.length ? this.setState({
      sortedTimecardList: (timecardList).sort(this.sortTimecardList)
     })
     :
     this.setState({
      sortedTimecardList: timecardList
     })

     if(location.query.type==='1'){
      qbdJobsList && qbdJobsList.results && qbdJobsList.results.length ? this.setState({
          sortedJobsList : (qbdJobsList.results).sort(this.sortNewestJobs)
      })
      :
      this.setState({
        sortedJobsList : qbdJobsList.results
      })

      metcTasksList  && metcTasksList.length ? this.setState({
        sortedTaskList : (metcTasksList).sort(this.sortNewestTasks)
      })
      :
      this.setState({
        sortedTaskList : metcTasksList
      })
     }

     if(location.query.type==='2'){
      qbdJobsList && qbdJobsList.results && qbdJobsList.results.length ? this.setState({
        sortedJobsList : (qbdJobsList.results).sort(this.sortJobsList)
      })
      :
      this.setState({
        sortedJobsList : qbdJobsList.results
      })

      metcTasksList  && metcTasksList.length ? this.setState({
        sortedTaskList : (metcTasksList).sort(this.sortTasksList)
      })
      :
      this.setState({
        sortedTaskList : metcTasksList
      })
     }
  }

  sortNewestJobs(a,b){
    const job1 = a.job && a.job.id !== null ? a.job.id :  null;
    const job2 = b.job && b.job.id !== null ? b.job.id : null;

    let comparison = 0;
    if (job1 < job2) {
      comparison = 1;
    } else if (job1 > job2) {
      comparison = -1;
    }
    return comparison;
  }

  sortNewestTasks(a,b){
    const task1 = a.task && a.task.id !== null ? a.task.id :  null;
    const task2 = b.task && b.task.id !== null ? b.task.id : null;

    let comparison = 0;
    if (task1 < task2) {
      comparison = 1;
    } else if (task1 > task2) {
      comparison = -1;
    }
    return comparison;
  }

  setTaskMapping(detail){
    const { location: { search }} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const query  = queryString.parse(search);
    return this.props.PluginDucks.setTasksMapping(detail)
    .then(() => {
    toast.success('Association done successfully');
    this.props.getData(query, params);
  })
  }

  sortEmployeeList(a,b){
    const employee1_last = a.employee && a.employee.last_name !== null ? a.employee.last_name.toUpperCase() :  '';
    const employee2_last = b.employee && b.employee.last_name !== null ? b.employee.last_name.toUpperCase() :  '';
    const employee1_first = a.employee && a.employee.first_name !== null ? a.employee.first_name.toUpperCase() : '';
    const employee2_first = b.employee && b.employee.first_name !== null ? b.employee.first_name.toUpperCase() : '';
    let comparison = 0;
    if (employee1_last > employee2_last) {
        comparison = 1;
    }else if(employee1_last < employee2_last){
        comparison = -1;
    }else if(employee1_last === employee2_last){
      if (employee1_first > employee2_first) {
        comparison = 1;
      } 
      else if (employee1_first < employee2_first) {
        comparison = -1;
      } 
    }
    return comparison; 
  }

  sortTasksList(a,b){
    const task1 = a.task && a.task.name !== null ? a.task.name.toUpperCase() :  null;
    const task2 = b.task && b.task.name !== null ? b.task.name.toUpperCase() : null;

    let comparison = 0;
    if (task1 > task2) {
      comparison = 1;
    } else if (task1 < task2) {
      comparison = -1;
    }
    return comparison;
  }

  sortJobsList(a,b){
    const job1 = a.job && a.job.name !== null ? a.job.name.toUpperCase() :  null;
    const job2 = b.job && b.job.name !== null ? b.job.name.toUpperCase() : null;

    let comparison = 0;
    if (job1 > job2) {
      comparison = 1;
    } else if (job1 < job2) {
      comparison = -1;
    }
    return comparison;
  }

  sortTimecardList(a,b){
    const timecard1 = a.source_dt && a.source_dt.date !== null ? new Date( a.source_dt.date)  :  null;
    const timecard2 =  a.source_dt && a.source_dt.date !== null ? new Date( b.source_dt.date)  :  null;

    let comparison = 0;
    if (timecard1 > timecard2) {
      comparison = 1;
    } else if (timecard1 < timecard2) {
      comparison = -1;
    }
    return comparison;
  }

  setPayrollMapping(detail){
    const { location: { search }, source} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const query  = queryString.parse(search);
    if(source==='ADP'){ 
      return this.props.PluginDucks.setAdpPayrollsMapping(detail)
      .then(() => {
      toast.success('Association done successfully');
      this.props.getData(query, params);
      })
      .catch((e)=>{
        console.log('error',e);
      })
    }
    else{
      return this.props.PluginDucks.setPayrollsMapping(detail)
      .then(() => {
      toast.success('Association done successfully');
      this.props.getData(query, params);
      })
    }
  }

  dynamicSort(property) {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        if(sortOrder === -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }        
    }
  }


  handleCheckboxes(index, val) {
    let updatedArr = [ ...this.state.selectedEmployee];
    updatedArr[index] = val;
    this.setState({ selectedEmployee: updatedArr });
  }

  removeJobsMapping(selectedJobs){
    const { location: { search }} = this.props;
    const query  = queryString.parse(search);
    const { router: { route: { match: { params } } } } = this.context;
    const destinationJobIdArray = selectedJobs.map((item) => ({
        job_id: item.job_id,
    }))
    return this.props.PluginDucks.JobsMappingUnset(destinationJobIdArray[0])
    .then(() => {
    toast.success('Mapping removed successfully');
    this.props.getData(query, params);
  })
  }

  removePayrollsMapping(detail){
    this.setState({removePayrollId : detail.list_id});
    const { location: { search },source} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const query  = queryString.parse(search);
    if(source==='ADP'){
      return this.props.PluginDucks.removeAdpPayrollsMapping(detail)
      .then(() => {
      this.setState({removePayrollId : null});
      toast.success('Association removed successfully');
      this.props.getData(query, params);
      })
    }
    else{
      return this.props.PluginDucks.removePayrollsMapping(detail)
      .then(() => {
      this.setState({removePayrollId : null});
      toast.success('Association removed successfully');
      this.props.getData(query, params);
      })
    }
  }


  removeTasksMapping(detail){
    this.setState({removeTaskId : detail.task_id});
    let arr = [];
    arr.push(detail);
     const { location: { search }} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const query  = queryString.parse(search);
    return this.props.PluginDucks.removeTaskMapping(arr)
    .then(() => {
    this.setState({removeTaskId : null});
    toast.success('Association removed successfully');
    this.props.getData(query, params);
  })
  }

  onDepartmentSelect(id){
    const { location: { pathname, search }} = this.props;
    const {router: { history }} = this.context;
    const query  = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    history.push({
      pathname,
      search: queryString.stringify({ ...rest, department_id: id, team_id: null }),
    });
    if(id){
      this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_id: id });
    }
  }


  toggleSearchEmployee(){
    const { openSearchEmployee } = this.state;
    this.setState({openSearchEmployee: !openSearchEmployee});

  }

  getQbdJobs(item){
    const { source, location: { query }} = this.props;
    return this.props.PluginDucks.getAllQbdJobs({...item, source : source, synced: query.synced});
  }

  openSearchEmployeeModal(arr, item){
    this.setState({openSearchEmployee: true});
    this.setState({employeeInfo: arr});
  }

  openSearchModal(){

    this.setState({ openSearchModal: true});
  }

  toggleTaskModal(){
    const { openTaskModal} = this.state;
    this.setState({ openTaskModal: !openTaskModal});
  }

  toggleEmployeeModal(){
    const { openEmployeeModal} = this.state;
    this.setState({openEmployeeModal : !openEmployeeModal});
  }

  openEmployeeSummary(jobId){
    this.props.EmployeeDucks.getAssignedEmployees({ job_id: jobId, assigned: true })
    .then(() => {
    this.setState({openEmployeeModal : true});
  })
  }

  openTaskSummary(jobId){
    this.props.TaskDucks.getAssignedTasks({ job_id: jobId, assigned: true })
    .then(() => {
    this.setState({openTaskModal : true});
  })
  }

  setMapping(data){
   /* let extraData;
    const { searchMetcJobsList} = this.props;
    const searchMetcJobs= searchMetcJobsList.results;
    let employeesExtraData;
    const dataArrayId = data && data.map((item) => item.suggestion.id);
    employeesExtraData = searchMetcJobs.filter((i) => dataArrayId.indexOf(i.id) > -1);*/
    const detail = data && data.length ? data.map((item) => {
      const newItem = { 
        job_id: item.job_id ? item.job_id : null,
        destination_job: item.destination_job,
      }
     /* let extraData = employeesExtraData.find((i) => item.suggestion.id === i.id);
      newItem.extra_data = extraData ? extraData : null;*/

      return newItem;
    }) : null ;

    return this.props.PluginDucks.setJobMapping(detail);
  }

  onChangeEmployee(val) {
    this.props.PluginDucks.setSelectedEmployee(val);
  }

  onChangePayrolls(val) {
    this.props.PluginDucks.setSelectedPayroll(val);
  }

  onChangeTasks(val) {
    this.props.PluginDucks.setSelectedTasks(val);
  }


  onChangeJobs(val) {
    this.props.PluginDucks.setSelectedJob(val);
  }

  getEmployees(params) {
    const newParams = { ...params, activated: true , is_active: true}
    return this.props.EmployeeDucks.getEmployees(newParams);
  }

  getTable(key){
    const {companyId, payrollAll} = this.props;
    const today= moment().format('YYYY-MM-DD');
    const yesterday= moment().subtract(1, 'days').format('YYYY-MM-DD');
    const monthStartDate = moment().startOf('month').format('YYYY-MM-DD');
    const monthEndDate = moment().endOf('month').format('YYYY-MM-DD');
    const startDate = payrollAll && payrollAll.length ? moment(payrollAll[0].start_datetime).format('YYYY-MM-DD') : monthStartDate;
    const endDate = payrollAll && payrollAll.length ? moment(payrollAll[0].end_datetime).format('YYYY-MM-DD') : monthEndDate;
    const id = payrollAll && payrollAll.length ? payrollAll[0].id : 'custom';
    const {router: { history }} = this.context;
    if(key === 'employee'){

      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?mapped=true`)
    }
    else if(key === 'sync'){
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?synced=true`)
    }
    else if(key === 'timecard'){
      this.props.TimecardDucks.getDepartmentList(companyId);
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?synced=true&start=${startDate}&end=${endDate}&payroll_id=${id}`)
    }
    else if(key === 'jobs'){
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?synced=true&type=1`)
    }
    else if(key === 'Payroll_items'){
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?synced=true`)
    }
     else if(key === 'tasks'){
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}?synced=true&type=1`)
    }
    else {
      return history.push(`/vendor/plugin/settings/Quickbooks/${key}`)
    }
  }
 
  toggleModal() {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen });
  }

  handlePluginSettingChange(key,value){
    const { pluginSettingList } = this.props;
    pluginSettingList[key] = value;
  }

  saveMapping(data){
    const { fetchData } = this.props;
    return this.props.PluginDucks.postMappingSet(data)
      .then(() => {
        this.setState({selectedList: []});
        this.setState({selectedEmployee: []});
        fetchData();
      })
    .catch((err) => {
        this.setState({selectedList : []});
        this.setState({selectedEmployee: []});
        const errObj = {
          _error: err.message,
          ...err,
        };
        toast.error(err.message);

        fetchData();
        throw new SubmissionError(errObj);
      });
  }

  removeMapping(data){   
    const { endPoint, location: { query }} = this.props;
    this.setState({ selectedItemId: data.destination_emp_id });
    return this.props.PluginDucks.putUnmappingSet(data)
      .then(() => {
        toast.success('Mapping removed successfully')
        this.props.PluginDucks.getQbdMetcEmployees({ ...query});
        this.props.PluginDucks.updatePluginSetting({ end_point: endPoint});
        this.setState({ selectedItemId: null });
      })
      .catch(() => this.setState({ selectedItemId: null }));
  }

  retrySync() {
    const timecardPageSize = cookie.load("qb_timecard_page_size");
    const { location: { query }, endPoint } = this.props;
    this.setState({ isSyncing :  true });
    return this.props.TimecardDucks.retryTimecardSync({ end_point: endPoint })
      .then(() => {
        this.setState({ isSyncing : false });
        this.props.PluginDucks.updatePluginSetting({ end_point: endPoint});
        this.props.TimecardDucks.getKonnectTimecards({...query, end_point: endPoint, page_size: timecardPageSize });
      })
      .catch(() => this.setState({ isSyncing: false }));
  }

  updatePluginSetting(data) {
    const { endPoint } = this.props;
    const detail = {...data};
    if(!detail.employee_sync){
      detail.timecard_sync = false;
      detail.job_sync = false
    }
    return this.props.PluginDucks.updateSync(detail)
      .then(() => {
        toast.success(MSGS.PLUGIN_SETTING_UPDATE_SUCCESS);
        this.props.PluginDucks.getPluginSetting({ end_point: endPoint});
        /*this.props.PluginDucks.getPluginFailureList({type:'employee'});
        this.props.PluginDucks.getPluginTimecardFailureList({type:'timecard'});*/
      })

  }

  selectPayroll(val){
    const { router: { history } } = this.context;
    const { payrollAll, location: { pathname, query } , departmentPayroll}  = this.props;

    if(val === 'custom' && query.payroll_id === 'custom') return true;
    else if( val === 'custom'){
      const startDate = moment().startOf('month').format('YYYY-MM-DD');
      const endDate = moment().endOf('month').format('YYYY-MM-DD');
      return history.push({
        pathname,
        search: queryString.stringify({ ...query, payroll_id: val,
          start: startDate,
          end: endDate,
        }),
      });
    }
    let selectedPayroll;
    if(query.department_id){
      selectedPayroll = departmentPayroll.find((i) => (i.id === val));
    }
    if(!selectedPayroll){
      selectedPayroll = payrollAll.find((i) => (i.id === val));
    }
    
    const startDate = selectedPayroll ? moment(selectedPayroll.start_datetime).format('YYYY-MM-DD') : null;
    const endDate =  selectedPayroll ? moment(selectedPayroll.end_datetime).format('YYYY-MM-DD') : null;

    return history.push({
      pathname,
      search: queryString.stringify({ ...query,
        payroll_id: val,
        start: startDate,
        end: endDate,
      }),
    });

  }

  render() {
    const {
      isLoading,
      updatePluginSettingData,
      metcEmployeeList,
      location,
      isTableLoading,
      timecardList,
      syncData,
      source,
      pluginDetail,
      employeeassignedList,
      taskAssignedList,
      departmentList,
      metcAndQbdEmployeeList,
      qbdJobsList,
      metcTasksList,
      popoverOpen,
      notification_list,
      payrollAll,
      departmentPayroll,
      basicSettingDetail,
    } = this.props;
    
    let payrollOptions =[];

    const { sortedEmployeeList, sortedTimecardList, sortedJobsList, sortedTaskList} = this.state;
    let departmentOptions = departmentList.results ? departmentList.results : [];
    const {router: { history }} = this.context;
    const { query } = location;
    const { openSearchEmployee, submitFlag, isOpen, selectedItemId, openEmployeeModal, openTaskModal } = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    const options = [
      { label: 'Worksana to QuickBooks', value: true },
      { label: 'QuickBooks to Worksana', value: false },
    ];
    const tabs = [
      { key: 'sync', label: 'Dashboard'},
      { key: 'Payroll_items', label: 'Payroll Items'},
      { key: 'employee', label: 'Employee(s)' },
      { key: 'timecard', label: 'Timecards'},
    ];
    if(source!= 'ADP'){
      tabs.push({ key: 'jobs', label: 'Jobs'},  
        { key: 'tasks', label: 'Tasks'});
    }

    let payrollList = [];
    if(query.department_id && departmentPayroll && departmentPayroll.length) {
      payrollList = departmentPayroll;
    }
    else payrollList = payrollAll;
    payrollOptions = payrollList && payrollList.map(item => ({ value: item.id, label: `${item.start_date_display} to ${item.end_date_display}` }));
    
    payrollOptions.unshift({ value: 'custom', label: 'Custom Date Range'})

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section >
        <Popover className='plugin notification-popover mt-2' placement="bottom" isOpen={popoverOpen} target="notification">
          <div className="py-1 pt-3 pb-2 px-2">
            <span className='notification-head'>Notifications</span>
            <span className="pt-1 mr-2 notification-list">{notification_list.length}</span>
          </div>
          <div className="popover-border"></div>
          {notification_list.length > 0 ?
            <ListGroup className="notification-scroll notification-height">
              {notification_list && notification_list.map(item =>
                <ListGroupItem className='notification-item' key={item.event_id}>
                  <ListGroupItemHeading className='notification-heading'>{item.message}</ListGroupItemHeading>
                  <div className="w-100">
                    <span className='notification-text'>Status : {item.status}</span>
                  </div>
                  <span className='notification-time'>{moment(item.record_time).format('YYYY-MM-DD')}</span>
                  <span className='notification-time ml-1'>{moment(item.record_time).format("HH:mm A")}</span>
                </ListGroupItem>
               )}
            </ListGroup>
            :
            <div className="no-notification">No New Notifications</div>
          }
        </Popover>
        <div className="mt-5 pl-3 pr-4" >
          <TabsComponent tabItems={tabs} active={params.tab} onClick={this.getTable} />
        </div>
          <div className="pos-relative pl-3 pr-4 pb-5" >
            { isTableLoading ?
              <SectionLoaderAtom active />
              :<div>
                <div className="filter mt-4">
                  { params.tab === 'timecard' ?
                    <Row >
                      <Col md={12}>
                        <div className="d-inline-block">
                          <FilterDropdownComponent
                            paramKey="synced"
                            location={location}
                            options={source=='ADP' ? ADP_TIMECARDS_FILTER_OPTIONS : TIMECARDS_FILTER_OPTIONS}
                            newFormat
                          />
                        </div>
                       {departmentOptions && departmentOptions.length > 0  &&
                          <div className="float-left d-inline-block mt-2" style={{zIndex:'4', position: 'relative'}}> 
                            <FilterDropdownComponent
                              options={departmentOptions}
                              location={location}
                              history={history}
                              paramKey="department_id"
                              valueLabel="name"
                              keyLabel="id"
                              onChange={(val) => this.onDepartmentSelect(val)}
                            />
                           </div>}
                        <span className='d-inline-block mt-2'>
                          {payrollOptions && payrollOptions.length > 0 && 
                            <FilterDropdownComponent  
                              paramKey="payroll_id"
                              location={location}
                              options={payrollOptions}
                              onChange={(val) => this.selectPayroll(val)}
                            />
                          }
                          </span>
                          {query.payroll_id === "custom" &&
                          <PluginSettingsTimecardFilterFormComponent
                            location={location}
                            dateFormat={this.props.dateFormat}
                            initialValues={query.start && query.end  ?
                              { end: moment(query.end).toISOString(),
                                start: moment(query.start).toISOString()} : null}/>}
                      </Col>
                    </Row> : null}
                </div>
                {params.tab !== 'sync'
                  ?
                  <QuickBooksTableComponent
                    location={location}
                    source={source}
                    employeeList={sortedEmployeeList}
                    metcEmployeeList={metcEmployeeList}
                    onChangeEmployee={this.onChangeEmployee}
                    initialValues={{ employeeList: this.props.metcAndQbdEmployeeList.results }}
                    saveMapping={this.saveMapping}
                    removeMapping={this.removeMapping}
                    selectedItemId={selectedItemId}
                    timecardList={sortedTimecardList}
                    employeeAndQbdList = {this.props.metcAndQbdEmployeeList.results}
                    qbdJobsList = {sortedJobsList}
                    getEmployees = {this.getEmployees}
                    AllemployeeList = {this.props.employeeList}
                    setMapping= {this.setMapping}
                    openEmployeeSummary = {this.openEmployeeSummary}
                    openTaskSummary = {this.openTaskSummary}
                    openSearchModal={this.openSearchModal}
                    fetchData={this.props.fetchData}
                    openSearchEmployeeModal={this.openSearchEmployeeModal}
                    getQbdJobs={this.getQbdJobs}
                    onChangeJobs ={this.onChangeJobs}
                    searchMetcJobsList={this.props.searchMetcJobsList}
                    removeJobsMapping={this.removeJobsMapping}
                    selectedEmployee={this.state.selectedEmployee}
                    handleCheckboxes={this.handleCheckboxes}
                    selectedList={this.state.selectedList}
                    qbdDropdownJobsList={this.props.qbdDropdownJobsList}
                    payrollItemsList ={this.props.payrollItemsList}
                    adjustmentTypesList ={this.props.adjustmentTypesList}
                    onChangePayrolls = {this.onChangePayrolls}
                    setPayrollMapping = {this.setPayrollMapping}
                    metcPayrollList ={this.props.metcPayrollList}
                    removePayrollsMapping ={this.removePayrollsMapping}
                    removePayrollId ={this.state.removePayrollId}
                    metcTasksList ={sortedTaskList || this.props.metcTasksList}
                    qbdTasksList = {this.props.qbdTasksList}
                    onChangeTasks ={this.onChangeTasks}
                    setTaskMapping={this.setTaskMapping}
                    removeTasksMapping ={this.removeTasksMapping}
                    removeTaskId ={this.state.removeTaskId}
                    basicSettingDetail={basicSettingDetail}
                  />

                : null
                }
                {params.tab === 'sync' ?
                  <PluginSettingFilterSection />
                  : null
                }
                {params.tab === 'sync' ?
                  <PluginSettingComponent
                    initialValues={pluginDetail}
                    updatePluginSettingData ={updatePluginSettingData}
                    options={options}
                    submitFlag = {submitFlag}
                    source={source}
                    updatePluginSetting={this.updatePluginSetting}
                    handlePluginSettingChange = {this.handlePluginSettingChange}
                    toggleModal={this.toggleModal}
                    location={location}
                    syncData={syncData}
                  />
                : null
                }

                  {/*{params.tab !== 'sync'  && params.tab!=='jobs'
                  ?
                  <PaginationComponent
                    count={params.tab === 'employee' ? this.props.metcAndQbdEmployeeList.results.length : timecardList.length}
                    location={location}
                    cookieKey={params.tab === 'employee' && "qb_metc_employee_page_size" }
                  />
                : null
                }*/}
                {
                  openEmployeeModal &&
                    <ModalComponent
                      isOpen={openEmployeeModal}
                      heading="Employee Summary"
                      size="md"
                      toggle={this.toggleEmployeeModal}
                    >
                      <div>
                       {employeeassignedList.count>0 ? employeeassignedList.results.map((item) => (

                        <div>{item.user.full_name}</div>
                       ))
                       :
                       <div>No employees  found</div>
                     }
                      </div>
                    </ModalComponent>
                }

                {
                  openTaskModal &&
                    <ModalComponent
                      isOpen={openTaskModal}
                      heading="Task Summary"
                      size="md"
                      toggle={this.toggleTaskModal}
                    >
                      <div>
                      {taskAssignedList.count>0 ? taskAssignedList.results.map((item) => (

                        <div>{item.name}</div>
                       ))
                      :
                      <div>No tasks found</div>
                    }
                      </div>
                    </ModalComponent>
                }

                 {
                  openSearchEmployee &&
                    <ModalComponent
                      isOpen={openSearchEmployee}
                      heading="Search Employees"
                      size="md"
                      toggle={this.toggleSearchEmployee}
                    >
                      <div>

                      </div>
                    </ModalComponent>
                }


              </div>}
          </div>

            

        {isOpen &&
          <HelpTextModalComponent
            isOpen={isOpen}
            toggle={this.toggleModal}
          />
        }
      </section>
    );
  }
}

const mapStateToProps = state => {
  return({
  location: RouteDucks.location(state),
  pluginSettingList: PluginDucks.pluginSettingList(state),
  pluginDetail: PluginDucks.pluginSetting(state),
  pluginList: PluginDucks.pluginList(state),
  metcEmployeeList: PluginDucks.metcEmployeeList(state),
  pluginFailureEmployeeList: PluginDucks.pluginFailureEmployeeList(state),
  pluginFailureTimecardList: PluginDucks.pluginFailureTimecardList(state),
  updatePluginSettingData: PluginDucks.updatePluginSettingData(state),
  timecardList: TimecardDucks.timecardList(state),
  metcAndQbdEmployeeList : PluginDucks.metcAndQbdEmployeeList(state),
  qbdJobsList: PluginDucks.qbdJobsList(state),
  employeeList: EmployeeDucks.employeeAll(state),
  employeeassignedList: EmployeeDucks.employeeassignedList(state),
  taskAssignedList: TaskDucks.taskAssignedList(state),
  searchMetcJobsList: PluginDucks.searchMetcJobsList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  departmentList : TimecardDucks.departmentList(state),
  payrollItemsList : PluginDucks.payrollItemsList(state),
  adjustmentTypesList: PluginDucks.adjustmentTypesList(state),
  metcPayrollList: PluginDucks.metcPayrollList(state),
  metcTasksList : PluginDucks.metcTasksList(state),
  qbdTasksList : PluginDucks.qbdTasksList(state),
  payrollAll: PayrollDucks.payrollAll(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
})};

const mapActionToProps = dispatch => ({
  PluginDucks: bindActionCreators(PluginDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

PluginSettingSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

PluginSettingSection.propTypes = ({
  PluginDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func.isRequired,
  endPoint: PropTypes.string.isRequired,
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(PluginSettingSection);
