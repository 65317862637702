import React from 'react';

import PropTypes from 'prop-types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import {Input} from 'reactstrap';
import { toTitleCase } from 'helpers/utils';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';
import DateTimeSelectComponent from 'components/common/DateTimeSelectComponent';
import TimeComponent from 'components/common/TimeSelectComponent';
import SectionLoaderAtom from 'atoms/SectionLoader';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

class FeedbackModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen , toggle , feedbackResponse , title , questionNumber , list , isFeedbackLoading, isSurveyLoading } = this.props;
    const isNextDisabled = ((list && list.length - 1) < (questionNumber + 1))
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Feedback</ModalHeader>
        <ModalBody className={styles["feedback-modal-body"]}>
        {
          isFeedbackLoading ?
          <section style={{ height: '55vh' }}><SectionLoaderAtom active /></section>
          :
          isSurveyLoading ? <div className={styles['feedback-loader-alignment']}>Loading...</div>
          :
          <div>
            <div className="text-center custom-disabled-ui">
              {/* <span className={this.props.questionNumber - 1 < 0 ? 'custom-disabled-ui' : 'link-name'}><small onClick={!(this.props.questionNumber - 1 < 0) && (()=>this.props.showPreviousOrNext('previous'))} className={`${styles['previous-modal-button']} goToLink pt-1`}>Previous</small></span> */}
              <span className={styles['employee-name-styling']}>{toTitleCase(title)}</span>
              <span className={styles['feedback-date']}>({this.props.date})</span>
              {/* <span className={isNextDisabled ? 'custom-disabled-ui' : 'link-name'}><small onClick={!isNextDisabled && (()=>this.props.showPreviousOrNext('next'))} className={`${styles['next-modal-button']} goToLink pt-1`}>Next</small></span> */}
            </div>
            <div className={`${styles['custom-survey-label']}`}>{feedbackResponse && feedbackResponse[0].label}</div>
            <div className={`${styles['feedback-que-section']} mt-2`}>
            {feedbackResponse && feedbackResponse.length ? feedbackResponse.map((item,index)=>
            <div className="mr-2">
              {(item.definition == 'single_select' || item.definition == 'SINGLE_SELECT') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'multi_select' || item.definition == 'MULTI_SELECT') &&
                <div>
                  <MultiSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                  </div>
              }
              {(item.definition == 'free_text' || item.definition == 'FREE_TEXT') &&
                <div>
                  <FreeSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'boolean' || item.definition == 'BOOLEAN') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }           
              {(item.definition == 'date_time_select' || item.definition == 'DATE_TIME_SELECT') &&
                <div>
                  <DateTimeSelectComponent data={item} index={index} slider="timecardslider" dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
              {(item.definition == 'time_select' || item.definition == 'TIME_SELECT') &&
                <div>
                  <TimeComponent data={item} index={index} slider="timecardslider" dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat}/>
                  <hr className="mt-0 mb-2"/>
                </div>
              }
            </div>
          )
          :
            <section className={styles['no-records']}><div>No Records Found</div></section>
          }
        </div>
        </div>
      }
        </ModalBody>
      </Modal>
    );
  }
};


FeedbackModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default FeedbackModal;