import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as BusinessAdminDucks from 'ducks/businessAdmins/businessAdmin';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import BasicSettingFormSection from 'sections/businessAdmins/basicSetting/Form';

import { NAV } from 'constants/businessAdmins';

/**
 * BasicSettingFormPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link BasicSettingFormSection}
 *
 * Fetch Needs:
 *    None
 */
class BasicSettingFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true};
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("basic_setting_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'business_admin.basic_setting'});
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  fetchData(){  
    this.setState({ isLoading: true });
    return this.props.BusinessAdminDucks.getBusinessAdmin()
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("basic_setting_info", !isInfoOpen);
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <SSTCFLayout navInfo={NAV.BasicSettingFormPage}
        tabItems={[
          { href: '/business-admin/basic-setting', label: 'Basic' },
        ]}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <BasicSettingFormSection isLoading={isLoading} />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  BusinessAdminDucks: bindActionCreators(BusinessAdminDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

BasicSettingFormPage.propTypes = {
  BusinessAdminDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(BasicSettingFormPage);
