import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import ButtonGroupAtom from 'atoms/ButtonGroup';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';

import { FORM_LAYOUT } from 'constants/layout';
import { BOOLEAN_OPTIONS } from 'constants/vendors';
import closeimage from 'components/vendors/CloseCross.png';

/**
 * SystemSettingFormPage -> SystemSettingFormSection -> SystemSettingFormModalComponent
 *
 * Props:
 *    - update
 */
const SystemSettingFormModalComponent = (props) => {
  const {
    error, initialValues: { CONTROL_VALUES, key },
    isOpen, submitting, handleSubmit, toggle, update,
  } = props;

  const submit = (data) => {
    let detail;
    switch (data.key.data_type) {
      case "DURATION":
        detail = {
          ...data,
          value: moment.duration(+data.hour, 'hours').asSeconds() +
            moment.duration(+data.minute, 'minutes').asSeconds(),
        };
        break;
      default: detail = { ...data };
    }
    if (data.key.key_code === 'MANDATORY_PHONE_NUMBER_ON_EMPLOYEE_PROFILE') {
      detail = { ...data,
        CONTROL_VALUES: data.key.control_values
          .map(item => (
            { value: item === 'YES' ? 'True' : 'False', label:  item === 'YES' ? 'True' : 'False' }
          )),
      };
      const newValue = data.value === 'YES' ? 'True' : 'False';
      detail = { ...detail, value: newValue };
    }
    
    if(data.key.data_type === 'INTEGER'){    
      if((+data.value) > 100 ||  (+data.value) < 0){
        return toast.error('Percentage must be between 0 and 100');
      }
      const keyValue = data.key.data_type == 'INTEGER' ? props.mileageKey : null;
      detail = { ...detail, extra_data: {enabled : keyValue} };
    }
    return update(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        err && err[0] && toast.error(err[0]);
        throw new SubmissionError(errObj);
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="form-modal" backdrop="static">
      <ModalHeader>Edit System Setting
       <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
      </ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <ModalBody>
          <div className="error-text">{error}</div>
          <div className="d-inline-block">
          <Field
            id="key"
            name="key.key"
            label="Key"
            component={InputAtom}
            layout={FORM_LAYOUT}
            static
          />
          </div>
          {key.data_type == 'INTEGER' ?
            <ButtonGroupAtom 
              options={[
                { value: true, label: 'ON' },
                { value: false, label: 'OFF' },
              ]}
              active={props.mileageKey}
              className="float-right"
              onToggle={props.toggleMileageKey}
              />
              :
              null
            }
          {
            (() => {
              switch (key.data_type) {
                case "DURATION":
                  return (
                    <Row>
                      <Col>
                        <Field
                          id="hour"
                          name="hour"
                          component={InputAtom}
                          label="Value"
                          layout={FORM_LAYOUT}
                          placeholder="Hour"
                          suffix="Hr"                          
                        />
                      </Col>
                      <Col>
                        <Field
                          id="minute"
                          name="minute"
                          component={InputAtom}
                          label="&nbsp;"
                          layout={FORM_LAYOUT}
                          placeholder="Minute"
                          suffix="Min"                          
                        />
                      </Col>
                    </Row>
                  );
                case "BOOLEAN":
                  return (<Field
                    id="value"
                    name="value"
                    label="Value"
                    component={SelectAtom}
                    layout={FORM_LAYOUT}
                    options={BOOLEAN_OPTIONS}
                    simpleValue     
                  />);
                case "LIST":
                  return (<Field
                    id="value"
                    name="value"
                    label="Value"
                    component={SelectAtom}
                    layout={FORM_LAYOUT}
                    options={CONTROL_VALUES}
                    disabled = {key.key_code === 'MANUAL_ENTRY_BY_USER'}
                    simpleValue
                  />);
                  case "INTEGER":
                  return (<Field
                    id="value"
                    name="value"
                    label="Value"
                    component={InputAtom}
                    type="number"
                    layout={FORM_LAYOUT}
                    options={CONTROL_VALUES}
                    disabled={!props.mileageKey}
                    simpleValue
                  />);
                // default is used for char data type
                default:
                  return (<Field
                    id="value"
                    type="textarea"
                    name="value"
                    label="Value"
                    component={InputAtom}
                    placeholder="Message"
                    layout={FORM_LAYOUT}
                    rows="4"
                  />);
              }
            })()
          }
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.value) errors.value = 'Enter Value';
  return errors;
};

SystemSettingFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  initialValues: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SystemSettingForm',
  validate,
})(SystemSettingFormModalComponent);
