import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_CUSTOMERS = 'customers/customer/GET_CUSTOMERS';
const GET_CUSTOMERS_FULFILLED = 'customers/customer/GET_CUSTOMERS_FULFILLED';
const GET_CUSTOMERS_DETAILS = 'customers/customer/GET_CUSTOMERS_DETAILS';
const GET_CUSTOMERS_DETAILS_FULFILLED = 'customers/customer/GET_CUSTOMERS_DETAILS_FULFILLED';
const PUT_CUSTOMERS_DETAILS = 'customers/customer/PUT_CUSTOMERS';
const PUT_CUSTOMERS_DETAILS_FULFILLED = 'customers/customer/PUT_CUSTOMERS_FULFILLED';
const POST_CUSTOMERS = 'customers/customer/POST_CUSTOMERS';
const POST_CUSTOMERS_FULFILLED = 'customers/customer/POST_CUSTOMERS_FULFILLED';
const RESET_CUSTOMER = 'customers/customer/RESET_CUSTOMER';
const POST_BULK_CUSTOMER = 'customers/customer/POST_BULK_CUSTOMER';
const POST_BULK_CUSTOMER_FULFILLED = 'customers/customer/POST_BULK_CUSTOMER_FULFILLED';
const GET_BULK_CUSTOMER = 'customers/customer/GET_BULK_CUSTOMER';
const GET_BULK_CUSTOMER_FULFILLED = 'customers/customer/GET_BULK_CUSTOMER_FULFILLED';



export function getCustomers(params) {
  return {
    type: GET_CUSTOMERS,
    payload: axios.get(URLS.CUSTOMERS, { params }),
  };
}
export function getCustomersDetails(params) {
  return {
    type: GET_CUSTOMERS_DETAILS,
    payload: axios.get(`${URLS.CUSTOMERS_DETAIL}${params.id}/`),
  };
}

export function getBulkCustomer() {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: GET_BULK_CUSTOMER,
    payload: axios.get(URLS.CUSTOMER_BULK_UPLOAD, config),
  };
}


export function postCustomers(data) {
  return {
    type: POST_CUSTOMERS,
    payload: axios.post(URLS.CUSTOMERS, data),
  };
}
export function putCustomersDetails(data) {
  return {
    type: PUT_CUSTOMERS_DETAILS,
    payload: axios.put(`${URLS.CUSTOMERS_DETAIL}${data.id}/`, data),
  };
}

export function resetCustomer(data) {
  return {
    type: RESET_CUSTOMER,
    payload: data,
  };
}

export function postBulkCustomer(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: POST_BULK_CUSTOMER,
    payload: axios.post(URLS.CUSTOMER_BULK_UPLOAD, data, config),
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
};


function GET_CUSTOMERS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}
function GET_CUSTOMERS_DETAILS_FULFILLED_REDUCER(state, action) {
  // const customerDetails = action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    detail: action.payload,
  });
}
function PUT_CUSTOMERS_DETAILS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state,{
    detail: action.payload,
  });
}
function POST_CUSTOMERS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_CUSTOMER_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

function POST_BULK_CUSTOMER_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_BULK_CUSTOMER_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_CUSTOMERS_FULFILLED]: GET_CUSTOMERS_FULFILLED_REDUCER,
  [GET_CUSTOMERS_DETAILS_FULFILLED]: GET_CUSTOMERS_DETAILS_FULFILLED_REDUCER,
  [PUT_CUSTOMERS_DETAILS_FULFILLED]: PUT_CUSTOMERS_DETAILS_FULFILLED_REDUCER,
  [POST_CUSTOMERS_FULFILLED]: POST_CUSTOMERS_FULFILLED_REDUCER,
  [RESET_CUSTOMER]: RESET_CUSTOMER_REDUCER,
  [POST_BULK_CUSTOMER_FULFILLED]: POST_BULK_CUSTOMER_FULFILLED_REDUCER,
  [GET_BULK_CUSTOMER_FULFILLED]: GET_BULK_CUSTOMER_FULFILLED_REDUCER,
};

const customerSelector = state => state.customers.customer;

export const customerList = createSelector(
  customerSelector,
  instance => instance.list,
);

export const customerDetails = createSelector(
  customerSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);