import React from 'react';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import FilterFormComponent from 'components/common/FilterForm';
import EmployeeJobFilterComponent from 'components/common/EmployeeJobFilter';
import { toast } from 'react-toastify';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

class EmployeeJobsFilterForm extends React.Component {
	constructor(props) {
		super(props);
		this.toggleJobs = this.toggleJobs.bind(this);
		this.assignJobToEmployee = this.assignJobToEmployee.bind(this);
		this.state = {
			unassignedJobsObj: [],
			checkedJobs : []
		};
	}

	componentWillMount(){
	const { unAssignedJobs } = this.props;
	const arr = [...unAssignedJobs]
    this.setState({unassignedJobsObj: arr});
	}

	componentDidUpdate(prevProps) {
  	// Typical usage (don't forget to compare props):
	  if (this.props.unAssignedJobs !== prevProps.unAssignedJobs) {
	  	const arr = [ ...this.props.unAssignedJobs]
	    this.setState({unassignedJobsObj: arr});
	  }
	}

	toggleJobs(checkedData){
		this.setState({checkedJobs : checkedData});

	}

	assignJobToEmployee(){
		const { checkedJobs } = this.state;
		const { params } = this.props;
		const jobsarray = checkedJobs.filter(i => i.checked);
		const jobsSelected = jobsarray && jobsarray.length ? jobsarray.map((i) => {
			return i.id;
		}): null;
		if (jobsSelected && jobsSelected.length > 0 ) {
		const assignJobObj = {
		employee_id: params.id,
		job_ids: jobsSelected.filter((i)=> i !==0),
		}
		this.props.usecase.assignJobs(assignJobObj)
		.then(()=> this.setState({checkedJobs : []}))
		}
		else if (checkedJobs.length === 0){
			toast.error('There is no job to assign!!!')
		}
		else { toast.error('Choose a job to assign');
		}
	}


	render() {
	const { unassignedJobsObj } = this.state;
	const { location, location: { search }, isButtonLoading, isLoading } = this.props;
	const query = queryString.parse(search);
		return (
			<div>
				<Row className="filter ml-3 mr-4">
					<Col md={12} className="px-0 mb-2 mt-4">
						<Col className="float-left px-0" md={8}>
						{ !isLoading?
								(<div>
										<div className="float-left">
											<div className="custom-width-dropdown float-left d-inline-block pl-1 employee-job-filter">
												<EmployeeJobFilterComponent
													location={location}
													unassignedJobs={this.state.unassignedJobsObj}
													toggleJobs={this.toggleJobs}
												/>
												<div className="float-right">
											</div>
											</div>
									</div>
									<section className="float-left ">
										<Button
											color="accent"
											className="mr-1 ml-2"
											//disabled={!selectedJobs.length}
											onClick={() => this.assignJobToEmployee()}
										>
										Assign Jobs <ButtonLoaderAtom active={isButtonLoading} />
										</Button>
									</section>
								</div>
								) : null
							}
						</Col>
						<div className="d-inline-block float-right override-cross-bulk">
							<FilterFormComponent
								location={this.props.location}
								placeholder="Search job code, job name"
								initialValues={query}
							/>
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}

export default EmployeeJobsFilterForm;
