import { createSelector } from 'reselect';

import createReducer from 'helpers/createReducer';
import axios from 'helpers/interceptor';
import URLS from './urls';

const GET_ADJUSTMENT_TYPES_TIMECARD = 'timecards/adjustmentTimecard/GET_ADJUSTMENT_TYPES_TIMECARD';
const GET_ADJUSTMENT_TYPES_TIMECARD_FULFILLED = 'timecards/adjustmentTimecard/GET_ADJUSTMENT_TYPES_TIMECARD_FULFILLED';
const PUT_ADJUSTMENT_TYPES_TIMECARD = 'timecards/adjustmentTimecard/PUT_ADJUSTMENT_TYPES_TIMECARD';
const PUT_ADJUSTMENT_TYPES_TIMECARD_FULFILLED = 'timecards/adjustmentTimecard/PUT_ADJUSTMENT_TYPES_TIMECARD';
const POST_ADJUSTMENT_TYPES_TIMECARD = 'timecards/adjustmentTimecard/POST_ADJUSTMENT_TYPES_TIMECARD';
const POST_ADJUSTMENT_TYPES_TIMECARD_FULFILLED = 'timecards/adjustmentTimecard/POST_ADJUSTMENT_TYPES_TIMECARD';


export function getAdjustmentTypesTimecards(params) {
  return {
    type: GET_ADJUSTMENT_TYPES_TIMECARD,
    payload: axios.get(URLS.ADJUSTMENT_TYPES_TIMECARD, { params }),
  };
}

export function putAdjustmentTypesTimecards(data) {
  let url = `${URLS.ADJUSTMENT_TYPES_TIMECARD}update/`;
  if (data.beta) url = `${URLS.ADJUSTMENT_TYPES_TIMECARD}update/?beta=true`;
  return {
    type: PUT_ADJUSTMENT_TYPES_TIMECARD,
    payload: axios.post(url, data),
  };
}

export function postAdjustmentTypesTimecards(data) {
  return {
    type: POST_ADJUSTMENT_TYPES_TIMECARD,
    payload: axios.post(URLS.ADJUSTMENT_TYPES_TIMECARD, data),
  };
}


const defaultState = {
  list: [],
  detail: [],
};


function GET_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function PUT_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}
const handlers = {
  [GET_ADJUSTMENT_TYPES_TIMECARD_FULFILLED]:GET_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER,
  [PUT_ADJUSTMENT_TYPES_TIMECARD_FULFILLED]:PUT_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER,
  [POST_ADJUSTMENT_TYPES_TIMECARD_FULFILLED]:POST_ADJUSTMENT_TYPES_TIMECARD_FULFILLED_REDUCER,
};


const adjustmentTimecardSelector = state => state.timecards.adjustmentTimecard;

export const adjustmentTimecardList = createSelector(
  adjustmentTimecardSelector,
  instance => instance.list,
);

export const adjustmentTimecardDetail = createSelector(
  adjustmentTimecardSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
