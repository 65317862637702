import React from 'react';
import { Row, Col} from 'reactstrap';
import {connect} from 'react-redux';
import queryString from 'query-string';
import UserAPIGateway from 'accounts/gateways/user';
import ChangeExpPasswordForm from 'accounts/components/accountChangeExpiredPassword';
import ContentLayout from 'layouts/Content';
import ChangePasswordUseCase from 'accounts/usecases/changePassword';
import { toast } from 'react-toastify';

class ChangeExpPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props);    
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new ChangePasswordUseCase(userAPIGateway);
    this.listenChangePasswordUseCase = this.listenChangePasswordUseCase.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }
   componentWillMount() {
    this.listenChangePasswordUseCase();
  }

   
  listenChangePasswordUseCase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'CHANGE_PASS_SUCCESS':
          toast.success("Password changed successfully");
          this.props.history.push('/dashboard');
          this.props.dispatch({
            type: 'accounts/user/RESET_STATE',
          });
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col xs={12} md={{ size:4, offset:4 }} className="align-self-center" >
            <ChangeExpPasswordForm  usecase={this.usecase}/>
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});



export default connect(
  mapDispatchToProps
)(ChangeExpPasswordPage);