import React, { Component } from 'react'

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import DateTimePickerAtom from 'atoms/DateTimePicker';

import { FILTER_FORM_LAYOUT } from 'constants/layout';


class EmployeeProficiencyReportFilterFormComponent extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this);
  }

  componentDidMount() { 
    const { router: { history } } = this.context;
    const {
      location: { pathname },
      company_id
    } = this.props;
    history.push({
      pathname, search: queryString.stringify({
        end_date: moment(new Date()).toISOString(),
        start_date: moment(new Date()).subtract(1, 'months').toISOString(),
        company_id
       }),
    });
  }

  submit(data) {
    const { router: { history } } = this.context;
    const {
      location: { pathname, query },
    } = this.props;

    const { page, ...rest } = query;
    const detail = { ...data };
    const { page: pageNo, ...restDetail } = detail;
    restDetail.start_date = moment(data.start_date).format('YYYY-MM-DD');
    restDetail.end_date = moment(data.end_date).format('YYYY-MM-DD');
    delete restDetail.team_id;
    delete restDetail.department_id;
    console.log('rest', rest, 'restDetail', restDetail)
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail }),
    });
  };
  render() {
    const {
      dateFormat,
      handleSubmit,
    } = this.props;
    return (
      <Form inline onSubmit={handleSubmit(this.submit)} className="report-filter">
        <Field
          id="start_date"
          name="start_date"
          placeholder="Start Date"
          component={DateTimePickerAtom}
          options={{ dateFormat }}
        />
        <Field
          id="end_date"
          name="end_date"
          placeholder="End Date"
          component={DateTimePickerAtom}
          options={{ dateFormat }}
        />
        <Button type="submit" color="accent" className="pr-4 pl-4" size={FILTER_FORM_LAYOUT.size}>
          Apply
        </Button>
      </Form>
    );
  }
}


const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  const formattedStartDate = moment(values.start_date).format();
  const formattedEndDate = moment(values.end_date).format();
  if (formattedStartDate > formattedEndDate) errors.start_date = 'Start date should be less than end date.';
  return errors;
};

EmployeeProficiencyReportFilterFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

EmployeeProficiencyReportFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'EmployeeProficiencyReportFilterForm',
  validate,
})(EmployeeProficiencyReportFilterFormComponent);

