export const NAV = {
  AdminDashboardPage: {
    title: 'Dashboard',
    description:''
  },
};

export const FILTER_OPTIONS = [
  { value: 1, label: '1 Day' },
  { value: 7, label: '7 Days' },
  { value: 14, label: '14 Days' },
  { value: 21, label: '21 Days' },
  { value: 30, label: '30 Days' },
];

export default { NAV , FILTER_OPTIONS };
