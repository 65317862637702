import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Table from 'reactstrap/lib/Table';

import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';

class AdjustmentHourFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state={isOpen:true}
  }


  render() {
    const {
      adjustmentTypesList,
      adjustmentTimecardList,
      fields,
      adjustmentHourView,
      regularHours,
      regularMins,
      overtimeMins,
      overtimeHours,
      doubleOvertimeMins,
      doubleOvertimeHours,
    } = this.props;
    return (
      <div className="table-adjustment">
        <Table className="mt-0">
          <thead className="adjustment-head text-center ml-0 mr-0 table-adjustment-header">
            <tr>
              <th className="pt-2 text-left adjustment-border">
                Adjustment Type
              </th>
              <th className="pt-2 text-center adjustment-border">
                Regular
              </th>
              <th className="pt-2 text-center adjustment-border">
                Overtime
              </th>
              <th className="pt-2 text-center">
                Double Overtime
              </th>
            </tr>
          </thead>  
          {adjustmentTypesList.length ? <tbody className={`modal-adjustment ${adjustmentTypesList.length < 7 ? 'scroll': ''}`}>
            {fields.map((item, index, arr) => {
              let name;
              if(adjustmentTimecardList && adjustmentTimecardList[index] && adjustmentTimecardList[index].adjustment_type_id){
                 name  =  adjustmentTypesList.find((i) => (i.id === adjustmentTimecardList[index].adjustment_type_id && i.is_active ===true) )?.name;
                if(!name ){
                  return
                } 
              }
              else
              {
                 name = adjustmentTypesList[index].is_active === true && adjustmentTypesList[index].name;
              }
              return (<tr className="text-left ml-0 mr-0 adjustment-background adjustment-input">
                <td md={3} className="pt-1 pr-0 adjustment-table">  
                  <div
                    id="name"
                    name="name"
                    className="pt-2 elipsis-job"
                  >
                    {name}
                  </div>
                </td>
                <td className="pt-2 adjustment-table">
                  <Row>
                    <Col md={3} className="pr-0 ml-2">
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].regular_time_hours} hh
                        </div>  
                        : <Field
                          id="regular_time_hours"
                          name={`${item}.regular_time_hours`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }  
                    </Col>
                    <Col md={2} className="pl-1 pt-1">
                      { !adjustmentHourView && <div>hh </div>}
                    </Col>
                    <Col md={3} className="pr-0"> 
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].regular_time_mins} mm
                        </div>
                        : <Field
                          id="regular_time_mins"
                          name={`${item}.regular_time_mins`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }   
                    </Col>
                    <Col md={2} className="pl-1 pt-1">
                      { !adjustmentHourView && <div>mm </div>}
                    </Col>  
                  </Row>    
                </td>
                <td className="pt-2 adjustment-table">
                  <Row>
                    <Col md={3} className="pr-0 ml-2">
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].over_time_hours} hh
                        </div>  
                        : <Field
                          id="over_time_hours"
                          name={`${item}.over_time_hours`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }   
                    </Col>
                    <Col md={2} className="pl-1 pt-1 ml-0">
                      { !adjustmentHourView && <div>hh </div>}
                    </Col>
                    <Col md={3} className="pr-0">
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].over_time_mins} mm
                        </div>  
                        : <Field
                          id="over_time_mins"
                          name={`${item}.over_time_mins`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }     
                    </Col>
                    <Col md={2} className="pt-1 pl-1">
                      { !adjustmentHourView && <div>mm </div>}
                    </Col>
                  </Row>
                </td>
                <td className="pt-2 adjustment-table">
                  <Row>
                    <Col md={3} className="pr-0 ml-2">
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].double_over_time_hours} hh
                        </div>  
                        : <Field
                          id="double_over_time_hours"
                          name={`${item}.double_over_time_hours`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }     
                    </Col>
                    <Col md={2} className="pr-0 pl-1 pt-1">
                      { !adjustmentHourView && <div>hh </div>}
                    </Col>
                    <Col md={3} className="pr-0">
                      {adjustmentHourView ===true
                        ? <div className="pl-3 pt-1">
                          {adjustmentTimecardList[index].double_over_time_mins} mm
                        </div>  
                        : <Field
                          id="double_over_time_mins"
                          name={`${item}.double_over_time_mins`}
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      }  
                    </Col>
                    <Col md={2} className="pr-0 pl-1 pt-1">
                      { !adjustmentHourView && <div>mm </div>}
                    </Col> 
                  </Row>
                </td>
              </tr>
              )})} 
          </tbody>: null}
          {adjustmentTypesList.length ?
            <thead>
              <tr className="text-left ml-0 mr-0 adjustment-background adjustment-input">
                <td md={3} className="pt-2 pb-2 adjustment-table">
                  <b>Total</b>
                </td>
                <td className="adjustment-table pb-1">
                  <Row>
                    <Col md={6} className="pr-0">
                      <div className={adjustmentHourView?"pl-4":"pl-2"}><b className={adjustmentHourView ? "" :"pl-1"}>{regularHours}</b> hh</div>
                    </Col>
                    <Col md={6} className="pr-0">  
                      <div className={adjustmentHourView?"pl-1 ml-1":null}><b className={adjustmentHourView?"":null}>{regularMins}</b> mm</div>
                    </Col>
                  </Row>  
                </td>
                <td className="adjustment-table">
                  <Row>
                    <Col md={6} className="pr-0 ">
                      <div className={adjustmentHourView?"pl-4":"pl-2"}><b className={adjustmentHourView ? "":"pl-1 pr-1"}>{overtimeHours}</b> hh</div>
                    </Col>
                    <Col md={6} className="pr-0">  
                      <div className={adjustmentHourView?"pl-1 ml-1":null}><b className={adjustmentHourView ? "":"pr-1"}>{overtimeMins}</b> mm</div>
                    </Col>
                  </Row>   
                </td>
                <td className="adjustment-table adjustment-border">
                  <Row>
                    <Col md={6} className="pr-0">
                      <div className={adjustmentHourView?"pl-4":"pl-2"}><b className={adjustmentHourView ? "":"pl-1"}>{doubleOvertimeHours}</b> hh</div>
                    </Col>
                    <Col md={6} className="pr-0">
                      <div className={adjustmentHourView?"pl-1 ml-1":null}><b className={adjustmentHourView ? "":"pr-1"}>{doubleOvertimeMins}</b> mm</div>
                    </Col>
                  </Row>  
                </td>
              </tr>
            </thead>: null}
          {!adjustmentTypesList.length &&
            <thead><tr><td className="text-center" colSpan="10">There are no records of adjustment types mapped for the company.<br />
Please add new adjustment types to log the hours.</td></tr></thead>
          }
        </Table>
      </div>
    );
  }
}

AdjustmentHourFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default AdjustmentHourFormComponent;