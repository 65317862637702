import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

import {ReactComponent as TrackingIcon} from './tracking.svg';

const GeoFenceBreachDetailTableComponent = (props, context) => {
  const { data, location: { query }, pageSize, toggle} = props;
  const { page = 1 } = query;


  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table">
      <thead className="Header-col">
        <tr>
          <th className="text-center pl-3">Action</th>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."first_name"'>
            First Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."last_name"'>
            Last Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."title"'>
            Title
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='start_datetime'>
            StartDate Time
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='end_datetime'>
            EndDate Time 
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='total_time'>
            Total Time
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td title="View Tracking" className="text-center pb-0 pl-3">
                <TrackingIcon
                  height="18"
                  width="18"
                  className="cursor-pointer"
                  onClick={() => toggle(item)}
                />
              </td>
              <td title={item.first_name}>
                {item.first_name}
              </td>
              <td title={item.last_name}>{item.last_name}</td>
              <td title={item.title}>{item.title}</td>
              <td title={item.start_datetime}>{item.start_datetime}</td>
              <td title={item.end_datetime}>{item.end_datetime}</td>
              <td className="text-right pr-3" title={item.total_time}>{item.total_time}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
      <tbody />
    </Table>
    </div>
  );
};

GeoFenceBreachDetailTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  child: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

GeoFenceBreachDetailTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default GeoFenceBreachDetailTableComponent;
