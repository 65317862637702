import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FullScreenLoaderAtom = (props) => {
  const { active } = props;
  if (!active) return null;
  return (
    <div className={styles.fullPageLoader}>
      <div className={styles['line-scale']}>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

FullScreenLoaderAtom.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default FullScreenLoaderAtom;
