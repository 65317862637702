import React from 'react';

import moment from 'moment';
import cookie from 'react-cookies';
import Proptypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';

import TwoColumnLayout from 'layouts/TwoColumn';
import DeviatedDailyTimecardFilterSection from 'sections/timecards/customDailyTimecardList/Filter';
import DeviatedDailyTimecardListSection from 'sections/timecards/deviatedDailyTimecardList/List';

import { NAV } from 'constants/timecards';
/**
 * DeviatedDailyTimecardListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link TimecardFilterSection}
 *    - {@link TimecardListSection}
 *
 * Fetch Needs:
 *    - get DailyTimeCard List 
 */
const monthStartDate = moment().startOf('month').format('YYYY-MM-DD');
const monthEndDate =  moment().endOf('month').format('YYYY-MM-DD'); 

class DeviatedDailyTimecardListPage extends React.Component {
  constructor(props){
    super(props);
    this.state = { isLoading: false, parent: NAV.DeviatedDailyTimecardListPage.parent, isInfoOpen: true };
    this.setParentUrl = this.setParentUrl.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.getPayrollsOfDepartment = this.getPayrollsOfDepartment.bind(this);
    this.getAllPayrolls = this.getAllPayrolls.bind(this);
    this.setQuery = this.setQuery.bind(this);
  }

  componentWillMount() { 
    const { location: { pathname, query } } = this.props;
    const { router: { history } } = this.context;
    if (!('page_size' in query)){
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: 5,
        }),
      });
    }
    else{
      this.props.InfoDucks.getInformations({route: 'timecard.deviated_daily_timecard.list'});
      this.getAllPayrolls();
      if(query.department_ids){
        this.getPayrollsOfDepartment(query);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery }, profile } = nextProps;
    if(prevQuery.department_ids !== nextQuery.department_ids){
      if(nextQuery.department_ids){
        this.getPayrollsOfDepartment(nextQuery);
      }
      else if (this.props.payrollAll.length && profile.company.is_default){
        this.setQuery(nextQuery, this.props.payrollAll[0].id);
      }
      else {
        this.setQuery(nextQuery, 'custom', monthStartDate, monthEndDate);
      }
    }
    else if (prevQuery !== nextQuery){
      this.fetchData(nextQuery); 
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        this.setState({
          parent: `${NAV.DeviatedDailyTimecardListPage.parent}?${queryString.stringify(parsedParams)}`,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  getPayrollsOfDepartment(query){
    this.setState({ isLoading: true });

    let departmentIdsArray;
    if(query?.department_ids){
     
      if(Array.isArray(query?.department_ids)){
        departmentIdsArray= query?.department_ids;
      } 
      else{
        departmentIdsArray= [query?.department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));



    return this.props.PayrollDucks.getPayrollsOfDepartment({ valid: true, paginate: false, department_ids: JSON.stringify(departmentIdsIntegers) })
      .then(() => {
        const { departmentPayroll, payrollAll, profile } = this.props;
        if(departmentPayroll.length){
          this.setQuery(query, departmentPayroll[0].id);
        }
        else if(payrollAll.length && profile.company.is_default){
          this.setQuery(query, payrollAll[0].id);
        }
        else{
          this.setQuery(query, 'custom', monthStartDate, monthEndDate );
        }
      })
  }

  getAllPayrolls(){
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    return this.props.PayrollDucks.getAllPayrolls({ paginate: false,  default: true, valid: true })
      .then(() => {
        const { payrollAll } = this.props;
        if(payrollAll.length) {
          this.setQuery(query, payrollAll[0].id);
        }
        else{
          this.setQuery(query, 'custom', monthStartDate, monthEndDate); 
        }
      })
  }

  setQuery(query, payrollId, startDate, endDate){
    const { router: { history } } = this.context;
    const { location: { pathname } } = this.props;
    if(!('payroll_id' in query)){
      return history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: payrollId,
          start_date: startDate,
          end_date: endDate,
        })
      });
    }
    else{
      this.fetchData(query);
    }
    return true;
  }

  fetchData (params) {
    this.setState({ isLoading: true });
    return  this.props.DailyTimecardDucks.getDeviateDailyTimecards({...params, manual_correction: true, paginate:false, page_size: 5 })
      .then(() => this.setState({isLoading: false}))
      .catch(() => this.setState({isLoading: false}));
  }

  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("deviated_daily_timecard_info", !isInfoOpen);
  }

  render() {
    const { isLoading, parent, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return(
      <TwoColumnLayout
        navInfo={{
          ...NAV.DeviatedDailyTimecardListPage, parent
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <DeviatedDailyTimecardFilterSection />
        <DeviatedDailyTimecardListSection  isLoading={isLoading} />
      </TwoColumnLayout>
    )
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  payrollAll: PayrollDucks.payrollAll(state),
  infoList: InfoDucks.infoList(state),
  profile: UserDucks.profile(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
})

const mapActionsToProps = (dispatch) => ({
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
})

DeviatedDailyTimecardListPage.propTypes = {
  payrollAll: Proptypes.array.isRequired,
  location: Proptypes.object.isRequired,
  PayrollDucks: Proptypes.object.isRequired,
  DailyTimecardDucks: Proptypes.object.isRequired,
  InfoDucks: Proptypes.object.isRequired,
  infoList: Proptypes.object.isRequired,
}

DeviatedDailyTimecardListPage.contextTypes = {
  router: Proptypes.object.isRequired,
}

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DeviatedDailyTimecardListPage)


