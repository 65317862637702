import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_JOB_REPORTS = 'jobs/reports/GET_REPORTS';
const GET_JOB_REPORTS_FULFILLED = 'jobs/reports/GET_REPORTS_FULFILLED';
const GET_USER_PREFERENCE = 'jobs/reports/GET_USER_PREFERENCE';
const GET_USER_PREFERENCE_FULFILLED = 'jobs/reports/GET_USER_PREFERENCE_FULFILLED';
const PUT_USER_PREFERENCE = 'jobs/reports/PUT_USER_PREFERENCE';
const PUT_USER_PREFERENCE_FULFILLED = 'jobs/reports/PUT_USER_PREFERENCE_FULFILLED';
const EXPORT_REPORTS = 'jobs/reports/EXPORT_REPORTS';

export function getJobReports(params) {
  return {
    type: GET_JOB_REPORTS,
    payload: axios.get(`${URLS.JOB}${URLS.JOB_REPORTS}`, {params}),
  };
}

export function getUserPreference(params) {
  return {
    type: GET_USER_PREFERENCE,
    payload: axios.get(`${URLS.REPORTS_USER_PREFERENCE}`, {params}),
  };
}

export function putUserPreference(params) {
  return {
    type: PUT_USER_PREFERENCE,
    payload: axios.put(`${URLS.REPORTS_USER_PREFERENCE}`, {...params}),
  };
}


export function exportReports(params) {
  return {
    type: EXPORT_REPORTS,
    payload: axios.get(`${URLS.JOB}${URLS.JOB_REPORTS}`, 
      { params, responseType: "arraybuffer" }),
  };
}

const defaultState = {
  list: {},
  detail: {},
  userPreference : []
};

function GET_JOB_REPORTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_USER_PREFERENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userPreference: action.payload,
  });
}


const handlers = {
  [GET_JOB_REPORTS_FULFILLED]: GET_JOB_REPORTS_FULFILLED_REDUCER,
  [GET_USER_PREFERENCE_FULFILLED] : GET_USER_PREFERENCE_FULFILLED_REDUCER
};


const jobReportsSelector = state => state.jobs.reports;

export const jobReportsList = createSelector(
  jobReportsSelector,
  instance => instance.list,
);

export const jobReportsUserPreference = createSelector(
  jobReportsSelector,
  instance => instance.userPreference,
);

export default createReducer(defaultState, handlers);