import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as JobDucks from 'ducks/jobs/job';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import TwoColumnLayout from 'layouts/TwoColumn';

import JobProgressBarSection from 'sections/jobs/form/ProgressBar';
import JobGeoFenceFormSection from 'sections/jobs/jobGeofence/jobGeofence';

import { NAV } from 'constants/jobs';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

/**
 * JobGeoFenceFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link GeoFenceFormSection}
 *
 * Fetch Needs:
 *    - get payroll detail
 */
class JobGeoFenceFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isGeoFenceAddOpen: true, isGeoFenceEditOpen: true, parent: NAV.JobEditFormPage.parent };
    this.fetchData = this.fetchData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.findMe = this.findMe.bind(this);
    this.locationAccessed = this.locationAccessed.bind(this);
    // this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    const { location : { query }} = this.props;
    this.findMe();

    this.setParentUrl(query);
    this.fetchData();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  locationAccessed(position) {
    var latitude  = position.coords.latitude;
    var longitude = position.coords.longitude;
    const data= {position : {lat : latitude, lng : longitude }};
    this.props.ActivityTrackDucks.getActivityLocationAddress(data , 'locationIq');
  }

  findMe() {
    if (!navigator.geolocation){
     console.log("Geolocation is not supported by your browser");
      return;
    }
    navigator.geolocation.getCurrentPosition(this.locationAccessed);
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    if(params.id){
      this.setState({ isLoading: true });
      Promise.all([
        this.props.GeoFenceDucks.getALLGeoFences({ paginate: false, is_active: true }),
        this.props.JobDucks.getJobGeoFenceMapping(params),
        this.props.JobDucks.getJob(params),
      ])
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const selectedId = params ? params.id : null;
    const { infoList, location: { query} ,jobDetail} = this.props;
    const { isGeoFenceEditOpen, isLoading } = this.state;

    return (
        <div>
          <TwoColumnLayout
            navInfo={query.setup ? NAV.JobAddFormPage : query.admin_list ? NAV.AdminJobEditFormPage : NAV.JobEditFormPage}
            infoList={infoList}
            isInfoOpen={params.id ? isGeoFenceEditOpen : isGeoFenceEditOpen}
            toggleInfo={this.toggleInfo}
            fetchData={this.fetchData}
            jobDetail={jobDetail}
          >
          <div className="pb-5">
             <Row className="pt-4 mr-4 ml-3" style={{backgroundColor: 'white'}}>
              <Col>
                <Row>
                  <Col className="text-center mb-4">
                    <JobProgressBarSection
                      active={5}
                      selectedId={selectedId}
                      isInfoOpen={params.id ? isGeoFenceEditOpen : isGeoFenceEditOpen}
                      addJob={query.setup}
                      location={this.props.location}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <JobGeoFenceFormSection isLoading={isLoading} fetchData={this.fetchData}/>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          </TwoColumnLayout>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  jobDetail: JobDucks.jobDetail(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  // InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

JobGeoFenceFormPage.propTypes = {
  JobDucks: PropTypes.object.isRequired,
  GeoFenceDucks: PropTypes.object.isRequired,
  // infoList: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

JobGeoFenceFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobGeoFenceFormPage);
