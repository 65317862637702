import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as TaskDucks from 'ducks/jobs/task';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as UserDucks from 'ducks/accounts/user';

import TwoColumnLayout from 'layouts/TwoColumn';
import JobTaskMappingFilterSection from 'sections/jobs/jobTaskMappingList/Filter';
import JobTaskMappingListSection from 'sections/jobs/jobTaskMappingList/List';

import { NAV } from 'constants/jobs';

/**
 * JobTaskMappingListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobSchedulesFilterSection}
 *    - {@link EmployeeListSection}
 *
 * Fetch Needs:
 *    - getEmployees
 */
class JobTaskMappingListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.selectTask = this.selectTask.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.resetSelectedTasks = this.resetSelectedTasks.bind(this);
    this.state = { isLoading: false, parent: NAV.JobTaskMappingListPage.parent, isInfoOpen: true , selectedTasks : []};
  }

  componentWillMount() {
    const cookieKey = cookie.load("job_task_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'job.task.assign'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { history  } = this.context;
    const { location: { query: prevQuery } } = this.props;
    const { location: { pathname, query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.setParentUrl(nextQuery);
      if(prevQuery.search !== '' && nextQuery.assigned !== '' && prevQuery.job_id){
        const { search, ...rest } = nextQuery;
        return history.push({ pathname, search: queryString.stringify({...rest, search: ''})});
      }
      return this.fetchData(nextQuery);
    }
    return true;
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  resetSelectedTasks(){
     this.setState({selectedTasks : []});
  }

  selectTask(item){
    const { selectedTasks } = this.state;
    var isTaskPresent = selectedTasks.indexOf(item.id);
    if(isTaskPresent === -1){
      let a = selectedTasks;
      a.push(item.id);
      this.setState({selectedTasks : a});
    }
    else{
      let a = selectedTasks;
      a.splice(isTaskPresent, 1);
      this.setState({selectedTasks : a});    
    }
    
  }

  setParentUrl(query) {
    const { jobDetail } = this.props;
    try {
      if (query.assigned === 'false') {
        const { assigned, ...rest } = query;
        this.setState({
          parent: `/job/${jobDetail.id}/task/assign?assigned=true&${queryString.stringify(rest)}`,
        });
      } else if (query.parent) {
        const parsedParams = query.parent;
        this.setState({
          parent: parsedParams,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(data) {
   const { router: { route: { match: { params } }, history } } = this.context;
    const { location: { pathname } } = this.props;
    const pageSize = cookie.load('task_mapping_size');
    this.setState({ isLoading: true });
    if(data.search && data.assigned !== ''){
      return history.push({ pathname, search: queryString.stringify({...data, assigned: ''}) });
    }
    return this.props.TaskDucks.getTasks({ ...data, page_size: pageSize })
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { router: { route: { match: { params } }, history } } = this.context;
    const { location: { pathname, query } } = this.props;
    if(query && query.job_id){
      this.setParentUrl(query);
    }
    this.setParentUrl(query);
    if(query.job_id){
      this.setState({ isLoading: true });
      this.props.JobDucks.getJob({ id: params.id })
        .then(() => {
          if (!('assigned' in query)) {
            history.push({
              pathname,
              search: queryString.stringify({
                ...query,
                assigned: true,
              }),
            });
          } else {
            this.fetchData(query);
          }
        })
        .catch(() => this.setState({ isLoading: false }));
    }else{
      this.fetchData(query);
    }
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("job_task_info", !isInfoOpen);
  }

  render() {
    const { isLoading, parent, isInfoOpen } = this.state;
    const { jobDetail, infoList, isGeoFenceVisible, location: { query } } = this.props;
    let breadcrumb = [];
    let description;
    let title;
    if (query.assigned === 'false') {
      description = 'Search and select task(s) to assign them to job';
      title = 'Search Tasks and Assign to Job';
    } else {
      description = NAV.JobTaskMappingListPage.description;
      title = NAV.JobTaskMappingListPage.title;
    }
    if (Object.keys(jobDetail).length) {
      breadcrumb = [
        ...NAV.JobTaskMappingListPage.breadcrumb,
        { href: `/job/${jobDetail.id}/edit`, label: `${jobDetail.name}` },
        { label: 'Tasks' },
      ];
      title += ` (${jobDetail.name})`;
    } else {
      breadcrumb = [
        ...NAV.JobTaskMappingListPage.breadcrumb,
        { href: '/job/lists/task', label: 'Tasks' },
        { label: 'All' },
      ];
    }

    const tabs = [
      { href: '/job/list', label: 'Customers/jobs' },
      { href: '/job/lists/task', label: 'Tasks'},
    ];
    if(isGeoFenceVisible){
      tabs.push({ href: '/geofence/job/lists', label: 'Geofences' });
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }
    else{
      tabs.push({ href: '/job/lists/jobreports', label: 'Reports'});
    }

    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.JobTaskMappingListPage, breadcrumb, parent, description, title,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
        jobPage={true}
        isLoading={isLoading}
      >
        <JobTaskMappingFilterSection isLoading={isLoading} selectedTasks = {this.state.selectedTasks} resetSelectedTasks={this.resetSelectedTasks}/>
        <JobTaskMappingListSection isLoading={isLoading} selectTask={this.selectTask} selectedTasks={JSON.parse(JSON.stringify(this.state.selectedTasks))}/>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  infoList: InfoDucks.infoList(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
});

const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

JobTaskMappingListPage.propTypes = {
  location: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
};

JobTaskMappingListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobTaskMappingListPage);
