import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import TwoColumnLayout from 'layouts/TwoColumn';

import ManualCorrectionDetailFilterSection from 'sections/reports/manualCorrectionSummary/DetailFilter';
import ManualCorrectionDetailListSection from 'sections/reports/manualCorrectionSummary/DetailList';
import ActivitySplitListSection from 'sections/reports/manualCorrectionSummary/ActivitySplitList';
import ReportActivitySplitFilterSection from 'sections/reports/manualCorrectionSummary/ActivitySplitFilter';
import MealViolationDetailListSection from 'sections/reports/mealViolation/DetailList';
import MealViolationDetailFilterSection from 'sections/reports/mealViolation/DetailFilter';
import OvertimeDetailFilterSection from 'sections/reports/overtimeSummary/DetailFilter';
import OvertimeDetailListSection from 'sections/reports/overtimeSummary/DetailList';
import GeoFenceBreachDetailFilterSection from 'sections/reports/geoFenceBreach/DetailFilter';
import GeoFenceBreachDetailListSection from 'sections/reports/geoFenceBreach/DetailList';
import CorrectionActivitySplitList from 'sections/reports/correctionRequestReport/CorrectionActivitySplitList'
import CorrectionActivitySplitFilter from 'sections/reports/correctionRequestReport/CorrectionActivitySplitFilter'

import { NAV, REPORT_KEY_MAPPINGS } from 'constants/reports';

/**
 * ReportDataDetailPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ReportDataDetailSection}
 *
 * Fetch Needs:
 *    - getReport
 *    - getReportData
 */
class ReportDataDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.getParsedParams = this.getParsedParams.bind(this);
    this.state = { isLoading: false, parent: null};
  }

  componentDidMount() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;

    const parsedParams = this.getParsedParams(query);
    // Copy only Start & End Date from previous page
    if (Object.keys(query).length) this.fetchData({
      start_date: parsedParams.start_date,
      end_date: parsedParams.end_date,
      ...query,
      ...params,
    });
    this.props.ReportDucks.getReport(params);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { router: { route: { match: { params } } } } = nextContext;
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery && Object.keys(nextQuery).length) {
      const parsedParams = this.getParsedParams(nextQuery);
      // Copy only Start & End Date from previous page
      this.fetchData({
        start_date: parsedParams.start_date,
        end_date: parsedParams.end_date,
        ...nextQuery,
        ...params,
      });
    }
  }

  componentWillUnmount() {
    this.props.ReportDucks.resetReportData();
  }


  getParsedParams(query) {
    let parsedParams;
    try {
      if (query.reportDataDetail) {
        parsedParams = JSON.parse(query.reportDataDetail);
        this.setState({parent: parsedParams && `/report/${parsedParams.id}/${parsedParams.code}/detail/${parsedParams.detailId}?${queryString.stringify(JSON.parse(query.reportDataDetail))}`});
      } else {
        parsedParams = JSON.parse(query.reportDetail);
        this.setState({parent: parsedParams && `/report/${parsedParams.id}/${parsedParams.code}/detail?${queryString.stringify(JSON.parse(query.reportDetail))}`});
      }
    } catch (e) {
      console.log(e);
    }
    return parsedParams;
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    return this.props.ReportDucks.getReportData(params)
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { isLoading, parent } = this.state;
    
    return (
      <TwoColumnLayout navInfo={{
        ...NAV.ReportDetailPage,
        breadcrumb: [
          ...NAV.ReportDetailPage.breadcrumb,
          { label: REPORT_KEY_MAPPINGS[params.code] },
        ],
        parent,
        title: REPORT_KEY_MAPPINGS[params.code],
      }}>
        {
          (() => {
            switch (params.code) {
              case 'OVERTIME-DETAIL':
                return (<div>
                  <OvertimeDetailFilterSection />
                  <OvertimeDetailListSection isLoading={isLoading} />
                </div>);

              case 'MEAL-VIOLATION-DETAIL':
                return (<div>
                  <MealViolationDetailFilterSection  />
                  <MealViolationDetailListSection isLoading={isLoading} />
                </div>);

              case 'EMPLOYEE-ACTIVITY-DETAILS-BY-DATE':
                return (<div>
                  <ReportActivitySplitFilterSection />
                  <ActivitySplitListSection isLoading={isLoading} />
                </div>);
              case 'DETAILS-CORRECTION-REQUEST-REPORT':
                return (<div>
                  <CorrectionActivitySplitFilter />
                  <CorrectionActivitySplitList isLoading={isLoading} />
                </div>);
                
              case 'GEO-FENCE-BREACH-DETAIL':
                return (<div>
                  <GeoFenceBreachDetailFilterSection />
                  <GeoFenceBreachDetailListSection isLoading={isLoading} />
                </div>);
              // Manual Correction Detail
              default:
                return (<div>
                  <ManualCorrectionDetailFilterSection />
                  <ManualCorrectionDetailListSection isLoading={isLoading} />
                </div>);
            }
          })()
        }
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

ReportDataDetailPage.propTypes = {
  location: PropTypes.object.isRequired,
  ReportDucks: PropTypes.object.isRequired,
};

ReportDataDetailPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ReportDataDetailPage);
