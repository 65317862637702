
const {
  REACT_APP_APPLICATION_BASE_URL,
  REACT_APP_KONNECT_BASE_URL,
  REACT_APP_KIOSK_BASE_URL,
  REACT_APP_CLIENT_ID_BASE_URL,
  REACT_APP_CLIENT_SECRET_BASE_URL,
  REACT_APP_SCHEDULING_EXE_BASE_URL,
  REACT_APP_SCHEDULING_BASE_URL,
  REACT_APP_GEOCODING_API,
  REACT_APP_JOB_CASTING_DETAILS_BASE_URL,
  REACT_APP_JOB_CASTING_SUMMARY_BASE_URL,
} = process.env;

const config = {
  homepage: 'http://thoughts2binary.com',

  // Web analytics settings
  analytics: {
    google: { trackingID: 'UA-XXXXX-Y' },
  },

  // Backend Server Configs
  server: {
    apiHost: REACT_APP_APPLICATION_BASE_URL,
    konnectHost: REACT_APP_KONNECT_BASE_URL,
    kioskHost: REACT_APP_KIOSK_BASE_URL,
    ClientId: REACT_APP_CLIENT_ID_BASE_URL,
    ClientSecret: REACT_APP_CLIENT_SECRET_BASE_URL,
    schedulingHost : REACT_APP_SCHEDULING_EXE_BASE_URL,
    geocodingOption: REACT_APP_GEOCODING_API,
    jobCastingDetailsHost: REACT_APP_JOB_CASTING_DETAILS_BASE_URL,
    jobCastingSummaryHost: REACT_APP_JOB_CASTING_SUMMARY_BASE_URL,
  },
};

module.exports = config;
