import React from 'react';

import PropTypes from 'prop-types';

import Downshift from 'downshift';
import styles from 'atoms/CountryCode/styles.module.scss';
import IndiaFlag from 'atoms/CountryCode/india.png';
import AustraliaFlag from 'atoms/CountryCode/australia.png';
import UKFlag from 'atoms/CountryCode/uk.png';
import USFlag from 'atoms/CountryCode/us.png';
import CanadaFlag from 'atoms/CountryCode/canada.png';

const items = [
  { code: '+61', name: 'Australia (+61)', flag: AustraliaFlag },
  { code: '+91', name: 'India (+91)', flag: IndiaFlag },
  { code: '+1', name: 'United States (+1)', flag: USFlag },
  { code: '+1', name: 'Canada (+1)', flag: CanadaFlag },
  { code: '+44', name: 'United Kingdom (+44)', flag: UKFlag },
];

class CountryCodeAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
    this.state = {
      isOpen: false,
      selectedCountry: props.field && props.field.value ? items.find((i) => i.code === props.field.value)
        : items[2],
    };
  }

  componentDidMount(){
    console.log(this.props,"props");
    const  { field, form: { setFieldValue } } = this.props;
    setFieldValue(field.name, this.state.selectedCountry.code);
  }

  handleSelect(selectedItem) {
    const { field, form: { setFieldValue }, onChange } = this.props;    
    this.setState({ selectedCountry: selectedItem });
    setFieldValue(field.name, selectedItem.code);
    if (onChange) onChange(selectedItem.code);
  }

  handleToggleMenu() {
    /* eslint-disable */
    const { disabled } = this.props;
    if(disabled) return true;
    return this.setState(({ isOpen }) => {
      return { isOpen: !isOpen };
    });
    /* eslint-enable */
  }


  render() {
    const {
      field, id, label, disabled
    } = this.props;
    const { isOpen, selectedCountry } = this.state;
    return (
      /* eslint-disable */
      <Downshift
        itemToString={(i) => i && i.name}
        onSelect={this.handleSelect}
        isOpen={isOpen}
        defaultSelectedItem={items.find((i) => i.code === field.value)}
        onOuterClick={() => this.setState({ isOpen: false })}
        render={({
          getInputProps,
          getItemProps,
          getLabelProps,
          inputValue,
          selectedItem,
          highlightedIndex,
          clearSelection,
        }) => {
          
          return (<div className="pos-relative flag-dropdown" onClick={() => this.handleToggleMenu()}>
            {label &&
              <label {...getLabelProps({
                htmlFor: id,
              })}
              className={`${styles['label']} mb-2`}>
                {label}
              </label>
            }
            <div className={ disabled ? `${styles['country-flag']} country-flag disabled` : styles['country-flag']}>
              <span
                role="button"
                tabIndex="0"
                className={disabled ? `${styles['country-flag__dropdown']} country-flag disabled` : styles['country-flag__dropdown']}
              >
                <img
                  src={selectedCountry && selectedCountry.flag}
                  height="18px"
                  className="d-inline-block"
                />
              </span>
              <button
                type="button"
                className={styles['button']}
              />
              <span className={styles['input']}>{selectedCountry && selectedCountry.code}</span>
            </div>
            {isOpen ? (
              <div className={items.length ? "autocomplete-drop-menu" : null}> 
                {items.map((item, index) => {
                  return (<div
                    key={index}
                    {...getItemProps({
                      item,
                      onClick: this.handleToggleMenu,
                    })}
                    style={{
                      backgroundColor:
                        highlightedIndex === index ? '#FCF2E4' : 'white',
                      fontWeight: selectedItem === item ? 'bold' : 'normal',
                      lineHeight: '1.1',
                      fontSize: '14px',
                    }}
                    className={styles['padding']}
                  >
                    <span className="outline-none">
                      <img
                        src={item.flag}
                        height="20"
                        width="15%"
                        className="d-inline-block"
                      />
                    </span>
                    &nbsp; {item.name}
                  </div>);
                })}
              </div>
            ) :  null }
          </div>);
        }}
      />
    );
  }
}

CountryCodeAtom.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  itemToString: PropTypes.func,
};

export default CountryCodeAtom;