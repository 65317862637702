import axios from 'lib/api';

export default class ApiGateway {
  configEndpoint = null;
  headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  Get = {
    method: 'GET',
    headers: this.headers
  };
  Post = {
    method: 'POST',
    headers: this.headers
  };
  Put = {
    method: 'PUT',
    headers: this.headers
  };
  Patch = {
    method: 'PATCH',
    headers: this.headers
  };
  Delete = {
    method: 'DELETE',
    headers: this.headers
  };

  constructor(config) {
    this.configEndpoint = config;
  }

  async load(params) {
    return axios(ApiGateway.generateHeader(this.configEndpoint, params, this.Get, true));
  }

  async save(dto) {
    return axios(ApiGateway.generateHeader(this.configEndpoint, dto, this.Post, true));
  }

  async update(dto) {
    return axios(ApiGateway.generateHeader(this.configEndpoint, dto, this.Put, true));
  }

  async partialUpdate(dto) {
    return axios(ApiGateway.generateHeader(this.configEndpoint, dto, this.Patch, true));
  }

  async delete(dto) {
    return axios(ApiGateway.generateHeader(this.configEndpoint, dto, this.Delete, true));
  }

  static generateHeader(url, dto, headerArg, hasData) {
    let header = headerArg;
    header.url = url;
    if (hasData &&
      (header.method === 'POST' ||
        header.method === 'PUT' ||
        header.method === 'PATCH')
      ) 
      {
        header.data = JSON.stringify(dto);
      }
    else if (hasData &&
      (header.method === 'GET'
      )
      ) {
        header.params = dto;
      }
    else if (hasData &&
      (header.method === 'DELETE'
      )
      ) {
        header.data = JSON.stringify(dto);
      }
    return header;
  }
}
