import React from "react";
import Table from "reactstrap/lib/Table";
import Link from 'react-router-dom/Link';
import queryString from 'query-string';

import SectionLoaderAtom from "atoms/SectionLoader";
import EditIconAtom from 'atoms/EditIcon';
import styles from './styles.module.scss'


class ApplicantListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      list,
      isLoading,
      selectedApplicants,
      onSelectApplicant,
      location: { pathname ,search }
    } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className={`ml-3 mr-3 pt-0 `}>
        <Table responsive hover className="table-emp">
          <thead className="Header-col">
            <tr>
              <th className="pl-3">&nbsp;</th>
              <th className="text-center">Action</th>
              <th className="text-center">ID</th>
              <th className="text-center">First Name</th>
              <th className="text-center">Last Name</th>
              <th className="text-center">Username</th>
              <th className="text-center">Hire Date</th>
              <th className="text-center">Date Created</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length ? (
              list.map((item) => (
                <tr
                  key={item.id}
                >
                  <td
                    title="Select to Mark"
                    className="text-center pt-3"
                  >
                    <input
                      type="checkbox"
                      checked={selectedApplicants.findIndex((i) => i.id === item.id) > -1 ? true : false}
                      onChange={() => onSelectApplicant(item)}
                    />
                  </td>
                  <td className="text-center">
                    <Link to={{
                        pathname: `/applicant/${item.id}/edit/detail`,
                        search: queryString.stringify({
                          parent: `${pathname}${search}`,
                        })
                      }} title="Edit Applicant" >
                        <EditIconAtom className={`${styles['action-icon']}`}/>
                    </Link>
                  </td>
                  <td className="text-center">
                    {item.employee_id}
                  </td>
                  <td className="text-center">
                    {item.first_name}
                  </td>
                  <td className="text-center">
                    {item.last_name}
                  </td>
                  <td className="text-center">
                    {item.username}
                  </td>
                  <td className="text-center">
                    {item.hire_date}
                  </td>
                  <td className="text-center">
                    {item.create_display_date}
                  </td>

                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center p-2" colSpan="9">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default ApplicantListComponent;
