import React from 'react';

import PropTypes from 'prop-types';

import { GoogleApiWrapper } from 'google-maps-react';
import connect from 'react-redux/lib/connect/connect';

import Button from 'reactstrap/lib/Button';
import { toast } from 'react-toastify';

import SectionLoaderAtom from 'atoms/SectionLoader';
//   MapComponent from './Map';
import MapComponent from 'components/common/googleMap/Map';
import MarkerClusterComponent from 'components/common/googleMap/MarkerCluster';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import InfoWindowComponent from 'components/common/googleMap/InfoWindow';
import CircleComponent from 'components/common/googleMap/Circle';
import styles from './styles.module.scss';
// import MealStartIcon from './meal_start.png';
// import MealEndIcon from './meal_end.png';
// import ShiftStartIcon from './shift_start.png';
// import ShiftEndIcon from './shift_end.png';
import BeaconIcon from './beacon.png';
import MarkerIcon from './marker.png';

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

class ActivityMapComponent extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        center: { lat: -25.363882, lng: 131.044922 },
        zoom: 9,
        showInfoWindow: false,
        activeShape: {},
        shapeEvent: {},
        data: {},
        addressArray: [],
        selectedMarkerId: null,
        currentIndex: props.sessionInfo && props.sessionInfo.locations && props.sessionInfo.locations.length,
      };
    this.markers = [];
    this.currentIndex = -1;
    this.nextMarker = this.nextMarker.bind(this);
    this.prevMarker = this.prevMarker.bind(this);
    this.onZoomChanged = this.onZoomChanged.bind(this);
    this.onShapeClick = this.onShapeClick.bind(this);
    this.onGeoFenceClick = this.onGeoFenceClick.bind(this);
    this.highlightMarker = this.highlightMarker.bind(this);
    this.changeIndex = this.changeIndex.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
    this.moveCaretAtEnd = this.moveCaretAtEnd.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(JSON.stringify(this.props.highlightedMarker) !== JSON.stringify(nextProps.highlightedMarker)){
      this.highlightMarker(nextProps.highlightedMarker);
    }
    if((nextProps.sessionInfo.locations && nextProps.sessionInfo.locations.length) !==
      (this.props.sessionInfo.locations && this.props.sessionInfo.locations.length)){
      if(nextProps.sessionInfo && nextProps.sessionInfo.locations && nextProps.sessionInfo.locations.length){
        this.setState(({currentIndex}) => ({ currentIndex: nextProps.sessionInfo.locations.length }));
      }
    }
  }

  componentWillUnmount(){
    this.props.resetData();
  }

  onShapeClick(data) {
    this.props.onMarkerClick(data);
  }

  onGeoFenceClick(data, shape, event) {
    this.props.toggleInfoWindow();
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data,
    });
  }


  onZoomChanged(center,zoom) {
    this.setState({ center });
  }

  onPressEnter(event){
    if(event.which === 13){
      this.node.blur();
    }
  }

  nextMarker() {
    this.currentIndex += 1;
    const currentMarker = this.props.sessionInfo.locations[
      this.currentIndex];
    this.markers.push(currentMarker);
    this.setState({
      center: {
        lat: currentMarker.location.coordinates[1],
        lng: currentMarker.location.coordinates[0]
      },
    });
    this.setState({ selectedMarkerId: currentMarker.id, currentIndex: this.currentIndex + 1  });
    this.props.onMarkerClick(currentMarker);
  }

  prevMarker() {
    this.currentIndex -= 1;
    const currentMarker = this.props.sessionInfo.locations[
      this.currentIndex];
    this.markers.pop();
    this.setState({
      center: {
        lat: currentMarker.location.coordinates[1],
        lng: currentMarker.location.coordinates[0]
      },
    });
    this.setState({ selectedMarkerId: currentMarker.id, currentIndex: this.currentIndex + 1 });
    this.props.onMarkerClick(currentMarker);
  }

  highlightMarker(marker){
    if(marker.id >= this.markers.length -1){
      const newarr = this.props.sessionInfo.locations.filter((item) => item.id <= marker.id);
      this.markers = newarr;
      this.currentIndex = newarr.length - 1;
      this.setState({ currentIndex: newarr.length });
    }
    else if(this.state.currentIndex === ''){
      this.currentIndex = marker.id;
      this.setState({ currentIndex: marker.id + 1 });
    }
    this.setState({ selectedMarkerId: marker.id });
    if(!marker.showInfo){
      this.props.toggleInfoWindow(marker);
    }
  }

  changeIndex(event){
    const { sessionInfo } = this.props;
    const val = event.target.value;
    const res = val.match('^[0-9]+$');
    if(val === ''){
      return this.setState({ currentIndex: '' });
    }
    if(val === '0' || res === null){
      return toast.error('Choose a valid marker');
    }
    const newarr = sessionInfo.locations.filter((item) => item.id < val);
    if(val > sessionInfo.locations.length) return toast.error('Choose a valid marker');
    this.markers = newarr;
    this.currentIndex = newarr.length - 1;
    this.setState({ selectedMarkerId: val, currentIndex: newarr.length });
    const isInfoOpen = sessionInfo.locations.filter((item) => item.id === val-1).length ?
      sessionInfo.locations.filter((item) => item.id === val-1)[0].showInfo : true;

    const marker = sessionInfo.locations.filter((item) => item.id === val-1).length ?
      sessionInfo.locations.filter((item) => item.id === val-1)[0] : null;
    if(!marker.address){
      this.props.getLocationAddress(marker);
    }
    if(!isInfoOpen){
      this.props.toggleInfoWindow({ id: val-1 });
    }
    return true;
  }

  moveCaretAtEnd(e) {
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  }

  render() {
    const { sessionInfo, google, isLoading,
      loaded,isModalLoading, isLocationLoading,
      toggleInfoWindow,  geoFenceDetail, permission } = this.props;
    const { activeShape, currentIndex, showInfoWindow, shapeEvent, data, selectedMarkerId } = this.state;
    if (sessionInfo.locations && sessionInfo.locations.length) {
      if (this.currentIndex === -1) {
        this.currentIndex = sessionInfo.locations.length - 1;
      }
      this.markers = sessionInfo.locations.slice(0, this.currentIndex + 1);
      this.markers = this.markers.map((item) => {
        let iconObj;
        let markerObj = {};

        if(item.activity_name === 'Beacon'){
          iconObj = {
            url: BeaconIcon,
            scaledSize: google && new google.maps.Size(30, 30),
            labelOrigin: google && new google.maps.Point(15,12),
            origin: google && new google.maps.Point(0, 0),
          };
        }else {
          iconObj = {
            url: MarkerIcon,
            scaledSize: google && new google.maps.Size(30, 30),
            labelOrigin: google && new google.maps.Point(15,12),
            origin: google && new google.maps.Point(0, 0),
          };
        }
        markerObj = {...item, icon: iconObj};
        return markerObj;
      });

      const lastMarker = this.markers[this.currentIndex];
      if (this.currentIndex === sessionInfo.locations.length - 1 && !lastMarker.address) {
        this.props.onMarkerClick(lastMarker);
      }
    }

    const markersDetail = this.markers.map(item => ({...item, shape_type: item.location.type,
      lat_long: { coordinates: item.location.coordinates }}));

    const sesssionInfoMappingGeofenceDetail = markersDetail;
    markersDetail.map((item) => {
      if(geoFenceDetail.length){
        geoFenceDetail.map((geofenceItem) => {
          sesssionInfoMappingGeofenceDetail.push(geofenceItem);
          return true;
        })
      }
      return true;
    });
   
    return (
      <div className={styles['map-wrap']}>
        {loaded && !isLoading
          ?<section style={{ height: '60vh', position: 'relative' }}>
            { !permission &&
              <div className={styles['overlay-tracking']}>
                <div className="text-center">
                  You do not have permission to view employee tracking.
                  <br/>Please contact your administrator.
                </div>
              </div>
            }
            <MapComponent
              google={google}
              locations={sesssionInfoMappingGeofenceDetail}
              getAddressForMarker = {this.props.getAddressForMarker}
              setLocation={this.currentIndex >= 0
                ? {
                  position: sessionInfo.locations[this.currentIndex].position,
                  zoom: 13,
                }
                : null
              }
            >
              {geoFenceDetail.length && geoFenceDetail.map((item, index) => {
                if (item.shape_type === 'Point') {
                  return <MarkerComponent
                    key={item.id}
                    getAddressForMarker = {this.props.getAddressForMarker}
                    data={item}
                    position={{
                      lat: item.lat_long.coordinates && item.lat_long.coordinates[1],
                      lng: item.lat_long.coordinates && item.lat_long.coordinates[0],
                    }}
                    onClick={this.onGeoFenceClick}
                  />;
                }
                else if (item.shape_type === 'Polygon') {
                  return <PolygonComponent
                    key={item.id}
                    data={item}
                    getAddressForMarker = {this.props.getAddressForMarker}
                    paths={
                      item.lat_long &&
                        item.lat_long.coordinates[0].map(latLng => ({
                          lat: latLng[1],
                          lng: latLng[0],
                        }))
                    }
                    fillColor={item.color}
                    onClick={this.onGeoFenceClick}
                  />;
                }
                else if (item.shape_type === 'Rectangle') {
                  return <RectangleComponent
                    key={item.id}
                    data={item}
                    getAddressForMarker = {this.props.getAddressForMarker}
                    bounds={item.lat_long.coordinates}
                    fillColor={item.color}
                    onClick={this.onGeoFenceClick}
                  />;
                }
                else if (item.shape_type === 'Circle') {
                  return <CircleComponent
                    key={item.id}
                    data={item}
                    getAddressForMarker = {this.props.getAddressForMarker}
                    radius={item.radius}
                    center={{
                      lat: item.lat_long && item.lat_long.coordinates[1],
                      lng: item.lat_long && item.lat_long.coordinates[0],
                    }}
                    fillColor={item.color}
                    onClick={this.onGeoFenceClick}
                  />;
                }
                return null;
              })}
              <MarkerClusterComponent
                data={this.markers}
                onClick={this.onShapeClick}
                toggleInfoWindow={toggleInfoWindow}
                selectedMarkerId={parseInt(selectedMarkerId, 10)}
              />
              <InfoWindowComponent
                shape={activeShape}
                visible={showInfoWindow}
                shapeEvent={shapeEvent}
                data={data}
                style={{"margin-bottom": "200px" }}
              >
                <div>
                  {data.name && <div>GeoFence: {data.name}</div>}
                  {data.employee_name && <div>Employee: {data.employee_name}</div>}
                  {data.datetime && <div>Date: {data.datetime}</div>}
                  {data.activity_name && <div>Activity: {data.activity_name === 'Start of Geofence Breach' ? 'Left' : data.activity_name === 'End of Geofence Breach' ? 'Entered' : data.activity_name}</div>}
                  {data.address && <div>Address: {data.address}</div>}
                  {data.create_date_display && <div>Date Created: {data.create_date_display}</div>}
                </div>
              </InfoWindowComponent>
            </MapComponent>
            {!(sessionInfo && sessionInfo.locations && sessionInfo.locations.length) &&
              !isLocationLoading && permission
              ?<div className={styles['no-location']}>
                No location is recorded for this date !!
              </div> : null }
            { isLocationLoading ?
              <div> &nbsp;</div>
              : <section className="float-right">
                <div>
                  {sessionInfo.locations && sessionInfo.locations.length ?
                    <div>
                      <Button
                        color="default"
                        className={styles['pagination-btn']}
                        disabled={this.currentIndex === 0}
                        onClick={this.prevMarker}
                      >
                      &#8249;
                      </Button>
                      { /* eslint-disable */
                        <input
                          className={styles['pagination-btn__current']}
                          onChange={(val) => this.changeIndex(val)}
                          value={currentIndex}
                          onFocus={this.moveCaretAtEnd}
                          onKeyPress={(event) => this.onPressEnter(event)}
                          ref={node => {this.node = node}}
                          data-lpignore={true}
                          autoComplete="off"
                        />
                        /* eslint-disable */}
                      <Button
                        color="default"
                        className={styles['pagination-btn']}
                        disabled={Object.keys(sessionInfo).length > 0 && !isModalLoading ? this.currentIndex + 1 === sessionInfo.locations.length : null}
                        onClick={this.nextMarker}
                      >
                        &#8250;
                      </Button>
                    </div>
                    : null }
                </div>
                  </section> }
              </section>
              : <section style={{ height: '60vh' }}><SectionLoaderAtom active /></section>
            }
      </div>
    );
  }
};

ActivityMapComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sessionInfo: PropTypes.object.isRequired,
  onMarkerClick: PropTypes.func,
  // onMarkerClose: PropTypes.func,
  toggleInfoWindow: PropTypes.func,
  // toggle: PropTypes.func.isRequired,
  google: PropTypes.object,
  // selectedMarkerAddress: PropTypes.string,
  geoFenceDetail: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  // isModalLoading: PropTypes.bool.isRequired,
  highlightedMarker: PropTypes.object.isRequired,
  isLocationLoading: PropTypes.bool.isRequired,
  resetData: PropTypes.func.isRequired,
  getLocationAddress: PropTypes.func,
  permission: PropTypes.bool,
};

const withConnect = connect(
)(ActivityMapComponent);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION
})(withConnect);
