import React from 'react';

import PropTypes from 'prop-types';
import MaintenanceMode from 'pages/maintenanceMode';

class NoMatch extends React.Component {
  render() {
    return (
        <MaintenanceMode/>
    );
  }
}

NoMatch.propTypes = {
  location: PropTypes.object.isRequired,
};

NoMatch.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NoMatch;
