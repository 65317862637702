import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as GroupDucks from 'ducks/vendors/group';
import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as RoleDucks from 'ducks/vendors/role';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import VendorRoleListSection from 'sections/vendors/roleList/List';
import VendorRoleFilterSection from 'sections/vendors/roleList/Filter';

import { NAV } from 'constants/vendors';

/**
 * VendorRoleListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link VendorRoleListSection}
 *
 * Fetch Needs:
 *    None
 */
class VendorRoleListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.saveRoleOrderPreference = this.saveRoleOrderPreference.bind(this);
    this.getRoleIds= this.getRoleIds.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_role_setting");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_role_setting", !isInfoOpen);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('role_page_size');

    return Promise.all([
      this.props.RoleDucks.getRoles({...params, page_size: pageSize}),
      this.props.InfoDucks.getInformations({route: 'vendor.role_setting'}),
      this.props.RoleDucks.getRolesIds()
    ])
    .then(() => this.setState({ isLoading: false }))
    .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    this.props.GroupDucks.getGroups({paginate: false})
      .then(() => {
        this.fetchData(query);
      });
  }

  saveRoleOrderPreference(order){
    return this.props.UserDucks.saveUserOrderPreference({data:{sequence: order} , preference_type: 'user_preference_roles'});
  }

  getRoleIds(){
    return this.props.RoleDucks.getRolesIds();
  }

  render() {
    const { userRole, isSubCompany, isOrganizationVisible, infoList, companyPermissions, roleIdsList} = this.props;
    const { isLoading, isInfoOpen } = this.state;
    console.log('userRolePreference', this.props.userRolePreference);
    const tabs = [
      { href: '/vendor/role-setting', label: 'Roles' },
      !isSubCompany && isOrganizationVisible && (!userRole || userRole === 'Client Admin')
        ? { href: '/vendor/sub-company', label: 'Manage Branches' }
        : null,
      isOrganizationVisible && companyPermissions.manage_organization
        ? { href: '/vendor/department', label: 'Departments' }
        : null,
    ];

    console.log('roles', this.props.roleIdsList);

    return (
      <SSTCFLayout
        navInfo={NAV.VendorRoleListPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <VendorRoleFilterSection />
        <VendorRoleListSection getRoleIds={this.getRoleIds} isLoading={isLoading} saveRoleOrderPreference={this.saveRoleOrderPreference} rolesIds = {roleIdsList}/>
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userRole: UserDucks.userRole(state),
  isSubCompany: UserDucks.isSubCompany(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  infoList: InfoDucks.infoList(state),
  roleIdsList: RoleDucks.roleIdsList(state),
});

const mapActionsToProps = dispatch => ({
  GroupDucks: bindActionCreators(GroupDucks, dispatch),
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

VendorRoleListPage.propTypes = {
  location: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  GroupDucks: PropTypes.object.isRequired,
  RoleDucks: PropTypes.object.isRequired,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
  companyPermissions: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

VendorRoleListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorRoleListPage);
