import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import * as RouteDucks from "ducks/routes";
import * as UserDucks from "ducks/accounts/user";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import queryString from "query-string";
import AdjustmentTypesFormModalComponent from "components/vendors/adjustmentTypes/FormModal";
import SectionLoaderAtom from "atoms/SectionLoader";
import FilterDropdownComponent from "components/common/FilterDropdown";
import { STATUS_OPTIONS } from "constants/vendors/records";

class MealAdjustmentsFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.goTo = this.goTo.bind(this);
    this.state = { isOpen: false, isLoading: false };
  }

  update(data) {
    const {
      userExtraData,
      location: { query },
    } = this.props;
    const detail = { ...data, company_name: userExtraData.company.id };
    return this.props.AdjustmentTypesDucks.postAdjustmentTypes(detail).then(
      () => {
        this.props.fetchData(query);
        toast.success("successfully added");
        this.setState({ isOpen: false, isLoading: false });
      }
    );
  }

  toggle(data) {
    const { isOpen } = this.state;
    if (isOpen) return this.setState({ isOpen: !isOpen });
    return this.props.AdjustmentTypesDucks.getAdjustmentTypesDetail({
      id: data.id,
    }).then(() => this.setState({ isOpen: !isOpen }));
  }

  addtoggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  onStatusSelect(id) {
    this.props.AdjustmentTypesDucks.getMealTypes({ is_active: id});
  }

  goTo(url) {
    this.props.history.push(url);
  }

  render() {
    const { isLoading, location } = this.props;
    const { isOpen } = this.state;

    return (
      <Row className="ml-3 mr-4 pt-2 mb-2">
        <Col
          xs={12}
          className="pr-0 d-flex justify-content-between align-items-center"
        >
          <div style={{ marginLeft: "-16px" }}>
            <FilterDropdownComponent
              size="lg"
              paramKey="status"
              location={location}
              options={STATUS_OPTIONS}
              onChange={(val) => this.onStatusSelect(val)}
            />
          </div>
          <div>
            <Button
              className="btn-other btn-width ml-2"
              size="sm"
              onClick={() => this.goTo("/vendor/meal-adjustments/mealDetails")}
            >
              Add
            </Button>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  userExtraData: UserDucks.userExtraData(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  adjustmentTypesDetail: AdjustmentTypesDucks.adjustmentTypesDetail(state),
  location: RouteDucks.location(state),
});

const mapActionToProps = (dispatch) => ({
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
});

MealAdjustmentsFilterSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(withRouter(MealAdjustmentsFilterSection));
