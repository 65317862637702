import React from "react";

import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import { toast } from "react-toastify";

import { NavDropdown, Popover } from "reactstrap";

import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";

import * as NotificationsDucks from "ducks/notifications/notification";

import SectionLoaderAtom from "atoms/SectionLoader";
import DeleteIconAtom from "atoms/DeleteIcon";
import ButtonLoaderAtom from "atoms/ButtonLoader";

import NotificationIcon from "components/common/Notification/assets/NotificationIcon.svg";
import NotificationCircleIcon from "components/common/Notification/assets/NotificationCircleIcon.png";
import MenuIcon from "components/common/Notification/assets/MenuIcon.png";
import TrashIcon from "components/common/Notification/assets/trash.svg";
import EmptyImg from "components/common/Notification/assets/EmptyBoxWithClouds.svg";
import closeIcon from "./assets/Cross_icon.png";

import styles from "./styles.module.scss";

class SideMenuNotificationComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.getNotificationList = this.getNotificationList.bind(this);
    this.getNotificationsCounts = this.getNotificationsCounts.bind(this);
    this.handleDeleteNotification = this.handleDeleteNotification.bind(this);
    this.state = {
      notificationList: [],
      isOpen: false,
      loader: false,
      isDeleting: false,
      deletedId: null,
      isMenu: false,
    };
  }

  componentDidMount() {
    this.startInterval();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getNotificationList() {
    this.setState({ loader: true });
    this.props.Notifications.getNotifications({ paginate: false })
      .then((res) => {
        this.setState({
          notificationList: res.value.results,
        });
        this.setState({ loader: false });
      })
      .catch((e) => {
        toast.error(e?.message ?? "Something went wrong");
        this.setState({ loader: false });
      });
  }

  getNotificationsCounts() {
    this.props.Notifications.getNotificationCounts();
  }

  startInterval() {
    this.getNotificationsCounts();
    this.interval = setInterval(() => {
      this.getNotificationsCounts();
    }, 1000 * 30);
  }

  async updateNotificationCount(notification) {
    if (!notification.is_read) {
      const { notificationList } = this.state;
      await this.props.Notifications.updateNotificationCounts(notification.id);
      const updatedList = notificationList.map((item) => {
        return {
          ...item,
          is_read: notification.id === item.id ? true : item.is_read,
        };
      });
      this.setState({ notificationList: updatedList });
    }
  }

  async handleDeleteNotification(id) {
    const { notificationList } = this.state;

    if (notificationList.length === 0) {
      return;
    }

    if (id === "all") {
      this.setState({ loader: true });
    } else {
      this.setState({ isDeleting: true, deletedId: id });
    }

    const params = {
      notification_ids: id === "all" ? [] : [id],
    };

    try {
      await this.props.Notifications.deleteNotification(params);
      this.getNotificationsCounts();
      const updatedList =
        id === "all" ? [] : notificationList.filter((item) => item.id !== id);
      this.setState({ notificationList: updatedList });
      toast.success(
        `${
          id === "all" ? "All notifications are" : "Notification"
        } removed successfully`
      );
    } catch (e) {
      toast.error(
        e?.non_field_errors ? e?.non_field_errors[0] : "Something went wrong"
      );
    }

    if (id === "all") {
      this.setState({ loader: false });
    } else {
      this.setState({ isDeleting: false, deletedId: null });
    }
  }

  toggle() {
    const { isOpen, isMenu } = this.state;
    if (!isOpen) {
      this.getNotificationList();
    }
    this.setState({ isOpen: !isOpen });
    if (isMenu) {
      this.setState({ isMenu: false });
    }
  }

  toggleMenu(e) {
    e.stopPropagation();
    const { isMenu } = this.state;
    this.setState({ isMenu: !isMenu });
  }

  render() {
    const { tether, notificationCount, sidemenuCollapsed } = this.props;
    const {
      notificationList,
      isOpen,
      loader,
      isDeleting,
      deletedId,
      isMenu,
    } = this.state;
    
    return (
      <div className={styles["sidenav-dropdown-collapse"]}>
        <NavDropdown
          isOpen={isOpen}
          toggle={() => !isMenu && this.toggle()}
          tether={tether}
          id="sidenav-dropdown"
        >
          <DropdownToggle nav caret>
            <div className="d-inline">
              <div
                className={styles["image-upload-collapse"]}
                style={{ margin: sidemenuCollapsed ? "5px" : null }}
              >
                <img
                  src={NotificationIcon}
                  alt="Logo"
                  height={"20px"}
                  width={"20px"}
                                  />
                {notificationCount ? (
                  <span className={styles["notification-dot"]} />
                ) : null}
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu
            className={
              !sidemenuCollapsed
                ? styles["dropdownMenu"]
                : styles["dropdownMenu-collapse"]
            }
          >
            <div className={styles["notifcation-header"]}>
              <div>Notifications</div>
              <div id="menu-icon">
                <img
                  src={closeIcon}
                  alt="Close"
                  className={styles["close-icon"]}
                  onClick={(e) => this.toggle(e)}
                />
                <img
                  src={MenuIcon}
                  alt="Close"
                  className={styles["menu-icon"]}
                  onClick={(e) => {
                    this.toggleMenu(e);
                  }}
                />
              </div>
              <Popover
                placement="bottom"
                target={`menu-icon`}
                className={styles["menu-popover-tooltip-wrapper"]}
                isOpen={isMenu}
                toggle={(e) => {
                  this.toggleMenu(e);
                }}
              >
                <div>
                  <div
                    className={styles["menu-list"]}
                    onClick={() => this.handleDeleteNotification("all")}
                  >
                    <img
                      src={TrashIcon}
                      alt="Close"
                      height={"25px"}
                      width={"25px"}
                    />
                    <text className={styles["menu-list-text"]}>Clear All</text>
                  </div>
                </div>
              </Popover>
            </div>

            {loader ? (
              <SectionLoaderAtom active left="45%" />
            ) : (
              <div
                style={{
                  overflow: "auto",
                  maxHeight: sidemenuCollapsed ? "405px" : "380px",
                  alignItems: "center",
                }}
              >
                {notificationList.length > 0 ? (
                  notificationList.map((item, index) => (
                    <div
                      className={styles["notification-list-wrapper"]}
                      key={`item_${index}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.updateNotificationCount(item);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          src={NotificationCircleIcon}
                          alt="Logo"
                          height={"32px"}
                          width={"32px"}
                        />
                        {!item.is_read ? (
                          <span className={styles["notification-read-dot"]} />
                        ) : null}
                      </div>
                      <div
                        style={{
                          lineHeight: "1.4rem",
                          padding: "5px",
                          marginLeft: !item.is_read ? "-10px" : null,
                        }}
                      >
                        <div
                          weight="bold"
                          className={styles["notification-list"]}
                          dangerouslySetInnerHTML={{ __html: item.verb }}
                        />
                        <div
                          className="d-flex justify-content-between"
                        >
                          <div>{item.create_date}</div>
                          <div className={styles["delteIcon"]}>
                            {!isDeleting ? (
                              <DeleteIconAtom
                                title="Clear Notification"
                                height="15"
                                width="15"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleDeleteNotification(item.id);
                                }}
                              />
                            ) : null}
                          </div>
                          {isDeleting && deletedId === item.id ? (
                            <ButtonLoaderAtom
                              active={isDeleting}
                              color="#09819A"
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={styles["empty-screen"]}>
                    <img
                      alt="Empty Screen"
                      height="375px"
                      width="350px"
                      src={EmptyImg}
                    />
                  </div>
                )}
              </div>
            )}
          </DropdownMenu>
        </NavDropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notificationCount: NotificationsDucks.notificationCount(state),
});

const mapActionsToProps = (dispatch) => ({
  Notifications: bindActionCreators(NotificationsDucks, dispatch),
});

SideMenuNotificationComponent.defaultProps = {
  tether: {
    targetAttachment: "bottom right",
    offset: "300px 10px",
  },
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SideMenuNotificationComponent);
