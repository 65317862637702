import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_EMPLOYEES = 'employees/employee/GET_EMPLOYEES';
const GET_CHANGED_BY = 'company/users/GET_CHANGED_BY';
const GET_CHANGED_BY_FULFILLED = 'company/users/GET_CHANGED_BY_FULFILLED';
const GET_EMPLOYEES_FULFILLED = 'employees/employee/GET_EMPLOYEES_FULFILLED';
const GET_EMPLOYEE_TAGS = 'employees/employee/GET_EMPLOYEE_TAGS';
const GET_EMPLOYEE_TAGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_TAGS_FULFILLED';
const GET_ASSIGNED_EMPLOYEES = 'employees/employee/GET_ASSIGNED_EMPLOYEES';
const GET_ASSIGNED_EMPLOYEES_FULFILLED = 'employees/employee/GET_ASSIGNED_EMPLOYEES_FULFILLED';
const GET_ALL_EMPLOYEES = 'employees/employee/GET_ALL_EMPLOYEES';
const GET_ALL_EMPLOYEES_FULFILLED = 'employees/employee/GET_ALL_EMPLOYEES_FULFILLED';
const GET_EMPLOYEE_AUDIT_LOGS = 'employees/employee/GET_EMPLOYEE_AUDIT_LOGS';
const GET_EMPLOYEE_AUDIT_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_AUDIT_LOGS_FULFILLED';
const GET_EMPLOYEES_AUDIT_LOGS = 'employees/employee/GET_EMPLOYEES_AUDIT_LOGS';
const GET_EMPLOYEE_ACTIVITY_LOGS = 'employees/employee/GET_EMPLOYEE_ACTIVITY_LOGS'
const GET_EMPLOYEES_AUDIT_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEES_AUDIT_LOGS_FULFILLED';
const GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED = 'employees/employee/GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED';

const GET_STATS = 'employees/employee/GET_STATS';
const GET_STATS_FULFILLED = 'employees/employee/GET_STATS_FULFILLED'
const SELECT_EMPLOYEES = 'employees/employee/SELECT_EMPLOYEES';
const SELECT_EMPLOYEES_ALL = 'employees/employee/SELECT_EMPLOYEES_ALL';
const POST_ACTIVE_STATUS = 'employees/employee/POST_ACTIVE_STATUS';
const POST_ACTIVE_STATUS_FULFILLED = 'employees/employee/POST_ACTIVE_STATUS_FULFILLED';
const GET_EMPLOYEE = 'employees/employee/GET_EMPLOYEE';
const GET_EMPLOYEE_FULFILLED = 'employees/employee/GET_EMPLOYEE_FULFILLED';
const GET_EMPLOYEE_PENDING = 'employees/employee/GET_EMPLOYEE_PENDING';
const RESET_PASSWORD = 'employees/employee/RESET_PASSWORD';
const POST_EMPLOYEE = 'employees/employee/POST_EMPLOYEE';
const POST_EMPLOYEE_FULFILLED = 'employees/employee/POST_EMPLOYEE_FULFILLED';
const PUT_EMPLOYEE = 'employees/employee/PUT_EMPLOYEE';
const PUT_EMPLOYEE_FULFILLED = 'employees/employee/PUT_EMPLOYEE_FULFILLED';
const POST_KIOSK_AUDITS = 'employees/employee/POST_KIOSK_AUDITS';
const POST_KIOSK_AUDITS_FULFILLED = 'employees/employee/POST_KIOSK_AUDITS_FULFILLED';
const SELECT_EMPLOYEE_AUDIT = 'employees/employee/SELECT_EMPLOYEE_AUDIT';
const RESET_EMPLOYEE = 'employees/employee/RESET_EMPLOYEE';
const POST_BULK_EMPLOYEE = 'employees/employee/POST_BULK_EMPLOYEE';
const POST_BULK_EMPLOYEE_FULFILLED = 'employees/employee/POST_BULK_EMPLOYEE_FULFILLED';
const GET_BULK_EMPLOYEE = 'employees/employee/GET_BULK_EMPLOYEE';
const GET_BULK_EMPLOYEE_FULFILLED = 'employees/employee/GET_BULK_EMPLOYEE_FULFILLED';
const EXPORT_EMPLOYEES = 'employees/employee/EXPORT_EMPLOYEES';
const RESEND_LINK = 'employees/employee/RESEND_LINK';
const UPDATE_EMPLOYEE_WAGE = 'employees/employee/UPDATE_EMPLOYEE_WAGE';
const UPDATE_EMPLOYEE_WAGE_FULFILLED = 'employees/employee/UPDATE_EMPLOYEE_WAGE_FULFILLED';

/**
 * @ Fetch employees
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getEmployees(params) {
  return {
    type: GET_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

/**
 * @ Fetch who changed something
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getChangedBy(params){
  return {
    type: GET_CHANGED_BY,
    payload: axios.get(URLS.CHANGED_BY, {params}),
  }

}

/**
 * @ Fetch employee tags
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getEmployeeTags(params) {
  return {
    type: GET_EMPLOYEE_TAGS,
    payload: axios.get(URLS.EMPLOYEE_TAGS, { params }),
  };
}

/**
 * @ Resend link
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function resendLink(params) {
  return {
    type: RESEND_LINK,
    payload: axios.get(`${URLS.RESEND_LINK}${params}` , {}),
  };
}

/**
 * @ Fetch assigned employees
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getAssignedEmployees(params) {
  return {
    type: GET_ASSIGNED_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

/**
 * @ Export employees
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function exportEmployees(params) {
  return {
    type: EXPORT_EMPLOYEES,
    payload: axios.get(URLS.EXPORT_EMPLOYEE,{ params }),
  };
}

/**
 * @ Fetch all employees
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getAllEmployees(params) {
  return {
    type: GET_ALL_EMPLOYEES,
    payload: axios.get(URLS.EMPLOYEE, { params }),
  };
}

/**
 * @ Fetch employee audit logs
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getEmployeeAuditLogs(params) {
  return {
    type: GET_EMPLOYEE_AUDIT_LOGS,
    payload: axios.get(`${URLS.EMPLOYEE}${params.id}/${URLS.KIOSK_EMPLOYEE_AUDIT_LOGS}`, { params }),
  };
}

/**
 * @ Fetch employees audit logs
 * @param {Object} params - Parameters for the API request
 * @returns {Object} Action
 */
export function getEmployeesAuditLogs(params) {
  return {
    type: GET_EMPLOYEES_AUDIT_LOGS,
    payload: axios.get(URLS.KIOSK_EMPLOYEES_AUDIT_LOGS, { params }),
  };
}

/**
 * @ Fetch employee activity logs
 * @param {Object} data - Parameters for the API request
 * @returns {Object} Action
 */
export function getEmployeeActivityLogs(data) {
  const {empId,...params}=data
  return {
    type: GET_EMPLOYEE_ACTIVITY_LOGS,
    payload: axios.get(`${URLS.EMPLOYEE}${URLS.KIOSK_EMPLOYEES_ACTIVITY_LOGS}${empId}/`, { params }),
  };
}

/**
 * @ Fetch statistics
 * @returns {Object} Action
 */
export function getStats() {
  return {
    type: GET_STATS,
    payload: axios.get(URLS.DASHBOARD_DATA),
  };
}

/**
 * @ Fetch bulk employee data
 * @param {Object} data - Configuration for the request
 * @returns {Object} Action
 */
export function getBulkEmployee(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: GET_BULK_EMPLOYEE,
    payload: axios.get(URLS.EMPLOYEE_BULK_UPLOAD, config),
  };
}

/**
 * @ Fetch single employee
 * @param {number} id - Employee ID
 * @returns {Object} Action
 */

export function selectEmployees(data) {
  return {
    type: SELECT_EMPLOYEES,
    payload: data,
  };
}

/**
 * @ Reset employee data
 * @returns {Object} Action
 */
export function selectEmployeesAll(data) {
  return {
    type: SELECT_EMPLOYEES_ALL,
    payload: data,
  };
}

/**
 * @ Update employee wage
 * @param {Object} data - Employee data with wage information
 * @returns {Object} Action
 */
export function postResetPasswordEmployee(data) {
  return {
    type: RESET_PASSWORD,
    payload: axios.post(URLS.RESET_PASSWORD, data),
  };
}

/**
 * @param {Object} data - The data to be sent in the POST request, which includes the active status information.
 * @returns {Object} The action object with type `POST_ACTIVE_STATUS` and the Axios POST request promise as the payload.
 */
export function postActiveStatus(data) {
  return {
    type: POST_ACTIVE_STATUS,
    payload: axios.post(URLS.UPDATE_ACTIVE_STATUS, data)
  };
}

/**
 * @ Post new employee
 * @param {Object} data - Employee data
 * @returns {Object} Action
 */
export function getEmployee(params) {
  return {
    type: GET_EMPLOYEE,
    payload: axios.get(`${URLS.EMPLOYEE}${params.id}/`),
  };
}

/**
 * @param {Object} data - The employee data to be sent in the POST request.
 * @returns {Object} The action object with type `POST_EMPLOYEE` and the Axios POST request promise as the payload.
 */
export function postEmployee(data) {
  return {
    type: POST_EMPLOYEE,
    payload: axios.post(URLS.EMPLOYEE, data),
  };
}

/**
 * @ Update employee
 * @param {Object} data - Employee data
 * @returns {Object} Action
 */
export function putEmployee(data) {
  return {
    type: PUT_EMPLOYEE,
    payload: axios.put(`${URLS.EMPLOYEE}${data.id}/`, data),
  };
}

export function updateEmployeeWage(id,data) {
  return {
    type: UPDATE_EMPLOYEE_WAGE,
    payload: axios.patch(`${URLS.EMPLOYEE}${id}/`, data),
  };
}

/**
 * @ Post kiosk audits
 * @param {Object} data - Kiosk audit data
 * @returns {Object} Action
 */
export function postKioskAudits(data) {
  return {
    type: POST_KIOSK_AUDITS,
    payload: axios.post(URLS.VALIDATE_KIOSK_AUDITS, data),
  };
}

/**
 * Action creator for selecting employee audit data.
 *
 * @param {Object} data - The employee audit data to be selected.
 * @returns {Object} The action object with type `SELECT_EMPLOYEE_AUDIT` and the provided data as payload.
 */
export function selectEmployeeAudit(data) {
  return {
    type: SELECT_EMPLOYEE_AUDIT,
    payload: data,
  };
}

/**
 * Action creator for resetting employee data.
 * @param {Object} data - The employee data to be reset.
 * @returns {Object} The action object with type `RESET_EMPLOYEE`.
 */
export function resetEmployee(data) {
  return {
    type: RESET_EMPLOYEE,
  };
}


/**
 * @ Post bulk employee data
 * @param {Object} data - Bulk employee data
 * @returns {Object} Action
 */
export function postBulkEmployee(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: POST_BULK_EMPLOYEE,
    payload: axios.post(URLS.EMPLOYEE_BULK_UPLOAD, data, config),
  };
}

const defaultState = {
  list: { results: [] },
  assignedlist: { results: [] },
  detail: {},
  stats: {},
  all:[],
  resendLink : {},
  tagList : [],
  changedByList: [],
  activityLogsList :[]
};

/**
 * @function GET_EMPLOYEES_FULFILLED_REDUCER
 * @description Handles the action where employees are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee data.
 * @returns {Object} - The updated state with the list of employees, each with an isSelected property set to false.
 */
function GET_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.results ? action.payload.results.map(item => ({ ...item, isSelected: false }))  : action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    list: { ...action.payload, results: empList },
  });
}


/**
 * @function GET_CHANGED_BY_FULFILLED_REDUCER
 * @description Handles the action where the employees changed by a specific action are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee data.
 * @returns {Object} - The updated state with the list of changed employees, each with an isSelected property set to false.
 */
function GET_CHANGED_BY_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.results ? action.payload.results.map(item => ({ ...item, isSelected: false }))  : action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    changedByList: { ...action.payload, results: empList },
  });
}


/**
 * @function GET_EMPLOYEE_TAGS_FULFILLED_REDUCER
 * @description Handles the action where employee tags are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with tag data.
 * @returns {Object} - The updated state with the list of tags.
 */
function GET_EMPLOYEE_TAGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    tagList: action.payload
  });
}

/**
 * @function GET_ASSIGNED_EMPLOYEES_FULFILLED_REDUCER
 * @description Handles the action where assigned employees are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with assigned employee data.
 * @returns {Object} - The updated state with the list of assigned employees, each with an isSelected property set to false.
 */
function GET_ASSIGNED_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    assignedlist: { ...action.payload, results: empList },
  });
}

/**
 * @function GET_ALL_EMPLOYEES_FULFILLED_REDUCER
 * @description Handles the action where all employees are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with all employee data.
 * @returns {Object} - The updated state with the list of all employees, each with an isSelected property set to false.
 */
function GET_ALL_EMPLOYEES_FULFILLED_REDUCER(state, action) {
  const empList = action.payload.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    all: empList,
  });
}

/**
 * @function GET_STATS_FULFILLED_REDUCER
 * @description Handles the action where statistics data is fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with statistics data.
 * @returns {Object} - The updated state with the statistics data.
 */
function GET_STATS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    stats: action.payload,
  });
}

/**
 * @function SELECT_EMPLOYEES_REDUCER
 * @description Toggles the selection state of an employee in the list.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee id.
 * @returns {Object} - The updated state with the selection state of the specified employee toggled.
 */
function SELECT_EMPLOYEES_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].isSelected = !list.results[i].isSelected;
    }
  }
  return Object.assign({}, state, { list });
}

/**
 * @function SELECT_EMPLOYEES_ALL_REDUCER
 * @description Toggles the selection state of an employee in the list of all employees.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee id.
 * @returns {Object} - The updated state with the selection state of the specified employee toggled in the all employees list.
 */
function SELECT_EMPLOYEES_ALL_REDUCER(state, action) {
  const arrayList = [ ...state.all ];
  for (let i = 0; i < arrayList.length; i += 1) {
    if (arrayList[i].id === action.payload.id) {
      arrayList[i].isSelected = !arrayList[i].isSelected;
    }
  }
  return Object.assign({}, state, { all: arrayList });
}

/**
 * @function SELECT_EMPLOYEE_AUDIT_REDUCER
 * @description Toggles the selection state of an employee in the audit list.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee id.
 * @returns {Object} - The updated state with the selection state of the specified employee toggled in the audit list.
 */
function SELECT_EMPLOYEE_AUDIT_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].isSelected = !list.results[i].isSelected;
    }
  }
  return Object.assign({}, state, { list });
}


/**
 * @function POST_ACTIVE_STATUS_FULFILLED_REDUCER
 * @description Resets the state to the default state after posting active status is fulfilled.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload (not used in this reducer).
 * @returns {Object} - The state reset to the default state.
 */
function POST_ACTIVE_STATUS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

/**
 * @function GET_EMPLOYEE_FULFILLED_REDUCER
 * @description Handles the action where a single employee's details are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee details.
 * @returns {Object} - The updated state with the employee details.
 */
function GET_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

/**
 * @function GET_EMPLOYEE_PENDING_REDUCER
 * @description Handles the action where fetching an employee's details is pending.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object (not used in this reducer).
 * @returns {Object} - The updated state with an empty employee detail.
 */
function GET_EMPLOYEE_PENDING_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: {},
  });
}

/**
 * @function GET_BULK_EMPLOYEE_FULFILLED_REDUCER
 * @description Handles the action where bulk employee details are fetched successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with bulk employee details.
 * @returns {Object} - The updated state with the bulk employee details.
 */
function GET_BULK_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


/**
 * @function POST_EMPLOYEE_FULFILLED_REDUCER
 * @description Handles the action where posting an employee is fulfilled successfully.
 * @param {Object} state - The current state of the reducer.
 * @param {Object} action - The action object containing the payload with employee details.
 * @returns {Object} - The updated state with the employee details.
 */
function POST_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

/**
 * Reducer for handling the fulfilled state of PUT_EMPLOYEE action.
 * Updates the employee details with the payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with new employee details.
 */
function PUT_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

/**
 * Reducer for handling the fulfilled state of UPDATE_EMPLOYEE_WAGE action.
 * Updates the employee wage details with the payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with new wage details.
 */
function UPDATE_EMPLOYEE_WAGE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

/**
 * Reducer for handling the fulfilled state of GET_EMPLOYEE_AUDIT_LOGS action.
 * Maps the audit logs with an additional `isSelected` field set to false.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with mapped audit logs list.
 */
function GET_EMPLOYEE_AUDIT_LOGS_FULFILLED_REDUCER(state, action) {
  const arr  = action.payload.map((item) => ({...item, isSelected: false }));
  return Object.assign({}, state, {
    list: { results: arr },
  });
}

/**
 * Reducer for handling the fulfilled state of GET_EMPLOYEES_AUDIT_LOGS action.
 * Updates the list with the provided payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with the new list of audit logs.
 */
function GET_EMPLOYEES_AUDIT_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

/**
 * Reducer for handling the fulfilled state of GET_EMPLOYEE_ACTIVITY_LOGS action.
 * Updates the activity logs list with the payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with the new activity logs list.
 */
function GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    activityLogsList: action.payload,
  });
}

/**
 * Reducer for handling the fulfilled state of POST_KIOSK_AUDITS action.
 * Updates the kiosk audit details with the payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with new kiosk audit details.
 */
function POST_KIOSK_AUDITS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

/**
 * Reducer for resetting the employee state to its default value.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object.
 * @returns {Object} The reset state to default values.
 */
function RESET_EMPLOYEE_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

/**
 * Reducer for handling the fulfilled state of POST_BULK_EMPLOYEE action.
 * Updates the employee details in bulk with the payload.
 *
 * @param {Object} state - The current state.
 * @param {Object} action - The action object containing the payload.
 * @returns {Object} The updated state with new bulk employee details.
 */
function POST_BULK_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}



const handlers = {
  [GET_EMPLOYEES_FULFILLED]: GET_EMPLOYEES_FULFILLED_REDUCER,
  [GET_CHANGED_BY_FULFILLED]: GET_CHANGED_BY_FULFILLED_REDUCER,
  [GET_ASSIGNED_EMPLOYEES_FULFILLED]: GET_ASSIGNED_EMPLOYEES_FULFILLED_REDUCER,
  [GET_ALL_EMPLOYEES_FULFILLED]: GET_ALL_EMPLOYEES_FULFILLED_REDUCER,
  [GET_EMPLOYEE_PENDING]: GET_EMPLOYEE_PENDING_REDUCER,
  [GET_STATS_FULFILLED]: GET_STATS_FULFILLED_REDUCER,
  [SELECT_EMPLOYEES]: SELECT_EMPLOYEES_REDUCER,
  [SELECT_EMPLOYEES_ALL]: SELECT_EMPLOYEES_ALL_REDUCER,
  [POST_ACTIVE_STATUS_FULFILLED]: POST_ACTIVE_STATUS_FULFILLED_REDUCER,
  [GET_EMPLOYEE_FULFILLED]: GET_EMPLOYEE_FULFILLED_REDUCER,
  [POST_EMPLOYEE_FULFILLED]: POST_EMPLOYEE_FULFILLED_REDUCER,
  [PUT_EMPLOYEE_FULFILLED]: PUT_EMPLOYEE_FULFILLED_REDUCER,
  [GET_EMPLOYEE_AUDIT_LOGS_FULFILLED]: GET_EMPLOYEE_AUDIT_LOGS_FULFILLED_REDUCER,
  [GET_EMPLOYEES_AUDIT_LOGS_FULFILLED]: GET_EMPLOYEES_AUDIT_LOGS_FULFILLED_REDUCER,
  [GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED]:GET_EMPLOYEE_ACTIVITY_LOGS_FULFILLED_REDUCER,
  [POST_KIOSK_AUDITS_FULFILLED]: POST_KIOSK_AUDITS_FULFILLED_REDUCER,
  [SELECT_EMPLOYEE_AUDIT]: SELECT_EMPLOYEE_AUDIT_REDUCER,
  [RESET_EMPLOYEE]: RESET_EMPLOYEE_REDUCER,
  [POST_BULK_EMPLOYEE_FULFILLED]: POST_BULK_EMPLOYEE_FULFILLED_REDUCER,
  [GET_BULK_EMPLOYEE_FULFILLED]: GET_BULK_EMPLOYEE_FULFILLED_REDUCER,
  [GET_EMPLOYEE_TAGS_FULFILLED]: GET_EMPLOYEE_TAGS_FULFILLED_REDUCER,
  [UPDATE_EMPLOYEE_WAGE_FULFILLED]: UPDATE_EMPLOYEE_WAGE_FULFILLED_REDUCER,
  
};


const employeeSelector = state => state.employees.employee;

export const employeeList = createSelector(
  employeeSelector,
  instance => (instance.list),
);

export const employeeassignedList = createSelector(
  employeeSelector,
  instance => (
    instance.assignedlist
  ),
);

export const employeeSelectedList = createSelector(
  employeeList,
  instance => instance.results.filter(item => item.isSelected).map(item => item.id),
);

export const employeeSelectionList = createSelector(
  employeeList,
  instance => instance.results.filter(item => item.isSelected),
);

export const tagsList = createSelector(
  employeeSelector,
  instance => instance.tagList,
);

export const employeeAll = createSelector(
  employeeSelector,
  instance => instance.all,
);

export const employeeSelectedAll = createSelector(
  employeeAll,
  instance => instance.filter(item => item.isSelected).map(item => item.id),
);

export const employeeDetail = createSelector(
  employeeSelector,
  instance => instance.detail,
);

export const stats = createSelector(
  employeeSelector,
  instance => instance.stats,
);

export const activityLogsList = createSelector(
  employeeSelector,
  instance => (instance.activityLogsList),
);

export const changedByList = createSelector(
  employeeSelector,
  instance => (instance.changedByList),
);

export default createReducer(defaultState, handlers);
