import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as KnowledgeBaseDucks from 'ducks/knowledgeBases/knowledgeBase';
import * as UserDucks from 'ducks/accounts/user';

import KnowledgeBaseFormComponent from 'components/knowledgeBases/form/Form';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ModalComponent from 'components/knowledgeBases/ViewModal';
import { MSGS } from 'constants/knowledgeBases';

/**
 * BasicSettingFormPage -> KnowledgeBaseFormSection
 *
 * Components:
 *    - {@link BasicSettingFormComponent}
 *
 * State:
 *    - setting detail
 *
 * Actions:
 *    - Form:
 *        - update basic settings
 */
class KnowledgeBaseFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.updateOrAdd = this.updateOrAdd.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getContexts = this.getContexts.bind(this);
    this.state = { isModalOpen: false, videoOrImage: null };
  }


  getContexts(params) {
    return this.props.UserDucks.getContexts(params);
  }

  toggle(item){
    this.setState(({ isModalOpen, videoOrImage }) => ({ isModalOpen: !isModalOpen,  videoOrImage: item }));
  }

  updateOrAdd(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { router: { history } } = this.context;
    if (params.id) {
      return this.props.KnowledgeBaseDucks.putKnowledgeBase(data)
        .then(() => {
          toast.success(MSGS.KNOWLEDGE_BASE_UPDATE_SUCCESS);
          history.push('/knowledge-base/list');
        });
    }
    return this.props.KnowledgeBaseDucks.postKnowledgeBase(data)
      .then(() => {
        toast.success(MSGS.KNOWLEDGE_BASE_ADD_SUCCESS);
        history.push('/knowledge-base/list');
      });
  }

  render() {
    const { isLoading, knowledgeBaseDetail } = this.props;
    const { videoOrImage, isModalOpen } = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    let contextObj = {};
    if(params.id && knowledgeBaseDetail){
      contextObj = { label: knowledgeBaseDetail.context && knowledgeBaseDetail.context.display,
        value: knowledgeBaseDetail.context && knowledgeBaseDetail.context.id };
    }
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <KnowledgeBaseFormComponent
          initialValues={params.id ? { ...knowledgeBaseDetail, context: contextObj } : {is_active: true}}
          updateOrAdd={this.updateOrAdd}
          toggle={this.toggle}
          getContexts={this.getContexts}
        />
        <ModalComponent
          isModalOpen={isModalOpen}
          toggle={this.toggle}
          videoOrImage={videoOrImage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  knowledgeBaseDetail: KnowledgeBaseDucks.knowledgeBaseDetail(state),
});

const mapActionToProps = dispatch => ({
  KnowledgeBaseDucks: bindActionCreators(KnowledgeBaseDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

KnowledgeBaseFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

KnowledgeBaseFormSection.propTypes = ({
  KnowledgeBaseDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(KnowledgeBaseFormSection);
