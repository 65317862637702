import React from "react";
import StorageGateway from "lib/storage-gateway";

import moment from "moment";
import PropTypes from "prop-types";
import Button from "reactstrap/lib/Button";

import styles from "./styles.module.scss";
import SideMenuNotificationComponent from "components/common/Notification";

class NavFooterComponent extends React.Component {
  constructor(props) {
    super(props);
    // moment.tz.setDefault(this.props.userTimezone)
    this.state = {
      currentDateTime: moment
        .tz(this.props.userTimezone)
        .format("MMM Do YYYY, h:mm:ss a"),
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        currentDateTime: moment
          .tz(this.props.userTimezone)
          .format("MMM Do YYYY, h:mm:ss a"),
      });
    }, 1000);
  }
  componentDidUpdate(nextProps) {
    if (nextProps.userTimezone !== this.props.userTimezone) {
        this.setState({
        currentDateTime: moment
          .tz(this.props.userTimezone)
          .format("MMM Do YYYY, h:mm:ss a"),
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      isLoggedInAdmin,
      isSubCompany,
      userTimezone,
      backToMyDashboard,
      sidemenuCollapsed,
      tether,
      isBusinessAdmin
    } = this.props;
    const companyId = StorageGateway.get("company_id");

    return (
      <section
      className={
        !sidemenuCollapsed
          ? styles["sidenav-timerwrapper"]
          : styles["sidenav-dropdown-collapse"]
      }>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
         {!sidemenuCollapsed && <div>
            <div className={styles["sidenav-timer"]}>
              {this.state.currentDateTime}
            </div>
            <div className={styles["sidenav-timer"]}>{userTimezone}</div>
          </div>}
          { !isBusinessAdmin && <SideMenuNotificationComponent
            tether={tether}
            sidemenuCollapsed={sidemenuCollapsed}
          /> }
        </div>
        {companyId && !sidemenuCollapsed &&(
          <div>
            <Button
              className="btn-backdashboard"
              color="primary"
              onClick={backToMyDashboard}
            >
              <u>
                Back To {isLoggedInAdmin && isSubCompany ? "Vendor" : "My"}{" "}
                Dashboard
              </u>
            </Button>
          </div>
        )}
      </section>
    );
  }
}

NavFooterComponent.propTypes = {
  isLoggedInAdmin: PropTypes.bool,
  isSubCompany: PropTypes.number,
  userTimezone: PropTypes.string.isRequired,
  backToMyDashboard: PropTypes.func,
};

NavFooterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default NavFooterComponent;
