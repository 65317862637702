  import React, {PureComponent} from 'react';
import Link from 'react-router-dom/Link';
import { Formik } from 'formik';
import { Label, Form, FormText, FormGroup, Input, Button , Card, CardTitle } from 'reactstrap';
import { server } from 'helpers/config';
import ButtonLoaderAtom from 'atoms/ButtonLoader'; 
import SectionLoaderAtom from 'atoms/SectionLoader';

import styles from './styles.module.scss';


class TermsConditionForm extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { isLoading , executeCta, isAcceptButtonLoader, isDeclineButtonLoader } = this.props;
    return(
      <div>
        <div style={{height : '90vh', overflowX : 'hidden', overflowY : 'hidden'}}>
          {isLoading  ?
            <SectionLoaderAtom active />
            :
            null
          }
          <iframe onLoad={()=> this.props.hideLoader()} id='terms_condition' width="100%" height="100%" src="https://www.worksana.com/terms-and-conditions" style={isLoading ? {border:'none', display : 'none'} : {border : 'none'}}/>
          </div>
          {
            !isLoading ?
              <div className="pt-4 text-center">
                <Button className="ml-3 mr-3 pr-4 pl-4" onClick={()=>executeCta('tc_agree')}> Accept <ButtonLoaderAtom active={isAcceptButtonLoader} /></Button>
                <Button className={isDeclineButtonLoader ? 'ml-3 mr-3 pr-4 pl-4' : `${styles['decline-btn']} ml-3 mr-3 pr-4 pl-4`} onClick={()=>executeCta('tc_disagree')}> Decline <ButtonLoaderAtom active={isDeclineButtonLoader} /> </Button>
              </div>
            :
            null
          }
      </div>
    );
  }
}

export default TermsConditionForm;
