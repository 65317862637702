import React, {PureComponent} from 'react';
import { Button, Modal, ModalBody , ModalHeader , ModalFooter} from 'reactstrap';
import styles from './styles.module.scss';

import ButtonLoaderAtom from "atoms/ButtonLoader";

class DisclaimerModal extends PureComponent {

  render() {
    const {isOpen, toggle , tableView , refresh , refreshLoader} = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={()=>toggle()}>Disclaimer</ModalHeader>
        <ModalBody>
          <div className={styles.disclaimerBody_font}>
            The analytics will be reloaded and it may take some time. Do you wish to continue?
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={()=>refresh(tableView)}>
            Yes <ButtonLoaderAtom active={refreshLoader} />
          </Button>
          <Button color="danger" onClick={()=>toggle()}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    );}
  }
  
export default DisclaimerModal;