import React from 'react';

import PropTypes from 'prop-types';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchAlways from 'pages/MatchAlways';
import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import DashboardPage from './Dashboard';
import DashboardLandingPage from './DashboardLanding';
import HomePage from './Home';
import AdminDashboardPage from './AdminDashboard';

const GenericsRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={`${match.url}dashboard`}
        name="dashboard"
        component={DashboardPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}dashboard-landing`}
        name="dashboard_landing"
        component={DashboardLandingPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}dashboard-admin`}
        name="dashboard_admin"
        component={AdminDashboardPage}
      />
      <MatchAlways
        exact
        path={match.url}
        component={HomePage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

GenericsRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default GenericsRoutes;
