import React from 'react';

import PropTypes from 'prop-types';

import MultiSelectAtom from 'atoms/MultiSelect';

class CompanyFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.toggleVendor = this.toggleVendor.bind(this);
  }

  toggleVendor(item) {
    const { toggleVendor } = this.props;
    return toggleVendor(item);
  }

  render() {
    const { subCompanyAll } = this.props;

    return (
      <MultiSelectAtom
        menuItems={subCompanyAll}
        onItemSelect={this.toggleVendor}
        className="allbranch-btn-width"
      />
    );
  }
};

CompanyFilterComponent.propTypes = {
  subCompanyAll: PropTypes.array.isRequired,
  toggleVendor: PropTypes.func.isRequired,
};

export default CompanyFilterComponent;
