import React, {PureComponent} from 'react';
import { Formik } from 'formik';
import { Form,FormGroup, FormText, Input,Button, Card , CardTitle} from 'reactstrap';
import logo from 'sections/common/Sidenav/logo.png';
import ButtonLoaderAtom from 'atoms/ButtonLoader';


class VerifyOTPForm extends PureComponent {

  constructor(props) {
    super(props);
    this.state={
      close:true,
      phoneActivating: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const {uid, password} = this.props;
    let code;
    code = values.code;
    values.uid = uid;
    values.password = password;
    setErrors({});
    this.props.usecase.setup_data(values);
    try {
      await this.props.usecase.execute();
    } catch (e) {
        setErrors(e.errors);
        this.updateState('phoneActivating', false);
    }
    setSubmitting(false);
  }
 
  render(){

    const { phoneActivating } = this.state;
    const { data, updateState, resendingCode } = this.props;
    return (
      <div>
        <div className="text-center pb-4">
        <img src={logo} height="100px" width="106px" alt="Logo" />
      </div>
      <Card className="ui-card">
        <CardTitle className="text-left">VERIFICATION</CardTitle>
        <hr className="line"/>
       
        <Formik
          initialValues={data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            setErrors,
            }) => {
            return(
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <div className="text-center">
                    <FormText style={{fontSize: '15px'}} color="danger">{errors && errors.non_field_errors}</FormText>
                    <div style={{minHeight: '2vh'}}>
                      <FormText color="danger">{errors && errors.code}</FormText>
                    </div>
                    <div className="text-center">
                      <p className="mb-1">A message with a verification code has been sent to your device.</p>
                      <p>Enter the code to continue.</p>
                    </div>
                  </div>
                  
                  <div>
                    <div className="text-center">
                       <div className="col d-flex justify-content-center">
                  <Input
                    onChange={ handleChange }
                    onBlur={ handleBlur }
                    value={values.code}
                    type="text" 
                    name="code"
                    className="w-50 text-center"
                  />
                  </div>
                    </div>
                  </div>

                </FormGroup>
                  <div className="text-center">
                    <Button
                      className="mt-2"
                      type="submit"
                      size="sm"
                      onClick={() => {
                        this.updateState('phoneActivating', true);
                        submitForm();
                      }}
                      isColor='primary'   
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                    >
                      SUBMIT <ButtonLoaderAtom active={phoneActivating} />
                    </Button>
                  </div>
                {/*<div className="text-center mt-2">
                 {'Did not receive OTP? Click to resend.'}</div>
                 <div className='text-center'>
                  <Button
                    className="mt-3 resend"
                    disabled={isSubmitting || resendingCode}
                    isLoading={isSubmitting}
                    type="button"
                    onClick={() => {
                      updateState('resendingCode', true);
                      this.props.usecase.resendActivationCodes();
                    }}
                  >
                    RESEND OTP <ButtonLoaderAtom active={resendingCode} />
                  </Button>
                </div>*/}
              </Form>
            )}}
          />
       </Card>
       </div>
    );
  }
}

export default VerifyOTPForm;
