import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';

import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';

import { FORM_LAYOUT } from 'constants/layout';
import styles from "./styles.module.scss"
import closeimage from 'employees/components/Close-Cross.png';


import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';

/**
 * EmployeeListPage -> EmployeeFilterSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class ResetPasswordFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.submit = this.submit.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password', showStrengthCheck : false };
  }

  submit(data) {
    const { resetPassword } = this.props;
    const detail = data;

    if(!detail.confirm_password) detail.confirm_password = data.password;
    return resetPassword(data)
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }


  render() {
    const { handleSubmit, error, isOpen, toggle } = this.props;
    const { inputType } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
        backdrop="static"
      >
        <ModalHeader>Reset Password
        <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody className="ml-4 mr-4">
            <div className="error-text">{error}</div>
            <div className="pos-relative">
              <Field
                id="password"
                type={inputType}
                name="password"
                component={InputAtom}
                label="Password"
                layout={FORM_LAYOUT}
                placeholder="Password"
                data-lpignore={true}
                onBlur={(e) => { this.strengthCheckToggle()}}
                onFocus = {this.strengthCheckToggle}
              />
              {inputType === 'password'
                ? (<EyeOpenIconAtom
                  className="password-icon"
                  height="22" width="22"
                  onClick={this.toggleInputType}
                />)
                : (<EyeCloseIconAtom
                  className="password-icon"
                  height="22" width="22"
                  onClick={this.toggleInputType}
                />)
              }
              {this.state.showStrengthCheck &&
                <PasswordStrengthMeter
                  values={{password : this.props.password}}
                />
              }
            </div>
            {inputType === 'password' &&
              (<Field
                id="confirm_password"
                type="password"
                name="confirm_password"
                placeholder="Confirm Password"
                component={InputAtom}
                label="Confirm Password"
                layout={FORM_LAYOUT}
                data-lpignore={true}
              />)
            }
            <Field
              id="registerEmployee"
              type="checkbox"
              name="registerEmployee"
              label="Register on behalf of employee"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
            />
            <small className={`${styles['label-part']}`}>Applicable ONLY for Kiosk/Admin panel employees.</small>
          </ModalBody>
          <ModalFooter className="mr-4">
            <Button className={`${styles['form-button']}`} type="submit" color="secondary" size={FORM_LAYOUT.size} >
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
  const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
  const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
  if (!values.password) errors.password = 'Enter Password';
  if (values.password && values.password.length < 8) errors.password = 'Password should contain atleast 8 characters';
  if (values.password && values.password.length >= 8 && values.password && (values.password.match(passwordRegex) || values.password.match(passwordSequential) || values.password.match(passwordRepeated ))) errors.password = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
  if (!values.confirm_password) errors.confirm_password = 'Enter Confirm Password';
  if ((values.password !== values.confirm_password) &&
    (values.password && values.confirm_password)) {
    errors.confirm_password = 'Password mismatch';
  }
  return errors;
};

ResetPasswordFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
};

const ResetPasswordFormModal= reduxForm({
  form: 'ResetPasswordFormModalComponent',
  validate,
})(ResetPasswordFormModalComponent);

const selector = formValueSelector('ResetPasswordFormModalComponent');

export default connect(
  (state) => {
    const password = selector(state, 'password');
    return { password };
  },
)(ResetPasswordFormModal);
