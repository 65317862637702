import React from "react";
import Table from "reactstrap/lib/Table";
import SectionLoaderAtom from "atoms/SectionLoader";
import styles from "./styles.module.scss";
import { UncontrolledTooltip } from "reactstrap";
import UsernameCircle from "atoms/UsernameCircle/UsernameCircle";
import LayerAtom from "atoms/DocsPreview/index";
import FilterDropdownComponent from "components/common/FilterDropComponent";
import greenNotes from "components/timecards/dailyTimecardList/greenNotes.png";
import greyNotes from "components/timecards/dailyTimecardList/greyNotes.png";
import AddNotesModal from "atoms/AddNotesModal";
import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import { toast } from "react-toastify";
import { status_options } from "constants/forms/records";
class FormResponseTableComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOverlayOpen: false,
      selectedId: null,
      notesModal: false,
      response_id: null,
      initialMessage: "",
    };
    this.formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(this.formsAPIGateway);
    this.onStatusChange = this.onStatusChange.bind(this);
  }

 
  addTdFixed = (arr) => {
    return arr.map((e, index) => (
      <th key={index} id={`Fixedheader${index}`}>
        {e}
        <UncontrolledTooltip placement="bottom" target={`Fixedheader${index}`}>
          {e}
        </UncontrolledTooltip>
      </th>
    ));
  };

  onStatusChange(val, id) {
    let obj = {
      id: id,
      status: val,
    };
    this.usecase.
    updateResponceData(obj) .then(() => {
      this.props.fetchData();
    })
    .catch(error => {
     toast.error(error);
    });

  }

  addTd = (arr) => {
    return arr.map((e, index) => (
      <th key={index} id={`Regularheader${index}`}>
        {e.replace("<p>", "").replace("</p>", "")}
        <UncontrolledTooltip
          placement="bottom"
          target={`Regularheader${index}`}
        >
          {e.replace("<p>", "").replace("</p>", "")}
        </UncontrolledTooltip>
      </th>
    ));
  };



  addModalToggler = () => {
    const { notesModal } = this.state;
    this.setState({ notesModal: !notesModal });
  };

  renderFrozenTd = (arr, idx) => {
        const idIndex = arr[arr.length - 2];
    const currentNotesValue = arr[arr.length - 1];
    const sliceArr = arr.slice(0, 3);
    return sliceArr.map((e, index) => (
      <td key={index} id={`fixedTableColumn${idx}${index}`}>
        {index === 0 ? (
          <>
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <div>
                <img
                className={`${styles["notes-image"]}`}
                  height="18"
                  width="18"
                  onClick={() => {
                    this.setState({
                      notesModal: true,
                      response_id: idIndex,
                      initialMessage: currentNotesValue,
                    });
                  }}
                  title={currentNotesValue}
                  src={
                    currentNotesValue ===''
                      ? greyNotes
                      : greenNotes
                  }
                ></img>
              </div>

              <UsernameCircle name={e} />
              <span className={`${styles["fixed-table-name"]} ml-2`}>{e}</span>
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={`fixedTableColumn${idx}${index}`}
            >
              {e}
            </UncontrolledTooltip>
          </>
        ) : index === 1 ? (
          <div className={`${styles["dropdown-menu"]}`}>
            <FilterDropdownComponent
              paramKey={idIndex}
              keyLabel="value"
              valueLabel="label"
              options={status_options}
              value={e === '' ? 'NEW' : e}
              onChange={(val) =>
                this.onStatusChange(val, idIndex)
              }
            />
          </div>
        ) : (
          e
        )}
      </td>
    ));
  };

  renderFrozen = (arr) => {
    return arr.map((e, index) => {
      return <tr key={index}>{this.renderFrozenTd(e, index)} </tr>;
    });
  };

  renderReularTd = (arr, idx) => {
    const sliceArr = arr.slice(3, arr.length - 2);
    return sliceArr.map((e, index) => (
      <td key={index} id={`RegularCell${idx}${index}`}>
        {e?.indexOf("file") === -1
          ? e
          : this.showFileData(e, `${idx}_${index}`)}{" "}
        {e?.indexOf("file") === -1 && (
          <UncontrolledTooltip
            placement="bottom"
            target={`RegularCell${idx}${index}`}
          >
            {e}
          </UncontrolledTooltip>
        )}
      </td>
    ));
  };

  showFileData = (e, id) => {
    const link = e.split("form_file")[1];
    const { isOverlayOpen, selectedId } = this.state;
    return (
      <div>
        <div
          onClick={() => this.handleOpenImageBox(true, id)}
          className={`${styles["docLink"]}`}
        >
          Doc
        </div>
        {isOverlayOpen && selectedId === id && (
          <LayerAtom
            active={isOverlayOpen}
            path={link}
            setToggle={() => this.handleOpenImageBox(false)}
          />
        )}
        {/* {isOverlayOpen && selectedId===id && (
         <DocsPreviewAtom link={link} isOpen={isOverlayOpen} toggle={this.handleOpenImageBox}/>
        )} */}
      </div>
    );
  };

  handleOpenImageBox = (flag, id = undefined) => {
    console.log(flag, id);
    this.setState({ isOverlayOpen: flag, selectedId: id });
  };

  renderReular = (arr) => {
    return arr.map((e, index) => {
      return <tr key={index}>{this.renderReularTd(e, index)}</tr>;
    });
  };

  render() {
    const { notesModal, response_id, initialMessage } = this.state;
    const { isLoading, data, headers, fetchData } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className={`${styles["container"]} ml-3 mr-3 pb-3 `}>
        <div className={`${styles["fixed"]}`}>
          <Table hover className={`${styles["table-style"]}`}>
            <thead className={styles["forms-Header-col"]}>
              <tr>{this.addTdFixed(headers.slice(0, 3))}</tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td className="text-center p-2" colSpan="2">
                    No Records Found
                  </td>
                </tr>
              ) : (
                this.renderFrozen(data)
              )}
            </tbody>
          </Table>
        </div>
        <div
          className="pt-1 pb-3"
          style={{
            display: "flex",
            justifyContent: "center",
            width: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              writingMode: "vertical-rl",
              transform: "rotate(180deg)",
            }}
          >
            {data.length > 4 && (
              <hr className={`${styles["vertical-line"]} mt-1 mb-1`} />
            )}
            <div
              className="p-2"
              style={{
                color: "#707070",
                writingMode: "vertical-rl",
                alignSelf: "center",
                height: `${data.length > 4 ? 50 : 100}%`,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {data.length !== 0 && "Form Answers"}
            </div>

            {data.length > 4 && (
              <hr className={`${styles["vertical-line"]} mt-1 mb-1`} />
            )}
          </div>
        </div>

        <div className={`${styles["scroll"]}`}>
          <Table
            hover
            className={`${styles["table-style"]} ${styles["scrollable"]} table-form-responce`}
          >
            <thead
              className={styles["forms-Header-col"]}
              style={{ minWidth: "150px !important" }}
            >
              <tr>{this.addTd(headers.slice(3, headers?.length))}</tr>
            </thead>
            <tbody>
              {data.length === 0 ? (
                <tr>
                  <td className="text-center p-2" colSpan={headers.length - 2}>
                    No Records Found
                  </td>
                </tr>
              ) : (
                this.renderReular(data)
              )}
            </tbody>

            {notesModal && (
              <AddNotesModal
                notesModal={notesModal}
                addModalToggler={this.addModalToggler}
                handleFormSubmit={this.handleFormSubmit}
                response_id={response_id}
                initialMessage={initialMessage}
                fetchData={fetchData}
              />
            )}
          </Table>
        </div>
      </div>
    );
  }
}

export default FormResponseTableComponent;
