import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import { toTitleCase } from 'helpers/utils';
import URLS from './urls';

const GET_DEPARTMENTS = 'vendors/department/GET_DEPARTMENTS';
const GET_DEPARTMENTS_FULFILLED = 'vendors/department/GET_DEPARTMENTS_FULFILLED'
const GET_ALL_DEPARTMENTS = 'vendors/department/GET_ALL_DEPARTMENTS';
const GET_ALL_DEPARTMENTS_FULFILLED = 'vendors/department/GET_ALL_DEPARTMENTS_FULFILLED';
const TOGGLE_DEPARTMENT_TEAMS = 'vendors/department/TOGGLE_DEPARTMENT_TEAMS;'
const GET_DEPARTMENT = 'vendors/department/GET_DEPARTMENT';
const GET_DEPARTMENT_FULFILLED = 'vendors/department/GET_DEPARTMENT_FULFILLED'
const POST_DEPARTMENT = 'vendors/department/POST_DEPARTMENT';
const POST_DEPARTMENT_FULFILLED = 'vendors/department/POST_DEPARTMENT_FULFILLED';
const PUT_DEPARTMENT = 'vendors/department/PUT_DEPARTMENT';
const PUT_DEPARTMENT_FULFILLED = 'vendors/department/PUT_DEPARTMENT_FULFILLED';
const DELETE_DEPARTMENT = 'vendors/department/DELETE_DEPARTMENT';
const GET_DEPARTMENT_IDS = 'vendors/department/GET_DEPARTMENT_IDS';
const GET_DEPARTMENT_IDS_FULFILLED = 'vendors/department/GET_DEPARTMENT_IDS_FULFILLED';

export function getDepartments(params) {
  return {
    type: GET_DEPARTMENTS,
    payload: axios.get(URLS.DEPARTMENT, { params }),
  };
}

export function getAllDepartments(params) {
  return {
    type: GET_ALL_DEPARTMENTS,
    payload: axios.get(URLS.DEPARTMENT, { params }),
  };
}

export function toggleDepartmentTeams(data) {
  return {
    type: TOGGLE_DEPARTMENT_TEAMS,
    payload: data,
  }
}

export function getDepartment(params) {
  return {
    type: GET_DEPARTMENT,
    payload: axios.get(`${URLS.DEPARTMENT}${params.id}/`),
  };
}

export function putDepartment(data) {
  return {
    type: PUT_DEPARTMENT,
    payload: axios.put(`${URLS.DEPARTMENT}${data.id}/`, data),
  };
}

export function postDepartment(data) {
  return {
    type: POST_DEPARTMENT,
    payload: axios.post(URLS.DEPARTMENT, data),
  };
}

export function deleteDepartment(data){
  return {
    type: DELETE_DEPARTMENT,
    payload: axios.delete(`${URLS.DELETE_DEPARTMENT}${data.id}/`)
  };
}

export function getDepartmentIds() {
  return {
    type: GET_DEPARTMENT_IDS,
    payload: axios.get(`${URLS.REORDERED_IDS}?entity=department`),
  };
}

const defaultState = {
  list: {},
  detail: {},
  all: [],
  departmentIdsList: []
};

function GET_DEPARTMENTS_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.results.map(item => ({ ...item,
    name: item.name, showTeamList: false }));
  return Object.assign({}, state, {
    list: { ...action.payload, results: arr },
  });
}

function GET_ALL_DEPARTMENTS_FULFILLED_REDUCER(state, action) {
  const arr = action.payload;
  return Object.assign({}, state, {
    all: arr,
  });
}

function TOGGLE_DEPARTMENT_TEAMS_REDUCER(state, action) {
  const list = { ...state.list };
  for (let i = 0; i < list.results.length; i += 1) {
    if (list.results[i].id === action.payload.id) {
      list.results[i].showTeamList = !list.results[i].showTeamList;
    }
    else {
      list.results[i].showTeamList = false;
    }
  }
  return Object.assign({}, state, { list });
}

function GET_DEPARTMENT_FULFILLED_REDUCER(state, action) {
  const item  = action.payload;
  return Object.assign({}, state, {
    detail:  item,
  });
}

function POST_DEPARTMENT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_DEPARTMENT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_DEPARTMENT_IDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    departmentIdsList: action.payload,
  });
}

const handlers = {
  [GET_DEPARTMENTS_FULFILLED]: GET_DEPARTMENTS_FULFILLED_REDUCER,
  [GET_ALL_DEPARTMENTS_FULFILLED]: GET_ALL_DEPARTMENTS_FULFILLED_REDUCER,
  [TOGGLE_DEPARTMENT_TEAMS]: TOGGLE_DEPARTMENT_TEAMS_REDUCER,
  [GET_DEPARTMENT_FULFILLED]: GET_DEPARTMENT_FULFILLED_REDUCER,
  [POST_DEPARTMENT_FULFILLED]: POST_DEPARTMENT_FULFILLED_REDUCER,
  [PUT_DEPARTMENT_FULFILLED]: PUT_DEPARTMENT_FULFILLED_REDUCER,
  [GET_DEPARTMENT_IDS_FULFILLED]: GET_DEPARTMENT_IDS_FULFILLED_REDUCER,
};


const departmentSelector = state => state.vendors.department;

export const departmentList = createSelector(
  departmentSelector,
  instance => instance.list
  
);
export const departmentAll = createSelector(
  departmentSelector,
  instance => instance.all
);
export const departmentDetail = createSelector(
  departmentSelector,
  instance => instance.detail
);

export const departmentIdsList = createSelector(
  departmentSelector,
  instance => instance.departmentIdsList
);

export default createReducer(defaultState, handlers);
