import StorageGateway from 'lib/storage-gateway';
import AuthSingleton from 'lib/auth-singleton';
import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  CHANGE_PASS_SUCCESS: 'CHANGE_PASS_SUCCESS',
  CHANGE_PASS_FAILURE: 'CHANGE_PASS_FAILURE',
};

class ChangePasswordUseCase {
 
  userAPIGateway = null;
  
  constructor(userAPIGateway) {
    
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'changePasswordUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data) {
    this.current_password = data.current_password;
    this.password = data.password;
    this.re_password = data.re_password;
  }

  async execute() {
    let errors = {};
    const passwordRegex = ' ^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';

    if(!this.newPassword){
      errors.newPassword = 'Enter Password';
    }
    if(!this.confirmPassword && this.inputType==="password"){
      errors.confirmPassword = 'Enter Confirm Password';
    }
    if(this.newPassword && this.confirmPassword && (this.newPassword !== this.confirmPassword) && this.inputType==="password"){
      errors.confirmPassword = 'Password and Confirm Password does not match';
    }
    if (this.newPassword && this.newPassword.length < 8){
      errors.newPassword = 'Password should contain atleast 8 characters';
    }
    if (this.newPassword && this.newPassword.length >= 8 && this.newPassword && (this.newPassword.match(passwordRegex) || this.newPassword.match(passwordSequential) || this.newPassword.match(passwordRepeated ))) {
      errors.newPassword = 'Password should have at least 8 characters, 1 capital alphabet, 1 lower case alphabet and 1 numeric characters. Should not contain more than three (3) repeating or sequential characters';
    } 
    let newPassword = null;
    newPassword = await this.userAPIGateway.changePassword(this.current_password, this.password , this.re_password);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CHANGE_PASS_SUCCESS,
    });      
  }
}
export default ChangePasswordUseCase; 