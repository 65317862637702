import React from 'react';
import Table from 'reactstrap/lib/Table';

import Link from 'react-router-dom/Link';
import SectionLoaderAtom from 'atoms/SectionLoader';


class SurveyListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    
  }

  render() {
    const { list, isLoading , getSurveyDetails , isSurveyModal } = this.props;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
        <div className="ml-3 mr-4">
          <Table responsive hover className="table-emp">
            <thead className="Header-col">
              <tr>
                <th className="pl-3">Employee Name</th>
                <th>Survey Name</th>
                <th>Date & Time</th>
                <th className="pr-3">Survey Status</th>
              </tr>
            </thead>
            <tbody>
              {list && list.length ? list.map(item => (
                <tr key={item.id}>
                  <td title={`${item.first_name} ${item.last_name}`} className="pl-3">{`${item.first_name} ${item.last_name}`}</td>
                  <td title={item.survey_name} onClick={()=>getSurveyDetails(item.id)} className="survey-link">
                    {item.survey_name}
                  </td>
                  <td title={item.date_time}>{item.date_time}</td>
                  <td title={item.status} className="pr-3">{item.status}</td>
                </tr>
              ))
              :
              <tr><td className="text-center" colSpan="4">No Records Found</td></tr>
              }
            </tbody>
          </Table>
        </div>                
    );
  }
};


export default SurveyListComponent;
