import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';

import styles from './styles.module.scss';
import {ReactComponent as DeleteIcon} from './bin.svg';

/**
 * ManualSessionPage -> ManualSessionTimelineSection -> ManualSessionMealPeriodComponent
 *
 * Props:
 *    None
 */
const ManualSessionMealPeriodComponent = (props) => {
  const {
    dateFormat, endDate, item, startDate, timeFormat, timeFormat24Hrs, editEntry, removeEntry, browserParseDate, reverseParseDate
  } = props;

  return (
    <Card block className={`${styles.timelinePointer} mb-3 activityCard`}>
      <CardTitle className="h5 f16">
        Meal Break
        <DeleteIcon
          className={styles['del-btn']}
          width={20} height={20}
          onClick={() => removeEntry(item)}
        >
          Remove
        </DeleteIcon>
      </CardTitle>
      <span className={styles['timeline-badge__mealPeriod']}></span>
      <Flatpickr
        className="form-control mb-2"
        value={item.start_datetime}
        options={{
          dateFormat: `${dateFormat}, ${timeFormat}`,
          enableTime: true,
          minuteIncrement: 1,
          time_24hr: timeFormat24Hrs,
          enable: [
            // Enable the dates b/w payroll startDate & endDate
            // Also no future date selection
            (date) => moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
              moment(date).isBefore(moment(endDate).add(1, 'day'))

          ],
        }}
        onChange={date => editEntry(item, date, true)}
      />
      <Flatpickr
        className="form-control"
        value={item.end_datetime}
        options={{
          dateFormat: `${dateFormat}, ${timeFormat}`,
          enableTime: true,
          minuteIncrement: 1,
          time_24hr: timeFormat24Hrs,
          enable: [
            // Enable the dates b/w payroll startDate & endDate
            // Also no future date selection
            (date) => moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
              moment(date).isBefore(moment(endDate).add(1, 'day'))

          ],
        }}
        onChange={date => editEntry(item, date)}
      />
    </Card>
  );
};

ManualSessionMealPeriodComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editEntry: PropTypes.func.isRequired,
  removeEntry: PropTypes.func.isRequired,
};

export default ManualSessionMealPeriodComponent;
