import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as RouteDucks from 'ducks/routes';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
//  import ActivityTrackMapComponent from 'components/activities/activityTrackList/Map';
import MapComponent from 'components/common/googleMap/Map';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import InfoWindowComponent from 'components/common/googleMap/InfoWindow';
import CircleComponent from 'components/common/googleMap/Circle';

import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;
//  import MarkerClusterer from 'react-google-maps/lib/addons/MarkerClusterer';
/**

 * ActivityTrackListPage -> ActivityTrackMapSection
 *
 * Components:
 *    - {@link ActivityTrackTableComponent}
 *
 * State:
 *    - activity track list
 *
 * Actions:
 *    None
 */
class ActivityTrackMapSection extends React.Component {

  constructor(props) {
    super(props);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.state = {
      center: { lat: -25.363882, lng: 131.044922 },
      zoom: 9,
      showInfoWindow: false,
      activeShape: {},
      shapeEvent: {},
      data: {},
    };
    this.onShapeClick = this.onShapeClick.bind(this);
    this.onGeoFenceClick = this.onGeoFenceClick.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
  }

  onShapeClick(data, shape, event) {
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data , GeocodingOption)
      .then(() => {
        const { selectedMarkerAddress } = this.props;
        this.setState({
          activeShape: shape,
          showInfoWindow: true,
          shapeEvent: event,
          data: {...data, address: selectedMarkerAddress},
        });
      });
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  onGeoFenceClick(data, shape, event) {
    this.setState({
      activeShape: shape,
      showInfoWindow: true,
      shapeEvent: event,
      data: {...data},
    });
  }

  toggleInfoWindow(marker) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleInfoWindow(marker);
    return this.props.ActivityTrackDucks.getActivityLocationAddress(marker , GeocodingOption)
      .then(() => {
        const { selectedMarkerAddress } = this.props;
        this.props.ActivityTrackDucks.setActivityLocationAddress({
          ...marker,
          address: selectedMarkerAddress,
        });
        this.props.ActivityTrackDucks.toggleInfoWindow(marker);
      });
  }

  render() {
    const { currentLocationList, isLoading, location, google, loaded, geoFenceList } = this.props;
    const { activeShape, showInfoWindow, shapeEvent, data } = this.state;

    const detail = currentLocationList.results.map(item => ({...item, shape_type: item.location.type,
      lat_long: { coordinates: item.location.coordinates }}));
    const combinedDetail = detail;
    detail.map((item) => {
      geoFenceList.results.map((geofenceItem) => {
        combinedDetail.push(geofenceItem);
        return true;
      })
      return true;
    })
    
    return (
      <section style={{ height: '60vh' }} className="mt-3 ml-3 mr-4">
        {isLoading && !loaded
          ? <SectionLoaderAtom active />
          : <MapComponent
            google={google}
            getAddressForMarker = {this.getAddressForMarker}
            locations={combinedDetail}
          >
            {geoFenceList.results.map((item, index) => {
              if (item.shape_type === 'Point') {
                return <MarkerComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  position={{
                    lat: item.lat_long.coordinates && item.lat_long.coordinates[1],
                    lng: item.lat_long.coordinates && item.lat_long.coordinates[0],
                  }}
                  onClick={this.onGeoFenceClick}
                />;
              }
              else if (item.shape_type === 'Polygon') {
                return <PolygonComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  paths={
                    item.lat_long &&
                      item.lat_long.coordinates[0].map(latLng => ({
                        lat: latLng[1],
                        lng: latLng[0],
                      }))
                  }
                  fillColor={item.color}
                  onClick={this.onGeoFenceClick}
                />;
              }
              else if (item.shape_type === 'Rectangle') {
                return <RectangleComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  bounds={item.lat_long.coordinates}
                  fillColor={item.color}
                  onClick={this.onGeoFenceClick}
                />;
              }
              else if (item.shape_type === 'Circle') {
                return <CircleComponent
                  key={item.id}
                  data={item}
                  getAddressForMarker = {this.getAddressForMarker}
                  radius={item.radius}
                  center={{
                    lat: item.lat_long && item.lat_long.coordinates[1],
                    lng: item.lat_long && item.lat_long.coordinates[0],
                  }}
                  fillColor={item.color}
                  onClick={this.onGeoFenceClick}
                />;
              }
              return null;
            })}

            {!isLoading && detail.map((item, index) => {
              if (item.location && item.location.type === 'Point') {
                return <MarkerComponent
                  key={item.id}
                  data={item}
                  position={{
                    lat: item.location.coordinates && item.location.coordinates[1],
                    lng: item.location.coordinates && item.location.coordinates[0],
                  }}
                  onClick={this.onShapeClick}
                />;
              }
              return null 
            })}
            <InfoWindowComponent
              shape={activeShape}
              visible={showInfoWindow}
              shapeEvent={shapeEvent}
              data={data}
            >
              <div>
                {data.name && <div>GeoFence: {data.name}</div>}
                {data.employee_name && <div>Employee: {data.employee_name}</div>}
                {data.datetime && <div>Date: {data.datetime}</div>}
                {data.activity_name && <div>Activity: {data.activity_name === 'Start of Geofence Breach' ? 'Left' : data.activity_name === 'End of Geofence Breach' ? 'Entered' : data.activity_name}</div>}
                {data.address && <div>Address: {data.address}</div>}
              </div>
            </InfoWindowComponent>
          </MapComponent>} 
        <PaginationComponent
          count={currentLocationList.count}
          location={location}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  currentLocationList: ActivityTrackDucks.currentLocationList(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  location: RouteDucks.location(state),
  geoFenceList: GeoFenceDucks.geoFenceList(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

ActivityTrackMapSection.propTypes = {
  currentLocationList: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  google: PropTypes.object,
  location: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  selectedMarkerAddress: PropTypes.string,
  ActivityTrackDucks: PropTypes.object.isRequired,
};

ActivityTrackMapSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withConnect = connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivityTrackMapSection);

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBeTBckDWxM1FVU58ZfamZIuNsWt_qIoxw',
  libraries: ['places', 'drawing'],
})(withConnect);
