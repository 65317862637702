import React, {PureComponent} from 'react';
import Link from 'react-router-dom/Link';
import { Formik } from 'formik';
import { Label, Form, FormText, FormGroup, Input, Button , Card, CardTitle } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import name from './name.png';
import styles from './styles.module.scss';

class ForgotPasswordForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
 async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    this.props.usecase.setup_data(values);
    try {
      await this.props.usecase.execute(values);
    } catch (e) {
      console.log( e.errors);
      setErrors(e.errors);
    }
    setSubmitting(false);


  }
 render() {
    return (
     <div>
      <Card className="ui-card mt-4">
      <div className="text-center pb-5 pt-1">
        <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
        <img src={name} height="26px" width="150px" alt="Logo"/>
      </div>      
      <CardTitle className={`${styles['heading']} text-left ml-5`}>Forgot Password?</CardTitle>
      <div className={`${styles['label-part']} pb-3 mt-3 ml-5 mr-5`} style={{color: '#666666'}}>Enter your registered Email to reset the password</div>
        <Formik
          initialValues={this.props.data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            }) => 
           (
              <Form onSubmit={handleSubmit} className="ml-5 mr-5">
                <FormGroup>
                <Label>Email address</Label>
                <Input
                  type="text"
                  placeholder="Enter your registered email address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  data-lpignore={true}
                />
                {errors.email &&
                 <div style={{minHeight: '3vh'}}><FormText color="danger">{errors.email}</FormText></div>
                }
                {errors && errors.non_field_errors &&
                 <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.non_field_errors}</FormText></div>
                }
                 </FormGroup>
                 <div className="text-right">
                   <Button
                    className={`${styles['accounts-button']} mt-4`}
                    type="submit"
                    isColor='primary'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Reset Password <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                 </div>
                 <div className="text-right mt-3">
                  <Link to="/accounts/login">Go back to login</Link>
                </div>
             </Form>
            )}
          />
          </Card>
        </div>
    );
  }}



export default ForgotPasswordForm;
