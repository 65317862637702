import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';
import cookie from 'react-cookies';

import { toast } from 'react-toastify';
import { GoogleApiWrapper } from 'google-maps-react';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as UserDucks from 'ducks/accounts/user';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as SessionDucks from 'ducks/activities/session';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';

import SectionLoaderAtom from 'atoms/SectionLoader';
import ActivitySplitFormModalComponent from 'components/activities/activitySplitList/FormModal';
import ActivitySplitMapComponent from 'components/activities/activitySplitList/ActivityMap';
import ActivityLocationWidgetComponent from 'components/activities/activitySplitList/ActivityLocationWidget';
import ConfirmationModal from 'components/activities/activitySplitList/ConfirmationModal';

import { MSGS } from 'constants/activities';
import { server } from 'helpers/config';

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

const GeocodingOption = server.geocodingOption;


/**
 * ActivitySplitListPage -> ActivitySplitListSection
 *
 * Components:
 *    - {@link ActivitySplitTableComponent}
 *
 * State:
 *    - activity split list
 *
 * Actions:
 *    - toggle
 *    - putActivitySplit
 */
class ActivitySplitListSection extends React.Component {
  constructor(props) {
    super(props);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.state = { isOpen: false, isModalOpen: false,
      highlightedMarker: {}, error: false,
      detail: {}, isProceeding: false, isCheckboxTrue: true,
    };
    this.highlightMarker = this.highlightMarker.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.resetData = this.resetData.bind(this);
    this.toggleConfirmationModal = this.toggleConfirmationModal.bind(this);
    this.goTo = this.goTo.bind(this);
    this.proceedToSubmit = this.proceedToSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount(){
    this.resetData();
  }

  componentWillReceiveProps(nextProps) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.props.ActivitySplitDucks.getActivitySplits({ ...params, ...nextQuery });
    }
  }

  getLocationAddress(marker) {
    if(!marker.address){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker , GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }
  
  closeModal(e){
    cookie.save("isCheckboxTrue", e.target.checked);
  }

  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
  }

  handleMarkerClick(marker, showBeacon) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
   if(showBeacon) return this.getLocationAddress(marker);
  }

  update(detail) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query }, activitySplitDetail } = this.props;

    let statusChanged = false ;
    if(detail.status !== activitySplitDetail.status) statusChanged = true;

    // const ShiftActivities = activitySplitList && activitySplitList.filter((item)=>item.activity_code ==="PUNCH-IN");

    let shiftId = detail.id;

    const logData = {
      from: [{ start : activitySplitDetail.start_datetime, end : activitySplitDetail.end_datetime, status : activitySplitDetail.status, time_changed: true, activity_code: activitySplitDetail.activity_code, comment: activitySplitDetail.reason,  status_changed: statusChanged}],
      to: [{ start : detail.start_datetime, end : detail.end_datetime, status : detail.status, time_changed: true, activity_code: detail.activity_code, comment: detail.reason,  status_changed: statusChanged}],
      shift: shiftId,
    };

    const allData = { ...detail, log_data: logData }

    return this.props.ActivitySplitDucks.putActivitySplit(allData)
      .then(() => {
        toast.success(MSGS.SPLIT_SESSION_SUCCESS);
        this.props.ActivitySplitDucks.getActivitySplits({ ...params, ...query });
        this.toggle();
      });
  }

  toggle(data) {
    const { isOpen } = this.state;
    if (!isOpen) this.props.ActivitySplitDucks.setActivitySplit(data);
    this.setState({ isOpen: !isOpen });
  }

  highlightMarker(marker){
   this.props.ActivityTrackDucks.getActivityLocationAddress(marker, GeocodingOption)
      .then(() => {
        const { selectedMarkerAddress } = this.props;
        this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
          ...marker,
          address: selectedMarkerAddress,
        });
      })
    return this.setState({ highlightedMarker: marker });
  }

  submitManualSession(detail) {
    if (moment(detail.meal_start_datetime) >= moment(detail.meal_end_datetime)) {
      return Promise.reject({
        non_field_errors: 'Meal end datetime must be greater than meal start datetime.',
      });
    }
    if (moment(detail.meal_start_datetime) <= moment(detail.start_datetime) ||
        moment(detail.meal_start_datetime) >= moment(detail.end_datetime) ||
        moment(detail.meal_end_datetime) <= moment(detail.start_datetime) ||
        moment(detail.meal_end_datetime) >= moment(detail.end_datetime)) {
      return Promise.reject({
        non_field_errors: 'Meal-Period must be between Punch-In session.',
      });
    }
    const mealPeriodSessionData = {
      split_id: detail.id,
      data: [{
        end_datetime: detail.meal_end_datetime,
        start_datetime: detail.meal_start_datetime,
      }],
    };

    return this.props.SessionDucks.postMealPeriodSession(mealPeriodSessionData, detail.id);
  }

  resetData(){
    this.props.ActivityTrackDucks.resetSessionInfo();
    this.props.GeoFenceDucks.resetGeoFence();
  }

  toggleConfirmationModal(error, data){
    const { isModalOpen } = this.state;
    if(error === 'true') {
      this.setState({ error: true, detail: data });
    }
    else {
      this.setState({ error: false, detail: data });
    }
    this.setState({ isModalOpen: !isModalOpen });
  }

  goTo(){
    const { location: { query }, dailyTimecardDetail } = this.props;
    const { detail } = this.state;
    const { router: { history, route: { match: { params } } } } = this.context;
    if(query.employee_id){
      return history.push({
        pathname: `/activity/timecard/custom/list/${params.id}/split/add`,
        search: queryString.stringify({
          employee: query.employee_id,
          shiftId: detail.id,
          parent: JSON.stringify(query),
        }),
      });
    }
    return history.push({
      pathname: `/activity/timecard/list/${params.timecard}/${params.id}/split/add`,
      search: queryString.stringify({
        employee: dailyTimecardDetail.employee.id,
        shiftId: detail.id,
        parent: JSON.stringify(query),
      }),
    });
  }

  proceedToSubmit(){
    const { detail, isModalOpen } = this.state;
    this.setState({ isProceeding: true });
    if (detail.add_meal) {
      return this.submitManualSession(detail)
        .then(response => {
          this.update(detail);
          this.setState({ isModalOpen: !isModalOpen, isProceeding: false });
        })
        .catch((err) => {
          this.setState({ isProceeding: false, isModalOpen: !isModalOpen });
          if(err.split_in_session){
            this.toggleConfirmationModal('true', detail);
          }
          toast.error(err.non_field_errors);
        });
    }
    return this.update(detail)
      .then(() => this.setState({ isModalOpen: !isModalOpen, isProceeding: false }))
      .catch((err) => {
        this.setState({ isProceeding: false, isModalOpen: !isModalOpen });
        if(err.split_in_session){
          this.toggleConfirmationModal('true', detail);
        }
        toast.error(err.non_field_errors);
      });
  }

  render() {
    const {
      isLoading,
      activitySplitList,
      activitySplitDetail,
      timecardDetail,
      dateFormat,
      timeFormat,
      timeFormat24Hrs,
      userTimezone,
      sessionInfo,
      selectedMarkerAddress,
      geoFenceDetail,
      isLocationLoading,
      permission,
      dailyTimecardDetail,
    } = this.props;

    const { isOpen, isModalOpen, highlightedMarker, error, isProceeding } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="mt-2">
        <Row className="ml-3 mr-4 pb-5">
          <Col md={6} className="pr-3 pl-0">
            <ActivityLocationWidgetComponent
              sessionInfo={sessionInfo}
              isLocationLoading={isLocationLoading}
              isLoading={isLoading}
              highlightMarker={this.highlightMarker}
              data={activitySplitList}
              toggle={this.toggle}
              timecardDetail={timecardDetail}
              dailyTimecardDetail={dailyTimecardDetail}
            />
          </Col>
          <Col md={6} className="pl-0 pr-0">
            <ActivitySplitMapComponent
              sessionInfo={sessionInfo}
              toggleInfoWindow={this.toggleInfoWindow}
              onMarkerClick={this.handleMarkerClick}
              geoFenceDetail={geoFenceDetail}
              isLoading={isLoading}
              resetData={this.resetData}
              isLocationLoading={isLocationLoading}
              selectedMarkerAddress={selectedMarkerAddress}
              highlightedMarker={highlightedMarker}
              getLocationAddress={this.getLocationAddress}
              permission={permission}

            />
          </Col>
        </Row>
        {isOpen &&
          <ActivitySplitFormModalComponent
            dateFormat={dateFormat}
            timeFormat={timeFormat}
            timeFormat24Hrs={timeFormat24Hrs}
            initialValues={{
              ...activitySplitDetail,
              meal_start_datetime_tz: activitySplitDetail.start_datetime_tz,
              meal_end_datetime_tz: activitySplitDetail.end_datetime_tz,
            }}
            isOpen={isOpen}
            userTimezone={userTimezone}
            formData={activitySplitDetail}
            toggle={this.toggle}
            update={this.update}
            submitManualSession={this.submitManualSession}
            toggleConfirmationModal={this.toggleConfirmationModal}
          />
        }
        {
          isModalOpen && 
          <ConfirmationModal
            toggle={this.toggleConfirmationModal}
            isOpen={isModalOpen}
            goTo={this.goTo}
            error={error}
            proceedToSubmit={this.proceedToSubmit}
            isProceeding={isProceeding}
            closeModal={this.closeModal}  
          />
        }
      </section>
    );
  }
}

const mapStateToProps = state => ({
  activitySplitDetail: ActivitySplitDucks.activitySplitDetail(state),
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  location: RouteDucks.location(state),
  timecardDetail: TimecardDucks.timecardDetail(state),
  userTimezone: UserDucks.userBATimezone(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
});

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

ActivitySplitListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  activitySplitList: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  userTimezone: PropTypes.string.isRequired,
  ActivitySplitDucks: PropTypes.object.isRequired,
  SessionDucks: PropTypes.object.isRequired,
  sessionInfo: PropTypes.object,
  geoFenceDetail: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  permission: PropTypes.bool,
  timecardDetail: PropTypes.object.isRequired,
};

ActivitySplitListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

// export default connect(
//   mapStateToProps,
//   mapActionsToProps,
// )(ActivitySplitListSection);

const connectAppToRedux = connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivitySplitListSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(connectAppToRedux);
