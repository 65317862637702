import React, {PureComponent} from 'react';
import { Button, Modal, ModalBody , ModalHeader , ModalFooter} from 'reactstrap';
//import ButtonLoaderAtom from 'atoms/ButtonLoader';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import styles from './styles.module.scss';
import Link from 'react-router-dom/Link';

class ResetPasswordModal extends PureComponent {
constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }
  render() {
    const {isOpen} = this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalBody>
          <div>Your password has expired, please change it before signing in.</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.goTo(`/accounts/change-expired-password`)}>Reset Password</Button>
        </ModalFooter>
      </Modal>
    );}
  }
  
const mapStateToProps = state => ({});

const mapActionsToProps = dispatch => ({});

ResetPasswordModal.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ResetPasswordModal);