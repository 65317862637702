import moment from 'moment-timezone';
import GraniteError from 'lib/granite-error';
import StorageGateway from 'lib/storage-gateway';
import EventEmitter from 'lib/event-emitter';
import { server } from 'helpers/config';

const EVENT_TYPES = {
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
};

/* eslint-disable */
class RegisterUseCase{

  userAPIGateway = null;

  constructor(userAPIGateway){
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString(){
    return 'RegisterUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data){
    this.email = data.email;
    this.password = data.password;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.confirm_password = data.confirm_password;
    this.company_name = data.company_name;
    this.phone_no = data.phone_no;
    this.phone_ext = data.phone_ext;
    this.timezone = moment.tz.guess();
    this.device = 'WEB';
    this.grant_type= "password";
    this.client_id = server.ClientId;
    this.client_secret= server.ClientSecret;
    this.inputType= data.inputType;
  }

  async execute() {

    let errors = {};
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\\d$@$!%*#?&]{6,123}$';
    if (!this.email) {
      errors.email = 'Enter Email';
    } else if (!re.test(this.email)) {
      errors.email = 'Invalid email address entered';
    }
    if (!this.password) {
      errors.password = 'Enter Password';
    }
    if(!this.first_name){
      errors.first_name = 'Enter First Name';
    }
    if(!this.company_name){
      errors.company_name = 'Enter Business Name';
    }
    
    if(!this.phone_ext){
      errors.phone_ext= 'Enter Country Code';
    }

    if(!this.password){
      errors.password = 'Enter Password';
    }
    
    if(!this.confirm_password && this.inputType=="password"){
      errors.confirm_password = 'Enter Confirm Password';
    }
    if(this.password && this.confirm_password && (this.password !== this.confirm_password) && this.inputType=="password"){
      errors.confirm_password = 'Password and Confirm Password does not match';
    }
    if(!this.phone_no){
      errors.phone_no='Enter phone number';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    const userEntity = await this.userAPIGateway.register(this.email, this.password, this.first_name, this.last_name,
      this.confirm_password, this.company_name, this.phone_no, this.phone_ext, this.timezone);
    StorageGateway.set('Token', userEntity.token);
    const oAuthToken = await this.userAPIGateway.postAuthentication(this.client_id,
      this.client_secret, this.device, this.grant_type, this.password, userEntity.username);
    StorageGateway.set('oAuthToken', oAuthToken);
    this.eventEmitter.emit({
      type: EVENT_TYPES.REGISTER_SUCCESS,
      data: userEntity,
    });
  }
}


export default RegisterUseCase;
