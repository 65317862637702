import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Input ,FormText} from 'reactstrap';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import InputAtom from 'atoms/Input';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import DeleteTagModal from 'components/common/DeleteTagModal';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import { FORM_LAYOUT } from 'constants/layout';
import closeimage from './Close-Cross.png';
import EditIconAtom from 'atoms/EditIcon';

import { Formik,FieldArray } from 'formik';


class TagModalComponent extends React.PureComponent{
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.deleteTag= this.deleteTag.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.deleteAllTags = this.deleteAllTags.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = { 
      tagsToBeDeleted:[],
      isLoading: false,
      isChecked :  props.tagsList && props.tagsList.length ? props.tagsList.map((item)=> false) : [],
      isDeleteModal: false,
    };
  }


  componentWillReceiveProps(nextProps){
    if(nextProps.tagsList !== this.props.tagsList){
      this.setState({isChecked : nextProps.tagsList && nextProps.tagsList.length && nextProps.tagsList.map((item)=> false)})
    }
  }

  toggle(){
    const { isDeleteModal, tagsToBeDeleted} = this.state;
    const { hideModal } = this.props;
    if(!tagsToBeDeleted.length){
      return toast.error('Please select tags to be deleted');
    }
    else{
      this.setState({isDeleteModal:!isDeleteModal});
      {hideModal && hideModal();}
    }
  }


  deleteAllTags(val){
    const { tagsList } = this.props;
    const { tagsToBeDeleted } = this.state;
    if(val.target.checked == false){
      this.setState({tagsToBeDeleted : []});
      this.setState({isChecked : this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> false)})
    }
    else{
      const a = [];
      tagsList.forEach((item, index)=>{
        a.push(item);
      });
      this.setState({tagsToBeDeleted : a});
      this.setState({isChecked : this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> true)})
    }
  }

 async updateTagName(item,setFieldValue,index){
    this.setState({isLoading : true});
    const { tagsCollection } = this.props;
    const tagsCollectionId = tagsCollection[0].id;
    if(item.id===item.name.trim()){
      setFieldValue(`tagList.${index}.status`,false)
      return
    }
    if(item.name.trim() === ''){
      toast.error("Tag name can't be blank")
      return 
    }
    setFieldValue(`tagList.${index}.submit`, true)
    await this.props.updateTagName(tagsCollectionId, {old_value: item.id,
    new_value:item.name.trim()})
      .then((res) => {
        this.props.getUpdatedList()
        toast.success('Tags name updated successfully');
      })
     .catch((err) => {
         console.log("err",err)
        setFieldValue(`tagList.${index}.name`, item.id)
        toast.error(err?.non_field_errors ?err?.non_field_errors[0]:'Something went wrong');
     });
     setFieldValue(`tagList.${index}.submit`, false)
     setFieldValue(`tagList.${index}.status`,false)
  }

  deleteSelectedTags(){
    this.setState({isLoading : true});
    const { tagsCollection, hideModal } = this.props;
    const { tagsToBeDeleted } = this.state;
    const tagsCollectionId = tagsCollection[0].id;
    const selectedTags = [];
    let resetChecked = this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> false);
    tagsToBeDeleted && tagsToBeDeleted.forEach((item)=>{
      selectedTags.push(item.name);
    });
    if(!tagsToBeDeleted.length){
      this.setState({isLoading : false });
      return toast.error('Please select tags to be deleted');
    }
     this.props.deleteSelectedTags(tagsCollectionId, selectedTags)
      .then((res) => {
        this.props.getUpdatedList();
        toast.success('Tags deleted successfully');
        this.setState({isLoading : false , isChecked: resetChecked, tagsToBeDeleted: [], isDeleteModal:false});
        {hideModal && hideModal();}
      })
     .catch((err) => {
        toast.error('No tags selected');
        this.setState({isLoading : false,  isChecked: resetChecked, tagsToBeDeleted: [], isDeleteModal:false});
        {hideModal && hideModal();}
     });
  }

  deleteTag(item, index){
    const { tagsList } = this.props;
    const { tagsToBeDeleted , isChecked} = this.state;
    var isTagPresent = tagsToBeDeleted.indexOf(item);
    if(isTagPresent === -1){
      tagsToBeDeleted.push(item);
      let a = isChecked && isChecked.map((item)=>item);
      a[index] = true;
      this.setState({isChecked : a });
    }
    else{
      let filteredTags =  tagsToBeDeleted.filter((i)=>i.id !== item.id);
      let a = isChecked && isChecked.map((item)=>item);
      a[index] = false;
      this.setState({isChecked : a, tagsToBeDeleted : filteredTags});
    }
  }



  submit(data,{setSubmitting, setErrors}) {
    const { createTag , change} = this.props;
    setErrors({})
    setSubmitting(true)
    if(!data.name){
      return toast.error('Please add the name of the tag');
    }
    return createTag(data)
    .then(() => {
      change('name', '');
      this.props.getUpdatedList();
      toast.success('New tag created successfully');
    })
    .catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
    setSubmitting(false)
  }

  render() {
    const { isModalOpen, toggle, handleSubmit, submitting , tagsList } = this.props;
    const { isLoading , isChecked, isDeleteModal } = this.state;
    const isAnyTagSelected = isChecked.length && isChecked.filter((item)=>item == true);
    const isSelectAllTrue = isAnyTagSelected && tagsList.length && isAnyTagSelected.length == tagsList.length ? true : false;

    return (
      <div>
        <Modal
          isOpen={isModalOpen}
          toggle={toggle}
          backdrop="static"
          className="form-modal"
        >
          <ModalHeader>
            Manage Tags
            <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
          </ModalHeader>
          <Formik
              initialValues={{name:'',tagList:tagsList.length?tagsList:[]}}
              onSubmit={this.submit}
              enableReinitialize={true}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                }) => (
          <Form onSubmit={handleSubmit} noValidate autoComplete = "off"  className="ml-3 mr-4 pl-2 pr-2 pb-2">
            <ModalBody className="pb-0">
              <div className="error-text mb-2">{this.props.error}</div>
              <Row>
                <Col>
                  <div className ="mb-2" style={{fontSize: '16px'}}>Add New Tags</div>
                  <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Add New Tags"
                            onChange={handleChange}
                            value={values.name}
                            className={errors && errors.name ? "form-control-error" : null}
                            data-lpignore={true}
                            autoComplete="new-password"
                          />
                          <FormText color="danger">{errors && errors.name}</FormText>
                </Col>
              </Row>
               <div className={`${styles['manage-tags']} pl-2`}>
               <span className='d-inline-block custom-select-pos' style={{width:'6%'}}>
                  <Field
                    id="selectAll"
                    name="selectAll"
                    component={CheckboxAtom}
                    onClick={(val)=>this.deleteAllTags(val)}                          
                    checked={isSelectAllTrue}
                    className={`${styles['custom-select-pos-tags']}` }
                  />
                  </span>
                <span style={{fontSize: '16px'}}>Select All</span>
                <span className={`${styles['deletetag']} link-name float-right isHide`} style={{fontSize: '16px'}} onClick={()=>this.toggle()}>Delete</span>
              </div>
               <div className={styles['tags-list']}>
               <FieldArray
                         name="friends"
                         render={arrayHelpers => (
                          <div>
                          {values.tagList.length > 0 ? values.tagList.map((item, index)=>(
                              <div className={styles['taglist']}>
                                <Input
                                  type="checkbox"
                                  id={item.id}
                                  label={item.name}
                                  name={item.id}
                                  onChange={(val)=>this.deleteTag(item, index)}
                                  className={styles['checkbox-tags-list']}
                                  checked={isChecked[index]}
                                />
                               {/* // <span className={styles['custom-position']}>{item.name}</span> */}
                                {!item.status?<span className={styles['custom-position']}>{item.name}</span>:<div className={styles['save-button']}><Input
                                  type="text"
                                  id={`tagList.${index}.name`}
                                  label={item.name}
                                  name={`tagList.${index}.name`}
                                  onChange={handleChange}
                                  value={item.name}
                                  className={errors && errors.name ? `form-control-error ${styles['custom-position']}` : styles['custom-position']}
                                  data-lpignore={true}
                                  autoComplete="off"
                                  />
                                  <Button
                                    className={`${styles["form-button"]}`}
                                    onClick={()=>{this.updateTagName(item,setFieldValue,index)}}
                                  >
                                      Save{" "}
                                      <ButtonLoaderAtom
                                        active={item.submit}
                                      />
                                  </Button>
                                  </div>
                                }
                                <EditIconAtom onClick={()=>setFieldValue(`tagList.${index}.status`, true)}/>
                              </div>
                              )
                            ) : <div className="text-center mt-3">
                                <span>No Records Found</span>
                              </div>
                            }
                            </div>
                       )}/>
                  
                </div>
            </ModalBody>
            <ModalFooter style={{ border: "none" }}>
              <Button
                color="primary"
                className="secondary"
                type="submit"
              >
                Add tag <ButtonLoaderAtom active={isSubmitting} />
              </Button>
            </ModalFooter>
          </Form>
           )}
           />
              
        </Modal>
        {isDeleteModal &&
          <DeleteTagModal
            isOpen={isDeleteModal}
            toggle={this.toggle}
            deleteSelectedTags={this.deleteSelectedTags}
            isSubmitting={isLoading}
          />
        }
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (values.name && values.name.length>25) errors.name = 'Tag name cannot have more than 25 characters';
  return errors;
};


TagModalComponent.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  createTag: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'TagModalComponent',
  validate,
})(TagModalComponent);