import React from 'react';

import moment from 'moment';
import { GoogleApiWrapper } from 'google-maps-react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import TwoColumnLayout from 'layouts/TwoColumn';
import ActivityTrackListSection from 'sections/activities/activityTrackList/List';
import ActivityTrackFilterSection from 'sections/activities/activityTrackList/Filter';

import { NAV } from 'constants/activities';
import { server } from 'helpers/config';

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;
const GeocodingOption = server.geocodingOption;
/**
 * TrackHistoricListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ActivityTrackFilterSection}
 *    - {@link ActivityTrackListSection}
 *
 * Fetch Needs:
 *    - getActivityTracks
 */
class TrackHistoricListPage extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.state = { isLoading: false, session: 'historic', isInfoOpen: true };
    this.punchOutAddress = this.punchOutAddress.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("historic_tracking_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'activity.tracking.historic'});
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    const { session } = this.state;

    if (prevQuery !== nextQuery) {
      this.fetchData({ session, ...nextQuery });
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('historic_tracking_page_size');
    return this.props.ActivityTrackDucks.getActivityTracksHistoric({...params, page_size: pageSize})
      .then(() => {
        this.punchOutAddress();
      })
  }

  loadData(){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const { session } = this.state;
    if (!('is_active' in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          is_active:true,
          start_date: moment().subtract( 6, "days").format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
        }),
      });
    }else {
      this.fetchData({ session, ...query });
    }
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("historic_tracking_info", !isInfoOpen);
  }


  punchOutAddress(){
    const { activityTrackList } = this.props;
    Promise.all(activityTrackList.results.map((item, index) => {
      setTimeout(() => {if(item.punch_out_location){
        const data = {
          position: { lat: item.punch_out_location.coordinates[1],
            lng: item.punch_out_location.coordinates[0] }
        }
        console.log('seeeeeee meee', this.props)
        return this.props.ActivityTrackDucks.getActivityLocationAddress(data , GeocodingOption)
          .then((res) => {
            let add = '';
            if(res.action.payload.data.status === 'OK'){
              add = res.action.payload.data.results[0].formatted_address;
            }
            else if(res.action.payload.items && res.action.payload.items.length){
            add = res.action.payload.items[0].label;
          }
            this.props.ActivityTrackDucks.setPunchOutLocation({...item, address: add });
        });
      }}, 100*index)
      return null;
    }))
     .finally(() => this.setState({ isLoading: false }));
  }
  
  render() {
    const { isLoading, session, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout
        navInfo={NAV.TrackHistoricListPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <ActivityTrackFilterSection session={session} />
        <ActivityTrackListSection
          isLoading={isLoading}
          session={session}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
  activityTrackList: ActivityTrackDucks.activityTrackList(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  //  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  //  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

TrackHistoricListPage.propTypes = {
  location: PropTypes.object.isRequired,
  ActivityTrackDucks: PropTypes.object.isRequired,
  //  DepartmentDucks: PropTypes.object.isRequired,
  //  TeamDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

TrackHistoricListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectAppToRedux = connect(
  mapStateToProps,
  mapActionsToProps,
)(TrackHistoricListPage);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(connectAppToRedux);
