import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import bindActionCreators from 'redux/lib/bindActionCreators';
import cookie from 'react-cookies';


import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import FullScreenLoaderAtom from 'atoms/FullScreenLoader';

class MatchWhenUnauthorized extends React.Component {
  constructor(props){
    super(props);
    this.state = { isLoading: false };
  }

  componentWillMount() {
    const { profile } = this.props;
    if (!Object.keys(profile).length) {
      cookie.remove('lastActivityTime', { path: '/' });
      this.setState({ isLoading: true });
      Promise.all([
        this.props.UserDucks.getProfileUser(),
        this.props.UserDucks.getAccessUser(),
        this.props.UserDucks.getExtraData(),
        this.props.BasicSettingDucks.getBasicSetting(),
      ])
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { component: Component, profile, ...rest } = this.props;
    const emailVerfied = profile.company && profile.company.is_email_verified;
    const phoneVerfied = profile.company && profile.company.is_phone_verified;
    const { isLoading } = this.state;
    if (isLoading) return <FullScreenLoaderAtom active />;
    return (
      <Route
        {...rest}
        render={
          (renderProps) => {
            if (Object.keys(profile).length && (emailVerfied || phoneVerfied)) {
              return (<Redirect to={{ pathname: '/dashboard' }} />);
            }
            else if (Object.keys(profile).length && (!emailVerfied && !phoneVerfied)) {
              return (<Redirect to={{ pathname: '/accounts/activate-user' }} />);
            }
            return <Component {...renderProps} />;
          }
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  profile: UserDucks.profile(state),
});

const mapActionsToProps = dispatch => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

MatchWhenUnauthorized.propTypes = {
  component: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  BasicSettingDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MatchWhenUnauthorized);
