import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { toast } from 'react-toastify';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job'

import GeoFenceBreachFilterFormComponent from 'components/reports/geoFenceBreach/FilterForm';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { REPORT_KEY_MAPPINGS } from 'constants/reports';

/**
 * ReportDetailPage -> GeoFenceBreachFilterSection
 *
 * Components:
 *    - {@link TimecardHistoryFilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - getJobs
 */
class GeoFenceBreachFilterSection extends React.Component {
  constructor(props) { 
    super(props);
    this.getJobs = this.getJobs.bind(this);
    this.export = this.export.bind(this);
    this.state = { isLoading : false };
  }

  getJobs(params) {
    return this.props.JobDucks.getJobs(params);
  }

  export() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });

    return this.props.ReportDucks.downloadReportData({ ...query, ...params })
      .then((data) => {
        const date = moment().format('DD/MM/YYYY');
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = ((evx) => 
          {
            const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.navigator.msSaveOrOpenBlob(myBlob, `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`);
          });
          a.click();
        }
        // chrome ,safari and mozilla browsers 
        else {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const  url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`;
          element.click();
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        toast.error('Something went wrong. Please contact your administrator.')
        this.setState({ isLoading: false })});
  }

  render() {
    const { dateFormat, location, jobAll } = this.props;
    const { query } = location;
    const { isLoading } = this.state;
    const formattedDates = {};
    let parseJobArr=[];
    if (query.jobs) parseJobArr = JSON.parse(query.jobs);

    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();

    return (
      <Row className="report-filter ml-3 mr-4">
        <Col md={11} className="px-0">
          <GeoFenceBreachFilterFormComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={{
              ...query,
              job_ids: parseJobArr,
              ...formattedDates,
            }}
            getJobs={this.getJobs}
            jobAll={jobAll}
          />
        </Col>
        <Col md={1} className="px-0">
          {Object.keys(query).length > 0 && query.start_date &&
            <section className="float-right">
              <Button color="accent" className='mr-0' onClick={this.export}>
                Export <ButtonLoaderAtom active={isLoading} />
              </Button>
            </section>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
  jobAll: JobDucks.jobAll(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

GeoFenceBreachFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  jobAll: PropTypes.array.isRequired,
};

GeoFenceBreachFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(GeoFenceBreachFilterSection); 
