import React from 'react';
import { Row, Col} from 'reactstrap'

import UserAPIGateway from 'accounts/gateways/user';
import LongCodeTestUseCase from 'accounts/usecases/longCodeTesting';

import LongTextForm from 'accounts/components/longTextForm';
import TwoColumnLayout from 'layouts/TwoColumn';
import { NAV } from 'constants/accounts';
import { toast } from 'react-toastify';

class LongTextPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateState = this.updateState.bind(this);
    this.listenLongCodeTestInUseCase = this.listenLongCodeTestInUseCase.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new LongCodeTestUseCase(userAPIGateway);
    this.state={smsButtonLoader:false};
  }

  componentWillMount(){
    this.listenLongCodeTestInUseCase();
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenLongCodeTestInUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SEND_SMS_SUCCESS':
          toast.success('SMS Sent Successfully')
        break;
        case 'SHOW_SMS_BUTTON_LOADER':
          this.updateState('smsButtonLoader',true)
        break;
        case 'HIDE_SMS_BUTTON_LOADER':
          this.updateState('smsButtonLoader',false)
        break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  render() {
    const {smsButtonLoader} = this.state;
    return (
      <TwoColumnLayout
        navInfo={NAV.LongCodeTestFormPage}
      >
        <Row>
          <Col>
            <LongTextForm usecase={this.usecase} smsButtonLoader={smsButtonLoader}/>
          </Col>
        </Row>
      </TwoColumnLayout>
    );
  }
}


export default LongTextPage;
