import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';


/*
 *
 * CustomerListPage -> CustomerListSection ->  CustomerTableComponent
 *
 * Props:
 *    - employee list
 *
 */
const CustomerFormComponent = (props, context) => {
  const { data, toggle } = props;
  // let parsedParams;
  const tagNames = data && data.tags.map((item) => (
    <span className="mr-1 mb-1 pt-1 pl-1 pr-1 pb-1 tags-back">{item}</span>));
  // const allTags=tagNames.join();

  // if(query && query.show_extra_info) parsedParams = JSON.parse(query.show_extra_info);
  return (
    <div className="pl-5 pt-3 pb-3 white-background customer-card" key={data.id} style={{width:'50%'}}>
      <Row>
        <Col md={8}>
          <h4 className="text-ellipsis">{data && data.customer_name}</h4>
          <p className="tag-wrapper">{tagNames}</p>
        </Col>
        <Col md={4}>
          <Button color="primary" className="btn-job btn-width font-styles" size="sm" onClick={(item) => toggle(item)}>Edit</Button>
        </Col>
      </Row>    
      <Row className="mt-2">
        <Col md={4}>
          <p className="customer-details-title">Point of Contact</p>
          <p className="customer-details-title">Address</p>
          <p className="customer-details-title">Phone Number</p>
          <p className="customer-details-title">E-mail</p>
        </Col>
        <Col md={5}>
          <p className="customer-details-subtitle elipsis-job">{data && data.user.full_name ? data.user.full_name : "---"}</p>
          <p className="customer-details-subtitle elipsis-job">{data && data.address ? data.address : "---"}</p>
          <p className="customer-details-subtitle ">{data && data.phone_no ? data.phone_no : "---"}</p>
          <p className="customer-details-subtitle elipsis-job">{data && data.user.email ? data.user.email : "---"}</p>
        </Col>
      </Row>    
    </div>
  );
};

CustomerFormComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func,
};


CustomerFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default CustomerFormComponent;