import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class TimecardWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  

  toggle() {
    const {isOpen} = this.props;
    isOpen = false;
  }

  render() {
    const { isOpen, toggle , updateState, submitManualSession} = this.props;

    return (
      <div>
        <Modal isOpen={isOpen} className={this.props.className}>
        <ModalHeader toggle={toggle}>Warning</ModalHeader>
          <ModalBody>
            <h5>
            You are creating a shift for a future time. Are you sure you want to proceed?
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button 
              color="secondary" 
              onClick={()=>{updateState('isConfirm' , true); submitManualSession();}}
            >Ok</Button>{' '}
            <Button color="danger" onClick={toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default TimecardWarning;
