import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import MatchAlways from 'pages/MatchAlways'
import NoMatch from 'pages/NoMatch';
import PtoPolicyPage from './PtoPolicyPage'
import TimeOffListPage from './TimeOffListPage';
import BulkUploadPage from 'pages/pto/BulkUpload';
// import AddPtoPolicyPage from './AddPtoPolicyPage'
import EditPtoPolicyPage from './EditPtoPolicyPage'

const PtoRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="paid_time_off.policies"
        component={PtoPolicyPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/time-off/list`}
        name="paid_time_off.list"
        component={TimeOffListPage}
      />
      <MatchAlways
        path={`${match.url}/bulk-upload`}
        name="paid_time_off.bulkupload"
        component={BulkUploadPage}
      />
      <MatchAlways
        path={`${match.url}/:id`}
        name="paid_time_off.addEditPolicies"
        component={EditPtoPolicyPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

PtoRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PtoRoutes;
