import React, { PureComponent } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

import styles from "./styles.module.scss";
import ButtonLoaderAtom from "atoms/ButtonLoader";

class InactivationWarningModal extends PureComponent {
  render() {
    const {
      isOpen,
      toggle,
      refreshLoader,
      checkEmpsJobAssignMentStatus,
      cancelLoader,
      updateJobAssignmentofEmployee,
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
        <ModalHeader toggle={() => toggle()}>Warning</ModalHeader>
        <ModalBody>
          <div style={{ fontSize: "16px" }}>
          If you mark Employees as inactive, paid holidays related to them (if any) will also be deleted.
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            className={styles.cancel_btn}
            onClick={() => toggle()}
            disabled = {refreshLoader}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            onClick={() => checkEmpsJobAssignMentStatus()}
          >
            Ok <ButtonLoaderAtom active={refreshLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InactivationWarningModal;
