import React from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';
import * as OnboardDucks from 'ducks/vendors/onboard';
import moment from 'moment-timezone';

import FooterSection from 'sections/common/Footer';
import SidenavSection from 'sections/common/Sidenav';
import SubHeaderSection from 'sections/common/SubHeader';
import TabsSection from 'sections/common/Tabs';

import RefreshIconAtom from 'atoms/RefreshIcon';
import CollapseIconAtom from 'atoms/VideoCollapseIcon';
import CollapsableInfoSection from 'sections/common/CollapsableInfo';
import TrialPeriodNotification from 'components/common/TrialPeriodNotification/trialPeriodNotification';

import Menu from './ham-menu.png';

/**
 * SSTCFLayout Layout
 *
 * Sections:
 *    - {@link SidenavSection}
 *    - {@link SubHeaderSection}
 *    - {@link TabsSection}
 *    - Content from children
 *    - {@link FooterSection}
 *
 * @example
 *  __________________________________
 * |            |                     |
 * |            |      SubHeader      |
 * |            |_____________________|
 * |            |                     |
 * |            |        Tabs         |
 * |  Sidenav   |_____________________|
 * |            |                     |
 * |            |                     |
 * |            |       Content       |
 * |            |                     |
 * |            |_____________________|
 * |            |                     |
 * |            |       Footer        |
 * |____________|_____________________|
 *
 */
class SSTCFLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sidemenuCollapsed: false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentWillMount() {
    const flapOpen = cookie.load('flapOpen');
    if(flapOpen){
      this.setState({ isOpen: JSON.parse(flapOpen) });
    }
    if (cookie.load('sidemenuCollapsed') === 'false') {
      this.setState({ sidemenuCollapsed: false });
    } else if(cookie.load('sidemenuCollapsed') === 'true') {
      this.setState({ sidemenuCollapsed: true });
    }
  }

  toggleCollapse() {
    this.setState({sidemenuCollapsed: !this.state.sidemenuCollapsed});
  }

  render() {
    const { children, navInfo, tabItems, infoList, toggleInfo,
      fetchData, isInfoOpen, isBusinessAdmin, customerDetail , userProfile, userExtraData} = this.props;
    const { sidemenuCollapsed } = this.state;
    const tutorialCompleted = userProfile && userProfile.company.tutorial_completed;
    const isEmployeeBool = userExtraData && userExtraData.company_permissions.is_employee;
    const isCompanyOwner = userExtraData && userExtraData.company_permissions.company_owner;
    const paddingValue = this.state.sidemenuCollapsed ? '55px' : '245px';
    cookie.save('sidemenuCollapsed', sidemenuCollapsed ? 'true' : 'false', { path: '/' });
    const showFlap = !isEmployeeBool && isCompanyOwner  && Object.keys(customerDetail).length
     && (customerDetail.commercial_status === "Trial Period" || moment(customerDetail.trial_end) >= moment()) && !isBusinessAdmin && customerDetail.commercial_status !== 'No Billing';
    const contanierHeight = showFlap ? '92vh' : '100vh';
    return (
      <div className={showFlap ? 'dashboard-mainbody' : null}>
        {showFlap &&
          <div>
            <div className='pending'>
              <TrialPeriodNotification
                customerDetail={customerDetail}
                tutorialDetail = {tutorialCompleted}
                userExtraData = {this.props.userExtraData}
              />
            </div>
          </div>}
         <div className={showFlap ? 'mainbody' : 'header'}>
          <div className={sidemenuCollapsed ? (isBusinessAdmin?'business-collapsed-side-nav side-nav-common':'collapsed-side-nav side-nav-common') : (isBusinessAdmin?'business-side-nav side-nav-common':'side-nav side-nav-common')} style={{width: paddingValue, zIndex: '11' ,minHeight: contanierHeight }}>
            <div className="collapse-menu-icon" role="button" tabIndex="0" onClick={this.toggleCollapse}>
              <img src={Menu} height="16" alt=""/>
            </div>
            <SidenavSection sidemenuCollapsed={sidemenuCollapsed} />
          </div>
          <div className="content container-padding" style={{marginLeft: paddingValue, transition: 'margin-left .5s' , minHeight: contanierHeight}}>
            {infoList && infoList.results && infoList.results.length ?
              <div>
                <CollapsableInfoSection
                  infoList={infoList.results}
                  toggleInfo={toggleInfo}
                  isInfoOpen={isInfoOpen}
                />
                <div className="collapse-text pr-3 mr-3">
                  <CollapseIconAtom
                    active={isInfoOpen}
                    className="cursor-pointer"
                    onClick={()=> toggleInfo()}
                    id="collapseIcon"
                  />
                </div>
              </div>
              : null}
            <SubHeaderSection navInfo={navInfo} fetchData={fetchData}/>
            <TabsSection tabItems={tabItems} />
            {children}
            <FooterSection />
          </div>
        </div>
      </div>
    );
  }
}




const mapStateToProps = (state) => ({
  userCompany: UserDucks.userCompany(state),
  isBusinessAdmin: UserDucks.isBusinessAdmin(state),
  customerDetail: PaymentDucks.customerDetail(state),
  tutorialDetail:OnboardDucks.tutorialDetail(state),
  userProfile: UserDucks.profile(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
});

SSTCFLayout.propTypes = {
  children: PropTypes.node.isRequired,
  navInfo: PropTypes.object,
  tabItems: PropTypes.array,
};

SSTCFLayout.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(SSTCFLayout);
