import { createSelector } from "reselect";

import axios from "helpers/interceptor";
import createReducer from "helpers/createReducer";
import URLS from "./urls";

const company_id = "";
const POST_BULK_PTO = "pto/policy/POST_BULK_PTO";
const POST_BULK_PTO_FULFILLED = "pto/policy/POST_BULK_PTO_FULFILLED";
const GET_BULK_PTO = "pto/policy/GET_BULK_PTO";
const GET_BULK_PTO_FULFILLED = "pto/policy/GET_BULK_PTO_FULFILLED";

export function getBulkPto(data) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return {
    type: GET_BULK_PTO,
    payload: axios.get(URLS.PTO_BULK_UPLOAD, config),
  };
}

export function postBulkPto(data, params) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return {
    type: POST_BULK_PTO,
    payload: axios.post(
      `${URLS.PTO_BULK_UPLOAD}?company_id=${params.company_id}`,
      data,
      config
    ),
  };
}

const defaultState = {
  detail: {},
};

function GET_BULK_PTO_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_BULK_PTO_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [POST_BULK_PTO_FULFILLED]: POST_BULK_PTO_FULFILLED_REDUCER,
  [GET_BULK_PTO_FULFILLED]: GET_BULK_PTO_FULFILLED_REDUCER,
};

export default createReducer(defaultState, handlers);
