import React from 'react';

import PropTypes from 'prop-types';

/**
 * ContentLayout Layout
 *
 * Components:
 *    - Content from children
 *
 * @example
 *  __________________________________
 * |                                  |
 * |                                  |
 * |             Content              |
 * |                                  |
 * |__________________________________|
 *
 */
const ContentLayout = (props) => {
  const { children } = props;

  return (
    <section className="container-padding">{children}</section>
  );
};

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentLayout;
