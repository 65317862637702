import React, {PureComponent} from 'react';
import { Formik } from 'formik';
import { Label, Form, FormText, FormGroup, Input , Button , Card , CardTitle } from 'reactstrap';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import name from './name.png';
import styles from './styles.module.scss';
import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';

class SetPasswordForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password' ,showStrengthCheck : false};
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const { inputType } = this.state;
    const { user } = this.props;
    setErrors({});
    this.props.usecase.setup_data({ ...values,
      uid: this.props.uid ,  inputType, username: user.username, email: user.email });
    try {
      await this.props.usecase.execute(values);
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }

 render() {
   const { inputType } = this.state;
   const { user } = this.props;

    return (
       <div>
      <Card className="ui-card mt-4">
        <div className="text-center pb-5 pt-3">
          <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
          <img src={name} height="26px" width="150px" alt="Logo"/>
        </div>
      <CardTitle className={`${styles['heading']} text-left ml-5`}>Set Password?</CardTitle>
        <Formik
          initialValues={this.props.data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="ml-5 mr-5">
                <FormGroup>                
                  <Label className="mt-3 mb-3"><b>Email:</b> {user.email}</Label>
                 </FormGroup>
                 <FormGroup>
                 <div className="pos-relative">
                    <Label className="mb-2">Password*</Label>
                    <Input
                      type={inputType}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      invalid={errors && touched.password && errors && errors.password}
                      className={errors && errors.password ? "form-control-error" : null}
                      data-lpignore={true}
                      onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                      onFocus = {this.strengthCheckToggle}
                    />
                    {inputType === 'password'
                      ? (<EyeOpenIconAtom
                        className="password-icon"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                      />)
                      : (<EyeCloseIconAtom
                        className="password-icon"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                    />)
                  }
                 <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.password}</FormText></div>
                  {this.state.showStrengthCheck &&
                    <PasswordStrengthMeter
                      values={values}
                    />
                  }
                 </div>
                 </FormGroup>
                 <FormGroup>

                {inputType === 'password'
                    ?(
                    <div>
                    <Label className="mb-2">Confirm Password*</Label>
                    <Input
                      type="password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirm_password}
                      invalid={errors && touched.confirm_password && errors.confirm_password}
                      className={errors && errors.confirm_password ? "form-control-error" : null}
                      data-lpignore={true}
                    />
                 <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.confirm_password}</FormText></div>
                 </div> ) : null }
                 </FormGroup>
                 <div className="text-right">
                   <Button
                    className="mt-2"
                    type="submit"
                    isColor='primary'
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                      Submit <ButtonLoaderAtom active={isSubmitting} />
                    </Button>
                 </div>
             </Form>
            )}
          />
        </Card>
      </div>
    );
  }}



export default SetPasswordForm;
