import React from 'react';

import PropTypes from 'prop-types';
import ViewIconAtom from 'atoms/ViewIcon';
import Table from 'reactstrap/lib/Table';

import ActiveIconAtom from 'atoms/ActiveIcon';
import ResetPasswordIconAtom from 'atoms/ResetPasswordIcon';
import SortHeaderComponent from 'components/common/SortHeader';

/**
 * IncompleteSignUpListPage -> IncompleteSignUpListSection -> IncompleteSignUpTableComponent
 *
 * Props:
 *  - Reset Incomple SignUp Vendor Password
 */

const IncompleteSignUpTableComponent = (props) => {
  const { data, toggleResetPasswordForm , toggle} = props;

  return (
    <Table hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <SortHeaderComponent ordering="first_name" className="pl-3">First Name</SortHeaderComponent>
          <SortHeaderComponent ordering="last_name">Last Name</SortHeaderComponent>
          <th>Business Name</th>
          <SortHeaderComponent ordering="email">Email</SortHeaderComponent>
          <th className="text-right">Phone Number</th>
          <th>Activation Status</th>   
          <th className="text-center">Show Codes</th>   
          <th className="text-center">Reset Password</th>    
          <SortHeaderComponent ordering="date_joined">Date Created</SortHeaderComponent>          
          
        </tr>
      </thead>
      <tbody>
        {data.results && data.results.map(item => (
          <tr key={item.id}>
            <td title={item.first_name} style={{width:'11%'}} className="pl-3">{item.first_name}</td>
            <td title={item.last_name} style={{width:'11%'}}>{item.last_name}</td>
            <td title={item.last_name}></td>
            <td title={item.email}>{item.email}</td>
            <td title={item.last_name} className="text-right"></td>
            {item.is_active ?
              <td>Yes</td>
              :
              <td>No</td>
            }
            <td title={item.last_name} className="text-center"><ViewIconAtom 
               className="cursor-pointer"
               onClick={() => toggle(item)}
            /></td>
            <td className="text-center">
              <ResetPasswordIconAtom
                className="cursor-pointer"
                onClick={() => toggleResetPasswordForm(item)}
              />
            </td>
            <td title={item.date_joined_display}>{item.date_joined_display}</td>
            
            
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
    </Table>
  );
};

IncompleteSignUpTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggleResetPasswordForm: PropTypes.func.isRequired,
  getActivateLink: PropTypes.func.isRequired,
};

export default IncompleteSignUpTableComponent;
