
const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
];

export const NAV = {

  DocumentsTypePage: {
    title: 'Document Setup',
    breadcrumb: [
      ...baseNav,
      { label: 'Document Setup' },
    ],
    parent: '/dashboard',
    
  },
  EDocsPage: {
    title: 'E-Docs Library',
    breadcrumb: [
      ...baseNav,
      { label: 'E-docs' },
    ],
    parent: '/dashboard',
    
  },
  TrainingPage: {
    title: 'Training Forms',
    breadcrumb: [
      ...baseNav,
      { label: 'Training Forms' },
    ],
    parent: '/dashboard',
  },
 
  
};




export const TABS = [
  { key: 'documentTypes', label: 'Document Bundles' },
  { key: 'eDocs', label: 'E-Doc Library'},
];


export default { NAV,  TABS, };
