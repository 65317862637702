import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as RoleDucks from 'ducks/vendors/role';
import * as GroupDucks from 'ducks/vendors/group';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import VendorRoleListTableComponent from 'components/vendors/roleList/Table';

import { MSGS } from 'constants/vendors';

/**
 * VendorRoleListPage -> VendorRoleListSection
 *
 * Components:
 *    - {@link VendorRoleListTableComponent}
 *    - {@link VendorRoleListFormModalComponent}
 *
 * State:
 *    - RoleSetting
 *
 * Actions:
 *    None
 */
class VendorRoleListSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false};
    this.deleteRole = this.deleteRole.bind(this);
  }

  deleteRole(data) {
    const { router: {  history } } = this.context;
    return this.props.RoleDucks.deleteRole(data)
      .then(() => {
        toast.success(MSGS.VENDOR_ROLE_DELETE_SUCCESS);
        history.push('/vendor/role-setting');
      })
      .catch((error) => {
        toast.error(error.non_field_errors[0]);
      });
  }

  render() {
    const { isLoading, location, roleList, groupList } = this.props;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <VendorRoleListTableComponent
          data={roleList}
          deleteRoleData={this.deleteRole}
          groupList={groupList}
          location={location}
          saveRoleOrderPreference={this.props.saveRoleOrderPreference}
          rolesIds={this.props.rolesIds}
          getRoleIds={this.props.getRoleIds}
        />
        <PaginationComponent
          count={roleList.count}
          location={location}
          cookieKey="role_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  roleList: RoleDucks.roleList(state),
  groupList: GroupDucks.groupList(state),
});

const mapActionsToProps = dispatch => ({
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
});

VendorRoleListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  roleList: PropTypes.object.isRequired,
  roleDetail: PropTypes.object.isRequired,
  groupList: PropTypes.array.isRequired,
};

VendorRoleListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorRoleListSection);
