import React from 'react';

import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import GreenCheckbox from './Checkbox_Green.png';
import RedCheckbox from './Checkbox_Red.png';


class PasswordStrengthMeterComponent  extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render(){
    const { values } = this.props;
    const CharacterLengthCheck = values && values.password && (values.password).length >= 8;
    const CapitalLetterCheck = (/[A-Z]/.test(values.password));
    const LowerCaseCheck = (/[a-z]/.test(values.password));
    const NumberCheck = (/[0-9]/.test(values.password));
    const passwordRepeated = '^.*(?=.*([a-zA-Z0-9])\\1\\1).*$';
    const passwordSequential = '^.*(?=.*(ABC|BCD|CDE|DEF|EFG|FGH|GHI|HIJ|IJK|JKL|KLM|LMN|MNO|NOP|OPQ|PQR|QRS|RST|STU|TUV|UVW|VWX|WXY|XYZ|abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)).*$';
    const SequentialValuesCheck = values && values.password && (values.password).match(passwordSequential);
    const RepeatingValuesCheck = values && values.password && (values.password).match(passwordRepeated);
    return (
      <div className="validations-box">
        <div className={`text-center ${styles['heading']}`}>Password should meet the following requirements:</div>
        <div className={`${styles['check-text']}`}> 
          {CharacterLengthCheck ? <img src={GreenCheckbox}/> : <img src={RedCheckbox}/>}  
          <div>At least 8 characters</div>
        </div>
        <div className={`${styles['check-text']}`}>
           {CapitalLetterCheck ? <img src={GreenCheckbox}/> : <img src={RedCheckbox}/>}  
           <div>At least one capital letter</div>
        </div>
        <div className={`${styles['check-text']}`}>
           {LowerCaseCheck ? <img src={GreenCheckbox}/> : <img src={RedCheckbox}/>}  
           <div>At least one lower case letter</div>
        </div>
        <div className={`${styles['check-text']}`}>
           {NumberCheck ? <img src={GreenCheckbox}/> : <img src={RedCheckbox}/>}  
           <div>At least one number</div>
        </div>
         <div className={`${styles['check-text']}`}>
          {RepeatingValuesCheck ? <img src={RedCheckbox}/> : <img src={GreenCheckbox}/>}  
          <div>No more than 3 repeating characters</div>
        </div>
        <div className={`${styles['check-text']}`}>
          {SequentialValuesCheck ? <img src={RedCheckbox}/> : <img src={GreenCheckbox}/>}
           <div>No more than 3 sequential characters</div>
        </div>
      </div>
    );
  }
};

PasswordStrengthMeterComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

PasswordStrengthMeterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PasswordStrengthMeterComponent;

