import React from 'react';

import PropTypes from 'prop-types';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';

import styles from './styles.module.scss';

const  ViewModalComponent = (props) => {
  const { isModalOpen, toggle, image } = props;
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        toggle={toggle}
        className={styles['video-modal-width']}
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className={styles['image-model-header']}/>
        <ModalBody>
          {(image) 
            ?<div className={`${styles['image-container']} d-flex justify-content-center`} >
              <img
                src={image}
                alt=''
                className={styles['image-preview']}
              />
            </div>
            : null
          }
        </ModalBody>
      </Modal>
    </div>  
  );
};



ViewModalComponent.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  image: PropTypes.object,
};

export default ViewModalComponent;