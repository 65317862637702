class GraniteError extends Error {
  constructor(message, api_error = false) {
    super(message);
    this.name = this.constructor.name;
    this.stack = new Error(message).stack;
    this.errors = message;
  }
}

export default GraniteError;
