import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import cookie from 'react-cookies';

import Form from 'reactstrap/lib/Form';

import InputAtom from 'atoms/Input';

import { FILTER_FORM_LAYOUT } from 'constants/layout';
import {ReactComponent as ClearIcon} from './close.svg';
import {ReactComponent as SearchIcon} from './search.svg';

import styles from './styles.module.scss';


class JobFilterFormComponent extends React.PureComponent {

  constructor(props){
    super(props);
    this.clear = this.clear.bind(this);
    this.submit = this.submit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  clear() {
    const { router: { history } } = this.context;
    const { location: { pathname, search }, change, id, recurringSearchCookieKey, projectSearchCookie, materialSearchCookie } = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    delete rest.search;
    if(id){ 
      if(id==="recurring_search"){  
        cookie.save(recurringSearchCookieKey, '', { path: '/' });
        change('recurring_search', null);  
        delete rest.recurring_search;
      }else if(id==="project_search"){  
        cookie.save(projectSearchCookie, '', { path: '/' });
        change('project_search', null);  
         delete rest.project_search;
      }else if(id==="material_search"){ 
        cookie.save(materialSearchCookie, '', { path: '/' });
        change('material_search', null); 
         delete rest.material_search;
      } 
    }else{  
      change('search', null); 
    }
    /*change('search', null);*/
    history.push({ pathname, search: queryString.stringify(rest), id: id});
  }

  submit(data){
    const { router: { history } } = this.context;
    const { location: { pathname, search } , id, recurringSearchCookieKey, projectSearchCookie, materialSearchCookie} = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    let query_search; 
    if(id){ 
      if(id==="recurring_search"){  
        query_search = data.recurring_search;
        cookie.save(recurringSearchCookieKey, query_search, { path: '/' });
        history.push({
          pathname, search : queryString.stringify({ ...rest, recurring_search: query_search }), id:id
        });
      }else if(id==="project_search"){  
        query_search = data.project_search;
        cookie.save(projectSearchCookie, query_search, { path: '/' });
        history.push({
          pathname, search : queryString.stringify({ ...rest, project_search: query_search }), id:id
        });
      }else if(id==="material_search"){ 
        query_search = data.material_search;
        cookie.save(materialSearchCookie, query_search, { path: '/' });
        history.push({
          pathname, search : queryString.stringify({ ...rest, material_search: query_search }), id:id
        }); 
      } 
    }else{  
      query_search = data.search; 
      history.push({
        pathname, search: queryString.stringify({ ...rest, search: query_search }), id:id
      });
    }
  }

  clearSearch(e,value){
    const { router: { history } } = this.context;
    const { location: { pathname, search }, id, recurringSearchCookieKey, projectSearchCookie, materialSearchCookie} = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    if(!value) {
      if(id){ 
      if(id==="recurring_search"){  
        cookie.save(recurringSearchCookieKey, value, { path: '/' });
        history.push({
        pathname, search: queryString.stringify({ ...rest, recurring_search: value }), id: id
      });
      }else if(id==="project_search"){  
        cookie.save(projectSearchCookie, value, { path: '/' });
        history.push({
        pathname, search: queryString.stringify({ ...rest, project_search: value }), id: id
      });
      }else if(id==="material_search"){ 
        cookie.save(materialSearchCookie, value, { path: '/' });
        history.push({
        pathname, search: queryString.stringify({ ...rest, material_search: value }), id: id
      });
      } 
    }
    else{  
       history.push({
        pathname, search: queryString.stringify({ ...rest, search: value }), id: id
      });
    }
  }
  }


  render(){
    const {  placeholder, search , handleSubmit, id , recurring_search , project_search, material_search} = this.props;
    return (
      <Form inline onSubmit={handleSubmit(this.submit)} className="search">
        <div className="input-group">
          <Field
            component={InputAtom}
            id={id || "search"}
            size={FILTER_FORM_LAYOUT.size}
            name={id || "search"}
            placeholder={placeholder}
            prefix={<span className={styles['search-allign']}  role="button" tabIndex={0} onClick={handleSubmit(this.submit)}> <SearchIcon width={16} height={15} className={styles['search-Icon']}/></span>}
            onChange={this.clearSearch}
          />
          {((id == 'recurring_search' && recurring_search ) || (id=='project_search' && project_search ) || (id == 'material_search' && material_search)) &&
            <ClearIcon
              className={styles['input-icon-clear']}
              height="13" width="13"
              onClick={this.clear}
            />
          }

        </div>
      </Form>
    );
  }
}

JobFilterFormComponent.defaultProps = {
  placeholder: "Type to search",
};

JobFilterFormComponent.propTypes = {
  location: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

JobFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const jobFilterForm = reduxForm({ 
  form: `JobFilterForm`,  
  enableReinitialize: true,
})(JobFilterFormComponent);

const selector = formValueSelector('JobFilterForm');

export default connect( 
  (state) => {  
    const search = selector(state, 'search'); 
    const recurring_search = selector(state, 'recurring_search'); 
    const project_search = selector(state, 'project_search'); 
    const material_search = selector(state, 'material_search'); 
    return { search , recurring_search , project_search , material_search };  
  },  
)(jobFilterForm);
