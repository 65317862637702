import { createSelector } from 'reselect';
import StorageGateway from 'lib/storage-gateway';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';
import cookie from 'react-cookies';

// ActionsNames - <VERB>/<VERB>_<NOUN>
const LOGIN = 'accounts/user/LOGIN';
const LOGIN_FULFILLED = 'accounts/user/LOGIN_FULFILLED';
const OAUTH_LOGIN = 'accounts/user/OAUTH_LOGIN';
const OAUTH_LOGIN_FULFILLED = 'accounts/user/OAUTH_LOGIN_FULFILLED';
const REGISTER = 'accounts/user/REGISTER';
const REQUEST_PASSWORD_RESET = 'accounts/user/REQUEST_PASSWORD_RESET';
const REGISTER_FULFILLED = 'accounts/user/REGISTER_FULFILLED';
const ACTIVATE = 'accounts/user/ACTIVATE';
const RESET_PASSWORD = 'accounts/user/RESET_PASSWORD';
const CHANGE_PASSWORD = 'accounts/user/CHANGE_PASSWORD';
const CHANGE_EMAIL = 'accounts/user/CHANGE_EMAIL';
const LOGOUT = 'accounts/user/LOGOUT';
const LOGOUT_FULFILLED = 'accounts/user/LOGOUT_FULFILLED';
const RESET_STATE = 'accounts/user/RESET_STATE';
const GET_AUTH_TOKEN = 'accounts/user/GET_AUTH_TOKEN';
const GET_AUTH_TOKEN_FULFILLED = 'accounts/user/GET_AUTH_TOKEN_FULFILLED';
const GET_PROFILE = 'accounts/user/GET_PROFILE';
const GET_PROFILE_FULFILLED = 'accounts/user/GET_PROFILE_FULFILLED';
const GET_EXTRA_DATA = 'accounts/user/GET_EXTRA_DATA';
const GET_EXTRA_DATA_FULFILLED = 'accounts/user/GET_EXTRA_DATA_FULFILLED';
const PUT_PROFILE = 'accounts/user/PUT_PROFILE';
const PUT_PROFILE_FULFILLED = 'accounts/user/PUT_PROFILE_FULFILLED';
const GET_ACCESS = 'accounts/user/GET_ACCESS';
const GET_ACCESS_FULFILLED = 'accounts/user/GET_ACCESS_FULFILLED';
const POST_OTP_ACTIVATION = 'accounts/user/POST_OTP_ACTIVATION';
const POST_OTP_ACTIVATION_FULFILLED = 'accounts/user/POST_OTP_ACTIVATION_FULFILLED';
const GET_CONTEXTS = 'accounts/user/GET_CONTEXTS';
const GET_CONTEXTS_FULFILLED = 'accounts/user/GET_CONTEXTS_FULFILLED';
const VERIFY_OTP = 'accounts/user/VERIFY_OTP';
const VERIFY_OTP_FULFILLED = 'accounts/user/VERIFY_OTP_FULFILLED';
const GET_OTP = 'accounts/user/GET_OTP';
const GET_OTP_FULFILLED = 'accounts/user/GET_OTP_FULFILLED';
const UPDATE_ORDER_USER_PREFERENCE = 'accounts/user/UPDATE_ORDER_USER_PREFERENCE';
const DO_NOT_SHOW_PTO_WARNING = 'accounts/user/DO_NOT_SHOW_PTO_WARNING';

// Action creator name: <VERB><NOUN>
export function putDontShowPTOWarning(data) {
  const {userId, check} = data;
  return {
    type: DO_NOT_SHOW_PTO_WARNING,
    payload: axios.put(URLS.DONT_SHOW_PTO_WARNING+userId+"/show-warning/", {do_not_show_pto_warning: check}),
  };
}

export function putDontShowPTOWarningModal({userId, check}) {
  return {
    type: DO_NOT_SHOW_PTO_WARNING,
    payload: axios.put(URLS.DONT_SHOW_PTO_WARNING+userId+"/show-warning/", {do_not_show_pto_warning: check}),
  };
}

export function postLoginUser(data) {
  return {
    type: LOGIN,
    payload: axios.post(URLS.LOGIN, data),
  };
}

export function postOauthLoginUser(data) {
  return {
    type: OAUTH_LOGIN,
    payload: axios.post(
      URLS.OAUTH_LOGIN,
      data,
    ),
  };
}

export function postRegisterUser(data) {
  return {
    type: REGISTER,
    payload: axios.post(URLS.REGISTER, data),
  };
}

export function postActivateUser(data) {
  return {
    type: ACTIVATE,
    payload: axios.post(URLS.ACTIVATE, data),
  };
}

export function postRequestPasswordResetUser(data) {
  return {
    type: REQUEST_PASSWORD_RESET,
    payload: axios.post(URLS.REQUEST_PASSWORD_RESET, data),
  };
}

export function postResetPasswordUser(data) {
  return {
    type: RESET_PASSWORD,
    payload: axios.post(URLS.RESET_PASSWORD, data),
  };
}

export function postChangePasswordUser(data) {
  return {
    type: CHANGE_PASSWORD,
    payload: axios.post(URLS.CHANGE_PASSWORD, data),
  };
}

export function postChangeEmailUser(data) {
  return {
    type: CHANGE_EMAIL,
    payload: axios.post(URLS.CHANGE_EMAIL, data),
  };
}

export function postLogoutUser() {
  return {
    type: LOGOUT,
    payload: axios.post(URLS.LOGOUT),
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function getProfileUser() {
  return {
    type: GET_PROFILE,
    payload: axios.get(URLS.PROFILE),
  };
}

export function getExtraData() {
  return {
    type: GET_EXTRA_DATA,
    payload: axios.get(URLS.EXTRA_DATA),
  };
}

export function putProfileUser(data) {
  return {
    type: PUT_PROFILE,
    payload: axios.put(URLS.PROFILE, data),
  };
}

export function getAccessUser() {
  return {
    type: GET_ACCESS,
    payload: axios.get(URLS.ACCESS),
  };
}

export function postOtpActivateUser(data) {
  return {
    type: POST_OTP_ACTIVATION,
    payload: axios.post(`${URLS.REQUEST_PASSWORD_RESET}${URLS.OTP_ACTIVATION}`, data),
  };
}




export function getContexts(params) {
  return {
    type: GET_CONTEXTS,
    payload: axios.get(`${URLS.ACCESS}${URLS.CONTEXT}/`, { params }),
  };
}

export function verifyOtp(data) {
  return {
    type: VERIFY_OTP,
    payload: axios.post(URLS.OTP_VERIFICATION, data),
  };
}


export function getAuthToken(params) {
  return {
    type: GET_AUTH_TOKEN,
    payload: axios.get(URLS.AUTH_TOKEN , { params }),
  };
}


export function getOtp(params) {
  return {
    type: GET_OTP,
    payload: axios.put(URLS.GET_OTP , { params }),
  };
}

export function saveUserOrderPreference(data){
  const {preference_type,...rest} = data;
  return {
    type: UPDATE_ORDER_USER_PREFERENCE,
    payload: axios.put(`${URLS.ORDER_USER_PREFERENCE}?preference_type=${preference_type}`, rest),
  };
}


// state name - <NOUN>
const defaultState = {
  authorization: '',
  profile: {},
  list: [],
  extra_data: {},
  access: { sidemenu: [], permissions: [] },
  otpInfo: {},
  joyRide: {
    isRunning: false,
    joyrideOverlay: true,
    joyrideType: 'continuous',
    selector: '',
    steps: [
      {
        text: 'You can edit profile here.',
        selector: '#sidenav-dropdown',
        position: 'right',
        style: {
          mainColor: '#162d6e',
          beacon: {
            inner: '#46B147',
            outer: '#46B147',
          },
        },
      }
    ]
  }
};

// Reducers - <NOUN>_<VERB>_REDUCER
function LOGIN_FULFILLED_REDUCER(state, action) {
/*  let domain = window.location.hostname;
  const host = window.location.hostname.split('.');
  if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;*/

  /* eslint-disable */
  // Authorization cookie added on root domain for report download directly from drf api
  // document.cookie = `Authorization=\"Token ${action.payload.auth_token}\";domain=${domain};path=/`;
  /* eslint-enable */
  return Object.assign({}, state, {
    authorization: action.payload.auth_token,
  });
}

function OAUTH_LOGIN_FULFILLED_REDUCER(state, action) {
/*  let domain = window.location.hostname;
  const host = window.location.hostname.split('.');
  if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;*/
  StorageGateway.set('oAuthToken', action.payload.access_token);
  /* eslint-disable */
  // Authorization cookie added on root domain for report download directly from drf api
  // document.cookie = `Authorization=\"Bearer ${action.payload.access_token}\";domain=${domain};path=/`;
  /* eslint-enable */
  return Object.assign({}, state, {
    authorization: action.payload.access_token,
  });
}

function REGISTER_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    authorization: action.data.token,
    profile: action.data,
  });
}

function LOGOUT_FULFILLED_REDUCER(state) {
	let domain = window.location.hostname;
	const host = window.location.hostname.split('.');
	if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
	//code to remove all the cookies

  document.cookie.split('; ').forEach(function(c) {
    if((c.trim().split('=')[0]).indexOf('isEmployeeNotes') == -1 && (c.trim().split('=')[0]).indexOf('isEmployeeDayNotWork') == -1 && (c.trim().split('=')[0]).indexOf('isCorrectionRequest') == -1 && (c.trim().split('=')[0]).indexOf('projectTableWidth') == -1 && (c.trim().split('=')[0]).indexOf('recurringTableWidth') == -1 && (c.trim().split('=')[0]).indexOf('materialTableWidth') == -1 && (c.trim().split('=')[0]).indexOf('tableView') == -1 && (c.trim().split('=')[0]).indexOf('isShowEmployeeListCookie') == -1){
      cookie.remove(c.trim().split('=')[0], { path: '/'});
    }
  });

  /*console.log('domain', document.cookie, domain)*/
  cookie.remove('Token', { path: '/' , domain});
  cookie.remove('shiftAuthToken', { path: '/' , domain});
  cookie.remove('oAuthToken', { path: '/' , domain});
  StorageGateway.remove('company_id');
  StorageGateway.remove('oAuthToken');
  StorageGateway.remove('uniqueUuid');
  StorageGateway.remove('graniteToken');
  StorageGateway.remove('userId');
  StorageGateway.remove('Token');
  StorageGateway.remove('pluginToken');
  StorageGateway.remove('company');

  return Object.assign({}, state, defaultState);
}

function GET_PROFILE_FULFILLED_REDUCER(state, action) {

  StorageGateway.set('uniqueUuid', action.payload.unique_uuid);
  return Object.assign({}, state, {
    profile: action.payload,
  });
}

function GET_EXTRA_DATA_FULFILLED_REDUCER(state, action) {
  let domain = window.location.hostname;
  const host = window.location.hostname.split('.');
  if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
  const cookieKey = cookie.load('shiftAuthToken');
  if(action.payload && action.payload.company_permissions && !action.payload.company_permissions.feedback && cookieKey){
    cookie.remove('shiftAuthToken', { path: '/' , domain});
  }
  return Object.assign({}, state, {
    extra_data: action.payload,
  });
}

function PUT_PROFILE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    profile: action.payload,
  });
}

function POST_OTP_ACTIVATION_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}

function GET_ACCESS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    access: action.payload,
  });
}

function GET_CONTEXTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_AUTH_TOKEN_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    authorization: action.payload,
  });
}

function VERIFY_OTP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}

function GET_OTP_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    otpInfo: action.payload,
  });
}

function RESET_STATE_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}


const handlers = {
  [LOGIN_FULFILLED]: LOGIN_FULFILLED_REDUCER,
  [REGISTER_FULFILLED]: REGISTER_FULFILLED_REDUCER,
  [LOGOUT_FULFILLED]: LOGOUT_FULFILLED_REDUCER,
  [OAUTH_LOGIN_FULFILLED]: OAUTH_LOGIN_FULFILLED_REDUCER,
  [GET_EXTRA_DATA_FULFILLED]: GET_EXTRA_DATA_FULFILLED_REDUCER,
  [GET_PROFILE_FULFILLED]: GET_PROFILE_FULFILLED_REDUCER,
  [PUT_PROFILE_FULFILLED]: PUT_PROFILE_FULFILLED_REDUCER,
  [POST_OTP_ACTIVATION_FULFILLED]: POST_OTP_ACTIVATION_FULFILLED_REDUCER,
  [GET_ACCESS_FULFILLED]: GET_ACCESS_FULFILLED_REDUCER,
  [GET_CONTEXTS_FULFILLED]: GET_CONTEXTS_FULFILLED_REDUCER,
  [GET_AUTH_TOKEN_FULFILLED]: GET_AUTH_TOKEN_FULFILLED_REDUCER,
  [GET_OTP_FULFILLED]: GET_OTP_FULFILLED_REDUCER,
  [RESET_STATE]: RESET_STATE_REDUCER,
  [VERIFY_OTP_FULFILLED] : VERIFY_OTP_FULFILLED_REDUCER
};


// Selectors
const userSelector = state => state.accounts.user;

export const profile = createSelector(
  userSelector,
  instance => instance.profile,
);

export const contextList = createSelector(
  userSelector,
  instance => instance.list,
);

export const userExtraData = createSelector(
  userSelector,
  instance => instance.extra_data,
);

export const userExtraCompanyData = createSelector(
  userExtraData,
  instance => instance.company,
);

export const isOrganizationVisible = createSelector(
  userExtraCompanyData,
  instance => instance.organization,
);

export const isGeoFenceVisible = createSelector(
  userExtraCompanyData,
  instance => instance.geo_fence_visible,
);

export const userTimezone = createSelector(
  profile,
  instance => instance.timezone,
);

export const userBATimezone = createSelector(
  profile,
  instance => instance.business_admin_timezone ?? instance.timezone,
);

export const otpInfo = createSelector(
  userSelector,
  instance => instance.otpInfo,
);

export const userFullName = createSelector(
  profile,
  instance => `${instance.first_name} ${instance.last_name ? instance.last_name : ''}`,
);

export const isBusinessAdmin = createSelector(
  profile,
  instance => instance.is_business_admin ? instance.is_business_admin : false,
);

export const isLoggedInAdmin = createSelector(
  profile,
  instance => instance.is_logged_in_admin,
);

export const userCompany = createSelector(
  profile,
  instance => instance.company ? instance.company : { },
);

export const defaultPayrollSetting = createSelector(
  profile,
  instance => instance.company && instance.company.default_payroll_settings,
);

export const isSubCompany = createSelector(
  userCompany,
  instance => instance.parent_company,
);

export const isPayrollCreated = createSelector(
  userCompany,
  instance => instance && instance.is_payroll ? instance.is_payroll : false,
);

export const userRole = createSelector(
  profile,
  instance => instance.role,
);

export const isCompanyName = createSelector(
  profile,
  (instance) => instance.company && instance.company.name ? instance.company.name : '',
);

export const accessPerms = createSelector(
  userSelector,
  instance => instance.access.permissions,
);

export const accessSidemenu = createSelector(
  userSelector,
  instance => instance.access.sidemenu,
);

export const companyPermissions = createSelector(
  userExtraData,
  instance => instance.company_permissions,
);

export const joyRidePerms = createSelector(
  userSelector,
  accessPerms,
  (instance, perms) => {
    const obj = JSON.parse(JSON.stringify(instance.joyRide));
    const accessIdArray = [ 'activity', 'timecard', 'employee' ];
    const defaultStyle = {
      position: 'right',
      style: {
        mainColor: '#162d6e',
        beacon: {
          inner: '#46B147',
          outer: '#46B147',
        },
      }
    };

    accessIdArray.filter((item) => {
      if (perms.indexOf(item) >= 0) {
        if (item === 'employee') {
          obj.steps.splice(0, 0, {
            text: 'Here you can add employees.',
            selector: `#${item}\\.list`,
            ...defaultStyle,
          });
        } else if (item === 'timecard') {
          obj.steps.splice(0, 0, {
            text: 'View employee timecards here.',
            selector: `#${item}\\.list`,
            ...defaultStyle,
          });
        } else if (item === 'activity') {
          obj.steps.splice(0,0,{
            text: 'Track the employee activities here.',
            selector: `#${item}`,
            ...defaultStyle,
          });
        }
      }
      return obj.steps;
    });

    return obj;
  },
);

export const authorization = createSelector(
  userSelector,
  instance => instance.authorization,
);
// export reducers as default
export default createReducer(defaultState, handlers);
