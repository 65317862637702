import React from 'react';
import {toast} from 'react-toastify';

import StorageGateway from 'lib/storage-gateway';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import VendorTableComponent from 'components/vendors/list/Table';

/**
 * VendorListPage -> VendorListSection
 *
 * Components:
 *    - {@link VendorTableComponent}
 *
 * State:
 *    - vendorList
 *
 * Actions:
 *    None
 */
class VendorListSection extends React.Component {
  static goTo(data) {
    let domain = window.location.hostname;
    const host = window.location.hostname.split('.');
    if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
    StorageGateway.set('company_id', data.id);
    cookie.save('company_id', data.id, { path: '/' , domain});  
    cookie.remove('payrollId', { path: '/' });
    cookie.remove('startDate', { path: '/' });
    cookie.remove('endDate', { path: '/' });
    cookie.remove('departmentId', { path: '/' });
    cookie.remove('teamId', { path: '/' });
    setTimeout(() => {
      window.location.href = '/dashboard';
    });
  }

  constructor(props) {
    super(props);
    this.updateSelected = this.updateSelected.bind(this);
    this.getStats = this.getStats.bind(this);
    this.approvePlanChange = this.approvePlanChange.bind(this);
    this.rejectPlanChange = this.rejectPlanChange.bind(this);
  }

  approvePlanChange(id){
    const { location: { query } } = this.props;
    let detail;
   /* if(query.search){
      const { search, ...rest } = query;
      detail = { ...rest};
    }*/
    detail ={...query};
    const pageSize = cookie.load('vendor_page_size');    
    return this.props.VendorDucks.planChange(id)
    .then(() => {
        this.props.VendorDucks.getVendors({...detail, page_size: pageSize})
      })
      .catch((e) => {
        /*this.props.VendorDucks.getVendors({...query, page_size:pageSize})*/
        toast.error('Cannot approve cancellation since payment is due');
    });

  }

  rejectPlanChange(id){
      const { location: { query } } = this.props;
      let detail;
      /*if(query.search){
        const { search, ...rest } = query;
        detail = { ...rest};
      }
      else*/ detail ={...query};
      const pageSize = cookie.load('vendor_page_size');
      const data = {'company_id' : id, 'in_progress': false};
      return this.props.VendorDucks.planReject(data)
       .then(() => {
        this.props.VendorDucks.getVendors({...detail, page_size: pageSize})
      })
      .catch((e) => {
        this.props.VendorDucks.getVendors({...detail, page_size:pageSize})
    });
  }



  getStats(data){
    return this.props.VendorDucks.getStats(data);
  }

  updateSelected(data) {
    return this.props.VendorDucks.selectVendors(data);
  }

  render() {
    const { isLoading, vendorList, setAttributeToggler, attributeToggler} = this.props;
    let newVendorList = vendorList;
    /*if(query.search && vendorList.length){
      newVendorList = vendorList.filter(item => {
        const name = item.name.toUpperCase();
        if(name.indexOf(query.search.toUpperCase()) > -1){
          return item;
        }
        return null;
      });
    }*/
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="pl-3 pr-4">
        <VendorTableComponent
          location = {this.props.location}
          data={newVendorList}
          goTo={this.constructor.goTo}
          updateSelected={this.updateSelected}
          getStats={this.getStats}
          approvePlanChange= {this.approvePlanChange}
          rejectPlanChange= {this.rejectPlanChange}
          setAttributeToggler = {setAttributeToggler}
          attributeToggler = {attributeToggler}
        />
      </section>  
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  vendorList: VendorDucks.vendorList(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

VendorListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  vendorList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorListSection);

