import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import SelectAtom from 'atoms/Select';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';
import closeimage from 'components/vendors/CloseCross.png';

/**
 *  * VendorDepartmentListPage -> VendorDepartmentFilterSection -> VendorDepartmentFormModalComponent
 *
 * Props:
 *    - Add Or Update Department
 */
const VendorDepartmentFormModalComponent = (props) => {
  const {
    error,
    isOpen,
    submitting,
    handleSubmit,
    toggleDeptForm,
    initialValues,
    createOrUpdate,
    payrollSettingAll,
    payrollAll
  } = props;
  const submit = (data) => {
    const detail = { ...data };
    if(!detail.is_active) detail.is_active = false;
    if(Object.keys(detail.payroll_setting).length) detail.payroll_setting  = data.payroll_setting && data.payroll_setting.id;
    return createOrUpdate(detail, "department")
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleDeptForm} className="form-modal" backdrop="static">
      <ModalHeader>
        {initialValues && initialValues.id ? "Edit Department" : "Add Department"}
        <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggleDeptForm()} />
      </ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate className="ml-4 mr-4 pl-1 pr-2 pb-2">
        <div className="error-text ml-2">{error}</div>
        <ModalBody>
          <Field
            id="name"
            name="name"
            label="Department Name"
            component={InputAtom}
            placeholder="Department Name"
            layout={FORM_LAYOUT}
          />
          <Field
            id="department_payroll"
            name="department_payroll"
            label="Payroll Name"
            component={SelectAtom}
            placeholder="Payroll Name"
            options={payrollAll}
            labelKey="name"
            valueKey="id"
            layout={FORM_LAYOUT}
            simpleValue
          />
          {payrollSettingAll && payrollSettingAll.length > 0 &&
            <Field
              id="payroll_setting"
              name="payroll_setting"
              label="Payroll Setting"
              component={SelectAtom}
              placeholder="Payroll Setting"
              options={payrollSettingAll}
              labelKey="name"
              valueKey="id"
              layout={FORM_LAYOUT}
              simpleValue
            />
          }
          <Field
            id="description"
            name="description"
            label="Department description"
            component={InputAtom}
            placeholder="Department description (Optional)"
            layout={FORM_LAYOUT}
          />
          <Field
            id="is_active"
            type="checkbox"
            name="is_active"
            label="Active?"
            component={CheckboxAtom}
            layout={FORM_LAYOUT}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Name';
  if (!values.payroll_setting) errors.payroll_setting = 'Select Payroll Setting';
  return errors;
};

VendorDepartmentFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleDeptForm: PropTypes.func.isRequired,
  payrollSettingAll: PropTypes.array.isRequired,
  payrollAll: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'VendorDepartmentFormModalComponent',
  validate,
})(VendorDepartmentFormModalComponent);
