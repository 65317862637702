import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const TabsComponent = (props) => {
  const { tabItems, onClick, active } = props;
  return (
    <div className={styles['div-menu']}>
      {tabItems.map(item =>
        <span
          key={item.key}
          role="button"
          tabIndex="0"
          onClick={() => onClick(item.key)}
          className={active === item.key ? styles['span-active'] : styles['span-other'] }
        >
          {item.label}
        </span>
      )}
    </div>
  );
};

TabsComponent.propTypes = {
  tabItems: PropTypes.array.isRequired,
};

export default TabsComponent;
