import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import queryString from "query-string";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import { toast } from "react-toastify";
import cookie from "react-cookies";
import { exportFile, exportTextFile } from "helpers/utils";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";

import { UncontrolledTooltip } from "reactstrap";
import Button from "reactstrap/lib/Button";

import * as BasicSettingDucks from "ducks/vendors/basicSetting";
import * as DepartmentDucks from "ducks/vendors/department";
import * as PayrollDucks from "ducks/payrolls/payroll";
import * as RouteDucks from "ducks/routes";
import * as SystemSettingDucks from "ducks/vendors/systemSetting";
import * as TeamDucks from "ducks/vendors/team";
import * as UserDucks from "ducks/accounts/user";
import * as TimecardDucks from "ducks/timecards/timecard";

import FilterDropdownComponent from "components/common/FilterDropdown";
import FilterFormComponent from "components/common/FilterForm";
import CustomTimecardFilterFormComponent from "components/timecards/customList/CustomTimecardFilterForm";
import ExportModalComponent from "components/timecards/customList/ExportModal";
import ButtonLoaderAtom from "atoms/ButtonLoader";
import ButtonGroupAtom from "atoms/ButtonGroup";
import showHideIcon from "components/timecards/customList/showHideIcon.png";
import FormikMultiSelectAtom from "atoms/FormikMultiselectDropdown";

import { DECIMAL_VIEW_OPTIONS } from "constants/timecards";

import styles from "./styles.module.scss";

/**
 * TimecardListPage -> TimecardFilterSection
 *
 * Components:
 *    - {@link FilterDropdownComponent}
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - putBasicSetting
 */

let team_ids = [];
let dept_ids = [];
let null_depts = [];
let combinedDepts = [];
class CustomTimecardFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.exportCsv = this.exportCsv.bind(this);
    this.exportPdfandXls = this.exportPdfandXls.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.showManualCorrection = this.showManualCorrection.bind(this);
    this.showExtraInformation = this.showExtraInformation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.createTimecard = this.createTimecard.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.toggleExportModal = this.toggleExportModal.bind(this);
    this.exportJobPayroll = this.exportJobPayroll.bind(this);
    this.exportDataTech = this.exportDataTech.bind(this);
    this.exportIif = this.exportIif.bind(this);
    this.exportJobPayrollDetail = this.exportJobPayrollDetail.bind(this);
    this.onTeamSelect = this.onTeamSelect.bind(this);
    this.exportADP = this.exportADP.bind(this);
    this.exportPayDatacsv = this.exportPayDatacsv.bind(this);
    this.exportmileagecsv = this.exportmileagecsv.bind(this);
    this.exportSouthEast = this.exportSouthEast.bind(this);
    this.toggleJobCorrectionCheckbox = this.toggleJobCorrectionCheckbox.bind(
      this
    );
    this.toggleEmployeeCheckbox = this.toggleEmployeeCheckbox.bind(this);
    this.exportSelectedFile = this.exportSelectedFile.bind(this);
    this.exportSage = this.exportSage.bind(this);
    this.exportiSolved = this.exportiSolved.bind(this);
    this.exportMikeRovnerCsv = this.exportMikeRovnerCsv.bind(this);
    this.exportMealsFlagExport = this.exportMealsFlagExport.bind(this);
    this.exportFenceFactory = this.exportFenceFactory.bind(this);
    this.exportPtoBalance = this.exportPtoBalance.bind(this);
    this.exportPaylocity = this.exportPaylocity.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.famousExport = this.famousExport.bind(this);
    this.mmchrExport = this.mmchrExport.bind(this);
    this.state = {
      isExportLoading: false,
      isModalOpen: false,
      attributeToggler: false,
      jobCosting: null,
      isHideDecimalConversion: false,
      isHideHHMMConversion: false,
      selectedDepts: [],
      selectedTeams: [],
      isSupervisorSignature: false,
    };
  }

  componentWillMount() {
    const {
      location: { query },
    } = this.props;
    const cookieDepartmentId = cookie.load("departmentId");
    const cookieTeamId = cookie.load("teamId");
    const nullDept = cookie.load("nullDept");
    let null_id = nullDept ? nullDept : query?.null_department;
    let department_ids = cookieDepartmentId
      ? cookieDepartmentId
      : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;

    let departmentIdsArray;
    let teamIdsArray;
    if (department_ids) {
      if (Array.isArray(department_ids)) {
        departmentIdsArray = department_ids;
      } else {
        departmentIdsArray = [department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    if (teams_ids) {
      if (Array.isArray(teams_ids)) {
        teamIdsArray = teams_ids;
      } else {
        teamIdsArray = [teams_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));
    let combinedDepartments = [
      ...(departmentIdsIntegers || []),
      ...(null_id || []),
    ];

    this.setState({
      selectedDepts: combinedDepartments?.length ? combinedDepartments : [],
      selectedTeams: teamIdsIntegers?.length ? teamIdsIntegers : [],
    });
    this.combinedDepts = combinedDepartments?.length ? combinedDepartments : [];
    this.team_ids = teamIdsIntegers?.length ? teamIdsIntegers : [];
    this.dept_ids = combinedDepartments?.length ? combinedDepartments : [];
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({ paginate: false }),
      this.props.SystemSettingDucks.getSystemSettings(),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        department_ids: JSON.stringify(departmentIdsIntegers),
      }),
    ]);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { query },
    } = this.props;
    const cookieDepartmentId = cookie.load("departmentId");
    const cookieTeamId = cookie.load("teamId");
    const nullDept = cookie.load("nullDept");

    let null_id = nullDept ? nullDept : query?.null_department;
    let department_ids = cookieDepartmentId
      ? cookieDepartmentId
      : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;

    if (
      prevProps.departmentAll !== this.props.departmentAll &&
      query &&
      !query.department_ids?.length
    ) {
      let departmentIdsArray;
      if (department_ids) {
        if (Array.isArray(department_ids)) {
          departmentIdsArray = department_ids;
        } else {
          departmentIdsArray = [department_ids];
        }
      } else {
        departmentIdsArray = [];
      }
      const departmentIdsIntegers = departmentIdsArray.map((id) =>
        parseInt(id, 10)
      );
      let combinedDepartments = [
        ...(departmentIdsIntegers || []),
        ...(null_id || []),
      ];

      this.setState({
        selectedDepts: combinedDepartments?.length ? combinedDepartments : [],
      });
      this.combinedDepts = combinedDepartments?.length
        ? combinedDepartments
        : [];
      this.dept_ids = combinedDepartments?.length ? combinedDepartments : [];
    }

    if (
      prevProps.teamAll !== this.props.teamAll &&
      query &&
      !query.team_ids?.length
    ) {
      let teamIdsArray;

      if (teams_ids) {
        if (Array.isArray(teams_ids)) {
          teamIdsArray = teams_ids;
        } else {
          teamIdsArray = [teams_ids];
        }
      } else {
        teamIdsArray = [];
      }
      const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));
      this.setState({
        selectedTeams: teamIdsIntegers?.length ? teamIdsIntegers : [],
      });
      this.team_ids = teamIdsIntegers?.length ? teamIdsIntegers : [];
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { query: nextQuery },
    } = nextProps;
    const {
      location: { query: prevQuery },
      departmentAll,
      teamAll,
    } = this.props;

    if (
      prevQuery !== nextQuery &&
      (nextQuery.department_ids || nextQuery.null_department)
    ) {
      const cookieDepartmentId = cookie.load("departmentId");
      const cookieTeamId = cookie.load("teamId");
      const nullDept = cookie.load("nullDept");
      let null_id = nullDept
        ? nullDept
        : nextQuery?.null_department === "true"
        ? ["true"]
        : [];
      let department_ids = cookieDepartmentId
        ? cookieDepartmentId
        : nextQuery?.department_ids;
      let teams_ids = cookieTeamId ? cookieTeamId : nextQuery?.team_ids;

      let departmentIdsArray;
      if (department_ids) {
        if (Array.isArray(department_ids)) {
          departmentIdsArray = department_ids;
        } else {
          departmentIdsArray = [department_ids];
        }
      } else {
        departmentIdsArray = [];
      }
      const departmentIdsIntegers = departmentIdsArray.map((id) =>
        parseInt(id, 10)
      );
      let combinedDepartments = [
        ...(departmentIdsIntegers || []),
        ...(null_id || []),
      ];
      this.setState({
        selectedDepts: combinedDepartments?.length ? combinedDepartments : [],
      });
      this.combinedDepts = combinedDepartments?.length
        ? combinedDepartments
        : [];
      this.dept_ids = combinedDepartments?.length ? combinedDepartments : [];

      let teamIdsArray;

      if (teams_ids) {
        if (Array.isArray(teams_ids)) {
          teamIdsArray = teams_ids;
        } else {
          teamIdsArray = [teams_ids];
        }
      } else {
        teamIdsArray = [];
      }
      const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));
      this.setState({
        selectedTeams: teamIdsIntegers?.length ? teamIdsIntegers : [],
      });
      this.team_ids = teamIdsIntegers?.length ? teamIdsIntegers : [];

      this.props.TeamDucks.getAllTeams({
        paginate: false,
        department_ids: JSON.stringify(departmentIdsIntegers),
      });
    }
  }

  toggleJobCorrectionCheckbox(id) {
    this.setState({ jobCosting: id });
  }

  toggleEmployeeCheckbox(param) {
    const isEmployeeNotes =
      cookie.load("isEmployeeNotes") && cookie.load("isEmployeeNotes") == "true"
        ? true
        : false;
    const isEmployeeDayNotWork =
      cookie.load("isEmployeeDayNotWork") &&
      cookie.load("isEmployeeDayNotWork") == "true"
        ? true
        : false;
    const isCorrectionRequest =
      cookie.load("isCorrectionRequest") &&
      cookie.load("isCorrectionRequest") == "true"
        ? true
        : false;
    const isHideDecimalConversion =
      cookie.load("isHideDecimalConversion") &&
      cookie.load("isHideDecimalConversion") == "true"
        ? true
        : false;
    const isHideHHMMConversion =
      cookie.load("isHideHHMMConversion") &&
      cookie.load("isHideHHMMConversion") === "true"
        ? true
        : false;
    const isOrgInfo =
      cookie.load("isOrgInfo") && cookie.load("isOrgInfo") == "true"
        ? true
        : false;
    const isPageNumber =
      cookie.load("isPageNumber") && cookie.load("isPageNumber") === "true"
        ? true
        : false;
    const isSupervisorSignature =
      cookie.load("isSupervisorSignature") &&
      cookie.load("isSupervisorSignature") === "true"
        ? true
        : false;

    if (param == "job_name") {
      cookie.save("isEmployeeNotes", !isEmployeeNotes);
      this.setState({ isEmployeeNotes: !isEmployeeNotes });
    } else if (param == "days_not_worked") {
      cookie.save("isEmployeeDayNotWork", !isEmployeeDayNotWork);
      this.setState({ isEmployeeDayNotWork: !isEmployeeDayNotWork });
    } else if (param == "correction_request") {
      cookie.save("isCorrectionRequest", !isCorrectionRequest);
      this.setState({ isCorrectionRequest: !isCorrectionRequest });
    } else if (param == "disable_decimal_row") {
      cookie.save("isHideDecimalConversion", !isHideDecimalConversion);
      this.setState({ isHideDecimalConversion: !isHideDecimalConversion });
    } else if (param === "disable_hh_mm_row") {
      cookie.save("isHideHHMMConversion", !isHideHHMMConversion);
      this.setState({ isHideHHMMConversion: !isHideHHMMConversion });
    } else if (param == "org_info") {
      cookie.save("isOrgInfo", !isOrgInfo);
      //this.setState({ isOrgInfo : !isOrgInfo });
    } else if (param === "include_page_number") {
      cookie.save("isPageNumber", !isPageNumber);
      this.setState({ isPageNumber: !isPageNumber });
    } else if (param === "add_supervisor_signature") {
      cookie.save("isSupervisorSignature", !isSupervisorSignature);
      this.setState({ isSupervisorSignature: !isSupervisorSignature });
    }
  }

  onDepartmentSelect(data) {
    this.null_depts = data
      .filter((item) => item.id === "true")
      .map((item) => item.id);
    this.dept_ids = data
      .filter((item) => item.id !== "true")
      .map((item) => item.id);
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const newQuery = { ...query };
    delete newQuery.team_ids;
    delete newQuery.department_id;
    if (newQuery.payroll_id !== "custom") {
      delete newQuery.payroll_id;
      delete newQuery.start_date;
      delete newQuery.end_date;
    }
    let team;
    this.props.TeamDucks.getAllTeams({
      department_ids: JSON.stringify(this.dept_ids),
      paginate: false,
    }).then((data) => {
      team = data.value.map((id) => id.id);
      if (team?.length === 0) {
        this.team_ids = [];
        this.setState({ selectedTeams: [] });
      }
    });
    if (this.dept_ids?.length === 0) {
      this.setState({ selectedTeams: [] });
      this.team_ids = [];
    }
  }

  onTeamSelect(data) {
    this.team_ids = data.map((item) => item.id);
  }

  // handleClick() {
  //   cookie.save('departmentId', this.dept_ids, { path: '/' });
  //   cookie.save('teamId', this.team_ids,  { path: '/'});
  //   this.setState({selectedDepts:this.dept_ids})
  //   this.setState({selectedTeams:this.team_ids})
  //   if((this.dept_ids?.length>1) || (this.dept_ids?.length===0)){
  //     this.handleChange('custom')
  //   }

  //     setTimeout(() => {
  //       const { router: { history } } = this.context;
  //   const { location: { pathname, query } } = this.props;
  //   const newQuery = { ...query };

  //   history.push({ pathname,
  //       search: queryString.stringify({...newQuery,
  //         department_ids: this.dept_ids,
  //         team_ids: this.team_ids,
  //     })});

  //     }, 1000);
  // };

  handleClick() {
    this.null_depts = this.null_depts || []; // Ensure it's always an array
    this.dept_ids = this.dept_ids || []; // Similarly, safeguard dept_ids
    this.combinedDepts = [...this.dept_ids, ...this.null_depts];
    cookie.save("departmentId", this.dept_ids, { path: "/" });
    cookie.save("teamId", this.team_ids, { path: "/" });
    cookie.save("nullDept", this.null_depts, { path: "/" });
    this.setState(
      { selectedDepts: this.combinedDepts, selectedTeams: this.team_ids },
      async () => {
        if (
          this.dept_ids?.length > 1 ||
          (this.dept_ids?.length === 0 && this.team_ids)
        ) {
          await this.handleChange("custom");
        }

        const {
          router: { history },
        } = this.context;
        const {
          location: { pathname, query },
        } = this.props;
        const newQuery = { ...query };
        history.push({
          pathname,
          search: queryString.stringify({
            ...newQuery,
            department_ids: this.dept_ids.filter((item) => item !== "true"),
            team_ids: this.team_ids,
            null_department: this.null_depts[0]
              ? this.null_depts[0]
              : undefined,
          }),
        });
      }
    );
  }

  onToggle(val) {
    const {
      router: {
        route: {
          match: { params },
        },
        history,
      },
    } = this.context;
    const {
      location: { pathname, query },
      userTimecardFilters,
      basicSettingDetail,
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query?.department_ids;
      } else {
        departmentIdsArray = [query?.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    const { page, parent, ...rest } = query;
    const preferenceObj = {
      preference_type: "user_preference_timecards",
      data: {
        manual_correction: query.manual_correction == "true" ? true : false,
        timecard_decimal_view: val,
        // (query.timecard_decimal_view == "true" || query.timecard_decimal_view == true) ? true : false,
        show_extra_info: query.show_extra_info == "true" ? true : false,
        ...rest,
        department_ids: departmentIdsIntegers,
      },
    };
    preferenceObj.data.timecard_decimal_view = val;
    return this.props.TimecardDucks.updateUserFilters(preferenceObj).then(() =>
      //this.props.TimecardDucks.getUserFilters({preference_type: 'user_preference_timecards'}),
      history.push({
        pathname,
        search: queryString.stringify({ ...query, timecard_decimal_view: val }),
      })
    );
  }
  exportDataTech(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadDataTechReport({
      ...obj,
      paginate: false,
      export_type: format,
    })
      .then((data) => {
        exportFile(
          data,
          `Data_Tech_Report_${query.start_date} to  ${query.end_date}.${format}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  famousExport(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let obj = {
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      company_id: userCompany.id,
    };
    return this.props.TimecardDucks.downloadFamousExport(obj)
      .then((data) => {
        exportTextFile(
          data,
          `FAMOUS_EXPORT_Report_${query.start_date} to  ${
            query.end_date
          }.${"txt"}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportIif(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      export_adjustment: format == "iif" ? null : true,
      include_pto: true,
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadIifReport({
      ...obj,
      paginate: false,
      export_type: "iif",
    })
      .then((data) => {
        exportFile(
          data,
          `IIF_Report_${query.start_date} to  ${query.end_date}.${"iif"}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }
  exportCsv(dates) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }

    return this.props.TimecardDucks.downloadCustomTimecardDataCsv({
      ...obj,
      paginate: false,
    })
      .then((data) => {
        // Only for IE 11
        exportFile(
          data,
          `Payroll_Data_Extraction_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportPayDatacsv(dates) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;
    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));
    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }

    return this.props.TimecardDucks.downloadPayDatacsv({
      ...obj,
      paginate: false,
    })
      .then((data) => {
        // Only for IE 11
        exportFile(
          data,
          `sdp_payroll_Extraction_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportmileagecsv(dates) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;
    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));
    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }

    return this.props.TimecardDucks.downloadMileagecsv({
      ...obj,
      paginate: false,
    })
      .then((data) => {
        // Only for IE 11
        exportFile(
          data,
          `sdp_reimbursement_Extraction_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportPdfandXls(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    const isEmployeeNotes =
      cookie.load("isEmployeeNotes") && cookie.load("isEmployeeNotes") == "true"
        ? true
        : false;
    const isEmployeeDayNotWork =
      cookie.load("isEmployeeDayNotWork") &&
      cookie.load("isEmployeeDayNotWork") == "true"
        ? true
        : false;
    const isCorrectionRequest =
      cookie.load("isCorrectionRequest") &&
      cookie.load("isCorrectionRequest") == "true"
        ? true
        : false;
    const isHideDecimalConversion =
      cookie.load("isHideDecimalConversion") &&
      cookie.load("isHideDecimalConversion") == "true"
        ? true
        : false;
    const isHideHHMMConversion =
      cookie.load("isHideHHMMConversion") &&
      cookie.load("isHideHHMMConversion") === "true"
        ? true
        : false;
    const isOrgInfo =
      cookie.load("isOrgInfo") && cookie.load("isOrgInfo") == "true"
        ? true
        : false;
    const isPageNumber =
      cookie.load("isPageNumber") && cookie.load("isPageNumber") === "true"
        ? true
        : false;
    const isSupervisorSignature =
      cookie.load("isSupervisorSignature") &&
      cookie.load("isSupervisorSignature") === "true"
        ? true
        : false;

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;

    if (format == "pdf") {
      return this.props.TimecardDucks.downloadCustomTimecardDataPdf({
        ...obj,
        paginate: false,
        export_type: format,
        include_day_not_work: isEmployeeDayNotWork,
        include_shift_name: isEmployeeNotes,
        include_correction_request: isCorrectionRequest,
        disable_decimal_row: isHideDecimalConversion,
        disable_hh_mm_row:
          obj?.timecard_decimal_view === "true"
            ? isHideHHMMConversion
            : undefined,
        include_page_number: isPageNumber,
        include_supervisor_signature_field: isSupervisorSignature,
        org_info: isOrgInfo,
        version2: true,
      })
        .then((data) => {
          if (data && data.value) {
            toast.success(data.value);
          }
          this.toggleExportModal();
        })
        .catch((err) => {
          if (err && err.non_field_errors.length && err.non_field_errors[0]) {
            toast.error(err.non_field_errors[0]);
          }
          this.toggleExportModal();
        });
    } else {
      return this.props.TimecardDucks.downloadCustomTimecardDataPdfandXls({
        ...obj,
        paginate: false,
        export_type: format,
        include_day_not_work: isEmployeeDayNotWork,
        include_shift_name: isEmployeeNotes,
        include_correction_request: isCorrectionRequest,
        disable_decimal_row: isHideDecimalConversion,
        disable_hh_mm_row:
          obj?.timecard_decimal_view === "true"
            ? isHideHHMMConversion
            : undefined,
        include_page_number: isPageNumber,
        include_supervisor_signature_field: isSupervisorSignature,
        org_info: isOrgInfo,
      })
        .then((data) => {
          exportFile(
            data,
            `Payroll_Data_Extraction_${query.start_date} to  ${
              query.end_date
            }.${format === "xls" ? "xlsx" : format}`
          );
          this.toggleExportModal();
        })
        .catch((err) => {
          let error = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(err))
          );
          if (
            error &&
            error.non_field_errors.length &&
            error.non_field_errors[0]
          ) {
            toast.error(error.non_field_errors[0]);
          }
          this.toggleExportModal();
        });
    }
  }

  exportiSolved(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadiSolvedReport({
      ...obj,
      paginate: false,
      export_type: format,
    })
      .then((data) => {
        if (data && data.value) {
          toast.success(data.value);
        }
        // exportFile(data,`iSolved_${query.start_date} to  ${query.end_date}.${format}`);
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportFenceFactory(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };
    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadFenceFactoryReport({
      ...obj,
      paginate: false,
      export_type: format,
    })
      .then((data) => {
        exportFile(
          data,
          `Fence_Factory_Report${query.start_date} to  ${query.end_date}.${format}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportJobPayroll(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    if (format === "pdf2") {
      return this.props.TimecardDucks.downloadJobCostingReport2({
        ...obj,
        paginate: false,
        export_type: "pdf",
      })
        .then((data) => {
          if (data && data.value) {
            toast.success(data.value);
          }
          this.toggleExportModal();
        })
        .catch(() => this.toggleExportModal());
    } else {
      return this.props.TimecardDucks.downloadJobCostingReport({
        ...obj,
        paginate: false,
        export_type: format,
      })
        .then((data) => {
          if (format === "pdf") {
            toast.warn(
              "This export will be deprecated after 15th July 2023. Please start using Export PDF 2."
            );
          }
          exportFile(
            data,
            `Job_Costing_Payroll_Report_${query.start_date} to  ${query.end_date}.${format}`
          );
          this.toggleExportModal();
        })
        .catch(() => this.toggleExportModal());
    }
  }

  exportJobPayrollDetail(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    if (format === "pdf2") {
      return this.props.TimecardDucks.downloadJobCostingDetailReport2({
        ...obj,
        paginate: false,
        export_type: "pdf",
        include_correction_message:
          this.state.jobCosting === "corrections_available" ? true : undefined,
        include_notes:
          this.state.jobCosting === "notes_available" ? true : undefined,
      })
        .then((data) => {
          if (data && data.value) {
            toast.success(data.value);
          }
          this.toggleExportModal();
        })
        .catch(() => this.toggleExportModal());
    } else {
      return this.props.TimecardDucks.downloadJobCostingDetailReport({
        ...obj,
        paginate: false,
        export_type: format,
        include_correction_message:
          this.state.jobCosting === "corrections_available" ? true : undefined,
        include_notes:
          this.state.jobCosting === "notes_available" ? true : undefined,
      })
        .then((data) => {
          if (format === "pdf") {
            toast.warn(
              "This export will be deprecated after 15th July 2023. Please start using Export PDF 2."
            );
          }
          exportFile(
            data,
            `Job_Costing_Payroll_Detail_Report_${query.start_date} to  ${query.end_date}.${format}`
          );
          this.toggleExportModal();
        })
        .catch(() => this.toggleExportModal());
    }
  }

  exportMikeRovnerCsv(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadMikeRovnerDetailReport({
      ...obj,
      paginate: false,
      export_type: format,
    })
      .then((data) => {
        exportFile(
          data,
          `Viewpoint_${query.start_date} to  ${query.end_date}.${format}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportMealsFlagExport(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;

    return this.props.TimecardDucks.downloadMelasFlagReport({ ...obj })
      .then((data) => {
        exportFile(
          data,
          `Meals_Flag_Report_${query.start_date} to  ${query.end_date}.${format}`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportSouthEast() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadSouthEastExport({
      ...obj,
      paginate: false,
    })
      .then((data) => {
        exportFile(
          data,
          `South_East_Report_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportADP(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      location: { query },
      selectedEmployeeTimecards,
      userCompany,
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    this.setState({ isExportLoading: true });
    let obj = {
      ...params,
      ...query,
      timecard_decimal_view: true,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadADPReport({
      ...obj,
      paginate: false,
      selectedFormat: format,
    })
      .then((data) => {
        if (format === "ADPXls") {
          exportFile(
            data,
            `ADP_Report_${query.start_date} to  ${query.end_date}.csv`
          );
        } else {
          exportFile(
            data,
            `ADP_Report_V2_${query.start_date} to  ${query.end_date}.csv`
          );
        }
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportSelectedFile(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    if (format === "auditXls2") {
      obj.version = true;
    }
    return this.props.TimecardDucks.downloadSelectedReport({
      ...obj,
      paginate: false,
      selectedFormat: format,
    })
      .then((data) => {
        if (format == "auditXls" || format == "auditXls2") {
          if (data && data.value) {
            toast.success(data.value);
          }
        }
        if (format == "adjustmentXls")
          exportFile(
            data,
            `Adjustment_Summary_Report_${query.start_date} to  ${query.end_date}.xlsx`
          );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportSage(dates) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      company_id: userCompany.id,
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
    }

    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }

    return this.props.TimecardDucks.downloadSAGEcsv({ ...obj, paginate: false })
      .then((data) => {
        // Only for IE 11
        exportFile(
          data,
          `SAGE_EXPORT_REPORT_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportPtoBalance() {
    const {
      selectedEmployeeTimecards,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      employee_ids: selectedEmployeeTimecards.length
        ? JSON.stringify(selectedEmployeeTimecards)
        : undefined,
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    return this.props.TimecardDucks.downloadPtoBalanceReport({ ...obj })
      .then((data) => {
        exportFile(
          data,
          `Pto_Balance_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  exportPaylocity() {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
      null_department: query.null_department
        ? query.null_department
        : undefined,
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }

    return this.props.TimecardDucks.downloadPaylocityReport({ ...obj })
      .then((data) => {
        exportFile(
          data,
          `Paylocity_${query.start_date} to  ${query.end_date}.csv`
        );
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  mmchrExport(format) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const {
      selectedEmployeeTimecards,
      userCompany,
      location: { query },
    } = this.props;

    let departmentIdsArray;
    if (query?.department_ids) {
      if (Array.isArray(query?.department_ids)) {
        departmentIdsArray = query.department_ids;
      } else {
        departmentIdsArray = [query.department_ids];
      }
    } else {
      departmentIdsArray = [];
    }
    const departmentIdsIntegers = departmentIdsArray.map((id) =>
      parseInt(id, 10)
    );

    let teamIdsArray;

    if (query?.team_ids) {
      if (Array.isArray(query.team_ids)) {
        teamIdsArray = query.team_ids;
      } else {
        teamIdsArray = [query.team_ids];
      }
    } else {
      teamIdsArray = [];
    }
    const teamIdsIntegers = teamIdsArray.map((id) => parseInt(id, 10));

    let obj = {
      ...params,
      ...query,
      start_date: moment(query.start_date).format("YYYY-MM-DD"),
      end_date: moment(query.end_date).format("YYYY-MM-DD"),
      department_ids: JSON.stringify(departmentIdsIntegers),
      team_ids: JSON.stringify(teamIdsIntegers),
    };

    if (selectedEmployeeTimecards.length) {
      obj.employee_ids = JSON.stringify(selectedEmployeeTimecards);
      obj.company_id = userCompany.id;
    } else {
      obj.company_id = userCompany.id;
    }
    if (query.payroll_id === "custom") delete obj.payroll_id;
    else {
      delete obj.start_date;
      delete obj.end_date;
    }
    return this.props.TimecardDucks.downloadMMCHR({
      ...obj,
      paginate: false,
      export_type: format,
    })
      .then((data) => {
        if (data && data.value) {
          toast.success(data.value);
        }
        // exportFile(data,`iSolved_${query.start_date} to  ${query.end_date}.${format}`);
        this.toggleExportModal();
      })
      .catch(() => this.toggleExportModal());
  }

  showManualCorrection(val) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        manual_correction: val.target.checked,
        page: 1,
      }),
    });
  }

  showExtraInformation(val) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        show_extra_info: val.target.checked,
      }),
    });
  }

  handleChange(val) {
    const {
      router: { history },
    } = this.context;
    const {
      payrollAll,
      location: { pathname, query },
      departmentPayroll,
    } = this.props;
    cookie.save("payrollId", val, { path: "/" });

    if (val === "custom" && query.payroll_id === "custom") return true;
    else if (val === "custom") {
      const startDate = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment()
        .endOf("month")
        .format("YYYY-MM-DD");
      cookie.save("startDate", startDate, { path: "/" });
      cookie.save("endDate", endDate, { path: "/" });
      return history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          payroll_id: val,
          start_date: startDate,
          end_date: endDate,
        }),
      });
    }
    let selectedPayroll;
    if (query.department_ids) {
      selectedPayroll = departmentPayroll.find((i) => i.id === val);
    }
    if (!selectedPayroll) {
      selectedPayroll = payrollAll.find((i) => i.id === val);
    }

    const startDate = selectedPayroll ? selectedPayroll?.start_date : null;
    const endDate = selectedPayroll
      ? moment(selectedPayroll.end_date)
          .subtract(1, "day")
          .format("YYYY-MM-DD")
      : null;
    cookie.save("startDate", startDate, { path: "/" });
    cookie.save("endDate", endDate, { path: "/" });

    return history.push({
      pathname,
      search: queryString.stringify({
        ...query,
        payroll_id: val,
        start_date: startDate,
        end_date: endDate,
      }),
    });
  }

  createTimecard(data) {
    const {
      location: { pathname, search, query },
    } = this.props;
    const {
      router: { history },
    } = this.context;

    const payrollId = queryString.parse(search).payroll_id || "custom";
    const startDate = data.start_date
      ? moment(data.start_date).format("YYYY-MM-DD")
      : query.start_date;
    const endDate = data.end_date
      ? moment(data.end_date).format("YYYY-MM-DD")
      : query.end_date;
    history.push({
      pathname: "/timecard/custom/list/createtimecard",
      search: queryString.stringify({
        start_date: startDate,
        end_date: endDate,
        parent: `${pathname}${search}`,
        payroll_id: payrollId,
      }),
    });
  }

  toggleExportModal() {
    const { isModalOpen } = this.state;
    const { timecardList } = this.props;
    if (!timecardList.count)
      return toast.error("There are no records to export");
    return this.setState({
      isModalOpen: !isModalOpen,
      jobCosting: null,
    });
  }

  render() {
    const {
      basicSettingDetail,
      departmentAll,
      manualEntryDetail,
      location,
      payrollAll,
      teamAll,
      userExtraData,
      isLoading,
      dateFormat,
      departmentPayroll,
      setAttributeToggler,
      approveAllEmployee,
      userTimecardFilters,
    } = this.props;
    const { isModalOpen } = this.state;
    const { query } = location;

    let payrollOptions = [];
    let departmentOptions = [];
    let teamOptions = [];
    let isExtraInfo = false;
    let isManualCheck = false;
    let departmentAllOptions;
    if (Object.keys(query).length > 0) {
      if (query.show_extra_info === "true") isExtraInfo = true;
      if (query.manual_correction === "true") isManualCheck = true;
    }
    let payrollList = [];
    if (query.department_ids && departmentPayroll && departmentPayroll.length) {
      payrollList = departmentPayroll;
    } else payrollList = payrollAll;
    payrollOptions =
      payrollList &&
      payrollList.map((item) => ({
        value: item.id,
        label: `${item.start_date_display} to ${item.end_date_display}`,
      }));

    payrollOptions.unshift({ value: "custom", label: "Custom Date Range" });

    if (departmentAll && departmentAll.length) {
      const nullDeptExists = departmentAll.some(
        (department) => department.id === 0
      );

      if (nullDeptExists) {
        departmentAll.pop();
        departmentAll.splice(1, 0, { id: -1, name: "No Department" });
      }

      departmentOptions = departmentAll && departmentAll.map((item) => item.id);
      teamOptions = teamAll && teamAll.map((item) => item.id);
    }

    const selectedPayroll = payrollList.length
      ? payrollList.find((i) => i.id === +query.payroll_id)
      : null;

    let dates = {};
    if (selectedPayroll) {
      dates = {
        end_date: selectedPayroll && selectedPayroll.end_datetime,
        start_date: selectedPayroll && selectedPayroll.start_datetime,
      };
    }

    const cookieKey = cookie.load("exportStandardFormat");
    const payrollIdCookie = cookie.load("payrollId");
    const cookieStartDate = cookie.load("startDate");
    const cookieEndDate = cookie.load("endDate");

    return (
      <div>
        <Row className="filter btn-color-blue pt-4 pl-3 pr-2">
          <Col md={6} className={`${styles["export-align"]} mb-2 pt-3 pr-0`}>
            <section
              className={styles["custom-filter"]}
              style={{ display: "flex", gap: "0.5rem" }}
            >
              {departmentOptions && departmentOptions.length > 0 && (
                <div
                  id="department"
                  className="d-inline-block department-index department-dropdown"
                  style={{
                    width: "12rem",
                    ...((this?.state?.selectedDepts?.length === 1 ||
                      this?.state?.selectedDepts?.length === 0) &&
                      query?.payroll_id !== "custom" && { marginTop: "16px" }),
                  }}
                >
                  <FormikMultiSelectAtom
                    key={this?.state?.selectedDepts?.join(",")}
                    id="department_id"
                    name="department_id"
                    items={departmentAll}
                    placeholder="Select Departments"
                    itemToString={(i) => i && i.name}
                    onChange={(data) => this.onDepartmentSelect(data)}
                    field={{ value: this.state.selectedDepts }}
                  />
                  <UncontrolledTooltip placement="top" target="department">
                    Select Department
                  </UncontrolledTooltip>
                </div>
              )}
              {departmentOptions && departmentOptions.length > 0 && (
                <div
                  id="team"
                  className="d-inline-block department-dropdown"
                  style={{
                    width: "12rem",
                    ...((this?.state?.selectedDepts?.length === 1 ||
                      this?.state?.selectedDepts?.length === 0) &&
                      query?.payroll_id !== "custom" && { marginTop: "16px" }),
                  }}
                >
                  <FormikMultiSelectAtom
                    key={this?.state?.selectedTeams?.join(",")}
                    id="team_id"
                    name="team_id"
                    items={
                      this.dept_ids?.length === 0 || this.dept_ids === undefined
                        ? []
                        : teamAll
                    }
                    placeholder="Select Teams"
                    itemToString={(i) => i && i.name}
                    onChange={(data) => this.onTeamSelect(data)}
                    field={{ value: this.state.selectedTeams }}
                    disabled={
                      this.dept_ids?.length === 0 || this.dept_ids === undefined
                    }
                  />
                  <UncontrolledTooltip placement="top" target="team">
                    Select Team
                  </UncontrolledTooltip>
                </div>
              )}

              {departmentOptions && departmentOptions.length > 0 && (
                <div
                  id="submit"
                  className="d-inline-block department-dropdown"
                  style={{
                    width: "12rem",
                    ...((this?.state?.selectedDepts?.length === 1 ||
                      this?.state?.selectedDepts?.length === 0) &&
                      query?.payroll_id !== "custom" && { marginTop: "16px" }),
                  }}
                >
                  <Button
                    type="submit"
                    color="accent"
                    style={{ marginBottom: "24px" }}
                    className="pr-4 pl-4"
                    onClick={() => this.handleClick()}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </section>
          </Col>
          <Col
            md={6}
            className={
              query.payroll_id !== "custom"
                ? `${styles["export-align"]} mb-2 pr-4 ${styles["filter-custom-padding"]}`
                : `${styles["export-align"]} mb-2 pr-4 pt-3`
            }
          >
            {((query.start_date && query.end_date) ||
              query.payroll_id !== "custom") && (
              <Button
                color="primary"
                onClick={() => this.toggleExportModal()}
                className="float-right mr-2 pr-4 pl-4"
              >
                Export
              </Button>
            )}
            {((query.start_date && query.end_date) ||
              query.payroll_id !== "custom") && (
              <Button
                color="primary"
                onClick={() => this.createTimecard(dates)}
                className="float-right mr-2 pr-4 pl-4"
              >
                Create Timecard
              </Button>
            )}
            <section className="float-right mr-2 button-job-override">
              <span id="decimal-view">
                <ButtonGroupAtom
                  options={DECIMAL_VIEW_OPTIONS}
                  active={
                    userTimecardFilters && userTimecardFilters.data
                      ? userTimecardFilters.data.timecard_decimal_view ==
                          "true" ||
                        userTimecardFilters.data.timecard_decimal_view == true
                        ? true
                        : false
                      : basicSettingDetail.timecard_decimal_view
                  }
                  onToggle={this.onToggle}
                />
              </span>
              <UncontrolledTooltip placement="left" target="decimal-view">
                Payroll Time Format
              </UncontrolledTooltip>
            </section>
          </Col>
        </Row>
        <Row className="filter btn-color-blue pt-2 pd-0 pl-3 pr-2">
          <Col md={6}>
            <div
              className="timecard_payroll d-inline-block"
              style={{ marginTop: "-15px" }}
            >
              <div>
                {selectedPayroll && (
                  <div className="mr-2" title={selectedPayroll.name}>
                    <div className="font-12px">{selectedPayroll.name}</div>
                  </div>
                )}
              </div>
              {payrollOptions && payrollOptions.length > 0 && (
                <span>
                  <span id="payroll_date">
                    <FilterDropdownComponent
                      paramKey="payroll_id"
                      location={location}
                      options={payrollOptions}
                      onChange={(val) => this.handleChange(val)}
                    />
                  </span>
                  <UncontrolledTooltip placement="top" target="payroll_date">
                    Custom Date Range
                  </UncontrolledTooltip>
                </span>
              )}
            </div>
          </Col>
          <Col md={6}>
            <section className="float-right mr-3">
              <FilterFormComponent
                initialValues={query}
                location={location}
                placeholder="Name, Title or Tag"
              />
            </section>
            <section className="float-right">
              <Button
                onClick={() =>
                  setAttributeToggler(!this.props.attributeToggler)
                }
                color="accent"
              >
                <div className={styles["button-icon"]} id="show_hide">
                  <img src={showHideIcon} alt="Icon" />
                  <UncontrolledTooltip placement="top" target="show_hide">
                    Show/Hide
                  </UncontrolledTooltip>
                </div>
              </Button>
            </section>

            {userExtraData.is_plugin_activated ? (
              <section className="float-right pr-1">
                <Button
                  onClick={() => approveAllEmployee()}
                  color="accent"
                  id="approve_all"
                  className="float-right"
                  disabled={
                    this.props.isApproveAllEmployeesLoading ||
                    isLoading ||
                    (this.props.selectedEmployeeTimecards &&
                      !this.props.selectedEmployeeTimecards.length)
                  }
                >
                  Approve All{" "}
                  <ButtonLoaderAtom
                    active={this.props.isApproveAllEmployeesLoading}
                  />
                  <UncontrolledTooltip placement="top" target="approve_all">
                    Approve all employees Daily timecards
                  </UncontrolledTooltip>
                </Button>
              </section>
            ) : null}
          </Col>
          {isModalOpen && (
            <ExportModalComponent
              isOpen={isModalOpen}
              toggle={this.toggleExportModal}
              exportCsv={this.exportCsv}
              exportPdfandXls={this.exportPdfandXls}
              famousExport={this.famousExport}
              //initialValues={{ export: cookieKey && JSON.parse(cookieKey) ? 1 : null }}
              dates={dates}
              exportJobPayroll={this.exportJobPayroll}
              exportDataTech={this.exportDataTech}
              exportIif={this.exportIif}
              exportJobPayrollDetail={this.exportJobPayrollDetail}
              exportADP={this.exportADP}
              exportPayDatacsv={this.exportPayDatacsv}
              exportTbsBilling={this.exportTbsBilling}
              exportmileagecsv={this.exportmileagecsv}
              exportSouthEast={this.exportSouthEast}
              toggleJobCorrectionCheckbox={this.toggleJobCorrectionCheckbox}
              exportSelectedFile={this.exportSelectedFile}
              exportSage={this.exportSage}
              exportiSolved={this.exportiSolved}
              exportMikeRovnerCsv={this.exportMikeRovnerCsv}
              exportFenceFactory={this.exportFenceFactory}
              exportPtoBalance={this.exportPtoBalance}
              exportPaylocity={this.exportPaylocity}
              toggleEmployeeCheckbox={this.toggleEmployeeCheckbox}
              exportMealsFlagExport={this.exportMealsFlagExport}
              mmchrExport={this.mmchrExport}
              userExtraData={userExtraData}
              initialValues={{
                export: cookieKey && JSON.parse(cookieKey) ? 1 : null,
                correction_request:
                  cookie.load("isCorrectionRequest") &&
                  cookie.load("isCorrectionRequest") == "true"
                    ? true
                    : false,
                days_not_worked:
                  cookie.load("isEmployeeDayNotWork") &&
                  cookie.load("isEmployeeDayNotWork") == "true"
                    ? true
                    : false,
                employee_shift_available:
                  cookie.load("isEmployeeNotes") &&
                  cookie.load("isEmployeeNotes") == "true"
                    ? true
                    : false,
                disable_decimal_row:
                  cookie.load("isHideDecimalConversion") &&
                  cookie.load("isHideDecimalConversion") == "true"
                    ? true
                    : false,
                disable_hh_mm_row:
                  cookie.load("isHideHHMMConversion") &&
                  cookie.load("isHideHHMMConversion") === "true"
                    ? true
                    : false,
                include_page_number:
                  cookie.load("isPageNumber") &&
                  cookie.load("isPageNumber") === "true"
                    ? true
                    : false,
                add_supervisor_signature:
                  cookie.load("isSupervisorSignature") &&
                  cookie.load("isSupervisorSignature") === "true"
                    ? true
                    : false,
                organization_information:
                  cookie.load("isOrgInfo") && cookie.load("isOrgInfo") == "true"
                    ? true
                    : false,
              }}
              userTimecardFilters={userTimecardFilters}
              jobCosting={this.state.jobCosting}
            />
          )}
        </Row>
        <Row className="pt-2 pd-0 pl-3 pr-2">
          {(query.start_date || cookieStartDate) && (
            <Col md={6}>
              <section className={styles["custom-filter"]}>
                {query.payroll_id === "custom" && (
                  <div className="d-inline-block">
                    <CustomTimecardFilterFormComponent
                      location={location}
                      dateFormat={dateFormat}
                      initialValues={
                        query.start_date && query.end_date
                          ? {
                              end_date: moment(query.end_date).toISOString(),
                              start_date: moment(
                                query.start_date
                              ).toISOString(),
                            }
                          : {
                              end_date: moment(cookieEndDate).toISOString(),
                              start_date: moment(cookieStartDate).toISOString(),
                            }
                      }
                    />
                  </div>
                )}
              </section>
            </Col>
          )}
        </Row>
        <Row>
          <Col md={6} className="mt-3 mb-3 pl-4">
            <div className="d-inline-block">
              {manualEntryDetail &&
                manualEntryDetail.value !== "No Manual Entry Allowed" && (
                  <span className="ml-2">
                    <input
                      id="manual_correction"
                      type="checkbox"
                      name="manual_correction"
                      label="Show Deviations"
                      onChange={(e) => this.showManualCorrection(e)}
                      disabled={isLoading}
                      className="mr-1 align-text-bottom"
                      checked={isManualCheck}
                    />
                    <span className="align-middle mr-3 font-colorInput">
                      Show Deviations
                    </span>
                  </span>
                )}
              {departmentAll.length > 0 && (
                <span className="ml-2">
                  <input
                    id="show_department_team"
                    type="checkbox"
                    name="show_department_team"
                    onChange={(e) => this.showExtraInformation(e)}
                    disabled={isLoading}
                    className=" mr-1 align-text-bottom"
                    checked={isExtraInfo}
                  />
                  <span className="align-middle font-colorInput">
                    Show Department & Teams
                  </span>
                </span>
              )}
            </div>
          </Col>
          <Col md={6}>
            <section className="float-right mr-2 mt-3 mb-3 pr-3">
              <small className="font-styles">
                * Adjustment activities do not count towards RT, OT, DOT totals.
              </small>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  manualEntryDetail: SystemSettingDucks.manualEntryDetail(state),
  isSubCompany: UserDucks.isSubCompany(state),
  location: RouteDucks.location(state),
  payrollAll: PayrollDucks.payrollAll(state),
  departmentPayroll: PayrollDucks.departmentPayroll(state),
  teamAll: TeamDucks.teamAll(state),
  userExtraData: UserDucks.userExtraData(state),
  userFilters: TimecardDucks.userTimecardFilters(state),

  timecardList: TimecardDucks.timecardList(state),
  selectedEmployeeTimecards: TimecardDucks.selectedEmployeeTimecards(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  userCompany: UserDucks.userCompany(state),
  userTimecardFilters: TimecardDucks.userTimecardFilters(state),
});

const mapActionsToProps = (dispatch) => ({
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  SystemSettingDucks: bindActionCreators(SystemSettingDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
});

CustomTimecardFilterSection.propTypes = {
  BasicSettingDucks: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  DepartmentDucks: PropTypes.object.isRequired,
  departmentAll: PropTypes.array.isRequired,
  manualEntryDetail: PropTypes.object,
  location: PropTypes.object.isRequired,
  payrollAll: PropTypes.array.isRequired,
  teamAll: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  timecardList: PropTypes.object.isRequired,
  selectedEmployeeTimecards: PropTypes.array,
  dateFormat: PropTypes.string.isRequired,
};

CustomTimecardFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomTimecardFilterSection);
