import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import Lightbox from 'react-image-lightbox';

import { toast } from 'react-toastify';
import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardBlock from 'reactstrap/lib/CardBlock';
import CardHeader from 'reactstrap/lib/CardHeader';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import ActiveIconAtom from 'atoms/ActiveIcon';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FileBrowserAtom from 'atoms/FileBrowser';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import CountryCodeAtom from 'atoms/CountryCode';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * VendorFormPage -> VendorFormSection -> VendorFormComponent
 *
 * Props:
 *    - update
 */
class VendorFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleLogoSelect = this.handleLogoSelect.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleMailingCountryChange = this.handleMailingCountryChange.bind(this);
    this.submit = this.submit.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
    this.state = { file: null, logoUrl: null,  photoIndex: 0,
      isOverlayOpen: false,
      selectedId: null, 
      verify : false, logoRemoved: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(media){
    const { initialValues, phoneNumber, phoneExt, updatePhone } = this.props;
    if((initialValues.phone_no === phoneNumber) && (initialValues.phone_ext === phoneExt)){
      return this.resendOtp(media);
    }

    if(!phoneNumber) return toast.error('Enter Phone Number');
    this.setState({ verify :  true });
    return updatePhone({ phone_ext: phoneExt, phone_no: phoneNumber }, initialValues.id)
      .then(() => this.setState({ verify :  false }))
      .catch((err) => {
        toast.error(err.phone_no || err.non_field_errors[0]);
        this.setState({ verify: false });
      });
  }

  resendOtp(media){
    this.setState({ verify :  true });
    const { resendOtp } = this.props;
    return resendOtp(media)
      .then(() => this.setState({ verify :  false }))
      .then(() => toast.success("SMS succesfully sent"))
      .catch(() => this.setState({ verify: false }));
  }

  handleLogoRemove() {
    const { change } = this.props;
    change('image', null);
    this.setState({ file: null, logoUrl: null, logoRemoved: true });
    document.getElementById('image').value = null;
  }

  handleCountryChange (e, value) {
    const { change, onCountrySelect } = this.props;
    change('state', null);
    onCountrySelect(value);
  }

  handleMailingCountryChange (e, value) {
    const { change, onMailingCountrySelect } = this.props;
    change('mailing_state', null);
    onMailingCountrySelect(value);
  }

  handleLogoSelect(e) {

    const file = e.target.files[0];
    const fileExt = file && file.name.split('.').pop();
    const imageExt = ['png', 'jpeg', 'jpg'];
    if(imageExt.indexOf(fileExt) < 0){
      document.getElementById('image').value = null;
      this.setState({ file: null, logoUrl: null });
      return toast.error('File type not supported');
    }
    const reader = new FileReader();
    reader.onload = () => this.setState({ file, logoUrl: reader.result, logoRemoved: false });
    if (file) reader.readAsDataURL(file);
    return true;
  }

  submit(detail) {
    const { update } = this.props;
    const { file, logoRemoved } = this.state;
    const userData = {};
    const data = { ...detail,
      address_line1: detail.address_line1 ? detail.address_line1 : '',
      address_line2: detail.address_line2 ? detail.address_line2 : '',
      country_id: detail.country_id ? detail.country_id : 0,
      state_id: detail.state_id ? detail.state_id : 0,
      city: detail.city ? detail.city : '',
      phone_ext: detail.phone_ext ? detail.phone_ext : '+1',
      postal_code: detail.postal_code ? detail.postal_code : null,
      mailing_state_id: detail.mailing_state_id ? detail.mailing_state_id : 0,
      mailing_city: detail.mailing_city ? detail.mailing_city : '',
      mailing_address_line1: detail.mailing_address_line1 ? detail.mailing_address_line1 : '',
      mailing_address_line2: detail.mailing_address_line2 ? detail.mailing_address_line2 : '',
      mailing_country_id: detail.mailing_country_id ? detail.mailing_country_id : 0,
      mailing_postal_code: detail.mailing_postal_code ? detail.mailing_postal_code : null,
      remove_image: !file || logoRemoved,
    };
    let formData;
    let hasImage = logoRemoved;
    Object.keys(data).forEach((key) => {
      userData[key] = data[key];
    });
    formData = new FormData();
    Object.keys(userData).forEach((key) => {
      formData.append(key, userData[key]);
    });
    if (file) { // a file is selected
      formData.append('image', file);
      hasImage = true;
    }else{
      delete userData['image'];
    }
    return update(userData, formData, userData.id, hasImage)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const {
      profile,
      error,
      submitting,
      companyLogo,
      countriesList,
      statesList,
      mailingStatesList,
      timezonesList,
      handleSubmit,
      initialValues,
      phoneNumber,
      phoneExt,
    } = this.props;
    const { logoUrl, isOverlayOpen, verify } = this.state;
    
    let imageCompany = {}
    if(logoUrl){
      imageCompany = logoUrl
    }
    else
      imageCompany = companyLogo;

    return (
      <Form onSubmit={handleSubmit(this.submit)} noValidate className="pl-3 pr-4">
        <div className="error-text">{error}</div>
        <Row>
          <Col>
            <Field
              id="name"
              name="name"
              label="Business Name"
              component={InputAtom}
              placeholder="Business Name"
              layout={FORM_LAYOUT}
            />
            <Field
              id="timezone"
              name="timezone"
              label="Timezone"
              component={SelectAtom}
              options={timezonesList.map(i => ({ label: i, value: i }))}
              placeholder="Timezone"
              layout={FORM_LAYOUT}
              simpleValue
            />
            <Row>
              <Col md={!initialValues.is_email_verified ? 10 : 12}>
                <Field
                  id="email"
                  name="email"
                  label="Email"
                  component={InputAtom}
                  placeholder="Email"
                  layout={FORM_LAYOUT}
                  disabled={!profile.is_logged_in_admin}
                  data-lpignore={true}
                  
                />
              </Col>
              {!initialValues.is_email_verified &&
                <Col md={2}>
                  <Button
                    type="button"
                    color="secondary"
                    className="float-sm-right my-4"
                    size="sm"
                    disabled={verify || submitting}
                    onClick={() => {
                      this.resendOtp('EMAIL')}}
                    >
                     Verify <ButtonLoaderAtom active={verify} />
                  </Button>
              </Col>}
            </Row>
            <Row>
              <Col md={initialValues.is_phone_verified ? 4 : 3} className="pr-0">
                <Field
                  id="phone_ext"
                  name="phone_ext"
                  component={CountryCodeAtom}
                  label="Code"
                  placeholder="+1"
                  disabled={initialValues.is_phone_verified}
                />
              </Col>
              <Col md={initialValues.is_phone_verified ? 8 : 7}>
                <Field
                  id="phone_no"
                  name="phone_no"
                  label="Phone Number"
                  component={InputAtom}
                  placeholder="8055550123"
                  layout={FORM_LAYOUT}
                  disabled={initialValues.is_phone_verified}
                />
              </Col>
              {!initialValues.is_phone_verified &&
                <Col md={2}>
                  <Button
                    type="button"
                    color="secondary"
                    className="float-sm-right my-4"
                    disabled={verify || submitting}
                    size="sm"
                    onClick={() => {
                      this.handleClick('SMS')}}
                  >
                     { ((initialValues.phone_no === phoneNumber) && (initialValues.phone_ext === phoneExt)) 
                       ? 'Verify' : 'Save' } <ButtonLoaderAtom active={verify} />
                  </Button>
              </Col>}
            </Row>
          </Col>
          <Col>
            <Field
              id="code"
              name="code"
              label="Company Code"
              component={InputAtom}
              placeholder="Vendor Code"
              layout={FORM_LAYOUT}
              disabled
            />
            <Field
              accept=".jpg, .jpeg, .png"
              id="image"
              name="image"
              label={
                <div>
                  Company Logo
                  <f6 className="float-right font-12px">
                    <i className="align-middle"> (Allowed types: .png .jpeg .jpg)</i>
                  </f6>
                </div>
              }
              component={FileBrowserAtom}
              type="file"
              placeholder="Company Logo"
              layout={FORM_LAYOUT}
              onChange={this.handleLogoSelect}
            />
            {logoUrl
              ? (<div className={styles['img-preview']}>
                <div
                  className="outline-none"
                  role='button'
                  tabIndex="0"
                  onClick={() => this.setState({ isOverlayOpen: true })} 
                >
                  <img
                    src={logoUrl}
                    alt="Logo"
                    height="120"
                  />
                </div>
                <ActiveIconAtom
                  height="15" width="15"
                  className="input-icon__clear-logo"
                  onClick={this.handleLogoRemove}
                />
              </div>)
              : ((companyLogo && companyLogo.length) > 0) && (<div className={styles['img-preview']}>
                <div 
                  className="outline-none"
                  role='button'
                  tabIndex="0"
                  onClick={() => this.setState({ isOverlayOpen: true })} 
                >
                  <img src={companyLogo} alt="Company Logo" height="120" />
                </div>  
                <ActiveIconAtom
                  height="15" width="15"
                  className="input-icon__clear-logo"
                  onClick={this.handleLogoRemove}
                />
              </div>)
            }
            {isOverlayOpen && (
              <Lightbox
                mainSrc={imageCompany}
                onCloseRequest={() => this.setState({ isOverlayOpen: false })}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>Primary Address</CardHeader>
              <CardBlock className='activityCard'>
                <Field
                  id="address_line1"
                  name="address_line1"
                  label="Address 1"
                  component={InputAtom}
                  placeholder="Address 1"
                  layout={FORM_LAYOUT}
                />
                <Field
                  id="address_line2"
                  name="address_line2"
                  label="Address 2"
                  component={InputAtom}
                  placeholder="Address 2 (optional)"
                  layout={FORM_LAYOUT}
                />
                <Field
                  id="country_id"
                  name="country_id"
                  label="Country"
                  component={SelectAtom}
                  options={countriesList}
                  placeholder="Country"
                  layout={FORM_LAYOUT}
                  labelKey="name"
                  valueKey="id"
                  onChange={this.handleCountryChange}
                  simpleValue
                />
                <Field
                  id="state_id"
                  name="state_id"
                  label="State"
                  component={SelectAtom}
                  options={statesList}
                  placeholder="State"
                  layout={FORM_LAYOUT}
                  labelKey="name"
                  valueKey="id"
                  simpleValue
                />
                <Field
                  id="city"
                  name="city"
                  label="City"
                  component={InputAtom}
                  placeholder="City"
                  layout={FORM_LAYOUT}
                />
                <Field
                  id="postal_code"
                  name="postal_code"
                  label="Postal Code"
                  component={InputAtom}
                  placeholder="Postal Code"
                  layout={FORM_LAYOUT}
                  type="number"
                />
              </CardBlock>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>Mailing Address</CardHeader>
              <CardBlock className='activityCard'>
                <Field
                  id="mailing_address_line1"
                  name="mailing_address_line1"
                  label="Address 1"
                  component={InputAtom}
                  placeholder="Address 1"
                  layout={FORM_LAYOUT}
                />
                <Field
                  id="mailing_address_line2"
                  name="mailing_address_line2"
                  label="Address 2"
                  component={InputAtom}
                  placeholder="Address 2"
                  layout={FORM_LAYOUT}
                />
                <Field
                  id="mailing_country_id"
                  name="mailing_country_id"
                  label="Country"
                  component={SelectAtom}
                  options={countriesList}
                  placeholder="Country"
                  layout={FORM_LAYOUT}
                  labelKey="name"
                  valueKey="id"
                  onChange={this.handleMailingCountryChange}
                  simpleValue
                />
                <Field
                  id="mailing_state_id"
                  name="mailing_state_id"
                  label="State"
                  component={SelectAtom}
                  options={mailingStatesList}
                  placeholder="State"
                  layout={FORM_LAYOUT}
                  labelKey="name"
                  valueKey="id"
                  simpleValue
                />
                <Field
                  id="mailing_city"
                  name="mailing_city"
                  label="City"
                  component={InputAtom}
                  placeholder="City"
                  layout={FORM_LAYOUT}
                />
                <Field
                  component={InputAtom}
                  id="mailing_postal_code"
                  label="Postal Code"
                  layout={FORM_LAYOUT}
                  name="mailing_postal_code"
                  placeholder="Postal Code"
                  type="number"
                />
              </CardBlock>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              type="submit"
              color="secondary" 
              className="float-sm-right my-4 btn-width py-2"
              size={FORM_LAYOUT.size}
            >
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Business Name';
  if (!values.phone_ext) errors.phone_ext = 'Enter Country Code';
  if (!values.phone_no) errors.phone_no = 'Enter Phone Number';
  if (!values.email) errors.email = 'Enter Email';
  if (!values.country) errors.country = 'Enter Country';
  if (!values.timezone) errors.timezone = 'Enter Timezone';
  return errors;
};

VendorFormComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  submitting: PropTypes.bool.isRequired,
  timezonesList: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

const vendorForm = reduxForm({
  form: 'VendorForm',
  validate,
  enableReinitialize: true,
})(VendorFormComponent);

const selector = formValueSelector('VendorForm');

export default connect(
  (state) => {
    const companyLogo = selector(state, 'image');
    const phoneNumber = selector(state, 'phone_no');
    const phoneExt = selector(state, 'phone_ext');
    return { companyLogo, phoneNumber, phoneExt };
  },
)(vendorForm);
