import React from 'react';
import { Row, Col} from 'reactstrap';
import { toast } from 'react-toastify';

import ContentLayout from 'layouts/Content';

import UserAPIGateway from 'accounts/gateways/user';
import AddCardUsecase from 'payments/paymentMethods/usecases/addCard';
import AddCardComponent from 'payments/paymentMethods/components/addCard';
import {Elements, StripeProvider} from 'react-stripe-elements';

const { REACT_APP_STRIPE_API_KEY_BASE } = process.env;

class AddCardPage extends React.PureComponent {
  constructor(props) {
    super(props);    
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new AddCardUsecase(userAPIGateway);
    this.listenAddCardUsecase = this.listenAddCardUsecase.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  componentWillMount() {
    this.listenAddCardUsecase();
  }

   
  listenAddCardUsecase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'RESENT_SUCCESS':
          toast.success("Password updated successfully");
          this.props.history.push('/login');
          break;
         default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col xs={12} md={{ size:4, offset:4 }} className="align-self-center" >
            <StripeProvider apiKey= {`${REACT_APP_STRIPE_API_KEY_BASE}`}>
              <Elements>
                <AddCardComponent usecase={this.usecase} />
              </Elements>
            </StripeProvider>
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}


export default AddCardPage;
