  import React, {PureComponent} from 'react';
import Link from 'react-router-dom/Link';
import { Formik } from 'formik';
import { Label, Form, FormText, FormGroup, Input, Button , Card, CardTitle } from 'reactstrap';
import { server } from 'helpers/config';
import { toast } from 'react-toastify';

import ButtonLoaderAtom from 'atoms/ButtonLoader'; 
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import styles from './styles.module.scss';
import name from './name.png';
import ResetPasswordModal from './ResetPasswordModal.jsx';

class SignInForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.state = { inputType: 'password' , resetpasswordModal: false};
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    this.props.usecase.setup_data(values);
    try {
      await this.props.usecase.execute(values);
    } catch (e) {
      setErrors(e.errors);
      toast.error(e);
    }
    setSubmitting(false);
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }
  render() {
    const {ismodalState} = this.props;
    const { inputType} = this.state;

    return (
      <div>
        <Card className="ui-card">
          <div className="text-center pb-5">
            <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
            <img src={name} height="26px" width="150px" alt="Logo"/>
          </div>
      
      <CardTitle className={`${styles['heading']} text-left ml-5 mr-5`}>Sign in</CardTitle>
        <Formik
          initialValues={this.props.data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            }) => 
           {
            return(
              <Form onSubmit={handleSubmit} className="ml-5 mr-5">
                <div style={{minHeight: '0vh', fontSize: '20px'}} className="mb-3"><FormText color="danger">{errors && errors.non_field_errors}</FormText></div>
                <FormGroup>
                  <Label className="mb-2">Email Address/Username</Label>
                  <Input
                    type="text"
                    placeholder="Email Address/Username"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={errors && errors.email ? "form-control-error" : null} 
                    data-lpignore={true}
                  />
                  <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.email}</FormText></div>
                </FormGroup>
                
                 <FormGroup className="mt-1">
                 <div className="pos-relative">
                 <Label className="mb-2">Password</Label>
                 <Input
                    type={inputType}
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    invalid={errors && touched.password && errors && errors.password}
                    className={errors && errors.password ? "form-control-error" : null}
                    data-lpignore={true}
                  />
                    {inputType === 'password'
                      ? (<EyeOpenIconAtom
                        className="password-icon"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                      />)
                      : (<EyeCloseIconAtom
                        className="password-icon"
                        height="22" width="22"
                        onClick={this.toggleInputType}
                    />)
                  }
                  <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.password}</FormText></div>
                </div>
                
                </FormGroup>    
                {/*{errors && errors.company ?             
                <FormGroup className="mt-1">
                 <Label>Company Name</Label>
                 <Input
                    type="text"
                    name="company_name"
                    placeholder="Company Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company_name}                    
                  />                  
                </FormGroup>  : null}  */ }            
                <div>
                  <a href="/accounts/forgot-password" className="float-left pt-3">Forgot Password?</a>
                  <Button
                    className={`${styles['accounts-button']} float-right`}
                    type="submit"
                    isColor='primary'
                    isLoading={isSubmitting}
                  >
                    Sign in <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                </div>
                <hr className="mt-5"/>
                <div className="text-left">
                  {'Don\'t have an account?'} <Link to="/accounts/register">Start my free trial</Link>
                </div>
                <div className="text-left mt-1">
                  Looking for the kiosk? <a href={server.kioskHost}>Click here</a>
                </div>
             </Form>
            )}}
          />
    </Card>
    {ismodalState &&
        <ResetPasswordModal 
          isOpen = {ismodalState}
        />

      }
    </div>);
  }
}

export default SignInForm;
