import { server } from 'helpers/config';

const SERVER_URL = server.apiHost;

export default {
  JOB: `${SERVER_URL}/job/`,
  JOB_LIST: `${SERVER_URL}/job/scenario/`,
  JOB_LIST_ADMIN: `${SERVER_URL}/job/list/admin/`,
  JOB_REPORTS:'report/',
  JOB_DELETE:`${SERVER_URL}/job/task/delete/`,
  GEOFENCE: `geo-fence/`,
  EMPLOYEE_ASSIGN:`employee/`,
  TASK: 'task/',
  TAG: 'tags/',
  STATS: 'stats/',
  HISTORY: 'document/',
  TIME_ALLOCATION: 'daily-timecard/employee-task-time-allocation-list/',
  TIME_ALLOCATION_DETAIL: 'daily-timecard/employee-task-time-allocation-detail/',
  TIME_ALLOCATION_UPDATE: 'daily-timecard/employee-task-time-allocation-update/',
  TIME_ALLOCATIONS: 'daily-timecard/employee-task-time-allocation-bulk-list/',
  TIME_ALLOCATIONS_DETAIL: 'daily-timecard/employee-task-time-allocation-bulk-detail/',
  TIME_ALLOCATIONS_UPDATE: 'daily-timecard/employee-task-time-allocation-bulk-update/',
  JOBS_OF_EMPLOYEE:`${SERVER_URL}/job/v2/`,
  TASKS_OF_EMPLOYEE:`${SERVER_URL}/job/task/v2/`,
  JOB_IDS: `${SERVER_URL}/job/id/`,
  EXPORT_CSV: `${SERVER_URL}/job/export-csv/`,
  TABLE_COUNT: `${SERVER_URL}/job/table-records/`,
  USER_PREFERENCE: 'user_preference/view/',
  JOB_TAGS: `${SERVER_URL}/job/tags/`,
  REPORTS_USER_PREFERENCE: `${SERVER_URL}/t2b/accounts/user_preference/view/`,
  TASK_BULK_UPLOAD :`${SERVER_URL}/job/task/bulk-upload/`,
  JOB_BULK_UPLOAD :`${SERVER_URL}/job/bulk-upload/`,
  JOB_USER_PREFERENCE :`${SERVER_URL}/t2b/accounts/user_preference/view/`,
  JOB_NEW_USER_PREFERENCE :`t2b/accounts/user_preference/view/`,
  JOB_SUMMARY:'summary/',
  JOB_SUMMARY_DATA : `${SERVER_URL}/job/summary/`,
};
