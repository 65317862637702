import React, {PureComponent} from 'react';
import { Formik } from 'formik';
import { Form, FormText , Button } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader'; 
import {CardElement, injectStripe} from 'react-stripe-elements';


class AddCardForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const { saveCard } = this.props;
    setErrors({});
    setSubmitting(true);
    try {
      await saveCard(this.props.stripe);
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  render() {
    const { updateTutorial } = this.props;
    return (
      <div>
        <Formik
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => 
           {
            return(
              <Form onSubmit={handleSubmit}>
                <div style={{minHeight: '3vh'}}>
                  <FormText color="danger">
                    {errors &&  errors.non_field_errors}
                  </FormText>
                </div>
                <div className="add-card">
                  <CardElement />
                </div>
                <div className="mt-4">
                  <p className="add-card-text">
                    <i>
                      Your 14-day trial begins now. We perform a $1 pre-authorization
                      to ensure your credit card is valid. We won't actually charge your card
                      until the end of your trial. If you cancel your subscription within the 
                      trial period, you will not be charged.
                      If you don't cancel by day 14, your card will be charged at the rate of $4
                      per user per month. You can cancel your subscription at any time.
                    </i>
                  </p>
                </div>
                <div className="mt-4 text-right">
                  <span className="link mr-4 cursor-pointer" onClick={() => updateTutorial()}>
                    SKIP
                  </span>
                  <Button
                    type='submit'
                    color='primary'
                  >
                    Save <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                </div>
              </Form>
            );}}
          />
    </div>);
  }
}

export default injectStripe(AddCardForm);
