import React from 'react';

import PropTypes from 'prop-types';
import Table from 'reactstrap/lib/Table';
import SectionLoaderAtom from 'atoms/SectionLoader';

const AccountAuditTableComponent  = (props) => {
  const { data, isAuditLogsLoading } = props;
  const dataKeyMappings = {
    'trial_end': 'Trial Period End Date',
    'suspension_date': 'Suspension Date',
    'close_date' : 'Closing Date',
    'plan' : 'Plan',
    'commercial_status': 'Commercial Status',
    'source_id': 'Source Id'
  };

  if(isAuditLogsLoading) return <SectionLoaderAtom active />;
  return (              
    <div className='table-kiosk-log '>
      <Table  className="table-emp table table-sm table-hover table-kiosk-audit pt-5" hover responsive size="sm">
        <thead className="Header-col">
          <tr>
            <th>Action</th>
            <th>Date &amp; time</th>
            <th>Modified by</th>
            <th>Previous Value</th>
            <th>Updated Value</th>
          </tr>
        </thead>
        <tbody>
          {(data.length > 0)  && data.map((item)=> {
            return (<tr key={item.id}>
              <td>
                {Object.keys(item.old_value).map((i) => (<div>{dataKeyMappings[i]}</div>))}
              </td>
              <td>{item.create_date_display}</td>
              <td>{item.user}</td>
              <td>{Object.values(item.old_value).map((i, index) => {

                  if(Object.keys(item.old_value)[index] === 'kiosk_user'){
                    return (<div>{i.toString()}</div>);
                  }
                  return i ? <div>{i.toString()}</div> : 'None';/*(<div>{i ? i : 'None'}</div>);*/
                  }
                )}
              </td>
              <td>{Object.values(item.new_value).map((i, index) => {
                  if(Object.keys(item.new_value)[index] === 'kiosk_user'){
                    return (<div>{i.toString()}</div>);
                  }
                  return (<div>{i ? i.toString() : 'None'}</div>);
                  }
                )}
              </td>
            </tr>
            )})}
          {data && !data.length &&
            <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
          }
        </tbody>
      </Table>
    </div>
    
  );
};

AccountAuditTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccountAuditTableComponent;
