
import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import moment from 'moment';
import cookie from "react-cookies";


import { toast } from 'react-toastify';

import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as AdjustmentTimecardDucks from 'ducks/timecards/adjustmentTimecard';
import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';
import * as UserDucks from 'ducks/accounts/user';
import * as TimecardDucks from 'ducks/timecards/timecard';

import AdjustmentHourFormModalComponent from 'components/timecards/dailyTimecardList/AdjustmentHourFormModalComponent';
import SectionLoaderAtom from 'atoms/SectionLoader';
import DailyTimecardTableComponent from 'components/timecards/customDailyTimecardList/TableV2';
import SessionMapModalComponent from 'components/activities/activityTrackList/SessionMapModal';
import { MSGS } from 'constants/timecards';

import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

/**
 * DailyTimecardListPage -> DailyTimecardListSection
 *
 * Components:
 *    - {@link DailyTimecardTableComponent}
 *
 * State:
 *    - daily timecard list
 *
 * Actions:
 *    None
 */
class DailyTimecardListSection extends React.Component {
  constructor(props) {
    super(props);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.trackingToggle = this.trackingToggle.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.resetModalData = this.resetModalData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.update = this.update.bind(this);
    this.approveDailyTimecard = this.approveDailyTimecard.bind(this);
    this.disapproveDailyTimecard=this.disapproveDailyTimecard.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
    this.state = {
      isTrackingModalLoading: false,
      isLocationLoading: false,
      permission: true,
      isModalOpen: false,
      idSelected:{},
      dateSelected:null,
      adjustmentHourView:false
    };
  }

  /**
 * Retrieves the location address for a given marker.
 * If the address is not present, it fetches the address from the activity track.
 * @param {Object} marker - The marker object containing location information.
 * @returns {Promise|null} - A promise if the address needs to be fetched; otherwise, null.
 */
  getLocationAddress(marker) {
    if(!marker.address){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker, GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }

  /**
 * Retrieves the address for a specific marker.
 * @param {Object} data - The marker data for which to get the address.
 * @returns {Promise} - A promise that resolves with the address data.
 */
  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  /**
 * Toggles the modal for viewing or editing adjustment hours.
 * @param {Object} data - The data associated with the adjustment.
 * @param {string} mode - The mode of the modal ('Edit', 'View', etc.).
 * @param {Object} childItem - The child item related to the adjustment.
 * @returns {boolean} - Returns true if the modal state changes.
 */
  toggleModal(data,view) {
    const { isModalOpen }=this.state;
    data && this.setState({ idSelected:data.ids });
    if(!isModalOpen){
      return this.props.AdjustmentTimecardDucks.getAdjustmentTypesTimecards({ daily_timecard_id: data.ids, paginate: false })
        .then(() => {
          this.setState({ isModalOpen: !isModalOpen,  dateSelected:data.date });
          if( view === 'view'){
            this.setState({ adjustmentHourView: true });
          }
          else{
            this.setState({adjustmentHourView:false});
          }
        })
    }
    this.setState({isModalOpen: !isModalOpen});
    return true;    
  }

  /**
 * Updates the adjustment hours based on the provided data.
 * @param {Object} data - The data to update.
 * @returns {Promise} - A promise that resolves when the update is complete.
 */
  update(data){
    const { isModalOpen, idSelected, dateSelected } = this.state;
    const { location: { query } } = this.props;
    const { router: { route: { match: { params },  } } } = this.context;


    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));

   

    const detail={
      date:dateSelected,
      employee_id:params.id || query.employee_id,
      adjustments:data.adjustmentHour,
    }
    return this.props.AdjustmentTimecardDucks.putAdjustmentTypesTimecards({...detail,id:idSelected})
      .then(() => {
        this.setState({ isModalOpen:!isModalOpen});
        toast.success("Adjustment Hours successfully updated");
        this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({ ...query,
          department_ids: JSON.stringify(departmentIdsIntegers),
          team_ids: JSON.stringify(teamIdsIntegers),
id: params.id, paginate: false });
      })
      .catch((err) => {
        toast.error(err.daily_timecard_id);
        toast.error(err.daily_timecard);
        toast.error(err.total_hours);
        toast.error(err.adjustment_type);
        if(err.non_field_errors.length){
          toast.error(err.non_field_errors[0]);
        }
      });
  }



/**
 * Approves a daily timecard.
 * @param {Object} data - The data for approval.
 * @returns {Promise} - A promise that resolves when the approval is complete.
 */
  approveDailyTimecard(data) {
    const { location: { query } } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    return this.props.DailyTimecardDucks.approveDailyTimecard(data)
      .then(() => {
        toast.success(MSGS.DAILY_TIMECARD_APPROVAL_SUCCESS);
        this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers), id: params.id, paginate: false });
      });
  }


  

/**
 * Disapproves a daily timecard.
 * @param {Object} data - The data for disapproval.
 * @returns {Promise} - A promise that resolves when the disapproval is complete.
 */
  disapproveDailyTimecard(data) {
    const { location: { query } } = this.props;
    const { router: { route: { match: { params } } } } = this.context;

    const cookieDepartmentId = cookie.load('departmentId');
    const cookieTeamId = cookie.load('teamId');
    let department_ids = cookieDepartmentId ? cookieDepartmentId : query?.department_ids;
    let teams_ids = cookieTeamId ? cookieTeamId : query?.team_ids;
  
    let departmentIdsArray;
    let teamIdsArray;
    if(department_ids){
     
      if(Array.isArray(department_ids)){
        departmentIdsArray= department_ids;
      } 
      else{
        departmentIdsArray= [department_ids]
      }
    }
    else{
      departmentIdsArray=[];
    }
    const departmentIdsIntegers = departmentIdsArray.map(id => parseInt(id, 10));
   
    if(teams_ids){
      if(Array.isArray(teams_ids)){
        teamIdsArray= teams_ids;
      } 
      else{
        teamIdsArray= [teams_ids]
      }
    }
    else{
      teamIdsArray=[];
    }
    const teamIdsIntegers = teamIdsArray.map(id => parseInt(id, 10));


    return this.props.DailyTimecardDucks.disapproveDailyTimecard(data)
      .then(() => {
        toast.success(MSGS.DAILY_TIMECARD_DISAPPROVAL_SUCCESS);
        this.props.DailyTimecardDucks.getCustomDailyTimecardsV2({ ...query,
          department_ids:JSON.stringify(departmentIdsIntegers),
        team_ids:JSON.stringify(teamIdsIntegers), id: params.id, paginate: false });
      });
  }

  /**
 * Resets the modal data.
 * @returns {Promise} - A promise that resolves when the reset is complete.
 */
  resetModalData(){
    return this.props.ActivityTrackDucks.resetSessionInfo();
  }


  /**
   * Handles the click event on a marker.
   * If the marker has an address, it toggles the session info window.
   * If not, and if showBeacon is true, it retrieves the location address.
   *
   * @param {Object} marker - The marker object that was clicked.
   * @param {boolean} showBeacon - Flag indicating whether to show the beacon information.
   */
  handleMarkerClick(marker, showBeacon) {
    // If location address already exists then just toggle
    if (marker.address) return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
     if(showBeacon) return this.getLocationAddress(marker);
  }

  
  /**
   * Toggles the tracking modal and fetches job activity track data.
   * @param {Object} data - The data object containing job details.
   */
  trackingToggle(data) {
    const { isTrackingOpen } = this.state;
    this.setState({ isTrackingOpen: !isTrackingOpen });
    if (data && data.id) {
      this.setState({ isTrackingModalLoading: true, isLocationLoading: true });
      this.props.ActivityTrackDucks.getJobActivityTrack(data)
        .then(() => {
          this.setState({ permission: true });
          const { sessionInfo } = this.props;
          const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
          this.codeLatLng(arr);
          this.props.GeoFenceDucks.getActivityTrackGeofences(data)
            .then(() => {
              this.setState({ isTrackingModalLoading: false })
            })
            .catch(() => this.setState({ isTrackingModalLoading: false, isLocationLoading: false }));
        })
        .catch((err) => {
          this.setState({ permission: false, isLocationLoading: false });
        })
    }
  }


  /**
   * Processes latitude and longitude to fetch activity location addresses.
   * @param {Array} array - An array of location objects.
   * @param {string} type - The type of punch-in.
   */
  codeLatLng(array) {
    const updatedArr = array.length ? array.filter((i)=> i.position && i.position.lat !== undefined && i.position.lng !== undefined) : array;
    if(!updatedArr || !updatedArr.length) return this.setState({ isLocationLoading: false });
    this.setState({ isLocationLoading:  true });
    Promise.all(updatedArr.map((item, index) =>
      setTimeout(()=>{this.props.ActivityTrackDucks.getActivityLocationAddress(item, GeocodingOption)
        .then((res) => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...item,
            address: selectedMarkerAddress,
          })
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })
             
          }
        })
        .catch(() => {
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })            
          }
        })
      },100*(index+1))
    ))
      .catch(() => this.setState({ isLocationLoading:  false }));
    return null;
  }


   /**
   * Toggles the information window for a given marker.
   * @param {Object} marker - The marker to toggle.
   */
  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleSessionInfoWindow(marker);
  }

  render() {
    const {
      isLoading,
      dailyTimecardList,
      location,
      basicSettingDetail,
      sessionInfo,
      selectedMarkerAddress,
      geoFenceDetail,
      payPeriodTotal,
      adjustmentTimecardList,
      adjustmentTypesList,
      dateFormat,
      totalAdjustmentsListView
    } = this.props;

    const { isTrackingOpen, isModalOpen, isTrackingModalLoading, isLocationLoading, permission, dateSelected, adjustmentHourView } =this.state;
    
    let date;
    if(Object.keys(basicSettingDetail).length){
      date = moment(dateSelected).format(basicSettingDetail.date_format_display);
    }
    const adjustmentTimecard = adjustmentTimecardList.length ? adjustmentTimecardList : [];
    
    let newAdjustmentTimecard = adjustmentTimecardList;
    let newAdjustmentTypeList = adjustmentTypesList;
    if(adjustmentTypesList.length !== adjustmentTimecard.length){
      const adjustmentIds = adjustmentTimecard.map((i) => i.adjustment_type_id);
      const remainingList = adjustmentTypesList.length ? adjustmentTypesList.filter((i) => adjustmentIds.indexOf(i.id) < 0) : null;
      const newRemainingList = remainingList && remainingList.map((i, index) => ({ ...i,
        adjustment_type: null,
        adjustment_type_id: i.id,
        daily_timecard :null,
        daily_timecard_id : null,
        double_over_time_hours : 0,
        double_over_time_mins : 0,
        id : null,
        over_time_hours : 0,
        over_time_mins : 0,
        regular_time_mins : 0,
        regular_time_hours : 0,
      }));
      newAdjustmentTimecard = adjustmentTimecard.concat(newRemainingList);
      newAdjustmentTypeList = adjustmentTypesList.length ? adjustmentTypesList.concat(newRemainingList) : [];
    }
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <DailyTimecardTableComponent
          data={dailyTimecardList}
          location={location}
          basicSettingDetail={basicSettingDetail}
          trackingToggle={this.trackingToggle}
          payPeriodTotal={payPeriodTotal}
          approveDailyTimecard={this.approveDailyTimecard}
          toggleModal={this.toggleModal}
          disapproveDailyTimecard={this.disapproveDailyTimecard}
          userTimecardFilters={this.props.userTimecardFilters}
          totalAdjustmentsListView={totalAdjustmentsListView}
        />
        {isTrackingOpen &&
          <SessionMapModalComponent
            isOpen={isTrackingOpen}
            sessionInfo={sessionInfo}
            onMarkerClick={this.handleMarkerClick}
            onMarkerClose={this.handleMarkerClick}
            toggle={this.trackingToggle}
            selectedMarkerAddress={selectedMarkerAddress}
            geoFenceDetail={geoFenceDetail}
            isModalLoading={isTrackingModalLoading}
            toggleInfoWindow={this.toggleInfoWindow}
            isLocationLoading={isLocationLoading}
            resetModalData={this.resetModalData}
            getLocationAddress={this.getLocationAddress}
            permission={permission}
            userExtraData={this.props.userExtraData}
            getAddressForMarker = {this.getAddressForMarker}
            accessSidemenu={this.props.accessSidemenu}
          />
        }
        { isModalOpen && adjustmentTypesList.length &&
          <AdjustmentHourFormModalComponent
            isOpen={isModalOpen}
            toggle={this.toggleModal}
            dateFormat={dateFormat}
            adjustmentTypesList = { newAdjustmentTypeList }
            adjustmentTimecardList = {newAdjustmentTimecard}
            initialValues = {{adjustmentHour:newAdjustmentTimecard}}
            update={this.update}
            date={date}
            dateSelected={dateSelected}
            edit
            adjustmentHourView={adjustmentHourView}
            location={location}
          />
        }
      </section>
    );
  }
};

const mapStateToProps = state => ({
  dailyTimecardList: DailyTimecardDucks.dailyTimecardList(state),
  payPeriodTotal: DailyTimecardDucks.payPeriodTotalV2(state),
  location: RouteDucks.location(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  geoFenceDetail: GeoFenceDucks.geoFenceDetail(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  adjustmentTimecardList: AdjustmentTimecardDucks.adjustmentTimecardList(state),
  userExtraData: UserDucks.userExtraData(state),
  accessSidemenu: UserDucks.accessSidemenu(state),
  userTimecardFilters : TimecardDucks.userTimecardFilters(state),
  totalAdjustmentsListView: DailyTimecardDucks.totalAdjustmentsListView(state),
});

const mapActionsToProps = dispatch => ({
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  AdjustmentTimecardDucks: bindActionCreators(AdjustmentTimecardDucks, dispatch),
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

DailyTimecardListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  dailyTimecardList: PropTypes.array.isRequired,
  payPeriodTotal : PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  selectedMarkerAddress: PropTypes.string,
  sessionInfo: PropTypes.object,
  geoFenceDetail: PropTypes.object,
};

DailyTimecardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(DailyTimecardListSection);
