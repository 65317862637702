import { createSelector } from 'reselect';

import { DATE_FORMAT_MAPPINGS } from 'constants/records';
import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_BASIC_SETTING = 'vendors/basicSetting/GET_BASIC_SETTING';
const GET_BASIC_SETTING_FULFILLED = 'vendors/basicSetting/GET_BASIC_SETTING_FULFILLED';
const PUT_BASIC_SETTING = 'vendors/basicSetting/PUT_BASIC_SETTING';
const PUT_BASIC_SETTING_FULFILLED = 'vendors/basicSetting/PUT_BASIC_SETTING_FULFILLED';


export function getBasicSetting() {
  return {
    type: GET_BASIC_SETTING,
    payload: axios.get(URLS.BASIC_SETTING),
  };
}

export function putBasicSetting(data) {
  return {
    type: PUT_BASIC_SETTING,
    payload: axios.put(URLS.BASIC_SETTING, data),

  };
}


const defaultState = {
  detail: {},
};

function GET_BASIC_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_BASIC_SETTING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_BASIC_SETTING_FULFILLED]: GET_BASIC_SETTING_FULFILLED_REDUCER,
  [PUT_BASIC_SETTING_FULFILLED]: PUT_BASIC_SETTING_FULFILLED_REDUCER,
};


const basicSettingSelector = state => state.vendors.basicSetting;

export const basicSettingDetail = createSelector(
  basicSettingSelector,
  instance => instance.detail,
);

export const dateFormat = createSelector(
  basicSettingDetail,
  instance => DATE_FORMAT_MAPPINGS[instance.date_format_display],
);

export const dateFormatDisplay = createSelector(
  basicSettingDetail,
  instance => instance.date_format_display,
);

export const timeFormat24Hrs = createSelector(
  basicSettingDetail,
  instance => instance.time_format_24_hrs,
);

export const timecardDecimalView = createSelector(
  basicSettingDetail,
  instance => instance.timecard_decimal_view,
);

export const timeFormat = createSelector(
  basicSettingDetail,
  instance => instance.time_format_24_hrs ? 'H:i' : 'h:i K',
);

export default createReducer(defaultState, handlers);
