import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as VendorDucks from 'ducks/vendors/vendor';
import * as AddressDucks from 'ducks/vendors/address';
import * as UserDucks from 'ducks/accounts/user';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import VendorFormSection from 'sections/vendors/form/Form';

import { NAV } from 'constants/vendors';

/**
 * VendorFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link VendorFormSection}
 *
 * Fetch Needs:
 *    - get vendor detail
 */
class VendorFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.toggleInfo = this.toggleInfo.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_profile");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_profile", !isInfoOpen);
  }

  loadData(){
    const { profile: { company } } = this.props;
    this.setState({ isLoading: true });
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.profile'}),
      this.props.VendorDucks.getVendor({ id: company.id })
    ])
      .then(() => {
        this.props.VendorDucks.getIndustries();
        this.props.AddressDucks.getCountries()
          .then(() => {
            const { countriesList, vendorDetail } = this.props;
            this.props.AddressDucks.getStates({
              country_id: vendorDetail.country_id || countriesList[0].id,
            });
            this.props.AddressDucks.getMailingStates({
              country_id: vendorDetail.mailing_country_id || countriesList[0].id,
            });
            this.setState({ isLoading: false });
          })
          .catch(() => this.setState({ isLoading: false }));
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  render() {
    const { isLoading, isInfoOpen } = this.state;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout 
        navInfo={NAV.VendorEditFormPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <VendorFormSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: UserDucks.profile(state),
  countriesList: AddressDucks.countriesList(state),
  vendorDetail: VendorDucks.vendorDetail(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
  AddressDucks: bindActionCreators(AddressDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

VendorFormPage.propTypes = {
  countriesList: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  vendorDetail: PropTypes.object.isRequired,
  VendorDucks: PropTypes.object.isRequired,
  AddressDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorFormPage);
