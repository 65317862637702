import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import PropTypes from 'prop-types';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as AdjustmentTypesDucks from 'ducks/vendors/adjustmentTypes';

import PaginationComponent from 'components/common/Pagination';
import AdjustmentTypesTableComponent from 'components/vendors/adjustmentTypes/Table';
import AdjustmentTypesFormModalComponent from 'components/vendors/adjustmentTypes/FormModal';
import SectionLoaderAtom from 'atoms/SectionLoader';

/**
 * AdjustmentTypesListPage -> AdjustmentTypesFormSection
 *
 * Components:
 *    - {@link AdjustmentTypesTableComponent}
 
 *
 */
class AdjustmentTypesListSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.toggle = this.toggle.bind(this);
    this.addtoggle = this.addtoggle.bind(this);
    this.deleteActivity = this.deleteActivity.bind(this);
    this.state = { isOpen: false };
  }
  
  update(data) {
    const { userExtraData, location: { query }} = this.props;
    const detail={...data,
      company_name:userExtraData.company.id,
    }
    return this.props.AdjustmentTypesDucks.putAdjustmentTypes({...detail,id:data.id})
      .then(() =>{
        this.props.fetchData(query);
        toast.success("successfully updated");
        this.setState({ isOpen: false, isLoading: false });
        
      });  
  }
  
  toggle(data) {
    const { isOpen } = this.state;
    if (isOpen) return this.setState({ isOpen: !isOpen });
    return this.props.AdjustmentTypesDucks.getAdjustmentTypesDetail({id:data.id})
      .then(() => this.setState({ isOpen: !isOpen }));
  }
  
  addtoggle(){
    const { isOpen } = this.state;
    this.setState({isOpen:!isOpen});
  }

  deleteActivity(data){
    const { location: { query,pathname }, userExtraData } = this.props;
    const { router: { history } }= this.context;
    return this.props.AdjustmentTypesDucks.deleteAdjustmentTypes({...query,id:data.id})
      .then(() =>{
        toast.success("Adjustment actvitiy successfully deleted");
        this.props.AdjustmentTypesDucks.getAdjustmentTypes({...query, company_id:userExtraData.company.id})
        history.push(pathname);    
      })
      .catch((err) => toast.error(err.non_field_errors[0]))
  }

  render() {
    const { isLoading, adjustmentTypesList, adjustmentTypesDetail, location } = this.props;
    const { isOpen } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>  
        <AdjustmentTypesTableComponent
          update={this.update}
          isOpen={isOpen}
          toggle={this.toggle}
          data={adjustmentTypesList}
          deleteActivity={this.deleteActivity}
          saveUserOrderPreference = {this.props.saveUserOrderPreference}
          adjustmentIdsList={this.props.adjustmentIdsList}
          getAdjustmentIds={this.props.getAdjustmentIds}
        />
        {isOpen &&
          <AdjustmentTypesFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            update={this.update}
            initialValues={adjustmentTypesDetail}
            edit
          />
        }
        <PaginationComponent
          count={adjustmentTypesList.count}
          location={location}
          cookieKey="adjustment_type_page_size"
        />
      </section>  
    );
  }
}
        
const mapStateToProps = state => ({
  userExtraData: UserDucks.userExtraData(state),
  adjustmentTypesList: AdjustmentTypesDucks.adjustmentTypesList(state),
  adjustmentTypesDetail: AdjustmentTypesDucks.adjustmentTypesDetail(state),
  location: RouteDucks.location(state),
});

const mapActionToProps = dispatch => ({
  AdjustmentTypesDucks: bindActionCreators(AdjustmentTypesDucks, dispatch),
});

AdjustmentTypesListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

AdjustmentTypesListSection.propTypes = ({
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
});

export default connect(
  mapStateToProps,
  mapActionToProps,
)(AdjustmentTypesListSection);
