import React from 'react';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import closeimage from './Close-Cross.png';
import styles from "./styles.module.scss"

/**
 * EmployeeListPage -> EmployeeFilterSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class ActivationLinkModal extends React.PureComponent {

  render() {
    const { isOpen, toggle , loginLink} = this.props;   
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
        backdrop="static"
      >
        <ModalHeader>Login Link
         <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <ModalBody className="ml-4 mr-4">
            <div className="pos-relative">
             {loginLink}
            </div>
          </ModalBody>
          <ModalFooter className={`${styles['label-part']} float-left mr-4`}>
            **Please note the following link is active for next 48 hours.
          </ModalFooter>

      </Modal>
    );
  }
};





export default ActivationLinkModal;
