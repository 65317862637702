import React from "react";
import { toast } from "react-toastify";
import connect from "react-redux/lib/connect/connect";
import queryString from "query-string";

import * as RouteDucks from "ducks/routes";
import * as UserDucks from 'ducks/accounts/user';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import { NAV, TABS } from "constants/applicants";
import TwoColumnLayout from "layouts/TwoColumn";
import TabsComponent from 'components/common/Tabs';
import SectionLoaderAtom from 'atoms/SectionLoader';

import listUseCase from "applicants/usecases/listUsecase";
import ApplicantsAPIGateway from "applicants/gateways/applicants";
import ApplicantForm from "applicants/components/applicantForm";
import DocumentsTab from "employees/components/DocumentsTab";
import LearningDocumentsTab from "employees/components/LearningDocumentsTab";

class AddApplicant extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      applicantData: {},
      subCompanyList:[],
      employeeTagsList : null,
      countryList: [],
      stateList: [],
      phoneNumberPlain: null,
      roleList: [],
      parent: '',
      refrshDocTabFlag: false,
      refrshLearningDocTabFlag:false,
    };
    this.applicantsAPIGateway = new ApplicantsAPIGateway();
    this.usecase = new listUseCase(this.applicantsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.handleCountrySelect = this.handleCountrySelect.bind(this);
    this.formatPhoneNumber = this.formatPhoneNumber.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
  }

  componentWillMount = () => {
    this.listenListUsecase();
    this.setParentUrl();
    const {
      location: { search, pathname },
      match: { params },
    } = this.props;
    if(pathname.indexOf("create") < 0){
      this.usecase.getApplicantDetails(params.id);
    }
  }
  

  componentDidMount() {
    const { userExtraCompanyData } = this.props;
    const companyId = userExtraCompanyData.id;
    this.usecase.getAllSubcompanies();
    this.usecase.getEmployeeTags({paginate:false, tag_type: 3});
    this.usecase.getCountryList();
    this.usecase.getRoleList({ company_id: JSON.stringify([companyId]), paginate: false });
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.applicantData !== this.state.applicantData){
      this.usecase.getStateList({ country_id: this.state.applicantData?.country, paginate: false })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.fetchTabData(nextProps);
    }
  }

  fetchTabData() {
    const {match: {params}} = this.props;
    if (params.tab === "documents") {
      this.setState({ refrshDocTabFlag: true });
      setTimeout(()=> this.setState({refrshDocTabFlag:false}),1000);
    }else if(params.tab === 'learning-documents'){
      this.setState({refrshLearningDocTabFlag:true});
      setTimeout(()=> this.setState({refrshLearningDocTabFlag:false}),1000);
    }else{
      this.usecase.getApplicantDetails(params.id);
    }
  }


  setParentUrl() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search); 
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
  

  handleCountrySelect(id){
    this.usecase.getStateList({ country_id: id, paginate: false })
  }


  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.toString().replace(/[^\d]/g, ""); //replace all characters except numbers
    const phoneNumberLength = phoneNumber.length;

    this.setState({phoneNumberPlain: phoneNumber.slice(0, 10)});
    
    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "SUBCOMPANY_LIST":
          this.setState({ subCompanyList: event.data });
          break;

        case 'GET_TAGS_LIST':
          this.updateState('employeeTagsList', event.data);
          break;

        case 'NEW_TAG_ADDED':
          toast.success('New tag created successfully');
          break;

        case 'GET_COUNTRY_LIST':
          this.updateState('countryList', event.data);
          break;
        
        case 'GET_STATE_LIST':
          this.updateState('stateList', event.data);
          break;

        case 'GET_ROLE_LIST':
          this.updateState('roleList', event.data);
          break;

        case "GET_APPLICANT_DETAILS_SUCCESS":
          this.setState({ applicantData: event.data});
          break;

        case "GET_APPLICANT_DETAILS_FAILURE":
          toast.error("Something went wrong")
          break;

        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;

        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  render() {
    const {
      location,
      match: { params },
      userExtraCompanyData,
      isOrganizationVisible,
      dateFormat,
      history,
      location: {search, pathname},
      companyPermissions,
    } = this.props;
    const {
      isLoading,
      applicantData,
      subCompanyList,
      employeeTagsList,
      countryList,
      stateList,
      roleList,
      parent,
      phoneNumberPlain,
      refrshDocTabFlag,
      refrshLearningDocTabFlag,
    } = this.state;

    const employeeRole = roleList.length ? roleList.find((i) => i.name === 'Applicant') : {};
    const title = applicantData && params.id && Object.keys(applicantData).length ? applicantData.name() : NAV.ApplicantEditPage.title;
    let tabs =[...TABS];
    if (companyPermissions && companyPermissions?.communication_center) {
      tabs = [...tabs, { key: "documents", label: "Documents" }];
    }
    if (companyPermissions && companyPermissions?.learning_center) {
      tabs = [...tabs, { key: "learning-documents", label: "Learning Documents" }];
    }

    return (
      <TwoColumnLayout
      navInfo={params.id ? {...NAV.ApplicantEditPage, title, parent} : NAV.ApplicantCreatePage}
      fetchData={this.fetchTabData}
      >
        <div className="mt-4 ml-3 mr-4">
          {params.id && 
          <TabsComponent 
            tabItems={tabs} 
            active={params.tab} 
            onClick={(key) => history.push(`/applicant/${params.id}/edit/${key}${search}`)}
            />
          }
          {(params.tab === "detail" || pathname.indexOf("create")>-1) && (isLoading ? <SectionLoaderAtom active /> : 
          <ApplicantForm 
            usecase={this.usecase}
            params={params}
            location={location} 
            history={history}
            initialValues={params.id ? applicantData : 
              {
                employee_role: employeeRole && employeeRole.id,
                is_active: true,
                phone_ext: userExtraCompanyData.phone_ext,
                company: userExtraCompanyData.id,
              }
            }

            isOrganizationVisible={isOrganizationVisible}
            companyList={subCompanyList}
            employeeTagsList={employeeTagsList}
            dateFormat={dateFormat}
            countryList={countryList}
            stateList={stateList}
            handleCountrySelect={this.handleCountrySelect}
            formatPhoneNumber = {this.formatPhoneNumber}
            phoneNumberPlain={phoneNumberPlain}
            roleList={roleList}
          />)
          }
        </div>
          {params.tab === "documents" && (
            <DocumentsTab
              employeeId={params.id}
              refrshFlag={refrshDocTabFlag}
            />
          )}
          {params.tab === "learning-documents" && (
            <LearningDocumentsTab
              employeeId={params.id}
              refrshFlag={refrshLearningDocTabFlag}
            />
          )}
        </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  userExtraCompanyData: UserDucks.userExtraCompanyData(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  companyPermissions: UserDucks.companyPermissions(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddApplicant);
