import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Switch from "react-switch";
import styles from "./styles.module.scss"
import SectionLoaderAtom from 'atoms/SectionLoader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import PropTypes from "prop-types";
import closeicon from './Close-Cross.png';

class CustomAttributeToggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: {} };
  }

  componentWillReceiveProps(nextProps) {
    const {
      data: { defaultVals, accessors }
    } = nextProps;
     /*defaultVals is an object containing keys and values received from backend, accessors is an array of keys listed in the order that needs to be displayed on the screen*/
    if (defaultVals && accessors.length) {
     let checked = Object.keys(defaultVals).sort((a,b)=>
                   {if(accessors.indexOf(a) > accessors.indexOf(b))
                      return 1; 
                    else 
                      return -1;
                    }).reduce(function(Obj, key) {  
                     Obj[key] = defaultVals[key];  
                     return Obj;  
                   }, {}); 
      /*keys of defaultVals object are sorted in the order of keys in accessors array and the sorted object is set in this.state.checked*/
      this.setState({ checked });
    }
  }

  handleChange(accessor, bool) {
    if(accessor == 'meal_questionnaire' && bool == true){
      this.setState({ checked: { ...this.state.checked, [accessor]: bool, ['feedback']: true } });
      this.props.onChange(this.state.checked);
    }
    else if(accessor == 'feedback' && bool == false){
      this.setState({ checked: { ...this.state.checked, [accessor]: bool, ['meal_questionnaire']: false } });
      this.props.onChange(this.state.checked);
    }
    else{
      this.setState({ checked: { ...this.state.checked, [accessor]: bool } });
      this.props.onChange(this.state.checked);
    }
  }

  render() {
    /*accessors is an array of keys listed in the order that needs to be displayed and this.props.data.labels are the labels for the keys in accessors*/
    return (
      <Modal 
        isOpen={this.props.toggle} 
        className={styles["attribute-form"]}
      >
        <ModalHeader className={styles["attribute-form__header"]}>
          Custom Setting
          <img src={closeicon} className="close_image" height="14px" width="14px" alt="Close" onClick={() => this.props.setToggle()} />
        </ModalHeader>
        {this.props.isLoading ? <SectionLoaderAtom active /> :
        <ModalBody className={`${styles["attribute-form__body"]} ml-4 mr-4 pl-1 pr-2`}>
          <div className={styles["attribute-form__container"]}>
            {this.props.data.accessors.map((item, idx) => (
              <li key={idx} className={styles["attribute-form__action"]}>
                <span className={styles["attribute-form__action--label"]}>
                  {this.props.data.labels[idx]}
                </span>
                <Switch 
                  onChange={checked => this.handleChange(item, checked)} 
                  checked={this.state.checked[item]} 
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={18}
                  width={36}
                  onColor='#09819A'
                  />
              </li>
            ))}
          </div>
        </ModalBody>
        }
        <ModalFooter className="mr-4 pr-2 pb-4">
          <Button disabled={this.props.isLoading || this.props.buttonLoading} className="py-2" onClick={() => this.props.setPermission()} color="secondary">
            Submit <ButtonLoaderAtom active={this.props.buttonLoading} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

CustomAttributeToggler.propTypes = {
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    accessors: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultVal: PropTypes.arrayOf(PropTypes.bool)
  }),
  onChange: PropTypes.func
};

export default CustomAttributeToggler;
