import React from 'react';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Alert from 'reactstrap/lib/Alert';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import DownloadImage from './icon-download.png';
import FolderSettingsImage from './icon-configure.png';

import styles from './styles.module.scss';

const PluginQBComponent = (props, context) => {
  const { toggle, downloadConnectors, createProfile, redirect, isLoading, filePath, isCreatingProfile } = props;
  return (
    <div>
      <Row className="mt-2 m-0">
        <Alert color="warning">
          Note : Integration available and compatible with QuickBooks Desktop  Enterprise/ Pro/ Premier versions of Year 2015 or above.
        </Alert>
      </Row>
      <Row>
        <Col sm="12" className={styles.steps}>
          <Row className={`${styles.steps__item} mt-3`}>
            <Col sm="3" className="align-self-center mb-2">
              <div className={styles.steps__btn} tabIndex="0" role="button" onClick={()=> redirect()}>
                <div className={styles['steps__btn--text']}>
                  <p>Download</p>
                  <span>QuickBooks Desktop Applications</span>
                </div>
                <div className={styles['steps__btn--icon']}>
                  <img
                    className={styles['report-wrap--qbicon']}
                    src={DownloadImage} alt="Icon"
                  />
                </div>
              </div>
            </Col>
            <Col sm="9">
              <h4 className="m-0 pl-3"><b>Step 1: </b>You must already have QuickBooks Desktop Enterprise/Pro/Premier versions of 2015 or above installed on your Windows Desktop. If not please set it up before you proceed.</h4>
            </Col>
          </Row>
          <Row className={`${styles.steps__item} mb-0`}>
            <Col sm="3" className="align-self-center">
              <div
                className={filePath && filePath.file_path ? styles.steps__btn : styles['steps__btn']}
                tabIndex="0" role="button"
                onClick={()=> createProfile()}
              >
                <div className={styles['steps__btn--text']}>
                  <p>Create Profile <ButtonLoaderAtom active={isCreatingProfile} /></p>
                  <span>Create Your Profile</span>
                </div>
                <div className={styles['steps__btn--icon']}>
                  <img
                    className={styles['report-wrap--qbicon']}
                    src={DownloadImage} alt="Icon"
                  />
                </div>
              </div>
            </Col>
            <Col sm="9">
              <h4 className="m-0 pl-3">
                <b>Step 2: </b>
                Create your profile in order to get started. It will sync Worksana and Quickbooks.
              </h4>

            </Col>
          </Row>

          <Row className={`${styles.steps__item} mb-0 pt-3`}>
            <Col sm="3" className="align-self-center">
              <div className={styles.steps__btn} tabIndex="0" role="button" onClick={()=> toggle()}>
                <div className={styles['steps__btn--text']}>
                  <p>Configure <ButtonLoaderAtom active={isLoading} /></p>
                  <span>Click to configure</span>
                </div>
                <div className={styles['steps__btn--icon']}>
                  <img
                    className={styles['report-wrap--qbicon']}
                    src={FolderSettingsImage} alt="Icon"
                  />
                </div>
              </div>
            </Col>
            <Col sm="9">
              <h4 className="m-0 pl-3"><b>Step 3: </b> Click on the configure button and navigate to your QuickBooks Installation Directory to copy the file location path and save it.This will auto create your profile for the sync.</h4>
            </Col>
          </Row>
          <Row className={`${styles.steps__item} mb-0 pt-3`}>
            <Col sm="3" className="align-self-center">
              <div
                className={filePath ? styles.steps__btn : styles['steps__btn--disabled']}
                tabIndex="0" role="button"
                onClick={()=> downloadConnectors()}
              >
                <div className={styles['steps__btn--text']}>
                  <p>Connect</p>
                  <span>QuickBooks Web Connectors</span>
                </div>
                <div className={styles['steps__btn--icon']}>
                  <img
                    className={styles['report-wrap--qbicon']}
                    src={DownloadImage} alt="Icon"
                  />
                </div>
              </div>
            </Col>
            <Col sm="9">
              <h4 className="m-0 pl-3">
                <b>Step 4: </b>
                <ul className="pl-3">
                  <li>Click on the button to download the web connector file.</li>
                  <li>Double click on the file to open the web connector.</li>
                  <li>Copy the Web Connector Key/ Password from the previous step,then paste it to the password field in your web connector and press enter to save it.</li>
                </ul>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PluginQBComponent;
