import EventEmitter from 'lib/event-emitter';

const EVENT_TYPES = {
  SURVEY_LIST: 'SURVEY_LIST',
  SHOW_LOADER : 'SHOW_LOADER',
  HIDE_LOADER : 'HIDE_LOADER',
  TEMPLATE_DETAILS : 'TEMPLATE_DETAILS',
  SURVEY_DETAILS: 'SURVEY_DETAILS',
  SHOW_MODAL_LOADER : 'SHOW_MODAL_LOADER',
  HIDE_MODAL_LOADER : 'HIDE_MODAL_LOADER',
  DEPARTMENT_LIST: 'DEPARTMENT_LIST',
  TEAM_LIST : 'TEAM_LIST',
  GET_SURVEY_TEMPLATE_LIST_SUCESS: 'GET_SURVEY_TEMPLATE_LIST_SUCESS',
  SHOW_TEMPLATE_LOADER: 'SHOW_TEMPLATE_LOADER',
  HIDE_TEMPLATE_LOADER: 'HIDE_TEMPLATE_LOADER',
  DELETE_TEMPLATE_SUCCESS: 'DELETE_TEMPLATE_SUCCESS',
  SHOW_DELETE_TEMPLATE_LOADER: 'SHOW_DELETE_TEMPLATE_LOADER',
  HIDE_DELETE_TEMPLATE_LOADER: 'HIDE_DELETE_TEMPLATE_LOADER',
  SHOW_SUBMIT_SP_TEMPLATE_LOADER: 'SHOW_SUBMIT_SP_TEMPLATE_LOADER',
  HIDE_SUBMIT_SP_TEMPLATE_LOADER: 'HIDE_SUBMIT_SP_TEMPLATE_LOADER',
  SHOW_SP_TEMPLATE_SUCESS:'SHOW_SP_TEMPLATE_SUCESS'
};


class listUseCase{

  SurveyAPIGateway = null;

  constructor(SurveyAPIGateway){
    
    this.SurveyAPIGateway = SurveyAPIGateway;
    this.eventEmitter = new EventEmitter();

  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }


  toString(){

    return 'listUseCase';
  }

  async getSurveyDetail(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_MODAL_LOADER,
    });
    this.surveyDetails = await this.SurveyAPIGateway.getSurveyResponse(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SURVEY_DETAILS,
      data: this.surveyDetails,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_MODAL_LOADER,
    });
  }

  async getTemplateDetail(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_MODAL_LOADER,
    });
    this.surveyDetails = await this.SurveyAPIGateway.getTemplateDetails(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEMPLATE_DETAILS,
      data: this.surveyDetails,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_MODAL_LOADER,
    });
  }

  async getSurveysList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.surveyList = await this.SurveyAPIGateway.getSurveyList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.SURVEY_LIST,
      data: this.surveyList,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }
  
  async getSurveysTemplates(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_TEMPLATE_LOADER,
    });
    this.surveyTemplateList = await this.SurveyAPIGateway.getSurveyTemplates({...params, admin: true });
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_SURVEY_TEMPLATE_LIST_SUCESS,
      data: this.surveyTemplateList,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_TEMPLATE_LOADER,
    });
  }

  async getDepartmentList(params){
    this.departmentList = await this.SurveyAPIGateway.getDepartmentList({ ...params, paginate: false });
    this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.DEPARTMENT_LIST,
      departmentList: this.departmentList,
    });
  }

  async getTeamList(params){
    this.teamList = await this.SurveyAPIGateway.getTeamList({ ...params, paginate: false });
    this.teamList.splice(0, 0, { id: "", name: "All Teams" })
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEAM_LIST,
      teamList: this.teamList,
    });
  }

  async updateSpanishTemplateData(data, id) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_SUBMIT_SP_TEMPLATE_LOADER,
    });
    try{
      this.surveyData = await this.SurveyAPIGateway.updateSurveyData(data, id);
      this.eventEmitter.emit({
        type: EVENT_TYPES.SHOW_SP_TEMPLATE_SUCESS,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_SUBMIT_SP_TEMPLATE_LOADER,
      });
    }
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_SUBMIT_SP_TEMPLATE_LOADER,
    });
  
  }

  async deleteSurveyTemplate(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_TEMPLATE_LOADER,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_DELETE_TEMPLATE_LOADER,
    });
    try{
      this.deleteTemplate = await this.SurveyAPIGateway.deleteTemplate(params);
      this.eventEmitter.emit({
        type: EVENT_TYPES.DELETE_TEMPLATE_SUCCESS,
      });
    }
    catch(e){
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_TEMPLATE_LOADER,
      });
      this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_DELETE_TEMPLATE_LOADER,
      });
    }
    this.eventEmitter.emit({
        type: EVENT_TYPES.HIDE_DELETE_TEMPLATE_LOADER,
    });
  }

}

export default listUseCase;
