import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import ManualSessionTimelineSection from 'sections/activities/manualSession/Timeline';

import { NAV } from 'constants/activities';

/**
 * ManualSessionPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ManualSessionTimelineSection}
 *
 * Fetch Needs:
 *    None
 */
class ManualSessionPage extends React.Component {
  constructor(props) {
    super(props);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, parent: NAV.ManualSessionPage.parent, isInfoOpen: true };
  }

  componentWillMount() {
    const cookieKey = cookie.load("manual_session_info");

    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'activity.manual'});
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }


  setParentUrl(query) {
    const { timecardDetail } = this.props;
    const parent = `/timecard/list/${timecardDetail.id}/daily`;
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        if(parsedParams.employee){
          const a = JSON.parse(parsedParams.parent);
          this.setState({
            parent: `/timecard/list?${queryString.stringify(a)}`,
          });
        }
        else{
          this.setState({
            parent: `${parent}?${queryString.stringify(parsedParams)}`,
          });
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({ parent });
    }
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    return this.props.TimecardDucks.getTimecard(params)
      .then(() => {
        this.setState({ isLoading: false });
        this.setParentUrl(query);
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("manual_session_info", !isInfoOpen);
  }

  render() {
    const { timecardDetail, infoList } = this.props;
    const { isLoading, parent, isInfoOpen } = this.state;

    const breadcrumb = [
      ...NAV.ManualSessionPage.breadcrumb,
      { href: `/timecard/list/${timecardDetail.id}/daily`, label: 'Daily Timecards' },
      { label: 'New' },
    ];
    const description = Object.keys(timecardDetail).length && timecardDetail.employee &&
      timecardDetail.employee.user
      ? `${NAV.ManualSessionPage.description} ${timecardDetail.employee.user.first_name}`
      : NAV.ManualSessionPage.description;

    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.ManualSessionPage, breadcrumb, description, parent,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <ManualSessionTimelineSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  timecardDetail: TimecardDucks.timecardDetail(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

ManualSessionPage.propTypes = {
  location: PropTypes.object.isRequired,
  timecardDetail: PropTypes.object.isRequired,
  TimecardDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

ManualSessionPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ManualSessionPage);
