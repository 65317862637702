import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';
import Table from 'reactstrap/lib/Table';
import Button from 'reactstrap/lib/Button';


import { secondsToHours } from 'helpers/utils';
import {  secondsToHHMM } from 'helpers/utils';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EditIconAtom from 'atoms/EditIcon';
import styles from './styles.module.scss';
import {ReactComponent as TrackingIcon} from './tracking.svg';

/**
 * DailyTimecardListPage -> DailyTimecardListSection -> DailyTimecardTableComponent
 *
 * Props:
 *    - daily timecard list
 */
const DailyTimecardTableComponent = (props) => {
  const { data, payPeriodTotal, location: { pathname, query }, basicSettingDetail, trackingToggle, approveDailyTimecard, toggleModal, disapproveDailyTimecard, userTimecardFilters, totalAdjustmentsListView} = props;
  let pay_period_display;
  if(payPeriodTotal.meal_period_time<0){
      let period_display = payPeriodTotal.meal_period_time.toString().slice(1);
      let pay_display= secondsToHHMM(period_display);
      pay_period_display = `${pay_display}`;
  }
  if(payPeriodTotal.meal_period_time>=0){
        pay_period_display= secondsToHHMM(payPeriodTotal.meal_period_time);
  }


  /**
 * Determines the color for a table row based on the provided parameters.
 *
 * @param {Object} param - The parameters used to determine the color.
 * @param {string} param.date_display - The display type for the date (e.g., 'Weekly Total').
 * @param {string} param.color - The specified color.
 * @returns {string} The determined color for the table row.
 */
  const trColors = (param) => {
    let string = "";
    if (param.date_display === 'Weekly Total') {
      string = '#CCD2D5';
    }
    else if(param.color === '') {
      string = '#fff';
    }
    else {
      string = param.color;
    }
    return string;
  };
  return (
    <div className="ml-3 mr-4 pb-5">
      <small className="float-right mt-2 mb-1">
          * Adjustment activities do not count towards RT, OT, DOT totals.
      </small>
    <Table hover responsive size="sm" className="daily-timecard-table mt-1">
      <thead>
        <tr className="Header-col">
          <th className="text-center pl-3">Action</th>
          <th>Session Date</th>
          <th className="text-right">Total Punch-In Time</th>
          <th className="text-right">Total Meal-Period Time</th>
          <th className="text-right">Total Paid Time</th>
          <th className="text-right">Regular Time</th>
          <th className="text-right">Overtime</th>
          <th className="text-right">Double Overtime</th>
          <th className="text-center pr-3">Approve</th>
        </tr>
      </thead>
      <tbody  className="timeCard-table">
        {data && data.map((item) => item && item.child.map(childItem => {
          // let meal_period_display;            
          // if(childItem.meal_period_time < 0){
          //  let meal_period_time_display = childItem.meal_period_time.toString().slice(1);
          //  let meal_display= secondsToHHMM(meal_period_time_display);
          //   meal_period_display = `${meal_display}`;
          // }
          // if(childItem.meal_period_time >= 0){                
          //   meal_period_display = secondsToHHMM(childItem.meal_period_time);
          // }
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const d = new Date(childItem.date); 
          d.setHours(0, 0, 0, 0);
          const disableApprove = today <= d;
          return (
            <tr
              key={childItem.id}
              style={{backgroundColor: trColors(childItem)}}
              className={childItem.date_display === "Weekly Total" ? 'font-weight-bold' : ''}>
              {!childItem.adjustment_type && childItem.date_display !== "Weekly Total"
                ? <td title="View Tracking" className="text-center pb-0 pl-3">
                  <TrackingIcon
                    height="18"
                    width="18"
                    className="cursor-pointer mr-1"
                    onClick={() => trackingToggle(childItem)}
                  />
                </td>  
                : null 
              }
              {childItem.adjustment_type && !childItem.is_approved &&
              <td title="Edit Adjustment Hours" className="text-center pb-0 pl-3">
                <EditIconAtom 
                  height="18"
                  width="18"
                  className="cursor-pointer"
                  onClick={() => toggleModal(childItem)}
                />
              </td>
              }
              {childItem.date_display === "Weekly Total" &&
              <td />
              }
              {childItem.adjustment_type && childItem.is_approved &&
              <td title="View Adjustment Hours" className="text-center pb-0 pl-3">
                <EyeOpenIconAtom 
                  height="18"
                  width="18"
                  className="cursor-pointer"
                  onClick={() => toggleModal(childItem,'view')}
                />
              </td>
              }
              <td title={childItem.date_display}>
                {childItem.date_display === "Weekly Total"
                  ? <b>{childItem.date_display}</b>
                  : (<span>
                    {!childItem.adjustment_type && query.employee_id &&
                      <Link className={`${styles['date-display']}`}
                        to={{
                        pathname: `/activity/timecard/custom/list/${childItem.id}/split`,
                        search: queryString.stringify({
                          parent: `${pathname}?${queryString.stringify(query)}`,
                          employee_id: query.employee_id,
                          start_date: query.start_date,
                          end_date: query.end_date,
                          payroll_id: query.payroll_id,
                          is_approved: childItem.is_approved,
                          first_name: query.first_name,
                          last_name: query.last_name,
                        }),
                      }}>
                        {childItem.date_display}
                      </Link>
                    }
                    {childItem.adjustment_type &&
                      <p className="mb-0 mt-0">{childItem.date_display}</p>
                    }
                  </span>)
                }
              </td>
              <td
                className="text-right"
                title={
                  userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") ? secondsToHours(childItem.punch_in_time) : childItem.punch_in_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.punch_in_time)
                    : childItem.punch_in_time_display
                }
              >
                <b>
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                        ? secondsToHours(childItem.punch_in_time) : childItem.punch_in_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.punch_in_time)
                    : childItem.punch_in_time_display
                  }
                </b>
              </td>
              <td className="text-right">
              {!childItem.adjustment_type 
                ? <div>
                    {userTimecardFilters && userTimecardFilters.data
                     ?
                      ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                      ? <span>
                      {(childItem.meal_paid_time === 0 && childItem.meal_unpaid_time === 0) ?
                        <div>0.00</div>
                        : null
                      }
                      {childItem.meal_paid_time !==0 ?
                        <div>{childItem.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(childItem.meal_paid_time)}</div>
                        : null
                      }
                      {childItem.meal_unpaid_time !==0 ?
                        <div style={{color: '#EC4409'}}>{childItem.meal_paid_time !==0 ? 'U: ' : null}{(childItem.meal_paid_time=== 0 && childItem.meal_unpaid_time!==0) ? '-' : null}{secondsToHours(childItem.meal_unpaid_time)}</div>
                        :
                        null
                      }
                      </span>
                      : <span>
                      {(childItem.meal_paid_time === 0 && childItem.meal_unpaid_time === 0) ?
                        <div>00:00</div>
                        : null
                      }
                      {childItem.meal_paid_time !==0 ?
                        <div>{childItem.meal_unpaid_time !==0 ? 'P: ' : null } {childItem.meal_paid_time_display}</div>
                        :
                        null
                      }
                      {childItem.meal_unpaid_time !==0 ?
                        <div style={{color: '#EC4409'}}>{childItem.meal_paid_time !==0 ? 'U: ' : null}{(childItem.meal_paid_time=== 0 && childItem.meal_unpaid_time!==0) ? '-' : null}{childItem.meal_unpaid_time_display}</div>
                        :
                        null
                      }
                      </span> )
                      : basicSettingDetail.timecard_decimal_view
                      ? <span>
                        {(childItem.meal_paid_time === 0 && childItem.meal_unpaid_time === 0) ?
                          <div>0.00</div>
                          : null
                        }
                        {childItem.meal_paid_time !==0 ?
                          <div>{childItem.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(childItem.meal_paid_time)}</div>
                          : null
                        }
                        {childItem.meal_unpaid_time !==0 ?
                          <div style={{color: '#EC4409'}}>{childItem.meal_paid_time !==0 ? 'U: ' : null}{(childItem.meal_paid_time=== 0 && childItem.meal_unpaid_time!==0) ? '-' : null}{secondsToHours(childItem.meal_unpaid_time)}</div>
                          :
                          null
                        }
                        </span>
                      : <span>
                        {(childItem.meal_paid_time === 0 && childItem.meal_unpaid_time === 0) ?
                          <div>00:00</div>
                          : null
                        }
                        {childItem.meal_paid_time !==0 ?
                          <div>{childItem.meal_unpaid_time !==0 ? 'P: ' : null } {childItem.meal_paid_time_display}</div>
                          :
                          null
                        }
                        {childItem.meal_unpaid_time !==0 ?
                          <div style={{color: '#EC4409'}}>{childItem.meal_paid_time !==0 ? 'U: ' : null}{(childItem.meal_paid_time=== 0 && childItem.meal_unpaid_time!==0) ? '-' : null}{childItem.meal_unpaid_time_display}</div>
                          :
                          null
                        }
                        </span>
                    }
                  </div>
                  : null
                 }
              </td>
              <td className="text-right"> 
                {childItem.adjustment_type
                  ? <b>
                    { userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                          ? secondsToHours(childItem.total_adjustment_time)
                          : childItem.total_adjustment_time_display )
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(childItem.total_adjustment_time)
                      : childItem.total_adjustment_time_display
                    }
                  </b>
                  : <b>
                    { userTimecardFilters && userTimecardFilters.data
                      ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                      ? secondsToHours(childItem.total_time_seconds)
                      : childItem.total_time )
                      : basicSettingDetail.timecard_decimal_view
                      ? secondsToHours(childItem.total_time_seconds)
                      : childItem.total_time
                    }
                </b>
                }
              </td>
              <td
                title={
                  userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                      ? secondsToHours(childItem.regular_time) : childItem.regular_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.regular_time)
                    : childItem.regular_time_display
                }
                className="text-right"
              >
                <b>
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                       ? secondsToHours(childItem.regular_time) : childItem.regular_time_display )
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.regular_time)
                    : childItem.regular_time_display
                  }
                </b>
              </td>
              <td
                title={
                  userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                       ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.over_time)
                    : childItem.over_time_display
                }
                className="text-right"
              >
                <b>
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                       ? secondsToHours(childItem.over_time) : childItem.over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.over_time)
                    : childItem.over_time_display
                  }
                </b>
              </td>
              <td
                title={
                  userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                     ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.double_over_time)
                    : childItem.double_over_time_display
                }
                className="text-right"
              >
                <b>
                  { userTimecardFilters && userTimecardFilters.data
                    ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                       ? secondsToHours(childItem.double_over_time) : childItem.double_over_time_display)
                    : basicSettingDetail.timecard_decimal_view
                    ? secondsToHours(childItem.double_over_time)
                    : childItem.double_over_time_display
                  }
                </b>
              </td>
              {childItem.adjustment_type &&
                <td />
              }
              {!childItem.adjustment_type && (childItem.date_display === "Weekly Total" ?
                <td />
                : <td title="Click to approve daily timecard" className="text-center pr-3 pb-0">
                  {childItem.is_approved
                    ? <Button
                      type="button"
                      size="sm"
                      color={
                        childItem.need_correction || disableApprove
                          ? 'default'
                          : 'secondary'
                      }
                      disabled={childItem.need_correction || disableApprove}
                      onClick={() => disapproveDailyTimecard(childItem)}
                      style={{ fontSize: '11px' }}
                      title="Click to disapprove the daily timecard"
                      className="mb-1 ml-1"
                    >
                    Disapprove
                    </Button>
                    : (<Button
                      type="button"
                      size="sm"
                      color={
                        childItem.need_correction || disableApprove
                          ? 'default'
                          : 'secondary'
                      }
                      disabled={childItem.need_correction || disableApprove}
                      onClick={() => approveDailyTimecard(childItem)}
                      style={{ fontSize: '11px' }}
                      title="Click to approve the daily timecard"
                      className="mb-1 ml-1"
                    >
                    Approve
                    </Button>)
                  }
                </td>)}
            </tr>
          );
        }))}
        {payPeriodTotal && data.length >0 &&
          <tr className={`${styles['dailytimecardlist--payperiodtotal']} font-weight-bold`}>
            <td />
            <td title={payPeriodTotal.date_display}>{payPeriodTotal.date_display}</td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                   ? secondsToHours(payPeriodTotal.punch_in_time) : payPeriodTotal.punch_in_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.punch_in_time)
                  : payPeriodTotal.punch_in_time_display
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                   ? secondsToHours(payPeriodTotal.punch_in_time) : payPeriodTotal.punch_in_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.punch_in_time)
                  : payPeriodTotal.punch_in_time_display
                }
              </b>
            </td>

            <td title = {pay_period_display} className="text-right">
              <div>
                  {userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true") 
                   ? <span>
                    {payPeriodTotal.meal_paid_time !==0 ?
                      <div>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(payPeriodTotal.meal_paid_time)}</div>
                      : null
                    }
                    {payPeriodTotal.meal_unpaid_time !==0 ?
                      <div style={{color:'#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{secondsToHours(payPeriodTotal.meal_unpaid_time)}</div>
                      :
                      null
                    }
                    {payPeriodTotal.meal_unpaid_time ===0 && payPeriodTotal.meal_paid_time ===0 ?
                      <div>0.00</div>
                      :
                      null
                    }
                    </span> : <span>
                      {payPeriodTotal.meal_unpaid_time ===0 && payPeriodTotal.meal_paid_time ===0 ?
                        <div>00:00</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_paid_time !==0 ?
                        <div>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null } {payPeriodTotal.meal_paid_time_display}</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_unpaid_time !==0 ?
                        <div style={{color:'#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{payPeriodTotal.meal_unpaid_time_display}</div>
                        :
                        null
                      }      
                      </span> )
                    : basicSettingDetail.timecard_decimal_view
                    ? <span>
                      {payPeriodTotal.meal_paid_time !==0 ?
                        <div>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null }{secondsToHours(payPeriodTotal.meal_paid_time)}</div>
                        : null
                      }
                      {payPeriodTotal.meal_unpaid_time !==0 ?
                        <div style={{color:'#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{secondsToHours(payPeriodTotal.meal_unpaid_time)}</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_unpaid_time ===0 && payPeriodTotal.meal_paid_time ===0 ?
                        <div>0.00</div>
                        :
                        null
                      }
                      </span>
                    : <span>
                      {payPeriodTotal.meal_unpaid_time ===0 && payPeriodTotal.meal_paid_time ===0 ?
                        <div>00:00</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_paid_time !==0 ?
                        <div>{payPeriodTotal.meal_unpaid_time !==0 ? 'P: ' : null } {payPeriodTotal.meal_paid_time_display}</div>
                        :
                        null
                      }
                      {payPeriodTotal.meal_unpaid_time !==0 ?
                        <div style={{color:'#EC4409'}}>{payPeriodTotal.meal_paid_time !==0 ? 'U: ' : null }{(payPeriodTotal.meal_unpaid_time!==0 && payPeriodTotal.meal_paid_time===0) ? '-' : null}{payPeriodTotal.meal_unpaid_time_display}</div>
                        :
                        null
                      }      
                      </span>
                  }
                </div>
            
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(payPeriodTotal.total_time_seconds) : payPeriodTotal.total_time)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.total_time_seconds)
                  : payPeriodTotal.total_time
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(payPeriodTotal.total_time_seconds) : payPeriodTotal.total_time)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.total_time_seconds)
                  : payPeriodTotal.total_time
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                   ? secondsToHours(payPeriodTotal.regular_time) : payPeriodTotal.regular_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.regular_time)
                  : payPeriodTotal.regular_time_display
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(payPeriodTotal.regular_time) : payPeriodTotal.regular_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.regular_time)
                  : payPeriodTotal.regular_time_display
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(payPeriodTotal.over_time) : payPeriodTotal.over_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.over_time)
                  : payPeriodTotal.over_time_display
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(payPeriodTotal.over_time) : payPeriodTotal.over_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.over_time)
                  : payPeriodTotal.over_time_display
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(payPeriodTotal.double_over_time) : payPeriodTotal.double_over_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.double_over_time)
                  : payPeriodTotal.double_over_time_display
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(payPeriodTotal.double_over_time) : payPeriodTotal.double_over_time_display)
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(payPeriodTotal.double_over_time)
                  : payPeriodTotal.double_over_time_display
                }
              </b>
            </td>
            <td />
          </tr>
        }
         {Object.keys(totalAdjustmentsListView || {})?.length > 0 && data?.length >0 &&
          <tr className={`${styles['dailytimecardlist--payperiodtotal']} font-weight-bold`}>
            <td />
            <td title={'Total Adjustment'}>Total Adjustment</td>
            <td />
            <td />            
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time) : secondsToHHMM(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time)
                  : secondsToHHMM(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time)
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time) : secondsToHHMM(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time)
                  : secondsToHHMM(totalAdjustmentsListView?.regular_time+totalAdjustmentsListView?.over_time+totalAdjustmentsListView?.double_over_time)
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                   ? secondsToHours(totalAdjustmentsListView.regular_time) : secondsToHHMM(totalAdjustmentsListView.regular_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.regular_time)
                  : secondsToHHMM(totalAdjustmentsListView.regular_time)
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                    ? secondsToHours(totalAdjustmentsListView.regular_time) : secondsToHHMM(totalAdjustmentsListView.regular_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.regular_time)
                  : secondsToHHMM(totalAdjustmentsListView.regular_time)
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(totalAdjustmentsListView.over_time) : secondsToHHMM(totalAdjustmentsListView.over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.over_time)
                  : secondsToHHMM(totalAdjustmentsListView.over_time)
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(totalAdjustmentsListView.over_time) : secondsToHHMM(totalAdjustmentsListView.over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.over_time)
                  : secondsToHHMM(totalAdjustmentsListView.over_time)
                }
              </b>
            </td>
            <td
              title={
                userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(totalAdjustmentsListView.double_over_time) : secondsToHHMM(totalAdjustmentsListView.double_over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.double_over_time)
                  : secondsToHHMM(totalAdjustmentsListView.double_over_time)
              }
              className="text-right"
            >
              <b>
                { userTimecardFilters && userTimecardFilters.data
                  ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                     ? secondsToHours(totalAdjustmentsListView.double_over_time) : secondsToHHMM(totalAdjustmentsListView.double_over_time))
                  : basicSettingDetail.timecard_decimal_view
                  ? secondsToHours(totalAdjustmentsListView.double_over_time)
                  : secondsToHHMM(totalAdjustmentsListView.double_over_time)
                }
              </b>
            </td>
            <td />
          </tr>
        }
        
        {!data.length &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

DailyTimecardTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  payPeriodTotal: PropTypes.object.isRequired,
  basicSettingDetail: PropTypes.object.isRequired,
  trackingToggle: PropTypes.func.isRequired,
  totalAdjustmentsListView: PropTypes.object,
};

export default DailyTimecardTableComponent;
