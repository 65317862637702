import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import ButtonGroupAtom from 'atoms/ButtonGroup';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SelectAtom from 'atoms/Select';
import InputAtom from 'atoms/Input';
import { FORM_LAYOUT } from 'constants/layout';
import { WEEKDAYS_IN_SHORT, REPEAT_OPTIONS, REPEAT_EVERY_MONTHLY_OPTIONS, BILL_TYPE_OPTIONS } from 'constants/jobs';

import styles from './styles.module.scss';



class JobBudgetComponent extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      weekDays: WEEKDAYS_IN_SHORT,
      billType: 'QUOTED_RECURRING',
      jobType: 'RECURRING',
    };
    this.submit = this.submit.bind(this);
    this.toggleBillType = this.toggleBillType.bind(this);
    this.toggleJobType = this.toggleJobType.bind(this);
    this.goTo = this.goTo.bind(this);
    this.jobList = this.jobList.bind(this);
    this.addBudgetForDay = this.addBudgetForDay.bind(this);
    this.addAmountForDay = this.addAmountForDay.bind(this);
  }



  componentWillMount() {
    const { initialValues: { days_of_week: values }, jobDetail , initialValues} = this.props;
    if(jobDetail.end_date){
      this.setState({jobType : 'PROJECT'});
    }

    if (jobDetail.days_of_week && jobDetail.days_of_week.length) {
      const weekDays = WEEKDAYS_IN_SHORT.map((i) => {
        const item = { ...i };
        if(values.indexOf(i.value.toString()) > -1){
          item.isSelected = true;
        }
        else item.isSelected = false;
        return item;
      });
      this.setState({ weekDays });
    }
    this.setState({billType : initialValues.bill_type});
  }

  componentWillReceiveProps(prevProps, nextProps) {
    // if (jobDetail.bill_type) {
    //   this.setState({ billType: jobDetail.bill_type });
    // }
    // if (jobDetail.job_type) {
    //   this.setState({ jobType: jobDetail.job_type });
    // }
  }

  jobList() {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    const pathname = `/job/list?status=1`;
    const newPath = `/job/list/new?status=1`;
    query.admin_list ? history.push(newPath) : history.push(pathname);
  }


  toggleJobType(data) {
    this.setState({ jobType: data });
  }

  toggleBillType(data) {
    this.setState({ billType: data});
  }

  selectDay(item) {
    const { weekDays } = this.state;
    const days = weekDays.map((i) => {
      if(item && (i.name === item)) {
        return { ...i , isSelected: !i.isSelected };
      }
      return i;
    });
    this.setState({ weekDays: days });
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  addBudgetForDay(){
    const { budget_hour } = this.props;
    const { weekDays } = this.state;  
    let total = 0;
    weekDays.forEach((item, index) => {
      if(weekDays[0].isSelected && index === 0){
        total += (parseFloat(budget_hour[6]) ? parseFloat(budget_hour[6]) : 0);
      }
      else if(weekDays[index].isSelected && index <= 6 && index >=1){
         total += (parseFloat(budget_hour[index - 1]) ? parseFloat(budget_hour[index - 1]) : 0);
      }
    });
    return total.toFixed(2);
  }

  addAmountForDay(){
    const { bill_rate } = this.props;
    const { weekDays } = this.state;  
    let Amount = 0;
    weekDays.forEach((item, index) => {
      if(weekDays[0].isSelected && index === 0){
        Amount += (parseFloat(bill_rate[6]) ? parseFloat(bill_rate[6]) : 0);
      }
      else if(weekDays[index].isSelected && index <= 6 && index >=1){
         Amount += (parseFloat(bill_rate[index - 1]) ? parseFloat(bill_rate[index - 1]) : 0);
      }
    });
    return Amount.toFixed(2);
  }

  submit(data) {
    const {  createOrUpdate } = this.props;
    const { billType, weekDays } = this.state;
    const detail = { ...data };
    if(billType === 'QUOTED_PROJECT') {
        delete detail.repeat;
        delete detail.every;
        delete detail.budgeted_hours_per_day;
        delete detail.daily_billing_rate;
        detail.job_type = 'PROJECT';
        detail.bill_type = 'QUOTED';

        // Resetting isSelected property of all days.
        for(let i=0; i<weekDays.length; i++)
        {
          weekDays[i].isSelected = false;
        }
      }
    else if (billType === 'QUOTED_RECURRING') {
        delete detail.total_budget_hour;
        delete detail.total_quoted_amount;
        detail.days_of_week = weekDays.filter((i) => i.isSelected).map((i) => i.value);
        detail.job_type = 'RECURRING';
        detail.bill_type = 'QUOTED';
        detail.daily_billing_rate = [];
        detail.budgeted_hours_per_day = [];
        for (let i=0;i < detail.days_of_week.length; i+=1) {
          detail.daily_billing_rate[+detail.days_of_week[i]] = detail[`bill_rate-${detail.days_of_week[i]}`]>0 ? detail[`bill_rate-${detail.days_of_week[i]}`] : 0;
          delete detail[`bill_rate-${+detail.days_of_week[i]}`];
        }

        for (let i=0;i < detail.days_of_week.length; i+=1) {
          detail.budgeted_hours_per_day[+detail.days_of_week[i]] = detail[`budget_hour-${+detail.days_of_week[i]}`] > 0 ?  detail[`budget_hour-${+detail.days_of_week[i]}`] : 0;
          delete detail[`budget_hour-${+detail.days_of_week[i]}`];
        }
      }
     else {
      delete detail.repeat;
      delete detail.every;
      delete detail.total_budget_hour;
      delete detail.total_quoted_amount;
      delete detail.job_type;
      delete detail.budgeted_hours_per_day;
      delete detail.daily_billing_rate;
      delete detail.days_of_week;
    }
    detail.type = data.type;
    detail.every = detail.every && detail.every.value;
    detail.repeat = data.repeat && data.repeat.value;
    detail.start_date = data.start_date && moment(data.start_date).format('YYYY-MM-DD');
    detail.end_date = data.end_date && moment(data.end_date).format('YYYY-MM-DD');
    return createOrUpdate(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
    }


  render() {
    const { handleSubmit, location: { query } , next, finish} = this.props;
    const { weekDays, billType } = this.state;
    return (
      <div>
        <Form style={{color: '#242424', padding: '0 80px'}} className="mt-0">
          <Row>
            <Col md={9}>
              {/*
                <div className="d-inline-flex">
                  <span>
                    <div className="mt-3">
                      <h5>
                        <small>
                          <b>{`${jobDetail.start_date_display} - ${jobDetail.end_date_display}`}</b>
                        </small>
                      </h5>
                    </div>
                  </span>
                </div>
              */}
              <Row className="mt-2">
                {/*
                  <Col>
                    <Field
                      id="start_date"
                      name="start_date"
                      label="Start Date"
                      layout={FORM_LAYOUT}
                      component={DateTimePickerAtom}
                      placeholder="Start Date"
                      options={{ dateFormat, }}
                      disabled={showDate}
                    />
                  </Col>
                  <Col>
                    <Field
                      id="end_date"
                      name="end_date"
                      label="Projected End"
                      layout={FORM_LAYOUT}
                      component={DateTimePickerAtom}
                      placeholder="Projected End"
                      options={{ dateFormat, }}
                      disabled={showDate}
                    />
                  </Col>
                  <Col md={1}>
                    <Button
                      className="btn-job-primary btn-search p-1 mt-4 float-right"
                      onClick={() => editDate()}
                    >
                      <img src={edit} alt="edit" height="26px" />
                    </Button>
                  </Col>
                */}
              </Row>

                <div className="mt-2"> Bill Type </div>
                  <Col md={12} className="pl-0">
                    <div className="button-budget-override w-100">
                      <Field
                        id="bill_type"
                        name="bill_type"
                        component={ButtonGroupAtom}
                        options={BILL_TYPE_OPTIONS}
                        onToggle={this.toggleBillType}
                        active={billType}
                        className={`${styles['button-group-width']}`}
                      />
                    </div>
                  </Col>
              {(billType === 'QUOTED_RECURRING' || billType==='QUOTED_PROJECT') &&
                <div>
                  {billType === 'QUOTED_RECURRING' ?
                    <div>
                      <Col md={12} className="remove-icon">
                        <Row className="mt-4">
                          <Col className="pl-0">
                            <div className="mb-2">
                              Repeats
                            </div>
                            <Field
                              id="repeat"
                              name="repeat"
                              component={SelectAtom}
                              options={REPEAT_OPTIONS}
                              layout={FORM_LAYOUT}
                              placeholder="Repeats"
                            />
                          </Col>
                          <Col>
                            <div className="mb-2">
                              Every
                            </div>
                            <Field
                              id="every"
                              name="every"
                              component={SelectAtom}
                              options={REPEAT_EVERY_MONTHLY_OPTIONS}
                              layout={FORM_LAYOUT}
                              placeholder="Every"
                            />
                          </Col>
                        </Row>
                      </Col>
                      <span className="my-3" style={{ width: '22%' }}>Select Days</span>
                      <div className="mb-4 mt-2 d-flex w-100">
                        <span className={`d-flex w-100 `}>
                          {weekDays.map((item) => (
                            <span
                              className={`custom-input badge badge-light ${item.isSelected ? 'job-select-day' : 'job-select-day-other' }`}
                              role="button"
                              tabIndex={0}
                              onClick={() => this.selectDay(item.name)}>{item.name}</span>
                          ))}
                        </span>
                      </div>
                      <span style={{ width: '22%' }}>Daily Bill Rate</span>
                      <div className="mb-1 mt-2 d-flex w-100">
                        <span className={` d-flex w-100`}>
                          {weekDays.map((item) => (
                            <Field
                              id={`bill_rate-${item.value}`}
                              name={`bill_rate-${item.value}`}
                              component={InputAtom}
                              className={`${styles['custom-input-bill']}`}
                              disabled={!item.isSelected}

                            />
                          ))}
                        </span>
                      </div>
                      <span style={{ width: '22%' }}>Budgeted Hours/Day</span>
                      <div className="mt-2 d-flex">
                        <span className="d-flex w-100">
                          {weekDays.map((item) => (
                            <Field
                              id={`budget_hour-${item.value}`}
                              name={`budget_hour-${item.value}`}
                              component={InputAtom}
                              className={`${styles['custom-input-bill']}`}
                              disabled={!item.isSelected}
                              // onChange={() =>this.addBudgetForDay()}        
                            />
                          ))}
                        </span>
                      </div>

                      <Row className="my-2 m-0 pr-3">
                          <Col md={5}>
                              <Row className={styles.job_budgeted_hours}>
                                <p className="m-2">
                                  Total Budgeted Hours :
                                </p>
                                <p className="m-2">
                                  {this.addBudgetForDay()}
                                </p>
                              </Row>
                          </Col>
                          <Col md={2}></Col>
                          <Col md={5}>
                            <Row className={styles.job_budgeted_hours}>
                              <p className="m-2">
                                Total Quoted Amount :
                              </p>
                              <p className="m-2">
                                {this.addAmountForDay()}
                              </p>
                            </Row>
                          </Col>
                      </Row>
                    </div>
                    :
                    <div>
                      <Row className="mt-4">
                        <Col>
                          <Field
                            id="total_budget_hour"
                            name="total_budget_hour"
                            component={InputAtom}
                            label="Total Budgeted Hours"
                            layout={FORM_LAYOUT}
                            placeholder="Total Budgeted Hours"
                          />
                        </Col>
                        <Col>
                          <Field
                            id="total_quoted_amount"
                            name="total_quoted_amount"
                            component={InputAtom}
                            label="Total Quoted Amount"
                            layout={FORM_LAYOUT}
                            placeholder="Total Quoted Amount"
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                </div>
              }
            </Col>
          </Row>

          <Row className='justify-content-center'>
            {query.setup &&
              <Col md={12} className="mt-2 mb-3 pr-0 text-right">
                <Button
                  color="primary"
                  className={`${styles['btn_accent_width']} btn-accent`}
                  type="submit"
                  onClick={handleSubmit(values =>
                      this.submit({
                        ...values,
                        type: 'finish'
                      }))}
                >
                Submit <ButtonLoaderAtom active={finish} />
                </Button>

                <Button
                  color="primary"
                  className={`${styles['btn_accent_width']} btn-accent`}
                  type="submit"
                  onClick={handleSubmit(values =>
                        this.submit({
                          ...values,
                          type: 'next'
                        }))}
                  >
                    Next <ButtonLoaderAtom active={next} />
                </Button>
              </Col>
            }
            {!query.setup &&
              <Col md={12} className="mt-2 mb-3 pr-0 text-right">
                <Button
                  color="primary"
                  className={`${styles['btn_accent_width']} float-right m-2`}
                  type='submit'
                  onClick={handleSubmit(values =>
                    this.submit({
                      ...values,
                      type: 'next'
                    }))}
                >
                Next <ButtonLoaderAtom active={next} />
                </Button>

                <Button
                  color="primary"
                  className={`${styles['btn_accent_width']} float-right m-2`}
                  type='submit'
                  onClick={handleSubmit(values =>
                    this.submit({
                      ...values,
                      type: 'finish'
                    }))}
                >
                  Save <ButtonLoaderAtom active={finish} />
                </Button>
              </Col>
            }
          </Row>
        </Form>
      </div>
    );
  }
}


const validate = (values) => {
  const errors = {};
  if (values.bill_type === 'QUOTED_RECURRING') {
    if (!values.repeat) errors.repeat = 'Select repeat';
    if (!values.every) errors.every = 'Select every';
  }
  if (String(values.bill_type) === 'QUOTED_PROJECT') {
    if(!values.total_budget_hour) errors.total_budget_hour = 'Enter Budgeted hour';
    if(!values.total_quoted_amount) errors.total_quoted_amount = 'Enter Quoted Amount';
  }

  if (!values.start_date) errors.start_date = 'Enter start date';

  if(values.start_date && values.end_date){
    const formattedStartDate=moment(values.start_date).format();
    const formattedEndDate=moment(values.end_date).format();
    const currentDate = moment().format();
    if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
    if(formattedEndDate < currentDate) errors.end_date ='End date could not be less then current date.';
  }

  const numberRegex = /^[+]?([0-9]*(?:[\\.][0-9]*)?|\\.[0-9]+)$/;
  if (values['bill_rate-0']) {
    if (!numberRegex.test(values['bill_rate-0'])) {
      errors['bill_rate-0'] = 'Invalid data';
    }
    if(String(values['bill_rate-0']).startsWith(".")) {
      values['bill_rate-0']= String(values['bill_rate-0']).padStart(2, '0');
    }
  }
  if (values['bill_rate-1']) {
    if (values['bill_rate-1'] && !numberRegex.test(values['bill_rate-1'])) {
      errors['bill_rate-1'] = 'Invalid data';
    }
    if(String(values['bill_rate-1']).startsWith(".")) {
      values['bill_rate-1']= String(values['bill_rate-1']).padStart(2, '0');
    }
  }
  if (values['bill_rate-2']) {
    if (values['bill_rate-2'] && !numberRegex.test(values['bill_rate-2'])) {
      errors['bill_rate-2'] = 'Invalid data';
    }
    if(String(values['bill_rate-2']).startsWith(".")) {
      values['bill_rate-2']= String(values['bill_rate-2']).padStart(2, '0');
    }
  }
  if (values['bill_rate-3']) {
    if (values['bill_rate-3'] && !numberRegex.test(values['bill_rate-3'])) {
      errors['bill_rate-3'] = 'Invalid data';
    }
    if(String(values['bill_rate-3']).startsWith(".")) {
      values['bill_rate-3']= String(values['bill_rate-3']).padStart(2, '0');
    }
  }
  if (values['bill_rate-4']) {
    if (values['bill_rate-4'] && !numberRegex.test(values['bill_rate-4'])) {
      errors['bill_rate-4'] = 'Invalid data';
    }
    if(String(values['bill_rate-4']).startsWith(".")) {
      values['bill_rate-4']= String(values['bill_rate-4']).padStart(2, '0');
    }
  }
  if (values['bill_rate-5']) {
    if (values['bill_rate-5'] && !numberRegex.test(values['bill_rate-5'])) {
      errors['bill_rate-5'] = 'Invalid data';
    }
    if(String(values['bill_rate-5']).startsWith(".")) {
      values['bill_rate-5']= String(values['bill_rate-5']).padStart(2, '0');
    }
  }
  if (values['bill_rate-6']) {
    if (values['bill_rate-6'] && !numberRegex.test(values['bill_rate-6'])) {
      errors['bill_rate-6'] = 'Invalid data';
    }
    if(String(values['bill_rate-6']).startsWith(".")) {
      values['bill_rate-6']= String(values['bill_rate-6']).padStart(2, '0');
    }
  }
  if (values['budget_hour-0']) {
    if (values['budget_hour-0'] && !numberRegex.test(values['budget_hour-0'])) {
      errors['budget_hour-0'] = 'Invalid data';
    }
    if(String(values['budget_hour-0']).startsWith(".")) {
      values['budget_hour-0']= String(values['budget_hour-0']).padStart(2, '0');
    }
  }
  if (values['budget_hour-1']) {
    if (values['budget_hour-1'] && !numberRegex.test(values['budget_hour-1'])) {
      errors['budget_hour-1'] = 'Invalid data';
    }
    if(String(values['budget_hour-1']).startsWith(".")) {
      values['budget_hour-1']= String(values['budget_hour-1']).padStart(2, '0');
    }
  }
  if (values['budget_hour-2']) {
    if (values['budget_hour-2'] && !numberRegex.test(values['budget_hour-2'])) {
      errors['budget_hour-2'] = 'Invalid data';
    }
    if(String(values['budget_hour-2']).startsWith(".")) {
      values['budget_hour-2']= String(values['budget_hour-2']).padStart(2, '0');
    }
  }
  if (values['budget_hour-3']) {
    if (values['budget_hour-3'] && !numberRegex.test(values['budget_hour-3'])) {
      errors['budget_hour-3'] = 'Invalid data';
    }
    if(String(values['budget_hour-3']).startsWith(".")) {
      values['budget_hour-3']= String(values['budget_hour-3']).padStart(2, '0');
    }
  }
  if (values['budget_hour-4']) {
    if (values['budget_hour-4'] && !numberRegex.test(values['budget_hour-4'])) {
      errors['budget_hour-4'] = 'Invalid data';
    }
    if(String(values['budget_hour-4']).startsWith(".")) {
      values['budget_hour-4']= String(values['budget_hour-4']).padStart(2, '0');
    }
  }
  if (values['budget_hour-5']) {
    if (values['budget_hour-5'] && !numberRegex.test(values['budget_hour-5'])) {
      errors['budget_hour-5'] = 'Invalid data';
    }
    if(String(values['budget_hour-5']).startsWith(".")) {
      values['budget_hour-5']= String(values['budget_hour-5']).padStart(2, '0');
    }
  }
  if (values['budget_hour-6']) {
    if (values['budget_hour-6'] && !numberRegex.test(values['budget_hour-6'])) {
      errors['budget_hour-6'] = 'Invalid data';
    }
    if(String(values['budget_hour-6']).startsWith(".")) {
      values['budget_hour-6']= String(values['budget_hour-6']).padStart(2, '0');
    }
  }

  return errors;
};

JobBudgetComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  showDate: PropTypes.bool,
  jobDetail: PropTypes.object.isRequired,
  editDate: PropTypes.func,
  // loaded: PropTypes.bool,
  // tagList: PropTypes.array,
  // isLoading: PropTypes.bool,
  dateFormat: PropTypes.string,
  // change: PropTypes.func,
  initialValues: PropTypes.object,
  // toggleTagModal: PropTypes.func,
  location: PropTypes.object,
};

JobBudgetComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};


const withReduxForm = reduxForm({
  form: 'JobBudgetForm',
  validate,
  enableReinitialize: true,
})(JobBudgetComponent);

const selector = formValueSelector('JobBudgetForm');

export default connect(
  (state) => {
    const budget_hour = [];
    budget_hour[0] = selector(state, 'budget_hour-0') ? selector(state, 'budget_hour-0') : 0;
    budget_hour[1] =selector(state, 'budget_hour-1') ? selector(state, 'budget_hour-1') : 0;
    budget_hour[2] =selector(state, 'budget_hour-2') ? selector(state, 'budget_hour-2') : 0;
    budget_hour[3] =selector(state, 'budget_hour-3') ? selector(state, 'budget_hour-3') : 0;
    budget_hour[4] =selector(state, 'budget_hour-4') ? selector(state, 'budget_hour-4') : 0;
    budget_hour[5] =selector(state, 'budget_hour-5') ? selector(state, 'budget_hour-5') : 0;
    budget_hour[6] =selector(state, 'budget_hour-6') ? selector(state, 'budget_hour-6') : 0;
    
    const bill_rate = [];
    bill_rate[0]   =selector(state, 'bill_rate-0')  ? selector(state, 'bill_rate-0') : 0;
    bill_rate[1]   =selector(state, 'bill_rate-1')  ? selector(state, 'bill_rate-1') : 0;
    bill_rate[2]   =selector(state, 'bill_rate-2')  ? selector(state, 'bill_rate-2') : 0;
    bill_rate[3]   =selector(state, 'bill_rate-3')  ? selector(state, 'bill_rate-3') : 0;
    bill_rate[4]   =selector(state, 'bill_rate-4')  ? selector(state, 'bill_rate-4') : 0;
    bill_rate[5]   =selector(state, 'bill_rate-5')  ? selector(state, 'bill_rate-5') : 0;
    bill_rate[6]   =selector(state, 'bill_rate-6')  ? selector(state, 'bill_rate-6') : 0;
   /* const totalHours = (+budget_hour0) + (+budget_hour1) + (+budget_hour2) + (+budget_hour3) + (+budget_hour4) + (+budget_hour5) + (+budget_hour6);
    const totalAmount = (+bill_rate0) + (+bill_rate1) + (+bill_rate2) + (+bill_rate3) + (+bill_rate4) + (+bill_rate5) + (+bill_rate6);*/
    // const employeeData=state.form.JobBudgetForm && state.form.JobBudgetForm.values;
    // return { budget_hour0 , budget_hour1 , budget_hour2 , budget_hour3 , budget_hour4, budget_hour5, budget_hour6, };
    return {bill_rate, budget_hour}
  },
)(withReduxForm);;
