import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import EyeView from './EyeView.png';
import styles from './styles.module.scss';

const FloatLabelInput = (props) => {
  const { id , name, label, mandatory, surveyLabel, disabled } = props;
  return (
    <div className="floating-form">
        <div className="floating-label">      
        <input className="floating-input" type="text" placeholder=" " id={id} name={name}  data-lpignore={true} autoComplete="off" style={surveyLabel ? {textAlign: 'center'} : null} onChange={props.handleChange} disabled={disabled}/>
        <label className="float-label" style={mandatory ? {textTransform: 'uppercase'} : surveyLabel ? {width: '95%'} : null} id={`${id}label-target-onlabel`} dangerouslySetInnerHTML={{ __html: label }}>
        </label>
        <div id={`${id}label-target`} className="floating-label-image"><img src={EyeView} width="10" height="10"/></div>
        <UncontrolledTooltip placement="left" target={`${id}label-target`} dangerouslySetInnerHTML={{ __html: label }} style={mandatory ? {textTransform: 'uppercase'} : null}>
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={`${id}label-target-onlabel`} dangerouslySetInnerHTML={{ __html: label }} style={mandatory ? {textTransform: 'uppercase'} : null}>
        </UncontrolledTooltip>
        </div>
    </div>
  );
};

export default FloatLabelInput;