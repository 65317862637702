import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import StorageGateway from 'lib/storage-gateway';

import Button from 'reactstrap/lib/Button';
import Label from 'reactstrap/lib/Label';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import closeimage from './CloseCross.png';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * ForgotPasswordPage -> ForgotPasswordFormSection -> PluginModalComponent
 *
 * Props:
 *    - update
 */

export const FORM_LAYOUT_SM = {
  labelCol: { sm: 4 },
  wrapperCol: { sm: 8 },
};

class PluginModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { save, handleModalClose } = this.props;
    return save(data)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        handleModalClose();
        throw new SubmissionError(errObj);
      });
  }


  render() {
    const {handleSubmit, submitting, error } = this.props;
    const { isOpen, toggle } = this.props;
    const qbdToken  = StorageGateway.get('pluginToken');
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="md"
        backdrop="static"
        className={`${styles['configure-modal']} form-modal`}
      >
        <ModalHeader>Configure QuickBooks Desktop Application
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <div className="error-text ml-3">{error}</div>
          <ModalBody className="pt-0">
            <div className="mb-3">
              <Label><b>Quickbooks Token</b></Label>
              <div className={styles['word-break']}>{qbdToken}</div>
            </div>
            <Field
              id="file_path"
              name="file_path"
              component={InputAtom}
              label="Copy the file loaction path here: (Open the file explorer(on your system) and locate the exact file path of the QuickBooks Web Connector and paste it here)"
              layout={FORM_LAYOUT}
              placeholder="Exact file path of the QuickBooks .qbw file on your system"
            />
            <small className={styles['file-path-example']}>Ex: C:\Users\Public\Documents\Intuit\QuickBooks\Company Files\Your Company.qbw</small>
          </ModalBody>

          <ModalFooter>
            <Button color="btn btn-secondary" type="submit" className="btn-width">
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if(!values.file_path) errors.file_path = 'Enter file location path';
  if(values.file_path && !values.file_path.trim()) errors.file_path = 'Enter file location path';
  return errors;
};

PluginModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default reduxForm({
  form: 'PluginModalForm',
  validate,
  enableReinitialize: true,
})(PluginModalComponent);
