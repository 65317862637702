import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const SAVE_CARD = 'vendors/payment/SAVE_CARD';
const SAVE_CARD_FULFILLED = 'vendors/payment/SAVE_CARD_FULFILLED';
const GET_CUSTOMER = 'vendors/payment/GET_CUSTOMER';
const GET_CUSTOMER_FULFILLED = 'vendors/payment/GET_CUSTOMER_FULFILLED';

export function saveCard(data, companyId) {
  return {
    type: SAVE_CARD,
    payload: axios.put(`${URLS.CUSTOMER}${companyId}/`, data),
  };
}

export function getCustomer(companyId) {
  return {
    type: GET_CUSTOMER,
    payload: axios.get(`${URLS.CUSTOMER}${companyId}/`),
  };
}

const defaultState = {
  detail: {},
};

function SAVE_CARD_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_CUSTOMER_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [SAVE_CARD_FULFILLED]: SAVE_CARD_FULFILLED_REDUCER,
  [GET_CUSTOMER_FULFILLED]: GET_CUSTOMER_FULFILLED_REDUCER,
};


const paymentSelector = state => state.vendors.payment;

export const customerDetail = createSelector(
  paymentSelector,
  instance => instance.detail,
);

export const onboardDetail = createSelector(
  paymentSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
