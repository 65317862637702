import React from 'react';

import {Card , Row, Col } from 'reactstrap';
import styles from './styles.module.scss';
import Flatpickr from 'react-flatpickr';

import EditorFloatAtom from './EditorFloatAtom';

const TimeFloatComponent = (props) => {
  const { data , index, disabled, dateFormat, timeFormat, timeFormat24Hrs, showSpLabel } = props;
  return (
    <Card className={`${styles['card-ui']} mr-3 pl-3 pr-3 pt-3 pb-3`}>
      <Row>
        <Col md={12} className={`${styles['custom-col']}`} style={{display: 'block'}}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <EditorFloatAtom value={showSpLabel ? data.sp_question : data.question} id={`question_${index}`} name={`question_${index}`} data={data} mandatory={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? true : false} handleChange={props.handleChange} disabled={disabled} onChange={props.onChange} index={index} key={showSpLabel ? `question_${index}_spanish`: `question_${index}_english`}/>
        </Col>
      </Row>
      <div className={`${styles['resize-disabled']} mt-3`}>
        <Row>
            <Col>
                <Flatpickr
                    className="form-control mb-2 mt-1"
                    options={{
                    dateFormat: `${timeFormat}`,
                    enableTime: true,
                    minuteIncrement: 1,
                    time_24hr: timeFormat24Hrs,
                    }}
                    aria-label="Start Date"
                    disabled={true}
                    placeholder="Time"
                />
            </Col>
        </Row>
      </div>
    </Card>
  );
};

export default TimeFloatComponent;