import React from 'react';

import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import InputGroup from 'reactstrap/lib/InputGroup';
import Label from 'reactstrap/lib/Label';

import {ReactComponent as CalendarIcon} from './calendar.svg';

/**
 * DateTimePickerAtom
 *
 * - For props refer: https://chmln.github.io/flatpickr/options/
 */
const DateTimePickerAtom = (props) => {
  const {
    input, layout, label, prefix, suffix, help,
    meta: { error, touched }, options: { onChange, ...restOptions }, ...rest
  } = props;
  let pickerInstance;

  const handleChange = (val) => {
    if (restOptions.mode === 'range' || restOptions.mode === 'multiple') {
      input.onChange(val || null);
      if (onChange) onChange(val || null);
    } else {
      input.onChange(val[0] || null);
      if (onChange) onChange(val[0] || null);
    }
  };

  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol}>
          <InputGroup>
            {prefix && (<div className="input-group-addon">{prefix}</div>)}
            <Flatpickr
              {...rest}
              value={input.value}
              options={{
                minuteIncrement: 1,
                disableMobile: true,
                ...restOptions,
              }}
              className="form-control"
              onChange={handleChange}
              ref={ref => {
                if (ref) pickerInstance = ref.flatpickr;
              }}
            />
            <div className="cursor-pointer input-group-addon" onClick={() => setTimeout(function(){ pickerInstance.open(); }, 0)}>
              {suffix || <CalendarIcon width="20" height="20" />}
            </div>
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<span style={{ width: '100%'}}>
          <InputGroup>
            {prefix && (<div className="input-group-addon">{prefix}</div>)}
            <Flatpickr
              {...rest}
              value={input.value}
              options={{
                minuteIncrement: 1,
                disableMobile: true,
                ...restOptions,
              }}
              className="form-control"
              onChange={handleChange}
              ref={ref => {
                if (ref) pickerInstance = ref.flatpickr;
              }}
            />
            <div className="cursor-pointer input-group-addon" onClick={() => setTimeout(function(){ pickerInstance.open(); }, 0)}>
              {suffix || <CalendarIcon width="20" height="22" />}
            </div>
          </InputGroup>
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </span>)
      }
    </FormGroup>
  );
};

DateTimePickerAtom.defaultProps = {
  layout: {},
};

DateTimePickerAtom.propTypes = {
  help: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  layout: PropTypes.object,
  meta: PropTypes.object.isRequired,
  options: PropTypes.object,
  prefix: PropTypes.node,
  suffix: PropTypes.node,
};

export default DateTimePickerAtom;