import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as UserDucks from 'ducks/accounts/user';

import ChangePasswordFormComponent from 'components/accounts/changePassword/Form';

import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/accounts';

/**
 * ChangePasswordFormPage -> ChangePasswordFormSection
 *
 * Components:
 *    - {@link ChangePasswordFormComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - Form:
 *        - submit user detail
 */
class ChangePasswordFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.changePassword = this.changePassword.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { showStrengthCheck : false };
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  changePassword(data) {
    return this.props.UserDucks.postChangePasswordUser(data)
      .then(() => toast.success(MSGS.CHANGE_PASSWORD_SUCCESS));
  }

  render() {
    const { isLoading } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <ChangePasswordFormComponent changePassword={this.changePassword} showStrengthCheck={this.state.showStrengthCheck}
      strengthCheckToggle = {this.strengthCheckToggle}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapActionsToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

ChangePasswordFormSection.propTypes = {
  UserDucks: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ChangePasswordFormSection);
