import React from "react";

import PropTypes from "prop-types";

import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import closeimage from "./Close-Cross.png";
import { Button } from "reactstrap";

const GenericConfirmationModal  =(props) => {
  const { isOpen, toggle, handleConfirmation, isSubmitting,text } =props;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" style={{cursor: isSubmitting?'not-allowed':null}}>
      <ModalHeader>
        {"Confirmation"}
        <img
          src={closeimage}
          className="close_image"
          height="14px"
          width="14px"
          alt="Close"
          onClick={() => toggle(null,false)}
        />
      </ModalHeader>
      <ModalBody>
        <div>{text}</div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => handleConfirmation()} disabled={isSubmitting}>
          Yes <ButtonLoaderAtom active={isSubmitting} />
        </Button>
        <Button color="secondary" onClick={() => toggle()} disabled={isSubmitting}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

GenericConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  heading: PropTypes.string,
};

export default GenericConfirmationModal;
