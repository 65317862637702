
import React from 'react';

import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';
import SectionLoaderAtom from 'atoms/SectionLoader';
import SortHeaderComponent from 'components/common/SortHeader';
import EditIconAtom from 'atoms/EditIcon';
import EyeOpenIconAtom from 'atoms/EyeOpen';


class BillListComponent extends React.PureComponent {

  render(){
    const { billList, isLoading, location: { pathname, search } } = this.props;
    if(isLoading) return <div className="col-min-height"><SectionLoaderAtom active /></div>;
    return (
      <Table responsive hover size="sm" className='pl-3 pr-4'>
        <thead className="Header-col">
          <tr>
            <th>&nbsp;</th>
            <SortHeaderComponent ordering="customer__name">Account Name</SortHeaderComponent>
            <SortHeaderComponent ordering="quantity" className="text-right">Billed Users</SortHeaderComponent>
            <th className="text-right">Billing Rate</th>
            <SortHeaderComponent ordering="total_amount" className="text-right pr-2">Total Amount</SortHeaderComponent>
            <th>Billing Status</th>
            <th>Invoice</th>
            <th className="text-center">Actions</th>
            <th >&nbsp;</th>  
          </tr>
        </thead>
        <tbody>
          { billList.results && billList.results.map((item, index) => {
              return (
                <tr key={index}>
                  <td>&nbsp;</td>
                  <td>{item.customer && item.customer.name}</td>
                  <td title={item.quantity} className="text-right">{item.quantity}</td>
                  <td title= {item.charge_rate} className="text-right">
                    {item.charge_rate}
                  </td>
                  <td title={item.total_amount} className="text-right pr-2">{item.total_amount}</td>
                  <td title={item.status}>{item.status}</td>
                  <td title={item.invoice && item.invoice.invoice_number}>
                    <Link to={{
                      pathname: `/vendor/invoice/${item.invoice.invoice_id}/invoice-detail`,
                      search: queryString.stringify({
                        parent: `${pathname}${search}`,
                        badmin: true,
                      })
                    }}>
                      {item.invoice && item.invoice.invoice_number}
                    </Link>
                  </td>
                  <td className="text-center">
                  {item.status !=="PAYMENT RECEIVED" ?
                    <span  className="cursor-pointer">
                      <Link
                        to={{
                          pathname: `/bill/${item.id}/edit`,
                        }}
                        title="View Billing Details" >
                          <EditIconAtom/>
                      </Link>
                      
                    </span>
                    :
                    <span  className="cursor-pointer">
                      <Link
                        to={{
                          pathname: `/bill/${item.id}/edit`,
                        }}
                        title="View Billing Details" >
                          <EyeOpenIconAtom/>
                      </Link>
                      
                    </span>
                    }       
                  </td>
                  <th >&nbsp;</th>
                </tr>
              );})}
          {!this.props.billListCount &&
            <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
          }
        </tbody>
      </Table>
   );
  }
}


export default BillListComponent;