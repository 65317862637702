import React from 'react';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import { Formik,Field } from 'formik';
import styles from './styles.module.scss';
import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import MultiSelectAtom from 'atoms/FormikMultiselectDropdown';
import FormikSelectAtom from 'generics/FormikSelect';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { SURVEY_TYPE_OPTIONS } from 'constants/surveys';



class SurveyFormComponent extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.selectRoles=this.selectRoles.bind(this);
    this.handleDeptChange=this.handleDeptChange.bind(this);
    this.selectTeams = this.selectTeams.bind(this);
    this.selectSurveyType= this.selectSurveyType.bind(this);
    this.state={ selectedTeams : props.initialValues && props.initialValues.teams ? props.initialValues.teams : [],  selectedDepts : props.initialValues && props.initialValues.departments ? props.initialValues.departments : [], selectedRoles: props.initialValues && props.initialValues.role ? props.initialValues.role : [], isNextActive:false, isSubmitActive:false, selectedSurveyType: props.initialValues && props.initialValues.survey_type ? props.initialValues.survey_type : []}
  }

  selectRoles(checkedData){ 
    let rolesId = checkedData ? checkedData.map((i)=> i.id) : [];
    this.setState({selectedRoles: rolesId.length && rolesId.filter((item)=> item !== 0 )});    
  }

  selectSurveyType(checkedData){
    let surveyTypeId = checkedData ? checkedData.map((i)=> i.id) : [];
    this.setState({selectedSurveyType: surveyTypeId.length && surveyTypeId.filter((item)=> item !== 0 )});
  }

  selectTeams(checkedData){
    let teamsId = checkedData && checkedData.map((i)=> i.id);
    this.setState({selectedTeams: teamsId.length && teamsId.filter((item)=> item !== 0 )});      
  }

  handleDeptChange(checkedData, setFieldValue){
    let departmentId = checkedData && checkedData.map((i)=> i.id); 
    departmentId = departmentId.length ? departmentId.filter((item)=> item !== 0) : [];
    this.setState({selectedDepts : departmentId })   
    this.props.usecase.handleDepartmentChange(departmentId);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const { selectedRoles , selectedForm, isNextActive , selectedDepts , selectedTeams, selectedSurveyType } = this.state;
    const { data: { roleList, departmentList, teamList } } = this.props;
    const buttonType = isNextActive ? 'next' : 'save';
    setErrors({});
    let detail = {};
    detail.template_data = {};
    detail.schedule = {};
    detail.context = { channel : {}};
    detail.template_data.label = values.label;
    detail.schedule.frequency = values.frequency * 3600;
    detail.template_data.outcome = values.outcome;
    detail.context.channel.kiosk = values.kiosk_check ? {} : false;
    detail.context.channel.app = values.mobile_check ? {} : false;
    detail.context.department_ids = selectedDepts.length ? selectedDepts : [];
    detail.context.team_ids = selectedTeams.length && selectedTeams.filter((id) => teamList.length && teamList.find(( elem ) => elem.id === id && elem.id !== 0));;
    detail.is_scehduled = values.frequency ? true : false;
    detail.is_authenticated = false;
    detail.oauth_roles = selectedRoles ? selectedRoles : [];
    detail.is_active = values.is_active ? true : false;
    detail.survey_type = values.survey_type && values.survey_type.value ? values.survey_type.value : values.survey_type;
    detail.template_data.survey_language = ["English","Spanish"];
    if((values.survey_type && values.survey_type.value && values.survey_type.value == 'geofence_breach') || (values.survey_type == 'geofence_breach'))
    {
      detail.context.channel.kiosk=false
    }
    if(detail.context.team_ids && detail.context.team_ids.length){
      detail.context.team_ids = detail.context.team_ids;
    }
    else{
      detail.context.team_ids = [];
    }

    this.props.saveSurveyData(detail,buttonType)
    .catch((e)=>{
      setErrors(e.errors);
    })
  }

  render() {
    const { isNextButtonLoader , isSaveButtonLoader, initialValues, data: { roleList, departmentList, teamList } } = this.props;
    const { selectedForm, isNextActive, isSubmitActive } = this.state;
    return (
      <section>
        <div style={{background:'white'}}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            enableReinitialize={true}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit} className="mt-0">
              <div className="ml-3">
                <Row className="ml-0 mr-0">
                  <Col md={9} className="mt-4">
                    <FormGroup>
                      <Label className={styles['fw-6']}>Title</Label>
                      <Input
                        type="text"
                        name="label"
                        placeholder="Title of the survey form"
                        data-lpignore={true}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.label}
                        className={errors && errors.label ? "form-control-error" : null}
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.label}</FormText></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-3 mt-2">
                  <Col md={3} className="pl-0">
                    <FormGroup className="survey-type-drop">
                    <Label className="mb-0">Survey Type</Label>
                    <Field
                      component={FormikSelectAtom}                         
                      options={SURVEY_TYPE_OPTIONS}
                      name="survey_type"
                      id="survey_type"
                      placeholder="Survey Type"
                      onChange={e => {
                        setFieldValue('kiosk_check', e?.value !== "geofence_breach")
                        handleChange(e)
                      }}
                    />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="ml-3 mt-4">
                  <div className={styles['fw-6']}>Visible On</div>
                </Row>
                {((values.survey_type && values.survey_type.value && values.survey_type.value == 'geofence_breach') || (values.survey_type == 'geofence_breach')) ?
                 <Row className="ml-3 mt-2">
                 <Col md={2} className="pl-0">
                 <FormGroup>
                   <label className="checkmarkcontainer mb-0">Mobile
                     <Input 
                       type="checkbox"
                       id="mobile_check" 
                       name="mobile_check"
                       onBlur={handleBlur}
                       value={values.mobile_check}
                       checked={values.mobile_check}
                       onChange={handleChange}
                     />
                     <span className="checkmarkblue"></span>
                   </label>
                 </FormGroup>
               </Col>
               </Row>:
                <Row className="ml-3 mt-2">
                  <Col md={2} className="pl-0">
                    <FormGroup>
                      <label className="checkmarkcontainer mb-0">Kiosk
                        <Input 
                          type="checkbox"
                          id="kiosk_check" 
                          name="kiosk_check"
                          onBlur={handleBlur}
                          value={values.kiosk_check}
                          checked={values.kiosk_check}
                          onChange={handleChange}
                        />
                        <span className="checkmarkblue"></span>
                      </label>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <label className="checkmarkcontainer mb-0">Mobile
                        <Input 
                          type="checkbox"
                          id="mobile_check" 
                          name="mobile_check"
                          onBlur={handleBlur}
                          value={values.mobile_check}
                          checked={values.mobile_check}
                          onChange={handleChange}
                        />
                        <span className="checkmarkblue"></span>
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
            }
                <Row className="ml-3 mt-4">
                  <div className={styles['fw-6']}>Visible To</div>
                </Row>
                <Row className="ml-0 mt-2">
                  <Col md={3}>
                  <FormGroup>
                    <Field
                      id="role"
                      name="role"
                      component={MultiSelectAtom}
                      items={roleList}
                      placeholder="Select Roles"
                      itemToString={i => i && i.name}
                      onBlur={handleBlur}
                      onChange={this.selectRoles}
                      className={errors && errors.role ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.role}</FormText></div>
                  </FormGroup>
                  </Col>
                  <Col md={3}>
                  <FormGroup>
                    <Field
                      id="departments"
                      name="departments"
                      component={MultiSelectAtom}
                      items={departmentList}
                      placeholder="Select Departments"
                      itemToString={i => i && i.name}
                      onBlur={handleBlur}
                      onChange={(v) => this.handleDeptChange(v, setFieldValue)}
                      className={errors && errors.role ? "form-control-error" : null}
                    />
                  </FormGroup>
                  </Col>
                  <Col md={3}>
                  <FormGroup>
                    <Field
                      id="teams"
                      name="teams"
                      component={MultiSelectAtom}
                      items={teamList}
                      placeholder="Select Teams"
                      itemToString={i => i && i.name}
                      onChange={this.selectTeams}
                      onBlur={handleBlur}
                      className={errors && errors.role ? "form-control-error" : null}
                      disabled={this.state.selectedDepts && this.state.selectedDepts.length ? false : true}
                    />
                  </FormGroup>
                  </Col>
                  </Row>
                  {((values.survey_type && values.survey_type.value && values.survey_type.value == 'others') || (values.survey_type == 'others')) &&
                    <Row className="ml-0 mt-2">
                      <Col md={3}>
                        <FormGroup>
                          <Label className={styles['fw-6']}>Repeat after (in hours)</Label>
                          <Input
                            type="number"
                            name="frequency"
                            placeholder="Repeat after (in hours)"
                            data-lpignore={true}
                            autoComplete="off"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.frequency}
                          />
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.frequency}</FormText></div>
                        </FormGroup>
                      </Col>
                    </Row>
                  }
                  {((values.survey_type && values.survey_type.value && values.survey_type.value == 'others') || (values.survey_type == 'others')) &&
                    <div>
                      <Row className="ml-3 mt-4">
                        <div className={styles['fw-6']}>Type Of Form</div>
                      </Row>
                      <Row className="ml-0 mt-2">
                        <Col className="ml-3 pl-0 mb-3">
                          <FormGroup>
                            <label className="container mb-0">Assesment Form <span className={`${styles['form-type-subheading']} ml-2`}>(User can Punch-in on the basis of score achieved)</span>
                              <Input 
                                type="radio"
                                id="scoring" 
                                name="outcome"
                                checked={values.outcome === 'scoring'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value='scoring'
                              />
                              <span className="checkmark"></span>
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="ml-0">
                        <Col className="ml-3 pl-0">
                          <FormGroup>
                            <label className="container mb-0">Feedback Form <span className={`${styles['form-type-subheading']} ml-2`}>(No score is calculated)</span>
                              <Input 
                                type="radio"
                                id="non-scoring" 
                                name="outcome"
                                checked={values.outcome === 'non-scoring'}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value='non-scoring'
                              />
                              <span className="checkmark"></span>
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  }
                  <Row className="ml-0 mt-2">
                    <Col md={6} className="mt-2">
                      <FormGroup>
                        <label className="checkmarkcontainer mb-0">Is Active?
                          <Input 
                            type="checkbox"
                            id="is_active" 
                            name="is_active"
                            onBlur={handleBlur}
                            value={values.is_active}
                            checked={values.is_active}
                            onChange={handleChange}
                          />
                          <span className="checkmarkblue"></span>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                <Row>
                  <Col className="mr-5 mt-2 mb-5">
                    <Button
                      color="secondary"
                      className="float-right"
                      type="submit"
                      disabled={isNextButtonLoader}
                      onClick={()=>this.setState({isNextActive:true, isSubmitActive:false})}
                    >
                      Next <ButtonLoaderAtom active={isNextButtonLoader} />
                    </Button>
                    <Button
                      color="secondary"
                      className="float-right mr-3"
                      type="submit"
                      disabled={isSaveButtonLoader}
                      onClick={()=>this.setState({isNextActive:false, isSubmitActive:true})}
                    >
                      Save <ButtonLoaderAtom active={isSaveButtonLoader} />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
            )}/>
        </div>
      </section>
    );
  }

}

export default SurveyFormComponent;