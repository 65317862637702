import React from 'react';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { GoogleApiWrapper } from 'google-maps-react';
import connect from 'react-redux/lib/connect/connect';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';

import MarkerClusterComponent from 'components/common/googleMap/MarkerCluster';
import MapComponent from 'components/common/googleMap/Map';

import SectionLoaderAtom from 'atoms/SectionLoader';
import styles from './styles.module.scss';
import BeaconIcon from './beaco.png';
import MarkerIcon from './beaco.png';
import closeimage from "./CloseCross.png";

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

class DashboardTrackingFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state={ currentIndex: null,activeShape: {}, shapeEvent: {}, showInfoWindow: false,  selectedMarkerId: null};
    this.currentIndex = -1;
    this.markers = [];
    this.onShapeClick = this.onShapeClick.bind(this);
    this.highlightMarker = this.highlightMarker.bind(this);
    this.nextMarker = this.nextMarker.bind(this);
    this.moveCaretAtEnd = this.moveCaretAtEnd.bind(this);
    this.prevMarker = this.prevMarker.bind(this);
    this.onPressEnter = this.onPressEnter.bind(this);
  }

  componentWillMount(){
    if(this.props.activityTrackList.tracking && this.props.activityTrackList.tracking.length){
      this.setState(({currentIndex}) => ({ currentIndex: this.props.activityTrackList.tracking.length }));
    }
  }

  componentWillReceiveProps(nextProps){
    if((nextProps.activityTrackList.tracking && nextProps.activityTrackList.tracking.length ) !==
      (this.props.activityTrackList.tracking && this.props.activityTrackList.tracking.length )){
      this.setState(({currentIndex}) => ({ currentIndex: nextProps.activityTrackList.tracking.length }));
    }
  }

  onShapeClick(data) {
    this.props.onMarkerClick(data);
  }
  
  onPressEnter(event){
    if(event.which === 13){
      this.node.blur();
    }
  }

  highlightMarker(marker){
    if(marker.id >= this.markers.length -1){
      const newarr = this.props.activityTrackList.tracking && this.props.activityTrackList.tracking.filter((item) => item.id <= marker.id);
      this.markers = newarr;
      this.currentIndex = newarr && newarr.length - 1;
      this.setState({ currentIndex: newarr && newarr.length });
    }
    else if(this.state.currentIndex === ''){
      this.currentIndex = marker.id;
      this.setState({ currentIndex: marker.id + 1 });
    }
    this.setState({ selectedMarkerId: marker.id });
    if(!marker.showInfo){
      this.props.toggleInfoWindow(marker);
    }
  }
  
  changeIndex(event){
    const { activityTrackList } = this.props;
    const val = event.target.value;
    const res = val.match('^[0-9]+$');
    if(val === ''){
      return this.setState({ currentIndex: '' });
    }
    if(val === '0' || res === null){
      return toast.error('Choose a valid marker');
    }
    const newarr = activityTrackList.tracking && activityTrackList.tracking.filter((item) => item.id < val);
    if(val > activityTrackList.tracking.length) return toast.error('Choose a valid marker');
    this.markers = newarr;
    this.currentIndex = newarr && newarr.length - 1;
    this.setState({ selectedMarkerId: val, currentIndex: newarr && newarr.length });
    const isInfoOpen = activityTrackList.tracking && activityTrackList.tracking.filter((item) => item.id === val-1).length ?
      activityTrackList.tracking.filter((item) => item.id === val-1)[0].showInfo : true;

    const marker = activityTrackList.tracking && activityTrackList.tracking.filter((item) => item.id === val-1).length ?
      activityTrackList.tracking.filter((item) => item.id === val-1)[0] : null;
    if(!marker.address){
      this.props.getLocationAddress(marker);
    }
    if(!isInfoOpen){
      this.props.toggleInfoWindow({ id: val-1 });
    }
    return true;
  }

  moveCaretAtEnd(e) {
    const tempValue = e.target.value;
    e.target.value = '';
    e.target.value = tempValue;
  }

  nextMarker() {
    this.currentIndex += 1;
    const currentMarker = this.props.activityTrackList.tracking[
      this.currentIndex];
    this.markers.push(currentMarker);
    this.setState({
      center: {
        lat: currentMarker.location.coordinates[1],
        lng: currentMarker.location.coordinates[0]
      },
    });
    this.setState({ selectedMarkerId: currentMarker.id, currentIndex: this.currentIndex + 1  });
    this.props.onMarkerClick(currentMarker);
  }

  prevMarker() {
    this.currentIndex -= 1;
    const currentMarker = this.props.activityTrackList.tracking[
      this.currentIndex];
    this.markers.pop();
    this.setState({
      center: {
        lat: currentMarker.location.coordinates[1],
        lng: currentMarker.location.coordinates[0]
      },
    });
    this.setState({ selectedMarkerId: currentMarker.id, currentIndex: this.currentIndex + 1 });
    this.props.onMarkerClick(currentMarker);
  }

  render() {
    const { isOpen, mapLoading, employeeAll , toggleTracking, google, profile, update , activityTrackList, handleMarkerClick } = this.props;
  
    const { currentIndex, selectedMarkerId } = this.state;

    const activityTemp = {
      is_temp: 1,
      name:"trevor",
      company: 1,
      create_date: "2018-10-25T08:14:00Z",
      department: null,
      employee: 4847,
      end_datetime: "2018-10-25T09:15:00Z",
      id: 64669,
      is_active: true,
      is_deleted: false,
      is_edited: false,
      is_ended: true,
      is_signed_off: true,
      meal_delayed: false,
      meal_violation_count: 0,
      modified_date: "2018-10-25T10:36:18.059007Z",
      session_id: "e5aa6c3c-11b0-4356-beff-e336fcc5c9ec",
      team: null,
      time_allocation_status: "ALLOCATED",
      total_meal_minutes: 0,
      total_work_mins: 0,
      tracking: [{
        activity_code: "PUNCH-IN",
        id:0,
        activity_name: "Shift Start",
        showInfo:true,
        address: "800 Quintana Rd, Morro Bay, CA93442, USA",
        date: "2018-10-25",
        datetime: "October 25, 2018 08:14 AM",
        employee_name: "trevor",
        location:{ 
          coordinates:{
            0: -120.851173,
            1: 35.370781
          },
          type: "Point",
        },  
        split_id: 117745,
      }]
    }
    
    let activities = {};
    if(activityTrackList.tracking && activityTrackList.tracking.length){
      activities=activityTrackList;
    } 
    else{
      activities=activityTemp;
    }

    this.markers = activities.tracking && activities.tracking.map((item)=>({...item,
      position: {lng: item.location.coordinates[0], lat: item.location.coordinates[1]},
      shape_type: "Point",
      lat_long: item.location,
    }));
    if (activities.tracking && activities.tracking.length) {
      if (this.currentIndex === -1) {
        this.currentIndex = activities.tracking.length - 1;
      }
      this.markers = this.markers.slice(0, this.currentIndex + 1);
    }
    this.markers = this.markers && this.markers.map((item) => {
      let iconObj;
      let markerObj = {};

      if(item.activity_name === 'Beacon'){
        iconObj = {
          url: BeaconIcon,
          scaledSize: google && new google.maps.Size(30, 30),
          labelOrigin: google && new google.maps.Point(15,12),
          origin: google && new google.maps.Point(0, 0),
        };
      }else {
        iconObj = {
          url: MarkerIcon,
          scaledSize: google && new google.maps.Size(30, 30),
          labelOrigin: google && new google.maps.Point(15,12),
          origin: google && new google.maps.Point(0, 0),
        };
      }
      markerObj = {...item, icon: iconObj};
      return markerObj;
    });
  
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleTracking}
        className="tracking-modal emp-dashboard-modal"
        size="lg"
        backdrop='static'
      >
        {activities && activities.is_temp ?
          <ModalHeader className={styles['modal-heading']}>View session tracking for {activities.name}<img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggleTracking()} /><span/></ModalHeader>
          :<ModalHeader className={styles['modal-heading']}>View session tracking for {employeeAll[0].user.first_name}<img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggleTracking()} /></ModalHeader>
        }  
        <ModalBody className={`${styles["modal-map-wrapper"]} mb-2`}>
          {mapLoading ? <SectionLoaderAtom active />
            :<Row className={`${styles["map-height"]} mt-2`}>
              {activities && activities.session_id &&
                <Col md={6} className={`{$styles["map-height"]} pr-0`}>
                  <MapComponent
                    google={google}
                    locations={this.markers}
                    getAddressForMarker={this.getAddressForMarker}
                  >
                    <MarkerClusterComponent
                      data={this.markers}
                      onClick={this.onShapeClick}
                      toggleInfoWindow={handleMarkerClick}
                      selectedMarkerId={parseInt(selectedMarkerId, 10)}
                    />
                  </MapComponent>
                  <div className="float-right mt-2"> 
                    <Button
                      className={styles['pagination-btn']}
                      color="default"
                      disabled={this.currentIndex === 0}
                      onClick={this.prevMarker}
                    > 
                    &#8249;
                    </Button>
                    {activities && activities.is_temp?
                      <input value="1" className={styles['pagination-btn__current']} />
                      :<input
                        className={styles['pagination-btn__current']}
                        value={currentIndex}
                        onChange={(val) => this.changeIndex(val)}
                        onKeyPress={(event) => this.onPressEnter(event)}
                        onFocus={this.moveCaretAtEnd}
                      />
                    }  
                    <Button
                      className={styles['pagination-btn']}
                      color="default"
                      disabled={Object.keys(activities.tracking).length > 0 ? this.currentIndex + 1 === activities.tracking.length : null}
                      onClick={this.nextMarker}
                    >
                      &#8250;
                    </Button>
                  </div>  
                </Col>
              }
              {activities && activities.session_id &&
                <Col md={3} className="white-background map-side-bar pr-0">
                  {activities.tracking && activities.tracking.map((item)=>(
                    <Row className={`${styles["div-map"]} pt-2`}>
                      <Col md={2} className="pr-0 tracking-modal-outline" role="button" tabIndex="0" onClick={() => this.highlightMarker(item)}>
                        <span><img src={MarkerIcon} height="20" alt="marker"/>
                          <span className={styles['marker-icon-label']}>{item.id + 1}</span>
                        </span>
                      </Col>
                      <Col className="pl-0 tracking-modal-outline negative-map" role="button" tabIndex="0" onClick={() => this.highlightMarker(item)}>  
                        <p className="mb-2">{item.activity_name} : {item.datetime}</p>
                        <p className="mb-1">{item.address}</p>
                      </Col>  
                    </Row>  
                  ))}
                </Col>
              }    
              <Col md={activities && activities.session_id?"3":"4"} className="text-center">
                <h6 className={`${styles["map-head"]} pl-0 mb-0 pb-1`}>Hello There, </h6>
                <h6 className={`${styles["map-head"]} pl-0 mb-0 text-center pt-0`}>{profile.first_name}</h6>
                <div className="text-center">
                  <Button className={styles["modal-btn"]} onClick={()=>update(null,3)}>FINISHED</Button>
                </div>
              </Col>
            </Row>
          }    
        </ModalBody>
      </Modal>  
    );
  }
};


DashboardTrackingFormModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const withConnect = connect(
)(DashboardTrackingFormModalComponent);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION
})(withConnect);