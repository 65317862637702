import React from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import SSTCFLayout from 'layouts/SSTCF';
import { NAV } from 'constants/vendors';
import Col from 'reactstrap/lib/Col';
import { toast } from 'react-toastify';

import * as UserDucks from 'ducks/accounts/user';
import * as PaymentDucks from 'ducks/vendors/payment';

import CompanyPlanComponent from 'payments/bills/components/plan';
import ConfirmPasswordModal from 'payments/bills/components/confirmPasswordModal';


import planUsecase from 'payments/bills/usecases/planUseCase';
import BillApiGateway from 'payments/bills/gateways/bill';
import UserAPIGateway from 'accounts/gateways/user';

class CompanyBillorPlanPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.listenCompanyBillUseCase = this.listenCompanyBillUseCase.bind(this);
    const billApiGateway = new BillApiGateway();
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new planUsecase(billApiGateway, userAPIGateway);
    this.fetchData = this.fetchData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.state={
      companyPlanDetails: [],
      isInfoOpen: true,
      infoList: [],
      parent : NAV.CompanyBillorPlanPage.parent,
      isModalOpen: false,
      isLoading: false,
      planDetails : [],
    }
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.listenCompanyBillUseCase();
    const cookieKey = cookie.load("company_plan_info");
    if(cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }
    this.fetchData();
    this.usecase.getInfoList();
  }


  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  fetchData(){
    const { profile } = this.props;
    this.usecase.getCompanyPlan(profile.company.id);
  }

  listenCompanyBillUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_LOADER':{
          this.updateState('isLoading', true);
          break;
        }
        case 'GET_COMPANY_PLAN_SUCCESS':
          this.setState({ companyPlanDetails: event.companyPlan,
            isLoading: false });
          break;
        case 'CANCEL_PLAN_SUCCESS': {
          toast.success('Your plan cancellation is in progress');
          this.updateState('isModalOpen', !this.state.isModalOpen);
          this.fetchData();
          break;
        }
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'GET_CUSTOMER_DETAIL_SUCCESS':{
          this.props.dispatch({
            type: 'vendors/payment/GET_CUSTOMER_FULFILLED',
            payload: event.detail,
          });
          break;
        }
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("company_plan_info", !isInfoOpen);
  }


  toggleModal(){
    const { isModalOpen } = this.state;
    this.setState({ isModalOpen: !isModalOpen });
  }

  render() {
    const { isInfoOpen, infoList, parent,
      companyPlanDetails, isModalOpen, isLoading } = this.state;
    const { profile, customerDetail } = this.props;
    const planType = customerDetail && customerDetail.plan && customerDetail.plan.plan_type;
    let tabs;
    planType ==='online'?
    tabs = [
      { href: '/vendor/plan', label: 'Plan' },
      { href: '/vendor/invoice', label: 'Invoices' },
      { href: '/vendor/payment-method', label: 'Payment Methods' }
    ]
    :
    tabs = [
      { href: '/vendor/plan', label: 'Plan' },
      { href: '/vendor/invoice', label: 'Invoices' },
      
    ];



    return(
      <SSTCFLayout
        navInfo={{...NAV.CompanyBillorPlanPage, parent}}
        tabItems={tabs}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.fetchData}
        infoList={infoList}
      >
        <Col>
          <CompanyPlanComponent
            companyPlanDetails ={companyPlanDetails}
            toggleModal={this.toggleModal}
            isLoading={isLoading}
            customerDetail={customerDetail}
          />
        </Col>
        {
          isModalOpen &&
            <ConfirmPasswordModal
              isOpen={isModalOpen}
              toggle={this.toggleModal}
              usecase={this.usecase}
              initialValues={{ email: profile.username,
                company_id: profile.company.id }}
            />
        }
      </SSTCFLayout>
    );
  }
}

const mapStatetoProps = (state) => ({
  profile: UserDucks.profile(state),
  customerDetail: PaymentDucks.customerDetail(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

CompanyBillorPlanPage.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default connect (
  mapStatetoProps,
  mapDispatchToProps,
)(CompanyBillorPlanPage);
