import React from 'react';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import ListGroupItemText from 'reactstrap/lib/ListGroupItemText';
import ListGroupItemHeading from 'reactstrap/lib/ListGroupItemHeading';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import CheckboxAtom from 'atoms/Checkbox';
import closeimage from './Close-Cross.png';

import { STATUSES, RESOLVED_AS } from 'constants/activities';
import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';

/**
 * ActivitySplitListPage -> ActivitySplitListSection -> ActivitySplitFormModalComponent
 *
 * Props:
 *    - update
 */
class ActivitySplitFormModalComponent extends React.Component{
  constructor(props){
    super(props);
    this.parseDate =  this.parseDate.bind(this);
    this.reverseParseDate = this.reverseParseDate.bind(this);
    this.submit = this.submit.bind(this);
  }


  parseDate(dateString){
    // convert user timezone date to browser timezone date
    const { userTimezone } = this.props;
    const momentWithUserTimezone = moment.tz(dateString, userTimezone);
    const newMoment = moment([
      momentWithUserTimezone.year(),
      momentWithUserTimezone.month(),
      momentWithUserTimezone.date(),
      momentWithUserTimezone.hour(),
      momentWithUserTimezone.minute(),
      momentWithUserTimezone.second(),
      momentWithUserTimezone.millisecond(),
    ]);
    return newMoment.toDate();
  }

  reverseParseDate(dateString){
    // convert browser timezone date to user timezone date
    const { userTimezone } = this.props;
    const momentWithBrowserTimezone = moment(dateString);
    const newMoment = moment.tz([
      momentWithBrowserTimezone.year(),
      momentWithBrowserTimezone.month(),
      momentWithBrowserTimezone.date(),
      momentWithBrowserTimezone.hour(),
      momentWithBrowserTimezone.minute(),
      momentWithBrowserTimezone.second(),
      momentWithBrowserTimezone.millisecond(),
    ], userTimezone);
    return newMoment;
  }

  submit(data){
    const { submitManualSession, update, toggleConfirmationModal, initialValues } = this.props;
    const detail = { ...data };
    let checkboxValue = cookie.load('isCheckboxTrue');
    if(checkboxValue){
      checkboxValue = JSON.parse(checkboxValue);
    }
    // Update datetimes only when edited
    // if (typeof data.start_datetime_tz !== 'string') {
    detail.start_datetime = this.reverseParseDate(data.start_datetime_tz).toISOString();
    // }
    // if (typeof data.end_datetime_tz !== 'string') {
    detail.end_datetime = this.reverseParseDate(data.end_datetime_tz).toISOString();
    // }
    detail.meal_start_datetime = this.reverseParseDate(data.meal_start_datetime_tz).toISOString();
    detail.meal_end_datetime = this.reverseParseDate(data.meal_end_datetime_tz).toISOString();

    if(moment(detail.end_datetime) > moment()) {
      const err = { end_datetime_tz: [ 'End Time must be less than current time']};
      throw new SubmissionError(err);
    }
    if(moment(detail.start_datetime) > moment()) {
      const err = { start_datetime_tz: [ 'Start Time must be less than current time']};
      throw new SubmissionError(err);
    }

    /*if( detail.activity_code === 'PUNCH-IN' && (initialValues.status !== data.status) && !checkboxValue ){
      console.log('helo');
      return toggleConfirmationModal('true',detail);
    } */   
   
    if (detail.add_meal) {
      return submitManualSession(detail)
        .then(response => (
          update(detail, initialValues)
            .catch((err) => {
              const errObj = {
                _error: err.non_field_errors,
                ...err,
              };
              if('split_in_session' in err){
                toggleConfirmationModal('true', detail);
              }
              throw new SubmissionError(errObj);
            })
        ))
        .catch((err) => {
          const errObj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errObj);
        });
    }
    return update(detail, initialValues)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        if('split_in_session' in err){
          err.split_in_session && toast.error(err.split_in_session[0]);
          toggleConfirmationModal('true', detail);
        }
        throw new SubmissionError(errObj);
      });
  }

  render(){
    const {
      error,
      isOpen,
      submitting,
      dateFormat,
      initialValues,
      timeFormat,
      timeFormat24Hrs,
      handleSubmit,
      toggle,
      formData,
      addMeal,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size={initialValues.message.length ? "lg" : "md"}
        className="form-modal"
        backdrop="static"
      >
        <ModalHeader>Edit Activity
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalBody>
            <div className="error-text">{error}</div>
            <Row>
              <Col sm="12" md={initialValues.message.length > 0 ? "6" : "12"}>
                <Field
                  id="start_datetime_tz"
                  name="start_datetime_tz"
                  label="Start Time"
                  component={DateTimePickerAtom}
                  layout={FORM_LAYOUT}
                  placeholder="Start Time"
                  options={{
                    dateFormat: `${dateFormat} ${timeFormat}`,
                    enableSeconds: false,
                    enableTime: true,
                    noCalendar: !initialValues.session_split,
                    time_24hr: timeFormat24Hrs,
                    parseDate: this.parseDate,
                    enable: [
                      // Enable the dates b/w payroll startDate & endDate
                      // Also no future date selection
                      (date) => moment(date).isBefore(moment()),
                      
                    ],
                    onValueUpdate: (selectedDates, dateStr, instance) => {
                    // case I: selected - 29 sep, moment: 29, init: 27
                    // case II: selected - 29 sep, moment: 29, init: 29
                      if (moment(selectedDates[0]) === moment()
                        && moment() !== moment(initialValues.start_datetime_tz)) {
                        instance.setDate(this.parseDate(initialValues.start_datetime_tz));
                      }
                    },
                    // minDate: moment(initialValues.start_datetime).subtract(1, 'day').toDate(),
                    // maxDate: moment(initialValues.start_datetime).add(1, 'day').toDate(),
                  }}
                />
                <Field
                  id="end_datetime_tz"
                  name="end_datetime_tz"
                  label="End Time"
                  component={DateTimePickerAtom}
                  layout={FORM_LAYOUT}
                  placeholder="End Time"
                  options={{
                    dateFormat: `${dateFormat} ${timeFormat}`,
                    enableSeconds: false,
                    enableTime: true,
                    noCalendar: !initialValues.session_split,
                    time_24hr: timeFormat24Hrs,
                    parseDate: this.parseDate,
                    enable: [
                      // Enable the dates b/w payroll startDate & endDate
                      // Also no future date selection
                      (date) => moment(date).isBefore(moment()),
                    ],
                    onValueUpdate: (selectedDates, dateStr, instance) => {
                      // case I: selected - 29 sep, moment: 29, init: 27
                      // case II: selected - 29 sep, moment: 29, init: 29
                      if (moment(selectedDates[0]) === moment()
                        && moment() !== moment(initialValues.end_datetime_tz)) {
                        instance.setDate(this.parseDate(initialValues.end_datetime_tz));
                      }
                    },
                    // minDate: moment(initialValues.end_datetime).subtract(1, 'day').toDate(),
                    // maxDate: moment(initialValues.end_datetime).add(1, 'day').toDate(),
                  }}
                />
                <Field
                  id="status"
                  name="status"
                  label="Status"
                  component={SelectAtom}
                  layout={FORM_LAYOUT}
                  placeholder="Status"
                  options={STATUSES}
                  simpleValue
                />
                <Field
                  id="validity_display"
                  name="validity_display"
                  label="Validity"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  placeholder="Validity"
                  disabled
                />
                {(formData.activity_code === 'PUNCH-IN' && formData.validity_display.indexOf('Missing Meal') >= 0) && formData.status_display === 'Awaited Approval' &&
                  <Field
                    type="checkbox"
                    name="add_meal"
                    label="Add Meal"
                    component={CheckboxAtom}
                    layout={FORM_LAYOUT}
                  />
                }
                {addMeal &&
                  <section>
                    <Field
                      id="meal_start_datetime"
                      name="meal_start_datetime_tz"
                      label="Meal Start Time"
                      component={DateTimePickerAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Meal Start Time"
                      options={{
                        dateFormat: `${dateFormat} ${timeFormat}`,
                        enableSeconds: false,
                        noCalendar: !initialValues.session_split,
                        enableTime: true,
                        time_24hr: timeFormat24Hrs,
                        parseDate: this.parseDate,
                        enable: [
                          // Enable the dates b/w payroll startDate & endDate
                          // Also no future date selection
                          (date) => moment(date).isBefore(moment()),
                        ],
                      }}
                    />
                    <Field
                      id="meal_end_datetime"
                      name="meal_end_datetime_tz"
                      label="Meal End Time"
                      component={DateTimePickerAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Meal End Time"
                      options={{
                        dateFormat: `${dateFormat} ${timeFormat}`,
                        enableSeconds: false,
                        enableTime: true,
                        noCalendar: !initialValues.session_split,
                        time_24hr: timeFormat24Hrs,
                        parseDate: this.parseDate,
                        enable: [
                          // Enable the dates b/w payroll startDate & endDate
                          // Also no future date selection
                          (date) => moment(date).isBefore(moment()),
                        ],
                      }}
                    />
                  </section>
                }
                {formData.validity_display.indexOf('Valid') >= 0
                  ? null
                  : (<Field
                    id="resolved_as"
                    name="resolved_as"
                    label="Resolved As"
                    component={SelectAtom}
                    layout={FORM_LAYOUT}
                    placeholder="Resolved As"
                    options={RESOLVED_AS}
                    simpleValue
                  />)
                }
                <Field
                  id="comments"
                  name="reason"
                  label="Comments"
                  component={InputAtom}
                  layout={FORM_LAYOUT}
                  type="textarea"
                  placeholder="Comments (max 500 characters)"
                  maxLength="500"
                />
                {initialValues.activity_code !== "MEAL-PERIOD" &&
                  <Field
                    id="job_name"
                    name="job_label"
                    label="Job Name"
                    component={InputAtom}
                    layout={FORM_LAYOUT}
                    type="textarea"
                    placeholder="Job Name (max 500 characters)"
                    maxLength="500"
                  />
                }
              </Col>
              {initialValues.message.length > 0
                ? (<Col sm="12" md="6" className={styles['listgroup--col']}>
                  <div className={styles['listgroup--scrollbar']}>
                    {initialValues && initialValues.message.map(item => (
                      <ListGroup key={`${item.timestamp}${Math.random()}`}>
                        <ListGroupItem className={styles['listgroup--item']}>
                          <ListGroupItemHeading>{item.user}
                            <span className="ml-2">{item.timestamp}</span>
                          </ListGroupItemHeading>
                          <ListGroupItemText>{item.message}</ListGroupItemText>
                        </ListGroupItem>
                      </ListGroup>
                    ))}
                  </div>
                </Col>)
                :null
              }
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
              Submit <ButtonLoaderAtom active={submitting} />
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};
  if (!values.start_datetime) errors.start_datetime = 'Enter Start Time';
  if (!values.end_datetime) errors.end_datetime = 'Enter End Time';
  if (!values.reason) errors.reason = 'Enter Comments';
  if (!values.status) errors.status = 'Enter Status';
  return errors;
};

ActivitySplitFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  userTimezone: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  submitManualSession: PropTypes.func.isRequired,
};

const ActivitySplitFormModal = reduxForm({
  form: 'ActivitySplitForm',
  validate,
  enableReinitialize: true,
})(ActivitySplitFormModalComponent);

const selector = formValueSelector('ActivitySplitForm');

export default connect(
  (state) => {
    const addMeal = selector(state, 'add_meal');
    return { addMeal };
  },
)(ActivitySplitFormModal);