import React from "react";

import { toast } from "react-toastify";
import PropTypes from "prop-types";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import StorageGateway from "lib/storage-gateway";
import * as RouteDucks from "ducks/routes";
import * as PtoDucks from "ducks/pto/pto";
import SectionLoaderAtom from "atoms/SectionLoader";

import BulkUploadFormComponent from "common/BulkUploadForm";

/**
 * EmployeeListPage -> PtoBulkUploadSection
 *
 * Components:
 *    - {@link EmployeeBulkUploadFormComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
let company_id = "";
let nre_company_id = "";

class PtoBulkUploadSection extends React.Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.sampleFile = this.sampleFile.bind(this);
    this.state = { errorEmp: {}, stats: {}, isBulkLoading: false };
  }

  update(data) {
    company_id = StorageGateway.get("company_id");
    nre_company_id = StorageGateway.get("nre_company_id");
    this.setState({ isBulkLoading: true });
    return this.props.PtoDucks.postBulkPto(data, { company_id: company_id || nre_company_id })
      .then((res) => {
        if (
          res.value.stats &&
          res.value.stats.created === res.value.stats.total
        ) {
          toast.success("Pto Policy Uploaded Succesfully");
        }
        if (
          res.value.stats &&
          res.value.stats.updated === res.value.stats.total
        ) {
          toast.success("Pto Policy Updated Succesfully");
        }
        this.setState({
          errorEmp: {},
          stats: res.value.stats,
          isBulkLoading: false,
        });
      })
      .catch((err) => {
        if (err.non_field_errors) {
          toast.error(err.non_field_errors[0]);
        } else if (err.employee) {
          toast.error(err.employee);
          this.setState({ isBulkLoading: false });
          this.setState({ errorEmp: err, stats: err.stats });
        } else if (err.stats && !err.stats.created && !err.stats.updated) {
          toast.error("Unable to upload file");
        } else if (err.stats && err.stats.updated === err.stats.total) {
          toast.error(
            "Some values are not updated successfully. Please review the errors below and resubmit"
          );
        } else if (
          err.stats &&
          (err.stats.create_failed !== err.stats.total ||
            err.stats.update_failed !== err.stats.total)
        ) {
          toast.error(
            "Some values are not updated successfully. Please review the errors below and resubmit"
          );
        }
        this.setState({
          errorEmp: err.data,
          stats: err.stats,
          isBulkLoading: false,
        });
      });
  }

  sampleFile() {
    return this.props.PtoDucks.getBulkPto();
  }

  render() {
    const { isLoading } = this.props;
    const { errorEmp, stats, isBulkLoading } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <BulkUploadFormComponent
          update={this.update}
          sampleFile={this.sampleFile}
          errorEmp={errorEmp}
          stats={stats}
          isBulkLoading={isBulkLoading}
          flag={true}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
});

const mapActionsToProps = (dispatch) => ({
  PtoDucks: bindActionCreators(PtoDucks, dispatch),
});

PtoBulkUploadSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

PtoBulkUploadSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PtoBulkUploadSection);
