import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const MealViolationTableComponent = (props, context) => {
  const { router: { route: { match: { params } } } } = context;
  const { child, data, location: { query }, pageSize } = props;
  const { page = 1 } = query;
  
  return (
    <div className="ml-3 mr-4">
      <Table hover responsive size="sm" className="align-table align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."first_name"'>
            First Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"accounts_user"."last_name"'>
            Last Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='employee_employee_id'>
            Employee Id
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='"employees_employeeprofile"."title"'>
            Title
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='department_name'>
            Department Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='team_name'>
            Team Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='meal_violation_count'>
            Count Of Violation
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
        {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index}>
              <td title={item.first_name} className="pl-3">
                {child.length > 0 &&
                  <Link to={{
                    pathname: `/report/${child[0].id}/${child[0].code}/detail/${item.employee_id}`,
                    search: queryString.stringify({
                      reportDetail: JSON.stringify({ ...query, ...params }),
                      start_date: query.start_date,
                      end_date: query.end_date,
                    }),
                  }}>
                    {item.first_name}
                  </Link>
                }
              </td>
              <td title={item.last_name}>{item.last_name}</td>
              <td title={item.employee_employee_id}>{item.employee_employee_id}</td>
              <td title={item.title}>{item.title}</td>
              <td title={item.department_name}>{item.department_name}</td>
              <td title={item.team_name}>{item.team_name}</td>
              <td title={item.meal_violation_count} className="text-right pr-3">{item.meal_violation_count}</td>
            </tr>
          ))
        }
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
      </Table>
    </div>
  );
};

MealViolationTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

MealViolationTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default MealViolationTableComponent;