import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as CustomerTagDucks from 'ducks/customers/tag';
import * as JobDucks from 'ducks/jobs/job';
import * as CustomerDucks from 'ducks/customers/customer';
import * as UserDucks from 'ducks/accounts/user';
import * as NoteDucks from 'ducks/jobs/comment';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import JobListTableComponent from 'components/jobs/list/Table';
import SectionLoaderAtom from 'atoms/SectionLoader';
import CustomerFormComponent from 'components/customers/form/Form';
import CustomerFilterFormComponent from 'components/customers/form/Filter';
import CustomerFormModalComponent from 'components/customers/list/FormModal';
import TagModalComponent from 'components/jobs/form/TagModal';
import JobInfoModal from "components/jobs/list/JobInfoModal";

/**
 * CustomerListPage -> CustomerListSection
 *
 * Components:
 *    - {@link CustomerTableComponent}
 *
 * State:
 *    - customerDetails
 *
 * Actions:
 *    None
 */
class CustomerFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.create = this.create.bind(this);
    this.createTag = this.createTag.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.state = { isOpen: false,
      isTagLoading: false,
      isNameVisible: false,
      jobView:false,
      jobItem: null,
      isViewOpen: false,
      notesLoading: false,
      jobArray: [],
      jobData: [],
      isCardsLoading: false,
      isTagModal: false,
    };
    this.toggleQuickView = this.toggleQuickView.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.goTo = this.goTo.bind(this);
    this.getJobDetails =  this.getJobDetails.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.setJobReportsData = this.setJobReportsData.bind(this);
    this.getUpdatedList = this.getUpdatedList.bind(this);
  }
  componentWillMount(){
    this.chunkArray(2);
    this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  componentWillReceiveProps(nextProps){
    if(nextProps !== this.props){
      this.chunkArray(2);
    }
  }

  getJobDetails(query) {
    const { jobData } = this.state;
    const list = jobData.map((item) => {
      const jobDetail = { ...item };
      if(item.id === query && item.called === false ) {
        this.props.JobDucks.getJobDetailsMap({ id: query });
        jobDetail.called = true;
      }
      return jobDetail;
    });
    this.setState({ jobData: list });
  }

  getUpdatedList(){
    return this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  setJobReportsData(data){
    return this.props.JobDucks.setJobReportsInitialValues(data);
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }


  updateStatus(data) {
    const { jobItem, isViewOpen } = this.state;
    const tagIds = jobItem && jobItem.tags.map(item => ( item.id ));
    const params = { ...jobItem, tag_ids: tagIds };
    params.status = data.status;
    return this.props.JobDucks.putJob(params)
      .then(() => {
        this.setState({ isViewOpen: !isViewOpen, isCardsLoading: true });
        this.props.JobDucks.getJobs({ paginate: false })
          .then(() => this.setState({ isCardsLoading: false }))
          .catch(() => this.setState({ isCardsLoading: false }));
      })
  }

  toggle(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen } = this.state;
    if (!isOpen) {
      this.props.CustomerDucks.getCustomersDetails({...data,id:params.id})
        .then(() => this.setState({ isOpen: !isOpen }));
    }
    else{
      this.setState({ isOpen: !isOpen });
    }
  }

  create(data) {
    const { isOpen } = this.state;
    return this.props.CustomerDucks.putCustomersDetails(data)
      .then(() => {
        this.props.CustomerDucks.getCustomersDetails(data);
        this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
        this.setState({ isOpen: !isOpen });
      });  
  }

  createTag(data) {
    const { change } = this.props;
    this.setState({ isTagLoading: true });
    return this.props.CustomerTagDucks.postCustomerTag({ ...data, tag_type: 0 })
      .then(() => this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 })
        .then(() => {
          change('name', null);
          /*this.toggleTagModal();*/
          toast.success('New tag created successfuly');
          }));
  }

  chunkArray(chunkSize){
    const { jobListIds } = this.props;
    let index = 0;
    const arrayLength = jobListIds.length;
    const tempArray = [];
    const data = [];
    for (index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = jobListIds.slice(index, index+chunkSize);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }
    for (index = 0; index < arrayLength; index += 1) {
      data.push({ id: jobListIds[index].id, called: false }); 
    }    
    this.setState({jobArray: tempArray, jobData: data });
  }

  toggleQuickView(data){
    const {  isViewOpen } = this.state;
    this.setState({ jobItem: data, status: data.status, isViewOpen: !isViewOpen });
    const { location: {  query } } = this.props;
    if(!isViewOpen){
      this.setState({ notesLoading: true });
      this.props.NoteDucks.getComments({ job_id: data.id, start_date: query.start_date, end_date: query.end_date })
        .then(() => this.setState({ notesLoading: false }))
        .catch(() => this.setState({ notesLoading: false }))
    }
    this.setState({ isViewOpen: !isViewOpen });
  }
  
  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }

  goTo(url){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    history.push({ pathname: url,
      search: queryString.stringify({
        parent: `${pathname}?${queryString.stringify(query)}`
      })
    });
  }

  render() {
    const { isLoading , location, customerDetails,
      customerTagList , userCompany, jobdetailMap, commentList, dateFormat, basicSettingDetail, isJobsLoading } = this.props;
    const { isOpen, isTagLoading, jobArray, isNameVisible,jobView, isViewOpen, jobItem, isCardsLoading, isTagModal, notesLoading } = this.state;
    let newTags;
    if (customerDetails.id) {
      newTags = customerDetails.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
    }

    const { query } = location;
    let startDate = {};
    let endDate = {};
    if(Object.keys(basicSettingDetail).length){
      startDate = moment(query.start_date).format(basicSettingDetail.date_format_display);
      endDate = moment(query.end_date).format(basicSettingDetail.date_format_display);
    }

     const tags = customerTagList&& customerTagList.length && customerTagList[0].tag_collection && customerTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className="pb-5 pl-3 pr-4">
        <section>
          <CustomerFormComponent
            data={customerDetails}
            location={location}
            toggle={this.toggle}
          />
        </section>
        <section>
          <CustomerFilterFormComponent
            location={location}
            dateFormat={dateFormat}
            initialValues={query.start_date && query.end_date ?
              { end_date: moment(query.end_date).toISOString(),
                start_date: moment(query.start_date).toISOString()} : null}
          />
        </section>
        { isCardsLoading || isJobsLoading ?
          <SectionLoaderAtom active />
          : <section>
            {jobArray.length ? jobArray.map((item) => (
              <Row>
                <Col md={6} className="job-card-right-padding">
                  <JobListTableComponent
                    location={location}
                    job={item[0]}
                    toggle={this.toggleQuickView}
                    isNameVisible={isNameVisible}
                    goTo={this.goTo}
                    getJobDetails={this.getJobDetails}
                    jobDetail={jobdetailMap}
                    setJobReportsData={this.setJobReportsData}
                  />
                </Col>
                <Col md={6} className="job-card-left-padding">
                  {item.length > 1 ?
                    <JobListTableComponent
                      location={location}
                      job={item[1]}
                      toggle={this.toggleQuickView}
                      isNameVisible={isNameVisible}
                      goTo={this.goTo}
                      getJobDetails={this.getJobDetails}
                      jobDetail={jobdetailMap}
                      setJobReportsData={this.setJobReportsData}
                    /> : null }
                </Col>
              </Row>))
              : <div className="text-center mt-3 pt-2 pb-2">There are no jobs associated with this customer.</div>}
          </section>}
        <section>
          {isOpen &&
            <CustomerFormModalComponent
              isOpen={isOpen}
              toggle={this.toggle}
              create={this.create}
              createTag={this.createTag}
              isTagLoading={isTagLoading}
              customerDetails={customerDetails}
              tagList={customerTagList}
              toggleTagModal={this.toggleTagModal}
              initialValues={{
                ...customerDetails,
                point_of_contact: customerDetails.user.full_name,
                phone_ext: userCompany.phone_ext,
                tags: newTags,
              }}
              edit
            />
          }
          {isTagModal &&
            <TagModalComponent
              isModalOpen={isTagModal}
              toggle={this.toggleTagModal}
              createTag={this.createTag}
              tagsList={tags}
              deleteSelectedTags={this.deleteSelectedTags}
              updateTagName={this.updateTagName}
              tagsCollection={customerTagList}
              getUpdatedList={this.getUpdatedList}
            />
          }
          {isViewOpen &&
            <JobInfoModal
              isOpen={isViewOpen}
              toggle={this.toggleQuickView}
              location={location}
              jobItem={jobItem}
              commentList={commentList}
              status={jobItem.status}
              jobView={jobView}
              startDate={startDate}
              endDate={endDate}
              notesLoading={notesLoading}
              updateStatus={this.updateStatus}
              initialValues={
                jobItem ? { status: jobItem.status } : null
              }
              jobNotes ={commentList}
              jobData={jobItem}
              customerView
            />
          }
        </section>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customerList: CustomerDucks.customerList(state),
  customerDetails: CustomerDucks.customerDetails(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
  location: RouteDucks.location(state),
  jobListIds: JobDucks.jobListIds(state),
  userCompany: UserDucks.userCompany(state),
  jobdetailMap: JobDucks.jobdetailMap(state),
  commentList: NoteDucks.commentList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
});

const mapActionsToProps = dispatch => ({
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
});

CustomerFormSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  customerTagList: PropTypes.array,
  customerDetails:PropTypes.object,
  jobListIds: PropTypes.array.isRequired,
  jobdetailMap: PropTypes.object,
};

CustomerFormSection.contextTypes = {
  router: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerFormSection);