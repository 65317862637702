import React, {PureComponent} from 'react';
import { Form,FormGroup, FormText, Label, Input ,
  Button, Modal, ModalBody , ModalHeader , ModalFooter} from 'reactstrap';
import { Formik } from 'formik';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import EyeOpenIconAtom from 'atoms/EyeOpen';
import EyeCloseIconAtom from 'atoms/EyeClose';
import closeimage from './Close-Cross.png';

import PasswordStrengthMeter from 'atoms/PasswordStrengthMeter/passwordStrengthMeter';

class ConfirmPasswordModal extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggleInputType = this.toggleInputType.bind(this);
    this.strengthCheckToggle = this.strengthCheckToggle.bind(this);
    this.state = { inputType: 'password' , showStrengthCheck : false };
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    try {
      await this.props.usecase.cancelPlan(values.email, values.password, values.company_id);
    } catch (e) {
      console.log(e.errors);
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  strengthCheckToggle(){
    const { showStrengthCheck } = this.state;
    this.setState({showStrengthCheck : !showStrengthCheck})
  }

  toggleInputType() {
    const { inputType } = this.state;
    this.setState({ inputType: inputType === 'password' ? 'text' : 'password' });
  }


  render() {
    const {isOpen, toggle } = this.props;
    const { inputType } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
        backdrop="static"
        >
        <ModalHeader>Confirm Password
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <Formik
            initialValues={this.props.initialValues}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                <ModalBody className="pt-0 mb-2">                
                <FormGroup>  
                  <div className="pos-relative">
                    <Label>Password</Label>
                    <Input
                      type={inputType}
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      invalid={errors && touched.password && errors && errors.password}
                      className={errors && errors.password ? "form-control-error" : null}
                      data-lpignore={true}
                      onBlur={(e) => { this.strengthCheckToggle(); handleBlur(e)}}
                      onFocus = {this.strengthCheckToggle}
                    />
                      {inputType === 'password'
                        ? (<EyeOpenIconAtom
                          className="password-icon34"
                          height="22" width="22"
                          onClick={this.toggleInputType}
                        />)
                        : (<EyeCloseIconAtom
                          className="password-icon34"
                          height="22" width="22"
                          onClick={this.toggleInputType}
                      />)
                    }
                  </div>
                  {this.state.showStrengthCheck &&
                    <PasswordStrengthMeter
                      values={values}
                    />
                  }
                  {errors && errors.non_field_errors && 
                   <div style={{minHeight: '3vh'}}>
                     <FormText color="danger">
                        {errors && errors.non_field_errors}
                      </FormText>                 
                  </div> 
                  }
                  {errors && errors.details && 
                  <div style={{minHeight: '3vh'}}>
                     <FormText color="danger">
                        {errors && errors.details}
                      </FormText>
                  </div> 
                }
                </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" color="secondary">
                    Submit <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                </ModalFooter>
                </Form>
                 )}
          />
      </Modal>
    );}
  }
  
export default ConfirmPasswordModal;