export const NAV = {
  ProfileEditFormPage: {
    title: 'My Profile',
    parent: '/dashboard',
  },
  ProfileChangePasswordFormPage: {
    title: 'Change My Password',
    parent: '/dashboard',
  },
  LongCodeTestFormPage: {
  	title: 'SMS Testing',
  	parent: '/dashboard-admin',
  },
};

export const DEVICE = {
  website:"WEB",
}

export default { NAV, DEVICE };
