import React from 'react';

import reduxForm from 'redux-form/lib/reduxForm';
import PropTypes from 'prop-types';
// import QRCode from 'qrcode-react';

import Slider from "react-slick";
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import closeimage from './CloseCross.png';

import styles from './styles.module.scss';
import image1 from './image1.png';
import image2 from './image2.png';
import image3 from './image3.png';
import image4 from './image4.png';



class DashboardOnboardFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { isOpen: false };
   
  }

  submit(data) {
    const { sendSms, change } = this.props;
    return sendSms(data)
      .then(() => {
        change("phone_no",null);
      }); 
  }

  


  render() {
    const { isOpen, toggle , update } = this.props;
     const settings = {
      dots: false,
      infinite: false,
      speed: .1,
      slidesToShow: 1,
      slidesToScroll: 1,
      //  slidesPerShow: 5,
      //  centerMode: true,
      variableWidth: false,
    };
    const arr = [
      {
        url: image1,
      },
      {
        url: image2,
      },
      {
        url: image3,
      },
      {
        url: image4,

      },
    ]
    
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="form-modal modal-onboard"
          backdrop='static'
        >
         <ModalHeader><p className={styles["modal-main"]}>Using Worksana Mobile App
         <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
         </p></ModalHeader>
         <ModalBody>
         <div>
        <Slider {...settings} >
          {
            arr.map((item,index)=> {
                return(
                  <div key={index} className="text-center">
                    <img src={item.url} alt='' className={styles['mobile-img']} />
                  </div>);})}

        </Slider>   
        </div> 

        {/*  <ModalHeader toggle={toggle}><p className={styles["modal-main"]}>Get the official myEtimecard mobile app</p></ModalHeader>
          <Form noValidate onSubmit={handleSubmit(this.submit)} className="mt-0">
            <ModalBody>
              <Row>
                <Col>
                  <p className={`${styles["modal-foot"]} mb-4`}>Choose anyone of the following options to get the right myEtimecard app for your device</p>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={5} className={`${styles["border-modal"]}`}>  
                  <div>
                    <p className={`pr-2 ${styles["modal-foot"]}`}>Enter Your Mobile Number</p>
                    <Row className="pt-3">
                      <Col md={3} className="pr-0 onboard-override">
                        <Field
                          id="phone_ext"
                          name="phone_ext"
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      </Col>
                      <Col md={9} className="pl-1">  
                        <Field
                          id="phone_no"
                          name="phone_no"
                          placeholder="Phone Number"
                          component={InputAtom}
                          layout={FORM_LAYOUT}
                        />
                      </Col>
                    </Row>    
                  </div>
                  <Button 
                    color="secondary" 
                    className={`${styles['modal-btn']}`} 
                    type="submit"
                  >
                  Send Link by SMS <ButtonLoaderAtom active={submitting}/>
                  </Button>  
                </Col> 
                <Col md={3} className={styles["border-modal"]}>
                  <p className={styles["modal-foot"]}>Scan this QR code</p>
                  <div className="text-center">
                    <QrcodeIcon className="qrcode-css" />
                  </div>
                </Col>
                <Col md={4}>
                  <p className={styles["modal-foot"]}>Visit on your Mobile device</p>
                  <div className="pt-3 text-center">
                    <a href="http://onelink.to/68fj7g"  target="_blank" className="pt-2" rel="noopener noreferrer"><i>Click here for the app</i></a>
                  </div>
                </Col> 
              </Row>  
            </ModalBody>*/}
            <Button className="btn-other float-right" onClick={()=>update(null,2)}>Finished</Button>
          </ModalBody>
        </Modal>
      </div>  
    );
  }
};

const validate = (values) => {
  const errors = {};
  // if (!values.phone_no) errors.phone_no='Enter phone number';
  if (values.phone_no && !values.phone_no.toString().match('^[-+]?[0-9]*\\.?[0-9]+$')) errors.phone_no = 'Enter a vaild number'
  return errors;
};

DashboardOnboardFormModalComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'DashboardOnboardFormModal',
  validate,
  enableReinitialize: true,
})(DashboardOnboardFormModalComponent);
