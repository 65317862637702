import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import { groupWith } from 'ramda';
import URLS from './urls';

const GET_JOBS = 'jobs/job/GET_JOBS';
const GET_JOBS_FULFILLED = 'jobs/job/GET_JOBS_FULFILLED';
const GET_JOBS_USER_PREFERENCE = 'jobs/job/GET_JOBS_USER_PREFERENCE';
const GET_JOBS_USER_PREFERENCE_FULFILLED = 'jobs/job/GET_JOBS_USER_PREFERENCE_FULFILLED';
const GET_ADMIN_JOBS_USER_PREFERENCE = 'jobs/job/GET_ADMIN_JOBS_USER_PREFERENCE';
const GET_ADMIN_JOBS_USER_PREFERENCE_FULFILLED = 'jobs/job/GET_ADMIN_JOBS_USER_PREFERENCE_FULFILLED';
const GET_QUOTED_RECURRING_JOBS = 'jobs/jobs/GET_QUOTED_RECURRING_JOBS';
const GET_QUOTED_RECURRING_JOBS_FULFILLED = 'jobs/jobs/GET_QUOTED_RECURRING_JOBS_FULFILLED';
const GET_QUOTED_PROJECT_JOBS = 'jobs/jobs/GET_QUOTED_PROJECT_JOBS';
const GET_QUOTED_PROJECT_JOBS_FULFILLED = 'jobs/jobs/GET_QUOTED_PROJECT_JOBS_FULFILLED';
const GET_TIME_AND_MATERIAL_JOBS = 'jobs/jobs/GET_TIME_AND_MATERIAL_JOBS';
const GET_TIME_AND_MATERIAL_JOBS_FULFILLED = 'jobs/jobs/GET_TIME_AND_MATERIAL_JOBS_FULFILLED';
const GET_NEW_RECURRING_JOBS_LIST = 'jobs/jobs/GET_NEW_RECURRING_JOBS_LIST';
const GET_NEW_RECURRING_JOBS_LIST_FULFILLED = 'jobs/jobs/GET_NEW_RECURRING_JOBS_LIST_FULFILLED';
const GET_NEW_PROJECT_JOBS_LIST = 'jobs/jobs/GET_NEW_PROJECT_JOBS_LIST';
const GET_NEW_PROJECT_JOBS_LIST_FULFILLED = 'jobs/jobs/GET_NEW_PROJECT_JOBS_LIST_FULFILLED';
const GET_NEW_TIME_AND_MATERIAL_JOBS_LIST = 'jobs/jobs/GET_NEW_TIME_AND_MATERIAL_JOBS_LIST';
const GET_NEW_TIME_AND_MATERIAL_JOBS_LIST_FULFILLED = 'jobs/jobs/GET_NEW_TIME_AND_MATERIAL_JOBS_LIST_FULFILLED';

const GET_JOBS_IDS = 'jobs/job/GET_JOBS_IDS';
const GET_JOBS_IDS_FULFILLED = 'jobs/job/GET_JOBS_IDS_FULFILLED';
const GET_JOBS_OF_EMPLOYEE = 'jobs/job/GET_JOBS_OF_EMPLOYEE';
const GET_JOBS_OF_EMPLOYEE_FULFILLED = 'jobs/job/GET_JOBS_OF_EMPLOYEE_FULFILLED';
const GET_ALL_JOBS = 'jobs/job/GET_ALL_JOBS';
const GET_ALL_JOBS_FULFILLED = 'jobs/job/GET_ALL_JOBS_FULFILLED';
const GET_JOB_STATS = 'jobs/job/GET_JOB_STATS';
const GET_JOB_STATS_FULFILLED = 'jobs/job/GET_JOB_STATS_FULFILLED';
const GET_JOB = 'jobs/job/GET_JOB';
const GET_JOB_FULFILLED = 'jobs/job/GET_JOB_FULFILLED';
const RESET_JOB_ALL = 'jobs/job/RESET_JOB_ALL';
const RESET_JOB = 'jobs/job/RESET_JOB';
const POST_JOB = 'jobs/job/POST_JOB';
const POST_JOB_FULFILLED = 'jobs/job/POST_JOB_FULFILLED';
const PUT_JOB = 'jobs/job/PUT_JOB';
const PUT_JOB_FULFILLED = 'jobs/job/PUT_JOB_FULFILLED';
const SELECT_JOB_STATUS = 'jobs/job/SELECT_JOB_STATUS';
const SELECT_JOB_STATUS_FULFILLED = 'jobs/job/SELECT_JOB_STATUS_FULFILLED';
const SET_JOB = 'jobs/job/SET_JOB';
const GET_JOB_GEOFENCE_MAPPING = 'jobs/job/GET_JOB_GEOFENCE_MAPPING';
const GET_JOB_GEOFENCE_MAPPING_FULFILLED = 'jobs/job/GET_JOB_GEOFENCE_MAPPING_FULFILLED';
const PUT_JOB_GEOFENCE_MAPPING = 'jobs/job/PUT_JOB_GEOFENCE_MAPPING';
const PUT_JOB_GEOFENCE_MAPPING_FULFILLED = 'jobs/job/PUT_JOB_GEOFENCE_MAPPING_FULFILLED';
const GET_JOB_MAP = 'jobs/job/GET_JOB_MAP';
const GET_JOB_MAP_FULFILLED = 'jobs/job/GET_JOB_MAP_FULFILLED';
const SET_JOB_CALLED_STATUS = 'jobs/job/SET_JOB_CALLED_STATUS';
const SET_JOB_REPORTS_INITIALVALUES = 'jobs/reports/SET_JOB_REPORTS_INITIALVALUES';
const JOB_EXPORT_CSV = 'jobs/job/JOB_EXPORT_CSV';
const GET_TABLE_COUNTS = 'jobs/job/GET_TABLE_COUNTS';
const GET_TABLE_COUNTS_FULFILLED = 'jobs/job/GET_TABLE_COUNTS_FULFILLED';
const GET_JOB_NOTES = 'jobs/job/GET_JOB_NOTES';
const GET_JOB_NOTES_FULFILLED = 'jobs/job/GET_JOB_NOTES_FULFILLED';
const GET_JOBS_AND_JOB_CODES = 'jobs/job/GET_JOBS_AND_JOB_CODES';
const GET_JOBS_AND_JOB_CODES_FULFILLED = 'jobs/job/GET_JOBS_AND_JOB_CODES_FULFILLED';
const GET_USER_VIEW_QUOTED_RECURRING = 'jobs/job/GET_USER_VIEW_QUOTED_RECURRING';
const GET_USER_VIEW_QUOTED_PROJECT = 'jobs/job/GET_USER_VIEW_QUOTED_PROJECT';
const GET_USER_VIEW_TIME_AND_MATERIAL = 'jobs/job/GET_USER_VIEW_TIME_AND_MATERIAL';
const GET_USER_VIEW_QUOTED_RECURRING_FULFILLED = 'jobs/job/GET_USER_VIEW_QUOTED_RECURRING_FULFILLED';
const GET_USER_VIEW_QUOTED_PROJECT_FULFILLED = 'jobs/job/GET_USER_VIEW_QUOTED_PROJECT_FULFILLED';
const GET_USER_VIEW_TIME_AND_MATERIAL_FULFILLED = 'jobs/job/GET_USER_VIEW_TIME_AND_MATERIAL_FULFILLED';

const GET_NEW_USER_VIEW_QUOTED_RECURRING = 'jobs/job/GET_NEW_USER_VIEW_QUOTED_RECURRING';
const GET_NEW_USER_VIEW_QUOTED_PROJECT = 'jobs/job/GET_NEW_USER_VIEW_QUOTED_PROJECT';
const GET_NEW_USER_VIEW_TIME_AND_MATERIAL = 'jobs/job/GET_NEW_USER_VIEW_TIME_AND_MATERIAL';
const GET_NEW_USER_VIEW_QUOTED_RECURRING_FULFILLED = 'jobs/job/GET_NEW_USER_VIEW_QUOTED_RECURRING_FULFILLED';
const GET_NEW_USER_VIEW_QUOTED_PROJECT_FULFILLED = 'jobs/job/GET_NEW_USER_VIEW_QUOTED_PROJECT_FULFILLED';
const GET_NEW_USER_VIEW_TIME_AND_MATERIAL_FULFILLED = 'jobs/job/GET_NEW_USER_VIEW_TIME_AND_MATERIAL_FULFILLED';

const SET_USER_VIEW = 'jobs/job/SET_USER_VIEW';
const DELETE_TAGS = 'jobs/job/DELETE_TAGS';
const UPDATE_TAG_NAME ='jobs/job/UPDATE_TAG_NAME'
const POST_BULK_JOB = 'jobs/job/POST_BULK_JOB';
const POST_BULK_JOB_FULFILLED = 'jobs/job/POST_BULK_JOB_FULFILLED';
const GET_BULK_JOB = 'jobs/job/GET_BULK_JOB';
const GET_BULK_JOB_FULFILLED = 'jobs/job/GET_BULK_JOB_FULFILLED';
const UPDATE_JOB_USER_PREFERENCE = 'jobs/job/UPDATE_JOB_USER_PREFERENCE';
const UPDATE_NEW_JOB_USER_PREFERENCE = 'jobs/job/UPDATE_NEW_JOB_USER_PREFERENCE';

const GET_JOB_SUMMARY = 'jobs/job/GET_JOB_SUMMARY';
const GET_JOB_SUMMARY_FULFILLED = 'jobs/job/GET_JOB_SUMMARY_FULFILLED';
const GET_ASSIGNED_TAGS = 'jobs/job/GET_ASSIGNED_TAGS';
const GET_ASSIGNED_TAGS_FULFILLED = 'jobs/job/GET_ASSIGNED_TAGS_FULFILLED';

const GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES = 'jobs/job/GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES';
const GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES_FULFILLED = 'jobs/job/GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES_FULFILLED';
const GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES = 'jobs/job/GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES';
const GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES_FULFILLED = 'jobs/job/GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES_FULFILLED';
const GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES = 'jobs/job/GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES';
const GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES_FULFILLED = 'jobs/job/GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES_FULFILLED';

const GET_QUOTED_RECURRING_SUMMARY_DATA = 'jobs/job/GET_QUOTED_RECURRING_SUMMARY_DATA';
const GET_QUOTED_PROJECT_SUMMARY_DATA = 'jobs/job/GET_QUOTED_PROJECT_SUMMARY_DATA';
const GET_TIME_AND_MATERIAL_SUMMARY_DATA = 'jobs/job/GET_TIME_AND_MATERIAL_SUMMARY_DATA';
const GET_QUOTED_RECURRING_SUMMARY_DATA_FULFILLED = 'jobs/job/GET_QUOTED_RECURRING_SUMMARY_DATA_FULFILLED';
const GET_QUOTED_PROJECT_SUMMARY_DATA_FULFILLED = 'jobs/job/GET_QUOTED_PROJECT_SUMMARY_DATA_FULFILLED';
const GET_TIME_AND_MATERIAL_SUMMARY_DATA_FULFILLED = 'jobs/job/GET_TIME_AND_MATERIAL_SUMMARY_DATA_FULFILLED';

export function getJobs(params) {
  return {
    type: GET_JOBS,
    payload: axios.get(URLS.JOB, { params }),
  };
}

export function getAssignedTags(params){
  return {
    type: GET_ASSIGNED_TAGS,
    payload: axios.get(`${URLS.JOB}${params.job_id}/`, { params }),
  };
}

export function getQuotedRecurringSummaryData(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_QUOTED_RECURRING_SUMMARY_DATA,
    payload: axios.get(URLS.JOB_SUMMARY_DATA, {params: paramsUpdated}),
  };
}

export function getQuotedProjectSummaryData(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_QUOTED_PROJECT_SUMMARY_DATA,
    payload: axios.get(URLS.JOB_SUMMARY_DATA, {params: paramsUpdated}),
  };
}

export function getTimeAndMaterialSummaryData(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_TIME_AND_MATERIAL_SUMMARY_DATA,
    payload: axios.get(URLS.JOB_SUMMARY_DATA, {params: paramsUpdated}),
  };
}

export function getAdminJobsUserPreference(params) {
  return {
    type: GET_ADMIN_JOBS_USER_PREFERENCE,
    payload: axios.get(URLS.JOB_USER_PREFERENCE, { params }),
  };
}

export function getJobsUserPreference(params) {
  return {
    type: GET_JOBS_USER_PREFERENCE,
    payload: axios.get(URLS.JOB_USER_PREFERENCE, { params }),
  };
}

export function getQuotedRecurringJobs(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_QUOTED_RECURRING_JOBS,
    payload: axios.get(URLS.JOB_LIST, {params: paramsUpdated}),
  };
}

export function getNewRecurringJobsList(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_NEW_RECURRING_JOBS_LIST,
    payload: axios.get(URLS.JOB_LIST_ADMIN, {params: paramsUpdated}),
  };
}

export function getQuotedProjectJobs(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_QUOTED_PROJECT_JOBS,
    payload: axios.get(URLS.JOB_LIST, {params: paramsUpdated }),
  };
}

export function getNewProjectJobsList(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_NEW_PROJECT_JOBS_LIST,
    payload: axios.get(URLS.JOB_LIST_ADMIN, {params: paramsUpdated }),
  };
}

export function deleteSelectedTags(id,data) {
  return {
    type: DELETE_TAGS,
    payload: axios.delete(`${URLS.JOB_TAGS}${id}/`, { data }),
  };
}

export function updateTagName(id,data) {
  return {
    type: UPDATE_TAG_NAME,
    payload: axios.put(`${URLS.JOB_TAGS}${id}/`,  data.tag_collection),
  };
}

export function getTimeAndMaterialJobs(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_TIME_AND_MATERIAL_JOBS,
    payload: axios.get(URLS.JOB_LIST, {params: paramsUpdated }),
  };
}

export function getNewTimeAndMaterialJobsList(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_NEW_TIME_AND_MATERIAL_JOBS_LIST,
    payload: axios.get(URLS.JOB_LIST_ADMIN, {params: paramsUpdated }),
  };
}

export function getJobsIds(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_JOBS_IDS,
    payload: axios.get(URLS.JOB_IDS, { params: paramsUpdated }),
  };
}

export function getQuotedRecurringJobsAndJobCodes(params) {
  return {
    type: GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES,
    payload: axios.get(URLS.JOBS_OF_EMPLOYEE, { params }),
  };
}

export function getQuotedProjectJobsAndJobCodes(params) {
  return {
    type: GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES,
    payload: axios.get(URLS.JOBS_OF_EMPLOYEE, { params }),
  };
}

export function getTimeAndMaterialJobsAndJobCodes(params) {
  return {
    type: GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES,
    payload: axios.get(URLS.JOBS_OF_EMPLOYEE, { params }),
  };
}

export function setJobCalledStatus(data) {
  return {
    type: SET_JOB_CALLED_STATUS,
    payload: data,
  };
}

export function updateUserJobPreference(data, params){
  return {
    type: UPDATE_JOB_USER_PREFERENCE,
    payload: axios.put(`${URLS.JOB_USER_PREFERENCE}?preference_type=${params.preference_type}`, { data }),
  };
}

export function updateNewUserJobPreference(data){
  return {
    type: UPDATE_NEW_JOB_USER_PREFERENCE,
    payload: axios.put(`${URLS.JOB_USER_PREFERENCE}?preference_type=${data.preference_type}`, { data }),
  };
}


export function getJobsOfEmployee(params) {
  return {
    type: GET_JOBS_OF_EMPLOYEE,
    payload: axios.get(URLS.JOBS_OF_EMPLOYEE, { params }),
  };
}

export function getAllJobs(params) {
  return {
    type: GET_ALL_JOBS,
    payload: axios.get(URLS.JOB, { params }),
  };
}

export function getJobsAndJobCodes(params) {
  return {
    type: GET_JOBS_AND_JOB_CODES,
    payload: axios.get(URLS.JOBS_OF_EMPLOYEE, { params }),
  };
}

export function getJob(params) {
  return {
    type: GET_JOB,
    payload: axios.get(`${URLS.JOB}${params.id}/`),
  };
}

export function getJobDetailsMap(parameters) {
  const { id, ...params } = parameters;
  return {
    type: GET_JOB_MAP,
    payload: axios.get(`${URLS.JOB}${id}/`, { params }),
  };
}

export function setJob(data) {
  return {
    type: SET_JOB,
    payload: data,
  };
}

export function resetJob(data) {
  return {
    type: RESET_JOB,
    payload: data,
  };
}

export function resetJobStatus(data) {
  return {
    type: SELECT_JOB_STATUS,
    payload: data,
  };
}

export function getJobStats(params) {
  return {
    type: GET_JOB_STATS,
    payload: axios.get(`${URLS.JOB}${params.id}/${URLS.STATS}`),
  };
}

export function postJob(data) {
  return {
    type: POST_JOB,
    payload: axios.post(URLS.JOB, data),
  };
}

export function putJob(data) {
  return {
    type: PUT_JOB,
    payload: axios.put(`${URLS.JOB}${data.id}/`, data),
  };
}

export function getJobGeoFenceMapping(params) {
  return {
    type: GET_JOB_GEOFENCE_MAPPING,
    payload: axios.get(`${URLS.JOB}${params.id}/${URLS.GEOFENCE}`),
  };
}

export function putJobGeoFenceMapping(data) {
  return {
    type: PUT_JOB_GEOFENCE_MAPPING,
    payload: axios.put(`${URLS.JOB}${data.id}/${URLS.GEOFENCE}`, data),
  };
}

export function setJobReportsInitialValues(data) {
  return {
    type: SET_JOB_REPORTS_INITIALVALUES,
    payload: data,
  };
}

export function resetJobAll(data) {
  return {
    type: RESET_JOB_ALL,
    payload: data,
  };
}

export function getJobNotes(data) {
  return {
    type: GET_JOB_NOTES,
    payload: axios.get(`${URLS.JOB}${data.id}/document/`)
  };
}

export function exportCSV(params) {
  return {
    type: JOB_EXPORT_CSV,
    payload: axios.get(URLS.EXPORT_CSV, { params, responseType: "arraybuffer" }),
  }
}

export function getTableCounts(params) {
  let paramsUpdated = params;
  const { status, ...rest } = params;
  if (paramsUpdated.status === 0) {
    paramsUpdated = {...rest};
  }
  return {
    type: GET_TABLE_COUNTS,
    payload: axios.get(URLS.TABLE_COUNT, {params: paramsUpdated }),
  };
}

export function getUserPreference(params) {
  if(params.bill_type === 'QUOTED') {
    if(params.job_type === 'RECURRING') {
      return {
        type: GET_USER_VIEW_QUOTED_RECURRING,
        payload: axios.get(`${URLS.JOB}${URLS.USER_PREFERENCE}`, {params: params })
      }
    } else {
      return {
        type: GET_USER_VIEW_QUOTED_PROJECT,
        payload: axios.get(`${URLS.JOB}${URLS.USER_PREFERENCE}`, {params: params })
      }
    }
  } else {
    return {
        type: GET_USER_VIEW_TIME_AND_MATERIAL,
        payload: axios.get(`${URLS.JOB}${URLS.USER_PREFERENCE}`, {params: params })
      }
  }
}

export function getNewUserPreference(params) {
  if(params.preference_type  === 'user_preference_manage_recurring') {
    return {
        type: GET_NEW_USER_VIEW_QUOTED_RECURRING,
        payload: axios.get(URLS.JOB_USER_PREFERENCE, {params: params })
    }
  }
  else if(params.preference_type  === 'user_preference_manage_project'){
    return {
      type: GET_NEW_USER_VIEW_QUOTED_PROJECT,
      payload: axios.get(URLS.JOB_USER_PREFERENCE, {params: params })
    }
  }
  else {
    return {
        type: GET_NEW_USER_VIEW_TIME_AND_MATERIAL,
        payload: axios.get(URLS.JOB_USER_PREFERENCE, {params: params })
      }
  }
}

export function setUserPreference(params) {
  return {
    type: SET_USER_VIEW,
    payload: axios.put(`${URLS.JOB}${URLS.USER_PREFERENCE}`, params)
  }
}

export function postBulkJob(data, params) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: POST_BULK_JOB,
    payload: axios.post(`${URLS.JOB_BULK_UPLOAD}?bill_type=${params.bill_type}&job_type=${params.job_type}`, data, config),
  };
}

export function getBulkJob(data) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return {
    type: GET_BULK_JOB,
    payload: axios.get(`${URLS.JOB_BULK_UPLOAD}?bill_type=${data.bill_type}&job_type=${data.job_type}` ,  config),
  };
}

export function getJobSummary(parameters) {
  const { job_id, ...params } = parameters;
  return {
    type: GET_JOB_SUMMARY,
    payload: axios.get(`${URLS.JOB}${URLS.JOB_SUMMARY}${job_id}/`, {params}),
  };
}

const defaultState = {
  list: {results: []},
  listIds : {},
  detail: {},
  geofenceDetail: {},
  stats: {},
  jobGeoFenceMapping: {},
  all: [],
  data:{},
  detailMap: [],
  initialValues: {},
  counts: {},
  quotedRecurringJobs: { results: [] },
  quotedProjectJobs: { results: [] },
  timeAndMaterialJobs: { results: [] },
  jobNotesDoc: {},
  jobAndCodeList: {results: []},
  userViewPreferenceQuotedRecurring: [],
  userViewPreferenceQuotedProject: [],
  userViewPreferenceTimeAndMaterial: [],
  jobsUserPreference : {},
  adminJobsUserPreference : {},
  jobSummary: {},
  quotedRecurringjobAndCodeList: {results: []},
  quotedProjectjobAndCodeList: {results: []},
  timeAndMaterialjobAndCodeList: {results: []},
  assignedTagsList : { results: [] },
  quotedRecurringSummary : {},
  quotedProjectSummary: {},
  timeAndMaterialSummary : {},
  newQuotedRecurringJobs : { results: [] },
  newQuotedProjectJobs: { results: [] },
  newTimeAndMaterialJobs: { results: [] },
  newUserViewPreferenceQuotedRecurring: [],
  newUserViewPreferenceQuotedProject: [],
  newUserViewPreferenceTimeAndMaterial: [],
};

function GET_JOB_NOTES_FULFILLED_REDUCER (state, action) {
  return Object.assign({}, state, {
    list: action.payload.results
  });
}

function GET_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ASSIGNED_TAGS_FULFILLED_REDUCER(state, action) {
  const tagsList = action.payload.results.map(item => ({ ...item, isSelected: false }));
  return Object.assign({}, state, {
    assignedTagsList: { ...action.payload, results: tagsList },
  });
}

function GET_JOBS_USER_PREFERENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    jobsUserPreference: action.payload,
  });
}

function GET_ADMIN_JOBS_USER_PREFERENCE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    adminJobsUserPreference: action.payload,
  });
}



function GET_QUOTED_RECURRING_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedRecurringJobs: action.payload,
  });
}

function GET_NEW_RECURRING_JOBS_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newQuotedRecurringJobs: action.payload,
  });
}

function GET_QUOTED_RECURRING_SUMMARY_DATA_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedRecurringSummary: action.payload,
  });
}

function GET_QUOTED_PROJECT_SUMMARY_DATA_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedProjectSummary: action.payload,
  });
}

function GET_TIME_AND_MATERIAL_SUMMARY_DATA_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    timeAndMaterialSummary: action.payload,
  });
}

function GET_QUOTED_PROJECT_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedProjectJobs: action.payload,
  });
}

function GET_NEW_PROJECT_JOBS_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newQuotedProjectJobs: action.payload,
  });
}

function GET_TIME_AND_MATERIAL_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    timeAndMaterialJobs: action.payload,
  });
}

function GET_NEW_TIME_AND_MATERIAL_JOBS_LIST_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newTimeAndMaterialJobs: action.payload,
  });
}

function GET_JOBS_IDS_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.map((item) => ({ ...item, called: false }));
  return Object.assign({}, state, {
    listIds: arr,
  });
}

function SET_JOB_CALLED_STATUS_REDUCER(state, action) {
  const arr = state.listIds.map((item) => {
    const newItem = { ...item };
    if(action.payload.id === item.id){
      newItem.called = true;
    }
    return newItem;
  });
  return Object.assign({}, state, {
    listIds: arr,
  });
}

function GET_JOBS_OF_EMPLOYEE_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_ALL_JOBS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_JOB_STATS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    stats: action.payload,
  });
}

function GET_JOB_GEOFENCE_MAPPING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    jobGeoFenceMapping: action.payload,
  });
}

function SELECT_JOB_STATUS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function SET_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    data: action.payload,
  });
}

function RESET_JOB_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function POST_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_JOB_GEOFENCE_MAPPING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    geofenceDetail: action.payload,
  });
}

function GET_JOB_MAP_FULFILLED_REDUCER(state, action) {
  const arr = [];
  if(Object.keys(state.detailMap).length !== 0) {
    Object.keys(state.detailMap).map((item) => {
      arr[item] = state.detailMap[item];
      return null;   
    });
  }
  arr[action.payload.id] = action.payload;
  return Object.assign({}, state, {
    detailMap: arr,
  });
}

function SET_JOB_REPORTS_INITIALVALUES_REDUCER(state, action) {
  return Object.assign({}, state, {
    initialValues: action.payload,
  });
}

function RESET_JOB_ALL_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: [],
  });
}

function GET_TABLE_COUNTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    counts: action.payload,
  });
}

function GET_JOBS_AND_JOB_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    jobAndCodeList: action.payload,
  });
}

function GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedRecurringjobAndCodeList: action.payload,
  });
}

function GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    quotedProjectjobAndCodeList: action.payload,
  });
}

function GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    timeAndMaterialjobAndCodeList: action.payload,
  });
}

function GET_USER_VIEW_QUOTED_RECURRING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreferenceQuotedRecurring: action.payload,
  });
}

function GET_USER_VIEW_QUOTED_PROJECT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreferenceQuotedProject: action.payload,
  });
}

function GET_USER_VIEW_TIME_AND_MATERIAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    userViewPreferenceTimeAndMaterial: action.payload,
  });
}

function GET_NEW_USER_VIEW_QUOTED_RECURRING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newUserViewPreferenceQuotedRecurring: action.payload.data ? action.payload.data : {},
  });
}

function GET_NEW_USER_VIEW_QUOTED_PROJECT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newUserViewPreferenceQuotedProject: action.payload.data ? action.payload.data : {},
  });
}

function GET_NEW_USER_VIEW_TIME_AND_MATERIAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    newUserViewPreferenceTimeAndMaterial: action.payload.data ? action.payload.data : {},
  });
}

function POST_BULK_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_BULK_JOB_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_JOB_SUMMARY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    jobSummary: action.payload,
  });
}

const handlers = {
  [GET_JOBS_FULFILLED]: GET_JOBS_FULFILLED_REDUCER,
  [GET_QUOTED_RECURRING_JOBS_FULFILLED] : GET_QUOTED_RECURRING_JOBS_FULFILLED_REDUCER,
  [GET_QUOTED_PROJECT_JOBS_FULFILLED] : GET_QUOTED_PROJECT_JOBS_FULFILLED_REDUCER,
  [GET_TIME_AND_MATERIAL_JOBS_FULFILLED] : GET_TIME_AND_MATERIAL_JOBS_FULFILLED_REDUCER,
  [GET_JOBS_OF_EMPLOYEE_FULFILLED]: GET_JOBS_OF_EMPLOYEE_FULFILLED_REDUCER,
  [GET_ALL_JOBS_FULFILLED]: GET_ALL_JOBS_FULFILLED_REDUCER,
  [GET_JOB_FULFILLED]: GET_JOB_FULFILLED_REDUCER,
  [SELECT_JOB_STATUS_FULFILLED]: SELECT_JOB_STATUS_FULFILLED_REDUCER,
  [GET_JOB_STATS_FULFILLED]: GET_JOB_STATS_FULFILLED_REDUCER,
  [RESET_JOB]: RESET_JOB_REDUCER,
  [SET_JOB]: SET_JOB_FULFILLED_REDUCER,
  [GET_JOB_GEOFENCE_MAPPING_FULFILLED]: GET_JOB_GEOFENCE_MAPPING_FULFILLED_REDUCER,
  [POST_JOB_FULFILLED]: POST_JOB_FULFILLED_REDUCER,
  [PUT_JOB_FULFILLED]: PUT_JOB_FULFILLED_REDUCER,
  [PUT_JOB_GEOFENCE_MAPPING_FULFILLED]: PUT_JOB_GEOFENCE_MAPPING_FULFILLED_REDUCER,
  [GET_JOB_MAP_FULFILLED]: GET_JOB_MAP_FULFILLED_REDUCER,
  [GET_JOBS_IDS_FULFILLED]: GET_JOBS_IDS_FULFILLED_REDUCER,
  [SET_JOB_CALLED_STATUS]: SET_JOB_CALLED_STATUS_REDUCER,
  [SET_JOB_REPORTS_INITIALVALUES]: SET_JOB_REPORTS_INITIALVALUES_REDUCER,
  [RESET_JOB_ALL]: RESET_JOB_ALL_REDUCER,
  [GET_TABLE_COUNTS_FULFILLED]: GET_TABLE_COUNTS_FULFILLED_REDUCER,
  [GET_JOB_NOTES_FULFILLED]: GET_JOB_NOTES_FULFILLED_REDUCER,
  [GET_JOBS_AND_JOB_CODES_FULFILLED]: GET_JOBS_AND_JOB_CODES_FULFILLED_REDUCER,
  [GET_USER_VIEW_QUOTED_RECURRING_FULFILLED]: GET_USER_VIEW_QUOTED_RECURRING_FULFILLED_REDUCER,
  [GET_USER_VIEW_QUOTED_PROJECT_FULFILLED]: GET_USER_VIEW_QUOTED_PROJECT_FULFILLED_REDUCER,
  [GET_USER_VIEW_TIME_AND_MATERIAL_FULFILLED]: GET_USER_VIEW_TIME_AND_MATERIAL_FULFILLED_REDUCER,
  [GET_NEW_USER_VIEW_QUOTED_RECURRING_FULFILLED]: GET_NEW_USER_VIEW_QUOTED_RECURRING_FULFILLED_REDUCER,
  [GET_NEW_USER_VIEW_QUOTED_PROJECT_FULFILLED]: GET_NEW_USER_VIEW_QUOTED_PROJECT_FULFILLED_REDUCER,
  [GET_NEW_USER_VIEW_TIME_AND_MATERIAL_FULFILLED]: GET_NEW_USER_VIEW_TIME_AND_MATERIAL_FULFILLED_REDUCER,

  [POST_BULK_JOB_FULFILLED]: POST_BULK_JOB_FULFILLED_REDUCER,
  [GET_BULK_JOB_FULFILLED]: GET_BULK_JOB_FULFILLED_REDUCER,
  [GET_JOBS_USER_PREFERENCE_FULFILLED] : GET_JOBS_USER_PREFERENCE_FULFILLED_REDUCER,
  [GET_ADMIN_JOBS_USER_PREFERENCE_FULFILLED] : GET_ADMIN_JOBS_USER_PREFERENCE_FULFILLED_REDUCER,
  [GET_JOB_SUMMARY_FULFILLED] : GET_JOB_SUMMARY_FULFILLED_REDUCER,
  [GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES_FULFILLED] : GET_QUOTED_RECURRING_JOBS_AND_JOB_CODES_FULFILLED_REDUCER,
  [GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES_FULFILLED] : GET_QUOTED_PROJECT_JOBS_AND_JOB_CODES_FULFILLED_REDUCER,
  [GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES_FULFILLED] : GET_TIME_AND_MATERIAL_JOBS_AND_JOB_CODES_FULFILLED_REDUCER,
  [GET_ASSIGNED_TAGS_FULFILLED] : GET_ASSIGNED_TAGS_FULFILLED_REDUCER,
  [GET_QUOTED_RECURRING_SUMMARY_DATA_FULFILLED] : GET_QUOTED_RECURRING_SUMMARY_DATA_FULFILLED_REDUCER,
  [GET_QUOTED_PROJECT_SUMMARY_DATA_FULFILLED] : GET_QUOTED_PROJECT_SUMMARY_DATA_FULFILLED_REDUCER,
  [GET_TIME_AND_MATERIAL_SUMMARY_DATA_FULFILLED] : GET_TIME_AND_MATERIAL_SUMMARY_DATA_FULFILLED_REDUCER,
  [GET_NEW_RECURRING_JOBS_LIST_FULFILLED] : GET_NEW_RECURRING_JOBS_LIST_FULFILLED_REDUCER,
  [GET_NEW_PROJECT_JOBS_LIST_FULFILLED] : GET_NEW_PROJECT_JOBS_LIST_FULFILLED_REDUCER,
  [GET_NEW_TIME_AND_MATERIAL_JOBS_LIST_FULFILLED] : GET_NEW_TIME_AND_MATERIAL_JOBS_LIST_FULFILLED_REDUCER,
};

const jobSelector = state => state.jobs.job;


export const quotedRecurringList = createSelector(
  jobSelector,
  (instance) => {
    const sortedJobs = [...instance.quotedRecurringJobs.results];
    const quotedRecurring = [];
    let recurringLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== recurringLastCustomerName) {
          quotedRecurring.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        quotedRecurring.push(item);
        recurringLastCustomerName = item.customer_data && item.customer_data.customer_name;
      });
    }
    return { results: quotedRecurring , count: instance.quotedRecurringJobs.count };
  }
);

export const newQuotedRecurringList = createSelector(
  jobSelector,
  (instance) => {
    const sortedJobs = [...instance.newQuotedRecurringJobs.results];
    const quotedRecurring = [];
    let recurringLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== recurringLastCustomerName) {
          quotedRecurring.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        quotedRecurring.push(item);
        recurringLastCustomerName = item.customer_data && item.customer_data.customer_name;
      });
    }
    return { results: quotedRecurring , count: instance.newQuotedRecurringJobs.count };
  }
);

export const quotedProjectList = createSelector(
  jobSelector,
  (instance) => {
    const sortedJobs = [...instance.quotedProjectJobs.results];
    const quotedProject = [];
    let projectLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== projectLastCustomerName) {
          quotedProject.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        quotedProject.push(item);
        projectLastCustomerName = item.customer_data &&  item.customer_data.customer_name;
      });
    }

    return {results: quotedProject , count: instance.quotedProjectJobs.count };
  }
);

export const newQuotedProjectList = createSelector(
  jobSelector,
  (instance) => {
    const sortedJobs = [...instance.newQuotedProjectJobs.results];
    const quotedProject = [];
    let projectLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== projectLastCustomerName) {
          quotedProject.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        quotedProject.push(item);
        projectLastCustomerName = item.customer_data &&  item.customer_data.customer_name;
      });
    }

    return {results: quotedProject , count: instance.newQuotedProjectJobs.count };
  }
);

export const timeAndMaterialList = createSelector(
  jobSelector,
  (instance) => {
    const timeAndMaterial = [];
    const sortedJobs = [...instance.timeAndMaterialJobs.results];
    let timeMaterialLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== timeMaterialLastCustomerName) {
          timeAndMaterial.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        timeAndMaterial.push(item);
        timeMaterialLastCustomerName = item.customer_data &&  item.customer_data.customer_name;
      });
    }
    return { results: timeAndMaterial , count: instance.timeAndMaterialJobs.count };
  }
);

export const newTimeAndMaterialList = createSelector(
  jobSelector,
  (instance) => {
    const timeAndMaterial = [];
    const sortedJobs = [...instance.newTimeAndMaterialJobs.results];
    let timeMaterialLastCustomerName = '';
    if(sortedJobs.length) {
      sortedJobs.forEach((item) => {
        if(item.customer_data && item.customer_data.customer_name !== timeMaterialLastCustomerName) {
          timeAndMaterial.push({ name: item.customer_data.customer_name,
            customer_data: {},
            customer_id: item.customer_data.id,
          });
        }
        timeAndMaterial.push(item);
        timeMaterialLastCustomerName = item.customer_data &&  item.customer_data.customer_name;
      });
    }
    return { results: timeAndMaterial , count: instance.newTimeAndMaterialJobs.count };
  }
);


export const customerGroupByJob = createSelector(
  jobSelector,
  (job) => {
    const jobList1 = job.detailMap.filter((i) => i.id);
    const jobListIds = job.list.length ? job.list.map((i) => i.id) : [];

    // To show only the loaded jobs in the customer view
    const newJobFilteredList = jobList1.filter((i) => jobListIds.indexOf(i.id) > -1);
    newJobFilteredList.sort((a, b) => {
      const nameA = a.customer
      const nameB = b.customer
      return nameA < nameB ? -1 : 1;
    });
    const arr = groupWith((a, b) => a.customer === b.customer , newJobFilteredList);
    return arr;
  },
);

export const jobStatsDetail = createSelector(
  jobSelector,
  instance => instance.stats,
)

export const quotedRecurringSummaryData = createSelector(
  jobSelector,
  instance => instance.quotedRecurringSummary,
)

export const quotedProjectSummaryData = createSelector(
  jobSelector,
  instance => instance.quotedProjectSummary,
)

export const timeAndMaterialSummaryData = createSelector(
  jobSelector,
  instance => instance.timeAndMaterialSummary,
)

export const jobListIds = createSelector(
  jobSelector,
  instance => instance.listIds,
)

export const jobData = createSelector(
  jobSelector,
  instance => instance.data,
);

export const assignedTagList = createSelector(
  jobSelector,
  instance => instance.assignedTagsList,
);

export const jobDetail = createSelector(
  jobSelector,
  instance => instance.detail,
);

export const jobGeoFenceMappingDetail = createSelector(
  jobSelector,
  instance => instance.jobGeoFenceMapping,
)

export const jobAll = createSelector(
  jobSelector,
  instance => instance.all,
)

export const jobsUserPreferences = createSelector(
  jobSelector,
  instance => instance.jobsUserPreference,
)

export const adminJobsUserPreferences = createSelector(
  jobSelector,
  instance => instance.adminJobsUserPreference,
)

export const jobdetailMap = createSelector(
  jobSelector,
  instance => instance.detailMap,
)

export const jobGeofence = createSelector(
  jobSelector,
  instance => instance.geofenceDetail,
)

export const reportInitialValues = createSelector(
  jobSelector,
  instance => instance.initialValues,
);

export const tableCounts = createSelector(
  jobSelector,
  instance => instance.counts,
)

export const jobNotes = createSelector(
  jobSelector,
  instance => instance.list,
  
)

export const jobsAndCodesList = createSelector(
  jobSelector,
  instance => instance.jobAndCodeList,
)

export const quotedRecurringjobsAndCodesList = createSelector(
  jobSelector,
  instance => instance.quotedRecurringjobAndCodeList,
)

export const quotedProjectjobsAndCodesList = createSelector(
  jobSelector,
  instance => instance.quotedProjectjobAndCodeList,
)

export const timeAndMaterialjobsAndCodesList = createSelector(
  jobSelector,
  instance => instance.timeAndMaterialjobAndCodeList,
)

export const userViewPreferenceQuotedRecurring = createSelector(
  jobSelector,
  instance => instance.userViewPreferenceQuotedRecurring,
)

export const userViewPreferenceQuotedProject = createSelector(
  jobSelector,
  instance => instance.userViewPreferenceQuotedProject,
)

export const userViewPreferenceTimeAndMaterial = createSelector(
  jobSelector,
  instance => instance.userViewPreferenceTimeAndMaterial,
)

export const newUserViewPreferenceQuotedRecurring = createSelector(
  jobSelector,
  instance => instance.newUserViewPreferenceQuotedRecurring,
)

export const newUserViewPreferenceQuotedProject = createSelector(
  jobSelector,
  instance => instance.newUserViewPreferenceQuotedProject,
)

export const newUserViewPreferenceTimeAndMaterial = createSelector(
  jobSelector,
  instance => instance.newUserViewPreferenceTimeAndMaterial,
)

export const jobSummaryView = createSelector(
  jobSelector,
  instance => instance.jobSummary,
)

export default createReducer(defaultState, handlers);
