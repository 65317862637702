import React from 'react';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import { Formik,Field , FieldArray } from 'formik';
import styles from './styles.module.scss';
import { Editor } from "@tinymce/tinymce-react";
import ButtonGroupAtom from 'atoms/ButtonGroup';
import { QUESTION_TYPE_OPTIONS, QUESTION_TYPE_OPTIONS_OTHERS } from 'constants/surveys';
import QuestionPreviewModal from './questionPreview';
import { toast } from 'react-toastify';

import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import DeleteIcon from './Delete.png';
import PlusIcon from './addquestion.png';
import UpArrow from './Top.png';
import DownArrow from './Down.png';

import EditorAtom from './editorAtom';
import CheckboxAtom from 'atoms/formikCheckboxAtom';

import DetailedSurveyForm from 'surveys/components/detailForm';
import GenericModal from 'components/common/GenericModal';
  

class SurveyFormQuestion extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.surveyPreview = this.surveyPreview.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state={ isSurveyPreview:false, noOfQuestionsArray:[{sequence_of_configuration : 1, question : null , definition: 'SINGLE_SELECT' , mandatory_question : false , answer : [{ value : null , score : 0 }]}], isNextActive:false, isSubmitActive:false}
  }



  async onSubmit(values, {setSubmitting, setErrors}) {
    const { isNextActive } = this.state;
    const { history } = this.props;
    let isOptions = false;
    const buttonType = isNextActive ? 'next' : 'save';
    setErrors({});
    let data = [];
    let arr = [];
    arr = values.questions && values.questions.length && values.questions.map((x)=> x.definition !== 'FREE_TEXT' || x.definition !== 'DATE_TIME_SELECT' || x.definition !== 'TIME_SELECT' ? x.answer : []);
    arr = arr.flat();
    arr = arr.length && arr.map((y)=> y.score);

    values && values.questions.length && values.questions.forEach((i)=>{
      console.log('iww', i)
        if((i.question) !== null && (i.question).trim() !== ""){
          if((i.answer && i.answer.length && i.answer.find((y)=> (y.value) !== null  && (y.value).trim() !== "")) || i.definition == 'FREE_TEXT' || i.definition == 'DATE_TIME_SELECT' || i.definition == 'TIME_SELECT'){
            const { mandatory_question , ...rest } = i;
            let obj = rest;
            obj.behaviour = mandatory_question == true ? 'MANDATORY' : 'SKIPPABLE';
            obj.answer = obj.answer && obj.answer.length && obj.answer.filter((x)=>x.value !== null && (x.value).trim() !== "")
            data.push(obj);
          }
          else{
            isOptions = true;
            return true;
          }
        }
      }
    );

    if(!data.length && !isOptions){
      return toast.error('Please fill at least one question for this survey')
    }

    if(isOptions){
      return toast.error('Please fill at least one option for each question')
    }

    this.props.submitData({template_data : {questions : data , total_score : arr.length ? arr.reduce((a, b) => a + b, 0) : 0}},buttonType);
  }


  surveyPreview(){
    const{ isSurveyPreview } = this.state;
    this.setState({ isSurveyPreview: !isSurveyPreview});
  }

  render() {
    const { questionType , noOfQuestionsArray,isNextActive, isSubmitActive} = this.state;
    const { selectStep, initialValues, isStepThree , query, isSaveButtonLoader, isNextButtonLoader } = this.props; 
    return (
      <div style={{backgroundColor:'white'}} >
        <Formik
          onSubmit={this.onSubmit}
          initialValues={{questions : initialValues && initialValues.template_data.questions && !query.setup ? initialValues.template_data.questions : noOfQuestionsArray}}
          enableReinitialize ={true}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
          }) => (
            <Form  onSubmit={handleSubmit} className="mt-0 pt-2">
              <div>
                <FieldArray
                  name="questions"
                  render={(arrayHelpers1) => (
                    <div>
                      {values.questions && values.questions.length > 0 && (
                        values.questions.map((item, k) => {
                          return(
                          <div key={`opt${k}`}>
                          <Row className="mt-3">
                            <Col md={initialValues && initialValues.survey_type && initialValues.survey_type[0] == 'others' ? 8 : 9} className={`${styles['question-border']} pb-3`}>
                              <Row className="ml-0 mr-0 mt-3">
                                <Col md={12}>
                                  <div className="button-question-override w-100">
                                    <Field
                                      name={`questions.${k}.definition`}
                                      component={ButtonGroupAtom}
                                      options={initialValues && initialValues.survey_type && initialValues.survey_type[0] == 'others' ? QUESTION_TYPE_OPTIONS_OTHERS : QUESTION_TYPE_OPTIONS}
                                      onToggle={(val) => setFieldValue(`questions[${k}].definition`, val)}
                                      className={`${styles['button-group-width']}`}
                                      key={`${values.questions[k].sequence_of_configuration}`}
                                      id={`${values.questions[k].sequence_of_configuration}`}
                                      active={`${values.questions[k].definition}`}
                                    />
                                    {values.questions[k+1] ?
                                      <img src={DownArrow} width="20" height="20" onClick={()=>arrayHelpers1.swap(k, k+1)} className="float-right mt-3 cursor-pointer"/>
                                      :
                                      null
                                    }
                                    {values.questions[k-1] ?
                                      <img src={UpArrow} width="20" height="20" onClick={()=>arrayHelpers1.swap(k, k-1)} className="float-right mt-3 mr-1 cursor-pointer"/>
                                      :
                                      null
                                    }
                                    {values.questions.length > 1 ?
                                      <img src={DeleteIcon} width="20" height="20" onClick={()=> arrayHelpers1.remove(k)} className="float-right mt-3 mr-2 cursor-pointer"/>
                                      :
                                      null

                                    }
                                  </div>
                                </Col>
                              </Row>
                              <Row className="ml-0 mr-0 mt-3">
                                <Col md={12}>
                                  <FormGroup>
                                    <span>
                                      <Label>Question { k + 1 }</Label>
                                        <FormGroup className="float-right">
                                          <Field
                                            name={`questions[${k}].mandatory_question`}
                                            component={CheckboxAtom}
                                            onChange={(val) => setFieldValue(`questions[${k}].mandatory_question`, val)}
                                            onBlur={handleBlur}
                                            key={`${values.questions[k].sequence_of_configuration}`}
                                            value={`${values.questions[k].mandatory_question}`}
                                          />
                                        </FormGroup>
                                    </span>
                                    <Field
                                      name={`questions[${k}].question`}
                                      onChange={(val) => setFieldValue(`questions[${k}].question`, val)}
                                      component={EditorAtom}
                                      onBlur={handleBlur}
                                      className={`${styles['button-group-width']}`}
                                      key={`${values.questions[k].sequence_of_configuration}`}
                                      value={`${values.questions[k].question}`  == 'null' ? '' : `${values.questions[k].question}`}
                                      placeholder={item.definition == 'DATE_TIME_SELECT' ? "Specify the label for Date and Time" : "Type your question here"}
                                      multilineToolbar={false}
                                      toolbarItems={'alignLeft,alignCenter,alignRight,alignJustify,|,bold,italic,|,orderedList,bulletList'}
                                      toolbarPosition={`#htmlEditorFooter${k}`}
                                    />
                                    <div id={`htmlEditorFooter${k}`}></div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            <div>
                            {item.answer && (item.definition == 'SINGLE_SELECT' || item.definition == 'MULTI_SELECT') ? 
                              <FieldArray
                                name={`questions[${k}].answer`}
                                render={arrayHelpers => (
                                  <div>
                                    {item.answer && item.answer.length > 0 && (
                                      item.answer.map((q, r) => (
                                        <Row className="ml-0 mr-0 mt-3"> 
                                          <Col md={1}>
                                            {item.definition == 'SINGLE_SELECT' ?
                                              <FormGroup>
                                                <label className="container">
                                                  <input 
                                                    type="radio"
                                                    id="answer_option" 
                                                    name={`questions[${k}].answer[${r}]`}
                                                    checked={true}
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                              </FormGroup>
                                            :
                                              <FormGroup>
                                                 <label className="checkmarkcontainer mb-1">
                                                    <input 
                                                      type="checkbox"
                                                      id="answer_option" 
                                                      name={`questions[${k}].answer[${r}]`}
                                                      checked={true}
                                                    />
                                                    <span className="checkmarkblue"></span>
                                                  </label>
                                              </FormGroup>
                                            }
                                          </Col>
                                          <Col md={9} className="pl-0 survey-option-width">
                                            <FormGroup>
                                              <Field
                                                type="text"
                                                name={`questions[${k}].answer[${r}].value`}
                                                placeholder={`Option ${r+1}`}
                                                data-lpignore={true}
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={q.value == null ? '' : q.value}
                                                key={k}
                                              />
                                            </FormGroup>
                                          </Col>
                                          {initialValues && initialValues.template_data && initialValues.template_data.outcome && initialValues.template_data.outcome == 'scoring' && initialValues.survey_type.length && initialValues.survey_type[0] == 'others' &&
                                            <Col md={2} className="pl-0 survey-option-width">
                                              <FormGroup>
                                                <Field
                                                  type="number" 
                                                  name={`questions[${k}].answer[${r}].score`}
                                                  placeholder="Point"
                                                  data-lpignore={true}
                                                  autoComplete="off"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  value={q.score}
                                                  key={k}
                                                  min="0"
                                                />
                                              </FormGroup>
                                            </Col>
                                          }
                                        </Row>
                                      )))}
                                      <Row>
                                        <Col className="mt-2">
                                          <div onClick={()=>arrayHelpers.push({value : null , score : 0 })} className="link-name">+ <span className={styles['text-decoration']}>Add Option</span></div>
                                        </Col>
                                      </Row>
                                    </div>  
                                    )}/>:null}
                            </div>
                          </Col> 
                        </Row>
                        </div>
                      )}))}
                      <Row className="ml-4 mt-3">
                        <Col className="ml-3 pl-0">
                          <Button onClick={()=>arrayHelpers1.push({sequence_of_configuration : values.questions[values.questions.length - 1].sequence_of_configuration + 1, question : null , definition: 'SINGLE_SELECT' , mandatory_question : false , answer : [{ value : null , score : 0}]})} color="secondary" className="pl-1 pr-1">
                            <img src={PlusIcon} width="18" height="18" className="mr-2"/>Add Question
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mr-5 mt-2 mb-5">
                          {isStepThree == true ?
                            <div>
                              <Button
                                type="submit"
                                color="secondary"
                                className="float-right"
                                disabled={isNextButtonLoader}
                                onClick={()=>this.setState({isNextActive:true, isSubmitActive:false})}
                              >
                               Next <ButtonLoaderAtom active={isNextButtonLoader} />
                              </Button>
                              <Button
                                color="secondary"
                                className="float-right mr-3"
                                type="submit"
                                onClick={()=>this.setState({isNextActive:false, isSubmitActive:true})}
                              >
                               Save <ButtonLoaderAtom active={isSaveButtonLoader} />
                              </Button>
                            </div>
                            :
                            <Button
                              type="submit"
                              color="secondary"
                              className="float-right"
                              disabled={isNextButtonLoader}
                              onClick={()=>this.setState({isNextActive:true, isSubmitActive:false})}
                            >
                             Submit <ButtonLoaderAtom active={isNextButtonLoader} />
                            </Button>
                          }
                          <Button
                            color="secondary"
                            className="float-right mr-3"
                            onClick={()=>this.props.goToUrl()}
                          >
                           Back
                          </Button>
                          <Button
                            color="secondary"
                            className="float-right mr-3"
                            onClick={()=>this.surveyPreview()}
                          >
                           Preview
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}/>
                </div>
                {this.state.isSurveyPreview &&
                  <GenericModal toggle={this.surveyPreview} isOpen={this.state.isSurveyPreview} heading="Survey" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
                    <DetailedSurveyForm
                      data = {values && values.questions.length && values.questions.filter((i)=> i.question !== null)}
                      date= {initialValues && initialValues.create_date_display}
                      label = {initialValues && initialValues.template_data && initialValues.template_data.label}
                      isLoading={false}
                      name = ''
                      dateFormat={this.props.dateFormat}
                      timeFormat={this.props.timeFormat}
                      timeFormat24Hrs = {this.props.timeFormat24Hrs}
                    />
                  </GenericModal>
                }
              </Form>
            )}/>
          </div>
        );
      }

  }

export default SurveyFormQuestion;