 /*import  GraniteError from 'lib/GraniteError'; */
class UserEntity
{
  constructor(object={}){
   /* if(!username || typeof username! == 'string'){

    throw GraniteError(
    {
      username :'username entity cant be initialized without username';

    })*/
  this.first_name = object.user.first_name;
  this.last_name = object.user.last_name;
  this.company_name = object.company_name;
  this.phone_no = object.phone_no;
  this.phone_ext = object.phone_ext;
  this.email = object.email;
  this.password = object.password;
  this.token = object.token;
  this.username = object.user.username;
  this.phone_ext = object.phone_ext;
  this.is_email_verified = object.is_email_verified;
  this.is_phone_verified = object.is_phone_verified;
  this.id = object.id;
 /* this.profile = obj.profile.medium_url;*/
/*  this.allowedRoutes = [];*/
  /*this.allowedSideMenu = [];*/
 }

 toString() {
    return '(' + this.first_name + ' ' + this.last_name + ')';
  }

  name() {
    let name = this.first_name;
    if (this.last_name) {
      name += ` ${this.last_name}`;
    }
    return name;
  }

  
  toJSON() {
    const { first_name, last_name, company_name, phone_no, email , password, token, username, phone_ext, is_email_verified, is_phone_verified, id } = this;
    return {first_name, last_name, company_name, phone_no, email, password, token, username, phone_ext, is_email_verified, is_phone_verified, id }
  }
}
export default UserEntity;


