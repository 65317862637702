import React from 'react';
import { server } from 'helpers/config';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';
import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as InfoDucks from 'ducks/info/info';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import TwoColumnLayout from 'layouts/TwoColumn';
import ActivitySplitFilterSection from 'sections/activities/activitySplitList/Filter';
import ActivitySplitListSection from 'sections/activities/activitySplitList/List';

import { NAV } from 'constants/activities';
const GeocodingOption = server.geocodingOption;

/**
 * CustomActivitySplitListPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link ActivitySplitFilterSection}
 *    - {@link ActivitySplitListSection}
 *
 * Fetch Needs:
 *    - getActivitySplits
 */
class CustomActivitySplitListPage extends React.Component {
  constructor(props) {
    super(props);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.codeLatLng = this.codeLatLng.bind(this);
    this.state = { isLoading: false,
      isLocationLoading:false,
      parent: NAV.CustomActivitySplitListPage.parent,
      isInfoOpen: true,
      permission: true };
  }

  componentWillMount() {
    const cookieKey = cookie.load("activities_split_list_info");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.ActivityTrackDucks.resetSessionInfo();
    this.props.GeoFenceDucks.resetGeoFence();
    this.props.InfoDucks.getInformations({route: 'activity.split'});
    this.fetchData();
  }


  componentWillUnmount(){
    this.props.ActivityTrackDucks.resetSessionInfo();
    this.props.GeoFenceDucks.resetGeoFence();
    this.props.InfoDucks.resetInformations();
    this.props.ActivitySplitDucks.resetActivitySplits();
  }

  setParentUrl(query) {
    const parent = `/timecard/custom/list/${query.employee_id}/dailyV0`;
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      this.setState({ parent });
    }
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });
    this.setParentUrl(query);
    this.props.ActivityTrackDucks.getJobActivityTrack({id: params.id})
      .then(() =>{
        this.setState({ permission: true });
      })
      .catch(() => {
        this.setState({ permission: false });
      });
    Promise.all([
      this.props.ActivitySplitDucks.getActivitySplits({ id: params.id }),
      this.props.DailyTimecardDucks.getDailyTimecardsDetail({ id: params.id }),
      this.props.GeoFenceDucks.getDailyTimecardActivityTrackGeofences({id: params.id, paginate:false }),
    ])
      .then(() => {
        this.setState({ isLoading: false });
        // const arr = sessionInfo.locations && sessionInfo.locations.filter((item) => item.activity_code !== 'BEACON');
        // this.codeLatLng(arr);
      })
      .catch(() => this.setState({ isLoading: false }));
  }

    codeLatLng(array) {
    const { isOpen, isLocationLoading } = this.state;
    const { sessionInfo, google} = this.props;
    const updatedArr = array.length ? array.filter((i)=> i.position && i.position.lat !== undefined && i.position.lng !== undefined) : array;
    if(!updatedArr || !updatedArr.length) return this.setState({isLocationLoading : false});
    this.setState({ isLocationLoading:  true });
    Promise.all(updatedArr.map((item, index) => 
       setTimeout(()=>{this.props.ActivityTrackDucks.getActivityLocationAddress(item , GeocodingOption)
        .then((res) => {
          const { selectedMarkerAddress } = this.props;
          let add = '';
          if(res.action.payload.data && res.action.payload.data.status === 'OK'){
            add = res.action.payload.data.results[0].formatted_address;
          }
          else if(res.action.payload.items && res.action.payload.items.length){
            add = res.action.payload.items[0].label;
          }
          this.props.ActivityTrackDucks.setSessionActivityLocationAddress({
            ...item,
            address: add,
          });
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })  
             
          }
        })
        .catch(() => {
          if(index == updatedArr.length - 1){
            this.props.ActivityTrackDucks.openSessionInfoWindow(updatedArr[updatedArr.length - 1]);
            this.setState({ isLocationLoading:  false })            
          }
        })
      },100*(index+1))
      )
    )
      .catch(() => this.setState({ isLocationLoading:  false }));
    return null;
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("activities_split_list_info", !isInfoOpen);
  }

  render() {
    const { activitySplitList, infoList, dailyTimecardDetail, location: { query } } = this.props;
    const { isLoading, parent, isInfoOpen, isLocationLoading, permission } = this.state;
    const queryParams = `?employee_id=${query.employee_id}&start_date=${query.start_date}&end_date=${query.end_date}&payroll_id=${query.payroll_id}&first_name=${query.first_name}&last_name=${query.last_name}`;
    const dailyTimecardUrl = `/timecard/custom/list/${query.employee_id}/dailyV0${queryParams}`;
    let breadcrumb = [
      ...NAV.CustomActivitySplitListPage.breadcrumb,
      { href: dailyTimecardUrl , label: 'Daily Timecards' },
      { label: 'Activities' },
    ];

    const description = Object.keys(dailyTimecardDetail).length
      ? `${NAV.CustomActivitySplitListPage.description} ${dailyTimecardDetail.date_display}`
      : NAV.CustomActivitySplitListPage.description;
    const title = Object.keys(dailyTimecardDetail).length
      ? `${NAV.CustomActivitySplitListPage.title} (${dailyTimecardDetail.employee.user.first_name} ${dailyTimecardDetail.employee.user.last_name})`
      : NAV.CustomActivitySplitListPage.title;

    return (
      <TwoColumnLayout
        navInfo={{
          ...NAV.CustomActivitySplitListPage, parent, breadcrumb, description, title,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        {activitySplitList.length > 0 &&
          <ActivitySplitFilterSection />
        }
        <ActivitySplitListSection
          isLoading={isLoading}
          isLocationLoading={isLocationLoading}
          permission={permission}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  location: RouteDucks.location(state),
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
  infoList: InfoDucks.infoList(state),
  sessionInfo: ActivityTrackDucks.sessionInfo(state),
});

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
  DailyTimecardDucks: bindActionCreators(DailyTimecardDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
});

CustomActivitySplitListPage.propTypes = {
  activitySplitList: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  ActivitySplitDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

CustomActivitySplitListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomActivitySplitListPage);
