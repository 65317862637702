import React from 'react';
import { UncontrolledTooltip, Tooltip , Row, Col , Popover } from 'reactstrap';
import Form from 'reactstrap/lib/Form';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import cookie from "react-cookies";
import Button from "reactstrap/lib/Button";
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';
import StorageGateway from 'lib/storage-gateway';
import moment from 'moment-timezone';

import styles from './styles.module.scss';

import {ReactComponent as FilterIcon} from './filter.svg';
import {ReactComponent as BackIcon} from './showFilters.svg';

import closeimage from './close.png';

import AsyncMultiSelectAtom from 'atoms/AsyncMultiSelect';
import AsyncMultiSelectDropdownAtom from 'atoms/AsyncMultiSelectDropdown';
import SelectAtom from 'atoms/Select';
import {  FORM_LAYOUT } from 'constants/layout';
import {  FILTER_OPTIONS , DATE_RANGE_OPTIONS } from 'constants/jobs';


class ManageJobAdvancedFilterDetailFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.updateSelectedFiltersList = this.updateSelectedFiltersList.bind(this);
    this.removeSelectedFilter = this.removeSelectedFilter.bind(this);
    this.applySelectedFilter = this.applySelectedFilter.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.state = { filtersList : props.filters , error : null }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.filters !== this.props.filters && nextProps.filters){
      this.updateSelectedFiltersList(nextProps.filters)
    }
  }

 resetForm(){
    const { dateFormatDisplay , change , userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    let advancedFilterCookie =  StorageGateway.get(`${this.props.advancedFilterCookie}${uniqueUuid}${userProfile.company.id}`);
    advancedFilterCookie = advancedFilterCookie && JSON.parse(advancedFilterCookie);
    advancedFilterCookie && Object.keys(advancedFilterCookie) && Object.keys(advancedFilterCookie).length && Object.keys(advancedFilterCookie).forEach((item)=>{
      change(`${item}`, advancedFilterCookie[`${item}`])
    });
  }

  updateFilter(key){
    this.setState({error : null});
    this.props.resetForm();
    this.props.updateFilter(key);
  }

  togglePopup(){
    this.setState({error : null});
    this.props.toggle();
  }

  removeSelectedFilter(data){
    this.props.removeSelectedFilter(data);
    this.togglePopup();
  }


  updateSelectedFiltersList(detail){
    this.setState({filtersList : detail});
    this.togglePopup();
  }

  applySelectedFilter(data){
    this.props.applySelectedFilter(data, 'detailForm');
    this.togglePopup();
  }


  render() {
    const { filtersList , error } = this.state;
    const { handleSubmit , advancedFilterCookie , openFilterPopup} = this.props;
    return (
        <div className={`${styles['filtered-inline']}`}>
          {
            filtersList && filtersList.length ? filtersList.map((item)=>(
              <div className='mb-2'>
                <Row className="ml-0 mr-0 cursor-pointer" id={`${advancedFilterCookie}${item.key}`}>
                  <Col md={2} className="pl-0 pr-0 mr-2">
                    <div className={`${styles['filtered-list']} pos-relative`}>
                      <span onClick={()=>{this.updateFilter(item.key) ; this.resetForm()}}>{item.label} : {item.value}</span>
                      <img  onClick={()=> this.removeSelectedFilter(item.key)} src={closeimage} height="8" width="8" className={`${styles['filter-heading-cross']} ${styles['filter-heading-inner']}`} />                
                    </div>
                  </Col>
                </Row>
                <div className='filter-advanced'>
                  {openFilterPopup && openFilterPopup === item.key &&
                    <Popover placement="right" isOpen={item.key===openFilterPopup} target={`${advancedFilterCookie}${item.key}`}>
                      <Form onSubmit={handleSubmit(this.applySelectedFilter)} className='w-100'>
                        <div className={`${styles['filter-popover-right']} box arrow-left`} tabIndex="0">
                          <span className={`${styles['filter-heading']}`}>Update filter</span>
                          <span onClick={()=> {this.updateFilter(); this.resetForm()}}><img src={closeimage} height="10" width="10" className={`${styles['filter-heading-cross']}`}/></span>
                          <div className='override-filter-ui'>{this.props.renderFilters(item.filterName)}</div>
                          <Button type="submit" color="secondary" className='f-12 mt-3' style={{lineHeight: '1'}}>
                            Update
                          </Button>
                        </div>
                       </Form>
                    </Popover>
                  }
                </div>
              </div>
          ))
            :
            null
          }
        </div>
    );
  }
};

const ManageJobAdvancedFilterDetailForm = reduxForm({
  form: 'ManageJobAdvancedFilterDetailForm',
  enableReinitialize: true,
})(ManageJobAdvancedFilterDetailFormComponent);


const quotedRecurringSelector = formValueSelector('quotedRecurringFiltersCookieNew');
const quotedProjectSelector = formValueSelector('quotedProjectFiltersCookieNew');
const timeAndMaterialSelector = formValueSelector('MaterialFiltersCookieNew');

export default connect(
  (state, props) => {
    const job_name = props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}job_name`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}job_name`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}job_name`);
    const date_range =  props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}date_range`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}date_range`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}date_range`);
    const tasks = props.advancedFilterCookie =='quotedRecurringFiltersCookieNew' ? quotedRecurringSelector(state, `${props.advancedFilterCookie}tasks`) : props.advancedFilterCookie =='quotedProjectFiltersCookieNew' ? quotedProjectSelector(state, `${props.advancedFilterCookie}tasks`) : timeAndMaterialSelector(state, `${props.advancedFilterCookie}tasks`);
    return { job_name , tasks , date_range };
  },
)(ManageJobAdvancedFilterDetailForm);

