import React from 'react';
import Button from 'reactstrap/lib/Button';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { toast } from 'react-toastify';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FormikMultiSelectAtom from 'atoms/FormikMultiselectDropdown';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';

class EmployeesFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isAllEmployeesSelected: false,
      departmentList: [],
      teamList: [],
      tagList : [],
      employeesList: [],
      selectedDeparments: [],
      selectedTeams: [],
      selectedEmployees: [],
      selectedTags: [],
    }  
    this.populateDepartments = this.populateDepartments.bind(this);
    this.populateEmployees = this.populateEmployees.bind(this);
    this.handleDeparment = this.handleDeparment.bind(this);
    this.handleTeam = this.handleTeam.bind(this);
    this.handleTag = this.handleTag.bind(this);
    this.handleEmployee = this.handleEmployee.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.setEmployeeList = this.setEmployeeList.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({
        paginate: false,
        get_active: true,
        get_active_teams: true,
      }),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        get_active: true
      }),
      this.props.EmployeeDucks.getEmployeeTags({
        paginate: false,
        tag_type: 3,
        is_active:true
      }),
      this.props.EmployeeDucks.getEmployees({ paginate: false, is_active: true }),
    ]);
  }

  handleAssign(){
    const {selectedEmployees, isAllEmployeesSelected} = this.state;
    if(!selectedEmployees.length && !isAllEmployeesSelected){
      return toast.error('Please select employee to assign');
    }
    if(this.props?.modalEmployeeAssign){
      this.props.modalEmployeeAssign(selectedEmployees, isAllEmployeesSelected)
      return;
    }
    this.props.handleAssignAndDelete(selectedEmployees, isAllEmployeesSelected);
  }

  setEmployeeList(dept_ids = [], team_ids = [], tags= []){
    this.props.EmployeeDucks.getEmployees({
      department_ids : JSON.stringify(dept_ids),
      team_ids: JSON.stringify(team_ids),
      tags: JSON.stringify(tags),
      assigned: false,
      is_active: true,
      paginate: false
    }).then(res => {
      if(res && res.value && res.value.length){
        let showEmployees = res.value.map(item => ({id: item.id, name: item.user.full_name}))
        this.setState({ employeesList: showEmployees })
      }
    });
  }

  componentDidUpdate (prevProps) {
    if (this.props.departmentAll !== prevProps.departmentAll) {
      this.populateDepartments(this.props.departmentAll);
    }
    if (this.props.employeeList !== prevProps.employeeList) {
      this.populateEmployees(this.props.employeeList);
    }
    if (this.props.tagsList !== prevProps.tagsList) {
      const tagList = this.props.tagsList[0] && this.props.tagsList[0].tag_collection && this.props.tagsList[0].tag_collection.length && this.props.tagsList[0].tag_collection.map((item, idx) => {
        // ? put item.id when id comes in tagList
        return {  
          name: item, 
          id: idx + 1, 
          checked: false,
        }
      });
      this.setState({ tagList });
    }
  }


  populateDepartments (departments) {
    if (!departments.length)  return;
    const departmentList = departments.map(item => {
      return {
        name: item.name, 
        id: item.id, 
        checked: false,
        teams: item.teams
      }
    });
    this.setState({ departmentList });
  }

  populateEmployees (employees) {
    if (!employees.results.length)  return;
    const employeesList = employees.results.map(item => ({id: item.id, name: item.user.full_name}));
    this.setState({ employeesList });
  }

  handleDeparment(data){
    const {selectedTeams, selectedTags} = this.state;
    let teamList = [];
    let department_ids = [];
    data.length && data.forEach((ele) => {
      department_ids.push(ele.id);
      ele.teams.forEach(item => teamList.push({ name: item.name, id: item.id, checked: false }))
    })
    this.setState({teamList, selectedDeparments: department_ids})

    let new_team_ids = [];
    selectedTeams.forEach(ele => {
      teamList.forEach(item => {
        if(item.id === ele) new_team_ids.push(ele);
      })
    })
    this.setEmployeeList(department_ids, new_team_ids, selectedTags);
  }


  handleTeam(data){
    const { selectedDeparments, selectedTags } = this.state;
    let team_ids =  data.map(item => item.id);
    this.setState({selectedTeams: team_ids})
    this.setEmployeeList(selectedDeparments, team_ids, selectedTags);
  }


  handleTag(data){
    const { selectedDeparments, selectedTeams } = this.state;
    let tags = data.map(item => item.name);
    this.setState({selectedTags: tags});
    this.setEmployeeList(selectedDeparments, selectedTeams, tags);
  }

  handleEmployee(data){
    let employee_ids = data.map(item => item.id);
    this.setState({selectedEmployees: employee_ids})
  }


  render() {
    const { 
      isAllEmployeesSelected,
      departmentList,
      teamList,
      tagList,
      employeesList,
      selectedDeparments,
      selectedEmployees,
    } = this.state;
    const { isAssigning, verticalStyle } = this.props;
    return (
      <div >
        <Row className={verticalStyle ? 'd-flex flex-column ' : 'p-0 m-0 pr-3 d-flex justify-content-between'}>
          {departmentList && <Col md={verticalStyle ? 12 : 3} className={verticalStyle ? "pl-3" : "pr-0"}>
            <div className="text-left">Department</div>
            <div className="w-100 task-override emp-task-override">
              <FormikMultiSelectAtom 
                id="departments"
                name="departments"
                items={departmentList}
                placeholder="Select Departments"
                itemToString={i => i && i.name}
                onChange={data => this.handleDeparment(data)}
              />
            </div>
          </Col>}
          {teamList && <Col md={verticalStyle ? 12 : 2} className="pl-3">
            <div className="text-left">Team</div>
              <div className="w-100 task-override emp-task-override ">
                <FormikMultiSelectAtom 
                  id="teams"
                  name="teams"
                  items={teamList}
                  placeholder="Select Teams"
                  itemToString={i => i && i.name}
                  onChange={data => this.handleTeam(data)}
                  disabled={selectedDeparments && selectedDeparments.length ? false : true}
                />
              </div>
          </Col>}

          {tagList && <Col md={verticalStyle ? 12 : 2} className="pl-3">
            <div className="text-left">Tags</div>
              <div className="w-100 task-override emp-task-override ">
                <FormikMultiSelectAtom 
                    id="tags"
                    name="tags"
                    items={tagList}
                    placeholder="Select Tags"
                    itemToString={i => i && i.name}
                    onChange={data => this.handleTag(data)}
                />
              </div>
          </Col>}

          {employeesList && <Col md={verticalStyle ? 12 : 3} className="pl-3">
            <div className="text-left">Employees</div>
            <div className="task-override emp-task-override">
              <FormikMultiSelectAtom 
                    id="employees"
                    name="employees"
                    items={employeesList}
                    placeholder="Select Employees"
                    itemToString={i => i && i.name}
                    onChange={data => this.handleEmployee(data)}
                    isDisableSelectAll={true}
                />
            </div>
            <label htmlFor="selectAllEmployees">
              <input 
                  type="checkbox" 
                  id="selectAllEmployees" 
                  onChange={() => this.setState({ isAllEmployeesSelected: !isAllEmployeesSelected })}
                  checked={isAllEmployeesSelected}
                  className="mt-2 mr-1"
                  disabled={!employeesList.length}
                  />
              Select all employees
            </label>
          </Col>}
          <Col md={2} className={verticalStyle ? "d-flex justify-content-end align-self-end" : "custom-assign-button pr-0 pl-3 d-flex justify-content-end"}>
              <Button
                type="submit"
                color="primary"
                style={{height: '2.2rem', marginTop: '1.2rem'}}
                onClick={this.handleAssign}
                disabled={isAssigning || (!isAllEmployeesSelected && selectedEmployees.length === 0) }
              >
                Assign <ButtonLoaderAtom active={isAssigning} />
              </Button>
          </Col>          
        </Row>      
      </div>
    );
  }
}



EmployeesFilter.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    employeeList: EmployeeDucks.employeeList(state),
    tagsList: EmployeeDucks.tagsList(state),
    departmentAll: DepartmentDucks.departmentAll(state),
    teamAll: TeamDucks.teamAll(state),
})

const mapActionToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

export default connect(
    mapStateToProps,
    mapActionToProps
)(EmployeesFilter);