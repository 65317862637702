import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import PayrollFormSection from 'sections/payrolls/form/Form';

import { NAV } from 'constants/payrolls';

/**
 * PayrollFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link PayrollFormSection}
 *
 * Fetch Needs:
 *    - get payroll detail
 */
class PayrollFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, isPayrollAdd: true, isPayrollEditInfo: true, isPayrollAddInfo: true };
  }

  componentWillMount() {
    const { router: { route: { match: { params } } } } = this.context;
    
    if (!params.id) {
      const cookieKey = cookie.load("payroll_add_form_info");
      if(cookieKey) {
        this.setState({isPayrollAddInfo: JSON.parse(cookieKey)});
      }
      this.props.InfoDucks.getInformations({route: 'payroll.add'});
    }

    if (params.id) {
      const cookieKey = cookie.load("payroll_edit_form_info");
      if(cookieKey) {
        this.setState({isJobEditInfo: JSON.parse(cookieKey)});
      }
      this.props.InfoDucks.getInformations({route: 'payroll.detail'});
    }
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    if (params.id) {
      this.setState({ isLoading: true });
      return this.props.PayrollDucks.getPayroll(params)
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
    return null;
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isPayrollAddInfo, isPayrollEditInfo } = this.state;
    if (params.id) {
      this.setState({ isPayrollEditInfo: !isPayrollEditInfo });
      cookie.save("payroll_edit_form_info", !isPayrollEditInfo);
    }
    else {
      this.setState({ isPayrollAddInfo: !isPayrollAddInfo });
      cookie.save("payroll_add_form_info", !isPayrollAddInfo);
    }
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { infoList } = this.props;
    const { location: { query } } = this.props;
    const {isPayrollEditInfo , isPayrollAddInfo } = this.state;
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.PayrollDetailFormPage : NAV.PayrollAddFormPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={params.id ? isPayrollEditInfo : isPayrollAddInfo}
        fetchData={this.fetchData}
        query={query}
      >
        <PayrollFormSection isLoading={this.state.isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

PayrollFormPage.propTypes = {
  PayrollDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

PayrollFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PayrollFormPage);
