import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import PayrollTableComponent from 'components/payrolls/list/Table';

/**
 * PayrollListPage -> PayrollListSection
 *
 * Components:
 *    - {@link PayrollTableComponent}
 *
 * State:
 *    - Payroll list
 *
 * Actions:
 *    None
 */
class PayrollListSection extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.props.PayrollDucks.getPayrolls(nextQuery);
    }
  }

  render() {
    const { isLoading, payrollList, location } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <PayrollTableComponent data={payrollList} />
        <PaginationComponent
          count={payrollList.count}
          location={location}
          cookieKey="payroll_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  payrollList: PayrollDucks.payrollList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
});

PayrollListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  payrollList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  PayrollDucks: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PayrollListSection);
