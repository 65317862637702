import React from 'react';

import PropTypes from 'prop-types';

import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Table from 'reactstrap/lib/Table';
import Button from 'reactstrap/lib/Button';

import EditIconAtom from 'atoms/EditIcon';

import styles from './styles.module.scss';

/**
 * DeviatedDailyTimecardListPage -> DeviatedDailyTimecardListSection -> DeviatedDaliyTimeCardTableComponent
 *
 * Props:
 *    - activity split list
 */
const DeviatedDaliyTimeCardTableComponent = (props) => {
  const { data, goTo, toggle  } = props;

  return (
    <div>
      {data && data.length > 0 && data.map(item => (
        <section key={item.id}>
          <Row className="mt-5">
            <Col md={5}>
              <h3 className="mb-0">{item.employee.user.full_name}</h3>
            </Col>
          </Row>
          <div>
            {item.dts.map(dtItem => (
              <Row key={dtItem.id}>
                <Col md={6} className="mt-4">
                  <p className="mb-0">Activities for session date {dtItem.daily_timecard.display}</p>
                </Col>
                <Col md={6} className="filter mb-2 mt-4">
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={() => goTo(`/activity/timecard/custom/list/${dtItem.daily_timecard.id}/split/add`, { employee_id : item.employee.id })}
                  >
                    Add Missing Activity
                  </Button>
                </Col>
                <Col md={12}>
                  <Table className="table-activity" hover responsive size="sm">
                    <thead className="Header-col">
                      <tr>
                        <th className="text-center">Action</th>
                        <th>Activity</th>
                        <th>Job Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th className="text-right pr-3">Time</th>
                        <th>Validity</th>
                        <th>Activity Status</th>
                        <th>Correction Date</th>
                        <th>Resolved As</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dtItem.daily_timecard.splits.map(activityItem => (
                        <tr
                          key={activityItem.id}
                          className={activityItem.status !== '1' ? 'bgRed' : ''}
                          style={
                            (activityItem.color === '')
                              ? { backgroundColor: '#FFF' }
                              : { background: activityItem.color }
                          }
                        >
                          <td className="text-center" title="Edit Activity">
                            <EditIconAtom className="cursor-pointer" onClick={() => toggle(activityItem)} />
                          </td>
                          <td title={activityItem.activity_name}>{activityItem.activity_name}</td>
                          <td title={activityItem.job_label}>{activityItem.job_label}</td>
                          <td title={activityItem.start_datetime_display}>{activityItem.start_datetime_display}</td>
                          <td title={activityItem.end_datetime_display}>{activityItem.end_datetime_display}</td>
                          <td className="text-right pr-3" title={activityItem.total_time_display}>{activityItem.total_time_display}</td>
                          <td title={activityItem.validity_display}>{activityItem.validity_display}</td>
                          <td title={activityItem.status_display}>{activityItem.status_display}</td>
                          <td title={activityItem.manual_action_time_display}>{activityItem.manual_action_time_display}</td>
                          <td title={activityItem.resolved_as}>{activityItem.resolved_as}</td>
                          {activityItem.message.length
                            ? <td id={`${activityItem.id}${activityItem.message[0].message}`}>
                              {activityItem.message[0].message}
                            </td>
                            : <td />
                          }
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ))}
          </div>
        </section>
      ))}
      {data && !data.length  &&
        <div className="mt-4"><p className={`${styles['no-records']} text-center`} colSpan="10">No Records Found</p></div>
      }
    </div>
  );
};

DeviatedDaliyTimeCardTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  toggle: PropTypes.func,
  goTo: PropTypes.func,
  isModalLoading: PropTypes.bool.isRequired,
  selectedItemId: PropTypes.number.isRequired,
};

export default DeviatedDaliyTimeCardTableComponent;
