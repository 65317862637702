import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  GET_BILL_DETAIL_SUCCESS : 'GET_BILL_DETAIL_SUCCESS',
  INFO_LIST: 'INFO_LIST',
  GET_BILL_STATUS_SUCCESS : 'GET_BILL_STATUS_SUCCESS',
  SHOW_LOADER: 'SHOW_LOADER',
  SHOW_AUDIT_LOGS_LOADER: 'SHOW_AUDIT_LOGS_LOADER',
  HIDE_AUDIT_LOGS_LOADER: 'HIDE_AUDIT_LOGS_LOADER',
  AUDIT_LIST: 'AUDIT_LIST',
  UPDATE_BILL_DETAILS_SUCCESS : 'UPDATE_BILL_DETAILS_SUCCESS',
};

class billDetailUseCase {
 
  billApiGateway = null;
  

  constructor(billApiGateway) {
    this.billApiGateway = billApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'billDetailUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data) {
    this.quantity = data.quantity;
    this.discount = data.discount ? parseFloat(data.discount ,10) : 0;
    this.charge_rate = data.charge_rate ?  parseFloat(data.charge_rate ,10) : 0;
    this.id = data.id; 
    this.total_amount = data.total_amount;
    this.service_fees = data.service_fees  ? parseFloat(data.service_fees ,10) : 0;
    this.billing_amount = data.billing_amount;
    this.discount_unit = data.discount_unit;
  }

  async getInfoList(){
    this.infoList = await this.billApiGateway.getInfoList({ route: 'vendor.account'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }
  
  async getCompanyBillDetails(params){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.billDetails = await this.billApiGateway.getCompanyBillDetails(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_BILL_DETAIL_SUCCESS,
      data: this.billDetails,
    });
  }

  async execute() {
    let errors = {};

    if(isNaN(this.discount)){
      errors.discount = 'Enter a valid number';
    }

    if(isNaN(this.charge_rate)){
      errors.charge_rate = 'Enter a valid number';
    }

    if(isNaN(this.service_fees)){
      errors.service_fees = 'Enter a valid number';
    }

    if ((this.discount > (this.charge_rate*this.quantity)) && (this.discount_unit === 'Amount')) {
      errors.discount = 'Discount should be less than the (Billed Users x Billing Rate)';
    }
    if (this.discount && !this.discount_unit) {
      errors.discount_unit = 'Select Discount Unit';
    }
    if(this.discount_unit === 'Percentage' && (this.discount > 100)){
      errors.discount = 'Discount cannot be greater than 100%';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }
    const billDetailEntity = await this.billApiGateway.updateCompanyDetails(this.discount, this.status_id, this.id, this.charge_rate, this.service_fees, this.discount_unit);
    this.eventEmitter.emit({
      type: EVENT_TYPES.UPDATE_BILL_DETAILS_SUCCESS,
      data: billDetailEntity,
    });
  }

  async getAuditLogs(id){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_AUDIT_LOGS_LOADER,
    });
    this.auditLogsList = await this.billApiGateway.getAuditLogs({
      entity_id: JSON.stringify([id]),
      entity_type: JSON.stringify(['billingcycle']),
      paginate: false
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.AUDIT_LIST,
      auditLogsList: this.auditLogsList,
    });
  }
}

export default billDetailUseCase;