import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

/**
 * PayrollListPage -> PayrollFilterSection
 *
 * Components:
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - get payroll list
 */
const PayrollFilterSection = (props, context) => {
  const { router: { history } } = context;

  const goTo = url => history.push(url);

  return (
    <Row className="filter ml-3 mr-4">
      <Col md={4} />
      <Col md={8} className="pr-0">
        <section className="float-right">
          <Button
            color="accent"
            onClick={() => goTo('/payroll/add')}
            className="mr-0"
          >
            Add Payroll Schedule
          </Button>
        </section>
      </Col>
    </Row>
  );
};

PayrollFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PayrollFilterSection;
