import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip, Collapse } from 'reactstrap';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import caretArrowUp from './caret-arrow-up.png';
import caretArrowDown from './caret-down.png';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import SelectAtom from 'atoms/Select';
import InfoGreyIconAtom from 'atoms/InfoIcon';
import ToggleAtom from 'atoms/Toggle';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import ButtonGroupAtom from 'atoms/ButtonGroup';

import { FORM_LAYOUT } from 'constants/layout';

import { DAYS, SHIFT_SPLIT_OPTIONS, } from 'constants/payrolls';
import styles from './styles.module.scss';
import MealCardComponent from './MealCard'

const OvertimeRuleComponent = (props) => {
  const { submitting, error, dailySplit, timeFormat,
    timeFormat24Hrs, showMessage, toggle, californiaRuleMessage, additionalOverTime,
    initialValues, OvertimeInfo, toggleOvertimeAccordian, mealBreakInfo, toggleMealBreakAccordian,
    toggleAddRuleModal, enableOvertime, mealRuleList , disableOvertime } = props;

  return (
    <div className="mt-0 pb-3">
      {error && <div className="error-text">{error}</div>}
      <Row>
        <Col xs={12} md={props.newLayout ? 12 : 6}>
          <Field
            id="is_meal_override"
            type="checkbox"
            name="is_meal_override"
            label={
              <div>
                Override Meal Payment
                <InfoGreyIconAtom id="overrideMeal" className="pl-2 align-text-top" />
                <UncontrolledTooltip placement="right" target="overrideMeal">
                  Select if meal paid can be overriden.
                </UncontrolledTooltip>
              </div>
            }
            component={CheckboxAtom}
            layout={FORM_LAYOUT}
          />
          <div>
            <Label>
              <div className="mb-1">
                Shifts to be split based on
                <InfoGreyIconAtom id="shiftSplit" className="pl-2 align-text-top" />
                <UncontrolledTooltip placement="right" target="shiftSplit">
                  {dailySplit ? 'Timecards would be split and created for each logical workday.'
                    : 'Daily timecards would be created based on user\'s punch in session and could span across workdays.'
                  }
                </UncontrolledTooltip>
              </div>
            </Label>
          </div>
          <div className="mb-4">
            <Field
              component={ButtonGroupAtom}
              name="daily_split"
              options={SHIFT_SPLIT_OPTIONS}
              active={initialValues && initialValues.daily_split ? initialValues.daily_split : false}
              size="sm"
            />
          </div>

          <div className="mt-3">
            <Field
              id="work_day_start_time"
              name="work_day_start_time"
              label="Work Day Start Time"
              layout={FORM_LAYOUT}
              placeholder="Start Time"
              component={DateTimePickerAtom}
              disabled={!dailySplit}
              options={{
                dateFormat: timeFormat,
                enableSeconds: false,
                enableTime: true,
                minuteIncrement: 1,
                noCalendar: true,
                time_24hr: timeFormat24Hrs,
              }}
            />
          </div>
          <div className="mt-3">
            <b>Overtime Rules</b>
            <span onClick={() => toggleOvertimeAccordian()} role="button" tabIndex="0" className="remove-focus" >
              <img className='ml-2 mr-2' src={OvertimeInfo ? caretArrowUp : caretArrowDown} alt='' height="10" width="10" />
            </span>
            <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
          </div>
          <Collapse isOpen={OvertimeInfo}>
            <div className={props.newLayout ? "newLayoutToggleCard" : "toggleCard"}>
              <Row>
                <Col md={12}>
                  <Col className="pl-0">
                    <Field
                      id="is_overtime_applicable"
                      type="checkbox"
                      name="is_overtime_applicable"
                      label={
                        <div>
                          Enable Overtime Rules
                        </div>
                      }
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                      disabled={disableOvertime ? true : false}
                    />
                  </Col>
                  <Label>
                    <div>
                      Weekly Overtime After (hours/work week)
                      <InfoGreyIconAtom id="weekOver" className="pl-2 align-text-top" />
                      <UncontrolledTooltip placement="top" target="weekOver">
                        After (x) hours and (y) minutes in a work week, overtime will begin accumulating.
                      </UncontrolledTooltip>
                    </div>
                  </Label>
                </Col>
                <Col md={6}>
                  <Field
                    id="week_overtime_hours"
                    name="week_overtime_hours"
                    type="number"
                    component={InputAtom}
                    placeholder="Hour"
                    layout={FORM_LAYOUT}
                    suffix="Hr"
                    min="1"
                    max="24"
                    disabled={!enableOvertime}
                  />
                </Col>
                <Col md={6} className="pl-3">
                  <Field
                    id="week_overtime_minutes"
                    name="week_overtime_minutes"
                    type="number"
                    component={InputAtom}
                    placeholder="Minute"
                    layout={FORM_LAYOUT}
                    suffix="Min"
                    min="0"
                    max="59"
                    disabled={!enableOvertime}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    component={SelectAtom}
                    id="week_day"
                    label="Work Week Start Day"
                    layout={FORM_LAYOUT}
                    name="week_day"
                    options={DAYS}
                    placeholder="Start Day"
                    simpleValue
                    disabled={disableOvertime ? true : !enableOvertime}
                  />
                </Col>
              </Row>
              <Field
                id="is_additional_overtime_applicable"
                type="checkbox"
                name="is_additional_overtime_applicable"
                label={
                  <div>
                    Additional overtime rules (if applicable)
                   <InfoGreyIconAtom id="addOver" className="pl-2 align-text-top" />
                    <UncontrolledTooltip placement="top" target="addOver">
                      Select if your state has daily overtime laws.
                   </UncontrolledTooltip>
                  </div>
                }
                component={CheckboxAtom}
                layout={FORM_LAYOUT}
                disabled={!enableOvertime}
              />
              <Row>
                <Col md={12}>
                  <Label>
                    <div>
                      Daily Overtime after (hours/work day)
                      <InfoGreyIconAtom id="dailyOver" className="pl-2 align-text-top" />
                      <UncontrolledTooltip placement="top" target="dailyOver">
                        After (x) hours and (y) minutes in a work day, Overtime hours will begin accumulating.
                      </UncontrolledTooltip>
                    </div>
                  </Label>
                </Col>
                <Col>
                  <Field
                    id="daily_overtime_hours"
                    name="daily_overtime_hours"
                    type="number"
                    component={InputAtom}
                    placeholder="Hour"
                    layout={FORM_LAYOUT}
                    suffix="Hr"
                    min="1"
                    max="24"
                    disabled={!(additionalOverTime && enableOvertime)}
                  />
                </Col>
                <Col>
                  <Field
                    id="daily_overtime_minutes"
                    name="daily_overtime_minutes"
                    type="number"
                    component={InputAtom}
                    placeholder="Minute"
                    layout={FORM_LAYOUT}
                    suffix="Min"
                    min="0"
                    max="59"
                    disabled={!(additionalOverTime && enableOvertime)}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col md={12}>
                <Label>
                  <div>
                    Daily Double OT after (hours/work day)
                    <InfoGreyIconAtom id="dailyD" className="pl-2 align-text-top" />
                    <UncontrolledTooltip placement="top" target="dailyD">
                      After (x) hours and (y) minutes in a work day, Double Overtime will begin accumulating.
                    </UncontrolledTooltip>
                  </div>
                </Label>
              </Col>
              <Col>
                <Field
                  id="daily_double_overtime_hours"
                  name="daily_double_overtime_hours"
                  type="number"
                  component={InputAtom}
                  placeholder="Hour"
                  layout={FORM_LAYOUT}
                  suffix="Hr"
                  min="1"
                  max="24"
                  disabled={!(additionalOverTime && enableOvertime)}
                />
              </Col>
              <Col>
                <Field
                  id="daily_double_overtime_minutes"
                  name="daily_double_overtime_minutes"
                  type="number"
                  component={InputAtom}
                  placeholder="Minute"
                  layout={FORM_LAYOUT}
                  suffix="Min"
                  min="0"
                  max="59"
                  disabled={!(additionalOverTime && enableOvertime)}
                />
              </Col>
            </Row>
            <Col className="pl-0 mb-3 mt-3 payroll-toggle">
              <Field
                id="is_cal_7_day_rule"
                name="is_cal_7_day_rule"
                type="boolean"
                label={
                  <div>
                    California 7 day rule
                    <InfoGreyIconAtom id="calRule" className="pl-2 align-text-top" />
                    <UncontrolledTooltip placement="top" target="calRule">
                      First 8 hours on the 7th consecutive day in the work week is overtime. Excess of 8 hours on the 7th consecutive day in the work week is double overtime.
                    </UncontrolledTooltip>
                  </div>
                }
                component={ToggleAtom}
                layout={FORM_LAYOUT}
                disabled={!(additionalOverTime && enableOvertime)}
              />
            </Col>
            <Field
              id="send_seven_message"
              type="checkbox"
              name="send_seven_message"
              label="Send text message on mobile"
              component={CheckboxAtom}
              layout={FORM_LAYOUT}
              disabled={!(enableOvertime && additionalOverTime && showMessage)}
            />
            {showMessage && enableOvertime && additionalOverTime ?
              <div className="mb-3">
                <Label>Confirmation message for the employee &nbsp;
                  <span role="button" onClick={() => toggle()} tabIndex="0" className={styles['message-link']}>
                    Click to Edit Message
                  </span>
                </Label>
                <div id="calMessage">
                  <Input value={californiaRuleMessage} disabled className="text-truncate" />
                  <UncontrolledTooltip placement="left" target="calMessage" >
                    {californiaRuleMessage}
                  </UncontrolledTooltip>
                </div>
              </div>
              : null
            }
            <p className={!props.newLayout && styles['payroll-p-tag']}>
              First 8 hours on the 7<sup>th</sup>  consecutive day in the work week is overtime.
              <br />Excess of 8 hours  on the 7<sup>th</sup> consecutive day in the work week is double overtime.
            </p>
          </Collapse>
          {((!props.newLayout && (props.params.id || props.payroll_id)) || (props.newLayout && props.payroll_id)) &&
            <div>
              <div className="mt-3">
                <b>Meal Break Preferences</b>
                <span onClick={() => toggleMealBreakAccordian()} role="button" tabIndex="0" className="remove-focus" >
                  <img className='ml-2 mr-2' src={mealBreakInfo ? caretArrowUp : caretArrowDown} alt='' height="10" width="10" />
                </span>
                <hr className={`${styles['horizontal-line']} mt-2 mb-4`} />
              </div>
              <Collapse isOpen={mealBreakInfo}>
                <div className="toggleCard">
                  {/*<Field
                     id="meal_period_duration"
                     type="number"
                     name="meal_period_duration"
                     label={
                      <div>
                         Meal Break Duration (in minutes)
                         <InfoGreyIconAtom id="mealPeriod" className="pl-2 align-text-top" />
                         <UncontrolledTooltip placement="right" target="mealPeriod">
                            The length of time an employee will be unable to punch in after choosing to start a meal break in the app.
                         </UncontrolledTooltip>
                      </div>
                    }
                    component={InputAtom}
                    layout={FORM_LAYOUT}
                />*/}
                </div>
                <div className="filter">
                  <Button
                    className="btn-job-primary"
                    onClick={() => toggleAddRuleModal()}
                  >
                    Add Rule
                </Button>
                </div>
                {((!props.newLayout && (props.params.id || props.payroll_id)) || (props.newLayout && props.payroll_id))  &&
                  <MealCardComponent
                    mealRuleList={mealRuleList}
                    openEditForm={props.openEditForm}
                    openDeleteFormModal={props.openDeleteFormModal}
                  />
                }
              </Collapse>
            </div>
          }
          <Row>
            <Col className="pt-3">
              <Button
                type="submit"
                color="secondary"
                className="float-sm-right my-4"
                size={FORM_LAYOUT.size}
              >
                Submit <ButtonLoaderAtom active={submitting} />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Name';
  if (values.name && !values.name.trim().length) errors.name = 'Enter Name';
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (values.is_overtime_applicable && !values.week_overtime_hours && !values.week_overtime_minutes) errors.week_overtime_hours = 'Enter weekly overtime duration';
  if (values.is_overtime_applicable && values.week_overtime_hours && !values.week_day) errors.week_day = 'Enter Work Week Start Day';

  const dailyOvertime = moment.duration(+values.daily_overtime_hours || 0, 'hours').asSeconds() +
    moment.duration(+values.daily_overtime_minutes || 0, 'minutes').asSeconds();
  const dailyDoubleOvertime = moment.duration(+values.daily_double_overtime_hours || 0, 'hours').asSeconds() +
    moment.duration(+values.daily_double_overtime_minutes || 0, 'minutes').asSeconds();
  if (values.is_additional_overtime_applicable && values.is_overtime_applicable && dailyDoubleOvertime <= dailyOvertime) errors.daily_double_overtime_hours = "Daily Double Overtime after should be greater than Daily Overtime After.";


  const weeklyValue = moment.duration(+values.week_overtime_hours || 0, 'hours').asSeconds() +
    moment.duration(+values.week_overtime_minutes || 0, 'minutes').asSeconds();
  const wavierValue = moment.duration(+values.wavier_hour || 0, 'hours').asSeconds() +
    moment.duration(+values.wavier_minute || 0, 'minutes').asSeconds();
  const firstMealThreshold = moment.duration(+values.hour || 0, 'hours').asSeconds() +
    moment.duration(+values.minute || 0, 'minutes').asSeconds();

  if (values.daily_split && !values.work_day_start_time) errors.work_day_start_time = 'Enter Work Day Start Time';
  if ((values.wavier_hour > 0 || values.wavier_minute > 0) && (values.hour > 0 || values.minute > 0) && wavierValue <= firstMealThreshold) errors.wavier_hour = 'Wavier Period should be greater than Max Time Before Meal Start.'
  if ((values.week_overtime_hours > 0 || values.week_overtime_minutes) && (weeklyValue < dailyDoubleOvertime)) errors.week_overtime_hours = "Weekly Overtime can not be less than Daily Double Overtime"
  return errors;
};

OvertimeRuleComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  createOrUpdate: PropTypes.func.isRequired,
  overTime: PropTypes.bool,
  additionalOverTime: PropTypes.bool,
  californiaRuleMessage: PropTypes.string.isRequired,
  showMessage: PropTypes.bool,
  initialValues: PropTypes.object,
};

const payrollForm = reduxForm({
  validate,
})(OvertimeRuleComponent);

const payrollSelector = formValueSelector('form1');
const advancedPayrollSelector = formValueSelector('form2');


export default connect(
  (state, props) => {
    const overTime = props.form == 'form1' ?  payrollSelector(state, 'is_overtime_applicable') : advancedPayrollSelector(state, 'is_overtime_applicable');
    const enableOvertime = props.form == 'form1' ? payrollSelector(state, 'is_overtime_applicable') : advancedPayrollSelector(state, 'is_overtime_applicable');
    const additionalOverTime = props.form == 'form1' ?  payrollSelector(state, 'is_additional_overtime_applicable')  : advancedPayrollSelector(state, 'is_additional_overtime_applicable');
    const showMessage = props.form == 'form1' ? payrollSelector(state, 'is_cal_7_day_rule') : advancedPayrollSelector(state, 'is_cal_7_day_rule' ) ;
    const dailySplit = props.form == 'form1' ?  payrollSelector(state, 'daily_split') : advancedPayrollSelector(state, 'daily_split');
    return { overTime, additionalOverTime, showMessage, dailySplit, enableOvertime };
  },
)(payrollForm);