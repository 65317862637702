import React from 'react';
import CloseImage from './Close-Cross.png';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';
import DateTimeComponent from 'components/common/DateTimeSelectComponent';
import TimeComponent from 'components/common/TimeSelectComponent';
import { toTitleCase } from 'helpers/utils';


import styles from './styles.module.scss';

class SurveySliderModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    
  }

  render() {
    const {hideSurveyModal, data , isLoading , label, date , name} = this.props;
    return (
      <div className={styles['survey-slider-container']}>
        <div>
          <h4 className="d-inline-block mt-3 ml-3 mb-1">Survey</h4>
          <img src={CloseImage} className={`${styles['close-icon']} mt-4 mr-3`} height="13" width="13" onClick={() => hideSurveyModal()}/>
        </div>
        <hr className={`${styles['survey-horizontal-line']} bg-primary`}/>
        <div className={styles['survey-slider-content']}>
          <div className="text-center" style={{fontSize : '15px'}}>     
            <span className={styles['survey-employee-name']}>{toTitleCase(name)}</span>
            <span>({date})</span>
          </div>
          <div className={`${styles['custom-label']} ${styles['survey-employee-name']}`}>{label}</div>
          {data && data.length ? data.map((item,index)=>
            <div className="ml-3 mr-3 mb-3">
              {(item.definition == "SINGLE_SELECT" || item.definition == 'single_select') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                </div>
              }
              {(item.definition == 'MULTI_SELECT' || item.definition == 'multi_select') &&
                <div>
                  <MultiSelectComponent data={item} index={index}/>
                </div>
              }
              {(item.definition == 'FREE_TEXT' ||  item.definition == 'free_text') &&
                <div>
                  <FreeSelectComponent data={item} index={index}/>
                </div>
              }
              {(item.definition == 'BOOLEAN' || item.definition == 'boolean') &&
                <div>
                  <SingleSelectComponent data={item} index={index}/>
                </div>
              }
              {(item.definition == 'DATE_TIME_SELECT' || item.definition == 'date_time_select') &&
                <div>
                  <DateTimeComponent
                      data={item}
                      index={index}
                      dateFormat={this.props.dateFormat}
                      timeFormat = {this.props.timeFormat}
                      timeFormat24Hrs={this.props.timeFormat24Hrs}
                    />
                </div>
              }
              {(item.definition == 'TIME_SELECT' || item.definition == 'time_select') &&
                <div>
                  <TimeComponent
                      data={item}
                      index={index}
                      dateFormat={this.props.dateFormat}
                      timeFormat = {this.props.timeFormat}
                      timeFormat24Hrs={this.props.timeFormat24Hrs}
                    />
                </div>
              }      
            </div>
            )
            :
            <section><div className={styles['no-template-records']}>No Records Found</div></section>
          }
        </div>
      </div>                
    );
  }
};


export default SurveySliderModalComponent;
