import React from "react";
import queryString from "query-string";
import Button from "reactstrap/lib/Button";

import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';
import * as UserDucks from 'ducks/accounts/user';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import Field from "redux-form/lib/Field";

import reduxForm from "redux-form/lib/reduxForm";
import Form from "reactstrap/lib/Form";
import moment from "moment";
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from "prop-types";
import formValueSelector from "redux-form/lib/formValueSelector";
import connect from "react-redux/lib/connect/connect";
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterFormComponent from 'components/common/FilterForm';

import DateTimePickerAtom from "atoms/DateTimePicker";
import { FILTER_FORM_LAYOUT } from "constants/layout";
import FilterDropdownComponent from 'components/common/FilterDropdown';
import {STATUS_OPTIONS} from 'constants/forms/records';


class FormResponceFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onDepartmentSelect = this.onDepartmentSelect.bind(this);
    this.onTeamSelect = this.onTeamSelect.bind(this);
    this.onStatusSelect = this.onStatusSelect.bind(this);
  }

  componentDidMount() {
    Promise.all([
      this.props.DepartmentDucks.getAllDepartments({
        paginate: false,
        get_active: true,
        get_active_teams: true,
      }),
      this.props.TeamDucks.getAllTeams({
        paginate: false,
        get_active: true
      }),
    ]);
  }

  onDepartmentSelect(id) {
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const newQuery = { ...query };

    delete newQuery.team_id;

    this.props.TeamDucks.getAllTeams({ department_id: id, paginate: false });

    history.push({ pathname,
      search: queryString.stringify({...newQuery,
        department_id: id,
    })});
  }

  onTeamSelect(id){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    history.push({ pathname,
      search: queryString.stringify({...query,
        team_id: id,
    })});
  }

  onStatusSelect(status){
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    

    history.push({ pathname,
      search: queryString.stringify({...query,
        status: status,
    })});
  }



  submit(data) {
    const {
      router: { history },
    } = this.context;
    const {
      location: { pathname, query },
    } = this.props;
    const { page, ...rest } = query;
    const detail = { ...data };
    const { page: pageNo, ...restDetail } = detail;
    restDetail.start_date = moment(data.resp_start_date).format("YYYY-MM-DD");
    restDetail.end_date = moment(data.resp_end_date).format("YYYY-MM-DD");
    history.push({
      pathname,
      search: queryString.stringify({ ...rest, ...restDetail }),
    });
  }



  render() {
    const { dateFormat, handleSubmit, handleExport, isExporting, departmentAll, teamAll, userExtraData, location } = this.props;
        let departmentOptions = [];
    let teamOptions = [];  
    const query = queryString.parse(location.search);
    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));
      if (!userExtraData.department){
        departmentOptions.splice(0, 0, { value: "", label: "All Departments" })
      }
      teamOptions = teamAll && teamAll.map(item => ({
        value: item.id, label: item.name,
      }));
      if (!userExtraData.team) {
        teamOptions.splice(0, 0, { value: "", label: "All Teams" })
      }
    }

    return (
      <div className="ml-3 mr-3 pt-0 ">
        <Row className="filter">
          <Col className="  mb-2    d-md-flex justify-content-between">
            <div>
              <div className="mb-2">
              <div id='status' className="d-inline-block department-dropdown">
                  <FilterDropdownComponent
                    paramKey="status"
                    location={location}
                    options={STATUS_OPTIONS}
                   
                    onChange={(val) => this.onStatusSelect(val)}
                  />
                  <UncontrolledTooltip placement="top" target = 'status'>
                    Select Status
                  </UncontrolledTooltip>
                </div>
                {departmentOptions && departmentOptions.length > 0 &&
                  <div id ="department" className="d-inline-block department-index department-dropdown">
                    <FilterDropdownComponent
                      paramKey="department_id"
                      location={location}
                      options={departmentOptions}
                      onChange={(val) => this.onDepartmentSelect(val)}
                    />
                    <UncontrolledTooltip placement="top" target="department">
                      Select Department
                    </UncontrolledTooltip>
                  </div>
                }
                {teamOptions && teamOptions.length > 0 &&
                <div id ="team" className="d-inline-block department-dropdown">
                  <FilterDropdownComponent
                    paramKey="team_id"
                    location={location}
                    options={teamOptions}
                    onChange={(val) => this.onTeamSelect(val)}
                  />
                  <UncontrolledTooltip placement="top" target="team">
                    Select Team
                  </UncontrolledTooltip>
                </div>
                }
                            </div>
              <Form
                inline
                onSubmit={handleSubmit(this.submit)}
                className="report-filter date-filter-width survey-status p-0"
              >
                <Field
                  id="resp_start_date"
                  name="resp_start_date"
                  placeholder="Start Date"
                  component={DateTimePickerAtom}
                  options={{ dateFormat }}
                />
                <Field
                  id="resp_end_date"
                  name="resp_end_date"
                  placeholder="End Date"
                  component={DateTimePickerAtom}
                  options={{ dateFormat }}
                />
                <Button
                  color="accent"
                  style={{ marginBottom: "24px", height: "34px" }}
                  size={FILTER_FORM_LAYOUT.size}
                  className="pr-4 pl-4"
                >
                  Search
                </Button>
              </Form>
            </div>
            <div className="d-flex justify-content-center">
              <div className="d-inline-block override-cross-bulk mr-2">
                <FilterFormComponent
                  location={this.props.location}
                  placeholder="id,name,email,title,phone or tag"
                  initialValues={query}
                />
              </div>
              <Button 
                style={{ marginBottom: "24px", height: "34px" }}
                onClick={handleExport}
                disabled={isExporting}
                >
                Export 
                <ButtonLoaderAtom active={isExporting} />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const validate = (values) => {
  const errors = {};
  const formattedStartDate = moment(values.resp_start_date);
  const formattedEndDate = moment(values.resp_end_date);
  if (!values.resp_start_date) errors.resp_start_date = "Select Start Date";
  else if (!values.resp_end_date) errors.resp_end_date = "Select End Date";
  else if (formattedStartDate > formattedEndDate)
    errors.resp_start_date = "Start date should be less than end date.";
  return errors;
};

FormResponceFilterComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const FormResponceFilter = reduxForm({
  form: "FormResponceFilterComponent",
  validate,
})(FormResponceFilterComponent);

const selector = formValueSelector("FormResponceFilterComponent");

const mapActionToProps = dispatch => ({
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

export default connect((state) => {
  const job = selector(state, "job");
  const task = selector(state, "task");
  return { 
    job, 
    task,
    departmentAll: DepartmentDucks.departmentAll(state),
    teamAll: TeamDucks.teamAll(state),
    userExtraData: UserDucks.userExtraData(state),
  };
}, mapActionToProps)(FormResponceFilter);
