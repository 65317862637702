import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';

import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';


import EmployeeProficiencyReportFilterFormComponent from "components/reports/employeeProficiencyReport/FilterForm";
import FilterDropdownComponent from 'components/common/FilterDropdown';


class EmployeeProficiencyReportFilterSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { dateFormat, location, departmentAll, userExtraData, teamAll, onDepartmentSelect, userCompany } = this.props;
    const { query } = location;
    const formattedDates = {};
    let departmentOptions = [];
    let teamOptions = [];

    if (departmentAll && departmentAll.length) {
      departmentOptions = departmentAll && departmentAll.map(item => ({
        value: item.id, label: item.name,
      }));
    }

    if (!userExtraData.department) {
      departmentOptions.splice(0, 0, { value: "", label: "All Departments" })
    }

    if (teamAll && teamAll.length) {
      teamOptions = teamAll && teamAll.map(item => ({
        value: item.id, label: item.name,
      }));
    }

    if (!userExtraData.team) {
      teamOptions.splice(0, 0, { value: "", label: "All Teams" })
    }

    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    return (
      <Row className="report-filter ml-3 mr-4">
        <Col md={10} className="px-0">
          <section>
            {departmentOptions.length > 0 &&
              <div id="department" className="d-inline-block department-index department-dropdown reportdropdown reportdropdown-override">
                <FilterDropdownComponent
                  paramKey="department_id"
                  location={location}
                  options={departmentOptions}
                  onChange={(val) => onDepartmentSelect(val)}
                />
                <UncontrolledTooltip placement="top" target="department">
                  Select Department
                </UncontrolledTooltip>
              </div>
            }
            {teamOptions.length > 0 &&
              <div id="team" className="d-inline-block department-dropdown reportdropdown reportdropdown-override">
                <FilterDropdownComponent
                  paramKey="team_id"
                  location={location}
                  options={teamOptions}
                />
                <UncontrolledTooltip placement="top" target="team">
                  Select Team
                </UncontrolledTooltip>
              </div>
            }
          </section>
          <EmployeeProficiencyReportFilterFormComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={
              {
                ...query,
                company_id: userCompany.id,
                ...formattedDates,
                start_date: query.start_date ? moment(query.start_date).toISOString() : moment(new Date()).subtract(1, 'months').toISOString(),
                end_date: query.end_date ? moment(query.end_date).toISOString() : moment(new Date()).toISOString()
                
              }
            }
            company_id={userCompany.id}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
  departmentAll: DepartmentDucks.departmentAll(state),
  userExtraData: UserDucks.userExtraData(state),
  teamAll: TeamDucks.teamAll(state),
  userCompany: UserDucks.userCompany(state),
});

EmployeeProficiencyReportFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};


export default connect(mapStateToProps)(EmployeeProficiencyReportFilterSection); 
