const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/pto-policy/time-off/list', label: 'Time Off Request' },
];

export const NAV = {
PTOSettingPage: {
    title: 'Time off Policies ',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Settings' },
    // ],
    description: 'Manage your time off policies settings',
    parent: '/dashboard',
  },
  TimeOffListPage: {
    title: 'Time off Requests ',
     breadcrumb: [
       ...baseNav,
       { label: 'List' },
     ],
    description: 'View, Approve, Reject or apply for Time Off Request of Employees ',
    parent: '/dashboard',
  },
  AddPTOPoliciesPage: {
    title: 'Add Policy ',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Settings' },
    // ],
    description: 'Add New Policy',
    parent: '/pto-policy/list',
  },
  EditPTOPoliciesPage: {
    title: 'Edit Policy ',
    // breadcrumb: [
    //   ...baseNav,
    //   { label: 'Settings' },
    // ],
    description: 'Edit and View Policy',
    parent: '/pto-policy/list',
  },
  PtoBulkUploadPage: {
    title: 'Pto Bulk Upload',
    breadcrumb: [
      ...baseNav,
      { label: 'Bulk Upload' },
    ],
    parent: '/pto-policy/list',
    description: 'Upload employees pto-policy',
  }
}

export default NAV