import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_COUNTRIES = 'vendors/address/GET_COUNTRIES';
const GET_COUNTRIES_FULFILLED = 'vendors/address/GET_COUNTRIES_FULFILLED';
const GET_STATES = 'vendors/address/GET_STATES';
const GET_STATES_FULFILLED = 'vendors/address/GET_STATES_FULFILLED';
const GET_MAILING_STATES = 'vendors/address/GET_MAILING_STATES';
const GET_MAILING_STATES_FULFILLED = 'vendors/address/GET_MAILING_STATES_FULFILLED';


export function getCountries() {
  return {
    type: GET_COUNTRIES,
    payload: axios.get(`${URLS.COUNTRIES}`),
  };
}

export function getStates(params) {
  return {
    type: GET_STATES,
    payload: axios.get(`${URLS.STATES}`, {params}),
  };
}

export function getMailingStates(params) {
  return {
    type: GET_MAILING_STATES,
    payload: axios.get(`${URLS.STATES}`, {params}),
  };
}

const defaultState = {
  countries: [],
  states: [],
  mailingStates: [],
};

function GET_COUNTRIES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    countries: action.payload,
  });
}


function GET_STATES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    states: action.payload,
  });
}

function GET_MAILING_STATES_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    mailingStates: action.payload,
  });
}

const handlers = {
  [GET_COUNTRIES_FULFILLED]: GET_COUNTRIES_FULFILLED_REDUCER,
  [GET_STATES_FULFILLED]: GET_STATES_FULFILLED_REDUCER,
  [GET_MAILING_STATES_FULFILLED]: GET_MAILING_STATES_FULFILLED_REDUCER,
};


const addressSelector = state => state.vendors.address;


export const countriesList = createSelector(
  addressSelector,
  instance => instance.countries,
);

export const statesList = createSelector(
  addressSelector,
  instance => instance.states,
);

export const mailingStatesList = createSelector(
  addressSelector,
  instance => instance.mailingStates
);

export default createReducer(defaultState, handlers);
