import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as SubCompanyDucks from 'ducks/vendors/subCompany';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import CompanyFilterSection from 'sections/vendors/subCompanyList/Filter';
import CompanyListSection from 'sections/vendors/subCompanyList/List';

import { NAV } from 'constants/vendors';

/**
 * CompanyListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link CompanyFilterSection}
 *    - {@link CompanyListSection}
 *
 * Fetch Needs:
 *    None
 */
class CompanyListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {

    const cookieKey = cookie.load("vendor_company_setting");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_company_setting", !isInfoOpen);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load('sub_company_page_size');

    return Promise.all([
      this.props.SubCompanyDucks.getSubCompanies({...params, page_size: pageSize}),
      this.props.InfoDucks.getInformations({route: 'vendor.company_setting'}),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  }

  loadData(){
    const { location: { query } } = this.props;
    this.fetchData(query);
  }

  render() {
    const { userRole, isSubCompany, isOrganizationVisible, companyPermissions, infoList} = this.props;
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: '/vendor/role-setting', label: 'Roles' },
      !isSubCompany && isOrganizationVisible && (!userRole || userRole === 'Client Admin')
        ? { href: '/vendor/sub-company', label: 'Manage Branches' }
        : null,
      isOrganizationVisible && companyPermissions.manage_organization
        ? { href: '/vendor/department', label: 'Departments' }
        : null,
    ];

    return (
      <SSTCFLayout
        navInfo={NAV.CompanyListPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <CompanyFilterSection />
        <CompanyListSection isLoading={isLoading} />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userRole: UserDucks.userRole(state),
  isSubCompany: UserDucks.isSubCompany(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  SubCompanyDucks: bindActionCreators(SubCompanyDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

CompanyListPage.propTypes = {
  location: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  SubCompanyDucks: PropTypes.object.isRequired,
  companyPermissions: PropTypes.object.isRequired,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

CompanyListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CompanyListPage);
