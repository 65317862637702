import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import EditIcon from 'components/jobs/jobGeofence/Edit.png';
import SortHeaderComponent from 'components/common/SortHeader';

/**
 * GeoFenceListPage -> GeoFenceListSection -> GeoFenceTableComponent
 *
 * Props:
 *    - Geo Fence list
 */
const GeoFenceTableComponent = (props, context) => {
  const { data } = props;
  return (
    <div className="ml-3 mr-4">
      <Table responsive hover size="sm" className="geo_fence_table">
      <thead>
        <tr>         
          <th className="text-center">Action</th>
          <SortHeaderComponent ordering="name" className="text-left">Name</SortHeaderComponent>
          <SortHeaderComponent ordering="shape_type" className="text-left">Type</SortHeaderComponent>
          <th className="text-left">Description</th>
          <SortHeaderComponent ordering="create_date" className="text-left">Create Date</SortHeaderComponent>
          <th className="text-left">Address</th>
          <th className="text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        {data.results && data.results.map(item => (
          <tr key={item.id}>
          <td className="text-center">
              <span  onClick = {() =>
                    props.updateStatus(item)
                  }>
                  <img alt="" src={EditIcon} height="15" width="15"
                    style={{cursor: 'pointer'}}
                  />
                </span>
            </td>
            <td title={item.name} className="text-left">{item.name}</td>
            <td title={item.shape_type} className="text-left">{item.shape_type}</td>
            <td title={item.description} className="text-left">{item.description}</td>
            <td title={item.create_date_display} className="text-left">{item.create_date_display}</td>
            <td title={item.address} className="text-left">{item.address}</td> 
            <td className="text-left" title={item.is_active ? 'Active' : 'Inactive'}>
              {item.is_active ? 'Active' : 'Inactive'}
            </td>
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
      </Table>
    </div>
  );
};

GeoFenceTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

GeoFenceTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default GeoFenceTableComponent;
