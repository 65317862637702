import EventEmitter from "lib/event-emitter";
import GraniteError from "lib/granite-error";

const EVENT_TYPES = {
  GET_APPLICANTS_LIST_SUCCESS: "GET_APPLICANTS_LIST_SUCCESS",
  GET_APPLICANTS_LIST_FAILURE: "GET_APPLICANTS_LIST_FAILURE",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  DEPARTMENT_LIST: "DEPARTMENT_LIST",
  TEAM_LIST: "TEAM_LIST",
  ERROR_TOAST: "ERROR_TOAST",
  GET_USER_PREFERENCE: 'GET_USER_PREFERENCE',
  SUBCOMPANY_LIST: "SUBCOMPANY_LIST",
  GET_APPLICANT_DETAILS_SUCCESS: "GET_APPLICANT_DETAILS_SUCCESS",
  GET_APPLICANT_DETAILS_FAILURE: "GET_APPLICANT_DETAILS_FAILURE",
  GET_TAGS_LIST : 'GET_TAGS_LIST',
  NEW_TAG_ADDED: 'NEW_TAG_ADDED',
  GET_COUNTRY_LIST: 'GET_COUNTRY_LIST',
  GET_STATE_LIST: 'GET_STATE_LIST',
  GET_ROLE_LIST: 'GET_ROLE_LIST',
  STATUS_CHANGED_SUCCESS: "STATUS_CHANGED_SUCCESS",
  STATUS_CHANGED_FAILURE: "STATUS_CHANGED_FAILURE",
};

class listUseCase {
  ApplicantsAPIGateway = null;

  constructor(ApplicantsAPIGateway) {
    this.ApplicantsAPIGateway = ApplicantsAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  toString() {
    return "listUseCase";
  }

  async getDepartmentList(params) {
    this.departmentList = await this.ApplicantsAPIGateway.getDepartmentList({
      ...params,
      paginate: false,
    });
    this.departmentList.splice(0, 0, { id: "", name: "All Departments" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.DEPARTMENT_LIST,
      departmentList: this.departmentList,
    });
  }

  async getTeamList(params) {
    this.teamList = await this.ApplicantsAPIGateway.getTeamList({
      ...params,
      paginate: false,
    });
    this.teamList.splice(0, 0, { id: "", name: "All Teams" });
    this.eventEmitter.emit({
      type: EVENT_TYPES.TEAM_LIST,
      teamList: this.teamList,
    });
  }

  async getApplicantsList(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });

    try {
      this.ApplicantsList = await this.ApplicantsAPIGateway.getApplicantsList({
        ...params,
        paginate: true,
      });

      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_APPLICANTS_LIST_SUCCESS,
        data: this.ApplicantsList,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.GET_APPLICANTS_LIST_FAILURE,
        data: error
      });
    }

    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async getApplicantDetails(applicantId){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.applicantData = await this.ApplicantsAPIGateway.getApplicantDetails(applicantId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_APPLICANT_DETAILS_SUCCESS,
      data: this.applicantData,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }

  async getUserPreference(params) {
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.userPreference = await this.ApplicantsAPIGateway.getUserPreference(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_USER_PREFERENCE,
      data: this.userPreference
    });
    return this.userPreference
  }


  async getAllSubcompanies(){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.subCompanies = await this.ApplicantsAPIGateway.getAllSubCompanies({ paginate: false });
    this.eventEmitter.emit({
      type: EVENT_TYPES.SUBCOMPANY_LIST,
      data: this.subCompanies,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.HIDE_LOADER,
    });
  }


  async createApplicant(params){
    this.applicant = await this.ApplicantsAPIGateway.createApplicant(params);
    return this.applicant.data;
  }

  async updateApplicant(params){
    this.applicant = await this.ApplicantsAPIGateway.updateApplicant(params);
    return this.applicant.data;
  }

  async getEmployeeTags(data){
    this.tagsList = await this.ApplicantsAPIGateway.getEmployeesTags(data);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_TAGS_LIST,
      data: this.tagsList,
    });
  }

  async addEmployeeTag(data){
    let errors = { };
    if (data.name && data.name.length > 25){
      errors.name = 'Tag name cannot have more than 25 characters';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }

    await this.ApplicantsAPIGateway.addEmployeeTags(data);
    await this.getEmployeeTags({paginate:false, tag_type: 3});
    this.eventEmitter.emit({
      type: EVENT_TYPES.NEW_TAG_ADDED,
    });
  }

  async deleteTags(id, tags){
    return this.ApplicantsAPIGateway.deleteMarkedTags(id, {tag_collection: tags});
  }

  async updateTagName(id, tags){
    return this.ApplicantsAPIGateway.updateTagName(id, tags);
  }

  async getCountryList(){
    this.countryList = await this.ApplicantsAPIGateway.getCountryList();
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_COUNTRY_LIST,
      data: this.countryList,
    });
  }

  async getStateList(params){
    this.stateList = await this.ApplicantsAPIGateway.getStateList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_STATE_LIST,
      data: this.stateList,
    });
  }

  async getRoleList(params){
    this.roleList = await this.ApplicantsAPIGateway.getRoleList(params);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_ROLE_LIST,
      data: this.roleList,
    });
  }

  async resetPassword(data){
    await this.ApplicantsAPIGateway.resetPassword(data);
  }

  async updateStatus(data){
    try {
      await this.ApplicantsAPIGateway.updateStatus(data);
      this.eventEmitter.emit({
        type: EVENT_TYPES.STATUS_CHANGED_SUCCESS,
      });
    } catch (error) {
      this.eventEmitter.emit({
        type: EVENT_TYPES.STATUS_CHANGED_FAILURE,
      });
    }
  }
}

export default listUseCase;
