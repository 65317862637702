import combineReducers from 'redux/lib/combineReducers';

import timecard from './timecard';
import dailyTimecard from './dailyTimecard';
import adjustmentTimecard from './adjustmentTimecard';

export default combineReducers({
  timecard,
  dailyTimecard,
  adjustmentTimecard,
});
