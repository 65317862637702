import React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { UncontrolledTooltip } from 'reactstrap';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';
import TimeComponent from 'components/common/TimeSelectComponent';
import SectionLoaderAtom from 'atoms/SectionLoader';
import { toTitleCase } from 'helpers/utils';

import DateTimeComponent from 'components/common/DateTimeSelectComponent';

import styles from './styles.module.scss';

const detailForm = (props) => {
  const { data , isLoading , label, date , name, slider } = props;
  if(isLoading) return <div style={{height : '70vh', overflowY : 'hidden'}}><SectionLoaderAtom active /></div>;
  return (
    <div style={{height : '70vh', overflow: 'auto'}}>
      <div className="text-center" style={{fontSize : '15px'}}>     
        <span className={styles['survey-employee-name']}>{toTitleCase(name)}</span>
        <span>({date})</span>
      </div>
      <div className={`${styles['custom-label']} ${styles['survey-employee-name']}`}>{label}</div>
      {data && data.length ? data.map((item,index)=>
        <div className="mr-2">
          {(item.definition == 'SINGLE_SELECT' || item.definition == 'single_select') &&
            <div>
              <SingleSelectComponent data={item} index={index} mandatory={item.mandatory_question}/>
              <hr className="mt-0 mb-2"/>
            </div>
          }
          {(item.definition == 'MULTI_SELECT' || item.definition == 'multi_select') &&
            <div>
              <MultiSelectComponent data={item} index={index} mandatory={item.mandatory_question}/>
              <hr className="mt-0 mb-2"/>
              </div>
          }
          {(item.definition == 'FREE_TEXT' || item.definition == 'free_text') &&
            <div>
              <FreeSelectComponent data={item} index={index} mandatory={item.mandatory_question}/>
              <hr className="mt-0 mb-2"/>
            </div>
          }
          {(item.definition == 'BOOLEAN' || item.definition == 'boolean') &&
            <div>
              <SingleSelectComponent data={item} index={index} mandatory={item.mandatory_question}/>
              <hr className="mt-0 mb-2"/>
            </div>
          }
          {(item.definition == 'DATE_TIME_SELECT' || item.definition == 'date_time_select') &&
            <div>
              <DateTimeComponent
                data={item}
                index={index}
                dateFormat={props.dateFormat}
                timeFormat = {props.timeFormat}
                timeFormat24Hrs={props.timeFormat24Hrs}
                mandatory={item.mandatory_question}
                slider={slider}
              />
              <hr className="mt-0 mb-2"/>
            </div>
          }
          {(item.definition == 'TIME_SELECT' || item.definition == 'time_select') &&
            <div>
              <TimeComponent
                data={item}
                index={index}
                dateFormat={props.dateFormat}
                timeFormat = {props.timeFormat}
                timeFormat24Hrs={props.timeFormat24Hrs}
                mandatory={item.mandatory_question}
                slider={slider}
              />
              <hr className="mt-0 mb-2"/>
            </div>
          }
        </div>
        )
        :
        <section><div className={styles['no-template-records']}>No Records Found</div></section>
      }
    </div>
  );
};

export default detailForm;

