import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user'
import * as DepartmentDucks from 'ducks/vendors/department';
import * as TeamDucks from 'ducks/vendors/team';
import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as InfoDucks from 'ducks/info/info';

import SSTCFLayout from 'layouts/SSTCF';
import VendorDepartmentFilterSection from 'sections/vendors/departmentList/Filter';
import VendorDepartmentListSection from 'sections/vendors/departmentList/List';

import { NAV } from 'constants/vendors';

import { MSGS } from 'constants/vendors';
/**
 * VendorDepartmentListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link VendorDepartmentFilterSection}
 *
 * Fetch Needs:
 *    None
 */
class VendorDepartmentListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true };
    this.deleteDepartment = this.deleteDepartment.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.deleteTeam = this.deleteTeam.bind(this);
    this.saveDepartmentTeamOrderPreference = this.saveDepartmentTeamOrderPreference.bind(this);
    this.getDepartmentIds = this.getDepartmentIds.bind(this);
    this.getTeamIds = this.getTeamIds.bind(this);
    this.toggleIsLoading = this.toggleIsLoading.bind(this)
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_department_list");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;

    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("vendor_department_list", !isInfoOpen);
  }

  fetchData(params) {
    this.setState({ isLoading: true });
    const pageSize = cookie.load("department_page_size");
    const pageTeamSize=cookie.load("team_page_size");
    Promise.all([
      this.props.InfoDucks.getInformations({route: 'vendor.department_setting'}),
      this.props.DepartmentDucks.getDepartments({...params, page_size:pageSize}),
      this.props.DepartmentDucks.getDepartmentIds(),
      this.props.TeamDucks.getTeamIds(),
    ])
    .then(() => this.setState({ isLoading: false }))
    .catch(() => this.setState({ isLoading: false })); 
  }

  loadData(){
    const { location: { query } } = this.props;
    this.props.PayrollSettingDucks.getAllPayrollSettings({paginate:false});
    this.props.PayrollDucks.getAllPayrolls({ paginate: false, is_active: true, default: false,  });
    this.fetchData(query);
  }

  deleteDepartment(department) {
    this.setState({ isLoading: true });
    const { location: { query } } = this.props;
    this.props.DepartmentDucks.deleteDepartment({id: department.id})
    .then(() => {
      toast.success(MSGS.VENDOR_DEPARTMENT_DELETE_SUCCESS);
      this.fetchData(query);
      //this.setState({ isLoading: false });
    })
    .catch((err) => {
      this.setState({ isLoading: false });
      console.log(err);
      toast.error(err.department);
      this.fetchData(query);
      });
    }

    toggleIsLoading(){
      this.setState({isLoading:false})
    }
    getDepartmentIds(){
      return this.props.DepartmentDucks.getDepartmentIds();
    }

    getTeamIds(){
      this.props.TeamDucks.getTeamIds();
    }

    deleteTeam(team) {
      this.setState({isLoading: true});
       const { location:{query} } = this.props;
       this.props.TeamDucks.deleteTeam({id: team.id})
       .then(() =>{
        this.fetchData(query);
        this.setState({isLoading: false});
       })
       .catch((err) => {
        toast.error(err.team);
        this.setState({isLoading: false});
        this.fetchData(query);
      });
    }

  saveDepartmentTeamOrderPreference(order, preferenceType){
    return this.props.UserDucks.saveUserOrderPreference({data:{sequence: order} , preference_type: preferenceType});
  }

  render() {
    const { userRole, isSubCompany, isOrganizationVisible, companyPermissions, infoList } = this.props;
    const { isLoading, isInfoOpen } = this.state;
    const tabs = [
      { href: '/vendor/role-setting', label: 'Roles' },
      !isSubCompany && isOrganizationVisible && (!userRole || userRole === 'Client Admin')
        ? { href: '/vendor/sub-company', label: 'Manage Branches' }
        : null,
      isOrganizationVisible && companyPermissions.manage_organization
        ? { href: '/vendor/department', label: 'Departments' }
        : null,
    ];

    return (
      <SSTCFLayout
        navInfo={NAV.DepartmentListPage}
        tabItems={tabs.filter(item => item)}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <VendorDepartmentFilterSection />
        <VendorDepartmentListSection 
          isLoading={isLoading}
          deleteDepartment = {this.deleteDepartment}
          deleteTeam= {this.deleteTeam}
          saveDepartmentTeamOrderPreference={this.saveDepartmentTeamOrderPreference}
          departmentIdsList={this.props.departmentIdsList}
          teamIdsList={this.props.teamIdsList}
          getTeamIds = {this.getTeamIds}
          getDepartmentIds = {this.getDepartmentIds}
          toggleIsLoading = {this.toggleIsLoading}
        />
      </SSTCFLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userRole: UserDucks.userRole(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  isSubCompany: UserDucks.isSubCompany(state),
  companyPermissions: UserDucks.companyPermissions(state),
  infoList: InfoDucks.infoList(state),
  departmentIdsList: DepartmentDucks.departmentIdsList(state),
  teamIdsList: TeamDucks.teamIdsList(state)
});

const mapActionsToProps = dispatch => ({
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks:bindActionCreators(TeamDucks,dispatch),
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  PayrollDucks: bindActionCreators(PayrollDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

VendorDepartmentListPage.propTypes = {
  DepartmentDucks: PropTypes.object.isRequired,
  TeamDucks:PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  companyPermissions: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  isSubCompany: PropTypes.number,
  isOrganizationVisible: PropTypes.bool,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

VendorDepartmentListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorDepartmentListPage);
