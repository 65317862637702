import React from 'react';
import SubmissionError from 'redux-form/lib/SubmissionError';
import queryString from 'query-string';
import Button from 'reactstrap/lib/Button';
import { Formik, Field as FilterField } from 'formik';
import cookie from 'react-cookies';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import Field from 'redux-form/lib/Field';
import { toast } from 'react-toastify';

import reduxForm from 'redux-form/lib/reduxForm';
import Form from 'reactstrap/lib/Form';
import moment from 'moment';
import PropTypes from 'prop-types';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import FilterDropdownComponent from 'components/common/FilterDropdown';
import {SURVEY_FILTER_OPTIONS, SURVEY_TYPE_FILTER_OPTIONS} from 'constants/surveys';
import DateTimePickerAtom from 'atoms/DateTimePicker';
import FilterFormComponent from 'components/common/FilterForm';
import { FILTER_FORM_LAYOUT } from 'constants/layout';
import GenericModal from 'components/common/GenericModal';
import AddSurveyQuestion from './addSurveyQuestion';
import MultiSelectAtom from 'atoms/FormikMultiselectDropdown';

import styles from './styles.module.scss';


class SurveyFilterFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.addSurveyQuestion = this.addSurveyQuestion.bind(this);
    this.surveyQuestionSubmit = this.surveyQuestionSubmit.bind(this);
    this.onDepartmentSelect= this.onDepartmentSelect.bind(this);
    this.state = {isAddQuestionModal: false}
  }


  onDepartmentSelect(id) {
    const { router: { history } } = this.context;
    const { location: { pathname, search }} = this.props
    const query  = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    history.push({
      pathname,
      search: queryString.stringify({ ...rest, department_id: id, team_id: null }),
    });
  }

  submit(data){
    const { router: { history } } = this.context;
    const {
      dateFormat,
      location: { pathname, query },
      handleSubmit,
    } = this.props;
    const { page, ...rest } = query;
    const detail = { ...data };
    const { page: pageNo, ...restDetail } = detail;
    restDetail.create_date_from = moment(data.create_date_from).format('YYYY-MM-DD');
    restDetail.create_date_to = moment(data.create_date_to).format('YYYY-MM-DD');
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail }),
    });
  }

  addSurveyQuestion(){
    const {isAddQuestionModal} = this.state;
    this.setState({isAddQuestionModal: !isAddQuestionModal});
  }

  surveyQuestionSubmit(data){
    console.log('data',data);
  }

  render() {
    const {dateFormat, location, handleSubmit , departmentList, teamList, initialSurveyValues} = this.props;
    const { router: { history } } = this.context;
    const {isAddQuestionModal} = this.state;
    const { query } = location;
    let departmentOptions = departmentList;
    let teamOptions = teamList;
    const survey_type_cookie = cookie.load('survey_type_dashboard')
    const survey_type_id = survey_type_cookie && survey_type_cookie.map(i=>i.id)
    return (
      <div className="ml-3 mr-4">
        <Formik
          initialValues={{survey_type : survey_type_id ? survey_type_id : [5]}}
          enableReinitialize={true}
          render={({
            values,
          }) => (
        <>      
        <Row className="filter">
          <Col className="float-left" md={9}>         
            <Form inline onSubmit={handleSubmit(this.submit)} className="report-filter date-filter-width survey-status">
              <Field
                id="create_date_from"
                name="create_date_from"
                placeholder="Start Date"
                component={DateTimePickerAtom}
                options={{ dateFormat }}
              />
              <Field
                id="create_date_to"
                name="create_date_to"
                placeholder="End Date"
                component={DateTimePickerAtom}
                options={{ dateFormat }}
              />
              <Button
                type="submit"
                color="accent"
                style={{ marginBottom: '24px'}}
                size={FILTER_FORM_LAYOUT.size}
                className="pr-4 pl-4"
              >
                Search
              </Button>
            </Form>
          </Col>
          <Col md={3}>
            <div className="float-right">
              <FilterFormComponent
                location={location}
                placeholder="Search by employee name"
                // initialValues={query}
              />
            </div>
          </Col>
        </Row>
        <Row className="filter">
          <Col  className="pl-0 mb-2 pr-0 ml-3">
            <div className="float-left d-inline-block">
              <FilterDropdownComponent
                paramKey="action_code"
                location={location}
                options={SURVEY_FILTER_OPTIONS}
              /> 
            </div>
            <div className="float-left d-inline-block" style={{width: '265px', paddingRight: '12px'}}>
            <FilterField
              id="survey_type"
              name="survey_type"
              value={values.survey_type}
              component={MultiSelectAtom}
              items={SURVEY_TYPE_FILTER_OPTIONS}
              placeholder="Select Survey Types"
              itemToString={i => i && i.name}
              onChange={(i) => this.props.getFilteredList(i)}
            />
            </div>
            <div className="float-left d-inline-block">
              {departmentOptions && departmentOptions.length > 0 &&
                <div id ="department" className="d-inline-block">
                  <FilterDropdownComponent
                    options={departmentOptions}
                    location={location}
                    history={history}
                    paramKey="department_id"
                    valueLabel="name"
                    keyLabel="id"
                    onChange={(val) => this.onDepartmentSelect(val)}
                  />
                  <UncontrolledTooltip placement="top" target="department">
                    Select Department
                  </UncontrolledTooltip>
                </div>
              }
            </div>
            <div className="float-left d-inline-block">
              {teamOptions && teamOptions.length > 0 &&
                <div className="float-left d-inline-block">
                  <FilterDropdownComponent
                    options={teamOptions}
                    location={location}
                    history={history}
                    paramKey="team_id"
                    valueLabel="name"
                    keyLabel="id"
                  />
                </div>
              }
            </div>            
          </Col>
        </Row>        
        {/*<Row>
          <Col>
            <Button
              color="secondary"
              size={FILTER_FORM_LAYOUT.size}
              className="pr-4 pl-4 pt-2 pb-2"
              onClick={()=>{this.addSurveyQuestion()}}
            >
              Add Survey Questions
            </Button>
          </Col>
        </Row>*/}
        { isAddQuestionModal &&
          <GenericModal toggle={this.addSurveyQuestion} isOpen={isAddQuestionModal} heading="Add New Template" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}} bodyClassName={`${styles['add-survey-modal']}`}>
            <AddSurveyQuestion 
              surveyQuestionSubmit= {this.surveyQuestionSubmit}
              roleList={this.props.roleList}
            />
          </GenericModal>
        }
        </>
        )}/>
      </div>
    );
  }}

const validate = (values) => {
  const errors = {};
  const formattedStartDate=moment(values.create_date_from);
  const formattedEndDate=moment(values.create_date_to);
  if (!values.create_date_from) errors.create_date_from = 'Select Start Date';
  else if (!values.create_date_to) errors.create_date_to = 'Select End Date'; 
  else if(formattedStartDate > formattedEndDate) errors.create_date_from ='Start date should be less than end date.';
  return errors;
};


SurveyFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const SurveyFilterForm = reduxForm({
  form: 'SurveyFilterForm',
  validate,
})(SurveyFilterFormComponent);

const selector = formValueSelector('SurveyFilterForm');

export default connect(
  (state) => {
    const job = selector(state, 'job');
    const task = selector(state, 'task');
    return { job, task };
  },
)(SurveyFilterForm);