 /*import  GraniteError from 'lib/GraniteError'; */
class EmployeeEntity {
  constructor(object={}){
    this.first_name = object.user.first_name;
    this.last_name = object.user.last_name;
    this.company = object.company;
    this.phone_no = object.phone_no;
    this.email = object.email;
    this.department = object.department;
    this.team = object.team;
    this.employee_role = object.employee_role;
    this.title = object.title;
    this.employee_id = object.employee_id;
    this.id = object.id;
    this.address_line_one = object.address_line_one;
    this.address_line_two = object.address_line_two;
    this.activated = object.activated;
    this.activation_code = object.activation_code;
    this.country = object.country;
    this.is_active = object.is_active;
    this.kiosk_user = object.kiosk_user;
    this.department_name = object.department_name;
    this.team_name = object.team_name;
    this.company_name = object.company_name;
    this.modified_date_display = object.modified_date_display;
    this.isSelected = false;
    this.username = object.user.username;
    this.phone_ext = object.phone_ext;
    this.wage = object.wage;
    this.zip_code = object.zip_code;
    this.city = object.city;
    this.state= object.state;
    this.offline_mode = object.offline_mode;
    this.tags = object.tags;
    this.dual_login = object.dual_login;
    this.biometric_setup = object.biometric_setup;
    this.biometric_access = object.biometric_access;
    this.pincode_access = object.pincode_access;
    this.pincode_setup = object.pincode_setup;
    this.hire_date=object.hire_date
    this.hire_date_display=object.hire_date_display;
    this.jobs_present = object.jobs_present;
    this.department_supervisor = object.department_supervisor;
    this.supervisory_departments = object.supervisory_departments;
    this.null_department = object.null_department;
    this.supervisory_teams = object.supervisory_teams;
    this.department_supervisor_access = object.department_supervisor_access;
    /* this.profile = obj.profile.medium_url;*/
    /*  this.allowedRoutes = [];*/
    /*this.allowedSideMenu = [];*/
 }

 toString() {
    return '(' + this.first_name + ' ' + this.last_name + ')';
  }

  name() {
    let name = this.first_name;
    if (this.last_name) {
      name += ` ${this.last_name}`;
    }
    return name;
  }

  toggleSelection(value){
    this.isSelected = value;
  }

  
  toJSON() {
    const { first_name, last_name, company, phone_no, email , department, team, employee_role, id, phone_ext, offline_mode,
      address_line_one, address_line_two, activated, activation_code, country, is_active, kiosk_user, company_name, isSelected , modified_date_display , username , employee_id, zip_code, tags,hire_date,hire_date_display} = this;
    return {first_name, last_name, company, phone_no, email, department, team, id, employee_role, company_name, phone_ext, offline_mode,
      address_line_one, address_line_two, activated, activation_code, country, is_active, kiosk_user, isSelected , modified_date_display , username, employee_id , zip_code, tags,hire_date,hire_date_display};
  }
}

export default EmployeeEntity;


