import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import CheckboxAtom from 'atoms/Checkbox';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import InputAtom from 'atoms/Input';
import closeimage from 'components/vendors/CloseCross.png';

import { FORM_LAYOUT } from 'constants/layout';

const  AdjustmentTypesFormModalComponent = (props) => {
  const { error, isOpen, submitting, handleSubmit, toggle, update , edit } = props;

  const submit = (data) => {
    return update(data)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errObj);
      });
  }  
 
 
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static" className="form-modal" autoComplete = "off">
      <ModalHeader>{edit ? 'Edit Adjustment Activity' : 'Add Adjustment Activity'}
      <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
      </ModalHeader>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <ModalBody>
          <div className="error-text">{error}</div>
          <Field
            id="name"
            name="name"
            label="Name"
            component={InputAtom}
            placeholder="Name"
            layout={FORM_LAYOUT}
          />
          <Field
            id="code"
            name="code"
            label="Code"
            component={InputAtom}
            placeholder="Code"
            layout={FORM_LAYOUT}
          />
          <div>
            <Field
              id="is_active"
              name="is_active"
              label="Active"
              component={CheckboxAtom}
              layout={FORM_LAYOUT} 
            />
          </div>  
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="secondary" size={FORM_LAYOUT.size}>
            Submit <ButtonLoaderAtom active={submitting} />
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Activity';
  return errors;
};

AdjustmentTypesFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'AdjustmentTypesForm',
  validate,
})(AdjustmentTypesFormModalComponent);
