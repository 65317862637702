import React from 'react';

import { Formik, Field } from 'formik';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col } from 'reactstrap';

import SelectAtom from 'generics/FormikSelect';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { DISCOUNT_UNIT_OPTIONS } from 'constants/vendors';

class BillFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    this.props.usecase.setup_data(values);
    try {
      await this.props.usecase.execute();
    } catch (e) {
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  render() {
    const { billDetails, isLoading } = this.props;
    if(isLoading) return <div className="col-min-height"><SectionLoaderAtom active /></div>;
    return(
      <Formik
        enableReinitialize={true}
        initialValues={billDetails}
        onSubmit={this.onSubmit}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
       }) => {
        return(
          <Form onSubmit={handleSubmit}>
            <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.non_field_errors}</FormText></div>
            <Row>         
              <Col md={12} className="mt-2">
                <Row>
                  <Col md={6} className="mt-2">
                    <FormGroup>
                      <Label className="mb-0">Billed Users</Label>
                      <Input
                        type="text"
                        name="quantity"
                        placeholder="Billed Users"
                        onChange={handleChange}
                        value={values && values.quantity}
                        className={errors && errors.quantity ? "form-control-error" : null}
                        disabled
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.max_active_user}</FormText></div>
                    </FormGroup>
                  </Col>
                  <Col md={6} className="mt-2">
                    <FormGroup>
                      <Label className="mb-0">Billing Rate ($)</Label>
                      <Input
                        type="text"
                        name="charge_rate"
                        placeholder="Billing Rate"
                        onChange={handleChange}
                        value={values &&  values.charge_rate ? values.charge_rate :''}
                        className={errors && errors.charge_rate ? "form-control-error" : null}
                        disabled={values && values.status === 'PAYMENT RECEIVED'}
                        data-lpignore={true}
                        autoComplete="off"
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.charge_rate}</FormText></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-2">
                    <Row>
                      <Col md={8} >
                        <FormGroup>
                          <Label className="mb-0">Discount</Label>
                          <Input
                            type="text"                           
                            name="discount"
                            placeholder="Discount"
                            onChange={handleChange}
                            value={values && values.discount ? values.discount : ''}
                            className={errors && errors.discount ? "form-control-error" : null}
                            disabled={values && values.status === 'PAYMENT RECEIVED'}
                          />
                          <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.discount}</FormText></div>
                        </FormGroup>
                      </Col>
                      <Col md={4} className="pl-0">
                        <Label className="mb-0">Unit</Label>
                        <Field
                          id="discount_unit"
                          name="discount_unit"
                          component={SelectAtom}
                          placeholder="Unit"
                          options={DISCOUNT_UNIT_OPTIONS}
                          labelKey="label"
                          valueKey="value"
                          simpleValue
                        />
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.discount_unit}</FormText></div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={6} className="mt-2">
                    <FormGroup>
                      <Label className="mb-0">Service Fee ($)</Label>
                      <Input
                        type="text"
                        name="service_fees"
                        placeholder="Service Fee"
                        onChange={handleChange}
                        value={values && values.service_fees ? values.service_fees : ''}
                        className={errors && errors.service_fees ? "form-control-error" : null}
                        disabled={values && values.status === 'PAYMENT RECEIVED'}
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.service_fees}</FormText></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mt-2">
                    <FormGroup>
                      <Label className="mb-0">Total Amount ($) </Label>
                      <Input
                        type="text"
                        name="total_amount"
                        placeholder="Total Amount"
                        onChange={handleChange}
                        value={values && values.total_amount ? values.total_amount : '0'}
                        className={errors && errors.total_amount ? "form-control-error" : null}
                        disabled
                      />
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.total_amount}</FormText></div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-2 text-right">
                  {billDetails && billDetails.status !== 'PAYMENT RECEIVED' &&
                    <Button
                      className= "ml-1 mr-1"
                      color='primary'
                      type='submit'
                      disabled={isSubmitting}
                    >
                    Submit <ButtonLoaderAtom active = {isSubmitting} />
                    </Button>
                  }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        );}
      }
      />);
  }
};
export default BillFormComponent;
