import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import CustomDailyTimecardListPage from './CustomDailyTimecardList';
import CustomDailyTimecardListPageV2 from './CustomDailyTimecardListV2';
import DeviatedDailyTimecardListPage from './DeviatedDailyTimecardList';
import CustomCreateTimeCardPage from './CustomCreateTimecard';
import CustomTimecardListPage from './CustomTimecardList';

const TimecardRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/historic`}
        name="timecards.timecard.historic_timecard.list"
        component={CustomDailyTimecardListPageV2}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/deviated-daily`}
        name="timecards.timecard.deviated_daily_timecard.list"
        component={DeviatedDailyTimecardListPage}
      />
      <Redirect exact from={`${match.url}/custom`} to={`${match.url}/custom/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/custom/list`}
        name="timecards.timecard.list"
        component={CustomTimecardListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/custom/list/:id/daily`}
        name="timecards.timecard.daily_timecard.list"
        component={CustomDailyTimecardListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/custom/list/:id/dailyV0`}
        name="timecards.timecard.daily_timecard.list"
        component={CustomDailyTimecardListPageV2}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/custom/list/createtimecard`}
        name="timecards.custom.customcreatetimecard"
        component={CustomCreateTimeCardPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

TimecardRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default TimecardRoutes;
