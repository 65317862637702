import React from 'react';
import { toast } from 'react-toastify';

import queryString from 'query-string';

import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import surveyFormUseCase from 'surveys/usecases/surveyFormUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';

import * as RouteDucks from 'ducks/routes';
import connect from 'react-redux/lib/connect/connect';

import SurveyFormOutcomesComponent from 'surveys/components/surveyFormOutcome';
import SurveyFormProgress from './surveyFormProgressBar';

import SectionLoaderAtom from 'atoms/SectionLoader';

class SurveyFormOutcomes extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.loadData = this.loadData.bind(this);
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new surveyFormUseCase(surveyAPIGateway);
    this.listenFormUsecase = this.listenFormUsecase.bind(this);
    this.submitData = this.submitData.bind(this);
    this.updateState = this.updateState.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.state = { roleList : [] , isButtonLoader : false , formData : null, isLoading: false }
  }

  componentWillMount(){
    this.loadData();
  }

  async submitData(data){
    const { match: { params } } = this.props;
    await this.usecase.updateTemplateOutcomeData(data, params.id);
  }


  loadData(){
    const { match: { params } } = this.props;
    this.listenFormUsecase()
    if(params.id){
      this.usecase.getFormData(params.id)
    }
  }


  goToUrl(){
    const { history } = this.props;
    const { match: { params } } = this.props;
    history.push(`/surveys/${params.id}/questions`);
  }


  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  listenFormUsecase() {
    const {history} = this.props;
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SHOW_OUTCOME_SUBMIT_LOADER':
          this.updateState('isButtonLoader',true);
          break;
        case 'HIDE_OUTCOME_SUBMIT_LOADER':
          this.updateState('isButtonLoader',false);
          break;
        case 'SUBMIT_OUTCOME_FORM_SUCCESS':
          history.push('/surveys/list')
          break;
        case 'SUBMIT_OUTCOME_FORM_FAILURE':
          toast.eror('Could not submit form.')
          break;
        case 'GET_SURVEY_DATA_SUCCESS':
          this.updateState('formData',event.data);
          break;
        case 'SHOW_FORM_LOADER':
          this.updateState('isLoading', true);
          break;  
        case 'HIDE_FORM_LOADER':
          this.updateState('isLoading', false);
          break;  
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    const {roleList, isButtonLoader, isLoading, formData} = this.state;
    const { match: { params } , location: { search, pathname }} = this.props;
    const query = queryString.parse(search);
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.EditSurveyTemplate : NAV.CreateSurveyTemplate}
        fetchData={() => this.loadData()}
      >
        <div className="ml-3 mr-4 pb-5">
        {isLoading ? 
          <SectionLoaderAtom active/> 
          :
          <div>
          <SurveyFormProgress 
            stepNumber={3}
            isStepThree = {formData && formData.template_data && formData.template_data.outcome == 'scoring' && formData.survey_type.length && formData.survey_type.find((i)=>i=='others') ? true : false}
            selectedId = { !(query && query.setup == 'true') && formData && formData.id}
            history={this.props.history}
            query={query}
          />
          {
            formData.template_data ?
             <SurveyFormOutcomesComponent 
                roleList={roleList}
                usecase={this.usecase}
                isButtonLoader={isButtonLoader}
                history={this.props.history}
                submitData={this.submitData}
                goToUrl = {this.goToUrl}
                initialValues={formData && formData.template_data 
                  ?
                  {
                    success_message : formData.template_data.scoring_actions && formData.template_data.scoring_actions.length && (formData.template_data.scoring_actions).find((i)=> i.action_code && i.action_code == 'SUCCESS') && formData.template_data.scoring_actions.find((i)=> i.action_code == 'SUCCESS').message,
                    failed_message : formData.template_data.scoring_actions && formData.template_data.scoring_actions.length && (formData.template_data.scoring_actions).find((i)=> i.action_code && i.action_code == 'FAILED') && formData.template_data.scoring_actions.find((i)=> i.action_code == 'FAILED').message,
                    success_limit : formData.template_data.scoring_actions && formData.template_data.scoring_actions.length && (formData.template_data.scoring_actions).find((i)=> i.action_code && i.action_code == 'SUCCESS') && formData.template_data.scoring_actions.find((i)=> i.action_code == 'SUCCESS').limit,
                    upperLimit : formData.template_data && formData.template_data.total_score  ? formData.template_data.total_score : 10
                  }
                  :
                  null  
                }
            /> 
            :
            null
          }
          </div>
        }
        </div>
      </TwoColumnLayout>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
});

export default connect(
  mapStateToProps,
)(SurveyFormOutcomes);