import React from 'react';
import queryString from 'query-string';
import TwoColumnLayout from 'layouts/TwoColumn';
import moment from 'moment';

import { NAV } from 'constants/vendors';
import Col from 'reactstrap/lib/Col';
import TabsComponent from 'components/common/Tabs';

import planFormUsecase from 'payments/bills/usecases/planFormUseCase';
import BillApiGateway from 'payments/bills/gateways/bill';

import AccountFormComponent from 'company/components/accountForm';
import AccountAuditLogsComponent from 'company/components/auditLogsList';
import { COMMERCIAL_STATUS_LIST , PAYMENT_METHOD_LIST} from 'constants/vendors';


class AccountFormPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.listenCompanyAccountFormUseCase = this.listenCompanyAccountFormUseCase.bind(this);
    const billApiGateway = new BillApiGateway();
    this.usecase = new planFormUsecase(billApiGateway);
    this.updateState = this.updateState.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.refresh= this.refresh.bind(this);
    this.state={
      companyDetailList: {},
      isLoading: false,
      infoList: [],
      isInfoOpen: true,
      parent: NAV.AccountFormPage.parent,
      commercialStatusList : [],
      submit : true,
      auditLogsList: [],
      isAuditLogsLoading: false,
    };
    this.switchTabs = this.switchTabs.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
  }

  componentDidMount() {
    this.usecase.getInfoList();
    this.listenCompanyAccountFormUseCase();
    this.fetchTabData(this.props);   
    this.setParentUrl(); 
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.fetchTabData(nextProps);  
    }
  }

  setParentUrl() {
    const { location: { search } } = this.props;    
    let parsedParams;
    let query;
    if(search){
      query = queryString.parse(search);
      parsedParams = JSON.parse(query.parent);
    }   
    try{
      if(parsedParams){
        this.updateState('parent', `${NAV.AccountFormPage.parent}?${queryString.stringify(parsedParams)}`);
      }
    } catch (e) {
      console.log(e);
    }
  }

  refresh(){
    this.fetchTabData(this.props);
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.updateState('isInfoOpen', !isInfoOpen );
  }

  listenCompanyAccountFormUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SET_DROPDOWN_VALUES':
          this.updateState('commercialStatusList', event.data);
          break;
        case 'GET_COMPANY_DETAILS_SUCCESS':
            let newData = {...event.data };
            this.updateState('companyDetailList', newData);
          break;
        case 'SET_COMPANY_DETAILS_SUCCESS':
          const query = queryString.parse(this.props.location.search);
          const parsedParams = JSON.parse(query.parent);
          this.props.history.push(`/vendor/list/all?${queryString.stringify(parsedParams)}`);          
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'HIDE_LOADER':
          this.updateState('isLoading', false);
          break;
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'SHOW_AUDIT_LOGS_LOADER':
          this.updateState('isAuditLogsLoading', true);
          break;
        case 'AUDIT_LIST':
          this.setState({ isAuditLogsLoading: false, auditLogsList: event.auditLogsList });
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  switchTabs(key){
    const { match: { params }, history, location: { search } } = this.props;
    return history.push(`/vendor/${params.id}/${key}${search}`);
  }

  fetchTabData(props){
    const { match: { params }, history, location: { pathname, search } } = props;
    const query = queryString.parse(search);
    if(params.tab === 'edit'){
      this.usecase.getCompanyAccountDetails(params)
        .then(() => {
          const { companyDetailList } = this.state;
          if(! ('id' in query)){
            history.push({
              pathname,
              search: queryString.stringify({ ...query, id:companyDetailList.id })
            });
          }
        });
    }
    else{
      this.usecase.getAuditLogs(query.id);
    }
  }

  render() {
    const { match: { params } } = this.props;
    const {companyDetailList, isLoading ,infoList, isInfoOpen, parent,
      commercialStatusList, auditLogsList, isAuditLogsLoading  } = this.state;
    const commercial_status =  COMMERCIAL_STATUS_LIST.find((item) => item.label === companyDetailList.commercial_status); 
    const plan= companyDetailList && companyDetailList.plan ;
    const plan_type =  PAYMENT_METHOD_LIST.find((item) => item.value === (plan && plan.plan_type));    
    const initialValues = {...companyDetailList, 
      plan_type: plan_type,
      commercial_status : commercial_status,
      trial_end : companyDetailList && companyDetailList.trial_end ? moment( companyDetailList && companyDetailList.trial_end).format('YYYY/MM/DD') : null,
      close_date : companyDetailList && companyDetailList.close_date ? moment( companyDetailList && companyDetailList.close_date).format('YYYY/MM/DD'): null,
      suspension_date: companyDetailList && companyDetailList.suspension_date ? moment( companyDetailList && companyDetailList.suspension_date).format('YYYY/MM/DD') : null,
    };
    const tabs = [
      { key: 'edit', label: 'Account Detail' },
      { key: 'auditLogs', label: 'Audit Logs'},
    ];

    return(
      <TwoColumnLayout
        navInfo={{...NAV.AccountFormPage , parent}}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.refresh}
        infoList={infoList}
      >
      {params.id &&
        <div className="mt-4 pl-3 pr-4">
          <TabsComponent tabItems={tabs} active={params.tab} onClick={this.switchTabs}/>
        </div>
      }
        <Col className="col-min-height pl-3 pr-4">
          {(params.tab === 'edit') && 
            <AccountFormComponent 
              location={this.props.location}
              data ={companyDetailList}
              usecase={this.usecase}
              companyID= {params}
              isLoading = { isLoading }
              commercialStatusList = { commercialStatusList}
              submit={this.state.submit}
              plan_type={plan_type}
              commercialStatus = {commercial_status}
              initialValues ={initialValues}
            />}
          { params.tab === 'auditLogs'
            && 
            <AccountAuditLogsComponent
              data={auditLogsList}
              isAuditLogsLoading={isAuditLogsLoading}
            /> }
        </Col>
      </TwoColumnLayout>
    );
  }
}


export default AccountFormPage;