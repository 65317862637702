import React from 'react';

import Table from 'reactstrap/lib/Table';

import SectionLoaderAtom from 'atoms/SectionLoader';
import {ReactComponent as DeleteIcon} from './delete.svg'


const EmployeeJobsTableComponent = (props) => {
	const {assignedJobs, isLoading} = props;
	if(isLoading) return <SectionLoaderAtom active />;
	return (
			<div className="ml-3 mr-4">
				<Table responsive hover size="sm" className="table-emp">
				<thead className="Header-col">
					<tr>
						<th width="10%" className="text-center pl-3">ACTION</th>
						<th width="20%">JOB CODE</th>
						<th width="20%">JOB NAME</th>
						<th width="20%">START DATE</th>
						<th width="5%" className="text-right pr-4">TASKS</th>
					</tr>
				</thead>
				<tbody>
				{assignedJobs && assignedJobs.length ? assignedJobs.map(item => (
					<tr>
						<td title="Delete Job" className="text-center pl-3">
							<DeleteIcon
							onClick={() => props.deleteEmployeeJob(item)}
							className="cursor-pointer"
							height="16"
							width="16" />
						</td>
						<td title={item.code}>{item.code}</td>
						<td title={item.name}>{item.name}</td>
						<td title={item.start_date_display}>{item.start_date_display}</td>
						<td title={item.task_count} className="text-right pr-4">{item.task_count}</td>
					</tr>
				)) : <tr><td colSpan="10" className='text-center'> No Records Found </td></tr>
				}
				</tbody>
				</Table>
			</div>
	);
};


export default EmployeeJobsTableComponent;
