import React from 'react';
import { Form,FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Formik,Field } from 'formik';
import { Handle, Track, Tick } from "./sliderComponents";

import reduxForm from 'redux-form/lib/reduxForm';
import formValueSelector from 'redux-form/lib/formValueSelector';
import connect from 'react-redux/lib/connect/connect';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import styles from './styles.module.scss';


class SurveyFormOutcome extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.onChange=this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {scoreRange: props.initialValues  && props.initialValues.success_limit ? props.initialValues.success_limit : [props.initialValues.upperLimit/2,props.initialValues.upperLimit]}
    this.domain = props.initialValues && props.initialValues.upperLimit ? [0, props.initialValues.upperLimit] : [0, 10];
    this.defaultValues = [props.initialValues.upperLimit/2, props.initialValues.upperLimit];
    this.sliderStyle = {position: "relative",width: "100%",marginTop: 25};
    this.railStyle = {position: "absolute",width: "100%",height: 8,cursor: "pointer",backgroundColor: "#e5eaed"}; 
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    const {scoreRange} = this.state;
    setErrors({})

    let data = [];
    let updatedData = [];
    data.push({action_code : 'SUCCESS', message : null , limit : []});
    data.push({action_code : 'FAILED', message : null , limit : []});

    data.forEach((item)=>{
      if(item.action_code == 'SUCCESS'){
        let obj = item;
        obj.message = values.success_message;
        updatedData.push(obj);
        obj.limit = scoreRange;
      }
      else if(item.action_code == 'FAILED'){
        let obj = item;
        obj.message = values.failed_message;
        updatedData.push(obj);
      }
    });
    this.props.submitData(updatedData)
    .catch((e) => {
      setErrors(e.errors);
    });
  }

  onChange(data){
    this.setState({scoreRange: data});
  }


  componentWillReceiveProps(nextProps, prevProps){
    if(nextProps.initialValues !== prevProps.initialValues){
      this.setState({scoreRange: nextProps.initialValues.success_limit});
    }
  }

  render() {
    const { selectStep, isButtonLoader , initialValues } = this.props;
    /*console.log('hey', this.state.scoreRange)*/
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        enableReinitialize = {true}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit} style={{backgroundColor:'white'}} className="mt-0 pt-2">
            <div className="ml-5">
              <Row>
                <Col>Score range for a successful submission</Col>
              </Row>
              <Row>
                <Col md={8} className="mt-3"> 
                  <div style={{height: 60}}>
                    <Slider
                      mode={2}
                      step={1}
                      domain={this.domain}
                      rootStyle={this.sliderStyle}
                      onUpdate={this.onUpdate}
                      onChange={this.onChange}
                      values={values.success_limit ? values.success_limit : this.defaultValues}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <div style={this.railStyle} {...getRailProps()} />
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={this.domain}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                      <Ticks count={10}>
                        {({ ticks }) => (
                          <div className="slider-ticks">
                            {ticks.map((tick) => (
                              <Tick key={tick.id} tick={tick} count={ticks.length} />
                            ))}
                          </div>
                        )}
                      </Ticks>
                    </Slider>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>Message to be shown when form is successfully submited</Label>
                    <Input
                      type="text"
                      name="success_message"
                      placeholder="Write message here"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.success_message}
                      className={errors && errors.success_message ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.success_message}</FormText></div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>Message to be shown when submission is failed</Label>
                    <Input
                      type="text"
                      name="failed_message"
                      placeholder="Write message here"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.failed_message}
                      className={errors && errors.failed_message ? "form-control-error" : null}
                    />
                    <div style={{minHeight: '3vh'}}><FormText color="danger">{errors &&  errors.failed_message}</FormText></div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mr-5 mt-2 mb-5">
                  <Button
                    type="submit"
                    color="secondary"
                    className="float-right ml-3"
                    type="submit"
                    disabled={isButtonLoader}
                  >
                   Submit <ButtonLoaderAtom active={isButtonLoader} />
                  </Button>
                  <Button
                    color="secondary"
                    className="float-right"
                    onClick={()=>this.props.goToUrl()}
                  >
                   Back
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}/>
    );
  }

}

export default SurveyFormOutcome;