import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import ListPage from 'applicants/pages/list';
import AddApplicant from 'applicants/pages/addApplicant';

const EmployeeRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="applicant.list"
        component={ListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/create`}
        name="applicant.add"
        component={AddApplicant}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/edit/:tab`}
        name="applicant.edit"
        component={AddApplicant}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

EmployeeRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EmployeeRoutes;
