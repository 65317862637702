import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';

import Lightbox from 'react-image-lightbox';
import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';

import {ReactComponent as PdfIcon} from '../pdf.svg';
import PlayIcon from '../play-btn.png';
import styles from './styles.module.scss';
/**

 * KnowledgeBaseListPage -> KnowledgeBaseListSection -> KnowledgeBaseListTableComponent
 *
 * Props:
 *    - employee list
 */
class KnowledgeBaseListTableComponent extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = { 
      videoOrImage: null, 
      isOverlayOpen: false,
      selectedId: null,
    };
  }

  render(){
    const { location, data, toggle } = this.props;
    const { isOverlayOpen, selectedId } = this.state;
    const { query } = location;

    return (
      <div className="ml-3 mr-4">
        <Table responsive hover size="sm" className="table-knowledge-base">
          <thead className="Header-col">
            <tr>
              <th className="text-center">Action</th>
              <th className="text-center">View</th>
              <th>Context</th>
              <th>Description</th>
              <th>KB Data Type</th>
              <th>Is Active</th>
            </tr>
          </thead>
          <tbody>
            {data.results && data.results.map(item => (
              <tr key={item.id}>
                <td className="text-center">
                  <Link
                    to={{
                      pathname: `/knowledge-base/${item.id}/edit`,
                      search: queryString.stringify({
                        parent: JSON.stringify(query),
                      }),
                    }}
                    title="Edit"
                  >
                    <EditIconAtom />
                  </Link>
                </td>
                <td title={item.external_reference} className="text-center">
                  {item.kb_data_type === 'Document' &&
                    <div className={`${styles['document-wrap']} d-flex justify-content-center align-items-center`}>
                      <a download={item.external_reference} rel="noopener noreferrer" target="_blank" href={item.external_reference}>
                        <PdfIcon height="30" width="30"/>
                      </a>
                    </div>
                  }
                  {item.kb_data_type === 'Video' &&
                    <div className={styles['video-wrapper']}>
                      <video
                        className={styles['video-content']}
                        muted
                        src={item.external_reference}
                      >
                        <track kind="captions" />
                      </video>
                      <div tabIndex="0" role="button" onClick={()=> toggle(item)}>
                        <img src={PlayIcon} alt='' className={styles['overlay-play-button']} />
                      </div>
                    </div>
                  }
                  {item.kb_data_type === 'Image' &&
                    <div 
                      className={`${styles['img-wrap']} outline-none`}  
                      tabIndex="0" 
                      role="button" 
                      onClick={() => this.setState({ selectedId: item.id, isOverlayOpen: true })} 
                    >
                      <img src={item.external_reference} alt=''  />
                    </div>
                  }
                  {isOverlayOpen && (selectedId === item.id) && (
                    <Lightbox
                      mainSrc={item.external_reference}
                      onCloseRequest={() => this.setState({ isOverlayOpen: false })}
                    />
                  )}
                </td>
                <td title={item.context && item.context.display}>{item.context && item.context.display}</td>
                <td title={item.rich_text_content}>{item.rich_text_content}</td>
                <td title={item.kb_data_type}>{item.kb_data_type}</td>
                {item.is_active ?
                  <td>Yes</td>
                  :
                  <td>No</td>
                }
              </tr>
            ))}
            {!data.count &&
              <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
            }
          </tbody>
        </Table>
      </div>
    );
  }
}

KnowledgeBaseListTableComponent.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

KnowledgeBaseListTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default KnowledgeBaseListTableComponent;
