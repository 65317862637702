import EventEmitter from 'lib/event-emitter';


const EVENT_TYPES = {
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  INFO_LIST: 'INFO_LIST',
  GET_COMPANY_PLAN_SUCCESS: 'GET_COMPANY_PLAN_SUCCESS',
  CANCEL_PLAN_SUCCESS: 'CANCEL_PLAN_SUCCESS',
  GET_COMPANY_PLAN_AFTER_CANCELLATION_SUCCESS: 'GET_COMPANY_PLAN_AFTER_CANCELLATION_SUCCESS',
  GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS',
};

class planUsecase {
 
  billApiGateway = null;

  constructor(billApiGateway, userApiGateway) {
    this.billApiGateway = billApiGateway;
    this.userApiGateway = userApiGateway;
    this.eventEmitter = new EventEmitter();
    this.data = { user: {} };
  }

  toString() {
    return 'planUsecase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  async getInfoList(){
    this.infoList = await this.billApiGateway.getInfoList({ route: 'vendor.account'});
    this.eventEmitter.emit({
      type: EVENT_TYPES.INFO_LIST,
      infoList: this.infoList,
    });
  }

  async getCompanyPlan(companyId){
    this.eventEmitter.emit({
      type: EVENT_TYPES.SHOW_LOADER,
    });
    this.companyPlanDetails = await this.billApiGateway.getCompanyPlan(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_COMPANY_PLAN_SUCCESS,
      companyPlan: this.companyPlanDetails,
    });
  }

  async cancelPlan(email, password, companyId){
    await this.billApiGateway.authenticatePassword(email, password, 'web');
    let customerDetail = await this.billApiGateway.cancelCompanyPlan(companyId);
    this.eventEmitter.emit({
      type: EVENT_TYPES.CANCEL_PLAN_SUCCESS,
    });
    this.eventEmitter.emit({
      type: EVENT_TYPES.GET_CUSTOMER_DETAIL_SUCCESS,
      detail: customerDetail,
    });
  }
}

export default planUsecase;