import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import styles from './styles.module.scss';


class ResetBiometricModal extends React.PureComponent {
  constructor(props) {
   super(props)
 }

 render() {
  const { isOpen, toggle, enableResetBiometric, enabledBiometric } =this.props;
  return(      
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
     <ModalHeader toggle={toggle}>Enable Reset Biometric</ModalHeader> 
     <ModalBody>
       <div>Are you sure you want to allow the employee to reset the biometric on Kiosk Login without supervisor access ?</div>
     </ModalBody>
     <ModalFooter>
       <Button color="default" className={styles['cancel-btn']} onClick={() => toggle()}>Cancel</Button>
       <Button type="submit" color="secondary" className={styles['yes-btn']} onClick={() => enableResetBiometric()}>
         Yes
         <ButtonLoaderAtom active={enabledBiometric} />
       </Button>
      </ModalFooter>
    </Modal>
  );
}
}

export default ResetBiometricModal;