import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import ReportGridComponent from 'components/reports/dashboard/Grid';

/**
 * ReportListPage -> ReportListSection
 *
 * Components:
 *    - {@link ReportGridComponent}
 *
 * State:
 *    - reportList
 *
 * Actions:
 *    None
 */
class ReportListSection extends React.Component {
  constructor(props) {
    super(props);
    this.goTo = this.goTo.bind(this);
  }
  
  componentWillReceiveProps(nextProps) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.props.ReportDucks.getReports(nextQuery);
    }
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render() {
    const { isLoading, reportList } = this.props;
    
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <ReportGridComponent
        data={reportList}
        goTo={this.goTo} 
      />
    );
  }
}

const mapStateToProps = state => ({
  reportList: ReportDucks.reportList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

ReportListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  reportList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  ReportDucks: PropTypes.object.isRequired,
};

ReportListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ReportListSection);
