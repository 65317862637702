import React from 'react';

import { FormGroup , Card  , Input , Row , Col} from 'reactstrap';
import styles from './styles.module.scss';

import FloatLabelInput from 'components/common/FloatingLabelInput';

import EditorAtom from 'surveys/components/editorAtom';

import EditorFloatAtom from './EditorFloatAtom';

const MultiSelectFloatComponent = (props) => {
  const { data , index, disabled, showSpLabel} = props;
  return (
   <Card className={`${styles['card-ui']} mr-3 pl-3 pr-3 pt-3 pb-3`}>
      <Row>
        <Col md={12} className={`${styles['custom-col']}`} style={{display: 'block'}}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <span><EditorFloatAtom value={showSpLabel ? data.sp_question : data.question} id={`question_${index}`} name={`question_${index}`} data={data} mandatory={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? true : false} handleChange={props.handleChange} disabled={disabled} onChange={props.onChange} onFocus={props.onFocus} index={index} key={showSpLabel ? `question_${index}_spanish`: `question_${index}_english`}/></span>
        </Col>
      </Row>
      <div className={`${styles['options-margin']} mt-3 custom-input-label`}>
      {
        data && data.answer && data.answer.length && data.answer.map((item, id)=>
          <FormGroup>
          {item.value
            ?
            <div style={{display: 'flex'}}>
                <div style={{position: 'relative', top: '10px'}}>
                    <input
                        type="checkbox"
                        id={`${index}_${id}`}
                        name={item.value}
                        label={item.value}
                        checked={item.checked}
                        disabled
                    /> 
                </div>
              <FloatLabelInput label={showSpLabel ? item.sp_value :item.value} id={`answer_${index}_${id}`} name={`answer_${index}_${id}`} handleChange={props.handleChange} disabled={disabled}/>
            </div>
            :
            null
          }
          </FormGroup>
        )
      }
      </div>
    </Card>
  );
};

export default MultiSelectFloatComponent;
