import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as KnowledgeBaseDucks from 'ducks/knowledgeBases/knowledgeBase';

import TwoColumnLayout from 'layouts/TwoColumn';
import KnowledgeBaseFormSection from 'sections/knowledgeBases/form/Form';

import { NAV } from 'constants/knowledgeBases';

/**
 * KnowledgeBaseFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link KnowledgeBaseFormSection}
 *
 * Fetch Needs:
 *    - get vendor detail
 */
class KnowledgeBaseFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData(){
    const { router: { route: { match: { params } } } } = this.context;
    this.props.UserDucks.getContexts({ paginate: false });
    if (params.id) {
      this.setState({ isLoading: true });
      this.props.KnowledgeBaseDucks.getKnowledgeBase(params)
        .then(() => this.setState({ isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { isLoading } = this.state;
    return (
      <TwoColumnLayout
        navInfo={params.id ? NAV.KnowledgeBaseEditFormPage : NAV.KnowledgeBaseAddFormPage}
        fetchData={this.fetchData}
      >
        <KnowledgeBaseFormSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  // countriesList: AddressDucks.countriesList(state),
  // vendorDetail: VendorDucks.vendorDetail(state),
});

const mapActionsToProps = dispatch => ({
  KnowledgeBaseDucks: bindActionCreators(KnowledgeBaseDucks, dispatch),
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

KnowledgeBaseFormPage.propTypes = {
  // countriesList: PropTypes.array.isRequired,
  // profile: PropTypes.object.isRequired,
  // vendorDetail: PropTypes.object.isRequired,
  KnowledgeBaseDucks: PropTypes.object.isRequired,
  UserDucks: PropTypes.object.isRequired,
};

KnowledgeBaseFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KnowledgeBaseFormPage);
