import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';
import { toast } from 'react-toastify';

import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as InfoDucks from 'ducks/info/info';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import TwoColumnLayout from 'layouts/TwoColumn';
import PayrollSettingFormSection from 'sections/payrolls/setting/Form';
import PayrollSettingAdvanceFormSection from 'sections/payrolls/setting/advancedPayrollForm';

import { NAV } from 'constants/payrolls';

import styles from './styles.module.scss';

import CloseImage from 'components/payrolls/setting/Close-Cross.png';

/**
 * PayrollSettingFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link PayrollSettingFormSection}
 *    - {@link PayrollSettingFilterSection}
 *
 * Fetch Needs:
 *    - getPayrollSetting
 */
class PayrollSettingFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.showPayrollDetails = this.showPayrollDetails.bind(this);
    this.showAdvancedSettings = this.showAdvancedSettings.bind(this);
    this.showPastRecords = this.showPastRecords.bind(this);
    this.hidePayrollDetails = this.hidePayrollDetails.bind(this);
    this.autoOpenDetails = this.autoOpenDetails.bind(this);
    this.getUpdatedList= this.getUpdatedList.bind(this);
    this.state = { isLoading: false, isPayrollSettingAddInfo: true, isPayrollSettingEditInfo: true, 
      isEditPayroll: false, advancedPayrollModalType:null, showPastRecordsLoader: false};
  }

  componentWillMount() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    if(!params.id){
      const cookieKey = cookie.load("payroll_setting_add_form_info");
      if(cookieKey) {
        this.setState({isPayrollSettingAddInfo: JSON.parse(cookieKey)});
      }
    }
    if (params.id) {
      const cookieKey = cookie.load("payroll_setting_edit_form_info");
      if(cookieKey) {
        this.setState({isPayrollSettingEditInfo: JSON.parse(cookieKey)});
      }
    }
    this.fetchData(query);
  }


  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
    this.props.PayrollSettingDucks.resetPayrollDetail();
  }

  componentWillReceiveProps(nextProps, prevProps){
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if(nextQuery.isAdvancedSettingsVisible !== prevQuery.isAdvancedSettingsVisible && nextQuery.isAdvancedSettingsVisible == 'true'){
      this.fetchData(nextQuery)
    }
    if(JSON.stringify(nextQuery) !== JSON.stringify(prevQuery)){
      this.getUpdatedList(nextQuery)
    }
  }

  getUpdatedList(query){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isLoading: true });
    if(!params.id){
      this.props.InfoDucks.getInformations({route: 'payroll.setting.add'})
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
    if (params.id) {
      Promise.all([
        this.props.InfoDucks.getInformations({route: 'payroll.setting.edit'}),
        this.props.PayrollSettingDucks.getPayrollSetting(params),
      ])
        .then(() => {
          this.props.PayrollSettingDucks.getAdvancedPayrollSetting(params, query)
          .then((response)=> {
            this.setState({ isLoading: false });
          })
          .catch(()=>
            this.setState({ isLoading: false })
          ) 
        })
        .catch(() => this.setState({ isLoading: false }));
    }
  }

  showPastRecords(val){
    const { router: { history ,  route: { match: { params } }  } } = this.context;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({...query, past_records: val.target.checked }),
    });
  }

  showAdvancedSettings(val){
    const { location: { pathname, search }, payrollSettingDetail } = this.props;
    const { router: { history, route: { match: { params } } } } = this.context;
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({...query, isAdvancedSettingsVisible : val.target.checked }),
    });
    const data = {...payrollSettingDetail, extra_data : {payroll_applicability : val.target.checked}}
    this.props.PayrollSettingDucks.putPayrollSetting(data ,params.id)
    
    if(val.target.checked===false){
      this.setState({isEditPayroll:false});    
    }
  }

  showPayrollDetails(type, id){
    const { isEditPayroll } = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    if(type == 'edit'){
      this.props.PayrollSettingDucks.getAdvancedPayrollSettingDetail(params, query , id)
      .then(()=>
        this.setState({ isEditPayroll: !isEditPayroll, advancedPayrollModalType:type}))
      .catch(() => toast.error('Could not fetch details'));
    }
    else{
      this.props.PayrollSettingDucks.resetAdvancePayrollDetail();
      this.setState({ isEditPayroll: !isEditPayroll, advancedPayrollModalType:type})
    }
  }


  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isPayrollSettingAddInfo, isPayrollSettingEditInfo } = this.state;
    if (params.id) {
      this.setState({ isPayrollSettingEditInfo: !isPayrollSettingEditInfo });
      cookie.save("payroll_setting_edit_form_info", !isPayrollSettingEditInfo);
    }
    else {
      this.setState({ isPayrollSettingAddInfo: !isPayrollSettingAddInfo });
      cookie.save("payroll_setting_add_form_info", !isPayrollSettingAddInfo);
    }
  }

  fetchData(query){
    const { router: { route: { match: { params } } } } = this.context;
    const payrollTablePageSize = cookie.load('advanced_payroll_table_size')
    const { location: { pathname } } = this.props;
    this.setState({ isLoading: true });
    if(!params.id){
      this.props.InfoDucks.getInformations({route: 'payroll.setting.add'})
        .then(() => {
          this.setState({ isLoading: false });
        })
        .catch(() => this.setState({ isLoading: false }));
    }
    if (params.id) {
        Promise.all([
          this.props.InfoDucks.getInformations({route: 'payroll.setting.edit'}),
          this.props.PayrollSettingDucks.getPayrollSetting(params),
        ])
          .then(() => {
            this.props.PayrollSettingDucks.getAdvancedPayrollSetting(params, query)
            .then((response)=> {
              query.isAdvancedSettingsVisible && this.autoOpenDetails(response, query);
              this.setState({ isLoading: false });
            })
            .catch(()=>
              this.setState({ isLoading: false })
            ) 
          })
          .catch(() => this.setState({ isLoading: false }));
      }
  }

  autoOpenDetails(response, params){
    if(params.isAdvancedSettingsVisible == 'false'){
      return true;
    }
    let list = [];
    list = response.action.payload && response.action.payload.results ? response.action.payload.results : response.action.payload;
    if(list.length){
      const autoOpenDetailId = list.find((i)=>i.current_payroll_setting == true) ? list.find((i)=>i.current_payroll_setting == true).id : list.find((i)=>i.is_default == true) ? list.find((i)=>i.is_default == true).id : null;
      /*console.log('auto', autoOpenDetailId)*/
      autoOpenDetailId && this.showPayrollDetails('edit' , autoOpenDetailId)
    }
  }

  hidePayrollDetails(){
    const { isEditPayroll } = this.state;
    this.props.PayrollSettingDucks.resetAdvancePayrollDetail();
    this.setState({isEditPayroll:false});
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { infoList, userExtraData } = this.props;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    const { isPayrollSettingAddInfo, isPayrollSettingEditInfo, isEditPayroll, advancedPayrollModalType } = this.state;
    return (
      <div className={`${styles['wrapPayrollDetails']}`}>
      <div  className={isEditPayroll ? `${styles['showEditModal']}` : `${styles['hideEditModal']}`}>
       <TwoColumnLayout
        navInfo={params.id ? NAV.PayrollSettingEditFormPage : NAV.PayrollSettingAddFormPage}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={params.id ? isPayrollSettingEditInfo : isPayrollSettingAddInfo}
        fetchData={() => this.fetchData(query)}
      >
        <PayrollSettingFormSection isLoading={this.state.isLoading}
          showPayrollDetails={this.showPayrollDetails}
          showAdvancedSettings={this.showAdvancedSettings}
          isEditPayroll={isEditPayroll}
          newLayout={false}
          advancedPayrollModalType='old'
          showPastRecords={this.showPastRecords}
          showPastRecordsLoader={this.state.showPastRecordsLoader}
        />
      </TwoColumnLayout>
      </div>
      {isEditPayroll &&
        <div className={styles['advance-payroll-slider']}>
          <div>
            <h4 className="d-inline-block mt-3 ml-3 mb-0">Payroll Setting</h4>
            <img src={CloseImage} className={`${styles['advance-payroll-close-icon']} mr-3`} height="13" width="13" onClick={() => this.hidePayrollDetails()}/>
          </div>
          <p className = {`${styles['advance-payroll-sub-heading']} pl-3 pr-4 mb-2`}>Manage Payrolls of company</p>
          <hr className={`${styles['advance-payroll-horizontal-line']} bg-primary`}/>
          <div className={styles['payroll-setting-modal']}>
            <PayrollSettingAdvanceFormSection 
              isLoading={this.state.isLoading}
              showPayrollDetails={this.showPayrollDetails}
              showAdvancedSettings={this.showAdvancedSettings}
              isEditPayroll={isEditPayroll}
              newLayout={true}
              advancedPayrollModalType={this.state.advancedPayrollModalType}
              showPastRecords={this.showPastRecords}
              showPastRecordsLoader={this.state.showPastRecordsLoader}
              fetchData={this.getUpdatedList}
            />
          </div>
        </div>
      }
      </div>
    );
  } 
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
  payrollSettingDetail: PayrollSettingDucks.payrollSettingDetail(state),
});

const mapActionsToProps = dispatch => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

PayrollSettingFormPage.propTypes = {
  PayrollSettingDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

PayrollSettingFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PayrollSettingFormPage);
