import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import queryString from 'query-string';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { toast } from 'react-toastify';

import * as MissingActivityDucks from 'ducks/activities/missingActivity';
import * as SessionDucks from 'ducks/activities/session';
import * as TimecardDucks from 'ducks/timecards/timecard';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import ManualSessionMealPeriodComponent from 'components/activities/manualSession/MealPeriod';
import ManualSessionPunchInComponent from 'components/activities/manualSession/PunchIn';
import ManualSessionPunchOutComponent from 'components/activities/manualSession/PunchOut';

import { MSGS } from 'constants/activities';
import styles from './styles.module.scss';

/**
 * ManualSessionPage -> ManualSessionTimelineSection
 *
 * Components:
 *    - {@link ManualSessionPunchInComponent}
 *    - {@link ManualSessionPunchOutComponent}
 *    - {@link ManualSessionMealPeriodComponent}
 *
 * State:
 *    None
 *
 * Actions:
 *    - addMissingActivity
 *    - updateMissingActivity
 *    - removeMissingActivity
 *    - postSession
 */
class ManualSessionTimelineSection extends React.Component {
  static isSessionValidated(activityList) {
    for (let i = 0; i < activityList.length; i += 1) {
      if (moment(activityList[i].start_datetime) > moment(activityList[i].end_datetime)) {
        toast.error('End datetime must be greater than start datetime');
        return false;
      }
      if (activityList[i].activity_code !== 'PUNCH-IN') {
        if (moment(activityList[i].start_datetime) < moment(activityList[0].start_datetime) ||
          moment(activityList[i].start_datetime) > moment(activityList[0].end_datetime) ||
          moment(activityList[i].end_datetime) < moment(activityList[0].start_datetime) ||
          moment(activityList[i].end_datetime) > moment(activityList[0].end_datetime)) {
          toast.error('Meal-Period must be between Punch-In session');
          return false;
        }
      }
      if (!activityList[i].end_datetime) {
        toast.error('select end datetime ');
        return false;
      }
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.addMealPeriodEntry = this.addMealPeriodEntry.bind(this);
    this.editEntry = this.editEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);
    this.submitManualSession = this.submitManualSession.bind(this);
    this.state = {isSubmitting: false };
  }

  componentWillReceiveProps(nextProps) {
    const { timecardDetail: prevTimecardDetail } = this.props;
    const { timecardDetail: nextTimecardDetail } = nextProps;
    if (prevTimecardDetail !== nextTimecardDetail) {
      const endDatetime = moment().valueOf() > moment(nextTimecardDetail.company_payroll.end_datetime).valueOf()
        ?  moment(nextTimecardDetail.company_payroll.end_datetime).toISOString() : moment().toISOString();
      const data = {
        activity_code: "PUNCH-IN",
        start_datetime: moment(nextTimecardDetail.company_payroll.start_datetime).toISOString(),
        end_datetime: endDatetime,
        index: 0,
      };
      this.props.MissingActivityDucks.updateMissingActivity(data);
    }
  }

  componentWillUnmount() {
    this.props.MissingActivityDucks.resetMissingActivity();
  }

  addMealPeriodEntry() {
    const { missingActivityList } = this.props;
    const data = {
      activity_code: "MEAL-PERIOD",
      start_datetime: moment(missingActivityList[0].start_datetime).toISOString(),
      end_datetime: moment(missingActivityList[0].end_datetime).toISOString(),
    };
    this.props.MissingActivityDucks.addMissingActivity(data);
  }

  editEntry(item, date, status) {
    const data = { ...item };
    if(Date.parse(date) > moment().valueOf()){
      toast.error('Selected date time should not be greater than current date time');
    }
    if (status) data.start_datetime = date[0];
    else data.end_datetime = date[0];
    return this.props.MissingActivityDucks.updateMissingActivity(data);
  }

  removeEntry(data) {
    return this.props.MissingActivityDucks.removeMissingActivity(data);
  }

  submitManualSession() {
    
    const { router: { history } } = this.context;
    const { missingActivityList, timecardDetail, location: { query } } = this.props;

    if (this.constructor.isSessionValidated(missingActivityList)) {
      this.setState({isSubmitting: true});
      this.props.SessionDucks.postSession(missingActivityList, timecardDetail.employee.id)
        .then(() => {
          this.setState({isSubmitting: false});
          toast.success(MSGS.MISSING_SESSION_SUCCESS);
          history.push({
            pathname: `/timecard/list/${timecardDetail.id}/daily`,
            search: queryString.stringify(JSON.parse(query.parent)),
          });
          // history.push({ pathname: `/timecard/list/${timecardDetail.id}/daily`});
        })
        .catch((err) => {
          this.setState({isSubmitting: false});
          if(Array.isArray(err)){
            toast.error(err[0].non_field_errors[0]);
          }
          else{
            toast.error(err.non_field_errors[0]);
          }
        });
    }
  }

  render() {
    const {
      dateFormat, isLoading, missingActivityList, timecardDetail, timeFormat, timeFormat24Hrs } = this.props;
    const { isSubmitting } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <Row>
        <Col sm="4" className={`${styles.timeline} offset-md-1`}>
          {missingActivityList.map(item => {
            if (item.activity_code === 'PUNCH-IN') {
              return (<ManualSessionPunchInComponent
                key={item.index}
                dateFormat={dateFormat}
                endDate={timecardDetail.end_date}
                item={item}
                startDate={timecardDetail.start_date}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                editEntry={this.editEntry}
              />);
            }
            return (<ManualSessionMealPeriodComponent
              key={item.index}
              dateFormat={dateFormat}
              endDate={timecardDetail.end_date}
              item={item}
              startDate={timecardDetail.start_date}
              timeFormat={timeFormat}
              timeFormat24Hrs={timeFormat24Hrs}
              editEntry={this.editEntry}
              removeEntry={this.removeEntry}
            />);
          })}
          {missingActivityList[0] &&
            (<ManualSessionPunchOutComponent
              dateFormat={dateFormat}
              endDate={timecardDetail.end_date}
              item={missingActivityList[0]}
              startDate={timecardDetail.start_date}
              timeFormat={timeFormat}
              timeFormat24Hrs={timeFormat24Hrs}
              editEntry={this.editEntry}
            />)
          }
        </Col>
        <Col sm="7">
          <Button onClick={this.submitManualSession} disabled={isSubmitting} className="float-right">Submit <ButtonLoaderAtom active={isSubmitting} /></Button>
          <Button className="mr-2 float-right" onClick={this.addMealPeriodEntry}>Add Meal Period</Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
  missingActivityList: MissingActivityDucks.missingActivityList(state),
  timecardDetail: TimecardDucks.timecardDetail(state),
});

const mapActionsToProps = dispatch => ({
  MissingActivityDucks: bindActionCreators(MissingActivityDucks, dispatch),
  SessionDucks: bindActionCreators(SessionDucks, dispatch),
});

ManualSessionTimelineSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  missingActivityList: PropTypes.array.isRequired,
  timecardDetail: PropTypes.object.isRequired,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  MissingActivityDucks: PropTypes.object.isRequired,
  SessionDucks: PropTypes.object.isRequired,
};

ManualSessionTimelineSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ManualSessionTimelineSection);
