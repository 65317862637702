import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';

import TwoColumnLayout from 'layouts/TwoColumn';
import CustomCreateTimecardSection from 'sections/timecards/customList/CustomCreateTimecard';

import { NAV } from 'constants/timecards';

/**
 * CustomCreateTimecard
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link CustomCreateTimecardSection}
 *
 * Fetch Needs:
 *    None
 */
class CustomCreateTimecard extends React.Component {
  constructor(props) {
    super(props);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.state = { isLoading: false, parent: NAV.CustomCreateTimecardPage.parent, isInfoOpen: true };
  }

  componentWillMount() {
    const cookieKey = cookie.load("manual_session_info");

    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.props.InfoDucks.getInformations({route: 'timecards.custom.customcreatetimecard'});
    this.fetchData();
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }


  setParentUrl(query) {
    const parent = `/timecard/custom/list`;
    try {
      if (query.parent) {
        const parsedParams = query.parent;
        this.setState({
          parent: parsedParams,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ parent });
    }
  }

  fetchData(){
    const { location: { query } } = this.props;
    // this.setState({ isLoading: true });
    this.setParentUrl(query);
    // return this.props.TimecardDucks.getTimecard(params)
    //   .then(() => {
    //     this.setState({ isLoading: false });
    //   })
    //   .catch(() => this.setState({ isLoading: false }));
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("manual_session_info", !isInfoOpen);
  }

  render() {
    const { infoList, location: { query } } = this.props;
    const { isLoading, parent, isInfoOpen } = this.state;
    let breadcrumb;
    let nav;
    if(query.parent && query.parent.includes('custom=true')) {
      breadcrumb = [
        ...NAV.CustomCreateTimecardPage.breadcrumb,
        { href: query.parent, label: 'Daily Timecards' },
        { label: 'New' },
      ];
      nav = NAV.AddMissingTimecard;
    } else {
      breadcrumb = [
        ...NAV.CustomCreateTimecardPage.breadcrumb,
        { label: 'New' },
      ];
      nav = NAV.CustomCreateTimecardPage;
    }

    return (
      <TwoColumnLayout
        navInfo={{
          ...nav, breadcrumb, parent,
        }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.fetchData}
      >
        <CustomCreateTimecardSection isLoading={isLoading} />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
});

CustomCreateTimecard.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

CustomCreateTimecard.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomCreateTimecard);
