import React from 'react';
import StorageGateway from 'lib/storage-gateway';
import { Button, Card, Row, Col } from 'reactstrap';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import styles from './styles.module.scss';
import moment from 'moment-timezone';
import name from './name.png';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

const AccountSuspensionComponent = (props) => {
  const {
    usecase,
    isLoggedInAdmin,
    isSubCompany,
    customerDetail,
    billList,
    logoutLoader,
    logOutOfAccount,
    openModal,
    userExtraData
  } = props;
  const  trialEndDate = customerDetail && customerDetail.trial_end;
  const suspensionDate = moment(customerDetail && customerDetail.suspension_date).format('ddd, DD MMM YYYY');
  const closingDate= moment( customerDetail && customerDetail.close_date).format('ddd, DD MMM YYYY');
  const companyId = StorageGateway.get('company_id');
  const results= billList && billList.results && billList.results;
  let totalAmount = 0;
  billList && billList.results && results.map((i) => {
    totalAmount += i.total_amount;
    return i;
  });
  if(trialEndDate === moment()){
    totalAmount = 0;
  }
  return (      
    <Col md={8} className={styles['account-suspension-card']}>
      <div className="text-center pb-4 pt-5">
        <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
        <img src={name} height="26px" width="150px" alt="Logo"/>
      </div>  
      <Card className="mt-2 p-3">             
            <b>Your account has been suspended for non repayment.</b>
            <br />
            Your account was suspended on {suspensionDate} due to outstanding balance of ${totalAmount}.
            <br />
            <b>Your account will be closed on {closingDate} and at that time system will automatically stop all services associated with this account</b>
            <br />
            <b>If you wish to restore your account and any attached services , Please make payment promptly.</b> 
            Please be aware that all your Worksana admin panel services will remain suspended until your payment
            has been processed.
            <br />
            If you believe this suspension has been made in error please contact us immediately.   
              <Row>
                <Col md={6}>
                  {companyId &&
                    <Button
                      className="btn-backdashboard float-left mt-4"
                      color="primary"
                      onClick={() => usecase.backToMyDashboard(isLoggedInAdmin, isSubCompany)}
                    >
                      <u>Back To {isLoggedInAdmin && isSubCompany ? 'Vendor' : 'My'} Dashboard</u>
                    </Button>
                  }
                </Col>
                <Col md={6} className="text-right">
                {!userExtraData.is_logged_in_admin && 
                  <Button
                    className={`${styles['accounts-button']} mt-3 d-inline-block mr-2 mb-2`}
                    onClick={() => openModal()}
                  >
                    Contact Support
                  </Button>
                }
                  <Button
                    className={`${styles['accounts-button']} mt-3 d-inline-block mr-2 mb-2`}
                    type="submit"
                    isColor='secondary'
                    onClick={() => logOutOfAccount()}
                  >
                    Logout <ButtonLoaderAtom active={logoutLoader} />
                  </Button>
                </Col>
              </Row>
      </Card>
    </Col>
  );
}

export default AccountSuspensionComponent;
