import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as InfoDucks from 'ducks/info/info';
import * as CustomerDucks from 'ducks/customers/customer';
import * as NoteDucks from 'ducks/jobs/comment';
import * as TaskDucks from 'ducks/jobs/task';
import * as EmployeeDucks from 'ducks/employees/employee';

import TwoColumnLayout from 'layouts/TwoColumn';
import EditJobFormSection from 'sections/jobs/form/EditJob';
import JobProgressBarSection from 'sections/jobs/form/ProgressBar';

import { NAV } from 'constants/jobs';

/**
 * JobFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class EditJobFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, parent: NAV.JobEditFormPage.parent, isJobAddInfo: true, isJobEditInfo: true };
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    this.props.TaskDucks.resetTask();
    this.props.EmployeeDucks.resetEmployee();
    this.props.CustomerDucks.resetCustomer();
    this.loadData();
  }

  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(params) {
    const { router: { history } } = this.context;
    this.setState({ isLoading: true });
    const { isGeoFenceVisible } = this.props;
    if(isGeoFenceVisible) this.props.JobDucks.getJobGeoFenceMapping(params);
    if(params.id){
      this.props.InfoDucks.getInformations({route: 'job.edit'});
    }
    else{
      this.props.InfoDucks.getInformations({route: 'job.add'});
    }
    this.props.JobDucks.getJob(params)
      .then(() => {
        /*const { jobDetail } = this.props;
        this.props.NoteDucks.getComments({ job_id: jobDetail.id });*/
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        if(err.detail === 'Not found.') {
          toast.error('This job does not belong to your company.');
          history.push('/job/list');
        }
        this.setState({ isLoading: false })
      });
  }

  loadData(){
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query}} = this.props;
    if (params.id) {
      const cookieKey = cookie.load("jobs_edit_form_info");
      if(cookieKey) {
        this.setState({isJobEditInfo: JSON.parse(cookieKey)});
      }
      this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true });
      this.setParentUrl(query);
      this.fetchData(params);
    }
    if(!params.id){
      const cookieKey = cookie.load("jobs_add_form_info");
      if(cookieKey) {
        this.setState({isJobAddInfo: JSON.parse(cookieKey)});
      }
      this.props.InfoDucks.getInformations({route: 'job.add'});
    }
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isJobAddInfo, isJobEditInfo } = this.state;
    if (params.id) {
      this.setState({ isJobEditInfo: !isJobEditInfo });
      cookie.save("jobs_edit_form_info", !isJobEditInfo);
    }
    else {
      this.setState({ isJobAddInfo: !isJobAddInfo });
      cookie.save("job_add_form_info", !isJobAddInfo);
    }
  }

  render() {
    const { isLoading , isJobEditInfo, isJobAddInfo } = this.state;
    const { jobDetail, infoList, location: { query } } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const selectedId = params ? params.id : null;
    return (
      <div>
        <TwoColumnLayout
          navInfo={query.setup ? NAV.JobAddFormPage : query.admin_list ? NAV.AdminJobEditFormPage : NAV.JobEditFormPage}
          infoList={infoList}
          isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          editJob
          jobDetail={jobDetail}
        >
        <Row className="pt-4 ml-3 mr-4" style={{backgroundColor: 'white', borderTop: '2px solid #09819A'}}>
          <Col>
            <Row>
              <Col className="text-center mb-4">
                <JobProgressBarSection
                  active={1}
                  selectedId={selectedId}
                  isInfoOpen={params.id ? isJobEditInfo : isJobAddInfo}
                  location={this.props.location}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <EditJobFormSection
                  isLoading={isLoading}
                  jobDetail={jobDetail}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pb-5"/>
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

EditJobFormPage.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

EditJobFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(EditJobFormPage);
