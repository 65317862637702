import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from './styles.module.scss';

// HANDLE COMPONENT

export class Handle extends Component {
  state = {
    showTooltip: false
  };

  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      getHandleProps
    } = this.props;
    const { showTooltip } = this.state;

    return (
      <div>
        {showTooltip ? (
          <div
            style={{
              left: `${percent}%`,
              position: "absolute",
              marginLeft: "-11px",
              marginTop: "-30px"
            }}
          >
            <div className={styles['tooltip']}>
              <span className={styles['tooltiptext']}>Score: {value}</span>
            </div>
          </div>
        ) : (
          <div
            style={{
              left: `${percent}%`,
              position: "absolute",
              marginLeft: "-11px",
              marginTop: "-30px",
              color: '#09819a'
            }}
          >
            <div>
              <span>{id==='$$-0' ? 'Min' : 'Max'}</span>
            </div>
          </div>)
        }
        <div
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          style={{
            left: `${percent}%`,
            position: "absolute",
            marginLeft: "-11px",
            marginTop: "-8px",
            zIndex: 2,
            width: 20,
            height: 20,
            cursor: "pointer",
            borderRadius: "50%",
            boxShadow: id==='$$-1' && "1px 1px 1px 1px rgba(0, 0, 0, 0.4)",
            backgroundColor: id==='$$-0' ? "#09819A" :"#B4B5B7"
          }}
          {...getHandleProps(id, {
            onMouseLeave: () => {
              this.setState({
                showTooltip: false
              });
            },
            onMouseOver: () => {
              this.setState({
                showTooltip: true
              });
            }
          })}
        />
      </div>
    );
  }
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired
};

// TRACK COMPONENT

export function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 8,
        zIndex: 1,
        backgroundColor: "#09819A",
        borderRadius: 4,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`
      }}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired
};

// TICK COMPONENT

export function Tick({ tick, count, format }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 18,
          width: 1,
          height: 10,
          backgroundColor: "#09819A",
          left: `${tick.percent}%`
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 30,
          fontSize: 15,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`
        }}
      >
        {tick.value}
      </div>
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired
};

Tick.defaultProps = {
  format: d => d
};
