import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';
import Link from 'react-router-dom/Link';
import queryString from 'query-string';

import SortHeaderComponent from 'components/common/SortHeader';
import EditIconAtom from 'atoms/EditIcon';
import {ReactComponent as CustomSettingIcon} from './customSetting.svg'
import styles from './styles.module.scss';

const VendorTableComponent = (props) => {
  const { data, isCustomerTableFlag, goTo, updateSelected /*, location : { search }*/, approvePlanChange, rejectPlanChange, setAttributeToggler, attributeToggler} = props;

  const query = props && props.location && queryString.parse(props.location.search);
  const newData = Array.isArray(data) ? data : data.results;

  return (
    <Table hover responsive size="sm" className={ !isCustomerTableFlag ? "vendor-table" : null}>
      <thead className="Header-col">
        <tr>
          {isCustomerTableFlag
            ? null
            : <th>&nbsp;</th>
          }
          {/*<th>Action</th>*/}

          {isCustomerTableFlag
            ? <th className="pl-4">Name</th>
            : <SortHeaderComponent ordering="name" className="pl-4">Name</SortHeaderComponent>
          }
          <th className="text-right">Account Number</th>
          {isCustomerTableFlag
            ? <th className="pl-2">Date Onboard</th>
            : <SortHeaderComponent ordering="create_date" className="pl-2">Date Onboard Modified</SortHeaderComponent>
          }
          {isCustomerTableFlag
            ? <th>Commercial Status</th>
            : <SortHeaderComponent ordering="pc.commercial_status">Commercial Status</SortHeaderComponent>
          }
          {isCustomerTableFlag
            ? <th className="text-right">Current Active Users</th>
            : <SortHeaderComponent ordering="cp.employees_count" className="text-right">Current Active Users</SortHeaderComponent>
          }
          <SortHeaderComponent ordering="tdt_count" className="text-right">Distinct Employee Sessions</SortHeaderComponent>
          <th className="text-right pr-3">Entries To Be Corrected</th>
          {!isCustomerTableFlag &&
            <th className="text-center">
              Actions
            </th>
          }
        </tr>
      </thead>
      <tbody>
        {newData.map(item => (
          <tr key={item.id}>
            {isCustomerTableFlag
              ? null
              : <td title="Select to Mark Active/Inactive">
                <input
                  type="checkbox"
                  value={item.isSelected}
                  onChange={() => updateSelected(item)}
                />
              </td>
            }
            <td title={item.name} className="pl-4">
              {isCustomerTableFlag
                ? item.name
                : (<Button
                  color="link"
                  className="button2link link-hover"
                  onClick={() => goTo(item)}>
                  {item.name}
                </Button>)
              }
            </td>
            <td className="text-right" title={item.id}>{item.id}</td>
            <td className="pl-2" title={item.create_date_display}>{item.create_date_display}</td>
            <td title={item.commercial_status}>{item.commercial_status}</td>
            <td className="text-right" title={item.count_of_active_employees}>{item.count_of_active_employees}</td>
            <td className="text-right" title={item.timecards}>{item.timecards}</td>
            <td className="text-right pr-3" title={item.entries_to_be_corrected}>{item.entries_to_be_corrected}</td>
            {!isCustomerTableFlag && <td className="text-center">
              <Link 
                to={{
                    pathname: `/vendor/${item.id}/edit`,
                    search: queryString.stringify({
                    parent: JSON.stringify(query ? query : 'dashboard'),
                  })
                }}
              >
                <EditIconAtom className="cursor-pointer" />
              </Link>
              <CustomSettingIcon height="15" width="15" className="cursor-pointer ml-2" onClick={() => setAttributeToggler(!attributeToggler, item) } />
              {item.commercial_status === 'Cancellation in progress' && 
                <span>
                  <Button size="sm" className="ml-1 font-11px"
                    onClick={() => approvePlanChange(`${item.id}`)}
                  >
                    Approve
                  </Button>
                  <Button color="default" size="sm" className={`ml-1 font-11px ${styles['reject-button']}`}
                    onClick={() => rejectPlanChange(`${item.id}`)}
                  >
                    Reject
                  </Button>
                </span>}
            </td>}
          </tr>
        ))}
        {!data.length &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
    </Table>
  );
};

VendorTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  goTo: PropTypes.func,
  updateSelected: PropTypes.func.isRequired,

};

export default VendorTableComponent;
