import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderWithSeparateKeysComponent from 'components/common/SortHeader/SeparateKeys';

const EmployeeProficiencyReportTableComponent = (props) => {
  const {  data, location: { query }, pageSize, navigation } = props;
  const { page = 1 } = query;
  return (
    <div className="ml-3 mr-4">
    <Table hover responsive size="sm" className="align-table align-firstcol">
      <thead className="Header-col">
        <tr>
          <SortHeaderWithSeparateKeysComponent field='employee_id'>
            Employee ID
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='employee_name'>
            Employee Name
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='manual_correction_count'>
            Manual Correction
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='force_time_out_count'>
            Force Timeout
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='missing_meal_count'>
            Missing Meal
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='delayed_meal_count'>
            Delay Meal
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='nosql'>
            Nosql
          </SortHeaderWithSeparateKeysComponent>
          <SortHeaderWithSeparateKeysComponent field='total'>
            Total
          </SortHeaderWithSeparateKeysComponent>
        </tr>
      </thead>
      <tbody>
      {data
          .filter((item, index) =>
            index < page * pageSize && index >= (page - 1) * pageSize
          )
          .map(item => (
            <tr key={item.index} onClick={() => navigation(item)}>
              <td className='pl-3' title={item.employee_id}>{item.employee_id}</td>
              <td className='cursor-pointer' style={{color:'#0F2BB4'}} title={item.employee_name}>{item.employee_name}</td>
              <td className='' title={item.manual_correction_count}>{item.manual_correction_count}</td>
              <td className='' title={item.force_time_out_count}>{item.force_time_out_count}</td>
              <td className='' title={item.missing_meal_count}>{item.missing_meal_count}</td>
              <td className='' title={item.delayed_meal_count}>{item.delayed_meal_count}</td>
              <td className='' title={item.nosql}>{item.nosql}</td>
              <td className='text-right pr-4' title={item.total}>{item.total}</td>
            </tr>
          ))
        }        
        {!data.length && Object.keys(query).length > 0 &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
        {!data.length && Object.keys(query).length === 0 &&
          <tr>
            <td className="text-center" colSpan="10">Search above for results</td>
          </tr>
        }
      </tbody>
    </Table>
    </div>
  );
};

EmployeeProficiencyReportTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

EmployeeProficiencyReportTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EmployeeProficiencyReportTableComponent;
