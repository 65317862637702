import StorageGateway from 'lib/storage-gateway';
import GraniteError from 'lib/granite-error';
import EventEmitter from 'lib/event-emitter';
import { server } from 'helpers/config';
import cookie from 'react-cookies';

const EVENT_TYPES = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  TWO_FACTOR_AUTHENTICATE: 'TWO_FACTOR_AUTHENTICATE',
};

class SignInUseCase {

  userAPIGateway = null;


  constructor(userAPIGateway) {
    this.userAPIGateway = userAPIGateway;
    this.eventEmitter = new EventEmitter();
  }

  toString() {
    return 'SignInUseCase';
  }

  getObservable() {
    return this.eventEmitter.getObservable();
  }

  setup_data(data) {
    this.email = data.email;
    this.password = data.password;
    this.device = 'WEB';
    this.grant_type= "password";
    this.client_id = server.ClientId;
    this.client_secret= server.ClientSecret;
  }

  async execute() {
    let errors = {};

    if (!this.email) {
      errors.email = 'Enter Email or Username';
    }
    if (!this.password) {
      errors.password = 'Enter Password';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      throw new GraniteError(errors);
    }
    let response = null;
    response = await this.userAPIGateway.signIn(this.email, this.password, this.device);
    
    if(response.OTP_id) {
      const otpId = response.OTP_id;
      this.eventEmitter.emit({
        type: EVENT_TYPES.TWO_FACTOR_AUTHENTICATE,
        data : otpId,
        password: this.password,
      });
    }
    else{
      let domain = window.location.hostname;
      const host = window.location.hostname.split('.');
      if (host[0].indexOf('admin') >= 0) domain = `.${host[1]}.${host[2]}`;
      StorageGateway.set('Token', response.token);
      cookie.save('shiftAuthToken', response.token, { path: '/', domain });
      const oAuthToken = await this.userAPIGateway.postAuthentication(this.client_id, this.client_secret, this.device, this.grant_type, this.password, response.username );
      StorageGateway.set('oAuthToken', oAuthToken);
      const profile = await this.userAPIGateway.getProfile()
        this.eventEmitter.emit({
        type: profile.password_expiry < 90 ? EVENT_TYPES.LOGIN_SUCCESS : EVENT_TYPES.LOGIN_FAILURE,
        data : profile
      });
      StorageGateway.set('nre_company_id', profile?.company?.id ?? '');
      const graniteAccess = await this.userAPIGateway.getGraniteToken();
      StorageGateway.set('userId', graniteAccess?.user_id ?? null);
      StorageGateway.set('graniteToken', graniteAccess?.token ?? null);
    }
  }
}
export default SignInUseCase;
