import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import EditIconAtom from 'atoms/EditIcon';
import ActiveIconAtom from 'atoms/ActiveIcon';
import { NOTIFICATION_HOVER_MESSAGE_OPTIONS } from 'constants/vendors';

/**
 * NotificationSettingListPage -> NotificationSettingListSection -> NotificationSettingTableComponent
 *
 * Props:
 *    - notification setting list
 */
const NotificationSettingTableComponent = (props) => {
  const { data, toggle } = props;
  const hhmm = sec => `${Math.floor(sec / 3600)} hours ${Math.floor((sec % 3600) / 60)} minutes`;

  return (
    <div className="ml-3 mr-4">
      <Table hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <th className="text-center">Action</th>
          <th width="20%">Is Active</th>
          <th>Description</th>
          <th>Notification Time (After)</th>
          <th>Notification Message</th>
        </tr>
      </thead>
      <tbody className="timeCard-table font-colorInput">
        {data.results && data.results.map(item => (
          <tr key={item.id}>
            <td title="Edit Setting" className="text-center">
              <EditIconAtom className="cursor-pointer" onClick={() => toggle(item)} />
            </td>
            {item.is_active ? <td>Yes</td> : <td>No</td>}
            <td title={NOTIFICATION_HOVER_MESSAGE_OPTIONS[item.notification_type]}>{item.description}</td>
            <td title={hhmm(item.notification_after)}>{hhmm(item.notification_after)}</td>
            <td title={item.notification_message}>{item.notification_message}</td>
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="5">No Records Found</td></tr>
        }
      </tbody>
      </Table>
    </div>
  );
};

NotificationSettingTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default NotificationSettingTableComponent;
