import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as InfoDucks from 'ducks/info/info';
import * as GroupDucks from 'ducks/vendors/group';
import * as RoleDucks from 'ducks/vendors/role';

import TwoColumnLayout from 'layouts/TwoColumn';
import RoleFormSection from 'sections/vendors/roleForm/Form';

import { NAV } from 'constants/vendors';

/**
 * RoleFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link VendorFormSection}
 *
 * Fetch Needs:
 *    - get vendor detail
 */
class RoleFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isInfoOpen: true, parent: NAV.VendorRoleAddFormPage.parent };
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("vendor_role_setting_add");
    if(cookieKey) {
      this.setState({isInfoOpen: JSON.parse(cookieKey)});
    }
    this.loadData();
    this.setParentUrl();
  }

  loadData() {
    const { router: { route: { match: { params } } } } = this.context;
    const route = params.id ? 'vendor.role_setting.edit' : 'vendor.role_setting.add';
    this.props.InfoDucks.getInformations({ route });
    this.setState({ isLoading: true });
    if(params.id){
      return Promise.all([
        this.props.GroupDucks.getGroups({ paginate: false }),
        this.props.RoleDucks.getRole({ id: params.id }), 
      ])
        .finally(() => this.setState({ isLoading: false }));
    }
    return this.props.GroupDucks.getGroups({ paginate: false })
      .finally(() => this.setState({ isLoading: false }));
  }

  componentWillUnmount(){
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl() {
    const { location: { query } } = this.props;
    try {
      if (query.parent) {
        this.setState({ parent: query.parent });
      }
    } catch (e) {
      console.log(e);
    }
  }


  render() {
    const { isLoading, isInfoOpen, parent } = this.state;
    const { router: { route: { match: { params } } } } = this.context;
    const { infoList } = this.props;
    return (
      <TwoColumnLayout 
        navInfo={params.id ? { ...NAV.VendorRoleEditFormPage, parent }: { ...NAV.VendorRoleAddFormPage, parent }}
        infoList={infoList}
        toggleInfo={this.toggleInfo}
        isInfoOpen={isInfoOpen}
        fetchData={this.loadData}
      >
        <RoleFormSection
          isLoading={isLoading}
        />
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  GroupDucks: bindActionCreators(GroupDucks, dispatch),
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
});

RoleFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

RoleFormPage.propTypes = {
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(RoleFormPage);
