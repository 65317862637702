import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_TUTORIAL = 'vendors/onboard/GET_TUTORIAL';
const GET_TUTORIAL_FULFILLED = 'vendors/onboard/GET_TUTORIAL_FULFILLED';
const POST_TUTORIAL = 'vendors/onboard/POST_TUTORIAL';
const POST_TUTORIAL_FULFILLED = 'vendors/onboard/POST_TUTORIAL_FULFILLED';
const POST_SEND_SMS = 'vendors/onboard/POST_SEND_SMS';
const POST_SEND_SMS_FULFILLED = 'vendors/onboard/POST_SEND_SMS_FULFILLED';

export function getTutorial(params) {
  return {
    type: GET_TUTORIAL,
    payload: axios.get(URLS.COMPANY_TUTORIAL, { params }),
  };
}

export function postTutorial(data) {
  return {
    type: POST_TUTORIAL,
    payload: axios.post(URLS.COMPANY_TUTORIAL, data),
  };
}

export function postSendSms(data) {
  return {
    type: POST_SEND_SMS,
    payload: axios.post(URLS.SEND_SMS, data),
  };
}



const defaultState = {
  detail: {},
};

function GET_TUTORIAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_TUTORIAL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_SEND_SMS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_TUTORIAL_FULFILLED]: GET_TUTORIAL_FULFILLED_REDUCER,
  [POST_TUTORIAL_FULFILLED]: POST_TUTORIAL_FULFILLED_REDUCER,
  [POST_SEND_SMS_FULFILLED]: POST_SEND_SMS_FULFILLED_REDUCER,
};


const onboardSelector = state => state.vendors.onboard;

export const tutorialDetail = createSelector(
  onboardSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
