import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as TimecardDucks from 'ducks/timecards/timecard';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import TimecardTableComponent from 'components/timecards/customList/CustomTable';

import { MSGS } from 'constants/timecards';

/**
 * TimecardListPage -> TimecardListSection
 *
 * Components:
 *    - {@link TimecardTableComponent}
 *
 * State:
 *    - timecard list
 *
 * Actions:
 *    None
 */
class CustomTimecardListSection extends React.Component {
  constructor(props) {
    super(props);
    this.approveTimecard = this.approveTimecard.bind(this);
  }

  approveTimecard(data) {
    const { location: { query } } = this.props;
    return this.props.TimecardDucks.putTimecard(data)
      .then(() => {
        toast.success(MSGS.TIMECARD_APPROVAL_SUCCESS);
        this.props.TimecardDucks.getTimecards(query);
      });
  }



  render() {
    const { timecardList, location, isLoading, payrollAll, showableAttributes , userExtraData, updateSelected,updateAll} = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <TimecardTableComponent
          data={timecardList}
          payrollAll={payrollAll}
          location={location}
          approveTimecard={this.approveTimecard}
          updateSelected={updateSelected}
          updateAll={updateAll}
          showableAttributes={showableAttributes}
          userExtraData={userExtraData}
        />
        <PaginationComponent
          count={timecardList.count}
          location={location}
          cookieKey="timecard_page_size"
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  timecardList: TimecardDucks.timecardList(state),
  payrollAll: PayrollDucks.payrollAll(state),
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
  TimecardDucks: bindActionCreators(TimecardDucks, dispatch),
});

CustomTimecardListSection.propTypes = {
  timecardList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomTimecardListSection);
