import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import PayrollSettingTableComponent from 'components/payrolls/setting/Table';

/**
 * PayrollSettingListPage -> PayrollSettingListSection
 *
 * Components:
 *    - {@link PayrollSettingTableComponent}
 *
 * State:
 *    - Payroll Setting list
 *
 * Actions:
 *    None
 */
const PayrollSettingListSection = (props) => {
  const { isLoading, payrollSettingList, location, userExtraData} = props;

  if (isLoading) return <SectionLoaderAtom active />;

  return (
    <section>
      <PayrollSettingTableComponent data={payrollSettingList} userExtraData={userExtraData}/>
      <PaginationComponent
        count={payrollSettingList.count}
        location={location}
        cookieKey="payroll_setting_page_size"
      />
    </section>
  );
}

const mapStateToProps = (state) => ({
  payrollSettingList: PayrollSettingDucks.payrollSettingList(state),
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
});

const mapActionsToProps = dispatch => ({
  PayrollSettingDucks: bindActionCreators(PayrollSettingDucks, dispatch),
});

PayrollSettingListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  payrollSettingList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

PayrollSettingListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PayrollSettingListSection);
