import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as NotificationSettingDucks from 'ducks/vendors/notificationSetting';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import NotificationSettingTableComponent from 'components/vendors/notificationSettingList/Table';
import NotificationSettingFormModalComponent from 'components/vendors/notificationSettingList/FormModal';

import { MSGS } from 'constants/vendors';


/**
 * NotificationSettingListPage -> NotificationSettingListSection
 *
 * Components:
 *    - {@link NotificationSettingTableComponent}
 *    - {@link NotificationSettingFormModalComponent}
 *
 * State:
 *    - notificationSettingList
 *
 * Actions:
 *    None
 */
class NotificationSettingListSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.update = this.update.bind(this);
    this.state = { isOpen: false, isActive: null };
  }

  onToggle(val) {
    const { notificationSettingDetail } = this.props;
    this.props.NotificationSettingDucks.toggleNotificationStatus({...notificationSettingDetail, is_active: val});
  }

  update(data) {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;

    return this.props.NotificationSettingDucks.putNotificationSetting(data)
      .then(() => {
        toast.success(MSGS.NOTIFICATION_SETTING_UPDATE_SUCCESS);
        this.props.NotificationSettingDucks.getNotificationSettings({ ...params, ...query });
        this.toggle();
      });
  }

  toggle(data) {
    const { isOpen } = this.state;
    if (isOpen) return this.setState({ isOpen: !isOpen });
    return this.props.NotificationSettingDucks.getNotificationSetting(data)
      .then(() => this.setState({ isOpen: !isOpen }));
  }

  render() {
    const { isLoading, notificationSettingDetail, notificationSettingList, location } = this.props;
    const { isOpen } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <NotificationSettingTableComponent
          data={notificationSettingList}
          toggle={this.toggle}
        />
        {isOpen &&
          <NotificationSettingFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            update={this.update}
            initialValues={{
              ...notificationSettingDetail,
              hour: Math.floor(notificationSettingDetail.notification_after / 3600),
              minute: Math.floor((notificationSettingDetail.notification_after % 3600) / 60),
            }}
            notificationStatus={notificationSettingDetail.is_active}
            onToggle={this.onToggle}
          />
        }
        <PaginationComponent
          count={notificationSettingList.count}
          location={location}
        />
      </section>
    );
  }
}

const mapStateToProps = state => ({
  notificationSettingDetail: NotificationSettingDucks.notificationSettingDetail(state),
  notificationSettingList: NotificationSettingDucks.notificationSettingList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  NotificationSettingDucks: bindActionCreators(NotificationSettingDucks, dispatch),
});

NotificationSettingListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  notificationSettingDetail: PropTypes.object.isRequired,
  notificationSettingList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  NotificationSettingDucks: PropTypes.object.isRequired,
};

NotificationSettingListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(NotificationSettingListSection);
