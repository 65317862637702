import React from 'react';

import { FormGroup, Input , Card , Row, Col } from 'reactstrap';
import FloatLabelInput from 'components/common/FloatingLabelInput';
import styles from './styles.module.scss';

import EditorAtom from 'surveys/components/editorAtom';

import EditorFloatAtom from './EditorFloatAtom';

const FreeSelectFloatComponent = (props) => {
  const { data , index, disabled, showSpLabel } = props;
  return (
    <Card className={`${styles['card-ui']} mr-3 pl-3 pr-3 pt-3 pb-3`}>
      <Row>
        <Col md={12} className={`${styles['custom-col']}`} style={{display: 'block'}}>
          <span><span className={`${styles['req-color']}`}>{((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? '*' : null}</span>{index + 1}.</span>
          <EditorFloatAtom value={showSpLabel ? data.sp_question : data.question} id={`question_${index}`} name={`question_${index}`} data={data} mandatory={((data.behaviour && data.behaviour === 'MANDATORY') || (props.mandatory)) ? true : false} handleChange={props.handleChange} disabled={disabled} onChange={props.onChange} index={index} key={showSpLabel ? `question_${index}_spanish`: `question_${index}_english`}/>
        </Col>
      </Row>
      <div className={`${styles['resize-disabled']} mt-3`}>
        <FormGroup>
          <Input
            type="textarea"
            id={`${index}`}
            name={`${index}`}
            placeholder="Write Here.."
            value={data.response && data.response.length ? data.response[0].value : null}
            disabled
          />
        </FormGroup>
      </div>
    </Card>
  );
};

export default FreeSelectFloatComponent;