import React from 'react';

import cookie from 'react-cookies';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as TaskDucks from 'ducks/jobs/task';
import * as TaskTagDucks from 'ducks/jobs/taskTag';
import * as RouteDucks from 'ducks/routes';
import * as JobTaskDucks from 'ducks/jobs/jobTaskMapping';
import * as JobDucks from 'ducks/jobs/job';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import JobTaskMappingTableComponent from 'components/jobs/jobTaskMappingList/TaskTable';
import JobTaskMappingFormModalComponent from 'components/jobs/jobTaskMappingList/FormModal';
import TagModalComponent from 'components/jobs/form/TagModal';

/**
 * JobTaskMappingListPage -> JobTaskMappingListSection
 *
 * Components:
 *    - {@link EmployeeTableComponent}
 *
 * State:
 *    - task list
 *
 * Actions:
 *    None
 */
class JobTaskMappingListSection extends React.Component {
  constructor(props) {
    super(props);
    this.updateSelected = this.updateSelected.bind(this);
    this.state = { isOpen: false, isTagModal: false,taskDetail:null,isAssociated:'',jobsAssociated:[], isModalHidden:false};
    this.toggle = this.toggle.bind(this);
    this.createTag = this.createTag.bind(this);
    this.editTask = this.editTask.bind(this);
    this.deleteTask = this.deleteTask.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.associatedJob = this.associatedJob.bind(this);
    this.togglePop = this.togglePop.bind(this);
    this.deleteAssociatedJob = this.deleteAssociatedJob.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  
  // componentWillMount(){
    // const { actionsRequired} = this.props;
    // if (!actionsRequired) {
    //   this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
    // }
  // }

  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }

  getUpdatedList(){
    this.props.TaskTagDucks.getTaskTags({ paginate: false, tag_type: 1 });
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  deleteTask(data) {
    const {location:{pathname, search} } = this.props;
    const { router: { history } } = this.context;
    const query = queryString.parse(search);
    return this.props.TaskDucks.deleteTask({...query,id:data.id})
      .then(() => {
        toast.success("Task Deleted  successfully!");
        history.push({
          pathname,
          search:queryString.stringify({...query,id:data.id,page:1})
        })
        this.props.TaskDucks.getTasks({ ...query,page:1});
      })
      .catch((err) => {

        toast.error(err.non_field_errors[0]);
      })
  }

  toggle(data) {
    const { isOpen } = this.state;
    if(!data || !data.id) return this.setState({ isOpen: !isOpen });
    if(!isOpen){
      this.setState({taskDetail:data})
      this.setState({ isOpen: !isOpen });
    }
    return true;
  }
  
  createTag(data) {
    return this.props.TaskTagDucks.postTaskTag({ ...data, tag_type: 1 })
  }

  editTask(data) {
    const { isOpen } = this.state;
    const { location } = this.props;
    const {query} = location;
    const param = {task_id: query.task_id};
    const pageSize = cookie.load('task_mapping_size');
    return this.props.TaskDucks.putTask(data,param)
      .then(() => {
        this.props.TaskDucks.getTasks({ ...query, page_size: pageSize })
        this.setState({ isOpen: !isOpen });
      });
  }

  updateSelected(data) {
    return this.props.TaskDucks.selectTasks(data);
  }

  associatedJob(data){
    const { isAssociated ,jobsAssociated} = this.state;
    let taskid=data.id;
    return this.props.TaskDucks.assignedJob({task_id: taskid, paginate: false, admin: true})
      .then((result) =>{
          this.setState({jobsAssociated: result.value});
          this.setState({ isAssociated: data.id });
      })
      .catch((err) => {
        err && err.non_field_errors && toast.error(err.non_field_errors[0]);
      });
  }

  togglePop(){
    const {isAssociated} = this.state;
    this.setState({isAssociated: false , jobsAssociated:[]})
  }


  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  deleteAssociatedJob(data){
    const {isAssociated,jobsAssociated} = this.state;
    const detail = {
      assigned: false,
      id: data,
      task_ids: [isAssociated],
    };
    return this.props.JobTaskDucks.putJobTaskMapping(detail)
      .then(() =>{
        toast.success('Job Deleted Successfully');
        this.props.TaskDucks.assignedJob({task_id: isAssociated, paginate: false, admin: true})
          .then((result) =>{
            this.setState({jobsAssociated: result.value});
      })
      })
  }

  render() {
    const { isLoading, taskList, location,taskTagList,taskAll,actionsRequired} = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const { isOpen, isTagModal, taskDetail, isAssociated ,jobsAssociated, isModalHidden} = this.state;
    let newTags;
    if(taskDetail && taskDetail.id) {
      newTags = taskDetail.tags.map((item) => {
        const data = {};
        data.id = item;
        data.name = item;
        return data;
      });
    }
    const tags = taskTagList&& taskTagList.length && taskTagList[0].tag_collection && taskTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section className="task-list">
        <JobTaskMappingTableComponent
          data={params.id?taskAll:taskList}
          actionsRequired={actionsRequired}
          updateSelected={this.updateSelected}
          deleteTask={this.deleteTask}
          location={location}
          toggle={this.toggle}
          jobDetail={{}}
          associatedJob={this.associatedJob}
          isAssociated={isAssociated}
          togglePop = {this.togglePop}
          jobsAssociated={jobsAssociated}
          deleteAssociatedJob = {this.deleteAssociatedJob}
          selectTask = {this.props.selectTask}
          selectedTasks={this.props.selectedTasks}
        />
        {isOpen &&
          <JobTaskMappingFormModalComponent
            tagList={taskTagList}
            isOpen={isOpen}
            toggle={this.toggle}
            create={this.editTask}
            initialValues={{
              ...taskDetail,
              tags: newTags,
            }}
            toggleTagModal={this.toggleTagModal}
            isModalHidden={isModalHidden}
          />
        }
        {isTagModal &&
          <TagModalComponent
            isModalOpen={isTagModal}
            toggle={this.toggleTagModal}
            createTag={this.createTag}
            tagType="1"
            tagsList={tags}
            tagsCollection={taskTagList}
            deleteSelectedTags={this.deleteSelectedTags}
            updateTagName={this.updateTagName}
            getUpdatedList={this.getUpdatedList}
            hideModal={this.hideModal}
          />
        }
        {params.id
          ?null
          :<PaginationComponent
            count={taskList.count}
            location={location}
            cookieKey="task_mapping_size"
          />
        }
       
      </section>
    );
  }
}

const mapStateToProps = state => ({
  taskList: TaskDucks.taskList(state),
  taskAll: TaskDucks.taskAll(state),
  location: RouteDucks.location(state),
  taskTagList: TaskTagDucks.taskTagList(state),
});

const mapActionsToProps = dispatch => ({
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
  JobTaskDucks: bindActionCreators(JobTaskDucks, dispatch),
  JobDucks : bindActionCreators(JobDucks, dispatch)
});

JobTaskMappingListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  taskList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  TaskDucks: PropTypes.object.isRequired,
};

JobTaskMappingListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobTaskMappingListSection);
