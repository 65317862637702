import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';

import { UncontrolledDropdown } from 'reactstrap/lib/Uncontrolled';

import styles from './styles.module.scss';

class PaginationComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageCount = this.handlePageCount.bind(this);
  }

  handlePageChange (page) {
    const { router: { history } } = this.context;
    const {  location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    history.push({
      pathname, search: queryString.stringify({ ...query, page }),
    });
  }

  handlePageCount (pageNo) {
    const { router: { history } } = this.context;
    const {  location: { pathname , search }, cookieKey } = this.props;
    const query = queryString.parse(search)
    const { page , ...rest } = query;

    if (cookieKey) cookie.save(cookieKey, pageNo, { path: '/' });
    history.push({
      pathname, search: queryString.stringify({ ...rest, page_size: pageNo }),
    });
  }

  render () {
    const { count, location: {  search }, size , pageCount, cookieKey, displayCount, hidePageSizeChange } = this.props;
    const query = queryString.parse(search);
    let filteredPageCount = {};
    let pageSize;
    if(query.page_size) {
      pageSize = +query.page_size;
      filteredPageCount = pageCount.filter(item => pageSize.toString() === item.value.toString())[0];
    }

    else if (cookieKey) {
      pageSize = cookie.load(cookieKey);
      if (pageSize) {
        filteredPageCount = pageCount.filter(item => pageSize.toString() === item.value.toString())[0];
      }
      else pageSize = 10;
    }
    else {
      pageSize = 10;
    }


    return (
      <Row className="mt-2 ml-3 mr-4 pb-5" style={{paddingBottom:'3rem !important'}}>
        <Col md={3} className="px-0">
          {!hidePageSizeChange && pageCount && count > 10 &&
            <div>
              <UncontrolledDropdown size={size} >
                <DropdownToggle
                  className ={styles["pagination-button"]}
                  caret color="accent"
                  title={(filteredPageCount && filteredPageCount.label) || pageCount[0].label}
                >
                  {(filteredPageCount && filteredPageCount.label) || pageCount[0].label}
                </DropdownToggle>
                <DropdownMenu className ={styles["paginate-dropdown-menu"]}>
                  {pageCount.map(item =>
                    <DropdownItem key={item.value} onClick={() => this.handlePageCount(item.value)}>
                      {item.label}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          }
        </Col>
        <Col md={9} className="px-0">
          { count > pageSize &&
            <section className="float-right">
              <Pagination
                activePage={+query.page || 1}
                itemClass="page-item"
                itemsCountPerPage={+pageSize}
                prevPageText="&#8249;"
                nextPageText="&#8250;"
                linkClass="page-link"
                totalItemsCount={count}
                onChange={this.handlePageChange}
              />
            </section>
          }
           <p className={`d-inline-block mt-2 float-right mb-0 mr-3 ${styles['pagination-totalRecords']}`}>Total Records: {!displayCount ? count : displayCount}</p>
        </Col>
      </Row>
    );
  }
}

PaginationComponent.defaultProps = {
  count: 0,
  pageCount : [
    {label:"10/Page" , value: 10},
    {label:"20/Page" , value: 20},
    {label:"50/Page" , value: 50},
    {label:"100/Page" , value: 100},
  ]
};

PaginationComponent.propTypes = {
  count: PropTypes.number,
  location: PropTypes.object.isRequired,
};

PaginationComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default PaginationComponent;