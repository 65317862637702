import React from 'react';

import PropTypes from 'prop-types';

import Button from 'reactstrap/lib/Button';
import Card from 'reactstrap/lib/Card';
import CardText from 'reactstrap/lib/CardText';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import ManualCorrectionSUMMARYImage from './manual_correction.png';
import OvertimeSummaryImage from './overtime_summary.png';
import MealViolationImage from './meal_violation.png';
import EmployeeDailyimecardImage from './employee_daily_timecard.png';
import GeoFenceBreachImage from './geo_fence_breach.png';
import MealFlagDetailImage from './meal-flags-details.png';
import CorrectionReportImage from './correction-report.png';
import GeneralAuditReportImage from './general_audit_icon.png';
import EmployeeProficiencyImage from './proficiency_report.png';
import styles from './styles.module.scss';

/**
 * ReportListPage -> ReportListSection -> CORRECTION-REQUEST-REPORTReportGridComponent
 *
 * Props:
 *    - reportList
 */
const ReportGridComponent = (props) => {
  const { data, goTo } = props;
  const Image = {
    "MANUAL-CORRECTION-SUMMARY": ManualCorrectionSUMMARYImage,
    "OVERTIME-SUMMARY": OvertimeSummaryImage,
    "MEAL-VIOLATION": MealViolationImage,
    "EMPLOYEE-WISE-DAILY-TIMECARDS": EmployeeDailyimecardImage,
    "GEO-FENCE-BREACH-SUMMARY": GeoFenceBreachImage,
    "MEAL-FLAG-DETAIL": MealFlagDetailImage,
    "CORRECTION-REQUEST-REPORT":CorrectionReportImage,
    "GENERAL-AUDIT-REPORT": GeneralAuditReportImage,
    "EMPLOYEE-PROFICIENCY-REPORT": EmployeeProficiencyImage
  };



  return (
    <Row className="ml-3 mr-4 pb-5">
      {data.results && data.results.map(item =>
        <Col sm="12" md="6" className="mb-4 px-0" key={item.id}>
          <Card block className={`${styles['report-wrap']} card-block`}>
            <Row>
              <Col sm="4" className={styles['image-background']}>
                <img className={styles['report-wrap--icon']} src={Image[item.code]} alt="Icon" />
              </Col>    
              <Col sm="8" className={`${styles['report-wrap--title']} pt-4`}>
                <span className={styles['txt-alignment']}>{item.name}</span><br />
                <CardText className="mt-2">
                  <small className={styles['report-wrap__desc']}>{item.description}</small>
                </CardText><br/>
                <small>
                  <Button
                    outline
                    color="primary"
                    size="sm"
                    onClick={() => goTo(`/report/${item.id}/${item.code}/detail`)}
                    className={styles['report-wrap--my-3']}
                  >
                    View  
                  </Button>
                </small>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
    </Row>
  );
};

ReportGridComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReportGridComponent;
