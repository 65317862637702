import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import { toTitleCase } from 'helpers/utils';
import URLS from './urls';

const GET_TEAMS = 'vendors/team/GET_TEAMS';
const GET_TEAMS_FULFILLED = 'vendors/team/GET_TEAMS_FULFILLED';
const GET_ALL_TEAMS = 'vendors/team/GET_ALL_TEAMS';
const GET_ALL_TEAMS_FULFILLED = 'vendors/team/GET_ALL_TEAMS_FULFILLED';
const GET_TEAM = 'vendors/team/GET_TEAM';
const GET_TEAM_FULFILLED = 'vendors/team/GET_TEAM_FULFILLED';
const POST_TEAM = 'vendors/team/POST_TEAM';
const POST_TEAM_FULFILLED = 'vendors/team/POST_TEAM_FULFILLED';
const PUT_TEAM = 'vendors/team/PUT_TEAM';
const PUT_TEAM_FULFILLED = 'vendors/team/PUT_TEAM_FULFILLED';
const RESET_TEAM = 'employees/employee/RESET_TEAM';
const DELETE_TEAM = 'vendors/team/DELETE_TEAM';
const GET_TEAM_IDS = 'vendors/team/GET_TEAM_IDS';
const GET_TEAM_IDS_FULFILLED = 'vendors/team/GET_TEAM_IDS_FULFILLED';

export function getTeams(params) {
  return {
    type: GET_TEAMS,
    payload: axios.get(URLS.TEAM, { params }),
  };
}

export function getAllTeams(params) {
  return {
    type: GET_ALL_TEAMS,
    payload: axios.get(URLS.TEAM, { params }),
  };
}

export function getTeam(params) {
  return {
    type: GET_TEAM,
    payload: axios.get(`${URLS.TEAM}${params.id}/`),
  };
}

export function putTeam(data) {
  return {
    type: PUT_TEAM,
    payload: axios.put(`${URLS.TEAM}${data.id}/`, data),
  };
}

export function postTeam(data) {
  return {
    type: POST_TEAM,
    payload: axios.post(URLS.TEAM, data),
  };
}

export function deleteTeam(data){
  return{
    type:DELETE_TEAM,
    payload:axios.delete(`${URLS.DELETE_TEAM}${data.id}/`)
  };
}

export function resetTeam(data) {
  return {
    type: RESET_TEAM,
  };
}

export function getTeamIds(data) {
  return {
    type: GET_TEAM_IDS,
    payload: axios.get(`${URLS.REORDERED_IDS}?entity=team`, data),
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
  all: [],
  teamIdsList: []
};

function GET_TEAMS_FULFILLED_REDUCER(state, action) {
  const arr = action.payload.results ? action.payload.results : action.payload;
  return Object.assign({}, state, {
    list: { ...action.payload, results: arr },
  });
}

function GET_ALL_TEAMS_FULFILLED_REDUCER(state, action) {
  const arr = action.payload;
  return Object.assign({}, state, {
    all: arr,
  });
}

function GET_TEAM_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function POST_TEAM_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_TEAM_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function RESET_TEAM_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

function GET_TEAM_IDS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    teamIdsList: action.payload,
  });
}


const handlers = {
  [GET_TEAMS_FULFILLED]: GET_TEAMS_FULFILLED_REDUCER,
  [GET_ALL_TEAMS_FULFILLED]: GET_ALL_TEAMS_FULFILLED_REDUCER,
  [GET_TEAM_FULFILLED]: GET_TEAM_FULFILLED_REDUCER,
  [POST_TEAM_FULFILLED]: POST_TEAM_FULFILLED_REDUCER,
  [PUT_TEAM_FULFILLED]: PUT_TEAM_FULFILLED_REDUCER,
  [RESET_TEAM]: RESET_TEAM_REDUCER,
  [GET_TEAM_IDS_FULFILLED]: GET_TEAM_IDS_FULFILLED_REDUCER
};


const teamSelector = state => state.vendors.team;

export const teamList = createSelector(
  teamSelector,
  instance => instance.list.results
);

export const teamAll = createSelector(
  teamSelector,
  instance => instance.all
);

export const teamDetail = createSelector(
  teamSelector,
  instance => instance.detail
);

export const teamIdsList = createSelector(
  teamSelector,
  instance => instance.teamIdsList
);

export default createReducer(defaultState, handlers);
