import React from 'react';

import PropTypes from 'prop-types';

import MultiSelectInlineComponent from 'components/common/MultiSelectInlineComponent';
import SingleSelectInlineComponent from 'components/common/SingleSelectInlineComponent';
import FreeSelectInlineComponent from 'components/common/FreeSelectInlineComponent';
import DateTimeCommentComponent from 'components/common/DateTimeCommentComponent';
import TimeCommentComponent from 'components/common/TimeCommentComponent';

import styles from './styles.module.scss';

class FeedbackCommentModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { feedbackResponse } = this.props;
    return (
        <div>
            {/* <div>Feedback :</div>
            <div className={`${styles['custom-survey-label-comment']}`}>{feedbackResponse[0].label}</div> */}
            <div>
            {feedbackResponse && feedbackResponse.length && feedbackResponse.map((item,index)=>
            <div>
              {(item.definition == 'single_select' || item.definition == 'SINGLE_SELECT') &&
                <div>
                  <SingleSelectInlineComponent data={item} index={index} noCard={true} auditComponent={false}/>
                </div>
              }
              {(item.definition == 'multi_select' || item.definition == 'MULTI_SELECT') &&
                <div>
                  <MultiSelectInlineComponent data={item} index={index} noCard={true} auditComponent={false}/>
                  </div>
              }
              {(item.definition == 'free_text' || item.definition == 'FREE_TEXT') &&
                <div>
                  <FreeSelectInlineComponent data={item} index={index} noCard={true} auditComponent={false}/>
                </div>
              }
              {(item.definition == 'boolean' || item.definition == 'BOOLEAN') &&
                <div>
                  <SingleSelectInlineComponent data={item} index={index} noCard={true} auditComponent={false}/>
                </div>
              }
              {(item.definition == 'date_time_select' || item.definition == 'DATE_TIME_SELECT') &&
                <div>
                  <DateTimeCommentComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} auditComponent={false}/>
                </div>
              }   
              {(item.definition == 'time_select' || item.definition == 'TIME_SELECT') &&
                <div>
                  <TimeCommentComponent data={item} index={index} slider="timecardslider" dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={this.props.dateFormat} timeFormat={this.props.timeFormat} auditComponent={false}/>
                </div>
              }
            </div>
          )}
        </div>
        </div>
    );
  }
};

export default FeedbackCommentModal;
