// import React from 'react';

// import PropTypes from 'prop-types';

// import { Editor } from "@tinymce/tinymce-react";
// import './editor.css'

// class EditorAtom extends React.PureComponent {
//   constructor(props){
//     super(props);
//   }

//   render() {
//     const {onChange , onBlur,item, index, placeholder } = this.props;
//     return (
//       <Editor
//         init={{
//           selector: '#questionEditor',
//           indentation: '20pt',
//           content_style: "body {font-size: 12pt;  style_format: bold;}",
//           placeholder: placeholder,
//           height: 150,
//           menubar: false,
//           branding: false,
//           statusbar: false,
//           plugins: 'lists',
//           toolbar: 'alignleft aligncenter alignright | bold italic | bullist numlist',
//           formats: {
//             'custom-wrapper': { block: 'div', classes: 'wrapper', wrapper: true }
//           }
//         }}
//         onEditorChange={this.props.onChange}
//         value={this.props.value || this.props.label}
//       />
//     );
//   }
// }


// export default EditorAtom;




import React from 'react';
import PropTypes from 'prop-types';
import 'devextreme/dist/css/dx.light.css';
import HtmlEditor, {
  Toolbar,
  Item,
} from 'devextreme-react/html-editor';

class EditorAtom extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onValueChanged = this.onValueChanged.bind(this);
    this.generateToolbar = this.generateToolbar.bind(this);
  }

  onValueChanged = (e) => {
    this.props.onChange(e.value);
  };

  generateToolbar = (toolbarItems, multilineToolbar, toolbarPosition) => {
    if (toolbarItems.split(',').length === 0) return <div></div>;

    return (
      <Toolbar multiline={multilineToolbar} container={toolbarPosition}>
        {toolbarItems.split(',').map((item, index) => {
          switch (item) {
            case '|':
              return <Item name="separator" key={index} />;
            default:
              return <Item name={item} key={index} />;
          }
        })}
      </Toolbar>
    );
  };

  render() {
    const {
      placeholder,
      value,
      label,
      toolbarItems,
      multilineToolbar,
      toolbarPosition,
      disabled
    } = this.props;
    
    return (
      <>
        <HtmlEditor
          onValueChanged={this.onValueChanged}
          defaultValue={value || label}
          placeholder={placeholder}
          disabled={disabled}
        >
          {this.generateToolbar(toolbarItems, multilineToolbar, toolbarPosition)}
        </HtmlEditor>
      </>
    );
  }
}

EditorAtom.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  item: PropTypes.object,
  index: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  toolbarItems: PropTypes.string,
  multilineToolbar: PropTypes.bool,
  toolbarPosition: PropTypes.string,
};

export default EditorAtom;


