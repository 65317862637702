import { createSelector } from "reselect";

import axios from "helpers/interceptor";
import createReducer from "helpers/createReducer";
import URLS from "./urls";

const GET_NOTIFICATIONS = "notifications/notification/GET_NOTIFICATIONS";
const GET_NOTIFICATIONS_FULFILLED =
  "notifications/notification/GET_NOTIFICATIONS_FULFILLED";
const GET_NOTIFICATION_COUNTS =
  "notifications/notification/GET_NOTIFICATION_COUNTS";
const GET_NOTIFICATION_COUNTS_FULFILLED =
  "notifications/notification/GET_NOTIFICATION_COUNTS_FULFILLED";
const UPDATE_NOTIFICATION_COUNTS =
  "notifications/notification/UPDATE_NOTIFICATION_COUNTS";
const UPDATE_NOTIFICATION_COUNTS_FULFILLED =
  "notifications/notification/UPDATE_NOTIFICATION_COUNTS_FULFILLED";
const DELETE_NOTIFICATIONS = "notifications/notification/DELETE_NOTIFICATIONS";

export function getNotifications(params) {
  return {
    type: GET_NOTIFICATIONS,
    payload: axios.get(URLS.NOTIFICATION, { params }),
  };
}

export function getNotificationCounts(params) {
  return {
    type: GET_NOTIFICATION_COUNTS,
    payload: axios.get(`${URLS.NOTIFICATION}${URLS.COUNTS}`, { params }),
  };
}

export function updateNotificationCounts(id) {
  return {
    type: UPDATE_NOTIFICATION_COUNTS,
    payload: axios.put(`${URLS.NOTIFICATION}${id}/`, { is_read: true }),
  };
}

export function deleteNotification(ids) {
  return {
    type: DELETE_NOTIFICATIONS,
    payload: axios.put(`${URLS.NOTIFICATION}${URLS.DELETE}`, ids),
  };
}

const defaultState = {
  notificationsCount: 0,
};

function GET_NOTIFICATIONS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {});
}

function GET_NOTIFICATION_COUNTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    notificationsCount: action.payload.notification_count
      ? action.payload.notification_count
      : 0,
  });
}

function UPDATE_NOTIFICATION_COUNTS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    notificationsCount: state.notificationsCount - 1,
  });
}

const handlers = {
  [GET_NOTIFICATIONS_FULFILLED]: GET_NOTIFICATIONS_FULFILLED_REDUCER,
  [GET_NOTIFICATION_COUNTS_FULFILLED]: GET_NOTIFICATION_COUNTS_FULFILLED_REDUCER,
  [UPDATE_NOTIFICATION_COUNTS_FULFILLED]: UPDATE_NOTIFICATION_COUNTS_FULFILLED_REDUCER,
};

const notificationSelector = (state) => state.notifications.notification;

export const notificationCount = createSelector(
  notificationSelector,
  (instance) => instance.notificationsCount
);

export default createReducer(defaultState, handlers);
