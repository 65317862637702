import React from 'react';

import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as JobDucks from 'ducks/jobs/job';
import * as NoteDucks from 'ducks/jobs/comment';
import * as TaskDucks from 'ducks/jobs/task';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as CustomerDucks from 'ducks/customers/customer';
import * as JobTagDucks from 'ducks/jobs/tag';
import * as TaskTagDucks from 'ducks/jobs/taskTag';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import StorageGateway from 'lib/storage-gateway';
import SectionLoaderAtom from 'atoms/SectionLoader';
import JobListTableComponent from 'components/jobs/list/Table';
import ManageQuotedProjectJobs from 'components/jobs/list/ManageQuotedProjectJobs';
import ManageQuotedRecurringJobs from 'components/jobs/list/ManageQuotedRecurringJobs';
import ManageTimeAndMaterialJobs from 'components/jobs/list/ManageTimeAndMaterialJobs';
import JobInfoModal from "components/jobs/list/JobInfoModal";
import { CREATE_UPDATE_JOB_FILTER_OPTIONS } from 'constants/jobs';
import {exportFile} from 'helpers/utils';
import ModalComponent from 'components/common/GenericModal';
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import Button from "reactstrap/lib/Button";
import ButtonLoaderAtom from "atoms/ButtonLoader";

import styles from "./styles.module.scss";


/**

 * JobScheduleListPage -> ManageJobsSection
 *
 * Components:
 *    - {@link JobListTableComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class ManageJobsSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.chunkArray=this.chunkArray.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.goTo = this.goTo.bind(this);
    this.getJobDetails =  this.getJobDetails.bind(this);
    this.saveStatus = this.saveStatus.bind(this);
    this.setJobReportsData = this.setJobReportsData.bind(this);
    this.exportCSV= this.exportCSV.bind(this);
    this.toggleAccordian = this.toggleAccordian.bind(this);
    this.getJob = this.getJob.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getJobList=this.getJobList.bind(this);
    this.openAccordian = this.openAccordian.bind(this);
    this.openJobSelectionModal = this.openJobSelectionModal.bind(this);
    this.submitJobNotes = this.submitJobNotes.bind(this);
    this.submitImageAndComment = this.submitImageAndComment.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.togglePopOver = this.togglePopOver.bind(this);
    this.getNewUserPreference = this.getNewUserPreference.bind(this);
    this.setUserPreference = this.setUserPreference.bind(this);
    this.setStartAndEndDate = this.setStartAndEndDate.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.onStatusSelection = this.onStatusSelection.bind(this);
    this.setEmployee = this.setEmployee.bind(this);
    this.getCustomers = this.getCustomers.bind(this);
    this.setTask = this.setTask.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.getJobs = this.getJobs.bind(this);
    this.getJobTags = this.getJobTags.bind(this);
    this.getTasks = this.getTasks.bind(this);
    this.getTaskTags = this.getTaskTags.bind(this);
    this.setAdvancedFilters = this.setAdvancedFilters.bind(this);
    this.sortJobCodes = this.sortJobCodes.bind(this);
    this.getEmployeeTags = this.getEmployeeTags.bind(this);
    this.setTags = this.setTags.bind(this);
    this.state = {
      jobArray:[],
      isOpen: false,
      notesLoading: false,
      dropdownOpen: false,
      selectedItem:null,
      jobItem: {},
      status: "",
      jobData: [],
      loading: false,
      exportQuotedRecurring : false,
      exportQuotedProject : false,
      exportTimeMaterials: false,
      quotedProject : false,
      quotedRecurring : false,
      timeAndMaterials : false,
      tableData: null,
      quotedRecurringLoader: false,
      isLoading: false ,
      isLoadingProject: false,
      isLoadingMaterial: false,
      pages : null,
      pageQuotedRecurring: null, 
      pageQuotedProjected: null,  
      pageTimeAndMaterials: null,
      openJobSelectionFilter: false,
      jobNotes: null,
      key: null,
      image : null,
      showModal : false,
      files : [],
      showImageModal: false,
      PopOverOpen: false,
      valueStatus: { value: "", label: "Open" },
      selectedId: null,
      isJobNotes: false,
      jobKey: null,
      jobActionType: null,
      isNotesAdded:false,
      jobNotesLoader: false,
    };
  }

  setStartAndEndDate(dateRange, startDate, endDate){
    const {dateFormatDisplay} = this.props;
    let start_date, end_date;
    if(dateRange == 'Yesterday'){
      start_date = moment().subtract(1, 'days').format(dateFormatDisplay);
      end_date = moment().subtract(1, 'days').format(dateFormatDisplay);
    }
    else if(dateRange == 'Today'){
      start_date = moment().format(dateFormatDisplay);
      end_date = moment().format(dateFormatDisplay);
    }
    else if(dateRange == 'This Week'){
      start_date = moment().startOf('week').format(dateFormatDisplay);
      end_date = moment().endOf('week').format(dateFormatDisplay);
    }
    else if(dateRange == 'Last Week'){
      start_date = moment().subtract(1, 'weeks').startOf('week').format(dateFormatDisplay);
      end_date = moment().subtract(1, 'weeks').endOf('week').format(dateFormatDisplay);
    }
    else if(dateRange == 'This Month'){
      start_date = moment().startOf('month').format(dateFormatDisplay);
      end_date = moment().endOf('month').format(dateFormatDisplay);
    }
    else if(dateRange == 'Last Month'){
      start_date = moment().subtract(1, 'month').startOf('month').format(dateFormatDisplay);
      end_date = moment().subtract(1, 'month').endOf('month').format(dateFormatDisplay);
    }
    else if(dateRange == 'Custom Date Range'){
      start_date = startDate ?  moment(startDate).format(dateFormatDisplay) :  moment().format(dateFormatDisplay);
      end_date = endDate ? moment(endDate).format(dateFormatDisplay) :  moment().format(dateFormatDisplay);
    }
    return `${start_date}-${end_date}`
  }



  setAdvancedFilters(data, cookiekey){
     let detail = { 
      status: data[`${cookiekey}status`] && data[`${cookiekey}status`].value,
      job_tags: data[`${cookiekey}job_tag`] && data[`${cookiekey}job_tag`].length ? JSON.stringify(data[`${cookiekey}job_tag`].map((i) => i.name)) : null,
      task_tags: data[`${cookiekey}task_tag`] && data[`${cookiekey}task_tag`].length ? JSON.stringify(data[`${cookiekey}task_tag`].map((i) => i.name)) : null,
      employee_ids : data[`${cookiekey}employee_name`] && data[`${cookiekey}employee_name`].length ? JSON.stringify(data[`${cookiekey}employee_name`].map((i) => i.id)) : null,
      job_ids : data[`${cookiekey}job_name`] && data[`${cookiekey}job_name`].length ? JSON.stringify(data[`${cookiekey}job_name`].map((i) => i.id)) : null,
      task_ids : data[`${cookiekey}task_name`] && data[`${cookiekey}task_name`].length ? JSON.stringify(data[`${cookiekey}task_name`].map((i) => i.id)) : null,
      customer_ids: data[`${cookiekey}customer_name`] && data[`${cookiekey}customer_name`].length ? JSON.stringify(data[`${cookiekey}customer_name`].map((i) => i.id)) : null,  
      employee_tags: data[`${cookiekey}employee_tags`] && data[`${cookiekey}employee_tags`].length ? JSON.stringify(data[`${cookiekey}employee_tags`].map((i) => i.name)) : null,
    };
    if(data[`${cookiekey}job_code`] && data[`${cookiekey}job_code`].length){
      if(detail.job_ids && detail.job_ids.length){
        let job_code_ids = data[`${cookiekey}job_code`].map((i) => i.id);
        detail['job_ids'] = JSON.stringify(JSON.parse(detail.job_ids).concat(job_code_ids))
      }
      else{
        let job_code_ids = JSON.stringify(data[`${cookiekey}job_code`].map((i) => i.id));
        detail.job_ids = job_code_ids
      }
    }
   return detail;
  }


  getEmployeeTags(){
    return this.props.EmployeeDucks.getEmployeeTags({paginate: false, tag_type: 3})
  }

  openJobSelectionModal() {
    const { openJobSelectionFilter } = this.state;

    this.setState({
      openJobSelectionFilter: !openJobSelectionFilter
    })
  }

  getJobTags(){
    return this.props.JobTagDucks.getJobTags({ paginate:false, tag_type: 2 });
  }

  sortJobCodes(a,b){
    const job_code1 = a.code && a.code !== null ? a.code.toUpperCase() :  '';
    const job_code2 = b.code && b.code !== null ? b.code.toUpperCase() :  '';
    let comparison = 0;
    if (job_code1 > job_code2) {
      comparison = 1;
    } else if (job_code1 < job_code2) {
      comparison = -1;
    }
    return comparison;
  }

  getTaskTags(){
    return this.props.TaskTagDucks.getTaskTags({ paginate:false, tag_type: 1 });
  }

  getEmployees(val){
    const detail = { search: val, is_active: true , name_search : true };
    return this.props.EmployeeDucks.getEmployees(detail);
  }

  dropdownToggle() {
    const { dropdownOpen } = this.state;
    this.setState({ dropdownOpen: !dropdownOpen });
  }

  getJobs(val, cookiekey){
    let billType , jobType;
    if(cookiekey == 'quotedRecurringFiltersCookieNew'){
      billType = 'QUOTED';
      jobType = 'RECURRING';
      return this.props.JobDucks.getQuotedRecurringJobsAndJobCodes({admin: true, paginate: false , billType : billType, jobType : jobType});
    }
    else if(cookiekey == 'quotedProjectFiltersCookieNew'){
      billType = 'QUOTED';
      jobType = 'PROJECT';
      return this.props.JobDucks.getQuotedProjectJobsAndJobCodes({admin: true, paginate: false , billType : billType, jobType : jobType});
    }
    else if(cookiekey == 'MaterialFiltersCookieNew'){
      billType = 'TIME_AND_MATERIAL';
      return this.props.JobDucks.getTimeAndMaterialJobsAndJobCodes({admin: true, paginate: false , billType : billType, jobType : jobType});
    }
  }

  getTasks(val, cookiekey){
    let billType , jobType;
    if(cookiekey == 'quotedRecurringFiltersCookieNew'){
      billType = 'QUOTED';
      jobType = 'RECURRING';
       return this.props.TaskDucks.getQuotedRecurringAssignedTaskAndTaskCodes({ admin: true, paginate : false , billType : billType , jobType: jobType});
    }
    else if(cookiekey == 'quotedProjectFiltersCookieNew'){
      billType = 'QUOTED';
      jobType = 'PROJECT';
       return this.props.TaskDucks.getQuotedProjectAssignedTaskAndTaskCodes({ admin: true, paginate : false , billType : billType , jobType: jobType});
    }
    else if(cookiekey == 'MaterialFiltersCookieNew'){
      billType = 'TIME_AND_MATERIAL';
       return this.props.TaskDucks.getTimeAndMaterialtAssignedTaskAndTaskCodes({ admin: true, paginate : false , billType : billType , jobType: jobType});
    }   
  }

  setEmployee(params){
    return this.props.EmployeeDucks.getAssignedEmployees({job_id: params.job_id, assigned: true, paginate: false })
  }

  setTags(params){
    return this.props.JobDucks.getAssignedTags({job_id: params.job_id, assigned: true, paginate: false })
  }

  setTask(params){
    return this.props.TaskDucks.getAssignedTasks({ job_id: params.job_id, assigned: true, paginate: false })
  }

  toggleImageModal(){
    const { showImageModal } = this.state;
    this.setState({showImageModal : !showImageModal});
  }

  getCustomers(val){
    return this.props.CustomerDucks.getCustomers({ paginate: false });
  }

  onStatusSelection(val, id) {
    if (val) {
      this.setState({ valueStatus: val, selectedId: id });
    } else {
      this.setState({ valueStatus: "" });
    }
  }


  onImageChange(event){
    const fileArray = event.target.files;
    this.setState((state) => ({ files: fileArray, path: event.target}));
      this.setState({image: URL.createObjectURL(event.target.files[0])});
      this.setState({showImageModal : true});
   }

  openAccordian(){  
    const cookieKey = cookie.load('tableViewNew'); 
    if(cookieKey){  
      if(cookieKey.quotedProject === true){  
        this.setState({quotedProject: true}); 
      } 
      if (cookieKey.quotedRecurring === true){ 
        this.setState({quotedRecurring: true}); 
      } 
      if(cookieKey.timeAndMaterials === true){ 
        this.setState({timeAndMaterials: true});  
      }
    }else{  
      cookie.save('tableViewNew', null); 
    } 
  }

  submitJobNotes(data){
      const { jobItem } = this.state;
      const { files} = this.state;
      let documentData;
      if ((this.state.image!== null && this.state.image.length > 0) || data.notes !== null) {
        documentData = new FormData();      
        this.state.files !== null && this.state.files[0] && documentData.append('files', files[0]);
        documentData.append('notes', data && data.note ? data.note : '');
      }
      this.props.NoteDucks.postComment(documentData, jobItem.id)
      .then(() => {
        toast.success('Comment added successfully');
        this.props.NoteDucks.getComments({job_id: jobItem.id})
        this.setState({showImageModal : false, files: null, image: null, isNotesAdded:true})
       })
      .catch((err) => {
        toast.error("Job notes field cannot be blank");
    });
  }

  submitImageAndComment(data){
      const { jobItem } = this.state;
      const { files} = this.state;
      let documentData;
      if ((this.state.image!== null && this.state.image.length > 0) || data.notes !== null) {
        documentData = new FormData();      
        this.state.files !==null && this.state.files[0] && documentData.append('files', files[0]);
        documentData.append('notes', data && data.note ? data.note : '');
      }
      this.props.NoteDucks.postComment(documentData, jobItem.id)
      .then(() => {
        toast.success('Comment added successfully');
        this.props.NoteDucks.getComments({job_id: jobItem.id})
        this.setState({showImageModal : false, files: null, image: null})
       });
  }

  getJob(key, item, refresh) {  
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    this.setState({ tableData: item }); 
    const cookieKey = cookie.load('tableViewNew'); 
    const RecurringpageSizeCookie = cookie.load('pageSizeRecurringNew'); 
    const ProjectpageSizeCookie = cookie.load('pageSizeProjectNew'); 
    const MaterialpageSizeCookie = cookie.load('pageSizeTimeNew'); 
    const RecurringpageCookie = cookie.load('RecurringpageNew'); 
    const MaterialpageCookie = cookie.load('MaterialpageNew'); 
    const ProjectpageCookie = cookie.load('ProjectpageNew'); 
    const recurringSearchCookie = cookie.load('recurring_search_new');
    const projectSearchCookie = cookie.load('project_search_new');
    const materialSearchCookie = cookie.load('material_search_new');
    const recurringSortCookie = cookie.load('recurringSortingNew');
    const projectSortCookie = cookie.load('projectSortingNew');
    const materialSortCookie = cookie.load('materialSortingNew');
    const recurringAdvancedFilterCookie = StorageGateway.get(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const projectAdvancedFilterCookie = StorageGateway.get(`quotedProjectFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const materialAdvancedFilterCookie = StorageGateway.get(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const { location: { query } } = this.props; 
    const { status, recurring_sorting, project_sorting, material_sorting, recurring_search, project_search, material_search ,...rest } = query;  
    let bill_type;  
    let job_type;  
    if (cookieKey){ 
      if(cookieKey.quotedRecurring === true && key === "quotedRecurring"){  
         bill_type="QUOTED";  
          job_type="RECURRING"; 
          this.setState({ isLoading: true }); 
          let params = { ...rest, search: recurring_search ? recurring_search : recurringSearchCookie, page : RecurringpageCookie ? (+RecurringpageCookie) : 1 ,  
            page_size: RecurringpageSizeCookie ? RecurringpageSizeCookie : 10, billType : bill_type, jobType: job_type};  
        if (query.recurring_sorting || recurringSortCookie) {
          params = {
            ...params,
            ordering: query.recurring_sorting ? query.recurring_sorting : recurringSortCookie
          };
        } 
        if(recurringAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(recurringAdvancedFilterCookie), 'quotedRecurringFiltersCookieNew')
          params = {...params, ...detail}
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
          this.props.JobDucks.getNewRecurringJobsList({...params })  
          .then((response) => { 
            let pageSize = RecurringpageSizeCookie ? RecurringpageSizeCookie : 10;  
            this.setState({ pageQuotedRecurring: Math.ceil(response.value.count / pageSize )}); 
            this.setState({  isLoading: false }) ;
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoading: false , refreshTable : false })); 
      } 
      else if (cookieKey.quotedProject === true && key === "quotedProject"){  
        bill_type="QUOTED"; 
        job_type="PROJECT"; 
        this.setState({ isLoadingProject: true });  
        let params = { ...rest, search: project_search ? project_search : projectSearchCookie,  page : ProjectpageCookie ? (+ProjectpageCookie): 1 , 
          page_size: ProjectpageSizeCookie ? ProjectpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
        if (query.project_sorting || projectSortCookie) {
          params = {
            ...params,
            ordering: query.project_sorting ? query.project_sorting : projectSortCookie
          };
        }  
         if(projectAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(projectAdvancedFilterCookie), 'quotedProjectFiltersCookieNew')
          
          params = {...params, ...detail}
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
         this.props.JobDucks.getNewProjectJobsList({...params }) 
          .then((response) => {         
          let pageSize = ProjectpageSizeCookie ? ProjectpageSizeCookie : 10;  
          this.setState({ pageQuotedProjected: Math.ceil(response.value.count / pageSize )}); 
          this.setState({  isLoadingProject: false }) 
        })  
        .catch(()=> {
          toast.error('Not able to compute data. Please reduce the date range.')
        })
        .finally(() => this.setState({  isLoadingProject: false , refreshTable : false }));  
      } 
      else if (cookieKey.timeAndMaterials === true && key === "timeAndMaterials"){  
        bill_type="TIME_AND_MATERIAL";  
        job_type = null;  
        this.setState({ isLoadingMaterial: true }); 
        let params = { ...rest, search: material_search ? material_search : materialSearchCookie, page : MaterialpageCookie ? (+MaterialpageCookie) : 1 ,  
          page_size: MaterialpageSizeCookie ? MaterialpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
        if (query.material_sorting || materialSortCookie) {
          params = {
            ...params,
            ordering: query.material_sorting ? query.material_sorting : materialSortCookie
          };
        }
         if(materialAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(materialAdvancedFilterCookie), 'MaterialFiltersCookieNew')
          params = {...params, ...detail}
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        } 
          this.props.JobDucks.getNewTimeAndMaterialJobsList({...params })  
          .then((response) => {   
            let pageSize = MaterialpageSizeCookie ? MaterialpageSizeCookie : 10;  
            this.setState({ pageTimeAndMaterials: Math.ceil(response.value.count / pageSize )});  
            this.setState({ isLoadingMaterial: false }) 
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoadingMaterial: false , refreshTable : false })); 
      } 
    }   
      // this.getJobList( this.props, item);  
  }

  toggleAccordian(key , item){ 
    const { router: { history } } = this.context; 
    const {  location: { pathname, search }} = this.props;  
    const query  = queryString.parse(search); 
    const cookieKey = cookie.load('tableViewNew'); 
    if(key === 'quotedRecurring') { 
      this.setState({ 
        quotedRecurring: !this.state.quotedRecurring, 
      }); 
      if(cookieKey){  
         if(cookieKey.quotedRecurring === false || cookieKey === 'null'){ 
         let obj = {quotedRecurring: true, quotedProject: this.state.quotedProject, timeAndMaterials: this.state.timeAndMaterials}  
         cookie.save('tableViewNew', obj); 
          } 
          if(cookieKey.quotedRecurring === true){  
            let obj = {quotedRecurring: false, quotedProject: this.state.quotedProject, timeAndMaterials: this.state.timeAndMaterials}  
             cookie.save('tableViewNew', obj); 
          } 
        }else{  
           cookie.save('tableViewNew', null);  
        } 
    } 
    else if(key=== 'quotedProject') { 
      this.setState({   
        quotedProject: !this.state.quotedProject, 
      }); 
      if(cookieKey){  
        if(cookieKey.quotedProject === false || cookieKey === 'null'){  
          let obj = {quotedRecurring: this.state.quotedRecurring, quotedProject:true , timeAndMaterials: this.state.timeAndMaterials} 
           cookie.save('tableViewNew', obj); 
        } 
        if(cookieKey.quotedProject === true){  
          let obj = {quotedRecurring: this.state.quotedRecurring, quotedProject:false , timeAndMaterials: this.state.timeAndMaterials}  
           cookie.save('tableViewNew', obj); 
        } 
       }else{ 
           cookie.save('tableViewNew', null);  
        } 
    } 
    else if(key === 'timeAndMaterials') { 
        this.setState({   
          timeAndMaterials : !this.state.timeAndMaterials,  
        }); 
        if(cookieKey){  
           if(cookieKey.timeAndMaterials === false || cookieKey === 'null'){  
            let obj = {quotedRecurring: this.state.quotedRecurring, quotedProject:this.state.quotedProject, timeAndMaterials: true} 
             cookie.save('tableViewNew', obj); 
          } 
          if(cookieKey.timeAndMaterials === true){ 
            let obj = {quotedRecurring: this.state.quotedRecurring, quotedProject:this.state.quotedProject, timeAndMaterials: false}  
             cookie.save('tableViewNew', obj); 
          } 
        }else{  
           cookie.save('tableViewNew', null);  
        } 
    } 
    if(key === 'quotedRecurring' && !this.state.quotedRecurring) this.getJob(key, item);  
    if(key === 'quotedProject' && !this.state.quotedProject) this.getJob(key, item);  
    if(key === 'timeAndMaterials' && !this.state.timeAndMaterials) this.getJob(key, item);  
  }


  refresh(key){
    this.setState({refreshTable : true})
    const cookieKey = cookie.load('tableViewNew');
    if(cookieKey) { 
      this.getJob( key, this.state.tableData, 'refresh');
    }
  }

  exportCSV(item, key, showableAttributes){
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid');
    const RecurringpageSizeCookie = cookie.load('pageSizeRecurringNew');
    const ProjectpageSizeCookie = cookie.load('pageSizeProjectNew');
    const MaterialpageSizeCookie = cookie.load('pageSizeTimeNew');
    const RecurringpageCookie = cookie.load('RecurringpageNew');
    const MaterialpageCookie = cookie.load('MaterialpageNew');
    const ProjectpageCookie = cookie.load('ProjectpageNew');
    const recurringSearchCookie = cookie.load('recurring_search_new');
    const projectSearchCookie = cookie.load('project_search_new');
    const materialSearchCookie = cookie.load('material_search_new');
    const recurringSortCookie = cookie.load('recurringSortingNew');
    const projectSortCookie = cookie.load('projectSortingNew');
    const materialSortCookie = cookie.load('materialSortingNew');
    const recurringAdvancedFilterCookie = StorageGateway.get(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const projectAdvancedFilterCookie = StorageGateway.get(`quotedProjectFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const materialAdvancedFilterCookie = StorageGateway.get(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const { location: { query } } = this.props;
    const cookieKey = cookie.load('tableViewNew');
    const { status, recurring_sorting, project_sorting, material_sorting, recurring_search, project_search, material_search,...rest} = query; 
    let bill_type;
    let job_type;
    let params;

    let showableKeys = "";
    let pageSizeCookie;
    let pageCookie;
    let idx = 0;
    for (let [key, val] of Object.entries(showableAttributes)) {
      if (idx === 0) {
        if (val)  {
          showableKeys = showableKeys.concat(key)
        }
        idx = idx + 1;
        continue;
      }
      if (val)  showableKeys = showableKeys.concat(",").concat(key);
      idx = idx + 1;
    }
    
    if(cookieKey){  
      if(cookieKey.quotedRecurring === true && key === "quotedRecurring"){  
        bill_type="QUOTED"; 
        job_type="RECURRING"  
        this.setState({ exportQuotedRecurring : true });  
        pageSizeCookie = RecurringpageSizeCookie; 
        pageCookie = RecurringpageCookie; 
        if (item) { 
        params = { ...rest, search: recurring_search ? recurring_search: recurringSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : item.page + 1, page_size: pageSizeCookie ? pageSizeCookie :  item.pageSize , billType : bill_type, jobType: job_type , ordering: query.recurring_sorting ? query.recurring_sorting : recurringSortCookie ? recurringSortCookie  : null};  
      } else {  
        params = { ...rest,  search: recurring_search ? recurring_search: recurringSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : 1, page_size: pageSizeCookie ? pageSizeCookie : 10 , billType : bill_type, jobType: job_type};  
      } 
       if(recurringAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(recurringAdvancedFilterCookie), 'quotedRecurringFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
      } 
      else if (cookieKey.quotedProject === true && key === "quotedProject"){  
        bill_type="QUOTED"; 
        job_type="PROJECT"; 
        this.setState({ exportQuotedProject : true });  
        pageSizeCookie = ProjectpageSizeCookie; 
        pageCookie = ProjectpageCookie; 
        if (item) { 
        params = { ...rest, search: project_search ? project_search : projectSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : item.page + 1, page_size: pageSizeCookie ? pageSizeCookie :  item.pageSize , billType : bill_type, jobType: job_type , ordering: query.project_sorting ? query.project_sorting : projectSortCookie ? projectSortCookie  : null};  
      } else {  
        params = { ...rest, search: project_search ? project_search : projectSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : 1, page_size: pageSizeCookie ? pageSizeCookie : 10 , billType : bill_type, jobType: job_type};  
      } 
      if(projectAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(projectAdvancedFilterCookie), 'quotedProjectFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
      } 
      else if (cookieKey.timeAndMaterials === true && key === "timeAndMaterials"){  
        bill_type="TIME_AND_MATERIAL";  
         this.setState({ exportTimeMaterials : true }); 
         pageSizeCookie = MaterialpageSizeCookie; 
         pageCookie = MaterialpageCookie; 
         if (item) { 
          params = { ...rest, search: material_search ? material_search : materialSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : item.page + 1, page_size: pageSizeCookie ? pageSizeCookie :  item.pageSize , billType : bill_type, jobType: job_type , ordering: query.material_sorting ? query.material_sorting : materialSortCookie ? materialSortCookie : null};  
          } else {  
          params = { ...rest, search: material_search ? material_search : materialSearchCookie, show: showableKeys, page: pageCookie ? pageCookie : 1, page_size: pageSizeCookie ? pageSizeCookie : 10 , billType : bill_type, jobType: job_type};  
          } 
           if(materialAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(materialAdvancedFilterCookie), 'MaterialFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all' }
        }
      } 
      this.props.JobDucks.exportCSV({...params, view: 'manage_jobs'}) 
        .then((data) => { 
          exportFile(data , `Jobs.xlsx`); 
          this.setState({ exportQuotedRecurring : false }); 
          this.setState({ exportQuotedProject : false }); 
          this.setState({ exportTimeMaterials : false }); 
        })  
    }
  }

  componentWillMount(){
    const cookieKey = cookie.load('tableViewNew');
    if (typeof(cookieKey) === "string"){
       cookie.save('tableViewNew', null);
     }
    this.chunkArray(2);
  }


  componentWillReceiveProps(nextProps){ 
    const { location: { search, id} } = nextProps;  
    const nextQuery = queryString.parse(search);  
    const { location: { search : nextSearch} } = this.props;  
    const query = queryString.parse(nextSearch);  
    if(nextProps!== this.props){  
      this.chunkArray(2); 
    } 
    if((nextQuery.search !== query.search) || (nextProps.location.query.status !== this.props.location.query.status) || (nextQuery.recurring_search !== query.recurring_search) || (nextQuery.project_search !== query.project_search) || (nextQuery.material_search !== query.material_search)){  
        const cookieKey = cookie.load('tableViewNew'); 
        if(cookieKey){  
           if(cookieKey.quotedRecurring === true){ 
             cookie.save('RecurringpageNew',1, { path: '/'});  
           }  
           if(cookieKey.quotedProject === true){ 
             cookie.save('ProjectpageNew',1, { path: '/'});  
           }  
           if(cookieKey.timeAndMaterials === true){  
             cookie.save('MaterialpageNew',1, { path: '/'}); 
           }  
           this.getJobList( nextProps , this.state.tableData, id);  
        } 
    }else if((nextQuery.recurring_sorting !== query.recurring_sorting)){
      this.getJobList( nextProps , this.state.tableData, 'recurring_search'); 
    }
    else if((nextQuery.project_sorting !== query.project_sorting)){
      this.getJobList( nextProps , this.state.tableData, 'project_search'); 
    }
    else if((nextQuery.material_sorting !== query.material_sorting)){
      this.getJobList( nextProps , this.state.tableData, 'material_search'); 
    }
  } 


  setJobReportsData(data){
    return this.props.JobDucks.setJobReportsInitialValues(data);
  }

  getNewUserPreference(params) {
    return this.props.JobDucks.getNewUserPreference(params)
  }

  setUserPreference(params){
    return this.props.JobDucks.updateNewUserJobPreference(params);
  }

  getJobDetails(query) {
    const { location } = this.props;
    const { jobData } = this.state;
    const list = jobData.map((item, index) => {
      const jobDetail = { ...item };
      if(item.id === query && item.called === false ) {
        this.props.JobDucks.getJobDetailsMap({id : query });
        this.props.JobDucks.setJobCalledStatus(item);
      }
      return jobDetail;
    });
    this.setState({ jobData: list });
  }

  togglePopOver(key, id, item){
    const { PopOverOpen } = this.state;
    this.setState({ PopOverOpen : !PopOverOpen, selectedItem: item, key: key})
  }
  
  saveStatus(key, status, data){  
    const { location: { query }, refreshDisable} = this.props;  
    refreshDisable(true); 
    this.setState({isStatusLoading : true});  
    const { PopOverOpen } = this.state; 
    const params = {  
      status: query.status, 
    };
    const cookieKey=cookie.load('tableViewNew'); 
    let bill_type;  
    let job_type; 
    if(key === "quotedRecurring"){ 
      bill_type="QUOTED"; 
      job_type="RECURRING"; 
    } 
    else if(key === "quotedProject"){  
      bill_type="QUOTED"; 
      job_type="PROJECT";     
    } 
    else if(key === "timeAndMaterials"){ 
      bill_type="TIME_AND_MATERIAL";  
      job_type = null;      
    } 
    this.props.JobDucks.putJob({...data, status :status.value, job_type:job_type, bill_type: bill_type , start_date: data.start_date && moment(data.start_date).format('YYYY-MM-DD'), end_date :  data.end_date && moment(data.end_date).format('YYYY-MM-DD')})  
      .then(() => { 
        this.setState({ PopOverOpen : !PopOverOpen}); 
        if(cookieKey.quotedRecurring === true && key === "quotedRecurring"){  
          cookie.save('RecurringpageNew',1); 
        } 
        else if(cookieKey.quotedProject === true && key === "quotedProject"){ 
          cookie.save('ProjectpageNew',1); 
        } 
        else if(cookieKey.timeAndMaterials === true && key === "timeAndMaterials"){ 
          cookie.save('MaterialpageNew',1);  
        } 
        this.getJob(key);
        this.setState({isStatusLoading : false})  
        refreshDisable(false)
      })  
      .catch(() =>{ 
        this.setState({isStatusLoading : false}); 
      })  
  }

  getJobList(nextProps, item, key , detail) { 
    const { userProfile } = this.props;
    const uniqueUuid = StorageGateway.get('uniqueUuid'); 
    const RecurringpageSizeCookie = cookie.load('pageSizeRecurringNew'); 
    const ProjectpageSizeCookie = cookie.load('pageSizeProjectNew'); 
    const MaterialpageSizeCookie = cookie.load('pageSizeTimeNew'); 
    const RecurringpageCookie = cookie.load('RecurringpageNew'); 
    const MaterialpageCookie = cookie.load('MaterialpageNew'); 
    const ProjectpageCookie = cookie.load('ProjectpageNew'); 
    const recurringSearchCookie = cookie.load('recurring_search_new');
    const projectSearchCookie = cookie.load('project_search_new');
    const materialSearchCookie = cookie.load('material_search_new');
    const recurringSortCookie = cookie.load('recurringSortingNew');
    const projectSortCookie = cookie.load('projectSortingNew');
    const materialSortCookie = cookie.load('materialSortingNew');
    const recurringAdvancedFilterCookie = StorageGateway.get(`quotedRecurringFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const projectAdvancedFilterCookie = StorageGateway.get(`quotedProjectFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const materialAdvancedFilterCookie = StorageGateway.get(`MaterialFiltersCookieNew${uniqueUuid}${userProfile.company.id}`);
    const cookieKey = cookie.load('tableViewNew'); 
    const { location: { query } } = nextProps;    
    const { status, recurring_sorting, project_sorting, material_sorting, recurring_search, project_search, material_search ,...rest} = query;
    let bill_type;  
    let job_type;  
    if(cookieKey){  
     if(key){
      if(cookieKey.quotedRecurring === true && key && key === "recurring_search"){ 
        bill_type="QUOTED"; 
        job_type="RECURRING"; 
        this.setState({ isLoading: true }); 
        let params = { ...rest, search: recurring_search ? recurring_search: recurringSearchCookie, page : RecurringpageCookie ? (+RecurringpageCookie) : 1 ,  
          page_size: RecurringpageSizeCookie ? RecurringpageSizeCookie : 10, billType : bill_type, jobType: job_type};  
          
        if (item && bill_type === 'QUOTED' && job_type === 'RECURRING') { 
          params = { ...rest, ...params, search: recurring_search ? recurring_search: recurringSearchCookie,
            billType : bill_type, jobType: job_type  };  
        } 
        if (query.recurring_sorting || recurringSortCookie) {
          params = {
            ...params,
            ordering: query.recurring_sorting ? query.recurring_sorting : recurringSortCookie
          };
        }
        if(detail){
          params = {...params, ...detail }
          params = {...params, status : params.status !== '0' ? params.status : 'all'}
        }
        else if(recurringAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(recurringAdvancedFilterCookie), 'quotedRecurringFiltersCookieNew')          
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }  
        this.props.JobDucks.getNewRecurringJobsList({...params })  
          .then((response) => {         
            let pageSize = RecurringpageSizeCookie ? RecurringpageSizeCookie : 10;  
            this.setState({ pageQuotedRecurring: Math.ceil(response.value.count / pageSize )}); 
            this.setState({  isLoading: false, refreshTable : false })  
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoading: false , refreshTable : false})); 
      } 
      else if (cookieKey.quotedProject === true && key && key === "project_search"){ 
        bill_type="QUOTED"; 
        job_type="PROJECT"; 
        this.setState({ isLoadingProject: true });  
        let params = { ...rest, search: project_search ? project_search: projectSearchCookie , page : ProjectpageCookie ? (+ProjectpageCookie): 1 , 
          page_size: ProjectpageSizeCookie ? ProjectpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
          if (item && bill_type === 'QUOTED' && job_type === 'PROJECT') { 
            params = { ...rest,...params, search: project_search ? project_search: projectSearchCookie , billType : bill_type, jobType: job_type };  
          }
        if (query.project_sorting || projectSortCookie) {
          params = {
            ...params,
            ordering: query.project_sorting ? query.project_sorting : projectSortCookie
          };
        }
        if(detail){
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
        else if(projectAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(projectAdvancedFilterCookie), 'quotedProjectFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }  
         this.props.JobDucks.getNewProjectJobsList({...params }) 
          .then((response) => {         
            let pageSize = ProjectpageSizeCookie ? ProjectpageSizeCookie : 10;  
            this.setState({ pageQuotedProjected: Math.ceil(response.value.count / pageSize )}); 
            this.setState({  isLoadingProject: false , refreshTable : false }) 
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoadingProject: false , refreshTable : false}));  
      } 
      else if (cookieKey.timeAndMaterials === true && key && key ==="material_search"){ 
        bill_type="TIME_AND_MATERIAL";  
        job_type = null;  
        this.setState({ isLoadingMaterial: true }); 
        let params = { ...rest, search: material_search ? material_search: materialSearchCookie , page : MaterialpageCookie ? (+MaterialpageCookie) : 1 ,  
          page_size: MaterialpageSizeCookie ? MaterialpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
          if (item && bill_type === 'TIME_AND_MATERIAL') { 
            params = { ...rest,...params, search: material_search ? material_search: materialSearchCookie , 
              billType : bill_type, jobType: job_type };  
          } 
        if (query.material_sorting || materialSortCookie) {
          params = {
            ...params,
            ordering: query.material_sorting ? query.material_sorting : materialSortCookie
          };
        } 
         if(detail){
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
        else if(materialAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(materialAdvancedFilterCookie), 'MaterialFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all' }
        }
          this.props.JobDucks.getNewTimeAndMaterialJobsList({...params })  
          .then((response) => {   
            let pageSize = MaterialpageSizeCookie ? MaterialpageSizeCookie : 10;  
            this.setState({ pageTimeAndMaterials: Math.ceil(response.value.count / pageSize )});  
            this.setState({ isLoadingMaterial: false , refreshTable : false}) 
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoadingMaterial: false , refreshTable : false})); 
      } 
     }else{
      if(cookieKey.quotedRecurring === true){ 
        bill_type="QUOTED"; 
        job_type="RECURRING"; 
        this.setState({ isLoading: true }); 
        let params = { ...rest, search: recurring_search ? recurring_search: recurringSearchCookie , page : RecurringpageCookie ? (+RecurringpageCookie) : 1 ,  
          page_size: RecurringpageSizeCookie ? RecurringpageSizeCookie : 10, billType : bill_type, jobType: job_type};  
          
        if (item && bill_type === 'QUOTED' && job_type === 'RECURRING') { 
          params = { ...rest,...params, search: recurring_search ? recurring_search: recurringSearchCookie ,
             billType : bill_type, jobType: job_type };   
        } 
        if (query.recurring_sorting || recurringSortCookie) {
          params = {
            ...params,
            ordering: query.recurring_sorting ? query.recurring_sorting : recurringSortCookie
          };
        } 
         if(detail){
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
        else if(recurringAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(recurringAdvancedFilterCookie), 'quotedRecurringFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        } 
        this.props.JobDucks.getNewRecurringJobsList({...params })  
          .then((response) => {         
            let pageSize = RecurringpageSizeCookie ? RecurringpageSizeCookie : 10;  
            this.setState({ pageQuotedRecurring: Math.ceil(response.value.count / pageSize )}); 
            this.setState({  isLoading: false })  
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoading: false })); 
      } 
      if (cookieKey.quotedProject === true){ 
        bill_type="QUOTED"; 
        job_type="PROJECT"; 
        this.setState({ isLoadingProject: true });  
        let params = { ...rest , search: project_search ? project_search: projectSearchCookie ,  page : ProjectpageCookie ? (+ProjectpageCookie): 1 , 
          page_size: ProjectpageSizeCookie ? ProjectpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
          if (item && bill_type === 'QUOTED' && job_type === 'PROJECT') { 
            params = { ...rest,...params, search: project_search ? project_search: projectSearchCookie ,  
             billType : bill_type, jobType: job_type };  
          } 
        if (query.project_sorting || projectSortCookie) {
          params = {
            ...params,
            ordering: query.project_sorting ? query.project_sorting : projectSortCookie
          };
        } 
        if(detail){
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
        else if(projectAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(projectAdvancedFilterCookie), 'quotedProjectFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all' }
        }
         this.props.JobDucks.getNewProjectJobsList({...params }) 
          .then((response) => {         
            let pageSize = ProjectpageSizeCookie ? ProjectpageSizeCookie : 10;  
            this.setState({ pageQuotedProjected: Math.ceil(response.value.count / pageSize )}); 
            this.setState({  isLoadingProject: false }) 
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoadingProject: false }));  
      } 
      if (cookieKey.timeAndMaterials === true){ 
        bill_type="TIME_AND_MATERIAL";  
        job_type = null;  
        this.setState({ isLoadingMaterial: true }); 
        let params = { ...rest, search: material_search ? material_search : materialSearchCookie ,  page : MaterialpageCookie ? (+MaterialpageCookie) : 1 ,  
          page_size: MaterialpageSizeCookie ? MaterialpageSizeCookie : 10, billType : bill_type, jobType: job_type }; 
          if (item && bill_type === 'TIME_AND_MATERIAL') { 
            params = { ...rest, ...params, search: material_search ? material_search : materialSearchCookie ,
               billType : bill_type, jobType: job_type };  
          }
        if (query.material_sorting || materialSortCookie) {
          params = {
            ...params,
            ordering: query.material_sorting ? query.material_sorting : materialSortCookie
          };
        }  
        if(detail){
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        }
        else if(materialAdvancedFilterCookie){
          const detail = this.setAdvancedFilters(JSON.parse(materialAdvancedFilterCookie), 'MaterialFiltersCookieNew')
          params = {...params, ...detail }
          params= {...params , status: params.status !== '0' ? params.status : 'all'}
        } 
          this.props.JobDucks.getNewTimeAndMaterialJobsList({...params })  
          .then((response) => {   
            let pageSize = MaterialpageSizeCookie ? MaterialpageSizeCookie : 10;  
            this.setState({ pageTimeAndMaterials: Math.ceil(response.value.count / pageSize )});  
            this.setState({ isLoadingMaterial: false }) 
          })  
          .catch(()=> {
            toast.error('Not able to compute data. Please reduce the date range.')
          })
          .finally(() => this.setState({  isLoadingMaterial: false })); 
      } 
     }
    } 
  }  


  toggle(item, type, key) {
    const { isOpen, jobKey, jobActionType, isNotesAdded } = this.state;  
    const { location: { query } } = this.props;
    this.setState({ jobNotesLoader : true , isOpen : !isOpen});
    if(!isOpen) {
      if(type==='jobnotes'){
        this.props.NoteDucks.getComments({ job_id: item.id })
        .then(() => {
          this.setState({ jobNotesLoader : false });
        });
      }
    }
    if(isOpen){
      this.props.NoteDucks.resetCommentList();
    }
    const s = item.status && CREATE_UPDATE_JOB_FILTER_OPTIONS.filter((i) => (i.value === item.status) || (i.label === item.status))[0].value;
    this.setState({ jobItem: item, status: s });
    if(type){
      this.setState({jobActionType: type});
    }
    if(type==="jobnotes"){
      this.setState({isJobNotes: true, jobKey: key})    
    }
    else if(type!=="view" && jobActionType!=="view"){
      this.setState({isJobNotes: false});
      {isNotesAdded && 
        this.getJob(jobKey);
      }
    }
    this.setState({isNotesAdded:false});
  }


  updateStatus(data) {
    const { jobItem, isOpen } = this.state;
    const { router: { history } } = this.context;
    const { location: { pathname, query } } = this.props;
    const tagIds = jobItem && jobItem.tags.map(item => ( item.id ));
    const params = { ...jobItem, tag_ids: tagIds };
    params.status = data.status;
    return this.props.JobDucks.putJob(params)
      .then(() => {
        this.setState({ isOpen: !isOpen });
        history.push({ pathname, search: queryString.stringify(query)});
        this.getJob();
      })
      .catch(() => this.setState({ isOpen: false }));
  }

  chunkArray(chunkSize){
    const { jobListIds } = this.props;
    let index = 0;
    const arrayLength = jobListIds.length;
    const tempArray = [];
    
    for (index = 0; index < arrayLength; index += chunkSize) {
      const myChunk = jobListIds.slice(index, index+chunkSize);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }
    this.setState({jobArray: tempArray, jobData: jobListIds });
  }

  goTo(url) {
    const { router: { history } } = this.context;
    const { location: { pathname, search } } = this.props;
    history.push({ pathname: url, search: queryString.stringify({ parent: `${pathname}${search}`, admin_list : true })});
  }

  render() {
    const { location, cardView, tableCounts,refreshDisable, jobdetailMap, commentList, basicSettingDetail, location:{ query }, userProfile, jobSummaryView , employeeList , customerList , tagsList, newQuotedRecurringList, newQuotedProjectList,newTimeAndMaterialList} = this.props;
    const { jobArray, isOpen, jobItem, status, notesLoading, loading, exportQuotedRecurring , exportTimeMaterials, exportQuotedProject, isLoading, isLoadingProject, image, files, showModal, selectedId, valueStatus, selectedItem} = this.state;
    let employeeDropdownList = [];
    employeeList && employeeList.results && employeeList.results.length && (employeeList.results).forEach((item)=>{
      employeeDropdownList.push({full_name: item.user.full_name, id : item.id})
    });
    let customerDropdownList = [];
    customerList && customerList && customerList.length && (customerList).forEach((item)=>{
      customerDropdownList.push({customer_name: item.customer_name, id : item.id})
    });
    const employeeTagsList = tagsList && tagsList.length && tagsList[0] && tagsList[0].tag_collection && tagsList[0].tag_collection.length && tagsList[0].tag_collection.map((item)=>({name: item,id: item}));
    if (this.props.refreshLoader) return <SectionLoaderAtom active />;
    return (
      <div className="pb-5 ml-3 mr-4">
        <section>
          {!cardView ?
            <section>
              <ManageQuotedRecurringJobs
                // openJobSelectionModal={this.openJobSelectionModal}
                location={location}
                quotedRecurringList={newQuotedRecurringList.results}
                totalRecords = { newQuotedRecurringList.count}
                goTo={this.goTo}
                saveStatus={this.saveStatus}
                toggle={this.toggle}
                getJobList={this.getJobList}
                loading={loading}
                pageQuotedRecurring={this.state.pageQuotedRecurring}
                exportCSV={this.exportCSV}
                exportQuotedRecurring = { exportQuotedRecurring }
                setJobReportsData={this.setJobReportsData}
                isLoading={isLoading}
                toggleAccordian = {this.toggleAccordian}
                quotedRecurring={this.state.quotedRecurring}
                getJob={this.getJob}
                setEmployee = {this.setEmployee}
                setTask = {this.setTask}
                tableData={this.state.tableData}
                refresh={this.refresh}
                userProfile={userProfile}
                openAccordian={this.openAccordian}
                originalCount = {tableCounts.quoted_count}
                isStatusLoading = {this.state.isStatusLoading}
                togglePopOver ={this.togglePopOver}
                PopOverOpen ={this.state.PopOverOpen}
                selectedItem={this.state.selectedItem}
                refreshDisable={refreshDisable}
                getNewUserPreference={this.getNewUserPreference}
                setUserPreference={this.setUserPreference}
                getCustomers={this.getCustomers}
                customerList={customerDropdownList}
                getEmployees = {this.getEmployees}
                newUserViewPreferenceQuotedRecurring={this.props.newUserViewPreferenceQuotedRecurring}
                getJobs = {this.getJobs}
                employeeList={employeeDropdownList}
                setStartAndEndDate = {this.setStartAndEndDate}
                jobsAndCodesList={this.props.quotedRecurringjobsAndCodesList}
                getJobTags={this.getJobTags}
                jobTagList={this.props.jobTagList}
                getTasks={this.getTasks}
                TasksAndCodesList={this.props.quotedRecurringTasksAndCodesList}
                getTaskTags={this.getTaskTags}
                taskTagList={this.props.taskTagList}
                dateFormat= {this.props.dateFormat}
                sortJobCodes={this.sortJobCodes}
                dateFormatDisplay={this.props.dateFormatDisplay}
                getEmployeeTags= {this.getEmployeeTags}
                employeeTagsList={employeeTagsList}
                setTags={this.setTags}
              />

                <ManageQuotedProjectJobs
                  // openJobSelectionModal={this.openJobSelectionModal}
                  location={location}
                  goTo={this.goTo}
                  saveStatus={this.saveStatus}
                  toggle={this.toggle}
                  getJobList={this.getJobList}
                  loading={loading}
                  pageQuotedProjected={this.state.pageQuotedProjected}
                  exportCSV={this.exportCSV}
                  exportQuotedProject = { exportQuotedProject }
                  setJobReportsData={this.setJobReportsData}
                  isLoadingProject={isLoadingProject}
                  toggleAccordian = {this.toggleAccordian}
                  quotedProject ={this.state.quotedProject}
                  quotedProjectList={newQuotedProjectList.results}
                  getJob={this.getJob}
                  setEmployee = {this.setEmployee}
                  setTask = {this.setTask}
                  tableData={this.state.tableData}
                  refresh={this.refresh}
                  userProfile={userProfile}
                  setStartAndEndDate = {this.setStartAndEndDate}
                  totalRecords = { newQuotedProjectList.count}
                  openAccordian={this.openAccordian}
                  originalCount={tableCounts.project_count}
                  isStatusLoading = {this.state.isStatusLoading}
                  togglePopOver ={this.togglePopOver}
                  PopOverOpen ={this.state.PopOverOpen}
                  selectedItem={this.state.selectedItem}
                  refreshDisable={refreshDisable}
                  getNewUserPreference={this.getNewUserPreference}
                  setUserPreference={this.setUserPreference}
                  newUserViewPreferenceQuotedProject={this.props.newUserViewPreferenceQuotedProject}
                  getCustomers={this.getCustomers}
                  customerList={customerDropdownList}
                  getEmployees = {this.getEmployees}
                  employeeList={employeeDropdownList}
                  jobsAndCodesList={this.props.quotedProjectjobsAndCodesList}
                  getJobTags={this.getJobTags}
                  jobTagList={this.props.jobTagList}
                  getTasks={this.getTasks}
                  TasksAndCodesList={this.props.quotedProjectTasksAndCodesList}
                  getTaskTags={this.getTaskTags}
                  taskTagList={this.props.taskTagList}
                  getJobs = {this.getJobs}
                  dateFormat= {this.props.dateFormat}
                  sortJobCodes={this.sortJobCodes}
                  dateFormatDisplay={this.props.dateFormatDisplay}
                  getEmployeeTags= {this.getEmployeeTags}
                  employeeTagsList={employeeTagsList}
                  setTags={this.setTags}
                />

                <ManageTimeAndMaterialJobs
                  location={location}
                  goTo={this.goTo}
                  saveStatus={this.saveStatus}
                  toggle={this.toggle}
                  getJobList={this.getJobList}
                  loading={loading}
                  setStartAndEndDate = {this.setStartAndEndDate}
                  pageTimeAndMaterials={this.state.pageTimeAndMaterials}
                  exportCSV={this.exportCSV}
                  exportTimeMaterials = { exportTimeMaterials }
                  setJobReportsData={this.setJobReportsData}
                  toggleAccordian = {this.toggleAccordian}
                  setEmployee = {this.setEmployee}
                  setTask = {this.setTask}
                  timeAndMaterials = {this.state.timeAndMaterials}
                  timeAndMaterialList ={newTimeAndMaterialList.results}
                  totalRecords ={newTimeAndMaterialList.count}
                  getJob={this.getJob}
                  tableData={this.state.tableData}
                  isLoadingMaterial={this.state.isLoadingMaterial}
                  refresh={this.refresh}
                  userProfile={userProfile}
                  openAccordian={this.openAccordian}
                  originalCount={tableCounts.time_and_material_count}
                  isStatusLoading = {this.state.isStatusLoading}
                  togglePopOver ={this.togglePopOver}
                  PopOverOpen ={this.state.PopOverOpen}
                  selectedItem={this.state.selectedItem}
                  refreshDisable={refreshDisable}
                  getNewUserPreference={this.getNewUserPreference}
                  setUserPreference={this.setUserPreference}
                  newUserViewPreferenceTimeAndMaterial={this.props.newUserViewPreferenceTimeAndMaterial}
                  getCustomers={this.getCustomers}
                  customerList={customerDropdownList}
                  getEmployees = {this.getEmployees}
                  employeeList={employeeDropdownList}
                  getJobs = {this.getJobs}
                  jobsAndCodesList={this.props.timeAndMaterialjobsAndCodesList}
                  getJobTags={this.getJobTags}
                  jobTagList={this.props.jobTagList}
                  getTasks={this.getTasks}
                  TasksAndCodesList={this.props.timeAndMaterialTasksAndCodesList}
                  getTaskTags={this.getTaskTags}
                  taskTagList={this.props.taskTagList}
                  dateFormat= {this.props.dateFormat}
                  sortJobCodes={this.sortJobCodes}
                  dateFormatDisplay={this.props.dateFormatDisplay}
                  getEmployeeTags= {this.getEmployeeTags}
                  setTags={this.setTags}
                  employeeTagsList={employeeTagsList}
                />

            </section>
            :
            <section>
              {jobArray.length ? jobArray.map((item) => (
                <Row>
                  <Col md={6} className="job-card-right-padding">
                    <JobListTableComponent
                      toggle={this.toggle}
                      location={location}
                      job={item[0]}
                      isNameVisible
                      goTo={this.goTo}
                      getJobDetails={this.getJobDetails}
                      jobDetail={jobdetailMap}
                      setJobReportsData={this.setJobReportsData}
                    />
                  </Col>
                  <Col md={6} className="job-card-left-padding">
                    {item.length > 1 ?
                      <JobListTableComponent
                        toggle={this.toggle}
                        location={location}
                        job={item[1]}
                        isNameVisible
                        goTo={this.goTo}
                        getJobDetails={this.getJobDetails}
                        jobDetail={jobdetailMap}
                        setJobReportsData={this.setJobReportsData}
                      /> : null }
                  </Col>
                </Row>)) : <div className="text-center mt-3 white-background pt-2 pb-2">No records Found!</div>}
            </section>
          }
          {isOpen &&
            <JobInfoModal
              isOpen={isOpen}
              toggle={this.toggle}
              location={location}
              jobView
              jobItem={jobItem}
              jobData={jobItem}
              status={status}
              updateStatus={this.updateStatus}
              initialValues={
                jobItem ? { status: jobItem.status } : null
              }
              notesLoading={notesLoading}
              jobNotes ={commentList}
              submit = {this.submitJobNotes}
              submitImageAndComment ={this.submitImageAndComment}
              onImageChange={this.onImageChange}
              image={image}
              showModal={showModal}
              files={files}
              onSubmit={this.submitJobNotes}
              showImageModal={this.state.showImageModal}
              toggleImageModal={this.toggleImageModal}
              isJobNotes = {this.state.isJobNotes}
              jobNotesLoader={this.state.jobNotesLoader}
              jobSummaryTask={jobSummaryView && jobSummaryView.tasks && jobSummaryView.tasks}
              manageJob="manageJob"
            />
          }

          {this.state.PopOverOpen &&
          <ModalComponent
            isOpen={this.state.PopOverOpen}
            heading="Select Status"
            size="md"
            toggle={this.togglePopOver}
          >
            <div className="ml-3 mr-4">
              <Dropdown isOpen={this.state.dropdownOpen} toggle={() => this.dropdownToggle()} className="pt-2">
                <DropdownToggle caret color="primary" className={styles["button-export"]}>
                  {selectedId === selectedItem.id ? valueStatus.label : selectedItem.status}
                </DropdownToggle>
                <DropdownMenu className="popover-override popover-override-jobs">
                  {CREATE_UPDATE_JOB_FILTER_OPTIONS &&
                    CREATE_UPDATE_JOB_FILTER_OPTIONS.map(status => (
                      <DropdownItem onClick={() => this.onStatusSelection(status, selectedItem.id)}>
                        {status.label}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
              <div className="text-right">
                <Button
                  className={`${styles["tooltip-btn"]} mt-2`}
                  onClick={() => this.saveStatus(this.state.key, valueStatus, selectedItem)}
                >
                  Save  <ButtonLoaderAtom active={this.state.isStatusLoading}/>
                </Button>
              </div>
            </div>
          </ModalComponent>
          }
          {/* {openJobSelectionFilter &&
            <JobSelectionModalComponent
              isOpen={openJobSelectionFilter}
              toggle={this.openJobSelectionModal}
            />
          } */}

        </section>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userProfile: UserDucks.profile(state),
  /*jobList: JobDucks.jobList(state),*/
  newQuotedRecurringList: JobDucks.newQuotedRecurringList(state),
  newQuotedProjectList: JobDucks.newQuotedProjectList(state),
  newTimeAndMaterialList: JobDucks.newTimeAndMaterialList(state),
  customerGroupByJob: JobDucks.customerGroupByJob(state),
  jobdetailMap: JobDucks.jobdetailMap(state),
  commentList: NoteDucks.commentList(state),
  jobSummaryView: JobDucks.jobSummaryView(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  jobListIds: JobDucks.jobListIds(state),
  tableCounts: JobDucks.tableCounts(state),
  jobNotes: JobDucks.jobNotes(state),
  newUserViewPreferenceQuotedRecurring: JobDucks.newUserViewPreferenceQuotedRecurring(state),
  newUserViewPreferenceQuotedProject: JobDucks.newUserViewPreferenceQuotedProject(state),
  newUserViewPreferenceTimeAndMaterial: JobDucks.newUserViewPreferenceTimeAndMaterial(state),
  employeeList: EmployeeDucks.employeeList(state),
  customerList: CustomerDucks.customerList(state),
  quotedRecurringjobsAndCodesList: JobDucks.quotedRecurringjobsAndCodesList(state),
  quotedProjectjobsAndCodesList: JobDucks.quotedProjectjobsAndCodesList(state),
  timeAndMaterialjobsAndCodesList: JobDucks.timeAndMaterialjobsAndCodesList(state),
  jobTagList: JobTagDucks.jobTagList(state),
  quotedRecurringTasksAndCodesList: TaskDucks.quotedRecurringTasksAndCodesList(state),
  quotedProjectTasksAndCodesList: TaskDucks.quotedProjectTasksAndCodesList(state),
  timeAndMaterialTasksAndCodesList: TaskDucks.timeAndMaterialTasksAndCodesList(state),
  taskTagList: TaskTagDucks.taskTagList(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  tagsList: EmployeeDucks.tagsList(state),
  dateFormatDisplay: BasicSettingDucks.dateFormatDisplay(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  CustomerDucks : bindActionCreators(CustomerDucks, dispatch),
  JobTagDucks: bindActionCreators(JobTagDucks, dispatch),
  TaskTagDucks: bindActionCreators(TaskTagDucks, dispatch),
});

ManageJobsSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isLoadingProject: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  /*jobList: PropTypes.array,*/
  jobdetailMap: PropTypes.object,
  jobListIds: PropTypes.array,
  basicSettingDetail: PropTypes.object.isRequired,
  updateList: PropTypes.func,
};

ManageJobsSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ManageJobsSection);
