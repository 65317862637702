import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { UncontrolledTooltip } from 'reactstrap';

import * as RouteDucks from 'ducks/routes';
import * as VendorDucks from 'ducks/vendors/vendor';

import FilterFormComponent from 'components/common/FilterForm';
import FilterDropdownComponent from 'components/common/FilterDropdown';

import { FILTER_OPTIONS } from 'constants/knowledgeBases';

/**
 * VendorListPage -> KnowledgeBaseFilterSection
 *
 * Components:
 *    - {@link VendorFilterComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    None
 */
class KnowledgeBaseFilterSection extends React.Component {
  constructor(props) {
    super(props);
    //  this.updateActiveStatus = this.updateActiveStatus.bind(this);
    this.state = { isOpen: false, isStatus: null, isSubmit: null };
    //  this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }

  render(){
    const {  location } = this.props;
    const { query } = location;

    return (
      <Row className="filter ml-3 mr-4">
        <Col md={6} className="pl-0">
          <div id="vendor" className='department-index'>
            <FilterDropdownComponent
              paramKey="is_active"
              location={location}
              options={FILTER_OPTIONS}
            />
            <UncontrolledTooltip placement="top" target="vendor">
              Vendor Status
            </UncontrolledTooltip>
          </div>
        </Col>
        <Col md={6} className="pr-0">
          <section className="float-right">
            <FilterFormComponent
              location={location}
              placeholder="Search name"
              initialValues={query}
            />
          </section>
          <section className="float-right">
            <Button
              id="add_kb"
              color="accent"
              onClick={() => this.goTo({
                pathname: '/knowledge-base/add',
                search: queryString.stringify({
                  parent: JSON.stringify(query),
                })
              })}
            >
              Add Knowledge Base
            </Button>
          </section>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  vendorSelectedList: VendorDucks.vendorSelectedList(state),
});

const mapActionsToProps = dispatch => ({
});

KnowledgeBaseFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

KnowledgeBaseFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KnowledgeBaseFilterSection);
