import React from 'react';

import PropTypes from 'prop-types';
import { server } from 'helpers/config';

const GeocodingOption = server.geocodingOption;

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    const { lat, lng } = props.initialCenter;

    this.state = {
      currentLocation: { lat, lng },
    };
  }

  componentDidMount() {
    // if (this.props.centerAroundCurrentLocation) {
    //   if (navigator && navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((pos) => {
    //       const coords = pos.coords;
    //       this.setState({
    //         currentLocation: {
    //           lat: coords.latitude,
    //           lng: coords.longitude,
    //         },
    //       });
    //     });
    //   }
    // }
    this.loadMap()
    this.forceUpdate()
  }

  componentDidUpdate(prevProps, prevState) {
    const { locations: prevLocations } = prevProps;
    const { locations: nextLocations, drawable, setLocation , google , setRestrictedViewbox , selectedGeofence} = this.props;
    const { lat, lng } = this.state.currentLocation;
    const zoomLevel = this.map.getZoom();
    var map = this.map;
    if (prevProps.google !== this.props.google) {
      this.loadMap();
    }
    // Set bounds if locations provided or changed
    if (!drawable && prevLocations.length !== nextLocations.length) {
      this.resetBounds();
    }
    if(JSON.stringify(prevLocations) !== JSON.stringify(nextLocations) && (!this.props.center)){
      this.resetBounds();
    }
    if (prevProps.drawable !== this.props.drawable ||
      prevProps.mode !== this.props.mode) {
      this.toggleDrawingManager();
    }
    if (prevState.currentLocation !== this.state.currentLocation
      || (prevProps.center !== this.props.center)) {
      this.recenterMap();
    }
    if (prevProps.setLocation && ((prevProps.setLocation.zoom !== setLocation && setLocation != null && setLocation.zoom) || (prevProps.setLocation.zoom !== zoomLevel))) {
      zoomLevel <= 17 ? this.map.setZoom(zoomLevel) : this.map.setZoom(17);
    }
    if (prevProps.bounds !== this.props.bounds && !this.props.locations.length) {
      this.map.fitBounds(this.props.bounds);
    }
    var timeout;
    if(setRestrictedViewbox){
      google.maps.event.addListener(map, 'bounds_changed', function() {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(function () {
        const coords = map.getBounds();
        const southWest = coords.getSouthWest();
        const northEast = coords.getNorthEast();
        const minLat = southWest.lat();
        const maxLat = northEast.lat();
        const minLon = southWest.lng();
        const maxLon = northEast.lng();
        setRestrictedViewbox({minLat, maxLat, minLon, maxLon})
        },    2000);
        });
    }
      
  }

  setDrawingManager() {
    const { google, mode, fillColor } = this.props;
    const maps = google.maps;
    let coordinates;
    let drawingMode;

    if (this.drawingManager) this.drawingManager.setMap(null);
    switch (mode) {
      case 'Polygon': drawingMode = 'polygon'; break;
      case 'Rectangle': drawingMode = 'rectangle'; break;
      case 'Circle': drawingMode = 'circle'; break;
      default: drawingMode = 'marker';
    }
    this.drawingManager = new maps.drawing.DrawingManager({
      drawingMode,
      drawingControl: false,
      // drawingControlOptions: {
      //   position: google.maps.ControlPosition.TOP_CENTER,
      //   drawingModes: ['marker', 'polygon', 'rectangle', 'circle'],
      // },
      markerOptions: {
        draggable: true,
      },
      polygonOptions: {
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: fillColor || '#162d6e',
        strokeColor: fillColor || '#162d6e',
        editable: true,
        draggable: true,
      },
      circleOptions: {
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: fillColor || '#162d6e',
        strokeColor: fillColor || '#162d6e',
        editable: true,
        draggable: true,
      },
      rectangleOptions: {
        fillOpacity: 0.5,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: fillColor || '#162d6e',
        strokeColor: fillColor || '#162d6e',
        editable: true,
        draggable: true,
      },
    });
    this.drawingManager.setMap(this.map);

    // Listener in location draw complete
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      let shape = {};
      if (event.type === 'marker') {
        shape = {
          type: 'Point',
          coordinates: [
            event.overlay.position.lng(),
            event.overlay.position.lat(),
          ],
        };
        coordinates = shape.coordinates;
      } else if (event.type === 'polygon') {
        let points = [];
        event.overlay.getPath().getArray().forEach((i) => {
          const arr = [ i.lng(), i.lat() ];
          points.push(arr);
        });
        if (points[0] !== points[points.length - 1]) {
          points.push(points[0]);
        }
        coordinates = points;
        // event.overlay.getPaths().forEach((path, index)=> {
        //   path.j.forEach((item) => {
        //     const arr = [ item.lng(), item.lat() ];
        //     points.push(arr);
        //   });
        //   if (points[0] !== points[points.length - 1]) {
        //     points.push(points[0]);
        //   }
        //   console.log('points', points);
        //   coordinates = points;
        // });
        shape = {
          type: 'Polygon',
          coordinates: [points],
        };
      } else if (event.type === 'rectangle') {
        const coords = event.overlay.getBounds();
        const SW = coords.getSouthWest();
        const NE = coords.getNorthEast();
        const latLng = new google.maps.LatLng(
          (SW.lat() + NE.lat()) / 2,
          (SW.lng() + NE.lng()) / 2,
        );
        shape = {
          type: 'Rectangle',
          coordinates: [
            [
              [NE.lng(), NE.lat()],
              [SW.lng(), NE.lat()],
              [SW.lng(), SW.lat()],
              [NE.lng(), SW.lat()],
              [NE.lng(), NE.lat()],
            ]
          ]
        };
        coordinates = latLng;
      } else if (event.type === 'circle') {
        const center = event.overlay.getCenter();
        shape = {
          type: 'Circle',
          coordinates: [ center.lng(), center.lat() ],
          radius: event.overlay.radius,
        };
        coordinates = [ center.lng(), center.lat() ];
      }
      // setShapeInfo(shape);
      this.updateShape(shape, coordinates, event, this.drawingManager);
    });
  }

  loadMap() {
    if (this.props && this.props.google) {
      // google is available

      const { google, zoom, drawable, controlSize } = this.props;
      const { lat, lng } = this.state.currentLocation;
      const maps = google.maps;
      const center = new maps.LatLng(lat, lng);
      const mapConfig = Object.assign({}, { center, zoom, controlSize , maxZoom: 18});
      this.map = new maps.Map(this.node, mapConfig);

      // this.recenterMap();
      if (drawable) this.setDrawingManager();
      this.resetBounds();
      this.props.bounds && !this.props.selectedGeofence && this.map.fitBounds(this.props.bounds);
    }
  }

  toggleDrawingManager() {
    if (this.props.drawable) {
      this.setDrawingManager();
    } else if (this.drawingManager) {
      this.drawingManager.setMap(null);
    }
  }

  resetBounds() {
    const { google: { maps }, locations } = this.props;
    const bounds = new maps.LatLngBounds();
    locations.map(loc => {
      if (loc.shape_type === 'Point') {
        bounds.extend({
          lat: loc.lat_long.coordinates[1] , lng: loc.lat_long.coordinates[0],
        });
      } else if (loc.shape_type === 'Polygon') {
        loc.lat_long.coordinates[0].map(item =>
          bounds.extend({ lat: item[1] , lng: item[0] }));
      } else if (loc.shape_type === 'Rectangle') {
        loc.lat_long.coordinates[0].map(item =>
          bounds.extend({ lat: item[1] , lng: item[0] }));
      } else if (loc.shape_type === 'Circle') {
        const circle = new maps.Circle({
          radius: loc.radius,
          center: {
            lat: loc.lat_long.coordinates[1],
            lng: loc.lat_long.coordinates[0],
          },
        });
        bounds.union(circle.getBounds());
      }
      return bounds;
    });

    if (locations.length && (JSON.stringify(bounds) !== JSON.stringify(new maps.LatLngBounds()))) {
      this.map.fitBounds(bounds);
    }
    // Control maxZoom level when fitting the bounds
    // if (zoomLevel > 18) this.map.setZoom(18);
  }

  updateShape(shapeData, latLong, event, drawingManager) {
    const { google: { maps }, setShapeInfo } = this.props;
    const bounds = new maps.LatLngBounds();
    let polygonAddress;
    let googleAddress;
    let locationData;
    let shapes;

    if (shapeData.type === 'Point') {
      locationData = { lat: parseFloat(latLong[1]), lng: parseFloat(latLong[0]) };
    }
    else if (shapeData.type === 'Polygon') {
      latLong.map(item => {
        const latLng = new maps.LatLng( item[1], item[0] );
        return bounds.extend(latLng);
      });
      polygonAddress = bounds.getCenter();
      const latitude = polygonAddress.lat();
      const longitude = polygonAddress.lng();
      locationData = { lat: latitude, lng: longitude };
    }
    else if (shapeData.type === 'Rectangle') {
      locationData = { lat: latLong.lat(), lng: latLong.lng() };
    }
    else if (shapeData.type === 'Circle') {
      locationData = { lat: latLong[1], lng: latLong[0] };
    }
    if(GeocodingOption == 'google maps'){
    const geocoder = new maps.Geocoder();
    geocoder.geocode({ location: locationData }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          googleAddress = results[0].formatted_address.toString();
          shapes = {
            ...shapeData,
            address: googleAddress,
          };
        }
      }
      setShapeInfo(shapes || shapeData);
      event.overlay.setMap(null);
      this.drawingManager.setMap(null);
      return null;
    });
    }
    else{
      this.props.getAddressForMarker({position: locationData})
      .then((res) => {
         if(res.action.payload.data){
          const data = res.action.payload.data;
          const googleAddress = data.display_name.toString();
          shapes = {
            ...shapeData,
            address: googleAddress,
          };
          setShapeInfo(shapes || shapeData);
          event.overlay.setMap(null);
          this.drawingManager.setMap(null);
          return null;
        }
      })
    }
  }

  recenterMap() {
    const { lat, lng } = this.state.currentLocation;
    const { google, center } = this.props;
    const maps = google.maps;

    if (this.map && center) {
      if (center.viewport) {
        this.map.fitBounds(center.viewport);
      } else {
        this.map.setCenter(center.location || new maps.LatLng(lat, lng));
        this.map.setZoom(17); // Todo
      }
    }
  }

  renderChildren() {
    const { children } = this.props;
    if (!children) return;
    /* eslint-disable */
    if (this.map) {
      return React.Children.map(children, c => {
        if (!c) return null;
        return React.cloneElement(c, {
          map: this.map,
          google: this.props.google,
          mapCenter: this.state.currentLocation,
        });
      });
    }
    return null;
    /* eslint-enable */
  }

  render() {
    return (
//      <div ref={node => {this.node = node}} style={{ width: '100%', height: '100%' }}>
      <div id ="map" className={this.props.customHeight ? `ml-3 mr-4`: `mr-4`} ref={node => {this.node = node}} style={this.props.customHeight ? { width: '97%', height: '90%' } : {width : '100%', height: '100%'}}>
        Loading map...
        {this.renderChildren()}
      </div>
    );
  }
}

MapComponent.defaultProps = {
  initialCenter: {
    lat: 37.774929,
    lng: -95.712891,
  },
  /*controlSize: 30,*/
  // centerAroundCurrentLocation: true,
  zoom:3,
  maxZoom: 18,
  locations: [],
};

MapComponent.propTypes = {
  google: PropTypes.object.isRequired,
  zoom: PropTypes.number,
  initialCenter: PropTypes.object,
  // centerAroundCurrentLocation: PropTypes.bool,
  setShapeInfo: PropTypes.func,
  locations: PropTypes.array,
  mode: PropTypes.string,
  drawable: PropTypes.bool,
  fillColor: PropTypes.string,
};

export default MapComponent;
