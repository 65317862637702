
import React, {PureComponent} from 'react';
import { Form , Modal, ModalBody , ModalHeader , FormGroup, FormText, Label, Input , Button, Row, Col , Collapse} from 'reactstrap';

import { Formik,FieldArray } from 'formik';
import closeimage from './CloseCross.png';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import SectionLoaderAtom from 'atoms/SectionLoader';
import EditIconAtom from 'atoms/EditIcon';
import DeleteTagModal from 'components/common/DeleteTagModal';


class TagModal extends PureComponent {

  constructor(props) {
    super(props);
    this.deleteTag= this.deleteTag.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.deleteAllTags = this.deleteAllTags.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.ref = React.createRef();
    //this.handleClickOutside = this.handleClickOutside.bind(this);
 
    this.state = { 
      tagsToBeDeleted:[],
      isLoading: false,
      submitTag: false,
      isChecked :  props.tagsList && props.tagsList.length && props.tagsList.map((item)=> false),
      isDeleteModal: false
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.tagsList !== this.props.tagsList){
      this.setState({isChecked : nextProps.tagsList && nextProps.tagsList.length && nextProps.tagsList.map((item)=> false)})
    }
  }

  toggle(){
    const { isDeleteModal, tagsToBeDeleted } = this.state;
    if(!tagsToBeDeleted.length){
      return toast.error('Please select tags to be deleted');
    }
    else{
      this.setState({isDeleteModal:!isDeleteModal});
    }
  }

  async onSubmit(values, { resetForm, setSubmitting, setErrors }){
    this.setState({submitTag: true});
    const { inputType, invite } = this.state;
    const { phoneFieldMandatory , initialValues} = this.props;
    setErrors({});
    if(!values.name){
      this.setState({submitTag: false});
      return toast.error('Please add the name of the tag');
    }
    try {
      await this.props.usecase.addEmployeeTag({...values, tag_type: 3});
      values.name = '';
      this.props.usecase.getEmployeeTags({paginate:false, tag_type: 3});
      /*this.toggleTagModal();*/
      this.setState({submitTag: false});
    } catch (e) {
      this.setState({submitTag: false});
      console.log(e.errors);
      setErrors(e.errors);
    }
    setSubmitting(false);
  }


  deleteAllTags(val){
    const { tagsList } = this.props;
    const { tagsToBeDeleted } = this.state;
    if(val.target.checked == false){
      this.setState({tagsToBeDeleted : []});
      this.setState({isChecked : this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> false)})
    }
    else{
      const a = [];
      tagsList.forEach((item, index)=>{
        a.push(item);
      });
      this.setState({tagsToBeDeleted : a});
      this.setState({isChecked : this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> true)})
    }
  }


  async deleteSelectedTags(){
    this.setState({isLoading : true});
    const { tagsCollection } = this.props;
    const { tagsToBeDeleted } = this.state;
    const tagsCollectionId = tagsCollection[0].id;
    const selectedTags = [];
    let resetChecked = this.props.tagsList && this.props.tagsList.length &&  this.props.tagsList.map((item)=> false);
    tagsToBeDeleted && tagsToBeDeleted.forEach((item)=>{
      selectedTags.push(item.name);
    });
    if(!tagsToBeDeleted.length){
      this.setState({isLoading : false });
      return toast.error('Please select tags to be deleted');
    }
    await this.props.usecase.deleteTags(tagsCollectionId, selectedTags)
      .then((res) => {
        this.props.usecase.getEmployeeTags({paginate:false, tag_type: 3});
        toast.success('Tags deleted successfully');
        this.setState({isLoading : false , isChecked: resetChecked, tagsToBeDeleted: [], isDeleteModal:false});
      })
     .catch((err) => {
        toast.error('No tags selected');
        this.setState({isLoading : false,  isChecked: resetChecked, tagsToBeDeleted: []});
     });

  }

  async updateTagName(item,setFieldValue,index){
    this.setState({isLoading : true});
    const { tagsCollection } = this.props;
    const tagsCollectionId = tagsCollection[0].id;
    const newTagNAme=item.name.trim()
    if(item.id===newTagNAme){
      setFieldValue(`tagList.${index}.status`,false)
      return
    }
    if(newTagNAme === ''){
      toast.error("Tag name can't be blank")
      return 
    }
    setFieldValue(`tagList.${index}.submit`, true)
    await this.props.usecase.updateTagName(tagsCollectionId, {old_value: item.id,
    new_value:newTagNAme})
      .then((res) => {
        this.props.usecase.getEmployeeTags({paginate:false, tag_type: 3});
        toast.success('Tags name updated successfully');
      })
     .catch((err) => {
        setFieldValue(`tagList.${index}.name`, item.id)
        toast.error('Something went wrong');
     });
     setFieldValue(`tagList.${index}.submit`, false)
     setFieldValue(`tagList.${index}.status`,false)
  }

  deleteTag(item, index){
    const { tagsList } = this.props;
    const { tagsToBeDeleted , isChecked} = this.state;
    var isTagPresent = tagsToBeDeleted.indexOf(item);
    const isAnyItemChecked = isChecked.length && isChecked.find((item)=>item === true);
    if(!isAnyItemChecked){

    }
    if(isTagPresent === -1){
      tagsToBeDeleted.push(item);
      let a = isChecked && isChecked.map((item)=>item);
      a[index] = true;
      this.setState({isChecked : a});
    }
    else{
      let filteredTags =  tagsToBeDeleted.filter((i)=>i.id !== item.id);
      let a = isChecked && isChecked.map((item)=>item);
      a[index] = false;
      this.setState({isChecked : a, tagsToBeDeleted : filteredTags });
    }
  }




  render() {
    const {isOpen, toggle, tagsList } = this.props;
    const { isLoading , isChecked, isDeleteModal } = this.state;
    const isAnyTagSelected = isChecked.length && isChecked.filter((item)=>item == true);
    const isSelectAllTrue = isAnyTagSelected && tagsList.length && isAnyTagSelected.length == tagsList.length ? true : false;
   
    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className='custom-height-modal'
          size="md"
          >
          <ModalHeader>Manage Tags
            <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
          </ModalHeader>
            <Formik
              initialValues={{name:'',tagList:tagsList.length?tagsList:[]}}
              onSubmit={this.onSubmit}
              enableReinitialize={true}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue
                }) => (
                <Row>
                  <Form onSubmit={handleSubmit} className={`${styles['delete-tags-form']} ml-3 mr-4`}>
                  <ModalBody className="pt-0">
                  <Form  autocomplete = "off">
                    <div className="error-text mb-2">{errors && errors.non_field_errors && errors.non_field_errors[0]}</div>
                    <div>
                        <div className="mt-2">
                          <div className ="mb-2" style={{fontSize: '16px'}}>Add New Tags</div>
                           <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Add New Tags"
                            onChange={handleChange}
                            value={values.name}
                            className={errors && errors.name ? "form-control-error" : null}
                            data-lpignore={true}
                            autoComplete="new-password"
                          />
                          <FormText color="danger">{errors && errors.name}</FormText>
                        </div>
                        <div className={styles['manage-tags']}>
                          <Input
                            type="checkbox"
                            id="selectAll"
                            name="selectAll"
                            onChange={(val)=>this.deleteAllTags(val)}                          
                            className={styles['checkbox-tags']}
                            checked={isSelectAllTrue}
                          />
                          <span className="ml-3" style={{fontSize: '16px'}}>Select All</span>
                          <span className={`${styles['deletetag']} link-name float-right`} style={{fontSize: '16px'}} onClick={()=>this.toggle()}>Delete</span>
                        </div>
                        <div className={styles['tags-list']}>
                        <FieldArray
                         name="friends"
                         render={arrayHelpers => (
                          <div>
                          {values.tagList.length > 0 ? values.tagList.map((item, index)=>(
                              <div className={styles['taglist']}>
                                <Input
                                  type="checkbox"
                                  id={item.id}
                                  label={item.name}
                                  name={item.id}
                                  onChange={(val)=>this.deleteTag(item, index)}
                                  className={styles['checkbox-tags-list']}
                                  checked={isChecked[index]}
                                />
                               {/* // <span className={styles['custom-position']}>{item.name}</span> */}
                                {!item.status?<span className={styles['custom-position']}>{item.name}</span>:<div className={styles['save-button']}><Input
                                  type="text"
                                  id={`tagList.${index}.name`}
                                  label={item.name}
                                  name={`tagList.${index}.name`}
                                  onChange={handleChange}
                                  value={item.name}        
                                  className={errors && errors.name ? `form-control-error ${styles['custom-position']}` : styles['custom-position']}
                                  data-lpignore={true}
                                  autocomplete="off"
                                  />
                                  <Button
                                    className={`${styles["form-button"]}`}
                                    onClick={()=>{this.updateTagName(item,setFieldValue,index)}}
                                  >
                                      Save{" "}
                                      <ButtonLoaderAtom
                                        active={item.submit}
                                      />
                                  </Button>
                                  </div>
                                }
                                <EditIconAtom onClick={()=>setFieldValue(`tagList.${index}.status`, true)}/>
                              </div>
                              )
                            ) : <div className="text-center mt-3">
                                <span>No Records Found</span>
                              </div>
                            }
                            </div>
                       )}/>
                        </div>
                      </div>
                      <Button
                          className={`${styles['form-button']} mb-3 ml-1 mr-1 btn btn-secondary btn-grp-alignment float-right`}
                          type="submit"
                        >
                          Add tag <ButtonLoaderAtom active={this.state.submitTag} />
                        </Button>
                    </Form>                
                  </ModalBody>
                  </Form>
                </Row>
                   )}
            />
               
        </Modal>
        {isDeleteModal &&
          <DeleteTagModal
            isOpen={isDeleteModal}
            toggle={this.toggle}
            deleteSelectedTags={this.deleteSelectedTags}
            isSubmitting={isLoading}
          />
        }
      </div>
    );}
  }
  
export default TagModal;
