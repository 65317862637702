import React from "react";
import {
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Formik } from "formik";
import { Handle, Track, Tick } from "./sliderComponents";

import ButtonLoaderAtom from "atoms/ButtonLoader";
import styles from "./styles.module.scss";

class AssessmentFormOutcome extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.defaultValues = [
      props.initialValues?.upperLimit / 2,
      props.initialValues?.upperLimit,
    ];
    this.sliderStyle = { position: "relative", width: "100%", marginTop: 25 };
    this.railStyle = {
      position: "absolute",
      width: "100%",
      height: 8,
      cursor: "pointer",
      backgroundColor: "#e5eaed",
    };
  }

  async onSubmit(values, { setSubmitting, setErrors }) {
    let errors = {};
      if (values.success_message === undefined || values.success_message === "") {
      errors.success_message = "Enter Success Message";
    }
    if (values.failed_message === undefined || values.failed_message === "") {
      errors.failed_message = "Enter Failure Message";
    }
    if (values.correct_question === "" || values.correct_question === 0 || (values.correct_question>values.total_questions) || values.correct_question === null || values.correct_question === undefined) {
      errors.correct_question =
      values.correct_question === null || values.correct_question === "" || values.correct_question === undefined
          ? "Enter no of correct questions"
          : values.correct_question === 0 ?"No of correct questions should be greater than zero":'No of correct questions must be less than no of total questions';
    }
    if (Object.getOwnPropertyNames(errors).length > 0) {
      setErrors(errors);
      return;
    }
    let data = [];
    let updatedData = [];
    data.push({ action_code: "SUCCESS", message: null, limit: [] });
    data.push({ action_code: "FAILED", message: null, limit: [] });

    data.forEach((item) => {
      if (item.action_code === "SUCCESS") {
        let obj = item;
        obj.message = values.success_message;
        updatedData.push(obj);
        obj.limit =
          values.correct_question !== 0
            ? [values.correct_question, values.total_questions]
            : [];
      } else if (item.action_code === "FAILED") {
        let obj = item;
        obj.message = values.failed_message;
        updatedData.push(obj);
      }
    });
    this.props.submitData(updatedData).catch((e) => {
      setErrors(e.errors);
    });
  }

  onChange(data) {
    this.setState({ scoreRange: data });
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (nextProps.initialValues !== prevProps.initialValues) {
      this.setState({ scoreRange: nextProps.initialValues.success_limit });
    }
  }

  render() {
    const {
      initialValues,
      saveAndNextButtonLoaderFlag,
    } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={handleSubmit}
            style={{ backgroundColor: "white" }}
            className="mt-0 pt-2"
          >
            <div className="ml-5">
              <Row className={"justify-content-center"}>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>Total questions</Label>
                    <Input
                      type="number"
                      name="total_questions"
                      placeholder="Write correct quetions no here"
                      data-lpignore={true}
                      autoComplete="off"
                      value={values.total_questions}
                      disabled
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"justify-content-center"}>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>
                      Correct answer required to clear the assessment
                    </Label>
                    <Input
                      type="number"
                      name="correct_question"
                      placeholder="Write correct quetions no here"
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.correct_question}
                      className={
                        errors && errors.correct_question
                          ? "form-control-error"
                          : null
                      }
                    />
                    <div style={{ minHeight: "3vh" }}>
                      <FormText color="danger">
                        {errors && errors.correct_question}
                      </FormText>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"justify-content-center"}>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>
                      Message to be shown when form is successfully submitted
                    </Label>
                    <Input
                      type="text"
                      name="success_message"
                      placeholder="Write message here"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.success_message}
                      className={
                        errors && errors.success_message
                          ? "form-control-error"
                          : null
                      }
                    />
                    <div style={{ minHeight: "3vh" }}>
                      <FormText color="danger">
                        {errors && errors.success_message}
                      </FormText>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className={"justify-content-center"}>
                <Col md={8} className="mt-4">
                  <FormGroup>
                    <Label>Message to be shown when submission is failed</Label>
                    <Input
                      type="text"
                      name="failed_message"
                      placeholder="Write message here"
                      data-lpignore={true}
                      autoComplete="off"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.failed_message}
                      className={
                        errors && errors.failed_message
                          ? "form-control-error"
                          : null
                      }
                    />
                    <div style={{ minHeight: "3vh" }}>
                      <FormText color="danger">
                        {errors && errors.failed_message}
                      </FormText>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="mr-5 mt-2 mb-5">
                  <div
                    className={`d-flex justify-content-between`}
                  >
                    <button
                      className={`${styles['back-secondary-color']}`}
                      type="submit"
                      onClick={() => this.props.handleBack()}
                    >
                      Back
                    </button>
                    <Button
                      type="submit"
                      color="primary"
                    >
                      {JSON.stringify(initialValues) === JSON.stringify(values) ? 'Submit' : 'Save & Submit'} <ButtonLoaderAtom active={saveAndNextButtonLoaderFlag} />
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      />
    );
  }
}

export default AssessmentFormOutcome;
