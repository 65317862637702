import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { toast } from 'react-toastify';

import * as ReportDucks from 'ducks/reports/report';
import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';

import MealViolationDetailFilterComponent from 'components/reports/mealViolation/DetailFilterForm';
import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { REPORT_KEY_MAPPINGS } from 'constants/reports';

/**
 * MeanViolationFilterSection -> MealViolationDetailFilterComponent
 *
 * Components:

 *    - {@link MealViolationDetailFilterComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    - downloadReportData
 */
class MealViolationDetailFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.export = this.export.bind(this);
    this.getParsedParams = this.getParsedParams.bind(this);
    this.state = { isLoading : false };
  }

  getParsedParams() {
    const { location: { query } } = this.props;
    let parsedParams;

    try {
      parsedParams = JSON.parse(query.reportDetail);
      parsedParams.start_date = moment(parsedParams.start_date).toISOString();
      parsedParams.end_date = moment(parsedParams.end_date).toISOString();
    } catch (e) {
      console.log(e);
    }
    return parsedParams;
  }

  export() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    this.setState({ isLoading: true });


    return this.props.ReportDucks.downloadReportData({ ...this.getParsedParams(), ...query, ...params })
      .then((data) => {
        const date = moment().format('DD/MM/YYYY');
        // Only for IE 11
        if (window.navigator.msSaveOrOpenBlob) {
          const a = document.createElement("download");
          document.body.appendChild(a);
          a.style.display = "none";
          a.onclick = ((evx) =>{
            const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            window.navigator.msSaveOrOpenBlob(myBlob, `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`);
          });
          a.click();
        }
        // chrome ,safari and mozilla browsers
        else {
          const  myBlob =  new Blob([data.action.payload] , {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
          const  url = window.URL.createObjectURL(myBlob);
          const element = document.createElement("a");
          document.body.appendChild(element);
          element.href = url;
          element.download = `${REPORT_KEY_MAPPINGS[params.code]}-${date}.xlsx`;
          element.click();
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        toast.error('Something went wrong. Please contact your administrator.')
        this.setState({ isLoading: false })});
  }

  render() {
    const { dateFormat, location } = this.props;
    const { query } = location;
    const formattedDates = {};
    const { isLoading } = this.state;

    if (query.start_date) formattedDates.start_date = moment(query.start_date).toISOString();
    if (query.end_date) formattedDates.end_date = moment(query.end_date).toISOString();
    return (
      <Row className="filter">
        <Col md={10}>
          <MealViolationDetailFilterComponent
            dateFormat={dateFormat}
            location={location}
            initialValues={{
              ...this.getParsedParams(),
              ...query,
              ...formattedDates,
            }}
          />
        </Col>
        <Col md={2} className="pr-2">
          {Object.keys(query).length > 0 &&
            <section className="float-right mr-4">
              <Button color="accent" onClick={this.export}>
                Export <ButtonLoaderAtom active={isLoading} />
              </Button>
            </section>
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  ReportDucks: bindActionCreators(ReportDucks, dispatch),
});

MealViolationDetailFilterSection.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

MealViolationDetailFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(MealViolationDetailFilterSection);
