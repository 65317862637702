import React from 'react';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import closeimage from './Close-Cross.png';

/**
 * EmployeeListPage -> EmployeeFilterSection -> ResetPasswordFormModalComponent
 *
 * Props:
 *    - update
 */
class ActivationCodeModal extends React.PureComponent {

  render() {
    const { isOpen, toggle, data, otpInfo } = this.props;
   
    const smsOTP = otpInfo.find((i) => i.type === 'SMS');
    const emailOTP = otpInfo.find((i) => i.type === 'EMAIL');
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="form-modal"
        backdrop="static"
      >
        <ModalHeader>Activation Codes
          <img src={closeimage} className="close_image" height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <ModalBody>
            <div className="pos-relative ml-4 mr-4 pl-1 pr-2">
              <p>Email: { data && data.email} </p>
              <p><b>Verification Codes : {emailOTP && emailOTP.code}</b></p>
              <p>Phone number : {data && data.phone_ext}-{ data && data.phone_no}</p>
              <p><b>Verification Codes : {smsOTP && smsOTP.code}</b></p>
            </div>
          </ModalBody>
          <ModalFooter className="float-left mr-4 pr-3 pb-4">
            <small>**Please note the following codes are active for next 24 hours.</small>
          </ModalFooter>

      </Modal>
    );
  }
};





export default ActivationCodeModal;
