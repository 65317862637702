import React from 'react';
import PropTypes from 'prop-types';
import Table from 'reactstrap/lib/Table';
import EditIcon from 'components/customers/list/Edit.png';


/**
 * CustomerListPage -> CustomerListSection ->  CustomerTableComponent
 *
 * Props:
 *    - employee list
 */
const CustomerTableComponent = (props, context) => {
  const { data, toggle } = props;
  return (
   <div className="ml-3 mr-4">
      <Table hover size="sm" className="table-customer">
      <thead>
        <tr>
          <th className="text-center pl-3">Action</th>
          <th className="text-left">Name</th>
          <th className="text-left">Address</th>
          <th className="text-right">Phone Number</th>
          <th>&nbsp;</th>
          <th className="text-left">Tags</th>
          <th className="text-left pr-3">Status</th>
        </tr>
      </thead>
      <tbody>
        {data.results && data.results.map(item => {
          const tagNames = item.tags.map(tagName=>tagName);
          const allTags=tagNames.join();

          return(
            <tr key={item.id}>
              <td className="text-center pl-3">
                <span title="Edit Customers" className="cursor-pointer"><img alt="" src={EditIcon} height="15" width="15" onClick={() => toggle(item)}/></span>
                {/*
                  <span title="Delete Customers" className="cursor-pointer"><img src={DeleteIcon} height="35" width="35" onClick="#"/></span>
                  <Link className={styles['margin-eye']} to={{
                    pathname: `/customers/list/${item.id}/details`,
                    search: queryString.stringify({ parent: `${pathname}${search}`})
                  }} title="View Customers" >
                    <EyeOpenIconAtom />
                  </Link>
                */}
              </td>
              <td className="text-left" title={item.customer_name}>{item.customer_name}</td>
              <td className="text-left" title={item.address}>{item.address}</td>
              <td className="text-right" title={item.phone_no}>{item.phone_no ? ( item.phone_ext ? `${item.phone_ext}-` : null ) : null}<span>{item.phone_no}</span></td>
              <td>&nbsp;</td>
              <td className="text-left" title={item.tags && item.tags.length && item.tags.map((item)=> ` ${item}`)}>{allTags}</td>
              <td className="text-left pr-3">{item.is_active ? "Active" : "Inactive"}</td>              
            </tr>
          )
        })}
        {!data.count &&
          <tr><td className="text-center" colSpan="7">No Records Found</td></tr>
        }
      </tbody>
      </Table>
   </div>
  );
};



CustomerTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  toggle: PropTypes.func,
};

CustomerTableComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default CustomerTableComponent;
