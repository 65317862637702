import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_CUSTOMER_TAGS = 'customers/tag/GET_CUSTOMER_TAGS';
const GET_CUSTOMER_TAGS_FULFILLED = 'customers/tag/GET_CUSTOMER_TAGS_FULFILLED';
const POST_CUSTOMER_TAG = 'customers/tag/POST_CUSTOMER_TAG';
const POST_CUSTOMER_TAG_FULFILLED = 'customers/tag/POST_CUSTOMER_TAG_FULFILLED';

export function getCustomerTags(params) {
  return {
    type: GET_CUSTOMER_TAGS,
    payload: axios.get(`${URLS.JOB}${URLS.TAG}`, { params }),
  };
}

export function postCustomerTag(data) {
  return {
    type: POST_CUSTOMER_TAG,
    payload: axios.post(`${URLS.JOB}${URLS.TAG}`, data),
  };
}

const defaultState = {
  list: { results: [] } ,
  detail: {},
};

function GET_CUSTOMER_TAGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function POST_CUSTOMER_TAG_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_CUSTOMER_TAGS_FULFILLED]: GET_CUSTOMER_TAGS_FULFILLED_REDUCER,
  [POST_CUSTOMER_TAG_FULFILLED]: POST_CUSTOMER_TAG_FULFILLED_REDUCER,
};


const customerTagSelector = state => state.customers.tag;

export const customerTagList = createSelector(
  customerTagSelector,
  instance => instance && instance.list,
);

export const customerTagDetail = createSelector(
  customerTagSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
