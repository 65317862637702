import React from 'react';
import exclamationMark from './exclamation-mark.png';
import styles from './styles.module.scss';
import moment from 'moment-timezone';

const CardExpiryNotification = (props) => {
  const currentMonth = moment().add(1, 'months');
  const startOfMonth = moment(currentMonth).startOf('month');
  let expiryDaysLeft = (moment().diff(startOfMonth, 'days'));
  expiryDaysLeft = Math.abs(expiryDaysLeft);
  return (<div className={`${styles['verify']} pt-2 pl-0 w-100 mb-3`}>
    <img
      className={styles['icon']}
      src={exclamationMark}
      alt="Icon"
    />
    {moment() < startOfMonth ?
      <span className={styles['display_text']}>Your default card will expire in {expiryDaysLeft} days. Request you to update the card. </span>
      :
      <span className={styles['display_text']}>Your default card is expired. Request you to update the card. </span>
    }
      </div>);
};

export default CardExpiryNotification;
