import combineReducers from 'redux/lib/combineReducers';

import address from './address';
import basicSetting from './basicSetting';
import department from './department';
import group from './group';
import notificationSetting from './notificationSetting';
import role from './role';
import systemSetting from './systemSetting';
import subCompany from './subCompany';
import team from './team';
import vendor from './vendor';
import plugin from './plugin';
import onboard from './onboard';
import adjustmentTypes from './adjustmentTypes';
import payment from './payment';

export default combineReducers({
  address,
  basicSetting,
  department,
  notificationSetting,
  role,
  systemSetting,
  subCompany,
  team,
  vendor,
  group,
  plugin,
  onboard,
  adjustmentTypes,
  payment
});
