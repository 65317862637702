import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Table from 'reactstrap/lib/Table';
import moment from 'moment';
import ActiveIconAtom from 'atoms/ActiveIcon';
import EditIconAtom from 'atoms/EditIcon';
import ViewIconAtom from 'atoms/ViewIcon';

/**
 * PayrollListPage -> PayrollListSection -> PayrollTableComponent
 *
 * Props:
 *    - payroll list
 */
const PayrollTableComponent = (props) => {
  const { data } = props;
  return (
    <div className="ml-3 mr-4">
      <Table hover responsive size="sm">
      <thead className="Header-col">
        <tr>
          <th width="8%" className="text-center">Action</th>
          <th>Payroll Name</th>
          <th>Payroll Cycle</th>
          <th>Start Date</th>
          <th className="text-right">First Half Closing Day</th>
          <th className="text-right">Second Half Closing Day</th>
          <th>Is Active?</th>
          <th>Is Default?</th>
        </tr>
      </thead>
      <tbody>
        {data.results && data.results.map(item => (
          <tr key={item.id}>
            <td className="text-center" title={ moment(item.end_date) < moment() ? 'View Payroll' : 'Edit Payroll' }>
              <Link to={`/payroll/${item.id}/detail`}>
                { moment(item.end_date) < moment() ? <ViewIconAtom /> : <EditIconAtom /> }
              </Link>
            </td>
            <td title={item.name}>{item.name}</td>
            <td title={item.payroll_cycle}>{item.payroll_cycle}</td>
            <td title={item.start_date_display}>{item.start_date_display}</td>
            <td title={item.first_half_of_month_closing_day} className="text-right">
              {item.first_half_of_month_closing_day}
            </td>
            <td title={item.second_half_of_month_closing_day} className="text-right">
              {item.second_half_of_month_closing_day}
            </td>
            {item.is_active ? <td>Yes</td> : <td>No</td>}
            {item.default ? <td>Yes</td> : <td>No</td>}
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
      </Table>
    </div>
  );
};

PayrollTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PayrollTableComponent;
