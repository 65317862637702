import React from "react";
import PropTypes from "prop-types";

import TwoColumnLayout from "layouts/TwoColumn";
import StorageGateway from "lib/storage-gateway";

import { NAV } from "constants/notification";
import { toast } from "react-toastify";

/**
 * AdjustmentTypesListPage
 *
 * Layout:
 *    - {@link SSTCFLayout}
 *
 * Sections:
 *    - {@link AdjustmentTypesFilterSection}
        {@link AdjustmentTypesListSection}
 *
 */

class DocumentCompletionReportListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authToken: null,
      userId: null,
      nre_company_id: null,
      company_id: null,
    };
    this.loadData = this.loadData.bind(this);
    this.eventListnerFunction = this.eventListnerFunction.bind(this);
    this.postMessageToChild = this.postMessageToChild.bind(this);
  }
  postMessageToChild(messageType) {
    const iFrame = document.getElementById("scheduling");
    iFrame.contentWindow.postMessage(messageType, "*");
  }
  loadData() {
    this.postMessageToChild("REFRESH_EMPLOYEE_COMPLETION_TABLE");
  }
  eventListnerFunction(event) {
    const data =
      event.data && typeof event.data !== "object" && JSON.parse(event.data);
    const message = data?.message;
    if (message === "FAILED_TOAST") {
      toast.error(data?.content?.toastMessage);
    } else if (message === "SUCCESS_TOAST") {
      toast.success(data?.content?.toastMessage);
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.eventListnerFunction, false);
    const oAuthToken = StorageGateway.get("graniteToken");
    const userId = StorageGateway.get("userId");
    const nre_company_id = StorageGateway.get("nre_company_id");
    const company_id = StorageGateway.get("company_id");
    this.setState({
      authToken: oAuthToken,
      userId: userId,
      nre_company_id: nre_company_id,
      company_id: company_id,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.eventListnerFunction);
  }

 

  render() {
    const { authToken, userId, nre_company_id, company_id } = this.state;

    return (
      <TwoColumnLayout
        navInfo={NAV.DocumentCompletionReportListPage}
        fetchData={this.loadData}
      >
        <div
          style={{
            height: "calc(100vh - 102px - 75px)",
            marginRight: "-15px",
          }}
        >
          <iframe
            id="scheduling"
            title="worksana-nre"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ width: "100%", height: "100%" }}
            src={`${
              process.env.REACT_APP_WMAPPER_BASE_URL
            }/documents-completion/report?access_token=${authToken}&user_id=${userId}&companyId=${company_id ||
              nre_company_id}`}
          />
        </div>
      </TwoColumnLayout>
    );
  }
}

DocumentCompletionReportListPage.propTypes = {
  location: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  AdjustmentTypesDucks: PropTypes.object.isRequired,
};

DocumentCompletionReportListPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default DocumentCompletionReportListPage;
