import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import { toast } from 'react-toastify';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { UncontrolledTooltip } from 'reactstrap';
import SectionLoaderAtom from 'atoms/SectionLoader';

import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as CustomerTagDucks from 'ducks/customers/tag';
import * as CustomerDucks from 'ducks/customers/customer';
import * as JobDucks from 'ducks/jobs/job';

import FilterDropdownComponent from 'components/common/FilterDropdown';
import FilterFormComponent from 'components/common/FilterForm';
import CustomerFormModalComponent from 'components/customers/list/FormModal';
import TagModalComponent from 'components/jobs/form/TagModal';

import { FILTER_OPTIONS } from 'constants/customers';

/**
 * CustomerListPage -> CustomerFilterSection
 *
 * Components:
 *    - {@link FilterDropdownComponent}
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    postActiveStatus
 */
class CustomerFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, isLoading: false,isTagLoading: false, isTagModal: false, isModalHidden: false};
    this.toggle=this.toggle.bind(this);
    this.createTag = this.createTag.bind(this);
    this.create=this.create.bind(this);
    this.goTo = this.goTo.bind(this);
    this.toggleTagModal = this.toggleTagModal.bind(this);
    this.deleteSelectedTags = this.deleteSelectedTags.bind(this);
    this.updateTagName = this.updateTagName.bind(this)
    this.getUpdatedList = this.getUpdatedList.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  hideModal(){
    const { isModalHidden } = this.state;
    this.setState({isModalHidden: !isModalHidden});
  }

  toggle() {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  goTo(url) {
    const { router: { history } } = this.context;
    history.push(url);
  }


  createTag(data) {
    const { change } = this.props;
    this.setState({ isTagLoading: true });
    return this.props.CustomerTagDucks.postCustomerTag({ ...data, tag_type: 0 })
  }

  deleteSelectedTags(tagId, selectedTags){
    return this.props.JobDucks.deleteSelectedTags(tagId, {tag_collection: selectedTags});
  }

  updateTagName(tagId,tagsData){
    return this.props.JobDucks.updateTagName(tagId, {tag_collection: tagsData});
  }

  create(data) {
    const { isOpen } = this.state;
    const { location: { query } } = this.props;
    const param = {customer_id: query.id};
    const pageSize = cookie.load('customer_list');
    return this.props.CustomerDucks.postCustomers(data, param)
      .then(() => {
        this.setState({ isOpen: !isOpen, isLoading: true });
        this.props.CustomerDucks.getCustomers({ ...query, 'pageSize': pageSize })
          .then(() => this.setState({ isLoading: false }))
          .catch(() => this.setState({ isLoading: false }));
      });
  }

  getUpdatedList(){
    return this.props.CustomerTagDucks.getCustomerTags({ paginate: false, tag_type: 0 });
  }

  toggleTagModal(data) {
    const { isTagModal } = this.state;
    this.setState({ isTagModal: !isTagModal });
  }

  render() {
    const {
      location,
      customerDetails,
      customerTagList,
      userCompany,
      companyPermissions,
    } = this.props;
    const { isOpen,isTagLoading, isLoading, isTagModal, isModalHidden } = this.state;
    const { query } = location;
    let initialValues={};
    initialValues={phone_ext: userCompany.phone_ext, user: { is_active: true } };
    const tags = customerTagList&& customerTagList.length && customerTagList[0].tag_collection && customerTagList[0].tag_collection.map(item => (
        { id: item , name: item }
      ));

    return (
      <Row className="filter ml-3 mr-4">
        <SectionLoaderAtom active = {isLoading} />
        <Col md={3} className="pl-0">
          <Button
            color="primary"
            id="add_customer"
            className="mr-2 align-top "
            onClick={() => this.toggle()}
          >Create New Customer
          </Button>
        </Col>
        <Col md={9} className="d-flex justify-content-end pr-0">
          <div id="customer">
            <FilterDropdownComponent
              paramKey="is_active"
              location={location}
              options={FILTER_OPTIONS}
            />
            <UncontrolledTooltip placement="top" target="customer">
              Customer Status
            </UncontrolledTooltip>
          </div>
          <Button
            color="primary"
            className="align-top"
            onClick={() => this.goTo({
              pathname: '/customers/bulk-upload',
              search: queryString.stringify({
                parent: JSON.stringify(query),
              })
            })}
            style={{marginBottom: '10px',marginRight: '10px'}}
          >
          Bulk Upload
          </Button>
          <div>
            <FilterFormComponent
              location={location}
              placeholder="Search by name"
              initialValues={query}
            />
          </div>
        </Col>
        {isOpen &&
          <CustomerFormModalComponent
            isOpen={isOpen}
            toggle={this.toggle}
            create={this.create}
            createTag={this.createTag}
            isTagLoading={isTagLoading}
            customerDetails={customerDetails}
            tagList={customerTagList}
            companyPermissions={companyPermissions}
            initialValues={initialValues}
            toggleTagModal={this.toggleTagModal}
            isModalHidden={isModalHidden}

          />
        }
        {isTagModal &&
          <TagModalComponent
            isModalOpen={isTagModal}
            toggle={this.toggleTagModal}
            createTag={this.createTag}
            tagsList = {tags}
            tagsCollection={customerTagList}
            deleteSelectedTags={this.deleteSelectedTags}
            updateTagName={this.updateTagName}
            getUpdatedList={this.getUpdatedList}
            hideModal={this.hideModal}
          />
        }
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  customerDetails: CustomerDucks.customerDetails(state),
  customerTagList: CustomerTagDucks.customerTagList(state),
  companyPermissions: UserDucks.companyPermissions(state),
  userCompany: UserDucks.userCompany(state),
});

const mapActionsToProps = dispatch => ({
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  CustomerTagDucks: bindActionCreators(CustomerTagDucks, dispatch),
  JobDucks: bindActionCreators(JobDucks, dispatch)
});

CustomerFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
  customerDetails:PropTypes.object,
  customerTagList: PropTypes.array,
  userCompany: PropTypes.object.isRequired,
};

CustomerFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};


export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerFilterSection);

