import React, { Component } from 'react'
import { createPortal } from 'react-dom'
import styles from 'forms/components/styles.module.scss';
import Mobile from 'forms/assets/mobile.png'

export class PreviewComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mountNode: null
    }
    this.setContentRef = (contentRef) => {
      this.setState({
        mountNode: contentRef?.contentWindow?.document?.body
      })
    }
  }

  render() {
    const { children, ...props } = this.props
    const { mountNode } = this.state
    return (
        <div className={`${styles['mobile-container']} m-2`}>
            <img src={Mobile} alt="" className={`${styles['mobile-image']}`}/>
            <iframe
                {...props}
                ref={this.setContentRef}
                className={`${styles['mobile-content']}`}
            >
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        </div>
    )
  }
}
