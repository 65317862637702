import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import BasicFormPage from './BasicSetting';


const BusinessAdminRoutes = (props) => {
  const { match } = props;

  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/basic-setting`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/basic-setting`}
        name="business_admin.basic_setting"
        component={BasicFormPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

BusinessAdminRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BusinessAdminRoutes;
