import React from "react";

import PropTypes from "prop-types";
import cookie from "react-cookies";
import connect from "react-redux/lib/connect/connect";
import bindActionCreators from "redux/lib/bindActionCreators";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import { toast } from "react-toastify";

import * as RouteDucks from "ducks/routes";
import * as JobDucks from "ducks/jobs/job";
import SectionLoaderAtom from "atoms/SectionLoader";

import * as InfoDucks from "ducks/info/info";
import * as AdjustmentTypesDucks from "ducks/vendors/adjustmentTypes";
import * as CustomerDucks from "ducks/customers/customer";
import * as NoteDucks from "ducks/jobs/comment";
import * as TaskDucks from "ducks/jobs/task";
import * as EmployeeDucks from "ducks/employees/employee";

import TwoColumnLayout from "layouts/TwoColumn";

// import MealSettingsForm from '../../components/vendors/mealAdjustments/MealSettingsForm';
import MealSettingsForm from "components/vendors/mealAdjustments/MealSettingsForm";
import MealProgressBarSection from "sections/vendors/form/ProgressBar";

import { NAV } from "constants/vendors";

/**
 * JobFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class MealAdjustmentsSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      parent: NAV.VendorMealAdjustmentsEditFormPage.parent,
      isMealAddInfo: true,
      isJobEditInfo: true,
      mealTypes: null,
      employees: [],
      errors: {},
      rules: null,
      is_active: null,
      initialValues: {
        meal_duration_hours: 0,
        meal_duration_minutes: 0,
        after_hours: 0,
        after_minutes: 0,
        shift_duration_hours: 0,
        shift_duration_minutes: 0,
        meal_type: null,
        accurals: null,
        adjustment_duration_hours: 0,
        adjustment_duration_minutes: 0,
        adjustment_type: null,
      },
    };

    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.update = this.update.bind(this);
  }

  componentWillMount() {
    // Set loading to true before starting API call
    this.setState({ isLoading: true });

    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;

    Promise.all([
      this.props.getMealTypes({
        company_id: this.props.userExtraData?.company?.id,
        page_size: 10,
        paginate: true,
        is_active: true,
        id: params.id,
      }),
    ])
      .then((responses) => {
        const mealTypesResponse = responses[0];
        this.setState({
          is_active: mealTypesResponse?.value?.is_active,
          rules: mealTypesResponse?.value?.rule_configuration,
          mealTypes: mealTypesResponse?.value?.meal_rule_type,
          employees: mealTypesResponse?.value?.employees,
          initialValues: {
            meal_duration_hours: mealTypesResponse?.value?.rule_configuration
              ?.meal_duration_hours
              ? mealTypesResponse?.value?.rule_configuration
                  ?.meal_duration_hours
              : 0,
            meal_duration_minutes: mealTypesResponse?.value?.rule_configuration
              ?.meal_duration_minutes
              ? mealTypesResponse?.value?.rule_configuration
                  ?.meal_duration_minutes
              : 0,
            after_hours: mealTypesResponse?.value?.rule_configuration
              ?.meal_start_after_hours
              ? mealTypesResponse?.value?.rule_configuration
                  ?.meal_start_after_hours
              : 0,
            after_minutes: mealTypesResponse?.value?.rule_configuration
              ?.meal_start_after_minutes
              ? mealTypesResponse?.value?.rule_configuration
                  ?.meal_start_after_minutes
              : 0,
            shift_duration_hours: mealTypesResponse?.value?.rule_configuration
              ?.shift_duration_threshold_hours
              ? mealTypesResponse?.value?.rule_configuration
                  ?.shift_duration_threshold_hours
              : 0,
            shift_duration_minutes: mealTypesResponse?.value?.rule_configuration
              ?.shift_duration_threshold_minutes
              ? mealTypesResponse?.value?.rule_configuration
                  ?.shift_duration_threshold_minutes
              : 0,
            meal_type:
              mealTypesResponse?.value?.rule_configuration?.is_meal_paid,
            accurals:
              mealTypesResponse?.value?.rule_configuration
                ?.count_towards_ot_accural,
            adjustment_duration_hours: mealTypesResponse?.value
              ?.rule_configuration?.adjustment_duration_hours
              ? mealTypesResponse?.value?.rule_configuration
                  ?.adjustment_duration_hours
              : 0,
            adjustment_duration_minutes: mealTypesResponse?.value
              ?.rule_configuration?.adjustment_duration_minutes
              ? mealTypesResponse?.value?.rule_configuration
                  ?.adjustment_duration_minutes
              : 0,
            adjustment_type:
              mealTypesResponse?.value?.rule_configuration?.adjustment_type,
          },
          // Set loading to false after successful response
          isLoading: false,
        });
      })
      .catch((error) => {
        toast.error(error.non_field_errors);
        // Set loading to false if there was an error
        this.setState({ isLoading: false });
      });
  }

  setParentUrl(query) {
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    try {
      if (query.parent) {
        if (params.id) {
          this.setState({
            parent: query.parent,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  toggleInfo() {
    const { isMealAddInfo } = this.state;
    this.setState({ isMealAddInfo: !isMealAddInfo });
    cookie.save("meal_setting_form_info", !isMealAddInfo);
  }

  update(data) {
    this.setState({ errors: data });
  }

  render() {
    const {
      isLoading,
      isJobEditInfo,
      isMealAddInfo,
      mealTypes,
      rules,
    } = this.state;
    const {
      jobDetail,
      infoList,
      location: { query },
    } = this.props;
    const {
      router: {
        route: {
          match: { params },
        },
      },
    } = this.context;
    const selectedId = params ? params.id : null;
    return (
      <div>
        <TwoColumnLayout
          navInfo={NAV.VendorMealAdjustmentsEditFormPage}
          infoList={infoList}
          isInfoOpen={isMealAddInfo}
          toggleInfo={this.toggleInfo}
          fetchData={this.loadData}
          editJob
          jobDetail={jobDetail}
        >
          <Row
            className="pt-4 ml-3 mr-4"
            style={{ backgroundColor: "white", borderTop: "2px solid #09819A" }}
          >
            <Col>
              <Row>
                <Col className="text-center mb-4">
                  <MealProgressBarSection
                    active={2}
                    isInfoOpen={isMealAddInfo}
                    location={this.props.location}
                    selectedId={selectedId}
                    rules={rules}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {isLoading ? (
                    <SectionLoaderAtom active />
                  ) : (
                    <MealSettingsForm
                      selectedId={selectedId}
                      mealTypes={mealTypes}
                      initialValues={this.state.initialValues}
                      isLoading={this.state.isLoading}
                      is_active={this.state.is_active}
                      employees={this.state.employees}
                      update={this.update}
                      errors={this.state.errors}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="pb-5" />
        </TwoColumnLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = (dispatch) => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
  NoteDucks: bindActionCreators(NoteDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  getMealTypes: bindActionCreators(AdjustmentTypesDucks.getMealTypes, dispatch),
});

MealAdjustmentsSettings.propTypes = {
  location: PropTypes.object.isRequired,
  JobDucks: PropTypes.object.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired,
};

MealAdjustmentsSettings.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MealAdjustmentsSettings);
