import React from 'react';
import moment from 'moment';

import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as UserDucks from 'ducks/accounts/user';
import * as JobDucks from 'ducks/jobs/job';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as RouteDucks from 'ducks/routes';

import { toast } from 'react-toastify';

import SectionLoaderAtom from 'atoms/SectionLoader';
import JobBudgetComponent from 'components/jobs/form/jobBudget';


class JobBudgetSection extends React.Component {
  constructor(props) {
    super(props);
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.goTo = this.goTo.bind(this);
    this.editDate = this.editDate.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.state = { status: null,
      showDate: true,
      next : false,
      finish : false,
    };
  }

  createOrUpdate(data) {
    if(data.type==='next'){
      this.setState({next :!this.state.next});
    }
    if(data.type === 'finish'){
    this.setState({finish :!this.state.finish});
    }
    const { router: { history } } = this.context;
    const { jobDetail, location: { query } } = this.props;
    const detail = data;
    if(data.type==='next' && !query.setup){
    return this.props.JobDucks.putJob({ ...detail, id: jobDetail.id })
      .then((res) => {
        this.setState({next: false});
        this.setState({finish: false});
        this.setState({ job: res.value });
        toast.success("Job successfully created !");
        history.push({ pathname:`/job/list/${res.value.id}/task/edit`,
          search : queryString.stringify(query)
         });
      });
    }
    if(data.type==='finish' && !query.setup){
    return this.props.JobDucks.putJob({ ...detail, id: jobDetail.id })
      .then((res) => {
        toast.success("Budget details successfully added !");
        this.setState({finish: false});
        this.setState({next: false});
        query.admin_list ? history.push({ pathname:`/job/list/new`}) : history.push({ pathname:`/job/list` });
      });
    }
    if(data.type==='next' && query.setup){
    return this.props.JobDucks.putJob({ ...detail, id: jobDetail.id })
      .then((res) => {
        this.setState({next: false});
        this.setState({finish: false});
        this.setState({ job: res.value });
        toast.success("Job successfully created !");
        history.push({ pathname:`/job/list/${res.value.id}/task/edit`,
          search: queryString.stringify({...query, setup: true })});
      });
    }
    if(data.type==='finish' && query.setup){
    return this.props.JobDucks.putJob({ ...detail, id: jobDetail.id })
      .then((res) => {
        this.setState({next: false});
        this.setState({finish: false});
        this.setState({ job: res.value });
        toast.success("Job successfully created !");
        query.admin_list ? 
        history.push({ pathname:`/job/list/new`,
          search: queryString.stringify({ status: 1 })
        }) :  
        history.push({ pathname:`/job/list`,
          search: queryString.stringify({ status: 1 })
        });
    });}

  }

  editDate() {
    const { showDate } = this.state;
    this.setState({ showDate: !showDate });
  }

   goToUrl(url) {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    if(query.setup){
      history.push(`${url}?setup=true`);
    }else{
      let newSearch;
      if(query.parent){
        newSearch = queryString.stringify({ parent: query.parent });
      }
      history.push(`${url}?${newSearch}`);
    }
  }

  goTo(url, data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const {location: { query } } = this.props;
    this.props.JobDucks.setJob(data);
    if(params.id){
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job_id: params.id,
        }),
      });
    }
    else {
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job: 'new',
        }),
      });
    }
  }

  render() {
    const { showDate , next, finish} = this.state;
    const {
      isLoading,
      dateFormat,
      location,
      jobDetail,
    } = this.props;
    const updateJobDetail = { ...jobDetail };
    if (Object.keys(updateJobDetail).length) {
      updateJobDetail.days_of_week.forEach((item) => {
        if (updateJobDetail.budgeted_hours_per_day) {
          updateJobDetail[`budget_hour-${item}`] = +updateJobDetail.budgeted_hours_per_day[item];
        }
        if (updateJobDetail.daily_billing_rate) {
          updateJobDetail[`bill_rate-${item}`] = +updateJobDetail.daily_billing_rate[item];
        }
      });
    }
    let billTypes;
    if(updateJobDetail.bill_type === 'QUOTED' && updateJobDetail.job_type === 'RECURRING'){
      billTypes = 'QUOTED_RECURRING';
    }
    else if(updateJobDetail.bill_type === 'QUOTED' && updateJobDetail.job_type === 'PROJECT'){
      billTypes = 'QUOTED_PROJECT';
    }
    else if(updateJobDetail.bill_type === 'TIME_AND_MATERIAL'){
      billTypes = 'TIME_AND_MATERIAL';

    }

    return (
      <section>
        {isLoading
          ? <SectionLoaderAtom active />
          :<section>
            <JobBudgetComponent
              jobDetail={updateJobDetail}
              editDate={this.editDate}
              showDate={showDate}
              dateFormat={dateFormat}
              createOrUpdate={this.createOrUpdate}
              location={location}
              goToUrl={this.goToUrl}
              next = { next }
              finish ={finish}
              initialValues={{ ...updateJobDetail,
                bill_type : billTypes,
                every: updateJobDetail.every ?
                  {label: +updateJobDetail.every, value: +updateJobDetail.every} : null,
                repeat: updateJobDetail.repeat ?
                  {value: updateJobDetail.repeat, label: updateJobDetail.repeat.toUpperCase()} : null,
                start_date: jobDetail.start_date &&
                    moment(jobDetail.start_date).toISOString(),
                  end_date: jobDetail.end_date &&
                    moment(jobDetail.end_date).toISOString(),
              }}
            />
          </section>
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  dateFormat: BasicSettingDucks.dateFormat(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  location: RouteDucks.location(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  userCompany: UserDucks.userCompany(state),
});

const mapActionToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
});

JobBudgetSection.propTypes = {
  jobDetail: PropTypes.object,
  jobData: PropTypes.object,
  dateFormat: PropTypes.string,
};

JobBudgetSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const jobForm = connect(
  state => ({
    formValues: state.form && state.form.JobAddForm && state.form.JobAddForm.values,
  })
)(JobBudgetSection);

export default connect(
  mapStateToProps,
  mapActionToProps,
)(jobForm);
