import React from 'react';

import PropTypes from 'prop-types';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import styles from './styles.module.scss';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import CheckboxAtom from 'atoms/Checkbox';
import InputAtom from 'atoms/Input';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';
import CloseCross from './CloseCross.png';


import { FORM_LAYOUT } from 'constants/layout';

import { toast } from 'react-toastify';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> JobTaskMappingFormModalComponent
 *
 * Props:
 *    - update
 */
class JobTaskMappingFormModalComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }
  submit(data) {
    const { create } = this.props;
    const detail = { ...data,
      billed_rate: data.billed_rate ? data.billed_rate : null,
      employee_fringe: data.employee_fringe ? data.employee_fringe : null,
      code : data.code ? data.code : null,
      tags: data.tags && data.tags.length ? data.tags.map(i => i.name) : []
    };
    if(!data.is_billable){
      detail.is_billable = false;
    }
    return create(detail)
      .catch((err) => {
        const errObj = {
          _error: err.non_field_errors,
          ...err,
        };
        toast.error(err.non_field_errors[0]??"Something went wrong");
        throw new SubmissionError(errObj);
      });
  }

  render() {
    const { handleSubmit, submitting,tagList , isOpen, toggle, initialValues, toggleTagModal, isModalHidden } = this.props;
    const newTags = tagList && tagList.length && tagList[0] ? (tagList[0].tag_collection && tagList[0].tag_collection.map((item) => {
      const data = {};
      data.name = item;
      data.id = item;
      return data;
    })) : [];

    return (
      <div>
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className={isModalHidden ? "form-modal w-25 mx-auto h-50 hide-modal":"form-modal w-25 mx-auto h-50"}
          backdrop="static"
        >
          <ModalHeader className={styles.modal_header}>
            {initialValues ? 'Edit Task' : 'Add Task'}
            <img src={CloseCross} className={styles.close_button} height="14px" width="14px" alt="Close" onClick={() =>toggle()} />
          </ModalHeader>
          <Row>
            <Col md={10} className="mx-auto pb-4">
              <Form onSubmit={handleSubmit(this.submit)} noValidate>
                <ModalBody className={styles.modal_body_text}>
                    <Field
                      id="name"
                      name="name"
                      label="Name" 
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Task Name"
                    />
                    <Field
                      id="description"
                      name="description"
                      label="Description"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Description (Optional)"
                    />
                   <div className="task-arrow">
                    <Field
                      component={MultiSelectDropdownAtom}
                      id="tags"
                      name="tags"
                      placeholder="Select Tags"
                      items={newTags}
                      itemToString={i => i && i.name}
                      label={<span>
                          Tags
                        <span className="float-right">
                          <div onClick={() => toggleTagModal()} tabIndex="0" role="button" className="link-name">
                            Manage Tags
                          </div>
                        </span>
                      </span>}
                    />
                  </div>
                    <Field
                      id="billed_rate"
                      name="billed_rate"
                      label="Hourly Bill Rate"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Hourly Bill Rate"
                    />
                    <Field
                      id="employee_fringe"
                      name="employee_fringe"
                      label="Employee Fringe (%)"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      placeholder="Employee Fringe"
                    />
                    <Field
                      className="mt-2"
                      id="code"
                      name="code"
                      label="Task Code"
                      component={InputAtom}
                      layout={FORM_LAYOUT}
                      maxLength = "50"
                    />
                    <Field
                      className="mt-2"
                      id="is_billable"
                      name="is_billable"
                      label="Billable"
                      component={CheckboxAtom}
                      layout={FORM_LAYOUT}
                    />
                  <Button type="submit" color="primary" className="btn-block" size={FORM_LAYOUT.size} >
                    Submit <ButtonLoaderAtom active={submitting} />
                  </Button>
                </ModalBody>

              </Form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
};

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Name';
  if(values.billed_rate && !values.billed_rate.toString().match('^[-+]?[0-9]*\\.?[0-9]+$')) errors.billed_rate = 'Enter a vaild number'
  if(values.employee_fringe && !values.employee_fringe.toString().match('^[-+]?[0-9]*\\.?[0-9]+$')) errors.employee_fringe = 'Enter a vaild number'
  return errors;
};

JobTaskMappingFormModalComponent.propTypes = {
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
  isOpen: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  tagList: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
};

export default reduxForm({
  form: 'TaskFormModal',
  validate,
})(JobTaskMappingFormModalComponent);
