import React from "react";
import queryString from "query-string";
import cookie from "react-cookies";
import PropTypes from "prop-types";

import TwoColumnLayout from "layouts/TwoColumn";
import TabsComponent from "components/common/Tabs";

import { NAV, TABS } from "constants/communicationCenter";
import EDocsListPage from "communicationCenter/components/EDocsList";
import DocumentTypesListPage from "communicationCenter/components/DocumentTypesList";
import SilderPreview from "forms/components/sliderPreview";
//import styles from "./styles.module.scss";

class EmployeeFormPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refrshDocTabFlag: false,
      refrehEDocTabFlag: false,
      clickedTemplateData: [],
      preview: false,
    };
    this.updateState = this.updateState.bind(this);
    this.switchTabs = this.switchTabs.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
    this.refresh = this.refresh.bind(this);
    this.refreshTimeOffTableTab = this.refreshTimeOffTableTab.bind(this);
    this.togglePreview = this.togglePreview.bind(this);
  }

  componentWillMount() {
    const {
      match: { params },
    } = this.props;
    this.setParentUrl();
    if (params.id) {
      const cookieKey = cookie.load("employee_edit_form_info");
      if (cookieKey) {
        this.updateState("isEditInfoOpen", JSON.parse(cookieKey));
      }
    } else {
      this.fetchData();
      const cookieKey = cookie.load("employee_add_form_info");
      if (cookieKey) {
        this.updateState("isAddInfoOpen", JSON.parse(cookieKey));
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.fetchTabData(nextProps);
    }
  }

  fetchTabData(props) {
    this.setState({ refrsh: true });
    setTimeout(() => {
      this.setState({ refrsh: false });
    }, 500);
  }

  refreshTimeOffTableTab() {
    this.setState({ employeeTimeOffRefresh: true });
    setTimeout(() => this.setState({ employeeTimeOffRefresh: false }));
  }

  setParentUrl() {
    const {
      location: { search },
    } = this.props;
    const query = queryString.parse(search);
    try {
      if (query.parent) {
        this.setState({
          parent: query.parent,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  switchTabs(key) {
    const { history } = this.props;
    return history.push(`/document/${key}`);
  }

  refresh() {
    const {
      match: { params },
    } = this.props;
    if (params.id === "documentTypes") {
      this.setState({ refrshDocTabFlag: true });
    } else if (params.id === "eDocs") {
      this.setState({ refrehEDocTabFlag: true });
    } else {
      this.setState({ refreshTrainingFormTabFlag: true });
    }
  }

  togglePreview(data, flag) {
    this.setState({ preview: flag, clickedTemplateData: data });
  }

  render() {
    const {
      match: { params },
      history,
    } = this.props;
    const {
      isLoading,
      refrehEDocTabFlag,
      refrshDocTabFlag,
      preview,
      clickedTemplateData,
    } = this.state;

    return (
      <div style={{ display: preview ? "flex" : null }}>
        <div style={{ width: preview ? "70%" : "100%" }}>
          <TwoColumnLayout
            navInfo={
              params.id === "documentTypes"
                ? {
                    ...NAV.DocumentsTypePage,
                  }
                : {
                    ...NAV.EDocsPage,
                  }
            }
            fetchData={this.refresh}
            isLoading={isLoading}
          >
            {params.id && (
              <div className="mt-1 ml-3 mr-4">
                <TabsComponent
                  tabItems={TABS}
                  active={params.id}
                  onClick={this.switchTabs}
                />
              </div>
            )}
            {params.id === "documentTypes" && (
              <DocumentTypesListPage
                refreshFlag={refrshDocTabFlag}
                updateState={this.updateState}
                history={history}
              />
            )}
            {params.id === "eDocs" && (
              <EDocsListPage
                employeeId={params.id}
                refreshFlag={refrehEDocTabFlag}
                updateState={this.updateState}
              />
            )}
          </TwoColumnLayout>
        </div>
        {preview && (
          <SilderPreview
            togglePreview={this.togglePreview}
            show={preview}
            templateData={clickedTemplateData}
          />
        )}
      </div>
    );
  }
}

EmployeeFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default EmployeeFormPage;
