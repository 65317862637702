const baseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/timecard', label: 'Pay Cycle View' },
];
const customBaseNav = [
  { href: '/dashboard', label: 'Dashboard' },
  { href: '/timecard/custom', label: 'Timecards' },
];

export const NAV = {
  TimecardListPage: {
    breadcrumb: [
      ...baseNav,
      { label: 'List' },
    ],
    description: "View & manage Employee's timecards",
    title: 'Pay Cycle View',
  },
  CustomTimecardList: {
    breadcrumb: [
      ...customBaseNav,
      { label: 'List' },
    ],
    description: "View & explore Employee's timecards",
    parent: '/dashboard',
    title: 'Timecards',
  },
  DailyTimecardListPage: {
    breadcrumb: [
      ...baseNav,
      { label: 'Daily Timecards' },
    ],
    description: "View Employee's date wise timecards",
    parent: '/timecard/list',
    title: 'Daily Timecards',
    // Dynamic description, title
  },
  CustomDailyTimecardListPage: {
    breadcrumb: [
      ...customBaseNav,
      { label: 'Daily Timecards' },
    ],
    description: "View Employee's date wise timecards",
    parent: '/timecard/custom/list',
    title: 'Daily Timecards',
    // Dynamic description, title
  },
  HistoricTimecardListPage: {
    breadcrumb: [
      ...customBaseNav,
      { label: 'Historic Timecards' },
    ],
    parent: '/employee/list',
    title: 'Historic Timecards',
    // Dynamic description, title
  },
  DeviatedDailyTimecardListPage: {
    breadcrumb: [
      ...customBaseNav,
      { label: 'Activities with Deviations ' },
    ],
    parent: '/dashboard',
    title: 'Activities with Deviations',
    // Dynamic description, title
  },
  CreateTimecardPage: {
    breadcrumb: [
      ...baseNav,
      { label: 'New' },
    ],
    parent: '/timecard/list',
    title: 'Create Timecard',
    description: "Create Timecard for new employee(s)",
    // Dynamic description, title
  },
  CustomCreateTimecardPage: {
    breadcrumb: [
      ...customBaseNav,
    ],
    parent: '/timecard/custom/list',
    title: 'Create Timecard',
    description: "Create Timecard for new employee(s)",
    // Dynamic description, title
  },
  AddMissingTimecard: {
    breadcrumb: [
      ...customBaseNav,
      { label: 'New' },
    ],
    description: 'Create missing timecard',
    parent: '/timecard/custom/list',
    title: 'Add Missing Timecard',
    // Dynamic breadcrumb, description, parent
  },
};

export const DECIMAL_VIEW_OPTIONS = [
  { value: true, label: 'DECIMAL' },
  { value: false, label: 'HH:MM' },
];

export default { NAV, DECIMAL_VIEW_OPTIONS };
