import React from 'react';

import PropTypes from 'prop-types';

import Table from 'reactstrap/lib/Table';

import SortHeaderComponent from 'components/common/SortHeader';

import {ReactComponent as TrackingIcon} from './tracking.svg';
import {ReactComponent as KioskIcon} from './monitor.svg';
import {ReactComponent as ManualIcon} from './placeholder.svg';

/**
 * ActivityTrackListPage -> ActivityTrackListSection -> ActivityTrackTableComponent
 *
 * Props:
 *    - activity track list
 */
const ActivityTrackTableComponent = (props) => {
  const { data, session, toggle, locationArray } = props;

  return (
   <div className="ml-3 mr-4">
      <Table hover responsive size="sm" className="table-tracking">
      <thead className="Header-col">
        <tr>
          <th className="text-center pl-3">Action</th>
          <SortHeaderComponent ordering="employee__user__first_name">First Name</SortHeaderComponent>
          <SortHeaderComponent ordering="employee__user__last_name">Last Name</SortHeaderComponent>
          {session !== 'historic' && <th hidden={true}>Employee Id</th>}
          <SortHeaderComponent ordering="employee__title">Employee Title</SortHeaderComponent>
          <th>Punch-In Time</th>
          {session === 'historic' && <th>Punch-Out Time</th>}
          {session === 'historic' && <th>Punch-Out Location</th>}
          <th className="text-right pr-3">Total Punch-In Time</th>
        </tr>
      </thead>
      <tbody>
        {data.results && data.results.map((item, index) => (
          <tr key={item.id}>
            {item.punch_in_type && item.punch_in_type.manual_entry ?
              <td title="View Manual Entry" className="text-center pl-3">
                <ManualIcon
                  height="18"
                  width="18"
                  className="cursor-pointer"
                  onClick={() => toggle(item)}
                />
              </td>
              :
              locationArray[index] && locationArray[index].length  ?
                <td title="View Tracking" className="text-center pl-3">
                  <TrackingIcon
                    height="18"
                    width="18"
                    className="cursor-pointer"
                    onClick={() => toggle(item)}
                  />
                </td>
              :  
              <td title="View Kiosk Employee" className="text-center pl-3">
                <KioskIcon
                  height="18"
                  width="18"
                  className="cursor-pointer"
                  onClick={() => toggle(item)}
                />
              </td> 
            }
            {!item.punch_in_type && <td />}
            <td title={item.employee.user.first_name}>{item.employee.user.first_name}</td>
            <td title={item.employee.user.last_name}>{item.employee.user.last_name}</td>
            {session !== 'historic' &&
              <td hidden={true} title={item.employee.employee_id}>
                {item.employee.employee_id}
              </td>}
            <td title={item.employee.title}>{item.employee.title}</td>
            <td title={item.punch_in_time}>{item.punch_in_time}</td>
            {session === 'historic' && <td title={item.punch_out_time}>{item.punch_out_time}</td>}
            {session === 'historic' && <td title="location">{item.punch_out_address}</td>}
            <td title={item.total_punch_in_time} className="text-right pr-3">{item.total_punch_in_time}</td>
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
    </Table> 
   </div> 
  );
};

ActivityTrackTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  session: PropTypes.string.isRequired,
  toggle: PropTypes.func,
};

export default ActivityTrackTableComponent;
