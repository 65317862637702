import React from 'react';
import queryString from 'query-string';
import cookie from 'react-cookies';
import moment from 'moment';

import TwoColumnLayout from 'layouts/TwoColumn';
import { NAV } from 'constants/vendors';
import { MONTHS } from 'constants/vendors';
import connect from 'react-redux/lib/connect/connect';
import * as UserDucks from 'ducks/accounts/user';

import BillListComponent from 'payments/bills/components/list';
import BillFilterFormComponent from 'payments/bills/components/filter';
import billUseCase from 'payments/bills/usecases/billUseCase';
import BillApiGateway from 'payments/bills/gateways/bill';

import PaginationComponent from 'components/common/Pagination';

class BillListPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    const billApiGateway = new BillApiGateway();
    this.usecase = new billUseCase(billApiGateway);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.updateState = this.updateState.bind(this);
    this.listenCompanyBillUseCase = this.listenCompanyBillUseCase.bind(this);
    this.state={
      infoList: [],
      isInfoOpen: true,
      billList :[],
      billStatusList : [],
      billListCount: null,
      isLoading: false,
    }
    this.refresh = this.refresh.bind(this);
  }

  componentWillMount() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    const Month = moment().format('MMMM');
    const currentMonth = MONTHS.find((i) => i.name === ((Month && Month.value) || Month));
     if (!('year' in query)) {
      this.props.history.push({
        pathname: '/bill/list',
        search: queryString.stringify({
          ...query,
          year: moment().format('YYYY'),
          month : '',
        }),
      });
    }
    const cookieKey = cookie.load("company_plan_info");

    if(cookieKey) {
      this.setState({ isInfoOpen: JSON.parse(cookieKey) });
    }


    this.listenCompanyBillUseCase();
    this.usecase.getInfoList();
    /*this.usecase.getCommercialStatusList({entity : 'companyplan', paginate: false});*/
    this.fetchData(search); 
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search: nextSearch } } = nextProps;
    this.fetchData(nextSearch);
  }
  

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  fetchData(search){
    const params = queryString.parse(search);
    const Month = moment().format('MMMM');
    const currentMonth = MONTHS.find((i) => i.name === ((Month && Month.value) || Month));
    let updatedParams;
    if (!('year' in params)) {
      updatedParams = ({...params , year: moment().format('YYYY'), month : ''})
    }
    else
    {
      updatedParams = ({...params});
    }
    const pageSize = cookie.load("bill_list_page_size");
    this.usecase.getBillList({...updatedParams, page_size : pageSize});
  }

  refresh(){
    const { location: { search } } = this.props;
    this.fetchData(search);
  }


  listenCompanyBillUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'GET_BILL_LIST_SUCCESS':
          this.setState({ billList: event.data,
            billListCount: event.data.count, isLoading: false });
          break;
        case 'GET_BILL_STATUS_SUCCESS':
          this.updateState('billStatusList', event.data);
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("company_plan_info", !isInfoOpen);
  }


  render() {
    const { isInfoOpen, infoList , billList, billStatusList, isLoading } = this.state;
    const { location, history, userExtraData } = this.props;
    return(
      <TwoColumnLayout
        navInfo={NAV.BillListPage}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.refresh}
        infoList={infoList}
      >
        <BillFilterFormComponent 
          location={location}
          history={history}
          billStatusList ={ billStatusList.results}
          userExtraData={userExtraData}
        />
        <div className = 'pl-3 pr-4'>
          <BillListComponent 
            billList ={billList}
            billListCount={this.state.billListCount}
            isLoading={isLoading}
            location={location}
          />
        </div>
        { !isLoading ?
            <PaginationComponent
              count={this.state.billListCount}
              location={location}
              cookieKey="bill_list_page_size"
              history={history}
            /> : null}
      </TwoColumnLayout>
    );
  }
}


const mapStateToProps = state => ({
  userExtraData: UserDucks.userExtraData(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillListPage);