import { createSelector } from "reselect";

import axios from "helpers/interceptor";
import createReducer from "helpers/createReducer";
import URLS from "./urls";

const GET_IN_PROGRESS_ACTIVITY =
  "activities/activityTrack/GET_IN_PROGRESS_ACTIVITY";
const GET_IN_PROGRESS_ACTIVITY_FULFILLED =
  "activities/activityTrack/GET_IN_PROGRESS_ACTIVITY_FULFILLED";

const APPROVE_IN_PROGRESS_ACTIVITY =
  "activities/activityTrack/APPROVE_IN_PROGRESS_ACTIVITY";
const APPROVE_IN_PROGRESS_ACTIVITY_FULFILLED =
  "activities/activityTrack/APPROVE_IN_PROGRESS_ACTIVITY_FULFILLED";

const APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY =
  "activities/activityTrack/APPROVE_IN_PROGRESS_ACTIVITY";
const APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY_FULFILLED =
  "activities/activityTrack/APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY_FULFILLED";

export function getInProgressActivity(params) {
  return {
    type: GET_IN_PROGRESS_ACTIVITY,
    payload: axios.get(`${URLS.ACTIVITY}${URLS.IN_PROGRESS}`, { params }),
  };
}

export function approveInProgressActivity(params) {
  return {
    type: APPROVE_IN_PROGRESS_ACTIVITY,
    payload: axios.put(
      `${URLS.ACTIVITY}${URLS.PUNCH_OUT}${params.id}/?employee_id=${params.employee_id}`
    ),
  };
}

export function acceptAndRejectInProgressActivity(params) {
  return {
    type: APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY,
    payload: axios.post(
      `${URLS.ACTIVITY_ACCEPT_REJECT_SESSION}${params.employee_id}/?reject_session=${params.reject_session}&accept_session=${params.accept_session}&session_id=${params.session_id===null?'':params.session_id}`
    ),
  };
}

const defaultState = {
  list: { results: [] },
  detail: {},
};

function GET_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function APPROVE_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER(
  state,
  action
) {
  return Object.assign({}, state, {});
}

const handlers = {
  [GET_IN_PROGRESS_ACTIVITY_FULFILLED]: GET_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER,
  [APPROVE_IN_PROGRESS_ACTIVITY_FULFILLED]: APPROVE_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER,
  [APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY_FULFILLED]: APPROVE_AND_REJECT_IN_PROGRESS_ACTIVITY_FULFILLED_REDUCER,
};

const activitySelector = (state) => state.activities.activity;

export const activityInProgressList = createSelector(
  activitySelector,
  (instance) => instance.list
);

export default createReducer(defaultState, handlers);
