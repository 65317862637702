import React from 'react';
import cookie from 'react-cookies';

import TwoColumnLayout from 'layouts/TwoColumn';
import { NAV } from 'constants/vendors';
import TabsComponent from 'components/common/Tabs';
import { toast } from 'react-toastify';

import BillFormComponent from 'payments/bills/components/form';
import BillAuditListComponent from 'payments/bills/components/auditLogsList';
import billDetailUseCase from 'payments/bills/usecases/billDetailUseCase';
import BillApiGateway from 'payments/bills/gateways/bill';

class BillFormPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    const billApiGateway = new BillApiGateway();
    this.usecase = new billDetailUseCase(billApiGateway);
    this.fetchData = this.fetchData.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
    this.updateState = this.updateState.bind(this);
    this.listenCompanyBillDetailUseCase = this.listenCompanyBillDetailUseCase.bind(this);
    this.state={
      infoList: [],
      isInfoOpen: true,
      parent : NAV.BillFormPage.parent,
      billDetails : {},
      billStatusList : [],
      isLoading: false,
      isAuditLogsLoading: [],
      auditLogsList: [],
    }
    this.switchTabs = this.switchTabs.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    this.listenCompanyBillDetailUseCase();
    this.fetchTabData(this.props); 
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match !== nextProps.match) {
      this.fetchTabData(nextProps);  
    }
  }

  updateState(key, value){
    this.setState({
      [key]: value,
    });
  }

  fetchData(){
    const { match: { params } } = this.props;
    this.usecase.getCompanyBillDetails(params);
  }

  listenCompanyBillDetailUseCase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'INFO_LIST':
          this.updateState('infoList', event.infoList);
          break;
        case 'GET_BILL_DETAIL_SUCCESS':
          this.setState({ billDetails: event.data, isLoading: false });
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'SHOW_AUDIT_LOGS_LOADER':
          this.updateState('isAuditLogsLoading', true);
          break;
        case 'UPDATE_BILL_DETAILS_SUCCESS':
          this.updateState('billDetails', event.data);
          toast.success('The details have been updated successfully');
          break;
        case 'AUDIT_LIST':
          this.setState({ isAuditLogsLoading: false, auditLogsList: event.auditLogsList });
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  toggleInfo(){
    const { isInfoOpen } = this.state;
    this.setState({ isInfoOpen: !isInfoOpen });
    cookie.save("company_plan_info", !isInfoOpen);
  }

  switchTabs(key){
    const { match: { params }, history, location: { search } } = this.props;
    return history.push(`/bill/${params.id}/${key}${search}`);
  }

  fetchTabData(props){
    const { params } = props.match;
    if(params.tab === 'edit'){
      this.fetchData(params.id);
    }
    else{
      this.usecase.getAuditLogs(params.id);
    }
  }

  refresh(){
    this.fetchTabData(this.props);
  }

  render() {
    const { isInfoOpen, infoList , parent, billDetails,
      billStatusList , isLoading, auditLogsList, isAuditLogsLoading } = this.state;

    const { match: { params } } = this.props;

    const tabs = [
      { key: 'edit', label: 'Bill Detail' },
      { key: 'auditLogs', label: 'Audit Logs'},
    ];

    return(
      <TwoColumnLayout
        navInfo={{...NAV.BillFormPage, parent}}
        isInfoOpen={isInfoOpen}
        toggleInfo={this.toggleInfo}
        fetchData={this.refresh}
        infoList={infoList}
      >
      {params.id &&
        <div className="mt-4 pl-3 pr-4">
          <TabsComponent tabItems={tabs} active={params.tab} onClick={this.switchTabs}/>
        </div>}
        <div className='pl-3 pr-4'>
          { params.tab === 'edit' && 
            <BillFormComponent 
              location={this.props.location}
              history={this.props.history}
              billDetails={{...billDetails, status_id: billDetails.status && billDetails.status.id}}
              usecase={this.usecase}
              billStatusList={billStatusList.results}
              isLoading={isLoading}
            />}
          { params.tab === 'auditLogs' && 
            <BillAuditListComponent
              data={auditLogsList}
              isAuditLogsLoading={isAuditLogsLoading}
            />}
        </div>
      </TwoColumnLayout>
    );
  }
}

export default BillFormPage;
