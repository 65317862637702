import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import * as RouteDucks from 'ducks/routes';
import * as PluginDucks from 'ducks/vendors/plugin';
import * as VendorDepartmentDucks from 'ducks/vendors/department';


/**
 * EmployeeListPage -> EmployeeFilterSection
 *
 * Components:
 *    - {@link FilterDropdownComponent}
 *    - {@link FilterFormComponent}
 *
 * State:
 *    - route params
 *
 * Actions:
 *    postActiveStatus
 */
class PluginSettingFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
    this.goTo = this.goTo.bind(this);
  }

  goTo(url) {
    const { router: { history }} = this.context;
    history.push(url);
  }

  render() {
    const { pluginSettingList } = this.props;
    const pluginName = pluginSettingList && pluginSettingList.destination_end_point && pluginSettingList.destination_end_point.name === 'QUICKBOOKS' ?
      'QUICKBOOKS' : 'QBD';
    return (
      <Row>
        <Col>
          <section className="text-right pb-">
            <Button
              outline color="primary"
              size="sm"
              onClick={() => this.goTo(`/vendor/plugin/uninstall/Quickbooks?plugin_name=${pluginName}`)}
            >
              Disconnect Integration
            </Button>
          </section>
        </Col>
      </Row>
    );
  }
};

const mapStateToProps = state => ({
  pluginSettingList: PluginDucks.pluginSettingList(state),
  location: RouteDucks.location(state),
});

const mapActionsToProps = dispatch => ({
  VendorDepartmentDucks: bindActionCreators(VendorDepartmentDucks, dispatch),
});

PluginSettingFilterSection.propTypes = {
  pluginSettingList: PropTypes.object.isRequired,
};

PluginSettingFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(PluginSettingFilterSection);


// <Button
//    color="accent"
//    onClick={() => this.toggle()}
//  >
//    Disconnect Integration
//  </Button>
