import React from 'react';

import PropTypes from 'prop-types';
import Input from 'reactstrap/lib/Input';

class EditableCellAtom extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDoubleClickItem = this.handleDoubleClickItem.bind(this);
    this.state = {
      value: this.props.value || this.props.values,
      editable: this.props.editable || true,
    };
    this.updateValue = this.updateValue.bind(this);
    this.checkIfItContainsString = this.checkIfItContainsString.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.value !== this.state.value) && (nextProps.value !== '--')) {
      if (nextProps.mileageValue)
        this.setState({ value: this.state.value });
      else
      this.setState({ value: nextProps.value });
    }
  }

  handleChange(e) {
    this.props.input && this.props.input.onChange && this.props.input.onChange(e.target.value);
    this.setState({ value: e.target.value });
  }

  handleSubmit(e) {
    const { mileageValue , details } = this.props;
    const { updateState , valueKey } = this.props;
    const { value } = this.state;
    this.forceUpdate();
    let newValue = e.target.value;
    if ( !mileageValue ) {
      if((e.target.value).indexOf(':') < 0){
        let stringStartIndex = this.checkIfItContainsString(newValue);
        if(stringStartIndex){
          newValue = newValue.substring(0, stringStartIndex);
        }
        if(newValue.length === 3){
          newValue = (newValue).substring(0,1) + ':' + (newValue).substring(1);
        }
        else if (newValue.length === 4){
          newValue = (newValue).substring(0,2) + ':' + (newValue).substring(2);
        }
        else if (newValue.length === 5 || newValue.length === 6){
          newValue = (newValue).substring(0,2) + ':' + (newValue).substring(2,4) + ':' + (newValue).substring(4);
        }
        if(stringStartIndex){
          newValue = newValue + (e.target.value).substring(stringStartIndex);
        }
      }
      this.setState({ value: newValue }, () => { this.updateValue(); });
      updateState && updateState(newValue, valueKey)
    }
    else{
      const key = this.props.valueKey;
      const data = { ...details };
      if(this.props.value == newValue){
        return null;
      }
      this.setState({ value: newValue }, () => { this.updateValue(); });
      updateState && updateState(newValue, valueKey)
    }    
  }

  checkIfItContainsString(inputValue){
    if(inputValue.indexOf('a') > -1){
      return inputValue.indexOf('a');
    }
    else if(inputValue.indexOf('A') > -1){
      return inputValue.indexOf('A');
    }
    else if(inputValue.indexOf('p') > -1){
      return inputValue.indexOf('p');
    }
    else if(inputValue.indexOf('P') > -1){
      return inputValue.indexOf('P');
    }
    return null;
  }

  updateValue(){
    const { update, validate,  details , index , validateInput} = this.props;
    const { value } = this.state;
    if((validateInput && validateInput(value, details, this.props.valueKey)) || (validate &&  validate(value, details, this.props.valueKey))){
      this.forceUpdate();
      const key = this.props.valueKey;
      const data = { ...details };
      if(data[key] === value){
        return null;
      }
      data[key] = value;
      update && update(data, key , value , index);
      this.setState({ editable :true, value });
    }
    else {
      this.setState({ editable :true, value: this.props.value || this.props.values});
    }
  }

  handleDoubleClickItem() {
    this.setState({
      editable: false,
    });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    const { value, editable , values} = this.state;
    const { disabled, tabIndex, wageEditable } = this.props;
    return (
      <div>
        {editable
          ? (<div>
            <Input
              value={value || values}
              onChange={this.handleChange}
              onKeyPress={(e)=>this.handleKeyPress(e)}
              onBlur={this.handleSubmit}
              placeholder={wageEditable ? " " :"--"}
              disabled={disabled}
              tabIndex={tabIndex}
            />
          </div>)
          : (<div
              onClick={this.handleDoubleClickItem}
              role="button"
              tabIndex={tabIndex}
            >
            {value.toString() || ' '}
          </div>)
        }
      </div>
    );
  }
}

EditableCellAtom.propTypes = {
  editable: PropTypes.instanceOf(Object),
  value: PropTypes.string,
  details: PropTypes.instanceOf(Object),
  valueKey: PropTypes.string,
  update: PropTypes.func.isRequired,
  validate: PropTypes.func,
  disabled:PropTypes.bool,
};

export default EditableCellAtom;
