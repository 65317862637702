import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Field from 'redux-form/lib/Field';
import reduxForm from 'redux-form/lib/reduxForm';
import SubmissionError from 'redux-form/lib/SubmissionError';
import cookie from 'react-cookies';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import DateTimePickerAtom from 'atoms/DateTimePicker';

import { FILTER_FORM_LAYOUT } from 'constants/layout';

const JobFilterFormComponent = (props, context) => {
  const { router: { history } } = context;
  const {
    dateFormat,
    location: { pathname, query },
    handleSubmit,
    error,
    jobStartDateCookieKey,
    jobEndDateCookieKey
  } = props;
  const { page, ...rest } = query;

  const submit = data => {
    const detail = { ...data };
    if (detail.start_date && detail.end_date) {
      const formattedStartDate = moment(detail.start_date)
      const formattedEndDate = moment(detail.end_date)
      if (formattedStartDate > formattedEndDate) {
        const errObj = {
          _error: 'The Start date should be less than or equal to end date.',
        };
        throw new SubmissionError(errObj);
      }
    }
    const { page: pageNo, ...restDetail } = detail;
    restDetail.start_date = moment(data.start_date).format('YYYY-MM-DD');
    restDetail.end_date = moment(data.end_date).format('YYYY-MM-DD');
    if (jobStartDateCookieKey || jobEndDateCookieKey) {
     cookie.save(jobStartDateCookieKey, restDetail.start_date, { path: '/' });
     cookie.save(jobEndDateCookieKey, restDetail.end_date, { path: '/' });
    }
    history.push({
      pathname, search: queryString.stringify({ ...rest, ...restDetail }),
    });
  };

  return (
    <Form inline onSubmit={handleSubmit(submit)} className="report-filter">
      <Field
        id="start_date"
        name="start_date"
        placeholder="Start Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Field
        id="end_date"
        name="end_date"
        placeholder="End Date"
        component={DateTimePickerAtom}
        options={{ dateFormat }}
      />
      <Button
        type="submit"
        color="primary"
        size={FILTER_FORM_LAYOUT.size}
        style={{ marginBottom: '24px' }}
        className="px-4 mr-0 ml-3"
      >
        Apply
      </Button>
    </Form>
  );
}

const validate = (values) => {
  const errors = {};
  if (!values.start_date) errors.start_date = 'Select Start Date';
  if (!values.end_date) errors.end_date = 'Select End Date';
  if(values.start_date&& values.end_date) {
    const formattedStartDate = moment(values.start_date)
    const formattedEndDate = moment(values.end_date)
      if (formattedStartDate > formattedEndDate) {
        errors.end_date='The Start date should be less than or equal to end date.'
      }
  }  
  // const formattedStartDate=moment(values.start_date).format();
  // const formattedEndDate=moment(values.end_date).format();
  // if(formattedStartDate > formattedEndDate) errors.start_date ='Start date should be less than end date.';
  return errors;
};

JobFilterFormComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([ PropTypes.array, PropTypes.string ]),
};

JobFilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default reduxForm({
  form: 'JobFilterForm',
  validate
})(JobFilterFormComponent);
