
import React, {PureComponent} from 'react';
import { Form,FormGroup, FormText, Input, Button, Modal, ModalBody , ModalHeader , ModalFooter} from 'reactstrap';
import { Formik } from 'formik';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import closeimage from './CloseCross.png';
import styles from './styles.module.scss';

class OtpFormModalForm extends PureComponent {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.resendCode = this.resendCode.bind(this);
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    setErrors({});
    try {
      await this.props.usecase.postOtp(values);
    } catch (e) {
      console.log(e.errors);
      setErrors(e.errors);
    }
    setSubmitting(false);
  }

  async resendCode() {
    try {
      await this.props.usecase.execute();
    } catch (e) {
      console.log('e', e);
    }
  }


  render() {
    const {isOpen, toggle } = this.props;
   

    return (
       <Modal
        isOpen={isOpen}
        toggle={toggle}
        >
        <ModalHeader className={`${styles['modal-heading']}`}>OTP Activation
          <img src={closeimage} className="close_image" height="12px" width="12px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
          <Formik
            initialValues={this.props.data}
            onSubmit={this.onSubmit}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                <ModalBody className="pt-0 ml-4 mr-4">
                
                <h6 className={`${styles['label-part']} pb-3 mt-3`}>Check your email for the OTP and enter the 6 digit code below to verify your email address.</h6>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Enter Otp"
                    name="token"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.token} />
                <div style={{minHeight: '3vh'}}><FormText color="danger">{errors.token}</FormText></div>
                </FormGroup>
                <div className= " w-100">
                     Didn't receive code? 
                      <span
                        className="pl-1 resend"
                        onClick={() => {
                          this.resendCode();
                        }}
                      >
                        Resend code
                      </span>
                  </div>   
                </ModalBody>
                <ModalFooter className={`${styles['modal-footer']}`}>
                  <Button  className={`${styles['accounts-button']} mr-4 mb-3`} type="submit" color="secondary">
                    Submit <ButtonLoaderAtom active={isSubmitting} />
                  </Button>
                </ModalFooter>
                </Form>
                 )}
          />
             
             </Modal>
    );}
  }
  
export default OtpFormModalForm;
