import React from 'react';

import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as UserDucks from 'ducks/accounts/user';

import ProfileFormComponent from 'components/accounts/profile/Form';

import SectionLoaderAtom from 'atoms/SectionLoader';

import { MSGS } from 'constants/accounts';

/**
 * ProfileFormPage -> ProfileFormSection
 *
 * Components:
 *    - {@link ProfileFormComponent}
 *
 * State:
 *    - profile
 *
 * Actions:
 *    - Form:
 *        - putProfileUser
 */
class ProfileFormSection extends React.Component {
  constructor(Props) {
    super(Props);
    this.update = this.update.bind(this);
    this.state = {
      timezones: moment.tz.names(),
      isOpen: false,
    }
  };

 
  update(data) {
    return this.props.UserDucks.putProfileUser(data)
      .then(() => toast.success(MSGS.PROFILE_UPDATE_SUCCESS));
  }


  render() {
    const { profile, userRole, isLoading } = this.props;
    const { timezones } = this.state;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div>
        <ProfileFormComponent
          initialValues={profile}
          timezonesList={timezones}
          userRole={userRole}
          update={this.update}
          getOtp={this.getOtp}
        />

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: UserDucks.profile(state),
  userRole: UserDucks.userRole(state),
});

const mapActionToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
});

ProfileFormSection.propTypes = {
  profile: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  UserDucks: PropTypes.object.isRequired,
};

export default connect (
  mapStateToProps,
  mapActionToProps,
)(ProfileFormSection);
