import React from 'react';

import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';

import Button from 'reactstrap/lib/Button';
import Table from 'reactstrap/lib/Table';


import SortHeaderComponent from 'components/common/SortHeader';

import EditIconAtom from 'atoms/EditIcon';
import ViewIconAtom from 'atoms/ViewIcon';

/**
 * VendorSubCompanyListPage -> VendorSubCompanyListSection -> VendorSubCompanyTableComponent
 *
 * Props:
 *    - subCompanyList
 */
const VendorSubCompanyTableComponent = (props) => {
  const { data, goTo } = props;
  return (
    <div className="ml-3 mr-4">
      <Table hover responsive size="sm" className="table-text-alignment">
      <thead className="Header-col">
        <tr>
          <th width="10%" className="pt-2 text-center">Action</th>
          <SortHeaderComponent ordering="name">Name</SortHeaderComponent>
          <th className="text-right">Account Number</th>
          <SortHeaderComponent ordering="create_date">Date Onboard</SortHeaderComponent>
          <SortHeaderComponent ordering="total_employees" className="text-right">Users</SortHeaderComponent>
          <th width="10%" className="text-right">Timecards To Be Approved</th>
          <th width="10%" className="text-right">Entries To Be Corrected</th>
          <th className="email">Email</th>
          <th className="text-right pr-3">Phone</th>
        </tr>
      </thead>
      <tbody className="timeCard-table font-colorInput">
        {data.results && data.results.map(item => (
          <tr key={item.id}>
            <td>
              {item.is_email_verified ?
                <Link to={{
                    pathname: `/vendor/sub-company/${item.id}/edit/`,

                  }}>
                  <ViewIconAtom />
                </Link>
                :
                <Link to={{
                    pathname: `/vendor/sub-company/${item.id}/edit/`,

                  }}>
                  <EditIconAtom />
                </Link>
              }

            </td>
            {(item.is_phone_verified || item.is_email_verified)
              ?(<td className="not-aligned" title={item.name}>
                <Button
                  color="link"
                  className="button2link link-hover"
                  onClick={() => goTo(item)}>
                  {item.name}
                </Button>
              </td>)
              :(<td className="not-aligned" title={item.name}>
                {item.name}
              </td>)
            }

            <td title={item.id} className="text-right">{item.id}</td>
            <td title={item.create_date_display} className="text-left">{item.create_date_display}</td>
            <td title={item.total_employees} className="text-right">{item.total_employees}</td>
            <td title={item.timecards_to_be_approved} className="text-right">{item.timecards_to_be_approved}</td>
            <td title={item.entries_to_be_corrected} className="text-right">{item.entries_to_be_corrected}</td>
            <td title={item.email} className="not-aligned">{item.email}</td>
            <td title={[item.phone_ext, item.phone_no].join('-')} className="text-right pr-3">{item.phone_ext}-{item.phone_no}</td>
          </tr>
        ))}
        {!data.count &&
          <tr><td className="text-center" colSpan="10">No Records Found</td></tr>
        }
      </tbody>
      </Table>
    </div>
  );
};


VendorSubCompanyTableComponent.propTypes = {
  data: PropTypes.object.isRequired,
  goTo: PropTypes.func,
};

export default VendorSubCompanyTableComponent;
