import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as UserDucks from 'ducks/accounts/user';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as JobDucks from 'ducks/jobs/job';
import * as RouteDucks from 'ducks/routes';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';

import { toast } from 'react-toastify';

import SectionLoaderAtom from 'atoms/SectionLoader';
import JobGeofenceFormComponent from 'components/jobs/jobGeofence/jobGeofenceForm';
import GenericModal from 'components/common/GenericModal';
import GeoFenceFormSection from 'sections/geoFences/form/Form';
import { server } from 'helpers/config';

import { COUNTRY_CODES_ARRAY } from 'constants/geoFences';

const GeocodingOption = server.geocodingOption;

const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION } = process.env;

/**
 * JobFormPage -> JobGeofenceFormSection
 *
 * Components:
 *    - {@link GeoFenceMapTableComponent}
 *
 * State:
 *    - activity track list
 *
 * Actions:
 *    None
 */
class JobGeofenceFormSection extends React.Component {
  constructor(props) {
    super(props);
    this.allocateGeofence = this.allocateGeofence.bind(this);
    this.createGeofence = this.createGeofence.bind(this);
    this.goTo=this.goTo.bind(this);
    this.jobList=this.jobList.bind(this);
    this.deleteGeofence = this.deleteGeofence.bind(this);
    this.state = { isTableLoading: false, isOpen: false, selectedGeofence: null, bounds: this.getCurrentCountryBounds(), };
    this.toggle = this.toggle.bind(this);
    this.getJobGeofence = this.getJobGeofence.bind(this);
    this.findMe = this.findMe.bind(this);
    this.locationAccessed = this.locationAccessed.bind(this);
    this.getCurrentCountryBounds = this.getCurrentCountryBounds.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
    this.resetGeofenceDetail = this.resetGeofenceDetail.bind(this);
  }

  componentDidUpdate(prevProps){
    const { currentBrowserLocation } = this.props;
    if((prevProps.currentBrowserLocation !== currentBrowserLocation)){
      this.setState({bounds: this.getCurrentCountryBounds()})
    }
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  getCurrentCountryBounds() {
    const { google, currentBrowserLocation } = this.props;
    if (!currentBrowserLocation) return null;
    
    const currentCountry = COUNTRY_CODES_ARRAY.find((i) => i.short_name == currentBrowserLocation.toUpperCase() )
      var index_country = parseInt(currentCountry.id) - 1;
      var myOptions = {
      center: new google.maps.LatLng(
        COUNTRY_CODES_ARRAY[index_country].center_lat,
        COUNTRY_CODES_ARRAY[index_country].center_lng)
      }
      // var map = new google.maps.Map(document.getElementById("map"), myOptions);
      // set the bounds of the map
      var bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(COUNTRY_CODES_ARRAY[index_country].sw_lat, COUNTRY_CODES_ARRAY[index_country].sw_lng),
        new google.maps.LatLng(COUNTRY_CODES_ARRAY[index_country].ne_lat, COUNTRY_CODES_ARRAY[index_country].ne_lng) );

      return bounds;
  }

  getJobGeofence(){
    const { router: { route: { match: { params } } } } = this.context;
    this.setState({ isTableLoading: true });
    this.props.JobDucks.getJobGeoFenceMapping(params)
      .then(() => this.setState({ isTableLoading: false }))
      .catch(() => this.setState({ isTableLoading: false }));
  }

  resetGeofenceDetail(){
    this.props.GeoFenceDucks.resetGeofenceDetail(); 
  }

  createGeofence(data){
    return this.props.GeoFenceDucks.postGeoFence(data)
      .then(() => this.props.GeoFenceDucks.getALLGeoFences({ paginate: false }));
  }

  allocateGeofence(data){
    const { router: { route: { match: { params } } } } = this.context;
    return this.props.JobDucks.putJobGeoFenceMapping(data)
      .then(() => {
        toast.success('Geofences are successfully allocated to the job.');
        this.props.JobDucks.getJobGeoFenceMapping(params);
      });
  }

  locationAccessed(position) {
    var latitude  = position.coords.latitude;
    var longitude = position.coords.longitude;
    const data= {position : {lat : latitude, lng : longitude }};
    this.props.ActivityTrackDucks.getActivityLocationAddress(data , 'locationIq');
  }

  findMe() {
  if (!navigator.geolocation){
   console.log("Geolocation is not supported by your browser");
    return;
  }
  navigator.geolocation.getCurrentPosition(this.locationAccessed);
  }


  deleteGeofence(item){
    const { router: { route: { match: { params } } } } = this.context;
    const { jobGeoFenceMappingDetail } = this.props;
    const geoFenceArr = jobGeoFenceMappingDetail.geo_fences.filter((i) => i.id !== item.id).map((y) => y.id);
    const detail = { ...jobGeoFenceMappingDetail, geo_fences_ids: geoFenceArr };
    this.setState({ isTableLoading: true });
    return this.props.JobDucks.putJobGeoFenceMapping(detail)
      .then(() => {
        toast.success('Geofence is successfully removed from the job.');
        this.props.JobDucks.getJobGeoFenceMapping(params)
          .then(() => this.setState({ isTableLoading: false }))
          .catch(() => this.setState({ isTableLoading: false }));
      })
      .catch(() => this.setState({ isTableLoading: false }));
  }

  goTo(url, data) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const {location: { query } } = this.props;
    this.props.JobDucks.setJob(data);
    if(params.id){
      history.push(url);
    }
    else {
      history.push({
        pathname: url ,
        search: queryString.stringify({
          parent: JSON.stringify(query),
          job: 'new',
        }),
      });
    }
  }

  jobList() {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    const pathname = `/job/list?status=1`;
    const newPath = `/job/list/new?status=1`;
    query.admin_list ? history.push(newPath) : history.push(pathname);
  }

  toggle(item){
    const { isOpen } = this.state;
    if(!isOpen){
      this.setState({ selectedGeofence: item });
      return this.props.GeoFenceDucks.getGeoFence(item)
        .then(() => this.setState({ isOpen: !isOpen }));
    }else if (isOpen && item == 'submit'){
      this.props.fetchData();
    }
    this.props.GeoFenceDucks.resetGeofenceDetail();
    return this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isTableLoading, isOpen, selectedGeofence } = this.state;
    const {
      isLoading,loaded,
      google,geoFenceAll,
      location,
      jobGeoFenceMappingDetail,
      isGeoFenceVisible,
    } = this.props;
    if(isLoading) return <SectionLoaderAtom active />;
    return (
      <section style={{padding: '0 80px', color: '#242424'}}>
        <JobGeofenceFormComponent
          isGeoFenceVisible={isGeoFenceVisible}
          loaded={loaded}
          google={google}
          initialValues={jobGeoFenceMappingDetail}
          geoFenceAll={geoFenceAll}
          allocateGeofence={this.allocateGeofence}
          createGeofence={this.createGeofence}
          goTo={this.goTo}
          jobList={this.jobList}
          deleteGeofence={this.deleteGeofence}
          isTableLoading={isTableLoading}
          location={location}
          toggle={this.toggle}
          bounds={this.state.bounds}
          getAddressForMarker = {this.getAddressForMarker}
          getJobGeofence={this.getJobGeofence}
          resetGeofenceDetail={this.resetGeofenceDetail}
        />
        { isOpen &&
          <GenericModal toggle={this.toggle} isOpen={isOpen} heading="Edit Geofence" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
            <GeoFenceFormSection
              selectedGeofence={selectedGeofence}
              toggle={this.toggle}
              getJobGeofence={this.getJobGeofence}
              submit={this.submit}
              size="lg"
            />
          </GenericModal>
        }
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  geoFenceAll: GeoFenceDucks.geoFenceAll(state),
  jobData: JobDucks.jobData(state),
  jobGeoFenceMappingDetail: JobDucks.jobGeoFenceMappingDetail(state),
  location: RouteDucks.location(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
  currentBrowserLocation : ActivityTrackDucks.currentBrowserLocation(state),
});

const mapActionToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
});

JobGeofenceFormSection.propTypes = {
  google: PropTypes.object,
  loaded: PropTypes.bool,
  jobData: PropTypes.object.isRequired,
  dateFormat: PropTypes.string.isRequired,
  geoFenceAll: PropTypes.array.isRequired,
  tagList: PropTypes.array,
  isGeoFenceVisible: PropTypes.bool.isRequired,
};

JobGeofenceFormSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectReactToGoogleApi = connect(
  mapStateToProps,
  mapActionToProps,
)(JobGeofenceFormSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(connectReactToGoogleApi);
