import React, {PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import EditableCellAtom from 'atoms/EditableCell';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';
import styles from './styles.module.scss';

/**
 * ActivitySplitFormPage -> ActivitySplitTimelineSection-> ActivitySplitPunchOutComponent
 *
 * Props:
 *    None
 */
class ActivitySplitPunchOutComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.updatable=this.updatable.bind(this);
    this.updateEntry= this.updateEntry.bind(this);
    this.state = { endDate : props.item && props.item.end_datetime , endTime :  props.item && moment(props.item.end_datetime).format('LT')};
  }

  updateEntry(item, date){
    const { editEntry, browserParseDateISO } = this.props;
    const { endTime } = this.state;
    this.setState({endDate : date});
    const updatedTime = moment(endTime, "h:mm:ss A").format("HH:mm:ss");
    const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedItem = {...item, end_datetime : updatedDateTime};
    editEntry({ ...updatedItem, end_datetime: moment(updatedItem.end_datetime).toISOString()});
    // editEntry({ ...updatedItem, start_datetime: browserParseDateISO(item.start_datetime, false), end_datetime : browserParseDateISO(updatedItem.end_datetime, false)});
  }

  updatable(data , key, value){
    const { editEntry, browserParseDateISO } = this.props;
    const { endDate } = this.state;
    if(!value){
      return toast.error('Please enter time');
    }
    const updatedTime = moment(value, "h:mm:ss A").format("HH:mm:ss");
    const updatedDateTime = moment(`${moment(endDate).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
    const updatedItem = {...data, end_datetime : updatedDateTime};
    // const updatedItem = {...data, start_datetime : browserParseDateISO(data.start_datetime, false),end_datetime : browserParseDateISO(updatedDateTime, false)};
    this.setState({endTime : value })
    editEntry(updatedItem);
  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime && data.start_datetime.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }

  render(){
    const { dateFormat, item , timeFormat,timeFormat24Hrs, editEntry, disabled, browserParseDate } = this.props;

    return (
      <div className='override_alignment'>
      {!this.props.newStyle && item ?
        <Card block className={`${styles.timelinePointer} p-2 pb-4 activityCard`}>
          <span className={styles['timeline-badge__punchOut']}></span>
          <Row className={`${styles['block-display']}`}>
            <Col md={2}>
              <CardTitle className={`${styles['fw']} f16 mb-0 pt-2 pl-2`}>Out</CardTitle>
            </Col>
            <Col md={6}>
              <div className={`${styles['in-out-width']} f16 mb-0 pt-3 pl-2`}>
                <Label className={`${styles['end-time-label']} mb-0`}> End Time </Label>
                <Flatpickr
                  className="form-control mb-2 mt-1"
                  value={item?.end_datetime}
                  options={{
                    dateFormat: `${dateFormat}, ${timeFormat}`,
                    enableTime: true,
                    minuteIncrement: 1,
                    time_24hr: timeFormat24Hrs,
                    enable: [
                      // Enable the dates b/w payroll startDate & endDate
                      // Also no future date selection
                      (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                        moment(date).isBefore(moment(endDate)) &&*/
                        moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                    ],
                  }}
                  onChange={date => editEntry({ ...item, end_datetime: date[0] })}
                  disabled={disabled}
                />
              </div>
            </Col>
          </Row>
        </Card>
        :
        <div className={styles['punchoutDiv']}>
          <div className={`${styles['fw']} f16 mb-0 pt-2 pl-2`}>Out</div>
          <Row>
            <Col><div className={`${styles['end-time-label']} mb-0 pl-2 pt-2`}>End Date</div></Col>
            <Col><div className={`${styles['end-time-label']} mb-0 pt-2`}>End Time</div></Col>
          </Row>
          <Row>
          <Col md={6} className='datePicker'>
            <Flatpickr
              className={`${styles['punch-date-label']} form-control ml-2 mb-0 mt-1`}
              value={this.state.endDate}
              options={{
                dateFormat: `${dateFormat}`,
                enableTime: false,
                minuteIncrement: 1,
                time_24hr: timeFormat24Hrs,
                enable: [
                  // Enable the dates b/w payroll startDate & endDate
                  // Also no future date selection
                  (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                    moment(date).isBefore(moment(endDate)) &&*/
                    moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                ],
              }}
              onChange={(date)=> this.updateEntry(item, date[0])}
              disabled={disabled}
            />
          </Col>
          <Col md={6} className="mt-1 edit-start-time">
            <EditableCellAtom
              value={this.state.endTime}
              valueKey="end_time"
              details={{...item , end_datetime : moment(this.state.endDate).toISOString()}}
              editable={true}
              update={this.updatable}
              validate={this.validate}
            />
          </Col>
          </Row>
        </div>
      }
      </div>
    );
    }
  }

ActivitySplitPunchOutComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editEntry: PropTypes.func,
};

export default ActivitySplitPunchOutComponent;
