import React from 'react';

import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';
import bindActionCreators from 'redux/lib/bindActionCreators';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';


import * as RouteDucks from 'ducks/routes';
import * as UserDucks from 'ducks/accounts/user';
import * as JobDucks from 'ducks/jobs/job';
import * as TaskDucks from 'ducks/jobs/task';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';
import * as InfoDucks from 'ducks/info/info';
import * as EmployeeDucks from 'ducks/employees/employee';
import * as CustomerDucks from 'ducks/customers/customer';
import * as JobTagDucks from 'ducks/jobs/tag';

import TwoColumnLayout from 'layouts/TwoColumn';
import JobFormSection from 'sections/jobs/form/Form';
import JobProgressBarSection from 'sections/jobs/form/ProgressBar';

import { NAV } from 'constants/jobs';

/**
 * JobFormPage
 *
 * Layout:
 *    - {@link TwoColumnLayout}
 *
 * Sections:
 *    - {@link JobFormSection}
 *
 * Fetch Needs:
 *    - get job detail and edit form
 */
class JobFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, parent: NAV.JobEditFormPage.parent, isJobAddInfo: true, isJobEditInfo: true };
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setParentUrl = this.setParentUrl.bind(this);
    this.toggleInfo = this.toggleInfo.bind(this);
  }

  componentWillMount() {
    const cookieKey = cookie.load("jobs_edit_form_info");
    if(cookieKey) {
      this.setState({isJobEditInfo: JSON.parse(cookieKey)});
    }
    this.props.CustomerDucks.resetCustomer();
    this.props.TaskDucks.resetTask();
    this.props.EmployeeDucks.resetEmployee();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { location: { query: prevQuery } } = this.props;
    const { location: { query: nextQuery } } = nextProps;
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }


  componentWillUnmount() {
    this.props.InfoDucks.resetInformations();
  }

  setParentUrl(query) {
    const { router: { route: { match: { params } } } } = this.context;
    try {
      if (query.parent) {
        const parsedParams = JSON.parse(query.parent);
        if (params.id) {
          this.setState({
            parent: `${NAV.JobEditFormPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        } else {
          this.setState({
            parent: `${NAV.JobEditFormPage.parent}?${queryString.stringify(parsedParams)}`,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  fetchData(params) {
    return this.props.EmployeeDucks.getAllEmployees({ ...params, paginate: false });
  }

  loadData(){
    const { location, isGeoFenceVisible} = this.props;
    const { query } = location;
    if(isGeoFenceVisible) this.props.GeoFenceDucks.getALLGeoFences({ paginate: false });
    this.setParentUrl(query);
    this.setState({ isLoading: true });
    this.props.JobDucks.resetJob();
    Promise.all([
      this.props.JobTagDucks.getJobTags({ paginate: false, tag_type: 2 }),
      this.props.CustomerDucks.getCustomers({ paginate: false, is_active: true }),
    ])
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
    this.fetchData(query);
  }

  toggleInfo(){
    const { router: { route: { match: { params } } } } = this.context;
    const { isJobAddInfo, isJobEditInfo } = this.state;
    if (params.id) {
      this.setState({ isJobEditInfo: !isJobEditInfo });
      cookie.save("jobs_edit_form_info", !isJobEditInfo);
    }
    else {
      this.setState({ isJobAddInfo: !isJobAddInfo });
      cookie.save("job_add_form_info", !isJobAddInfo);
    }
  }

  render() {
    const { isLoading , isJobEditInfo, isJobAddInfo } = this.state;
    const { infoList } = this.props;
    const { router: { route: { match } } } = this.context;
    return (
      <TwoColumnLayout
        navInfo={NAV.JobAddFormPage}
        infoList={infoList}
        isInfoOpen={match.params.id ? isJobEditInfo : isJobAddInfo}
        toggleInfo={this.toggleInfo}
        fetchData={this.loadData}
      >
      <div className="pb-5">
         <Row className="pt-4 mr-4 ml-3" style={{backgroundColor: 'white'}}>
          <Col>
            <Row>
              <Col className="text-center mb-4">
                <JobProgressBarSection active={1}
                addJob="true"
                location={this.props.location}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <JobFormSection isLoading={isLoading} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      </TwoColumnLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  location: RouteDucks.location(state),
  jobDetail: JobDucks.jobDetail(state),
  jobData: JobDucks.jobData(state),
  geoFenceAll: GeoFenceDucks.geoFenceAll(state),
  isGeoFenceVisible: UserDucks.isGeoFenceVisible(state),
  infoList: InfoDucks.infoList(state),
});

const mapActionsToProps = dispatch => ({
  JobDucks: bindActionCreators(JobDucks, dispatch),
  GeoFenceDucks: bindActionCreators(GeoFenceDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  TaskDucks: bindActionCreators(TaskDucks, dispatch),
  JobTagDucks: bindActionCreators(JobTagDucks, dispatch),
  CustomerDucks: bindActionCreators(CustomerDucks, dispatch),
});

JobFormPage.propTypes = {
  location: PropTypes.object.isRequired,
  GeoFenceDucks: PropTypes.object.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
  InfoDucks: PropTypes.object.isRequired,
  infoList: PropTypes.object.isRequired,
  EmployeeDucks: PropTypes.object.isRequired,
};

JobFormPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(JobFormPage);
