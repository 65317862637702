import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_TASK_TAGS = 'jobs/taskTag/GET_TASK_TAGS';
const GET_TASK_TAGS_FULFILLED = 'jobs/taskTag/GET_TASK_TAGS_FULFILLED';
const POST_TASK_TAG = 'jobs/taskTag/POST_TASK_TAG';
const POST_TASK_TAG_FULFILLED = 'jobs/taskTag/POST_TASK_TAG_FULFILLED';

export function getTaskTags(params) {
  return {
    type: GET_TASK_TAGS,
    payload: axios.get(`${URLS.JOB}${URLS.TAG}`, { params }),
  };
}

export function postTaskTag(data) {
  return {
    type: POST_TASK_TAG,
    payload: axios.post(`${URLS.JOB}${URLS.TAG}`, data),
  };
}

const defaultState = {
  list: { results: [] } ,
  detail: {},
};

function GET_TASK_TAGS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function POST_TASK_TAG_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_TASK_TAGS_FULFILLED]: GET_TASK_TAGS_FULFILLED_REDUCER,
  [POST_TASK_TAG_FULFILLED]: POST_TASK_TAG_FULFILLED_REDUCER,
};


const taskTagSelector = state => state.jobs.taskTag;

export const taskTagList = createSelector(
  taskTagSelector,
  instance => instance.list,
);

export const taskTagDetail = createSelector(
  taskTagSelector,
  instance => instance.detail
)

export default createReducer(defaultState, handlers);
