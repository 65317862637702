import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';

import Form from 'reactstrap/lib/Form';

import InputAtom from 'atoms/Input';

import { FILTER_FORM_LAYOUT } from 'constants/layout';
import {ReactComponent as ClearIcon} from './close.svg';
import {ReactComponent as SearchIcon} from './search.svg';

import styles from './styles.module.scss';


class FilterFormComponent extends React.PureComponent {

  constructor(props){
    super(props);
    this.clear = this.clear.bind(this);
    this.submit = this.submit.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  clear() {
    const { router: { history } } = this.context;
    const { location: { pathname, search }, change } = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    delete rest.search;
    change('search', null);
    history.push({ pathname, search: queryString.stringify(rest) });
  }

  submit(data){
    const { router: { history } } = this.context;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    history.push({
      pathname, search: queryString.stringify({ ...rest, search: data.search }),
    });
  }

  clearSearch(e,value){
    const { router: { history } } = this.context;
    const { location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    const { page, ...rest } = query;
    delete rest.page_size;
    if(!value) {
      history.push({
        pathname, search: queryString.stringify({ ...rest, search: value }),
      });
    }
  }


  render(){
    const {  placeholder, search, handleSubmit } = this.props;

    return (
      <Form inline onSubmit={handleSubmit(this.submit)} className="search">
        <div className="input-group">
          <Field
            component={InputAtom}
            id="search"
            size={FILTER_FORM_LAYOUT.size}
            name="search"
            placeholder={placeholder}
            suffix={<span className={styles['search-allign']}  role="button" tabIndex={0} onClick={handleSubmit(this.submit)}> <SearchIcon width={16} height={15} className="search-Icon"/></span>}
            onChange={this.clearSearch}
          />
          {search &&
            <ClearIcon
              className={styles['input-icon-clear']}
              height="13" width="13"
              onClick={this.clear}
            />
          }

        </div>
      </Form>
    );
  }
}

FilterFormComponent.defaultProps = {
  placeholder: "Type to search",
};

FilterFormComponent.propTypes = {
  location: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

FilterFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const filterForm = reduxForm({
  form: 'FilterForm',
  enableReinitialize: true,
})(FilterFormComponent);

const selector = formValueSelector('FilterForm');

export default connect(
  (state) => {
    const search = selector(state, 'search');
    return { search };
  },
)(filterForm);
