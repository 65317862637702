import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { toast } from 'react-toastify';

import * as RouteDucks from 'ducks/routes';
import * as DepartmentDucks from 'ducks/vendors/department';
import * as PayrollSettingDucks from 'ducks/payrolls/setting';
import * as PayrollDucks from 'ducks/payrolls/payroll';
import * as TeamDucks from 'ducks/vendors/team';


import VendorDepartmentFormModalComponent from 'components/vendors/departmentList/FormModal';
import VendorTeamFormModalComponent from 'components/vendors/departmentList/TeamFormModal';


import { MSGS } from 'constants/vendors';

/**
 * VendorDepartmentListPage -> VendorDepartmentFilterSection
 *
 * Components:
 *    - Add Department
 *
 * State:
 *    None
 *
 * Actions:
 *    None
 */
class VendorDepartmentFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isDepartmentOpen: false, isTeamOpen: false};
    this.createOrUpdate = this.createOrUpdate.bind(this);
    this.toggleDeptForm = this.toggleDeptForm.bind(this);
    this.toggleTeamForm = this.toggleTeamForm.bind(this);
  }

  toggleDeptForm(data) {
    const { isDepartmentOpen } = this.state;
    this.setState({ isDepartmentOpen: !isDepartmentOpen });
    return this.state.isDepartmentOpen;
  }

  toggleTeamForm(data) {
    const { isTeamOpen } = this.state;
    if (!isTeamOpen) this.props.DepartmentDucks.getAllDepartments({ paginate: false})
    return this.setState({ isTeamOpen: !isTeamOpen });
  }

  createOrUpdate(data, type) {
    const { router: { history, route: { match: { params } } } } = this.context;
    const { location: { query } } = this.props;
    if ( type === "department") {
      return this.props.DepartmentDucks.postDepartment(data)
        .then(() => {
          toast.success(MSGS.VENDOR_DEPARTMENT_ADD_SUCCESS);
          this.props.DepartmentDucks.getDepartments({ ...params, ...query});
          this.toggleDeptForm();
          history.push('/vendor/department');
        });
    }
    else if ( type==="team") {
      return this.props.TeamDucks.postTeam(data)
        .then(() => {
          toast.success(MSGS.VENDOR_TEAM_ADD_SUCCESS);
          this.props.DepartmentDucks.getDepartments();
          this.toggleTeamForm();
          history.push('/vendor/department');
        });
    }
    return null;
  }

  render() {
    const { isDepartmentOpen, isTeamOpen } = this.state;
    const { payrollSettingAll, departmentAll, payrollAll } = this.props;
    const defaultPayrollSetting = payrollSettingAll && payrollSettingAll.length ? payrollSettingAll.find((i) => i.name === 'California') : {};
    
    return (
      <Row className="filter ml-3 mr-4">
        <Col className="px-0">
          <section className="float-right mt-4">
            <Button
              className="pr-4 pl-4"
              color="accent"
              onClick={() => this.toggleDeptForm()}
            >
              Add Department
            </Button>
            <Button
              className="pr-4 pl-4 mr-0"
              color="accent"
              onClick={() => this.toggleTeamForm()}
            >
              Add Team
            </Button>
            {isDepartmentOpen &&
              <VendorDepartmentFormModalComponent
                isOpen={isDepartmentOpen}
                toggleDeptForm={this.toggleDeptForm}
                createOrUpdate={this.createOrUpdate}
                payrollSettingAll={payrollSettingAll && payrollSettingAll.length > 0 ? payrollSettingAll: null}
                payrollAll={payrollAll}
                initialValues={ { payroll_setting: defaultPayrollSetting, is_active: true }}
              />
            }
            {isTeamOpen &&
              <VendorTeamFormModalComponent
                isOpen={isTeamOpen}
                toggleTeamForm={this.toggleTeamForm}
                createOrUpdate={this.createOrUpdate}
                departmentAll={departmentAll}
                initialValues={{ is_active: true }}
              />
            }
          </section>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = state => ({
  departmentAll: DepartmentDucks.departmentAll(state),
  location: RouteDucks.location(state),
  payrollSettingAll: PayrollSettingDucks.payrollSettingAll(state),
  payrollAll: PayrollDucks.payrollAll(state),
});

const mapActionsToProps = dispatch => ({
  DepartmentDucks: bindActionCreators(DepartmentDucks, dispatch),
  TeamDucks: bindActionCreators(TeamDucks, dispatch),
});

VendorDepartmentFilterSection.propTypes = {
  departmentAll: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
};

VendorDepartmentFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(VendorDepartmentFilterSection);
