import React from 'react';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import { FORM_LAYOUT } from 'constants/layout';
import styles from './styles.module.scss';
import closeimage from './Close-Cross.png';

/**
 * JobTaskMappingListPage -> JobTaskMappingFilterSection -> ConfirmationModal
 *
 * Props:
 *    - update
 */
class DeleteTemplateConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
    console.log('');
  }

  render() {
    const { isOpen,toggle, deleteSurveyTemplate,isDeleteTemplateLoader } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader>
          Confirm Delete
          <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle(null)} />
        </ModalHeader>
        <ModalBody>
          <h5>Are you sure you want to delete this template?</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => toggle(null)} size={FORM_LAYOUT.size} >
            Cancel
          </Button>
          <Button type="submit" color="secondary" onClick={() => deleteSurveyTemplate(isOpen)} size={FORM_LAYOUT.size} >
            Yes <ButtonLoaderAtom active={isDeleteTemplateLoader} />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};


export default DeleteTemplateConfirmationModal;