import React from "react";

// import MultiSelectComponent from "components/common/MultiSelectComponent";
// import SingleSelectComponent from "components/common/SingleSelectComponent";
// import FreeSelectComponent from "components/common/FreeSelectComponent";
// import DateTimeComponent from "components/common/DateTimeSelectComponent";
// import TimeComponent from "components/common/TimeSelectComponent";
import { FORMS_QUESTIONS_TYPE } from "constants/forms";
import DatePicker from "react-flatpickr";
class TempComponent extends React.Component {
  constructor(props) {
    super(props);
    this.checkTagsInString = this.checkTagsInString.bind(this);
  }
  checkTagsInString(str) {
    let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    return isHTML(str);
  }
  render() {
    const { templateData } = this.props;
    return (
      <div>
        {templateData?.questions?.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* {templateData?.questions?.map(
              (item, index) => (
                <div className="ml-3 mr-3 mb-3">
                  {(item.definition == "SINGLE_SELECT" ||
                    item.definition == "single_select") && (
                    <div>
                      <SingleSelectComponent data={item} index={index} />
                    </div>
                  )}
                  {(item.definition == "MULTI_SELECT" ||
                    item.definition == "multi_select") && (
                    <div>
                      <MultiSelectComponent data={item} index={index} />
                    </div>
                  )}
                  {(item.definition == "FREE_TEXT" ||
                    item.definition == "free_text") && (
                    <div>
                      <FreeSelectComponent data={item} index={index} />
                    </div>
                  )}
                  {(item.definition == "BOOLEAN" ||
                    item.definition == "boolean") && (
                    <div>
                      <SingleSelectComponent data={item} index={index} />
                    </div>
                  )}
                  {(item.definition == "DATE_TIME_SELECT" ||
                    item.definition == "date_time_select") && (
                    <div>
                      <DateTimeComponent
                        data={item}
                        index={index}
                        dateFormat={this.props.dateFormat}
                        timeFormat={this.props.timeFormat}
                        timeFormat24Hrs={this.props.timeFormat24Hrs}
                      />
                    </div>
                  )}
                  {(item.definition == "TIME_SELECT" ||
                    item.definition == "time_select") && (
                    <div>
                      <TimeComponent
                        data={item}
                        index={index}
                        dateFormat={this.props.dateFormat}
                        timeFormat={this.props.timeFormat}
                        timeFormat24Hrs={this.props.timeFormat24Hrs}
                      />
                    </div>
                  )}
                </div>
              ) */}
            {templateData?.questions?.map((ele, idx) => {
              return (
                <div
                  style={{
                    padding: "5px 2px",
                    background: "white",
                    fontSize: "10px",
                    pointerEvents: "none",
                    width: "100%",
                    marginBottom: "10px",
                    fontFamily: "system-ui",
                    boxShadow: "0 2px 6px 0 rgb(0 0 0 / 20%)",
                  }}
                >
                  <div
                    style={
                      this.checkTagsInString(ele.question)
                        ? {
                            fontWeight: "bold",
                            padding: "0.25rem",
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "-10px",
                            marginTop: "-10px",
                          }
                        : {
                            fontWeight: "bold",
                            padding: "0.25rem",
                            display: "flex",
                            alignItems: "center",
                          }
                    }
                  >
                    {ele.behaviour === "MANDATORY" && (
                      <span
                        style={{
                          color: "red",
                          marginleft: "2px",
                          marginRigtt: "2px",
                        }}
                      >
                        *
                      </span>
                    )}
                    <span>{idx + 1}.</span>
                    <span
                      style={{ paddingLeft: "0.25rem" }}
                      dangerouslySetInnerHTML={{ __html: ele.question }}
                    />
                  </div>
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.SINGLE_SELECT && (
                    <div style={{ padding: "0.25rem" }}>
                      {ele.answer.map((e, i) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="radio"
                            name="ans"
                            id=""
                            onChange={(e) => this.ansMarked(e, i)}
                          />
                          <span style={{ paddingLeft: "0.25rem" }}>
                            {e.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.MULTI_SELECT && (
                    <div style={{ padding: "0.25rem" }}>
                      {ele.answer.map((e, i) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            name="ans"
                            id=""
                            onChange={(e) => this.ansMarked(e, i)}
                          />
                          <span style={{ paddingLeft: "0.25rem" }}>
                            {e.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.DATE && (
                    <div style={{ padding: "0.25rem" }}>
                      <input type="date" style={{width:'100%'}}/>
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.NUMBER && (
                    <div style={{ padding: "0.25rem" }}>
                      <input
                        type="number"
                        name=""
                        id=""
                        style={{ width: "95%" }}
                      />
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.FILE_UPLOAD && (
                    <div style={{ padding: "0.25rem" }}>
                      <input
                        type="file"
                        id="fileUpload"
                        name="fileUpload"
                        style={{ fontSize: "11px", width: "95%" }}
                      />
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.FREE_TEXT && (
                    <div style={{ padding: "0.25rem" }}>
                      <input
                        type="text"
                        id="openEnded"
                        name="openEnded"
                        style={{ fontSize: "11px", width: "95%" }}
                      />
                    </div>
                  )}
                  {FORMS_QUESTIONS_TYPE[ele.definition] ===
                    FORMS_QUESTIONS_TYPE.SIGNATURE && (
                    <div style={{ padding: "0.25rem" }}>
                      <input
                        type="signature"
                        id="openEnded"
                        name="openEnded"
                        style={{ fontSize: "11px", width: "95%" }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
            <button
              style={{
                cursor: "pointer",
                background: "#09819a",
                color: "white",
                border: "none",
                lineHeight: "1.5em",
                padding: "5px 15px",
                fontSize: "12px",
                borderRadius: "3px",
              }}
              onClick={this.handleSubmit}
            >
              <strong>Submit</strong>
            </button>{" "}
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No records found
          </div>
        )}
        {}
      </div>
    );
  }
}

export default TempComponent;
