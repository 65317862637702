import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';
import Field from 'redux-form/lib/Field';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import Row from 'reactstrap/lib/Row';
import styles from './styles.module.scss';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import MultiSelectDropdownAtom from 'atoms/MultiSelectDropdown';
import CircleComponent from 'components/common/googleMap/Circle';
import MapComponent from 'components/common/googleMap/Map';
import PolygonComponent from 'components/common/googleMap/Polygon';
import MarkerComponent from 'components/common/googleMap/Marker';
import RectangleComponent from 'components/common/googleMap/Rectangle';
import InfoWindowComponent from 'components/common/googleMap/InfoWindow';
import GoogleAutoCompleteAtom from 'atoms/GoogleAutoComplete';
import { FORM_LAYOUT } from 'constants/layout';
import { toast } from 'react-toastify';
import GeofenceTableComponent from './geofenceTable';
import GenericModal from 'components/common/GenericModal';
import GeoFenceFormSection from 'sections/geoFences/form/Form';
/**
 * JobFormPage -> JobFormSection -> JobFormComponent
 *
 * Props:
 *    - createOrUpdate
 */
class JobGeofenceFormComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showInfoWindow: false,
      selectedGeofence: null,
      assignTask:false,
      activeShape: {},
      shapeEvent: {},
      data: {},
      geoFences: [],
      viewbox: null,
      isFormOpen: false
    };
    this.submit = this.submit.bind(this);;
    this.handleChange = this.handleChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.goToUrl = this.goToUrl.bind(this);
    this.setRestrictedViewbox = this.setRestrictedViewbox.bind(this);
    this.openGeofenceForm = this.openGeofenceForm.bind(this);
    this.addAndAssociateNewGeofence = this.addAndAssociateNewGeofence.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
  }

  componentWillReceiveProps(nextProps, nextState){
    if(this.props.isTableLoading !== nextProps.isTableLoading){
      this.setState({ geoFences: [], selectedGeofence: null });
    }
  }


  setRestrictedViewbox(params){
    this.setState({viewbox : params});
  }

  openGeofenceForm(){
    this.props.resetGeofenceDetail();
    const { isFormOpen } = this.state;
    this.setState({isFormOpen : !isFormOpen});
  }

  handlePositionChange(){
    const { change } = this.props;
    change('g_address', null);
  }

  handleChange(e, val) {
    const { change } = this.props;
    change('g_address', null);
    this.setState({ geoFences: val, selectedGeofence: [] });
  }

  addAndAssociateNewGeofence(data){
    const { initialValues , createGeofence , allocateGeofence} = this.props;
    const { selectedGeofence } = this.state;
    const existingGeofences = initialValues.geo_fences.length ? initialValues.geo_fences.map((i) => i.id) : [];
    if( !initialValues.id) return Promise.reject(toast.error('Please create a job to assign geofences'));
    if(!data.address) return toast.error('Please search or add Geofences to allocate.');
    if(data.address){
      const geofenceData = {
        ...data,
        is_active: true,
      };
      return createGeofence(geofenceData)
        .then(() => {
          const { geoFenceAll } = this.props;
          const addedGeofence = geoFenceAll.filter((item) => item.name === data.name)[0];
          if(addedGeofence.id){
            const newDetail = { ...initialValues, geo_fences_ids: [...existingGeofences, addedGeofence.id]};
            return allocateGeofence(newDetail)
              .then(() => {
                this.props.getJobGeofence();
                this.setState({ isFormOpen: null })
              });
          }
          return true;
        })
    }
  }

  submit(data) {
    const { allocateGeofence, createGeofence, initialValues } = this.props;
    const { selectedGeofence } = this.state;
    const existingGeofences = data.geo_fences.length ? data.geo_fences.map((i) => i.id) : [];
    const addedGeofences = data.geo_fence && data.geo_fence.length ? data.geo_fence.map((i) => i.id) : [];
    const detail = { ...data,
      geo_fences_ids: existingGeofences.concat(addedGeofences),
    };
    if( !initialValues.id) return Promise.reject(toast.error('Please create a job to assign geofences'));
    if(!data.g_address && !addedGeofences.length) return toast.error('Please search or add Geofences to allocate.');
    if(data.g_address){
      const geofenceData = {
        ...selectedGeofence,
        is_active: true,
      };
      return createGeofence(geofenceData)
        .then(() => {
          const { geoFenceAll } = this.props;
          const addedGeofence = geoFenceAll.filter((item) => item.name === selectedGeofence.name)[0];
          if(addedGeofence.id){
            const newDetail = { ...detail, geo_fences_ids: [...detail.geo_fences_ids, addedGeofence.id]};
            return allocateGeofence(newDetail)
              .then(() => {
                this.setState({ selectedGeofence: null })
              });
          }
          return true;
        })
    }
    return allocateGeofence(detail);
  }

  handleAddressSelect(value) {
    const { change } = this.props;
    const { latLong, selectedGeofence } = this.state;
    if(value){
      this.setState({ selectedGeofence: { name:  value.description,
        radius: 100,
        address: value.description,
        shape_type: 'Circle',
        draggable: true ,
        lat_long: { type: 'Point',
        coordinates: [value.location.lng || value.location.lng() , value.location.lat || value.location.lat() ] },
      },
      geoFences: [],
    });
    }else{
      this.setState({selectedGeofence: null})
    }
    change('geo_fence', null);
  }


  goToUrl(url) {
    const { router: { history } } = this.context;
    const {location: { query } } = this.props;
    if(query.setup){
      history.push(`${url}?setup=true`);
    }else{
      let newSearch;
      if(query.parent){
        newSearch = queryString.stringify({ parent: query.parent });
      }
      history.push(`${url}?${newSearch}`);
    }
  }

  render() {
    const {
      handleSubmit,
      initialValues,submitting,
      loaded,isGeoFenceVisible,
      isLoading, google, toggle,
      geoFenceAll,
      jobList, deleteGeofence, isTableLoading , location: { query },
    } = this.props;
    const { router: { route: { match: { params } } } } = this.context;
    const { activeShape, showInfoWindow, shapeEvent, geoFences, data, selectedGeofence , isFormOpen} = this.state;
    let newGeofence = initialValues.geo_fences.concat(geoFences);
    if(selectedGeofence && Object.keys(selectedGeofence).length){
      newGeofence = [selectedGeofence];
    }
    return (
      <section>
        <Form onSubmit={handleSubmit(this.submit)} className="mt-0">
          {!params.id &&
            <Row className="add-job-back" id="third">
              <Col md={4}>
                <h4 className="job-title mb-2"> 3. Define Geofences</h4>
              </Col>
            </Row>
          }
          {isGeoFenceVisible &&
            <Row className="pb-2">
              <Col md={4} className="pl-3 pr-0 geofence-arrow">
                <Field
                  component={MultiSelectDropdownAtom}
                  id="geo_fence"
                  name="geo_fence"
                  placeholder="Search an existing Geofence"
                  label="Select Geofence"
                  items={geoFenceAll}
                  itemToString={i => i && i.name}
                  onChange={(e, val) =>  this.handleChange(e, val)}
                />
              </Col>
              {/*<Col md={4} className="offset-1 pl-0">
                <Field
                  id="g_address"
                  name="g_address"
                  component={GoogleAutoCompleteAtom}
                  layout={FORM_LAYOUT}
                  google={google}
                  labelKey="description"
                  label="Enter Address to Add"
                  valueKey="place_id"
                  placeholder="Enter Address to add one"
                  onSelect={(e, v) => this.handleAddressSelect(e,v)}
                  viewbox={this.state.viewbox}
                />
              </Col>*/}
              <Col md={2}>
                <div>
                  <button
                    className="rounded assign-button custom-shape"
                    type="submit"
                  >
                    <span>Assign</span>
                      <ButtonLoaderAtom active={submitting} />
                </button>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <button
                    className="rounded assign-button custom-shape float-right w-50"
                    type="button"
                    onClick = {()=>this.openGeofenceForm()}
                  >
                    <span>Add Geofence</span>
                </button>
                </div>
              </Col>
            </Row>
          }
          {isGeoFenceVisible &&
            <Row>
              <Col md={5} className="pr-3 col col-md-6">
                <GeofenceTableComponent
                  data={initialValues.geo_fences}
                  deleteGeofence={deleteGeofence}
                  isTableLoading={isTableLoading}
                  toggle={toggle}
                  query= {query}
                />
              </Col>
              <Col md={6} className="pl-0 pr-3 col col-md-6">
                <div style={{ height: '57vh' }} className="mt-2">
                  {loaded && <MapComponent
                    google={google}
                    setRestrictedViewbox={this.setRestrictedViewbox}
                    locations={newGeofence.length ? newGeofence : []}
                    getAddressForMarker = {this.getAddressForMarker}
                    bounds={!newGeofence.length ? this.props.bounds : null}
                  >
                    {!isLoading && newGeofence.map((item, index) => {
                      if (item.shape_type === 'Point') {
                        return <MarkerComponent
                          key={item.id}
                          getAddressForMarker = {this.getAddressForMarker}
                          data={item}
                          position={{
                            lat: item.lat_long && item.lat_long.coordinates[1],
                            lng: item.lat_long && item.lat_long.coordinates[0],
                          }}
                          onClick={this.onShapeClick}
                          draggable={item.draggable}
                        />;
                      }
                      else if (item.shape_type === 'Polygon') {
                        return (<PolygonComponent
                          id={item.id}
                          getAddressForMarker = {this.getAddressForMarker}
                          paths={item.lat_long.coordinates[0].map(
                            (latLng) => ({ lat: latLng[1], lng: latLng[0] }))}
                          fillColor={item.color}
                        />);
                      }
                      else if (item.shape_type === 'Rectangle') {
                        return (<RectangleComponent
                          id={item.id}
                          getAddressForMarker = {this.getAddressForMarker}
                          bounds={item.lat_long.coordinates}
                          fillColor={item.color}
                        />);
                      }
                      else if (item.shape_type === 'Circle') {
                        return (<CircleComponent
                          id={item.id}
                          getAddressForMarker = {this.getAddressForMarker}
                          radius={item.lat_long.radius || item.radius}
                          handlePositionChange={this.handlePositionChange}
                          center={
                            item.lat_long.coordinates.length
                              ? {
                                lat: item.lat_long.coordinates[1],
                                lng: item.lat_long.coordinates[0],
                              }
                              : {}
                          }
                          fillColor={item.color}
                        />);
                      }
                      return null;
                    })}
                    <InfoWindowComponent
                      shape={activeShape}
                      visible={showInfoWindow}
                      shapeEvent={shapeEvent}
                      data={data}
                    >
                      <div>9898989898</div>
                    </InfoWindowComponent>
                  </MapComponent>}
                </div>
              </Col>
            </Row>
          }
        </Form>
        { query.setup ? <Row>
          {/*
            <Col md={6}>
              <Button
                className="btn-job-primary btn-job mt-3 float-left button-padding"
                onClick={() => this.goToUrl(`/job/list/${params.id}/budget/edit`)}>
                Previous
              </Button>
            </Col>
          */}
          <Col md={12} className="mt-2 mb-3 pr-0 text-right">
            <Button
              color="primary"
              className={`${styles['btn_accent_width']} btn-accent my-3 float-right`}
              onClick={() => jobList()}
            >
              Submit
            </Button>
          </Col>
        </Row> :
        <Row>
          <Col md={12} className="mt-2 mb-3 pr-0 text-right">
            <Button
              color="primary"
              className={`${styles['btn_accent_width']} btn-accent float-right`}
              onClick={() => jobList()}
              >
              Save
            </Button>
          </Col>
        </Row>
      }
      { isFormOpen &&
          <GenericModal toggle={this.openGeofenceForm} isOpen={isFormOpen} heading="Add Geofence" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
            <GeoFenceFormSection
              toggle={this.openGeofenceForm}
              size="lg"
              associateNewGeofence
              addAndAssociateNewGeofence={this.addAndAssociateNewGeofence}
            />
          </GenericModal>
        }
      </section>
    );
  }
}



JobGeofenceFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isGeoFenceVisible: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  google: PropTypes.object.isRequired,
  loaded: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  geoFenceAll: PropTypes.array.isRequired,
  jobGeoFenceMappingDetail: PropTypes.object.isRequired,
  jobList: PropTypes.func,
  change: PropTypes.func,
};

JobGeofenceFormComponent.contextTypes = {
  router: PropTypes.object.isRequired,
};

const withReduxForm = reduxForm({
  form: 'JobGeofenceForm1',
  enableReinitialize: true,
})(JobGeofenceFormComponent);

const selector = formValueSelector('JobGeofenceForm1');

export default connect(
  state => {
    const newGeofence1 = selector(state, 'g_address');
    return  { newGeofence1 } ;
  }
)(withReduxForm);
