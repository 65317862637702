 /*import  GraniteError from 'lib/GraniteError'; */
class SurveyEntity {
  constructor(object={}){
    this.first_name = object.user.first_name || '';
    this.last_name = object.user.last_name || '';
    this.survey_name = object.response && object.response.template && object.response.template.template_data && object.response.template.template_data.label || '';
    this.date_time = object.create_date_display || '';
    this.status = object.response && object.response.action_code && object.response.action_code == 'SUCCESS' ? 'ACCEPTED' : object.response.action_code && object.response.action_code == 'FAILED' ? 'FLAGGED' : '';
    this.id = object.id
 }
}

export default SurveyEntity;


