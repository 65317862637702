import React from 'react';

import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import * as RouteDucks from 'ducks/routes';
import * as EmployeeDucks from 'ducks/employees/employee';

import { toast } from 'react-toastify';
import moment from 'moment'

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import KioskEmployessAuditTableComponent from 'components/employees/list/KioskEmployeesAuditTable';
import ViewActivityModal from 'components/employees/list/ViewActivityModal'

/**
 * KioskEmployeesAuditListPage -> KioskEmployeesAuditListSection
 *
 * Components:
 *    - {@link KioskEmployessAuditTableComponent}
 *
 * State:
 *    - employee list
 *
 * Actions:
 *    None
 */
class KioskEmployeesAuditListSection extends React.PureComponent {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleActivityModal = this.toggleActivityModal.bind(this);
    this.state = { isModalOpen: false, image: null, selectedItemId: null ,isViewActivityModalOpen:false,isActivityModalLoading:true,activityDate:null,activityLogs:{}};
    this.onSelectImage = this.onSelectImage.bind(this);
  }

  onSelectImage(item){
    const { location: { query } } = this.props;
    const pageSize = cookie.load("kiosk_employees_page_size");
    this.setState(({ selectedItem }) => ({ selectedItemId: item.id }));
    if(!item.verified){
      this.props.EmployeeDucks.postKioskAudits({ id: item.id, add: true })
        .then(() => {
          toast.success('Image is marked as valid now.');
          this.props.EmployeeDucks.getEmployeesAuditLogs({...query, page_size: pageSize })
            .then(() => this.setState(({ selectedItem }) => ({ selectedItemId: null })))
            .catch(() => this.setState(({ selectedItem }) => ({ selectedItemId: null })));
        })
        .catch(() => {
          toast.error('Unable to mark image as valid.');
          this.setState(({ selectedItem }) => ({ selectedItemId: null }));
        });
    }else{
      this.props.EmployeeDucks.postKioskAudits({ id: item.id, add: false })
        .then(() => {
          toast.success('Image is marked as invalid now.');
          this.props.EmployeeDucks.getEmployeesAuditLogs({...query, page_size: pageSize })
            .then(() => this.setState(({ selectedItem }) => ({ selectedItemId: null })))
            .catch(() => this.setState(({ selectedItem }) => ({ selectedItemId: null })));
        })
        .catch(() => {
          toast.error('Unable to mark image as invalid.');
          this.setState(({ selectedItem }) => ({ selectedItemId: null }));
        });
    }
  }

  toggle(item){
    this.setState(({ isModalOpen, image }) => ({ isModalOpen: !isModalOpen,  image: item }));
  }

  
  toggleActivityModal(status,data){

    this.setState({isViewActivityModalOpen:status})

    if(status)
    {
      const date=data?.create_date
      const empId=data?.user?.employee_id 
      const doc_id =data?.id || 0
      
      this.props.EmployeeDucks.getEmployeeActivityLogs({empId,date:moment(date).format("YYYY-MM-DD"),doc_id})
      .then(()=>{
        this.setState({isActivityModalLoading:false})
      })
      .catch((err)=>{
        const error =err?.non_field_errors?.[0] || 'Unable to fetch activity logs'
        toast.error(error);
        this.setState({isActivityModalLoading:false})
      })
    }else
    {
      this.setState({isActivityModalLoading:true})
    }
  }

  render(){
    const { isLoading, location, employeeList,activityLogsList } = this.props;
    const { selectedItemId,isViewActivityModalOpen,isActivityModalLoading,activityDate } = this.state;
   
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <KioskEmployessAuditTableComponent
          data={employeeList}
          location={location}
          toggle={this.toggle}
          onSelectImage={this.onSelectImage}
          selectedItemId={selectedItemId}
          toggleActivityModal={this.toggleActivityModal}
        />
        <PaginationComponent
          count={employeeList.count}
          location={location}
          cookieKey="kiosk_employees_page_size"
        />
        {
         isViewActivityModalOpen && <ViewActivityModal
         activityLogs={activityLogsList}
         dateDisplay={activityDate}
         isOpen ={isViewActivityModalOpen}
         toggle ={this.toggleActivityModal}
         isActivityModalLoading={isActivityModalLoading}
         />
        }
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  employeeList: EmployeeDucks.employeeList(state),
  activityLogsList :EmployeeDucks.activityLogsList(state)
});

const mapActionsToProps = dispatch => ({
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
});

KioskEmployeesAuditListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

KioskEmployeesAuditListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(KioskEmployeesAuditListSection);
