import React from 'react';

import connect from 'react-redux/lib/connect/connect';
import formValueSelector from 'redux-form/lib/formValueSelector';
import reduxForm from 'redux-form/lib/reduxForm';

import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';

import ButtonLoaderAtom from 'atoms/ButtonLoader';
import ButtonGroupAtom from 'atoms/ButtonGroup';

import ActivitySplitPunchInComponent from 'components/activities/activitySplitForm/PunchIn';
import ActivitySplitPunchOutComponent from 'components/activities/activitySplitForm/PunchOut';
import TaskComponent from 'components/activities/activitySplitForm/Task';
import ActivitySplitMealPeriodComponent from 'components/activities/activitySplitForm/MealPeriod';
import { SHIFT_VIEW_OPTIONS } from 'constants/activities';
import CommentsComponent from 'components/activities/activitySplitForm/Comments';

import GoToIcon  from './goTo.png';
import {ReactComponent as ClearIcon} from 'components/common/FilterForm/close.svg';
import AddIcon from './plusIcon.png';



import styles from './styles.module.scss';

class AllocateTaskPopupSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state={isOpen:true, showView: '1'};
    this.changePopupView=this.changePopupView.bind(this);
    this.scroll = this.scroll.bind(this);
  }

  componentDidUpdate(){
    this.scroll();
  }

  scroll(){
    if(this.props.savedId !==null){
      let newItem = document.getElementById(this.props.savedId);
      newItem && newItem.scrollIntoView();
    }
  }

  changePopupView(val){
    this.setState({showView : val});
  }

  render() {
    const { dateFormat , splitData, shiftData , timeFormat , timeFormat24Hrs , 
           activitySplitList, toggle , setPunchOutDate, timecardDecimalView, userTimecardFilters,
          hideTaskModal , goTo , submitSession , resetJobList, isSubmitting, shiftId, activitySplitsLoading} = this.props;
    const { showView } = this.state;
    const newdata = activitySplitList && activitySplitList.find((item) => item.activity_name == 'Shift' && item.id == splitData.id);
    return (
    <div>
      <Form noValidate>
        <div>
          <div>
            <h4 className = {`${styles['add-activities-heading']} d-inline-block mt-1 pl-4 mr-2 mb-0`}>Add Activities And Tasks</h4>
            <span onClick={() => goTo(`/activity/timecard/custom/list/${shiftId || shiftData.id}/split/add`,splitData, shiftData)}>
              <img className = {`${styles['goToIcon']} ml-1 mb-2`} src={GoToIcon} height="10px" width="10px" alt="Logo" />
            </span>
            <ClearIcon
              className={`${styles['clear-Icon']}`}
              height="13" width="13"
              onClick={() => hideTaskModal()}
            />
          </div>
          <p className = {`${styles['add-activities-sub-heading']} pl-4 pr-4 mb-0`}>Manage activities and tasks for a shift</p>
          <hr className={`${styles['horizontal-line']} bg-primary`}/>
          
          {((isSubmitting && showView === '1') || activitySplitsLoading) ?
          <div className="loadingActivities" role="status">
            <span>Loading...</span>
          </div>
          :
          <div>
            <div>
              <div className='d-flex pl-2 pr-2 mb-2'>
                { <small onClick={()=> resetJobList(splitData, shiftData, 'previous')} className={`${styles['previous-button']} link-name goToLink pt-1`}>Previous</small>}
                <small className={`${styles['shift-time']} ml-3 mr-3`}>Shift : {splitData.start_datetime_display} to {splitData.end_datetime_display}</small>
                { <small onClick={()=> resetJobList(splitData, shiftData, 'next')} className={`${styles['next-button']} link-name goToLink pt-1`}>Next</small>}
              </div>
            </div>
            <ButtonGroupAtom
              className={`${styles['timeline-shift-button']} toggleView pl-4 pr-4`}
              options={SHIFT_VIEW_OPTIONS}
              onToggle={this.changePopupView}
              active={this.state.showView}
            />
            {((splitData.id && shiftData.is_approved === false) || !splitData.id) && showView === '1' &&
              <div className={`${styles['button_group']} w-100 pl-4 pr-4 mt-3`}>
                <Button className={`mr-2`} onClick={() => toggle()}>Allocate Task</Button>
                <Button className={`mr-2`} onClick={this.props.toggleMealModal}>Add Meal Period</Button>
                <Button disabled={this.props.submitDisabled} onClick={() => this.props.submitManualSession(false)}>Submit <ButtonLoaderAtom active={submitSession}/></Button>
              </div>
            }
            <div className='pl-1 pr-1' style={{height:'calc(72vh)', overflowY: 'auto', overflowX: 'hidden'}}>
              {showView === '1' && splitData ?
                <div>                          
              <ActivitySplitPunchInComponent
                dateFormat={dateFormat}
                item={splitData.id ? splitData : null}
                timeFormat={timeFormat}
                timeFormat24Hrs={timeFormat24Hrs}
                editEntry={this.props.setPunchInDate}
                split={splitData.id}
                initialValues={{start_datetime : splitData.start_datetime }}
                disabled={((shiftData.is_approved === true) && splitData.id)}
                newStyle
                browserParseDateISO={this.props.browserParseDateISO}
                browserParseDate={this.props.browserParseDate}
                userTimezone={this.props.userTimezone}
              />
              <div className={styles['plus-icon-position']}>
                <img src={AddIcon} width="45" height="45" onClick={()=>this.props.toggleAddNewActivityModal({start_datetime : splitData.start_datetime , end_datetime : newdata.split_list.length ? newdata.split_list[0].start_datetime : splitData.end_datetime})}/>
              </div>
              {newdata && newdata.split_list.length ?
                newdata.split_list.map((item, index) => {
                  if (item.activity_code === 'MEAL-PERIOD') {
                    return (
                      <div>
                        <ActivitySplitMealPeriodComponent
                          key={item.id}
                          id={item.id}
                          dateFormat={dateFormat}
                          endDate={item.end_datetime}
                          item={item}
                          startDate={item.start_datetime}
                          timeFormat={timeFormat}
                          timeFormat24Hrs={timeFormat24Hrs}
                          editEntry={this.props.setActivitySplits}
                          removeEntry={this.props.toggleDelete}
                          approved={((shiftData.is_approved === true))}
                          savedId={this.props.savedId}
                          splitData = {splitData}
                          newStyle
                          updatable={this.updatable}
                          browserParseDateISO={this.props.browserParseDateISO}
                          browserParseDate={this.props.browserParseDate}
                userTimezone={this.props.userTimezone}

                        />
                        <div className={styles['plus-icon-position']}>
                          <img src={AddIcon} width="45" height="45" onClick={()=>this.props.toggleAddNewActivityModal({start_datetime :  item.end_datetime , end_datetime : newdata.split_list[index + 1] ? newdata.split_list[index + 1].start_datetime : splitData.end_datetime})}/>
                        </div>
                      </div>
                    );
                  }
                  else if(item.activity_code === 'TASK') {
                    return (
                      <div>
                        <TaskComponent
                          key={item.id}
                          index={item.id}
                          id={item.id}
                          dateFormat={dateFormat}
                          endDate={item.end_date}
                          item={item}
                          startDate={item.start_date}
                          timeFormat={timeFormat}
                          timecardDecimalView={timecardDecimalView}
                          timeFormat24Hrs={timeFormat24Hrs}
                          jobName={item.job.name}
                          taskName={item.task && item.task.name}
                          task={activitySplitList.find((i) => i.id === item.id)}
                          editTask={this.props.setActivitySplits}
                          removeTask = {this.props.toggleDelete}
                          isApproved = {"is_approved" in shiftData ? shiftData.is_approved : "false"}
                          editTaskEntry = {this.props.editTaskEntry}
                          savedId = {this.props.savedId}
                          splitData = {splitData}
                          userTimecardFilters={userTimecardFilters}
                          newStyle
                          browserParseDateISO={this.props.browserParseDateISO}
                          browserParseDate={this.props.browserParseDate}
                          userTimezone={this.props.userTimezone}
                        />
                        <div className={styles['plus-icon-position']}>
                          <img src={AddIcon} width="45" height="45" onClick={()=>this.props.toggleAddNewActivityModal({start_datetime :  item.end_datetime , end_datetime : newdata.split_list[index + 1] ? newdata.split_list[index + 1].start_datetime : splitData.end_datetime})}/>
                        </div>
                      </div>  
                    );
                  }
                  return null;
                }
                )
                : null }
                <ActivitySplitPunchOutComponent
                  dateFormat={dateFormat}
                  item={splitData.id ? splitData : null}
                  timeFormat={timeFormat}
                  approved={shiftData.is_approved}
                  // disabled={((shiftData.is_approved === 'true') && splitData.id)}
                  disabled={((shiftData.is_approved === true) && splitData.id)}
                  editEntry={setPunchOutDate}
                  newStyle
                  browserParseDateISO={this.props.browserParseDateISO}
                  browserParseDate={this.props.browserParseDate}
                userTimezone={this.props.userTimezone}

                />
              </div>
              :
              <CommentsComponent isSubmitting={this.props.isSubmitting} showTasksModal={this.props.showTasksModal} submitComment={this.props.submitManualSession} commentSubmitting={this.props.commentSubmitting} splitData ={splitData} shiftData = {this.props.shiftData}  data={splitData} newStyle saveValidity={this.props.saveValidity} feedbackResponse={this.props.feedbackResponse} feedbackResponseData={this.props.feedbackResponseData} dateFormatDisplay={this.props.dateFormatDisplay} dateFormat={dateFormat} timeFormat={this.props.timeFormat}/>}
              </div>
          </div>
          }
         </div>
      </Form>
    </div>
    );
  }
}


const AllocateTaskForm = reduxForm({
  form: 'AllocateTaskForm',
  enableReinitialize: true,
})(AllocateTaskPopupSection);

const selector = formValueSelector('AllocateTaskForm');

export default connect(
  state => {
    const selectedValueArray = selector(state, 'date');
    return {
      selectedValueArray,
    };
  }
)(AllocateTaskForm);