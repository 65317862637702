import React, {Component} from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Button, Form, FormText } from 'reactstrap';
import { Formik } from 'formik';

import ButtonLoaderAtom from 'atoms/ButtonLoader';

import {CardElement, injectStripe} from 'react-stripe-elements';

class PaymentMethodForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      isAdding: false,
      error : false ,
    }
  }

  submit(){
    const { stripe, profile, usecase } = this.props; 
    this.setState({ isAdding: true });
    usecase.saveCard(
      stripe,
      profile.company.id,
      profile.company.email,
      profile.company.name,
      profile.company.phone_no,
    )
    .then(() => {
      this.setState({ isAdding:  false });
    })
    .catch(() => {
      this.setState({ isAdding:  false });
      this.setState({error : true});
    });
  }

  render() {
    const { isAdding } = this.state;
    const { cardDetails } = this.props; 

    return (
      <div>
        <Row className="checkout">
          <Col>
            <Formik
              initialValues={cardDetails}
              onSubmit={this.submit}
              render={({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => 
               {
                return(
                  <Form onSubmit={handleSubmit}>
                    <div style={{minHeight: '3vh'}}>
                      <FormText color="danger">
                        {this.state.error ?
                          'Invalid Card Details'
                          :
                          null
                        }
                      </FormText>
                    </div>     
                    <CardElement />
                    <div className="mt-4 text-right">
                      <Button
                        type='submit'
                        color='primary'
                      >
                        Save <ButtonLoaderAtom active={isAdding} />
                      </Button>
                    </div>
                  </Form>
                );}}
              />
          </Col>
        </Row>
      </div>
    );
  }
}

export default injectStripe(PaymentMethodForm);