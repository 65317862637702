import React,{PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import EditableCellAtom from 'atoms/EditableCell';
import Card from 'reactstrap/lib/Card';
import CardTitle from 'reactstrap/lib/CardTitle';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';

import styles from './styles.module.scss';
import {ReactComponent as DeleteIcon} from './bin.svg';
/**
 * ActivitySplitFormPage -> ActivitySplitTimelineSection -> ActivitySplitMealPeriodComponent
 *
 * Props:
 *    None
 */
class ActivitySplitMealPeriodComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.updatable=this.updatable.bind(this);
    this.updateEntry= this.updateEntry.bind(this);
    this.state = { startDate : props.item && props.item.start_datetime , startTime :  props.item && moment(props.item.start_datetime).format('LT') ,
     endDate : props.item && props.item.end_datetime , endTime :  props.item && moment(props.item.end_datetime).format('LT')};
  }

  updateEntry(item, date, key){
    const { editEntry,browserParseDateISO } = this.props;
    const { startTime , endTime } = this.state;
    if(key == 'start'){
      this.setState({startDate : date});
      const updatedTime = moment(startTime, "h:mm:ss A").format("HH:mm:ss");
      const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      const updatedItem = {...item, start_datetime : updatedDateTime};
      // editEntry({ ...updatedItem, start_datetime: moment(updatedItem.start_datetime).toISOString()});
      editEntry({ ...updatedItem, start_datetime: browserParseDateISO(updatedItem.start_datetime, false), end_datetime : browserParseDateISO(item.end_datetime, false)});
    }
    else if(key == 'end'){
      this.setState({endDate : date});
      const updatedTime = moment(endTime, "h:mm:ss A").format("HH:mm:ss");
      const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      const updatedItem = {...item, end_datetime : updatedDateTime};
      // editEntry({ ...updatedItem, end_datetime: moment(updatedItem.end_datetime).toISOString()});
      editEntry({ ...updatedItem,start_datetime : browserParseDateISO(item.start_datetime, false), end_datetime: browserParseDateISO(updatedItem.end_datetime, false)});
    }

    
  }

  updatable(data , key, value){
    const { editEntry, browserParseDateISO } = this.props;
    const { endDate , startDate } = this.state;
    if(!value){
      return toast.error('Please enter time');
    }
    const updatedTime = moment(value, "h:mm:ss A").format("HH:mm:ss");
    let updatedDateTime, updatedItem;
    if(key == 'start_time'){
      updatedDateTime = moment(`${moment(startDate).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      // updatedItem = {...data, start_datetime : moment(updatedDateTime).toISOString()};
      updatedItem = {...data, start_datetime : browserParseDateISO(updatedDateTime, false),end_datetime : browserParseDateISO(data.end_datetime, false)};
      this.setState({startTime : value })
    }
    else if(key == 'end_time'){
      updatedDateTime = moment(`${moment(endDate).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      // updatedItem = {...data, end_datetime : moment(updatedDateTime).toISOString()};
      updatedItem = {...data, start_datetime : browserParseDateISO(data.start_datetime, false), end_datetime : browserParseDateISO(updatedDateTime, false)};
      this.setState({endTime : value })
    }
    editEntry(updatedItem);
  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime&& data.start_datetime.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }

  render(){
    const {
      dateFormat,
      item,
      timeFormat,
      timeFormat24Hrs,
      editEntry,
      removeEntry,
      approved,
      id,
      browserParseDate
    } = this.props;
    return (
      <div className='override_alignment'>
      {!this.props.newStyle && item ?
        <Card block className={`${styles.timelinePointer} mb-2 p-2 pb-3 activityCard`}>
          <span className={styles['timeline-badge__mealPeriod']}></span>
          <Row className={`${styles['block-display']}`}>
            <Col>
              <CardTitle className={`${styles['fw']} f16 mb-0 pt-2 pl-2`}>
                Meal Break
              </CardTitle>
            </Col>
            <Col>
              <Row className="f16 mb-0 pt-3 pl-2 pr-2">
                <Col md={6} className={`${styles['meal-width']} mr-3`}>
                  <Label className={`${styles['start-time-label']} mb-0`}> Start Time </Label>
                  <Flatpickr
                    className="form-control mb-2 mt-1"
                    value={item.start_datetime}
                    disabled = {approved === 'true'}
                    options={{
                      dateFormat: `${dateFormat}, ${timeFormat}`,
                      enableTime: true,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      /*noCalendar: !selectedShift.session_split,*/
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                          moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())

                      ],
                    }}
                    onChange={date => editEntry({ ...item, start_datetime: date[0]})}
                  />
                </Col>
                <Col md={6} className={`${styles['meal-width']} mr-1`}>
                  <Label className={`${styles['end-time-label']} mb-0`}> End Time </Label>
                  <Flatpickr
                    className="form-control mb-2 mt-1"
                    value={item.end_datetime}
                    disabled = {approved === 'true'}
                    options={{
                      dateFormat: `${dateFormat}, ${timeFormat}`,
                      enableTime: true,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      // noCalendar: !selectedShift.session_split,
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                          moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())

                      ],
                    }}
                    onChange={date => editEntry({ ...item, end_datetime: date[0]})}
                  />
                </Col>
                <Col className={`${styles['delete-icon-width']} pt-4 mt-2`}>
                {!(approved === 'true')&& <DeleteIcon
                className={`${styles['del-btn']} mb-0`}
                width={15} height={16}
                onClick={() => removeEntry(item)}
              >
                Remove
              </DeleteIcon>}
            </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        :
         <div id={id} className={item.id === this.props.savedId ? `${styles['mealDiv']}  ${styles['apply-shake-meal']}` : `${styles['mealDiv']} `}>
            <div className={`${styles['fw']} f16 mb-0 pt-2 pr-2 pl-2`}>
              Meal Break
              {!(approved === true)&& <DeleteIcon
                  className={`${styles['del-btn']}  ${styles['delete-meal']} mb-0`}
                  width={15} height={16}
                  onClick={() => removeEntry(item)}
                >
                Remove
              </DeleteIcon>}
            </div>
            <div className="d-flex pl-2 pr-2 w-100">
              <div className={`${styles['meal-job-date-label']} mr-4`}>
                <div className={`${styles['start-time-label']} mb-0 pt-2`}>
                  Start Date  
                </div>

                <span className='datePicker'>
                  <Flatpickr
                    className={`${styles['date-label']} form-control mb-0 mt-1`}
                    value={this.state.startDate}
                    options={{
                      dateFormat: `${dateFormat}`,
                      enableTime: false,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      /*noCalendar: !selectedShift.session_split,*/
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                          moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())

                      ],
                    }}
                    disabled = {approved === true}
                    onChange={(date)=> this.updateEntry(item, date[0], 'start')}
                  />
                </span>
              </div>
              <div className={`${styles['meal-job-date-label']}`}>
                <div className={`${styles['end-time-label']} mb-1 pt-2`}>Start Time</div>
                <span className='datePicker override_class edit-start-time'>
                  {/*<Flatpickr
                    className={`${styles['date-label']} form-control mb-0 mt-1`}
                    value={item.end_datetime}
                    options={{
                      dateFormat: `${dateFormat}, ${timeFormat}`,
                      enableTime: true,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      // noCalendar: !selectedShift.session_split,
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                         /* moment(date).isBefore(moment()),
                      ],
                    }}
                    disabled = {approved === true}
                    onChange={date => editEntry({ ...item, end_datetime: date[0] })}
                  /> */}
                  <EditableCellAtom
                    value={this.state.startTime}
                    valueKey="start_time"
                    details={{...item , start_datetime : moment(this.state.startDate).toISOString()}}
                    editable={true}
                    update={this.updatable}
                    validate={this.validate}
                  />
                </span>
              </div>
            </div>
            <div className="d-flex pl-2 pr-2 w-100">
              <div className={`${styles['meal-job-date-label']} mr-4`}>
                <div className={`${styles['start-time-label']} mb-0 pt-2`}>
                  End Date
                </div>

                <span className='datePicker'>
                  <Flatpickr
                    className={`${styles['date-label']} form-control mb-0 mt-1`}
                    value={this.state.endDate}
                    options={{
                      dateFormat: `${dateFormat}`,
                      enableTime: false,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      /*noCalendar: !selectedShift.session_split,*/
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                          moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                      ],
                    }}
                    disabled = {approved === true}
                    onChange={(date)=> this.updateEntry(item, date[0], 'end')}
                  />
                </span>
              </div>
              <div className={`${styles['meal-job-date-label']}`}>
                <div className={`${styles['end-time-label']} mb-1 pt-2`}>End Time</div>
                <span className='datePicker override_class edit-start-time'>
                  {/*<Flatpickr
                    className={`${styles['date-label']} form-control mb-0 mt-1`}
                    value={item.end_datetime}
                    options={{
                      dateFormat: `${dateFormat}, ${timeFormat}`,
                      enableTime: true,
                      minuteIncrement: 1,
                      time_24hr: timeFormat24Hrs,
                      // noCalendar: !selectedShift.session_split,
                      enable: [
                        // Enable the dates b/w payroll startDate & endDate
                        // Also no future date selection
                        (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                          moment(date).isBefore(moment(endDate)) &&*/
                          /*moment(date).isBefore(moment()),
                      ],
                    }}
                    disabled = {approved === true}
                    onChange={date => editEntry({ ...item, end_datetime: date[0] })}
                  />*/}
                  <EditableCellAtom
                    value={this.state.endTime}
                    valueKey="end_time"
                    details={{...item , end_datetime : moment(this.state.endDate).toISOString()}}
                    editable={true}
                    update={this.updatable}
                    validate={this.validate}
                  />
                </span>
              </div>
            </div>
          </div>
      }
      </div>
    );
  }
}
ActivitySplitMealPeriodComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editEntry: PropTypes.func.isRequired,
  removeEntry: PropTypes.func.isRequired,
};

export default ActivitySplitMealPeriodComponent;
