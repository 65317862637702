import React from 'react';
import StorageGateway from 'lib/storage-gateway';
import { server } from 'helpers/config';

class QBOsubmission extends React.Component {
    constructor(props) {
        super(props);
        const KONNECT_URL = server.konnectHost;
        this.company_id = StorageGateway.get('company');
        this.uniqueUuid =  StorageGateway.get('uniqueUuid');
        this.token = StorageGateway.get('Token');
        this.url = `${KONNECT_URL}/qb/auth/initiate`;
        this.reference = this.reference.bind(this);
        this.formRef = null;
        
    }

    reference() {
        this.formRef.submit();
    }

    componentDidMount() {
       this.reference();
    }

    render() {
        return (
            <form ref={(ref) => {this.formRef = ref}} style={{display: 'hidden'}} method="post" id="autoformqbo" action={this.url}>
                 <input type="hidden" name="plugin_name" value="QUICKBOOKS"/>
                 <input type="hidden" name="destination_platform" value="QBO"/>
                 <input type="hidden" name="profile_id" value={this.company_id}/>
                 <input type="hidden" name="profile_uuid" value={this.uniqueUuid}/>
                 <input type="hidden" name="token" value={this.token} />
            </form>
        );
    }
}


export default QBOsubmission;