import React, { Component } from "react";

import TempComponent from "forms/components/tempComponent";
import { PreviewComponent } from "forms/components/previewComponent";
import { ReactComponent as ClearIcon } from "components/common/FilterForm/close.svg";

import SectionLoaderAtom from "atoms/SectionLoader";

import styles from "forms/components/styles.module.scss";

class SilderPreviewComponent extends Component {
 
  render() {
    const { togglePreview, templateData } = this.props;
    return (
      <div className={`${styles["slider-preview"]}`}>
        <div>
          <div className={`${styles["slider-preview-heading"]} ml-2 mt-2`}>
            Preview
          </div>
          <ClearIcon
            className={`${styles["clear-Icon"]}`}
            height="13"
            width="13"
            onClick={()=>togglePreview([],false)}
          />
        </div>
        <hr className={`${styles["horizontal-line"]} bg-primary`} />

        <div className="d-flex justify-content-center">
          <div className={`${styles["slider-mobile-container"]}`}>
            <PreviewComponent title="class-based-iframe">
              {<TempComponent templateData={templateData} />}
            </PreviewComponent>
          </div>
        </div>
      </div>
    );
  }
}

export default SilderPreviewComponent;
