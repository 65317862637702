import React from 'react';

import PropTypes from 'prop-types';

import Async from 'react-select/lib/Async';
import Col from 'reactstrap/lib/Col';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormText from 'reactstrap/lib/FormText';
import Label from 'reactstrap/lib/Label';

import _ from 'lodash';

/**
 * AutoCompleteAtom
 *
 * - simpleValue: to return value only.
 * - getData: function which calls API to get options.
 * - isLoading: show spinner until API is calling.
 */
const AutoCompleteAtom = (props) => {
  const {
    input, layout, label, help, options, mapKeys,
    meta: { error, touched }, getData, onChange, isCache, ...rest
  } = props;

  const handleChange = (val) => {
    input.onChange(val);
    if (onChange) onChange(val);
    input.onBlur();
  };

  const getOptions = (data, callback) => {
    const inputData = data;
    if (inputData) {
      getData({ search: inputData })
        .then((response) => {
          callback(null, {
            options: response.value.results ? response.value.results.map(item => {
              if (Array.isArray(mapKeys.label)) {
                return ({ label: item[mapKeys.label[0]][mapKeys.label[1]], value: item[mapKeys.value] });
              }
              return ({ label: item[mapKeys.label], value: item[mapKeys.value] });
            }) : response.value.map(item => {
              if (Array.isArray(mapKeys.label)) {
                return ({ label: item[mapKeys.label[0]][mapKeys.label[1]], value: item[mapKeys.value] });
              }
              return ({ label: item[mapKeys.label], value: item[mapKeys.value] });
            }),
          });
        });
    } else {
      callback(null, {
        options: [],
      });
    }
  };

  const handleBlur = () => {
    input.onBlur();
  };

  return (
    <FormGroup
      row={Object.keys(layout).length > 0}
      color={touched && error ? 'danger' : ''}
    >
      {label && <Label {...layout.labelCol} for={rest.id}>{label}</Label>}
      {Object.keys(layout).length > 0
        ? (<Col {...layout.wrapperCol}>
          <Async
            {...input}
            {...rest}
            isLoading
            loadOptions={_.debounce(getOptions, 500)}
            menuContainerStyle={{ zIndex: 999 }}
            autoload
            autosize={false}
            onChange={val => handleChange(val)}
            onBlur={handleBlur}
            cache={false}
            filterOption={() => true}
            className="select-drop-color select-open select-hover remove-lastpass"
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </Col>)
        : (<span>
          <Async
            {...input}
            {...rest}
            isLoading
            loadOptions={_.debounce(getOptions, 500)}
            menuContainerStyle={{ zIndex: 999 }}
            autoload
            autosize={false}
            filterOption={() => true}
            onChange={val => handleChange(val)}
            onBlur={handleBlur}
            cache={false}
            className="select-drop-color select-open select-hover"
          />
          {rest.addonAfter}
          <FormText color="muted">{help}</FormText>
          <FormFeedback>{touched && error}</FormFeedback>
        </span>)
      }
    </FormGroup>
  );
};

AutoCompleteAtom.defaultProps = {
  layout: {},
};

AutoCompleteAtom.propTypes = {
  help: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object,
  mapKeys: PropTypes.object,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array,
  getData: PropTypes.func,
  onChange: PropTypes.func,
};

export default AutoCompleteAtom;
