import React from 'react';

import { Collapse } from "reactstrap";

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import styles from "./styles.module.scss";

class JobCostingSummary extends React.PureComponent {
  constructor(props){
    super(props);
  }

  render(){
    const { jobType , summaryData , tableView } = this.props;
    return (
      <div>
        <Row>
          {tableView !== 'timeAndMaterial' ?
            <Col md={4}>
              <Row>
                <Col md={6} className='pr-0'>
                  Total Budget (Hrs)
                </Col>
                <Col md={1}>
                  :
                </Col>
                <Col md={4}>
                  {summaryData && summaryData.total_budgeted_hours}
                </Col>
              </Row>
            </Col>
            :
            null
          }
          <Col md={4}>
            <Row>
              <Col md={6} className='pr-0'>
                Total Actual (Hrs)
              </Col>
              <Col md={1}>
                :
              </Col>
              <Col md={4}>
                 {summaryData && summaryData.total_actual_hours}
              </Col>
            </Row>
          </Col>
          {tableView == 'quotedRecurring' ?
            <Col md={4}>
              <Row>
                <Col md={6} className='pr-0'>
                  Total Variance (Hrs)
                </Col>
                <Col md={1}>
                  :
                </Col>
                <Col md={4}>
                  {summaryData && summaryData.total_variance_hours}
                </Col>
              </Row>
            </Col>
            :
            null
          }
          {tableView !== 'timeAndMaterial' ?
            <Col md={4}>
              <Row>
                <Col md={6} className='pr-0'>
                  Total Quoted Amount
                </Col>
                <Col md={1}>
                  :
                </Col>
                <Col md={4}>
                  {summaryData && summaryData.total_quoted_amount}
                </Col>
              </Row>  
            </Col>
            :
            null
          }
          <Col md={4}>
            <Row>
              <Col md={6} className='pr-0'>
                Total Labor Cost
              </Col>
              <Col md={1}>
                :
              </Col>
              <Col md={4}>
                {summaryData && summaryData.total_labor_cost}
              </Col>
            </Row>  
          </Col>
          <Col md={4}>
            <Row>
              <Col md={6} className='pr-0'>
                Labor Ratio
              </Col>
              <Col md={1}>
                :
              </Col>
              <Col md={4}>
                {summaryData && summaryData.total_labor_ratio}
              </Col>
            </Row>  
          </Col>
        </Row>
      </div>
    );
  }  
};

export default JobCostingSummary;
