import { createSelector } from 'reselect';

import createReducer from 'helpers/createReducer';

const ADD_MISSING_ENTRY = 'activities/missingEntry/ADD_MISSING_ENTRY';
const UPDATE_MISSING_ENTRY = 'activities/missingEntry/UPDATE_MISSING_ENTRY';
const REMOVE_MISSING_ENTRY = 'activities/missingEntry/REMOVE_MISSING_ENTRY';
const RESET_MISSING_ENTRY = 'activities/missingEntry/RESET_MISSING_ENTRY';


export function addMissingEntry(data) {
  return {
    type: ADD_MISSING_ENTRY,
    payload: data,
  };
}


export function updateMissingEntry(data) {
  return {
    type: UPDATE_MISSING_ENTRY,
    payload: data,
  };
}

export function removeMissingEntry(data) {
  return {
    type: REMOVE_MISSING_ENTRY,
    payload: data,
  };
}

export function resetMissingEntry() {
  return {
    type: RESET_MISSING_ENTRY,
  };
}


const defaultState = {
  list: [
    {
      start_datetime: null,
      end_datetime: null,
      index: 0,
    },
  ],
};

function ADD_MISSING_ENTRY_REDUCER(state, action) {
  const arr = [ ...state.list ];
  arr.push(action.payload);
  const entries = arr.map((item, index) => ({ ...item, index }));
  return Object.assign({}, state, {
    list: entries,
  });
}

function UPDATE_MISSING_ENTRY_REDUCER(state, action) {
  const entries = [ ...state.list ];
  for (let i = 0; i < entries.length; i += 1) {
    if (entries[i].index === action.payload.index) {
      entries[i] = action.payload;
    }
  }
  return Object.assign({}, state, {
    list: entries,
  });
}

function REMOVE_MISSING_ENTRY_REDUCER(state, action) {
  const entries = state.list.filter(item => item.index !== action.payload.index);
  return Object.assign({}, state, {
    list: entries,
  });
}

function RESET_MISSING_ENTRY_REDUCER(state, action) {
  return Object.assign({}, state, defaultState);
}

const handlers = {
  [ADD_MISSING_ENTRY]: ADD_MISSING_ENTRY_REDUCER,
  [UPDATE_MISSING_ENTRY]: UPDATE_MISSING_ENTRY_REDUCER,
  [REMOVE_MISSING_ENTRY]: REMOVE_MISSING_ENTRY_REDUCER,
  [RESET_MISSING_ENTRY]: RESET_MISSING_ENTRY_REDUCER,
};


const missingEntrySelector = state => state.activities.missingEntry;

export const missingEntryList = createSelector(
  missingEntrySelector,
  instance => instance.list,
);

export default createReducer(defaultState, handlers);
