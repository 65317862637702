import React from 'react';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';
import { toast } from 'react-toastify';

import * as ActivitySplitDucks from 'ducks/activities/activitySplit';

import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import closeimage from './Close-Cross.png';

import styles from './styles.module.scss';

class PtoTimecardDeleteComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit= this.submit.bind(this);
    this.state = {isLoading: true, ptoRequestList: []}
  }

  submit(){
      //return
    const {data, saveData,toggle} = this.props;
    saveData(data)
    .then(() => toggle());
  }
  
  componentDidMount() { 
    const {data} = this.props;
    this.props.ActivitySplitDucks.getPaidTimeOffRequest(data).then(res => {
      this.setState({ ptoRequestList: res.value.request_details, isLoading: false });
    }).catch(e => {
      this.setState({ ptoRequestList: [], isLoading: false });
      toast.error("Something went wrong!!");
    })
  }

  render() {
    const { isLoading, ptoRequestList } = this.state;
    const { isOpen, toggle, confirmdeleteLoader } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <ModalHeader>
          Confirm Delete
          <img src={closeimage} className={styles['close-icon']} height="14px" width="14px" alt="Close" onClick={() => toggle()} />
        </ModalHeader>
        {isLoading ? <div className='d-flex justify-content-center py-3'>Loading...</div>:<>
        <ModalBody>
          {ptoRequestList.length === 1 ? <h5 className='px-1'>Are you sure you want to delete this Paid Time Off ?</h5> :<>
            <h5 className='px-1'>Deleting this pto entry will also delete the following entries:</h5>
            <ul>
              {ptoRequestList.map(item => <li className='text-muted'>{item.datetime}</li>)}
            </ul>
          </>}
        </ModalBody>
        <ModalFooter>
          <Button color="default" className={styles['cancel-btn']} className="btn-width" onClick={toggle} >
            Cancel
          </Button>
          <Button type="submit" color="secondary" className="btn-width" onClick={() => this.submit()}>
             Yes <ButtonLoaderAtom active={confirmdeleteLoader} />
          </Button>
        </ModalFooter>
        </>}
      </Modal>
    );
  }
}

PtoTimecardDeleteComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

const mapActionsToProps = dispatch => ({
  ActivitySplitDucks: bindActionCreators(ActivitySplitDucks, dispatch),
});

export default connect(
  null,
  mapActionsToProps,
)(PtoTimecardDeleteComponent);
