import React from 'react';

import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { toast } from 'react-toastify';

import * as VendorDucks from 'ducks/vendors/vendor';
import * as RouteDucks from 'ducks/routes';

import SectionLoaderAtom from 'atoms/SectionLoader';
import PaginationComponent from 'components/common/Pagination';
import IncompleteSignUpTableComponent from 'components/vendors/incompleteSignUpList/Table';
import IncompleteSignUpResetPasswordFormModalComponent from 'components/vendors/incompleteSignUpList/ResetPasswordFormModal';
import ActivationLinkFormModalComponent from 'components/vendors/incompleteSignUpList/ActivationLinkFormModal';
import ActivationCodeModal from 'components/vendors/incompleteSignUpList/activationCodesModal';
import { MSGS } from 'constants/vendors';

/**
 * IncompleteSignUpListPage -> IncompleteSignUpListSection
 *
 * Components:
 *    - {@link VendorTableComponent}
 *
 * State:
 *    - vendorIncompleteSignUpList
 *
 * Actions:
 *    None
 */
class IncompleteSignUpListSection extends React.Component {
  constructor(props) {
    super(props);
    this.getActivateLink = this.getActivateLink.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.toggleActivateModal = this.toggleActivateModal.bind(this);
    this.toggleResetPasswordForm = this.toggleResetPasswordForm.bind(this);
    this.state = { isOpen: false, selectedId: {}, isShowActivateLinkModal: false , showModal: false, selectedCompany: {} };
    this.toggle = this.toggle.bind(this);
  }

  getActivateLink(vendorId) {
    const detail = {};
    detail.user_id = vendorId;
    return this.props.VendorDucks.postVendorsIncompleteSignUpActivateLink(detail)
      .then(() => {
        this.setState({ isShowActivateLinkModal: !this.state.isShowActivateLinkModal });
      });
  }

  resetPassword(data){
    return this.props.VendorDucks.postRequestVendorsIncompleteSignUpResetPassword(data)
      .then(() => {
        toast.success(MSGS.CHANGE_PASSWORD_SUCCESS);
        this.setState({ isOpen: !this.state.isOpen });
      });
  }

  toggleActivateModal() {
    this.setState({ isShowActivateLinkModal: !this.state.isShowActivateLinkModal });
  }

  toggleResetPasswordForm(data) {
    this.setState({ isOpen: !this.state.isOpen });
    data && this.setState({ selectedId: data.id });
  }

  toggle(company) {
    const { showModal } = this.state;
    if(!showModal){
      this.setState({ selectedCompany: company });
      this.props.VendorDucks.showOtp(company.id)
        .then(() => this.setState({ showModal: !showModal }));
    }
    else this.setState({ showModal: !showModal });
  }


  render() {
    const { isLoading, location, vendorIncompleteSignUpList, vendorActivateLink, otpInfo } = this.props;
    const { selectedId, isOpen, isShowActivateLinkModal, showModal } = this.state;

    if (isLoading) return <SectionLoaderAtom active />;
    return ( 
      <section>
        <div className='pl-3 pr-4'>
          <IncompleteSignUpTableComponent
            data={vendorIncompleteSignUpList}
            toggleResetPasswordForm={this.toggleResetPasswordForm}
            getActivateLink={this.getActivateLink}
            toggle = {this.toggle}
          />
        </div>
        <PaginationComponent
          count={vendorIncompleteSignUpList.count}
          location={location}
          cookieKey="incomplete_signup_page_size"
        />
        {isOpen &&
          <IncompleteSignUpResetPasswordFormModalComponent
            isOpen={isOpen}
            vendorId={selectedId}
            toggle={this.toggleResetPasswordForm}
            resetPassword={this.resetPassword}
          />
        }
        {isShowActivateLinkModal &&
          <ActivationLinkFormModalComponent
            isOpen={isShowActivateLinkModal}
            toggle={this.toggleActivateModal}
            initialValues={vendorActivateLink}
          />
        }
        {showModal &&
          <ActivationCodeModal
            isOpen={showModal}
            toggle={this.toggle}
            otpInfo={otpInfo}
            data={this.state.selectedCompany}
          />
        }
       
      </section>  
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  vendorIncompleteSignUpList: VendorDucks.vendorIncompleteSignUpList(state),
  vendorActivateLink: VendorDucks.vendorActivateLink(state),
  otpInfo: VendorDucks.otpInfo(state),
});

const mapActionsToProps = dispatch => ({
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
});

IncompleteSignUpListSection.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  vendorIncompleteSignUpList: PropTypes.object.isRequired,
  vendorActivateLink: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(IncompleteSignUpListSection);


