import React from 'react';

import PropTypes from 'prop-types';

import { Editor } from "@tinymce/tinymce-react";

class EditorAtom extends React.PureComponent {
  constructor(props){
    super(props);
  }

  render() {
    const {onChange , handleBlur } = this.props;
    return (
      <label className="checkmarkcontainer mb-0">Mandatory                                           
        <input 
          type="checkbox"
          onChange={(val)=>onChange(val.target.checked)}
          checked={this.props.value == 'true' ? true : false}
        />
        <span className="checkmarkblue"></span>
      </label>
    );
  }
}


export default EditorAtom;
