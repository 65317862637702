import React from 'react';
import cookie from 'react-cookies';
import connect from 'react-redux/lib/connect/connect';
import queryString from 'query-string';

import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import bindActionCreators from 'redux/lib/bindActionCreators';

import { NAV } from 'constants/surveys';
import TwoColumnLayout from 'layouts/TwoColumn';
import { toast } from 'react-toastify';
import moment from 'moment';

import listUseCase from 'surveys/usecases/surveyListUsecase';
import SurveyAPIGateway from 'surveys/gateways/survey';
import SectionLoaderAtom from 'atoms/SectionLoader';

import SurveyListComponent from 'surveys/components/surveyList';
import SurveyFilterForm from 'surveys/components/filterForm'
import GenericModal from 'components/common/GenericModal';
import PaginationComponent from 'components/common/Pagination';

import MultiSelectComponent from 'components/common/MultiSelectComponent';
import SingleSelectComponent from 'components/common/SingleSelectComponent';
import FreeSelectComponent from 'components/common/FreeSelectComponent';

import DetailedSurveyForm from 'surveys/components/detailForm';


class SurveyListPage extends React.PureComponent {
  
  constructor(props) {
    super(props);
    this.state = { departmentList: null, teamList: null, surveyList : null, isLoading: false , isSurveyModal: false , surveyListCount : 0 , surveyDetail : null, isModalLoading : false, roleList : null, isShowSurvey:false, surveyType: [{value: 'others'}]}
    const surveyAPIGateway = new SurveyAPIGateway();
    this.usecase = new listUseCase(surveyAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.listenListUsecase = this.listenListUsecase.bind(this);
    this.getSurveyDetails = this.getSurveyDetails.bind(this);
    this.goTo=this.goTo.bind(this);
    this.getFilteredList = this.getFilteredList.bind(this);
  }

  componentWillMount(){
    const { location: { search, pathname } , history } = this.props;
    const query = queryString.parse(search);
    Promise.all([
      this.usecase.getDepartmentList({paginate : false}),
      this.usecase.getTeamList({
        paginate: false,
        department_id: query.department_id,
      }),
      this.loadData()
      /*this.usecase.getAccountRoles();*/
    ]);
  }

  componentWillReceiveProps(nextProps, prevProps){
    const { location: { search: prevSearch } } = this.props;
    const { location: { search: nextSearch } } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);

    if(prevQuery.department_id !== nextQuery.department_id){
      this.usecase.getTeamList({ department_id: nextQuery.department_id , paginate : false });
    }

    if(prevQuery !== nextQuery){
      this.fetchData(nextQuery);
      this.listenListUsecase()
    }
  }

 
  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  loadData(){
    const { history, location: { search, pathname } } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("survey_list_size") || 10;
    if (
      !('action_code' in query)
      || !('create_date_from' in query)
      || !('create_date_to' in query)
      ) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          action_code: 'SUCCESS',
          create_date_from : moment().startOf('month').format('YYYY-MM-DD'),
          create_date_to: moment().endOf('month').format('YYYY-MM-DD'),
          page_size: pageSize,
          page: query.page || 1,
        }),
      });
    }
    else{
      this.fetchData(query)
    }
  }

  fetchData(search){
    const pageSize = cookie.load("survey_list_size") || 10;
    this.listenListUsecase();
    let survey_type_cookie = cookie.load('survey_type_dashboard')    
    let survey_type_list = survey_type_cookie ? survey_type_cookie.map(i=> i.value) : this.state.surveyType.map(i => i.value)    
    this.usecase.getSurveysList({...search, page_size: pageSize, list_survey_type: JSON.stringify(survey_type_list)});
  }

  getFilteredList(survey_type){
    this.setState({surveyType: survey_type})
    cookie.save('survey_type_dashboard', survey_type)
    let survey_type_cookie = cookie.load('survey_type_dashboard')    
    let survey_type_list = survey_type_cookie ? survey_type_cookie.map(i=> i.value) : survey_type.map(i => i.value)
    const pageSize = cookie.load("survey_list_size") || 10;
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.listenListUsecase();
    this.usecase.getSurveysList({...query, page_size: pageSize, list_survey_type: JSON.stringify(survey_type_list)});
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'SURVEY_LIST':
          this.updateState('surveyList', event.data.results);
          this.updateState('surveyListCount', event.data.count);
          break;
        case 'SHOW_LOADER':
          this.updateState('isLoading', true);
          break;
        case 'HIDE_LOADER':
          this.updateState('isLoading', false);
          break;
        case 'SURVEY_DETAILS':
          this.updateState('surveyDetail', event.data);
          break;
        case 'GET_ROLES_SUCCESS':
          this.updateState('roleList', event.data);
          break;
        case 'SHOW_MODAL_LOADER':
          this.updateState('isModalLoading', true);
          break;
        case 'HIDE_MODAL_LOADER':
          this.updateState('isModalLoading', false);
          break;
        case 'DEPARTMENT_LIST':
            this.updateState('departmentList', event.departmentList);
          break;
        case 'TEAM_LIST':
          this.updateState('teamList', event.teamList);
          break;
        default:
          console.log('Sorry, we are not handling this');
      }
    });
  }

  getSurveyDetails(id){
    const { isSurveyModal } = this.state;
    if(!isSurveyModal){
      this.usecase.getSurveyDetail(id);
    }
    else{
      this.updateState('surveyDetail', null)
    }
    
    this.setState({isSurveyModal : !isSurveyModal})
  }

  goTo(url){
    const { history, location: { search, pathname } } = this.props;
    history.push(url);

  }

  render() {
    const { dateFormat , location } = this.props;
    const { surveyList, isLoading , isSurveyModal , surveyListCount , surveyDetail, roleList , departmentList , teamList } = this.state;
    const { location: { search } } = this.props;
    const query = queryString.parse(search);    
    return (
      <div>
        <div>
          <TwoColumnLayout
            navInfo={NAV.SurveyListPage}
            fetchData={() => this.loadData()}
            goTo={this.goTo}
          >
            <SurveyFilterForm
              dateFormat={dateFormat}
              location={location}
              departmentList={departmentList}
              teamList={teamList}
              initialValues={{
                create_date_from : query.create_date_from ? moment(query.create_date_from).toISOString() : moment().startOf('month').toISOString(),
                create_date_to : query.create_date_to ? moment(query.create_date_to).toISOString() :  moment().endOf('month').toISOString(),
                action_code : query.action_code ? query.action_code : 'SUCCESS',
              }}
              roleList={roleList}
              getFilteredList={this.getFilteredList}
              surveyType={this.state.surveyType}
            />
            <div>
              <SurveyListComponent
                list={surveyList}
                isLoading={isLoading}
                isSurveyModal={this.state.isSurveyModal}
                getSurveyDetails={this.getSurveyDetails}

              />
              {surveyListCount && !isLoading ?
                <PaginationComponent
                  count={surveyListCount}
                  location={location}
                  cookieKey="survey_list_size"
                  history={this.props.history}
                /> : null}
            </div>
            { isSurveyModal &&
              <GenericModal toggle={this.getSurveyDetails} isOpen={isSurveyModal} heading="Surveys" style={{maxWidth : '800px'}} headStyle={{borderBottom: '1px solid #09819A'}}>
                <DetailedSurveyForm
                  data = {surveyDetail && surveyDetail.results}
                  isLoading={this.state.isModalLoading}
                  date={surveyDetail && surveyDetail.date}
                  label = {surveyDetail && surveyDetail.surveyName}
                  name = {surveyDetail && surveyDetail.empName}
                  dateFormat={this.props.dateFormat}
                  timeFormat={this.props.timeFormat}
                  timeFormat24Hrs = {this.props.timeFormat24Hrs}
                  slider="true"
                />
              </GenericModal>
            }
          </TwoColumnLayout>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  userExtraData: UserDucks.userExtraData(state),
  isOrganizationVisible: UserDucks.isOrganizationVisible(state),
  companyPermissions: UserDucks.companyPermissions(state),
  isSubCompany: UserDucks.isSubCompany(state),
  dateFormat: BasicSettingDucks.dateFormat(state),
  timeFormat: BasicSettingDucks.timeFormat(state),
  timeFormat24Hrs: BasicSettingDucks.timeFormat24Hrs(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
  BasicSettingDucks: bindActionCreators(BasicSettingDucks, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyListPage);