import React from "react";
import Table from "reactstrap/lib/Table";
import { toast } from "react-toastify";

import Link from "react-router-dom/Link";
import queryString from "query-string";

import PreviewIcon from "forms/assets/Preview.png";

import SectionLoaderAtom from "atoms/SectionLoader";
import UsernameCircle from "atoms/UsernameCircle/UsernameCircle";

import styles from "forms/components/styles.module.scss";

import LoadCountOfResponsesComponent from "forms/components/loadCOR";

class SystemFormsListComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  setSearchParameters(id, item = {}) {
    const {
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);

    return queryString.stringify({
      parent: `${pathname}?${queryString.stringify({
        ...query,
      })}`,
      form_id: id,
      form_name: item?.name,
    });
  }

  render() {
    const { list, isLoading, handlePreview, history } = this.props;
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <div className={`ml-3 mr-3 pt-0 `}>
        <Table
          responsive
          hover
          className={`${styles["table-style"]} table-form`}
        >
          <thead className={styles["forms-Header-col"]}>
            <tr>
              <th className="pl-3">Name</th>
              <th className="text-center">Responses</th>
              <th className="text-center">Assigned To</th>
              <th className="">Created by</th>
              <th className="text-center">Status</th>
              <th className="text-center">Date Created</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {list && list.length ? (
              list.map((item) => (
                <tr
                  key={item.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push({
                      pathname: `/forms/${item.id}/responces`,
                      search: this.setSearchParameters(item.id, item),
                    });
                  }}
                >
                  <td
                    title={item.name}
                    className="pl-3 pr-3 pt-3"
                    style={{ maxWidth: " 120px", minWidth: "80px" }}
                  >
                    <span>{item.name !== "" ? item.name : ""}</span>
                  </td>
                  <td
                    title={item.count_of_responses}
                    className="text-center pt-3"
                  >
                    <LoadCountOfResponsesComponent formId={item.id} />
                  </td>
                  <td title={item.assigned_to} className="text-center pt-3">
                    {item.assigned_to}
                  </td>
                  <td title={item.created_by} className="text-center">
                    {item.created_by && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <div>
                          <UsernameCircle name={item.created_by} />
                        </div>
                        {item.created_by}
                      </div>
                    )}
                  </td>
                  <td title={item.status} className="text-center pt-3">{`${
                    item.status ? "Active" : "Inactive"
                  }`}</td>
                  <td title={item.date_created} className="text-center pt-3">
                    {item.date_created}
                  </td>
                  <td className=" text-center mt-1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        height="25"
                        width="25"
                        alt="preview icon"
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ selectedRowID: item.id });
                          handlePreview(item?.template_data ?? [], true);
                        }}
                        className="cursor-pointer"
                        title="Preview"
                        src={PreviewIcon}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="text-center p-2" colSpan="8">
                  No Records Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default SystemFormsListComponent;
