import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_BUSINESS_ADMIN = 'businessAdmins/businessAdmin/GET_BUSINESS_ADMIN';
const GET_BUSINESS_ADMIN_FULFILLED = 'businessAdmins/businessAdmin/GET_BUSINESS_ADMIN_FULFILLED';
const PUT_BUSINESS_ADMIN = 'businessAdmins/businessAdmin/PUT_BUSINESS_ADMIN';
const PUT_BUSINESS_ADMIN_FULFILLED = 'businessAdmins/businessAdmin/PUT_BUSINESS_ADMIN_FULFILLED';


export function getBusinessAdmin(params) {
  return {
    type: GET_BUSINESS_ADMIN,
    payload: axios.get(URLS.BUSINESS_ADMIN),
  };
}

export function putBusinessAdmin(data) {
  return {
    type: PUT_BUSINESS_ADMIN,
    payload: axios.put(URLS.BUSINESS_ADMIN, data),
  };
}


const defaultState = {
  detail: {},
};

function GET_BUSINESS_ADMIN_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_BUSINESS_ADMIN_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

const handlers = {
  [GET_BUSINESS_ADMIN_FULFILLED]: GET_BUSINESS_ADMIN_FULFILLED_REDUCER,
  [PUT_BUSINESS_ADMIN_FULFILLED]: PUT_BUSINESS_ADMIN_FULFILLED_REDUCER,
};


const businessAdminSelector = state => state.businessAdmins.businessAdmin;

export const businessAdminDetail = createSelector(
  businessAdminSelector,
  instance => instance.detail,
);

export default createReducer(defaultState, handlers);
