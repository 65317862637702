import React from 'react';

import { GoogleApiWrapper } from 'google-maps-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/connect/connect';
import bindActionCreators from 'redux/lib/bindActionCreators';

import {Elements, StripeProvider} from 'react-stripe-elements';
import { server } from 'helpers/config';

import * as EmployeeDucks from 'ducks/employees/employee';
import * as BasicSettingDucks from 'ducks/vendors/basicSetting';
import * as UserDucks from 'ducks/accounts/user';
import * as RouteDucks from 'ducks/routes';
import * as InfoDucks from 'ducks/info/info';
import * as OnboardDucks from 'ducks/vendors/onboard';
import * as ActivityTrackDucks from 'ducks/activities/activityTrack';
import * as RoleDucks from 'ducks/vendors/role';
import * as VendorDucks from 'ducks/vendors/vendor';
import * as PaymentDucks from 'ducks/vendors/payment';
import * as GeoFenceDucks from 'ducks/geoFences/geoFence';

import SectionLoaderAtom from 'atoms/SectionLoader';
import DashboardOnboardGridComponent from 'components/generics/dashboardOnboard/Grid';
import DashboardOnboardFormModalComponent from 'components/generics/dashboardOnboard/FormModal';
import DashboardTrackingFormModalComponent from 'components/generics/dashboardOnboard/TrackingFormModal';
import DashboardOnboardPopUpModalComponent from 'components/generics/dashboardOnboard/PopUpModal';
import EmployeeFormSection from 'sections/employees/form/Form';
import ModalComponent from 'components/common/GenericModal';
import AddCardForm from 'payments/paymentMethods/components/addCard';


const { REACT_APP_GOOGLE_API_KEY_BASE, REACT_APP_GOOGLE_API_VERSION , REACT_APP_STRIPE_API_KEY_BASE} = process.env;

const GeocodingOption = server.geocodingOption;

class DashboardOnboardListSection extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleVideoModal = this.toggleVideoModal.bind(this);
    this.getLocationAddress = this.getLocationAddress.bind(this);
    this.update = this.update.bind(this);
    this.sendSms = this.sendSms.bind(this);
    this.toggleInfoWindow = this.toggleInfoWindow.bind(this);
    this.toggleTracking = this.toggleTracking.bind(this);
    this.state={
      isOpen:false ,
      isTrackingOpen: false,
      mapLoading:false,
      isPopUpOpen: false,
      isEmployeeModalOpen: false,
      createdEmployee:{},
      isAddCardModalOpen: false,
    }
    this.handleMarkerClick = this.handleMarkerClick.bind(this);
    this.getOtp = this.getOtp.bind(this);
    this.toggleAddCardModal = this.toggleAddCardModal.bind(this);
    this.saveCard = this.saveCard.bind(this);
    this.updateTutorial = this.updateTutorial.bind(this);
    this.getAddressForMarker = this.getAddressForMarker.bind(this);
  }

  componentWillMount(){
    this.props.RoleDucks.getAllRoles({ paginate: false })
  }

  getLocationAddress(marker) {
    if(!marker.address){
      return this.props.ActivityTrackDucks.getActivityLocationAddress(marker , GeocodingOption)
        .then(() => {
          const { selectedMarkerAddress } = this.props;
          this.props.ActivityTrackDucks.setOnboardingSessionActivityLocationAddress({
            ...marker,
            address: selectedMarkerAddress,
          });
        })
    }
    return null;
  }

  getAddressForMarker(data){
    return this.props.ActivityTrackDucks.getActivityLocationAddress(data);
  }

  handleMarkerClick(marker) {
    // If location address already exists then just toggle
    return this.props.ActivityTrackDucks.toggleOnboardInfoWindow(marker);
  }

  toggleInfoWindow(marker) {
    return this.props.ActivityTrackDucks.toggleOnboardInfoWindow(marker);
  }
  
  toggle(){
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen});
  }
  
  togglePopUp(){
    const { isPopUpOpen } = this.state;
    this.setState({ isPopUpOpen: !isPopUpOpen});
  }
  

  toggleTracking(){
    const { isTrackingOpen } = this.state;
    const { employeeAll} = this.props;
    let empID=null
    if(employeeAll && employeeAll[0]){
      empID = employeeAll[0].id;
    }
    if(!isTrackingOpen){
      this.setState({isTrackingOpen: !isTrackingOpen ,  mapLoading: true })
      this.props.ActivityTrackDucks.getOnboardActivityTracks({employee_id:empID})
        .then(() => {
          const { activityTrackList } = this.props;
          if(activityTrackList.tracking && activityTrackList.tracking.length){ 
            const markers = activityTrackList.tracking.map((item) =>({...item,
              position: {lng: item.location.coordinates[0], lat: item.location.coordinates[1]},
              showInfo: false,
              shape_type: "Point",
              lat_long: item.location,
            }
            ));  
            Promise.all(markers.map((item) => 
              (this.props.ActivityTrackDucks.getActivityLocationAddress(item, GeocodingOption)
                .then((res) => {
                  const { selectedMarkerAddress } = this.props;
                  this.props.ActivityTrackDucks.setOnboardingSessionActivityLocationAddress({
                    ...item,
                    address: selectedMarkerAddress,
                  });
                })
              )
            ))
              .then(() => {
                this.setState({ mapLoading:  false })
              })
          }
          else this.setState({ mapLoading: false})
        }) 
    }
    else {
      this.setState({isTrackingOpen: !isTrackingOpen, mapLoading: false });
    }
  }
  
  toggleVideoModal(data){
    const { isEmployeeModalOpen } = this.state;
    this.setState({
      isEmployeeModalOpen: !isEmployeeModalOpen,
      createdEmployee:data
    });
  }

  updateTutorial(){
    const { isPopUpOpen } = this.state;
    this.props.OnboardDucks.getTutorial()
      .then(() => {
        const { tutorialDetail } = this.props
        if(tutorialDetail.view_tracking){
          this.setState({isPopUpOpen : !isPopUpOpen});
        }
        else this.props.OnboardDucks.postTutorial({tutorial_completed:"True"})
          .then(() => {
            this.props.UserDucks.getProfileUser();
            this.props.EmployeeDucks.getStats()
            this.props.OnboardDucks.getTutorial()
          });
      });
  }

  toggleAddCardModal(){
    const { isAddCardModalOpen } = this.state;
    this.setState({ isAddCardModalOpen: !isAddCardModalOpen });
  }
  
  update(data,screen){
    const { isEmployeeModalOpen, isTrackingOpen, isOpen } = this.state;

    if(screen===1){
      this.props.OnboardDucks.postTutorial({employee_management:"True"})
        .then(() => {
          this.setState({isEmployeeModalOpen:!isEmployeeModalOpen})
          this.props.OnboardDucks.getTutorial()
        });
    }
    else if(screen===2){
      this.props.OnboardDucks.postTutorial({app_download:"True"})
        .then(() => {
          this.setState({isOpen:!isOpen})
          this.props.OnboardDucks.getTutorial()
        }); 
    }
    else if(screen===3){
      this.props.OnboardDucks.postTutorial({view_tracking:"True"})
        .then(() => {
          this.setState({isTrackingOpen:!isTrackingOpen})
          this.props.OnboardDucks.getTutorial()
        }); 
    }
    else this.props.OnboardDucks.postTutorial({tutorial_completed:"True"})
      .then(() => {
        this.props.UserDucks.getProfileUser();
        this.props.EmployeeDucks.getStats()
        this.props.OnboardDucks.getTutorial()
      });
  } 
  
  sendSms(data){
    const phoneNumber = `${data.phone_ext}${data.phone_no}`;
    
    return this.props.OnboardDucks.postSendSms({phn_number:phoneNumber})
      .then(() => {
        this.props.OnboardDucks.getTutorial()
        toast.success("SMS succesfully sent")
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  getOtp(){
    const { tutorialDetail } = this.props;    
    return this.props.EmployeeDucks.resendLink(tutorialDetail.employee.id);    
  }

  async saveCard(stripe){
    const { userCompany } = this.props;
    let response = await stripe.createSource({
      type: 'card',
    });
    const data = {
      email: userCompany.email,
      name: userCompany.name,
      phone: userCompany.phone_no,
      source_id: response.source.id,
    };
    return this.props.PaymentDucks.saveCard(data, userCompany.id)
      .then(() => this.toggleAddCardModal())
      .then(() => toast.success('Your card has been successfully added.'))
      .then(() => this.updateTutorial())
      .catch((err) => {
        toast.error('Invalid card details');
      });
  }

  render() {
    const { 
      isLoading,
      location,
      google,
      profile,
      tutorialDetail,
      activityTrackList,
      basicSettingDetail,
      employeeDetail,
      employeeAll,
    } = this.props;

    const { isOpen, isTrackingOpen, isAddCardModalOpen,
      isEmployeeModalOpen, createdEmployee, mapLoading, isPopUpOpen } = this.state;
 
    if (isLoading) return <SectionLoaderAtom active />;
    return (
      <section>
        <DashboardOnboardGridComponent
          location={location}
          toggle={this.toggle}
          toggleVideoModal={this.toggleVideoModal}
          toggleTracking={this.toggleTracking}
          toggleAddCardModal = {this.toggleAddCardModal}
          update={this.update}
          tutorialDetail={tutorialDetail}
          isPopUpOpen={isPopUpOpen}
          getOtp={this.getOtp}
          updateTutorial={this.updateTutorial}
          extraData = {this.props.userExtraData}
          customerDetail = {this.props.customerDetail}
          onboardDetail = { this.props.onboardDetail}
        />
        {isOpen &&
          <DashboardOnboardFormModalComponent 
            isOpen={isOpen}
            toggle={this.toggle}
            initialValues={{ phone_ext:profile && profile.company && profile.company.phone_ext }}
            update={this.update}
            sendSms={this.sendSms}
          />
        }
        {isPopUpOpen &&
          <DashboardOnboardPopUpModalComponent 
            isOpen={isPopUpOpen}
            toggle={this.togglePopUp}
            update={this.update}
            tutorialDetail={tutorialDetail}
          />
        }
        {isTrackingOpen &&
          <DashboardTrackingFormModalComponent 
            isOpen={isTrackingOpen}
            toggleTracking={this.toggleTracking}
            google={google}
            profile={profile}
            update={this.update}
            createdEmployee={createdEmployee}
            activityTrackList={activityTrackList}
            onMarkerClick={this.handleMarkerClick}
            basicSettingDetail={basicSettingDetail}
            employeeDetail={employeeDetail}
            employeeAll={employeeAll}
            toggleInfoWindow={this.toggleInfoWindow}
            getLocationAddress={this.getLocationAddress}
            mapLoading={mapLoading}
            getAddressForMarker={this.getAddressForMarker}
          />
        }
        { isEmployeeModalOpen &&
          <EmployeeFormSection onboarding toggle={this.toggleVideoModal} isOpen={isEmployeeModalOpen}  />
        }

        {isAddCardModalOpen &&
          <ModalComponent
            isOpen={isAddCardModalOpen}
            heading="Add Your Card"
            size="md"
          >
            <StripeProvider apiKey= {`${REACT_APP_STRIPE_API_KEY_BASE}`}>
              <Elements>
                <AddCardForm
                  saveCard={this.saveCard}
                  updateTutorial={this.updateTutorial}
                />
              </Elements>
            </StripeProvider>
          </ModalComponent>
        }
      </section>
    );
  }
}

const mapStateToProps = state => ({
  location: RouteDucks.location(state),
  profile: UserDucks.profile(state),
  infoList: InfoDucks.infoList(state),
  tutorialDetail:OnboardDucks.tutorialDetail(state),
  activityTrackList: ActivityTrackDucks.activityTrackList(state),
  basicSettingDetail: BasicSettingDucks.basicSettingDetail(state),
  employeeDetail: EmployeeDucks.employeeDetail(state),
  employeeAll: EmployeeDucks.employeeAll(state),
  selectedMarkerAddress: ActivityTrackDucks.selectedMarkerAddress(state),
  userCompany: UserDucks.userCompany(state),
  userExtraData: UserDucks.userExtraData(state),
  customerDetail: PaymentDucks.customerDetail(state),
  onboardDetail : PaymentDucks.onboardDetail(state),
});

const mapActionsToProps = dispatch => ({
  UserDucks: bindActionCreators(UserDucks, dispatch),
  RoleDucks: bindActionCreators(RoleDucks, dispatch),
  EmployeeDucks: bindActionCreators(EmployeeDucks, dispatch),
  InfoDucks: bindActionCreators(InfoDucks, dispatch),
  OnboardDucks:bindActionCreators(OnboardDucks, dispatch),
  ActivityTrackDucks: bindActionCreators(ActivityTrackDucks, dispatch),
  VendorDucks: bindActionCreators(VendorDucks, dispatch),
  PaymentDucks: bindActionCreators(PaymentDucks, dispatch),
});

DashboardOnboardListSection.propTypes = {
  location: PropTypes.object.isRequired,
};

DashboardOnboardListSection.contextTypes = {
  router: PropTypes.object.isRequired,
};



const connectReactToGoogleApi = connect(
  mapStateToProps,
  mapActionsToProps,
)(DashboardOnboardListSection);

export default GoogleApiWrapper({
  apiKey: REACT_APP_GOOGLE_API_KEY_BASE,
  libraries: ['places', 'drawing'],
  version: REACT_APP_GOOGLE_API_VERSION,
})(connectReactToGoogleApi);