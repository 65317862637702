import React from 'react';

import PropTypes from 'prop-types';
import queryString from 'query-string';
import connect from 'react-redux/lib/connect/connect';

import * as RouteDucks from 'ducks/routes';
import * as DailyTimecardDucks from 'ducks/timecards/dailyTimecard';
import * as ActivitySplitDucks from 'ducks/activities/activitySplit';

import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
/**
 * ActivitySplitListPage -> ActivitySplitFilterSection
 *
 * Components:
 *    - Add Missing Activity Link
 *
 * State:
 *    None
 *
 * Actions:
 *    None
 */
class ActivitySplitFilterSection extends React.Component {
  constructor(props){
    super(props);
    this.goTo = this.goTo.bind(this);
    this.state = { isOpen: false, isModalLoading: false};
  }

  goTo(url, view) {
    const { location: { query } } = this.props;
    const { router: { history } } = this.context;
    history.push({
      pathname: url,
      search: queryString.stringify({
        employee: query.employee_id,
        view: view,
        parent: JSON.stringify(query),
      }),
    });
  }

  render() {
    const { router: { route: { match: { params } } } } = this.context;
    const { location : { pathname }, activitySplitList, dailyTimecardDetail} = this.props;

    const shift = activitySplitList && activitySplitList.filter(item => item.activity_code === 'PUNCH-IN')[0];
    return (
      <Row className="filter ml-3 mr-4">
        <Col md={6} className="px-0"> &nbsp;</Col>
        <Col md={6} className="px-0">
          <section className="float-right">
            {pathname.indexOf('custom') > -1 && shift && !dailyTimecardDetail.is_approved &&
              <div>
                <Button
                  className="mr-0"
                  color="accent"
                  onClick={() => this.goTo(`/activity/timecard/custom/list/${params.id}/split/add`, 'old')}
                >
                  Manage Activities and Tasks
                </Button>
              </div>
            }
          </section>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  activitySplitList: ActivitySplitDucks.activitySplitList(state),
  location: RouteDucks.location(state),
  dailyTimecardDetail: DailyTimecardDucks.dailyTimecardDetail(state),
});

const mapActionsToProps = (dispatch) => ({
});

ActivitySplitFilterSection.propTypes = {
  location: PropTypes.object.isRequired,
};

ActivitySplitFilterSection.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(ActivitySplitFilterSection);
