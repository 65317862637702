import React, {PureComponent} from 'react';
import Link from 'react-router-dom/Link';
import { Formik, Field } from 'formik';
import { Form,FormGroup, FormText, Input,Button, Card, CardTitle } from 'reactstrap';
import ButtonLoaderAtom from 'atoms/ButtonLoader';
import LogoMainBlueAtom from 'atoms/LogoMainBlue';
import CountryCodeFormik from 'generics/CountryCodeFormik';
import name from './name.png';
import styles from './styles.module.scss';
import {sanitizationDangerousHTML} from "common/sanitization.js"



class ActivationForm extends PureComponent {

  constructor(props) {
    super(props);
    this.state={
      close:true,
      updatingEmail: false,
      updatingPhone: false,
      emailActivating: false,
      phoneActivating: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.changeEmailOrPhone = this.changeEmailOrPhone.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  async onSubmit(values, {setSubmitting, setErrors}) {
    let code;
    let media;
    if(this.state.emailActivating){
      code = values.code1 ;
      media = 'EMAIL';
    }
    else{
      code = values.code2 ;
      media = 'SMS';
    }
    setErrors({});
    this.props.usecase.setup_data({ ...values , code , media});
    try {
      await this.props.usecase.execute();
    } catch (e) {
        setErrors(e.errors);
        this.updateState('phoneActivating', false);
        this.updateState('emailActivating', false);
    }
    setSubmitting(false);
  }

  changeEmailOrPhone(values, type , setErrors){

    const { data } = this.props;
    let detail = {};
    if(type === 'email') {
      this.updateState('updatingEmail', true);
      detail.email = values.email;
    }
    if(type === 'phone') {
      this.updateState('updatingPhone', true);
      detail.phone_ext = values.company.phone_ext ? values.company.phone_ext : '+1';
      detail.phone_no = values.phone_no;
    }

    this.props.usecase.updateCompanyProfile(detail, type, data.company.id)
      .then(() => {
        this.updateState('updatingEmail', false);
        this.updateState('updatingPhone', false);
      })
      .catch((e) => {
        
        setErrors(e.errors);
        this.updateState('updatingEmail', false);
        this.updateState('updatingPhone', false);
    });
  }
 
  render(){

    const { updatingPhone, emailActivating, phoneActivating } = this.state;
    const { data, editPno, updateState, resendingCode } = this.props;
    return (
      <div>
      <Card className="ui-card mt-4">
        <div className="text-center pb-4 pt-1">
          <LogoMainBlueAtom height="85" className={`${styles['logo']}`} width="130"/><br />
          <img src={name} height="26px" width="150px" alt="Logo"/>
        </div>
        <Formik
          initialValues={data}
          onSubmit={this.onSubmit}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            submitForm,
            setErrors,
            setFieldValue,
            }) => {
            return(
              <Form onSubmit={handleSubmit} className="ml-5 mr-5">
                <FormGroup>
                  <div>
                    <CardTitle className={`${styles['heading']} text-left ml-3`}>Hi {data.first_name}!</CardTitle>
                    <FormText color="danger" className="pl-3">{errors && errors.non_field_errors}</FormText>
                    <div style={{minHeight: '2vh'}}>
                      <FormText color="danger" className="pl-3">{errors && errors.code}</FormText>
                    </div>
                    <div>
                      <p className={`${styles['label-part']} mb-2 ml-3`}>Enter the activation code sent on your email id :</p>
                      <p className="mb-2 ml-3"><b>{data.email}</b></p>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <Input
                        onChange={(e)=> {
                          let val = sanitizationDangerousHTML(e.target.value);
                          setFieldValue("code1",val)
                        }}
                        onBlur={ handleBlur }
                        value={values.code1}
                        type="text" 
                        name="code1"
                        className="text-center"
                      />
                    </div>
                  </div>
                </FormGroup>
                 <FormGroup className="mb-3 ml-3 mr-3 mt-2 text-center">
                  <span>
                   <Button
                      className="mt-2 mb-3"
                      onClick={() => {
                        this.updateState('emailActivating', true);
                        submitForm()
                      }}
                      isColor='primary'
                      //type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      className={`${styles['accounts-button']}`}
                    >
                      Activate <ButtonLoaderAtom active={emailActivating} />
                    </Button>
                  </span>
                </FormGroup>
                <div className="text-center mb-3"><h6>or</h6></div>
                <FormGroup>
                  <div>
                    <div>
                      {!editPno ?
                        <div>
                          <p className={`${styles['label-part']} mb-2 ml-3`}>Enter the activation code sent on your phone number :</p>
                          <p className="mb-2 ml-3"><b>{data.company.phone_ext} {data.phone_no}</b></p>
                          <div className="ml-2 mb-2">
                            <span onClick={() => updateState('editPno', !editPno)} className="ml-2 resend">Click to change Phone Number</span>
                          </div>
                        </div>
                      :
                      <FormGroup className="ml-3 mr-3">
                      <div className="input-group justify-content-center">
                        <span className="mr-2">
                          <Field
                            id="company.phone_ext"
                            name="company.phone_ext"
                            component={CountryCodeFormik}
                            className={`${errors && errors.phone_no ? "form-control-error" : null}`}
                          />
                        </span>
                      <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_ext}</FormText></div>                                                          
                        <Input
                          type="tel"
                          name="phone_no"
                          placeholder="Enter new phone number"
                          onChange={(e)=> {
                            let val = sanitizationDangerousHTML(e.target.value);
                            setFieldValue("phone_no",val)
                          }}
                          onBlur={handleBlur}
                          value={values.phone_no}
                          invalid={errors && touched.phone_no && errors.phone_no}
                          className="form-control phoneField"
                        />                            
                        <Button  type="button" className="okay" onClick={() => this.changeEmailOrPhone(values, 'phone' , setErrors )}>
                          OK <ButtonLoaderAtom active={updatingPhone} />
                        </Button>
                        </div>
                        <div style={{minHeight: '3vh'}}><FormText color="danger">{errors && errors.phone_no}</FormText></div>                          
                      </FormGroup>
                      }
                         
                     {/* <input type="text" id="name" name="activation_code_1"  maxLength="1" />
                      <input type="text" id="name" name="activation_code_2"  maxLength="1" />
                      <input type="text" id="name" name="activation_code_3"  maxLength="1" />
                      <input type="text" id="name" name="activation_code_4"  maxLength="1" />
                      <input type="text" id="name" name="activation_code_5"  maxLength="1" />
                      <input type="text" id="name" name="activation_code_6"  maxLength="1" />*/}
                       <div className="col d-flex justify-content-center">
                  <Input
                    onChange={(e)=> {
                      let val = sanitizationDangerousHTML(e.target.value);
                      setFieldValue("code2",val)
                    }}
                    onBlur={ handleBlur }
                    value={values.code2}
                    type="text" 
                    name="code2"
                    className="text-center"
                  />
                  </div>
                    </div>
                  </div>

                </FormGroup>
                 <FormGroup className=" ml-3 mr-3 mt-2 text-center">
                  <span>
                   <Button
                      className="mt-2 mb-3"
                      onClick={() => {
                        this.updateState('phoneActivating', true);
                        submitForm();
                      }}
                      isColor='primary'
                     // type="submit"
                      disabled={isSubmitting}
                      isLoading={isSubmitting}
                      className={`${styles['accounts-button']}`}
                    >
                      Activate <ButtonLoaderAtom />
                    </Button>
                  </span>
                </FormGroup>  
                <div className= "text-center w-100 mt-4">
                     Didn't receive code? 
                      <span
                        className="pl-1 resend"
                        onClick={() => {
                          updateState('resendingCode', true);
                          this.props.usecase.resendActivationCodes();
                        }}
                      >
                        Resend code
                      </span>
                  </div>                
              </Form>
            )}}
          />
       </Card>
       </div>
    );
  }
}

export default ActivationForm;
