import React from 'react';
import { Row, Col} from 'reactstrap';
import queryString from 'query-string';
import UserAPIGateway from 'accounts/gateways/user';
import ResetPasswordForm from 'accounts/components/resetPassword';
import ContentLayout from 'layouts/Content';
import ResetPasswordUseCase from 'accounts/usecases/resetPassword';
import { toast } from 'react-toastify';

class ResetPasswordPage extends React.PureComponent {
  constructor(props) {
    super(props);    
    this.updateState = this.updateState.bind(this);
    const userAPIGateway = new UserAPIGateway();
    this.usecase = new ResetPasswordUseCase(userAPIGateway);
    this.state = {  uid: '' };
    this.listenResetPasswordUsecase = this.listenResetPasswordUsecase.bind(this);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }
   componentWillMount() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.updateState('uid', query.uid);
    this.listenResetPasswordUsecase();
  }

   
  listenResetPasswordUsecase(){
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case 'RESENT_SUCCESS':
          toast.success("Password updated successfully");
          this.props.history.push('/login');
          break;
         default:
          console.log('Sorry, we are not handling this');
      }
    });
  }


  render() {
    return (
       <ContentLayout>
        <Row className="content-area">
          <Col xs={12} md={{ size:4, offset:4 }} className="align-self-center" >
            <ResetPasswordForm  usecase={this.usecase} uid={this.state.uid}/>
          </Col>
        </Row>   
      </ContentLayout> 
    );
  }
}


export default ResetPasswordPage;
