import React from 'react';

import PropTypes from 'prop-types';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorized';
import NoMatch from 'pages/NoMatch';
import PayrollFormPage from './Form';
import PayrollListPage from './List';
import PayrollSettingListPage from './Setting';
import PayrollSettingFormPage from './SettingForm';

const PayrollRoutes = (props) => {
  const { match } = props;
  return (
    <Switch>
      <Redirect exact from={match.url} to={`${match.url}/list`} />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/list`}
        name="payroll.list"
        component={PayrollListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/add`}
        name="payroll.add"
        component={PayrollFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/:id/detail`}
        name="payroll.detail"
        component={PayrollFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/setting/list`}
        name="payroll.setting.list"
        component={PayrollSettingListPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/setting/add`}
        name="payroll.setting.add"
        component={PayrollSettingFormPage}
      />
      <MatchWhenAuthorized
        exact
        path={`${match.url}/setting/:id/edit`}
        name="payroll.setting.edit"
        component={PayrollSettingFormPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

PayrollRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default PayrollRoutes;
