import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as ActiveIcon} from './active.svg';
import {ReactComponent as InActiveIcon} from './inactive.svg';

const ActiveIconAtom = (props) => {
  const { active, ...rest } = props;
  return active ? <ActiveIcon {...rest} /> : <InActiveIcon {...rest} />;
};

ActiveIconAtom.defaultProps = {
  width: 15,
  height: 15,
};

ActiveIconAtom.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default ActiveIconAtom;
