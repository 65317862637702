import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as PlusIcon} from './plus.svg';
import {ReactComponent as MinusIcon} from './minus.svg';

const CollapseIconAtom = (props) => {
  const { active, ...rest } = props;
  return active ? <MinusIcon {...rest} /> : <PlusIcon {...rest} />;
};

CollapseIconAtom.defaultProps = {
  width: 15,
  height: 15,
};

CollapseIconAtom.propTypes = {
  active: PropTypes.bool,
  height: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  width: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
};

export default CollapseIconAtom;
