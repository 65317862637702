import { createSelector } from 'reselect';

import axios from 'helpers/interceptor';
import createReducer from 'helpers/createReducer';
import URLS from './urls';

const GET_PAYROLLS = 'payrolls/payroll/GET_PAYROLLS';
const GET_PAYROLLS_FULFILLED = 'payrolls/payroll/GET_PAYROLLS_FULFILLED';
const GET_ALL_PAYROLLS = 'payrolls/payroll/GET_ALL_PAYROLLS';
const GET_ALL_PAYROLLS_FULFILLED = 'payrolls/payroll/GET_ALL_PAYROLLS_FULFILLED';
const GET_ALL_PAYROLLS_PENDING = 'payrolls/payroll/GET_ALL_PAYROLLS_PENDING';
const GET_PAYROLL = 'payrolls/payroll/GET_PAYROLL';
const GET_PAYROLL_FULFILLED = 'payrolls/payroll/GET_PAYROLL_FULFILLED';
const POST_PAYROLL = 'payrolls/payroll/POST_PAYROLL';
const POST_PAYROLL_FULFILLED = 'payrolls/payroll/POST_PAYROLL_FULFILLED';
const POST_PAYROLL_CONTACT_US = 'payrolls/payroll/POST_PAYROLL_CONTACT_US';
const PUT_PAYROLL = 'payrolls/payroll/PUT_PAYROLL';
const PUT_PAYROLL_FULFILLED = 'payrolls/payroll/PUT_PAYROLL_FULFILLED';
const DELETE_PAYROLL = 'payrolls/payroll/DELETE_PAYROLL';
const GET_PAYROLLS_OF_DEPARTMENT = 'payrolls/payroll/GET_PAYROLLS_OF_DEPARTMENT';
const GET_PAYROLLS_OF_DEPARTMENT_FULFILLED = 'payrolls/payroll/GET_PAYROLLS_OF_DEPARTMENT_FULFILLED';

export function getPayrolls(params) {
  return {
    type: GET_PAYROLLS,
    payload: axios.get(URLS.PAYROLL, { params }),
  };
}

export function getAllPayrolls(params) {
  return {
    type: GET_ALL_PAYROLLS,
    payload: axios.get(URLS.PAYROLL, { params }),
  };
}

export function getPayroll(params) {
  return {
    type: GET_PAYROLL,
    payload: axios.get(`${URLS.PAYROLL}${params.id}/`),
  };
}

export function deletePayroll(params) {
  return {
    type: DELETE_PAYROLL,
    payload: axios.delete(`${URLS.PAYROLL}${params.id}/`),
  };
}

export function postPayroll(data) {
  return {
    type: POST_PAYROLL,
    payload: axios.post(URLS.PAYROLL, data),
  };
}

export function postPayrollContactUs(data) {
  return {
    type: POST_PAYROLL_CONTACT_US,
    payload: axios.post(URLS.PAYROLL_CONTACT_US, data),
  };
}

export function putPayroll(data) {
  return {
    type: PUT_PAYROLL,
    payload: axios.put(`${URLS.PAYROLL}${data.id}/`, data),
  };
}

export function getPayrollsOfDepartment(params) {
  return {
    type: GET_PAYROLLS_OF_DEPARTMENT,
    payload: axios.get(URLS.PAYROLL_DEPARTMENT, { params }),
  };
}

const defaultState = {
  list: {},
  detail: {},
  all: [],
  departmentPayroll: [],
};

function GET_PAYROLLS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    list: action.payload,
  });
}

function GET_ALL_PAYROLLS_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: action.payload,
  });
}

function GET_ALL_PAYROLLS_PENING_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    all: [],
  });
}

function GET_PAYROLL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}


function POST_PAYROLL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function PUT_PAYROLL_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    detail: action.payload,
  });
}

function GET_PAYROLLS_OF_DEPARTMENT_FULFILLED_REDUCER(state, action) {
  return Object.assign({}, state, {
    departmentPayroll: action.payload,
  });
}

const handlers = {
  [GET_PAYROLLS_FULFILLED]: GET_PAYROLLS_FULFILLED_REDUCER,
  [GET_PAYROLLS_OF_DEPARTMENT_FULFILLED]: GET_PAYROLLS_OF_DEPARTMENT_FULFILLED_REDUCER,
  [GET_ALL_PAYROLLS_FULFILLED]: GET_ALL_PAYROLLS_FULFILLED_REDUCER,
  [GET_ALL_PAYROLLS_PENDING]: GET_ALL_PAYROLLS_PENING_FULFILLED_REDUCER,
  [GET_PAYROLL_FULFILLED]: GET_PAYROLL_FULFILLED_REDUCER,
  [POST_PAYROLL_FULFILLED]: POST_PAYROLL_FULFILLED_REDUCER,
  [PUT_PAYROLL_FULFILLED]: PUT_PAYROLL_FULFILLED_REDUCER,
};


const payrollSelector = state => state.payrolls.payroll;

export const payrollList = createSelector(
  payrollSelector,
  instance => instance.list,
);

export const payrollAll = createSelector(
  payrollSelector,
  instance => instance.all
);

export const payrollDetail = createSelector(
  payrollSelector,
  instance => instance.detail,
);

export const departmentPayroll = createSelector(
  payrollSelector,
  instance => instance.departmentPayroll,
);

export default createReducer(defaultState, handlers);
