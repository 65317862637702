import React,{PureComponent} from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import Flatpickr from 'react-flatpickr';
import { toast } from 'react-toastify';
import EditableCellAtom from 'atoms/EditableCell';
import Card from 'reactstrap/lib/Card';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Label from 'reactstrap/lib/Label';
import { secondsToHours } from 'helpers/utils';
import styles from './styles.module.scss';
import {ReactComponent as DeleteIcon} from './bin.svg';
import {ReactComponent as EditIcon} from './editTask.svg';

/**
 * ActivitySplitFormPage -> ActivitySplitTimelineSection -> TaskComponent
 *
 * Props:
 *    None
 */
class TaskComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.updatable=this.updatable.bind(this);
    this.updateEntry= this.updateEntry.bind(this);
    this.state = { startDate : props.item && props.item.start_datetime , startTime :  props.item && moment(props.item.start_datetime).format('LT') ,
     endDate : props.item && props.item.end_datetime , endTime :  props.item && moment(props.item.end_datetime).format('LT')};
  }

  updateEntry(item, date, key){
    const { editTask, browserParseDateISO } = this.props;
    const { startTime , endTime } = this.state;
    if(key == 'start'){
      this.setState({startDate : date});
      const updatedTime = moment(startTime, "h:mm:ss A").format("HH:mm:ss");
      const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      const updatedItem = {...item, start_datetime : updatedDateTime};
      editTask({ ...updatedItem, start_datetime: browserParseDateISO(updatedItem.start_datetime, false), end_datetime : browserParseDateISO(item.end_datetime, false)});
    }
    else if(key == 'end'){
      this.setState({endDate : date});
      const updatedTime = moment(endTime, "h:mm:ss A").format("HH:mm:ss");
      const updatedDateTime = moment(`${moment(date).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      const updatedItem = {...item, end_datetime : updatedDateTime};
      editTask({ ...updatedItem,start_datetime : browserParseDateISO(item.start_datetime, false), end_datetime: browserParseDateISO(updatedItem.end_datetime, false)});
    }

    
  }

  updatable(data , key, value, index){
    const { editTask, browserParseDateISO } = this.props;
    if(!value){
      return toast.error('Please enter time');
    }
    const updatedTime = moment(value, "h:mm:ss A").format("HH:mm:ss");
    let updatedDateTime, updatedItem;
    if(key == 'start_time'){
      updatedDateTime = moment(`${moment(data.start_datetime).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      updatedItem = {...data, start_datetime : browserParseDateISO(updatedDateTime, false),end_datetime : browserParseDateISO(data.end_datetime, false)};
      this.setState({startTime : value });
    }
    else if(key == 'end_time'){
      updatedDateTime = moment(`${moment(data.end_datetime).format('YYYY-MM-DD')}T${updatedTime}`, 'YYYY-MM-DD HH:mm:ss')
      updatedItem = {...data, start_datetime : browserParseDateISO(data.start_datetime, false), end_datetime : browserParseDateISO(updatedDateTime, false)};
      this.setState({endTime : value });
    }  
    editTask(updatedItem, index);
  }

  validate(val,data, key){
    const { userTimezone } = this.props;
    const format = (val).toString(10).split("");
    const startDateTz = data && data.start_datetime && data.start_datetime.split(" ");
    const endDateTz = data && data.end_datetime && data.end_datetime.split(" ");
    const standardFormat = format.find((item) => (item === ':'));
    if((val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9]:([0-5]?[0-9])[\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':')
        || (val.match('^([01]?[0-9]|2[0-3]):[0-5][0-9][\' \']*([?:(aApP)][mM]?)?$') && standardFormat === ':') || (val.match('^([01]?[0-9]|2[0-3])[\' \']*([?:(aApP)][mM]?)?$')) && (standardFormat !== ':')){
      if(key && key == 'start_time' && startDateTz && startDateTz.length && moment(startDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      else if(key && key == 'end_time' && endDateTz && endDateTz.length && moment(endDateTz[0]).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
        if(moment.tz(moment.tz(userTimezone).format("h:mm:ss A"),"h:mm:ss A", userTimezone) < moment.tz(val, "h:mm:ss A", userTimezone)){
          toast.error('Time entered should be less than current time');
          return false;
        }
      }
      //updateTime(val,data);
      return true;
    }
    if(val===''){
      return true;
    }
    toast.error("Enter valid time");
    //this.setState({editable: false});
    return false;
  }

  render(){
    const {
      dateFormat,
      timecardDecimalView,
      item,
      timeFormat,
      timeFormat24Hrs,
      jobName,
      taskName,
      editTask,
      removeTask,
      index,
      task,
      isApproved,
      editTaskEntry,
      id,
      userTimecardFilters,
      browserParseDate
    } = this.props;

    const isApprovedFlag = isApproved && JSON.parse(isApproved);
    return (
      <div className='override_alignment'>
        {!this.props.newStyle ? 
          <Card block className={`${styles.timelinePointer} mb-3 p-2 pb-4 activityCard`}>
            <span className={styles['timeline-badge__task']}></span>
            <Row className={`${styles['block-display']}`}>
              <Col className="pr-0">
                <div className={`f16 mt-2 mb-2 pl-3 ${styles['task-color']}`}>
                  <span className={`${styles['fw']} mb-0 mr-2`}>Job :</span>
                  <div className='job_task_name'>
                    {jobName }
                  </div>
                </div>
                <div className={`f16 mb-2 pl-3 ${styles['task-color']}`}>
                  <span className={`${styles['fw']} mb-0 mr-2`}>Task :</span>
                  <div className="job_task_name">{taskName}</div>
                </div>
              </Col>

              <Col>
                <Row className="f16 mb-0 pt-2 pl-3 pr-2">
                  <Col md={6} className={`${styles['task-width']} mr-3`}>
                    <Label className={`${styles['start-time-label']} mb-0`}> Start Time </Label>
                    <Flatpickr
                      className="form-control mb-2 mt-1"
                      value={item.start_datetime}
                      options={{
                        dateFormat: `${dateFormat}, ${timeFormat}`,
                        enableTime: true,
                        minuteIncrement: 1,
                        time_24hr: timeFormat24Hrs,
                       /* noCalendar: !selectedShift.session_split,*/
                        enable: [
                          // Enable the dates b/w payroll startDate & endDate
                          // Also no future date selection
                          (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                            moment(date).isBefore(moment(endDate)) &&*/
                            moment(date).isBefore(moment()),
                        ],
                      }}
                      disabled = {isApprovedFlag}
                      onChange={date => editTask({ ...item, start_datetime: date[0] }, index)}
                    />
                  </Col>
                  <Col md={6} className={`${styles['task-width']} mr-1`}>
                    <Label className={`${styles['end-time-label']} mb-0`}> End Time </Label>
                    <Flatpickr
                      className="form-control mb-2 mt-1"
                      value={item.end_datetime}
                      options={{
                        dateFormat: `${dateFormat}, ${timeFormat}`,
                        enableTime: true,
                        minuteIncrement: 1,
                        time_24hr: timeFormat24Hrs,
                        // noCalendar: !selectedShift.session_split,
                        enable: [
                          // Enable the dates b/w payroll startDate & endDate
                          // Also no future date selection
                          (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                            moment(date).isBefore(moment(endDate)) &&*/
                            moment(date).isBefore(moment()),
                        ],
                      }}
                      disabled = {isApprovedFlag}
                      onChange={date => editTask({ ...item, end_datetime: date[0] }, index)}
                    />
                  </Col>
                  <Col className={`${styles['delete-icon-width']} pt-4 mt-2`}>
                {!isApprovedFlag ?
                  <span>
                    <DeleteIcon
                      className={`${styles['del-btn']} mb-0`}
                      width={15} height={16}
                      onClick={() => removeTask(item, index)}
                      >
                      Remove
                    </DeleteIcon>
                  </span>
                  : null
                }
              </Col>
                </Row>
                {item.id &&
                  <Row className={`${styles['allocation-div']} pl-4 pr-3`}>
                    <small className={`${styles['allocation-of-hours']} w-100 pb-1 pt-2 text-center`}>Allocation of Hours</small>
                    <hr width="100%" className="mb-0 mt-0 ml-2"/>
                  </Row>
                }
                {item.id &&
                  <Row className={`${styles['allocation-div']} pt-2`}>
                    <Col className="text-center">
                      <h5 className={`${styles['allocation-of-hours']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.total_time) : item.total_time_display)
                        : timecardDecimalView ? secondsToHours(task.total_time) : item.total_time_display
                      }
                      </h5>
                      <small className={`${styles['allocation-of-hours']}`}>Total</small>
                    </Col>
                    <Col className="text-center">
                      <h5 className={`${styles['allocation-of-hours']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.regular_time) : task.regular_time_display ? task.regular_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.regular_time) : task.regular_time_display ? task.regular_time_display : '0'}
                        </h5>
                      <small className={`${styles['allocation-of-hours']}`}>Regular</small>
                    </Col>
                    <Col className="text-center">
                      <h5 className={`${styles['allocation-of-hours']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.over_time) : task.over_time_display ? task.over_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.over_time) :task.over_time_display ? task.over_time_display : '0'}
                      </h5>
                      <small className={`${styles['allocation-of-hours']}`}>Overtime</small>
                    </Col>
                    <Col className="text-center">
                      <h5 className={`${styles['allocation-of-hours']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.double_over_time) : task.double_over_time_display ? task.double_over_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.double_over_time) : task.double_over_time_display ? task.double_over_time_display : '0'}
                      </h5>
                      <small className={`${styles['allocation-of-hours']}`}>Double Overtime</small>
                    </Col>
                  </Row>}
              </Col>
            </Row>
          </Card>
          :
          <div id={id} className={item.id === this.props.savedId ? `${styles['jobDiv']}  ${styles['apply-shake']}` : `${styles['jobDiv']} `}>
            <div className={`mb-0 pt-2 pl-2 d-inline-block widthCustom`}>
                <div>
                  <div className="d-inline-block customName w-100">
                    <span className={`${styles['fw']} ${styles['job-task-heading']} f16 mb-0 mr-2`}>Job:</span>
                    <span> {jobName}</span>
                     {!isApprovedFlag ?
                      <span>
                        <DeleteIcon
                        className={`${styles['del-btn']}  ${styles['delete-meal']} mb-0`}
                        width={15} height={16}
                        onClick={() => removeTask(item, index)}
                        >
                        Remove
                        </DeleteIcon>
                        <EditIcon 
                          className={`${styles['del-btn']}  ${styles['edit-task']} mb-0`}
                          width={15} height={16}
                          onClick={() => editTaskEntry(item, index)}
                          >
                          Remove
                        </EditIcon>
                      </span>
                        : null
                      }
                    </div>
                </div>
                <span className={`${styles['fw']} ${styles['job-task-heading']} f16 mb-0 mr-2`}>
                  Task: 
                </span>
                <span> {taskName}</span>
            </div>
            <div className="pt-2 pr-2 pl-2 w-100">
              <div className="d-flex">
                <div className={`${styles['meal-job-date-label']} mr-4`}>
                  <div className={`${styles['start-time-label']} mb-0 pt-2 d-inline-block`}>Start Date</div>
                  <span className='datePicker override_class'>
                     <Flatpickr
                       className={`${styles['date-label']} form-control mb-0 mt-1`}
                        value={item.start_datetime}
                        options={{
                          dateFormat: `${dateFormat}`,
                          enableTime: false,
                          minuteIncrement: 1,
                          time_24hr: timeFormat24Hrs,
                         /* noCalendar: !selectedShift.session_split,*/
                          enable: [
                            // Enable the dates b/w payroll startDate & endDate
                            // Also no future date selection
                            (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                              moment(date).isBefore(moment(endDate)) &&*/
                              moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                          ],
                        }}
                          disabled = {isApprovedFlag}
                          onChange={(date)=> this.updateEntry(item, date[0], 'start')}
                      />
                  </span>
                </div>
                <div className={`${styles['meal-job-date-label']}`}>
                  <div className={`${styles['end-time-label']} mb-1 pt-2`}>Start Time</div>
                  <span className='datePicker override_class edit-start-time'>
                     {/*<Flatpickr
                       className={`${styles['date-label']} form-control mb-0 mt-1`}
                        value={item.end_datetime}
                        options={{
                          dateFormat: `${dateFormat}, ${timeFormat}`,
                          enableTime: true,
                          minuteIncrement: 1,
                          time_24hr: timeFormat24Hrs,
                         /* noCalendar: !selectedShift.session_split,*/
                          /*enable: [
                            // Enable the dates b/w payroll startDate & endDate
                            // Also no future date selection
                            (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                              moment(date).isBefore(moment(endDate)) &&*/
                              /*moment(date).isBefore(moment()),
                          ],
                        }}
                          disabled={isApprovedFlag}
                          onChange={date => editTask({ ...item, end_datetime: date[0] }, index)}
                      />*/}
                      <EditableCellAtom
                        value={this.state.startTime}
                        valueKey="start_time"
                        details={{...item , start_datetime : moment(this.state.startDate).toISOString()}}
                        editable={true}
                        update={this.updatable}
                        validate={this.validate}
                        index={index}
                      />
                  </span>
                </div>
              </div>
              <div className="d-flex">
                <div className={`${styles['meal-job-date-label']} mr-4`}>
                  <div className={`${styles['start-time-label']} mb-0 pt-2 d-inline-block`}>End Date</div>
                  <span className='datePicker override_class'>
                     <Flatpickr
                       className={`${styles['date-label']} form-control mb-0 mt-1`}
                        value={this.state.endDate}
                        options={{
                          dateFormat: `${dateFormat}`,
                          enableTime: false,
                          minuteIncrement: 1,
                          time_24hr: timeFormat24Hrs,
                         /* noCalendar: !selectedShift.session_split,*/
                          enable: [
                            // Enable the dates b/w payroll startDate & endDate
                            // Also no future date selection
                            (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                              moment(date).isBefore(moment(endDate)) &&*/
                              moment(date).isBefore(browserParseDate(moment.utc().add(2, 'second').format()).toISOString())
                          ],
                        }}
                          disabled = {isApprovedFlag}
                          onChange={(date)=> this.updateEntry(item, date[0], 'end')}
                      />
                  </span>
                </div>
                <div className={`${styles['meal-job-date-label']}`}>
                  <div className={`${styles['end-time-label']} mb-1 pt-2 override_alignment`}>End Time</div>
                  <span className='datePicker override_class edit-start-time'>
                     {/*<Flatpickr
                       className={`${styles['date-label']} form-control mb-0 mt-1`}
                        value={item.end_datetime}
                        options={{
                          dateFormat: `${dateFormat}, ${timeFormat}`,
                          enableTime: true,
                          minuteIncrement: 1,
                          time_24hr: timeFormat24Hrs,
                         /* noCalendar: !selectedShift.session_split,*/
                          /*enable: [
                            // Enable the dates b/w payroll startDate & endDate
                            // Also no future date selection
                            (date) => /*moment(date).isAfter(moment(startDate).subtract(1, 'day')) &&
                              moment(date).isBefore(moment(endDate)) &&*/
                              /*moment(date).isBefore(moment()),
                          ],
                        }}
                          disabled={isApprovedFlag}
                          onChange={date => editTask({ ...item, end_datetime: date[0] }, index)}
                      />*/}
                    <EditableCellAtom
                      value={this.state.endTime}
                      valueKey="end_time"
                      details={{...item , end_datetime : moment(this.state.endDate).toISOString()}}
                      editable={true}
                      update={this.updatable}
                      validate={this.validate}
                      index={index}
                    />
                  </span>
                </div>
              </div>
              {item.id &&
                <div className={`${styles['fw']} ${styles['task-hours-font']} mb-1 mt-4`}>
                  Hours Allocation
                </div>
              }
              {item.id && 
                <Row className={`${styles['job-row-flex']} pt-2`}>
                  <Col className="text-center pr-0 pl-1">
                    <h5 className={`${styles['hours-font']} mb-1`}>
                      { userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.regular_time) : task.regular_time_display ? task.regular_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.regular_time) : task.regular_time_display ? task.regular_time_display : '0'
                      }
                    </h5>
                    <small className={`${styles['fw']} ${styles['task-hours-font']}`}>Regular</small>
                  </Col>
                  <Col className="pl-2 pr-0" md={1}><h4> + </h4></Col>
                  <Col className="text-center pl-0 pr-0">
                    <h5 className={`${styles['hours-font']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.over_time) : task.over_time_display ? task.over_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.over_time) :task.over_time_display ? task.over_time_display : '0'
                      }
                    </h5>
                    <small className={`${styles['fw']} ${styles['task-hours-font']}`}>Overtime</small>
                  </Col>
                  <Col className="pr-0 pl-2" md={1}><h4> + </h4></Col>
                  <Col className="text-center pl-0 pr-0">
                    <h5 className={`${styles['hours-font']} mb-1`}>
                      {userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.double_over_time) : task.double_over_time_display ? task.double_over_time_display : '0')
                        : timecardDecimalView ? secondsToHours(task.double_over_time) : task.double_over_time_display ? task.double_over_time_display : '0'
                      }
                      </h5>
                    <small className={`${styles['fw']} ${styles['task-hours-font']}`}>Double {"\n"} Overtime</small>
                  </Col>
                  <Col className="pl-2 pr-0" md={1}><h4> = </h4></Col>
                  <Col className="text-center pl-0 pr-1">
                    <h5 className={`${styles['hours-font']} mb-1`}>
                      { userTimecardFilters && userTimecardFilters.data
                        ? ((userTimecardFilters.data.timecard_decimal_view == true || userTimecardFilters.data.timecard_decimal_view == "true")
                             ? secondsToHours(task.total_time) : item.total_time_display)
                        : timecardDecimalView ? secondsToHours(task.total_time) : item.total_time_display
                      }
                    </h5>
                    <small className={`${styles['fw']} ${styles['task-hours-font']}`}>Total</small>
                  </Col>
              </Row>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

TaskComponent.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  item: PropTypes.object.isRequired,
  startDate: PropTypes.string,
  timeFormat: PropTypes.string.isRequired,
  timecardDecimalView: PropTypes.bool.isRequired,
  timeFormat24Hrs: PropTypes.bool.isRequired,
  editTask: PropTypes.func.isRequired,
  removeTask: PropTypes.func.isRequired,
  jobName: PropTypes.string,
  taskName: PropTypes.string,
};

export default TaskComponent;
