import React, { Component } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import cookie from "react-cookies";

import AssignedEmployeesTable from "forms/components/assignedEmployeesTable";
import EmployeesFilter from "forms/components/employeesFilter";
import PaginationComponent from "components/common/Pagination";
import GenericConfirmationModal from "components/common/GenericDeleteConfirmationModal";

import listUseCase from "forms/usecases/formsListUsecase";
import FormsAPIGateway from "forms/gateways/forms";
import Button from "reactstrap/lib/Button";
import styles from "forms/components/styles.module.scss";

class AssignedEmployeesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      assignedEmployeesList: [],
      assignedEmployeesListCount: 0,
      deleteFlag: false,
      isDeleting: false,
      deleted_id: null,
      isAssigning: false,
    };
    const formsAPIGateway = new FormsAPIGateway();
    this.usecase = new listUseCase(formsAPIGateway);
    this.updateState = this.updateState.bind(this);
    this.loadData = this.loadData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.toggleDelteModal = this.toggleDelteModal.bind(this);
    this.handleAssignAndDelete = this.handleAssignAndDelete.bind(this);
  }

  componentDidMount() {
    this.listenListUsecase();
    this.loadData();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery !== nextQuery) {
      this.fetchData(nextQuery);
    }
  }

  loadData() {
    const {
      history,
      location: { search, pathname },
    } = this.props;
    const query = queryString.parse(search);
    const pageSize = cookie.load("assigned_employees_list_size") || 10;
    if (!("page_size" in query)) {
      history.push({
        pathname,
        search: queryString.stringify({
          ...query,
          page_size: pageSize,
          page: query.page || 1,
          is_active: true,
        }),
      });
    } else {
      this.fetchData(query);
    }
  }

  fetchData(params) {
    let obj = {
      ...params,
      form_id: this.props?.id,
    };
    delete obj.parent;
    this.usecase.getAssignedEmployeesList(obj);
  }

  updateState(key, value) {
    this.setState({
      [key]: value,
    });
  }

  toggleDelteModal(id = null) {
    this.setState({
      deleteFlag: !this.state.deleteFlag,
      isDeleting: false,
      deleted_id: id,
    });
  }

  handleAssignAndDelete(selectedEmployee = [], isAllEmployeesSelected = false) {
    const { deleted_id } = this.state;
    let params = {};
    let type = "";
    params = {
      id: this.props.id,
      assign_employees: selectedEmployee,
      assign_all_employees: isAllEmployeesSelected,
      remove_employees: deleted_id !== null ? [deleted_id] : [],
    };
    if (selectedEmployee.length || isAllEmployeesSelected) {
      this.setState({ isAssigning: true });
      type = "assigned";
    } else {
      this.setState({ isDeleting: true });
      type = "removed";
    }
    this.usecase.assignAndDeleteEmployeesFromForms(params, type);
  }

  listenListUsecase() {
    this.usecase.getObservable().subscribe((event) => {
      switch (event.type) {
        case "GET_ASSIGNED_EMPLOYEES_LIST_SUCCESS":
          this.updateState("assignedEmployeesList", event.data.results);
          this.updateState("assignedEmployeesListCount", event.data.count);
          break;
        case "GET_ASSIGNED_EMPLOYEES_LIST_FAILED":
          toast.error("No Records Found!");
          break;
        case "ASSIGN_DELETE_EMPLOYEE_SUCCESS":
          this.setState({
            isAssigning: false,
            deleteFlag: false,
            isDeleting: false,
            deleted_id: null,
          });
          this.loadData();
          toast.success(`Employee has been ${event.data.type} successfully!!!`);
          break;
        case "ASSIGN_DELETE_EMPLOYEE_FAILURE":
          this.setState({
            isAssigning: false,
            deleteFlag: false,
            isDeleting: false,
            deleted_id: null,
          });
          toast.error(`Something went wrong!`);
          break;
        case "SHOW_LOADER":
          this.updateState("isLoading", true);
          break;
        case "HIDE_LOADER":
          this.updateState("isLoading", false);
          break;
        default:
          console.log("Sorry, we are not handling this");
      }
    });
  }

  render() {
    const {
      isLoading,
      assignedEmployeesList,
      assignedEmployeesListCount,
      deleteFlag,
      isDeleting,
      isAssigning,
    } = this.state;
    const { location, handleBack, backToListPage } = this.props;
    return (
      <div style={{ background: "white" }} className="pl-2 pr-2 pt-1 pb-1">
        <EmployeesFilter
          location={location}
          id={this.props.id}
          handleAssignAndDelete={this.handleAssignAndDelete}
          isAssigning={isAssigning}
        />
        <AssignedEmployeesTable
          list={assignedEmployeesList}
          isLoading={isLoading}
          handleDeleteEmployee={this.toggleDelteModal}
        />
        {assignedEmployeesListCount && !isLoading ? (
          <PaginationComponent
            count={assignedEmployeesListCount}
            location={location}
            cookieKey="assigned_employees_list_size"
            history={this.props.history}
          />
        ) : null}
        <div className={`d-flex justify-content-between px-3 pb-3 mt-3 `}>
          <button
            className={`${styles["back-secondary-color"]}`}
            onClick={() => handleBack()}
            disabled={isAssigning}
          >
            Back
          </button>
          <Button
            type="submit"
            color="primary"
            onClick={backToListPage}
            disabled={isAssigning}
          >
            Save
          </Button>
        </div>
        {deleteFlag && (
          <GenericConfirmationModal
            handleConfirmation={this.handleAssignAndDelete}
            toggle={this.toggleDelteModal}
            text={"Are you sure you want to delete it ?"}
            isSubmitting={isDeleting}
            isOpen={deleteFlag}
          />
        )}
      </div>
    );
  }
}

export default AssignedEmployeesComponent;
